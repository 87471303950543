import {ProjectAttachmentImportInitializationDataApiDto} from "./restModels";

export class ProjectAttachmentImportInitializationData {
	constructor(
		public readonly id: string,
		public readonly filename: string,
		public readonly contentType: string
	) {
	}

	public static fromJson(json: ProjectAttachmentImportInitializationDataApiDto): ProjectAttachmentImportInitializationData {
		return new ProjectAttachmentImportInitializationData(json.id, json.filename, json.contentType);
	}
}