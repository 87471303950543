import {EditorLine, LengthMeasurement} from "../../models/editor";
import {Vector2d} from "konva/types/types";
import {add, getMovedEditorLine} from "../../utils";

export default class LengthHelper {
	public static getUnscaledTotalLength(length: LengthMeasurement, scale: number | null): number {
		if (scale === null) return 0;
		const totalLength = length.lengthFragments
			.reduce<EditorLine[]>((prev, current) => [...prev, ...current.lines], [])
			.reduce((prev, current) => prev + current.distance, 0)
		const totalDrop = length.drops.reduce((prev, current) => prev + current.value, 0);
		// Since we moved scale from drawing version to drawing, returned value is no longer in meters but in pixels.
		// On the client side drop and wastage are entered and stored in meters, so before sending data to backend we have to
		// calculate drop and wastage in pixels to get the proper sum value.
		return totalLength + totalDrop / scale + (length.wastage || 0) / scale;
	}

	public static getTotalLength(length: LengthMeasurement, scale: number | null): number | undefined {
		if (scale === null) return;
		const totalLength = length.lengthFragments
			.reduce<EditorLine[]>((prev, current) => [...prev, ...current.lines], [])
			.reduce((prev, current) => prev + current.distance, 0)
		const totalDrop = length.drops.reduce((prev, current) => prev + current.value, 0);
		return (totalLength * scale) + totalDrop + (length.wastage || 0);
	}

	static getMovedLengths(lengths: LengthMeasurement[], delta: Vector2d): LengthMeasurement[] {
		return lengths.map(length => ({
			...length,
			lengthFragments: length.lengthFragments.map(fragment => ({
				...fragment,
				lines: fragment.lines.map(line => getMovedEditorLine(line, delta)),
				lastMouseUpPosition: fragment.lastMouseUpPosition
					? add(fragment.lastMouseUpPosition, delta)
					: fragment.lastMouseUpPosition
			}))
		}));
	}
}