import {Status} from "../models/enums";
import {QueryStatus} from "react-query";

export function toStatus(status: QueryStatus): Status {
	switch (status) {
		case "idle":
			return Status.IDLE
		case "error":
			return Status.ERROR
		case "loading":
			return Status.LOADING
		case "success":
			return Status.SUCCESS
	}
}