export enum EditorTool {
	SCALE = "SCALE",
	SCALE_INFO = "SCALE_INFO",
	LENGTH = "LENGTH",
	ARC = "ARC",
	COUNT = "COUNT",
	PEN = "PEN",
	AREA = "AREA",
	VOLUME = "VOLUME",
	TEXT = "TEXT",
	RULER = "RULER",
	IMAGE = "IMAGE",
	GROUP_MEASUREMENT_SELECT = "GROUP_MEASUREMENT_SELECT",
	MEASUREMENT_SELECT = "MEASUREMENT_SELECT",
	SYMBOL_RECOGNITION = "SYMBOL_RECOGNITION",
}

export enum GlobalVisibilityOptions {
	COUNT = "COUNT",
	LENGTH = "LENGTH",
	AREA = "AREA",
	VOLUME = "VOLUME",
	LAYERS = "LAYERS",
	VALUE_MARKS = "VALUE_MARKS"
}

export enum EditorPanelTabKeys {
	COUNT = "COUNT",
	LENGTH = "LENGTH",
	AREA = "AREA",
	VOLUME = "VOLUME",
	LAYERS = "LAYERS",
}

export enum MaterialType {
	CATALOG = "CATALOG",
	PREBUILD = "PREBUILD",
	ONE_OFF = "ONE_OFF",
	TEMPLATE = "TEMPLATE"
}

export enum FontFamily {
	SANS_SERIF = "sans-serif",
	SERIF = "serif",
	MONOSPACE = "monospace",
	CURSIVE = "cursive",
}

export enum ExportMode {
	PNG = "PNG",
	JPG = "JPG",
	PDF = "PDF"
}

export enum XAxisAnchor {
	LEFT = "LEFT", CENTER = "CENTER", RIGHT = "RIGHT"
}

export enum YAxisAnchor {
	TOP = "TOP", CENTER = "CENTER", BOTTOM = "BOTTOM"
}

export enum MeasurementType {
	COUNT = "COUNT",
	LENGTH = "LENGTH",
	AREA = "AREA",
	VOLUME = "VOLUME"
}

export enum EditorPanelMode {
	TAKEOFF_ITEMS = "TAKEOFF_ITEMS",
	PINNED_ITEMS = "PINNED_ITEMS"
}

export enum FillType {
	SOLID = "SOLID",
	DIAGONAL_PATTERN = "DIAGONAL_PATTERN"
}

export enum DrawingErrorTypes {
	VERSION_LOCK = "VERSION_LOCK",
	DRAWING_LOCK = "DRAWING_LOCK",
	DEFAULT = "DEFAULT"
}

export enum LockError {
	LOCK = "LOCK",
	DEFAULT = "DEFAULT"
}


export enum DeletedTreeElementModals {
	EXISTING = "EXISTING",
	NEW = "NEW",
	DELETE = "DELETE",
	NONE = "NONE"
}