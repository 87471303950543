import {useTranslation} from "react-i18next";
import React from "react";
import {SymbolGroup} from "../../../models/SymbolGroup";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {defaultSymbolGroups, getDefaultGroupPacks} from "../../project-drawings/features/editor/icons/symbol_pack/defaultPacks";
import {useQuery} from "react-query";
import {toStatus} from "../../../utils/ReactQueryUtils";
import {TIME_5_MIN_IN_MILLISECONDS} from "../../project-drawings/features/editor/constants";
import {SYMBOL_QUERY_KEY} from "../../../api/SymbolsApi";

function useSymbolGroups(enabledSystemPackIds?: string[], withDefault?: boolean) {
	const {t} = useTranslation();

	const {data, status} = useQuery({
		queryKey: [SYMBOL_QUERY_KEY, {url: apiInstance.symbolsApi.fetchSymbolGroups().url}],
		queryFn: () => apiInstance.symbolsApi.fetchSymbolGroups().promise(),
		onError: (err: any) => {
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), t("settings.tabs.symbols.fetchGroupsError"));
		},
		staleTime: TIME_5_MIN_IN_MILLISECONDS
	})

	const loadStatus = toStatus(status)

	const symbolGroups: SymbolGroup[] = React.useMemo(() => {
		if (data) {
			if (withDefault) {
				const defaultGroups = defaultSymbolGroups.filter(group => {
					const packs = getDefaultGroupPacks(group.id);
					return (enabledSystemPackIds ?
						packs.filter(pack => enabledSystemPackIds.some(id => id === pack.id)) :
						packs)
						.length > 0;
				});
				return [...data, ...defaultGroups];
			}
			else {
				return data
			}
		}
		return []
	}, [data, withDefault, enabledSystemPackIds])

	return {symbolGroups, loadStatus}
}

export default useSymbolGroups;
