import {Status} from "../../../../../../../../models/enums";
import {useEffect, useState} from "react";
import {apiInstance} from "../../../../../../../../api/api";
import LoggerService from "../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import {CatalogItemGroup} from "../../../../../../../../models/CatalogItemGroup";
import {UseCatalogGroupsHook} from "./useCatalogGroups";

function usePreBuildGroups(parentGroupId?: number | null): UseCatalogGroupsHook {

	const [groups, setGroups] = useState<CatalogItemGroup[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	useEffect(() => {
		setLoadStatus(Status.LOADING);
		apiInstance.preBuildsApi.fetchPreBuildGroups(parentGroupId)
			.then(groups => {
				setGroups(groups);
				setLoadStatus(Status.SUCCESS);
			})
			.catch((err) => {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("projects.addItemModal.preBuilds.loadGroupsErrorDesc"));
			})
	}, [parentGroupId, t]);
	return {loadStatus, groups};
}

export default usePreBuildGroups;
