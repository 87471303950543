import {Vector2d} from "konva/types/types";
import {RootState} from "../../editorStore";
import {AreaHelper} from "../area/AreaHelper";
import {Line} from "../../models/editor";
import {Store} from "@reduxjs/toolkit";

export type RectPoints = {
	topLeft: Vector2d,
	topRight: Vector2d,
	bottomRight: Vector2d,
	bottomLeft: Vector2d
}

type SelectionResult = {
	from: Vector2d,
	to: Vector2d,
	selectedItems: SelectedItems,
};

type FragmentsSelection = {
	measurementId: string,
	fragmentId?: string,
	subItemIds: string[]
}

type SelectedItems = {
	area: FragmentsSelection[],
	count: FragmentsSelection[],
	length: FragmentsSelection[],
	pen: FragmentsSelection[],
	text: FragmentsSelection[],
	image: FragmentsSelection[]
}

export class GroupMeasurementSelectionHelper {
	static getItemsWithinBoundary(
		store: Store<RootState>,
		point1: Vector2d,
		point2: Vector2d,
		textToolRectPointsMap: Map<string, RectPoints>,
		imageToolRectPointsMap: Map<string, RectPoints>
	): SelectionResult {
		const from = {x: Math.min(point1.x, point2.x), y: Math.min(point1.y, point2.y)};
		const to = {x: Math.max(point1.x, point2.x), y: Math.max(point1.y, point2.y)};
		let left: number | null = null;
		let top: number | null = null;
		let right: number | null = null;
		let bottom: number | null = null;
		const setBoundary = (position: Vector2d, offset: number = 10) => {
			if (!left || left > position.x - offset)
				left = position.x - offset;
			if (!right || right < position.x + offset)
				right = position.x + offset;
			if (!top || top > position.y - offset)
				top = position.y - offset;
			if (!bottom || bottom < position.y + offset)
				bottom = position.y + offset;
		};
		const storeData = store.getState().undoGroup.present;
		const areas = storeData.area.areas.filter(item => item.visible);
		const countGroups = storeData.count.countGroups.filter(item => item.visible);
		const lengths = storeData.length.lengths.filter(item => item.visible);
		const penGroups = storeData.pen.penGroups.filter(item => item.visible);
		const textGroups = storeData.text.textGroups.filter(item => item.visible);
		const images = storeData.image.images.filter(item => item.visible);
		const selectedItems: SelectedItems = {
			area: [], count: [], length: [], pen: [], text: [], image: []
		};
		areas.forEach(area =>
			area.areaFragments.forEach(fragment => {
				const fragmentHasPointWithinSelection = fragment.lines.some((line, index) => {
					return (index === 0 && isPointWithin(from, to, line.from.position)) ||
						isPointWithin(from, to, line.to.position)
				});
				const fragmentHasLineIntersectingSelection = AreaHelper.isAreaLinesIntersecting(fragment.lines, [from, to])
				if (fragmentHasPointWithinSelection || fragmentHasLineIntersectingSelection) {
					selectedItems.area.push({measurementId: area.id, fragmentId: fragment.id, subItemIds: []});
					fragment.lines.forEach(line => {
						setBoundary(line.to.position)
					})
				}
			})
		);
		countGroups.forEach(count =>
			count.countItems.forEach(item => {
				const hasPointWithin = item.visible && isPointWithin(from, to, item.position);
				if (hasPointWithin) {
					setBoundary(item.position, count.style.size / 2);
					const alreadyInCount = selectedItems.count.find(selectedCount => selectedCount.measurementId === count.id);
					if (alreadyInCount)
						alreadyInCount.subItemIds.push(item.id);
					else
						selectedItems.count.push({measurementId: count.id, fragmentId: undefined, subItemIds: [item.id]});
				}
			})
		);
		penGroups.forEach(penGroup =>
			penGroup.penLines.forEach(item => {
				if (item.visible &&
					(hasPenPointsWithin(from, to, item.points) || hasLinesFromPenPointsIntersecting(from, to, item.points))
				) {
					if (item.points.length >= 2) {
						for (let i = 0; i < item.points.length; i = i + 2) {
							setBoundary({x: item.points[i], y: item.points[i + 1]})
						}
					}
					const alreadyInPen = selectedItems.pen.find(selectedPen => selectedPen.measurementId === penGroup.id)
					if (alreadyInPen) {
						alreadyInPen.subItemIds.push(item.id)
					}
					else {
						selectedItems.pen.push({measurementId: penGroup.id, subItemIds: [item.id]})
					}
				}
			})
		)
		textGroups.forEach(textGroup =>
			textGroup.textItems.forEach(item => {
				const textItemRectPoints = textToolRectPointsMap.get(item.id)
				if (textItemRectPoints) {
					const areaLines = rectPointsToAreaLines(textItemRectPoints)
					const isIntersecting = AreaHelper.isAreaLinesIntersecting(areaLines, [from, to])

					if (isIntersecting) {
						setBoundary(textItemRectPoints.topLeft)
						setBoundary(textItemRectPoints.topRight)
						setBoundary(textItemRectPoints.bottomRight)
						setBoundary(textItemRectPoints.bottomLeft)

						const alreadyInPen = selectedItems.text.find(selectedItem => selectedItem.measurementId === textGroup.id)
						if (alreadyInPen) {
							alreadyInPen.subItemIds.push(item.id)
						}
						else {
							selectedItems.text.push({measurementId: textGroup.id, subItemIds: [item.id]})
						}
					}
				}
			})
		)
		images.forEach(item => {
			const imageItemRectPoints = imageToolRectPointsMap.get(item.id)
			if (imageItemRectPoints) {
				const areaLines = rectPointsToAreaLines(imageItemRectPoints)
				const isIntersecting = AreaHelper.isAreaLinesIntersecting(areaLines, [from, to])

				if (isIntersecting) {
					setBoundary(imageItemRectPoints.topLeft)
					setBoundary(imageItemRectPoints.topRight)
					setBoundary(imageItemRectPoints.bottomRight)
					setBoundary(imageItemRectPoints.bottomLeft)

					const alreadyInImage = selectedItems.image.find(selectedItem => selectedItem.measurementId === item.id)
					if (!alreadyInImage) {
						selectedItems.image.push({measurementId: item.id, subItemIds: []})
					}
				}
			}
		})

		let lengthFragments: FragmentsSelection[] = [];
		lengths.forEach(length => {
			length.lengthFragments.forEach(fragment => {
				const lengthSelection: FragmentsSelection = {
					measurementId: length.id,
					fragmentId: fragment.id,
					subItemIds: []
				};
				const fragmentHasLineIntersectingSelection = AreaHelper.isLengthFragmentIntersecting(fragment, [from, to])
				if (fragmentHasLineIntersectingSelection) {
					fragment.lines.forEach(line => {
						if (AreaHelper.isLineIntersecting(line, [from, to])) {
							setBoundary(line.from.position);
							setBoundary(line.to.position);
							if (line.arcPointPosition) {
								setBoundary(line.arcPointPosition)
							}
							lengthSelection.subItemIds.push(line.id);
						}
					});
					lengthFragments.push(lengthSelection);
				}
			})
		});
		selectedItems.length = lengthFragments;

		return {
			from: {x: left ?? 0, y: top ?? 0},
			to: {x: right ?? 0, y: bottom ?? 0},
			selectedItems
		};
	}
}

function isPointWithin(from: Vector2d, to: Vector2d, point: Vector2d): boolean {
	return point.x >= from.x && point.x <= to.x && point.y >= from.y && point.y <= to.y;
}

function hasPenPointsWithin(from: Vector2d, to: Vector2d, penPoints: number[]): boolean {
	if (penPoints.length >= 2) {
		for (let i = 0; i < penPoints.length; i = i + 2) {
			const point = {x: penPoints[i], y: penPoints[i + 1]}
			if (isPointWithin(from, to, point)) {
				return true;
			}
		}
	}
	return false
}

function hasLinesFromPenPointsIntersecting(from: Vector2d, to: Vector2d, penPoints: number[]): boolean {
	if (penPoints.length >= 4) {
		for (let i = 3; i < penPoints.length; i = i + 2) {
			const line: Line = {
				from: {position: {x: penPoints[i - 3], y: penPoints[i - 2]}},
				to: {position: {x: penPoints[i - 1], y: penPoints[i]}}
			}
			if (AreaHelper.isLineIntersecting(line, [from, to])) {
				return true;
			}
		}
	}
	return false;
}

function rectPointsToAreaLines(rectPoints: RectPoints): Line[] {
	return [
		{from: {position: rectPoints.topLeft}, to: {position: rectPoints.topRight}},
		{from: {position: rectPoints.topRight}, to: {position: rectPoints.bottomRight}},
		{from: {position: rectPoints.bottomRight}, to: {position: rectPoints.bottomLeft}},
		{from: {position: rectPoints.bottomLeft}, to: {position: rectPoints.topLeft}}
	]
}
