import "./symbolRecognitionMatchesDisplayPanel.scss"
import Button from "../../../../../../components/ui/Button";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import SymbolRecognitionSensitivitySlider from "./SymbolRecognitionSensitivitySlider";
import {useDispatch, useSelector} from "react-redux";
import {selectSymbolRecognitionResults, symbolRecognitionSlice} from "./symbolRecognitionSlice";
import SymbolRecognitionPreview from "./SymbolRecognitionPreview";
import IconArrowLeft from "../../icons/IconArrowLeft";
import ManualApprovalButton from "./manualApprovalButton";
import {simpleCompare} from "../../../../../../utils/SortUtils";
import {countActions} from "../count/countSlice";
import {EditorTool, MaterialType} from "../../models/enums";
import {SymbolType} from "../../icons/symbol_pack/default";
import {configActions, selectStageConfig} from "../config/configSlice";

type SymbolRecognitionMatchesDisplayPanelProps = {
	templateData: { x: number, y: number, width: number, height: number }
	onCancelClick: () => void
}

const SymbolRecognitionMatchesDisplayPanel: React.FC<SymbolRecognitionMatchesDisplayPanelProps> = ({
	templateData,
	onCancelClick
}) => {
	const dispatch = useDispatch();
	const {
		referentialData: symbolRecognitionResultList,
		remainingIndexes: remainingResultsIndexes,
		rejectedIndexes: rejectedResultsIndexes,
		approvedIndexes: approvedResultsIndexes,
		activeIndex: displayedResultIndex,
		displayableIndexes
	} = useSelector(selectSymbolRecognitionResults)
	const stageConfig = useSelector(selectStageConfig);

	const {t} = useTranslation();

	const designateSliderInitialValue = (): number => {
		const minQuality = 0.5;
		const minQualityEdgeIndex = symbolRecognitionResultList.findIndex(result => result.matchQuality < minQuality);
		if (minQualityEdgeIndex === 0 || minQualityEdgeIndex === -1) {
			return Math.round(symbolRecognitionResultList.length / 2);
		}
		return symbolRecognitionResultList.length - minQualityEdgeIndex;
	}

	const onLeftNavigationArrowClick = () => {
		if (displayedResultIndex > 0) {
			dispatch(symbolRecognitionSlice.actions.setActiveIndex(displayedResultIndex - 1));
		}
	}

	const onRightNavigationArrowClick = () => {
		if (displayedResultIndex < displayableIndexes.length - 1) {
			dispatch(symbolRecognitionSlice.actions.setActiveIndex(displayedResultIndex + 1));
		}
	}

	const [sliderValue, setSliderValue] = useState<number>(designateSliderInitialValue)
	const sliderMaxValue = symbolRecognitionResultList.length - 1;
	const displayedResult = symbolRecognitionResultList[displayableIndexes[displayedResultIndex] ?? 0]
	const displayedResultHeight = displayedResult.rotationAngleInDegrees === 0 ? templateData.height : templateData.width;
	const displayedResultWidth = displayedResult.rotationAngleInDegrees === 0 ? templateData.width : templateData.height;

	useEffect(() => {
		const remainingIndexesArray = Array.from(Array(symbolRecognitionResultList.length - sliderValue).keys()).filter(
			index => !rejectedResultsIndexes.includes(index) && !approvedResultsIndexes.includes(index)
		)
		dispatch(symbolRecognitionSlice.actions.setRemainingIndexes(remainingIndexesArray));
		if (displayedResultIndex + 1 > displayableIndexes.length && displayableIndexes.length !== 0) {
			dispatch(symbolRecognitionSlice.actions.setActiveIndex(displayableIndexes.length - 1))
		}
	}, [approvedResultsIndexes, dispatch, rejectedResultsIndexes, sliderValue, symbolRecognitionResultList.length,
		displayableIndexes.length, displayedResultIndex])

	const onManualVerification = (isApproved: boolean) => {
		const relevantIndexes = isApproved ? approvedResultsIndexes : rejectedResultsIndexes;
		const otherIndexes = isApproved ? rejectedResultsIndexes : approvedResultsIndexes;
		const setRelevantIndexes = isApproved ? symbolRecognitionSlice.actions.setApprovedIndexes :
			symbolRecognitionSlice.actions.setRejectedIndexes
		const setOtherIndexes = isApproved ? symbolRecognitionSlice.actions.setRejectedIndexes :
			symbolRecognitionSlice.actions.setApprovedIndexes

		if (!relevantIndexes.includes(displayedResultIndex)) {
			if (otherIndexes.includes(displayedResultIndex)) {
				const newIndexes = [...otherIndexes].filter(index => index !== displayableIndexes[displayedResultIndex])
				dispatch(setOtherIndexes(newIndexes))
			}

			const newRelevantIndexes = [...relevantIndexes, displayedResultIndex].sort((a, b) => simpleCompare(a, b));
			dispatch(setRelevantIndexes(newRelevantIndexes))
			if (displayedResultIndex < displayableIndexes.length - 1 && remainingResultsIndexes.length > 0) {
				dispatch(symbolRecognitionSlice.actions.setActiveIndex(displayedResultIndex));
			}
		}
		navigateToNextRemainingIndex()
	}

	const navigateToNextRemainingIndex = () => {
		for (let i = displayedResultIndex + 1; i < displayableIndexes.length; i++) {
			const index = displayableIndexes[i];
			if ((!approvedResultsIndexes.includes(index) && !rejectedResultsIndexes.includes(index))) {
				dispatch(symbolRecognitionSlice.actions.setActiveIndex(i))
				return;
			}
		}
	}

	const onApproveAllButtonClick = () => {
		dispatch(symbolRecognitionSlice.actions.setApprovedIndexes([...approvedResultsIndexes, ...remainingResultsIndexes]))
	}

	const onSaveAndFinish = () => {
		dispatch(countActions.addCount({
			material: {name: "Symbol Recognition", type: MaterialType.ONE_OFF},
			style: {color: '#05F36BFF', size: 20, symbol: SymbolType.CIRCLE},
			height: undefined,
			defaultDrop: undefined
		}))
		approvedResultsIndexes.forEach(index => {
			const approvedResult = symbolRecognitionResultList[index]
			dispatch(countActions.addCountItem({
				position: {
					x: approvedResult.x + stageConfig.backgroundImagePosition.x,
					y: approvedResult.y + stageConfig.backgroundImagePosition.y
				}
			}))
		})
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.COUNT}));
	}

	return (
		<div className={"symbol-recognition-matches-display-panel"}>
			<div className={"symbol-recognition-matches-display-panel_approve-all-section"}>
				{remainingResultsIndexes.length + " " +
					t("editor.symbolRecognitionPanel.matchDisplayScreen.approveAllSection.foundMatchesLabel")}
				<Button onClick={onApproveAllButtonClick} variant={"primary"}
						label={t("editor.symbolRecognitionPanel.matchDisplayScreen.approveAllSection.approveAllButton")}/>
			</div>
			<div className={"symbol-recognition-matches-display-panel_advanced-settings-header"}>
				<div className={"symbol-recognition-matches-display-panel_advanced-settings-header_title"}>
					{t("editor.symbolRecognitionPanel.matchDisplayScreen.advancedSettingsHeader")}
				</div>
			</div>
			<div className={"symbol-recognition-matches-display-panel_sensitivity-adjustment"}>
				<div className={"symbol-recognition-matches-display-panel_sensitivity-adjustment_title"}>
					{t("editor.symbolRecognitionPanel.matchDisplayScreen.sensitivitySection.title")}
				</div>
				<SymbolRecognitionSensitivitySlider sliderValue={sliderValue} sliderMaxValue={sliderMaxValue}
													onSliderChange={(value) => {setSliderValue(value)}}/>
			</div>
			<div className={"symbol-recognition-matches-display-panel_manual-approval-section"}>
				<div className={"symbol-recognition-matches-display-panel_manual-approval-section_title"}>
					{t("editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.title")}
				</div>
				<div className={"symbol-recognition-matches-display-panel_manual-approval-section_statuses"}>
					<span className={"symbol-recognition-matches-display-panel_manual-approval-section_statuses_rejected-label"}>
						{t("editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.rejectedLabel") +
							`: ${rejectedResultsIndexes.length}`}
					</span>
					<span
						className={"symbol-recognition-matches-display-panel_manual-approval-section_statuses_approved-label"}>
						{t("editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.approvedLabel") +
							`: ${approvedResultsIndexes.length}`}
					</span>
					<span
						className={"symbol-recognition-matches-display-panel_manual-approval-section_statuses_remaining-label"}>
						{t("editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.remainingLabel") +
							`: ${remainingResultsIndexes.length}`}
					</span>
				</div>
				<div className={"symbol-recognition-matches-display-panel_manual-approval-section_panel"}>
					<div
						className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_previews-container"}>
						<div
							className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_previews-container_preview"}>
							<SymbolRecognitionPreview templateData={templateData}/>
						</div>
						<div
							className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_previews-container_preview"}>
							<SymbolRecognitionPreview templateData={{
								x: displayedResult.x - displayedResultWidth / 2,
								y: displayedResult.y - displayedResultHeight / 2,
								width: displayedResultWidth,
								height: displayedResultHeight
							}}/>
						</div>
					</div>
					<div
						className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_navigation"}>
						<span onClick={onLeftNavigationArrowClick}
							  className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_navigation_left-arrow"}>
							<IconArrowLeft/>
						</span>
						<span
							className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_navigation_text"}>
							{(displayedResultIndex + 1).toString() + " " +
								t("editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.of") +
								" " +
								displayableIndexes.length}
						</span>
						<span onClick={onRightNavigationArrowClick}
							  className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_navigation_right-arrow"}>
							<IconArrowLeft/>
						</span>
					</div>
					<div className={"symbol-recognition-matches-display-panel_manual-approval-section_panel_buttons"}>
						<ManualApprovalButton onClick={() => {onManualVerification(false)}}
											  isSelected={rejectedResultsIndexes.includes(displayedResultIndex)}
											  variant={"red"}
											  label={t(
												  "editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.buttons.reject")}/>
						<ManualApprovalButton onClick={() => {onManualVerification(true)}}
											  isSelected={approvedResultsIndexes.includes(displayedResultIndex)}
											  variant={"green"}
											  label={t(
												  "editor.symbolRecognitionPanel.matchDisplayScreen.manualApprovalSection.buttons.approve")}/>
					</div>
				</div>
			</div>
			<div className={"symbol-recognition-matches-display-panel_footer"}>
				<Button onClick={onCancelClick} variant={"optional"} label={t("common.buttons.cancel")}/>
				<Button disabled={approvedResultsIndexes.length === 0} onClick={onSaveAndFinish} variant={"secondary"}
						label={t("common.buttons.saveAndFinish")}/>
			</div>
		</div>
	)
}

export default SymbolRecognitionMatchesDisplayPanel;
