import {useTranslation} from "react-i18next";
import {useInternalDataContext, useMaterialModalContext} from "../../../../MaterialModalProvider";
import ModalWithHeader from "../../../../../../../../../../../components/modal/ModalWithHeader";
import {NameInput} from "../../../../components/one-off/NameInput";
import {OneOffMaterialChangeStyles} from "./OneOffMaterialChangeStyles";
import {ContentFooter} from "../../../../components/one-off/ContentFooter";
import React from "react";

export function OneOffMaterialChangeModal() {
	const {t} = useTranslation()
	const {closeModal} = useMaterialModalContext()
	const {materialChangeData} = useInternalDataContext()
	const {nameInput, stylesContainer, contentFooter, modalTitle} = materialChangeData.oneOffs
	return (
		<ModalWithHeader isOpen={true}
						 title={modalTitle ?? t("projects.oneOffModal.title.editItem")}
						 onClose={closeModal}>
			<div className="one-off-modal">
				<NameInput {...nameInput}/>
				<OneOffMaterialChangeStyles {...stylesContainer}/>
				<ContentFooter {...{...contentFooter, closeModal}}/>
			</div>
		</ModalWithHeader>
	);
}