import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import "./selectionToolBox.scss";
import Konva from "konva";
import {IRect} from "konva/types/types";
import {selectPlanUploadZoomState} from "../features/view/planUploadViewSlice";
import Button from "../../../../../components/ui/Button";
import {useTranslation} from "react-i18next";
import {SelectionState} from "../../../../base-konva/types";
import MuiPopper from "@mui/material/Popper";
import {transformKonvaRect} from "../../../../base-konva/utils";

type SelectionToolBoxProps = {
	whiteCanvasRef: React.RefObject<Konva.Rect>
	selection: SelectionState | undefined,
	onCancel: () => void,
	onConfirmSelection: () => void
}
export const SelectionToolBox: React.FC<SelectionToolBoxProps> = ({whiteCanvasRef, selection, onCancel, onConfirmSelection}) => {
	const zoomState = useSelector(selectPlanUploadZoomState)
	const [canvasRect, setCanvasRect] = useState<IRect | null>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const {t} = useTranslation();

	useEffect(() => {
		const canvas = whiteCanvasRef.current
		if (canvas && selection) {
			const currentClientRect = canvas.getClientRect()
			setCanvasRect(transformKonvaRect(zoomState.rotation, currentClientRect))
		}

	}, [whiteCanvasRef, selection, zoomState]);

	return (canvasRect && selection) ? (
		<div className="selection-tool-box">
			<div style={{
				position: "absolute",
				top: canvasRect.y,
				left: canvasRect.x,
				height: canvasRect.height,
				width: canvasRect.width,
				transform: `rotate(${zoomState.rotation}deg)`,
				transformOrigin: "top left",
				pointerEvents: "none",
			}}>
				<div
					ref={setAnchorEl}
					style={{
						position: "absolute",
						top: zoomState.scale * selection.y,
						left: zoomState.scale * selection.x,
						width: zoomState.scale * selection.width,
						height: zoomState.scale * selection.height,
					}}
				/>
				<MuiPopper
					open={Boolean(anchorEl)}
					anchorEl={anchorEl}
					placement={"right-start"}
				>
					<div className={"selection-tool-box_buttons-container"}>
						<Button
							onClick={onCancel}
							variant={"tertiary"}
							label={t("common.buttons.cancel")}
						/>
						<Button
							onClick={onConfirmSelection}
							variant={"secondary"}
							label={t("planUploadEditor.tools.confirmSelection")}
						/>
					</div>
				</MuiPopper>
			</div>
		</div>
	) : null
};
