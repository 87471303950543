import {AreaMeasurement, CountMeasurement, LengthMeasurement} from "../features/project-drawings/features/editor/models/editor";
import {AreaHelper} from "../features/project-drawings/features/editor/features/area/AreaHelper";
import LengthHelper from "../features/project-drawings/features/editor/features/length/LengthHelper";
import {MaterialType, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import {MeasurementStyle} from "./interfaces";

export class PlanSummaryItem {
	constructor(public readonly id: string,
		public readonly value: number,
		public readonly name: string,
		public readonly materialType: MaterialType,
		public readonly partNumber: string | undefined,
		public readonly laborTime: number | undefined,
		public readonly fitTimeId: number | undefined,
		public readonly markupQty: number,
		public readonly deduction: number | undefined,
		public readonly measurementType: MeasurementType,
		public readonly style: MeasurementStyle
	) {}

	public static fromAreaMeasurement(measurement: AreaMeasurement, scale: number) {
		const material = measurement.material;
		const deduction = AreaHelper.getDeductionsArea(measurement, scale) * (measurement.height ?? 1)
		const value = AreaHelper.getArea(measurement, scale) * (measurement.height ?? 1)
		return new PlanSummaryItem(measurement.measurementId!, value - (deduction ?? 0), material.name,
			material.type, material.partNumber, material.laborTime, material.fitTimeId, value, deduction,
			measurement.type, measurement.style)
	}

	public static fromCountMeasurement(measurement: CountMeasurement) {
		const material = measurement.material;
		const value = measurement.countItems.length
		return new PlanSummaryItem(measurement.measurementId!, value, material.name, material.type, material.partNumber,
			material.laborTime, material.fitTimeId, value, undefined, measurement.type, measurement.style)
	}

	public static fromLengthMeasurement(measurement: LengthMeasurement, scale: number) {
		const material = measurement.material;
		const value = LengthHelper.getTotalLength(measurement, scale)
		return new PlanSummaryItem(measurement.measurementId!, value!, material.name, material.type, material.partNumber,
			material.laborTime, material.fitTimeId, value ?? 0, undefined, measurement.type, measurement.style)
	}
}
