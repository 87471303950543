import {CountMeasurement} from "../../models/editor";
import {Vector2d} from "konva/types/types";
import {add} from "../../utils";

export class CountHelper {
	static getMovedCountGroups(countGroups: CountMeasurement[], delta: Vector2d): CountMeasurement[] {
		return countGroups.map(countGroup => ({
			...countGroup,
			countItems: countGroup.countItems.map(countItem => ({
				...countItem,
				position: add(countItem.position, delta)
			}))
		}))
	}
}