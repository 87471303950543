import React from 'react';
import "./accordion.scss";
import IconOrder from "../../../../../../../components/icons/IconOrder";
import {get} from "../../../../../../../utils/ClassNameUtils";

interface AccordionProps {
	label: string | JSX.Element;
	expand: {
		isExpanded: boolean,
		toggle: () => void
	}
	icon?: React.ReactNode | undefined;
}

export const Accordion: React.FC<AccordionProps> = ({label, expand, children, icon}) => {
	const {isExpanded, toggle} = expand

	return (
		<>
			<div className={get("accordion", {"is-expanded": isExpanded})} onClick={toggle}>
				{icon ? <div className={"accordion_icon-container"}>{icon}</div> : null}
				{label}
				<div className={"accordion_spacer"}/>
				<IconOrder customRotate={isExpanded ? 0 : -90} active={false}/>
			</div>
			{isExpanded ? <div>{children}</div> : null}
		</>
	);
};
