import React from "react";
import TreeSection from "./TreeSection";
import "./treeSections.scss";
import Section from "../../../../models/Section";
import {UseTreeExpandData} from "../../hooks/useTreeExpandData";
import {EmptyTreeRowLabel} from "./EmptyTreeRowLabel";
import {ProjectSource} from "../../../../models/enums";
import {useTranslation} from "react-i18next";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import {capitalizeString} from "../../../../utils/TextUtils";

type Props = {
	sections: Section[]
	treeExpandData: UseTreeExpandData
	onCreateNewSection: () => void
	onCreateCostCenter: (sectionId?: string) => void
	openCreateBlankDrawingModal: (costCenterId?: string, sectionId?: string) => void
}

const TreeSections: React.FC<Props> = ({
	sections,
	treeExpandData,
	onCreateNewSection,
	onCreateCostCenter,
	openCreateBlankDrawingModal
}) => {
	const {projectDetails: {project}} = useProjectDetailContext()
	const {t} = useTranslation()
	const textBeforeButton = project?.source === ProjectSource.JOB ?
		t("projects.details.tree.section.emptyJob") :
		t("projects.details.tree.section.emptyQuote")

	return (
		<div className="tree-sections">
			{sections.length === 0 ?
				(<EmptyTreeRowLabel
					textBeforeButton={textBeforeButton}
					textAfterButton={capitalizeString(t("projects.details.tree.section.label"))}
					onClick={onCreateNewSection}/>) : null}
			{sections.map(section =>
				<TreeSection
					key={section.id}
					openCreateBlankDrawingModal={openCreateBlankDrawingModal}
					section={section}
					treeExpandData={treeExpandData}
					onCreateCostCenter={onCreateCostCenter}
				/>
			)}
		</div>
	)
};

export default TreeSections;
