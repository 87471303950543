import React, {useState} from "react";
import {useDirectProjectAccessDataContext} from "../../../providers/DirectProjectAccessDataProvider";
import ProjectDetails from "../../../models/ProjectDetails";
import Section from "../../../models/Section";
import {UseTreeExpandData} from "../../project-details/hooks/useTreeExpandData";
import {ProjectType} from "../../../models/enums";
import DrawingDetails from "../../../models/DrawingDetails";


type BreadcrumbsProjectData = {
	project: ProjectDetails | undefined,
	sections: Section[],
	treeExpandDataRef: React.MutableRefObject<UseTreeExpandData>
}
type BreadcrumbsDrawingData = {
	drawingDetails: DrawingDetails | undefined
}
type BreadcrumbsContextType = {
	isProjectDirectAccess: boolean
	isServiceProject: boolean
	projectData?: BreadcrumbsProjectData
	setProjectData: (data: BreadcrumbsProjectData | undefined) => void
	drawingData?: BreadcrumbsDrawingData
	setDrawingData: (data: BreadcrumbsDrawingData | undefined) => void
}
const BreadcrumbsContext = React.createContext<BreadcrumbsContextType | undefined>(undefined)
const BreadcrumbsProvider: React.FC = function({children}) {
	const {isProjectDirectAccess} = useDirectProjectAccessDataContext();
	const [projectData, setProjectData] = useState<BreadcrumbsProjectData | undefined>(undefined);
	const [drawingData, setDrawingData] = useState<BreadcrumbsDrawingData | undefined>(undefined);
	const isServiceProject = projectData?.project?.type === ProjectType.SERVICE

	return (
		<BreadcrumbsContext.Provider value={{
			isProjectDirectAccess,
			isServiceProject,
			projectData,
			setProjectData,
			drawingData,
			setDrawingData,
		}}>
			{children}
		</BreadcrumbsContext.Provider>
	)
}

function useBreadcrumbsContext() {
	const context = React.useContext(BreadcrumbsContext)
	if (context === undefined) {
		throw new Error("useBreadcrumbsContext must be used within a BreadcrumbsProvider")
	}
	return context;
}

export {BreadcrumbsProvider, useBreadcrumbsContext}