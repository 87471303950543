import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import NotificationService from "../../../../services/NotificationService";
import {apiInstance} from "../../../../api/api";
import LoggerService from "../../../../services/LoggerService";
import ConfirmationModal from "../../../../components/ui/ConfirmationModal";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";

interface DeleteSectionModalProps {
	onClose: () => void
	deletedSectionId: string
}

const DeleteSectionModal: React.FC<DeleteSectionModalProps> = ({
	onClose, deletedSectionId
}) => {
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const {refreshProjectTreeAfterSimproUpdate} = useProjectDetailContext()

	const onDelete = () => {
		setIsLoading(true)
		apiInstance.projectsApi.deleteSection(deletedSectionId)
			.then(() => {
				refreshProjectTreeAfterSimproUpdate()
				NotificationService.successNotification(t("common.success"),
					t(`projects.details.deleteSectionModal.deleteSectionSuccessDesc`))
				onClose()
			})
			.catch((error) => {
				LoggerService.logError(error)
				if (error?.response?.data?.exception?.includes("SectionNotDeletedInExternalSystemException")) {
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.deleteSectionModal.onNotDeletedInExternalSystemError"))
				}
				else {
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.deleteSectionModal.onDeleteErrorDesc"))
				}
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<ConfirmationModal isLoading={isLoading} title={t("projects.details.deleteSectionModal.title")} isOpen={true}
						   text={t("projects.details.deleteSectionModal.message")} onConfirm={onDelete}
						   onClose={onClose}/>
	);
};

export default DeleteSectionModal
