import React from "react";
import "./settingsSymbols.scss";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../components/layout/table/TableHeaderCell";
import {CheckListItem, TableColumnConfig, WithActionsListItem} from "../../../models/interfaces";
import TableNoRecords from "../../../components/layout/table/TableNoRecords";
import {SymbolGroup} from "../../../models/SymbolGroup";
import SymbolGroupsTableRow from "./SymbolGroupsTableRow";

interface SymbolGroupsTableProps {
	symbolGroups: SymbolGroup[];
	reloadSymbolGroups: () => void;
}

const SymbolGroupsTable: React.FC<SymbolGroupsTableProps> = ({symbolGroups, reloadSymbolGroups}) => {

	const {t} = useTranslation();

	const columns: TableColumnConfig<WithActionsListItem<SymbolGroup>>[] = [
		{
			id: "name",
			label: t("settings.tabs.symbols.groups.nameColumn"),
			sortable: false
		},
		{
			id: "actions",
			label: "",
			sortable: false,
			width: 42
		}
	];

	return (
		<table className={"table"}>
			<thead>
			<tr>
				{columns.map(
					col => <TableHeaderCell<WithActionsListItem<CheckListItem<SymbolGroup>>> key={col.id}
																							 id={col.id}
																							 sortable={col.sortable}
																							 label={col.label}
																							 width={col.width}/>)}
			</tr>
			</thead>
			<tbody>
			{symbolGroups.length ?
				symbolGroups.map(symbolGroup => <SymbolGroupsTableRow key={symbolGroup.id}
																	  symbolGroup={symbolGroup}
																	  reloadSymbolGroups={reloadSymbolGroups}/>)
				: <TableNoRecords cols={2}/>}
			</tbody>
		</table>
	);
};

export default SymbolGroupsTable;
