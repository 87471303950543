import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {OnSuccess} from "../../../utils/CallbackUtils";
import {LockError} from "../../project-drawings/features/editor/models/enums";

interface UseVersionMoveHook {
	saveStatus: Status,
	moveDrawingVersion: (newCostCenterId: string) => void,
}

function useDrawingVersionMove(
	drawingId: string,
	drawingType: DrawingType,
	onSuccess: OnSuccess
): UseVersionMoveHook {

	const {t} = useTranslation();

	const [saveStatus, setSaveStatus] = useState(Status.IDLE)

	const translations = {
		"moveSuccess": {
			[DrawingType.TAKE_OFF]: t(`projects.details.moveDrawingModal.moveDrawingSuccessDesc.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.moveDrawingModal.moveDrawingSuccessDesc.as_built`)
		},
		"moveErrors": {
			[LockError.LOCK]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.moveDrawingModal.moveDrawingErrDescLock.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.moveDrawingModal.moveDrawingErrDescLock.as_built`)
			},
			[LockError.DEFAULT]: {
				[DrawingType.TAKE_OFF]: t(`projects.details.moveDrawingModal.moveDrawingErrDesc.take_off`),
				[DrawingType.AS_BUILT]: t(`projects.details.moveDrawingModal.moveDrawingErrDesc.as_built`)
			}
		}
	}

	function moveDrawingVersion(newCostCenterId: string) {
		setSaveStatus(Status.LOADING)
		apiInstance.drawingsApi.moveDrawingVersion(drawingId, newCostCenterId)
			.then(() => {
				setSaveStatus(Status.SUCCESS)
				NotificationService.successNotification(t("common.success"),
					translations["moveSuccess"][drawingType])
				onSuccess();
			})
			.catch(err => {
				setSaveStatus(Status.ERROR)
				let errorKey;
				if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
					errorKey = LockError.LOCK
				}
				else {
					errorKey = LockError.DEFAULT
				}
				NotificationService.errorNotification(t("common.error"),
					translations["moveErrors"][errorKey][drawingType]);
				LoggerService.logError(err);
			})
	}

	return {saveStatus, moveDrawingVersion}
}

export default useDrawingVersionMove;