import moment from "moment";

export function compareMomentDates(a: moment.Moment, b: moment.Moment): number {
	if (a.isSame(b)) {
		return 0
	}
	else {
		return a.isBefore(b) ? -1 : 1
	}
}


export function simpleCompare(a: any, b: any): number {
	return a === b ? 0 : a < b ? -1 : 1;
}