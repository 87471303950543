import React from "react";

interface IconFillProps {
	fill: string,
}

const IconImageFilled: React.FC<IconFillProps> = ({fill}) => {

	return (
		<svg width={21} height={21} viewBox="0 0 32 32">
			<path fill={fill}
				  d="M29.996 4c0.001 0.001 0.003 0.002 0.004 0.004v23.993c-0.001 0.001-0.002 0.003-0.004 0.004h-27.993c-0.001-0.001-0.003-0.002-0.004-0.004v-23.993c0.001-0.001 0.002-0.003 0.004-0.004h27.993zM30 2h-28c-1.1 0-2 0.9-2 2v24c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-24c0-1.1-0.9-2-2-2v0z"/>
			<path fill={fill} d="M26 9c0 1.657-1.343 3-3 3s-3-1.343-3-3 1.343-3 3-3 3 1.343 3 3z"/>
			<path fill={fill} d="M28 26h-24v-4l7-12 8 10h2l7-6z"/>
		</svg>
	);
};

export default IconImageFilled

