import React from "react";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import "./createCostCenterInputField.scss";
import {CreateCostCenterField} from "../CreateCostCenterField";
import {useTranslation} from "react-i18next";
import {TextButton} from "../../../../../components/ui/TextButton";

interface CostCenterInputFieldProps {
	isCostCenterFieldInCreateMode: boolean
	onCostCenterNameChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
	handleCompanyCostCenterChange: (companyCostCenterId: number) => void
	toggleCreateCostCenter: () => void
	companyCostCenterId: number | undefined
	costCenterName: string
	costCentersToSelect: { label: string, value: string }[]
	handleCostCenterChange: (costCenterId: string) => void
	costCenterId: string | undefined,
	isSectionFieldInCreateMode: boolean

}


const CreateCostCenterInputField: React.FC<CostCenterInputFieldProps> = ({
	isCostCenterFieldInCreateMode,
	onCostCenterNameChange,
	handleCompanyCostCenterChange,
	toggleCreateCostCenter,
	companyCostCenterId,
	costCenterName,
	costCenterId,
	handleCostCenterChange,
	costCentersToSelect,
	isSectionFieldInCreateMode
}) => {
	const {t} = useTranslation();

	return (
		<div className={"cost-center-input-field"}>
			{isCostCenterFieldInCreateMode ?
				<CreateCostCenterField
					onCostCenterNameChange={onCostCenterNameChange}
					handleCompanyCostCenterChange={handleCompanyCostCenterChange}
					companyCostCenterId={companyCostCenterId}
					costCenterName={costCenterName}/> :
				<>
					<div className="cost-center-input-field_label">
						{t("projects.details.createCostCenterInputField.costCenterLabel")}
					</div>
					<CustomSelect
						placeholder={t("projects.details.createCostCenterInputField.costCenterPlaceholder")}
						customClass="cost-center-input-field_select"
						items={costCentersToSelect}
						onChange={handleCostCenterChange}
						value={costCenterId}/>
				</>
			}
			{!isSectionFieldInCreateMode && <div className={"cost-center-input-field_create-new-label-container"}>
				<TextButton onClick={toggleCreateCostCenter} fontSize={10}>
					{isCostCenterFieldInCreateMode ?
						t("projects.details.createCostCenterInputField.chooseExistingCostCenterLabel") :
						t("projects.details.createCostCenterInputField.createNewCostCenterLabel")
					}
				</TextButton>
			</div>}
		</div>
	);
};

export default CreateCostCenterInputField;
