import React from "react";

type Props = {
	fill?: string
}
const IconFolder: React.FC<Props> = ({fill = "#1976D2"}) => {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path
				d="M10,4 L4,4 C2.9,4 2.01,4.9 2.01,6 L2,18 C2,19.1 2.9,20 4,20 L20,20 C21.1,20 22,19.1 22,18 L22,8 C22,6.9 21.1,6 20,6 L12,6 L10,4 Z"
				fill={fill}/>
		</svg>
	);
};

export default IconFolder
