import {EditorTool, MaterialType, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import {TakeoffTemplateItemApiDto, TakeoffTemplateItemUpdateApiDto} from "./restModels";
import LoggerService from "../services/LoggerService";
import {MaterialAddableTool, MaterialMeasurementItemStyle} from "./interfaces";
import {toMaterialAddableTool} from "../utils/PinnedUtils";

export default class TakeoffTemplateItem {
	constructor(
		public readonly defaultDrop: number | undefined,
		public readonly height: number | undefined,
		public readonly idExternal: number,
		public readonly materialId: number | undefined,
		public readonly materialType: MaterialType,
		public readonly partNumber: string | undefined,
		public readonly laborTime: number | undefined,
		public readonly measurementType: MaterialAddableTool | undefined,
		public readonly name: string,
		public readonly style: MaterialMeasurementItemStyle | undefined,
		public readonly isVolumeHeightError: boolean | undefined
	) {}

	public withNewTypeAndStyle(
		measurementType: MaterialAddableTool | undefined,
		style: MaterialMeasurementItemStyle | undefined,
		isVolumeHeightError?: boolean
	) {
		return new TakeoffTemplateItem(
			this.defaultDrop,
			this.height,
			this.idExternal,
			this.materialId,
			this.materialType,
			this.partNumber,
			this.laborTime,
			measurementType,
			this.name,
			style,
			isVolumeHeightError ?? this.isVolumeHeightError
		)
	}

	public withNewHeight(height: number | undefined, isVolumeHeightError?: boolean) {
		return new TakeoffTemplateItem(
			this.defaultDrop,
			height,
			this.idExternal,
			this.materialId,
			this.materialType,
			this.partNumber,
			this.laborTime,
			this.measurementType,
			this.name,
			this.style,
			isVolumeHeightError
		)
	}

	public withNewDefaultDrop(defaultDrop: number | undefined) {
		return new TakeoffTemplateItem(
			defaultDrop,
			this.height,
			this.idExternal,
			this.materialId,
			this.materialType,
			this.partNumber,
			this.laborTime,
			this.measurementType,
			this.name,
			this.style,
			this.isVolumeHeightError
		)
	}

	public get id() {
		return `${this.idExternal}_${this.materialType}`
	}

	public toUpdateJson(): TakeoffTemplateItemUpdateApiDto {
		if (this.measurementType === undefined) {
			throw new Error("Measurement type is required in update operation")
		}
		if (this.style === undefined) {
			throw new Error("Style is required in update operation")
		}

		return {
			defaultDrop: this.measurementType === EditorTool.LENGTH ? this.defaultDrop : undefined,
			height: this.measurementType === EditorTool.VOLUME ? this.height : undefined,
			idExternal: this.idExternal,
			materialId: this.materialId,
			materialType: this.materialType,
			partNumber: this.partNumber,
			measurementType: this.measurementType,
			style: encodeURIComponent(JSON.stringify(this.style))
		}
	}

	public static fromJson(json: TakeoffTemplateItemApiDto): TakeoffTemplateItem {
		const materialType = MaterialType[json.materialType];
		if (materialType === undefined) {
			const error = new Error(`Unknown material type: ${json.materialType}`);
			LoggerService.logError(error);
			throw error;
		}

		let measurementType = undefined;
		if (json.measurementType) {
			measurementType = MeasurementType[json.measurementType];
			if (measurementType === undefined) {
				const error = new Error(`Unknown measurement type: ${json.measurementType}`);
				LoggerService.logError(error);
				throw error;
			}
		}

		const style = json.style
			? JSON.parse(decodeURIComponent(json.style)) as MaterialMeasurementItemStyle
			: undefined

		if (style && !measurementType) {
			const error = new Error(`Missing measurement type but measurement style is present`);
			LoggerService.logError(error);
			throw error;
		}

		return new TakeoffTemplateItem(
			json.defaultDrop ?? undefined,
			json.height ?? undefined,
			json.idExternal,
			json.materialId ?? undefined,
			materialType,
			json.partNumber ?? undefined,
			json.laborTime ?? undefined,
			measurementType ? toMaterialAddableTool(measurementType) : undefined,
			json.name,
			style ?? undefined,
			undefined
		)
	}
}