import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlanUploadRootState} from "../../planUploadStore";
import {EraseAreaState, SelectionState} from "../../../../../base-konva/types";

const initialState: EraseAreaState = {erasedAreas: []}

export const planUploadEraseAreaSlice = createSlice({
	name: 'erase-area',
	initialState,
	reducers: {
		reset: (_, {payload}: PayloadAction<{ erasedAreas?: SelectionState[] }>) => ({
			...initialState,
			erasedAreas: payload.erasedAreas ?? initialState.erasedAreas
		}),
		setSelection: (state, {payload}: PayloadAction<SelectionState | undefined>) => {
			state.selection = payload
		},
		confirmEraseArea: (state) => {
			if (state.selection !== undefined) {
				state.erasedAreas.push(state.selection)
				state.selection = undefined;
			}
		}
	}
})


export const planUploadEraseAreaReducer = planUploadEraseAreaSlice.reducer;
export const planUploadEraseAreaActions = planUploadEraseAreaSlice.actions;

/**
 * Selection is always relative to plan image not to stage dimensions,
 * if stage is resized add vector from stageConfig.backgroundImagePosition
 */
export const selectPlanUploadEraseAreaSelectionState = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.eraseArea.selection;
export const selectPlanUploadErasedAreas = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.eraseArea.erasedAreas;