import {useTranslation} from "react-i18next";
import {useMaterialModalContext} from "../../../../MaterialModalProvider";
import {Status} from "../../../../../../../../../../../models/enums";
import Spinner from "../../../../../../../../../../../components/Spinner";
import {Search} from "../../../../components/Search";
import TableOverlaySpinner from "../../../../../../../../../../../components/ui/TableOverlaySpinner";
import {TemplateBreadcrumbs} from "../../../../components/template/TemplateBreadcrumbs";
import ModalButtons from "../../../../../../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../../../../../../components/ui/Button";
import React from "react";
import {useAddItemsTemplatesData} from "../../hooks/useAddItemsTemplatesData";
import {useTemplatesData} from "../../../../hooks/useTemplatesData";
import {ItemsTable} from "../../../../components/ItemsTable";

type TemplateItemPresenterProps = {
	itemPresenterData: ReturnType<typeof useAddItemsTemplatesData>["itemPresenterData"],
	breadcrumbs: ReturnType<typeof useTemplatesData>["breadcrumbs"]
}

export function TemplateItemPresenter(props: TemplateItemPresenterProps) {
	const {t} = useTranslation()
	const {closeModal} = useMaterialModalContext()
	const {itemPresenterData, breadcrumbs} = props

	return (
		<>
			{itemPresenterData.loadStatus === Status.LOADING ? <Spinner/> : null}
			{itemPresenterData.loadStatus === Status.SUCCESS ?
				<>
					<div className="material-modal_container_content">
						<Search {...itemPresenterData.search} />
						<TableOverlaySpinner {...itemPresenterData.overlay}>
							<TemplateBreadcrumbs {...breadcrumbs}/>
							<ItemsTable {...itemPresenterData.itemsTable}/>
						</TableOverlaySpinner>
					</div>
					<div className="material-modal_container_footer">
						<div/>
						<ModalButtons>
							<Button label={t("projects.addItemModal.saveItems")}
									variant={"primary"}
									disabled={!itemPresenterData.contentFooter.canSaveTakeoffTemplateItems}
									onClick={itemPresenterData.contentFooter.saveTakeoffTemplateItems}/>
							<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={() => {
								closeModal();
								itemPresenterData.clearErrors();
							}}/>
							<Button label={t("projects.addItemModal.addItems")}
									variant={"primary"}
									disabled={!itemPresenterData.contentFooter.canAddSelectedItems}
									onClick={() => {
										const succeed = itemPresenterData.contentFooter.addSelectedItems()
										if (succeed)
											closeModal()
									}}/>
						</ModalButtons>
					</div>
				</> : null
			}
		</>
	)
}