import React from "react";
import Section from "../../../../models/Section";
import "./treeSection.scss";
import TreeCostCenter from "./TreeCostCenter";
import {UseTreeExpandData} from "../../hooks/useTreeExpandData";
import {TreeRow} from "./TreeRow";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import TreeSectionHeader from "./TreeSectionHeader";
import {EmptyTreeRowLabel} from "./EmptyTreeRowLabel";
import {useTranslation} from "react-i18next";
import {capitalizeString} from "../../../../utils/TextUtils";

interface ITreeSectionProps {
	section: Section
	treeExpandData: UseTreeExpandData
	onCreateCostCenter: (sectionId?: string) => void
	openCreateBlankDrawingModal: (costCenterId?: string, sectionId?: string) => void
}

const TreeSection: React.FC<ITreeSectionProps> = ({section, treeExpandData, onCreateCostCenter, openCreateBlankDrawingModal}) => {
	const {t} = useTranslation()
	const {isServiceProject, treeData} = useProjectDetailContext()
	const {isSectionSelected, selectSection} = treeData.selectionData
	const open = treeExpandData.isExpanded(section.id)

	const handleIsCheckedChange = () => {
		selectSection(section.id)
	}

	const toggleSection = () => {
		treeExpandData.toggleSection(section.id)
	};

	const onCreateCostCenterClick = () => {
		onCreateCostCenter(section.id)
	}

	function renderCostCenters() {
		if (section.costCenters.length === 0) {
			return <EmptyTreeRowLabel
				onClick={onCreateCostCenterClick}
				textBeforeButton={t("projects.details.tree.costCenter.empty")}
				textAfterButton={capitalizeString(t("projects.details.tree.costCenter.label"))}
			/>
		}

		return section.costCenters.map(cc =>
			<TreeCostCenter
				openCreateBlankDrawingModal={openCreateBlankDrawingModal}
				key={cc.id}
				costCenter={cc}
				section={section}
				treeExpandData={treeExpandData}
			/>
		)
	}

	return (
		<>
			{!isServiceProject ?
				<TreeRow
					header={
						<TreeSectionHeader
							sectionId={section.id}
							isDeleted={section.deletedExternal}
							label={section.getLabel()}
							isChecked={isSectionSelected(section.id)}
							onChange={handleIsCheckedChange}
						/>
					}
					toggleExpand={toggleSection}
					isOpen={open}>
					{renderCostCenters()}
				</TreeRow>
				: renderCostCenters()}
		</>
	);
};


export default TreeSection
