import React, {useState} from "react";
import {selectZoomState, viewActions} from "../view/viewSlice";
import {useDispatch, useSelector} from "react-redux";
import {lengthActions, selectLengths} from "../length/lengthSlice";
import {ResizeAnchor, ResizeStageDimensions} from "../../models/editor";
import {countActions, selectCountGroups} from "../count/countSlice";
import {areaActions, selectAreas} from "../area/areaSlice";
import {selectTextGroups, textActions} from "../text/textSlice";
import {penActions, selectPenGroups} from "../pen/penSlice";
import {imageActions, selectImages} from "../image/imageSlice";
import {configActions, selectStageConfig} from "../config/configSlice";
import {add, getDelta} from "../../utils";
import {ResizeStageModal} from "./ResizeStageModal";
import LengthHelper from "../length/LengthHelper";
import {CountHelper} from "../count/CountHelper";
import {AreaHelper} from "../area/AreaHelper";
import {TextHelper} from "../text/TextHelper";
import {PenHelper} from "../pen/PenHelper";
import {ImageHelper} from "../image/ImageHelper";
import {ScaleHelper} from "../scale/ScaleHelper";
import IconResize from "../../icons/unselectableEditorHeaderIcons/IconResize";
import IconWithTooltip from "../../../../../../components/ui/IconWithTooltip";
import {useTranslation} from "react-i18next";
import {getId} from "../../../../../../utils";
import {scaleActions, selectScaleConfig} from "../scale/scaleSlice";

type ResizeButtonProps = {
	disabled?: boolean,
}
export const ResizeButton: React.FC<ResizeButtonProps> = ({disabled}) => {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const dispatch = useDispatch();
	const stageConfig = useSelector(selectStageConfig);
	const zoomState = useSelector(selectZoomState);

	const lengths = useSelector(selectLengths);
	const countGroups = useSelector(selectCountGroups);
	const areas = useSelector(selectAreas);
	const textGroups = useSelector(selectTextGroups);
	const penGroups = useSelector(selectPenGroups);
	const images = useSelector(selectImages);
	const scaleConfig = useSelector(selectScaleConfig);

	const {t} = useTranslation();

	function handleResize(anchor: ResizeAnchor, resizeStageDimensions: ResizeStageDimensions) {
		const deltaVector = getDelta(anchor, resizeStageDimensions)
		const actionId = getId();
		dispatch(lengthActions.setLengths({value: LengthHelper.getMovedLengths(lengths, deltaVector), actionId}));
		dispatch(countActions.setCounts({value: CountHelper.getMovedCountGroups(countGroups, deltaVector), actionId}));
		dispatch(areaActions.setAreas({value: AreaHelper.getMovedAreas(areas, deltaVector), actionId}));
		dispatch(textActions.setTextGroups({value: TextHelper.getMovedTextGroups(textGroups, deltaVector), actionId}));
		dispatch(penActions.setPenGroups({value: PenHelper.getMovedPenGroups(penGroups, deltaVector), actionId}));
		dispatch(imageActions.setImages({value: ImageHelper.getMovedImages(images, deltaVector), actionId}));
		dispatch(scaleActions.setScaleConfig({value: ScaleHelper.getMovedScale(scaleConfig, deltaVector), actionId}));

		dispatch(configActions.setStageConfig({
			value: {
				height: resizeStageDimensions.newHeight,
				width: resizeStageDimensions.newWidth,
				backgroundImagePosition: add(stageConfig.backgroundImagePosition, deltaVector)
			}, actionId
		}))
		dispatch(viewActions.setDimensions({
			viewportWidth: zoomState.viewportSize.width,
			viewportHeight: zoomState.viewportSize.height,
			stageWidth: resizeStageDimensions.newWidth,
			stageHeight: resizeStageDimensions.newHeight,
		}));

		setIsModalOpen(false)
	}

	const openModal = () => !disabled && setIsModalOpen(true)

	return (
		<>
			{isModalOpen ? (
				<ResizeStageModal
					isOpen={true}
					onClose={() => setIsModalOpen(false)}
					onResize={handleResize}
				/>
			) : null}
			<IconWithTooltip
				tooltipText={t("editor.tooltip.resize")}
				icon={(
					<IconResize
						disabled={disabled}
						onClick={openModal}
					/>
				)}
			/>
		</>
	)
}
