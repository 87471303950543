import React, {useEffect, useState} from "react";
import "./planUploadScaleDialog.scss"
import IconWarning from "../../../../project-drawings/features/editor/icons/IconWarning";
import {useTranslation} from "react-i18next";
import {Colors} from "../../../../../styles";
import TextInput from "../../../../project-drawings/features/editor/components/TextInput";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import Button from "../../../../../components/ui/Button";
import {IconDrawScale} from "../../../../project-drawings/features/editor/icons/IconDrawScale";
import {pageSizeMap} from "../../../../project-drawings/features/editor/constants";
import {useDispatch, useSelector} from "react-redux";
import {planUploadViewActions, selectPlanUploadScaleOptions} from "../features/view/planUploadViewSlice";
import {
	planUploadConfigActions,
	selectPlanUploadActiveTool,
	selectPlanUploadStageConfig
} from "../features/config/planUploadConfigSlice";
import {PlanUploadEditorTool} from "../types";
import LengthInput from "../../../../../components/ui/LengthInput";
import {useUserSettingsDataContext} from "../../../../../providers/UserSettingsProvider";
import {convertToMeters, convertToUnit} from "../../../../../utils/ConversionUtils";
import {UnitsType} from "../../../../../models/enums";
import {maxFourDecimalsNumberRegexp} from "../../../../../utils/TextUtils";
import {PageSizeFormat} from "../../../../base-konva/enums";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {PageScaleRatio} from "../../../../../models/DrawingDetails";
import {getScaleInfoState} from "../../../../project-drawings/features/editor/features/scaleInfo/scaleInfoUtils";
import {
	planUploadScaleActions,
	selectPlanUploadInputLength,
	selectPlanUploadScaleConfig,
	selectPlanUploadScalePoints
} from "../features/scale/planUploadScaleSlice";

interface PlanUploadScaleDialogProps {
	onClose: () => void
}

export const PlanUploadScaleDialog: React.FC<PlanUploadScaleDialogProps> = ({onClose}) => {
	const {t} = useTranslation()

	const dispatch = useDispatch()
	const {settings} = useUserSettingsDataContext();
	const {planUploadData: {currentEditorPlanPage, updateEditorPlanPage}} = useProjectDetailContext()

	const {ratioInput, pageSize} = useSelector(selectPlanUploadScaleOptions)
	const inputLength = useSelector(selectPlanUploadInputLength);
	const scalePoints = useSelector(selectPlanUploadScalePoints);
	const {scale, scaleInfoState} = useSelector(selectPlanUploadScaleConfig);
	const activeTool = useSelector(selectPlanUploadActiveTool)
	const stageConfig = useSelector(selectPlanUploadStageConfig);

	const selectItems = Object.keys(pageSizeMap).map(key => ({label: key, value: key}))
	const handlePageSizeChange = (pageSize: string) => {
		dispatch(planUploadViewActions.setScaleOptions({
			pageSize: pageSize as PageSizeFormat,
			ratioInput
		}))
	};

	const [drawStep, setDrawStep] = useState<'initial' | 'draw-scale'>("initial");

	function handleDrawScaleClick() {
		setDrawStep('draw-scale')
		dispatch(planUploadConfigActions.switchEditorTool({
			planUploadEditorTool: PlanUploadEditorTool.SCALE, isUndoRedoExcluded: true
		}))
	}

	function onScaleRatioChange(evt: React.ChangeEvent<HTMLInputElement>) {
		const {value} = evt.target;
		if (value === "") {
			dispatch(planUploadViewActions.setScaleOptions({
				pageSize,
				ratioInput: undefined
			}))
		}
		else if (/^[0-9]*$/.test(value)) {
			dispatch(planUploadViewActions.setScaleOptions({
				pageSize,
				ratioInput: Number(value)
			}))
		}
	}

	const [length, setLength] = useState<number | undefined>(
		convertToUnit(inputLength || undefined, settings?.measurementSystem) || undefined
	);

	useEffect(() => {
		setLength(convertToUnit(inputLength || undefined, settings?.measurementSystem) || undefined)
	}, [inputLength, settings?.measurementSystem])

	function handleLengthChange(value: number | undefined) {
		if (value === undefined) {
			setLength(undefined);
		}
		else {
			if (settings?.measurementSystem === UnitsType.IMPERIAL || maxFourDecimalsNumberRegexp.test(value.toString())) {
				setLength(Number(value));
			}
		}
	}


	const canSaveScale = currentEditorPlanPage && (
		(ratioInput !== undefined && ratioInput > 0) ||
		(activeTool === PlanUploadEditorTool.SCALE && length !== undefined && length > 0 && scalePoints.length === 2)
	)

	function handleSaveScale() {
		if (!canSaveScale) {
			return
		}

		let scale: number;
		let pageScaleRatio: PageScaleRatio | undefined;
		if (scalePoints.length !== 0) {
			let inputLength = convertToMeters(length || undefined, settings?.measurementSystem) || null;
			if (!inputLength) {
				return;
			}
			const [p1, p2] = scalePoints;
			const distance = Math.hypot(p1.x - p2.x, p1.y - p2.y);
			scale = 1 / (distance / inputLength);
			dispatch(planUploadViewActions.setScaleOptions({
				ratioInput: undefined, pageSize: PageSizeFormat.A4
			}))
		}
		else {
			const stageWidth = Math.min(stageConfig.height, stageConfig.width)
			scale = 1 / (stageWidth / (pageSizeMap[pageSize].width * ratioInput!))
			pageScaleRatio = new PageScaleRatio(ratioInput!, pageSize)
		}

		const infoState = getScaleInfoState(scale, scaleInfoState, settings, stageConfig)

		updateEditorPlanPage({
			...currentEditorPlanPage,
			planScale: scale,
			pageScaleRatio,
		})
		dispatch(planUploadScaleActions.resetScalePoints())
		dispatch(planUploadScaleActions.setScale({value: scale}))
		dispatch(planUploadScaleActions.setScaleInfoState({value: infoState}))
		if (activeTool === PlanUploadEditorTool.SCALE) {
			dispatch(planUploadConfigActions.switchEditorToolBack({isUndoRedoExcluded: true}))
		}
		onClose();
	}

	const onRatioInputFocus = () => {
		setDrawStep("initial")
		dispatch(planUploadScaleActions.resetScalePoints())
		if (activeTool === PlanUploadEditorTool.SCALE) {
			dispatch(planUploadConfigActions.switchEditorToolBack({isUndoRedoExcluded: true}))
		}
		setLength(undefined);
	}

	const onDrawScaleInputFocus = () => {
		if (activeTool !== PlanUploadEditorTool.SCALE) {
			dispatch(planUploadConfigActions.switchEditorTool({
				planUploadEditorTool: PlanUploadEditorTool.SCALE, isUndoRedoExcluded: true
			}))
		}
	}

	const onDrawScaleReset = () => {
		dispatch(planUploadScaleActions.resetScalePoints())
	}

	const onCancel = () => {
		onClose()
		dispatch(planUploadConfigActions.switchEditorToolBack({}))
		dispatch(planUploadScaleActions.resetScalePoints())
	}

	return (
		<div className="plan-upload-scale-dialog">
			<div className="plan-upload-scale-dialog_card">
				<div className="plan-upload-scale-dialog_card_header">
					<div className="plan-upload-scale-dialog_card_header_title">
						{t("planUploadEditor.scaleDialog.title")}
					</div>
					{!scale && <div className="plan-upload-scale-dialog_card_header_warning">
						<IconWarning fill={Colors.TRANSPARENT}/>
						{t("planUploadEditor.scaleDialog.description")}
					</div>}
				</div>
				<div className="plan-upload-scale-dialog_card_content">
					<div className="plan-upload-scale-dialog_card_content_ratio">
						<div className="label">
							{t("editor.scale.setScaleModal.scaleRatioLabel")}
						</div>
						<div className="plan-upload-scale-dialog_card_content_ratio_row">
							<strong>1</strong>:
							<TextInput
								placeholder={t("editor.scale.setScaleModal.scaleInputPlaceholder")}
								value={(drawStep === "draw-scale" || !ratioInput) ? "" : ratioInput}
								onChange={onScaleRatioChange}
								onFocus={onRatioInputFocus}
							/>
							<CustomSelect
								placeholder={""}
								customClass="plan-upload-scale-dialog_card_content_ratio_row_select"
								items={selectItems}
								onChange={handlePageSizeChange}
								value={pageSize}
								popperDisablePortal={true}
							/>
						</div>
					</div>
					<div className="plan-upload-scale-dialog_card_content_drawing">
						<div className="label">
							{t("editor.scale.setScaleModal.scaleDrawLabel")}
						</div>
						<div className="plan-upload-scale-dialog_card_content_drawing_row">
							{drawStep === "initial" && (
								<Button
									variant={"secondary"}
									label={t("editor.scale.setScaleModal.drawScaleButton")}
									rightIcon={<IconDrawScale/>}
									onClick={handleDrawScaleClick}
								/>
							)}
							{drawStep === "draw-scale" && (
								<LengthInput
									onFocus={onDrawScaleInputFocus}
									unitsType={settings?.measurementSystem}
									label={""} value={length}
									onChange={handleLengthChange}
									placeholder={t("editor.scale.inputPlaceholder")}
								/>
							)}
						</div>
					</div>
				</div>
				<div className="plan-upload-scale-dialog_card_footer">
					{drawStep === "draw-scale" && <Button
						label={t("editor.scale.resetButton")}
						variant={"optional"}
						onClick={onDrawScaleReset}
					/>}
					{scale && <Button
						label={t("common.buttons.cancel")}
						variant={"optional"}
						onClick={onCancel}
					/>}
					<Button
						label={t("common.buttons.save")}
						variant={"primary"}
						disabled={!canSaveScale}
						onClick={handleSaveScale}
					/>
				</div>
			</div>
		</div>
	)
}