import {PageSizeFormat} from "../../enums";
import {PayloadAction} from "@reduxjs/toolkit";
import {
	BaseViewState,
	HighlightItemActionPayload,
	RemoteContentLoadedInfo,
	RemoveHighlightItemActionPayload,
	ScaleOptionsState,
	SelectItemActionPayload,
	ZoomState
} from "../../types";

const initialState: BaseViewState = {
	zoom: {
		scale: 1,
		savedScale: undefined,
		minScale: 0.1,
		viewportSize: {height: 0, width: 0},
		rotation: 0,
	},
	highlight: {},
	selection: [],
	remoteContentLoadedInfo: {
		allItemsLoaded: true,
		loadedInfos: []
	},
	panInProgress: false,
	editorIsDirty: false,
	scaleOptions: {
		ratioInput: undefined,
		pageSize: PageSizeFormat.A4
	},
	exportInProgress: false,
}

const reducers = {
	setZoomState: (state: BaseViewState, {payload}: PayloadAction<ZoomState>) => {
		state.zoom = payload
	},
	setEditorIsDirty: (state: BaseViewState, {payload}: PayloadAction<boolean>) => {
		state.editorIsDirty = payload
	},
	setPanInProgress: (state: BaseViewState, {payload}: PayloadAction<boolean>) => {
		state.panInProgress = payload;
	},
	setScaleOptions: (state: BaseViewState, {payload}: PayloadAction<ScaleOptionsState>) => {
		state.scaleOptions = payload
	},
	setExportInProgress: (state: BaseViewState, {payload}: PayloadAction<boolean>) => {
		state.exportInProgress = payload
	},
	selectItem: (state: BaseViewState, {payload}: PayloadAction<SelectItemActionPayload>) => {
		state.selection.push({
			itemId: payload.id!,
			fragmentId: payload.fragmentId,
			subItemIds: payload.subIds ?? [],
			type: payload.type
		});
	},
	highlightItem: (state: BaseViewState, {payload}: PayloadAction<HighlightItemActionPayload>) => {
		state.highlight.itemId = payload.id;
	},
	clearHighlight: (state: BaseViewState, _action: PayloadAction<RemoveHighlightItemActionPayload>) => {
		state.highlight.itemId = undefined;
	},
	clearSelection: (state: BaseViewState, _action: PayloadAction<RemoveHighlightItemActionPayload>) => {
		state.selection = [];
	},
	setRemoteContentLoadedInfo: (state: BaseViewState, {payload}: PayloadAction<RemoteContentLoadedInfo>) => {
		state.remoteContentLoadedInfo = payload;
	},
	setRemoteItemLoaded: (state: BaseViewState, action: PayloadAction<string>) => {
		const countItemId = action.payload
		let allItemsLoaded = true;
		for (let loadedInfo of state.remoteContentLoadedInfo.loadedInfos) {
			if (loadedInfo.id === countItemId) {
				loadedInfo.loaded = true
			}
			if (!loadedInfo.loaded) {
				allItemsLoaded = false;
			}
		}
		state.remoteContentLoadedInfo.allItemsLoaded = allItemsLoaded;
	},
}

export {initialState, reducers}