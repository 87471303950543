import {XAxisAnchor, YAxisAnchor} from "../../models/enums";
import {ResizeAnchor} from "../../models/editor";
import AnchorTL from "../../icons/selectableEditorHeaderIcons/achors/AnchorTL";
import AnchorB from "../../icons/selectableEditorHeaderIcons/achors/AnchorB";
import AnchorBL from "../../icons/selectableEditorHeaderIcons/achors/AnchorBL";
import AnchorBR from "../../icons/selectableEditorHeaderIcons/achors/AnchorBR";
import AnchorC from "../../icons/selectableEditorHeaderIcons/achors/AnchorC";
import AnchorT from "../../icons/selectableEditorHeaderIcons/achors/AnchorT";
import AnchorTR from "../../icons/selectableEditorHeaderIcons/achors/AnchorTR";
import AnchorL from "../../icons/selectableEditorHeaderIcons/achors/AnchorL";
import AnchorR from "../../icons/selectableEditorHeaderIcons/achors/AnchorR";
import {getId} from "../../../../../../utils";

export const anchors: ResizeAnchor[][] = [
	[{
		id: getId(),
		icon: AnchorTL,
		xAxisAnchor: XAxisAnchor.LEFT,
		yAxisAnchor: YAxisAnchor.TOP,
	}, {
		id: getId(),
		icon: AnchorT,
		xAxisAnchor: XAxisAnchor.CENTER,
		yAxisAnchor: YAxisAnchor.TOP,
	}, {
		id: getId(),
		icon: AnchorTR,
		xAxisAnchor: XAxisAnchor.RIGHT,
		yAxisAnchor: YAxisAnchor.TOP,
	}],
	[{
		id: getId(),
		icon: AnchorL,
		xAxisAnchor: XAxisAnchor.LEFT,
		yAxisAnchor: YAxisAnchor.CENTER,
	}, {
		id: getId(),
		icon: AnchorC,
		xAxisAnchor: XAxisAnchor.CENTER,
		yAxisAnchor: YAxisAnchor.CENTER,
	}, {
		id: getId(),
		icon: AnchorR,
		xAxisAnchor: XAxisAnchor.RIGHT,
		yAxisAnchor: YAxisAnchor.CENTER,
	}],
	[{
		id: getId(),
		icon: AnchorBL,
		xAxisAnchor: XAxisAnchor.LEFT,
		yAxisAnchor: YAxisAnchor.BOTTOM,
	}, {
		id: getId(),
		icon: AnchorB,
		xAxisAnchor: XAxisAnchor.CENTER,
		yAxisAnchor: YAxisAnchor.BOTTOM,
	}, {
		id: getId(),
		icon: AnchorBR,
		xAxisAnchor: XAxisAnchor.RIGHT,
		yAxisAnchor: YAxisAnchor.BOTTOM,
	}]
]