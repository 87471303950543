import {useTranslation} from "react-i18next";
import {CatalogItem} from "../../../../../../../../models/CatalogItem";
import {Status} from "../../../../../../../../models/enums";
import React, {useState} from "react";
import {apiInstance} from "../../../../../../../../api/api";
import LoggerService from "../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../services/NotificationService";
import {CatalogItemUpdateApiDto} from "../../../../../../../../models/restModels";
import {useQuery} from "react-query";
import {CATALOG_QUERY_KEY} from "../../../../../../../../api/CatalogsApi";
import {toStatus} from "../../../../../../../../utils/ReactQueryUtils";
import {PREBUILD_QUERY_KEY} from "../../../../../../../../api/PreBuildsApi";

export interface ICatalogsHook {
	items: CatalogItem[],
	loadStatus: Status,
	onPageChange: (page: number) => void,
	pageNumber: number,
	totalPages: number,
	saveStatus: Status,
	saveAttributes: (items: CatalogItemUpdateApiDto[]) => void
}

type QueryKey = [string, QueryKeyParams]
type QueryKeyParams = {
	pageNumber: number,
	pageSize: number,
	onlyFavorites: boolean,
	query: string | undefined,
	groupId?: number | null
}

const useCatalogMaterialItems = (query: string | undefined, onlyFavorites: boolean, groupId?: number | null) => {
	const {t} = useTranslation();
	const saveAttributesFn = React.useCallback(
		(items: CatalogItemUpdateApiDto[]) => {
			return apiInstance.catalogApi.putCatalogsAttributes(items)
		}, []
	)
	return useMaterialItems(
		CATALOG_QUERY_KEY,
		(page, size, onlyFavorites, query, groupId) => {
			return apiInstance.catalogApi.fetchCatalogs(page, size, onlyFavorites, query, groupId)
		},
		saveAttributesFn,
		t("projects.addItemModal.catalog.loadErrorDesc"),
		query,
		onlyFavorites,
		groupId
	)
}

const usePrebuildMaterialItems = (query: string | undefined, onlyFavorites: boolean, groupId?: number | null) => {
	const {t} = useTranslation();
	const saveAttributesFn = React.useCallback(
		(items: CatalogItemUpdateApiDto[]) => {
			return apiInstance.preBuildsApi.putPreBuildsAttributes(items)
		}, []
	)
	return useMaterialItems(
		PREBUILD_QUERY_KEY,
		(page, size, onlyFavorites, query, groupId) => {
			return apiInstance.preBuildsApi.fetchPreBuilds(page, size, onlyFavorites, query, groupId)
		},
		saveAttributesFn,
		t("projects.addItemModal.preBuilds.loadErrorDesc"),
		query,
		onlyFavorites,
		groupId
	)
}

const useMaterialItems = (
	queryKeyId: typeof PREBUILD_QUERY_KEY | typeof CATALOG_QUERY_KEY,
	queryFn: typeof apiInstance.preBuildsApi.fetchPreBuilds | typeof apiInstance.catalogApi.fetchCatalogs,
	saveAttributesFn: typeof apiInstance.preBuildsApi.putPreBuildsAttributes | typeof apiInstance.catalogApi.putCatalogsAttributes,
	errorMessage: string,
	query: string | undefined,
	onlyFavorites: boolean,
	groupId?: number | null
): ICatalogsHook => {
	const pageSize = 50;
	const {t} = useTranslation();
	const [pageNumber, setPageNumber] = useState(0);

	const queryKey: QueryKey = React.useMemo(() => {
		return [queryKeyId, {pageNumber, pageSize, onlyFavorites, query, groupId}]
	}, [queryKeyId, pageNumber, pageSize, onlyFavorites, query, groupId])

	const {data, status, isPreviousData} = useQuery({
		queryKey: queryKey,
		queryFn: ({queryKey}) => {
			const [, {pageNumber, pageSize, onlyFavorites, query, groupId}] = queryKey
			return queryFn(pageNumber, pageSize, onlyFavorites, query, groupId)
		},
		onError: (err) => {
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), errorMessage);
		},
		keepPreviousData: true
	})

	const items = React.useMemo(() => data?.records ?? [], [data])
	const totalPages = React.useMemo(() => data?.totalPages || 0, [data])
	const loadStatus = React.useMemo(() => {
		if (isPreviousData) {
			return Status.LOADING
		}
		return toStatus(status)
	}, [isPreviousData, status])

	const onPageChange = React.useCallback((page: number) => {
		setPageNumber(page)
	}, [])

	const [saveStatus, setSaveStatus] = useState(Status.IDLE);

	const saveAttributes = React.useCallback((items: CatalogItemUpdateApiDto[]) => {
		setSaveStatus(Status.LOADING);

		saveAttributesFn(items).then(() => {
			setSaveStatus(Status.SUCCESS)
		}).catch(err => {
			setSaveStatus(Status.ERROR)
			LoggerService.logError(err)
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.addItemModal.saveItemsErrorDesc")
			)
		})

	}, [saveAttributesFn, t])

	return {items, loadStatus, onPageChange, pageNumber, totalPages, saveStatus, saveAttributes};
};

export {useCatalogMaterialItems, usePrebuildMaterialItems};
