import {TakeoffTemplateGroupApiDto} from "./restModels";

export class TakeoffTemplateGroup {
	constructor(
		public readonly id: number,
		public readonly name: string
	) {}

	static fromJson(json: TakeoffTemplateGroupApiDto) {
		return new TakeoffTemplateGroup(
			json.id,
			json.name
		)
	}
}