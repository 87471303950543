import React, {useState} from "react";
import "./settingsSymbols.scss";
import {useTranslation} from "react-i18next";
import {SymbolGroup} from "../../../models/SymbolGroup";
import Dropdown from "../../../components/ui/Dropdown";
import DropdownItem from "../../../components/ui/DropdownItem";
import {SymbolGroupsModal} from "../../../models/enums";
import ConfirmationModal from "../../../components/ui/ConfirmationModal";
import useDeleteSymbolGroup from "../hooks/useDeleteSymbolGroup";
import RenameSymbolGroupModal from "./RenameSymbolGroupModal";
import {pages, useNavigator} from "../../navigator";

interface SymbolGroupsTableProps {
	symbolGroup: SymbolGroup;
	reloadSymbolGroups: () => void;
}

const SymbolGroupsTableRow: React.FC<SymbolGroupsTableProps> = ({symbolGroup, reloadSymbolGroups}) => {
	const [modal, setModal] = useState(SymbolGroupsModal.NONE);
	const {deleteSymbolGroup} = useDeleteSymbolGroup();
	const {t} = useTranslation();
	const {navigateTo} = useNavigator()

	const onRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, groupId: string) => {
		// this condition prevents double action on dropdown button click (onRowClick should not be called then)
		if ((event.target as HTMLElement).tagName === "TD") {
			navigateTo(pages.symbolPacksPage(groupId))
		}
	};

	const openRenameModal = () => setModal(SymbolGroupsModal.RENAME);
	const openDeleteModal = () => setModal(SymbolGroupsModal.DELETE);

	const closeModal = () => setModal(SymbolGroupsModal.NONE);

	const onSuccess = () => {
		closeModal();
		reloadSymbolGroups();
	};

	return (
		<>
			<tr onClick={(event) => onRowClick(event, symbolGroup.id)}
				className="settings-symbols_table-row with-actions">
				<td>{symbolGroup.name}</td>
				<td>
					<Dropdown>
						<DropdownItem label={t("settings.tabs.symbols.packs.dropdown.rename.itemName")}
									  disabled={symbolGroup.id === "default"}
									  onClick={openRenameModal}/>
						<DropdownItem label={t("settings.tabs.symbols.groups.dropdown.delete.itemName")}
									  disabled={symbolGroup.id === "default"}
									  onClick={openDeleteModal}/>
					</Dropdown>
				</td>
			</tr>
			<ConfirmationModal isOpen={modal === SymbolGroupsModal.DELETE}
							   text={t("settings.tabs.symbols.groups.dropdown.delete.question")}
							   onConfirm={() => deleteSymbolGroup(symbolGroup.id, onSuccess)}
							   onClose={closeModal}/>
			<RenameSymbolGroupModal isOpen={modal === SymbolGroupsModal.RENAME}
									symbolGroup={symbolGroup}
									onSuccess={onSuccess}
									onClose={closeModal}/>
		</>
	);
};

export default SymbolGroupsTableRow;
