import {useKeyboardOnEditor} from "./useKeyboardOnEditor";
import {useEffect, useState} from "react";
import {KonvaEventObject} from "konva/types/Node";
import {setStageCursor} from "../../../../base-konva/utils";
import {CursorStyle} from "../../../../base-konva/enums";

type CursorStyleState = {
	event: KonvaEventObject<Event>,
	cursor: CursorStyle,
	shiftKeyCursor?: CursorStyle,
	altKeyCursor?: CursorStyle,
}

export function useCursorStyle() {
	const [cursorStyle, setCursorStyle] = useState<CursorStyleState | null>(null);
	const {isShiftDown, isAltDown} = useKeyboardOnEditor({when: true})

	useEffect(() => {
		if (cursorStyle) {
			const cursor =
				(isShiftDown && cursorStyle.shiftKeyCursor) ||
				(isAltDown && cursorStyle.altKeyCursor) ||
				cursorStyle.cursor
			setStageCursor(cursorStyle.event, cursor)
		}
	}, [cursorStyle, isShiftDown, isAltDown]);

	function changeCursor(cursorStyleState: CursorStyleState) {
		setCursorStyle(cursorStyleState)
	}

	return {changeCursor}
}