import {PlanTemplateData} from "../../../models/PlanTemplateData";
import {PlanUploadData, PlanUploadPageData} from "../features/plan-upload/types";
import {contentTypeToFileType, fileExtensionToFileType, getFileExtension} from "../../../utils/TextUtils";
import {PlanTemplateAttachment} from "../../../models/PlanTemplateAttachment";
import {FileType} from "../../../models/enums";
import {apiInstance} from "../../../api/api";
import {wait} from "../../../utils/ApiUtils";

export const DEFAULT_PDF_RASTERIZATION_DPI = 150;

export const getPlanPagesToUpload = async (
	projectId: string,
	isPdf: boolean,
	templateId: string,
	templateAttachmentId: string,
	pageNumbers: number[],
	dpi: number): Promise<PlanUploadPageData[]> => {

	if (isPdf) {
		return await createPdfPreviews(projectId, pageNumbers, templateId, dpi)
	}
	else {
		return getSinglePageTemplatePreview(templateAttachmentId)
	}
}

const getSinglePageTemplatePreview = (attachmentId: string): PlanUploadPageData[] => {
	return [{
		attachmentId: attachmentId,
		pageNumber: 1,
		pageScaleRatio: undefined,
		planScale: undefined,
		title: `Page ${1}`,
		existsExternally: false
	}]
}

const createPdfPreviews = async (projectId: string,
	selectedPages: number[],
	templateId: string,
	dpi: number): Promise<PlanUploadPageData[]> => {
	const pdfPreviewsCreationId = await getPdfPreviewsCreationId(projectId, templateId, selectedPages, dpi)
	const isProcessFinishedBeforeTimeout = await repetitivelyAskIfPdfPreviewsFinished(pdfPreviewsCreationId)
	if (!isProcessFinishedBeforeTimeout) {
		throw new Error("Pdf previews creation timeout.")
	}
	const pdfPreviews = await apiInstance.projectsApi.getPdfPreviews(pdfPreviewsCreationId);
	return pdfPreviews.map(preview => {
		if (!preview.attachmentId || preview.error) {
			throw new Error("Could not create pdf page preview attachment id.")
		}
		return (
			{
				attachmentId: preview.attachmentId,
				pageNumber: preview.pageNumber,
				pageScaleRatio: undefined,
				planScale: undefined,
				title: `Page ${preview.pageNumber}`,
				existsExternally: false
			}
		)
	})
}

const getPdfPreviewsCreationId = async (projectId: string,
	templateId: string,
	selectedPages: number[],
	dpi: number): Promise<string> => {
	return (await apiInstance.projectsApi.startPdfPreviewsCreation(projectId, templateId,
		{pages: selectedPages, dpi})).id
}

const repetitivelyAskIfPdfPreviewsFinished = async (pdfPreviewsCreationId: string): Promise<boolean> => {
	let timeElapsed = 0;
	let isFinished = false
	const timeoutInSeconds = 300;
	const timeBetweenChecksInSeconds = 2;
	while (timeElapsed < timeoutInSeconds && !isFinished) {
		await wait(timeBetweenChecksInSeconds * 1000)
		isFinished = (await apiInstance.projectsApi.fetchPdfPreviewsCreationStatus(pdfPreviewsCreationId)).finished
		timeElapsed = timeElapsed + timeBetweenChecksInSeconds
	}
	return isFinished;
}

export const toPlanUploadData = (templates: PlanTemplateData[]): PlanUploadData[] => {
	return templates.map(template => {
		const templateItem: PlanUploadData = {
			id: template.id,
			attachment: template.attachment,
			name: template.name,
			pages: template.pages.map(page => {
				const pageItem: PlanUploadPageData = {
					pageNumber: page.pageNumber,
					attachmentId: page.attachmentId,
					planScale: page.planScale,
					pageScaleRatio: page.pageScaleRatio,
					title: page.title,
					existsExternally: true
				}
				return pageItem;
			}),
			isPdf: template.isPdf,
			fullPageCount: template.fullPageCount,
			dpi: template.dpi
		}
		return templateItem;
	})
}

export const checkIfAttachmentIsPdf = (filename: string, contentType?: string) => {
	if (contentType) {
		return contentType === "application/pdf"
	}
	else {
		return getFileExtension(filename) === FileType.PDF
	}
}

export const getAttachmentFileType = (attachment: PlanTemplateAttachment): FileType | undefined => {

	function fromContentType(): FileType | undefined {
		const contentType = attachment.contentType
		if (contentType) {
			return contentTypeToFileType(contentType)
		}
		return undefined;
	}

	return fromContentType() ?? fileExtensionToFileType(getFileExtension(attachment.filename))
}