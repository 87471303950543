import React from "react";
import "./button.scss"
import IconPlusFilled from "../../features/project-drawings/features/editor/icons/IconPlusFilled";
import {Colors} from "../../styles";
import DropdownArrow from "./DropdownArrow";

interface IProps {
	label: string;
	icon?: React.ReactNode;
	rightIcon?: React.ReactNode;
	onClick?: () => void;
	disabled?: boolean;
	variant?: "primary" | "secondary" | "tertiary" | "optional";
	type?: "button" | "submit" | "reset" | undefined;
	withPlusIcon?: boolean;
	dropdownArrow?: boolean;
}

const Button: React.FC<IProps> = (props) => {

	const {label, icon, rightIcon, onClick, disabled, variant, type, withPlusIcon, dropdownArrow} = props;

	const variantMap = {
		"primary": "btnGreen",
		"secondary": "btnBlue",
		"tertiary": "btnGray",
		"optional": "btnSec btnSec--secondaryButton",
	}

	const cls = [
		variant ? variantMap[variant] : "btnText",
		disabled && "disabled",
		dropdownArrow && "dropdown-arrow",
		withPlusIcon && "plus-icon"
	].filter(Boolean).join(" ")

	const Icon = icon ? icon : null;
	const RightIcon = rightIcon ? rightIcon : null;

	const renderDropdownArrow = () => {
		return (
			<DropdownArrow variant={variant}/>
		);
	};

	const renderPlusIcon = () => {
		return (
			<span className="btn_plus-icon_container" onClick={onClick}>
				<IconPlusFilled fill={variant === "optional" ? Colors.SIMPROBLUE : "white"}/>
			</span>
		);
	};

	return (
		<>
			<button className={cls} disabled={disabled || false} type={type || "button"} onClick={onClick}>
				{Icon} {label} {RightIcon}
				{dropdownArrow && renderDropdownArrow()}
				{withPlusIcon && renderPlusIcon()}
			</button>
		</>
	);
};

export default Button
