import React from "react";
import {Colors} from "../../../../../../styles";

export const IconArea = function() {
	const fill = Colors.SIMPROBLUE;
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path fill={fill}
				  d="M22,8.77a1,1,0,0,0,1-1V2a1,1,0,0,0-1-1H16.23a1,1,0,0,0-1,1V3.89H8.77V2a1,1,0,0,0-1-1H2A1,1,0,0,0,1,2V7.77a1,1,0,0,0,1,1H3.76v6.46H2a1,1,0,0,0-1,1V22a1,1,0,0,0,1,1H7.77a1,1,0,0,0,1-1V20.11h6.46V22a1,1,0,0,0,1,1H22a1,1,0,0,0,1-1V16.23a1,1,0,0,0-1-1H20V8.77ZM17.23,3H21V6.77H17.23ZM3,3H6.77V6.77H3ZM6.77,21H3V17.23H6.77ZM21,21H17.23V17.23H21Zm-3-5.77H16.23a1,1,0,0,0-1,1v1.88H8.77V16.23a1,1,0,0,0-1-1h-2V8.77h2a1,1,0,0,0,1-1V5.89h6.46V7.77a1,1,0,0,0,1,1H18Z"/>
		</svg>
	)
}