import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import NumberInput from "../../../../components/ui/NumberInput";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import LoggerService from "../../../../services/LoggerService";
import {PlanSummaryItem} from "../../../../models/PlanSummaryItem";
import {formatLabor} from "../../../../utils/TextUtils";
import EditableCell from "./EditableCell";

interface EditLabourInputProps {
	item: PlanSummaryItem,
	onSuccess: () => void
}

const EditLabourCell: React.FC<EditLabourInputProps> = ({item, onSuccess}) => {
	const [labour, setLabour] = useState<number | undefined>(item.laborTime);
	const [isSaving, setSaving] = useState(false);
	const {t} = useTranslation();

	const onSave = () => {
		setSaving(true);
		apiInstance.measurementsApi.putMeasurementData(item.id, item.fitTimeId, labour)
			.then(() => {
				NotificationService.successNotification(t("common.success"),
					t("projects.summary.itemsTable.editLabourModal.saveSuccessDesc"));
				onSuccess();
			})
			.catch(err => {
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("projects.summary.itemsTable.editLabourModal.saveErrorDesc"))
			})
			.finally(() => {
				setSaving(false);
			})
	};

	return (
		<EditableCell label={formatLabor(item.laborTime)} onSave={onSave} isSaving={isSaving}>
			<NumberInput value={labour}
						 min={0}
						 onChange={setLabour}/>
		</EditableCell>
	);
};

export default EditLabourCell;
