import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../../components/modal/Modal";
import ModalTitle from "../../../components/modal/ModalTitle";
import ModalButtons from "../../../components/modal/ModalButtons";
import Button from "../../../components/ui/Button";

export function UnsavedChangesPromptModal({
	disableButtons, onClose, onConfirm, onSave, middleButtonLabel, contentLine1, contentLine2
}: {
	disableButtons: boolean
	onClose: () => void
	onConfirm: () => void
	onSave: () => void
	middleButtonLabel?: string,
	contentLine1?: string,
	contentLine2?: string,
}) {
	const {t} = useTranslation();

	return (
		<Modal isOpen={true} onClose={onClose}>
			<ModalTitle>{t("editor.unsavedChangesNavigationPromptModal.title")}</ModalTitle>
			<div style={{flexGrow: 1}}>
				<div>{contentLine1 ?? t("editor.unsavedChangesNavigationPromptModal.contentLine1")}</div>
				<div>{contentLine2 ?? t("editor.unsavedChangesNavigationPromptModal.contentLine2")}</div>
			</div>
			<ModalButtons>
				<Button
					label={t("common.buttons.cancel")}
					variant={"optional"}
					onClick={onClose}
					disabled={disableButtons}
				/>
				<Button
					label={middleButtonLabel ?? t("editor.unsavedChangesNavigationPromptModal.leaveButton")}
					variant={"optional"}
					onClick={onConfirm}
					disabled={disableButtons}
				/>
				<Button
					label={t("common.buttons.save")}
					variant={"primary"}
					onClick={onSave}
					disabled={disableButtons}
				/>
			</ModalButtons>
		</Modal>
	)
}