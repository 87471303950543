import {
	BaseStageConfig,
	BaseViewState,
	GroupedActionPayload,
	ScaleConfig,
	UndoRedoExclusionPayload
} from "../../../../base-konva/types";

export enum PlanUploadEditorTool {
	MEASUREMENT_SELECT = "MEASUREMENT_SELECT",
	PEN = "PEN",
	TEXT = "TEXT",
	IMAGE = "IMAGE",
	SCALE = "SCALE",
	CLIP = "CLIP",
	ERASE_AREA = "ERASE_AREA",
	SCALE_INFO = "SCALE_INFO",
}

export type PlanUploadViewState = BaseViewState;

export type PlanUploadConfigState = {
	activeTool: PlanUploadEditorTool,
	lastActiveTool: PlanUploadEditorTool,
	stageConfig: BaseStageConfig,
}

export type PlanUploadScaleState = {
	scaleConfig: ScaleConfig,
}

export type SwitchPlanUploadEditorToolActionPayload = {
	planUploadEditorTool: PlanUploadEditorTool,
} & GroupedActionPayload & UndoRedoExclusionPayload

export type ResetPlanUploadConfigActionPayload =
	{ stageConfig: BaseStageConfig | null }
	& UndoRedoExclusionPayload

export type ResetPlanUploadScaleActionPayload =
	{ planScale: number | null, unitFactor: number | null }
	& UndoRedoExclusionPayload
