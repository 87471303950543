import {Colors} from "../../../../../styles";
import React from "react";

export function IconDrawScale() {
	const fill = Colors.WHITE;

	return (
		<svg width={16} height={16} viewBox="0 0 24 24">
			<path fill={fill}
				  d="M22.25,15,13.14,5.85a.76.76,0,0,0-.6-.29.75.75,0,0,0-.58.29L8.16,4.51a1.43,1.43,0,0,0,0-.22A3.74,3.74,0,0,0,2,1.15,3.75,3.75,0,0,0,4.17,7.74h.3a3.69,3.69,0,0,0,2.43-.9,4,4,0,0,0,.77-.92l4.1,1.45v12a.78.78,0,0,0,.77.77.8.8,0,0,0,.62-.31l2.4-2.67,2.66,6.14a1.1,1.1,0,1,0,2-.89l-2.75-6.09,4.28,0a.77.77,0,0,0,.77-.76A.76.76,0,0,0,22.25,15ZM5.93,5.71a2.23,2.23,0,0,1-1.64.53,2.18,2.18,0,0,1-1.53-.78,2.24,2.24,0,0,1,1.7-3.71h.18a2.18,2.18,0,0,1,1.53.78,2.26,2.26,0,0,1,.54,1.63A2.28,2.28,0,0,1,5.93,5.71Z"/>
		</svg>
	)
}