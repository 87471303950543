import React from "react";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import EditPlanTemplateModal from "./EditPlanTemplateModal";
import PlanTemplateRenameModal from "./PlanTemplateRenameModal";
import PlanTemplateDeleteModal from "./PlanTemplateDeleteModal";
import PlanPageRenameModal from "./PlanPageRenameModal";
import PlanPageDeleteModal from "./PlanPageDeleteModal";
import CreateDrawingModal from "../../tree/modals/CreateDrawingModal";
import FilePreviewModal from "./FilePreviewModal";
import {UploadPlanTemplateModal} from "./UploadPlanTemplateModal";
import CreatePlanTemplateModal from "./CreatePlanTemplateModal";

const PlanTemplateModals = function() {

	const {
		planTemplateModalsData: {modal, closeModal}
	} = useProjectDetailContext()

	switch (modal?.type) {
		case 'upload-template': {
			const {onNext} = modal.data
			return (
				<UploadPlanTemplateModal
					onNext={onNext}
					onClose={closeModal}
				/>
			)
		}
		case 'create-template': {
			const {previewSources, filename, attachmentData, refreshPlanTemplates, onPrevious} = modal.data
			return (
				<CreatePlanTemplateModal
					refreshPlanTemplates={refreshPlanTemplates}
					previewFileData={{previewSources, displayedFilename: filename, attachmentData}}
					onClose={closeModal}
					onPrevious={onPrevious}
				/>
			)
		}
		case 'edit-template': {
			const {template, previewSources, displayedFilename, refreshPlanTemplates} = modal.data;
			return (
				<EditPlanTemplateModal
					planTemplate={template}
					refreshPlanTemplates={refreshPlanTemplates}
					previewFileData={{previewSources, displayedFilename}}
					onClose={closeModal}
				/>
			)
		}
		case 'rename-template': {
			const {template} = modal.data
			return (
				<PlanTemplateRenameModal planTemplate={template} onClose={closeModal}/>
			)
		}
		case 'delete-template': {
			const {template, refreshPlanTemplates} = modal.data
			return (
				<PlanTemplateDeleteModal
					planTemplate={template}
					refreshPlanTemplates={refreshPlanTemplates}
					onClose={closeModal}
				/>
			)
		}
		case 'rename-page': {
			const {template, page, onPageRename} = modal.data
			return (
				<PlanPageRenameModal
					planTemplate={template}
					planPage={page}
					onClose={closeModal}
					onDone={onPageRename}
				/>
			)
		}
		case 'delete-page': {
			const {template, page} = modal.data
			return (
				<PlanPageDeleteModal
					planTemplate={template}
					planPage={page}
					onClose={closeModal}
				/>
			)
		}
		case 'create-drawing': {
			const {template, page, drawingType, onSuccess} = modal.data
			return (
				<CreateDrawingModal
					planTemplate={template}
					planPage={page}
					drawingType={drawingType}
					onSuccess={() => {
						onSuccess();
						closeModal();
					}}
					onClose={closeModal}
				/>
			)
		}
		case 'preview-file': {
			const {fileData} = modal.data
			return (
				<FilePreviewModal
					fileData={fileData}
					onClose={closeModal}
				/>
			)
		}
		default:
			return null;
	}
}

export {PlanTemplateModals}