import React from "react";
import {Group, KonvaNodeEvents, Rect, Text} from "react-konva";
import {defaultMarkStyle, defaultMarkTextStyle} from "../constants";
import {useSelector} from "react-redux";
import {Vector2d} from "konva/types/types";
import {selectExportInProgress, selectZoomState} from "../features/view/viewSlice";
import {selectTabsVisibilityState} from "../features/config/configSlice";
import isEqual from "lodash/isEqual";

type AreaMeasurementValueMarkProps = {
	label: string;
	centerOfMass: Vector2d,
	callbacks?: KonvaNodeEvents,
	draggable?: boolean
	listening?: boolean
}

export const AreaMeasurementValueMark: React.FC<AreaMeasurementValueMarkProps> = ({
	label,
	centerOfMass,
	callbacks = {},
	draggable = false,
	listening = true
}: AreaMeasurementValueMarkProps) => {
	const tabsVisibilityState = useSelector(selectTabsVisibilityState);
	const exportInProgress = useSelector(selectExportInProgress);
	const zoomState = useSelector(selectZoomState);
	const isVisible = tabsVisibilityState.VALUE_MARKS || exportInProgress

	return (
		<AreaMeasurementValueMarkKonva
			rotation={zoomState.rotation}
			isVisible={isVisible}
			label={label}
			centerOfMass={centerOfMass}
			callbacks={callbacks}
			draggable={draggable}
			listening={listening}/>
	)
}

type AreaMeasurementValueMarkKonvaProps = AreaMeasurementValueMarkProps & {
	rotation: number,
	isVisible: boolean,
}

export function _AreaMeasurementValueMarkKonva({
	rotation,
	isVisible,
	label,
	centerOfMass,
	callbacks = {},
	draggable = false,
	listening = true
}: AreaMeasurementValueMarkKonvaProps) {
	const elementSize = {
		width: label.length >= 6 ? 60 : 45,
		height: 20,
	}
	const rectangleStyle = {
		...elementSize,
		...defaultMarkStyle
	}
	const textStyle = {
		...elementSize,
		...defaultMarkTextStyle
	}

	return (
		<Group
			visible={isVisible}
			listening={listening}
			offsetX={elementSize.width / 2}
			offsetY={elementSize.height / 2}
			x={centerOfMass.x} y={centerOfMass.y}
			rotation={-rotation}
			{...elementSize}>
			<Rect {...rectangleStyle} {...callbacks}
				  draggable={draggable}/>
			<Text listening={false} {...textStyle} text={label} offsetY={-1}/>
		</Group>
	)
}

const AreaMeasurementValueMarkKonva = React.memo(_AreaMeasurementValueMarkKonva, isEqual)
