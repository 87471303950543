import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectStageConfig} from "../config/configSlice";
import React from "react";
import Modal from "../../../../../../components/modal/Modal";
import ModalTitle from "../../../../../../components/modal/ModalTitle";
import {EditorTool} from "../../models/enums";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import {useScaleSetDataContext} from "./ScaleSetProvider";
import {pageSizeMap} from "../../constants";
import "./setScaleModal.scss";
import TextInput from "../../components/TextInput";
import CustomSelect from "../../../../../../components/ui/CustomSelect";
import {IconDrawScale} from "../../icons/IconDrawScale";
import {selectScaleOptions, viewActions} from "../view/viewSlice";
import {PageScaleRatio} from "../../../../../../models/DrawingDetails";
import {useKeyboardOnModal} from "../../hooks/useKeyboardOnModal";
import {PageSizeFormat} from "../../../../../base-konva/enums";

type SetScaleModalProps = {
	isOpen: boolean
	onClose: () => void;
}

export function SetScaleModal({isOpen, onClose}: SetScaleModalProps) {

	const {t} = useTranslation();
	const dispatch = useDispatch();
	const stageConfig = useSelector(selectStageConfig);
	const {
		save,
	} = useScaleSetDataContext()
	const {ratioInput, pageSize} = useSelector(selectScaleOptions)
	const canSetScale = Boolean(ratioInput && ratioInput > 0)

	useKeyboardOnModal({
		when: canSetScale,
		keyUpMap: {
			"Enter": calculateScale,
			"13": calculateScale
		}
	});

	function handleClose() {
		onClose()
	}

	function calculateScale() {
		if (!canSetScale) return;

		const stageWidth = Math.min(stageConfig.height, stageConfig.width)
		const scale = 1 / (stageWidth / (pageSizeMap[pageSize].width * ratioInput!))
		save(scale, ratioInput ? new PageScaleRatio(ratioInput, pageSize) : undefined);
		handleClose()
	}

	const selectItems = Object.keys(pageSizeMap).map(key => ({label: key, value: key}))
	const handlePageSizeChange = (pageSize: string) => {
		dispatch(viewActions.setScaleOptions({
			pageSize: pageSize as PageSizeFormat,
			ratioInput
		}))
	};

	function handleDrawScaleClick() {
		handleClose()
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.SCALE, isUndoRedoExcluded: true}))
	}

	function onScaleRatioChange(evt: React.ChangeEvent<HTMLInputElement>) {
		const {value} = evt.target;
		if (value === "") {
			dispatch(viewActions.setScaleOptions({
				pageSize,
				ratioInput: undefined
			}))
		}
		else if (/^[0-9]*$/.test(value)) {
			dispatch(viewActions.setScaleOptions({
				pageSize,
				ratioInput: Number(value)
			}))
		}
	}

	return isOpen ? (
		<Modal isOpen={true} onClose={handleClose} customClass={"set-scale-modal"}>
			<ModalTitle>{t("editor.scale.setScaleModal.title")}</ModalTitle>
			<div className={"set-scale-modal-content"}>
				<div className={"set-scale-modal-content_section"}>
					<div className="set-scale-modal-content_section_field_label">
						{t("editor.scale.setScaleModal.scaleRatioLabel")}
					</div>
					<div className={"set-scale-modal-content_section_row"}>
						<strong>1</strong>:
						<TextInput
							placeholder={t("editor.scale.setScaleModal.scaleInputPlaceholder")}
							value={ratioInput ?? ""}
							onChange={onScaleRatioChange}
						/>
						<CustomSelect
							placeholder={""}
							customClass="set-scale-modal-content_section_select"
							items={selectItems}
							onChange={handlePageSizeChange}
							value={pageSize}
						/>
					</div>
				</div>
				<div className={"set-scale-modal-content_divider"}/>
				<div className={"set-scale-modal-content_section"}>
					<div className="set-scale-modal-content_section_field_label">
						{t("editor.scale.setScaleModal.scaleDrawLabel")}
					</div>
					<div className={"center"}>
						<Button variant={"secondary"}
								label={t("editor.scale.setScaleModal.drawScaleButton")}
								rightIcon={<IconDrawScale/>}
								onClick={handleDrawScaleClick}
						/>
					</div>
				</div>
			</div>
			<ModalButtons>
				<Button label={t("common.buttons.cancel")}
						variant={"optional"}
						onClick={handleClose}/>
				<Button label={t("editor.scale.setScaleModal.setButton")}
						variant={"primary"}
						disabled={!canSetScale}
						onClick={calculateScale}/>
			</ModalButtons>
		</Modal>
	) : null
}

