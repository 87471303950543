import moment from "moment";
import {AttachmentDetailApiDto} from "./restModels";

export class AttachmentDetails {
	constructor(
		public readonly id: string,
		public readonly createdAt: moment.Moment,
		public readonly filename: string,
		public readonly size: number
	) {}

	static fromJson(json: AttachmentDetailApiDto) {
		const {id, createdAt, filename, size} = json
		return new AttachmentDetails(id, moment(createdAt), filename, size)
	}
}