import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const IconPenToolPolyLine: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M13.3480083,8.45832538L15.0291848 9.54167462 11.788 14.569 24.0919351 15.0584926 20.8098624 22.6876295 18.972659 21.8972602 21.104 16.941 8.21119854 16.4297934z"]}/>
	);
};

export default IconPenToolPolyLine