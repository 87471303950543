import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Vector2d} from "konva/types/types";
import {RootState} from "../../editorStore";
import {distance, FlattenUtils, getEditorLine, lineAngle, midpoint} from "../../utils";
import {
	ActiveAreaFragmentCallback,
	ActiveDeductionCallback,
	AreaCallback,
	AreaDeductionActivateActionPayload,
	AreaDeductionAddPointActionPayload,
	AreaDeductionCloseActionPayload,
	AreaDeductionCreateActionPayload,
	AreaDeductionDeleteActionPayload,
	AreaDeductionUpdatePointPositionActionPayload,
	AreaDeductionUpdatePositionActionPayload,
	AreaFragment,
	AreaFragmentCallback,
	AreaMeasurement,
	AreaMeasurementState,
	AreaSetVisibilityActionPayload,
	AreaStyle,
	ChangeStyleActionPayload,
	CloseAreaActionPayload,
	Deduction,
	DeleteManyActionPayload,
	EditorPoint,
	MaterialChangeActionPayload,
	MaterialMeasurementCreateActionPayload,
	SetAreaHeightActionPayload,
	UpdateFragmentPointPositionActionPayload,
	UpdateFragmentPositionActionPayload
} from "../../models/editor";
import {AreaHelper} from "./AreaHelper";
import {EditorTool, MeasurementType} from "../../models/enums";
import Flatten from "@flatten-js/core";
import {
	ActionIdValuePayload,
	ActivateActionPayload,
	DeleteActionPayload,
	DeleteSelectedItemActionPayload,
	GroupedActionPayload
} from "../../../../../base-konva/types";
import {getId} from "../../../../../../utils";


const initialState: AreaMeasurementState = {
	areas: []
};

export const areaSlice = createSlice({
	name: 'area',
	initialState: initialState,
	reducers: {
		reset: () => ({...initialState}),
		addArea: (state, {payload}: PayloadAction<MaterialMeasurementCreateActionPayload>) => {
			const areaId = getId();
			const area: AreaMeasurement = {
				id: areaId,
				material: payload.material,
				visible: true,
				style: payload.style as AreaStyle,
				areaFragments: [],
				activeAreaFragmentId: null,
				height: payload.height,
				type: payload.height ? MeasurementType.VOLUME : MeasurementType.AREA,
				deductions: [],
				activeDeductionId: null
			}
			state.areas.push(area)
			state.activeAreaId = areaId;
		},
		addAreaDeduction: (state, {payload}: PayloadAction<AreaDeductionCreateActionPayload>) => {
			const {areaId} = payload
			executeOnSelectedArea(state, areaId, area => {
				const deductionId = getId()
				area.deductions.push({
					id: deductionId,
					lines: [],
					lastMouseUpPosition: null,
					closed: false,
					areaFragmentId: null
				})
				area.activeDeductionId = deductionId
			})
		},
		addPointToActiveFragment: (state, action: PayloadAction<Vector2d>) => {
			const pointPosition = action.payload
			executeOnActiveFragment(state, (_area, fragment) => {
				addPointToFragment(fragment, pointPosition)
			})
		},
		addPointToDeduction: (state, {payload}: PayloadAction<AreaDeductionAddPointActionPayload>) => {
			const {areaId, deductionId, pointPosition} = payload
			executeOnSelectedArea(state, areaId, area => {
				executeOnSelectedDeduction(area, deductionId, deduction => {
					addPointToFragment(deduction, pointPosition)
				})
			})
		},
		startNewAreaFragment: (state, action: PayloadAction<Vector2d>) => {
			executeOnActiveArea(state, area => {
				const areaFragmentId = getId()
				const areaFragment: AreaFragment = {
					id: areaFragmentId,
					lines: [],
					lastMouseUpPosition: action.payload,
					closed: false,
				}
				area.areaFragments.push(areaFragment)
				area.activeAreaFragmentId = areaFragmentId
			})
		},
		closeArea: (state, action: PayloadAction<CloseAreaActionPayload>) => {
			executeOnSelectedArea(state, action.payload.area.id, area => {
				executeOnActiveAreaFragment(area, fragment => {
					closeAreaFragment(fragment)
				})
				area.activeAreaFragmentId = null
			})
		},
		closeDeduction: (state, {payload}: PayloadAction<AreaDeductionCloseActionPayload>) => {
			const {areaId, deductionId} = payload
			executeOnSelectedArea(state, areaId, area => {
				executeOnSelectedDeduction(area, deductionId, deduction => {
					closeSelectedDeduction(deduction, area)
				})
				area.activeDeductionId = null
			})
		},
		closeAreas: state => {
			state.areas = state.areas
				.map(area => {
					area.areaFragments = area.areaFragments
						.filter(fragment => fragment.lines.length >= 2)
						.map(fragment => {
							if (!fragment.closed) {
								closeAreaFragment(fragment)
							}
							return fragment
						})
					area.deductions = autoCloseDeductions(area)
					area.activeAreaFragmentId = null
					area.activeDeductionId = null
					return area
				})
		},
		updateAreaPointPosition: (state, action: PayloadAction<UpdateFragmentPointPositionActionPayload>) => {
			const {itemId: pointId, newPosition, fragmentId} = action.payload;

			executeOnSelectedFragment(state, fragmentId, (area, fragment) => {
				updatePointPosition(fragment, pointId, newPosition)
				updateDeductionsAfterAreaModification(area, fragmentId)
			})
		},
		updateDeductionPointPosition: (state, action: PayloadAction<AreaDeductionUpdatePointPositionActionPayload>) => {
			const {areaId, deductionId, pointId, newPosition} = action.payload

			executeOnSelectedArea(state, areaId, area => {
				executeOnSelectedDeduction(area, deductionId, deduction => {
					updatePointPosition(deduction, pointId, newPosition)
					updateAreaDeduction(deduction, area)
				})
			})
		},
		activateArea: (state, action: PayloadAction<ActivateActionPayload>) => {
			const {id} = action.payload
			activateArea(state, id)
		},
		activateDeduction: (state, {payload}: PayloadAction<AreaDeductionActivateActionPayload>) => {
			const {areaId, deductionId} = payload
			activateArea(state, areaId)
			executeOnSelectedArea(state, areaId, area => {
				const deduction = area.deductions.find(deduction => deduction.id === deductionId)
				area.activeDeductionId = deduction?.id ?? null
			})
		},
		clearActiveArea: (state, _action: PayloadAction<GroupedActionPayload>) => {
			state.activeAreaId = undefined;
		},
		updateAreaFragmentPosition: (state, {payload}: PayloadAction<UpdateFragmentPositionActionPayload>) => {
			const {fragmentId, positionDelta} = payload;

			executeOnSelectedFragment(state, fragmentId, (area, fragment) => {
				updatePosition(fragment, positionDelta)
				updateDeductionsAfterAreaModification(area, fragmentId)
			})
		},
		updateDeductionPosition: (state, action: PayloadAction<AreaDeductionUpdatePositionActionPayload>) => {
			const {areaId, deductionId, positionDelta} = action.payload

			executeOnSelectedArea(state, areaId, area => {
				executeOnSelectedDeduction(area, deductionId, deduction => {
					updatePosition(deduction, positionDelta)
					updateAreaDeduction(deduction, area)
				})
			})
		},
		changeStyle: (state, {payload}: PayloadAction<ChangeStyleActionPayload<AreaStyle>>) => {
			executeOnSelectedArea(state, payload.id, area => {
				area.style = {...payload.style}
			})
		},
		changeMaterial: (state, {payload}: PayloadAction<MaterialChangeActionPayload>) => {
			const {id, material, style} = payload;
			executeOnSelectedArea(state, id, area => {
				area.material = material
				area.style = style as AreaStyle
			})
		},
		deleteArea: (state, {payload}: PayloadAction<DeleteActionPayload>) => {
			state.areas = state.areas.filter(a => a.id !== payload.id)
		},
		deleteDeduction: (state, action: PayloadAction<AreaDeductionDeleteActionPayload>) => {
			const {areaId, deductionId} = action.payload
			executeOnSelectedArea(state, areaId, area => {
				area.deductions = area.deductions.filter(d => d.id !== deductionId)
				if (area.activeDeductionId === deductionId) {
					area.activeDeductionId = null
				}
			})
		},
		setAreas: (state, {payload}: PayloadAction<ActionIdValuePayload<AreaMeasurement[]>>) => {
			state.areas = payload.value
		},
		setAreaHeight: (state, {payload}: PayloadAction<SetAreaHeightActionPayload>) => {
			const {id, height} = payload;
			executeOnSelectedArea(state, id, area => {
				area.height = height
				area.type = height ? MeasurementType.VOLUME : MeasurementType.AREA
			})
		},
		setVisibility: (state, {payload}: PayloadAction<AreaSetVisibilityActionPayload>) => {
			const {id, visible, setAll, type} = payload;
			for (let measurement of state.areas) {
				if (measurement.id === id || setAll) {
					if ((type === EditorTool.AREA && measurement.height === undefined) ||
						(type === EditorTool.VOLUME && measurement.height !== undefined)) {
						measurement.visible = visible;
					}
					if (!setAll) break;
				}
			}
		},
		removeItems: (state, {payload}: PayloadAction<DeleteManyActionPayload>) => {
			state.areas = state.areas.filter(l => !payload.ids.some(id => id === l.id));
		},
		removeSelectionItems: (state, action: PayloadAction<DeleteSelectedItemActionPayload>) => {
			let area = state.areas.find(area => area.id === action.payload.id);
			if (area) {
				area.areaFragments = area.areaFragments.filter(fragment => fragment.id !== action.payload.fragmentId);
				area.deductions = area.deductions.filter(deduction => deduction.areaFragmentId !== action.payload.fragmentId)
			}
		},
	}
});

function closeAreaFragment(area: AreaFragment) {
	let lastActivePoint: EditorPoint = area.lines[area.lines.length - 1].to;
	const newPoint = area.lines[0].from;
	const closeLine = {
		id: getId(),
		from: lastActivePoint,
		to: newPoint,
		angle: lineAngle(lastActivePoint.position, newPoint.position),
		distance: distance(lastActivePoint.position, newPoint.position),
		center: midpoint(lastActivePoint.position, newPoint.position),
	}
	area.lastMouseUpPosition = newPoint.position;
	area.closed = true;
	area.lines.push(closeLine)
}

function autoCloseDeductions(area: AreaMeasurement) {
	area.deductions = area.deductions.filter(deduction => deduction.lines.length >= 2)
	for (let deduction of area.deductions) {
		if (!deduction.closed) {
			closeSelectedDeduction(deduction, area)
		}
	}
	return area.deductions
}

function closeSelectedDeduction(deduction: Deduction, area: AreaMeasurement) {
	closeAreaFragment(deduction)
	for (let areaFragment of area.areaFragments) {
		recalculateAreaFragmentDeduction(deduction, areaFragment, area)
	}
}

function addPointToFragment(fragment: AreaFragment, pointPosition: Vector2d) {
	if (!fragment.closed) {
		if (fragment.lastMouseUpPosition) {
			let lastActivePoint: EditorPoint = {
				id: getId(),
				position: fragment.lastMouseUpPosition
			};
			if (fragment.lines.length > 0)
				lastActivePoint = fragment.lines[fragment.lines.length - 1].to;

			const newPoint: EditorPoint = {
				id: getId(),
				position: pointPosition
			};
			fragment.lastMouseUpPosition = newPoint.position;
			fragment.lines.push({
				id: getId(),
				from: lastActivePoint,
				to: newPoint,
				angle: lineAngle(lastActivePoint.position, newPoint.position),
				distance: distance(lastActivePoint.position, newPoint.position),
				center: midpoint(lastActivePoint.position, newPoint.position),
			})
		}
		else {
			fragment.lastMouseUpPosition = pointPosition
		}
	}
}

function activateArea(state: AreaMeasurementState, areaId: string) {
	const area = state.areas.find(area => area.id === areaId)
	if (area) {
		state.activeAreaId = areaId
		area.activeDeductionId = null
	}
	else {
		state.activeAreaId = undefined
	}
}

function updatePointPosition(fragment: AreaFragment, pointId: string, newPosition: Vector2d) {
	for (let line of fragment.lines) {
		let lineUpdate = false;

		if (line.from.id === pointId) {
			lineUpdate = true;
			line.from.position = newPosition;
		}
		else if (line.to.id === pointId) {
			lineUpdate = true;
			line.to.position = newPosition;
		}

		if (lineUpdate) {
			line.angle = lineAngle(line.from.position, line.to.position);
			line.distance = distance(line.from.position, line.to.position);
			line.center = midpoint(line.from.position, line.to.position)
		}
	}
}

function updatePosition(fragment: AreaFragment, positionDelta: Vector2d) {
	const {x: xDelta, y: yDelta} = positionDelta

	if (fragment.centerOfMass) {
		fragment.centerOfMass.x += xDelta
		fragment.centerOfMass.y += yDelta
	}

	fragment.lines = fragment.lines.map(line => {
		const from: EditorPoint = {
			...line.from,
			position: {
				x: line.from.position.x + xDelta,
				y: line.from.position.y + yDelta
			}
		}
		const to: EditorPoint = {
			...line.to,
			position: {
				x: line.to.position.x + xDelta,
				y: line.to.position.y + yDelta
			}
		}
		return getEditorLine(line.id, from, to)
	})
}

function updateDeductionsAfterAreaModification(area: AreaMeasurement, fragmentId: string) {
	const deductions = area.deductions.filter(deduction => deduction.areaFragmentId === fragmentId)
	for (let deduction of deductions) {
		updateAreaDeduction(deduction, area)
	}
}

function updateAreaDeduction(deduction: Deduction, area: AreaMeasurement) {
	if (deduction.closed && deduction.areaFragmentId) {
		const fragment = area.areaFragments.find(fragment => fragment.id === deduction.areaFragmentId)
		if (fragment) {
			recalculateAreaFragmentDeduction(deduction, fragment, area);
		}
	}
}

function recalculateAreaFragmentDeduction(deduction: Deduction, fragment: AreaFragment, area: AreaMeasurement) {
	const updateActiveDeductionId = area.activeDeductionId !== null

	let simplifyPolygon = AreaHelper.simplifyPolygonLines(deduction.lines);
	simplifyPolygon.forEach((simplifiedDeduction) => {

		const fragmentPolygon = FlattenUtils.getPolygon(fragment.lines)
		const deductionPolygon = FlattenUtils.getPolygon(simplifiedDeduction)

		if (fragmentPolygon.contains(deductionPolygon)) {
			const deductionPolygon = FlattenUtils.getPolygon(simplifiedDeduction)
			const mergedDeduction = mergeDeductions(deductionPolygon, deduction.id, area, fragment.id);
			let intersectedPolygon = mergedDeduction.intersectedPolygon;
			area.deductions = mergedDeduction.deductions;
			const linesArray = FlattenUtils.polygonToLines(intersectedPolygon)
			linesArray.forEach(lines => {
				const id = getId()
				area.deductions.push({
					id: id,
					closed: true,
					areaFragmentId: fragment.id,
					lines: lines,
					lastMouseUpPosition: null,
					centerOfMass: undefined
				})
				if (updateActiveDeductionId) {
					area.activeDeductionId = id
				}
			})
		}
		else {
			const intersectionPoints = fragmentPolygon.intersect(deductionPolygon)
			if (intersectionPoints.length > 0) {

				let intersectedPolygon = FlattenUtils.intersectPolygons(
					fragmentPolygon,
					deductionPolygon
				)
				const mergedDeduction = mergeDeductions(intersectedPolygon, deduction.id, area, fragment.id);
				intersectedPolygon = mergedDeduction.intersectedPolygon;
				area.deductions = mergedDeduction.deductions;
				const linesArray = FlattenUtils.polygonToLines(intersectedPolygon)
				linesArray.forEach(lines => {
					const id = getId()
					area.deductions.push({
						id: id,
						closed: true,
						areaFragmentId: fragment.id,
						lines: lines,
						lastMouseUpPosition: null,
						centerOfMass: undefined
					})
					if (updateActiveDeductionId) {
						area.activeDeductionId = id
					}
				})
			}
			else {
				area.deductions = area.deductions.filter(d => d.id !== deduction.id)
			}
		}
	})
}

function mergeDeductions(deduction: Flatten.Polygon, deductionId: string, area: AreaMeasurement, areaFragmentId: string) {
	let newDeduction = deduction;
	const newDeductions = area.deductions.filter(ded => {
		if (ded.id !== deductionId) {
			const deductionPolygon = FlattenUtils.getPolygon(ded.lines)
			const contains = deductionPolygon.contains(newDeduction);
			const isContained = newDeduction.contains(deductionPolygon);
			const intersectionPoints = deductionPolygon.intersect(newDeduction)
			if (intersectionPoints.length > 0 || contains || isContained) {
				const newPolygons = FlattenUtils.unifyPolygons(
					deductionPolygon,
					newDeduction
				);
				if (newPolygons.faces.size > 1) {
					newDeduction = FlattenUtils.subtract(
						newDeduction,
						deductionPolygon
					)
					return true;
				}
				else if (ded.areaFragmentId === areaFragmentId) {
					newDeduction = newPolygons;
					return false;
				}
			}
		}
		else
			return false;
		return true;
	});

	return {deductions: newDeductions, intersectedPolygon: newDeduction};
}

function executeOnActiveFragment(state: AreaMeasurementState, callback: AreaFragmentCallback) {
	executeOnActiveArea(state, area => {
		for (let areaFragment of area.areaFragments) {
			if (areaFragment.id === area.activeAreaFragmentId) {
				callback(area, areaFragment);
				break;
			}
		}
	})
}

function executeOnSelectedFragment(state: AreaMeasurementState, fragmentId: string, callback: AreaFragmentCallback) {
	executeOnActiveArea(state, area => {
		for (let areaFragment of area.areaFragments) {
			if (areaFragment.id === fragmentId) {
				callback(area, areaFragment);
				break;
			}
		}
	})
}

function executeOnActiveArea(state: AreaMeasurementState, callback: AreaCallback) {
	for (let area of state.areas) {
		if (area.id === state.activeAreaId) {
			callback(area);
			break;
		}
	}
}

function executeOnSelectedArea(state: AreaMeasurementState, areaId: string, callback: AreaCallback) {
	for (let area of state.areas) {
		if (area.id === areaId) {
			callback(area);
			break;
		}
	}
}

function executeOnActiveAreaFragment(area: AreaMeasurement, callback: ActiveAreaFragmentCallback) {
	for (let areaFragment of area.areaFragments) {
		if (areaFragment.id === area.activeAreaFragmentId) {
			callback(areaFragment)
		}
	}
}

function executeOnSelectedDeduction(area: AreaMeasurement, deductionId: string, callback: ActiveDeductionCallback) {
	for (let deduction of area.deductions) {
		if (deduction.id === deductionId) {
			callback(deduction)
		}
	}
}

export const areaActions = areaSlice.actions;
export const selectAreas = (state: RootState) => state.undoGroup.present.area.areas;
export const selectActiveAreaId = (state: RootState) => state.undoGroup.present.area.activeAreaId;
export const selectAreaActiveDeductionId = (areaId?: string) => (state: RootState): string | null => {
	for (let area of state.undoGroup.present.area.areas) {
		if (area.id === areaId) {
			return area.activeDeductionId
		}
	}
	return null
}

export const areaReducer = areaSlice.reducer;
