import React from "react";
import {useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";
import ProjectExportConfirmationModal from "./ProjectExportConfirmationModal";
import CcExportStatusModal from "./CcExportStatusModal";
import ProjectExportErrorsModal from "./ProjectExportErrorsModal";

export function SyncStageModals() {
	const {syncStageModals: {modal}} = useSyncMaterialsContext()

	switch (modal?.type) {
		case "confirmation": {
			const {onSubmit, onClose, projectSource, isSyncOptionsModelLoading} = modal.data
			return (
				<ProjectExportConfirmationModal
					projectSource={projectSource}
					onSubmit={onSubmit}
					onClose={onClose}
				 	isSyncOptionsModelLoading={isSyncOptionsModelLoading}
				/>
			)
		}
		case "status": {
			const {onShowError, onTryAgain, onClose} = modal.data
			return (
				<CcExportStatusModal
					onShowError={onShowError}
					onTryAgain={onTryAgain}
					onClose={onClose}
				/>
			)
		}
		case "error": {
			const {onClose} = modal.data
			return (
				<ProjectExportErrorsModal
					onClose={onClose}
				/>
			)
		}
		default:
			return null;
	}
}