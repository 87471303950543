import React from "react";
import {capitalizeString} from "../../../utils/TextUtils";
import {useBreadcrumbsContext} from "../providers/BreadcrumbsProvider";

interface ProjectDetailsBreadcrumbProps {
	projectId: string,
}

const ProjectDetailsBreadcrumb: React.FC<ProjectDetailsBreadcrumbProps> = ({projectId}) => {
	const {projectData} = useBreadcrumbsContext()
	const project = projectData?.project

	if (project === undefined) {
		return <span>...</span>
	}
	if (project.id === projectId) {
		return <span>{`${capitalizeString(project.type)} #${project.number}${project.name ? ` - ${project.name}` : ""}`}</span>
	}
	return null
};

export default ProjectDetailsBreadcrumb
