import React from "react";
import {BrowserRouter, useHistory} from "react-router-dom";
import {NavigateToOptions} from "./types";
import {NavigatorProvider} from "./NavigatorProvider";

type NavigateToHandler =
	(history: ReturnType<typeof useHistory>) => (path: string, options: NavigateToOptions | undefined) => void

export function RouterWithNavigatorProvider({children, getUserConfirmation, getNavigateTo}: {
	children: React.ReactNode,
	getUserConfirmation?: ((message: string, callback: (ok: boolean) => void) => void) | undefined,
	getNavigateTo: NavigateToHandler
}) {
	return (
		<BrowserRouter getUserConfirmation={getUserConfirmation}>
			<NavigatorProviderWrapper getNavigateTo={getNavigateTo}>
				{children}
			</NavigatorProviderWrapper>
		</BrowserRouter>
	)
}

function NavigatorProviderWrapper({children, getNavigateTo}: {
	children: React.ReactNode,
	getNavigateTo: NavigateToHandler
}) {
	const history = useHistory();
	return (
		<NavigatorProvider navigateTo={getNavigateTo(history)}>
			{children}
		</NavigatorProvider>
	)
}