import {useTranslation} from "react-i18next";
import ModalButtons from "../../../../../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../../../../../components/ui/Button";
import React from "react";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../../../models/interfaces";
import {MeasurementStyleSelector} from "../../../components/MeasurementStyleSelector";

type ContentFooterProps = {
	closeModal: () => void
	canChangeMaterial: boolean
	changeMaterial: () => void
	measurementStyleSelector?: {
		measurementType: MaterialAddableTool
		style: MeasurementStyle
		onChange: (newStyle: MeasurementStyle) => void
	}
}

export function ContentFooter({closeModal, canChangeMaterial, changeMaterial, measurementStyleSelector}: ContentFooterProps) {
	const {t} = useTranslation();
	return (
		<div className="material-modal_container_footer">
			{measurementStyleSelector ? <MeasurementStyleSelector {...measurementStyleSelector}/> : null}
			<ModalButtons>
				<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={closeModal}/>
				<Button label={t("editor.changeMaterial")}
						variant={"primary"}
						disabled={!canChangeMaterial}
						onClick={() => {
							changeMaterial()
							closeModal()
						}}/>
			</ModalButtons>
		</div>
	)
}