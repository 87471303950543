import {AxiosInstance} from "axios";
import UserSettings from "../models/UserSettings";
import {SymbolSettings} from "../models/SymbolSettings";
import {KeySettingsType, UnitsType} from "../models/enums";
import {DeployVersionInfoApiDto} from "../models/restModels";

export default class SettingsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchSettings(): Promise<UserSettings> {
		const url = "/api/users/me/settings";
		return this.axiosInstance.get(url).then(resp => UserSettings.fromJson(resp.data));
	}

	putSettings(measurementSystem: UnitsType, keySettings: KeySettingsType, downloadKeyTable: boolean): Promise<void> {
		const url = "/api/users/me/settings";
		return this.axiosInstance.put(url, {measurementSystem, keySettings, downloadKeyTable});
	}

	fetchSymbolSettings(): Promise<SymbolSettings> {
		const url = "/api/symbol_settings"
		return this.axiosInstance.get(url).then(resp => SymbolSettings.fromJson(resp.data));
	}

	fetchWebVersion(): Promise<string> {
		const url = "/info"
		return this.axiosInstance.get(url).then(resp => (resp.data as DeployVersionInfoApiDto).build.version)
	}

	fetchBackendVersion(): Promise<string> {
		const url = "/actuator/info"
		return this.axiosInstance.get(url).then(resp => (resp.data as DeployVersionInfoApiDto).build.version)
	}
}
