import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconZoomIn: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => !disabled && onClick(event);

	return (
		<IconUnselectable>
			<svg width={18} height={18} viewBox="0 0 18 18" onClick={handleClick} style={{cursor: cursor}}>
				<path fill={fill}
					  d="M9,14.6 C5.913,14.6 3.4,12.087 3.4,9 C3.4,5.913 5.913,3.4 9,3.4 C12.087,3.4 14.6,5.913 14.6,9 C14.6,12.087 12.087,14.6 9,14.6 L9,14.6 Z M9,2 C5.136,2 2,5.136 2,9 C2,12.864 5.136,16 9,16 C12.864,16 16,12.864 16,9 C16,5.136 12.864,2 9,2 L9,2 Z M9.7,5.5 L8.3,5.5 L8.3,8.3 L5.5,8.3 L5.5,9.7 L8.3,9.7 L8.3,12.5 L9.7,12.5 L9.7,9.7 L12.5,9.7 L12.5,8.3 L9.7,8.3 L9.7,5.5 Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconZoomIn
