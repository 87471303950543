import React, {useEffect, useRef} from "react";
import Konva from "konva";
import {Layer, Line, Stage} from "react-konva";
import {dashPatterConfig} from "../../../../../../project-drawings/features/editor/constants";
import {useUpdatedRef} from "../../../../../../../hooks/useUpdatedRef";
import {LineStyle} from "../../../../../../base-konva/types";

type LengthSymbolPreviewGeneratorProps = {
	style: LineStyle
	onSuccess(stageDataUrl: string): void
	onFailure(): void
}

export function LengthSymbolPreviewGenerator({style, onSuccess, onFailure}: LengthSymbolPreviewGeneratorProps) {
	const layerRef = useRef<Konva.Layer>(null);
	const dataRef = useUpdatedRef({onSuccess, onFailure})

	useEffect(() => {
		const {onSuccess, onFailure} = dataRef.current
		if (layerRef.current) {
			try {
				layerRef.current.batchDraw()
				const dataUrl = layerRef.current.getStage().toDataURL()
				if (dataUrl) {
					onSuccess(dataUrl)
				}
				else {
					onFailure()
				}
			}
			catch (e) {
				onFailure()
			}
		}
	}, [layerRef, dataRef])

	const height = 40;
	const width = 60;
	const strokeWidth = 4;

	return (
		<Stage height={height} width={width}>
			<Layer ref={layerRef}>
				<Line
					points={[0, height / 2, width, height / 2]}
					stroke={style.stroke}
					strokeWidth={strokeWidth}
					lineCap={"round"} lineJoin={"round"}
					dash={dashPatterConfig[style.dashType].konvaDashArray.map(
						(value, index) => {
							if (index % 2 === 1) {
								return value + strokeWidth;
							}
							return value;
						})
					}
				/>
			</Layer>
		</Stage>
	)
}