import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Status} from "../../../models/enums";
import {OnSuccess} from "../../../utils/CallbackUtils";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {useInvalidateSymbolQueries} from "./useInvalidateSymbolQueries";

interface UseDeleteSymbolGroupHook {
	deleteStatus: Status,
	deleteSymbolGroup: (symbolGroupId: string, onSuccess: OnSuccess) => void;
}

function useDeleteSymbolGroup(): UseDeleteSymbolGroupHook {

	const {t} = useTranslation();
	const [deleteStatus, setDeleteStatus] = useState(Status.IDLE);
	const {invalidateSymbolQueries} = useInvalidateSymbolQueries();

	function deleteSymbolGroup(symbolGroupId: string, onSuccess: OnSuccess) {
		setDeleteStatus(Status.LOADING);
		apiInstance.symbolsApi.deleteSymbolGroup(symbolGroupId)
			.then(() => {
				onSuccess();
				invalidateSymbolQueries();
				setDeleteStatus(Status.SUCCESS);
				NotificationService.successNotification(t("common.success"),
					t("settings.tabs.symbols.groups.dropdown.delete.success"));
			})
			.catch((err: any) => {
				setDeleteStatus(Status.ERROR);
				NotificationService.errorNotification(t("common.error"),
					t("settings.tabs.symbols.groups.dropdown.delete.errorDesc"));
				LoggerService.logError(err);
			})
	}

	return {deleteStatus, deleteSymbolGroup}
}

export default useDeleteSymbolGroup;
