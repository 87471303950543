import {useState} from "react";
import {PlanUploadData, PlanUploadPageData} from "../types";

type Modal = {
	type: 'reselect-pages',
	data: {
		template: PlanUploadData,
		onConfirmReselect: (template: PlanUploadData) => void
	}
} | {
	type: 'rename-template',
	data: {
		template: PlanUploadData,
		onSuccess: (newName: string) => void,
	}
} | {
	type: 'rename-page',
	data: {
		template: PlanUploadData,
		page: PlanUploadPageData,
		onDone: (newPlanPage: PlanUploadPageData, templateId: string) => void,
	}
} | {
	type: 'delete-page',
	data: {
		template: PlanUploadData,
		page: PlanUploadPageData,
		onSuccess: (pageNumber: number, planTemplate: PlanUploadData) => void
	}
} | {
	type: 'reset-page',
	data: {
		template: PlanUploadData,
		page: PlanUploadPageData,
	}
} | {
	type: 'no-scale-info',
	data: {
		onClose: () => void,
		pageTitles: string[],
	}
}

export function useModalsData() {

	const [modal, setModal] = useState<Modal | null>(null);

	function closeModal() {
		setModal(null);
	}

	function openReselectPagesModal(template: PlanUploadData, onConfirmReselect: (template: PlanUploadData) => void) {
		setModal({
			type: 'reselect-pages',
			data: {template, onConfirmReselect}
		})
	}

	function openRenameTemplateModal(template: PlanUploadData, onSuccess: (newName: string) => void) {
		setModal({
			type: 'rename-template',
			data: {template, onSuccess}
		})
	}

	function openRenamePageModal(
		template: PlanUploadData,
		page: PlanUploadPageData,
		onDone: (newPlanPage: PlanUploadPageData, templateId: string) => void,
	) {
		setModal({
			type: 'rename-page',
			data: {template, page, onDone}
		})
	}

	function openDeletePageModal(
		template: PlanUploadData,
		page: PlanUploadPageData,
		onSuccess: (pageNumber: number, planTemplate: PlanUploadData) => void,
	) {
		setModal({
			type: 'delete-page',
			data: {template, page, onSuccess}
		})
	}

	function openResetPageModal(template: PlanUploadData, page: PlanUploadPageData) {
		setModal({
			type: 'reset-page',
			data: {template, page}
		})
	}

	function openNoScaleInfoModal(pageTitles: string[], onClose: () => void) {
		setModal({
			type: 'no-scale-info',
			data: {onClose, pageTitles}
		})
	}

	return {
		modal,
		closeModal,
		openReselectPagesModal,
		openRenameTemplateModal,
		openRenamePageModal,
		openDeletePageModal,
		openResetPageModal,
		openNoScaleInfoModal,
	}
}