import React from "react";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../../components/layout/table/TableHeaderCell";
import {TableColumnConfig} from "../../../../models/interfaces";
import TableNoRecords from "../../../../components/layout/table/TableNoRecords";
import "./planSummaryItemsTable.scss";
import {PlanSummaryItem} from "../../../../models/PlanSummaryItem";
import FitTime from "../../../../models/FitTime";
import {DrawingType, UnitsType} from "../../../../models/enums";
import EditLabourCell from "./EditLabourCell";
import EditFtmCell from "./EditFtmCell";
import {useUserSettingsDataContext} from "../../../../providers/UserSettingsProvider";
import {formatDeduction, formatMarkupQty, formatValue} from "../../../../utils/SummaryUtils";
import {useDrawingDetailsDataContext} from "../../providers/DrawingDetailsProvider";

export interface IPlanSummaryItemsTableProps {
	items: PlanSummaryItem[],
	fitTimes: FitTime[],
	onUpdate: () => void
}

const PlanSummaryItemsTable: React.FC<IPlanSummaryItemsTableProps> = ({items, fitTimes, onUpdate}) => {
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext();
	const isDrawingTakeoffType = drawingDetails?.drawingType === DrawingType.TAKE_OFF;
	const {t} = useTranslation();
	const {settings} = useUserSettingsDataContext()
	const unitType = settings?.measurementSystem ?? UnitsType.METRIC

	const columns: TableColumnConfig<PlanSummaryItem>[] = [
		{
			id: "partNumber",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.partNumber"),
			sortable: false,
			width: 240
		},
		{
			id: "name",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.name"),
			sortable: false,
			width: 450
		},
		isDrawingTakeoffType ? {
			id: "laborTime",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.labour"),
			sortable: false,
			width: 120
		} : undefined,
		isDrawingTakeoffType ? {
			id: "fitTimeId",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.fitTimeMultiplier"),
			sortable: false,
			width: 120
		} : undefined,
		{
			id: "markupQty",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.markupQty"),
			sortable: false,
			width: 120
		},
		{
			id: "deduction",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.deductions"),
			sortable: false,
			width: 120
		},
		{
			id: "value",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.quantity"),
			sortable: false,
			width: 60,
		},
	].filter(Boolean) as TableColumnConfig<PlanSummaryItem>[];

	return (
		<div className="drawing-summary-items-table_container">
			<table className="drawing-summary-items-table">
				<thead>
				<tr>
					{columns.map(col =>
						<TableHeaderCell<PlanSummaryItem> key={col.id} id={col.id} label={col.label} width={col.width}/>
					)}
				</tr>
				</thead>
				<tbody>
				{items.length ?
					items.map(item => {
						return (
							<tr key={item.id}>
								<td className={"break-word"}>{item.partNumber}</td>
								<td className={"break-word"}>{item.name}</td>
								{isDrawingTakeoffType &&
									<>
										<td>
											<EditLabourCell item={item} onSuccess={onUpdate}/>
										</td>
										<td>
											<EditFtmCell item={item} onSuccess={onUpdate} fitTimes={fitTimes}/>
										</td>
									</>}
								<td>{formatMarkupQty(item, unitType)}</td>
								<td>{formatDeduction(item, unitType)}</td>
								<td>{formatValue(item, unitType)}</td>
							</tr>
						)
					}) :
					<TableNoRecords cols={columns.length}/>
				}
				</tbody>
			</table>
		</div>
	);
};

export default PlanSummaryItemsTable;
