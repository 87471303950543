import {useState} from "react";
import {useIsMounted} from "./useIsMounted";

export function useMountedState<T>(initialState: T): [T, (state: T) => void] {
	const [state, setState] = useState<T>(initialState);
	const {isMounted} = useIsMounted()

	function setMountedState(state: T) {
		if (isMounted()) {
			setState(state)
		}
	}

	return [state, setMountedState]
}