import React from "react";
import "./filePreviewModal.scss";
import {FileType} from "../../../../../models/enums";
import {useTranslation} from "react-i18next";
import JpgFilePreview from "./JpgFilePreview";
import PdfPreview from "./PdfFilePreview";
import ModalWithHeader from "../../../../../components/modal/ModalWithHeader";

export type FileData = {
	attachmentId: string,
	filename: string,
	fileType?: FileType
}

type FilePreviewModalProps = {
	onClose: () => void,
	fileData: FileData
}

const FilePreviewModal: React.FC<FilePreviewModalProps> = ({onClose, fileData}) => {

	const {t} = useTranslation();
	const {attachmentId, filename, fileType} = fileData

	const renderPreview = () => {
		switch (fileType) {
			case FileType.JPEG:
			case FileType.JPG:
			case FileType.PNG:
			case FileType.GIF:
			case FileType.BMP:
				return <JpgFilePreview attachmentId={attachmentId} filename={filename}/>;
			case FileType.PDF:
				return <PdfPreview attachmentId={attachmentId} filename={filename}/>;
			default:
				return (
					<div className="file-preview-modal_error">
						{t("projects.details.planTemplates.unsupportedDrawingVersion")}
					</div>
				);
		}
	};

	return (
		<ModalWithHeader isOpen={true} title={filename} onClose={onClose}>
			{renderPreview()}
		</ModalWithHeader>
	);
};

export default FilePreviewModal;
