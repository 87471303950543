import React from "react";
import "./spinner.scss";
import InlineSpinner from "./InlineSpinner";

interface IProps {
	customSize?: number;
}

const Spinner: React.FC<IProps> = ({customSize = 40}) => {

	return (
		<div className="spinner">
			<InlineSpinner width={customSize} height={customSize}/>
		</div>
	);
};

export default Spinner
