import React from "react";
import Tooltip from "../tooltip/Tooltip";

interface IconWithTooltipProps {
	icon: JSX.Element;
	tooltipText: string;
}

const IconWithTooltip: React.FC<IconWithTooltipProps> = ({icon, tooltipText}) => {
	return (
		<Tooltip tooltipText={tooltipText}>
			<div>
				{icon}
			</div>
		</Tooltip>
	);
};

export default IconWithTooltip