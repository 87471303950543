import React, {useState} from "react";
import {DrawingVersion} from "../../../models/DrawingVersion";
import UserSummary from "../../../models/UserSummary";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../components/modal/ModalButtons";
import Button from "../../../components/ui/Button";
import "./versionsModal.scss";
import {TableColumnConfig} from "../../../models/interfaces";
import TableHeaderCell from "../../../components/layout/table/TableHeaderCell";
import DatePresenter from "../../../components/ui/DatePresenter";
import Dropdown from "../../../components/ui/Dropdown";
import DropdownItem from "../../../components/ui/DropdownItem";
import {get} from "../../../utils/ClassNameUtils";
import {VersionLabel} from "../../../models/enums";

export function VersionsModal({
	onClose, currentVersionId, versions, users, onLoad, openCopyDrawingVersionModal, openRevertDrawingVersionModal
}: {
	onClose: () => void,
	currentVersionId: string,
	versions: DrawingVersion[]
	users: UserSummary[]
	onLoad: (selectedVersionId: string) => void,
	openCopyDrawingVersionModal: (drawingVersion: DrawingVersion) => void
	openRevertDrawingVersionModal: (drawingVersion: DrawingVersion) => void
}) {
	const {t} = useTranslation();
	const columns: TableColumnConfig<DrawingVersion & { options: boolean }>[] = [{
		id: "version",
		label: t("editor.versionsModal.columns.version"),
		sortable: false,
	}, {
		id: "modifiedAt",
		label: t("editor.versionsModal.columns.lastSaved"),
		sortable: false,
	}, {
		id: "modifiedBy",
		label: t("editor.versionsModal.columns.modifiedBy"),
		sortable: false,
	}, {
		id: "locked",
		label: t("editor.versionsModal.columns.locked"),
		sortable: false,
	}, {
		id: "labels",
		label: t("editor.versionsModal.columns.status"),
		sortable: false,
	}, {
		id: "options",
		label: "",
		sortable: false,
		width: 37,
	}]

	const [selectedVersionId, setSelectedVersionId] = useState<string>(currentVersionId);

	function loadSelectedVersion() {
		onLoad(selectedVersionId)
	}

	return (
		<ModalWithHeader
			isOpen={true}
			title={t("common.versions")}
			onClose={onClose}
			customClass={"versions-modal"}
		>
			<div className="versions-modal_content">
				<div className="versions-modal_content_table-container">
					<table className="table">
						<thead>
						<tr>
							{columns.map(col => (
								<TableHeaderCell
									key={col.id}
									id={col.id}
									label={col.label}
									sortable={col.sortable}
									width={col.width}
								/>
							))}
						</tr>
						</thead>
						<tbody>
						{versions.map(version => (
							<tr
								key={version.id}
								className={get({"is-checked": version.id === selectedVersionId})}
								onClick={() => {setSelectedVersionId(version.id)}}
							>
								<td className={get({"subversion": version.version.minor !== 0})}>
									{version.version.getVersionLabel()}
								</td>
								<td><DatePresenter date={version.modifiedAt}/></td>
								<td>{users.find(user => user.id === version.modifiedBy)?.name}</td>
								<td>{version.locked ? t("common.locked") : null}</td>
								<td>{version.formattedLabels}</td>
								<td>
									<Dropdown>
										<DropdownItem
											label={t("common.menus.copy")}
											onClick={() => { openCopyDrawingVersionModal(version); }}
										/>
										{version.labels.includes(VersionLabel.REVERTABLE) ? (
											<DropdownItem
												label={t("common.menus.revert")}
												onClick={() => { openRevertDrawingVersionModal(version); }}
											/>
										) : null}
									</Dropdown>
								</td>
							</tr>
						))}
						</tbody>
					</table>
				</div>
				<ModalButtons>
					<Button
						label={t("common.buttons.cancel")}
						onClick={onClose}
						variant={"optional"}
					/>
					<Button
						label={t("editor.versionsModal.loadButtonLabel")}
						onClick={loadSelectedVersion}
						variant={"primary"}
						disabled={!selectedVersionId}
					/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	)
}