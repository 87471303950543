import React, {useRef, useState} from 'react';
import {Group, Rect} from "react-konva";
import {KonvaEventObject} from "konva/types/Node";
import {useSelector} from "react-redux";
import {Vector2d} from "konva/types/types";
import {getEditorLine} from "../../utils";
import {useSnapToPoint} from "../length/snapUtils";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {EditorLine, EditorPoint} from "../../models/editor";
import {RulerPoint} from "./RulerPoint";
import {selectSnappingState} from "../view/viewSlice";
import {RulerElement} from "./RulerElement";
import {selectStageConfig} from "../config/configSlice";
import {getId} from "../../../../../../utils";
import {KonvaEventType} from "../../../../../base-konva/types";
import {getPointerPosition, getSnappedPosition} from "../../../../../base-konva/utils";
import {CursorStyle} from "../../../../../base-konva/enums";
import {selectScale} from "../scale/scaleSlice";

type LineDragState = {
	dragStart: Vector2d,
	points: EditorPoint[],
}
const rulerLineId = getId();
const rulerFromId = getId();
const rulerToId = getId();

type RulerLayerProps = {
	listening?: boolean
}
export const RulerLayer: React.FC<RulerLayerProps> = ({listening = false}) => {

	const {snapToPoint} = useSnapToPoint()
	const [rulerPoints, setRulerPoints] = useState<EditorPoint[]>([]);
	const [pointerLocation, setPointerLocation] = useState<Vector2d | null>(null);
	const snapping = useSelector(selectSnappingState);
	const stageConfig = useSelector(selectStageConfig)
	const {changeCursor} = useCursorStyle();
	const elementDragState = useRef<LineDragState>({dragStart: {x: 0, y: 0}, points: []});
	const line = getRulerLine();
	const scale = useSelector(selectScale);

	function getRulerLine(): EditorLine | null {
		let line: EditorLine | null = null;
		if (rulerPoints.length === 1 && pointerLocation) {
			const from: EditorPoint = {id: rulerFromId, position: rulerPoints[0].position}
			const to: EditorPoint = {id: rulerToId, position: pointerLocation}
			line = getEditorLine(rulerLineId, from, to);
		}
		else if (rulerPoints.length === 2) {
			const from: EditorPoint = {id: rulerFromId, position: rulerPoints[0].position}
			const to: EditorPoint = {id: rulerToId, position: rulerPoints[1].position}
			line = getEditorLine(rulerLineId, from, to);
		}
		return line;
	}

	function handleMouseDown(event: KonvaEventObject<KonvaEventType>) {
		const position = getPointerPosition(event)
		if (position) {
			if (rulerPoints.length === 0) {
				setRulerPoints([{id: rulerFromId, position}])
			}
			if (rulerPoints.length === 1) {
				setRulerPoints(prevState => [
					...prevState,
					{
						id: rulerToId,
						position: snapping.point ? snapToPoint(position).snapTo : snapping.angle
							? getSnappedPosition(rulerPoints[0].position, position)
							: position
					}]);
				changeCursor({event, cursor: CursorStyle.DEFAULT})
			}
		}
	}

	function handleMouseMove(event: KonvaEventObject<MouseEvent>) {
		if (rulerPoints.length < 2) {
			changeCursor({event, cursor: CursorStyle.CROSSHAIR})
		}
		const position = getPointerPosition(event)
		if (position) {
			if (rulerPoints.length === 0) {
				setPointerLocation(position);
			}
			if (rulerPoints.length === 1) {
				setPointerLocation(snapping.point ? snapToPoint(position).snapTo : snapping.angle
					? getSnappedPosition(rulerPoints[0].position, position)
					: position)
			}
		}
	}

	function renderRulerPoints() {
		return rulerPoints.map(({id, position}) => (
			<RulerPoint
				key={id}
				center={position}
				angle={line?.angle ?? 0}
				onDragMove={(evt => {
					let currentPosition: Vector2d = position;
					const pointer = evt.target.position();
					setRulerPoints(
						prevState => {
							if (prevState.length < 2) {
								return prevState;
							}
							const [point1, point2] = prevState;
							if (point1.id === id) {
								currentPosition = snapping.point
									? snapToPoint(pointer).snapTo
									: snapping.angle
										? getSnappedPosition(point2.position, pointer)
										: pointer;
								return [{...point1, position: currentPosition}, point2];
							}
							else {
								currentPosition = snapping.point
									? snapToPoint(pointer).snapTo
									: snapping.angle
										? getSnappedPosition(point1.position, pointer)
										: pointer;
								return [point1, {...point2, position: currentPosition}];
							}
						}
					)
					return currentPosition;
				})}
			/>
		))
	}

	function handleRulerElementDragStart(evt: KonvaEventObject<DragEvent>) {
		const position = getPointerPosition(evt)
		if (position) {
			elementDragState.current = {
				dragStart: position,
				points: rulerPoints
			}
		}
	}

	function handleRulerElementDragMove(evt: KonvaEventObject<DragEvent>) {
		const position = getPointerPosition(evt)
		if (position) {
			const delta = {
				x: position.x - elementDragState.current.dragStart.x,
				y: position.y - elementDragState.current.dragStart.y,
			}
			setRulerPoints(elementDragState.current.points.map(point => ({
				...point,
				position: {
					x: point.position.x + delta.x,
					y: point.position.y + delta.y,
				}
			})))
			evt.target.x(0)
			evt.target.y(0)
		}
	}

	return (
		<Group listening={listening} onMouseDown={handleMouseDown} onTouchStart={handleMouseDown} onMouseMove={handleMouseMove}>
			<Rect name={"background_rect"} width={stageConfig.width} height={stageConfig.height}/>
			{line && scale ?
				<RulerElement
					line={line}
					scale={scale}
					onDragStart={handleRulerElementDragStart}
					onDragMove={handleRulerElementDragMove}
				/> : null}
			{renderRulerPoints()}
		</Group>
	);
};
