import {useTranslation} from "react-i18next";
import React from "react";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import Modal from "../../../../../../components/modal/Modal";
import "./confirmCancelModal.scss";
import IconClose from "../../../../../../components/icons/IconClose";
import {useDispatch} from "react-redux";
import {configActions} from "../config/configSlice";
import {Colors} from "../../../../../../styles";

type SymbolRecognitionCancelModalProps = {
	onClose: () => void
}


const SymbolRecognitionCancelModal: React.FC<SymbolRecognitionCancelModalProps> = ({onClose}) => {
	const {t} = useTranslation()
	const dispatch = useDispatch();

	const onConfirm = () => {
		onClose();
		dispatch(configActions.switchEditorToolBack({}))
	}
	return (
		<Modal customClass={"confirm-cancel-modal"} isOpen={true}
			   onClose={onClose}>
			<div className={"confirm-cancel-modal_header"}>
				{t("editor.symbolRecognitionPanel.matchDisplayScreen.closeModal.title")}
				<span className={"confirm-cancel-modal_header_icon"} onClick={onClose}>
					<IconClose size={28} color={Colors.GREY}/>
				</span>
			</div>
			<div className={"confirm-cancel-modal_body"}>
				{t("editor.symbolRecognitionPanel.matchDisplayScreen.closeModal.message1")}
				<br/>
				{t("editor.symbolRecognitionPanel.matchDisplayScreen.closeModal.message2")}
				<br/>
				<br/>
				{t("editor.symbolRecognitionPanel.matchDisplayScreen.closeModal.question")}
			</div>
			<ModalButtons>
				<Button label={t("common.buttons.back")} variant={"optional"} onClick={onClose}/>
				<Button label={t("common.buttons.confirm")} variant={"secondary"} onClick={onConfirm}/>
			</ModalButtons>
		</Modal>
	);
}

export default SymbolRecognitionCancelModal;