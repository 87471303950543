import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorB: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M11.104,4l0,12.604l-3.85,-3.844l-1.254,1.252l6,5.988l6,-5.988l-1.254,-1.252l-3.85,3.844l-0,-12.604l-1.792,0Z"]}/>
	);
};

export default AnchorB
