import React, {useEffect, useRef, useState} from "react";
import {Group, Line, Rect, Text} from "react-konva";
import {EditorLine, RulerScalePoint} from "../../models/editor";
import {RULER_ELEMENT_HEIGHT, RULER_SCALE_MARK_HEIGHT, RULER_SCALE_MARK_WIDTH} from "../../constants";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {KonvaEventObject} from "konva/types/Node";
import Konva from "konva";
import {formatLength} from "../../../../../../utils/TextUtils";
import {useSelector} from "react-redux";
import {selectRulerStyle} from "../view/viewSlice";
import {UnitsType} from "../../../../../../models/enums";
import {feetToMeters} from "../../../../../../utils/ConversionUtils";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";
import {getId} from "../../../../../../utils";
import {CursorStyle} from "../../../../../base-konva/enums";

type RulerElementProps = {
	line: EditorLine,
	scale: number,
	onDragStart: (evt: KonvaEventObject<DragEvent>) => void,
	onDragMove: (evt: KonvaEventObject<DragEvent>) => void
}
export const RulerElement: React.FC<RulerElementProps> = ({
	line, scale, onDragStart, onDragMove
}) => {

	const {settings} = useUserSettingsDataContext();
	const [rulerScalePoints, setRulerScalePoints] = useState<RulerScalePoint[]>([]);
	const unitFactor = settings?.measurementSystem === UnitsType.METRIC ? 1 : feetToMeters(1)!;
	const unitPx = unitFactor / scale;
	const {changeCursor} = useCursorStyle();
	const rulerStyle = useSelector(selectRulerStyle);
	const totalLength = formatLength(line.distance * scale, settings?.measurementSystem);
	const textStyle: Konva.TextConfig = {
		fill: rulerStyle.color,
		fontStyle: "bold",
	}
	const textRef = useRef(new Konva.Text(textStyle)) // Text node for calculating width of given text

	useEffect(() => {
		const points: RulerScalePoint[] = [];
		let pointPosition = unitPx / 2;
		let half = true;
		let meters = 1;

		while (pointPosition < line.distance) {
			points.push({
				id: getId(),
				xPosition: pointPosition,
				half,
				text: half ? undefined : meters.toString(),
			})
			if (!half) {
				meters += 1;
			}
			half = !half;
			pointPosition += (unitPx / 2);
		}
		setRulerScalePoints(points);
	}, [line.distance, unitPx])

	function renderBackgroundRect() {
		return <Rect
			width={line.distance}
			height={RULER_ELEMENT_HEIGHT}
			stroke={rulerStyle.color}
			fill={rulerStyle.backgroundColor}
			draggable={true}
			onMouseEnter={evt => {
				changeCursor({event: evt, cursor: CursorStyle.MOVE})
			}}
			onMouseLeave={evt => {
				changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
			}}
			onDragStart={evt => {
				changeCursor({event: evt, cursor: CursorStyle.MOVE})
				onDragStart(evt)
			}}
			onDragMove={onDragMove}
			onDragEnd={evt => {
				changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
			}}
		/>
	}

	function renderScalePoints() {
		return rulerScalePoints.map(
			({id, xPosition, half, text}) => {
				const textWidth = textRef.current.measureSize(text || "").width;
				const showScaleText = !half && xPosition + textWidth < line.distance;
				const linePoints = [
					xPosition, 0,
					xPosition, RULER_SCALE_MARK_HEIGHT * (half ? 0.5 : 1)
				];
				return <React.Fragment key={id}>
					{showScaleText ? <Text
						text={text}
						{...textStyle}
						x={xPosition}
						y={RULER_SCALE_MARK_HEIGHT + 5}
						offsetX={textWidth / 2}
						listening={false}/> : null}
					<Line
						listening={false}
						points={linePoints}
						strokeWidth={RULER_SCALE_MARK_WIDTH}
						stroke={rulerStyle.color}
					/>
				</React.Fragment>;
			}
		)
	}

	function renderTotalDistance() {
		return <Text
			{...textStyle}
			text={totalLength}
			height={RULER_ELEMENT_HEIGHT - 1}
			width={line.distance - 5}
			align={"right"}
			fontSize={16}
			verticalAlign={"bottom"}
			listening={false}
		/>
	}

	return (
		<Group
			x={line.from.position.x}
			y={line.from.position.y}
			width={line.distance}
			height={RULER_ELEMENT_HEIGHT}
			rotation={line.angle}>

			{renderBackgroundRect()}
			{renderScalePoints()}
			{renderTotalDistance()}

		</Group>
	)
}