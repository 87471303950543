import React, {useState} from "react";
import "./editableCell.scss";
import EditableContentLabel from "../../../../components/layout/table/EditableContentLabel";
import IconAccept from "../../features/editor/icons/IconAccept";
import IconClose from "../../../../components/icons/IconClose";
import {Colors} from "../../../../styles";

interface EditLabourInputProps {
	label: string;
	onSave: () => void;
	isSaving: boolean;
	isNotEditable?: boolean;
}

const EditableCell: React.FC<EditLabourInputProps> = ({label, onSave, isSaving, isNotEditable, children}) => {
	const [editMode, setEditMode] = useState(false);

	const onSubmit = () => {
		if (isSaving) return;
		onSave();
		setEditMode(false);
	};

	return (
		editMode ?
			<div className="editable-cell">
				{children}
				<span className="editable-cell_action-button submit" onClick={onSubmit}>
					<IconAccept/>
				</span>
				<span className="editable-cell_action-button cancel" onClick={() => setEditMode(false)}>
					<IconClose color={Colors.WHITE}/>
				</span>
			</div>
			: <EditableContentLabel label={label} onClick={() => setEditMode(true)} isNotEditable={isNotEditable}/>

	);
};

export default EditableCell;
