import React from "react";
import {useTranslation} from "react-i18next";
import IconClose from "../../../../../../components/icons/IconClose";
import SymbolRecognitionPreview from "./SymbolRecognitionPreview";
import "./symbolRecognitionSelectionPanel.scss"
import {Colors} from "../../../../../../styles";
import {SelectionState} from "../../../../../base-konva/types";

export const InfoWithSelectionPanel = ({
	title,
	description,
	buttons,
	handleCloseIconClick,
	templateData,
}: {
	title: React.ReactNode
	description: React.ReactNode
	buttons: React.ReactNode
	handleCloseIconClick: () => void
	templateData?: SelectionState
}) => {
	const {t} = useTranslation();
	return (
		<div className={"symbol-recognition-selection-panel"}>
			<div className={"symbol-recognition-selection-panel_header"}>
				<div className={"symbol-recognition-selection-panel_header_title"}>
					{title}
				</div>
				<div className={"symbol-recognition-selection-panel_header_icon-container"}
					 onClick={handleCloseIconClick}>
					<IconClose size={28} color={Colors.GREY}/>
				</div>
			</div>
			<div className={"symbol-recognition-selection-panel_description"}>
				{description}
			</div>
			{templateData && <>
				<div className={"symbol-recognition-selection-panel_select-section"}>
					{t("editor.symbolRecognitionPanel.symbolSelectionScreen.selectionLabel")}
					<div className={"symbol-recognition-selection-panel_select-section_preview-container"}>
						<SymbolRecognitionPreview templateData={templateData}/>
					</div>
				</div>
				<div className={"symbol-recognition-selection-panel_buttons"}>
					{buttons}
				</div>
			</>}
		</div>
	)
}