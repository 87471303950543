import {MeasurementStyle} from "../../../../../../../models/interfaces";

export enum Level {
	ROOT = "ROOT",
	GROUP = "GROUP",
	SUBGROUP = "SUBGROUP",
	TEMPLATE = "TEMPLATE"
}

export enum TemplateView {
	BROWSER = "BROWSER",
	ITEM_PRESENTER = "ITEM_PRESENTER"
}

export type RenderStyleSelectorHandler = (style: MeasurementStyle, onChange: (setStyle: MeasurementStyle) => void) => JSX.Element

export type Breadcrumb = {
	id?: number,
	name: string,
	level: Level
}
