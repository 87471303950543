import {AreaMeasurement, CountMeasurement, LengthMeasurement} from "../features/project-drawings/features/editor/models/editor";
import {EditorTool, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import {AreaHelper} from "../features/project-drawings/features/editor/features/area/AreaHelper";
import LengthHelper from "../features/project-drawings/features/editor/features/length/LengthHelper";
import {formatArea, formatLength, formatVolume} from "./TextUtils";
import {UnitsType} from "../models/enums";
import {MaterialAddableTool} from "../models/interfaces";

export function sumUpMeasurements(measurements: (CountMeasurement | LengthMeasurement | AreaMeasurement)[],
	scale: number | null, unitsType: UnitsType | undefined): string {
	const type = measurements[0]?.type; // whole array will be one type - same as pinned
	if (type === MeasurementType.COUNT) {
		return measurements.map(item => (item as CountMeasurement).countItems.length).reduce((a, b) => a + b, 0).toString();
	}
	if (type === MeasurementType.AREA || type === MeasurementType.VOLUME) {
		const sum = measurements.map(item => AreaHelper.getAreaValue(item as AreaMeasurement, scale)).reduce((a, b) => a + b,
			0);
		return type === MeasurementType.AREA ? formatArea(sum, unitsType) : formatVolume(sum, unitsType);
	}
	if (type === MeasurementType.LENGTH) {
		const sum = measurements.map(item => LengthHelper.getTotalLength(item as LengthMeasurement, scale) ?? 0).reduce(
			(a, b) => a + b, 0);
		return formatLength(sum, unitsType);
	}
	return "-";
}

export function toMaterialAddableTool(measurementType: MeasurementType): MaterialAddableTool {
	switch (measurementType) {
		case MeasurementType.COUNT:
			return EditorTool.COUNT
		case MeasurementType.LENGTH:
			return EditorTool.LENGTH
		case MeasurementType.AREA:
			return EditorTool.AREA
		case MeasurementType.VOLUME:
			return EditorTool.VOLUME
	}
}

export function toMeasurementType(materialAddableTool: MaterialAddableTool): MeasurementType {
	switch (materialAddableTool) {
		case EditorTool.COUNT:
			return MeasurementType.COUNT
		case EditorTool.LENGTH:
			return MeasurementType.LENGTH
		case EditorTool.AREA:
			return MeasurementType.AREA
		case EditorTool.VOLUME:
			return MeasurementType.VOLUME
	}
}

