import {useEffect, useRef, useState} from "react";

export function useStateWithCallback<T>(initialValue: T): [T, (newValue: T, callback?: () => void) => void] {
	const [state, setState] = useState<T>(initialValue);

	const callbackRef = useRef<(() => void) | undefined>(undefined);

	useEffect(() => {
		if (callbackRef.current) {
			callbackRef.current();
			callbackRef.current = undefined;
		}
	}, [state]);

	const setValueWithCallback = (newValue: T, callback?: (() => void)) => {
		callbackRef.current = callback;
		setState(newValue);
	};

	return [state, setValueWithCallback]
}