import React, {useState} from "react";
import Modal from "../../../../../../components/modal/Modal";
import ModalTitle from "../../../../../../components/modal/ModalTitle";
import AttachmentApi from "../../../../../../api/AttachmentApi";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import {useTranslation} from "react-i18next";
import {Status} from "../../../../../../models/enums";
import Spinner from "../../../../../../components/Spinner";
import "./previewImageModal.scss";

interface PreviewImageModalProps {
	previewImageId: string,
	previewImageName: string,
	onClose: () => void,
}

const PreviewImageModal: React.FC<PreviewImageModalProps> = ({previewImageId, previewImageName, onClose}) => {

	const {t} = useTranslation();
	const [loadStatus, setLoadStatus] = useState(Status.LOADING);

	return (
		<Modal isOpen={previewImageId !== undefined} onClose={onClose}>
			<ModalTitle>{t("editor.imagePreviewModal.title")}</ModalTitle>
			<div className={"image-preview-modal_content"}>
				<img src={`${AttachmentApi.getAttachmentDownloadUrl(previewImageId, previewImageName)}`} alt={""}
					 onLoad={() => setLoadStatus(Status.SUCCESS)}
					 onError={() => setLoadStatus(Status.ERROR)}/>
				{loadStatus === Status.LOADING ? <Spinner/> : null}
				{loadStatus === Status.ERROR ? (
					<div className={"image-preview-modal_content_error"}>
						{t("editor.imagePreviewModal.previewLoadError")}
					</div>
				) : null}
				<div
					className={"image-preview-modal_content_preview"}
					style={{
						position: "relative",
						width: "100%",
						height: 340,
						background: `url('${AttachmentApi.getAttachmentDownloadUrl(previewImageId, previewImageName)}')`,
						backgroundSize: "contain",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
					}}
				/>
			</div>
			<ModalButtons>
				<Button label={t("common.buttons.close")} variant={"optional"} onClick={onClose}/>
			</ModalButtons>
		</Modal>
	);
};

export default PreviewImageModal
