import {AreaStyle, CountMeasurementStyle} from "./models/editor";
import Konva from "konva";
import {SymbolType} from "./icons/symbol_pack/default";
import {FileType} from "../../../../models/enums";
import {Colors} from "../../../../styles";
import {DashPatternType, LineStyle, TextToolStyle} from "../../../base-konva/types";
import {PageSizeFormat, TextAlign, TextStyle} from "../../../base-konva/enums";
import {scaleLineDefaultStyle, scalePointLinesDefaultStyle} from "../../../base-konva/constants";

export const DRAWING_SCHEMA_VERSION = 4;

export const MAX_SCALE = 10;

export const DEFAULT_BLANK_STAGE_WIDTH = 1024;
export const DEFAULT_BLANK_STAGE_HEIGHT = 768;

export const LINE_POINT_RECT_NAME = "LINE_POINT_RECT";
export const LINE_POINT_RECT_SIZE = 10;
export const ARC_LINE_TENSION = 0.5
// Minimum length in pixels of free pen tool segment.
export const PEN_TOOL_THRESHOLD = 3;

export const LONG_TOUCH_DURATION = 500;
export const LONG_TOUCH_DISTANCE_THRESHOLD = 20;

// Values based on context menu css styles.
export const CONTEXT_MENU_ITEM_WIDTH = 150;
export const CONTEXT_MENU_ITEM_HEIGHT = 38;

// Verification cookie name to check if backend can set cookies properly.
export const SESSION_COOKIE_NAME = "TAKEOFFS_COOKIE";

export const DOUBLE_CLICK_DELAY = 300;

export const TIME_5_MIN_IN_MILLISECONDS = 5 * 60 * 1000

export const INITIAL_VERSION_ID = "initial_version"

export const rulerPointLinesDefaultStyle = scalePointLinesDefaultStyle;

export const rulerLineDefaultStyle = scaleLineDefaultStyle;

export const RULER_ELEMENT_HEIGHT = 50;
export const RULER_SCALE_MARK_HEIGHT = 10;
export const RULER_SCALE_MARK_WIDTH = 1;
export const POINT_SNAPPING_DISTANCE = 20;

export const textToolDefaultStyle: TextToolStyle = {
	fontFamily: "Arial",
	fontSize: 20,
	fontStyle: TextStyle.NORMAL,
	align: TextAlign.LEFT,
	fill: Colors.SIMPROBLUE,
	backgroundFill: Colors.TRANSPARENT,
}

export const TEXT_TOOL_DEFAULT_PADDING = 3;
export const TEXT_TOOL_TEXTAREA_DEFAULT_PADDING = 5;

export const MAX_MINOR_VERSION_TO_DISPLAY = 10;
//How often call to backend for session refresh, in minutes
export const USER_REFRESH_INTERVAL = 10;

export const dashPatterConfig: {
	[key: string]: {
		optionDescription: string
		konvaDashArray: number[]
	}
} = {
	[DashPatternType.LINE]: {
		optionDescription: "—————",
		konvaDashArray: []
	},
	[DashPatternType.DOT]: {
		optionDescription: "‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧ ‧",
		konvaDashArray: [0.1, 5]
	},
	[DashPatternType.DASH]: {
		optionDescription: "— — — —",
		konvaDashArray: [10, 5, 10, 5]
	},
	[DashPatternType.DASHDOT]: {
		optionDescription: "— ‧ — ‧ —",
		konvaDashArray: [10, 5, 0.1, 5]
	},
}

export const defaultLineStyle: LineStyle = {
	strokeWidth: 4,
	stroke: Colors.SIMPROBLUE,
	dashType: DashPatternType.LINE,
}

export const closePreviewLineStyle: LineStyle = {
	strokeWidth: 2,
	stroke: Colors.BLACK,
	dashType: DashPatternType.DOT
}

export const defaultAreaStyle: AreaStyle = {
	...defaultLineStyle,
	strokeWidth: 2,
	fill: `${Colors.SIMPROBLUE}55`
}

export const defaultCountStyle: CountMeasurementStyle = {
	color: Konva.Util.getRandomColor(),
	size: 20,
	symbol: SymbolType.CIRCLE,
}

export const defaultMarkStyle = {
	cornerRadius: 10,
	stroke: Colors.SIMPROBLUE,
	strokeWidth: 2,
	fill: Colors.WHITE,
}

export const defaultMarkTextStyle = {
	align: "center",
	verticalAlign: "middle",
	ellipsis: true,
	fill: Colors.BLACK
}

export const mimeTypeMap: Record<FileType, string> = {
	[FileType.JPEG]: "image/jpeg",
	[FileType.JPG]: "image/jpeg",
	[FileType.PNG]: "image/png",
	[FileType.GIF]: "image/gif",
	[FileType.BMP]: "image/bmp",
	[FileType.PDF]: "application/pdf",
}

export const pageSizeMap: Record<PageSizeFormat, { width: number, height: number }> = {
	A0: {width: 0.841, height: 1.189},
	A1: {width: 0.549, height: 0.841},
	A2: {width: 0.420, height: 0.549},
	A3: {width: 0.297, height: 0.420},
	A4: {width: 0.210, height: 0.297},
	A5: {width: 0.148, height: 0.210},
}

