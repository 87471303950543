import ReactDOM from "react-dom";
import {LengthSymbolPreviewGenerator} from "./LengthSymbolPreviewGenerator";
import {CountSymbolPreviewGenerator} from "./CountSymbolPreviewGenerator";
import React from "react";
import {LineStyle} from "../../../../../../base-konva/types";

export function generateLengthSymbolPreviewImageUrl(style: LineStyle): Promise<{ imageUrl: string }> {
	return new Promise<{ imageUrl: string }>((resolve, reject) => {
		const onSuccess = (stageDataUrl: string) => {
			resolve({imageUrl: stageDataUrl})
		}
		try {
			const container = document.createElement("div")
			ReactDOM.render((
				<LengthSymbolPreviewGenerator
					style={style}
					onSuccess={onSuccess}
					onFailure={reject}
				/>
			), container)
		}
		catch (e) {
			reject()
		}
	})
}

export function generateCountSymbolPreviewImageUrl(image: HTMLImageElement, color: string): Promise<{ imageUrl: string }> {
	return new Promise<{ imageUrl: string }>((resolve, reject) => {
		const onSuccess = (stageDataUrl: string) => {
			resolve({imageUrl: stageDataUrl})
		}
		try {
			const container = document.createElement("div")
			ReactDOM.render((
				<CountSymbolPreviewGenerator
					image={image}
					color={color}
					onSuccess={onSuccess}
					onFailure={reject}
				/>
			), container)
		}
		catch (e) {
			reject()
		}
	})
}