import React, {useEffect, useState} from "react";
import Modal from "../../../../../components/modal/Modal";
import "./templatePagesPreviewModal.scss";
import {useTranslation} from "react-i18next";
import IconClose from "../../../../../components/icons/IconClose";
import Button from "../../../../../components/ui/Button";
import InlineSpinner from "../../../../../components/InlineSpinner";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import {Colors} from "../../../../../styles";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {MinMaxValidatedNumberInput} from "../../../../project-drawings/features/editor/components/MinMaxValidatedNumberInput";

interface TemplatePagesPreviewProps {
	onClose: () => void,
	onBackButtonClick: () => void,
	backButtonLabel: string,
	onNext: (selectedPagesNumbers: number[]) => Promise<void>,
	previewFileData: { previewSources: string[], displayedFilename: string },
	unselectablePagesNumbers?: number[],
	dpiValue: number,
	onDpiInputChange: (value: number) => void,
	isTemplatePdf: boolean
}

const TemplatePagesPreview: React.FC<TemplatePagesPreviewProps> = ({
	onClose,
	previewFileData,
	onNext,
	onBackButtonClick,
	backButtonLabel,
	unselectablePagesNumbers,
	dpiValue,
	onDpiInputChange,
	isTemplatePdf
}) => {
	const {t} = useTranslation();
	const {previewSources, displayedFilename} = previewFileData;
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [selectedPageNumbers, setSelectedPageNumbers] = useState<number[]>(previewSources.length === 1 ? [1] : [])
	const [isAnyImageLoaded, setIsAnyImageLoaded] = useState<boolean>(false);

	useEffect(function autoSelectOnlyPage() {
		if (previewSources.length === 1) {
			setSelectedPageNumbers([1])
		}
	}, [previewSources])

	const onPreviewClick = (pageNumber: number, isSelected: boolean) => {
		if (previewSources.length === 1 || unselectablePagesNumbers?.includes(pageNumber)) {
			return;
		}
		if (isSelected) {
			const newPageNumbers = selectedPageNumbers.filter(number => number !== pageNumber)
			setSelectedPageNumbers([...newPageNumbers])
		}
		else {
			setSelectedPageNumbers([...selectedPageNumbers, pageNumber])
		}
	}

	const onImageLoad = () => {
		if (!isAnyImageLoaded) {
			setIsAnyImageLoaded(true)
		}
	}

	const onModalClose = () => {
		if (isAnyImageLoaded)
			onClose();
	}

	const onNextClick = async () => {
		setIsLoading(true)
		try {
			await onNext(selectedPageNumbers)
		}
		catch {
			onClose()
		}
		finally {
			setIsLoading(false)
		}
	}

	const onImageLoadError = (error: React.SyntheticEvent<HTMLImageElement, Event>) => {
		onBackButtonClick();
		LoggerService.logError(error);
		NotificationService.errorNotification(
			t("common.error"),
			t("projects.details.pagesPreviewModal.imageLoadErrorDesc")
		)
	}

	const onClearSelectionClick = () => {
		setSelectedPageNumbers([])
	}

	return (
		<Modal isOpen={true} onClose={onModalClose}
			   customClass={"template-pages-preview-modal"}>
			<div className={"template-pages-preview-modal_header"}>
				{t("projects.details.pagesPreviewModal.title")}
				{previewSources.length > 0 && <span className={"template-pages-preview-modal_header_icon"} onClick={onModalClose}>
					<IconClose size={28} color={Colors.GREY}/>
				</span>}
			</div>
			<div className={"template-pages-preview-modal_content"}>
				{previewSources.length > 0 ? <>
						<div className={"template-pages-preview-modal_content_filename"}>{displayedFilename}</div>
						{isTemplatePdf && <div className={"template-pages-preview-modal_content_dpi-input-container"}>
							<span className={"template-pages-preview-modal_content_dpi-input-container_label"}>{t(
								"projects.details.pagesPreviewModal.dpiInputLabel")}
							</span>
							<MinMaxValidatedNumberInput min={1} max={10000} value={dpiValue} onChange={onDpiInputChange}/>
						</div>}
						<div className={"template-pages-preview-modal_content_line"}/>
						<div className={"template-pages-preview-modal_content_preview"}>
							{previewSources.map((source, index) => {
								const isUnselectable = unselectablePagesNumbers?.includes(index + 1);
								const isSelected = selectedPageNumbers.includes(index + 1) && !isUnselectable;
								const previewContainerCls = [
									"template-pages-preview-modal_content_preview_container",
									previewSources.length > 1 && "multi",
									isSelected && "selected",
									isUnselectable && "unselectable",
									!isAnyImageLoaded && "hidden"
								].filter(Boolean).join(" ");
								return (<div key={source + index} onClick={() => {onPreviewClick(index + 1, isSelected)}}
											 className={previewContainerCls}>
									<img alt={displayedFilename}
										 onError={onImageLoadError}
										 className={"template-pages-preview-modal_content_preview_container_image"}
										 onLoad={onImageLoad}
										 src={source}/>
								</div>)
							})}
							{!isAnyImageLoaded && <InlineSpinner width={40} height={40}/>}
						</div>
						<div className={"template-pages-preview-modal_content_line"}/>
					</> :
					<div className={"template-pages-preview-modal_content_loading"}>
						{t("projects.details.pagesPreviewModal.loadingMessage")}
						<div className={"template-pages-preview-modal_content_loading_spinner"}>
							<InlineSpinner height={40} width={40}/>
						</div>
					</div>}
			</div>
			{
				previewSources.length > 0 && <ModalButtons>
					{isLoading && <InlineSpinner/>}
					{previewSources.length > 1 &&
						<Button onClick={onClearSelectionClick}
								disabled={selectedPageNumbers.length === 0}
								label={t("projects.details.pagesPreviewModal.clearButton")}
								variant={"tertiary"}/>}
					<Button onClick={onBackButtonClick}
							disabled={previewSources.length === 0}
							label={backButtonLabel}
							variant={"optional"}/>
					<Button
						label={t("common.buttons.next")}
						variant={"primary"}
						onClick={onNextClick}
						disabled={selectedPageNumbers.length === 0 || isLoading}
					/>
				</ModalButtons>
			}
		</Modal>
	);
};

export default TemplatePagesPreview;