import React from "react";

const fscreen = require("fscreen").default;

type FullScreenHandle = {
	active: boolean;
	enter: () => Promise<void>;
	exit: () => Promise<void>;
}

/**
 * Reference: https://github.com/snakesilk/react-fullscreen/blob/master/src/index.tsx
 * This implementation toggle fullscreen on document.body,
 * this fixes problems with modals/tooltips/poppers attached to body
 * and not visible when fullscreen is enabled deeper in DOM tree (like before with react-fullscreen lib)
 */
function useFullScreenHandle(): FullScreenHandle {
	const [active, setActive] = React.useState<boolean>(false);
	const node = React.useRef<HTMLElement>(document.body);

	React.useEffect(() => {
		const handleChange = () => {
			setActive(fscreen.fullscreenElement === node.current);
		};
		fscreen.addEventListener('fullscreenchange', handleChange);
		return () => fscreen.removeEventListener('fullscreenchange', handleChange);
	}, []);

	const enter = React.useCallback(() => {
		if (fscreen.fullscreenElement) {
			return fscreen.exitFullscreen().then(() => {
				return fscreen.requestFullscreen(node.current);
			});
		}
		else if (node.current) {
			return fscreen.requestFullscreen(node.current);
		}
	}, []);

	const exit = React.useCallback(() => {
		if (fscreen.fullscreenElement === node.current) {
			return fscreen.exitFullscreen();
		}
		return Promise.resolve();
	}, []);

	return React.useMemo(
		() => ({
			active,
			enter,
			exit,
		}),
		[active, enter, exit],
	);
}


type FullscreenContextType = {
	fullscreenHandle: FullScreenHandle
}

const FullscreenContext = React.createContext<FullscreenContextType | undefined>(undefined)

function FullscreenProvider({children}: { children: React.ReactNode }) {
	const fullscreenHandle = useFullScreenHandle()

	return (
		<FullscreenContext.Provider value={{fullscreenHandle}}>
			{children}
		</FullscreenContext.Provider>
	)
}

function useFullscreenContext() {
	const context = React.useContext(FullscreenContext)
	if (!context) {
		throw new Error("useFullscreenContext must be used within a FullscreenProvider")
	}
	return context
}

export {FullscreenProvider, useFullscreenContext}