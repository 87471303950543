import {Props, useMaterialData} from "../../../hooks/useMaterialData";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {CatalogItem} from "../../../../../../../../../../models/CatalogItem";
import {useTableSelectionState} from "../../../../../../../../../../hooks/useTableSelectionState";
import {useUpdatedRef} from "../../../../../../../../../../hooks/useUpdatedRef";
import {EditorTool, MaterialType} from "../../../../../../models/enums";
import {getDefaultStyle} from "../../../../../../utils";
import {CheckListItem, TableColumnConfig} from "../../../../../../../../../../models/interfaces";
import TableHeaderCell from "../../../../../../../../../../components/layout/table/TableHeaderCell";
import {MaterialItemRow} from "../../../components/MaterialItemRow";
import LoggerService from "../../../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../../../services/NotificationService";
import {countActions} from "../../../../../count/countSlice";
import {lengthActions} from "../../../../../length/lengthSlice";
import {areaActions} from "../../../../../area/areaSlice";
import {useDispatch} from "react-redux";
import {configActions} from "../../../../../config/configSlice";
import {ItemsTableProps} from "../../../components/ItemsTable";

export function useAddItemsData({
	useMaterialItemsHook,
	loadGroupsHook,
	rootGroupLabel,
	type
}: Props & { type: MaterialType.CATALOG | MaterialType.PREBUILD }) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const materialData = useMaterialData({useMaterialItemsHook, loadGroupsHook, rootGroupLabel})

	const hookItems = materialData.itemsTable.items;
	const [items, setItems] = useState<CatalogItem[]>([]);
	const {header, row, reset} = useTableSelectionState<CatalogItem, number>(items)
	const resetRef = useUpdatedRef(reset)

	useEffect(() => {
		const result: CatalogItem[] = []
		for (let hookItem of hookItems) {
			const measurementType = hookItem.measurementType ?? EditorTool.COUNT
			const style = hookItem.style ?? getDefaultStyle(measurementType)
			const item = CatalogItem.createNew({...hookItem, measurementType, style})
			result.push(item)
		}
		setItems(result)
		resetRef.current()
	}, [hookItems, resetRef]);

	function updateItem(id: number, source: CatalogItem) {
		const result: CatalogItem[] = []
		for (let catalogItem of items) {
			let newItem = catalogItem
			if (catalogItem.id === id) {
				newItem = source
			}
			result.push(newItem)
		}
		setItems(result)
	}

	const itemsTable: ItemsTableProps<CatalogItem> = {
		tableRef: materialData.itemsTable.tableRef,
		items,
		columns: [
			{
				id: "checked",
				label: "",
				sortable: false,
				width: 40
			},
			{
				id: "partNumber",
				label: t(`projects.addItemModal.table.columns.partNumber`),
				sortable: false,
				width: "20%"
			},
			{
				id: "name",
				label: t(`projects.addItemModal.table.columns.name`),
				sortable: false,
				width: "20%"
			},
			{
				id: "measurementType",
				label: t("projects.addItemModal.table.columns.type"),
				sortable: false,
				width: "15%"
			},
			{
				id: "style",
				label: t("projects.addItemModal.table.columns.style"),
				sortable: false,
				width: "70%"
			}
		],
		headerTemplate: (col: TableColumnConfig<CheckListItem<CatalogItem>>) => {
			return col.id === "checked" ? (
				<th style={{width: col.width}} key={col.id}>
					<input type={"checkbox"} checked={header.isSelected} onChange={header.toggle}/>
				</th>
			) : (
				<TableHeaderCell id={col.id} label={col.label} width={col.width}/>
			);
		},
		rowTemplate: (item: CatalogItem) => {
			return (
				<MaterialItemRow key={item.id} item={item} row={row} updateItem={updateItem}/>
			);
		}
	}

	const selectedItems = itemsTable.items.filter(item => row.isSelected(item.id))

	const validateVolumeHeights = () => {
		let isError = false;
		const validatedItems: CatalogItem[] = itemsTable.items.map(item => {
			const isHeightEmpty = item.measurementType === EditorTool.VOLUME && (item.height === undefined || item.height === 0)
			if (isHeightEmpty) {
				isError = true;
				return (CatalogItem.createNew({...item, isVolumeHeightError: true}))
			}
			else return item;
		})
		setItems([...validatedItems])
		return isError;
	}

	const clearErrors = () => {
		const clearedItems = [...items].map(item => CatalogItem.createNew({...item, isVolumeHeightError: false}))
		setItems([...clearedItems])
	}

	const contentFooter = {
		canSaveItems: itemsTable.items.length > 0,
		saveItems: (): boolean => {
			if (contentFooter.canSaveItems) {
				if (validateVolumeHeights()) {
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.addItemModal.volumeHeightNotFilledErrorDesc")
					)
					return false;
				}
				try {
					const updateJsonList = itemsTable.items.map(item => item.toUpdateJson())
					materialData.itemsTable.saveAttributes(updateJsonList)
				}
				catch (e) {
					LoggerService.logError(e)
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.addItemModal.saveItemsErrorDesc")
					)
				}
			}
			return true;
		},
		clearErrors: clearErrors,
		canAddItems: selectedItems.length > 0,
		addItems: (): boolean => {
			const saveSucceeded = contentFooter.saveItems();
			if (!saveSucceeded) {
				return false;
			}
			if (contentFooter.canAddItems) {
				let lastEditorTool = EditorTool.COUNT;

				for (let selectedItem of selectedItems) {
					let measurementMaterialAddAction = countActions.addCount

					switch (selectedItem.measurementType) {
						case EditorTool.COUNT:
							lastEditorTool = EditorTool.COUNT
							measurementMaterialAddAction = countActions.addCount
							break;
						case EditorTool.LENGTH:
							lastEditorTool = EditorTool.LENGTH
							measurementMaterialAddAction = lengthActions.addLength
							break;
						case EditorTool.AREA:
							lastEditorTool = EditorTool.AREA
							measurementMaterialAddAction = areaActions.addArea
							break;
						default:
							lastEditorTool = EditorTool.VOLUME
							measurementMaterialAddAction = areaActions.addArea
							break;
					}
					dispatch(measurementMaterialAddAction({
						material: {...selectedItem, type},
						style: selectedItem.style!,
						height: selectedItem.height,
						defaultDrop: selectedItem.defaultDrop
					}))
				}
				dispatch(configActions.switchEditorTool({editorTool: lastEditorTool}))
				reset()
			}
			return true;
		}
	}

	return {...materialData, itemsTable, contentFooter}
}