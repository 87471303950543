import React, {PropsWithChildren} from "react";
import TableNoRecords from "../../../../../../../../components/layout/table/TableNoRecords";
import {CheckListItem, TableColumnConfig} from "../../../../../../../../models/interfaces";

export type ItemsTableProps<T> = {
	items: T[],
	columns: TableColumnConfig<CheckListItem<T>>[]
	headerTemplate: (col: TableColumnConfig<CheckListItem<T>>) => React.ReactNode
	rowTemplate: (item: T) => React.ReactNode
	tableRef: React.MutableRefObject<HTMLTableElement | null>
}
export const ItemsTable = function <T extends { id: (string | number) }, >({
	items,
	columns,
	headerTemplate,
	rowTemplate,
	tableRef
}: PropsWithChildren<ItemsTableProps<T>>) {
	return (
		<div className="material-modal_container_content_table-container items">
			<table className={"table"} ref={tableRef}>
				<thead>
				<tr>
					{columns.map(col =>
						<React.Fragment key={col.id.toString()}>
							{headerTemplate(col)}
						</React.Fragment>
					)}
				</tr>
				</thead>
				<tbody>
				{items.length ?
					items.map(item =>
						<React.Fragment key={item.id}>
							{rowTemplate(item)}
						</React.Fragment>
					)
					: <TableNoRecords cols={columns.length}/>}
				</tbody>
			</table>
		</div>
	);
}