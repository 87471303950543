import React, {CSSProperties, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import Konva from "konva";
import {IRect} from "konva/types/types";
import {useEditorDrawnItemsDataContext} from "../../../project-drawings/features/editor/EditorDrawnItemsDataProvider";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {TextGroup, TextItem, TextToolStyle, ZoomState} from "../../types";
import {ElementSize} from "../../../../models/interfaces";
import {TEXT_TOOL_DEFAULT_PADDING, TEXT_TOOL_TEXTAREA_DEFAULT_PADDING} from "../../../project-drawings/features/editor/constants";
import IconAccept from "../../../project-drawings/features/editor/icons/IconAccept";
import {usePrevious} from "../../../../hooks/usePrevious";
import {TextStyle} from "../../enums";
import {transformKonvaRect} from "../../utils";
import {SaveTextItemActionPayload} from "../../../project-drawings/features/editor/models/editor";

type BaseEditTextContainerProps = {
	whiteCanvasRef: React.RefObject<Konva.Rect>
	group: TextGroup | undefined,
	isTextTool: boolean,
	zoomState: ZoomState,
	style: TextToolStyle,
	deleteTextItem: (id: string) => void,
	saveTextItem: (payload: SaveTextItemActionPayload) => void,
}
export const BaseEditTextContainer: React.FC<BaseEditTextContainerProps> = ({
	whiteCanvasRef,
	group,
	isTextTool,
	zoomState,
	style,
	deleteTextItem,
	saveTextItem,
}) => {
	const editingItem = group?.textItems.find(item => item.isEditing);
	const editTextVisible = Boolean(group && editingItem && isTextTool)
	const [canvasRect, setCanvasRect] = useState<IRect | null>(null);
	const {setEditTextVisible} = useEditorDrawnItemsDataContext();
	const previousGroup = usePrevious(group)
	const [text, setText] = useState("");

	const dataRef = useUpdatedRef({zoomState, saveTextItem, deleteTextItem, setEditTextVisible})

	const handleFinishEditing = useCallback(function(item: TextItem) {
		return function() {
			const {zoomState, saveTextItem, deleteTextItem} = dataRef.current
			if (text !== "") {
				const isNewItem = item.text === ""
				saveTextItem({
					itemId: item.id,
					text: text,
					rotation: isNewItem ? -zoomState.rotation : item.rotation
				})
			}
			else {
				deleteTextItem(item.id)
			}
		}
	}, [text, dataRef]);

	useEffect(() => {
		const canvas = whiteCanvasRef.current
		if (editTextVisible && canvas) {
			const currentClientRect = canvas.getClientRect()
			setCanvasRect(transformKonvaRect(zoomState.rotation, currentClientRect))
		}

	}, [whiteCanvasRef, editTextVisible, zoomState]);

	useEffect(() => {
		const previousEditingItem = previousGroup?.textItems.find(item => item.isEditing);

		if (group?.id !== previousGroup?.id && previousEditingItem) {
			handleFinishEditing(previousEditingItem)();
		}
	}, [group, previousGroup, handleFinishEditing])

	useEffect(() => {
		const {setEditTextVisible} = dataRef.current
		setEditTextVisible(editTextVisible);
	}, [editTextVisible, dataRef]);

	useEffect(function deleteContainerOnToolSwitch() {
		return () => {
			if (editingItem && !isTextTool) {
				deleteTextItem(editingItem.id)
			}
		}
	}, [deleteTextItem, editingItem, isTextTool])

	useEffect(() => {
		if (editingItem) {
			setText(editingItem.text)
		}
	}, [editingItem])

	return (
		<>
			{(editTextVisible && canvasRect) ? (
				<div className={"edit-text-container"}>
					<div style={{
						position: "absolute",
						top: canvasRect.y,
						left: canvasRect.x,
						height: canvasRect.height,
						width: canvasRect.width,
						transform: `rotate(${zoomState.rotation}deg)`,
						transformOrigin: "top left"
					}}>
						<BaseEditText
							style={style}
							zoomState={zoomState}
							text={text}
							onChange={setText}
							finishEditing={handleFinishEditing}
							textItem={editingItem!}
						/>
					</div>
				</div>
			) : null}
		</>
	)
};


type BaseEditTextProps = {
	textItem: TextItem,
	text: string,
	onChange: (value: string) => void,
	finishEditing: (item: TextItem) => () => void,
	zoomState: ZoomState,
	style: TextToolStyle
}
const BaseEditText: React.FC<BaseEditTextProps> = ({textItem, text, onChange, finishEditing, zoomState, style}) => {
	const textRef = useRef<HTMLTextAreaElement | null>(null);
	const sizeReferenceContainerRef = useRef<HTMLDivElement | null>(null);
	const [size, setSize] = useState<ElementSize>({width: 10, height: 10})

	useEffect(() => {
		if (textRef.current) {
			textRef.current.focus()
		}
	}, [textRef]);

	useLayoutEffect(() => {
		if (sizeReferenceContainerRef.current) {
			setSize({
				width: sizeReferenceContainerRef.current.clientWidth + TEXT_TOOL_DEFAULT_PADDING * 2 +
					TEXT_TOOL_TEXTAREA_DEFAULT_PADDING * 2,
				height: sizeReferenceContainerRef.current.clientHeight + TEXT_TOOL_DEFAULT_PADDING * 2 +
					TEXT_TOOL_TEXTAREA_DEFAULT_PADDING * 2,
			})
		}
	}, [text, style]);

	function handleChange(evt: React.ChangeEvent<HTMLTextAreaElement>) {
		onChange(evt.target.value)
	}

	function handleClick(evt: React.MouseEvent<HTMLTextAreaElement, MouseEvent>) {
		evt.preventDefault();
		evt.stopPropagation();
	}

	function moveCaretAtEnd(e: React.FocusEvent<HTMLTextAreaElement>) {
		const temp = e.target.value;
		e.target.value = "";
		e.target.value = temp;
	}

	const position = {
		top: zoomState.scale * textItem.position.y - TEXT_TOOL_TEXTAREA_DEFAULT_PADDING,
		// +2 Fixing horizontal flickering between textarea and canvas Text
		left: zoomState.scale * textItem.position.x - TEXT_TOOL_TEXTAREA_DEFAULT_PADDING + 2,
	}

	const cssStyle = {
		color: style.fill,
		fontSize: zoomState.scale * style.fontSize,
		background: style.backgroundFill,
		fontFamily: style.fontFamily,
		textAlign: style.align,
	}

	// Mapping between css and canvas font styles. "bold" is css fontWeight attribute not fontStyle.
	const fontWeight = style.fontStyle === TextStyle.BOLD ? {fontWeight: "bold"} : {fontWeight: "normal"};
	const fontStyle = style.fontStyle === TextStyle.BOLD ? {fontStyle: "normal"} : {fontStyle: style.fontStyle}

	return <>
		<div
			style={{
				position: 'absolute',
				...position, ...size,
				transform: `rotate(${-zoomState.rotation}deg)`,
				transformOrigin: "top left",
			}}
		>
			<textarea
				ref={textRef}
				value={text}
				onChange={handleChange}
				onClick={handleClick}
				onFocus={moveCaretAtEnd}
				style={{
					position: "absolute",
					...cssStyle,
					...(fontWeight as CSSProperties),
					...(fontStyle as CSSProperties),
					top: 0, left: 0, right: 0, bottom: 0,
				}}
			/>
			<div className={"accept-button"}
				 style={{top: -12, left: -12}}
				 onClick={finishEditing(textItem)}>
				<IconAccept/>
			</div>
		</div>
		<div className={"reference"}
			 style={{...cssStyle, ...(fontWeight as CSSProperties), ...(fontStyle as CSSProperties)}}
			 ref={sizeReferenceContainerRef}>{text}<br/></div>
	</>
}
