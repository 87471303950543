import React from "react";
import IconPenToolPolyLine from "../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconPenToolPolyLine";
import {ButtonsGroup, ButtonsGroupDivider} from "../../../../components/layout";
import LineStyleSelector from "../../../project-drawings/features/editor/components/LineStyleSelector";
import IconPenToolFree from "../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconPenToolFree";
import {LineStyle, PenToolType} from "../../types";

export const BasePenToolOptions: React.FC<{
	penType: PenToolType
	penSelectorStyle: LineStyle
	onStyleSave: (style: LineStyle) => void
	onPenTypeChange: (type: PenToolType) => void
}> = ({
	penType,
	penSelectorStyle,
	onStyleSave,
	onPenTypeChange
}) => {

	function handleStyleSave(style: LineStyle) {
		onStyleSave(style)
	}

	function handlePenTypeChange(type: PenToolType) {
		return () => {onPenTypeChange(type)}
	}

	return (
		<>
			<ButtonsGroup>
				<IconPenToolFree
					onClick={handlePenTypeChange(PenToolType.FREE)}
					active={penType === PenToolType.FREE}
				/>
				<IconPenToolPolyLine
					onClick={handlePenTypeChange(PenToolType.POLYLINE)}
					active={penType === PenToolType.POLYLINE}
				/>
			</ButtonsGroup>
			<ButtonsGroupDivider/>
			<ButtonsGroup>
				<LineStyleSelector
					style={penSelectorStyle}
					onStyleChange={handleStyleSave}
					horizontal={true}
				/>
			</ButtonsGroup>
		</>
	)
}