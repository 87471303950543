import {useDispatch} from "react-redux";
import {lengthActions} from "../features/length/lengthSlice";
import {areaActions} from "../features/area/areaSlice";
import {countActions} from "../features/count/countSlice";
import {penActions} from "../features/pen/penSlice";
import {textActions} from "../features/text/textSlice";
import {imageActions} from "../features/image/imageSlice";

export function useActivate() {
	const dispatch = useDispatch();

	function activateCount(id: string, parentId: string) {
		dispatch(countActions.activateCountItem({id: id, parentId: parentId}))
		dispatch(countActions.activateCountGroup({id: parentId}));
	}

	function activateLength(id: string, actionId?: string) {
		dispatch(lengthActions.activateLength({id: id, actionId: actionId}));
	}

	function activateAreaOrVolume(id: string) {
		dispatch(areaActions.activateArea({id: id}));
	}

	function activatePen(id: string, parentId: string) {
		dispatch(penActions.activatePenLine({id, parentId}))
		dispatch(penActions.activatePenGroup({id: parentId}))
	}

	function activateText(id: string, parentId: string) {
		dispatch(textActions.activateTextItem({id, parentId}))
		dispatch(textActions.activateTextGroup({id: parentId}))
	}

	function activateImage(id: string) {
		dispatch(imageActions.activateImage({id}))
	}

	return {
		activateCount,
		activateLength,
		activateAreaOrVolume,
		activatePen,
		activateText,
		activateImage,
	}
}
