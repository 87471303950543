import React from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import Button from "../../../../../components/ui/Button";

export const SavePlanPageButton: React.FC = () => {

	const {t} = useTranslation()
	const {projectId} = useParams<{ projectId: string }>();
	const {
		planUploadData: {
			savePlanPageData,
			currentEditorPlanPage
		},
		forcePlanTemplatesUpdate
	} = useProjectDetailContext()
	const canSavePlanPage = savePlanPageData.canSavePlanPage(currentEditorPlanPage)

	async function handleSavePlanPage() {
		await savePlanPageData.savePlanPage(
			projectId,
			currentEditorPlanPage,
			() => {
				forcePlanTemplatesUpdate();
			}
		)
	}

	return (
		<Button
			label={t("planUploadEditor.savePlanPage.label")}
			onClick={handleSavePlanPage}
			variant={"primary"}
			disabled={!canSavePlanPage}
		/>
	)
}