import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {LengthPointContextMenuState, RulerStyle, SetDimensionsPayload, ViewState,} from "../../models/editor";
import {RootState} from "../../editorStore";
import {PageScaleRatioPayload, Rotation} from "../../../../../base-konva/types";
import {Colors} from "../../../../../../styles";
import {PageSizeFormat} from "../../../../../base-konva/enums";
import {initialState as baseInitialState, reducers} from "../../../../../base-konva/features/view";

const initialState: ViewState = {
	...baseInitialState,
	snapping: {
		angle: false,
		point: false,
	},
	rulerStyle: {
		color: Colors.GREY,
		backgroundColor: Colors.YELLOW,
	},
	lengthPointContextMenu: {
		visible: false,
		x: 0, y: 0,
		pointId: ""
	},
	guidelines: true,
	drawingLoaded: false,
}

const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		...reducers,
		reset: (_, {payload}: PayloadAction<PageScaleRatioPayload | null>) => ({
			...initialState,
			scaleOptions: {
				ratioInput: payload?.scaleRatio,
				pageSize: payload?.pageFormat ?? PageSizeFormat.A4
			}
		}),
		setDrawingLoaded: (state, {payload}: PayloadAction<boolean>) => {
			state.drawingLoaded = payload
		},
		setSavedZoomStateScale: (state, {payload}: PayloadAction<number | undefined>) => {
			state.zoom.savedScale = payload
		},
		setSavedRotation: (state, {payload}: PayloadAction<Rotation>) => {
			state.zoom.rotation = payload
		},
		setDimensions: (state, {payload}: PayloadAction<SetDimensionsPayload>) => {
			const {viewportHeight, viewportWidth, stageHeight, stageWidth} = payload;

			const minScale = Math.max(100 / stageWidth, 100 / stageHeight)
			const scale = state.zoom.scale < minScale ? minScale : state.zoom.scale;

			state.zoom = {
				...state.zoom,
				scale,
				minScale,
				viewportSize: {
					height: viewportHeight,
					width: viewportWidth,
				}
			}
		},
		changeRulerStyle: (state, {payload}: PayloadAction<RulerStyle>) => {
			state.rulerStyle = payload
		},
		toggleAngleSnapping: (state) => {
			state.snapping.angle = !state.snapping.angle
		},
		togglePointSnapping: (state) => {
			state.snapping.point = !state.snapping.point
		},
		setLengthPointContextMenuState: (state, {payload}: PayloadAction<LengthPointContextMenuState>) => {
			state.lengthPointContextMenu = payload;
		},
		toggleGuidelines: (state) => {
			state.guidelines = !state.guidelines
		},
		resetScaleOptions: (state) => {
			state.scaleOptions = initialState.scaleOptions;
		},
	}
});

export const viewReducer = viewSlice.reducer;
export const viewActions = viewSlice.actions;

export const selectZoomState = (state: RootState) => state.view.zoom;
export const selectDrawingLoaded = (state: RootState) => state.view.drawingLoaded;
export const selectSnappingState = (state: RootState) => state.view.snapping;
export const selectRulerStyle = (state: RootState) => state.view.rulerStyle;
export const selectHighlightState = (state: RootState) => state.view.highlight;
export const selectSelectionState = (state: RootState) => state.view.selection;
export const selectLengthPointContextMenuState = (state: RootState) => state.view.lengthPointContextMenu;
export const selectRemoteContentLoadedInfo = (state: RootState) => state.view.remoteContentLoadedInfo;
export const selectPanInProgress = (state: RootState) => state.view.panInProgress;
export const selectEditorIsDirty = (state: RootState) => state.view.editorIsDirty;
export const selectEditorGuidelines = (state: RootState) => state.view.guidelines;
export const selectScaleOptions = (state: RootState) => state.view.scaleOptions;
export const selectExportInProgress = (state: RootState) => state.view.exportInProgress;
