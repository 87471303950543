import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const IconPenToolFree: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M15.1173149,9.12761755 L16.0949628,10.8723825 L15.4679107,11.2296939 L14.8769096,11.5784959 C14.6858796,11.6933302 14.5008008,11.8067241 14.3216159,11.9186554 L13.801686,12.2500394 C13.6342318,12.3590234 13.4726143,12.4665227 13.3167762,12.572515 L12.8665428,12.8859491 L12.4506423,13.1902089 C12.3176928,13.2900849 12.1904081,13.3884097 12.0687311,13.4851612 L11.7204654,13.7706731 C10.228457,15.0340365 9.74799565,16.006609 10.1381712,16.6338594 C10.325919,16.9356848 10.5498757,17.0518096 11.1201684,16.8938084 L11.3235186,16.8308672 C11.3596899,16.8186444 11.3970366,16.8055453 11.4356118,16.7915546 L11.6822296,16.6967903 C11.7259314,16.6791728 11.7709679,16.6606333 11.8173924,16.6411568 L12.274125,16.4401589 L12.8144242,16.1861045 L13.2253036,15.9860461 L14.1801806,15.5090012 C16.4299786,14.3724703 17.9306022,13.8481806 19.59467,13.8481806 C22.2704138,13.8481806 23.4240366,15.6086086 23.0197319,18.1480408 C22.7522554,19.8280574 21.8038734,21.8901325 20.6345766,23.5596609 L20.397908,23.8881031 L18.791432,22.6967866 C19.8908286,21.2142634 20.8156734,19.2715154 21.044608,17.8335801 C21.2718404,16.4063367 20.9060761,15.8481806 19.59467,15.8481806 C18.4051951,15.8481806 17.2084742,16.2432046 15.4064022,17.1321319 L13.6773735,18.0071408 L13.3727312,18.1552758 C12.723251,18.4655603 12.2011312,18.6750113 11.7117349,18.8058897 C10.3226062,19.1773819 9.17146667,18.8662837 8.43992005,17.6902415 C7.24214916,15.764692 8.36226983,13.7042674 11.5147474,11.398468 L11.9905606,11.0592694 C12.2356882,10.8887815 12.4916223,10.716951 12.7582512,10.5437347 L13.3057354,10.1954413 C13.3993483,10.1370801 13.4941413,10.0785618 13.5901101,10.0198847 L14.1800003,9.66590347 L14.7979126,9.30803424 L15.1173149,9.12761755 Z"]}/>
	);
};

export default IconPenToolFree