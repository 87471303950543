import React from "react";
import Button from "../../../../../../components/ui/Button";
import {selectActiveTool} from "../config/configSlice";
import {useDispatch, useSelector} from "react-redux";
import {areaActions, selectActiveAreaId, selectAreas} from "../area/areaSlice";
import {canCloseArea, canCloseDeduction, canFinishLength} from "../../utils";
import {lengthActions, selectActiveLengthId, selectLengths} from "../length/lengthSlice";
import {useTranslation} from "react-i18next";
import {EditorTool} from "../../models/enums";

export function EditorHeaderFinishShapePanel() {

	const {t} = useTranslation();
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const activeAreaId = useSelector(selectActiveAreaId);
	const activeLengthId = useSelector(selectActiveLengthId);
	const lengths = useSelector(selectLengths);
	const activeLength = lengths.find(length => length.id === activeLengthId);
	const areas = useSelector(selectAreas);
	const activeArea = areas.find(area => area.id === activeAreaId);
	const activeDeduction = activeArea?.deductions.find(deduction => deduction.id === activeArea.activeDeductionId)

	const canFinishLine = Boolean(
		activeLength && canFinishLength(activeLength)
	)
	const canCloseAreaMeasurement = Boolean(
		activeArea && canCloseArea(activeArea)
	)

	const canCloseAreaDeduction = Boolean(
		activeDeduction && canCloseDeduction(activeDeduction)
	)

	function handleFinishLine() {
		dispatch(lengthActions.disableLinePreview())
	}

	function handleCloseArea() {
		activeArea && dispatch(areaActions.closeArea({area: activeArea}));
	}

	function handleCloseDeduction() {
		activeDeduction && activeArea && activeAreaId && activeArea.activeDeductionId &&
		dispatch(areaActions.closeDeduction({deductionId: activeArea.activeDeductionId, areaId: activeAreaId}))
	}

	if (activeTool === EditorTool.LENGTH && canFinishLine) {
		return <Button onClick={handleFinishLine} label={t("editor.length.finishLine")} variant={"primary"}/>
	}

	if ((activeTool === EditorTool.AREA || activeTool === EditorTool.VOLUME) && canCloseAreaMeasurement) {
		return <Button onClick={handleCloseArea} label={t("editor.area.closeArea")} variant={"primary"}/>
	}

	if ((activeTool === EditorTool.AREA || activeTool === EditorTool.VOLUME) && canCloseAreaDeduction) {
		return <Button onClick={handleCloseDeduction} label={t("editor.area.closeArea")} variant={"primary"}/>
	}

	return null;
}