import {useSelector} from "react-redux";
import {Prompt} from "react-router-dom";
import React from "react";
import {selectEditorIsDirty} from "../features/view/viewSlice";
import {PromptModalType} from "../../../../../models/enums";
import {selectEditorLockState} from "../features/config/configSlice";
import {useDrawingDetailsDataContext} from "../../../providers/DrawingDetailsProvider";

export function UnsavedChangesNavigationPrompt() {
	const editorLocked = useSelector(selectEditorLockState);
	const isDirty = useSelector(selectEditorIsDirty);
	const {versionsModalsData} = useDrawingDetailsDataContext()

	const active =
		isDirty
		&& !editorLocked
		&& versionsModalsData.modal?.type !== "unsaved-changes-prompt"

	return (
		<Prompt
			when={active}
			message={PromptModalType.UNSAVED_CHANGES}
		/>
	)
}
