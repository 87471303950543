import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {initialState, reducers} from "../../../../../base-konva/features/pen";

export const penSlice = createSlice({
	name: 'pen',
	initialState,
	reducers
})

export const penActions = penSlice.actions;
export const selectPenGroups = (state: RootState) => state.undoGroup.present.pen.penGroups;
export const selectActivePenGroupId = (state: RootState) => state.undoGroup.present.pen.activePenGroupId;
export const selectPenType = (state: RootState) => state.undoGroup.present.pen.type;
export const selectPenSelectorStyle = (state: RootState) => state.undoGroup.present.pen.penSelectorStyle;

export const penReducer = penSlice.reducer;