import React, {useState} from "react";
import TextInput from "../../project-drawings/features/editor/components/TextInput";
import Button from "../../../components/ui/Button";
import Modal from "../../../components/modal/Modal";
import ModalTitle from "../../../components/modal/ModalTitle";
import ModalButtons from "../../../components/modal/ModalButtons";
import "./createSymbolGroupModal.scss"
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../api/api";
import {SymbolGroup} from "../../../models/SymbolGroup";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import InlineSpinner from "../../../components/InlineSpinner";
import {useSymbolPackFormControl} from "../hooks/useSymbolPackFormControl";
import {useInvalidateSymbolQueries} from "../hooks/useInvalidateSymbolQueries";

type CreateSymbolGroupModalProps = {
	isOpen: boolean
	onClose(): void
	onSave(): void;
}

export function CreateSymbolGroupModal({isOpen, onClose, onSave}: CreateSymbolGroupModalProps) {

	const {t} = useTranslation();
	const [saving, setSaving] = useState(false);
	const [groupName, setGroupName] = useState("");
	const {
		loadedZip, symbolPackName,
		resetSymbolPackFormControl,
		renderSymbolPackFormControl
	} = useSymbolPackFormControl()
	const {invalidateSymbolQueries} = useInvalidateSymbolQueries();

	const canSave = Boolean(loadedZip && loadedZip.SVGs.length >= 1 && groupName && symbolPackName && !saving)

	function handleClose() {
		setGroupName("")
		resetSymbolPackFormControl()
		onClose()
	}

	async function handleSave() {
		if (!canSave) return

		setSaving(true)
		let symbolGroup: SymbolGroup | null = null;
		try {
			symbolGroup = await apiInstance.symbolsApi.postSymbolGroup(groupName)
		}
		catch (e) {
			handleError(e)
			return;
		}

		try {
			const symbolIds: string[] = [];
			const attachments = await Promise.all(
				loadedZip!.SVGs.map(file => apiInstance.attachmentApi.uploadFile(file))
			)
			symbolIds.push(...attachments.map(attachment => attachment.id))
			await apiInstance.symbolsApi.postSymbolPack(symbolGroup.id, symbolPackName, symbolIds)
			handleSuccess()
		}
		catch (e) {
			// If upload symbols or creating a symbol pack fails we delete the empty symbol group, for now we ignore whether it succeeded or not
			apiInstance.symbolsApi.deleteSymbolGroup(symbolGroup.id).then()
			handleError(e)
			return;
		}

		function handleError(e: unknown) {
			invalidateSymbolQueries()
			setSaving(false)
			LoggerService.logError(e);
			NotificationService.errorNotification(
				t("common.error"),
				t("settings.symbolSettings.createSymbolGroupModal.errorDesc")
			)
		}

		function handleSuccess() {
			invalidateSymbolQueries()
			setSaving(false)
			NotificationService.successNotification(
				t("common.success"),
				t("settings.symbolSettings.createSymbolGroupModal.success")
			)
			handleClose()
			onSave();
		}
	}

	function handleGroupNameChange(evt: React.ChangeEvent<HTMLInputElement>) {
		setGroupName(evt.target.value)
	}

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalTitle>{t("settings.symbolSettings.createSymbolGroupModal.title")}</ModalTitle>
			<div className={"create-symbol-group-modal"}>
				<div className={"create-symbol-group-modal_field"}>
					<div className="create-symbol-group-modal_field_label">
						{t("settings.symbolSettings.createSymbolGroupModal.groupName")}
					</div>
					<TextInput value={groupName} onChange={handleGroupNameChange}/>
				</div>
				{renderSymbolPackFormControl()}
			</div>
			<ModalButtons>
				{saving && <InlineSpinner/>}
				<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={handleClose} disabled={saving}/>
				<Button label={t("common.buttons.save")} variant={"primary"} onClick={handleSave} disabled={!canSave}/>
			</ModalButtons>
		</Modal>
	)
}