import React from "react";

const IconPDF: React.FC = () => {
	return (
		<svg height={25} width={20} viewBox="0 0 21 21">
			<g id="_1" data-name="1" transform="translate(-98.69 -48)">
				<path id="Path_3" data-name="Path 3"
					  d="M114.652,71H101.557a2.87,2.87,0,0,1-2.867-2.867V50.867A2.87,2.87,0,0,1,101.557,48h8.588a.858.858,0,0,1,.606.251l6.516,6.516a.858.858,0,0,1,.252.607V68.132A2.87,2.87,0,0,1,114.652,71ZM101.557,49.716a1.152,1.152,0,0,0-1.151,1.151V68.133a1.152,1.152,0,0,0,1.151,1.151h13.095a1.152,1.152,0,0,0,1.151-1.151v-12.4l-6.013-6.013Z"
					  fill="#1976d2"/>
				<path id="Path_4" data-name="Path 4"
					  d="M291.264,56.232h-4.507a2.87,2.87,0,0,1-2.867-2.867V48.858a.858.858,0,0,1,1.716,0v4.507a1.152,1.152,0,0,0,1.151,1.151h4.507a.858.858,0,1,1,0,1.716Z"
					  transform="translate(-174.604 0)" fill="#1976d2"/>
				<path id="Path_5" data-name="Path 5"
					  d="M156,261.5a.394.394,0,0,1,.439-.356H158a1.59,1.59,0,0,1,1.787,1.742v.038a1.638,1.638,0,0,1-1.863,1.757h-.748v1.636c0,.243-.3.363-.591.363s-.588-.121-.588-.363Zm1.181.674v1.6h.75c.424,0,.682-.242.682-.757v-.084c0-.515-.258-.758-.682-.758Z"
					  transform="translate(-54.031 -200.945)" fill="#1976d2"/>
				<path id="Path_6" data-name="Path 6"
					  d="M233.115,261.14a1.646,1.646,0,0,1,1.863,1.787v1.962a1.646,1.646,0,0,1-1.863,1.787h-1.341c-.31,0-.515-.167-.515-.356V261.5c0-.189.2-.356.515-.356Zm-.674,1.03v3.476h.674c.425,0,.682-.242.682-.757v-1.962c0-.515-.257-.758-.682-.758Z"
					  transform="translate(-124.985 -200.945)" fill="#1976d2"/>
				<path id="Path_7" data-name="Path 7"
					  d="M309.351,261.5c0-.243.257-.364.515-.364h2.628c.25,0,.356.265.356.507,0,.28-.129.522-.356.522h-1.962v1.28h1.144c.229,0,.356.219.356.462,0,.2-.106.447-.356.447h-1.144v1.954c0,.243-.3.363-.59.363s-.591-.121-.591-.363Z"
					  transform="translate(-198.607 -200.945)" fill="#1976d2"/>
			</g>
		</svg>
	);
};

export default IconPDF