import React, {useEffect, useMemo} from "react";
import Konva from "konva";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {getStageBorderPosition} from "../../../project-drawings/features/editor/utils";
import throttle from "lodash/throttle";
import {ZoomState} from "../../types";
import {useBaseZoomActions} from "./useBaseZoomActions";

type UseZoomOnWheelProps = {
	scrollContainer: React.RefObject<HTMLElement>
	stageRef: React.RefObject<Konva.Stage>;
	zoomState: ZoomState,
	handleZoomOutByPercent: ReturnType<typeof useBaseZoomActions>["handleZoomOutByPercent"],
	handleZoomInByPercent: ReturnType<typeof useBaseZoomActions>["handleZoomInByPercent"],
}

export function useBaseZoomOnWheel({
	scrollContainer,
	stageRef,
	zoomState,
	handleZoomOutByPercent,
	handleZoomInByPercent
}: UseZoomOnWheelProps) {
	const setZoomOnWheelRef = useUpdatedRef(setZoomOnWheel)

	function setZoomOnWheel(wheelEvent: WheelEvent) {
		const oldScale = zoomState.scale;
		let newScale: number;

		const stage = stageRef.current;
		if (stage) {
			const rect = stage.getClientRect()
			const pointer = stage.getPointerPosition()
			if (pointer) {
				const old_stage_pos = stage.position()

				const stageLeftSidePosition = old_stage_pos.x - rect.width / 2
				const stageTopSidePosition = old_stage_pos.y - rect.height / 2

				const mousePointTo = {
					x: (pointer.x - stageLeftSidePosition) / oldScale,
					y: (pointer.y - stageTopSidePosition) / oldScale
				}

				if (Math.sign(wheelEvent.deltaY) > 0) {
					newScale = handleZoomOutByPercent(5)
				}
				else {
					newScale = handleZoomInByPercent(5)
				}

				const newPos = {
					x: pointer.x - (mousePointTo.x * newScale),
					y: pointer.y - (mousePointTo.y * newScale)
				}
				const diff_x = newPos.x - (stageLeftSidePosition / oldScale * newScale)
				const diff_y = newPos.y - (stageTopSidePosition / oldScale * newScale)

				const newStage = getStageBorderPosition(
					{
						x: (old_stage_pos.x / oldScale * newScale) + diff_x,
						y: (old_stage_pos.y / oldScale * newScale) + diff_y
					},
					stage.getClientRect(),
					zoomState.viewportSize
				)

				stage.position(newStage)
				stage.batchDraw()
			}
		}
	}

	const throttledChange = useMemo(() => throttle(
		(wheelEvent: WheelEvent) => setZoomOnWheelRef.current(wheelEvent),
		50, {leading: true, trailing: false}
	), [setZoomOnWheelRef])

	useEffect(() => {
		const scrollDiv = scrollContainer.current;
		if (scrollDiv) {
			const handler = (wheelEvent: WheelEvent) => {
				wheelEvent.preventDefault(); // blocks scroll behaviour
				throttledChange(wheelEvent)
			};

			scrollDiv.addEventListener("wheel", handler)
			return () => {
				scrollDiv.removeEventListener("wheel", handler)
			}
		}
	}, [scrollContainer, throttledChange])
}