import React from "react";
import {Colors} from "../../../../../../styles";

export const IconCount = function() {
	const fill = Colors.SIMPROBLUE;
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path fill={fill}
				  d="M10.23,12.57H9.3v-.93a.75.75,0,1,0-1.5,0v.93H6.87a.75.75,0,0,0,0,1.5H7.8V15a.75.75,0,1,0,1.5,0v-.93h.93a.75.75,0,0,0,0-1.5Z"/>
			<path fill={fill} d="M17.13,12.57H13.77a.75.75,0,0,0,0,1.5h3.36a.75.75,0,0,0,0-1.5Z"/>
			<path fill={fill} d="M10.23,19.45H6.87a.75.75,0,0,0,0,1.5h3.36a.75.75,0,0,0,0-1.5Z"/>
			<path fill={fill} d="M10.23,17.43H6.87a.75.75,0,0,0,0,1.5h3.36a.75.75,0,0,0,0-1.5Z"/>
			<path fill={fill}
				  d="M17.59,16.85a.74.74,0,0,0-1.06,0l-1.08,1.08-1.08-1.08a.74.74,0,0,0-1.06,0,.75.75,0,0,0,0,1.06L14.39,19l-1.08,1.08a.75.75,0,0,0,0,1.06.75.75,0,0,0,1.06,0l1.08-1.08,1.08,1.08a.74.74,0,0,0,.53.22.73.73,0,0,0,.53-.22.74.74,0,0,0,0-1.06L16.51,19l1.08-1.08A.74.74,0,0,0,17.59,16.85Z"/>
			<path fill={fill}
				  d="M16.2,2.68H7.8a2.5,2.5,0,0,0-2.5,2.5V7.43a2.5,2.5,0,0,0,2.5,2.5h8.4a2.5,2.5,0,0,0,2.5-2.5V5.18A2.5,2.5,0,0,0,16.2,2.68Zm.5,4.75a.5.5,0,0,1-.5.5H7.8a.5.5,0,0,1-.5-.5V5.18a.5.5,0,0,1,.5-.5h8.4a.5.5,0,0,1,.5.5Z"/>
			<path fill={fill}
				  d="M18,.1H6.05A3.73,3.73,0,0,0,2.24,3.73V20.27A3.73,3.73,0,0,0,6.05,23.9H18a3.73,3.73,0,0,0,3.81-3.63V3.73A3.73,3.73,0,0,0,18,.1Zm1.81,20.17A1.73,1.73,0,0,1,18,21.9H6.05a1.73,1.73,0,0,1-1.81-1.63V3.73A1.73,1.73,0,0,1,6.05,2.1H18a1.73,1.73,0,0,1,1.81,1.63Z"/>
		</svg>
	)
}