import React from "react";
import {Breadcrumbs} from "../features/breadcrumbs";
import {useTranslation} from "react-i18next";
import {useDirectProjectAccessDataContext} from "../providers/DirectProjectAccessDataProvider";
import {Link} from "react-router-dom";
import Button from "../components/ui/Button";
import "./navigationHeader.scss"
import {IconSettings} from "../components/icons/IconSettings";
import {paths} from "../features/navigator";

type NavigationHeaderConfig = {
	saveAndFinishButtonVisibility: "visible" | "hidden"
}

const defaultConfig: NavigationHeaderConfig = {
	saveAndFinishButtonVisibility: "visible"
}

const NavigationHeader = function({children, config = defaultConfig}: {
	children?: React.ReactNode
	config?: NavigationHeaderConfig
}) {
	const {t} = useTranslation();
	const {isProjectDirectAccess, redirectToTheSimpro} = useDirectProjectAccessDataContext();

	return (
		<div className="navigation-header">
			<Breadcrumbs/>
			<div className="navigation-header_actions">
				<Link to={paths.settings}>
					<Button icon={<IconSettings/>} label={t("settings.buttonLabel")} variant={"optional"}/>
				</Link>
				{children}
				{isProjectDirectAccess && config.saveAndFinishButtonVisibility === "visible"
					? <Button
						variant={"primary"}
						label={t("common.buttons.saveAndFinish")}
						onClick={redirectToTheSimpro}
					/> : null}
			</div>
		</div>
	)
}

export {NavigationHeader}