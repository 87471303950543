import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {apiInstance} from "../../../../../../api/api";
import CustomSelect from "../../../../../../components/ui/CustomSelect";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {BaseImageUploadModal} from "../../../../../base-konva/features/image/BaseImageUploadModal";
import "./imageUploadModal.scss"

type ImageUploadModalProps = {
	isOpen: boolean
	onCloseModal: () => void
	onHandleSave: (selectedItem: { value: string, label: string }) => void
}

export const ImageUploadModal: FC<ImageUploadModalProps> = function({isOpen, onCloseModal, onHandleSave}) {
	const {t} = useTranslation();
	const {drawingId} = useParams<{ projectId: string, drawingId: string, versionId: string }>();
	const [imageId, setImageId] = useState<string | null>(null);
	const {drawingData: {drawingDetails}, forceDrawingUpdate} = useDrawingDetailsDataContext();
	const imageSelectItems = drawingDetails?.images?.map(image => {
		return {value: image.id, label: image.filename}
	}) ?? []
	const [selectedItem, setSelectedItem] = useState<{ value: string, label: string } | undefined>(undefined)


	const onImageUpload = async (attachmentId: string) => {
		await apiInstance.drawingsApi.connectImageWithDrawing(drawingId, attachmentId)
		forceDrawingUpdate();
	}

	const onConnectedSelect = (value: (string | null)) => {
		setImageId(value)
		setSelectedItem(imageSelectItems.find(item => value === item.value))
	}


	const onModalClose = () => {
		onCloseModal()
		setImageId(null)
	}

	return (
		<BaseImageUploadModal
			isOpen={isOpen}
			onCloseModal={onModalClose}
			onHandleSave={onHandleSave}
			onImageUpload={onImageUpload}
			selectedItem={selectedItem}
			connectedImageSelector={
				<div className={"image-upload-modal"}>
					<div className="image-upload-modal_field_label">
						{t("editor.uploadImageModal.connectedImages")}
					</div>
					<CustomSelect
						placeholder={t("editor.uploadImageModal.connectedImagesPlaceholder")}
						customClass={"image-upload-modal_field_select"}
						items={imageSelectItems}
						onChange={onConnectedSelect}
						value={imageId}
					/>
				</div>
			}
		/>
	)
}