import {useState} from "react";
import {ProjectSource} from "../../../../../models/enums";

type ConfirmationModalData = {
	projectSource: ProjectSource,
	onClose: () => void,
	onSubmit: () => void,
	isSyncOptionsModelLoading: boolean,
}
type StatusModalData = {
	onShowError: () => void,
	onTryAgain: () => void,
	onClose: () => void,
}
type ErrorModalData = {
	onClose: () => void,
}
type SyncStageModal =
	| { type: "confirmation", data: ConfirmationModalData }
	| { type: "status", data: StatusModalData }
	| { type: "error", data: ErrorModalData }

export function useSyncStageModals() {
	const [modal, setModal] = useState<SyncStageModal | null>(null);

	function openConfirmationModal(data: ConfirmationModalData) {
		setModal({type: "confirmation", data})
	}

	function openStatusModal(data: StatusModalData) {
		setModal({type: "status", data})
	}

	function openErrorModal(data: ErrorModalData) {
		setModal({type: "error", data})
	}

	function closeModal() {
		setModal(null)
	}

	return {
		modal,
		openConfirmationModal,
		openStatusModal,
		openErrorModal,
		closeModal,
	}
}