import {SymbolType} from "./default";
import {SymbolSystemPack} from "../../../../../../models/SymbolSystemPack";
import {SortConfig} from "../../../../../../models/interfaces";
import {SortDirection} from "../../../../../../models/enums";
import {SymbolGroup} from "../../../../../../models/SymbolGroup";

const defaultGeneralPack = new SymbolSystemPack(
	"default",
	"General",
	"Electrical",
	"Electrical",
	[
		SymbolType.ANNUNCIATOR_01,
		SymbolType.AUTOMATIC_FIRE_ALARM_DEVICE_01,
		SymbolType.BATTERY_01,
		SymbolType.BUZZER_01,
		SymbolType.CABLE_TV_OUTLET,
		SymbolType.CARD_READER_ACCESS_SYSTEM_01,
		SymbolType.CEILING_FAN,
		SymbolType.DIGITAL_SATELLITE_SYSTEM_01,
		SymbolType.DIMMER_SWITCHES,
		SymbolType.DIMMER_SWITCHES_01,
		SymbolType.DOORBELL_01,
		SymbolType.DOORBELL_CHIME_01,
		SymbolType.DOORBELL_TRANSFORMER_01,
		SymbolType.DOUBLE_SOCKET,
		SymbolType.DOWNLIGHTER,
		SymbolType.ELECTRIC_DOOR_OPENER_01,
		SymbolType.ELECTRIC_MOTORS_01,
		SymbolType.ELECTROMAGNETIC_DOOR_01,
		SymbolType.EMERGENCY_LIGHT,
		SymbolType.EMERGENCY_RELEASE_BUTTON_01,
		SymbolType.FIRE_ALARM_BELL_01,
		SymbolType.FIRE_ALARM_CENTRAL_STATION_01,
		SymbolType.FIRE_ALARM_PANEL,
		SymbolType.FIRE_ALARM_SOUNDER_01,
		SymbolType.FIRE_ALARM_STATION_01,
		SymbolType.FLOURESCENT_FIXTURE,
		SymbolType.GROUND_CONNECTION_01,
		SymbolType.HORN_01,
		SymbolType.ILLUMINATED_EMERGENCY_SIGN,
		SymbolType.INSIDE_ANTENNA_01,
		SymbolType.INTERCOM_01,
		SymbolType.MAGNETIC_DOO_HOLD_01,
		SymbolType.MAIDS_SIGNAL_PLUG_01,
		SymbolType.MAIN_CONTROL_01,
		SymbolType.MODULAR_FLOURESCENT_FITTING,
		SymbolType.MOTION_SENSOR_01,
		SymbolType.MULTI_LIGHTBAR,
		SymbolType.MULTI_SWITCH,
		SymbolType.OUTDOOR_LIGHTING,
		SymbolType.OUTSIDE_ANTENNA_01,
		SymbolType.PULL_CORD_SWITCH,
		SymbolType.PUSH_BUTTON_01,
		SymbolType.SIGNAL_CENTRAL_STATION_01,
		SymbolType.SINGLE_PHASE_01,
		SymbolType.SOCKET_OUTLET,
		SymbolType.STEREO_SPEAKER_OUTLET,
		SymbolType.SURFACE_FLOURESCENT_LIGHT,
		SymbolType.SWITCH,
		SymbolType.SWITCH_2WAY,
		SymbolType.SWITCH_12POLE,
		SymbolType.SWITCHBOARDS_FLUSH_01,
		SymbolType.SWITCHBOARDS_SURFACE_01,
		SymbolType.SWITCHED_SOCKET,
		SymbolType.SWITCH_INTERMEDIATE,
		SymbolType.TELEPHONE_KEY_SYSTEM_01,
		SymbolType.TELEPHONE_OUTLET,
		SymbolType.THERMOSTAT,
		SymbolType.THREE_OF_POLYPHASE_01,
		SymbolType.WALL_LIGHT,
		SymbolType.WALL_MOUNTED_ELECTRICAL_JUNCTION_BOX_01,
		SymbolType.WALL_MOUNTED_TELEPHONE_DATABOX_01,
		SymbolType.WATCHMANS_CENTRAL_STATION_01,
		SymbolType.WATCHMANS_STATION_01,
		SymbolType.TWO_GANG_SSO_01,
		SymbolType.TWO_FOUR_TUBE_FLOURESCENT_LUMINAIRE_01,
		SymbolType.CONSUMER_UNIT_01,
		SymbolType.CONSUMER_UNIT_DISTRIBUTION_BOARD_FUSE_BOARD_01,
		SymbolType.EMERGENCY_LIGHT_01,
		SymbolType.ENERGY_METER_01,
		SymbolType.FLOURESCENT_LUMINAIRE_01,
		SymbolType.FUSE_01,
		SymbolType.INVERTERTER_01,
		SymbolType.MOTOR_STARTER_01,
		SymbolType.RECTIFIER_01,
		SymbolType.SELF_CONTAINED_EMERGENCY_LIGHT_01,
		SymbolType.SOCKET_OUTLET_01,
		SymbolType.STAR_DELTA_STARTER_01,
		SymbolType.SWITCH_01,
		SymbolType.SWITCHED_SOCKET_OUTLET_01,
		SymbolType.THREE_PHASE_WINDING_DELTA_01,
		SymbolType.THREE_PHASE_WINDING_STAR_01,
		SymbolType.CEILING_ROSE,
		SymbolType.EMERGENCY_LAMP_1,
		SymbolType.EMERGENCY_LAMP_2,
		SymbolType.EMERGENCY_LAMP_3,
		SymbolType.EMERGENCY_LAMP_4,
		SymbolType.FLASHING_LAMP,
		SymbolType.FLOODLIGHT,
		SymbolType.FLOURESCENT_1,
		SymbolType.FLOURESCENT_2,
		SymbolType.FLOURESCENT_3,
		SymbolType.FLOURESCENT_4,
		SymbolType.LAMP_1,
		SymbolType.LAMP_2,
		SymbolType.LAMP_3,
		SymbolType.LAMP_4,
		SymbolType.LAMP_WALL_MOUNTED,
		SymbolType.LIGHT_FITTING,
		SymbolType.LIGHTING_POSITION,
		SymbolType.PROTECTED_LIGHT_FITTING,
		SymbolType.PULL_CORD,
		SymbolType.SCONCE,
		SymbolType.SPOTLIGHT_1,
		SymbolType.SPOTLIGHT_2,
		SymbolType.ONE_GANG_TWO_WAY_LIGHT_SWITCHING,
		SymbolType.ONE_GANG_THREE_WAY_LIGHT_SWITCHING,
		SymbolType.ONE_GANG_LIGHT_SWITCH,
		SymbolType.ONE_GANG_PULL_CORD,
		SymbolType.TWO_CORE_SPEAKER_CABLE,
		SymbolType.TWO_GANG_TWO_WAY_LIGHT_SWITCH,
		SymbolType.TWO_GANG_THREE_WAY_LIGHT_SWITCH,
		SymbolType.TWO_GANG_LIGHT_SWITCH,
		SymbolType.AUDIO_SPEAKER,
		SymbolType.CAT_5E,
		SymbolType.CAT_5_OR_6_DATA_SOCKET,
		SymbolType.CONSUMER_UNIT_2,
		SymbolType.COOKER_SWITCH,
		SymbolType.DOORBELL_CHIME_2,
		SymbolType.DOORBELL_PUSH,
		SymbolType.DOUBLE_SWITCHED_FLOOR_SOCKETS,
		SymbolType.DOUBLE_SWITCHED_WALL_SOCKET,
		SymbolType.DOUBLE_SWITCHED_WALL_SOCKET_1200MM,
		SymbolType.EXTERNAL_LIGHT,
		SymbolType.EXTERNAL_LIGHT_BULKHEAD_TYPE,
		SymbolType.FLOURESCENT_STRIP_LIGHT,
		SymbolType.FUSE_SPUR_FOR_TOWEL_RAIL,
		SymbolType.HEAT_DETECTOR_AND_FIRE_ALARM_SOUNDER,
		SymbolType.IMMERSION_HEATER_AND_THERMOSTAT,
		SymbolType.LIGHT_POINT_BATTEN_HOLDER,
		SymbolType.LIGHT_POINT_PENDANT_HOLDER_4INCH,
		SymbolType.LIGHT_POINT_PENDANT_HOLDER_6INCH,
		SymbolType.MECHANICAL_EXTRACT_FAN,
		SymbolType.MECHANICAL_EXTRACT_FAN_COOKER_HOB_HOOD,
		SymbolType.RADIATOR,
		SymbolType.RECESSED_DOWNLIGHT,
		SymbolType.SECURITY_ALARM,
		SymbolType.SINGLE_SWITCHED_FUSE_SPUR_AT_HIGH_LEVEL_FOR_EXTRACT_FAN,
		SymbolType.SINGLE_SWITCHED_FUSE_SPUR_BELOW_FL,
		SymbolType.SINGLE_SWITCHED_WALL_SOCKET,
		SymbolType.SINGLE_SWITCHED_WALL_SOCKET_1200MM,
		SymbolType.SMOKE_HEAT_DETECTOR_FIRE_ALARM_SOUNDER,
		SymbolType.TELEPHONE_POINT,
		SymbolType.THERMOSTAT_2,
		SymbolType.TV_AERIAL_POINT,
		SymbolType.UNDERFLOOR_HEATING_MANIFOLD,
		SymbolType.WALL_LIGHT_2,
		SymbolType.SYMBOLPACK_1_60,
		SymbolType.SYMBOLPACK_1_61,
		SymbolType.SYMBOLPACK_1_62,
		SymbolType.SYMBOLPACK_1_63,
		SymbolType.SYMBOLPACK_1_64,
		SymbolType.SYMBOLPACK_1_66,
		SymbolType.SYMBOLPACK_1_67,
		SymbolType.SYMBOLPACK_1_68,
		SymbolType.SYMBOLPACK_1_69,
		SymbolType.SYMBOLPACK_1_70,
		SymbolType.SYMBOLPACK_1_71,
		SymbolType.SYMBOLPACK_1_72,
		SymbolType.SYMBOLPACK_1_73,
		SymbolType.SYMBOLPACK_1_74,
		SymbolType.SYMBOLPACK_1_75,
		SymbolType.SYMBOLPACK_1_76,
		SymbolType.SYMBOLPACK_1_77,
		SymbolType.SYMBOLPACK_1_78,
		SymbolType.SYMBOLPACK_1_79,
		SymbolType.SYMBOLPACK_1_80,
		SymbolType.SYMBOLPACK_1_81,
		SymbolType.SYMBOLPACK_1_82,
		SymbolType.SYMBOLPACK_1_83,
		SymbolType.SYMBOLPACK_1_84,
		SymbolType.SYMBOLPACK_1_86,
		SymbolType.SYMBOLPACK_1_87,
		SymbolType.SYMBOLPACK_1_97,
		SymbolType.SYMBOLPACK_1_98,
		SymbolType.SYMBOLPACK_1_99,
		SymbolType.SYMBOLPACK_1_100,
		SymbolType.SYMBOLPACK_1_101,
		SymbolType.SYMBOLPACK_1_102,
		SymbolType.SYMBOLPACK_1_104,
		SymbolType.SYMBOLPACK_1_105
	]);

const defaultBasicPack = new SymbolSystemPack(
	"basic",
	"Basic",
	"ALL",
	"General",
	[
		SymbolType.CIRCLE,
		SymbolType.SQUARE,
		SymbolType.TRIANGLE,
		SymbolType.STAR,
		SymbolType.SYMBOLPACK_1_01,
		SymbolType.SYMBOLPACK_1_02,
		SymbolType.SYMBOLPACK_1_02_COPY,
		SymbolType.SYMBOLPACK_1_06,
		SymbolType.SYMBOLPACK_1_08,
		SymbolType.SYMBOLPACK_1_09,
		SymbolType.SYMBOLPACK_1_10,
		SymbolType.SYMBOLPACK_1_12,
		SymbolType.SYMBOLPACK_1_13,
		SymbolType.SYMBOLPACK_1_14,
		SymbolType.SYMBOLPACK_1_15,
		SymbolType.SYMBOLPACK_1_16,
		SymbolType.SYMBOLPACK_1_17,
		SymbolType.SYMBOLPACK_1_18,
		SymbolType.SYMBOLPACK_1_19,
		SymbolType.SYMBOLPACK_1_20,
		SymbolType.SYMBOLPACK_1_21,
		SymbolType.SYMBOLPACK_1_22,
		SymbolType.SYMBOLPACK_1_23,
		SymbolType.SYMBOLPACK_1_24,
		SymbolType.SYMBOLPACK_1_25,
		SymbolType.SYMBOLPACK_1_26,
		SymbolType.SYMBOLPACK_1_27,
		SymbolType.SYMBOLPACK_1_28,
		SymbolType.SYMBOLPACK_1_29,
		SymbolType.SYMBOLPACK_1_30,
		SymbolType.SYMBOLPACK_1_32,
		SymbolType.SYMBOLPACK_1_32_COPY,
		SymbolType.SYMBOLPACK_1_33,
		SymbolType.SYMBOLPACK_1_34,
		SymbolType.SYMBOLPACK_1_35,
		SymbolType.SYMBOLPACK_1_36,
		SymbolType.SYMBOLPACK_1_37,
		SymbolType.SYMBOLPACK_1_38,
		SymbolType.SYMBOLPACK_1_39,
		SymbolType.SYMBOLPACK_1_40,
		SymbolType.SYMBOLPACK_1_41,
		SymbolType.SYMBOLPACK_1_42,
		SymbolType.SYMBOLPACK_1_43,
		SymbolType.SYMBOLPACK_1_44,
		SymbolType.SYMBOLPACK_1_45,
		SymbolType.SYMBOLPACK_1_46,
		SymbolType.SYMBOLPACK_1_47,
		SymbolType.SYMBOLPACK_1_48,
		SymbolType.SYMBOLPACK_1_49,
		SymbolType.SYMBOLPACK_1_50,
		SymbolType.SYMBOLPACK_1_51,
		SymbolType.SYMBOLPACK_1_52,
		SymbolType.SYMBOLPACK_1_53,
		SymbolType.SYMBOLPACK_1_54,
		SymbolType.SYMBOLPACK_1_55,
		SymbolType.SYMBOLPACK_1_57,
		SymbolType.SYMBOLPACK_1_58,
		SymbolType.SYMBOLPACK_1_59,
		SymbolType.SYMBOLPACK_1_65,
		SymbolType.SYMBOLPACK_1_88,
		SymbolType.SYMBOLPACK_1_89,
		SymbolType.SYMBOLPACK_1_109,
		SymbolType.SYMBOLPACK_1_110,
		SymbolType.SYMBOLPACK_1_112,
		SymbolType.SYMBOLPACK_1_113,
		SymbolType.SYMBOLPACK_1_114,
		SymbolType.SYMBOLPACK_1_115,
		SymbolType.SYMBOLPACK_1_116,
		SymbolType.SYMBOLPACK_1_117,
		SymbolType.SYMBOLPACK_1_118,
		SymbolType.SYMBOLPACK_1_119,
		SymbolType.SYMBOLPACK_1_120,
		SymbolType.SYMBOLPACK_1_121,
		SymbolType.SYMBOLPACK_1_122,
		SymbolType.SYMBOLPACK_1_123,
		SymbolType.SYMBOLPACK_1_124,
		SymbolType.SYMBOLPACK_1_125,
		SymbolType.SYMBOLPACK_1_127,
		SymbolType.SYMBOLPACK_1_128,
		SymbolType.SYMBOLPACK_1_129,
		SymbolType.SYMBOLPACK_1_130,
		SymbolType.SYMBOLPACK_1_132,
		SymbolType.SYMBOLPACK_1_133,
		SymbolType.SYMBOLPACK_1_134,
		SymbolType.SYMBOLPACK_1_135,
		SymbolType.SYMBOLPACK_1_136,
		SymbolType.SYMBOLPACK_1_137,
		SymbolType.SYMBOLPACK_1_138,
		SymbolType.SYMBOLPACK_1_139,
		SymbolType.SYMBOLPACK_1_140,
		SymbolType.SYMBOLPACK_1_141,
		SymbolType.SYMBOLPACK_1_146,
		SymbolType.SYMBOLPACK_1_147,
		SymbolType.SYMBOLPACK_1_148,
		SymbolType.SYMBOLPACK_1_149,
		SymbolType.SYMBOLPACK_1_150,
		SymbolType.SYMBOLPACK_1_151,
		SymbolType.SYMBOLPACK_1_152,
		SymbolType.SYMBOLPACK_1_153,
		SymbolType.SYMBOLPACK_1_154,
		SymbolType.SYMBOLPACK_1_155,
		SymbolType.SYMBOLPACK_1_156,
		SymbolType.SYMBOLPACK_1_157,
		SymbolType.SYMBOLPACK_1_158,
		SymbolType.SYMBOLPACK_1_159,
		SymbolType.SYMBOLPACK_1_160,
		SymbolType.SYMBOLPACK_1_161,
		SymbolType.SYMBOLPACK_1_162,
		SymbolType.SYMBOLPACK_1_163,
		SymbolType.SYMBOLPACK_1_164,
		SymbolType.SYMBOLPACK_1_165,
		SymbolType.SYMBOLPACK_1_166,
		SymbolType.SYMBOLPACK_1_167,
		SymbolType.SYMBOLPACK_1_168,
		SymbolType.SYMBOLPACK_1_169,
		SymbolType.SYMBOLPACK_1_170,
		SymbolType.SYMBOLPACK_1_171,
		SymbolType.SYMBOLPACK_1_172,
		SymbolType.SYMBOLPACK_1_173,
		SymbolType.SYMBOLPACK_1_174,
		SymbolType.SYMBOLPACK_1_175,
		SymbolType.SYMBOLPACK_1_176,
		SymbolType.SYMBOLPACK_1_177,
		SymbolType.SYMBOLPACK_1_178,
		SymbolType.SYMBOLPACK_1_179,
		SymbolType.SYMBOLPACK_1_180
	]);

const defaultElectricalActuatorsPack = new SymbolSystemPack(
	"electrical_actuators",
	"Electrical Actuators",
	"Electrical",
	"Electrical",
	[
		SymbolType.ACTUATORBY_MECHANICAL_ENERGY_ACCUMULATION,
		SymbolType.ACTUATOR_LEVEL_POWEREDBY_LIQUID_LEVEL,
		SymbolType.ACTUATOR_MANUAL_CONTROL,
		SymbolType.ACTUATOR_MANUAL_PROTECTAGAINST_ROUGH_HANDLING,
		SymbolType.CONTACT_CONTROL,
		SymbolType.COUNTER_POWERED_ACTUATOR,
		SymbolType.DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_DOUBLE_EFFECT,
		SymbolType.DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_SA,
		SymbolType.ELECTRICAL_CLOCK_CONTROL,
		SymbolType.ELECTRIC_MOTOR_CONTROL,
		SymbolType.ELECTROMAGNETIC_ACTUATOR,
		SymbolType.EMERGENCY_ACTUATOR,
		SymbolType.FLUID_ACTUATOR,
		SymbolType.FREQUENCY_ACTUATOR,
		SymbolType.GAS_ACTUATOR_POWEREDBY_FLUID_GAS,
		SymbolType.MECHANICAL_CONTROL_BY_CRANK,
		SymbolType.MECHANICAL_CONTROLBY_PEDAL,
		SymbolType.MECHANICAL_CONTROLBY_ROLLEROR_SLIDE,
		SymbolType.MECHANICAL_CONTROL_BY_SHOOTER_AUTO_RETURN,
		SymbolType.MECHANICAL_CONTROL_CAM,
		SymbolType.MECHANICAL_CONTROL_HAND_WHEEL,
		SymbolType.MECHANICAL_CONTROL_KEY,
		SymbolType.MECHANICAL_CONTROL_PUSH_BUTTON_AUTO_RETURN,
		SymbolType.MECHANICAL_MANUAL_CONTROL_WITH_LEVER,
		SymbolType.MECHANICAL_MANUAL_ROTARY_CONTROL,
		SymbolType.PRESSURE_ACTUATOR,
		SymbolType.PROXY_CONTROL_GENERIC,
		SymbolType.RELATIVE_HUMIDITY_ACTUATOR,
		SymbolType.RELAY_ELECTROMAGNETICACTUATOR,
		SymbolType.REMOVABLE_MANUAL_CONTROL,
		SymbolType.THERMAL_ACTUATOR_BY_TEMP_EFFECT
	]);

const defaultFireGeneralPack = new SymbolSystemPack(
	"fire_general",
	"General",
	"Fire",
	"Fire",
	[
		SymbolType.ALARM_BUTTON_PANIC_BUTTON,
		SymbolType.FIRE_SENSOR_AUTOMATIC_FIRE_SENSOR,
		SymbolType.FIRE_WARNING_DEVICE,
		SymbolType.FLAME_DETECTOR,
		SymbolType.HEAT_DETECTOR,
		SymbolType.IONIZING_SMOKE_DETECTOR,
		SymbolType.SIREN_ALARM,
		SymbolType.SMOKE_ALARM,
		SymbolType.SMOKE_DETECTOR,
		SymbolType.SMOKE_DETECTORWITH_ALARM,
		SymbolType.SYMBOLPACK_1_106,
		SymbolType.SYMBOLPACK_1_107,
		SymbolType.SYMBOLPACK_1_108,
		SymbolType.SYMBOLPACK_NEW_191,
		SymbolType.SYMBOLPACK_NEW_192,
		SymbolType.SYMBOLPACK_NEW_193,
		SymbolType.SYMBOLPACK_NEW_194,
		SymbolType.SYMBOLPACK_NEW_195,
		SymbolType.SYMBOLPACK_NEW_227,
		SymbolType.SYMBOLPACK_NEW_282,
		SymbolType.SYMBOLPACK_NEW_283,
		SymbolType.SYMBOLPACK_NEW_333,
		SymbolType.SYMBOLPACK_NEW_334,
		SymbolType.SYMBOLPACK_NEW_335,
		SymbolType.SYMBOLPACK_NEW_336,
		SymbolType.SYMBOLPACK_NEW_337,
		SymbolType.SYMBOLPACK_NEW_338,
		SymbolType.SYMBOLPACK_NEW_339,
		SymbolType.SYMBOLPACK_NEW_340,
		SymbolType.SYMBOLPACK_NEW_341,
		SymbolType.SYMBOLPACK_NEW_342,
		SymbolType.SYMBOLPACK_NEW_343,
		SymbolType.SYMBOLPACK_NEW_344,
		SymbolType.SYMBOLPACK_NEW_345
	]);

const defaultSecuritySystemPack = new SymbolSystemPack(
	"security_system",
	"Security System",
	"Security",
	"Security",
	[
		SymbolType.BROKEN_GLASS_SENSOR,
		SymbolType.BUGLAR_SECURITY_ALARM,
		SymbolType.GLASS_BREAK_DETECTOR,
		SymbolType.INFRARED_MOTION_SENSOR,
		SymbolType.KEYBOARD_ALARM_KEYPAD,
		SymbolType.MAGNETIC_SENSOR_MAGNETOF_ALARM,
		SymbolType.MOTION_DETECTOR_SENSOR,
		SymbolType.PANIC_BUTTON,
		SymbolType.P_I_R_ANTIMASKING,
		SymbolType.P_I_R_EMITTER,
		SymbolType.P_I_R_RECEPTOR,
		SymbolType.P_I_R_SENSOR_PASSIVE_INFRARED_DETECTOR_MOTION_DETECTOR,
		SymbolType.P_I_R_WEATHERING_P_I_R_OUTDOOR,
		SymbolType.SPEAKER_SIREN_ALARM,
		SymbolType.ULTRASONIC_MOTION_SENSOR,
		SymbolType.SYMBOLPACK_NEW_255,
		SymbolType.SYMBOLPACK_NEW_256,
		SymbolType.SYMBOLPACK_NEW_257,
		SymbolType.SYMBOLPACK_NEW_258,
		SymbolType.SYMBOLPACK_NEW_259,
		SymbolType.SYMBOLPACK_NEW_217,
		SymbolType.SYMBOLPACK_NEW_218,
		SymbolType.SYMBOLPACK_NEW_219,
		SymbolType.SYMBOLPACK_NEW_220,
		SymbolType.SYMBOLPACK_NEW_221,
		SymbolType.SYMBOLPACK_NEW_222,
		SymbolType.SYMBOLPACK_NEW_223,
		SymbolType.SYMBOLPACK_NEW_224,
		SymbolType.SYMBOLPACK_NEW_225,
		SymbolType.SYMBOLPACK_NEW_226,
		SymbolType.SYMBOLPACK_NEW_228,
		SymbolType.SYMBOLPACK_NEW_229,
		SymbolType.SYMBOLPACK_NEW_230,
		SymbolType.SYMBOLPACK_NEW_231,

		SymbolType.SYMBOLPACK_346,
		SymbolType.SYMBOLPACK_347,
		SymbolType.SYMBOLPACK_348,
		SymbolType.SYMBOLPACK_349,
		SymbolType.SYMBOLPACK_350,
		SymbolType.SYMBOLPACK_351,
		SymbolType.SYMBOLPACK_352,
		SymbolType.SYMBOLPACK_353,
		SymbolType.SYMBOLPACK_354,
		SymbolType.SYMBOLPACK_355,
		SymbolType.SYMBOLPACK_356,
		SymbolType.SYMBOLPACK_357,
		SymbolType.SYMBOLPACK_358,
		SymbolType.SYMBOLPACK_359,
		SymbolType.SYMBOLPACK_360,
		SymbolType.SYMBOLPACK_361,
		SymbolType.SYMBOLPACK_362,
		SymbolType.SYMBOLPACK_363,
		SymbolType.SYMBOLPACK_364,
		SymbolType.SYMBOLPACK_365,
		SymbolType.SYMBOLPACK_366,
		SymbolType.SYMBOLPACK_367,
		SymbolType.SYMBOLPACK_368,
		SymbolType.SYMBOLPACK_369,
		SymbolType.SYMBOLPACK_370,
		SymbolType.SYMBOLPACK_371,
		SymbolType.SYMBOLPACK_372,
		SymbolType.SYMBOLPACK_373,
		SymbolType.SYMBOLPACK_374,
		SymbolType.SYMBOLPACK_375,
		SymbolType.SYMBOLPACK_376,
		SymbolType.SYMBOLPACK_377,
		SymbolType.SYMBOLPACK_378,
		SymbolType.SYMBOLPACK_379,
		SymbolType.SYMBOLPACK_380,
		SymbolType.SYMBOLPACK_381,
		SymbolType.SYMBOLPACK_382,
		SymbolType.SYMBOLPACK_383,
		SymbolType.SYMBOLPACK_384,
		SymbolType.SYMBOLPACK_385,
		SymbolType.SYMBOLPACK_386,
		SymbolType.SYMBOLPACK_387,
		SymbolType.SYMBOLPACK_388,
		SymbolType.SYMBOLPACK_389,
		SymbolType.SYMBOLPACK_390,
		SymbolType.SYMBOLPACK_391,
		SymbolType.SYMBOLPACK_392,
		SymbolType.SYMBOLPACK_393,
		SymbolType.SYMBOLPACK_394,
		SymbolType.SYMBOLPACK_395,
		SymbolType.SYMBOLPACK_396,
		SymbolType.SYMBOLPACK_397,
		SymbolType.SYMBOLPACK_398,
		SymbolType.SYMBOLPACK_399,
		SymbolType.SYMBOLPACK_400,
		SymbolType.SYMBOLPACK_401,
		SymbolType.SYMBOLPACK_402,
		SymbolType.SYMBOLPACK_403,
	]);

const defaultLightingSystemPack = new SymbolSystemPack(
	"lighting_symbols",
	"Lighting 1",
	"Electrical",
	"Lighting",
	[
		SymbolType.AUTONOMOUS_LIGHT_POINT,
		SymbolType.AUXILIARY_DISCHARGE_LAMP_DEVICE,
		SymbolType.BLOCK_AUTONOMOUS_EMERGENCY_LIGHT,
		SymbolType.DISCHARGE_LAMP2,
		SymbolType.EMBEDDED_FLUORESCEN_LINE_OUTPUT,
		SymbolType.EMBEDDED_FLUORESCENT_OUTPUT,
		SymbolType.EMBEDDED_MERCURY_LAMP_OUTPUT,
		SymbolType.EMERGENCY_LIGHTING,
		SymbolType.FLASHBULB,
		SymbolType.FLUORESCENT_LAMP,
		SymbolType.FLUORESCENT_OUTPUT,
		SymbolType.FLUORESCENT_OUTPUT_LINE,
		SymbolType.INCANDESCENT_LAMP_LIGHTBULB,
		SymbolType.INCANDESCENT_LAMP_LIGHTBULB2,
		SymbolType.INCANDESCENT_LAMP_LIGHTBULB3,
		SymbolType.LAMP,
		SymbolType.LAMP_LOAD,
		SymbolType.LAMPWITH_TRANSFORMER,
		SymbolType.LIGHTBULB_GENERIC,
		SymbolType.LIGHTING_OUTPUT,
		SymbolType.LIGHTNING_LIGHT_LAMP,
		SymbolType.LOW_INTENSITY_LAMPS,
		SymbolType.LUMINAIRE_LIGHT_FIXTURE,
		SymbolType.LUMINAIRE_POINT,
		SymbolType.LUMINAIRE_WITH_THREE_ELEMENT,
		SymbolType.LUMINOUS_INDICATOR,
		SymbolType.MERCURY_LAMP_OUTPUT,
		SymbolType.NEON_LAMP,
		SymbolType.OSCILLATORY_LAMP,
		SymbolType.PROJECTED_LIGHT,
		SymbolType.SEPERATE_AUXILIARY_LIGHTS,
		SymbolType.WALL_LAMP_OUTPUT,
		SymbolType.WALL_LIGHTING,
		SymbolType.XENON_FLASH_LAMP
	]);

const defaultPlumbingSystemPack = new SymbolSystemPack(
	"plumbing_symbols",
	"Plumbing 1",
	"Plumbing",
	"Plumbing",
	[
		SymbolType.BATHTUB,
		SymbolType.CAP,
		SymbolType.CLEAN_OUT,
		SymbolType.CLOTHES_WASHER,
		SymbolType.DISHWASHER,
		SymbolType.DOUBLE_SINK_WITH_CABINET,
		SymbolType.FLOOR_DRAIN,
		SymbolType.FREESTANDING_SINK,
		SymbolType.GATE_VALVE,
		SymbolType.LAVATORY,
		SymbolType.PIPE_TURNS_DOWN,
		SymbolType.PIPE_TURNS_UP,
		SymbolType.SHOWER,
		SymbolType.SINK_WITH_CABINET,
		SymbolType.TOILET,
		SymbolType.URINAL,
		SymbolType.VENT_THRU_ROOF,
		SymbolType.WATER_CLOSET,
		SymbolType.WATER_HEATER,
		SymbolType.WATER_HEATER_SHUT_OFF,
		SymbolType.WATER_METER,
		SymbolType.SYMBOLPACK_1_90,
		SymbolType.SYMBOLPACK_1_91,
		SymbolType.SYMBOLPACK_1_92,
		SymbolType.SYMBOLPACK_1_93,
		SymbolType.SYMBOLPACK_1_94,
		SymbolType.SYMBOLPACK_1_95,
		SymbolType.SYMBOLPACK_1_96,
		SymbolType.SYMBOLPACK_1_142,
		SymbolType.SYMBOLPACK_1_143,
		SymbolType.SYMBOLPACK_1_144,
		SymbolType.SYMBOLPACK_1_145
	]);

const defaultHvacSystemPack = new SymbolSystemPack(
	"hvac_symbols",
	"HVAC 1",
	"HVAC",
	"HVAC",
	[
		SymbolType.AIR_CONDITIONER,
		SymbolType.AIR_FILTER,
		SymbolType.CONDENSER,
		SymbolType.FAN,
		SymbolType.HEATER,
		SymbolType.RETURN_AIR_VENT,
		SymbolType.STRAIGHT_DUCT,
		SymbolType.Y_JUNCTION_DUCT
	]
);

const defaultGeneralLettersPack = new SymbolSystemPack(
	"general_letters",
	"Letters",
	"ALL",
	"General",
	[
		SymbolType.SYMBOLPACK_2_11,
		SymbolType.SYMBOLPACK_2_12,
		SymbolType.SYMBOLPACK_2_13,
		SymbolType.SYMBOLPACK_2_14,
		SymbolType.SYMBOLPACK_2_15,
		SymbolType.SYMBOLPACK_2_16,
		SymbolType.SYMBOLPACK_2_17,
		SymbolType.SYMBOLPACK_2_18,
		SymbolType.SYMBOLPACK_2_19,
		SymbolType.SYMBOLPACK_2_20,
		SymbolType.SYMBOLPACK_2_21,
		SymbolType.SYMBOLPACK_2_22,
		SymbolType.SYMBOLPACK_2_23,
		SymbolType.SYMBOLPACK_2_24,
		SymbolType.SYMBOLPACK_2_25,
		SymbolType.SYMBOLPACK_2_26,
		SymbolType.SYMBOLPACK_2_27,
		SymbolType.SYMBOLPACK_2_28,
		SymbolType.SYMBOLPACK_2_29,
		SymbolType.SYMBOLPACK_2_30,
		SymbolType.SYMBOLPACK_2_31,
		SymbolType.SYMBOLPACK_2_32,
		SymbolType.SYMBOLPACK_2_33,
		SymbolType.SYMBOLPACK_2_34,
		SymbolType.SYMBOLPACK_2_35,
		SymbolType.SYMBOLPACK_2_36,
		SymbolType.SYMBOLPACK_3_11,
		SymbolType.SYMBOLPACK_3_12,
		SymbolType.SYMBOLPACK_3_13,
		SymbolType.SYMBOLPACK_3_14,
		SymbolType.SYMBOLPACK_3_15,
		SymbolType.SYMBOLPACK_3_16,
		SymbolType.SYMBOLPACK_3_17,
		SymbolType.SYMBOLPACK_3_18,
		SymbolType.SYMBOLPACK_3_19,
		SymbolType.SYMBOLPACK_3_20,
		SymbolType.SYMBOLPACK_3_21,
		SymbolType.SYMBOLPACK_3_22,
		SymbolType.SYMBOLPACK_3_23,
		SymbolType.SYMBOLPACK_3_24,
		SymbolType.SYMBOLPACK_3_25,
		SymbolType.SYMBOLPACK_3_26,
		SymbolType.SYMBOLPACK_3_27,
		SymbolType.SYMBOLPACK_3_28,
		SymbolType.SYMBOLPACK_3_29,
		SymbolType.SYMBOLPACK_3_30,
		SymbolType.SYMBOLPACK_3_31,
		SymbolType.SYMBOLPACK_3_32,
		SymbolType.SYMBOLPACK_3_33,
		SymbolType.SYMBOLPACK_3_34,
		SymbolType.SYMBOLPACK_3_35,
		SymbolType.SYMBOLPACK_3_36,
		SymbolType.SYMBOLPACK_4_11,
		SymbolType.SYMBOLPACK_4_12,
		SymbolType.SYMBOLPACK_4_13,
		SymbolType.SYMBOLPACK_4_14,
		SymbolType.SYMBOLPACK_4_15,
		SymbolType.SYMBOLPACK_4_16,
		SymbolType.SYMBOLPACK_4_17,
		SymbolType.SYMBOLPACK_4_18,
		SymbolType.SYMBOLPACK_4_19,
		SymbolType.SYMBOLPACK_4_20,
		SymbolType.SYMBOLPACK_4_21,
		SymbolType.SYMBOLPACK_4_22,
		SymbolType.SYMBOLPACK_4_23,
		SymbolType.SYMBOLPACK_4_24,
		SymbolType.SYMBOLPACK_4_25,
		SymbolType.SYMBOLPACK_4_26,
		SymbolType.SYMBOLPACK_4_27,
		SymbolType.SYMBOLPACK_4_28,
		SymbolType.SYMBOLPACK_4_29,
		SymbolType.SYMBOLPACK_4_30,
		SymbolType.SYMBOLPACK_4_31,
		SymbolType.SYMBOLPACK_4_32,
		SymbolType.SYMBOLPACK_4_33,
		SymbolType.SYMBOLPACK_4_34,
		SymbolType.SYMBOLPACK_4_35,
		SymbolType.SYMBOLPACK_4_36,
		SymbolType.SYMBOLPACK_5_11,
		SymbolType.SYMBOLPACK_5_12,
		SymbolType.SYMBOLPACK_5_13,
		SymbolType.SYMBOLPACK_5_14,
		SymbolType.SYMBOLPACK_5_15,
		SymbolType.SYMBOLPACK_5_16,
		SymbolType.SYMBOLPACK_5_17,
		SymbolType.SYMBOLPACK_5_18,
		SymbolType.SYMBOLPACK_5_19,
		SymbolType.SYMBOLPACK_5_20,
		SymbolType.SYMBOLPACK_5_21,
		SymbolType.SYMBOLPACK_5_22,
		SymbolType.SYMBOLPACK_5_23,
		SymbolType.SYMBOLPACK_5_24,
		SymbolType.SYMBOLPACK_5_25,
		SymbolType.SYMBOLPACK_5_26,
		SymbolType.SYMBOLPACK_5_27,
		SymbolType.SYMBOLPACK_5_28,
		SymbolType.SYMBOLPACK_5_29,
		SymbolType.SYMBOLPACK_5_30,
		SymbolType.SYMBOLPACK_5_31,
		SymbolType.SYMBOLPACK_5_32,
		SymbolType.SYMBOLPACK_5_33,
		SymbolType.SYMBOLPACK_5_34,
		SymbolType.SYMBOLPACK_5_35,
		SymbolType.SYMBOLPACK_5_36,
		SymbolType.SYMBOLPACK_6_11,
		SymbolType.SYMBOLPACK_6_12,
		SymbolType.SYMBOLPACK_6_13,
		SymbolType.SYMBOLPACK_6_14,
		SymbolType.SYMBOLPACK_6_15,
		SymbolType.SYMBOLPACK_6_16,
		SymbolType.SYMBOLPACK_6_17,
		SymbolType.SYMBOLPACK_6_18,
		SymbolType.SYMBOLPACK_6_19,
		SymbolType.SYMBOLPACK_6_20,
		SymbolType.SYMBOLPACK_6_21,
		SymbolType.SYMBOLPACK_6_22,
		SymbolType.SYMBOLPACK_6_23,
		SymbolType.SYMBOLPACK_6_24,
		SymbolType.SYMBOLPACK_6_25,
		SymbolType.SYMBOLPACK_6_26,
		SymbolType.SYMBOLPACK_6_27,
		SymbolType.SYMBOLPACK_6_28,
		SymbolType.SYMBOLPACK_6_29,
		SymbolType.SYMBOLPACK_6_30,
		SymbolType.SYMBOLPACK_6_31,
		SymbolType.SYMBOLPACK_6_32,
		SymbolType.SYMBOLPACK_6_33,
		SymbolType.SYMBOLPACK_6_34,
		SymbolType.SYMBOLPACK_6_35,
		SymbolType.SYMBOLPACK_6_36,
		SymbolType.SYMBOLPACK_6_37,
		SymbolType.SYMBOLPACK_6_38,
		SymbolType.SYMBOLPACK_6_39,
		SymbolType.SYMBOLPACK_6_40,
		SymbolType.SYMBOLPACK_6_41,
		SymbolType.SYMBOLPACK_6_42,
		SymbolType.SYMBOLPACK_6_43,
		SymbolType.SYMBOLPACK_6_44,
		SymbolType.SYMBOLPACK_6_45,
		SymbolType.SYMBOLPACK_6_46,
		SymbolType.SYMBOLPACK_6_47,
		SymbolType.SYMBOLPACK_6_48,
		SymbolType.SYMBOLPACK_3_SOLID_11,
		SymbolType.SYMBOLPACK_3_SOLID_12,
		SymbolType.SYMBOLPACK_3_SOLID_13,
		SymbolType.SYMBOLPACK_3_SOLID_14,
		SymbolType.SYMBOLPACK_3_SOLID_15,
		SymbolType.SYMBOLPACK_3_SOLID_16,
		SymbolType.SYMBOLPACK_3_SOLID_17,
		SymbolType.SYMBOLPACK_3_SOLID_18,
		SymbolType.SYMBOLPACK_3_SOLID_19,
		SymbolType.SYMBOLPACK_3_SOLID_20,
		SymbolType.SYMBOLPACK_3_SOLID_21,
		SymbolType.SYMBOLPACK_3_SOLID_22,
		SymbolType.SYMBOLPACK_3_SOLID_23,
		SymbolType.SYMBOLPACK_3_SOLID_24,
		SymbolType.SYMBOLPACK_3_SOLID_25,
		SymbolType.SYMBOLPACK_3_SOLID_26,
		SymbolType.SYMBOLPACK_3_SOLID_27,
		SymbolType.SYMBOLPACK_3_SOLID_28,
		SymbolType.SYMBOLPACK_3_SOLID_29,
		SymbolType.SYMBOLPACK_3_SOLID_30,
		SymbolType.SYMBOLPACK_3_SOLID_31,
		SymbolType.SYMBOLPACK_3_SOLID_32,
		SymbolType.SYMBOLPACK_3_SOLID_33,
		SymbolType.SYMBOLPACK_3_SOLID_34,
		SymbolType.SYMBOLPACK_3_SOLID_35,
		SymbolType.SYMBOLPACK_3_SOLID_36,
		SymbolType.SYMBOLPACK_3_SOLID_37,
		SymbolType.SYMBOLPACK_3_SOLID_38,
		SymbolType.SYMBOLPACK_3_SOLID_39,
		SymbolType.SYMBOLPACK_3_SOLID_40,
		SymbolType.SYMBOLPACK_3_SOLID_41,
		SymbolType.SYMBOLPACK_3_SOLID_42,
		SymbolType.SYMBOLPACK_3_SOLID_43,
		SymbolType.SYMBOLPACK_3_SOLID_44,
		SymbolType.SYMBOLPACK_3_SOLID_45,
		SymbolType.SYMBOLPACK_3_SOLID_46,
		SymbolType.SYMBOLPACK_3_SOLID_47,
		SymbolType.SYMBOLPACK_3_SOLID_48,
		SymbolType.SYMBOLPACK_4_SOLID_11,
		SymbolType.SYMBOLPACK_4_SOLID_12,
		SymbolType.SYMBOLPACK_4_SOLID_13,
		SymbolType.SYMBOLPACK_4_SOLID_14,
		SymbolType.SYMBOLPACK_4_SOLID_15,
		SymbolType.SYMBOLPACK_4_SOLID_16,
		SymbolType.SYMBOLPACK_4_SOLID_17,
		SymbolType.SYMBOLPACK_4_SOLID_18,
		SymbolType.SYMBOLPACK_4_SOLID_19,
		SymbolType.SYMBOLPACK_4_SOLID_20,
		SymbolType.SYMBOLPACK_4_SOLID_21,
		SymbolType.SYMBOLPACK_4_SOLID_22,
		SymbolType.SYMBOLPACK_4_SOLID_23,
		SymbolType.SYMBOLPACK_4_SOLID_24,
		SymbolType.SYMBOLPACK_4_SOLID_25,
		SymbolType.SYMBOLPACK_4_SOLID_26,
		SymbolType.SYMBOLPACK_4_SOLID_27,
		SymbolType.SYMBOLPACK_4_SOLID_28,
		SymbolType.SYMBOLPACK_4_SOLID_29,
		SymbolType.SYMBOLPACK_4_SOLID_30,
		SymbolType.SYMBOLPACK_4_SOLID_31,
		SymbolType.SYMBOLPACK_4_SOLID_32,
		SymbolType.SYMBOLPACK_4_SOLID_33,
		SymbolType.SYMBOLPACK_4_SOLID_34,
		SymbolType.SYMBOLPACK_4_SOLID_35,
		SymbolType.SYMBOLPACK_4_SOLID_36,
		SymbolType.SYMBOLPACK_4_SOLID_37,
		SymbolType.SYMBOLPACK_4_SOLID_38,
		SymbolType.SYMBOLPACK_4_SOLID_39,
		SymbolType.SYMBOLPACK_4_SOLID_40,
		SymbolType.SYMBOLPACK_4_SOLID_41,
		SymbolType.SYMBOLPACK_4_SOLID_42,
		SymbolType.SYMBOLPACK_4_SOLID_43,
		SymbolType.SYMBOLPACK_4_SOLID_44,
		SymbolType.SYMBOLPACK_4_SOLID_45,
		SymbolType.SYMBOLPACK_4_SOLID_46,
		SymbolType.SYMBOLPACK_4_SOLID_47,
		SymbolType.SYMBOLPACK_4_SOLID_48,
		SymbolType.SYMBOLPACK_5_SOLID_11,
		SymbolType.SYMBOLPACK_5_SOLID_12,
		SymbolType.SYMBOLPACK_5_SOLID_13,
		SymbolType.SYMBOLPACK_5_SOLID_14,
		SymbolType.SYMBOLPACK_5_SOLID_15,
		SymbolType.SYMBOLPACK_5_SOLID_16,
		SymbolType.SYMBOLPACK_5_SOLID_17,
		SymbolType.SYMBOLPACK_5_SOLID_18,
		SymbolType.SYMBOLPACK_5_SOLID_19,
		SymbolType.SYMBOLPACK_5_SOLID_20,
		SymbolType.SYMBOLPACK_5_SOLID_21,
		SymbolType.SYMBOLPACK_5_SOLID_22,
		SymbolType.SYMBOLPACK_5_SOLID_23,
		SymbolType.SYMBOLPACK_5_SOLID_24,
		SymbolType.SYMBOLPACK_5_SOLID_25,
		SymbolType.SYMBOLPACK_5_SOLID_26,
		SymbolType.SYMBOLPACK_5_SOLID_27,
		SymbolType.SYMBOLPACK_5_SOLID_28,
		SymbolType.SYMBOLPACK_5_SOLID_29,
		SymbolType.SYMBOLPACK_5_SOLID_30,
		SymbolType.SYMBOLPACK_5_SOLID_31,
		SymbolType.SYMBOLPACK_5_SOLID_32,
		SymbolType.SYMBOLPACK_5_SOLID_33,
		SymbolType.SYMBOLPACK_5_SOLID_34,
		SymbolType.SYMBOLPACK_5_SOLID_35,
		SymbolType.SYMBOLPACK_5_SOLID_36,
		SymbolType.SYMBOLPACK_5_SOLID_37,
		SymbolType.SYMBOLPACK_5_SOLID_38,
		SymbolType.SYMBOLPACK_5_SOLID_39,
		SymbolType.SYMBOLPACK_5_SOLID_40,
		SymbolType.SYMBOLPACK_5_SOLID_41,
		SymbolType.SYMBOLPACK_5_SOLID_42,
		SymbolType.SYMBOLPACK_5_SOLID_43,
		SymbolType.SYMBOLPACK_5_SOLID_44,
		SymbolType.SYMBOLPACK_5_SOLID_45,
		SymbolType.SYMBOLPACK_5_SOLID_46,
		SymbolType.SYMBOLPACK_5_SOLID_47,
		SymbolType.SYMBOLPACK_5_SOLID_48,
		SymbolType.SYMBOLPACK_6_SOLID_11,
		SymbolType.SYMBOLPACK_6_SOLID_12,
		SymbolType.SYMBOLPACK_6_SOLID_13,
		SymbolType.SYMBOLPACK_6_SOLID_14,
		SymbolType.SYMBOLPACK_6_SOLID_15,
		SymbolType.SYMBOLPACK_6_SOLID_16,
		SymbolType.SYMBOLPACK_6_SOLID_17,
		SymbolType.SYMBOLPACK_6_SOLID_18,
		SymbolType.SYMBOLPACK_6_SOLID_19,
		SymbolType.SYMBOLPACK_6_SOLID_20,
		SymbolType.SYMBOLPACK_6_SOLID_21,
		SymbolType.SYMBOLPACK_6_SOLID_22,
		SymbolType.SYMBOLPACK_6_SOLID_23,
		SymbolType.SYMBOLPACK_6_SOLID_24,
		SymbolType.SYMBOLPACK_6_SOLID_25,
		SymbolType.SYMBOLPACK_6_SOLID_26,
		SymbolType.SYMBOLPACK_6_SOLID_27,
		SymbolType.SYMBOLPACK_6_SOLID_28,
		SymbolType.SYMBOLPACK_6_SOLID_29,
		SymbolType.SYMBOLPACK_6_SOLID_30,
		SymbolType.SYMBOLPACK_6_SOLID_31,
		SymbolType.SYMBOLPACK_6_SOLID_32,
		SymbolType.SYMBOLPACK_6_SOLID_33,
		SymbolType.SYMBOLPACK_6_SOLID_34,
		SymbolType.SYMBOLPACK_6_SOLID_35,
		SymbolType.SYMBOLPACK_6_SOLID_36,
		SymbolType.SYMBOLPACK_6_SOLID_37,
		SymbolType.SYMBOLPACK_6_SOLID_38,
		SymbolType.SYMBOLPACK_6_SOLID_39,
		SymbolType.SYMBOLPACK_6_SOLID_40,
		SymbolType.SYMBOLPACK_6_SOLID_41,
		SymbolType.SYMBOLPACK_6_SOLID_42,
		SymbolType.SYMBOLPACK_6_SOLID_43,
		SymbolType.SYMBOLPACK_6_SOLID_44,
		SymbolType.SYMBOLPACK_6_SOLID_45,
		SymbolType.SYMBOLPACK_6_SOLID_46,
		SymbolType.SYMBOLPACK_6_SOLID_47,
		SymbolType.SYMBOLPACK_6_SOLID_48
	]
);

const defaultGeneralCharactersPack = new SymbolSystemPack(
	"general_characters",
	"Characters",
	"ALL",
	"General",
	[
		SymbolType.SYMBOLPACK_2_37,
		SymbolType.SYMBOLPACK_2_38,
		SymbolType.SYMBOLPACK_2_39,
		SymbolType.SYMBOLPACK_2_40,
		SymbolType.SYMBOLPACK_2_41,
		SymbolType.SYMBOLPACK_2_42,
		SymbolType.SYMBOLPACK_2_43,
		SymbolType.SYMBOLPACK_2_44,
		SymbolType.SYMBOLPACK_2_45,
		SymbolType.SYMBOLPACK_2_46,
		SymbolType.SYMBOLPACK_2_47,
		SymbolType.SYMBOLPACK_2_48,
		SymbolType.SYMBOLPACK_3_37,
		SymbolType.SYMBOLPACK_3_38,
		SymbolType.SYMBOLPACK_3_39,
		SymbolType.SYMBOLPACK_3_40,
		SymbolType.SYMBOLPACK_3_41,
		SymbolType.SYMBOLPACK_3_42,
		SymbolType.SYMBOLPACK_3_43,
		SymbolType.SYMBOLPACK_3_44,
		SymbolType.SYMBOLPACK_3_45,
		SymbolType.SYMBOLPACK_3_46,
		SymbolType.SYMBOLPACK_3_47,
		SymbolType.SYMBOLPACK_3_48,
		SymbolType.SYMBOLPACK_4_37,
		SymbolType.SYMBOLPACK_4_38,
		SymbolType.SYMBOLPACK_4_39,
		SymbolType.SYMBOLPACK_4_40,
		SymbolType.SYMBOLPACK_4_41,
		SymbolType.SYMBOLPACK_4_42,
		SymbolType.SYMBOLPACK_4_43,
		SymbolType.SYMBOLPACK_4_44,
		SymbolType.SYMBOLPACK_4_45,
		SymbolType.SYMBOLPACK_4_46,
		SymbolType.SYMBOLPACK_4_47,
		SymbolType.SYMBOLPACK_4_48,
		SymbolType.SYMBOLPACK_5_37,
		SymbolType.SYMBOLPACK_5_38,
		SymbolType.SYMBOLPACK_5_39,
		SymbolType.SYMBOLPACK_5_40,
		SymbolType.SYMBOLPACK_5_41,
		SymbolType.SYMBOLPACK_5_42,
		SymbolType.SYMBOLPACK_5_43,
		SymbolType.SYMBOLPACK_5_44,
		SymbolType.SYMBOLPACK_5_45,
		SymbolType.SYMBOLPACK_5_46,
		SymbolType.SYMBOLPACK_5_47,
		SymbolType.SYMBOLPACK_5_48
	]
);

const defaultGeneralNumbersPack = new SymbolSystemPack(
	"general_numbers",
	"Numbers",
	"ALL",
	"General",
	[
		SymbolType.SYMBOLPACK_2_01,
		SymbolType.SYMBOLPACK_2_02,
		SymbolType.SYMBOLPACK_2_03,
		SymbolType.SYMBOLPACK_2_04,
		SymbolType.SYMBOLPACK_2_05,
		SymbolType.SYMBOLPACK_2_06,
		SymbolType.SYMBOLPACK_2_07,
		SymbolType.SYMBOLPACK_2_08,
		SymbolType.SYMBOLPACK_2_09,
		SymbolType.SYMBOLPACK_2_10,
		SymbolType.SYMBOLPACK_3_01,
		SymbolType.SYMBOLPACK_3_02,
		SymbolType.SYMBOLPACK_3_03,
		SymbolType.SYMBOLPACK_3_04,
		SymbolType.SYMBOLPACK_3_05,
		SymbolType.SYMBOLPACK_3_06,
		SymbolType.SYMBOLPACK_3_07,
		SymbolType.SYMBOLPACK_3_08,
		SymbolType.SYMBOLPACK_3_09,
		SymbolType.SYMBOLPACK_3_10,
		SymbolType.SYMBOLPACK_4_01,
		SymbolType.SYMBOLPACK_4_02,
		SymbolType.SYMBOLPACK_4_03,
		SymbolType.SYMBOLPACK_4_04,
		SymbolType.SYMBOLPACK_4_05,
		SymbolType.SYMBOLPACK_4_06,
		SymbolType.SYMBOLPACK_4_07,
		SymbolType.SYMBOLPACK_4_08,
		SymbolType.SYMBOLPACK_4_09,
		SymbolType.SYMBOLPACK_4_10,
		SymbolType.SYMBOLPACK_5_01,
		SymbolType.SYMBOLPACK_5_02,
		SymbolType.SYMBOLPACK_5_03,
		SymbolType.SYMBOLPACK_5_04,
		SymbolType.SYMBOLPACK_5_05,
		SymbolType.SYMBOLPACK_5_06,
		SymbolType.SYMBOLPACK_5_07,
		SymbolType.SYMBOLPACK_5_08,
		SymbolType.SYMBOLPACK_5_09,
		SymbolType.SYMBOLPACK_5_10,
		SymbolType.SYMBOLPACK_6_01,
		SymbolType.SYMBOLPACK_6_02,
		SymbolType.SYMBOLPACK_6_03,
		SymbolType.SYMBOLPACK_6_04,
		SymbolType.SYMBOLPACK_6_05,
		SymbolType.SYMBOLPACK_6_06,
		SymbolType.SYMBOLPACK_6_07,
		SymbolType.SYMBOLPACK_6_08,
		SymbolType.SYMBOLPACK_6_09,
		SymbolType.SYMBOLPACK_6_10,
		SymbolType.SYMBOLPACK_3_SOLID_01,
		SymbolType.SYMBOLPACK_3_SOLID_02,
		SymbolType.SYMBOLPACK_3_SOLID_03,
		SymbolType.SYMBOLPACK_3_SOLID_04,
		SymbolType.SYMBOLPACK_3_SOLID_05,
		SymbolType.SYMBOLPACK_3_SOLID_06,
		SymbolType.SYMBOLPACK_3_SOLID_07,
		SymbolType.SYMBOLPACK_3_SOLID_08,
		SymbolType.SYMBOLPACK_3_SOLID_09,
		SymbolType.SYMBOLPACK_3_SOLID_10,
		SymbolType.SYMBOLPACK_4_SOLID_01,
		SymbolType.SYMBOLPACK_4_SOLID_02,
		SymbolType.SYMBOLPACK_4_SOLID_03,
		SymbolType.SYMBOLPACK_4_SOLID_04,
		SymbolType.SYMBOLPACK_4_SOLID_05,
		SymbolType.SYMBOLPACK_4_SOLID_06,
		SymbolType.SYMBOLPACK_4_SOLID_07,
		SymbolType.SYMBOLPACK_4_SOLID_08,
		SymbolType.SYMBOLPACK_4_SOLID_09,
		SymbolType.SYMBOLPACK_4_SOLID_10,
		SymbolType.SYMBOLPACK_5_SOLID_01,
		SymbolType.SYMBOLPACK_5_SOLID_02,
		SymbolType.SYMBOLPACK_5_SOLID_03,
		SymbolType.SYMBOLPACK_5_SOLID_04,
		SymbolType.SYMBOLPACK_5_SOLID_05,
		SymbolType.SYMBOLPACK_5_SOLID_06,
		SymbolType.SYMBOLPACK_5_SOLID_07,
		SymbolType.SYMBOLPACK_5_SOLID_08,
		SymbolType.SYMBOLPACK_5_SOLID_09,
		SymbolType.SYMBOLPACK_5_SOLID_10,
		SymbolType.SYMBOLPACK_6_SOLID_01,
		SymbolType.SYMBOLPACK_6_SOLID_02,
		SymbolType.SYMBOLPACK_6_SOLID_03,
		SymbolType.SYMBOLPACK_6_SOLID_04,
		SymbolType.SYMBOLPACK_6_SOLID_05,
		SymbolType.SYMBOLPACK_6_SOLID_06,
		SymbolType.SYMBOLPACK_6_SOLID_07,
		SymbolType.SYMBOLPACK_6_SOLID_08,
		SymbolType.SYMBOLPACK_6_SOLID_09,
		SymbolType.SYMBOLPACK_6_SOLID_10
	]
);

const defaultCommunicationsPack = new SymbolSystemPack(
	"communications",
	"Communications",
	"Electrical",
	"Communications",
	[
		SymbolType.SYMBOLPACK_NEW_232,
		SymbolType.SYMBOLPACK_NEW_233,
		SymbolType.SYMBOLPACK_NEW_234,
		SymbolType.SYMBOLPACK_NEW_235,
		SymbolType.SYMBOLPACK_NEW_236,
		SymbolType.SYMBOLPACK_NEW_237,
		SymbolType.SYMBOLPACK_NEW_201,
		SymbolType.SYMBOLPACK_NEW_202,
		SymbolType.SYMBOLPACK_NEW_203,
		SymbolType.SYMBOLPACK_NEW_204,
		SymbolType.SYMBOLPACK_NEW_205,
		SymbolType.SYMBOLPACK_NEW_206,
		SymbolType.SYMBOLPACK_NEW_207,
	]
);

const defaultEmergencyLightingPack = new SymbolSystemPack(
	"emergency_lighting",
	"Emergency Lighting",
	"Electrical",
	"Lighting",
	[
		SymbolType.SYMBOLPACK_NEW_196,
		SymbolType.SYMBOLPACK_NEW_197,
		SymbolType.SYMBOLPACK_NEW_198,
		SymbolType.SYMBOLPACK_NEW_199,
		SymbolType.SYMBOLPACK_NEW_200,
	]
);


const defaultLighting2Pack = new SymbolSystemPack(
	"lighting_2",
	"Lighting 2",
	"Electrical",
	"Lighting",
	[
		SymbolType.SYMBOLPACK_NEW_260,
		SymbolType.SYMBOLPACK_NEW_261,
		SymbolType.SYMBOLPACK_NEW_262,
		SymbolType.SYMBOLPACK_NEW_263,
		SymbolType.SYMBOLPACK_NEW_264,
		SymbolType.SYMBOLPACK_NEW_265,
		SymbolType.SYMBOLPACK_NEW_266
	]
);

const defaultPlumbing2Pack = new SymbolSystemPack(
	"plumbing_2",
	"Plumbing 2",
	"Plumbing",
	"Plumbing",
	[
		SymbolType.SYMBOLPACK_NEW_267,
		SymbolType.SYMBOLPACK_NEW_268,
		SymbolType.SYMBOLPACK_NEW_269,
		SymbolType.SYMBOLPACK_NEW_270,
		SymbolType.SYMBOLPACK_NEW_271,
		SymbolType.SYMBOLPACK_NEW_272,
		SymbolType.SYMBOLPACK_NEW_273,
		SymbolType.SYMBOLPACK_NEW_274,
		SymbolType.SYMBOLPACK_NEW_276,
		SymbolType.SYMBOLPACK_NEW_277,
		SymbolType.SYMBOLPACK_NEW_278,
		SymbolType.SYMBOLPACK_NEW_279,
		SymbolType.SYMBOLPACK_NEW_280,
		SymbolType.SYMBOLPACK_NEW_281,
		SymbolType.SYMBOLPACK_NEW_284,
		SymbolType.SYMBOLPACK_NEW_285,
		SymbolType.SYMBOLPACK_NEW_286,
		SymbolType.SYMBOLPACK_NEW_287,
		SymbolType.SYMBOLPACK_NEW_288,
		SymbolType.SYMBOLPACK_NEW_289,
		SymbolType.SYMBOLPACK_NEW_290,
		SymbolType.SYMBOLPACK_NEW_291,
		SymbolType.SYMBOLPACK_NEW_292,
		SymbolType.SYMBOLPACK_NEW_293,
		SymbolType.SYMBOLPACK_NEW_294,
		SymbolType.SYMBOLPACK_NEW_295,
		SymbolType.SYMBOLPACK_NEW_296,
		SymbolType.SYMBOLPACK_NEW_297,
		SymbolType.SYMBOLPACK_NEW_298,
		SymbolType.SYMBOLPACK_NEW_299,
		SymbolType.SYMBOLPACK_NEW_300,
		SymbolType.SYMBOLPACK_NEW_301,
		SymbolType.SYMBOLPACK_NEW_302,
		SymbolType.SYMBOLPACK_NEW_303,
		SymbolType.SYMBOLPACK_NEW_304,
		SymbolType.SYMBOLPACK_NEW_305,
		SymbolType.SYMBOLPACK_NEW_306,
		SymbolType.SYMBOLPACK_NEW_307,
		SymbolType.SYMBOLPACK_NEW_308,
		SymbolType.SYMBOLPACK_NEW_309,
		SymbolType.SYMBOLPACK_NEW_310,
		SymbolType.SYMBOLPACK_NEW_311,
		SymbolType.SYMBOLPACK_NEW_312,
		SymbolType.SYMBOLPACK_NEW_313,
		SymbolType.SYMBOLPACK_NEW_314,
		SymbolType.SYMBOLPACK_NEW_315,
		SymbolType.SYMBOLPACK_NEW_316,
		SymbolType.SYMBOLPACK_NEW_317,
		SymbolType.SYMBOLPACK_NEW_318,
		SymbolType.SYMBOLPACK_NEW_319,
		SymbolType.SYMBOLPACK_NEW_320,
		SymbolType.SYMBOLPACK_NEW_321,
		SymbolType.SYMBOLPACK_NEW_322,
		SymbolType.SYMBOLPACK_NEW_324,
		SymbolType.SYMBOLPACK_NEW_325,
		SymbolType.SYMBOLPACK_NEW_326,
		SymbolType.SYMBOLPACK_NEW_327,
		SymbolType.SYMBOLPACK_NEW_328,
		SymbolType.SYMBOLPACK_NEW_329,
		SymbolType.SYMBOLPACK_NEW_330,
		SymbolType.SYMBOLPACK_NEW_331,
		SymbolType.SYMBOLPACK_NEW_332,
	]
);

const defaultPower1Pack = new SymbolSystemPack(
	"power_1",
	"Power 1",
	"Electrical",
	"Power",
	[
		SymbolType.SYMBOLPACK_NEW_208,
		SymbolType.SYMBOLPACK_NEW_209,
		SymbolType.SYMBOLPACK_NEW_210,
		SymbolType.SYMBOLPACK_NEW_211,
		SymbolType.SYMBOLPACK_NEW_212,
		SymbolType.SYMBOLPACK_NEW_213,
		SymbolType.SYMBOLPACK_NEW_214,
		SymbolType.SYMBOLPACK_NEW_215,
		SymbolType.SYMBOLPACK_NEW_216
	]
);

const defaultSwitchBoardSchematicsPack = new SymbolSystemPack(
	"switch_board_schematics",
	"Switch Board Schematics",
	"Electrical",
	"Power",
	[
		SymbolType.SYMBOLPACK_NEW_238,
		SymbolType.SYMBOLPACK_NEW_239,
		SymbolType.SYMBOLPACK_NEW_240,
		SymbolType.SYMBOLPACK_NEW_241,
		SymbolType.SYMBOLPACK_NEW_242,
		SymbolType.SYMBOLPACK_NEW_243,
		SymbolType.SYMBOLPACK_NEW_244,
		SymbolType.SYMBOLPACK_NEW_245,
		SymbolType.SYMBOLPACK_NEW_246,
		SymbolType.SYMBOLPACK_NEW_247,
		SymbolType.SYMBOLPACK_NEW_248,
		SymbolType.SYMBOLPACK_NEW_249,
		SymbolType.SYMBOLPACK_NEW_250,
		SymbolType.SYMBOLPACK_NEW_251,
		SymbolType.SYMBOLPACK_NEW_252,
		SymbolType.SYMBOLPACK_NEW_253,
		SymbolType.SYMBOLPACK_NEW_254,
		SymbolType.SYMBOLPACK_NEW_323,
	]
);

const defaultSymbolPacks = [
	defaultGeneralPack,
	defaultBasicPack,
	defaultElectricalActuatorsPack,
	defaultFireGeneralPack,
	defaultSecuritySystemPack,
	defaultLightingSystemPack,
	defaultPlumbingSystemPack,
	defaultHvacSystemPack,
	defaultGeneralLettersPack,
	defaultGeneralCharactersPack,
	defaultGeneralNumbersPack,
	defaultCommunicationsPack,
	defaultEmergencyLightingPack,
	defaultLighting2Pack,
	defaultPlumbing2Pack,
	defaultPower1Pack,
	defaultSwitchBoardSchematicsPack
];

export const getDefaultSymbolPack = (id: string): SymbolSystemPack | undefined => {
	return defaultSymbolPacks.find(pack => pack.id === id);
};

export const getSortedDefaultSymbolPacks = (sortConfig: SortConfig<SymbolSystemPack>) => {
	let packs: SymbolSystemPack[];
	switch (sortConfig.key) {
		case "name":
			packs = defaultSymbolPacks.sort(SymbolSystemPack.sortByName);
			break;
		case "industry":
			packs = defaultSymbolPacks.sort(SymbolSystemPack.sortByIndustry);
			break;
		case "group":
			packs = defaultSymbolPacks.sort(SymbolSystemPack.sortByGroup);
			break;
		default:
			packs = defaultSymbolPacks;
			break;
	}
	return sortConfig.direction === SortDirection.ASC ? packs : packs.reverse();
};

const defaultGeneralGroup = new SymbolGroup("general", "General");
const defaultElectricalGroup = new SymbolGroup("electrical", "Electrical");
const defaultFireGroup = new SymbolGroup("fire", "Fire");
const defaultSecurityGroup = new SymbolGroup("security", "Security");
const defaultLightingGroup = new SymbolGroup("lighting", "Lighting");
const defaultPlumbingGroup = new SymbolGroup("plumbing", "Plumbing");
const defaultHvacGroup = new SymbolGroup("hvac", "HVAC");
const defaultCommunicationsGroup = new SymbolGroup("communications", "Communications");
const defaultPowerGroup = new SymbolGroup("power", "Power");

export const defaultSymbolGroups = [
	defaultGeneralGroup,
	defaultElectricalGroup,
	defaultFireGroup,
	defaultSecurityGroup,
	defaultLightingGroup,
	defaultPlumbingGroup,
	defaultHvacGroup,
	defaultCommunicationsGroup,
	defaultPowerGroup
];

export const getDefaultGroupPacks = (groupId: string): SymbolSystemPack[] => {
	const group = defaultSymbolGroups.find(group => group.id === groupId);
	if (group) {
		return defaultSymbolPacks.filter(pack => pack.group === group.name);
	}
	return [];
};

export const isSystemSymbolGroup = (groupId: string): boolean => {
	return defaultSymbolGroups.some(group => group.id === groupId);
};
