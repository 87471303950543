import React, {useState} from "react";
import "./treeCostCenterSummary.scss";
import AccordionBar from "../../../../../components/ui/AccordionBar";
import IconFolderOutline from "../../../../../components/icons/IconFolderOutline";
import {CostCenter} from "../../../../../models/CostCenter";
import {useTranslation} from "react-i18next";
import ProjectCCsSummaryDetails from "./ProjectCCsSummaryDetails";
import {DrawingType} from "../../../../../models/enums";
import {useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";
import Section from "../../../../../models/Section";
import useCostCenterSummary from "../hooks/useCostCenterSummary";

interface ITreeCostCenterSummaryProps {
	section: Section,
	costCenter: CostCenter,
}

const TreeCostCenterSummary: React.FC<ITreeCostCenterSummaryProps> = ({costCenter, section}) => {

	const {selectionState: {isCostCenterChecked, onCostCenterToggle}} = useSyncMaterialsContext()
	const {measurements} = useCostCenterSummary(costCenter.id, true, 0);
	const {t} = useTranslation();
	const [open, setOpen] = useState(true);
	const toggleOpen = () => setOpen(!open);

	const cls = [
		"tree-cost-center-summary",
		open && "is-open"
	].filter(Boolean).join(" ");

	/**
	 * Project summary view requires takeoffs tree data
	 */
	const drawings = costCenter.drawings.filter(drawing => drawing.drawingType === DrawingType.TAKE_OFF)

	return (
		<div className={cls}>
			<div className="tree-cost-center-summary_header">
				<div className="tree-cost-center-summary_header_bar">
					<input type={"checkbox"}
						   checked={isCostCenterChecked(costCenter)}
						   onChange={() => onCostCenterToggle(section, costCenter)}
						   disabled={!costCenter.hasCompletedDrawings() || measurements.length === 0}/>
					<AccordionBar label={costCenter.name} onClick={toggleOpen} isOpen={open} icon={<IconFolderOutline/>}/>
				</div>
			</div>
			<div className="tree-cost-center-summary_content">
				{drawings.length === 0 ? (
					<div className="tree-cost-center-summary_content_no-records">
						{t("projects.details.noDrawingsInSummaryTableMessage")}
					</div>
				) : (
					<ProjectCCsSummaryDetails costCenterId={costCenter.id} loadData={open}/>
				)}
			</div>
		</div>
	);
};

export default TreeCostCenterSummary;
