import JSZip from "jszip";

export class ZipGenerator {
	zip: JSZip;

	constructor() {
		this.zip = new JSZip();
	}

	addImage(imgData: File, imageName: string) {
		this.zip.file(imageName, imgData);
	}

	generateZip(): Promise<Blob> {
		return this.zip.generateAsync({type: "blob"})
	}
}
