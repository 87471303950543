import {ProjectLogItemApiDto} from "./restModels";
import moment from "moment";

export default class ProjectLogItem {
	constructor(
		public readonly id: string,
		public readonly dateTime: moment.Moment,
		public readonly userName: string,
		public readonly action: string
	) {}

	public static fromJson(json: ProjectLogItemApiDto): ProjectLogItem {
		const dateTime = moment(json.dateTime);
		return new ProjectLogItem(json.id, dateTime, json.userName, json.action);
	}
}
