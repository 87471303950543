import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlanUploadRootState} from "../../planUploadStore";
import {PlanUploadViewState} from "../../types";
import {PageScaleRatioPayload} from "../../../../../base-konva/types";
import {initialState as baseInitialState, reducers} from "../../../../../base-konva/features/view";
import {PageSizeFormat} from "../../../../../base-konva/enums";

const initialState: PlanUploadViewState = {...baseInitialState}

const planUploadViewSlice = createSlice({
	name: 'plan-upload-view',
	initialState,
	reducers: {
		...reducers,
		reset: (_, {payload}: PayloadAction<PageScaleRatioPayload | null>) => ({
			...initialState,
			scaleOptions: {
				ratioInput: payload?.scaleRatio,
				pageSize: payload?.pageFormat ?? PageSizeFormat.A4
			}
		}),
		setDimensions: (state, {payload}: PayloadAction<typeof initialState.zoom.viewportSize>) => {
			const {height, width} = payload;
			state.zoom.viewportSize = {height, width}
		},
	}
})

export const planUploadViewReducer = planUploadViewSlice.reducer;
export const planUploadViewActions = planUploadViewSlice.actions;
export const selectPlanUploadZoomState = (state: PlanUploadRootState) => state.planUploadView.zoom;
export const selectPlanUploadPanInProgress = (state: PlanUploadRootState) => state.planUploadView.panInProgress;
export const selectPlanUploadScaleOptions = (state: PlanUploadRootState) => state.planUploadView.scaleOptions;
export const selectPlanUploadExportInProgress = (state: PlanUploadRootState) => state.planUploadView.exportInProgress;
export const selectPlanUploadHighlightState = (state: PlanUploadRootState) => state.planUploadView.highlight;
export const selectPlanUploadSelectionState = (state: PlanUploadRootState) => state.planUploadView.selection;
export const selectPlanUploadRemoteContentLoadedInfo = (state: PlanUploadRootState) => state.planUploadView.remoteContentLoadedInfo;
export const selectPlanUploadEditorIsDirty = (state: PlanUploadRootState) => state.planUploadView.editorIsDirty;
