import {useDispatch, useSelector} from "react-redux";
import {lengthActions, selectActiveLengthId, selectLengths} from "./lengthSlice";

export function useLengthPointContextMenuActions() {
	const dispatch = useDispatch()
	const activeLengthId = useSelector(selectActiveLengthId);
	const activeLength = useSelector(selectLengths).find(length => length.id === activeLengthId)

	function activateLengthPoint(pointId: string) {
		dispatch(lengthActions.activateLengthPoint({id: pointId}))
	}

	function addDropToActive(pointId: string) {
		if (!activeLength?.drops.some(d => d.pointId === pointId)) {
			dispatch(lengthActions.addDropToActive({pointId, value: 0}))
		}
	}

	return {
		activateLengthPoint,
		addDropToActive
	}
}