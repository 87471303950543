import {PaginationConfig} from "../models/interfaces";

export enum PaginationHeaders {
	PAGE_NUMBER = "X-PAGE-NUMBER",
	PAGE_SIZE = "X-PAGE-SIZE",
	TOTAL_ELEMENTS = "X-PAGE-TOTAL-ELEMENTS",
	TOTAL_PAGES = "X-PAGE-TOTAL-PAGES"
}

export function getPaginationFromHeaders(headers: any): PaginationConfig {
	// Axios always make header names lowercase.
	const pageNumber = parseInt(headers[PaginationHeaders.PAGE_NUMBER.toLowerCase()]) || null;
	const pageSize = parseInt(headers[PaginationHeaders.PAGE_SIZE.toLowerCase()]) || null;
	const totalElements = parseInt(headers[PaginationHeaders.TOTAL_ELEMENTS.toLowerCase()]) || null;
	const totalPages = parseInt(headers[PaginationHeaders.TOTAL_PAGES.toLowerCase()]) || null;
	return {
		pageNumber, pageSize, totalElements, totalPages
	}
}