import {BaseScaleLayer} from "../../../../../base-konva/features/scale";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectPlanUploadStageConfig} from "../config/planUploadConfigSlice";
import {selectPlanUploadPanInProgress} from "../view/planUploadViewSlice";
import {planUploadScaleActions, selectPlanUploadScalePoints} from "./planUploadScaleSlice";

const PlanUploadScaleLayer: React.FC<{
	listening?: boolean
}> = ({
	listening = false
}) => {
	const dispatch = useDispatch()

	const scalePoints = useSelector(selectPlanUploadScalePoints);
	const stageConfig = useSelector(selectPlanUploadStageConfig);
	const panInProgress = useSelector(selectPlanUploadPanInProgress);

	return (
		<BaseScaleLayer
			listening={listening}
			addScalePoint={position => {dispatch(planUploadScaleActions.addScalePoint(position))}}
			scalePoints={scalePoints}
			stageConfig={stageConfig}
			panInProgress={panInProgress}
		/>
	)
}

export {PlanUploadScaleLayer}