import React, {useCallback} from 'react';
import "./editTextContainer.scss";
import Konva from "konva";
import {BaseEditTextContainer} from "../../../../../base-konva/features/text/BaseEditTextContainer";
import {selectActiveTextGroupId, selectTextGroups, selectTextSelectorStyle, textActions} from "./textSlice";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveTool} from "../config/configSlice";
import {EditorTool} from "../../models/enums";
import {selectZoomState} from "../view/viewSlice";
import {SaveTextItemActionPayload} from "../../models/editor";

type EditTextContainerProps = {
	whiteCanvasRef: React.RefObject<Konva.Rect>
}
export const EditTextContainer: React.FC<EditTextContainerProps> = ({whiteCanvasRef}) => {
	const textGroups = useSelector(selectTextGroups);
	const activeTextGroupId = useSelector(selectActiveTextGroupId);
	const group = textGroups.find(group => group.id === activeTextGroupId)
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const isTextTool = activeTool === EditorTool.TEXT;
	const zoomState = useSelector(selectZoomState)
	const style = useSelector(selectTextSelectorStyle);

	const deleteTextItem = useCallback((id: string) => {
		dispatch(textActions.deleteTextItem({id}))
	}, [dispatch])

	function saveTextItem(payload: SaveTextItemActionPayload) {
		dispatch(textActions.saveTextItem(payload))
	}

	return (
		<BaseEditTextContainer
			whiteCanvasRef={whiteCanvasRef}
			group={group}
			isTextTool={isTextTool}
			zoomState={zoomState}
			style={style}
			deleteTextItem={deleteTextItem}
			saveTextItem={saveTextItem}
		/>
	)
};
