import React from "react";
import {PenGroup, PenLine} from "../../../../../base-konva/types";
import {BasePenElementKonva, BasePenEventHandlers} from "../../../../../base-konva/features/pen";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {ShapeConfig} from "konva/types/Shape";
import {getDashTypePointArray} from "../../../../../project-drawings/features/editor/utils";
import {useDispatch, useSelector} from "react-redux";
import {usePlanUploadActivate} from "../../hooks/planUploadActivate";
import {usePlanUploadGetSelectionShapeStyle, usePlanUploadSelectSetter} from "../../hooks/planUploadSelect";
import {planUploadConfigActions, selectPlanUploadActiveTool} from "../config/planUploadConfigSlice";
import {PlanUploadEditorTool} from "../../types";
import {usePlanUploadGetHighlightShapeStyle, usePlanUploadHighlightSetter} from "../../hooks/planUploadHighlight";
import {DrawingItemType} from "../../../../../base-konva/enums";
import {selectPlanUploadExportInProgress} from "../view/planUploadViewSlice";

const PlanUploadPenElement: React.FC<{
	line: PenLine
	group: PenGroup,
	isDrawing: boolean
}> = ({line, group, isDrawing}) => {
	const dispatch = useDispatch();
	const activeTool = useSelector(selectPlanUploadActiveTool);
	const activateActions = usePlanUploadActivate();
	const selectActions = usePlanUploadSelectSetter();
	const highlightActions = usePlanUploadHighlightSetter(line.id);
	const {getSelectionStyle} = usePlanUploadGetSelectionShapeStyle()
	const {getHighlightStyle} = usePlanUploadGetHighlightShapeStyle()
	const exportInProgress = useSelector(selectPlanUploadExportInProgress);

	const selectionStyle = exportInProgress ? undefined : getSelectionStyle(highlighted => (
		highlighted.some(item => (
			item.type === DrawingItemType.PEN &&
			item.itemId === group.id &&
			(item.subItemIds && item.subItemIds.some(id => id === line.id))
		))
	))
	const highlightStyle = exportInProgress ? undefined : getHighlightStyle(
		(id) => id === line.id || id === group.id
	);


	function selectElement() {
		dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: PlanUploadEditorTool.PEN}))
		dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: PlanUploadEditorTool.MEASUREMENT_SELECT}))
		activateActions.activatePen(line.id, group.id)
		selectActions.clearSelection();
		selectActions.setSelection(DrawingItemType.PEN, group.id, undefined, [line.id])
	}

	const events: BasePenEventHandlers = {
		onMouseEnter: highlightActions.setHighlight,
		onMouseLeave: highlightActions.clearHighlight
	}
	if (activeTool === PlanUploadEditorTool.MEASUREMENT_SELECT) {
		events.onClick = selectElement
		events.onTap = selectElement
	}

	const style: ShapeConfig = {
		...group.style,
		...highlightStyle,
		...selectionStyle,
		lineCap: "round",
		lineJoin: "round",
		dash: getDashTypePointArray(group.style.dashType, group.style.strokeWidth)
	}

	const eventsRef: React.MutableRefObject<BasePenEventHandlers> = useUpdatedRef(
		isDrawing ? {} : events
	)

	return (
		<BasePenElementKonva
			visible={group.visible && line.visible}
			line={line}
			style={style}
			eventsRef={eventsRef}
		/>
	)
}

export {PlanUploadPenElement}