import {RGBColor} from "react-color";
import moment from "moment";
import {FileType, UnitsType} from "../models/enums";
import {cubicMetersToCubicFeet, metersToFeet, squareMetersToSquareFeet} from "./ConversionUtils";
import i18n from "../i18n";
import Company from "../models/Company";
import momentTZ from "moment-timezone";
import {mimeTypeMap} from "../features/project-drawings/features/editor/constants";

export const uuidRegexp = /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/
export const maxFourDecimalsNumberRegexp = /^[0-9]*([.|,][0-9]{1,4})?$/;
// Default date and time format according to ISO 8601
export const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";
export const DEFAULT_TIMEZONE = "UTC";

export function capitalizeString(text: string): string {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function enumKeyToCapitalizeCase(text: string): string {
	return text.split("_").map(part => capitalizeString(part)).join(" ");
}

/**
 * Takes a php date format and converts it into a JS format for moment.
 * https://stackoverflow.com/questions/30186611/php-dateformat-to-moment-js-format
 * @param phpFormat
 */
export function phpDateFormatToMomentJsFormat(phpFormat: string) {
	const conversions: { [key: string]: string } = {
		'd': 'DD',
		'D': 'ddd',
		'j': 'D',
		'l': 'dddd',
		'N': 'E',
		'S': 'o',
		'w': 'e',
		'z': 'DDD',
		'W': 'W',
		'F': 'MMMM',
		'm': 'MM',
		'M': 'MMM',
		'n': 'M',
		't': '',
		'L': '',
		'o': 'YYYY',
		'Y': 'YYYY',
		'y': 'YY',
		'a': 'a',
		'A': 'A',
		'B': '',
		'g': 'h',
		'G': 'H',
		'h': 'hh',
		'H': 'HH',
		'i': 'mm',
		's': 'ss',
		'u': 'SSS',
		'e': 'zz',
		'I': '',
		'O': '',
		'P': '',
		'T': '',
		'Z': '',
		'c': '',
		'r': '',
		'U': 'X',
	};
	return phpFormat.split("").map(char => conversions[char] || char).join("");
}

export function formatLength(value: number | undefined, unitsType: UnitsType | undefined): string {
	if (value !== undefined) {
		if (unitsType === UnitsType.METRIC) {
			return `${value.toFixed(2)}m`
		}
		else if (unitsType === UnitsType.IMPERIAL) {
			return `${metersToFeet(value).toFixed(2)}ft`
		}
	}
	return "-";
}

export function formatArea(value: number | undefined, unitsType: UnitsType | undefined): string {
	const upperIndex2Char = String.fromCharCode(178);
	if (value !== undefined) {
		if (unitsType === UnitsType.METRIC) {
			return `${value.toFixed(2)}m${upperIndex2Char}`
		}
		else if (unitsType === UnitsType.IMPERIAL) {
			return `${squareMetersToSquareFeet(value).toFixed(2)}ft${upperIndex2Char}`;
		}
	}
	return "-";
}

export function formatVolume(value: number | undefined, unitsType: UnitsType | undefined): string {
	const upperIndex3Char = String.fromCharCode(179);
	if (value !== undefined) {
		if (unitsType === UnitsType.METRIC) {
			return `${value.toFixed(2)}m${upperIndex3Char}`
		}
		else if (unitsType === UnitsType.IMPERIAL) {
			return `${cubicMetersToCubicFeet(value).toFixed(2)}ft${upperIndex3Char}`;
		}
	}
	return "-";
}

export function formatLabor(labor: number | undefined) {
	return labor ? `${labor}${i18n.t("projects.summary.itemsTable.suffixes.labour", {count: labor})}` : "-";
}

export function formatDate(date: moment.Moment | undefined, company: Company | undefined) {
	const format = company ? company.uiDateFormat : DEFAULT_DATE_FORMAT;
	const timezone = company && momentTZ.tz.names().includes(company.timezone) ? company.timezone : DEFAULT_TIMEZONE;
	return date ? momentTZ(date.toISOString()).tz(timezone, true).format(format) : "-"
}

function opacityHexToDec(h: string): number {
	const intValue = parseInt(h, 16);
	return intValue / 255;
}

export function colorHexToRGB(hex: string): RGBColor {
	const r = parseInt(hex.slice(1, 3), 16);
	const g = parseInt(hex.slice(3, 5), 16);
	const b = parseInt(hex.slice(5, 7), 16);
	const alphaHex = hex.slice(7, 9);
	const a = alphaHex ? opacityHexToDec(alphaHex) : 1;
	return {r, g, b, a};
}

function opacityDecToHex(p: number) {
	const intValue = Math.round(p * 255);
	const hexValue = intValue.toString(16);
	return hexValue.padStart(2, '0').toUpperCase();
}

export function colorRgbToHex(color: RGBColor) {
	const {r, g, b, a} = color;

	const hexColor = '#' + [r, g, b].map(x => {
		const hex = x.toString(16);
		return hex.length === 1 ? '0' + hex : hex
	}).join('').toUpperCase();

	return `${hexColor}${a ? opacityDecToHex(a) : "00"}`;
}

export function getFileExtension(filename: string): string {
	const lastIndexOfDot = filename.lastIndexOf('.');
	return lastIndexOfDot !== -1 ? filename.substr(lastIndexOfDot + 1).toLowerCase() : "";
}

export function getAllSubstrings(str: string) {
	const result = [];

	for (let i = 0; i < str.length; i++) {
		for (let j = i + 1; j < str.length + 1; j++) {
			result.push(str.slice(i, j));
		}
	}
	return result;
}

export function replaceDatesWithIsoDates(str: string, dateFormat: string): string {
	if (dateFormat === DEFAULT_DATE_FORMAT) {
		return str;
	}
	const substrings = getAllSubstrings(str);
	let strWithReplacedDates = str;
	substrings.forEach(substring => {
		const dateSubstring = moment(substring, dateFormat, true);
		if (dateSubstring.isValid()) {
			strWithReplacedDates =
				strWithReplacedDates.replace(substring, dateSubstring.format(DEFAULT_DATE_FORMAT));
		}
	});
	return strWithReplacedDates;
}

export function parsePages(value: string, lastPageNumber: number | undefined) {
	const rangeRegex = /^[0-9]+\s*-+\s*[0-9]+$/; // matches 2-5, 2 - 5 etc.
	let numberItems: number[] = [];
	value.split(",").map(v => v.trim()).filter(Boolean).forEach(v => {
		const numberMatches = v.match(/\d+/g); // matches numbers
		if (v.match(rangeRegex) && numberMatches && numberMatches.length === 2) {
			const rangeStart = Number(numberMatches[0]);
			const rangeEnd = Number(numberMatches[1]);
			const startIndex = lastPageNumber ? Math.min(rangeStart, rangeEnd, lastPageNumber) : Math.min(rangeStart, rangeEnd);
			const endIndex = lastPageNumber ? Math.min(Math.max(rangeStart, rangeEnd), lastPageNumber) :
				Math.max(rangeStart, rangeEnd);
			for (let i = startIndex; i <= endIndex; i++) {
				numberItems.push(i);
			}
		}
		else {
			const itemAsNumber = Number(v);
			if (!isNaN(itemAsNumber) && (!lastPageNumber || itemAsNumber <= lastPageNumber)) {
				numberItems.push(itemAsNumber);
			}
		}
	});
	return Array.from(new Set(numberItems)).sort((a, b) => a > b ? 1 : -1); // using Set to remove duplicates
}

export function removeNotAllowedCharsFromString(value: string) {
	// characters not allowed by backend
	const notAllowedChars = ["/", "?", "<", ">", "\\", ":", "*", "|", '"', "%", ".", "$", "^", "{", "}"];
	let clearValue = value;
	notAllowedChars.forEach(char => clearValue = clearValue.split(char).join(""));
	return clearValue;
}

/**
 * Removes not allowed chars from filename.
 * @param filename
 * @param defaultFilename - set if original filename contains only not allowed chars (this does not contain extension)
 */
export function removeNotAllowedCharsFromFilename(filename: string, defaultFilename: string = "file"): string {
	const fileExt = getFileExtension(filename);
	let newFilename = removeNotAllowedCharsFromString(filename.replace(fileExt, ""));
	// if filename contained only not allowed chars, it will be like ".jpg", so default filename is set.
	if (newFilename.length === 0) {
		newFilename = defaultFilename;
	}
	return fileExt ? `${newFilename}.${fileExt}` : newFilename;
}

export function addCharEveryNChars(text: string | undefined, n: number, char: string) {
	const regexp = new RegExp(`.{0,${n}}`, "g");
	return text?.match(regexp)?.join(' ').trim().replace(/\s/g, char) ?? "";
}

export function fileExtensionToFileType(extension: string): FileType | undefined {
	switch (extension.toLowerCase()) {
		case FileType.JPG:
			return FileType.JPG
		case FileType.JPEG:
			return FileType.JPEG
		case FileType.PNG:
			return FileType.PNG
		case FileType.GIF:
			return FileType.GIF
		case FileType.BMP:
			return FileType.BMP
		case FileType.PDF:
			return FileType.PDF
		default:
			return undefined;
	}
}

export function contentTypeToFileType(contentType: string): FileType | undefined {
	const mimeTypeKeys = Object.keys(mimeTypeMap) as FileType[]
	return mimeTypeKeys.find(key => mimeTypeMap[key] === contentType);
}