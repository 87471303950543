import React from "react";
import "./modalWithHeader.scss";
import Modal from "./Modal";
import IconClose from "../icons/IconClose";
import {get} from "../../utils/ClassNameUtils";
import {Colors} from "../../styles";

interface ModalWithHeaderProps {
	isOpen: boolean,
	title: string,
	customClass?: string,
	onClose: () => void;
}

const ModalWithHeader: React.FC<ModalWithHeaderProps> = ({isOpen, title, customClass, onClose, children}) => {

	return (
		<Modal isOpen={isOpen} onClose={onClose} customClass={get("modal-with-header", customClass ?? "")}>
			<div className="modal-with-header_header">
				<div className="modal-with-header_header_title">{title}</div>
				<span onClick={onClose}><IconClose color={Colors.SIMPROBLUE}/></span>
			</div>
			{children}
		</Modal>
	);
};

export default ModalWithHeader;
