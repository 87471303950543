import {MAX_SCALE} from "../../../project-drawings/features/editor/constants";
import {ZoomState} from "../../types";


type UseBaseZoomActionsProps = {
	zoomState: ZoomState
	setZoomState: (zoomState: ZoomState) => void
}

export function useBaseZoomActions({zoomState, setZoomState}: UseBaseZoomActionsProps) {
	const minZoom = zoomState.scale <= zoomState.minScale;
	const maxZoom = Math.round(zoomState.scale * 100) >= MAX_SCALE * 100;

	function handleZoomOutByPercent(percent: 5 | 10) {
		switch (percent) {
			case 5:
				return handleZoom(-0.05, 100)
			case 10:
				return handleZoom(-0.1, 10)
		}
	}

	function handleZoomInByPercent(percent: 5 | 10) {
		switch (percent) {
			case 5:
				return handleZoom(0.05, 100)
			case 10:
				return handleZoom(0.1, 10)
		}
	}

	function handleZoom(change: number, roundFactor: number) {
		let newScale = Math.round((zoomState.scale + change) * roundFactor) / roundFactor
		if (newScale < zoomState.minScale) {
			newScale = zoomState.minScale
		}
		else if (newScale > MAX_SCALE) {
			newScale = MAX_SCALE
		}
		setZoomState({
			...zoomState, scale: newScale
		})
		return newScale
	}

	function handleZoomReset() {
		setZoomState({
			...zoomState,
			scale: 1
		})
	}

	return {
		minZoom, maxZoom,
		handleZoomOutByPercent,
		handleZoomInByPercent,
		handleZoomReset,
	}
}