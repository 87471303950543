import {Image, Layer, Rect, Stage} from "react-konva";
import React, {useEffect, useMemo, useRef} from "react";
import {Provider, ReactReduxContext, useDispatch, useSelector} from "react-redux";
import Konva from "konva";
import EditorStageLayers from "./EditorStageLayers";
import {UserSettingsDataContextBridge, useUserSettingsDataContext} from "../../../../providers/UserSettingsProvider";
import {ExportMode} from "./models/enums";
import {ProjectPlanDownloadOptions} from "./models/editor";
import LoggerService from "../../../../services/LoggerService";
import NotificationService from "../../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import {selectDrawingLoaded, selectRemoteContentLoadedInfo, selectZoomState} from "./features/view/viewSlice";
import {pdf} from '@react-pdf/renderer';
import {StageAsPDF} from "./StageAsPDF";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {selectStageConfig} from "./features/config/configSlice";
import {ScaleSetDataContextBridge} from "./features/scale/ScaleSetProvider";
import {SymbolCacheDataContextBridge} from "../../../../providers/SymbolCacheDataProvider";
import {IsAnyModalOpenContextBridge} from "../../../../providers/IsAnyModalOpenProvider";
import {EditorDrawnItemsDataProvider} from "./EditorDrawnItemsDataProvider";
import {EditorPanelProvider} from "./features/panel/EditorPanelProvider";
import {useInitScaleInfoState} from "../../../base-konva/features/config";
import {ScaleInfoState} from "../../../base-konva/types";
import {scaleActions, selectScaleConfig} from "./features/scale/scaleSlice";
import {getRotationAwareSizeProps} from "../../../base-konva/utils";

interface EditorProps {
	image: HTMLImageElement | undefined;
	options: ProjectPlanDownloadOptions;
	onSave: (data: Blob) => void
}

export const HiddenEditor: React.FC<EditorProps> = ({image, options, onSave}) => {
	const dispatch = useDispatch();
	const stageRef = useRef<Konva.Stage>(null);
	const hasSaved = useRef(false);
	const {t} = useTranslation();
	const {settings} = useUserSettingsDataContext();
	const {allItemsLoaded} = useSelector(selectRemoteContentLoadedInfo)
	const drawingLoaded = useSelector(selectDrawingLoaded)
	const scaleConfig = useSelector(selectScaleConfig)
	const stageConfig = useSelector(selectStageConfig);
	const zoomState = useSelector(selectZoomState)
	const dataRef = useUpdatedRef({handleExport, options, onSave})

	const isLoading =
		!allItemsLoaded ||
		!drawingLoaded

	useEffect(() => {
		const {handleExport, options, onSave} = dataRef.current
		if (
			!hasSaved.current &&
			!isLoading &&
			stageConfig.width &&
			stageConfig.height &&
			stageRef.current
		) {
			stageRef.current.batchDraw()
			setTimeout(async () => {
				await handleExport(options, onSave)
			})
		}
	}, [dataRef, isLoading, stageConfig]);

	const setScaleInfoState = function(state: ScaleInfoState) {
		dispatch(scaleActions.setScaleInfoState({value: state}))
	}
	useInitScaleInfoState({scaleConfig, settings, setScaleInfoState, stageConfig, isLoading})

	const sizeProps = useMemo(() => {
		return getRotationAwareSizeProps(stageConfig, zoomState.rotation)
	}, [stageConfig, zoomState.rotation])

	return (
		<UserSettingsDataContextBridge.Consumer>{(settings) => (
			<ReactReduxContext.Consumer>{({store}) => (
				<ScaleSetDataContextBridge.Consumer>{scaleData => (
					<SymbolCacheDataContextBridge.Consumer>{symbolCache => (
						<IsAnyModalOpenContextBridge.Consumer>{modalData => (
							<Stage
								ref={stageRef}
								listening={false}
								rotation={zoomState.rotation}
								offsetX={stageConfig.width / 2}
								offsetY={stageConfig.height / 2}
								{...sizeProps}
							>
								<UserSettingsDataContextBridge.Provider value={settings}>
									<Provider store={store}>
										<EditorPanelProvider>
											<EditorDrawnItemsDataProvider>
												<ScaleSetDataContextBridge.Provider value={scaleData}>
													<SymbolCacheDataContextBridge.Provider value={symbolCache}>
														<IsAnyModalOpenContextBridge.Provider value={modalData}>
															<Layer listening={false}>
																<Rect fill={"white"}
																	  x={0} y={0}
																	  width={stageConfig.width}
																	  height={stageConfig.height}/>
																<Image image={image}{...stageConfig.backgroundImagePosition}/>
																<EditorStageLayers/>
															</Layer>
														</IsAnyModalOpenContextBridge.Provider>
													</SymbolCacheDataContextBridge.Provider>
												</ScaleSetDataContextBridge.Provider>
											</EditorDrawnItemsDataProvider>
										</EditorPanelProvider>
									</Provider>
								</UserSettingsDataContextBridge.Provider>
							</Stage>
						)}</IsAnyModalOpenContextBridge.Consumer>
					)}</SymbolCacheDataContextBridge.Consumer>
				)}</ScaleSetDataContextBridge.Consumer>
			)}</ReactReduxContext.Consumer>
		)}</UserSettingsDataContextBridge.Consumer>
	);

	async function handleExport(options: ProjectPlanDownloadOptions, onSave: (data: Blob) => void) {
		hasSaved.current = true
		if (stageRef.current) {
			try {
				if (options.fileType === ExportMode.PDF) {
					const blob = await pdf(<StageAsPDF stage={stageRef.current}/>).toBlob();
					onSave(blob);
				}
				else {
					stageRef.current.toCanvas({}).toBlob((data) => {
						if (data) {
							onSave(data);
						}
					}, options.fileType === ExportMode.JPG ? 'image/jpeg' : 'image/png', 1)
				}
			}
			catch (e) {
				LoggerService.logError(e);
				NotificationService.errorNotification(t("common.error"), t("editor.exportDrawingErrorDesc"))
			}
		}
	}
};
