import React from "react";
import "./buttonsGroup.scss";

interface EditorHeaderButtonsProps {
	children: React.ReactNode,
	customClass?: string;
}

const ButtonsGroup: React.FC<EditorHeaderButtonsProps> = ({children, customClass}) => {
	const cls = ["buttons-group", customClass && customClass].filter(Boolean).join(" ");
	return (
		<div className={cls}>
			{children}
		</div>
	);
};

const ButtonsGroupSpacer: React.FC = () => {
	return (
		<div style={{flexGrow: 1}}/>
	);
};

const ButtonsGroupDivider: React.FC = () => {
	return (
		<div style={{height: 32, width: 1, backgroundColor: "#E2E2E2"}}/>
	);
};

export {ButtonsGroup, ButtonsGroupSpacer, ButtonsGroupDivider}
