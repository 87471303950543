import {SelectionState} from "../../../../../base-konva/types";
import React from "react";
import {Rect} from "react-konva";

type EraseAreaRectanglesProps = {
	erasedAreas: SelectionState[]
}
export const EraseAreaRectangles: React.FC<EraseAreaRectanglesProps> = ({erasedAreas}) => {

	return (
		<>
			{erasedAreas.map((area, index) => <Rect
				key={index}
				fill={"white"}
				{...area}
			/>)}
		</>
	)
};
