import React, {useCallback, useEffect, useMemo, useState} from "react";
import "./filePreviewModal.scss";
import AttachmentApi from "../../../../../api/AttachmentApi";
import {Status} from "../../../../../models/enums";
import {convertPdfToImages} from "../../../../../utils/PdfUtils";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import DataFetchError from "../../../../../components/ui/DataFetchError";
import Spinner from "../../../../../components/Spinner";
import {useInView} from "react-intersection-observer";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import IconArrowSmall from "../../../../project-drawings/features/editor/icons/IconArrowSmall";

type Data =
	| { loadStatus: Status.IDLE }
	| { loadStatus: Status.LOADING }
	| { loadStatus: Status.SUCCESS, previewSources: string[] }
	| { loadStatus: Status.ERROR }

const PdfPreview = ({attachmentId, filename}: {
	attachmentId: string,
	filename: string,
}) => {

	const {t} = useTranslation();
	const [data, setData] = useState<Data>({loadStatus: Status.IDLE})

	useEffect(function getPdfData() {
		setData({loadStatus: Status.LOADING});
		(async function() {
			try {
				const fileDownloadUrl = AttachmentApi.getAttachmentDownloadUrl(attachmentId, filename);
				setData({
					loadStatus: Status.SUCCESS,
					previewSources: await convertPdfToImages(fileDownloadUrl)
				});
			}
			catch (e: any) {
				LoggerService.logError(e);
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.planTemplates.filePreviewErrorDesc")
				);
				setData({loadStatus: Status.ERROR});
			}
		})()
	}, [t, attachmentId, filename])

	return (
		<>
			{data.loadStatus === Status.ERROR && <DataFetchError/>}
			{data.loadStatus === Status.LOADING && <Spinner/>}
			{data.loadStatus === Status.SUCCESS &&
				<div className="file-preview-modal_pdf">
					<Viewer
						filename={filename}
						previewSources={data.previewSources}
					/>
				</div>
			}
		</>
	);
};

type State = {
	node: HTMLDivElement | null | undefined,
	inView: boolean | undefined,
	entry: IntersectionObserverEntry | undefined
}

function Viewer({previewSources, filename}: {
	previewSources: string[],
	filename: string
}) {
	const [itemsDataMap, setItemsDataMap] = useState<Map<number, State>>(new Map());
	const [currentPageData, setCurrentPageData] = useState<{ index: number, itemState: State } | null>(null);

	useEffect(() => {
		const array = Array.from(itemsDataMap.entries()).map(([index, state]) => ({index, state}))
		const visibleItems = array.filter(({state}) => state.inView)
		let item: { index: number, state: State } | null = null

		for (let visibleItem of visibleItems) {
			const {index, state} = visibleItem;
			if (!item) {
				item = {index, state}
			}
			else {
				const {state: itemState} = item
				if (state.entry?.intersectionRatio !== undefined && itemState.entry?.intersectionRatio !== undefined) {
					if (state.entry.intersectionRatio > itemState.entry.intersectionRatio) {
						item = {index, state}
					}
				}
			}
		}
		if (item !== null) {
			const {index, state} = item;
			setCurrentPageData({index, itemState: state})
		}
	}, [itemsDataMap])

	const onInViewItemChange = useCallback((
		index: number,
		inView: boolean,
		entry: IntersectionObserverEntry | undefined
	) => {
		setItemsDataMap(prevMap => {
			const item = prevMap.get(index)
			if (item) {
				return new Map([
					...Array.from(prevMap.entries()),
					[index, {node: item.node, inView, entry}]
				])
			}
			else {
				return prevMap
			}
		})
	}, [])

	const registerItemNode = useCallback((index: number, node: HTMLDivElement | null | undefined) => {
		setItemsDataMap(prevMap => new Map([
			...Array.from(prevMap.entries())
			, [index, {node, inView: undefined, entry: undefined}]
		]))
	}, [])

	const previousPageData = useMemo(() => {
		const canGoToPreviousPage = Boolean(
			currentPageData && currentPageData.index > 0
		)

		function goToPreviousPage() {
			if (!currentPageData || !canGoToPreviousPage) {
				return;
			}
			const previousData = itemsDataMap.get(currentPageData.index - 1)
			if (previousData && previousData.node) {
				previousData.node.scrollIntoView()
			}
		}

		return {
			canGoToPreviousPage,
			goToPreviousPage
		}
	}, [currentPageData, itemsDataMap])

	const nextPageData = useMemo(() => {
		const canGoToNextPage = Boolean(
			currentPageData && currentPageData.index < previewSources.length - 1
		)

		function goToNextPage() {
			if (!currentPageData || !canGoToNextPage) {
				return;
			}
			const nextData = itemsDataMap.get(currentPageData.index + 1)
			if (nextData && nextData.node) {
				nextData.node.scrollIntoView()
			}
		}

		return {
			canGoToNextPage,
			goToNextPage
		}
	}, [currentPageData, previewSources, itemsDataMap])

	return (
		<div className="viewer">
			<div className="viewer_toolbar">
				{currentPageData ? (
					<>
						<button
							className="viewer_toolbar_previous-page"
							onClick={previousPageData.goToPreviousPage}
							disabled={!previousPageData.canGoToPreviousPage}
						><IconArrowSmall/></button>
						<span>{currentPageData.index + 1} / {previewSources.length}</span>
						<button
							className="viewer_toolbar_next-page"
							onClick={nextPageData.goToNextPage}
							disabled={!nextPageData.canGoToNextPage}
						><IconArrowSmall/></button>
					</>
				) : null}
			</div>
			<div className="viewer_items">
				{previewSources.map((source, index) => (
					<ViewerItem
						key={index}
						index={index}
						source={source}
						alt={`${filename} page:${index + 1}`}
						onInViewItemChange={onInViewItemChange}
						registerItemNode={registerItemNode}
					/>
				))}
			</div>
		</div>
	)
}

function _ViewerItem({source, index, alt, onInViewItemChange, registerItemNode}: {
	source: string,
	index: number,
	alt: string,
	onInViewItemChange: (index: number, inView: boolean, entry: (IntersectionObserverEntry | undefined)) => void,
	registerItemNode: (index: number, node: (HTMLDivElement | null | undefined)) => void
}) {
	const {ref, inView, entry} = useInView({
		threshold: [0.3, 0.4, 0.6, 0.7]
	})

	const dataRef = useUpdatedRef({entry, index,})

	useEffect(() => {
		const {index, entry} = dataRef.current
		onInViewItemChange(index, inView, entry)
	}, [inView, dataRef, onInViewItemChange])


	const setRef = useCallback((instance: HTMLDivElement | null | undefined) => {
		const {index} = dataRef.current
		ref(instance)
		registerItemNode(index, instance)
	}, [ref, dataRef, registerItemNode])

	return (
		<div className="viewer_items_item" ref={setRef}>
			<img
				alt={alt}
				src={source}
			/>
		</div>
	)
}

const ViewerItem = React.memo(_ViewerItem)

export default PdfPreview;
