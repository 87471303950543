import React from "react";
import {useSyncMaterialsData} from "../hooks/useSyncMaterialsData";
import {SyncMaterialsModalData} from "../types";

type SyncMaterialsData = ReturnType<typeof useSyncMaterialsData>

const SyncMaterialsDataContext = React.createContext<SyncMaterialsData | undefined>(undefined)

function SyncMaterialsDataProvider({data, children}: {
	data: SyncMaterialsModalData
	children: React.ReactNode
}) {
	const syncMaterialsData = useSyncMaterialsData(data)
	return (
		<SyncMaterialsDataContext.Provider value={syncMaterialsData}>
			{children}
		</SyncMaterialsDataContext.Provider>
	)
}

function useSyncMaterialsContext() {
	const context = React.useContext(SyncMaterialsDataContext)
	if (context === undefined) {
		throw new Error("useSyncMaterialsContext must be used within a SyncMaterialsDataProvider")
	}
	return context;
}

export {SyncMaterialsDataProvider, useSyncMaterialsContext}