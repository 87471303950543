import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {planUploadImageActions} from "./planUploadImageSlice";
import {getId} from "../../../../../../utils";
import {BaseImageOptions} from "../../../../../base-konva/features/image/BaseImageOptions";
import {PlanUploadImageUploadModal} from "./PlanUploadImageUploadModal";
import {selectPlanUploadStageConfig} from "../config/planUploadConfigSlice";
import {selectPlanUploadZoomState} from "../view/planUploadViewSlice";


export const PlanUploadImageOptions: React.FC = () => {
	const dispatch = useDispatch();
	const stageConfig = useSelector(selectPlanUploadStageConfig)
	const zoomState = useSelector(selectPlanUploadZoomState)

	const handleUploadedImageSave = (selectedItem: { value: string, label: string }) => {
		dispatch(planUploadImageActions.addImage({
			id: getId(),
			attachmentId: selectedItem.value,
			visible: true,
			name: selectedItem.label,
			position: {x: stageConfig.width / 2, y: stageConfig.height / 2},
			rotation: -zoomState.rotation,
			scale: {x: 1, y: 1},
		}))
	}

	const renderImageUploadModal = (isOpen: boolean, onCloseModal: () => void) => {
		return (
			<PlanUploadImageUploadModal
				onHandleSave={handleUploadedImageSave}
				isOpen={isOpen}
				onCloseModal={onCloseModal}
			/>
		)
	}

	return (
		<BaseImageOptions renderImageUploadModal={renderImageUploadModal}/>
	)
}