import {useRef} from "react"
import {KonvaEventObject} from "konva/types/Node";
import {DOUBLE_CLICK_DELAY} from "../constants";
import {KonvaEventType} from "../../../../base-konva/types";

interface UseDoubleClickHook {
	onMouseUp: (e: KonvaEventObject<KonvaEventType>) => void;
}

interface UseDoubleClickProps {
	onSingleClick: (e: KonvaEventObject<KonvaEventType>) => void,
	onDoubleClick: (e: KonvaEventObject<KonvaEventType>) => void;
}

export function useDoubleClick({
	onSingleClick,
	onDoubleClick
}: UseDoubleClickProps): UseDoubleClickHook {

	const clicks = useRef<number>(0);

	function onMouseUp(e: KonvaEventObject<KonvaEventType>) {
		clicks.current++;
		setTimeout(() => {
			clicks.current = 0;
		}, DOUBLE_CLICK_DELAY);
		if (clicks.current === 2) {
			clicks.current = 0;
			onDoubleClick(e);
		} else {
			onSingleClick(e);
		}
	}

	return {onMouseUp};
}
