import {ProjectDetailsApiDto} from "./restModels";
import moment from "moment";
import {ConversionSourceType, ProjectSource, ProjectStage, ProjectType} from "./enums";
import LoggerService from "../services/LoggerService";
import ProjectStatus from "./ProjectStatus";

export default class ProjectDetails {
	constructor(
		public readonly id: string,
		public readonly customerName: string,
		public readonly name: string,
		public readonly number: number,
		public readonly salesperson: string | undefined,
		public readonly site: string,
		public readonly source: ProjectSource,
		public readonly stage: ProjectStage,
		public readonly status: ProjectStatus | undefined,
		public readonly type: ProjectType,
		public readonly createdAtSimpro: moment.Moment,
		public readonly labels: string[],
		public readonly description: string,
		public readonly conversionSourceType: ConversionSourceType | null,
	) {}

	public static fromJson(json: ProjectDetailsApiDto): ProjectDetails {
		const source = ProjectSource[json.source];
		if (source === undefined) {
			const error = new Error(`Unknown Project source: ${json.source}`);
			LoggerService.logError(error);
			throw error;
		}
		const stage = ProjectStage[json.stage];
		if (stage === undefined) {
			const error = new Error(`Unknown Project stage: ${json.stage}`);
			LoggerService.logError(error);
			throw error;
		}
		const type = ProjectType[json.type];
		if (type === undefined) {
			const error = new Error(`Unknown Project type: ${json.type}`);
			LoggerService.logError(error);
			throw error;
		}
		const conversionSourceType = function() {
			if (!json.conversionSourceType) return null;

			const jsonConversionSourceType = ConversionSourceType[json.conversionSourceType]
			if (jsonConversionSourceType === undefined) {
				const error = new Error(`Unknown Conversion Source type: ${json.conversionSourceType}`);
				LoggerService.logError(error);
				throw error;
			}
			return jsonConversionSourceType
		}()

		const status = json.status ? ProjectStatus.fromJson(json.status) : undefined;
		const createdAtSimpro = moment(json.createdAtSimpro);

		return new ProjectDetails(
			json.id,
			json.customerName,
			json.name,
			json.number,
			json.salesperson,
			json.site,
			source,
			stage,
			status,
			type,
			createdAtSimpro,
			json.labels,
			json.description,
			conversionSourceType,
		);
	}
}
