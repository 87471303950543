import React, {createContext, useState} from "react";
import {noop} from "../utils/CallbackUtils";
import {UserConfirmationContextData} from "../models/interfaces";

const initialUserConfirmationData: UserConfirmationContextData = {
	data: {message: "", callback: noop},
	setData: noop
}

const UserConfirmationDataContext = createContext<UserConfirmationContextData | undefined>(undefined)

const UserConfirmationDataProvider: React.FC = ({children}) => {
	const [userConfirmationData, setUserConfirmationData] = useState(initialUserConfirmationData.data)
	return (
		<UserConfirmationDataContext.Provider value={{
			data: userConfirmationData,
			setData: setUserConfirmationData
		}}>
			{children}
		</UserConfirmationDataContext.Provider>
	)
}

function useUserConfirmationDataContext() {
	const context = React.useContext(UserConfirmationDataContext)
	if (!context) {
		throw new Error("useUserConfirmationDataContext must be used within a UserConfirmationDataProvider")
	}
	return context
}

export {UserConfirmationDataProvider, useUserConfirmationDataContext}