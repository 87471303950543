import {useDispatch} from "react-redux";
import {EditorTool, MaterialType} from "../../../../../../models/enums";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {MaterialChangeActionPayload} from "../../../../../../models/editor";
import {countActions} from "../../../../../count/countSlice";
import {lengthActions} from "../../../../../length/lengthSlice";
import {areaActions} from "../../../../../area/areaSlice";
import {useState} from "react";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../../../models/interfaces";
import {useOneOffData} from "../../../hooks/useOneOffData";

export  type OneOffChangeState = {
	measurementId: string,
	measurementStyle: MeasurementStyle,
	measurementType: MaterialAddableTool,
	materialName: string,
	modalTitle: string,
}

export function useMaterialChangeOneOffData() {
	const dispatch = useDispatch();
	const {state, setState, nameInput, stylesContainer} = useOneOffData()
	const [measurementId, setMeasurementId] = useState<string | undefined>();

	const contentFooter = {
		canSubmit: !!nameInput.name && measurementId !== undefined,
		submit: () => {
			if (contentFooter.canSubmit) {
				let measurementMaterialChangeAction: ActionCreatorWithPayload<MaterialChangeActionPayload, string>;

				switch (state.type) {
					case EditorTool.COUNT:
						measurementMaterialChangeAction = countActions.changeMaterial;
						break;
					case EditorTool.LENGTH:
						measurementMaterialChangeAction = lengthActions.changeMaterial;
						break;
					default:
						measurementMaterialChangeAction = areaActions.changeMaterial;
						break;
				}

				dispatch(measurementMaterialChangeAction({
					id: measurementId!,
					material: {name: nameInput.name!, type: MaterialType.ONE_OFF},
					style: state.measurementStyle
				}))
			}
		}
	}
	const modalTitle = state.modalTitle

	return {
		nameInput,
		stylesContainer,
		contentFooter,
		modalTitle,
		setChangeState: (changeState: OneOffChangeState) => {
			setMeasurementId(changeState.measurementId)
			setState(prevState => ({
				...prevState,
				modalTitle: changeState.modalTitle,
				type: changeState.measurementType,
				measurementStyle: changeState.measurementStyle,
			}))
			nameInput.setName(changeState.materialName)
		}
	}
}