import React, {useMemo} from "react";
import {JobStage, ProjectSource} from "../../../models/enums";
import {apiInstance} from "../../../api/api";
import ProjectsWrapper from "./ProjectsWrapper";
import {useTranslation} from "react-i18next";
import {COMPANY_JOBS_WITH_STAGE_QUERY_KEY} from "../../../api/CompaniesApi";
import {UseProjectListQueryKeyArgs} from "../hooks/useProjectsList";

interface JobsLoadProps {
	sourceStage: JobStage,
}

const JobsLoad: React.FC<JobsLoadProps> = ({sourceStage}) => {

	const {t} = useTranslation();

	const stageLabelTranslations = {
		[JobStage.COMPLETE]: t(`common.stages.job.complete`),
		[JobStage.PROGRESS]: t(`common.stages.job.progress`),
		[JobStage.PENDING]: t(`common.stages.job.pending`),
		[JobStage.ALL]: t(`common.stages.job.all`),
		[JobStage.ARCHIVED]: t(`common.stages.job.archived`),
		[JobStage.INVOICED]: t(`common.stages.job.invoiced`)
	}

	const queryKeyArgs: UseProjectListQueryKeyArgs = useMemo(() => {
		return {
			id: COMPANY_JOBS_WITH_STAGE_QUERY_KEY,
			stage: sourceStage
		}
	}, [sourceStage])

	return (
		<ProjectsWrapper
			projectSource={ProjectSource.JOB}
			stageLabel={stageLabelTranslations[sourceStage]}
			queryKeyArgs={queryKeyArgs}
			asyncDataLoad={apiInstance.companiesApi.fetchCompanyJobsWithStage(sourceStage)}
		/>
	);
};

export default JobsLoad
