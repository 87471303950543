import React from "react";
import {anchors} from "./anchors";
import {ResizeAnchor} from "../../models/editor";
import {useTranslation} from "react-i18next";
import "./anchorGrid.scss";

interface AnchorGridProps {
	activeAnchor: ResizeAnchor,
	setActiveAnchor: (anchor: ResizeAnchor) => void,
}

const AnchorGrid: React.FC<AnchorGridProps> = ({activeAnchor, setActiveAnchor}) => {
	const {t} = useTranslation();
	return (
		<div className={"anchor-grid"}>
			<div className={"anchor-grid_label"}>
				{t("editor.resizeStageModal.anchor")}:
			</div>
			<div className={"anchor-grid_grid"}>
				{anchors.map((row, i) => (
					<div className={"anchor-grid_grid_row"} key={i}>
						{row.map((anchor, j) => {
								const Icon = anchor.icon
								return <Icon key={j}
											 active={anchor.id === activeAnchor?.id}
											 onClick={() => setActiveAnchor(anchor)}/>
							}
						)}
					</div>
				))}
			</div>
		</div>
	);
};

export default AnchorGrid
