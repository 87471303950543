import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconZoomOut: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => !disabled && onClick(event);

	return (
		<IconUnselectable>
			<svg width={18} height={18} viewBox="0 0 18 18" onClick={handleClick} style={{cursor: cursor}}>
				<path fill={fill}
					  d="M9,14.6c-3.087,0 -5.6,-2.513 -5.6,-5.6c0,-3.087 2.513,-5.6 5.6,-5.6c3.087,0 5.6,2.513 5.6,5.6c-0,3.087 -2.513,5.6 -5.6,5.6Zm0,-12.6c-3.864,-0 -7,3.136 -7,7c0,3.864 3.136,7 7,7c3.864,0 7,-3.136 7,-7c0,-3.864 -3.136,-7 -7,-7Zm-3.5,7.7l7,0l0,-1.4l-7,0l0,1.4Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconZoomOut