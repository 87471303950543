import {CatalogItem} from "../../../../../../../../models/CatalogItem";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useUserSettingsDataContext} from "../../../../../../../../providers/UserSettingsProvider";
import {EditorTool} from "../../../../models/enums";
import {getDefaultStyle} from "../../../../utils";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../models/interfaces";
import {AreaStyle, CountMeasurementStyle} from "../../../../models/editor";
import SymbolStyleSelector from "../../../../components/SymbolStyleSelector";
import LineStyleSelector from "../../../../components/LineStyleSelector";
import AreaStyleSelector from "../../../../components/AreaStyleSelector";
import {UnitsType} from "../../../../../../../../models/enums";
import LengthInput from "../../../../../../../../components/ui/LengthInput";
import {MeasurementTypeSelect} from "./MeasurementTypeSelect";
import {RenderStyleSelectorHandler} from "../types";
import {LineStyle} from "../../../../../../../base-konva/types";

type MaterialItemRowProps = {
	item: CatalogItem,
	row: {
		isSelected: (id: number) => boolean,
		toggle: (id: number) => void
	},
	updateItem: (id: number, source: CatalogItem) => void
}
export const MaterialItemRow: React.FC<MaterialItemRowProps> = function({
	item, row, updateItem
}) {
	const {t} = useTranslation();
	const {settings} = useUserSettingsDataContext();
	const [localStyleMap, setLocalStyleMap] = useState({
		[EditorTool.COUNT]: getStyleFromItem(EditorTool.COUNT) ?? getDefaultStyle(EditorTool.COUNT),
		[EditorTool.LENGTH]: getStyleFromItem(EditorTool.LENGTH) ?? getDefaultStyle(EditorTool.LENGTH),
		[EditorTool.AREA]: getStyleFromItem(EditorTool.AREA) ?? getDefaultStyle(EditorTool.AREA),
		[EditorTool.VOLUME]: getStyleFromItem(EditorTool.VOLUME) ?? getDefaultStyle(EditorTool.VOLUME)
	});

	function getStyleFromItem(tool: MaterialAddableTool) {
		if (item.measurementType === tool && item.style) {
			return item.style
		}
		return undefined
	}

	const renderCountStyleSelector = (
		style: MeasurementStyle,
		onChange: (style: CountMeasurementStyle) => void
	) => {
		return (
			<SymbolStyleSelector style={style as CountMeasurementStyle}
								 onStyleChange={onChange}/>
		);
	};

	const renderLengthStyleSelector = (style: MeasurementStyle, onChange: (style: LineStyle) => void) => {
		return (
			<LineStyleSelector style={style as LineStyle} onStyleChange={onChange}/>
		);
	};

	const renderAreaStyleSelector = (style: MeasurementStyle, onChange: (style: AreaStyle) => void) => {
		return (
			<AreaStyleSelector style={style as AreaStyle} onStyleChange={onChange}/>
		);
	};

	let renderStyleSelector: RenderStyleSelectorHandler

	switch (item.measurementType) {
		case EditorTool.COUNT:
			renderStyleSelector = renderCountStyleSelector;
			break;
		case EditorTool.LENGTH:
			renderStyleSelector = renderLengthStyleSelector;
			break;
		default:
			renderStyleSelector = renderAreaStyleSelector;
			break;
	}


	function renderDefaultDropInput() {
		const inputLabel = `${t("editor.length.setDefaultDropValueModal.inputLabel")}  ${settings?.measurementSystem ===
		UnitsType.METRIC ?
			`[${t("common.units.meterSymbol")}]` : ""}`;

		return (
			<LengthInput
				label={inputLabel}
				value={item.defaultDrop}
				unitsType={settings?.measurementSystem}
				onChange={value => {
					updateItem(item.id, CatalogItem.createNew({...item, defaultDrop: value}))
				}}
			/>
		)
	}

	const onHeightValueChange = (value?: number) => {
		updateItem(item.id,
			CatalogItem.createNew({...item, height: value, isVolumeHeightError: value ? false : item.isVolumeHeightError}))
	}

	function renderHeightInput() {
		const inputLabel = `${t("editor.area.setHeightModal.inputLabel")}  ${settings?.measurementSystem ===
		UnitsType.METRIC ?
			`[${t("common.units.meterSymbol")}]` : ""}`;

		return (
			<LengthInput
				label={inputLabel}
				isError={item.isVolumeHeightError}
				value={item.height}
				unitsType={settings?.measurementSystem}
				onChange={onHeightValueChange}
			/>
		)
	}

	function onMeasurementTypeChange(value: MaterialAddableTool) {
		updateItem(item.id, CatalogItem.createNew({
			...item, measurementType: value, style: localStyleMap[value], isVolumeHeightError: false
		}))
	}

	return (
		<tr>
			<td><input type={"checkbox"} checked={row.isSelected(item.id)} onChange={() => row.toggle(item.id)}/></td>
			<td className={"break-word"}>{item.partNumber}</td>
			<td className={"break-word"}>{item.name}</td>
			<td>
				<MeasurementTypeSelect
					value={item.measurementType}
					onChange={onMeasurementTypeChange}
				/>
			</td>
			<td>
				<div style={{display: "flex"}}>
					{item.style && renderStyleSelector(item.style!, newStyle => {
						if (item.measurementType) {
							const newLocalStyleMap = {...localStyleMap}
							newLocalStyleMap[item.measurementType] = newStyle
							setLocalStyleMap(newLocalStyleMap)
							updateItem(item.id, CatalogItem.createNew({...item, style: newStyle}))
						}
					})}
					{(item.measurementType === EditorTool.VOLUME) && renderHeightInput()}
					{(item.measurementType === EditorTool.LENGTH) && renderDefaultDropInput()}
				</div>
			</td>
		</tr>
	)
}