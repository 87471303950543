import "./symbolRecognitionPanel.scss"
import SymbolRecognitionInitialPanel from "./SymbolRecognitionInitialPanel";
import SymbolRecognitionMatchesDisplayPanel from "./SymbolRecognitionMatchesDisplayPanel";
import {useDispatch, useSelector} from "react-redux";
import SymbolRecognitionSelectionPanel from "./SymbolRecognitionSelectionPanel";
import {SymbolRecognitionPanelStage} from "./types";
import {selectPanelStage, selectSymbolRecognitionSelection, symbolRecognitionActions} from "./symbolRecognitionSlice";
import SymbolRecognitionCancelModal from "./SymbolRecognitionCancelModal";
import React, {useEffect, useState} from "react";
import {SymbolRecognitionProcessingPanel} from "./SymbolRecognitionProcessingPanel";

const SymbolRecognitionPanel = () => {
	const dispatch = useDispatch();
	const selection = useSelector(selectSymbolRecognitionSelection)
	const panelStage = useSelector(selectPanelStage)
	const [isCancelModalVisible, setIsCancelModalVisible] = useState<boolean>(false);

	const showSymbolRecognitionCancelModal = () => {
		setIsCancelModalVisible(true)
	}

	useEffect(() => {
		return () => {
			dispatch(symbolRecognitionActions.reset())
		}
	}, [dispatch])

	return (
		<div className={"symbol-recognition-panel"}>
			<div className={"symbol-recognition-panel_top-bar"}/>
			{isCancelModalVisible && <SymbolRecognitionCancelModal onClose={() => {setIsCancelModalVisible(false)}}/>}
			{panelStage === SymbolRecognitionPanelStage.INITIAL && (
				<SymbolRecognitionInitialPanel
					showSymbolRecognitionCancelModal={showSymbolRecognitionCancelModal}
				/>
			)}
			{panelStage === SymbolRecognitionPanelStage.SYMBOL_SELECTION && (
				<SymbolRecognitionSelectionPanel
					templateData={selection}
					quitSymbolRecognitionMode={showSymbolRecognitionCancelModal}
				/>
			)}
			{panelStage === SymbolRecognitionPanelStage.PROCESSING && selection && (
				<SymbolRecognitionProcessingPanel
					quitSymbolRecognitionMode={showSymbolRecognitionCancelModal}
					templateData={selection}
				/>
			)}
			{panelStage === SymbolRecognitionPanelStage.MATCHES_DISPLAY && selection && (
				<SymbolRecognitionMatchesDisplayPanel
					onCancelClick={showSymbolRecognitionCancelModal}
					templateData={selection}
				/>
			)}
		</div>
	)
}

export default SymbolRecognitionPanel;
