import {useState} from "react";
import {TakeoffTemplateGroup} from "../../../../../../../../models/TakeoffTemplateGroup";
import {Status} from "../../../../../../../../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../../../../../../api/api";
import LoggerService from "../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../services/NotificationService";
import {useUpdatedRef} from "../../../../../../../../hooks/useUpdatedRef";

export function useTakeoffTemplateGroups() {
	const [groups, setGroups] = useState<TakeoffTemplateGroup[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();
	const loadTakeoffTemplateGroupsRef = useUpdatedRef(loadTakeoffTemplateGroups)

	function loadTakeoffTemplateGroups(parentGroupId?: number | null) {
		setLoadStatus(Status.LOADING);
		apiInstance.takeoffTemplatesApi.fetchTakeoffTemplateGroups(parentGroupId)
			.then(groups => {
				setGroups(groups);
				setLoadStatus(Status.SUCCESS);
			})
			.catch((err) => {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.addItemModal.takeoffTemplates.loadGroupsErrorDesc")
				);
			})
	}

	return {loadStatus, groups, loadTakeoffTemplateGroupsRef};
}