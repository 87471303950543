import React from "react";
import PlanTreeTemplate from "./PlanTreeTemplate";
import {PlanUploadData, PlanUploadPageData} from "../../features/plan-upload/types";

interface PlanUploadPanelProps {
	planTemplates: PlanUploadData[],
	renderTemplateOptionsDropdown: (template: PlanUploadData) => JSX.Element,
	renderPageOptionsDropdown: (
		template: PlanUploadData,
		page: PlanUploadPageData
	) => (selectedPageNumber: number | undefined) => JSX.Element,
	selectedPageNumber?: number,
	onPageClick: (page: PlanUploadPageData) => void,
	onTemplateClick?: (template: PlanUploadData) => void
}

const PlanUploadPanel: React.FC<PlanUploadPanelProps> = ({
	planTemplates,
	renderTemplateOptionsDropdown,
	renderPageOptionsDropdown,
	onTemplateClick,
	selectedPageNumber,
	onPageClick
}) => {
	return (
		<div>
			{planTemplates.map((planTemplate) =>
				<PlanTreeTemplate
					selectedPageNumber={selectedPageNumber}
					onPageClick={onPageClick}
					onTemplateClick={onTemplateClick}
					key={planTemplate.id}
					planTemplate={planTemplate}
					renderPageOptionsDropdown={renderPageOptionsDropdown}
					renderTemplateOptionsDropdown={renderTemplateOptionsDropdown}
				/>
			)}
		</div>
	);
};

export default PlanUploadPanel;
