import {useState} from "react";
import {ProjectSource, Status} from "../../../../../models/enums";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../../../api/api";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {AttachmentDetailsFolder} from "../../../../../models/AttachmentDetailsFolder";

export function useAttachmentFolders() {
	const [attachmentFolders, setAttachmentFolders] = useState<AttachmentDetailsFolder[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);

	const loadAttachmentDetailFoldersRef = useUpdatedRef(loadAttachmentDetailFolders)
	const {t} = useTranslation();

	function loadAttachmentDetailFolders(source: ProjectSource, simproId: number, parentFolderId?: string) {
		const apiMethod = source === ProjectSource.JOB
			? apiInstance.projectsApi.fetchJobAttachmentsFolders
			: apiInstance.projectsApi.fetchQuoteAttachmentsFolders
		setLoadStatus(Status.LOADING);
		apiMethod.bind(apiInstance.projectsApi)(simproId, parentFolderId).then(data => {
			setAttachmentFolders(data)
			setLoadStatus(Status.SUCCESS)
		}).catch(err => {
			setAttachmentFolders([])
			setLoadStatus(Status.ERROR)
			LoggerService.logError(err)
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.attachmentSelectModal.loadAttachmentFolderErrorDesc")
			)
		})
	}

	return {loadAttachmentDetailFoldersRef, loadStatus, attachmentFolders}
}
