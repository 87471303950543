import {useDispatch, useSelector} from "react-redux";
import {selectActiveTool} from "../config/configSlice";
import {selectExportInProgress, selectSnappingState} from "../view/viewSlice";
import {EditorTool, FillType} from "../../models/enums";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {EventHandlers, KonvaArea} from "./KonvaArea";
import React from "react";
import {AreaMeasurement, Deduction, EditorLine, EditorPoint} from "../../models/editor";
import {Vector2d} from "konva/types/types";
import {distance, lineAngle, midpoint} from "../../utils";
import {useSnapToPoint} from "../length/snapUtils";
import {areaActions} from "./areaSlice";
import {AreaHelper} from "./AreaHelper";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";
import {Colors} from "../../../../../../styles";
import {getId} from "../../../../../../utils";
import {getSnappedPosition} from "../../../../../base-konva/utils";
import {selectScale} from "../scale/scaleSlice";

type AreaDeductionElementProps = {
	area: AreaMeasurement
	deduction: Deduction
	hookTool: EditorTool,
	isPointerOverLayer: boolean,
	pointerLocation?: Vector2d
}

export function AreaDeductionElement({
	area,
	deduction,
	hookTool,
	isPointerOverLayer,
	pointerLocation
}: AreaDeductionElementProps) {

	const {snapToPoint} = useSnapToPoint()
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const deductionActive = area.activeDeductionId === deduction.id
	const snapping = useSelector(selectSnappingState);
	const exportInProgress = useSelector(selectExportInProgress);
	const showPointElements =
		deductionActive &&
		!exportInProgress &&
		(activeTool === hookTool || activeTool === EditorTool.MEASUREMENT_SELECT)
	const scale = useSelector(selectScale);
	const {settings} = useUserSettingsDataContext();

	function getPreviewLine(): EditorLine | null {
		let previewLine: EditorLine | null = null;
		const pointerPosition = pointerLocation ?? {x: 0, y: 0}
		const lastMouseUpPosition = deduction.lastMouseUpPosition;

		if (lastMouseUpPosition) {
			const lastPointer: EditorPoint = {
				id: getId(),
				position: lastMouseUpPosition
			};
			const pointer: EditorPoint = {
				id: getId(),
				position:
					snapping.point ? snapToPoint(pointerPosition).snapTo :
						snapping.angle
							? getSnappedPosition(lastMouseUpPosition, pointerPosition)
							: pointerPosition
			};
			previewLine = {
				id: getId(),
				from: lastPointer,
				to: pointer,
				angle: lineAngle(lastPointer.position, pointer.position),
				center: midpoint(lastPointer.position, pointer.position),
				distance: distance(lastPointer.position, pointer.position)
			}
		}

		return previewLine;
	}

	const previewLine = getPreviewLine();

	const eventsRef = useUpdatedRef<EventHandlers>({
		onPointDragEnd: ({pointId, newPosition}) => {
			dispatch(areaActions.updateDeductionPointPosition({
				areaId: area.id,
				deductionId: deduction.id,
				pointId,
				newPosition
			}))
		},
		onShapeDragEnd: ({positionDelta}) => {
			dispatch(areaActions.updateDeductionPosition({
				areaId: area.id,
				deductionId: deduction.id,
				positionDelta
			}))
		},
		onClick: () => {},
		onMouseEnter: () => {},
		onMouseLeave: () => {},
		snapToPoint
	})

	const showPreviewLines = deductionActive && isPointerOverLayer && !deduction.closed

	const getCloseLinePoints = (): number[] => {
		if (deduction.lines[0]) {
			const pointerPosition = pointerLocation ?? {x: 0, y: 0}
			const closePoint = deduction.lines[0].from;
			return [pointerPosition.x, pointerPosition.y, closePoint.position.x, closePoint.position.y];
		}
		return [];
	}

	const centerOfMassLabel = `-${AreaHelper.getDeductionValueFormat(deduction, area.height, scale, settings?.measurementSystem)}`

	return (
		<KonvaArea
			areaActive={deductionActive}
			style={{...area.style, fill: undefined}}
			fill={{
				type: FillType.DIAGONAL_PATTERN,
				color_1: Colors.WHITE,
				color_2: "transparent"
			}}
			listening={deduction.closed}
			draggable={area.activeDeductionId === deduction.id}
			lines={deduction.lines}
			showPreviewLines={showPreviewLines}
			previewLine={showPreviewLines ? previewLine ?? undefined : undefined}
			closeLinePoints={showPreviewLines ? getCloseLinePoints() : undefined}
			closed={deduction.closed}
			showPointElements={showPointElements}
			snapping={snapping}
			centerOfMassLabel={centerOfMassLabel}
			eventsRef={eventsRef}
		/>
	)
}