import React from "react";
import {useMeDataContext} from "../../providers/MeProvider";
import {DEFAULT_TIME_FORMAT, DEFAULT_TIMEZONE} from "../../utils/TextUtils";
import momentTZ from "moment-timezone";
import moment from "moment";

interface TimePresenterProps {
	date: moment.Moment | undefined,
}

const TimePresenter: React.FC<TimePresenterProps> = ({date}) => {

	const {company} = useMeDataContext();
	const format = company ? company.uiTimeFormat : DEFAULT_TIME_FORMAT;
	const timezone = company && momentTZ.tz.names().includes(company.timezone) ? company.timezone : DEFAULT_TIMEZONE;
	const outTime = date ? momentTZ(date.toISOString()).tz(timezone).format(format) : "-"

	return (<>{outTime}</>);
};

export default TimePresenter
