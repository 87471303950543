import React, {useState} from "react";
import "./settingsSymbols.scss";
import PageHeader from "../../../components/layout/page/PageHeader";
import {useTranslation} from "react-i18next";
import PageHeaderButtons from "../../../components/layout/page/PageHeaderButtons";
import Button from "../../../components/ui/Button";
import {useParams} from "react-router-dom";
import PageContent from "../../../components/layout/page/PageContent";
import {Status, SymbolPacksModal} from "../../../models/enums";
import Spinner from "../../../components/Spinner";
import DataFetchError from "../../../components/ui/DataFetchError";
import useSymbolPacks from "../hooks/useSymbolPacks";
import SymbolPacksTable from "./SymbolPacksTable";
import useSymbolGroups from "../hooks/useSymbolGroups";
import {CreateSymbolPackModal} from "./CreateSymbolPackModal";
import {useInvalidateSymbolQueries} from "../hooks/useInvalidateSymbolQueries";
import {paths, useNavigator} from "../../navigator";

const SymbolPacks: React.FC = () => {

	const {groupId} = useParams<{ groupId?: string }>();
	const {t} = useTranslation();
	const {navigateTo} = useNavigator()

	const {invalidateSymbolQueries} = useInvalidateSymbolQueries()
	const {symbolGroups} = useSymbolGroups();
	const symbolGroup = symbolGroups.find(group => group.id === groupId);

	const {symbolPacks, loadStatus} = useSymbolPacks(groupId);

	const [modal, setModal] = useState<SymbolPacksModal>(SymbolPacksModal.NONE);

	const redirectToGroups = () => {
		navigateTo(paths.settings_symbols_user_groups)
	};

	function closeModal() {
		setModal(SymbolPacksModal.NONE);
	}

	return (
		<>
			{modal === SymbolPacksModal.CREATE ? (
				<CreateSymbolPackModal
					isOpen={true}
					onClose={closeModal}
					onSave={invalidateSymbolQueries}
				/>
			) : null}
			<PageHeader>
				<div className="settings-symbols_breadcrumbs">
					{symbolGroup && <>
						<span className="settings-symbols_breadcrumbs_item" onClick={redirectToGroups}>
							{t("settings.tabs.symbols.groups.title")}
						</span>
						<span>{" / "}</span>
						<span className="settings-symbols_breadcrumbs_current-item">{symbolGroup.name}</span>
					</>}
				</div>
				<PageHeaderButtons>
					<Button variant={"primary"} onClick={() => setModal(SymbolPacksModal.CREATE)}
							label={t("settings.tabs.symbols.packs.createPack")}/>
					<Button
						label={t("common.buttons.cancel")}
						onClick={() => { navigateTo(paths.root) }}
						variant={"optional"}
					/>
				</PageHeaderButtons>
			</PageHeader>
			<PageContent>
				{loadStatus === Status.LOADING && <Spinner/>}
				{loadStatus === Status.SUCCESS &&
					<SymbolPacksTable
						symbolPacks={symbolPacks}
						reloadSymbolPacks={invalidateSymbolQueries}
					/>
				}
				{loadStatus === Status.ERROR && <DataFetchError/>}
			</PageContent>
		</>
	);
};

export default SymbolPacks;
