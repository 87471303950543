import {Status} from "../../../../../models/enums";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {apiInstance} from "../../../../../api/api";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import SummaryMeasurement from "../../../../../models/SummaryMeasurement";

export interface UseCostCenterSummaryHook {
	measurements: SummaryMeasurement[],
	loadStatus: Status,
}

function useCostCenterSummary(costCenterId: string, loadData: boolean, forceUpdate?: number): UseCostCenterSummaryHook {

	const {t} = useTranslation();

	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const [measurements, setMeasurements] = useState<SummaryMeasurement[]>([]);

	useEffect(() => {
		if (loadData) {
			setLoadStatus(Status.LOADING);
			apiInstance.measurementsApi.fetchMeasurements(costCenterId)
				.then((measurements) => {
					setMeasurements(measurements);
					setLoadStatus(Status.SUCCESS);
				})
				.catch(err => {
					setLoadStatus(Status.ERROR);
					LoggerService.logError(err);
					NotificationService.errorNotification(t("common.error"),
						t("projects.summary.itemsTable.fetchCcSummaryErrorDesc"))
				})
		}
	}, [costCenterId, forceUpdate, loadData, t]);

	useEffect(() => {
		if (!loadData) {
			setMeasurements([]);
			setLoadStatus(Status.IDLE);
		}
	}, [loadData]);

	return {measurements, loadStatus}
}

export default useCostCenterSummary;
