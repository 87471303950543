import React, {useEffect, useState} from "react";
import usePinnedMeasurements from "../pinnedMeasurements/usePinnedMeasurements";
import {EditorPanelMode} from "../../models/enums";
import {AreaMeasurement, CountMeasurement, LengthMeasurement} from "../../models/editor";
import {PinnedMeasurement} from "../../../../../../models/PinnedMeasurement";
import {useEditorPanelExpandData} from "./useEditorPanelExpandData";

type EditorPanelContextData = {
	editorPanelMode: EditorPanelMode
	setEditorPanelMode: (editorPanelMode: EditorPanelMode) => void
	takeoffs: ReturnType<typeof useEditorPanelExpandData>
	pinnedItems: ReturnType<typeof usePinnedMeasurements>
	findPinnedMeasurement: (measurement: CountMeasurement | LengthMeasurement | AreaMeasurement) => PinnedMeasurement | undefined
}

const EditorPanelDataContext = React.createContext<EditorPanelContextData | undefined>(undefined)

const EditorPanelProvider: React.FC = ({children}) => {
	const [editorPanelMode, setEditorPanelMode] = useState(EditorPanelMode.TAKEOFF_ITEMS);
	const {getExpandState} = useEditorPanelExpandData()
	const pinnedItems = usePinnedMeasurements()

	const findPinnedMeasurement = (
		measurement: CountMeasurement | LengthMeasurement | AreaMeasurement
	): PinnedMeasurement | undefined => {
		return pinnedItems.pinnedMeasurements.find(pinnedItem => pinnedItem.equalsMeasurement(measurement));
	};

	useEffect(() => {
		pinnedItems.loadPinnedMeasurements.current()
	}, [pinnedItems.loadPinnedMeasurements])

	return (
		<EditorPanelDataContext.Provider value={{
			editorPanelMode,
			setEditorPanelMode,
			takeoffs: {getExpandState},
			pinnedItems,
			findPinnedMeasurement
		}}>{children}</EditorPanelDataContext.Provider>
	)
};

function useEditorPanelDataContext() {
	const context = React.useContext(EditorPanelDataContext)
	if (!context) {
		throw new Error("useEditorPanelDataContext must be used within a EditorPanelProvider")
	}
	return context
}

const EditorPanelDataContextBridge = {
	Consumer: EditorPanelDataContext.Consumer,
	Provider: EditorPanelDataContext.Provider
}

export {EditorPanelProvider, useEditorPanelDataContext, EditorPanelDataContextBridge}