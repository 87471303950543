import Button from "../../../../../../components/ui/Button"
import {useTranslation} from "react-i18next";
import React from "react";
import {useDispatch} from "react-redux";
import {symbolRecognitionActions} from "./symbolRecognitionSlice";
import {SymbolRecognitionPanelStage} from "./types";
import {InfoWithSelectionPanel} from "./InfoWithSelectionPanel";
import {SelectionState} from "../../../../../base-konva/types";

type SymbolRecognitionSelectionPanelProps = {
	quitSymbolRecognitionMode: () => void
	templateData?: SelectionState
}

const SymbolRecognitionSelectionPanel: React.FC<SymbolRecognitionSelectionPanelProps> = ({
	quitSymbolRecognitionMode,
	templateData
}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch()

	function handleReselect() {
		dispatch(symbolRecognitionActions.reset())
	}

	function startSymbolRecognition() {
		dispatch(symbolRecognitionActions.setPanelStage(SymbolRecognitionPanelStage.PROCESSING))
	}

	return (
		<InfoWithSelectionPanel
			title={<>{t("editor.symbolRecognitionPanel.symbolSelectionScreen.title")}</>}
			description={<>
				{t("editor.symbolRecognitionPanel.symbolSelectionScreen.description1")}
				<br/><br/>
				{t("editor.symbolRecognitionPanel.symbolSelectionScreen.description2")}
			</>}
			buttons={<>
				<Button onClick={handleReselect} variant={"optional"} label={t("common.buttons.reselect")}/>
				<Button onClick={startSymbolRecognition} variant={"primary"} label={t("common.buttons.continue")}/>
			</>}
			handleCloseIconClick={quitSymbolRecognitionMode}
			templateData={templateData}
		/>
	)
}
export default SymbolRecognitionSelectionPanel;
