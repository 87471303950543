import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Vector2d} from "konva/types/types";
import {RootState} from "../../editorStore";
import {distance, getEditorLine, lineAngle, midpoint} from "../../utils";
import {
	AddDropActionPayload,
	ChangeStyleActionPayload,
	DeleteArcPointActionPayload,
	DeleteManyActionPayload,
	EditorLine,
	EditorPoint,
	LengthCallback,
	LengthFragment,
	LengthFragmentCallback,
	LengthMeasurement,
	LengthMeasurementState,
	MaterialChangeActionPayload,
	MaterialMeasurementCreateActionPayload,
	UpdateChildItemNumericValueActionPayload,
	UpdateFragmentPointPositionActionPayload,
	UpdateFragmentPositionActionPayload,
	UpdateItemGenericValueActionPayload
} from "../../models/editor";
import {MeasurementType} from "../../models/enums";
import {
	ActionIdValuePayload,
	ActivateActionPayload,
	DeleteActionPayload,
	DeleteChildItemActionPayload,
	DeleteSelectedItemActionPayload,
	GroupedActionPayload,
	LineStyle,
	SetVisibilityActionPayload
} from "../../../../../base-konva/types";
import {getId} from "../../../../../../utils";

const initialState: LengthMeasurementState = {
	lengths: []
}

export const lengthSlice = createSlice({
	name: 'length',
	initialState: initialState,
	reducers: {
		reset: () => ({...initialState}),
		addLength: (state, {payload}: PayloadAction<MaterialMeasurementCreateActionPayload>) => {
			const lengthId = getId();
			const length: LengthMeasurement = {
				id: lengthId,
				material: payload.material,
				visible: true,
				style: payload.style as LineStyle,
				drops: [],
				type: MeasurementType.LENGTH,
				defaultDrop: payload.defaultDrop,
				lengthFragments: [],
				activeLengthFragmentId: null
			}
			state.lengths.push(length)
			state.activeLengthId = lengthId;
		},
		addPointToActiveFragment: (state, action: PayloadAction<Vector2d>) => {
			executeOnActiveFragment(state, (length, lengthFragment) => {
				if (lengthFragment.lastMouseUpPosition) {
					let lastActivePoint: EditorPoint = {
						id: getId(),
						position: lengthFragment.lastMouseUpPosition
					}
					if (lengthFragment.activePointId) {
						for (let line of lengthFragment.lines) {
							if (line.from.id === lengthFragment.activePointId) {
								lastActivePoint = line.from;
								break;
							}
							if (line.to.id === lengthFragment.activePointId) {
								lastActivePoint = line.to;
								break;
							}
						}
					}

					const newPoint: EditorPoint = {
						id: getId(),
						position: action.payload
					}
					lengthFragment.activePointId = newPoint.id
					lengthFragment.lastMouseUpPosition = newPoint.position
					lengthFragment.lines.push(getEditorLine(getId(), lastActivePoint, newPoint))

					if (length.defaultDrop !== undefined) {
						if (lengthFragment.lines.length === 1) {
							const {to, from} = lengthFragment.lines[0]
							addDrop(length, from.id, length.defaultDrop)
							addDrop(length, to.id, length.defaultDrop)
						}
						else if (lengthFragment.lines.length > 1 && lengthFragment.activePointId) {
							addDrop(length, lengthFragment.activePointId, length.defaultDrop)
						}
					}
				}
				else if (lengthFragment.lines.length === 0) {
					lengthFragment.lastMouseUpPosition = action.payload
				}
			})

			function addDrop(length: LengthMeasurement, pointId: string, drop: number) {
				if (!length.drops.some(d => d.pointId === pointId)) {
					length.drops.push({id: getId(), pointId, value: drop})
				}
			}
		},
		startNewLengthFragment: (state, action: PayloadAction<Vector2d>) => {
			executeOnActiveLength(state, (length => {
				const lengthFragmentId = getId();
				const lengthFragment: LengthFragment = {
					id: lengthFragmentId,
					lines: [],
					activePointId: null,
					lastMouseUpPosition: action.payload,
				}
				length.lengthFragments.push(lengthFragment)
				length.activeLengthFragmentId = lengthFragmentId;
			}))
		},
		updateLengthPointPosition: (state, action: PayloadAction<UpdateFragmentPointPositionActionPayload>) => {
			const {itemId: pointId, newPosition, fragmentId} = action.payload;

			executeOnSelectedFragment(state, fragmentId, (length, fragment) => {
				for (let line of fragment.lines) {
					let lineUpdate = false;

					if (line.from.id === pointId) {
						lineUpdate = true;
						line.from.position = newPosition;
					}
					else if (line.to.id === pointId) {
						lineUpdate = true;
						line.to.position = newPosition;
					}
					if (lineUpdate) {
						line.angle = lineAngle(line.from.position, line.to.position);
						line.distance = distance(line.from.position, line.to.position, line.arcPointPosition);
						line.center = midpoint(line.from.position, line.to.position)
					}
				}
			})
		},

		updateLengthArcPointPosition: (state, action: PayloadAction<UpdateFragmentPointPositionActionPayload>) => {
			const {itemId: lineId, newPosition, fragmentId} = action.payload;

			executeOnSelectedFragment(state, fragmentId, (length, fragment) => {
				for (let line of fragment.lines) {
					if (line.id === lineId) {
						line.arcPointPosition = newPosition
						line.distance = distance(line.from.position, line.to.position, newPosition);
					}
				}
			})
		},

		activateLengthPoint: (state, {payload}: PayloadAction<ActivateActionPayload>) => {
			const {id} = payload;

			executeOnActiveLength(state, length => {
				for (let lengthFragment of length.lengthFragments) {
					for (let line of lengthFragment.lines) {
						if (line.from.id === id) {
							length.activeLengthFragmentId = lengthFragment.id
							lengthFragment.activePointId = id
							lengthFragment.lastMouseUpPosition = line.from.position
							break;
						}
						if (line.to.id === id) {
							length.activeLengthFragmentId = lengthFragment.id
							lengthFragment.activePointId = id
							lengthFragment.lastMouseUpPosition = line.to.position
							break;
						}
					}
				}
			})
		},
		activateLength: (state, action: PayloadAction<ActivateActionPayload>) => {
			state.activeLengthId = action.payload.id;
		},
		clearActiveLength: (state, _action: PayloadAction<GroupedActionPayload>) => {
			state.activeLengthId = undefined;
		},
		setWastage: (state, action: PayloadAction<ActivateActionPayload & { value: number | undefined }>) => {
			const {id, value} = action.payload
			executeOnSelectedLength(state, id, length => {
				length.wastage = value
			})
		},
		deleteLength: (state, {payload}: PayloadAction<DeleteActionPayload>) => {
			state.lengths = state.lengths.filter(l => l.id !== payload.id)
		},
		changeLengthStyle: (state, {payload}: PayloadAction<ChangeStyleActionPayload<LineStyle>>) => {
			const {id, style} = payload
			executeOnSelectedLength(state, id, length => {
				length.style = {...style}
			})
		},
		changeMaterial: (state, {payload}: PayloadAction<MaterialChangeActionPayload>) => {
			const {id, material, style} = payload;
			executeOnSelectedLength(state, id, length => {
				length.material = material
				length.style = style as LineStyle
			})
		},
		disableLinePreview: state => {
			executeOnActiveFragment(state, (length, fragment) => {
				length.activeLengthFragmentId = null;
				fragment.activePointId = null
				fragment.lastMouseUpPosition = null
			})
		},
		finishLines: state => {
			state.lengths = state.lengths.map(length => {
				length.activeLengthFragmentId = null
				length.lengthFragments = length.lengthFragments
					.filter(fragment => fragment.lines.length >= 1)
					.map(fragment => {
						fragment.activePointId = null
						fragment.lastMouseUpPosition = null
						return fragment
					})
				return length
			})
		},
		updateLengthFragmentPosition: (state, {payload}: PayloadAction<UpdateFragmentPositionActionPayload>) => {
			const {fragmentId, positionDelta: {x: xDelta, y: yDelta}} = payload;

			executeOnSelectedFragment(state, fragmentId, (length, fragment) => {
				fragment.lines = fragment.lines.map(line => {
					const from: EditorPoint = {
						...line.from,
						position: {
							x: line.from.position.x + xDelta,
							y: line.from.position.y + yDelta
						}
					}
					const to: EditorPoint = {
						...line.to,
						position: {
							x: line.to.position.x + xDelta,
							y: line.to.position.y + yDelta
						}
					}
					let arcPointPosition: Vector2d | undefined;
					if (line.arcPointPosition) {
						arcPointPosition = {
							x: line.arcPointPosition.x + xDelta,
							y: line.arcPointPosition.y + yDelta
						}
					}
					return getEditorLine(line.id, from, to, arcPointPosition)
				})
			})
		},
		setLengths: (state, {payload}: PayloadAction<ActionIdValuePayload<LengthMeasurement[]>>) => {
			state.lengths = payload.value
		},
		addDropToActive: (state, {payload}: PayloadAction<AddDropActionPayload>) => {
			executeOnActiveLength(state, length => {
				length.drops.push({id: getId(), ...payload})
			})
		},
		setDropValue: (state, {payload}: PayloadAction<UpdateChildItemNumericValueActionPayload>) => {
			const {id, parentId, value} = payload
			executeOnSelectedLength(state, parentId, length => {
				for (let drop of length.drops) {
					if (drop.id === id) {
						drop.value = value
						break;
					}
				}
			})
		},
		setDefaultDropValue: (state, {payload}: PayloadAction<UpdateItemGenericValueActionPayload<number | undefined>>) => {
			const {id, value} = payload
			executeOnSelectedLength(state, id, length => {
				length.defaultDrop = value
			})
		},
		setAllDropsValue: (state, {payload}: PayloadAction<UpdateItemGenericValueActionPayload<number | undefined>>) => {
			const {id, value} = payload
			executeOnSelectedLength(state, id, length => {
				for (let drop of length.drops) {
					drop.value = value ?? 0;
				}
			})
		},
		deleteDrop: (state, {payload}: PayloadAction<DeleteChildItemActionPayload>) => {
			const {id, parentId} = payload
			executeOnSelectedLength(state, parentId, length => {
				length.drops = length.drops.filter(item => item.id !== id)
			})
		},
		setVisibility: (state, {payload}: PayloadAction<SetVisibilityActionPayload>) => {
			const {id, visible, setAll} = payload;
			for (let measurement of state.lengths) {
				if (measurement.id === id || setAll) {
					measurement.visible = visible;
					if (!setAll) break;
				}
			}
		},
		removeItems: (state, {payload}: PayloadAction<DeleteManyActionPayload>) => {
			state.lengths = state.lengths.filter(l => !payload.ids.some(id => id === l.id));
		},
		removeSelectionItems: (state, {payload}: PayloadAction<DeleteSelectedItemActionPayload>) => {
			let lineSet: EditorLine[][] = [];
			for (let measurement of state.lengths) {
				if (measurement.id === payload.id) {
					for (let lengthFragment of measurement.lengthFragments) {
						if (lengthFragment.id === payload.fragmentId) {
							lengthFragment.lines.forEach((line: EditorLine) => {
								if (!payload.subIds!.some(lineToRemove => line.id === lineToRemove))
									if (lineSet.length === 0)
										lineSet.push([line]);
									else lineSet.some((set: EditorLine[], index) => {
										let hasConnection = set.some((setLine: EditorLine) => {
											return (setLine.to.position.x === line.to.position.x && setLine.to.position.y ===
												line.to.position.y) ||
												(setLine.from.position.x === line.to.position.x && setLine.from.position.y ===
													line.to.position.y) ||
												(setLine.to.position.x === line.from.position.x && setLine.to.position.y ===
													line.from.position.y) ||
												(setLine.from.position.x === line.from.position.x &&
													setLine.from.position.y ===
													line.from.position.y)
										});
										if (hasConnection) {
											lineSet[index].push(line);
										}
										else
											lineSet.push([line]);
										return true;
									});
							});
							if (lineSet.length === 0) {
								measurement.lengthFragments =
									measurement.lengthFragments.filter(item => item.id !== lengthFragment.id);
							}
							else {
								lengthFragment.lines = lineSet[0];
							}
						}
					}
				}
			}
			for (let measurement of state.lengths) {
				if (measurement.id === payload.id) {
					measurement.drops = measurement.drops.filter(item => lineSet.some(
						set => set.some(line => line.from.id === item.pointId || line.to.id === item.pointId)))
					lineSet.forEach((lines, index) => {
						if (index !== 0)
							measurement.lengthFragments.push(
								{id: getId(), lines: lines, activePointId: null, lastMouseUpPosition: null});

					});
				}
			}
		},
		removeArcPoint: (state, {payload}: PayloadAction<DeleteArcPointActionPayload>) => {
			const {fragmentId, subIds} = payload;
			if (fragmentId)
				executeOnSelectedFragment(state, fragmentId, (length, fragment) => {
					fragment.lines.forEach(line => {
						if (subIds?.includes(line.id)) {
							line.arcPointPosition = undefined;
							line.distance = distance(line.from.position, line.to.position);
						}
					})
				})
		},
	}
})

function executeOnActiveFragment(state: LengthMeasurementState, callback: LengthFragmentCallback) {
	executeOnActiveLength(state, length => {
		for (let lengthFragment of length.lengthFragments) {
			if (lengthFragment.id === length.activeLengthFragmentId) {
				callback(length, lengthFragment);
				break;
			}
		}
	})
}

function executeOnSelectedFragment(state: LengthMeasurementState, fragmentId: string, callback: LengthFragmentCallback) {
	executeOnActiveLength(state, length => {
		for (let lengthFragment of length.lengthFragments) {
			if (lengthFragment.id === fragmentId) {
				callback(length, lengthFragment);
				break;
			}
		}
	})
}

function executeOnActiveLength(state: LengthMeasurementState, callback: LengthCallback) {
	for (let length of state.lengths) {
		if (length.id === state.activeLengthId) {
			callback(length);
			break;
		}
	}
}

function executeOnSelectedLength(state: LengthMeasurementState, lengthId: string, callback: LengthCallback) {
	for (let length of state.lengths) {
		if (length.id === lengthId) {
			callback(length);
			break;
		}
	}
}

export const lengthActions = lengthSlice.actions;
export const selectLengths = (state: RootState) => state.undoGroup.present.length.lengths;
export const selectActiveLengthId = (state: RootState) => state.undoGroup.present.length.activeLengthId;
export const lengthReducer = lengthSlice.reducer;
