import {useSelector} from "react-redux";
import {TextButton} from "../../../../../components/ui/TextButton";
import {selectPlanUploadScaleOptions} from "../features/view/planUploadViewSlice";
import {useTranslation} from "react-i18next";
import "./planUploadScaleInfo.scss"
import React from "react";

interface PlanUploadScaleInfoProps {
	onClick: () => void
}

export const PlanUploadScaleInfo: React.FC<PlanUploadScaleInfoProps> = ({onClick}) => {
	const scaleOptions = useSelector(selectPlanUploadScaleOptions)
	const {t} = useTranslation()

	const scaleValue = scaleOptions.ratioInput !== undefined
		? `1:${scaleOptions.ratioInput}`
		: t("planUploadEditor.scaleDialog.drawn")

	return (
		<div className="plan-upload-scale-info">
			{t("planUploadEditor.scaleDialog.label")}: {scaleValue}
			<TextButton
				fontSize={12}
				onClick={onClick}
				children={t("planUploadEditor.scaleDialog.change")}
			/>
		</div>
	)
}