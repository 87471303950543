import {ActionIdValuePayload, ScaleConfig, ScaleInfoState, ScaleState} from "../../types";
import {initialScaleInfoState} from "../../constants";
import {PayloadAction} from "@reduxjs/toolkit";
import {Vector2d} from "konva/types/types";

const initialState: ScaleState = {
	scaleConfig: {
		scalePoints: [],
		scale: null,
		inputValue: null,
		scaleInfoState: {...initialScaleInfoState}
	}
}

const reducers = {
	addScalePoint: (state: ScaleState, action: PayloadAction<Vector2d>) => {
		state.scaleConfig.scalePoints.push(action.payload)
	},
	setScale: (state: ScaleState, action: PayloadAction<ActionIdValuePayload<number | null>>) => {
		state.scaleConfig.scale = action.payload.value;
	},
	setScaleConfig: (state: ScaleState, {payload}: PayloadAction<ActionIdValuePayload<ScaleConfig>>) => {
		state.scaleConfig = payload.value
	},
	setScaleInfoState: (state: ScaleState, {payload}: PayloadAction<ActionIdValuePayload<ScaleInfoState>>) => {
		state.scaleConfig.scaleInfoState = payload.value
	},
}

export {initialState, reducers}