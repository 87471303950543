import {KonvaEventObject} from "konva/types/Node";
import React, {useState} from "react";
import {Line} from "react-konva";
import {useSelector} from "react-redux";
import {Colors} from "../../../../../../styles";
import {selectStageConfig} from "../config/configSlice";
import {KonvaEventType} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";

interface UseGuidelineHook {
	renderGuideline: () => JSX.Element | JSX.Element[] | null,
	onMouseMoveGuideline: (evt: KonvaEventObject<KonvaEventType>) => void,
	onMouseLeaveGuideline: () => void,
}

function useGuideline(): UseGuidelineHook {
	const stageConfig = useSelector(selectStageConfig);
	const [pointerPosition, setPointerPosition] = useState<number[]>([]);

	function onMouseMove(evt: KonvaEventObject<KonvaEventType>) {
		const position = getPointerPosition(evt);
		if (position) {
			setPointerPosition([position.x, position.y]);
		}
	}

	function onMouseLeave() {
		setPointerPosition([]);
	}

	function render() {
		if (pointerPosition.length !== 2)
			return null;
		return <>
			<Line key={"GUIDELINE_H"}
				  points={[0, pointerPosition[1], stageConfig.width, pointerPosition[1]]}
				  stroke={Colors.SIMPROBLUE}
				  strokeWidth={1}
				  listening={false}
				  dash={[5]}
				  lineCap={"round"} lineJoin={"round"}

			/>
			<Line key={"GUIDELINE_V"}
				  points={[pointerPosition[0], 0, pointerPosition[0], stageConfig.height]}
				  stroke={Colors.SIMPROBLUE}
				  strokeWidth={1}
				  listening={false}
				  dash={[5]}
				  lineCap={"round"} lineJoin={"round"}

			/>
		</>;
	}

	return {renderGuideline: render, onMouseMoveGuideline: onMouseMove, onMouseLeaveGuideline: onMouseLeave}
}

export default useGuideline;
