import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useTemplatesData} from "../../../hooks/useTemplatesData";
import {useItemPresenterData} from "../../../hooks/useItemPresenterData";
import React, {useEffect, useState} from "react";
import TakeoffTemplateItem from "../../../../../../../../../../models/TakeoffTemplateItem";
import {useTableSelectionState} from "../../../../../../../../../../hooks/useTableSelectionState";
import {useUpdatedRef} from "../../../../../../../../../../hooks/useUpdatedRef";
import {EditorTool} from "../../../../../../models/enums";
import {getDefaultStyle} from "../../../../../../utils";
import LoggerService from "../../../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../../../services/NotificationService";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {MaterialMeasurementCreateActionPayload} from "../../../../../../models/editor";
import {countActions} from "../../../../../count/countSlice";
import {lengthActions} from "../../../../../length/lengthSlice";
import {areaActions} from "../../../../../area/areaSlice";
import {CheckListItem, TableColumnConfig} from "../../../../../../../../../../models/interfaces";
import TableHeaderCell from "../../../../../../../../../../components/layout/table/TableHeaderCell";
import {TemplateItemsTableRow} from "../components/template/TemplateItemsTableRow";
import {ItemsTableProps} from "../../../components/ItemsTable";

export function useAddItemsTemplatesData() {
	const {t} = useTranslation()
	const dispatch = useDispatch()
	const {selectedTemplateId, ...templatesData} = useTemplatesData()
	const itemPresenterData = useItemPresenterData(selectedTemplateId)

	const query = itemPresenterData.search.query
	const updateTakeoffTemplateItems = itemPresenterData.itemsTable.updateTakeoffTemplateItems;

	const hookItems = itemPresenterData.itemsTable.items
	const [editItemsState, setEditItemsState] = useState<TakeoffTemplateItem[]>([]);
	const items = editItemsState.filter(item => {
		if (query === undefined) {
			return true
		}
		return item.name.toLowerCase().includes(query.toLowerCase())
	})
	const {header, row, reset} = useTableSelectionState<TakeoffTemplateItem, string>(items)
	const resetRef = useUpdatedRef(reset)

	useEffect(() => {
		const result: TakeoffTemplateItem[] = []
		for (let hookItem of hookItems) {
			const measurementType = hookItem.measurementType ?? EditorTool.COUNT
			const style = hookItem.style ?? getDefaultStyle(measurementType)
			const item = hookItem.withNewTypeAndStyle(measurementType, style)
			result.push(item)
		}
		setEditItemsState(result)
		resetRef.current()
	}, [hookItems, resetRef]);

	function updateItem(id: string, source: TakeoffTemplateItem) {
		const result: TakeoffTemplateItem[] = []
		for (let templateItem of editItemsState) {
			let newItem = templateItem
			if (templateItem.id === id) {
				newItem = source
			}
			result.push(newItem)
		}
		setEditItemsState(result)
	}

	const canSaveTakeoffTemplateItems = selectedTemplateId !== undefined && items.length > 0;

	const validateVolumeHeights = () => {
		let isError = false;
		const validatedItems: TakeoffTemplateItem[] = itemsTable.items.map(item => {
			const isHeightEmpty = item.measurementType === EditorTool.VOLUME && (item.height === undefined || item.height === 0)
			if (isHeightEmpty) {
				isError = true;
				return (item.withNewHeight(item.height, true))
			}
			else return item;
		})
		setEditItemsState([...validatedItems])
		return isError;
	}

	const selected = items.filter(item => row.isSelected(item.id))
	const canAddSelectedItems = selected.length > 0

	function saveTakeoffTemplateItems() {
		if (canSaveTakeoffTemplateItems) {
			if (validateVolumeHeights()) {
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.addItemModal.volumeHeightNotFilledErrorDesc")
				)
				return false;
			}
			try {
				const updateJsonList = items.map(item => item.toUpdateJson())
				updateTakeoffTemplateItems(selectedTemplateId!, updateJsonList)
			}
			catch (e) {
				LoggerService.logError(e)
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.addItemModal.takeoffTemplates.saveTakeoffTemplateItemsErrorDesc")
				)
			}
		}
		return true;
	}

	function addSelectedItems() {
		const saveSucceeded = saveTakeoffTemplateItems();
		if (!saveSucceeded) {
			return false;
		}
		let measurementMaterialAddAction: ActionCreatorWithPayload<MaterialMeasurementCreateActionPayload> | undefined;

		for (let selectedItem of selected) {
			measurementMaterialAddAction = undefined;
			switch (selectedItem.measurementType) {
				case EditorTool.COUNT:
					measurementMaterialAddAction = countActions.addCount
					break;
				case EditorTool.LENGTH:
					measurementMaterialAddAction = lengthActions.addLength
					break;
				case EditorTool.VOLUME:
				case EditorTool.AREA:
					measurementMaterialAddAction = areaActions.addArea
					break;
			}

			if (measurementMaterialAddAction && selectedItem.style) {
				dispatch(measurementMaterialAddAction({
					material: {
						id: selectedItem.materialId,
						partNumber: selectedItem.partNumber,
						name: selectedItem.name,
						laborTime: selectedItem.laborTime,
						type: selectedItem.materialType
					},
					style: selectedItem.style,
					height: selectedItem.height,
					defaultDrop: selectedItem.defaultDrop
				}))
			}
		}
		return true;
	}

	const itemsTable: ItemsTableProps<TakeoffTemplateItem> = {
		tableRef: {current: null},
		items,
		columns: [
			{
				id: "checked",
				label: "",
				sortable: false,
				width: 40
			}, {
				id: "name",
				label: t("projects.addItemModal.table.columns.templateItemName"),
				sortable: false,
				width: "20%"
			}, {
				id: "measurementType",
				label: t("projects.addItemModal.table.columns.type"),
				sortable: false,
				width: "15%"
			}, {
				id: "style",
				label: t("projects.addItemModal.table.columns.style"),
				sortable: false,
				width: "70%"
			}
		],
		headerTemplate: (col: TableColumnConfig<CheckListItem<TakeoffTemplateItem>>) => {
			if (col.id === "checked") {
				return (
					<th style={{width: col.width}} key={col.id}>
						<input type={"checkbox"} checked={header.isSelected} onChange={header.toggle}/>
					</th>
				)
			}
			return <TableHeaderCell<CheckListItem<TakeoffTemplateItem>>
				id={col.id}
				label={col.label}
				width={col.width}
			/>;
		},
		rowTemplate: (item: TakeoffTemplateItem) => {
			return <TemplateItemsTableRow item={item} row={row} updateItem={updateItem}/>
		}
	}

	const clearErrors = () => {
		const clearedItems = [...items].map(item => item.withNewHeight(item.height, false))
		setEditItemsState([...clearedItems])
	}

	return {
		...templatesData,
		itemPresenterData: {
			...itemPresenterData,
			clearErrors,
			itemsTable,
			contentFooter: {
				canSaveTakeoffTemplateItems: canSaveTakeoffTemplateItems,
				saveTakeoffTemplateItems: saveTakeoffTemplateItems,
				canAddSelectedItems: canAddSelectedItems,
				addSelectedItems: addSelectedItems
			}
		}
	}
}