import React, {PropsWithChildren} from "react";
import "./tableHeaderCell.scss";
import IconOrder from "../../icons/IconOrder";
import {SortConfig} from "../../../models/interfaces";
import {SortDirection} from "../../../models/enums";

export interface ITableHeaderCellProps<T> {
	id: keyof Partial<T>,
	label: string,
	width?: number | string,
	sortable?: boolean,
	sortConfig?: SortConfig<T>,
	onClick?: (id: keyof Partial<T>) => void,
}

const TableHeaderCell = <T, >({id, label, width, sortable, sortConfig, onClick}: PropsWithChildren<ITableHeaderCellProps<T>>) => {

	const isActive = sortConfig?.key === id;

	const cls = [
		"table-header-cell",
		(onClick && sortable) && "is-clickable",
		isActive && "is-active",
		id === "actions" && "action"
	].filter(Boolean).join(" ")

	const handleClick = () => {
		sortable && onClick && onClick(id);
	}

	return (
		<th className={cls} style={{width}}>
			<span onClick={handleClick}>
			{label} {onClick && sortable && sortConfig ?
				<IconOrder direction={isActive ? sortConfig.direction : SortDirection.DESC} active={isActive}/> : null}
			</span>
		</th>
	);
};

export default TableHeaderCell
