import {ExportStatus, SummariesExportStatusResult, SummaryExportStatus} from "../../../../../models/SummariesExportStatusResult";
import {wait} from "../../../../../utils/ApiUtils";
import {apiInstance} from "../../../../../api/api";
import {useState} from "react";
import {DrawingType, Status} from "../../../../../models/enums";
import {useDrawingSummaryPDF} from "../components/pdf/useDrawingSummaryPDF";
import FitTime from "../../../../../models/FitTime";
import {OnFailure, OnSuccess} from "../../../../../utils/CallbackUtils";
import Section from "../../../../../models/Section";

function isSettled(summaryExport: SummaryExportStatus): boolean {
	return (
		summaryExport.status === ExportStatus.COMPLETE ||
		summaryExport.status === ExportStatus.FAIL
	)
}

function isCompleted(summaryExport: SummaryExportStatus): boolean {
	return summaryExport.status === ExportStatus.COMPLETE
}

const SUMMARY_EXPORT_ABORTED = "SUMMARY_EXPORT_ABORTED"

export function useDrawingVersionsSummaryExporter() {

	const [status, setStatus] = useState(Status.IDLE);
	const [result, setResult] = useState<SummaryExportStatus[]>([]);

	const {createSummaryAttachment} = useDrawingSummaryPDF()

	function exportDrawingVersionsSummary(
		projectId: string,
		drawingData: { versionId: string, drawingType: DrawingType }[],
		fitTimes: FitTime[],
		sections: Section[],
		onSuccess: OnSuccess,
		onFailure: OnFailure,
		abortSignal?: AbortSignal,
	) {
		setStatus(Status.LOADING)
		setResult(drawingData.map(item => ({
			drawingVersionId: item.versionId,
			status: ExportStatus.PENDING,
			error: undefined
		})))

		const processExport = async function() {
			const drawingVersionIdSummaryAttachmentIdMap = new Map<string, string>()

			for (let drawingItem of drawingData) {
				checkAbortStatus();
				const attachmentInfo = await createSummaryAttachment(
					drawingItem.drawingType,
					drawingItem.versionId,
					fitTimes,
					sections,
				)
				drawingVersionIdSummaryAttachmentIdMap.set(
					drawingItem.versionId,
					attachmentInfo.id
				)
			}

			const exportList = Array.from(drawingVersionIdSummaryAttachmentIdMap.entries()).map(
				([drawingVersionId, summaryId]) => ({
					drawingVersionId, summaryId
				})
			)

			checkAbortStatus();
			const {id: taskId} = await apiInstance.projectsApi.startSummaryExport(projectId, exportList)

			return await getAllSettledResults(taskId, apiResult => {setResult(apiResult.statuses) })
		}

		processExport().then(result => {
			if (result.statuses.every(isCompleted)) {
				onSuccess()
				setStatus(Status.SUCCESS)
			}
			else {
				onFailure(new Error("Export summary failed for some drawing versions"))
				setStatus(Status.ERROR)
			}
		}).catch(error => {
			if (error?.message === SUMMARY_EXPORT_ABORTED) {
				return;
			}
			onFailure(error)
			setStatus(Status.ERROR)
		})

		function checkAbortStatus() {
			if (abortSignal?.aborted) {
				throw new Error(SUMMARY_EXPORT_ABORTED)
			}
		}

		async function getAllSettledResults(taskId: string, updateStatus: (apiResult: SummariesExportStatusResult) => void) {
			const getResult = async function() {
				await wait(2000)
				checkAbortStatus()
				const apiResult = await apiInstance.projectsApi.askForSummaryExportResults(taskId)
				updateStatus(apiResult)
				return apiResult
			}
			let result = await getResult()

			while (!result.statuses.every(isSettled)) {
				result = await getResult()
			}
			return result;
		}
	}

	function clearResult() {
		setStatus(Status.IDLE)
		setResult([])
	}

	return {
		status, result,
		exportDrawingVersionsSummary,
		clearResult,
	}
}