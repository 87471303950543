import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../../../components/modal/EditValueModal";
import {maxFourDecimalsNumberRegexp} from "../../../../../../../utils/TextUtils";
import {convertToMeters, convertToUnit} from "../../../../../../../utils/ConversionUtils";
import {useUserSettingsDataContext} from "../../../../../../../providers/UserSettingsProvider";
import LengthInput from "../../../../../../../components/ui/LengthInput";
import {UnitsType} from "../../../../../../../models/enums";
import "./setNumberValueModal.scss";

interface SetDropValueModalProps {
	isOpen: boolean,
	initialValue: number | undefined,
	onSave: (value: number) => void,
	onClose: () => void
	title: string
	inputLabel: string
}

const SetNumberValueModal: React.FC<SetDropValueModalProps> = ({
	isOpen,
	initialValue,
	onSave,
	onClose,
	title,
	inputLabel
}) => {
	const [value, setValue] = useState<number | undefined>(initialValue);
	const {settings} = useUserSettingsDataContext();
	const {t} = useTranslation();

	useEffect(() => {
		setValue(convertToUnit(initialValue, settings?.measurementSystem));
	}, [isOpen, initialValue, settings]);

	const onChange = (value: number | undefined) => {
		if (value === undefined) {
			setValue(undefined);
		}
		else {
			if (settings?.measurementSystem === UnitsType.IMPERIAL || maxFourDecimalsNumberRegexp.test(value.toString())) {
				setValue(Number(value));
			}
		}
	};

	const handleSave = () => {
		onSave(convertToMeters(value, settings?.measurementSystem)!);
	};

	const handleKeySearch = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		(evt.key === 'Enter' && value) && handleSave();
	};

	const label = `${inputLabel} ${settings?.measurementSystem === UnitsType.METRIC ? `[${t("common.units.meterSymbol")}]` : ""}`;

	return (
		<EditValueModal isOpen={isOpen}
						onClose={onClose}
						onSave={handleSave}
						title={title}
						disableSave={value === undefined}>
			<div className="set-number-value-modal_input-container">
				<LengthInput label={label}
							 value={value}
							 unitsType={settings?.measurementSystem}
							 onKeyDown={handleKeySearch}
							 onChange={onChange}/>
			</div>
		</EditValueModal>
	);
};

export default SetNumberValueModal;
