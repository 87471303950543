import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlanUploadRootState} from "../../planUploadStore";
import Flatten from "@flatten-js/core";
import {FlattenUtils} from "../../../../../project-drawings/features/editor/utils";
import {ClipState, SelectionState} from "../../../../../base-konva/types";

const initialState: ClipState = {}

const planUploadClipSlice = createSlice({
	name: 'clip',
	initialState,
	reducers: {
		reset: (_, {payload}: PayloadAction<{ clipArea?: SelectionState }>) => ({
			...initialState,
			clipArea: payload.clipArea ?? initialState.clipArea
		}),
		setSelection: (state, {payload}: PayloadAction<SelectionState | undefined>) => {
			state.selection = payload
		},
		confirmClipArea: (state) => {
			if (state.selection && state.clipArea) {
				state.clipArea = getIntersectionArea(state.selection, state.clipArea)
			}
			else {
				state.clipArea = state.selection
			}
			state.selection = undefined
		}
	}

})

function convertSelectionAreaToPolygon(selectionArea: SelectionState): Flatten.Polygon {
	const x = selectionArea.x;
	const y = selectionArea.y

	if (selectionArea.width > 0 && selectionArea.height > 0) {
		return new Flatten.Polygon(
			[
				[x, y],
				[x, y + selectionArea.height],
				[x + selectionArea.width, y + selectionArea.height],
				[x + selectionArea.width, y]
			],
		)
	}
	else {
		return new Flatten.Polygon()
	}
}

function getIntersectionArea(area1: SelectionState, area2: SelectionState): SelectionState {
	const selectionPolygon = convertSelectionAreaToPolygon(area1)
	const clippedPolygon = convertSelectionAreaToPolygon(area2)
	const intersectionBox = FlattenUtils.intersectPolygons(clippedPolygon, selectionPolygon).box

	if (intersectionBox.xmin && intersectionBox.ymax) {
		return {
			x: intersectionBox.xmin,
			y: intersectionBox.ymin,
			width: intersectionBox.width,
			height: intersectionBox.height
		}
	}
	else {
		return {
			x: 0,
			y: 0,
			width: 0,
			height: 0
		}
	}
}


export const planUploadClipReducer = planUploadClipSlice.reducer;
export const planUploadClipActions = planUploadClipSlice.actions;

/**
 * Selection is always relative to plan image not to stage dimensions,
 * if stage is resized add vector from stageConfig.backgroundImagePosition
 */
export const selectClipSelectionState = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.clip.selection;
export const selectClipArea = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.clip.clipArea;