import React from "react";
import Modal from "../../../../../components/modal/Modal";
import ModalTitle from "../../../../../components/modal/ModalTitle";
import "./projectExportErrorsModal.scss";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import {ProjectSource} from "../../../../../models/enums";
import "./projectExportConfirmationModal.scss";
import {useTranslation} from "react-i18next";

interface ProjectExportConfirmationModalProps {
	projectSource: ProjectSource,
	onSubmit: () => void,
	onClose: () => void,
	isSyncOptionsModelLoading: boolean;
}

const ProjectExportConfirmationModal: React.FC<ProjectExportConfirmationModalProps> = ({
	projectSource,
	onSubmit,
	onClose,
	isSyncOptionsModelLoading
}) => {
	const {t} = useTranslation();
	const translations = {
		"title": {
			[ProjectSource.QUOTE]: isSyncOptionsModelLoading ? t(`projects.summary.confirmationModal.quote.syncOptionsTitle`) : t(`projects.summary.confirmationModal.quote.title`),
			[ProjectSource.JOB]: isSyncOptionsModelLoading ? t(`projects.summary.confirmationModal.job.syncOptionsTitle`) : t(`projects.summary.confirmationModal.quote.title`)
		},
		"contentLine1": {
			[ProjectSource.QUOTE]: isSyncOptionsModelLoading ? t(`projects.summary.confirmationModal.quote.syncOptionsAlertLine1`) : t(`projects.summary.confirmationModal.quote.contentLine1`),
			[ProjectSource.JOB]: isSyncOptionsModelLoading ? t(`projects.summary.confirmationModal.job.syncOptionsAlertLine1`) : t(`projects.summary.confirmationModal.job.contentLine1`)
		},
		"contentLine2": {
			[ProjectSource.QUOTE]: isSyncOptionsModelLoading ? t(`projects.summary.confirmationModal.quote.syncOptionsAlertLine2`) : t(`projects.summary.confirmationModal.quote.contentLine2`),
			[ProjectSource.JOB]: isSyncOptionsModelLoading ? t(`projects.summary.confirmationModal.job.syncOptionsAlertLine2`) : t(`projects.summary.confirmationModal.job.contentLine2`)
		},
		"cancelButtonLabel": isSyncOptionsModelLoading ? t("common.buttons.cancel") : t("common.buttons.no"),
		"proceedButtonLabel": isSyncOptionsModelLoading ? t("common.buttons.syncAnyway") : t("common.buttons.yes")
	}

	return (
		<Modal isOpen={true} onClose={onClose} customClass="project-export-confirmation-modal">
			<ModalTitle>
				{translations.title[projectSource]}
			</ModalTitle>
			<div className="project-export-confirmation-modal_content">
				{translations.contentLine1[projectSource]}
			</div>
			<div className="project-export-confirmation-modal_content">
				{translations.contentLine2[projectSource]}
			</div>
			<ModalButtons>
				<Button	label={translations.cancelButtonLabel} variant={"optional"}	onClick={onClose}/>
				<Button	label={translations.proceedButtonLabel}	variant={"primary"}	onClick={onSubmit}/>
			</ModalButtons>
		</Modal>
	);
};

export default ProjectExportConfirmationModal;
