import React from "react";
import {useTranslation} from "react-i18next";
import TextInput from "../../../project-drawings/features/editor/components/TextInput";
import "./createCostCenterField.scss"
import CustomSelect from "../../../../components/ui/CustomSelect";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";

type CreateCostCenterFieldProps = {
	onCostCenterNameChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
	handleCompanyCostCenterChange: (ccId: number) => void
	companyCostCenterId: number | undefined
	costCenterName: string
	onError?: () => void
}

export const CreateCostCenterField: React.FC<CreateCostCenterFieldProps> = ({
	onCostCenterNameChange,
	handleCompanyCostCenterChange,
	companyCostCenterId,
	costCenterName,
}) => {
	const {t} = useTranslation()
	const {treeData: {companyCostCenters}} = useProjectDetailContext()

	return (
		<div className={"create-cost-center-field"}>
			<div className="create-cost-center-field_label">
				{t("projects.details.createCostCenterField.costCenterLabel")}
			</div>
			<CustomSelect
				placeholder={t("projects.details.createCostCenterField.companyCostCenterPlaceholder")}
				customClass="create-cost-center-field_select"
				items={companyCostCenters.map(cc => ({label: cc.name, value: cc.id}))}
				onChange={handleCompanyCostCenterChange}
				value={companyCostCenterId}/>
			<div className="create-cost-center-field_label">
				{t("projects.details.createCostCenterField.costCenterNameLabel")}
			</div>
			<TextInput
				value={costCenterName}
				onChange={onCostCenterNameChange}
				placeholder={t("projects.details.createCostCenterField.inputPlaceholder")}
				className={"create-cost-center-field_text-input"}/>
		</div>
	)
}