import React, {useState} from "react";
import "./settingsSymbols.scss";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {SymbolPack} from "../../../models/SymbolPack";
import Dropdown from "../../../components/ui/Dropdown";
import DropdownItem from "../../../components/ui/DropdownItem";
import {SymbolPacksModal} from "../../../models/enums";
import ConfirmationModal from "../../../components/ui/ConfirmationModal";
import useDeleteSymbolPack from "../hooks/useDeleteSymbolPack";
import RenameSymbolPackModal from "./RenameSymbolPackModal";
import {pages, useNavigator} from "../../navigator";

interface SymbolPacksTableRowProps {
	symbolPack: SymbolPack;
	reloadSymbolPacks?: () => void;
}

const SymbolPacksTableRow: React.FC<SymbolPacksTableRowProps> = ({symbolPack, reloadSymbolPacks}) => {
	const {groupId} = useParams<{ groupId: string }>();
	const [modal, setModal] = useState(SymbolPacksModal.NONE);

	const {deleteSymbolPack} = useDeleteSymbolPack();
	const {t} = useTranslation();
	const {navigateTo} = useNavigator()

	const isDefaultPack = !reloadSymbolPacks;

	const onRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, packId: string) => {
		// this condition prevents double action on dropdown button click (onRowClick should not be called then)
		if ((event.target as HTMLElement).tagName === "TD") {
			if (groupId) {
				navigateTo(pages.symbolsPreviewPage(groupId ?? "default", packId))
			}
		}
	};

	const openDeleteModal = () => setModal(SymbolPacksModal.DELETE);

	const openRenameModal = () => setModal(SymbolPacksModal.RENAME);

	const closeModal = () => setModal(SymbolPacksModal.NONE);

	const onSuccess = () => {
		closeModal();
		reloadSymbolPacks && reloadSymbolPacks();
	};

	return (
		<>
			<tr onClick={(event) => onRowClick(event, symbolPack.id)}
				className="settings-symbols_table-row with-actions">
				<td>{symbolPack.name}</td>
				<td>
					<Dropdown>
						<DropdownItem label={t("settings.tabs.symbols.packs.dropdown.rename.itemName")}
									  onClick={openRenameModal}
									  disabled={isDefaultPack}/>
						<DropdownItem label={t("settings.tabs.symbols.groups.dropdown.delete.itemName")}
									  onClick={openDeleteModal}
									  disabled={isDefaultPack}/>
					</Dropdown>
				</td>
			</tr>
			<ConfirmationModal isOpen={modal === SymbolPacksModal.DELETE}
							   text={t("settings.tabs.symbols.packs.dropdown.delete.question")}
							   onConfirm={() => deleteSymbolPack(symbolPack.id, onSuccess)}
							   onClose={closeModal}/>
			<RenameSymbolPackModal isOpen={modal === SymbolPacksModal.RENAME}
								   symbolPack={symbolPack}
								   onSuccess={onSuccess}
								   onClose={closeModal}/>
		</>
	);
};

export default SymbolPacksTableRow;
