import React, {useState} from "react";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import {useTranslation} from "react-i18next";
import TextInput from "../../../project-drawings/features/editor/components/TextInput";
import "./assignToNewSectionModal.scss"
import Button from "../../../../components/ui/Button";
import ModalButtons from "../../../../components/modal/ModalButtons";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import {ProjectSource} from "../../../../models/enums";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import InlineSpinner from "../../../../components/InlineSpinner";
import LoggerService from "../../../../services/LoggerService";
import {useLocation, useParams} from "react-router-dom";
import {useCreateCostCenter, useMoveDrawingsToExistingCostCenter} from "../../hooks";

type AssignToNewSectionModalProps = {
	deletedSectionId: string
	onClose: () => void
}

export const AssignToNewSectionModal: React.FC<AssignToNewSectionModalProps> = ({onClose, deletedSectionId}) => {
	const {t} = useTranslation()
	const [sectionName, setSectionName] = useState<string>("")
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const {treeData: {sections, companyCostCenters}, refreshProjectTreeAfterSimproUpdate} = useProjectDetailContext()
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {projectId} = useParams<{ projectId: string }>();
	const {projectDetails: {project}} = useProjectDetailContext()
	const {createCostCenter} = useCreateCostCenter();
	const {moveDrawingsToExistingCostCenter} = useMoveDrawingsToExistingCostCenter();

	const canSave = project && !isLoading && sectionName.length !== 0

	const onSave = async () => {
		setIsLoading(true)
		const deletedSectionCostCenters = sections.find(s => s.id === deletedSectionId)?.costCenters;
		const costCentersThatRemainDeletedIds: string[] = [];

		try {
			const createdSectionId = await createNewSection();
			if (!createdSectionId) {
				return
			}
			if (deletedSectionCostCenters) {
				for (const deletedCostCenter of deletedSectionCostCenters) {
					const companyCostCenterWithSameName = companyCostCenters.find(ccc => ccc.name === deletedCostCenter.name);
					if (companyCostCenterWithSameName !== undefined) {
						const createdCostCentreId = await createCostCenter(
							type === "jobs", projectId, createdSectionId,
							companyCostCenterWithSameName.name,
							companyCostCenterWithSameName.id
						)
						if (createdCostCentreId) {
							await moveDrawingsToExistingCostCenter(deletedCostCenter, createdCostCentreId)
						}
					}
					else {
						costCentersThatRemainDeletedIds.push(deletedCostCenter.id)
					}
				}
				if (costCentersThatRemainDeletedIds.length > 0) {
					await apiInstance.projectsApi.moveDeletedCostCenterWithoutCreatingExternally(
						costCentersThatRemainDeletedIds,
						createdSectionId
					);
				}
				refreshProjectTreeAfterSimproUpdate();
				onClose();
			}
		}
		catch (error) {
			LoggerService.logError(error)
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.assignToNewSectionModal.onAssignmentErrDesc")
			)
		}
		finally {
			setIsLoading(false)
		}
	}

	const createNewSection = async () => {
		try {
			const response = await (project!.source === ProjectSource.JOB
				? apiInstance.projectsApi.postNewJobSection(project!.id, sectionName)
				: apiInstance.projectsApi.postNewQuoteSection(project!.id, sectionName))

			if (response.errors.length > 0) {
				response.errors.forEach(error => {
					NotificationService.errorNotification(
						t("common.error"),
						error.message
					)
				})
				return null
			}
			else {
				return response.id
			}
		}
		catch (error) {
			LoggerService.logError(error)
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.assignToNewSectionModal.sectionCreationErrorDesc")
			)
		}
	}

	const onSectionNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setSectionName(evt.target.value)
	}

	return (
		<ModalWithHeader customClass={"assign-to-new-section-modal"} isOpen={true}
						 title={t("projects.details.assignToNewSectionModal.title")} onClose={onClose}>
			<div className={"assign-to-new-section-modal_container"}>
				<span className={"assign-to-new-section-modal_container_label"}>
					{t("projects.details.assignToNewSectionModal.header")}</span>
				<TextInput value={sectionName} onChange={onSectionNameChange}
						   placeholder={t("projects.details.assignToNewSectionModal.inputPlaceholder")}
						   className={"assign-to-new-section-modal_container_input"}/>
				<div className={"assign-to-new-section-modal_container_footer"}>
					<ModalButtons>
						{isLoading && <InlineSpinner/>}
						<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
						<Button label={t("common.buttons.save")} disabled={!canSave}
								variant={"primary"}
								onClick={onSave}/>
					</ModalButtons>
				</div>
			</div>
		</ModalWithHeader>
	)
}