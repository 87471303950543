import React, {useMemo} from "react";
import isEqual from "lodash/isEqual";
import {Colors} from "../../../../../../styles";
import {Group, Line, Rect} from "react-konva";
import {STROKE_FRAME_WIDTH} from "./types";
import {useSelector} from "react-redux";
import {selectSymbolRecognitionResults, selectSymbolRecognitionSelection} from "./symbolRecognitionSlice";
import {getFrameMap} from "./utils";
import {selectStageConfig} from "../config/configSlice";


function ResultFrames() {
	const selection = useSelector(selectSymbolRecognitionSelection)
	const {
		referentialData: symbolRecognitionResultList,
		remainingIndexes: remainingResultsIndexes,
		approvedIndexes: approvedResultsIndexes,
		activeIndex: displayedResultIndex,
	} = useSelector(selectSymbolRecognitionResults)
	const stageConfig = useSelector(selectStageConfig);

	const frames: JSX.Element[] = useMemo(() => {
		if (!selection) return []

		const remainingResultsFrameMap = getFrameMap(
			remainingResultsIndexes, Colors.RED2, selection, symbolRecognitionResultList, stageConfig
		)
		const approvedResultsFrameMap = getFrameMap(
			approvedResultsIndexes, Colors.GREEN2, selection, symbolRecognitionResultList, stageConfig
		)

		const frameMap: Record<number, JSX.Element> = {...remainingResultsFrameMap, ...approvedResultsFrameMap}

		const activeItem = symbolRecognitionResultList[displayedResultIndex]
		if (activeItem) {
			const markSize = Math.min(selection.width / 3, selection.height / 3)
			const borderStyle = {
				strokeWidth: STROKE_FRAME_WIDTH,
				stroke: Colors.RED
			}

			frameMap[displayedResultIndex] = (
				<Group
					key={displayedResultIndex}
					x={activeItem.x + stageConfig.backgroundImagePosition.x}
					y={activeItem.y + stageConfig.backgroundImagePosition.y}
					offsetX={selection.width / 2}
					offsetY={selection.height / 2}
					height={selection.height}
					width={selection.width}
					rotation={activeItem.rotationAngleInDegrees}
				>
					<Rect
						strokeWidth={STROKE_FRAME_WIDTH}
						fill={'rgba(255, 0, 0, 0.1)'}
						x={0}
						y={0}
						height={selection.height}
						width={selection.width}
					/>
					<Line
						points={[
							0, markSize,
							0, 0,
							markSize, 0
						]}
						{...borderStyle}
					/>
					<Line
						points={[
							selection.width - markSize, 0,
							selection.width, 0,
							selection.width, markSize
						]}
						{...borderStyle}
					/>
					<Line
						points={[
							0, selection.height - markSize,
							0, selection.height,
							markSize, selection.height
						]}
						{...borderStyle}
					/>
					<Line
						points={[
							selection.width - markSize, selection.height,
							selection.width, selection.height,
							selection.width, selection.height - markSize
						]}
						{...borderStyle}
					/>
				</Group>
			)
		}

		return Object.values(frameMap)
	}, [
		approvedResultsIndexes, displayedResultIndex, remainingResultsIndexes, selection, symbolRecognitionResultList, stageConfig
	])

	return (
		<Group>
			{frames}
		</Group>
	)
}


function _ResultFrameKonva({
	stroke, x, y, height, width, rotation
}: {
	stroke: Colors,
	x: number,
	y: number,
	height: number,
	width: number,
	rotation: number,
}) {
	return (
		<Rect
			stroke={stroke}
			strokeWidth={STROKE_FRAME_WIDTH}
			x={x}
			y={y}
			offsetX={width / 2}
			offsetY={height / 2}
			height={height}
			width={width}
			rotation={rotation}
		/>
	)
}

const ResultFrameKonva = React.memo(_ResultFrameKonva, isEqual)

export {ResultFrames, ResultFrameKonva}