import {Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {deserializePlanToSummaryItems} from "../features/editor/features/serialization/SerializationHelper";
import {PlanSummaryItem} from "../../../models/PlanSummaryItem";

export interface UseDrawingSummaryHook {
	drawingSummaryItems: PlanSummaryItem[],
	loadData: () => void,
	loadStatus: Status;
}

function useDrawingSummary(versionId: string): UseDrawingSummaryHook {

	const {t} = useTranslation();

	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const [drawingSummaryItems, setDrawingSummaryItems] = useState<PlanSummaryItem[]>([]);

	const loadData = () => {
		setLoadStatus(Status.LOADING);
		apiInstance.drawingsApi.fetchDrawingData(versionId)
			.then((drawingDetails) => {
				if (drawingDetails.data) {
					setDrawingSummaryItems(
						deserializePlanToSummaryItems(
							drawingDetails.data,
							drawingDetails.measurements,
							drawingDetails.planScale
						)
					)
				}
				setLoadStatus(Status.SUCCESS);
			})
			.catch(err => {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"), t("editor.loadDrawingErrorDesc.general"))
			})
	};

	return {drawingSummaryItems, loadData, loadStatus}
}

export default useDrawingSummary;
