import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorBL: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M17.023,6.335l-8.912,8.912l-0.004,-5.44l-1.772,-0.002l0.008,8.477l8.477,0.008l-0.001,-1.772l-5.441,-0.004l8.912,-8.912l-1.267,-1.267Z"]}/>
	);
};

export default AnchorBL
