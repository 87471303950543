import {AreaMeasurement_v2, AreaMeasurement_v3, EditorDrawingData_v2, EditorDrawingData_v3} from "../../../models/editor";
import {DrawingItemType} from "../../../../../../base-konva/enums";

export function from_v2_to_v3(schema: EditorDrawingData_v2): EditorDrawingData_v3 {
	return {
		...schema,
		schemaVersion: 3,
		items: schema.items.map(item => {
			if (item.type === DrawingItemType.AREA) {
				const areaMeasurement = item.data as AreaMeasurement_v2
				delete areaMeasurement.deduction;
				const areaMeasurement_v3: AreaMeasurement_v3 = areaMeasurement;
				return {
					type: DrawingItemType.AREA,
					data: areaMeasurement_v3
				}
			}
			return item
		})
	}
}
