import React from "react";
import {CatalogItemGroup} from "../../../../../../../../models/CatalogItemGroup";

type BreadcrumbsProps = {
	groupsBreadcrumbs: CatalogItemGroup[]
	onEnteredGroupClick: (enteredGroup: CatalogItemGroup) => void
}
export const Breadcrumbs: React.FC<BreadcrumbsProps> = function(props) {
	const {groupsBreadcrumbs, onEnteredGroupClick} = props
	return (
		<div className="material-modal_container_content_breadcrumbs">
			{groupsBreadcrumbs.map((enteredGroup, index) => {
				return (
					<span key={index}>
						<span className="link" onClick={() => onEnteredGroupClick(enteredGroup)}>{enteredGroup.name}</span>
						{(index !== groupsBreadcrumbs.length - 1) && <span>{" / "}</span>}
					</span>
				);
			})}
		</div>
	)
}