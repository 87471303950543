import React from "react";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import "./planUploadNoScaleInfoModal.scss"
import IconWarning from "../../../../../project-drawings/features/editor/icons/IconWarning";
import {Colors} from "../../../../../../styles";

const PlanUploadNoScaleInfoModal: React.FC<{
	onClose: () => void,
	pageTitles: string[]
}> = ({
	onClose,
	pageTitles
}) => {
	const {t} = useTranslation();

	return (
		<ModalWithHeader
			isOpen={true}
			title={t("planUploadEditor.planUploadNoScaleInfoModal.title")}
			onClose={onClose}
		>
			<div className="plan-upload-no-scale-info-modal">
				<div className="plan-upload-no-scale-info-modal_content">
					<div className="plan-upload-no-scale-info-modal_content_warning-info">
						<div><IconWarning fill={Colors.TRANSPARENT}/></div>
						<div>{t("planUploadEditor.planUploadNoScaleInfoModal.warningInfo")}</div>
					</div>
					<div className="plan-upload-no-scale-info-modal_content_pages">
						{pageTitles.map((pageTitle, index) => (
							<div key={index}>{pageTitle}</div>
						))}
					</div>
				</div>
				<ModalButtons>
					<Button variant={"secondary"} label={t("common.buttons.ok")} onClick={onClose}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	)
}

export {PlanUploadNoScaleInfoModal}