import {Vector2d} from "konva/types/types";
import {getMovedPoints} from "../../utils";
import {PenGroup} from "../../../../../base-konva/types";

export class PenHelper {
	static getMovedPenGroups(penGroups: PenGroup[], delta: Vector2d): PenGroup[] {
		return penGroups.map(penGroup => ({
			...penGroup,
			penLines: penGroup.penLines.map(penLine => ({
				...penLine,
				points: getMovedPoints(penLine.points, delta)
			}))
		}))
	}
}