import React, {ChangeEvent, useState} from "react";
import "./assignToNewCostCenterModal.scss";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../../components/modal/ModalButtons";
import Button from "../../../../components/ui/Button";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import {useLocation, useParams} from "react-router-dom";
import {CreateCostCenterField} from "./CreateCostCenterField";
import InlineSpinner from "../../../../components/InlineSpinner";
import CustomSelect from "../../../../components/ui/CustomSelect";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import LoggerService from "../../../../services/LoggerService";
import {useCreateCostCenter} from "../../hooks";
import {CostCenter} from "../../../../models/CostCenter";

interface AssignToNewCostCenterModalProps {
	onClose: () => void
	deletedCostCenter: CostCenter
}

const AssignToNewCostCenterModal: React.FC<AssignToNewCostCenterModalProps> = ({
		onClose, deletedCostCenter
	}) => {
		const {t} = useTranslation();
		const {treeData: {sections}, refreshProjectTreeAfterSimproUpdate, isServiceProject} = useProjectDetailContext()
		const [costCenterName, setCostCenterName] = useState<string>("")
		const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>(isServiceProject ? sections[0].id : undefined)
		const [companyCostCenterId, setCompanyCostCenterId] = useState<number | undefined>(undefined)
		const [isLoading, setIsLoading] = useState<boolean>(false)
		const {createCostCenter} = useCreateCostCenter();
		const location = useLocation();
		const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
		const {projectId} = useParams<{ projectId: string }>();
		const saveDisabled = costCenterName.length === 0 || !companyCostCenterId || !selectedSectionId;
		const sectionsToSelect = sections.filter(s => !s.deletedExternal);

		const onCostCenterNameChange = (event: ChangeEvent<HTMLInputElement>) => {
			setCostCenterName(event.target.value)
		}

		const handleCompanyCostCenterChange = (ccId: number) => {
			setCompanyCostCenterId(ccId);
		};

		const handleSectionSelect = (sectionId: string) => {
			setSelectedSectionId(sectionId)
		}

		const moveDrawingsToNewCostCenter = (movedDrawingsIds: string[], createdCostCentreId: string) => {
			apiInstance.drawingsApi.putDrawingsToNewCostCenter(movedDrawingsIds, createdCostCentreId)
				.then(() => {
					NotificationService.successNotification(t("common.success"),
						t(`projects.details.assignToNewCostCenterModal.onAssignmentSuccess`));
					refreshProjectTreeAfterSimproUpdate();
					onClose();
				})
				.catch((error) => {
					LoggerService.logError(error)
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.assignToNewCostCenterModal.onAssignmentErrDesc")
					)
				})
		}

		const onSave = async () => {
			setIsLoading(true)
			try {
				if (saveDisabled) {
					return;
				}
				const createdCostCentreId = await createCostCenter(type === "jobs", projectId, selectedSectionId, costCenterName,
					companyCostCenterId!);
				const movedDrawingsIds = deletedCostCenter.drawings.map(drawing => drawing.id)
				if (movedDrawingsIds && movedDrawingsIds.length > 0 && createdCostCentreId) {
					moveDrawingsToNewCostCenter(movedDrawingsIds, createdCostCentreId)
				}
				else {
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.assignToNewCostCenterModal.onAssignmentErrDesc")
					)
				}
			}
			finally {
				setIsLoading(false)
			}
		}

		return (
			<ModalWithHeader
				customClass={"assign-to-new-cost-center-modal"}
				isOpen={true} title={t("projects.details.assignToNewCostCenterModal.title")}
				onClose={onClose}>
				<div className={"assign-to-new-cost-center-modal_container"}>
					{!isServiceProject && <div className={"assign-to-new-cost-center-modal_container_field"}>
						<span className={"assign-to-new-cost-center-modal_container_field_input-label"}>
						{t("projects.details.assignToNewCostCenterModal.sectionSelectLabel")}
					</span>
						<CustomSelect placeholder={t("projects.details.createNewDrawingModal.sectionPlaceholder")}
									  customClass="assign-to-new-cost-center-modal_container_field_select"
									  items={sectionsToSelect.map(s => ({label: s.getLabel(), value: s.id}))}
									  onChange={handleSectionSelect}
									  value={selectedSectionId}/>
					</div>}
					<div className={"assign-to-new-cost-center-modal_container_field"}>
						<CreateCostCenterField
							onCostCenterNameChange={onCostCenterNameChange}
							handleCompanyCostCenterChange={handleCompanyCostCenterChange}
							companyCostCenterId={companyCostCenterId}
							costCenterName={costCenterName}/>
					</div>
					<ModalButtons>
						{isLoading && <InlineSpinner/>}
						<Button label={t("common.buttons.cancel")}
								variant={"optional"}
								disabled={isLoading}
								onClick={onClose}/>
						<Button label={t("common.buttons.save")}
								variant={"primary"}
								disabled={saveDisabled || isLoading}
								onClick={onSave}/>
					</ModalButtons>
				</div>
			</ModalWithHeader>
		);
	}
;

export default AssignToNewCostCenterModal
