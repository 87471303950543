import {PDFDocumentProxy, PDFPageProxy} from "pdfjs-dist/types/display/api";
import {getDocument, GlobalWorkerOptions} from "pdfjs-dist"

GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

export const pdfGetDocumentError = "pdfGetDocumentError";

export function blobToBase64(blob: Blob): Promise<string> {
	const reader = new FileReader();
	reader.readAsDataURL(blob);
	return new Promise<string>(resolve => {
		reader.onloadend = () => {
			resolve(reader.result?.toString() || "");
		};
	});
}

export const convertPdfToImages = async (fileDownloadUrl: string) => {
	const base64ImageStrings: string[] = [];
	let pdf;
	try {
		pdf = await getDocument(fileDownloadUrl).promise
	}
	catch (e) {
		throw new Error(pdfGetDocumentError);
	}
	const canvas = document.createElement("canvas");
	const pagesPromises = getPdfPagesPromises(pdf);
	const pages = await Promise.all(pagesPromises)
	for (let page of pages) {
		const viewport = page.getViewport({scale: 1});
		const context = canvas.getContext("2d");
		canvas.height = viewport.height;
		canvas.width = viewport.width;
		await page.render({canvasContext: context!, viewport: viewport}).promise;
		base64ImageStrings.push(canvas.toDataURL());
	}
	canvas.remove();
	return base64ImageStrings;
}

const getPdfPagesPromises = (pdf: PDFDocumentProxy): Promise<PDFPageProxy>[] => {
	const pages: Promise<PDFPageProxy>[] = [];
	for (let i = 0; i < pdf.numPages; i++) {
		pages.push(pdf.getPage(i + 1));
	}
	return pages;
}