import React from "react";
import {SymbolType} from "../../project-drawings/features/editor/icons/symbol_pack/default";
import {capitalizeString, colorHexToRGB} from "../../../utils/TextUtils";
import {useSymbolImage} from "../../project-drawings/features/editor/features/count/useSymbolImage";
import {RemoteSymbol} from "../../project-drawings/features/editor/models/editor";
import {getFilters} from "../../../utils/ColorChanger";
import isEqual from "lodash/isEqual";
import {Colors} from "../../../styles";


type SymbolPreviewElementProps = {
	symbol?: SymbolType
	color?: string
	remoteSymbol?: RemoteSymbol
}

function SymbolPreviewElement({symbol, remoteSymbol, color}: SymbolPreviewElementProps) {
	const [image] = useSymbolImage(symbol, remoteSymbol)

	function getDescription() {
		if (symbol) {
			return capitalizeString(symbol)
		}
		if (remoteSymbol) {
			return remoteSymbol.filename
		}
		return ""
	}

	return (
		<>
			{image &&
				<div style={{
					width: "inherit",
					height: "inherit",
					backgroundImage: `url("${image.src}")`,
					backgroundSize: "cover",
					display: "flex",
				}}>
					<img
						style={{
							width: "inherit",
							height: "inherit",
							mixBlendMode: "multiply",
							filter: getFilters(colorHexToRGB(color ?? Colors.SIMPROBLUE), true)
						}}
						src={image!.src}
						alt={getDescription()}/>
				</div>
			}
		</>
	)
}

export default React.memo(SymbolPreviewElement, isEqual)