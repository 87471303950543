import React from "react";
import {selectPlanUploadHasRedo, selectPlanUploadHasUndo} from "../planUploadStore";
import BaseEditorHeaderUndoPanel from "../../../../base-konva/features/undo/BaseEditorHeaderUndoPanel";
import {useSelector} from "react-redux";


const EditorHeaderUndoPanel: React.FC = () => {
	const hasUndo = useSelector(selectPlanUploadHasUndo);
	const hasRedo = useSelector(selectPlanUploadHasRedo);

	return (
		<BaseEditorHeaderUndoPanel hasUndo={hasUndo} hasRedo={hasRedo}/>
	);
};

export default EditorHeaderUndoPanel
