import React, {useState} from "react";
import "./settingsSymbols.scss";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../components/layout/table/TableHeaderCell";
import {SortConfig, TableColumnConfig, WithActionsListItem} from "../../../models/interfaces";
import useSymbolSystemPacks from "../hooks/useSymbolSystemPacks";
import TableOverlaySpinner from "../../../components/ui/TableOverlaySpinner";
import {SortDirection, Status} from "../../../models/enums";
import {OnFailure} from "../../../utils/CallbackUtils";
import {getSortedDefaultSymbolPacks} from "../../project-drawings/features/editor/icons/symbol_pack/defaultPacks";
import SymbolSystemPacksTableRow from "./SymbolSystemPacksTableRow";
import {SymbolSystemPack} from "../../../models/SymbolSystemPack";
import {useChangeSystemPackVisibility} from "../hooks/useChangeSystemPackVisibility";

const SymbolSystemPacksTable: React.FC = () => {
	const {enabledSystemPackIds, loadStatus} = useSymbolSystemPacks();
	const {changeSystemPackVisibility, saveStatus} = useChangeSystemPackVisibility()
	const [sortConfig, setSortConfig] = useState<SortConfig<SymbolSystemPack>>({key: "industry", direction: SortDirection.ASC});
	const {t} = useTranslation();

	const columns: TableColumnConfig<WithActionsListItem<SymbolSystemPack>>[] = [
		{
			id: "industry",
			label: t("settings.tabs.symbols.packs.industryColumn"),
			sortable: true
		},
		{
			id: "group",
			label: t("settings.tabs.symbols.packs.groupColumn"),
			sortable: true
		},
		{
			id: "name",
			label: t("settings.tabs.symbols.packs.nameColumn"),
			sortable: true
		},
		{
			id: "actions",
			label: t("settings.tabs.symbols.packs.enableColumn"),
			sortable: false,
			width: 77
		}
	];

	const onHeaderCellClick = (id: string) => {
		if (id === sortConfig.key) {
			setSortConfig(
				{
					...sortConfig,
					direction: sortConfig.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
				}
			);
		}
		else {
			setSortConfig(
				{
					key: id as keyof SymbolSystemPack,
					direction: SortDirection.DESC
				}
			)
		}
	};

	const symbolPacks = getSortedDefaultSymbolPacks(sortConfig);

	const isLoading = loadStatus === Status.LOADING || saveStatus === Status.LOADING;

	return (
		<TableOverlaySpinner loading={isLoading}>
			<table className={"table"}>
				<thead>
				<tr>
					{columns.map(
						col => <TableHeaderCell<WithActionsListItem<SymbolSystemPack>> key={col.id}
																					   id={col.id}
																					   sortable={col.sortable}
																					   sortConfig={sortConfig}
																					   onClick={onHeaderCellClick}
																					   label={col.label}
																					   width={col.width}/>)}
				</tr>
				</thead>
				<tbody>
				{symbolPacks.map(symbolPack => {
					const onChange = (enabled: boolean, onFailure: OnFailure) => changeSystemPackVisibility(symbolPack.id,
						enabled, onFailure);
					return <SymbolSystemPacksTableRow key={symbolPack.id}
													  symbolPack={symbolPack}
													  enabledSystemPackIds={enabledSystemPackIds}
													  onSystemPackVisibilityChange={onChange}
													  disabled={isLoading}/>;
				})}
				</tbody>
			</table>
		</TableOverlaySpinner>
	);
};

export default SymbolSystemPacksTable;
