import React from "react";

import "./projectStatusPresenter.scss";
import ProjectStatus from "../../models/ProjectStatus";
import Tooltip from "../tooltip/Tooltip";

interface IProjectStatusPresenterProps {
	status: ProjectStatus | undefined,
}

const ProjectStatusPresenter: React.FC<IProjectStatusPresenterProps> = ({status}) => {

	return status ? (
		<div className={"project-status-presenter"}>
			<Tooltip tooltipText={status.name}>
				<div className="project-status-presenter_dot"
					 style={{backgroundColor: status.color}}
					 data-class={"project-status-presenter_tooltip"}>
				</div>
			</Tooltip>
		</div>
	) : null;
};

export default ProjectStatusPresenter
