import React from "react";

interface IconTextToolSmallProps {
	isActive?: boolean
}

const IconTextToolSmall: React.FC<IconTextToolSmallProps> = (props) => {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16">
			<path
				d="M 3.004 2.5 3.004 3.96630055 3.89174253 3.96630055 3.89097259 3.30101277 7.60978996 3.3017973 7.60978996 12.6974182 6.47797598 12.6974182 6.47797598 13.5 9.57622051 13.5 9.57622051 12.6974182 8.49676255 12.6974182 8.49676255 3.3017973 12.1162575 3.30101277 12.1162575 3.96630055 13.004 3.96630055 13.004 2.5z"
				fill={props.isActive ? "white" : "#262626"}/>
		</svg>
	);
};

export default IconTextToolSmall
