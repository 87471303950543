import React from "react";
import {Group, Rect} from "react-konva";
import useGroupMeasurementSelect from "./useGroupMeasurementSelect";
import {useSelector} from "react-redux";
import {selectActiveTool, selectStageConfig} from "../config/configSlice";
import {EditorTool} from "../../models/enums";

export const GroupMeasurementSelectGroup: React.FC = () => {
	const stageConfig = useSelector(selectStageConfig)
	const selectionState = useSelector(selectActiveTool) === EditorTool.GROUP_MEASUREMENT_SELECT;
	const {render, onMouseDown, onMouseMove, onMouseUp} = useGroupMeasurementSelect();

	return <Group listening={selectionState}
				  onMouseDown={onMouseDown}
				  onTouchStart={onMouseDown}
				  onMouseMove={onMouseMove}
				  onTouchMove={onMouseMove}
				  onMouseUp={onMouseUp}
				  onTouchEnd={onMouseUp}>
		<Rect name={"background_rect"} width={stageConfig.width} height={stageConfig.height}/>
		{render()}
	</Group>
};
