import {KonvaEventObject} from "konva/types/Node";
import React, {useEffect, useRef, useState} from "react";
import {Rect} from "react-konva";
import {useSelector, useStore} from "react-redux";
import {EditorTool} from "../../models/enums";
import {selectActiveTool} from "../config/configSlice";
import {GroupMeasurementSelectionHelper} from "./GroupMeasurementSelectionHelper";
import {useSelectSetter} from "../../hooks/select";
import {selectSelectionState} from "../view/viewSlice";
import {useEditorDrawnItemsDataContext} from "../../EditorDrawnItemsDataProvider";
import {RootState} from "../../editorStore";
import {Colors} from "../../../../../../styles";
import {KonvaEventType} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {DrawingItemType} from "../../../../../base-konva/enums";

interface UseGroupMeasurementSelectHook {
	render: () => JSX.Element | JSX.Element[] | null,
	onMouseDown: (evt: KonvaEventObject<KonvaEventType>) => void,
	onMouseMove: (evt: KonvaEventObject<KonvaEventType>) => void,
	onMouseUp: () => void,
}

function useGroupMeasurementSelect(): UseGroupMeasurementSelectHook {
	const selectActions = useSelectSetter();
	const selectionState = useSelector(selectActiveTool) === EditorTool.GROUP_MEASUREMENT_SELECT;
	const selection = useSelector(selectSelectionState);
	const [isDrawing, setIsDrawing] = useState(false)
	const [selectionPoints, setSelectionPoints] = useState<number[]>([]);
	const [boundaryPoints, setBoundaryPoints] = useState<number[]>([]);
	const resetSelectionRef = useRef(resetSelection)
	const {textToolRectPointsMap, imageToolRectPointsMap} = useEditorDrawnItemsDataContext()
	const store = useStore<RootState>()

	useEffect(() => {
		(!selectionState || selection.length === 0) && resetSelectionRef.current()
	}, [selectionState, selection, resetSelectionRef]);

	function resetSelection() {
		setBoundaryPoints([]);
		setSelectionPoints([]);
	}

	function onMouseDown(evt: KonvaEventObject<KonvaEventType>) {
		setIsDrawing(true);
		const position = getPointerPosition(evt);
		if (position) {
			setSelectionPoints([position.x, position.y]);
		}
	}

	function onMouseUp() {
		setIsDrawing(false);
		selectActions.clearSelection();
		const boundary = GroupMeasurementSelectionHelper.getItemsWithinBoundary(
			store,
			{x: selectionPoints[0], y: selectionPoints[1]},
			{x: selectionPoints[2], y: selectionPoints[3]},
			textToolRectPointsMap,
			imageToolRectPointsMap
		);
		boundary.selectedItems.length.forEach(selectedFragment => {
			selectActions.setSelection(
				DrawingItemType.LENGTH,
				selectedFragment.measurementId,
				selectedFragment.fragmentId,
				selectedFragment.subItemIds
			);
		});
		boundary.selectedItems.area.forEach(selectedFragment => {
			selectActions.setSelection(
				DrawingItemType.AREA,
				selectedFragment.measurementId,
				selectedFragment.fragmentId
			);
		});
		boundary.selectedItems.count.forEach(selectedFragment => {
			selectActions.setSelection(
				DrawingItemType.COUNT,
				selectedFragment.measurementId,
				undefined,
				selectedFragment.subItemIds
			);
		});
		boundary.selectedItems.pen.forEach(selectedFragment => {
			selectActions.setSelection(
				DrawingItemType.PEN,
				selectedFragment.measurementId,
				undefined,
				selectedFragment.subItemIds
			)
		})
		boundary.selectedItems.text.forEach(selectedFragment => {
			selectActions.setSelection(
				DrawingItemType.TEXT,
				selectedFragment.measurementId,
				undefined,
				selectedFragment.subItemIds
			)
		})
		boundary.selectedItems.image.forEach(selectedFragment => {
			selectActions.setSelection(
				DrawingItemType.IMAGE,
				selectedFragment.measurementId
			)
		})
		setSelectionPoints([]);
		setBoundaryPoints([boundary.from.x, boundary.from.y, boundary.to.x, boundary.to.y]);
	}

	function onMouseMove(evt: KonvaEventObject<KonvaEventType>) {
		const position = getPointerPosition(evt);
		if (position && isDrawing) {
			setSelectionPoints(points => {
				if (points.length <= 1) return [position.x, position.y]
				return [points[0], points[1], position.x, position.y]
			})
		}
	}

	function render() {
		if (!selectionState)
			return null;
		return <>
			{renderSelection()}
			{renderBoundary()}
		</>
	}

	function renderSelection() {
		if (selectionPoints.length < 4)
			return null;
		return <Rect key={"SELECTION"}
					 x={Math.min(selectionPoints[0], selectionPoints[2])}
					 y={Math.min(selectionPoints[1], selectionPoints[3])}
					 width={Math.abs(selectionPoints[2] - selectionPoints[0])}
					 height={Math.abs(selectionPoints[3] - selectionPoints[1])}
					 dash={[10]}
					 stroke={Colors.SIMPROBLUE}
					 strokeWidth={2}
					 lineCap={"round"} lineJoin={"round"}

		/>;
	}

	function renderBoundary() {
		if (boundaryPoints.length < 4)
			return null;
		return <Rect key={"BOUNDARY"}
					 x={Math.min(boundaryPoints[0], boundaryPoints[2])}
					 y={Math.min(boundaryPoints[1], boundaryPoints[3])}
					 width={Math.abs(boundaryPoints[2] - boundaryPoints[0])}
					 height={Math.abs(boundaryPoints[3] - boundaryPoints[1])}
					 stroke={Colors.SIMPROBLUE}
					 strokeWidth={2}
					 lineCap={"round"} lineJoin={"round"}

		/>;
	}

	return {render, onMouseDown, onMouseMove, onMouseUp}
}

export default useGroupMeasurementSelect;
