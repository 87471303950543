import React from "react";
import {Colors} from "../../../../../../styles";

export const IconDrawn = function() {
	const fill = Colors.SIMPROBLUE;
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path fill={fill}
				  transform={"translate(-4, -4)"}
				  d="M23.1118,11.0252 L22.5678,11.5692 L19.9708,8.9722 L20.5148,8.4272 C20.7978,8.1442 21.2578,8.1442 21.5408,8.4272 L23.1118,9.9992 C23.3948,10.2822 23.3948,10.7422 23.1118,11.0252 L23.1118,11.0252 Z M21.7078,12.4292 L11.8508,22.2872 L9.2538,19.6902 L19.1108,9.8322 L21.7078,12.4292 Z M10.6038,22.7592 L8.3668,23.1732 L8.7808,20.9372 L10.6038,22.7592 Z M23.9718,9.1392 L22.4008,7.5672 C21.6438,6.8112 20.4128,6.8112 19.6548,7.5672 L7.9638,19.2592 C7.8788,19.3452 7.8178,19.4612 7.7958,19.5792 L7.0098,23.8222 C6.9738,24.0192 7.0368,24.2212 7.1778,24.3632 C7.2938,24.4782 7.4478,24.5412 7.6088,24.5412 C7.6448,24.5412 7.6818,24.5372 7.7188,24.5302 L11.9618,23.7452 C12.0818,23.7222 12.1948,23.6632 12.2808,23.5772 L23.9718,11.8852 C24.7288,11.1282 24.7288,9.8962 23.9718,9.1392 L23.9718,9.1392 Z"/>
		</svg>
	)
};
