import ProjectExportStatusResult, {CostCenterExportStatusResult} from "../../../../../models/ProjectExportStatusResult";
import {ExportStatus} from "../../../../../models/SummariesExportStatusResult";
import {useState} from "react";
import {Status} from "../../../../../models/enums";
import {CostCenterExportStatuses, StatusType} from "../types";
import {CostCenter} from "../../../../../models/CostCenter";
import {apiInstance} from "../../../../../api/api";
import {OnFailure, OnSuccess} from "../../../../../utils/CallbackUtils";

function isCompleted(exportStatusResult: CostCenterExportStatusResult) {
	return exportStatusResult.status === ExportStatus.COMPLETE;
}

function getProgress(ccResult: CostCenterExportStatusResult | undefined): number {
	if (ccResult === undefined || ccResult.drawingsCount === 0) return 0;
	return Math.trunc((ccResult.exportedDrawingsCount * 100) / ccResult.drawingsCount)
}

const MEASUREMENTS_EXPORT_ABORTED = "MEASUREMENTS_EXPORT_ABORTED"

export function useMeasurementsExporter() {

	const [status, setStatus] = useState(Status.IDLE);
	const [result, setResult] = useState<CostCenterExportStatuses>([]);

	function exportMeasurements(
		projectId: string,
		costCenters: CostCenter[],
		onSuccess: OnSuccess,
		onFailure: OnFailure,
		abortSignal?: AbortSignal,
	) {
		setStatus(Status.LOADING)
		const ccToExportIds: string[] = [];
		setResult(costCenters.map(costCenter => {
			// if cc was already successfully exported, don't export it again
			const completedResult = result?.find(ccResult =>
				ccResult.costCenter.id === costCenter.id && ccResult.exportMeasurementStatus === ExportStatus.COMPLETE
			);
			if (completedResult) {
				return completedResult;
			}
			else {
				ccToExportIds.push(costCenter.id);
				return {
					costCenter,
					progress: 0,
					exportMeasurementStatus: ExportStatus.PENDING,
					exportSummaryStatus: ExportStatus.COMPLETE,
					errors: [],
					statusType: StatusType.MEASUREMENT
				}
			}
		}));

		const processExport = async function() {
			checkAbortStatus()
			let projectResults: ProjectExportStatusResult[] = [];
			let costCenterStatus: Promise<ProjectExportStatusResult>[] = [];
			for (const ccToExportId of ccToExportIds) {
				let result = await apiInstance.projectsApi.startCostCentersExport(projectId, [ccToExportId]);
				projectResults.push(result);
			}
			projectResults.forEach(resp => {
				costCenterStatus.push(apiInstance.projectsApi.fetchCostCentersExportStatus(resp.id));
			});
			return await Promise.all(costCenterStatus);

		}

		processExport().then(costCenterStatusResults => {
			let exportStatusResults: ProjectExportStatusResult[] = []
			setResult(updateResult(costCenters, costCenterStatusResults, result))
			costCenterStatusResults.forEach(costCenterStatusResult => {
				exportStatusResults.push(costCenterStatusResult);
			})
			for (const exportResult of exportStatusResults) {
				if (!exportResult.costCenterStatuses.every(isCompleted)) {
					setStatus(Status.ERROR)
					return;
				}
			}
			onSuccess()
			setStatus(Status.SUCCESS)
		}).catch(error => {
			if (error?.message === MEASUREMENTS_EXPORT_ABORTED) {
				return;
			}
			onFailure(error)
			setStatus(Status.ERROR)
		})

		function checkAbortStatus() {
			if (abortSignal?.aborted) {
				throw new Error(MEASUREMENTS_EXPORT_ABORTED)
			}
		}
	}

	function clearResult() {
		setStatus(Status.IDLE)
		setResult([])
	}

	return {
		status, result,
		exportMeasurements,
		clearResult,
	}
}

function updateResult(
	costCenters: CostCenter[],
	exportStatusResults: ProjectExportStatusResult[],
	result: CostCenterExportStatuses
): CostCenterExportStatuses {
	return costCenters.map(costCenter => {
		for (const exportStatusResult of exportStatusResults){
			const ccResult = exportStatusResult.costCenterStatuses.find(ccStatus => ccStatus.costCenterId === costCenter.id);
			if (ccResult) {
				return {
					costCenter,
					exportMeasurementStatus: ccResult?.status ?? ExportStatus.PENDING,
					exportSummaryStatus: ExportStatus.COMPLETE,
					errors: ccResult.errors.map(error => error.message) ?? [],
					progress: getProgress(ccResult),
					statusType: StatusType.MEASUREMENT
				};
			}
			const prevResult = result.find(ccRes => ccRes.costCenter.id === costCenter.id);
			if (prevResult) {
				return prevResult
			}
		}
		return {
			costCenter,
			exportMeasurementStatus: ExportStatus.PENDING,
			exportSummaryStatus: ExportStatus.COMPLETE,
			errors: [],
			progress: 0,
			statusType: StatusType.MEASUREMENT
		};
	})
}