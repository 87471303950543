import {ImageData} from "../../types";
import React, {useEffect, useRef} from "react";
import {useImage} from "../../../project-drawings/hooks/useImage";
import AttachmentApi from "../../../../api/AttachmentApi";
import {useCursorStyle} from "../../../project-drawings/features/editor/hooks/useCursorStyle";
import Konva from "konva";
import {useEditorDrawnItemsDataContext} from "../../../project-drawings/features/editor/EditorDrawnItemsDataProvider";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {
	RectPoints
} from "../../../project-drawings/features/editor/features/group-measurement-select/GroupMeasurementSelectionHelper";
import {Vector2d} from "konva/types/types";
import {Status} from "../../../../models/enums";
import {KonvaEventObject} from "konva/types/Node";
import {CursorStyle} from "../../enums";
import {ImageElementKonva, ImageEventHandlers} from "../../../project-drawings/features/editor/features/image/ImageElementKonva";
import ShapeConfig = Konva.ShapeConfig;
import {noop} from "lodash";

export type BaseImageElementActions = {
	setRemoteItemLoaded?: (id: string) => void,
	activateImage: (id: string) => void,
	transformImage: (itemId: string, newPosition: Vector2d, rotation: number, scale: Vector2d) => void,
}

type UploadedImageProps = {
	imageData: ImageData,
	actions: BaseImageElementActions,
	exportInProgress: boolean,
	onEnableHighlight?: () => void,
	onClearHighlight?: () => void,
	getStyle?: (id: string) => ShapeConfig | undefined,
	selectElement: (id: string) => void,
	isEditorToolSelect: boolean,
	isElementActive: boolean,
}

const _BaseImageElement: React.FC<UploadedImageProps> = ({
	imageData,
	actions,
	exportInProgress,
	onEnableHighlight,
	onClearHighlight,
	getStyle,
	selectElement,
	isElementActive,
	isEditorToolSelect
}) => {

	const {id, attachmentId, name, position, rotation, scale, visible} = imageData;
	const [image, status] = useImage(AttachmentApi.getAttachmentDownloadUrl(attachmentId, name), "anonymous");
	const {changeCursor} = useCursorStyle();
	const transformerRef = useRef<Konva.Transformer>(null);
	const imageRef = useRef<Konva.Image>(null);

	const isVisible = visible || exportInProgress;
	const showTransformer = !exportInProgress && isElementActive;
	const {setImageRectPoints} = useEditorDrawnItemsDataContext();
	const setImageRectPointsRef = useUpdatedRef(setImageRectPoints)

	useEffect(() => {
		if (transformerRef.current && imageRef.current) {
			transformerRef.current.nodes([imageRef.current])
			transformerRef.current.getLayer()?.batchDraw();
		}
	}, [image, showTransformer])

	useEffect(() => {
		const image = imageRef.current
		const setImageRectPoints = setImageRectPointsRef.current

		if (image) {
			const imageSize = image.size()
			const rectPoints: RectPoints = {
				topLeft: {x: 0, y: 0},
				topRight: {x: imageSize.width, y: 0},
				bottomRight: {x: imageSize.width, y: imageSize.height},
				bottomLeft: {x: 0, y: imageSize.height}
			}
			const transform = image.getTransform();
			const transformPoint = (point: Vector2d): Vector2d => {
				return transform.point(point)
			};
			setImageRectPoints(imageData.id, {
				topLeft: transformPoint(rectPoints.topLeft),
				topRight: transformPoint(rectPoints.topRight),
				bottomRight: transformPoint(rectPoints.bottomRight),
				bottomLeft: transformPoint(rectPoints.bottomLeft)
			})
		}
	}, [imageData, status, imageRef, setImageRectPointsRef]);

	useEffect(function() {
		if (status === Status.SUCCESS && actions.setRemoteItemLoaded) {
			actions.setRemoteItemLoaded(id)
		}
	}, [status, actions, id])

	function handleClick(evt: KonvaEventObject<Event>) {
		if (!isElementActive) {
			actions.activateImage(id)
			changeCursor({event: evt, cursor: CursorStyle.MOVE})
		}
	}

	function handleMouseEnter(evt: KonvaEventObject<MouseEvent>) {
		changeCursor({
			event: evt,
			cursor: isElementActive ? CursorStyle.MOVE : CursorStyle.POINTER
		})
		if (onEnableHighlight)
			onEnableHighlight()
	}

	function handleMouseLeave(evt: KonvaEventObject<MouseEvent>) {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
		if (onClearHighlight)
			onClearHighlight()
	}

	function handleTransformEnd(evt: KonvaEventObject<DragEvent>) {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT})

		const newPosition = evt.target.position();
		const rotation = evt.target.rotation();
		const scale = evt.target.scale();

		actions.transformImage(id, newPosition, rotation, scale)
	}

	const draggable = isEditorToolSelect ? false : isElementActive;

	const onSelectElement = () => {
		selectElement(id)
	}

	const eventsRef: React.MutableRefObject<ImageEventHandlers> = useUpdatedRef(
		{
			onClick: isEditorToolSelect ? onSelectElement : handleClick,
			onTap: isEditorToolSelect ? onSelectElement : handleClick,
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			onDragEnd: isEditorToolSelect ? noop : handleTransformEnd,
			onTransformEnd: isEditorToolSelect ? noop : handleTransformEnd
		}
	)

	const provideStyle = () => {
		if (getStyle)
			return getStyle(id)
	}

	return (
		<ImageElementKonva
			draggable={draggable}
			isVisible={isVisible}
			imageRef={imageRef}
			position={position}
			image={image}
			rotation={rotation}
			scale={scale}
			style={provideStyle()}
			eventsRef={eventsRef}
			showTransformer={showTransformer}
			transformerRef={transformerRef}
		/>
	)
};

export const BaseImageElement = React.memo(_BaseImageElement)