import {useEffect, useState} from "react";
import {useUpdatedRef} from "./useUpdatedRef";

export function useTableSelectionState<T extends { id: S }, S>(items: T[]) {

	const itemsRef = useUpdatedRef(items)
	const isRowSelectedRef = useUpdatedRef(isRowSelected)
	const [selectAll, setSelectAll] = useState(false);
	const [selectedIds, setSelectedIds] = useState<S[]>([]);

	useEffect(() => {
		const items = itemsRef.current
		const isRowSelected = isRowSelectedRef.current
		if (items.length > 0) {
			const selected = items.map(item => isRowSelected(item.id))
			if (selected.every(item => item)) {
				setSelectAll(true)
			}
			else {
				setSelectAll(false)
			}
		}
	}, [selectedIds, itemsRef, isRowSelectedRef]);

	function toggleHeaderSelectAll() {
		if (selectAll) {
			setSelectedIds([])
		}
		else {
			setSelectedIds(items.map(item => item.id))
		}
	}

	function toggleRowSelection(id: S) {
		if (isRowSelected(id)) {
			setSelectedIds(selectedIds.filter(selectedId => selectedId !== id))
		}
		else {
			setSelectedIds([...selectedIds, id])
		}
	}

	function isRowSelected(id: S) {
		return selectedIds.includes(id)
	}

	function reset() {
		setSelectedIds([])
	}

	return {
		header: {
			isSelected: selectAll,
			toggle: toggleHeaderSelectAll
		},
		row: {
			isSelected: isRowSelected,
			toggle: toggleRowSelection
		},
		reset
	}
}