import {SymbolMap, SymbolType} from "../../icons/symbol_pack/default";
import {Status} from "../../../../../../models/enums";
import {useImage} from "../../../../hooks/useImage";
import {useEffect, useState} from "react";
import {RemoteSymbol} from "../../models/editor";
import {useSymbolCacheDataContext} from "../../../../../../providers/SymbolCacheDataProvider";
import LoggerService from "../../../../../../services/LoggerService";
import NotificationService from "../../../../../../services/NotificationService";
import {useTranslation} from "react-i18next";

export function useSymbolImage(
	staticSymbol?: SymbolType,
	remoteSymbol?: RemoteSymbol
): [HTMLImageElement | undefined, Status] {

	const [staticSymbolImage, staticSymbolStatus] = useImage(staticSymbol ? SymbolMap[staticSymbol] : "")
	const [remoteSymbolImage, remoteSymbolStatus] = useRemoteSymbolImage(remoteSymbol)

	if (staticSymbol) {
		return [staticSymbolImage, staticSymbolStatus]
	}
	if (remoteSymbol) {
		return [remoteSymbolImage, remoteSymbolStatus]
	}
	return [undefined, Status.LOADING]
}

function useRemoteSymbolImage(remoteSymbol?: RemoteSymbol): [HTMLImageElement | undefined, Status] {

	const {t} = useTranslation();
	const {getSymbolUrl} = useSymbolCacheDataContext()
	const [attachmentDownloadStatus, setAttachmentDownloadStatus] = useState(Status.IDLE);
	const [imageUrl, setImageUrl] = useState<string | null>(null);
	const [imageApi, statusApi] = useImage(imageUrl || "")

	useEffect(() => {
		if (!remoteSymbol) {
			return;
		}

		setAttachmentDownloadStatus(Status.LOADING)

		getSymbolUrl(remoteSymbol).then(url => {
			setImageUrl(url)
			setAttachmentDownloadStatus(Status.SUCCESS)
		}).catch(err => {
			LoggerService.logError(err);
			setAttachmentDownloadStatus(Status.ERROR)
			NotificationService.errorNotification(
				t("common.error"),
				t("editor.count.fetchRemoteSymbolErrorDesc")
			);
		})
	}, [remoteSymbol, getSymbolUrl, t])

	if (attachmentDownloadStatus === Status.SUCCESS) {
		return [imageApi, statusApi]
	}
	else {
		return [undefined, attachmentDownloadStatus]
	}
}
