import {useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import {PlanTemplateData} from "../../../models/PlanTemplateData";

function usePlanTemplates(projectId: string | undefined) {

	const [templates, setTemplates] = useState<PlanTemplateData[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	function loadPlanTemplates() {
		if (!projectId) return;

		setLoadStatus(Status.LOADING)
		apiInstance.projectsApi.fetchPlanTemplates(projectId).then(templates => {
			setTemplates(templates)
			setLoadStatus(Status.SUCCESS)
		}).catch(err => {
			setLoadStatus(Status.ERROR)
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), t("projects.details.fetchPlanTemplatesErrorDesc"));

		})
	}

	return {templates, loadStatus, loadPlanTemplates}
}

export default usePlanTemplates;
