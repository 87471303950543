import React, {useMemo} from "react";
import {CostCenter} from "../../../../models/CostCenter";
import "./treeCostCenter.scss";
import TreeDrawing from "./TreeDrawing";
import {useTranslation} from "react-i18next";
import Section from "../../../../models/Section";
import {DrawingType} from "../../../../models/enums";
import {UseTreeExpandData} from "../../hooks/useTreeExpandData";
import {TreeRow} from "./TreeRow";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import TreeCostCenterHeader from "./TreeCostCenterHeader";
import {EmptyTreeRowLabel} from "./EmptyTreeRowLabel";
import {CostCenterDrawing} from "../../../../models/CostCenterDrawing";

/**
 * As built drawing should be displayed below takeoff drawing
 */
function costCenterDrawingComparator(a: CostCenterDrawing, b: CostCenterDrawing) {
	const result = a.name.localeCompare(b.name)
	if (result === 0) {
		return a.drawingType === DrawingType.AS_BUILT ? 1 : -1;
	}
	return result
}

interface ITreeCostCenterProps {
	costCenter: CostCenter,
	section: Section,
	treeExpandData: UseTreeExpandData
	openCreateBlankDrawingModal: (costCenterId?: string, sectionId?: string) => void
}

const TreeCostCenter: React.FC<ITreeCostCenterProps> = ({costCenter, section, treeExpandData, openCreateBlankDrawingModal}) => {

	const {t} = useTranslation();
	const {treeData} = useProjectDetailContext()
	const {isCostCenterSelected, selectCostCenter} = treeData.selectionData
	const open = treeExpandData.isExpanded(costCenter.id)

	const _changeSelection = () => {
		selectCostCenter(section.id, costCenter.id);
	};
	const toggleOpen = () => {
		treeExpandData.toggleCostCenter(costCenter.id)
	};

	const onCreateDrawingClick = () => {
		openCreateBlankDrawingModal(costCenter.id, section.id)
	}

	const sortedDrawings = useMemo(() => {
		return costCenter.drawings.sort(costCenterDrawingComparator);
	}, [costCenter.drawings])

	return (
		<TreeRow
			header={(
				<TreeCostCenterHeader
					isDeleted={costCenter.deletedExternal && !section.deletedExternal}
					costCenter={costCenter}
					isChecked={isCostCenterSelected(section.id, costCenter.id)}
					onChange={_changeSelection}/>
			)}
			toggleExpand={toggleOpen}
			isOpen={open}>
			{sortedDrawings.length === 0 ?
				<EmptyTreeRowLabel
					onClick={onCreateDrawingClick}
					textBeforeButton={t(`projects.details.noDrawingsInTreeMessage`)}
					textAfterButton={t(`projects.details.blankTakeoff`)}/>
				: sortedDrawings.map(dr =>
					<TreeDrawing
						key={dr.id}
						drawing={dr}
						section={section}
						costCenter={costCenter}
					/>
				)
			}
		</TreeRow>
	);
};

export default TreeCostCenter
