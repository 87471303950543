import {VersionApiDto} from "./restModels";

export default class Version {
	constructor(public readonly major: number, public readonly minor: number,) {}

	public static fromJson(json: VersionApiDto): Version {
		return new Version(json.major, json.minor);
	}

	getVersionLabel(): string {
		return `${this.major}.${this.minor}`;
	}
}