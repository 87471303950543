import React, {useCallback} from "react";
import {FileType} from "../../models/enums";
import {getAcceptString} from "../../features/project-drawings/features/editor/utils";
import {removeNotAllowedCharsFromFilename} from "../../utils/TextUtils";
import SingleFileDropzone from "./SingleFileDropzone";

interface FileUploaderProps {
	onFileUpload: (file: File) => void,
	disabled?: boolean,
	accept: FileType[],
}

const FileUploader: React.FC<FileUploaderProps> = ({onFileUpload, disabled, accept}) => {

	const onDrop = useCallback((acceptedFiles: File[]) => {
		const file = changeName(acceptedFiles[0])
		if (file) {
			onFileUpload(file);
		}
	}, [onFileUpload]);

	const changeName = (file: File): File | undefined => {
		if (file !== undefined) {
			const newFilename = removeNotAllowedCharsFromFilename(file.name);
			return new File([file], newFilename, {type: file.type});
		}
	};

	return (
		<SingleFileDropzone onDrop={onDrop} accept={getAcceptString(accept)} disabled={disabled}/>
	);
};

export default FileUploader;
