import React, {useState} from "react";
import {Image} from "@react-pdf/renderer";
import {generateLengthSymbolPreviewImageUrl} from "./utils";
import {LineStyle} from "../../../../../../base-konva/types";

type LengthSymbolPreviewProps = {
	style: LineStyle
}

export function LengthSymbolPreview({style}: LengthSymbolPreviewProps) {
	const [promise] = useState(() =>
		new Promise<string>(async (resolve, reject) => {
			try {
				const {imageUrl} = await generateLengthSymbolPreviewImageUrl(style)
				resolve(imageUrl)
			}
			catch (e) {
				reject();
			}
		})
	)

	return <Image style={{height: 20, width: 30}} src={promise}/>
}