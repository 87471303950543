import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {penActions, selectPenSelectorStyle, selectPenType} from "../../pen/penSlice";
import {BasePenToolOptions} from "../../../../../../base-konva/features/pen";
import {LineStyle, PenToolType} from "../../../../../../base-konva/types";

export const PenToolOptions: React.FC = () => {
	const dispatch = useDispatch();
	const penType = useSelector(selectPenType);
	const penSelectorStyle = useSelector(selectPenSelectorStyle);

	function handleStyleSave(style: LineStyle) {
		dispatch(penActions.changePenStyle(style));
	}

	function handlePenTypeChange(type: PenToolType) {
		dispatch(penActions.changePenType(type))
	}

	return (
		<BasePenToolOptions
			penType={penType}
			penSelectorStyle={penSelectorStyle}
			onStyleSave={handleStyleSave}
			onPenTypeChange={handlePenTypeChange}
		/>
	)
}