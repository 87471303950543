import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconRotateCw: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width={32} height={32} viewBox="0 0 32 32" fill={"none"} style={{cursor: cursor}}>
				<path
					d="M10.3431 10.3431C12.2644 8.42192 14.9994 7.63704 17.5924 8.16383L16 6.57143L16.9426 5.62885L20.2426 8.92892L16.9426 12.229L16 11.2864L17.7268 9.55966C15.4492 8.94801 12.9884 9.58299 11.2857 11.2857C8.6765 13.8949 8.6765 18.105 11.2857 20.7143C13.8949 23.3235 18.1051 23.3235 20.7143 20.7143L21.6569 21.6568C18.5385 24.7752 13.4615 24.7752 10.3431 21.6568C7.22481 18.5385 7.22481 13.4615 10.3431 10.3431Z"
					fill={fill}
				/>
				<path
					d="M16 23.3245C11.9493 23.3245 8.66553 20.0423 8.66553 15.9935C8.66553 11.9448 11.9493 8.6626 16 8.6626"
					stroke={fill}
					strokeWidth={1.3}
				/>
				<path
					d="M23.3345 16.0001C23.3345 20.0489 20.0507 23.3311 16 23.3311"
					stroke={fill}
					strokeWidth={1.3}
				/>
			</svg>
		</IconUnselectable>
	);
};

export default IconRotateCw