import {AppSettingsApiDto} from "./restModels";
import LoggerService from "../services/LoggerService";

export default class AppSettings {
	/**
	 * Settings model
	 * @param environment Environment string identifier..
	 * @param allowWithoutIframe Flag that indicates if application can be run directly, outside iframe.
	 */
	constructor(
		public readonly environment: string,
		public readonly allowWithoutIframe: boolean,
	) {}

	public static fromJson(json: AppSettingsApiDto): AppSettings | never {
		const {environment, allowWithoutIframe} = json;
		if (!environment) {
			const error = new Error("Incomplete settings.");
			LoggerService.logError(error);
			throw error;
		}
		return new AppSettings(environment, allowWithoutIframe || false);
	}
}