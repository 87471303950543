import React from "react";
import {useTranslation} from "react-i18next";
import "./confirmationModal.scss";
import Modal from "../modal/Modal";
import Button from "./Button";
import ModalTitle from "../modal/ModalTitle";
import ModalButtons from "../modal/ModalButtons";
import InlineSpinner from "../InlineSpinner";

interface ConfirmationModalProps {
	isOpen: boolean,
	isLoading?: boolean,
	text: string,
	title?: string,
	confirmationButtonLabel?: string,
	onConfirm: () => void,
	onClose: () => void,
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
	isOpen,
	isLoading,
	text,
	title,
	confirmationButtonLabel,
	onConfirm,
	onClose
}) => {
	const {t} = useTranslation();

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalTitle>{title ?? t("common.confirmationModalTitle")}</ModalTitle>
			<div className="confirmation-modal_content">{text}</div>
			<ModalButtons>
				{isLoading && <InlineSpinner/>}
				<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose} disabled={isLoading}/>
				<Button label={confirmationButtonLabel ?? t("common.buttons.ok")}
						variant={"primary"}
						onClick={onConfirm}
						disabled={isLoading}/>
			</ModalButtons>
		</Modal>
	);
};

export default ConfirmationModal;
