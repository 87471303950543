import React from "react";
import {Colors} from "../../styles";

type Props = {
	color?: string
}
export const IconTakeoff: React.FC<Props> = function({color = Colors.SIMPROBLUE}) {

	return (
		<svg width="24.503" height="24.35" viewBox="0 0 24.503 24.35">
			<g transform="translate(0.155 0.344)">
				<path d="M12.93,4H20V20H4V4H14.31" fill="none" stroke={color} strokeWidth="1"/>
				<line x2="7.36" transform="translate(4 11.45)" fill="none" stroke={color} strokeWidth="1.22"/>
				<line y2="4.36" transform="translate(11.36 9.27)" fill="none" stroke={color} strokeWidth="1.22"/>
				<line y1="1.92" transform="translate(11.36 4.33)" fill="none" stroke={color} strokeWidth="1.22"/>
				<line y1="2.45" transform="translate(11.36 17.16)" fill="none" stroke={color} strokeWidth="1.22"/>
				<rect width="3.7" height="15.96" transform="matrix(0.966, -0.259, 0.259, 0.966, 0.457, 7.977)" fill="#fff"
					  stroke={color} strokeWidth="1"/>
				<line x1="2.04" y2="0.54" transform="translate(2.74 9.84)" fill="#fff" stroke={color} strokeWidth="1"/>
				<line x1="2.03" y2="0.55" transform="translate(3.62 13.1)" fill="#fff" stroke={color} strokeWidth="1"/>
				<line x1="2.04" y2="0.54" transform="translate(4.49 16.37)" fill="#fff" stroke={color} strokeWidth="1"/>
				<line x1="2.03" y2="0.55" transform="translate(5.37 19.63)" fill="#fff" stroke={color} strokeWidth="1"/>
				<rect width="3.38" height="10.84" transform="translate(20.475 1.246) rotate(30)" fill="#fff"/>
				<rect width="3.4" height="8.53" transform="translate(19.742 2.542) rotate(30)" fill={color}/>
				<path d="M14.83,14.44c-.5-.29.24-3.8.24-3.8L18,12.34S15.34,14.73,14.83,14.44Z" fill={color}/>
				<path d="M21.5.33H23a.94.94,0,0,1,.94.94V2.86H20.56V1.27A.94.94,0,0,1,21.5.33Z"
					  transform="translate(3.78 -10.91) rotate(30)" fill={color}/>
			</g>
		</svg>
	)
}