import React from "react";
import {Colors} from "../../../../../styles";

const IconCut: React.FC = () => {
	const fill = Colors.SIMPROBLUE;
	return (
		<svg fill={fill} height={20} width={20} viewBox="0 0 21 21">
			<path className="a"
				  d="M16.71,10.49h0a2.2,2.2,0,0,0-2.42.94l-2.42-.65-.93-3.5a1.31,1.31,0,0,0-1.59-.92s0,0,0,0l1.06,4-4-1.06s0,0,0,0a1.31,1.31,0,0,0,.92,1.59l3.5.93.65,2.42a2.2,2.2,0,0,0-.94,2.42,2.14,2.14,0,0,0,1,1.34,2.11,2.11,0,0,0,1.09.3,2.25,2.25,0,0,0,.58-.08,2.21,2.21,0,0,0,1.56-2.7,2.17,2.17,0,0,0-1-1.34,2.09,2.09,0,0,0-1-.28l-.45-1.68,1.68.45a2.19,2.19,0,0,0,1.62,2,2.18,2.18,0,0,0,2.7-1.56A2.2,2.2,0,0,0,16.71,10.49Zm-3.25,5.43A.87.87,0,0,1,12.84,17a.9.9,0,0,1-.66-.08.89.89,0,0,1-.4-.53.87.87,0,0,1,.61-1.07.91.91,0,0,1,.23,0,.89.89,0,0,1,.84.65ZM17,12.84a.86.86,0,0,1-1.06.62.87.87,0,0,1,.22-1.71.91.91,0,0,1,.23,0A.86.86,0,0,1,17,12.84Z"/>
		</svg>
	);
};

export default IconCut