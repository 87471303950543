import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {planUploadViewActions, planUploadViewReducer} from "./features/view/planUploadViewSlice";
import logger from "redux-logger";
import undoable, {ActionCreators, excludeAction} from "redux-undo";
import {planUploadConfigActions, planUploadConfigReducer} from "./features/config/planUploadConfigSlice";
import {planUploadPenReducer} from "./features/pen";
import {planUploadEditorIsDirtyMarker} from "./middlewares/planUploadEditorIsDirtyMarker";
import {planUploadClipReducer} from "./features/clip/planUploadClipSlice";
import {PlanUploadPageData} from "./types";
import {planUploadEraseAreaReducer} from "./features/erase-area/planUploadEraseAreaSlice";
import {planUploadTextActions, planUploadTextReducer} from "./features/text/planUploadTextSlice";
import {planUploadImageReducer} from "./features/image/planUploadImageSlice";
import {planUploadScaleActions, planUploadScaleReducer} from "./features/scale/planUploadScaleSlice";

function getPlanUploadStore(pageNumber: number | undefined): StoreInstanceType | undefined {
	if (pageNumber) {
		return planUploadStoreMap.get(pageNumber)
	}
	return undefined
}

function initPlanUploadStoreMap(pages: PlanUploadPageData[]) {
	planUploadStoreMap.clear()
	pages.forEach(page => {
		const store = getStoreInstance();
		initScale(store, page)
		planUploadStoreMap.set(page.pageNumber, store)
	})
}

function getStoreInstance() {
	return configureStore({
		reducer: {
			planUploadView: planUploadViewReducer,
			planUploadScale: planUploadScaleReducer,
			planUploadUndoGroup: planUploadUndoReducer,
		},
		middleware: (getDefaultMiddleware => {
			const custom: any[] = [
				/* autoFinishToolWhenSwitchEditorTool,
				activeItemsCleaner*/
				planUploadEditorIsDirtyMarker
			]

			if (process.env.NODE_ENV === "production") {
				return getDefaultMiddleware().concat(...custom)
			}
			return getDefaultMiddleware().concat(...custom
				, logger
			)
		})
	})
}

function checkIfAnyEditorIsDirty(): boolean {
	return Array.from(planUploadStoreMap.values()).some(store =>
		store.getState().planUploadView.editorIsDirty
	)
}

type CheckResult = { allPagesHaveScale: true } | { allPagesHaveScale: false, noScalePageNumbers: number[] }

function checkPagesScalePresence(): CheckResult {
	const storeEntries = Array.from(planUploadStoreMap.entries())
	const noScaleEntries = storeEntries.filter(
		([, store]) => store.getState().planUploadScale.scaleConfig.scale === null
	)

	if (noScaleEntries.length === 0) {
		return {allPagesHaveScale: true}
	}
	else {
		return {
			allPagesHaveScale: false,
			noScalePageNumbers: noScaleEntries.map(([pageNumber,]) => pageNumber)
		}
	}
}

function markPlanUploadStoreAsDirty(pageNumber: number) {
	const store = planUploadStoreMap.get(pageNumber);
	if (store) {
		store.dispatch(planUploadViewActions.setEditorIsDirty(true))
	}
}

function clearStoresDirtyFlag() {
	const storeEntries = Array.from(planUploadStoreMap.entries())
	storeEntries.forEach(([, store]) => {
		store.dispatch(planUploadViewActions.setEditorIsDirty(false))
	})
}

function deletePlanUploadStoreInstance(pageNumber: number) {
	planUploadStoreMap.delete(pageNumber)
}

function deleteAllPlanUploadStoreInstances() {
	planUploadStoreMap.clear()
}

function resetPlanUploadStoreInstance(pageNumber: number, initData?: PlanUploadPageData) {
	deletePlanUploadStoreInstance(pageNumber);
	const store = getStoreInstance();
	if (initData) {
		initScale(store, initData)
	}
	planUploadStoreMap.set(pageNumber, store);
}

function initScale(store: StoreInstanceType, initData: PlanUploadPageData) {
	const {planScale, pageScaleRatio} = initData
	store.dispatch(planUploadScaleActions.reset({
		planScale: planScale ?? null,
		unitFactor: null,
		isUndoRedoExcluded: true
	}))
	store.dispatch(planUploadViewActions.reset(pageScaleRatio ? {...pageScaleRatio} : null))
	store.dispatch(ActionCreators.clearHistory())
}

const planUploadUndoReducer = undoable(combineReducers({
	config: planUploadConfigReducer,
	pen: planUploadPenReducer,
	text: planUploadTextReducer,
	clip: planUploadClipReducer,
	eraseArea: planUploadEraseAreaReducer,
	image: planUploadImageReducer
}), {
	groupBy: (action: any) => action.payload?.actionId || null,
	filter: (action, currentState, previousHistory) => {
		if (action.payload?.isUndoRedoExcluded) {
			return false;
		}
		if (typeof action.type === "string" &&
			(action.type.startsWith("plan-upload-view") || action.type.startsWith("plan-upload-scale"))) {
			return false;
		}
		else {
			return excludeAction([
				planUploadTextActions.addTextElement.type,
				planUploadConfigActions.setInitialStageConfig.type,
			])(action, currentState, previousHistory)
		}
	},
})

/**
 * Map key is pageNumber,
 * Map value is redux store instance
 */
const planUploadStoreMap: Map<number, StoreInstanceType> = new Map()

type StoreInstanceType = ReturnType<typeof getStoreInstance>
export type PlanUploadRootState = ReturnType<StoreInstanceType["getState"]>

export const selectPlanUploadHasUndo = (state: PlanUploadRootState) => state.planUploadUndoGroup.past.length !== 0;
export const selectPlanUploadHasRedo = (state: PlanUploadRootState) => state.planUploadUndoGroup.future.length !== 0;

export {
	getStoreInstance,
	getPlanUploadStore,
	initPlanUploadStoreMap,
	deletePlanUploadStoreInstance,
	resetPlanUploadStoreInstance,
	checkIfAnyEditorIsDirty,
	deleteAllPlanUploadStoreInstances,
	clearStoresDirtyFlag,
	markPlanUploadStoreAsDirty,
	checkPagesScalePresence,
}