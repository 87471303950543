import React, {useEffect, useState} from "react";
import {UserConfirmationData} from "../../../../../models/interfaces";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import {PromptModalType} from "../../../../../models/enums";
import {PlanUploadCancelModal} from "../components/modals/PlanUploadConfirmModals";

export function usePlanUploadPromptCustomConfirmationModal(userConfirmationData: UserConfirmationData) {

	const [open, setOpen] = useState(false);
	const {planUploadData: {isAnyEditorDirty}} = useProjectDetailContext()
	const dataRef = useUpdatedRef({isDirty: isAnyEditorDirty.value})

	useEffect(function openPromptModal() {
		const {isDirty} = dataRef.current
		if (isDirty) {
			setOpen(true)
		}
	}, [userConfirmationData, dataRef])

	function handleClose() {
		userConfirmationData.callback(false)
		setOpen(false)
	}

	function handleConfirm() {
		userConfirmationData.callback(true);
		setOpen(false);
	}

	function renderPlanUploadPromptCustomConfirmationModal() {
		if (userConfirmationData.message === PromptModalType.PLAN_UPLOAD_UNSAVED_CHANGES && open) {
			return (
				<PlanUploadCancelModal onLeave={handleConfirm} onClose={handleClose}/>
			)
		}
		return null;
	}

	return {renderPlanUploadPromptCustomConfirmationModal}
}