import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import "./planTemplateRenameModal.scss"
import {useParams} from "react-router-dom";
import {apiInstance} from "../../../../../api/api";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import ModalWithHeader from "../../../../../components/modal/ModalWithHeader";
import TextInput from "../../../../project-drawings/features/editor/components/TextInput";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import {PlanUploadData} from "../../../features/plan-upload/types";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";

type PlanTemplateRenameModalProps = {
	onClose: () => void,
	planTemplate: PlanUploadData,
	onSuccess?: (newName: string) => void
}

const PlanTemplateRenameModal: React.FC<PlanTemplateRenameModalProps> = ({onClose, planTemplate, onSuccess}) => {
	const [nameInput, setNameInput] = useState<string>(planTemplate.name)
	const {t} = useTranslation();
	const {projectId} = useParams<{ projectId: string }>();
	const {planTemplates: {loadPlanTemplates}} = useProjectDetailContext()


	const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setNameInput(e.target.value)
	}

	const onDone = async () => {
		try {
			await apiInstance.projectsApi.editPlanTemplateName(projectId, planTemplate.id, nameInput)
			loadPlanTemplates()
			onClose();
			if (onSuccess) {
				onSuccess(nameInput)
			}
			NotificationService.successNotification(
				t("common.success"),
				t("projects.details.planTemplates.modals.renamePlanTemplateModal.renameSuccess")
			)
		}
		catch (e) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.modals.renamePlanTemplateModal.renameErrorDesc"));
			LoggerService.logError(e);
		}

	}

	return (
		<ModalWithHeader customClass={"plan-template-rename-modal"} isOpen={true}
						 title={t("projects.details.planTemplates.modals.renamePlanTemplateModal.title")}
						 onClose={onClose}>
			<div className={"plan-template-rename-modal_content"}>
				<div className={"plan-template-rename-modal_content_input-container"}>
					<span className={"plan-template-rename-modal_content_input-container_input-label"}
					>{t("projects.details.planTemplates.modals.renamePlanTemplateModal.inputLabel")}</span>
					<TextInput value={nameInput} className={"plan-template-rename-modal_content_input-container_text-input"}
							   onChange={onNameChange}/>
				</div>
				<ModalButtons>
					<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
					<Button label={t("common.buttons.done")}
							variant={"primary"}
							onClick={onDone}
							disabled={nameInput.trim().length === 0 || nameInput === planTemplate.name}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	);
};

export default PlanTemplateRenameModal;
