import {ToolHookResult} from "../../models/editor";
import React, {useEffect, useMemo, useState} from "react";
import {add, distance, midpoint} from "../../utils";
import {EditorTool} from "../../models/enums";
import {KonvaEventObject} from "konva/types/Node";
import {Line} from "react-konva";
import {LinePoint, LinePointProps} from "../../components/LinePoint";
import {noop} from "../../../../../../utils/CallbackUtils";
import {Vector2d} from "konva/types/types";
import {useDispatch, useSelector} from "react-redux";
import {selectPanelStage, symbolRecognitionActions} from "./symbolRecognitionSlice";
import {SymbolRecognitionPanelStage} from "./types";
import {ResultFrames} from "./ResultFrames";
import {selectStageConfig} from "../config/configSlice";
import {Colors} from "../../../../../../styles";
import {getId} from "../../../../../../utils";
import {
	BOTTOM_POINT,
	LEFT_POINT,
	RIGHT_POINT,
	TOP_POINT,
	Vertices,
	KonvaEventType,
	PointDragState
} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {createTopLeftOrientedVertices, getDraggingVertices} from "../../../../../base-konva/utils/frameUtils";

function useSymbolRecognition(): ToolHookResult {
	const [renderId] = useState(() => getId())

	const [firstPoint, setFirstPoint] = useState<Vector2d | null>(null);
	const [secondPoint, setSecondPoint] = useState<Vector2d | null>(null);
	const [mousePosition, setMousePosition] = useState<Vector2d | null>(null);
	const [pointDragState, setPointDragState] = useState<PointDragState>({isDragging: false});
	const [vertices, setVertices] = useState<Vertices | null>(null);

	const dispatch = useDispatch()
	const panelStage = useSelector(selectPanelStage)
	const stageConfig = useSelector(selectStageConfig);

	useEffect(() => {
		if (panelStage === SymbolRecognitionPanelStage.INITIAL) {
			setFirstPoint(null)
			setSecondPoint(null)
			setPointDragState({isDragging: false})
			setVertices(null)
			setMousePosition(null)
		}
	}, [panelStage])

	useEffect(() => {
		dispatch(symbolRecognitionActions.setSelection(vertices ? {
			x: vertices.topLeft.x,
			y: vertices.topLeft.y,
			height: distance(vertices.topLeft, vertices.bottomLeft),
			width: distance(vertices.topLeft, vertices.topRight)
		} : undefined))
	}, [dispatch, vertices])

	const points = useMemo(() => {
		if (!firstPoint) return []

		const relativeFirstPoint = add(firstPoint, stageConfig.backgroundImagePosition)

		if (!secondPoint) {
			if (!mousePosition) return []
			return [
				relativeFirstPoint.x, relativeFirstPoint.y,
				relativeFirstPoint.x, mousePosition.y,
				mousePosition.x, mousePosition.y,
				mousePosition.x, relativeFirstPoint.y,
			]
		}
		else if (vertices) {
			const {
				topLeft, topRight, bottomRight, bottomLeft
			} = pointDragState.isDragging ? getDraggingVertices(vertices, pointDragState) : vertices

			const relativeTopLeft = add(topLeft, stageConfig.backgroundImagePosition)
			const relativeTopRight = add(topRight, stageConfig.backgroundImagePosition)
			const relativeBottomRight = add(bottomRight, stageConfig.backgroundImagePosition)
			const relativeBottomLeft = add(bottomLeft, stageConfig.backgroundImagePosition)

			return [
				relativeTopLeft.x, relativeTopLeft.y,
				relativeTopRight.x, relativeTopRight.y,
				relativeBottomRight.x, relativeBottomRight.y,
				relativeBottomLeft.x, relativeBottomLeft.y,
			]
		}
		return []
	}, [pointDragState, firstPoint, secondPoint, vertices, mousePosition, stageConfig])

	const linePoints = useMemo(() => {
		if (!vertices) return []

		const result: JSX.Element[] = []
		const {
			topLeft, topRight, bottomRight, bottomLeft
		} = pointDragState.isDragging ? getDraggingVertices(vertices, pointDragState) : vertices
		const getProps = function(pointId: string, position: Vector2d): LinePointProps & { key: string } {
			return {
				key: pointId,
				point: {id: pointId, position},
				onDragStart: (evt) => {
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = mousePosition;
						setPointDragState({
							isDragging: true,
							dragPointId: pointId,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						})
					}
				},
				onDragMove: (evt) => {
					if (pointId === TOP_POINT || pointId === BOTTOM_POINT) {
						evt.target.x(position.x)
					}
					else {
						evt.target.y(position.y)
					}
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						const pointer = {
							x: mousePosition.x - stageConfig.backgroundImagePosition.x,
							y: mousePosition.y - stageConfig.backgroundImagePosition.y
						}
						setPointDragState(prevState => ({
							...prevState,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						}))
					}
				},
				onDragEnd: (evt) => {
					if (pointId === TOP_POINT || pointId === BOTTOM_POINT) {
						evt.target.x(position.x)
					}
					else {
						evt.target.y(position.y)
					}

					setPointDragState({isDragging: false});
					const {topLeft, bottomRight} = getDraggingVertices(vertices, pointDragState)
					setVertices(createTopLeftOrientedVertices(topLeft, bottomRight))
				},
				onMouseEnter: noop,
				onMouseLeave: noop,
			}
		}

		const getPosition = function(pointA: Vector2d, pointB: Vector2d) {
			const center = midpoint(pointA, pointB)
			return add(center, stageConfig.backgroundImagePosition)
		}

		result.push(<LinePoint {...getProps(LEFT_POINT, getPosition(topLeft, bottomLeft))} />)
		result.push(<LinePoint {...getProps(TOP_POINT, getPosition(topLeft, topRight))} />)
		result.push(<LinePoint {...getProps(RIGHT_POINT, getPosition(topRight, bottomRight))} />)
		result.push(<LinePoint {...getProps(BOTTOM_POINT, getPosition(bottomLeft, bottomRight))} />)

		return result
	}, [pointDragState, vertices, stageConfig])

	function onMouseUp(event: KonvaEventObject<KonvaEventType>) {
		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			const relativePosition = {
				x: mousePosition.x - stageConfig.backgroundImagePosition.x,
				y: mousePosition.y - stageConfig.backgroundImagePosition.y
			}

			if (!firstPoint) {
				setFirstPoint(relativePosition)
			}
			else if (!secondPoint) {
				setSecondPoint(relativePosition)
				setVertices(createTopLeftOrientedVertices(firstPoint, relativePosition))
			}
		}
	}

	function onMouseMove(event: KonvaEventObject<KonvaEventType>) {
		if (secondPoint) return;

		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			setMousePosition(mousePosition)
		}
	}

	function render() {
		return (
			<>
				{(
					panelStage === SymbolRecognitionPanelStage.SYMBOL_SELECTION ||
					panelStage === SymbolRecognitionPanelStage.INITIAL
				) && (<>
					<Line
						listening={false}
						closed={true}
						points={points}
						stroke={Colors.SIMPROBLUE}
						strokeWidth={2}
						lineCap={"round"}
						lineJoin={"round"}
					/>
					{linePoints}
				</>)}
				{panelStage === SymbolRecognitionPanelStage.MATCHES_DISPLAY && (
					<ResultFrames/>
				)}
			</>
		)
	}

	return {
		id: renderId,
		render,
		tool: EditorTool.SYMBOL_RECOGNITION,
		callbacks: {
			onMouseUp,
			onMouseMove,
		}
	}
}

export {useSymbolRecognition}