import {Redirect, Route, Switch, useLocation, useParams} from "react-router-dom";
import {useProjectDetailContext} from "../providers/ProjectDetailProvider";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import React, {useEffect} from "react";
import {ProjectDrawingPage} from "../../project-drawings";
import ProjectDetails from "./ProjectDetails";
import {projectPaths} from "./index";
import {Status} from "../../../models/enums";
import {ProjectPlanUploadPage} from "../features/plan-upload";
import {paths, useNavigator} from "../../navigator";

export const ProjectDetailsByUuid = () => {
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const redirectUrl = type === "quotes"
		? paths.takeoffs_quotes_details_projectId
		: paths.takeoffs_jobs_details_projectId

	const {projectDetails: {project, loadProjectDetails, loadStatus}} = useProjectDetailContext()
	const {projectId} = useParams<{ projectId: string }>();
	const {navigateTo} = useNavigator()
	const dataRef = useUpdatedRef({loadProjectDetails, navigateTo})

	useEffect(() => {
		const {loadProjectDetails} = dataRef.current
		if (!project || project.id !== projectId) {
			loadProjectDetails(projectId).then()
		}
	}, [project, projectId, dataRef])

	useEffect(() => {
		const {navigateTo} = dataRef.current
		if (loadStatus === Status.ERROR) {
			navigateTo(paths.takeoffs_quotes)
		}
	}, [loadStatus, dataRef])

	return (
		<Switch>
			<Route component={ProjectDrawingPage} path={[
				paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version_versionId,
				paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version_versionId,
			]}/>
			<Route component={ProjectPlanUploadPage} path={[
				paths.takeoffs_quotes_details_projectId_planUpload,
				paths.takeoffs_jobs_details_projectId_planUpload,
			]}/>
			<Route exact component={ProjectDetails} path={projectPaths}/>
			<Redirect to={redirectUrl}/>
		</Switch>
	)
}