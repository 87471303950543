import React from "react";
import Konva from "konva";
import {useSelector} from "react-redux";
import {selectPlanUploadZoomState} from "./planUploadViewSlice";
import {usePlanUploadZoomActions} from "./usePlanUploadZoomActions";
import {useBaseZoomOnWheel} from "../../../../../base-konva/features/view";

type UseZoomOnWheelProps = {
	scrollContainer: React.RefObject<HTMLElement>
	stageRef: React.RefObject<Konva.Stage>;
}

export function usePlanUploadZoomOnWheel({scrollContainer, stageRef}: UseZoomOnWheelProps) {
	const zoomState = useSelector(selectPlanUploadZoomState)
	const {handleZoomInByPercent, handleZoomOutByPercent} = usePlanUploadZoomActions()

	useBaseZoomOnWheel({
		scrollContainer,
		stageRef,
		zoomState,
		handleZoomOutByPercent,
		handleZoomInByPercent
	})
}