import {Status} from "../../../models/enums";
import UserSettings from "../../../models/UserSettings";
import {useEffect, useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {useTranslation} from "react-i18next";

interface UseSettingsHook {
	settings: UserSettings | undefined,
	loadStatus: Status
}

function useUserSettings(forceUpdate?: number): UseSettingsHook {
	const [settings, setSettings] = useState<UserSettings | undefined>()
	const [loadStatus, setLoadStatus] = useState<Status>(Status.IDLE)
	const {t} = useTranslation();

	useEffect(() => {
		setLoadStatus(Status.LOADING)
		apiInstance.settingsApi.fetchSettings()
			.then(settings => {
				setSettings(settings);
				setLoadStatus(Status.SUCCESS);
			})
			.catch(err => {
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"), t("settings.mainSettings.fetchErrorDesc"))
				setLoadStatus(Status.ERROR);
			})
	}, [t, forceUpdate])

	return {settings, loadStatus}
}

export default useUserSettings;