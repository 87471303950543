import React from "react";
import {IconProps} from "../../models/interfaces";
import {Colors} from "../../../../../../styles";
import IconUnselectable from "./IconUnselectable";

const IconVisibility: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : Colors.MIDGREY
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width="22" height="22" viewBox="0 -2 24 24" style={{cursor}}>
				<path fill={fill}
					  d="M12,4.33C5.65,4.33.5,12,.5,12S5.65,19.67,12,19.67,23.5,12,23.5,12,18.35,4.33,12,4.33Zm0,12.6A4.93,4.93,0,1,1,16.93,12,4.93,4.93,0,0,1,12,16.93Z"/>
				<path fill={fill} d="M12,8.38A3.62,3.62,0,1,0,15.62,12,3.62,3.62,0,0,0,12,8.38Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconVisibility;
