import React from "react";
import "./tabs.scss";

interface SubTabsProps {
	className?: string;
}

const SubTabs: React.FC<SubTabsProps> = ({className, children}) => {
	const cls = [
		"sub-tabs",
		className && className
	].filter(Boolean).join(" ");

	return (
		<div className={cls}>{children}</div>
	);
};

export default SubTabs
