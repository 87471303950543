import {Props, useMaterialData} from "../../../hooks/useMaterialData";
import React, {useState} from "react";
import {countActions} from "../../../../../count/countSlice";
import {EditorTool, MaterialType} from "../../../../../../models/enums";
import {lengthActions} from "../../../../../length/lengthSlice";
import {areaActions} from "../../../../../area/areaSlice";
import {useDispatch} from "react-redux";
import {MaterialChangeActionPayload} from "../../../../../../models/editor";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../../../models/interfaces";
import {ItemsTableProps} from "../../../components/ItemsTable";
import {CatalogItem} from "../../../../../../../../../../models/CatalogItem";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../../../../../../../../components/layout/table/TableHeaderCell";
import {get} from "../../../../../../../../../../utils/ClassNameUtils";


export type ChangeState = {
	measurementId: string,
	measurementStyle: MeasurementStyle,
	measurementType: MaterialAddableTool
}

export function useMaterialChangeData({
	useMaterialItemsHook,
	loadGroupsHook,
	rootGroupLabel,
	type
}: Props & { type: MaterialType.CATALOG | MaterialType.PREBUILD }) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const materialData = useMaterialData({useMaterialItemsHook, loadGroupsHook, rootGroupLabel})
	const [itemToAddId, setItemToAddId] = useState<number | undefined>(undefined);
	const [changeState, setChangeState] = useState<ChangeState | undefined>();

	const itemsTable: ItemsTableProps<CatalogItem> = {
		tableRef: materialData.itemsTable.tableRef,
		items: materialData.itemsTable.items,
		columns: [{
			id: "partNumber",
			label: t(`projects.addItemModal.table.columns.partNumber`),
			sortable: false,
			width: 200
		}, {
			id: "name",
			label: t(`projects.addItemModal.table.columns.name`),
			sortable: false
		}],
		headerTemplate: col => (
			<TableHeaderCell id={col.id} label={col.label} width={col.width}/>
		),
		rowTemplate: item => (
			<tr
				className={get({"is-checked": item.id === itemToAddId})}
				onClick={() => setItemToAddId(item.id)}
			>
				<td className={"break-word"}>{item.partNumber}</td>
				<td className={"break-word"}>{item.name}</td>
			</tr>
		)
	}

	let measurementStyleSelector = undefined;
	if (changeState) {
		measurementStyleSelector = {
			measurementType: changeState.measurementType,
			style: changeState.measurementStyle,
			onChange: (newStyle: MeasurementStyle) => {
				setChangeState(prevState => {
					if (prevState) {
						return {...prevState, measurementStyle: newStyle}
					}
					return undefined
				})
			}
		}
	}

	const contentFooter = {
		canChangeMaterial: itemToAddId !== undefined && changeState !== undefined,
		changeMaterial: () => {
			if (contentFooter.canChangeMaterial) {
				const [item] = itemsTable.items.filter(item => item.id === itemToAddId)
				let measurementMaterialChangeAction: ActionCreatorWithPayload<MaterialChangeActionPayload>
				switch (changeState!.measurementType) {
					case EditorTool.COUNT:
						measurementMaterialChangeAction = countActions.changeMaterial;
						break;
					case EditorTool.LENGTH:
						measurementMaterialChangeAction = lengthActions.changeMaterial;
						break;
					default:
						measurementMaterialChangeAction = areaActions.changeMaterial;
						break;
				}
				dispatch(measurementMaterialChangeAction({
					id: changeState!.measurementId,
					material: {
						id: item.id,
						name: item.name,
						type: type,
						partNumber: item.partNumber,
						laborTime: item.laborTime,
						fitTimeId: undefined,
					},
					style: changeState!.measurementStyle
				}))
				setItemToAddId(undefined)
			}
		},
		measurementStyleSelector
	}

	return {...materialData, itemsTable, contentFooter, setChangeState}
}