import {Group, Rect} from "react-konva";
import React from "react";
import {KonvaEventObject} from "konva/types/Node";
import {BaseStageConfig, BaseToolHookResult, KonvaEventType} from "../types";

interface SelectRectangleLayerProps {
	correspondingHook: () => BaseToolHookResult,
	stageConfig: BaseStageConfig,
	panInProgress: boolean
}

const SelectRectangleLayer: React.FC<SelectRectangleLayerProps> = ({correspondingHook, stageConfig, panInProgress}) => {
	const {render, callbacks} = correspondingHook()

	function handleMouseUp(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		callbacks.onMouseUp?.(e)
	}

	function handleMouseMove(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		callbacks.onMouseMove?.(e)
	}

	return (
		<Group
			onMouseUp={handleMouseUp}
			onTouchEnd={handleMouseUp}
			onMouseMove={handleMouseMove}
			onTouchMove={handleMouseMove}
		>
			<Rect
				name={"background_rect"}
				width={stageConfig.width}
				height={stageConfig.height}
			/>
			{render()}
		</Group>
	)
}

export {SelectRectangleLayer}