import {useDispatch} from "react-redux";
import {planUploadPenActions} from "../features/pen";
import {planUploadTextActions} from "../features/text/planUploadTextSlice";
import {planUploadImageActions} from "../features/image/planUploadImageSlice";

export function usePlanUploadActivate() {
	const dispatch = useDispatch();

	function activatePen(id: string, parentId: string) {
		dispatch(planUploadPenActions.activatePenLine({id, parentId}))
		dispatch(planUploadPenActions.activatePenGroup({id: parentId}))
	}

	function activateText(id: string, parentId: string) {
		dispatch(planUploadTextActions.activateTextItem({id, parentId}))
		dispatch(planUploadTextActions.activateTextGroup({id: parentId}))
	}

	function activateImage(id: string) {
		dispatch(planUploadImageActions.activateImage({id}))
	}

	return {
		activatePen,
		activateText,
		activateImage,
	}
}
