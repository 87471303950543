import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import translation from "./locales/en/translation.json"

const defaultNS = 'ns1'
const resources = {
	en: {
		ns1: translation
	}
} as const;

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		fallbackLng: 'en',
		lng: "en",
		debug: process.env.NODE_ENV !== "production" && process.env.NODE_ENV !== "test",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		defaultNS,
		resources
	});

export {defaultNS, resources}
export default i18n;