import React from "react";
import {Colors} from "../../../../../styles";

const IconWarning: React.FC<{ fill?: Colors }> = ({fill = Colors.YELLOW}) => {
	return (
		<svg width="16" height="14" viewBox="0 0 16 14" fill="none">
			<path
				d="M6.85433 1.63819L1.17957 11.0114C1.06257 11.2118 1.00066 11.4391 1.00001 11.6706C0.99935 11.9021 1.05997 12.1297 1.17583 12.3308C1.2917 12.532 1.45877 12.6995 1.66042 12.8169C1.86207 12.9343 2.09128 12.9975 2.32524 13H13.6748C13.9087 12.9975 14.1379 12.9343 14.3396 12.8169C14.5412 12.6995 14.7083 12.532 14.8242 12.3308C14.94 12.1297 15.0006 11.9021 15 11.6706C14.9993 11.4391 14.9374 11.2118 14.8204 11.0114L9.14567 1.63819C9.02623 1.44337 8.85806 1.2823 8.65738 1.17051C8.45671 1.05873 8.2303 1 8 1C7.7697 1 7.54329 1.05873 7.34262 1.17051C7.14194 1.2823 6.97377 1.44337 6.85433 1.63819Z"
				fill={fill} stroke="#DE104F" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8 5.0459V7.69743" stroke="#DE104F" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8 10.3486H8.007" stroke="#DE104F" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

	);
};

export default IconWarning