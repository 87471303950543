import * as Redux from "redux";
import {configActions} from "../features/config/configSlice";
import {EditorTool} from "../models/enums";
import {lengthActions} from "../features/length/lengthSlice";
import {areaActions} from "../features/area/areaSlice";
import {RootState} from "../editorStore";
import {AreaMeasurement, LengthMeasurement} from "../models/editor";

export const autoFinishToolWhenSwitchEditorTool: Redux.Middleware = (store) => (next) => (action) => {
	if (action.type === configActions.switchEditorTool.type ||
		action.type === configActions.switchEditorToolBack.type
	) {
		const state: RootState = store.getState()
		const {config, length, area} = state.undoGroup.present
		const activeTool = config.activeTool
		if (activeTool === EditorTool.LENGTH && shouldFinishLines(length.lengths)) {
			store.dispatch(lengthActions.finishLines())
		}
		if ((activeTool === EditorTool.AREA || activeTool === EditorTool.VOLUME) && shouldCloseAreas(area.areas)) {
			store.dispatch(areaActions.closeAreas())
		}
	}

	next(action)
}

function shouldFinishLines(lengths: LengthMeasurement[]): boolean {
	return lengths.some(length => {
		return length.activeLengthFragmentId !== null || length.lengthFragments.some(fragment => fragment.lines.length < 1);
	})
}

function shouldCloseAreas(areas: AreaMeasurement[]): boolean {
	return areas.some(area => {
		const hasFragmentsToClose = area.activeAreaFragmentId !== null ||
			area.areaFragments.some(fragment => fragment.lines.length < 2);
		const hasDeductionsToClose = area.activeDeductionId !== null ||
			area.deductions.some(deduction => deduction.lines.length < 2);
		return hasFragmentsToClose || hasDeductionsToClose
	})
}