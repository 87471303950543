import {Group, Image, Line, Transformer} from "react-konva";
import Konva from "konva";
import React from "react";
import isEqual from "lodash/isEqual";
import {KonvaEventObject} from "konva/types/Node";
import {ShapeConfig} from "konva/types/Shape";

export type EventHandlers = {
	onDragEnd?: (evt: KonvaEventObject<MouseEvent>) => void,
	onMouseEnter?: (evt: KonvaEventObject<MouseEvent>) => void,
	onMouseLeave?: (evt: KonvaEventObject<MouseEvent>) => void,
	onClick?: () => void,
	onTap?: () => void,
	handleTransformStart?: () => void,
	handleTransformEnd?: (evt: KonvaEventObject<Event>) => void,
}

type _SymbolElementKonvaProps = {
	visible: boolean
	group: {
		ref: React.RefObject<Konva.Group>
		draggable: boolean
		offsetX: number
		offsetY: number
		rotation: number
		x: number
		y: number
		height: number
		width: number
	}
	eventsRef: React.MutableRefObject<EventHandlers>,
	image: {
		ref: React.RefObject<Konva.Image>
		src: HTMLImageElement | undefined
		height: number
		width: number
		style: ShapeConfig
	}
	line?: {
		id: string
		points: number[]
		style: ShapeConfig,
	}
	transformer?: {
		ref: React.RefObject<Konva.Transformer>
	}
}

function _SymbolElementKonva({
	visible,
	group,
	eventsRef,
	image,
	line,
	transformer
}: _SymbolElementKonvaProps) {
	return (
		<Group visible={visible}>
			<Group
				ref={group.ref}
				offsetX={group.offsetX}
				offsetY={group.offsetY}
				rotation={group.rotation}
				x={group.x}
				y={group.y}
				height={group.height}
				width={group.width}
				draggable={group.draggable}
				onDragEnd={evt => {eventsRef.current.onDragEnd?.(evt)}}
				onMouseEnter={evt => {eventsRef.current.onMouseEnter?.(evt)}}
				onMouseLeave={evt => {eventsRef.current.onMouseLeave?.(evt)}}
				onClick={() => {eventsRef.current.onClick?.()}}
				onTap={() => {eventsRef.current.onTap?.()}}
			>
				<Image
					image={image.src}
					height={image.height}
					width={image.width}
					{...image.style}
				/>
				<Image
					ref={image.ref}
					image={image.src}
					height={image.height}
					width={image.width}
					filters={[Konva.Filters.RGB]}
					{...image.style}
				/>
				{line ? <Line
					id={line.id}
					points={line.points}
					{...line.style}
				/> : null}
			</Group>
			{transformer ? <Transformer
				ref={transformer.ref}
				resizeEnabled={false}
				rotationSnaps={[0, 90, 180, 270]}
				onTransformStart={() => {eventsRef.current.handleTransformStart?.()}}
				onTransformEnd={evt => {eventsRef.current.handleTransformEnd?.(evt)}}
			/> : null}
		</Group>
	)
}

export const SymbolElementKonva = React.memo(_SymbolElementKonva, isEqual)