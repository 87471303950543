import Section from "../../../models/Section";
import {useEffect, useState} from "react";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {CostCenter} from "../../../models/CostCenter";

export type UseTreeExpandData = {
	isExpanded: (id: string) => boolean
	toggleSection: (id: string) => void
	toggleCostCenter: (id: string) => void
	handleSectionBreadcrumbClick: (id: string) => void
	handleCostCenterBreadcrumbClick: (id: string) => void
}

export function useTreeExpandData(sections: Section[]): UseTreeExpandData {
	const [treeExpandMap, setTreeExpandMap] = useState<Record<string, boolean>>({});
	const treeExpandMapRef = useUpdatedRef(treeExpandMap)

	useEffect(() => {
		const treeExpandMap = treeExpandMapRef.current
		const newTreeExpandMap: Record<string, boolean> = {}
		for (let section of sections) {
			newTreeExpandMap[section.id] = treeExpandMap[section.id] ?? false
			for (let costCenter of section.costCenters) {
				newTreeExpandMap[costCenter.id] = treeExpandMap[costCenter.id] ?? false
				for (let drawing of costCenter.drawings) {
					newTreeExpandMap[drawing.id] = treeExpandMap[drawing.id] ?? false
				}
			}
		}
		setTreeExpandMap(newTreeExpandMap)
	}, [sections, treeExpandMapRef])

	function isExpanded(id: string): boolean {
		return treeExpandMap[id]
	}

	function toggleSection(id: string) {
		const isSectionOpen = isExpanded(id)

		if (isSectionOpen) {
			setTreeExpandMap(_getToggledIdMap(treeExpandMap, id))
		}
		else {
			const section = sections.find(section => section.id === id);
			if (section) {
				setTreeExpandMap(_getExpandedIdsMap(treeExpandMap, _getSectionIds(section)))
			}
		}
	}

	function toggleCostCenter(id: string) {
		const isCostCenterOpen = isExpanded(id)

		if (isCostCenterOpen) {
			setTreeExpandMap(_getToggledIdMap(treeExpandMap, id))
		}
		else {
			for (let section of sections) {
				const costCenter = section.costCenters.find(costCenter => costCenter.id === id)
				if (costCenter) {
					setTreeExpandMap(_getExpandedIdsMap(treeExpandMap, _getCostCenterIds(costCenter)))
					return;
				}
			}
		}
	}

	/**
	 * Returns section id, child cost centers ids and child cost centers drawings ids
	 * @param section
	 */
	function _getSectionIds(section: Section) {
		const ids: string[] = [section.id]
		for (let costCenter of section.costCenters) {
			ids.push(..._getCostCenterIds(costCenter))
		}
		return ids;
	}

	/**
	 * Returns cost center id, and child drawings ids
	 * @param costCenter
	 */
	function _getCostCenterIds(costCenter: CostCenter) {
		const ids: string[] = [costCenter.id]
		for (let drawing of costCenter.drawings) {
			ids.push(drawing.id)
		}
		return ids;
	}

	function _getToggledIdMap(treeExpandMap: Record<string, boolean>, id: string) {
		const newTreeExpandMap = {...treeExpandMap}
		newTreeExpandMap[id] = !newTreeExpandMap[id]
		return newTreeExpandMap;
	}

	function _getExpandedIdsMap(treeExpandMap: Record<string, boolean>, ids: string[]) {
		const newTreeExpandMap = {...treeExpandMap}
		for (let id of ids) {
			newTreeExpandMap[id] = true;
		}
		return newTreeExpandMap;
	}

	function handleSectionBreadcrumbClick(sectionId: string) {
		const newTreeExpandMap: Record<string, boolean> = {};
		Object.keys(treeExpandMap).forEach(item => {
			newTreeExpandMap[item] = false;
		})
		const clickedSection = sections.find(section => section.id === sectionId)
		if (clickedSection) {
			setTreeExpandMap(_getExpandedIdsMap(newTreeExpandMap, _getSectionIds(clickedSection)))
		}
	}

	function handleCostCenterBreadcrumbClick(costCenterId: string) {
		const newTreeExpandMap: Record<string, boolean> = {};
		Object.keys(treeExpandMap).forEach(item => {
			newTreeExpandMap[item] = false;
		})
		for (let section of sections) {
			const costCenter = section.costCenters.find(costCenter => costCenter.id === costCenterId)
			if (costCenter) {
				setTreeExpandMap(_getExpandedIdsMap(newTreeExpandMap, [section.id, ..._getCostCenterIds(costCenter)]))
				return;
			}
		}
	}

	return {
		isExpanded,
		toggleSection,
		toggleCostCenter,
		handleSectionBreadcrumbClick,
		handleCostCenterBreadcrumbClick
	}
}