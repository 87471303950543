import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectZoomState, viewActions} from "../view/viewSlice";
import Konva from "konva";
import {EditorTool} from "../../models/enums";
import {selectActiveTool} from "../config/configSlice";
import {useBasePan} from "../../../../../base-konva/features/pan";

type UsePanProps = {
	stageRef: React.RefObject<Konva.Stage>;
}

export function usePan({stageRef}: UsePanProps) {

	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const zoomState = useSelector(selectZoomState);

	const {onMouseDown, onTouchStart, panInProgress} = useBasePan({
		stageRef,
		canScroll: () => activeTool !== EditorTool.PEN
			&& activeTool !== EditorTool.GROUP_MEASUREMENT_SELECT,
		viewportSize: zoomState.viewportSize
	})

	useEffect(() => {
		dispatch(viewActions.setPanInProgress(panInProgress));
	}, [panInProgress, dispatch]);

	return {
		onMouseDown,
		onTouchStart
	}
}
