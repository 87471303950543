import React, {useState} from "react";
import Pagination from "../../../components/ui/Pagination";
import {ProjectSource, SortDirection, Status} from "../../../models/enums";
import Spinner from "../../../components/Spinner";
import DataFetchError from "../../../components/ui/DataFetchError";
import ProjectsTable, {ProjectSummary} from "./ProjectsTable";
import PageHeader from "../../../components/layout/page/PageHeader";
import PageContent from "../../../components/layout/page/PageContent";
import {useTranslation} from "react-i18next";
import useProjectsList, {UseProjectListQueryKeyArgs} from "../hooks/useProjectsList";
import {PaginatedResponse, SortConfig} from "../../../models/interfaces";
import SearchBar from "../../../components/ui/SearchBar";
import SearchBarWrapper from "../../../components/ui/SearchBarWrapper";

interface ProjectsWrapperProps {
	projectSource: ProjectSource,
	queryKeyArgs: UseProjectListQueryKeyArgs,
	asyncDataLoad: (page: number, size: number, orderBy: string, query?: string) => Promise<PaginatedResponse<ProjectSummary>>,
	stageLabel: string
}

const ProjectsWrapper: React.FC<ProjectsWrapperProps> = ({
	projectSource,
	queryKeyArgs,
	asyncDataLoad,
	stageLabel
}) => {

	const {t} = useTranslation();
	const [sortConfig, setSortConfig] = useState<SortConfig<ProjectSummary>>({key: "id", direction: SortDirection.DESC});
	const [query, setQuery] = useState<string | undefined>(undefined);
	const {
		items,
		onPageChange,
		loadStatus,
		totalPages,
		pageNumber,
		totalElements,
		redirectToProject
	} = useProjectsList(queryKeyArgs, asyncDataLoad, sortConfig, query, projectSource);

	const onSearch = (query: string) => {
		pageNumber !== 0 && onPageChange(0);
		setQuery(query.length > 0 ? query : undefined);
	};

	const headerTranslations = {
		[ProjectSource.JOB]: t(`projects.table.header.job`),
		[ProjectSource.QUOTE]: t(`projects.table.header.quote`)
	}

	return (
		<>
			<PageHeader>
				<h1 className={"heading1"}>{stageLabel} {headerTranslations[projectSource]}
					({loadStatus === Status.LOADING ? "..." : totalElements})</h1>
			</PageHeader>
			<PageContent>
				{loadStatus === Status.SUCCESS || loadStatus === Status.LOADING ?
					<>
						<SearchBarWrapper>
							<SearchBar isSearching={loadStatus === Status.LOADING}
									   onSearch={onSearch}
									   minQueryLength={1}/>
						</SearchBarWrapper>
						{loadStatus === Status.LOADING ? <Spinner/> : null}
						{loadStatus === Status.SUCCESS ? <>
							<ProjectsTable
								items={items}
								redirectToProject={redirectToProject}
								projectSource={projectSource}
								sortConfig={sortConfig}
								onSortConfigChange={setSortConfig}
							/>
							<Pagination pageNumber={pageNumber} totalPages={totalPages} onPageChange={onPageChange}/>
						</> : null}
					</> : null}
				{loadStatus === Status.ERROR ? <DataFetchError/> : null}
			</PageContent>
		</>
	);
};

export default ProjectsWrapper
