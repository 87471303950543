import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconResize: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width={32} height={32} viewBox="0 0 32 32" style={{cursor: cursor}}>
				<path fill={fill}
					  d="M25.0221098,6.00004029 C25.5352774,6.00004029 25.9598481,6.38760869 26.0178829,6.88512916 L26.0246424,7.00176703 L26.0246424,24.0696031 C26.0246424,24.5825836 25.637074,25.0067935 25.1393922,25.0647776 L25.0227142,25.0715313 L7.00271396,25.0715313 C6.48935923,25.0715313 6.0647752,24.6839629 6.0067395,24.1862811 L5.99997985,24.0696031 L5.99997985,7.00176703 C5.99997985,6.48897355 6.38789568,6.06477705 6.88594973,6.00679385 L7.00271396,6.00004029 L25.0221098,6.00004029 Z M24.692096,7.33238521 L7.33313066,7.33238521 L7.33313066,14.8695126 L15.2008762,14.8703185 C15.714231,14.8703185 16.138815,15.2578869 16.1968507,15.7555687 L16.2036103,15.8722467 L16.2036103,23.7399922 L24.6914916,23.7399922 L24.692096,7.33238521 Z M14.870258,16.2028649 L7.33313066,16.2028649 L7.33313066,23.7399922 L14.870258,23.7399922 L14.870258,16.2028649 Z M23.5819765,8.36473594 C23.8324082,8.61395878 23.8324082,9.02033103 23.5819765,9.26955388 L19.5994479,13.2520825 L22.0118927,13.3540282 C22.3662848,13.3691387 22.6402889,13.6671181 22.6251784,14.0203014 C22.6110752,14.3650227 22.3271989,14.6337885 21.9859026,14.6337885 L18.0900076,14.4701919 C18.0436687,14.4680429 17.9993893,14.4600735 17.9563336,14.4478955 L17.8665734,14.4182117 C17.8188913,14.39793 17.7741642,14.3713803 17.7332277,14.340234 L17.65966,14.2761728 L17.65966,14.2761728 C17.6228575,14.2385644 17.5908008,14.1962102 17.5642063,14.1503041 L17.5220536,14.064827 C17.5032494,14.019294 17.4911609,13.9708956 17.4845944,13.9205869 L17.4753117,13.8027099 L17.4753117,13.8027099 L17.6399156,9.93401381 C17.656235,9.58062911 17.9586469,9.30863975 18.3075991,9.32173554 C18.6619912,9.33684606 18.9359953,9.63482551 18.9208848,9.98821021 L18.8265951,12.2150979 L22.6769571,8.36473594 C22.9263814,8.11430425 23.3315448,8.11430425 23.5819765,8.36473594 Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconResize
