import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorBR: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M6.335,7.602l8.912,8.912l-5.44,0.004l-0.002,1.772l8.477,-0.008l0.008,-8.477l-1.772,0.002l-0.004,5.44l-8.912,-8.912l-1.267,1.267Z"]}/>
	);
};

export default AnchorBR
