import {resetStore, RootState} from "../../editorStore";
import {
	AreaMeasurement,
	CountMeasurement,
	CountMeasurementItem,
	DrawingItem,
	EditorDrawingData,
	LengthMeasurement,
	Measurement,
	ScaleInfoConfig,
} from "../../models/editor";
import {DRAWING_SCHEMA_VERSION} from "../../constants";
import {configActions} from "../config/configSlice";
import {areaActions} from "../area/areaSlice";
import {countActions} from "../count/countSlice";
import {lengthActions} from "../length/lengthSlice";
import {UpdateDrawingApiDto, UpdateSerializedMeasurementApiDto} from "../../../../../../models/restModels";
import {AreaHelper} from "../area/AreaHelper";
import LengthHelper from "../length/LengthHelper";
import {ActionCreators} from "redux-undo";
import {penActions} from "../pen/penSlice";
import {textActions} from "../text/textSlice";
import {PlanSummaryItem} from "../../../../../../models/PlanSummaryItem";
import {roundNumber} from "../../../../../../utils/NumberUtils";
import SerializedMeasurement from "../../../../../../models/SerializedMeasurement";
import {imageActions} from "../image/imageSlice";
import {viewActions} from "../view/viewSlice";
import {transformDrawingItems} from "../../utils";
import {Store} from "@reduxjs/toolkit";
import {ImageData, PenGroup, ScaleConfig, TextGroup} from "../../../../../base-konva/types";
import {DrawingItemType} from "../../../../../base-konva/enums";
import {initialScaleInfoState} from "../../../../../base-konva/constants";
import {scaleActions} from "../scale/scaleSlice";

const defaultScaleConfig: ScaleConfig = {
	scalePoints: [],
	scale: null,
	inputValue: null,
	scaleInfoState: {...initialScaleInfoState}
};

export function serializeRootState(state: RootState): UpdateDrawingApiDto {
	const scaleConfig = state.scale.scaleConfig;
	const planScale = scaleConfig.scale;
	const scaleInfoConfig: ScaleInfoConfig = {
		infoPosition: scaleConfig.scaleInfoState.position,
		infoUnitFactor: scaleConfig.scaleInfoState.unitFactor,
		infoUnitWidth: scaleConfig.scaleInfoState.unitWidth
	};
	const areaMeasurements = state.undoGroup.present.area.areas;
	const countMeasurements = state.undoGroup.present.count.countGroups;
	const lengthMeasurements = state.undoGroup.present.length.lengths;
	const penTool = state.undoGroup.present.pen.penGroups;
	const textTool = state.undoGroup.present.text.textGroups.map(tg => {
		// filters out empty items
		const textItems = tg.textItems.filter(ti => ti.text.length > 0);
		return {...tg, textItems};
	});
	const imageTool = state.undoGroup.present.image.images;
	const stageConfig = state.undoGroup.present.config.stageConfig;
	const scaleOptions = state.view.scaleOptions;

	const items: DrawingItem[] = [
		...countMeasurements.map(cm => ({type: DrawingItemType.COUNT, data: cm})),
		...lengthMeasurements.map(lm => ({type: DrawingItemType.LENGTH, data: lm})),
		...areaMeasurements.map(am => ({type: DrawingItemType.AREA, data: am})),
		...penTool.map(pt => ({type: DrawingItemType.PEN, data: pt})),
		...textTool.map(tt => ({type: DrawingItemType.TEXT, data: tt})),
		...imageTool.map(it => ({type: DrawingItemType.IMAGE, data: it}))
	]

	const data: EditorDrawingData = {
		schemaVersion: DRAWING_SCHEMA_VERSION,
		stageConfig,
		scaleInfoConfig,
		scaleOptions,
		items,
		zoomScale: state.view.zoom.scale,
		rotation: state.view.zoom.rotation,
	};

	const toSerializedMeasurementApiDto = (
		measurement: Measurement,
		value: number,
		deduction?: number
	): UpdateSerializedMeasurementApiDto => {
		return {
			editorId: measurement.id,
			materialId: measurement.material.id,
			materialType: measurement.material.type,
			materialName: measurement.material.name,
			materialPartNumber: measurement.material.partNumber,
			laborTime: measurement.material.laborTime,
			fitTimeId: measurement.material.fitTimeId,
			measurementType: measurement.type,
			measurementValue: roundNumber(value, 2)!,
			deduction: deduction ? roundNumber(deduction, 2) : undefined
		}
	};

	const areaMeasurementsApiDtos = areaMeasurements.map(area =>
		toSerializedMeasurementApiDto(
			area,
			AreaHelper.getUnscaledAreaValue(area, planScale),
			AreaHelper.getUnscaledDeductionValue(area, planScale)
		)
	);

	const countMeasurementsApiDtos = countMeasurements.map(countGroup =>
		toSerializedMeasurementApiDto(countGroup, countGroup.countItems.length)
	);

	const lengthMeasurementsApiDtos = lengthMeasurements.map(length =>
		toSerializedMeasurementApiDto(length, LengthHelper.getUnscaledTotalLength(length, planScale))
	);

	return {data, measurements: [...areaMeasurementsApiDtos, ...countMeasurementsApiDtos, ...lengthMeasurementsApiDtos]}
}

/**
 * If serialized measurements array contains measurement item, its labor and fit time could have been updated.
 * Function replaces these values with updated ones (if exist). Also backend measurement id is set.
 * @param item
 * @param measurements
 */
function withUpdatedLaborAndFitTime<T extends Measurement>(item: T, measurements: SerializedMeasurement[]): T {
	const serializedItem = measurements.find(m => m.editorId === item.id);
	if (serializedItem) {
		return {
			...item,
			measurementId: serializedItem.id,
			material: {
				...item.material,
				laborTime: serializedItem.laborTime,
				fitTimeId: serializedItem.fitTimeId
			}
		};
	}
	else {
		return item;
	}
}

export function deserializeDrawingSchema(
	store: Store<RootState>,
	schema: EditorDrawingData,
	serializedMeasurements: SerializedMeasurement[],
	locked: boolean,
	planScale: number | undefined
): void {
	resetStore({store})

	const countGroups = schema.items
		.filter(drawingItem => drawingItem.type === DrawingItemType.COUNT)
		.map(drawingItem => {
			const countGroup = withUpdatedLaborAndFitTime(drawingItem.data as CountMeasurement, serializedMeasurements);
			countGroup.visible = true
			countGroup.countItems.forEach(item => {item.visible = true;})
			return countGroup;
		})
	const lengthMeasurements = schema.items
		.filter(drawingItem => drawingItem.type === DrawingItemType.LENGTH)
		.map(drawingItem => {
			const lengthMeasurement = withUpdatedLaborAndFitTime(drawingItem.data as LengthMeasurement, serializedMeasurements);
			lengthMeasurement.visible = true
			return lengthMeasurement;
		})
	const areaMeasurements = schema.items
		.filter(drawingItem => drawingItem.type === DrawingItemType.AREA)
		.map(drawingItem => {
			const areaMeasurement = withUpdatedLaborAndFitTime(drawingItem.data as AreaMeasurement, serializedMeasurements);
			areaMeasurement.visible = true
			return areaMeasurement;
		})
	const penGroups = schema.items
		.filter(drawingItem => drawingItem.type === DrawingItemType.PEN)
		.map(drawingItem => {
			const penGroup = drawingItem.data as PenGroup;
			penGroup.visible = true
			penGroup.penLines.forEach(item => {item.visible = true;})
			return penGroup;
		})
	const textGroups = schema.items
		.filter(drawingItem => drawingItem.type === DrawingItemType.TEXT)
		.map(drawingItem => {
			const textGroup = drawingItem.data as TextGroup;
			textGroup.visible = true
			textGroup.textItems.forEach(item => {item.visible = true;})
			return textGroup;
		})
	const images = schema.items
		.filter(drawingItem => drawingItem.type === DrawingItemType.IMAGE)
		.map(drawingItem => {
			const image = drawingItem.data as ImageData;
			image.visible = true
			return image;
		})

	const dispatch = store.dispatch;

	const getScaleInfoState = function() {
		if (schema.scaleInfoConfig) {
			return {
				...initialScaleInfoState,
				unitFactor: schema.scaleInfoConfig.infoUnitFactor ?? initialScaleInfoState.unitFactor,
				unitWidth: schema.scaleInfoConfig.infoUnitWidth ?? initialScaleInfoState.unitWidth,
				position: schema.scaleInfoConfig.infoPosition,
			}
		}
		else {
			return {...initialScaleInfoState}
		}
	}

	dispatch(scaleActions.setScaleConfig({
		value: {
			...defaultScaleConfig,
			scaleInfoState: getScaleInfoState(),
			scale: planScale ?? null
		}
	}));

	dispatch(configActions.setStageConfig({value: schema.stageConfig}));
	dispatch(configActions.setEditorLockState(locked));

	dispatch(countActions.setCounts({value: countGroups}));
	dispatch(lengthActions.setLengths({value: lengthMeasurements}));
	dispatch(areaActions.setAreas({value: areaMeasurements}));
	dispatch(penActions.setPenGroups({value: penGroups}));
	dispatch(textActions.setTextGroups({value: textGroups}));
	dispatch(imageActions.setImages({value: images}))

	const scaleOptions = schema.scaleOptions
	scaleOptions && dispatch(viewActions.setScaleOptions(scaleOptions))

	const loadedInfos = countGroups
		.reduce<CountMeasurementItem[]>((prev, current) => [...prev, ...current.countItems], [])
		.map(item => ({id: item.id, loaded: false}))
		.concat((images).map(image => ({id: image.id, loaded: false})))

	dispatch(viewActions.setRemoteContentLoadedInfo({
		allItemsLoaded: loadedInfos.length === 0,
		loadedInfos: loadedInfos
	}))

	if (schema.zoomScale !== undefined) {
		dispatch(viewActions.setSavedZoomStateScale(schema.zoomScale))
	}
	if (schema.rotation !== undefined) {
		dispatch(viewActions.setSavedRotation(schema.rotation))
	}

	dispatch(ActionCreators.clearHistory());
	dispatch(viewActions.setEditorIsDirty(false))
}

export function deserializePlanToSummaryItems(
	data: EditorDrawingData,
	measurements: SerializedMeasurement[],
	scale: number | undefined
): PlanSummaryItem[] {
	const {areaMeasurements, countMeasurements, lengthMeasurements} = transformDrawingItems(data.items)
	let summaryItems: PlanSummaryItem[] = [];
	if (scale !== undefined) {
		summaryItems = [
			...areaMeasurements.map(am =>
				PlanSummaryItem.fromAreaMeasurement(withUpdatedLaborAndFitTime(am, measurements) as AreaMeasurement, scale)
			),
			...countMeasurements.map(cm =>
				PlanSummaryItem.fromCountMeasurement(withUpdatedLaborAndFitTime(cm, measurements) as CountMeasurement)
			),
			...lengthMeasurements.map(lm =>
				PlanSummaryItem.fromLengthMeasurement(withUpdatedLaborAndFitTime(lm, measurements) as LengthMeasurement, scale)
			)
		]
	}
	return summaryItems
}
