import {useState} from "react";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import {Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import CompanyCostCenter from "../../../models/CompanyCostCenter";
import LoggerService from "../../../services/LoggerService";

function useCompanyCostCenters() {
	const [companyCostCenters, setCompanyCostCenters] = useState<CompanyCostCenter[]>([])
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	async function loadCompanyCostCenters() {
		setLoadStatus(Status.LOADING);
		try {
			const data = await apiInstance.usersApi.fetchCompanyCostCenters()
			setCompanyCostCenters(data)
			setLoadStatus(Status.SUCCESS)
		}
		catch (err) {
			setLoadStatus(Status.ERROR);
			LoggerService.logError(err);
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.createNewCCModal.companyCCFetchErrorDesc")
			)
		}
	}

	return {companyCostCenters, companyCostCentersLoadStatus: loadStatus, loadCompanyCostCenters}
}

export default useCompanyCostCenters;
