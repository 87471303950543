import React from "react";
import {Status} from "../../../../../models/enums";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../../../components/layout/table/TableHeaderCell";
import InlineSpinner from "../../../../../components/InlineSpinner";
import DatePresenter from "../../../../../components/ui/DatePresenter";
import {formatBytes} from "../../../../../utils/ConversionUtils";
import TableNoRecords from "../../../../../components/layout/table/TableNoRecords";
import {AttachmentDetailsFolder} from "../../../../../models/AttachmentDetailsFolder";
import {AttachmentDetails} from "../../../../../models/AttachmentDetails";
import "./attachmentTable.scss";
import {get} from "../../../../../utils/ClassNameUtils";

type AttachmentTableProps = {
	onFolderClick(folder: AttachmentDetailsFolder): void
	filesLoadStatus: Status
	folderLoadStatus: Status
	attachments: AttachmentDetails[]
	attachmentFolders: AttachmentDetailsFolder[]
	selectedFileId: string | null
	onFileSelect(file: AttachmentDetails): void
}

export function AttachmentTable({
	onFolderClick,
	filesLoadStatus,
	folderLoadStatus,
	attachments,
	attachmentFolders,
	selectedFileId,
	onFileSelect
}: AttachmentTableProps) {

	const {t} = useTranslation();
	const columns = [
		{
			id: "name",
			label: t("projects.details.attachmentSelectModal.columns.name"),
		}, {
			id: "date",
			label: t("projects.details.attachmentSelectModal.columns.date")
		}, {
			id: "size",
			label: t("projects.details.attachmentSelectModal.columns.size")
		}
	]

	return (
		<div className={"attachment-table-container scrollable"}>
			<table className={"table"}>
				<thead>
				<tr>
					{columns.map(col => (
						<TableHeaderCell key={col.id} id={col.id} label={col.label}/>
					))}
				</tr>
				</thead>
				<tbody>
				{(filesLoadStatus === Status.LOADING || folderLoadStatus === Status.LOADING)
					? <tr>
						<td style={{textAlign: "center"}} colSpan={columns.length}><InlineSpinner/></td>
					</tr>
					: null}
				{(filesLoadStatus === Status.SUCCESS && folderLoadStatus === Status.SUCCESS) ? (
					<>
						{attachmentFolders.map(folder => (
							<tr
								key={folder.id}
								className={"link"}
								onClick={() => { onFolderClick(folder) }}
							>
								<td>{folder.name}</td>
								<td/>
								<td/>
							</tr>
						))}
						{attachments.map(attachment => (
							<tr
								key={attachment.id}
								onClick={() => {onFileSelect(attachment)}}
								className={get({"active": selectedFileId === attachment.id})}
							>
								<td className={"break-word"}>{attachment.filename}</td>
								<td><DatePresenter date={attachment.createdAt}/></td>
								<td>{formatBytes(attachment.size)}</td>
							</tr>
						))}
						{(attachmentFolders.length === 0 && attachments.length === 0) ? (
							<TableNoRecords cols={columns.length}/>
						) : null}
					</>
				) : null}
				</tbody>
			</table>
		</div>
	)
}
