import React, {MutableRefObject, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectActiveTool} from "../config/configSlice";
import Konva from "konva";
import {KonvaEventObject} from "konva/types/Node";
import {CountMeasurement, CountMeasurementItem} from "../../models/editor";
import {countActions, selectActiveCountGroupId, selectGroupActiveItemId} from "./countSlice";
import {colorHexToRGB} from "../../../../../../utils/TextUtils";
import {EditorPanelTabKeys, EditorTool} from "../../models/enums";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {useGetHighlightShapeStyle, useHighlightSetter} from "../../hooks/highlight";
import {selectExportInProgress, viewActions} from "../view/viewSlice";
import {useGetSelectionShapeStyle, useSelectSetter} from "../../hooks/select";
import {useActivate} from "../../hooks/activate";
import {useEditorPanelDataContext} from "../panel/EditorPanelProvider";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {EventHandlers, SymbolElementKonva} from "./SymbolElementKonva";
import {ShapeConfig} from "konva/types/Shape";
import {CursorStyle, DrawingItemType} from "../../../../../base-konva/enums";

type SymbolElementProps = {
	countGroup: CountMeasurement
	countItem: CountMeasurementItem
	isTransformMode: MutableRefObject<boolean>
	symbolImage: HTMLImageElement
}
const _SymbolElement: React.FC<SymbolElementProps> = ({
	countGroup,
	countItem,
	isTransformMode,
	symbolImage,
}) => {
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const countGroupActive = useSelector(selectActiveCountGroupId) === countGroup.id
	const {changeCursor} = useCursorStyle();
	const imageRef = useRef<Konva.Image>(null);
	const strokeWidth = 1.5;
	const padding = 5;
	const vertices = [
		{x: -padding, y: -padding},
		{x: countGroup.style.size + padding, y: -padding},
		{x: countGroup.style.size + padding, y: countGroup.style.size + padding},
		{x: -padding, y: countGroup.style.size + padding},
	]
	const {getHighlightStyle} = useGetHighlightShapeStyle();
	const highlightActions = useHighlightSetter(countItem.id);
	const lineDashPattern = [3, 3];
	const transformerRef = useRef<Konva.Transformer>(null);
	const groupRef = useRef<Konva.Group>(null);
	const activeItemId = useSelector(selectGroupActiveItemId(countGroup.id));
	const exportInProgress = useSelector(selectExportInProgress);
	const isCountGroupActive = countGroupActive && !exportInProgress &&
		(activeTool === EditorTool.COUNT || activeTool === EditorTool.MEASUREMENT_SELECT)
	const showTransformer = !exportInProgress && isCountGroupActive && activeItemId === countItem.id;
	const visible = countItem.visible || exportInProgress;
	const selectActions = useSelectSetter();
	const {getSelectionStyle} = useGetSelectionShapeStyle();
	const activateActions = useActivate();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext();

	useEffect(function() {
		if (symbolImage) {
			dispatch(viewActions.setRemoteItemLoaded(countItem.id))
		}
	}, [symbolImage, dispatch, countItem.id])

	function getPoints() {
		const reducer = (prev: number[], currentValue: { x: number, y: number }) => {
			return [...prev, currentValue.x, currentValue.y]
		}
		return vertices.reduce(reducer, []);
	}

	function handleDragEnd(evt: KonvaEventObject<MouseEvent>) {
		dispatch(countActions.updateCountItemPosition({
			parentId: countGroup.id,
			id: countItem.id,
			position: {x: evt.target.x(), y: evt.target.y()}
		}))
	}

	function handleMouseEnter(evt: KonvaEventObject<MouseEvent>) {
		changeCursor({
			event: evt,
			cursor: activeTool === EditorTool.MEASUREMENT_SELECT
				? CursorStyle.POINTER
				: CursorStyle.MOVE
		})
		highlightActions.setHighlight()
	}

	function handleMouseLeave(evt: KonvaEventObject<MouseEvent>) {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT});
		highlightActions.clearHighlight()
	}

	function selectElement() {
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.COUNT}));
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.MEASUREMENT_SELECT}));
		activateActions.activateCount(countItem.id, countGroup.id);
		selectActions.clearSelection();
		selectActions.setSelection(DrawingItemType.COUNT, countGroup.id, undefined, [countItem.id]);
		getExpandState(EditorPanelTabKeys.COUNT).expandTab();
	}

	function activateCountItem() {
		dispatch(countActions.activateCountItem({
			parentId: countGroup.id,
			id: countItem.id
		}))
	}

	function handleTransformStart() {
		isTransformMode.current = true;
	}

	function handleTransformEnd(evt: KonvaEventObject<Event>) {
		dispatch(countActions.transformCountItem({
			parentId: countGroup.id,
			id: countItem.id,
			rotation: evt.target.rotation()
		}))
	}

	useEffect(() => {
		if (imageRef.current) {
			const color = colorHexToRGB(countGroup.style.color);
			imageRef.current.cache()
			imageRef.current.red(color.r)
			imageRef.current.green(color.g)
			imageRef.current.blue(color.b)
			if (color.a) {
				imageRef.current.opacity(color.a);
			}
			imageRef.current.getLayer()?.batchDraw()
		}
	}, [symbolImage, imageRef, countGroup.style]);

	useEffect(() => {
		if (transformerRef.current && groupRef.current) {
			transformerRef.current.nodes([groupRef.current])
			transformerRef.current.getLayer()?.batchDraw();
		}
	}, [symbolImage, showTransformer])

	const highlightStyle = getHighlightStyle(id => id === countItem.id || id === countGroup.id)
	const selectionStyle = getSelectionStyle((highlighted) => {
		return highlighted.some(item =>
			item.type === DrawingItemType.COUNT && item.itemId === countGroup.id &&
			(item.subItemIds && item.subItemIds.some((id: string) => id === countItem.id)));
	})

	const lineStyle: ShapeConfig = {
		closed: true,
		dash: lineDashPattern,
		lineJoin: "round",
		strokeWidth: strokeWidth,
		stroke: "#4f4f4f",
		...highlightStyle
	}

	const draggable = activeTool === EditorTool.MEASUREMENT_SELECT ? false : countGroupActive

	const events: EventHandlers = activeTool === EditorTool.MEASUREMENT_SELECT
		? {
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			onClick: selectElement,
			onTap: selectElement,
		}
		: {
			onDragEnd: handleDragEnd,
			onMouseEnter: handleMouseEnter,
			onMouseLeave: handleMouseLeave,
			onClick: activateCountItem,
			onTap: activateCountItem,
		}

	const eventsRef: React.MutableRefObject<EventHandlers> = useUpdatedRef({
		...events,
		handleTransformStart: handleTransformStart,
		handleTransformEnd: handleTransformEnd,
	})

	return (
		<SymbolElementKonva
			visible={visible}
			group={{
				ref: groupRef,
				draggable: draggable,
				offsetX: countGroup.style.size / 2,
				offsetY: countGroup.style.size / 2,
				rotation: countItem.rotation,
				x: countItem.position.x,
				y: countItem.position.y,
				height: countGroup.style.size,
				width: countGroup.style.size
			}}
			eventsRef={eventsRef}
			image={{
				ref: imageRef,
				src: symbolImage,
				height: countGroup.style.size,
				width: countGroup.style.size,
				style: {
					...highlightStyle,
					...selectionStyle
				}
			}}
			line={isCountGroupActive ? {
				id: `symbol_element_${countItem.id}`,
				points: getPoints(),
				style: lineStyle
			} : undefined}
			transformer={showTransformer ? {
				ref: transformerRef
			} : undefined}
		/>
	)
}

export const SymbolElement = React.memo(_SymbolElement)
