import React from "react";
import {apiInstance} from "../api/api";
import LoggerService from "../services/LoggerService";
import {RemoteSymbol} from "../features/project-drawings/features/editor/models/editor";

type RemoteSymbolId = string
type BlobUrl = string
type SymbolCacheType = Record<RemoteSymbolId, Promise<BlobUrl> | undefined>

type SymbolCacheDataContextType = {
	getSymbolUrl: (remoteSymbol: RemoteSymbol) => Promise<BlobUrl>
}
const SymbolCacheDataContext = React.createContext<SymbolCacheDataContextType | undefined>(undefined)

const SymbolCacheDataContextBridge = {
	Consumer: SymbolCacheDataContext.Consumer,
	Provider: SymbolCacheDataContext.Provider
}

const SymbolCacheDataProvider: React.FC = ({children}) => {

	const symbolCacheRef = React.useRef<SymbolCacheType>({});

	const getSymbolUrl = React.useCallback(function(remoteSymbol: RemoteSymbol) {
		const cachedPromise = symbolCacheRef.current[remoteSymbol.id];
		if (cachedPromise) {
			return cachedPromise;
		}
		else {
			const urlPromise = createSymbolUrlPromise(remoteSymbol)
			symbolCacheRef.current[remoteSymbol.id] = urlPromise
			urlPromise.catch(() => {
				symbolCacheRef.current[remoteSymbol.id] = undefined;
			})
			return urlPromise
		}
	}, [symbolCacheRef])

	return (
		<SymbolCacheDataContext.Provider value={{getSymbolUrl}}>
			{children}
		</SymbolCacheDataContext.Provider>
	)
}

function useSymbolCacheDataContext() {
	const context = React.useContext(SymbolCacheDataContext)
	if (!context) {
		throw new Error("useSymbolCacheDataContext must be used within a SymbolCacheDataProvider")
	}
	return context
}

function createSymbolUrlPromise(remoteSymbol: RemoteSymbol) {
	return new Promise<string>(async (resolve, reject) => {
		try {
			let attachment;
			try {
				attachment = await apiInstance.attachmentApi.downloadExternal(remoteSymbol.id, remoteSymbol.filename)
			}
			catch (e) {
				LoggerService.logError(
					new Error("Error downloading external remote symbol"),
					{remoteSymbol}
				)
				return reject(e)
			}
			const buffer = await new Blob([attachment.data]).arrayBuffer()
			const text = await new Blob([buffer]).text()
			const blob = new Blob([text], {type: "image/svg+xml"})
			const url = URL.createObjectURL(blob)
			return resolve(url)
		}
		catch (e) {
			return reject(e)
		}
	})
}

export {SymbolCacheDataProvider, useSymbolCacheDataContext, SymbolCacheDataContextBridge}