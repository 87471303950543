import {AxiosInstance} from "axios";
import {SymbolRecognitionProcessInfo} from "../models/SymbolRecognitionProcessInfo";
import {SymbolRecognitionResultData} from "../models/SymbolRecognitionResultData";
import {SelectionState} from "../features/base-konva/types";

export default class SymbolRecognizerApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	startSymbolRecognition(
		selection: SelectionState,
		planId: string
	): Promise<SymbolRecognitionProcessInfo> {
		const url = `/api/symbol_recognizer`
		return this.axiosInstance.post(url, {symbol: selection, planId: planId}).then(
			resp => SymbolRecognitionProcessInfo.fromJson(resp.data)
		)
	}

	askForSymbolRecognitionResults(id: string): Promise<SymbolRecognitionResultData> {
		const url = `/api/symbol_recognizer/${id}`;
		return this.axiosInstance.get(url).then(response => SymbolRecognitionResultData.fromJson(response.data));
	}
}