import {useQueryClient} from "react-query";
import {SYMBOL_QUERY_KEY} from "../../../api/SymbolsApi";

export function useInvalidateSymbolQueries() {
	const queryClient = useQueryClient()

	function invalidateSymbolQueries() {
		queryClient.invalidateQueries(SYMBOL_QUERY_KEY).then()
	}

	return {invalidateSymbolQueries}
}