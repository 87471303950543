import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {initialState, reducers} from "../../../../../base-konva/features/image/baseImageSlice";

export const imageSlice = createSlice({
	name: "image",
	initialState,
	reducers
})

export const imageReducer = imageSlice.reducer;
export const imageActions = imageSlice.actions;

export const selectImages = (state: RootState) => state.undoGroup.present.image.images;
export const selectActiveImageId = (state: RootState) => state.undoGroup.present.image.activeImageId;