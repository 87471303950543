import {useMountedState} from "../../../hooks/useMountedState";
import ProjectDetails from "../../../models/ProjectDetails";
import {ProjectSource, Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {useEffect, useRef, useState} from "react";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {useProjectDetailContext} from "../providers/ProjectDetailProvider";
import {paths, useNavigator} from "../../navigator";

const takeoffsTabsPaths = {
	[ProjectSource.JOB]: paths.takeoffs_jobs,
	[ProjectSource.QUOTE]: paths.takeoffs_quotes,
}

function useProjectDetails() {
	const [project, setProject] = useMountedState<ProjectDetails | undefined>(undefined);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();
	const {navigateTo} = useNavigator()
	const shouldRefetchProject = useRef(false);

	const loadProjectDetails = async (projectId: string) => {
		setLoadStatus(Status.LOADING)
		try {
			const project = await apiInstance.projectsApi.fetchProject(projectId)
			setProject(project)
			setLoadStatus(Status.SUCCESS)
		}
		catch (err) {
			setLoadStatus(Status.ERROR)
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), t("projects.details.fetchDetailsErrorDesc"))
		}
	};


	const loadProjectDetailsBySimproId = (simproId: number, projectSource: ProjectSource) => {
		setLoadStatus(Status.LOADING);
		(projectSource === ProjectSource.JOB ?
				apiInstance.projectsApi.fetchJobProject(simproId) :
				apiInstance.projectsApi.fetchQuoteProject(simproId)
		)
			.then(project => {
				setProject(project)
				setLoadStatus(Status.SUCCESS)
			})
			.catch(err => {
				setLoadStatus(Status.ERROR)
				navigateTo(takeoffsTabsPaths[projectSource])
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"), t("projects.details.fetchDetailsErrorDesc"))
			})
	};

	return {
		project, loadStatus, shouldRefetchProject,
		loadProjectDetails,
		loadProjectDetailsBySimproId
	}
}

function useRefetchProjectTreeOnMount() {
	const {refreshProjectTreeAfterSimproUpdate, projectDetails} = useProjectDetailContext()
	const {shouldRefetchProject} = projectDetails
	const dataRef = useUpdatedRef({
		refreshProjectTreeAfterSimproUpdate,
		shouldRefetchProject
	})

	useEffect(() => {
		const {
			refreshProjectTreeAfterSimproUpdate,
			shouldRefetchProject
		} = dataRef.current

		if (shouldRefetchProject.current) {
			shouldRefetchProject.current = false;
			refreshProjectTreeAfterSimproUpdate();
		}

		return function unmount() {
			shouldRefetchProject.current = true
		}
	}, [dataRef])
}

export {takeoffsTabsPaths, useProjectDetails, useRefetchProjectTreeOnMount}