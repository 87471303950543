import {DrawingAndVersionsIdsApiDto} from "./restModels";

export default class DrawingCopyResult {
	constructor(
		public readonly drawingId: string,
		public readonly drawingVersionsIds: string[]
	) {}

	public static fromJson(json: DrawingAndVersionsIdsApiDto) {
		return new DrawingCopyResult(
			json.drawingId,
			json.drawingVersionsIds
		)
	}
}