import React from "react";
import "./settingsSymbols.scss";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../components/layout/table/TableHeaderCell";
import {TableColumnConfig, WithActionsListItem} from "../../../models/interfaces";
import TableNoRecords from "../../../components/layout/table/TableNoRecords";
import {SymbolPack} from "../../../models/SymbolPack";
import SymbolPacksTableRow from "./SymbolPacksTableRow";

interface SymbolPackTableProps {
	symbolPacks: SymbolPack[],
	reloadSymbolPacks: () => void;
}

const SymbolPacksTable: React.FC<SymbolPackTableProps> = ({symbolPacks, reloadSymbolPacks}) => {
	const {t} = useTranslation();

	const columns: TableColumnConfig<WithActionsListItem<SymbolPack>>[] = [
		{
			id: "name",
			label: t("settings.tabs.symbols.packs.nameColumn"),
			sortable: false
		},
		{
			id: "actions",
			label: "",
			sortable: false,
			width: 42
		}
	];

	return (
		<table className={"table"}>
			<thead>
			<tr>
				{columns.map(
					col => <TableHeaderCell<WithActionsListItem<SymbolPack>> key={col.id}
																			 id={col.id}
																			 sortable={col.sortable}
																			 label={col.label}
																			 width={col.width}/>)}
			</tr>
			</thead>
			<tbody>
			{symbolPacks.length ?
				symbolPacks.map(symbolPack => {
					return <SymbolPacksTableRow key={symbolPack.id}
												symbolPack={symbolPack}
												reloadSymbolPacks={reloadSymbolPacks}/>;
				})
				: <TableNoRecords cols={2}/>}
			</tbody>
		</table>
	);
};

export default SymbolPacksTable;
