import React from "react";

interface IconImageToolSmallProps {
	isActive?: boolean
}

export const IconImageToolSmall: React.FC<IconImageToolSmallProps> = (props) => {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16">
			<path
				d="M12.158,3c0.439,0 0.802,0.339 0.838,0.769l0.004,0.073l0,8.316c0,0.44 -0.339,0.802 -0.769,0.839l-0.073,0.003l-8.316,-0c-0.44,-0 -0.802,-0.339 -0.839,-0.769l-0.003,-0.073l0,-8.316c0,-0.44 0.339,-0.802 0.769,-0.839l0.073,-0.003l8.316,-0Zm-1.56,4.415l-2.531,2.536l1.201,1.201c0.125,0.125 0.125,0.329 -0,0.455c-0.116,0.11 -0.293,0.12 -0.418,0.031l-0.039,-0.033l-2.63,-2.63l-2.536,2.536l-0,0.647c-0,0.095 0.067,0.175 0.157,0.193l0.04,0.004l8.325,0c0.092,-0.004 0.167,-0.071 0.185,-0.159l0.003,-0.038l-0,-2.986l-1.757,-1.757Zm1.56,-3.771l-8.316,0l-0.039,0.004c-0.038,0.007 -0.073,0.026 -0.101,0.054c-0.028,0.028 -0.046,0.063 -0.054,0.101l-0.004,0.039l0,6.759l2.311,-2.308c0.112,-0.112 0.289,-0.124 0.414,-0.033l0.04,0.033l1.201,1.201l2.763,-2.76c0.112,-0.113 0.289,-0.124 0.414,-0.034l0.04,0.034l1.528,1.528l0,-4.421c0,-0.095 -0.067,-0.174 -0.157,-0.193l-0.04,-0.004Zm-6.908,0.356c0.689,0 1.25,0.561 1.25,1.25c0,0.689 -0.561,1.25 -1.25,1.25c-0.689,0 -1.25,-0.561 -1.25,-1.25c0,-0.689 0.561,-1.25 1.25,-1.25Zm0,0.592c-0.363,0 -0.658,0.296 -0.658,0.658c-0,0.363 0.295,0.659 0.658,0.659c0.363,-0 0.658,-0.296 0.658,-0.659c0,-0.362 -0.295,-0.658 -0.658,-0.658Z"
				fill={props.isActive ? "white" : "#262626"}/>
		</svg>
	);
}