import React from "react";
import IconWithTooltip from "../../../../components/ui/IconWithTooltip";
import IconZoomOut from "../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconZoomOut";
import IconZoomIn from "../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconZoomIn";
import {useTranslation} from "react-i18next";
import "./zoomControls.scss"

const ZoomControls: React.FC<{
	currentZoom: number,
	minZoom: boolean,
	maxZoom: boolean,
	handleZoomOut: () => void,
	handleZoomReset: () => void,
	handleZoomIn: () => void,
}> = ({
	currentZoom,
	minZoom,
	maxZoom,
	handleZoomOut,
	handleZoomReset,
	handleZoomIn,
}) => {
	const {t} = useTranslation();

	return (
		<div className={"zoom-controls"}>
			<IconWithTooltip
				tooltipText={t("editor.tooltip.zoomOut")}
				icon={<IconZoomOut disabled={minZoom} onClick={handleZoomOut}/>}
			/>
			<div className={"zoom-controls_scale"} onClick={handleZoomReset}>
				{currentZoom}%
			</div>
			<IconWithTooltip
				tooltipText={t("editor.tooltip.zoomIn")}
				icon={<IconZoomIn disabled={maxZoom} onClick={handleZoomIn}/>}
			/>
		</div>
	)
}

export {ZoomControls}