import {DrawingType, Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import {UpdateDrawingApiDto} from "../../../models/restModels";
import {EditorDrawingData} from "../../project-drawings/features/editor/models/editor";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import DrawingDetails from "../../../models/DrawingDetails";
import {DrawingVersion} from "../../../models/DrawingVersion";
import {DrawingErrorTypes} from "../../project-drawings/features/editor/models/enums";
import {INITIAL_VERSION_ID} from "../../project-drawings/features/editor/constants";

interface UseDrawingVersionCopyHook {
	copyDrawingVersion: (
		oldDrawingId: string,
		oldDrawingVersionId: string,
		newDrawingName: string,
		newSectionId: string,
		newCostCenterId: string,
		onSuccess: (drawingDetails: DrawingDetails, version: string) => void
	) => void,
	saveStatus: Status,
}

function useDrawingVersionCopy(projectId: string): UseDrawingVersionCopyHook {
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	const errorTranslations = {
		[DrawingErrorTypes.VERSION_LOCK]: t(`projects.details.copyDrawingModal.copyDrawingErrDescVersionLock`),
		[DrawingErrorTypes.DRAWING_LOCK]: t(`projects.details.copyDrawingModal.copyDrawingErrDescDrawingLock`),
		[DrawingErrorTypes.DEFAULT]: t(`projects.details.copyDrawingModal.copyDrawingErrDesc`)
	}

	function handleError(err: any) {
		setSaveStatus(Status.ERROR);
		LoggerService.logError(err);
		let errorKey: DrawingErrorTypes;
		if (err?.response?.data?.exception?.includes("SourceDrawingVersionIsLockedException")) {
			errorKey = DrawingErrorTypes.VERSION_LOCK
		}
		else if (err?.response?.data?.exception?.includes("DrawingLockedException")) {
			errorKey = DrawingErrorTypes.DRAWING_LOCK
		}
		else {
			errorKey = DrawingErrorTypes.DEFAULT
		}
		NotificationService.errorNotification(t("common.error"), errorTranslations[errorKey])
	}

	function copyDrawingVersion(
		oldDrawingId: string,
		oldDrawingVersionId: string,
		newDrawingName: string,
		newSectionId: string,
		newCostCenterId: string,
		onSuccess: (drawingDetails: DrawingDetails, version: string) => void) {
		setSaveStatus(Status.LOADING);
		const sourceVersionId = oldDrawingVersionId === INITIAL_VERSION_ID ? undefined : oldDrawingVersionId;
		// DrawingType.TAKE_OFF because AS_BUILT type cannot have copies
		apiInstance.drawingsApi.postDrawingFromSource(projectId, newSectionId, newCostCenterId, newDrawingName, oldDrawingId,
			DrawingType.TAKE_OFF)
			.then((drawingDetails) => {
				apiInstance.drawingsApi.fetchDrawingData(oldDrawingVersionId)
					.then(drawingData => {
						const {data, measurements} = drawingData;
						const putDrawingData: UpdateDrawingApiDto = {
							data: (data) as EditorDrawingData,
							measurements,
							sourceVersionId
						};
						if (data) {
							apiInstance.drawingsApi.putDrawingData(drawingDetails.id, putDrawingData)
								.then((drawingVersion: DrawingVersion) => {
									setSaveStatus(Status.SUCCESS);
									onSuccess(drawingDetails, drawingVersion.id);
									NotificationService.successNotification(t("common.success"),
										t("projects.details.copyDrawingModal.copyDrawingSuccessDesc"))

								})
								.catch(handleError)
						}
					})
					.catch(handleError)
			})
			.catch(handleError)
	}

	return {copyDrawingVersion, saveStatus}
}

export default useDrawingVersionCopy;
