import {useTemplatesData} from "../../hooks/useTemplatesData";
import {get} from "../../../../../../../../../utils/ClassNameUtils";
import React from "react";

export function TemplateBreadcrumbs(props: ReturnType<typeof useTemplatesData>["breadcrumbs"]) {
	const {breadcrumbs, handleBreadcrumbClick} = props

	return (
		<div className="material-modal_container_content_breadcrumbs">
			{breadcrumbs.map((enteredGroup, index) => {
				const last = index === breadcrumbs.length - 1
				return (
					<span key={index}>
						<span className={get({"link": !last})}
							  onClick={() => {!last && handleBreadcrumbClick(enteredGroup)}}>
							{enteredGroup.name}
						</span>
						{(!last) && <span> / </span>}
					</span>
				);
			})}
		</div>
	);
}