import {useEffect, useState} from "react";
import {Status} from "../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../api/api";
import LoggerService from "../services/LoggerService";
import NotificationService from "../services/NotificationService";
import FitTime from "../models/FitTime";

function useFitTimes() {
	const {t} = useTranslation();

	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const [fitTimes, setFitTimes] = useState<FitTime[]>([]);

	useEffect(() => {
		async function loadFitTimes() {
			setLoadStatus(Status.LOADING);
			try {
				const fitTimes = await apiInstance.measurementsApi.fetchFitTimes()
				setFitTimes(fitTimes);
				setLoadStatus(Status.SUCCESS);
			}
			catch (err) {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.summary.itemsTable.editFtmModal.loadErrorDesc")
				)
			}
		}

		loadFitTimes().then()
	}, [t])

	return {fitTimes, loadStatus}
}

export default useFitTimes;
