import React, {MutableRefObject} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Konva from "konva";
import {Vector2d} from "konva/types/types";
import {TextGroup, TextItem} from "../../../../../base-konva/types";
import {BaseTextElement} from "../../../../../base-konva/features/text/BaseTextElement";
import {planUploadTextActions} from "./planUploadTextSlice";
import {planUploadConfigActions, selectPlanUploadActiveTool} from "../config/planUploadConfigSlice";
import {selectPlanUploadExportInProgress} from "../view/planUploadViewSlice";
import {usePlanUploadGetHighlightShapeStyle, usePlanUploadHighlightSetter} from "../../hooks/planUploadHighlight";
import {DrawingItemType} from "../../../../../base-konva/enums";
import {usePlanUploadGetSelectionShapeStyle, usePlanUploadSelectSetter} from "../../hooks/planUploadSelect";
import {usePlanUploadActivate} from "../../hooks/planUploadActivate";
import {PlanUploadEditorTool} from "../../types";

type PlanUploadTextElementProps = {
	textGroup: TextGroup,
	textItem: TextItem,
	isTransformMode: MutableRefObject<boolean>,
}
export const PlanUploadTextElement: React.FC<PlanUploadTextElementProps> = ({textItem, textGroup, isTransformMode}) => {
	const {style} = textGroup;
	const {id} = textItem;
	const activeTool = useSelector(selectPlanUploadActiveTool);
	const exportInProgress = useSelector(selectPlanUploadExportInProgress);
	const dispatch = useDispatch();
	const {getHighlightStyle} = usePlanUploadGetHighlightShapeStyle();
	const highlightActions = usePlanUploadHighlightSetter(id);
	const textHighlightStyle = getHighlightStyle(highlightedPanelItemId =>
		highlightedPanelItemId === id || highlightedPanelItemId === textGroup.id)
	const activateActions = usePlanUploadActivate();
	const selectActions = usePlanUploadSelectSetter();
	const {getSelectionStyle} = usePlanUploadGetSelectionShapeStyle();
	const selectionStyle = getSelectionStyle(highlighted => (
		highlighted.some(item => (
			item.type === DrawingItemType.TEXT &&
			item.itemId === textGroup.id &&
			(item.subItemIds && item.subItemIds.some(id => id === textItem.id))
		))
	))

	function handleDoubleClick() {
		dispatch(planUploadTextActions.unselectAllTextItems())
		dispatch(planUploadTextActions.editTextItem({id}))
	}

	function handleClick() {
		dispatch(planUploadTextActions.selectTextItem({id}))
	}

	function handleDragEnd(evt: Konva.KonvaEventObject<Event>) {
		const position = evt.target.position();
		dispatch(planUploadTextActions.updateTextElementPosition({itemId: textItem.id, newPosition: position}));
	}

	const onTransformEnd = (newPosition: Vector2d, scale: number, rotation: number) => {
		dispatch(planUploadTextActions.transformTextElement(
			{itemId: textItem.id, newPosition, fontSize: style.fontSize * scale, rotation}))
	}

	const selectElement = () => {
		dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: PlanUploadEditorTool.TEXT}))
		dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: PlanUploadEditorTool.MEASUREMENT_SELECT}))
		activateActions.activateText(textItem.id, textGroup.id)
		selectActions.clearSelection()
		selectActions.setSelection(DrawingItemType.TEXT, textGroup.id, undefined, [textItem.id])
	}

	const isToolSelect = activeTool === PlanUploadEditorTool.MEASUREMENT_SELECT;

	return (
		<BaseTextElement
			textGroup={textGroup}
			textItem={textItem}
			isTransformMode={isTransformMode}
			exportInProgress={exportInProgress}
			isTextToolActive={activeTool === PlanUploadEditorTool.TEXT}
			onEnableHighlight={highlightActions.setHighlight}
			onClearHighlight={highlightActions.clearHighlight}
			selectionStyle={exportInProgress ? undefined : selectionStyle}
			textHighlightStyle={textHighlightStyle}
			onTransformEnd={onTransformEnd}
			handleDoubleClick={isToolSelect ? undefined : handleDoubleClick}
			handleClick={isToolSelect ? selectElement : handleClick}
			handleDragEnd={handleDragEnd}/>
	);
};

