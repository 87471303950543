import React from "react";
import MuiTooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';

interface TooltipComponentProps {
	tooltipText: string;
}


const StyledMuiTooltip = styled(({className, children, ...props}: TooltipProps) => (
	<MuiTooltip {...props} classes={{popper: className}} children={children}/>
))(({theme}) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.white,
		color: 'black',
		fontWeight: "normal",
		boxShadow: theme.shadows[1],
		fontSize: 12,
	},
}));

const Tooltip: React.FC<TooltipComponentProps> = ({tooltipText, children}) => {
	return (
		<StyledMuiTooltip title={tooltipText} placement={"top"} arrow>
			<div>
				{children}
			</div>
		</StyledMuiTooltip>
	);
};

export default Tooltip
