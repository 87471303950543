import React, {useEffect} from "react";
import Konva from "konva";
import {useDispatch, useSelector} from "react-redux";
import {planUploadViewActions, selectPlanUploadZoomState} from "../view/planUploadViewSlice";
import {selectPlanUploadActiveTool} from "../config/planUploadConfigSlice";
import {useBasePan} from "../../../../../base-konva/features/pan";
import {PlanUploadEditorTool} from "../../types";

type UsePanProps = {
	stageRef: React.RefObject<Konva.Stage>;
}

function usePlanUploadPan({stageRef}: UsePanProps) {

	const dispatch = useDispatch()
	const zoomState = useSelector(selectPlanUploadZoomState)
	const activeTool = useSelector(selectPlanUploadActiveTool)

	const {onMouseDown, onTouchStart, panInProgress} = useBasePan({
		stageRef,
		canScroll: () => activeTool !== PlanUploadEditorTool.PEN
			&& activeTool !== PlanUploadEditorTool.CLIP
			&& activeTool !== PlanUploadEditorTool.ERASE_AREA,
		viewportSize: zoomState.viewportSize
	})

	useEffect(() => {
		dispatch(planUploadViewActions.setPanInProgress(panInProgress));
	}, [panInProgress, dispatch]);

	return {
		onMouseDown,
		onTouchStart
	}
}

export {usePlanUploadPan}