import React from "react";
import IconBlueArrow from "../icons/IconBlueArrow";
import "./accordionBar.scss";

interface IAccordionBarProps {
	label: string,
	onClick: () => void,
	isOpen: boolean,
	icon?: JSX.Element;
}

const AccordionBar: React.FC<IAccordionBarProps> = ({label, onClick, isOpen, icon}) => {

	const cls = [
		"accordion-bar",
		isOpen && "is-open"
	].filter(Boolean).join(" ")

	return (
		<div className={cls} onClick={onClick}>
			{icon}
			<div className={"accordion-bar_label"}>{label}</div>
			<IconBlueArrow/>
		</div>
	);
};

export default AccordionBar
