import {apiInstance} from "../../../api/api";
import {CostCenter} from "../../../models/CostCenter";

function useMoveDrawingsToExistingCostCenter() {

	async function moveDrawingsToExistingCostCenter(deletedCostCenter: CostCenter, targetedCostCenterId: string) {
		const drawingIds = deletedCostCenter.drawings.map(drawing => drawing.id)
		if (drawingIds.length > 0) {
			await apiInstance.drawingsApi.putDrawingsToNewCostCenter(drawingIds, targetedCostCenterId)
		}
	}

	return {moveDrawingsToExistingCostCenter};
}

export {useMoveDrawingsToExistingCostCenter}
