import React from "react";
import Modal from "../../../../../../../../../components/modal/Modal";
import {MaterialType} from "../../../../../models/enums";
import {useInternalDataContext, useMaterialModalContext} from "../../MaterialModalProvider";
import {PrebuildTabContent} from "./components/PrebuildTabContent";
import {CatalogTabContent} from "./components/CatalogTabContent";
import {MaterialModalTabs} from "../../components/MaterialModalTabs";
import {AddItemsModal as OneOffAddItemsModal} from "./components/one-off/AddItemsModal";
import {TemplateTabContent} from "./components/template/TemplateTabContent";

const AddItemsModal: React.FC = function() {
	const {closeModal} = useMaterialModalContext()
	const {modalTab} = useInternalDataContext()

	return (
		<>
			{modalTab.active === MaterialType.ONE_OFF ? (
				<OneOffAddItemsModal/>
			) : (
				<Modal isOpen={true} onClose={closeModal} customClass="material-modal">
					<div className="material-modal_container">
						<MaterialModalTabs/>
						{modalTab.active === MaterialType.TEMPLATE ? <TemplateTabContent/> : null}
						{modalTab.active === MaterialType.PREBUILD ? <PrebuildTabContent/> : null}
						{modalTab.active === MaterialType.CATALOG ? <CatalogTabContent/> : null}
					</div>
				</Modal>
			)}
		</>
	)
}

export {AddItemsModal}