import {Status} from "../../../../../../../../models/enums";
import {useEffect, useState} from "react";
import {apiInstance} from "../../../../../../../../api/api";
import LoggerService from "../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import {CatalogItemGroup} from "../../../../../../../../models/CatalogItemGroup";

export interface UseCatalogGroupsHook {
	loadStatus: Status,
	groups: CatalogItemGroup[];
}

function useCatalogGroups(parentGroupId?: number | null): UseCatalogGroupsHook {

	const [groups, setGroups] = useState<CatalogItemGroup[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	useEffect(() => {
		setLoadStatus(Status.LOADING);
		apiInstance.catalogApi.fetchCatalogGroups(parentGroupId)
			.then(groups => {
				let catalogItemGroups: CatalogItemGroup[];
				// adds separate group in root view for groupless items
				if (parentGroupId === undefined) {
					const nullGroup = new CatalogItemGroup(null, t("projects.addItemModal.catalog.nullGroupName"), undefined);
					catalogItemGroups = [...groups, nullGroup];
				}
				// handles groupless items group as a parent (it has no subgroups)
				else if (parentGroupId === null) {
					catalogItemGroups = [];
				}
				else {
					catalogItemGroups = groups;
				}
				setGroups(catalogItemGroups);
				setLoadStatus(Status.SUCCESS);
			})
			.catch((err) => {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"), t("projects.addItemModal.catalog.loadGroupsErrorDesc"));
			})
	}, [parentGroupId, t]);
	return {loadStatus, groups};
}

export default useCatalogGroups;
