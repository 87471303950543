import React from "react";
import {Colors} from "../../styles";

const IconDownload: React.FC<{ fill?: string }> = ({fill = Colors.WHITE}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{height: 22, width: 22}}>
			<path
				d="M13,7.5 L13,13.5 L9,13.5 L16.001,20.501 L23.001,13.5 L19,13.5 L19,7.5 L13,7.5 Z M9,24.5 L23,24.5 L23,22.5 L9,22.5 L9,24.5 Z"
				fill={fill}/>
		</svg>
	);
};

export const IconDownloadSmall: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={16} height={16}>
			<path id="Path" d="M-8,-8l32,0l0,32l-32,0l0,-32Z" fill="none"/>
			<path id="Fill-1"
				  d="M5.176,0l0,5.647l-3.765,0l6.589,6.589l6.589,-6.589l-3.766,0l0,-5.647l-5.647,0Zm-3.765,16l13.177,0l-0,-1.882l-13.177,-0l0,1.882Z"
				  fill="#fff"/>
		</svg>
	);
};

export default IconDownload
