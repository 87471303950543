import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import LoggerService from "../../../services/LoggerService";

function useCreateCostCenter() {
	const {t} = useTranslation();

	function createCostCenter(
		isJobType: boolean,
		projectId: string,
		selectedSectionId: string,
		costCenterName: string,
		companyCostCenterId: number) {
		return (isJobType ?
				apiInstance.projectsApi.postNewJobCostCenter(projectId, selectedSectionId, costCenterName, companyCostCenterId!) :
				apiInstance.projectsApi.postNewQuoteCostCenter(projectId, selectedSectionId, costCenterName, companyCostCenterId!)
		)
			.then(
				(response) => {
					if (response.errors.length > 0) {
						response.errors.forEach(error => {
							NotificationService.errorNotification(
								t("common.error"),
								error.message
							)
						})
						return undefined;
					}
					else {
						return response.id;
					}
				})
			.catch(
				(error) => {
					LoggerService.logError(error)
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.assignToNewCostCenterModal.costCenterCreationError")
					)
					return undefined;
				}
			)
	}

	return {createCostCenter};
}

export {useCreateCostCenter}
