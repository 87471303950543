import React from "react";
import {selectHasRedo, selectHasUndo} from "../../editorStore";
import {useSelector} from "react-redux";
import BaseEditorHeaderUndoPanel from "../../../../../base-konva/features/undo/BaseEditorHeaderUndoPanel";

type EditorHeaderUndoPanelProps = {
	disabled?: boolean,
}
const EditorHeaderUndoPanel: React.FC<EditorHeaderUndoPanelProps> = ({disabled}) => {
	const hasUndo = useSelector(selectHasUndo);
	const hasRedo = useSelector(selectHasRedo);

	return (
		<BaseEditorHeaderUndoPanel disabled={disabled} hasUndo={hasUndo} hasRedo={hasRedo}/>
	);
};

export default EditorHeaderUndoPanel
