import {Colors} from "../../../../../../styles";
import React from "react";

export const IconVolume = function() {
	const fill = Colors.SIMPROBLUE;
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<rect fill={fill} x="19.72" y="0.37" width="4.28" height="1.5"/>
			<rect fill={fill} x="19.72" y="4" width="2.92" height="1.5"/>
			<rect fill={fill} x="19.72" y="7.62" width="2.92" height="1.5"/>
			<rect fill={fill} x="19.72" y="11.25" width="4.28" height="1.5"/>
			<rect fill={fill} x="19.72" y="22.13" width="4.28" height="1.5"/>
			<rect fill={fill} x="19.72" y="14.88" width="2.92" height="1.5"/>
			<rect fill={fill} x="19.72" y="18.5" width="2.92" height="1.5"/>
			<path fill={fill}
				  d="M9.62.12C5,.12.32,1.65.32,4.58v15a1.09,1.09,0,0,0,0,.26c.42,2.65,4.87,4,9.27,4s8.85-1.39,9.27-4a1.09,1.09,0,0,0,0-.26v-15C18.92,1.65,14.24.12,9.62.12Zm0,2c4.39,0,7.3,1.48,7.3,2.46S14,7,9.62,7s-7.3-1.48-7.3-2.45S5.23,2.12,9.62,2.12Zm0,19.76c-4.39,0-7.3-1.48-7.3-2.46v-12A15.28,15.28,0,0,0,9.62,9a15.28,15.28,0,0,0,7.3-1.6v12C16.92,20.4,14,21.88,9.62,21.88Z"/>
		</svg>
	)
}