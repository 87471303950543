import React from "react";
import {useTranslation} from "react-i18next";
import {dashPatterConfig} from "../constants";
import {AreaStyle} from "../models/editor";
import ColorPicker from "./ColorPicker";
import {MinMaxValidatedNumberInput} from "./MinMaxValidatedNumberInput";
import {DashPatternType} from "../../../../base-konva/types";

interface AreaStyleSelectorProps {
	style: AreaStyle,
	onStyleChange: (style: AreaStyle) => void
}

const AreaStyleSelector: React.FC<AreaStyleSelectorProps> = ({style, onStyleChange}) => {

	const {t} = useTranslation();

	return (
		<div className={"style-selector"}>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.areaStyleEditor.stroke")}</div>
				<div className={"style-selector_field_input"}>

					<MinMaxValidatedNumberInput
						min={1} max={10} value={style.strokeWidth}
						onChange={value => {onStyleChange({...style, strokeWidth: value})}}
					/>
				</div>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.areaStyleEditor.color")}</div>
				<ColorPicker color={style.stroke} onChange={color => {onStyleChange({...style, stroke: color})}}/>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.areaStyleEditor.pattern")}</div>
				<select value={style.dashType} onChange={event => {
					onStyleChange({
							...style,
							dashType: event.target.value as DashPatternType
						}
					)
				}}>
					{Object.keys(dashPatterConfig).map(key =>
						<option key={key} value={key}>
							{dashPatterConfig[key].optionDescription}
						</option>)}
				</select>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.areaStyleEditor.fill")}</div>
				<ColorPicker color={style.fill} onChange={color => {onStyleChange({...style, fill: color})}}/>
			</div>
		</div>
	);
};

export default AreaStyleSelector;
