import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorT: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M12.896,20l-0,-12.604l3.85,3.844l1.254,-1.252l-6,-5.988l-6,5.988l1.254,1.252l3.85,-3.844l0,12.604l1.792,0Z"]}/>
	);
};

export default AnchorT