import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import SymbolGroups from "../components/SymbolGroups";
import SymbolPacks from "../components/SymbolPacks";
import SymbolsPreview from "../components/SymbolsPreview";
import DefaultSymbolPacks from "../components/DefaultSymbolPacks";
import {paths} from "../../navigator";

const UserSymbolsRouter: React.FC = () => {
	return (
		<Switch>
			<Route path={paths.settings_symbols_user_groups_groupId_packs_packId} component={SymbolsPreview}/>
			<Route path={paths.settings_symbols_user_groups_default} component={DefaultSymbolPacks}/>
			<Route path={paths.settings_symbols_user_groups_groupId} component={SymbolPacks}/>
			<Route path={paths.settings_symbols_user_groups} component={SymbolGroups}/>
			<Redirect to={paths.settings_symbols_user_groups}/>
		</Switch>
	);
};

export default UserSymbolsRouter;
