import {CostCenterCreationResultApiDto} from "./restModels";
import SimproApiError from "./SimproApiError";

export default class CostCenterCreationResult {
	constructor(
		public readonly errors: SimproApiError[],
		public readonly id: string
	) {}

	public static fromJson(json: CostCenterCreationResultApiDto): CostCenterCreationResult {
		const errors = json.unhandleableErrors.map(error => SimproApiError.fromJson(error));
		return new CostCenterCreationResult(errors, json.id);
	}
}
