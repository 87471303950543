import React, {useState} from "react";
import "./treeSection.scss";
import {useTranslation} from "react-i18next";
import OptionsDropdown from "../../../../components/ui/OptionsDropdown";
import DropdownItem from "../../../../components/ui/DropdownItem";
import {DeletedTreeElementModals} from "../../../project-drawings/features/editor/models/enums";
import DeleteSectionModal from "./DeleteSectionModal";
import AssignToExistingSectionModal from "./AssignToExistingSectionModal";
import {AssignToNewSectionModal} from "./AssignToNewSectionModal";

interface ITreeSectionHeaderProps {
	label: string
	isChecked: boolean
	onChange: () => void
	isDeleted: boolean
	sectionId: string
}

const TreeSectionHeader: React.FC<ITreeSectionHeaderProps> = ({label, isChecked, onChange, isDeleted, sectionId}) => {
	const [modal, setModal] = useState<DeletedTreeElementModals>(DeletedTreeElementModals.NONE)

	const {t} = useTranslation();

	const openDeleteSectionModal = () => {
		setModal(DeletedTreeElementModals.DELETE)
	}

	const openAssignToExistingSectionModal = () => {
		setModal(DeletedTreeElementModals.EXISTING)
	}

	const openAssignToNewSectionModal = () => {
		setModal(DeletedTreeElementModals.NEW)
	}

	const onCloseModal = () => {
		setModal(DeletedTreeElementModals.NONE)
	}

	return (
		<>
			{modal === DeletedTreeElementModals.DELETE &&
				<DeleteSectionModal deletedSectionId={sectionId} onClose={onCloseModal}/>
			}
			{modal === DeletedTreeElementModals.EXISTING &&
				<AssignToExistingSectionModal deletedSectionId={sectionId} onClose={onCloseModal}/>
			}
			{modal === DeletedTreeElementModals.NEW &&
				<AssignToNewSectionModal deletedSectionId={sectionId} onClose={onCloseModal}/>
			}
			<div className="tree-section_header">
				<input
					type={"checkbox"}
					checked={isChecked}
					onChange={onChange}
				/>
				<div className={"tree-section_header_container"}>
				<span className={"tree-section_header_container_label"}>
					{isDeleted &&
						<span className={"tree-section_header_container_label_deleted"}>
							{`${t("projects.details.tree.section.labelDeleted")}: `}
						</span>}
					{label}
				</span>
					{!isDeleted && ` (${t("projects.details.tree.section.label")})`}
				</div>
			</div>
			{isDeleted && <OptionsDropdown>
				<DropdownItem label={t("projects.details.tree.section.deletedOptions.assignToNew")}
							  onClick={openAssignToNewSectionModal}/>
				<DropdownItem label={t("projects.details.tree.section.deletedOptions.assignToExisting")}
							  onClick={openAssignToExistingSectionModal}/>
				<DropdownItem label={t("projects.details.tree.section.deletedOptions.deleteCostCenter")}
							  onClick={openDeleteSectionModal}/>
			</OptionsDropdown>}
		</>
	);
};


export default TreeSectionHeader
