import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorC: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M16.0002,11.99988 C16.0002,14.2101737 14.2092306,15.99988 12.0002,15.99988 C9.79116937,15.99988 8.0002,14.2101737 8.0002,11.99988 C8.0002,9.79084937 9.79116937,7.99988 12.0002,7.99988 C14.2092306,7.99988 16.0002,9.79084937 16.0002,11.99988"]}/>
	);
};

export default AnchorC
