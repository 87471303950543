import React, {useEffect, useState} from "react";
import "./settingsSymbols.scss";
import SwitchComponent from "../../../components/ui/Switch/SwitchComponent";
import {OnFailure} from "../../../utils/CallbackUtils";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {SymbolSystemPack} from "../../../models/SymbolSystemPack";
import {pages, useNavigator} from "../../navigator";

interface SymbolSystemPacksTableProps {
	symbolPack: SymbolSystemPack;
	onSystemPackVisibilityChange: (enabled: boolean, onFailure: OnFailure) => void,
	enabledSystemPackIds: string[],
	disabled?: boolean;
}

const SymbolSystemPacksTableRow: React.FC<SymbolSystemPacksTableProps> = ({
	symbolPack,
	onSystemPackVisibilityChange,
	enabledSystemPackIds,
	disabled
}) => {

	const isChecked = (systemPackIds: string[]): boolean => {
		return systemPackIds.some(id => id === symbolPack.id);
	};

	const isCheckedRef = useUpdatedRef(isChecked);

	const [checked, setChecked] = useState(isChecked(enabledSystemPackIds));
	const {navigateTo} = useNavigator()

	useEffect(() => {
		const {current: isChecked} = isCheckedRef;
		setChecked(isChecked(enabledSystemPackIds));
	}, [enabledSystemPackIds, isCheckedRef]);

	const onRowClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, packId: string) => {
		// this condition prevents double action on toggle button click (onRowClick should not be called then)
		if ((event.target as HTMLElement).tagName === "TD") {
			navigateTo(pages.defaultSymbolsPreviewPage(packId))
		}
	};

	const onSwitch = (value: boolean) => {
		setChecked(value);
		onSystemPackVisibilityChange(value, () => setChecked(!value));
	};

	return (
		<tr onClick={(event) => onRowClick(event, symbolPack.id)}
			className="settings-symbols_table-row with-actions">
			<td>{symbolPack.industry}</td>
			<td>{symbolPack.group}</td>
			<td>{symbolPack.name}</td>
			<td>
				<SwitchComponent checked={checked}
								 onSwitch={onSwitch}
								 disabled={disabled}/>
			</td>
		</tr>
	);
};

export default SymbolSystemPacksTableRow;
