import {useSelector, useStore} from "react-redux";
import {RootState} from "../features/project-drawings/features/editor/editorStore";
import NotificationService from "../services/NotificationService";
import {useTranslation} from "react-i18next";
import {selectScaleConfig} from "../features/project-drawings/features/editor/features/scale/scaleSlice";

export function useSaveDrawingValidator() {
	const scaleConfig = useSelector(selectScaleConfig)
	const scale = scaleConfig.scale;
	const scaleInfoPosition = scaleConfig.scaleInfoState.position

	const scaleIsSet = scale !== null && scaleInfoPosition !== null
	const {t} = useTranslation();
	const store = useStore<RootState>()

	function drawingHasMeasurements(): boolean {
		const storeData = store.getState().undoGroup.present;
		return (
			storeData.count.countGroups.length > 0 ||
			storeData.length.lengths.length > 0 ||
			storeData.area.areas.length > 0 ||
			storeData.pen.penGroups.length > 0 ||
			storeData.text.textGroups.length > 0 ||
			storeData.image.images.length > 0
		);
	}

	function validateSaveDrawing(canSaveCallback?: () => void, cannotSaveCallback?: () => void) {
		if (drawingHasMeasurements() && !scaleIsSet) {
			NotificationService.errorNotification(
				t("common.error"),
				t("editor.saveDrawingValidator.errorDesc")
			)
			cannotSaveCallback?.()
		}
		else {
			canSaveCallback?.()
		}
	}

	return {validateSaveDrawing}
}