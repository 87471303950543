import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import "./editorPlanPageResetModal.scss"
import {PlanUploadData, PlanUploadPageData} from "../../types";
import {useParams} from "react-router-dom";
import {useProjectDetailContext} from "../../../../providers/ProjectDetailProvider";
import NotificationService from "../../../../../../services/NotificationService";
import LoggerService from "../../../../../../services/LoggerService";
import InlineSpinner from "../../../../../../components/InlineSpinner";
import {resetPlanUploadStoreInstance} from "../../planUploadStore";
import {DEFAULT_PDF_RASTERIZATION_DPI, getPlanPagesToUpload} from "../../../../utils/planTemplateUtils";

type EditorPlanPageResetModalProps = {
	onClose: () => void,
	planTemplate: PlanUploadData,
	planPage: PlanUploadPageData,
}

const EditorPlanPageResetModal: React.FC<EditorPlanPageResetModalProps> = ({onClose, planTemplate, planPage}) => {
	const {t} = useTranslation();
	const {projectId} = useParams<{ projectId: string }>();
	const {planUploadData: {updateEditorPlanPage}} = useProjectDetailContext()
	const [isResetting, setIsResetting] = useState<boolean>(false)

	const onConfirm = async () => {
		setIsResetting(true)
		try {
			const rawPage = await getRawPage()
			NotificationService.successNotification(
				t("common.success"),
				t("projects.details.planTemplates.modals.resetEditorPlanPageModal.resetSuccessDesc")
			)
			updateEditorPlanPage(rawPage)
			resetPlanUploadStoreInstance(planPage.pageNumber)
			onClose();
		}
		catch (e) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.modals.resetEditorPlanPageModal.resetErrorDesc"));
			LoggerService.logError(e);
		}
		finally {
			setIsResetting(false)
		}
	}

	const getRawPage = async () => {
		const pages = await getPlanPagesToUpload(projectId, planTemplate.isPdf, planTemplate.id, planTemplate.attachment.id,
			[planPage.pageNumber], planTemplate.dpi ?? DEFAULT_PDF_RASTERIZATION_DPI)
		const rawPage = pages[0];
		rawPage.title = planPage.title
		return rawPage;
	}

	return (
		<ModalWithHeader customClass={"editor-plan-page-reset-modal"} isOpen={true}
						 title={t("projects.details.planTemplates.modals.resetEditorPlanPageModal.title")}
						 onClose={onClose}>
			<div className={"editor-plan-page-reset-modal_content"}>
				<div className={"editor-plan-page-reset-modal_content_message"}>
					{t("projects.details.planTemplates.modals.resetEditorPlanPageModal.message")}
				</div>
				<ModalButtons>
					{isResetting && <InlineSpinner/>}
					<Button disabled={isResetting} label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
					<Button disabled={isResetting} label={t("common.buttons.ok")}
							variant={"primary"}
							onClick={onConfirm}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	);
};

export default EditorPlanPageResetModal;
