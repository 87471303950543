import * as Redux from "redux";
import {planUploadViewActions} from "../features/view/planUploadViewSlice";
import {planUploadPenActions} from "../features/pen";
import {planUploadTextActions} from "../features/text/planUploadTextSlice";
import {planUploadImageActions} from "../features/image/planUploadImageSlice";
import {planUploadEraseAreaActions} from "../features/erase-area/planUploadEraseAreaSlice";
import {planUploadClipActions} from "../features/clip/planUploadClipSlice";
import {planUploadScaleActions} from "../features/scale/planUploadScaleSlice";

const dirtableActionTypeSet = new Set([
	planUploadScaleActions.addScalePoint.type,

	planUploadPenActions.addPenGroup.type,
	planUploadPenActions.deletePenGroup.type,
	planUploadPenActions.addPenLine.type,
	planUploadPenActions.deletePenLine.type,
	planUploadPenActions.changePenStyle.type,

	planUploadTextActions.addTextGroup.type,
	planUploadTextActions.addTextElement.type,
	planUploadTextActions.updateTextElementPosition.type,
	planUploadTextActions.transformTextElement.type,
	planUploadTextActions.changeTextStyle.type,
	planUploadTextActions.saveTextItem.type,
	planUploadTextActions.deleteTextItem.type,
	planUploadTextActions.editTextItem.type,
	planUploadTextActions.deleteTextGroup.type,

	planUploadImageActions.addImage.type,
	planUploadImageActions.transformImage.type,
	planUploadImageActions.deleteImage.type,

	planUploadEraseAreaActions.confirmEraseArea.type,

	planUploadClipActions.confirmClipArea.type,
])

export const planUploadEditorIsDirtyMarker: Redux.Middleware = store => next => action => {
	const {dispatch} = store;

	if (dirtableActionTypeSet.has(action.type)) {
		dispatch(planUploadViewActions.setEditorIsDirty(true))
	}

	next(action)
}
