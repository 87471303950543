import React, {useMemo, useReducer} from "react";
import {SyncMaterialsDataProvider, useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";
import ModalWithHeader from "../../../../../components/modal/ModalWithHeader";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {DrawingType, ProjectSource, Status} from "../../../../../models/enums";
import {useTranslation} from "react-i18next";
import Button from "../../../../../components/ui/Button";
import {getFilters} from "../../../../../utils/ColorChanger";
import {colorHexToRGB} from "../../../../../utils/TextUtils";
import {Colors} from "../../../../../styles";
import IconDownload from "../../../../../components/icons/IconDownload";
import Spinner from "../../../../../components/Spinner";
import TreeSectionSummary from "./TreeSectionSummary";
import DataFetchError from "../../../../../components/ui/DataFetchError";
import "./syncMaterialsModal.scss";
import {apiInstance} from "../../../../../api/api";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import ProjectDetails from "../../../../../models/ProjectDetails";
import {SyncMaterialsModalData} from "../types";
import {SyncStageModals} from "./SyncStageModals";


function SyncMaterialsModalContent({project}: {
	project: ProjectDetails
}) {
	const {
		takeoffSections,
		fitTimes,
		syncStageModals,
		selectionState,
		syncMaterials,
		clearResult,
		result: {status}
	} = useSyncMaterialsContext()
	const {t} = useTranslation();

	const updateButtonLabel = useMemo(() => {
		if (project) {
			if (project.source === ProjectSource.JOB) {
				return status === Status.LOADING ?
					t(`projects.summary.updateButton.job.updating`) :
					t(`projects.summary.updateButton.job.update`)
			}
			else {
				return status === Status.LOADING ?
					t(`projects.summary.updateButton.quote.updating`) :
					t(`projects.summary.updateButton.quote.update`)
			}
		}
		else {
			return status === Status.LOADING ?
				t(`projects.summary.updateButton.general.updating`) :
				t(`projects.summary.updateButton.general.update`)
		}
	}, [status, project, t])

	const translations = {
		"message": {
			[ProjectSource.QUOTE]: t(`projects.summary.projectTreeSummaryModal.quote.message`),
			[ProjectSource.JOB]: t(`projects.summary.projectTreeSummaryModal.job.message`)
		}
	}

	const canUpdateProject = selectionState.selectedCostCenters.length > 0 && status !== Status.LOADING

	function onStatusModalClose() {
		syncStageModals.closeModal()
		selectionState.reset()
		clearResult()
	}

	async function handleSimproUpdate() {
		const costCenters = selectionState.selectedCostCenters

		syncMaterials(project.id, costCenters, fitTimes)
		syncStageModals.openStatusModal({
			onClose: onStatusModalClose,
			onTryAgain: handleSimproUpdate,
			onShowError: () => {
				syncStageModals.openErrorModal({onClose: syncStageModals.closeModal})
			}
		})
	}

	const isPopupRequired = selectionState.selectedCostCenters.length > 1 && status !== Status.LOADING;

	const handleUpdateButtonClick = () => {
		const onSubmitFunc = isPopupRequired ? handlePopupProceed : handleSimproUpdate;
		const data = {
			projectSource: project.source,
			onSubmit: onSubmitFunc,
			onClose: syncStageModals.closeModal,
			isSyncOptionsModelLoading: isPopupRequired
		};
		syncStageModals.openConfirmationModal(data);
	};

	async function handlePopupProceed() {
		syncStageModals.closeModal();
		syncStageModals.openConfirmationModal({
			projectSource: project.source,
			onSubmit: handleSimproUpdate,
			onClose: syncStageModals.closeModal,
			isSyncOptionsModelLoading: false
		});
	};

	return (<>
		<SyncStageModals/>
		<div className={"project-summary-wrapper-modal_header"}>
			<span className={"project-summary-wrapper-modal_header_message-text"}>
				{translations.message[project.source]}
			</span>
			<div className={"project-summary-wrapper-modal_header_button-container"}>
				<Button
					label={updateButtonLabel}
					disabled={!canUpdateProject}
					variant={"primary"}
					onClick={handleUpdateButtonClick}
				/>
				<div className={"project-summary-wrapper-modal_header_button-container_icon-download"}>
					<span style={{filter: getFilters(colorHexToRGB(Colors.LIGHTERTEXT), true)}}>
						<IconDownload/>
					</span>
				</div>
			</div>
		</div>
		<div className={"project-summary-wrapper-modal_content"}>
			<div className={"project-summary-wrapper-modal_content_materials"}>
				<div className={"project-summary-wrapper-modal_content_materials_title"}>
					{t("projects.summary.projectTreeSummaryModal.materials")}
				</div>
				<div className="project-summary-wrapper-modal_content_materials_tree">
					{takeoffSections.map((section) => (
						<TreeSectionSummary
							key={section.id}
							section={section}
						/>
					))}
				</div>
			</div>
		</div>
	</>)
}

type State =
	| { status: Status.LOADING }
	| { status: Status.ERROR }
	| { status: Status.SUCCESS, data: SyncMaterialsModalData }

type Action =
	| { type: 'DATA_FETCH_SUCCESS', payload: SyncMaterialsModalData }
	| { type: 'DATA_FETCH_ERROR' }

/**
 * Note: SyncMaterialsModal displays only DrawingType.TAKE_OFF drawings
 */
function SyncMaterialsModal({onClose}: {
	onClose: () => void
}) {
	const {projectDetails: {project}, fitTimesData} = useProjectDetailContext()
	const {fitTimes, loadStatus: fitTimesLoadStatus} = fitTimesData
	const {t} = useTranslation();

	const [state, dispatch] = useReducer((state: State, action: Action): State => {
		switch (action.type) {
			case "DATA_FETCH_ERROR":
				return {status: Status.ERROR}
			case "DATA_FETCH_SUCCESS":
				return {status: Status.SUCCESS, data: action.payload}
			default:
				return state;
		}
	}, {status: Status.LOADING});

	const dataRef = useUpdatedRef({onClose})
	React.useEffect(() => {
		const {onClose} = dataRef.current

		const fetchTakeoffSections = async function(projectId: string) {
			const [takeoffSections] = await Promise.all([
				await apiInstance.projectsApi.fetchTree(projectId, DrawingType.TAKE_OFF),
			])
			dispatch({
				type: "DATA_FETCH_SUCCESS",
				payload: {takeoffSections, fitTimes}
			})
		}

		if (fitTimesLoadStatus === Status.LOADING) {
			return;
		}
		if (project?.id && fitTimesLoadStatus === Status.SUCCESS) {
			fetchTakeoffSections(project.id).catch(e => {
				onClose();
				dispatch({type: "DATA_FETCH_ERROR"})
				LoggerService.logError(e)
				NotificationService.errorNotification(t("common.error"), t("projects.summary.fetchErrorDesc"))
			})
		}
		else {
			onClose()
		}
	}, [project?.id, t, dataRef, fitTimes, fitTimesLoadStatus])

	const translations = {
		"title": {
			[ProjectSource.QUOTE]: t(`projects.summary.projectTreeSummaryModal.quote.title`),
			[ProjectSource.JOB]: t(`projects.summary.projectTreeSummaryModal.job.title`)
		}
	}

	if (!project) {
		return null;
	}
	return (
		<ModalWithHeader
			customClass={"project-summary-wrapper-modal"}
			title={translations.title[project.source]}
			isOpen={true} onClose={onClose}
		>
			{state.status === Status.LOADING ? <Spinner/> : null}
			{state.status === Status.ERROR ? <DataFetchError/> : null}
			{state.status === Status.SUCCESS ? (
				<SyncMaterialsDataProvider data={state.data}>
					<SyncMaterialsModalContent project={project}/>
				</SyncMaterialsDataProvider>
			) : null}
		</ModalWithHeader>
	)
}

export {SyncMaterialsModal}