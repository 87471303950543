import React, {createContext, useEffect, useState} from "react";
import User from "../models/User";
import {apiInstance} from "../api/api";
import {Status} from "../models/enums";
import LoggerService from "../services/LoggerService";
import Company from "../models/Company";
import Spinner from "../components/Spinner";
import DataFetchError from "../components/ui/DataFetchError";
import {useTranslation} from "react-i18next";
import {USER_REFRESH_INTERVAL} from "../features/project-drawings/features/editor/constants";

export interface MeContextData {
	user: User | undefined,
	company: Company | undefined,
}

const MeDataContext = createContext<MeContextData | undefined>(undefined)

const MeProvider: React.FC = ({children}) => {

	const {t} = useTranslation();
	const [errorKey, setErrorKey] = useState<string>(t("init.initFetchError"))
	const [user, setUser] = useState<User | undefined>();
	const [company, setCompany] = useState<Company | undefined>();
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);

	const handleError = (err: any) => {
		// Do not display error for 401, interceptor will to simPRO to login into the application
		if (err?.response?.status !== 401) {
			setLoadStatus(Status.ERROR)
			LoggerService.logError(err)
		}
	}

	useEffect(() => {
		setLoadStatus(Status.LOADING)
		apiInstance.usersApi.fetchMe()
			.then(user => {
				apiInstance.companiesApi.fetchCompany(user.companyId)
					.then(company => {
						setUser(user)
						setCompany(company)
						setLoadStatus(Status.SUCCESS)
					})
					.catch(handleError)
			})
			.catch(handleError)
	}, [])

	useEffect(() => {
		const fetchUserInterval = setInterval(() => {
			apiInstance.usersApi.fetchMe()
				.then(receivedUser => {
					if (receivedUser.userId !== user?.userId && receivedUser.simproUserId !== user?.simproUserId) {
						setUser(undefined)
						setCompany(undefined)
						setErrorKey(t("init.sessionExpiredError"))
						setLoadStatus(Status.ERROR)
					}
				})
				.catch(handleError)
		}, USER_REFRESH_INTERVAL * 60 * 1000);
		return () => {
			clearTimeout(fetchUserInterval);
		}
	}, [user, t])

	useEffect(() => {
		LoggerService.setLoggerUser(user)
	}, [user]);

	return (
		<>
			{loadStatus === Status.LOADING ? <Spinner/> : null}
			{loadStatus === Status.SUCCESS ?
				<MeDataContext.Provider value={{user, company}}>{children}</MeDataContext.Provider>
				: null}
			{loadStatus === Status.ERROR ? <DataFetchError message={errorKey}/> : null}
		</>
	);
};

function useMeDataContext() {
	const context = React.useContext(MeDataContext)
	if (!context) {
		throw new Error("useMeDataContext must be used within a MeProvider")
	}
	return context
}

const MeDataContextBridge = {
	Consumer: MeDataContext.Consumer,
	Provider: MeDataContext.Provider
}

export {MeProvider, useMeDataContext, MeDataContextBridge}
