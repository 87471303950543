import {AxiosInstance} from "axios";
import {PaginatedResponse} from "../models/interfaces";
import {getPaginationFromHeaders} from "../utils/PaginationUtils";
import {CatalogItem} from "../models/CatalogItem";
import {CatalogItemGroup} from "../models/CatalogItemGroup";
import {paramsSerializer} from "../utils/ApiUtils";
import {CatalogItemUpdateApiDto} from "../models/restModels";

export const PREBUILD_QUERY_KEY = "PREBUILD_QUERY_KEY"

export default class PreBuildsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchPreBuilds(
		page: number,
		size: number,
		onlyFavourites: boolean,
		query?: string,
		groupId?: number | null
	): Promise<PaginatedResponse<CatalogItem>> {
		const params = {page, size, query, group_id: groupId, is_favorite: onlyFavourites ? true : undefined};
		const url = `/api/prebuilds`;
		return this.axiosInstance.get(url, {params, paramsSerializer}).then(resp => {
			const paginationConfig = getPaginationFromHeaders(resp.headers);
			const records = resp.data.map(CatalogItem.fromJson);
			return ({records, ...paginationConfig})
		})
	}

	putPreBuildsAttributes(items: CatalogItemUpdateApiDto[]) {
		const url = `/api/prebuilds_attributes`
		const data = {
			prebuildsAttributes: items
		}
		return this.axiosInstance.put(url, data)
	}

	fetchPreBuildGroups(parentGroupId?: number | null): Promise<CatalogItemGroup[]> {
		const params = {parent_group_id: parentGroupId};
		const url = `/api/prebuild_groups`;
		return this.axiosInstance.get(url, {params}).then(resp => resp.data.map(CatalogItemGroup.fromJson));
	}
}
