import React, {ChangeEvent, KeyboardEventHandler, PropsWithChildren, useEffect, useState} from "react";
import "./numberInput.scss";
import {getId} from "../../utils";

interface NumberInputProps {
	label?: string,
	value: number | undefined,
	placeholder?: string,
	min?: number,
	onFocus?: () => void,
	onChange: (value: number | undefined) => void,
	onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
	isError?: boolean,
}

const NumberInput: React.FC<PropsWithChildren<NumberInputProps>> = (props) => {
	const [id] = useState(getId());
	const [displayedValue, setDisplayedValue] = useState<string | undefined>(props.value?.toString())

	useEffect(() => {
		if (props.value === undefined) {
			setDisplayedValue("")
		}
	}, [props.value])

	const onChange = (evt: ChangeEvent<HTMLInputElement>) => {
		const value = evt.target.value;
		if (value === "") {
			props.onChange(undefined);
			setDisplayedValue("")
		}
		else if (isNaN(Number(value)) || value[0] === ".") {
			return;
		}
		else if (props.min !== undefined && Number(value) < props.min) {
			props.onChange(props.min)
			setDisplayedValue(props.min.toString())
		}
		else {
			props.onChange(Number(value));
			setDisplayedValue(value)
		}
	};

	return (
		<label htmlFor={id} className="number-input">
			<span>{props.label}</span>
			<input
				className={props.isError ? "number-input_error" : undefined} type="text"
				placeholder={props.placeholder}
				onKeyDown={props.onKeyDown}
				value={displayedValue ?? ""}
				id={id}
				onChange={onChange}
				onFocus={() => {props.onFocus?.();}}
			/>
		</label>
	);
};

export default NumberInput;
