import {useTranslation} from "react-i18next";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import React, {useEffect, useMemo, useState} from "react";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import DrawingNameInput from "../../../../project-drawings/features/editor/components/DrawingNameInput";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import "./modalContent.scss";

export function BaseCopyVersionModal({initialName, title, isLoading, onClose, onSave, onSaveAndOpen}: {
	initialName: string
	title: string
	isLoading: boolean
	onClose: () => void
	onSave: (name: string, sectionId?: string, ccId?: string) => void
	onSaveAndOpen: (name: string, sectionId?: string, ccId?: string) => void
}) {
	const {t} = useTranslation();
	const {isServiceProject, treeData: {sections}} = useProjectDetailContext()
	const [name, setName] = useState<string>(initialName);
	const [sectionId, setSectionId] = useState<string | undefined>(undefined);
	const [ccId, setCcId] = useState<string | undefined>(undefined);
	const saveDisabled: boolean = !(name && ccId);

	useEffect(() => {
		setSectionId(isServiceProject ? sections[0]?.id : undefined)
	}, [sections, isServiceProject]);

	const sectionsToSelect = useMemo(() => {
		return sections
			.filter(section => !section.deletedExternal)
			.map(s => ({label: s.getLabel(), value: s.id}))
	}, [sections])

	const costCentersToSelect = useMemo(() => {
		return sections
			.filter(section => !section.deletedExternal)
			.find(s => s.id === sectionId)?.costCenters
			.filter(cc => !cc.deletedExternal)
			.map(cc => ({label: cc.name, value: cc.id})) || [];
	}, [sectionId, sections])

	const handleSectionChange = (sectionId: string) => {
		setSectionId(sectionId);
		setCcId(undefined);
	};

	return (
		<EditValueModal
			isOpen={true}
			onClose={onClose}
			onSave={() => { onSave(name, sectionId, ccId); }}
			onSaveAndOpen={() => { onSaveAndOpen(name, sectionId, ccId) }}
			title={title}
			disableSave={saveDisabled}
			isLoading={isLoading}
		>
			<div className="modal-content">
				<div className="modal-content_field">
					<div className="modal-content_field_label">
						{t("projects.details.copyDrawingModal.nameLabel")}
					</div>
					<DrawingNameInput value={name} onChange={setName}/>
				</div>
				{!isServiceProject ?
					<div className="modal-content_field">
						<div className="modal-content_field_label">
							{t("projects.details.createNewDrawingModal.sectionLabel")}
						</div>
						<CustomSelect
							placeholder={t("projects.details.copyDrawingModal.sectionPlaceholder")}
							customClass="modal-content_field_select"
							items={sectionsToSelect}
							onChange={handleSectionChange}
							value={sectionId}
						/>
					</div> : null
				}
				<div className="modal-content_field">
					<div className="modal-content_field_label">
						{t("projects.details.copyDrawingModal.ccLabel")}
					</div>
					<CustomSelect
						placeholder={t("projects.details.copyDrawingModal.ccPlaceholder")}
						customClass="modal-content_field_select"
						items={costCentersToSelect}
						onChange={setCcId}
						value={ccId}
					/>
				</div>
			</div>
		</EditValueModal>
	)
}