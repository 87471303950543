import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../../../styles";
import {getId} from "../../../../../../utils";
import {PlanUploadEditorTool, PlanUploadToolHookResult} from "../../types";
import {distance} from "../../../../../project-drawings/features/editor/utils";
import {planUploadClipActions, selectClipSelectionState} from "./planUploadClipSlice";
import {useBaseSelection} from "../../hooks/useBaseSelection";

function usePlanUploadClip(): PlanUploadToolHookResult {
	const [renderId] = useState(() => getId())

	const clipSelection = useSelector(selectClipSelectionState)
	const dispatch = useDispatch()

	const {render, onMouseUp, onMouseMove} = useBaseSelection(
		clipSelection,
		vertices => {
			dispatch(planUploadClipActions.setSelection({
				x: vertices.topLeft.x,
				y: vertices.topLeft.y,
				height: distance(vertices.topLeft, vertices.bottomLeft),
				width: distance(vertices.topLeft, vertices.topRight)
			}))
		},
		Colors.GREEN
	)

	return {
		id: renderId,
		render,
		tool: PlanUploadEditorTool.CLIP,
		callbacks: {
			onMouseUp,
			onMouseMove,
		}
	}
}

export {usePlanUploadClip}