import {get} from "../../../../../utils/ClassNameUtils";
import React from "react";
import {Breadcrumb} from "./AttachmentSelectModal";

type BreadcrumbsProps = {
	breadcrumbs: Breadcrumb[]
	onBreadcrumbClick(breadcrumb: Breadcrumb): void
}

export function Breadcrumbs({breadcrumbs, onBreadcrumbClick}: BreadcrumbsProps) {
	return (
		<div>
			{breadcrumbs.map((breadcrumb, index) => {
				const last = index === breadcrumbs.length - 1
				return (
					<span key={breadcrumb.folderId ?? "root"}>
							<span className={get({"link": !last})}
								  onClick={() => {!last && onBreadcrumbClick(breadcrumb)}}>
								{breadcrumb.name}
							</span>
						{(!last) && <span> / </span>}
						</span>
				);
			})}
		</div>
	)
}
