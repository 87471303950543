import React from "react";
import {useBreadcrumbsContext} from "../providers/BreadcrumbsProvider";

interface DrawingDetailsBreadcrumbProps {
	drawingId: string,
}

const DrawingDetailsBreadcrumb: React.FC<DrawingDetailsBreadcrumbProps> = ({drawingId}) => {
	const {drawingData} = useBreadcrumbsContext()
	if (drawingData === undefined) {
		return <span>...</span>
	}
	const {drawingDetails} = drawingData

	return (
		<span>
			{drawingDetails?.id === drawingId ? drawingDetails?.name : null}
		</span>
	);
};

export default DrawingDetailsBreadcrumb
