import React from "react";
// reused stylesheet
import "./commonDropdown.scss";
import {DropdownContextBridge} from "./CommonDropdown";
import Button from "./Button";
import DropdownArrow from "./DropdownArrow";
import {usePopper} from "../popper";
import {get} from "../../utils/ClassNameUtils";

type ButtonWithDropdownProps = {
	label: string,
	onClick: () => void,
	disabled?: boolean;
}

const ButtonWithDropdown: React.FC<ButtonWithDropdownProps> = ({children, label, onClick, disabled}) => {
	const {renderPopper, isPopperOpen, closePopper} = usePopper({placement: "bottom-end", disabled})

	return (
		<div className="dropdown flex">
			<Button label={label}
					onClick={onClick}
					variant={"primary"}
					disabled={disabled}/>
			{renderPopper({
				target: (
					<div className={get("dropdown_with_button", {"disabled": !!disabled})}>
						<DropdownArrow variant={"primary"}/>
					</div>
				),
				content: (
					<nav className={`dropdown_menu ${isPopperOpen ? 'active' : 'inactive'}`}>
						<DropdownContextBridge.Provider value={{onClose: closePopper}}>
							<ul>
								{children}
							</ul>
						</DropdownContextBridge.Provider>
					</nav>
				)
			})}
		</div>
	);
};

export default ButtonWithDropdown;
