import React from "react";
import {TableColumnConfig} from "../../../../../../../../models/interfaces";
import TableHeaderCell from "../../../../../../../../components/layout/table/TableHeaderCell";
import {useTranslation} from "react-i18next";
import TableNoRecords from "../../../../../../../../components/layout/table/TableNoRecords";
import {CatalogItemGroup} from "../../../../../../../../models/CatalogItemGroup";
import GroupsTableRow from "./GroupsTableRow";

interface GroupsTableProps {
	items: CatalogItemGroup[]
	checkedItemId: number | undefined,
	onItemClick: (checkedGroup: CatalogItemGroup) => void,
}

/** Common for catalog items and prebuilds **/
const GroupsTable: React.FC<GroupsTableProps> = ({items, checkedItemId, onItemClick}) => {
	const {t} = useTranslation();

	const columns: TableColumnConfig<CatalogItemGroup>[] = [
		{
			id: "name",
			label: t("projects.addItemModal.catalogGroupsTable.groupName"),
			sortable: false
		}
	];

	return (
		<div className="material-modal_container_content_table-container groups">
			<table className="table">
				<thead>
				<tr>
					{columns.map(
						col => <TableHeaderCell<CatalogItemGroup> key={col.id}
																  id={col.id}
																  label={col.label}
																  width={col.width}/>)}
				</tr>
				</thead>
				<tbody>
				{items.length ?
					items.map(item =>
						<GroupsTableRow key={item.id}
										item={item}
										checked={item.id === checkedItemId}
										onClick={onItemClick}/>)
					: <TableNoRecords cols={1}/>}
				</tbody>
			</table>
		</div>

	);
};

export default GroupsTable;
