import React, {useMemo, useState} from "react";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import {useTranslation} from "react-i18next";
import "./createCostCenterModal.scss"
import Button from "../../../../components/ui/Button";
import ModalButtons from "../../../../components/modal/ModalButtons";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import CustomSelect from "../../../../components/ui/CustomSelect";
import Section from "../../../../models/Section";
import {ProjectSource} from "../../../../models/enums";
import {CreateCostCenterField} from "./CreateCostCenterField";
import {useCreateCostCenter} from "../../hooks";
import InlineSpinner from "../../../../components/InlineSpinner";
import NotificationService from "../../../../services/NotificationService";
import LoggerService from "../../../../services/LoggerService";

type CreateCostCenterModalProps = {
	onClose: () => void
	sections: Section[]
	initialSectionId?: string
}

export const CreateCostCenterModal: React.FC<CreateCostCenterModalProps> = ({onClose, sections, initialSectionId}) => {
	const {t} = useTranslation()
	const {projectDetails: {project}, isServiceProject} = useProjectDetailContext()

	const [costCenterName, setCostCenterName] = useState<string>("")
	const [sectionId, setSectionId] = useState<string | undefined>(isServiceProject && sections.length > 0 ? sections[0].id : initialSectionId)
	const [companyCostCenterId, setCompanyCostCenterId] = useState<number | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const {createCostCenter} = useCreateCostCenter();
	const {forceTreeUpdate} = useProjectDetailContext()

	const onSave = () => {
		if (project && sectionId && companyCostCenterId) {
			setIsLoading(true)
			createCostCenter(project.source === ProjectSource.JOB, project.id, sectionId, costCenterName, companyCostCenterId)
				.then(() => {
					forceTreeUpdate()
					onClose()
					NotificationService.successNotification(
						t("common.success"),
						t("projects.details.createNewCCModal.creationSuccessDesc")
					)
				}).catch((error) => {
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.createNewCCModal.creationErrorDesc")
				)
				LoggerService.logError(error)
			})
				.finally(() => {
					setIsLoading(false)
				})
		}
	}

	const onCostCenterNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setCostCenterName(evt.target.value)
	}

	const handleSectionChange = (sectionId: string) => {
		setSectionId(sectionId);
	};

	const handleCompanyCostCenterChange = (ccId: number) => {
		setCompanyCostCenterId(ccId);
	};

	const sectionsToSelect = useMemo(() => {
		return sections
			.filter(section => !section.deletedExternal)
			.map(s => ({label: s.getLabel(), value: s.id}))
	}, [sections])

	return (
		<ModalWithHeader customClass={"create-cost-center-modal"} isOpen={true}
						 title={t("projects.details.createNewCCModal.title")} onClose={onClose}>
			<div className={"create-cost-center-modal_container"}>
				{!isServiceProject &&
					<>
						<span className={"create-cost-center-modal_container_label"}>
							{t("projects.details.createNewCCModal.sectionSelectionLabel")}
						</span>
						<div className={"create-cost-center-modal_container_field"}>
							<CustomSelect placeholder={t("projects.details.createNewCCModal.sectionPlaceholder")}
										  customClass="create-cost-center-modal_container_field_section-select"
										  items={sectionsToSelect}
										  onChange={handleSectionChange}
										  value={sectionId}/>
						</div>
					</>}
				<CreateCostCenterField
					onCostCenterNameChange={onCostCenterNameChange}
					handleCompanyCostCenterChange={handleCompanyCostCenterChange}
					companyCostCenterId={companyCostCenterId}
					costCenterName={costCenterName}/>
				<div className={"create-cost-center-modal_container_footer"}>
					<ModalButtons>
						{isLoading && <InlineSpinner/>}
						<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
						<Button
							label={t("common.buttons.save")}
							disabled={!project || companyCostCenterId === undefined || sectionId === undefined || isLoading}
							variant={"primary"}
							onClick={onSave}
						/>
					</ModalButtons>
				</div>
			</div>
		</ModalWithHeader>
	)
}