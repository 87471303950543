import {DrawingDataApiDto} from "./restModels";
import {EditorDrawingData} from "../features/project-drawings/features/editor/models/editor";
import Version from "./Version";
import SerializedMeasurement from "./SerializedMeasurement";
import {SchemaMigrator} from "../features/project-drawings/features/editor/features/migration/migrator";

export default class DrawingData {
	constructor(public readonly id: string,
		public readonly data: EditorDrawingData | undefined,
		public readonly locked: boolean,
		public readonly measurements: SerializedMeasurement[],
		public readonly version: Version,
		public readonly planScale: number | undefined) {}

	public static fromJson(json: DrawingDataApiDto): DrawingData {
		const version = Version.fromJson(json.version);
		const measurements = json.measurements.map(SerializedMeasurement.fromJson);
		let schema: EditorDrawingData | undefined
		if (json.data) {
			schema = SchemaMigrator.migrate(json.data)
		}
		return new DrawingData(json.id, schema, json.locked, measurements, version, json.planScale);
	}
}
