import {useSymbolImage} from "../../../../../../project-drawings/features/editor/features/count/useSymbolImage";
import {useUpdatedRef} from "../../../../../../../hooks/useUpdatedRef";
import React, {useEffect, useRef, useState} from "react";
import {Status} from "../../../../../../../models/enums";
import LoggerService from "../../../../../../../services/LoggerService";
import {Image} from "@react-pdf/renderer";
import {CountMeasurementStyle} from "../../../../../../project-drawings/features/editor/models/editor";
import {generateCountSymbolPreviewImageUrl} from "./utils";

type CountSymbolPreviewProps = {
	style: CountMeasurementStyle
}

export function CountSymbolPreview({style}: CountSymbolPreviewProps) {
	const [image, status] = useSymbolImage(style.symbol, style.remoteSymbol)
	const dataRef = useUpdatedRef({image, color: style.color})

	const executorRef = useRef<{
		resolve: (value: string) => void,
		reject: () => void
	}>();

	const [promise] = useState(() =>
		new Promise<string>((resolve, reject) => {
			executorRef.current = {resolve, reject}
		})
	);

	useEffect(() => {
		const {image, color} = dataRef.current

		if (status === Status.SUCCESS) {
			const resolveImageSource = async function() {
				const {imageUrl} = await generateCountSymbolPreviewImageUrl(image!, color)
				executorRef.current?.resolve(imageUrl)
				executorRef.current = undefined;
			}
			resolveImageSource().catch(err => {
				LoggerService.logError(err);
				reject()
			})
		}
		else if (status === Status.ERROR) {
			reject()
		}

		function reject() {
			executorRef.current?.reject()
			executorRef.current = undefined;
		}
	}, [status, executorRef, dataRef])

	return <Image style={{height: 20, width: 20}} src={promise}/>
}