import React from "react";
import TextInput from "./TextInput";
import {removeNotAllowedCharsFromString} from "../../../../../utils/TextUtils";

interface TextInputProps {
	value?: string;
	placeholder?: string;
	onChange: (value: string) => void;
}

const DrawingNameInput: React.FC<TextInputProps> = ({value, onChange, placeholder}) => {

	const handleNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		onChange(removeNotAllowedCharsFromString(evt.target.value));
	};

	return (
		<TextInput value={value}
				   placeholder={placeholder}
				   onChange={handleNameChange}/>
	);
};

export default DrawingNameInput;
