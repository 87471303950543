import React from "react";
import "./pageContent.scss";

const PageContent: React.FC = ({children}) => {
	return (
		<div className={"page-content"}>{children}</div>
	);
};

export default PageContent
