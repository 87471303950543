import React from "react";
import Modal from "./Modal";
import {useTranslation} from "react-i18next";
import Button from "../ui/Button";
import ModalTitle from "./ModalTitle";
import ModalButtons from "./ModalButtons";
import InlineSpinner from "../InlineSpinner";

interface EditValueModalProps {
	isOpen: boolean,
	onClose: () => void,
	onSave: () => void,
	onSaveAndOpen?: () => void,
	title: string,
	saveLabel?: string,
	disableSave?: boolean
	isLoading?: boolean;
}

const EditValueModal: React.FC<EditValueModalProps> = ({
	isOpen,
	onClose,
	onSave,
	title,
	saveLabel,
	disableSave,
	isLoading,
	children,
	onSaveAndOpen
}) => {
	const {t} = useTranslation();

	const saveButtonLabel = saveLabel ? saveLabel : t("common.buttons.save");

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalTitle>{title}</ModalTitle>
			{children}
			<ModalButtons>
				{isLoading && <InlineSpinner/>}
				<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
				<Button label={saveButtonLabel} variant={"primary"} onClick={onSave} disabled={disableSave || isLoading}/>
				{onSaveAndOpen ? <Button label={t("common.buttons.saveAndOpen")} variant={"primary"} onClick={onSaveAndOpen}
										 disabled={disableSave || isLoading}/> : null}
			</ModalButtons>
		</Modal>
	);
};

export default EditValueModal;
