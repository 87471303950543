import React from "react";
import {PlanUploadPageData} from "../types";
import {getPlanUploadStore, getStoreInstance} from "../planUploadStore";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {PlanUploadHiddenEditor} from "../components/PlanUploadHiddenEditor";
import {IsAnyModalOpenProvider} from "../../../../../providers/IsAnyModalOpenProvider";
import {planUploadViewActions} from "../features/view/planUploadViewSlice";
import {planUploadClipActions} from "../features/clip/planUploadClipSlice";
import {planUploadPenActions} from "../features/pen";
import {planUploadTextActions} from "../features/text/planUploadTextSlice";
import {planUploadImageActions} from "../features/image/planUploadImageSlice";
import {planUploadEraseAreaActions} from "../features/erase-area/planUploadEraseAreaSlice";
import {UserSettingsDataContextBridge, useUserSettingsDataContext} from "../../../../../providers/UserSettingsProvider";

export function usePlanUploadExporter() {
	const userSettingsContext = useUserSettingsDataContext();

	async function generateFileFromPlanPage(planPage: PlanUploadPageData): Promise<File> {
		return new Promise<File>((resolve, reject) => {
			const sourceStore = getPlanUploadStore(planPage.pageNumber)
			if (!sourceStore) {
				return reject(new Error('Cannot find sourceStore for given plan page'))
			}

			const store = getStoreInstance();
			store.dispatch(planUploadViewActions.setExportInProgress(true));

			const {dispatch} = store
			const initState = sourceStore.getState()
			const {pen, text, image, clip, eraseArea} = initState.planUploadUndoGroup.present
			const {zoom} = initState.planUploadView
			dispatch(planUploadPenActions.setPenGroups({value: pen.penGroups}))
			dispatch(planUploadTextActions.setTextGroups({value: text.textGroups}))
			dispatch(planUploadImageActions.setImages({value: image.images}))
			dispatch(planUploadClipActions.reset({clipArea: clip.clipArea}))
			dispatch(planUploadEraseAreaActions.reset({erasedAreas: eraseArea.erasedAreas}))
			dispatch(planUploadViewActions.setZoomState(zoom))

			const loadedInfos = image.images.map(image => ({id: image.id, loaded: false}))
			dispatch(planUploadViewActions.setRemoteContentLoadedInfo({
				allItemsLoaded: loadedInfos.length === 0,
				loadedInfos: loadedInfos
			}))

			function handleSuccess(blob: Blob) {
				return resolve(new File([blob], `${planPage.title}.png`))
			}

			try {
				const container = document.createElement("div")
				ReactDOM.render((
					<UserSettingsDataContextBridge.Provider value={userSettingsContext}>
						<IsAnyModalOpenProvider>
							<Provider store={store}>
								<PlanUploadHiddenEditor
									planPage={planPage}
									onSuccess={handleSuccess}
									onFailure={reject}
								/>
							</Provider>
						</IsAnyModalOpenProvider>
					</UserSettingsDataContextBridge.Provider>
				), container)
			}
			catch (e) {
				return reject(e)
			}
		})
	}

	return {
		generateFileFromPlanPage
	}
}