import React from "react";
import {useTranslation} from "react-i18next";
import "./styleSelector.scss";
import ColorPicker from "../../../project-drawings/features/editor/components/ColorPicker";
import {FontFamily} from "../../../project-drawings/features/editor/models/enums";
import {capitalizeString, enumKeyToCapitalizeCase} from "../../../../utils/TextUtils";
import {MinMaxValidatedNumberInput} from "../../../project-drawings/features/editor/components/MinMaxValidatedNumberInput";
import {TextToolStyle} from "../../types";
import {TextAlign, TextStyle} from "../../enums";

interface LineStyleSelectorProps {
	style: TextToolStyle,
	onStyleChange: (style: TextToolStyle) => void,
}

const TextStyleSelector: React.FC<LineStyleSelectorProps> = ({style, onStyleChange}) => {

	const {t} = useTranslation();

	return (
		<div className={"style-selector is-horizontal"}>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.textStyleEditor.fontFace")}</div>
				<select value={style.fontFamily} onChange={event => {
					onStyleChange({
							...style,
							fontFamily: event.target.value as FontFamily
						}
					)
				}}>
					{Object.entries(FontFamily).map(([key, value]) =>
						<option key={key} value={value}> {enumKeyToCapitalizeCase(key)} </option>)}
				</select>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.textStyleEditor.textStyle")}</div>
				<select value={style.fontStyle} onChange={event => {
					onStyleChange({
							...style,
							fontStyle: event.target.value as TextStyle
						}
					)
				}}>
					{Object.entries(TextStyle).map(([key, value]) =>
						<option key={key} value={value}> {capitalizeString(key)} </option>)}
				</select>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.textStyleEditor.align")}</div>
				<select value={style.align} onChange={event => {
					onStyleChange({
							...style,
							align: event.target.value as TextAlign
						}
					)
				}}>
					{Object.entries(TextAlign).map(([key, value]) =>
						<option key={key} value={value}> {capitalizeString(key)} </option>)}
				</select>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.textStyleEditor.size")}</div>
				<MinMaxValidatedNumberInput
					min={8} max={100} value={style.fontSize}
					onChange={value => {onStyleChange({...style, fontSize: value})}}
				/>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.textStyleEditor.color")}</div>
				<ColorPicker color={style.fill} onChange={color => {onStyleChange({...style, fill: color})}}/>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.textStyleEditor.backgroundColor")}</div>
				<ColorPicker color={style.backgroundFill || "#00000000"}
							 onChange={color => {onStyleChange({...style, backgroundFill: color})}}/>
			</div>
		</div>
	);
};

export default TextStyleSelector
