import React from "react";
import {useTranslation} from "react-i18next";
import SwitchComponent from "../../../../../../../../components/ui/Switch/SwitchComponent";

type FavouriteSwitchProps = {
	showFavorite: boolean,
	onShowFavouritesChange: (value: boolean) => void
}
export const FavouriteSwitch: React.FC<FavouriteSwitchProps> = function(props) {
	const {t} = useTranslation();
	const {showFavorite, onShowFavouritesChange} = props
	return (
		<div className="material-modal_container_content_fav-switch">
			<SwitchComponent checked={showFavorite}
							 onSwitch={(value) => onShowFavouritesChange(value)}/>
			<span className="material-modal_container_content_fav-switch_label">
					{t("projects.addItemModal.favourites.favSwitchLabel")}
				</span>
		</div>
	)
}