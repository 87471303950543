import React, {useState} from "react";
import "./filePreviewModal.scss";
import AttachmentApi from "../../../../../api/AttachmentApi";
import {TransformComponent, TransformWrapper} from "@pronestor/react-zoom-pan-pinch";
import IconZoomIn from "../../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconZoomIn";
import IconZoomOut from "../../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconZoomOut";
import {useImage} from "../../../../project-drawings/hooks/useImage";
import {Status} from "../../../../../models/enums";
import Spinner from "../../../../../components/Spinner";
import DataFetchError from "../../../../../components/ui/DataFetchError";

interface JpgFilePreviewProps {
	attachmentId: string,
	filename: string,
}

const JpgFilePreview: React.FC<JpgFilePreviewProps> = ({filename, attachmentId}) => {

	const [image, status] = useImage(AttachmentApi.getAttachmentDownloadUrl(attachmentId, filename),
		undefined, true);
	const [isHovered, setHovered] = useState(false);

	return (
		<>
			{status === Status.LOADING && <Spinner/>}
			{status === Status.SUCCESS &&
				<div className="file-preview-modal_jpg"
					 onMouseEnter={() => setHovered(true)}
					 onMouseLeave={() => setHovered(false)}>
					<TransformWrapper centerOnInit>
						{(props) => {
							return (
								<>
								<span className={`file-preview-modal_jpg_tools ${isHovered ? "visible" : ""}`}>
									<IconZoomIn onClick={() => props.zoomIn()}/>
									<IconZoomOut onClick={() => props.zoomOut()}/>
								</span>
									<TransformComponent>
										<img src={image?.src} alt="preview"/>
									</TransformComponent>
								</>)
						}}
					</TransformWrapper>
				</div>}
			{status === Status.ERROR && <DataFetchError/>}
		</>
	);
};

export default JpgFilePreview;
