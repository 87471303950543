import React from "react";
import {Status} from "../../../models/enums";
import {DrawingVersion} from "../../../models/DrawingVersion";
import {useTranslation} from "react-i18next";
import {BaseCopyVersionModal} from "../../project-details/components/tree/modals/BaseCopyVersionModal";

export function CopyDrawingVersionModal({copyStatus, drawingName, drawingVersion, onClose, onCopy}: {
	copyStatus: Status
	drawingName: string
	drawingVersion: DrawingVersion
	onClose: () => void
	onCopy(
		mode: 'save' | 'save-and-open',
		drawingVersion: DrawingVersion
	): (name: string, sectionId?: string, ccId?: string) => void
}) {
	const {t} = useTranslation();
	return (
		<BaseCopyVersionModal
			initialName={drawingName}
			title={t("projects.details.copyDrawingModal.title")}
			isLoading={copyStatus === Status.LOADING}
			onClose={onClose}
			onSave={onCopy("save", drawingVersion)}
			onSaveAndOpen={onCopy("save-and-open", drawingVersion)}
		/>
	)
}