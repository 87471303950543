import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const IconTextTool: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M 9.004 8 9.004 10.1328008 10.2468395 10.1328008 10.2457616 9.16510948 15.4521059 9.16625062 15.4521059 22.8326082 13.8675664 22.8326082 13.8675664 24 18.2051087 24 18.2051087 22.8326082 16.6938676 22.8326082 16.6938676 9.16625062 21.7611605 9.16510948 21.7611605 10.1328008 23.004 10.1328008 23.004 8z"]}/>
	);
};

export default IconTextTool

