import {ImageData} from "../../../../../base-konva/types";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {BaseImageElement, BaseImageElementActions} from "../../../../../base-konva/features/image/BaseImageElement";
import {planUploadImageActions, selectPlanUploadActiveImageId} from "./planUploadImageSlice";
import {planUploadConfigActions, selectPlanUploadActiveTool} from "../config/planUploadConfigSlice";
import {planUploadViewActions, selectPlanUploadExportInProgress} from "../view/planUploadViewSlice";
import {DrawingItemType} from "../../../../../base-konva/enums";
import {PlanUploadEditorTool} from "../../types";
import {usePlanUploadGetSelectionShapeStyle, usePlanUploadSelectSetter} from "../../hooks/planUploadSelect";
import {usePlanUploadGetHighlightShapeStyle, usePlanUploadHighlightSetter} from "../../hooks/planUploadHighlight";
import {usePlanUploadActivate} from "../../hooks/planUploadActivate";

type PlanUploadImageElementProps = {
	imageData: ImageData
}
export const PlanUploadImageElement: React.FC<PlanUploadImageElementProps> = ({imageData}) => {
	const dispatch = useDispatch();
	const activeImageId = useSelector(selectPlanUploadActiveImageId);
	const activeTool = useSelector(selectPlanUploadActiveTool);
	const exportInProgress = useSelector(selectPlanUploadExportInProgress);
	const selectActions = usePlanUploadSelectSetter();
	const highlightActions = usePlanUploadHighlightSetter(imageData.id);
	const activateActions = usePlanUploadActivate();
	const {getHighlightStyle} = usePlanUploadGetHighlightShapeStyle();
	const {getSelectionStyle} = usePlanUploadGetSelectionShapeStyle();
	const isElementActive = activeTool === PlanUploadEditorTool.IMAGE && activeImageId === imageData.id;

	const actions: BaseImageElementActions = {
		activateImage: (id) => {dispatch(planUploadImageActions.activateImage({id}))},
		transformImage: (id, newPosition, rotation, scale) => {
			dispatch(planUploadImageActions.transformImage({itemId: id, newPosition, rotation, scale}))
		},
		setRemoteItemLoaded: id => {dispatch(planUploadViewActions.setRemoteItemLoaded(id))}
	}

	function selectElement(id: string) {
		dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: PlanUploadEditorTool.IMAGE}))
		dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: PlanUploadEditorTool.MEASUREMENT_SELECT}))
		activateActions.activateImage(id)
		selectActions.clearSelection()
		selectActions.setSelection(DrawingItemType.IMAGE, id)
	}


	const getStyle = (id: string) => {
		const highlightStyle = getHighlightStyle(
			(id) => id === imageData.id
		);
		const selectionStyle = getSelectionStyle(highlighted => (
			highlighted.some(item => (
				item.type === DrawingItemType.IMAGE &&
				item.itemId === id
			))
		))

		if (!exportInProgress) {
			return {
				...highlightStyle,
				...selectionStyle
			}
		}
		else {
			return;
		}
	}

	return (<BaseImageElement
		isEditorToolSelect={activeTool === PlanUploadEditorTool.MEASUREMENT_SELECT}
		isElementActive={isElementActive}
		selectElement={selectElement}
		getStyle={getStyle}
		onEnableHighlight={highlightActions.setHighlight}
		onClearHighlight={highlightActions.clearHighlight}
		exportInProgress={exportInProgress}
		actions={actions}
		imageData={imageData}/>)
};
