import React from "react";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import SubTabs from "../../../components/ui/SubTabs";
import SubTab from "../../../components/ui/SubTab";
import PageTabsBottomSpacer from "../../../components/layout/page/PageTabsBottomSpacer";
import {JobStage} from "../../../models/enums";
import JobsLoad from "../components/JobsLoad";
import Tabs from "../../../components/ui/Tabs";
import Tab from "../../../components/ui/Tab";
import {paths, useNavigator} from "../../navigator";

const JobsSubTabs: React.FC = () => {
	const {t} = useTranslation();
	const location = useLocation();
	const {navigateTo} = useNavigator()
	let isQuote = /quotes/.test(location.pathname);
	let isJob = /jobs/.test(location.pathname);

	const values: { stage: JobStage, path: string, label: string }[] = [{
		stage: JobStage.ALL,
		path: paths.takeoffs_jobs_all,
		label: t(`common.stages.job.all`)
	}, {
		stage: JobStage.PENDING,
		path: paths.takeoffs_jobs_pending,
		label: t(`common.stages.job.pending`)
	}, {
		stage: JobStage.PROGRESS,
		path: paths.takeoffs_jobs_progress,
		label: t(`common.stages.job.progress`)
	}, {
		stage: JobStage.COMPLETE,
		path: paths.takeoffs_jobs_complete,
		label: t(`common.stages.job.complete`)
	}, {
		stage: JobStage.INVOICED,
		path: paths.takeoffs_jobs_invoiced,
		label: t(`common.stages.job.invoiced`)
	}, {
		stage: JobStage.ARCHIVED,
		path: paths.takeoffs_jobs_archived,
		label: t(`common.stages.job.archived`)
	}]

	return (
		<>
			<Tabs className={"has-sub-tabs"}>
				<Tab label={t("projects.quote.tabName")} active={isQuote}
					 onClick={() => { navigateTo(paths.takeoffs_quotes) }}/>
				<Tab label={t("projects.job.tabName")} active={isJob}
					 onClick={() => { navigateTo(paths.takeoffs_jobs) }}/>
			</Tabs>
			<SubTabs>
				{values.map(v => (
					<SubTab
						key={v.stage} label={v.label}
						active={new RegExp(v.stage, "i").test(location.pathname)}
						onClick={() => { navigateTo(v.path) }}
					/>
				))}
			</SubTabs>
			<PageTabsBottomSpacer/>
			<Switch>
				{values.map(v => (
					<Route
						key={v.stage}
						path={v.path}
						component={() => <JobsLoad sourceStage={v.stage}/>}
					/>
				))}
				<Redirect to={paths.takeoffs_jobs_pending}/>
			</Switch>
		</>
	);
};

export default JobsSubTabs
