import React from "react";

const IconDoneSmall: React.FC = () => {
	return (
		<svg width="15px" height="13px" viewBox="0 0 15 13">
			<polygon fill="#41B555"
					 points="13.8068182 0 4.77272727 9.32302772 1.19318182 5.62899787 0 6.86034115 4.77272727 11.7857143 15 1.23134328"/>
		</svg>
	);
};

export default IconDoneSmall
