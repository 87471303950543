import {
	AreaMeasurement_v1,
	AreaMeasurement_v2,
	DrawingItem_v2,
	EditorDrawingData_v1,
	EditorDrawingData_v2
} from "../../../models/editor";
import {DrawingItemType} from "../../../../../../base-konva/enums";
import {getId} from "../../../../../../../utils";

export function from_v1_to_v2(schema: EditorDrawingData_v1): EditorDrawingData_v2 {
	return {
		...schema,
		schemaVersion: 2,
		items: schema.items.map(item => {
			if (item.type === DrawingItemType.AREA) {
				const areaMeasurement = item.data as AreaMeasurement_v1
				const areaMeasurement_v2: AreaMeasurement_v2 = {
					id: areaMeasurement.id,
					measurementId: areaMeasurement.measurementId,
					type: areaMeasurement.type,
					height: areaMeasurement.height,
					activeAreaFragmentId: null,
					style: areaMeasurement.style,
					visible: areaMeasurement.visible,
					material: areaMeasurement.material,
					areaFragments: [{
						id: getId(),
						lines: areaMeasurement.lines,
						closed: areaMeasurement.closed,
						lastMouseUpPosition: areaMeasurement.lastMouseUpPosition,
						centerOfMass: areaMeasurement.centerOfMass
					}]
				}
				return {
					type: DrawingItemType.AREA,
					data: areaMeasurement_v2
				}
			}
			return item as DrawingItem_v2
		})
	}
}