import React from "react";
import {SelectRectangleLayer} from "../../../../../base-konva/components/SelectRectangleLayer";
import {usePlanUploadClip} from "./usePlanUploadClip";
import {useSelector} from "react-redux";
import {selectPlanUploadStageConfig} from "../config/planUploadConfigSlice";
import {selectPlanUploadPanInProgress} from "../view/planUploadViewSlice";

const PlanUploadClipLayer: React.FC = () => {
	const stageConfig = useSelector(selectPlanUploadStageConfig);
	const panInProgress = useSelector(selectPlanUploadPanInProgress);

	return (
		<SelectRectangleLayer correspondingHook={usePlanUploadClip} stageConfig={stageConfig} panInProgress={panInProgress}/>
	)
}

export {PlanUploadClipLayer}