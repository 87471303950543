import {useEffect, useState} from "react";
import {useTakeoffTemplates} from "../api/useTakeoffTemplates";
import {useTakeoffTemplateGroups} from "../api/useTakeoffTemplateGroups";
import {TakeoffTemplateGroup} from "../../../../../../../../models/TakeoffTemplateGroup";
import {Breadcrumb, Level} from "../types";

export function useBrowserData(breadcrumbs: Breadcrumb[], setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void) {
	const [query, setQuery] = useState<string | undefined>();
	const {
		groups, loadStatus: groupsLoadStatus, loadTakeoffTemplateGroupsRef
	} = useTakeoffTemplateGroups()

	const onSearch = (query: string) => {
		pageNumber !== 0 && onPageChange(0);
		setQuery(query.length > 0 ? query : undefined);
	};

	const lastBreadcrumb = breadcrumbs[breadcrumbs.length - 1]
	const subGroupLevel = lastBreadcrumb.level === Level.SUBGROUP

	const groupId = subGroupLevel ? undefined : lastBreadcrumb.id
	const subGroupId = subGroupLevel ? lastBreadcrumb.id : undefined
	const enabled = lastBreadcrumb.level !== Level.TEMPLATE
	const {
		items, pageNumber, totalPages, onPageChange, loadStatus
	} = useTakeoffTemplates(enabled, query, groupId, subGroupId)

	const onGroupClick = (group: TakeoffTemplateGroup) => {
		setBreadcrumbs([...breadcrumbs, {
			id: group.id,
			name: group.name,
			level: lastBreadcrumb.level === Level.ROOT ? Level.GROUP : Level.SUBGROUP
		}])
	};

	useEffect(() => {
		const loadTakeoffTemplateGroups = loadTakeoffTemplateGroupsRef.current
		if (lastBreadcrumb && !subGroupLevel) {
			loadTakeoffTemplateGroups(lastBreadcrumb.id)
		}
	}, [lastBreadcrumb, loadTakeoffTemplateGroupsRef, subGroupLevel])

	return {
		onSearch, onGroupClick,
		items, pageNumber, totalPages, onPageChange, loadStatus,
		groups: subGroupLevel ? [] : groups,
		groupsLoadStatus
	}
}