import React from "react";
import {CustomSelectItem, MaterialAddableTool} from "../../../../../../../../models/interfaces";
import {EditorTool} from "../../../../models/enums";
import {capitalizeString} from "../../../../../../../../utils/TextUtils";

type Props = {
	value: MaterialAddableTool | undefined
	onChange: (value: MaterialAddableTool) => void
}

export function MeasurementTypeSelect({value, onChange}: Props) {
	const items: CustomSelectItem<MaterialAddableTool>[] = [
		EditorTool.COUNT, EditorTool.LENGTH, EditorTool.AREA, EditorTool.VOLUME
	].map(type => ({
		label: capitalizeString(type),
		value: type as MaterialAddableTool
	}))

	return (
		<select
			value={value}
			onChange={event => {
				onChange(event.target.value as MaterialAddableTool)
			}}
		>
			{items.map(item => (
				<option key={item.value} value={item.value}>
					{item.label}
				</option>
			))}
		</select>
	)
}