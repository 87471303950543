import {PayloadAction} from "@reduxjs/toolkit";
import {Vector2d} from "konva/types/types";
import {textToolDefaultStyle} from "../../../project-drawings/features/editor/constants";
import {
	ActivateTextItemActionPayload,
	EditActionPayload,
	SaveTextItemActionPayload,
	SelectActionPayload,
	TextElementTransformationPayload
} from "../../../project-drawings/features/editor/models/editor";
import {
	ActionIdValuePayload,
	ActivateActionPayload,
	DeleteActionPayload,
	DeleteSelectedItemActionPayload,
	GroupedActionPayload,
	SetItemVisibilityActionPayload,
	SetVisibilityActionPayload,
	TextGroup,
	TextToolState,
	TextToolStyle,
	UpdateItemPositionActionPayload
} from "../../types";
import {getId} from "../../../../utils";
import i18n from "i18next";

const initialState: TextToolState = {
	textGroups: [],
	textSelectorStyle: textToolDefaultStyle,
}

const reducers = {
	reset: () => ({...initialState}),
	setTextGroups: (state: TextToolState, {payload}: PayloadAction<ActionIdValuePayload<TextGroup[]>>) => {
		state.textGroups = payload.value ?? initialState.textGroups
	},
	addTextGroup: (state: TextToolState) => {
		const newId = getId();
		state.activeTextGroupId = newId;
		state.textGroups.push({
			id: newId,
			name: `${i18n.t("editor.text.textGroup")} ${state.textGroups.length + 1}`,
			visible: true,
			textItems: [],
			style: state.textSelectorStyle
		})
	},
	addTextElement: (state: TextToolState, {payload}: PayloadAction<Vector2d>) => {
		for (let textGroup of state.textGroups) {
			if (textGroup.id === state.activeTextGroupId) {
				textGroup.textItems.push({
					id: getId(),
					isEditing: true,
					isSelected: false,
					text: "",
					position: payload,
					rotation: 0,
					visible: true,
				})
				break;
			}
		}
	},
	updateTextElementPosition: (state: TextToolState, {payload}: PayloadAction<UpdateItemPositionActionPayload>) => {
		const {itemId, newPosition} = payload

		for (let textGroup of state.textGroups) {
			if (textGroup.id === state.activeTextGroupId) {
				for (let textItem of textGroup.textItems) {
					if (textItem.id === itemId) {
						textItem.position = newPosition;
						break;
					}
				}
				break;
			}
		}
	},
	transformTextElement: (state: TextToolState, {payload}: PayloadAction<TextElementTransformationPayload>) => {
		const {itemId, newPosition, rotation, fontSize} = payload

		state.textSelectorStyle.fontSize = fontSize;

		for (let textGroup of state.textGroups) {
			if (textGroup.id === state.activeTextGroupId) {
				textGroup.style = {...textGroup.style, fontSize}
				for (let textItem of textGroup.textItems) {
					if (textItem.id === itemId) {
						textItem.position = newPosition;
						textItem.rotation = rotation;
						break;
					}
				}
				break;
			}
		}
	},
	changeTextStyle: (state: TextToolState, {payload}: PayloadAction<TextToolStyle>) => {
		state.textSelectorStyle = payload;
		for (let penGroup of state.textGroups) {
			if (penGroup.id === state.activeTextGroupId) {
				penGroup.style = payload
				break;
			}
		}
	},
	saveTextItem: (state: TextToolState, action: PayloadAction<SaveTextItemActionPayload>) => {
		const {itemId, text, rotation} = action.payload

		for (let textGroup of state.textGroups) {
			for (let textItem of textGroup.textItems) {
				if (textItem.id === itemId) {
					textItem.isEditing = false;
					textItem.text = text;
					textItem.rotation = rotation ?? textItem.rotation
					break;
				}
			}
		}
	},
	deleteTextItem: (state: TextToolState, {payload}: PayloadAction<DeleteActionPayload>) => {
		for (let textGroup of state.textGroups) {
			textGroup.textItems = textGroup.textItems.filter(item => item.id !== payload.id)
		}
	},
	selectTextItem: (state: TextToolState, {payload}: PayloadAction<SelectActionPayload>) => {
		for (let textGroup of state.textGroups) {
			if (textGroup.id === state.activeTextGroupId) {
				for (let textItem of textGroup.textItems) {
					textItem.isSelected = textItem.id === payload.id;
				}
				break;
			}
		}
	},
	unselectAllTextItems: (state: TextToolState) => {
		for (let textGroup of state.textGroups) {
			if (textGroup.id === state.activeTextGroupId) {
				for (let textItem of textGroup.textItems) {
					textItem.isSelected = false;
				}
				break;
			}
		}
	},
	editTextItem: (state: TextToolState, {payload}: PayloadAction<EditActionPayload>) => {
		for (let textGroup of state.textGroups) {
			if (textGroup.id === state.activeTextGroupId) {
				for (let textItem of textGroup.textItems) {
					if (textItem.id === payload.id) {
						textItem.isEditing = true;
						break;
					}
				}
				break;
			}
		}
	},
	deleteTextGroup: (state: TextToolState, {payload}: PayloadAction<DeleteActionPayload>) => {
		state.textGroups = state.textGroups
			.filter(tg => tg.id !== payload.id)
			.map((tg, idx) => ({...tg, name: `${i18n.t("editor.text.textGroup")} ${idx + 1}`}))
	},
	activateTextItem: (state: TextToolState, {payload}: PayloadAction<ActivateTextItemActionPayload>) => {
		const {id, parentId} = payload
		for (let textGroup of state.textGroups) {
			if (textGroup.id === parentId) {
				textGroup.activeTextItemId = textGroup.activeTextItemId === id ? undefined : id;
				break;
			}
		}
	},
	activateTextGroup: (state: TextToolState, {payload}: PayloadAction<ActivateActionPayload>) => {
		state.activeTextGroupId = payload.id;

		for (let textGroup of state.textGroups) {
			for (let textItem of textGroup.textItems) {
				textItem.isSelected = false;
			}
		}
	},
	deactivateTextGroup: (state: TextToolState, _action: PayloadAction<GroupedActionPayload>) => {
		state.activeTextGroupId = undefined;

		for (let textGroup of state.textGroups) {
			for (let textItem of textGroup.textItems) {
				textItem.isSelected = false;
			}
		}
	},
	setVisibility: (state: TextToolState, {payload}: PayloadAction<SetVisibilityActionPayload>) => {
		const {id, visible, setAll} = payload;
		for (let measurement of state.textGroups) {
			if (measurement.id === id || setAll) {
				measurement.visible = visible;
				for (let textItem of measurement.textItems) {
					textItem.visible = visible;
				}
				if (!setAll) break;
			}
		}
	},
	setItemVisibility: (state: TextToolState, {payload}: PayloadAction<SetItemVisibilityActionPayload>) => {
		const {id, visible, parentId} = payload;
		for (let group of state.textGroups) {
			if (group.id === parentId) {
				for (let item of group.textItems) {
					if (item.id === id) {
						item.visible = visible;
						break;
					}
				}
				break;
			}
		}
	},
	removeSelectionItems: (state: TextToolState, {payload}: PayloadAction<DeleteSelectedItemActionPayload>) => {
		for (let textGroup of state.textGroups) {
			if (textGroup.id === payload.id) {
				textGroup.textItems = textGroup.textItems
					.filter(item => !payload.subIds!.some(id => item.id === id))
					.map((tg, idx) => ({...tg, name: `${i18n.t("editor.text.textGroup")} ${idx + 1}`}))
				break;
			}
		}
	},
}
export {initialState, reducers}