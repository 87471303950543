import {useTranslation} from "react-i18next";
import React from "react";
import {apiInstance} from "../../../api/api";
import {Status} from "../../../models/enums";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {Symbol} from "../../../models/Symbol";
import {useQuery} from "react-query";
import {toStatus} from "../../../utils/ReactQueryUtils";
import {TIME_5_MIN_IN_MILLISECONDS} from "../../project-drawings/features/editor/constants";
import {SYMBOL_QUERY_KEY} from "../../../api/SymbolsApi";

function useSymbols(packId?: string) {
	const {t} = useTranslation();

	const queryKey: [string, { url: string, packId: string }] | undefined = React.useMemo(() => {
		if (packId) {
			return [SYMBOL_QUERY_KEY, {url: apiInstance.symbolsApi.fetchSymbols(packId).url, packId}]
		}
		return undefined
	}, [packId])

	const {data, status} = useQuery({
		queryKey,
		enabled: queryKey !== undefined,
		queryFn: ({queryKey}) => {
			const [, {packId}] = queryKey
			return apiInstance.symbolsApi.fetchSymbols(packId).promise()
		},
		onError: (err: any) => {
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), t("settings.tabs.symbols.fetchSymbolsError"));
		},
		staleTime: TIME_5_MIN_IN_MILLISECONDS
	})

	const symbols: Symbol[] = data ? data : []
	const loadStatus: Status = toStatus(status);

	return {symbols, loadStatus}
}

export default useSymbols;
