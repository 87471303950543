import {SummariesExportStatusResultApiDto, SummaryExportStatusApiDto} from "./restModels";
import LoggerService from "../services/LoggerService";

export enum ExportStatus {
	COMPLETE = "COMPLETE",
	FAIL = "FAIL",
	PENDING = "PENDING",
	PROGRESS = "PROGRESS"
}

export class SummariesExportStatusResult {
	constructor(
		public readonly id: string,
		public readonly statuses: SummaryExportStatus[]
	) {}

	public static fromJson(json: SummariesExportStatusResultApiDto) {
		return new SummariesExportStatusResult(
			json.id,
			json.statuses.map(status => SummaryExportStatus.fromJson(status))
		)
	}
}

export class SummaryExportStatus {
	constructor(
		public readonly drawingVersionId: string,
		public readonly status: ExportStatus,
	) {}

	public static fromJson(json: SummaryExportStatusApiDto) {
		const status = ExportStatus[json.status];
		if (status === undefined) {
			const error = new Error(`Unknown status type: ${json.status}`);
			LoggerService.logError(error);
			throw error;
		}

		return new SummaryExportStatus(
			json.drawingVersionId,
			status
		)
	}
}

export class StartExportResult {
	constructor(
		public readonly id: string
	) {}

	public static fromJson(json: { id: string }) {
		return new StartExportResult(json.id)
	}
}

