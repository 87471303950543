import React, {useEffect} from "react";
import {SymbolRecognitionPanelStage} from "./types";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {apiInstance} from "../../../../../../api/api";
import {wait} from "../../../../../../utils/ApiUtils";
import {SymbolRecognitionSummaryStatus} from "../../../../../../models/enums";
import LoggerService from "../../../../../../services/LoggerService";
import {symbolRecognitionActions} from "./symbolRecognitionSlice";
import NotificationService from "../../../../../../services/NotificationService";
import {InfoWithSelectionPanel} from "./InfoWithSelectionPanel";
import Button from "../../../../../../components/ui/Button";
import AnimatedEllipsis from "../../../../../../components/AnimatedEllipsis";
import {SelectionState} from "../../../../../base-konva/types";

const SymbolRecognitionProcessingPanel: React.FC<{
	quitSymbolRecognitionMode: () => void
	templateData: SelectionState
}> = ({
	quitSymbolRecognitionMode,
	templateData
}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch()
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext()

	useEffect(() => {
		let mounted = true;

		const startSymbolRecognition = async function() {
			if (!drawingDetails?.planId) {
				throw new Error("Could not start recognition process without planId")
			}

			const {id: taskId} = await apiInstance.symbolRecognizerApi.startSymbolRecognition(
				templateData,
				drawingDetails.planId
			)

			while (mounted) {
				await wait(1000)
				const {
					result, status, error: resultError
				} = await apiInstance.symbolRecognizerApi.askForSymbolRecognitionResults(taskId)
				if (!mounted) return;
				if (status === SymbolRecognitionSummaryStatus.FAILED) {
					throw new Error(resultError ?? t("editor.symbolRecognitionPanel.processingScreen.genericError"))
				}
				if (status === SymbolRecognitionSummaryStatus.SUCCESS) {
					if (!result) {
						throw new Error("No symbol recognition result")
					}
					dispatch(symbolRecognitionActions.setSymbolRecognitionResults(result.results))
					dispatch(symbolRecognitionActions.setPanelStage(SymbolRecognitionPanelStage.MATCHES_DISPLAY))
					return;
				}
			}
		}
		startSymbolRecognition().catch(err => {
			NotificationService.errorNotification(
				t("common.error"),
				t("editor.symbolRecognitionPanel.processingScreen.recognizeTaskErrorDesc")
			);
			LoggerService.logError(err)
			dispatch(symbolRecognitionActions.setPanelStage(SymbolRecognitionPanelStage.SYMBOL_SELECTION))
		})

		return () => {
			mounted = false;
		}
	}, [templateData, drawingDetails, dispatch, t])

	function handleCancel() {
		dispatch(symbolRecognitionActions.setPanelStage(SymbolRecognitionPanelStage.SYMBOL_SELECTION))
	}

	return (
		<InfoWithSelectionPanel
			title={<>{t("editor.symbolRecognitionPanel.processingScreen.title")}
				<AnimatedEllipsis/>
			</>}
			description={<>{t("editor.symbolRecognitionPanel.processingScreen.description")}</>}
			buttons={<>
				<Button onClick={handleCancel} variant={"optional"} label={t("common.buttons.cancel")}/>
			</>}
			handleCloseIconClick={quitSymbolRecognitionMode}
			templateData={templateData}
		/>
	)
}
export {SymbolRecognitionProcessingPanel};