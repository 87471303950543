import React from "react";
import "./searchBarWrapper.scss"

const SearchBarWrapper: React.FC = ({children}) => {
	return (
		<div className={"search-bar-wrapper"}>{children}</div>
	);
};

export default SearchBarWrapper
