import React, {useMemo} from "react";
import "./planTemplates.scss";
import {FileType, Status} from "../../../../models/enums";
import Spinner from "../../../../components/Spinner";
import DataFetchError from "../../../../components/ui/DataFetchError";
import {useTranslation} from "react-i18next";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import Button from "../../../../components/ui/Button";
import PlanUploadPanel from "./PlanUploadPanel";
import Dropdown from "../../../../components/ui/Dropdown";
import DropdownItem from "../../../../components/ui/DropdownItem";
import OptionsDropdown from "../../../../components/ui/OptionsDropdown";
import {PlanUploadAttachment, PlanUploadData, PlanUploadPageData} from "../../features/plan-upload/types";
import {apiInstance} from "../../../../api/api";
import {downloadData} from "../../../../utils/AttachementUtils";
import NotificationService from "../../../../services/NotificationService";
import LoggerService from "../../../../services/LoggerService";
import {getAttachmentFileType, toPlanUploadData} from "../../utils/planTemplateUtils";
import {useLocation, useParams} from "react-router-dom";
import {getFileExtension} from "../../../../utils/TextUtils";
import {pages, useNavigator} from "../../../navigator";

type Props = ReturnType<typeof useProjectDetailContext>["planTemplates"]
const PlanTemplates: React.FC<Props> = (props) => {
	const {templates, loadStatus, loadPlanTemplates} = props
	const {projectId} = useParams<{ projectId: string }>();
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {navigateTo} = useNavigator();
	const {
		forceTreeUpdate,
		planTemplateModalsData: {
			openUploadTemplateModal,
			openCreateTemplateModal,
			openEditTemplateModal,
			openRenameTemplateModal,
			openDeleteTemplateModal,
			openRenamePageModal,
			openDeletePageModal,
			openCreateTakeoffDrawingModal,
			openCreateAsBuiltDrawingModal,
			openFilePreviewModal,
		},
		planUploadData: {initEditorPlanUploadData, switchActivePageNumber}
	} = useProjectDetailContext()

	const {t} = useTranslation();
	const cls = [
		"plan-templates",
		loadStatus === Status.LOADING && "is-reloading"
	].filter(Boolean).join(" ");

	const openUploadModal = () => {openUploadTemplateModal(onNextAfterUpload);}

	const onNextAfterUpload = (previewSources: string[], filename: string, attachmentData: PlanUploadAttachment) => {
		openCreateTemplateModal({
			data: {previewSources, filename, attachmentData},
			refreshPlanTemplates: loadPlanTemplates,
			onPrevious: openUploadModal
		})
	}

	const onPageEdit = (template: PlanUploadData, planPage: PlanUploadPageData) => {
		initEditorPlanUploadData({
			attachment: template.attachment,
			name: template.name,
			pages: template.pages,
			id: template.id,
			isPdf: template.isPdf,
			fullPageCount: template.fullPageCount,
			dpi: template.dpi
		})
		switchActivePageNumber(planPage.pageNumber)
		navigateTo(pages.planUploadPage(type, projectId));
	}

	const handleDownload = (id: string, filename: string) => {
		apiInstance.attachmentApi.downloadExternal(id, filename)
			.then(res => {
				const url = window.URL.createObjectURL(new Blob([res.data]));
				downloadData(url, filename)
			})
			.catch(err => {
				NotificationService.errorNotification(t("common.error"),
					t("projects.details.planTemplates.downloadPlanErrorDesc"));
				LoggerService.logError(err)
			})
	};

	const renderTemplateOptionsDropdown = (template: PlanUploadData) => {
		return (
			<OptionsDropdown>
				<DropdownItem
					label={t("projects.details.planTemplates.planTemplateOptionsDropdown.rename")}
					onClick={() => { openRenameTemplateModal(template); }}
				/>
				<DropdownItem
					label={t("projects.details.planTemplates.planTemplateOptionsDropdown.delete")}
					onClick={() => { openDeleteTemplateModal(template, loadPlanTemplates); }}
				/>
				<DropdownItem
					label={t("projects.details.planTemplates.planTemplateOptionsDropdown.download")}
					onClick={() => {
						const getFilename = function() {
							const fileExtension = getFileExtension(template.attachment.filename)
							if (template.name.toLowerCase().endsWith(fileExtension.toLowerCase())) {
								return template.name
							}
							else {
								const extensionString = fileExtension === "" ? "" : "." + fileExtension
								return template.name + extensionString
							}
						}
						handleDownload(template.attachment.id, getFilename());
					}}
				/>
				{(template.pages.length === 0 || (template.fullPageCount && template.fullPageCount > 1)) &&
					<DropdownItem
						label={t("projects.details.planTemplates.planTemplateOptionsDropdown.edit")}
						onClick={() => { openEditTemplateModal(template, loadPlanTemplates); }}
					/>}
			</OptionsDropdown>
		)
	}

	const renderPageOptionsDropdown = (
		template: PlanUploadData,
		planPage: PlanUploadPageData
	) => (selectedPageNumber: number | undefined,) => {
		return (
			<Dropdown isActive={selectedPageNumber === planPage.pageNumber}>
				<DropdownItem
					label={t("projects.details.planTemplates.planPageOptionsDropdown.rename")}
					onClick={() => { openRenamePageModal(template, planPage, onPageRename); }}
				/>
				<DropdownItem
					label={t("projects.details.planTemplates.planPageOptionsDropdown.delete")}
					onClick={() => { openDeletePageModal(template, planPage); }}
				/>
				<DropdownItem
					label={t("projects.details.planTemplates.planPageOptionsDropdown.download")}
					onClick={() => { handleDownload(planPage.attachmentId, planPage.title + ".png") }}
				/>
				<DropdownItem
					label={t("projects.details.planTemplates.planPageOptionsDropdown.createTakeoff")}
					onClick={() => { openCreateTakeoffDrawingModal(template, planPage, forceTreeUpdate); }}
				/>
				{type === "jobs" &&
					<DropdownItem
						label={t("projects.details.planTemplates.planPageOptionsDropdown.createAsBuilt")}
						onClick={() => { openCreateAsBuiltDrawingModal(template, planPage, forceTreeUpdate); }}
					/>
				}
				<DropdownItem
					label={t("projects.details.planTemplates.planPageOptionsDropdown.edit")}
					onClick={() => {onPageEdit(template, planPage)}}
				/>
			</Dropdown>
		)
	}

	const planTemplates = useMemo(() => {
		return toPlanUploadData(templates).sort((a, b) => {
			return a.name.toLowerCase().localeCompare(b.name.toLowerCase())
		})
	}, [templates])

	const onPageClick = (page: PlanUploadPageData) => {
		openFilePreviewModal({
			attachmentId: page.attachmentId,
			filename: page.title,
			fileType: FileType.PNG
		})
	}

	const onTemplateClick = (template: PlanUploadData) => {
		openFilePreviewModal({
			attachmentId: template.attachment.id,
			filename: template.name,
			fileType: getAttachmentFileType(template.attachment)
		})
	}

	const onPageRename = async (newPlanPage: PlanUploadPageData, planTemplateId: string) => {
		try {
			await apiInstance.projectsApi.editPlanPageName(projectId, planTemplateId, newPlanPage.pageNumber, newPlanPage.title)
			loadPlanTemplates()
			NotificationService.successNotification(
				t("common.success"),
				t("projects.details.planTemplates.modals.renamePlanPageModal.renameSuccessDesc")
			)
		}
		catch (e) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.modals.renamePlanPageModal.renameErrorDesc"));
			LoggerService.logError(e);
		}
	}

	return (
		<div className={cls}>
			{loadStatus === Status.ERROR ? <DataFetchError/> : null}
			{loadStatus === Status.LOADING ? <Spinner/> : null}
			{loadStatus === Status.SUCCESS ? (
				<>
					<div className={"plan-templates_header"}>
						<div className="plan-templates_header_label">{t("projects.details.planTemplates.title")}</div>
						<Button
							onClick={openUploadModal}
							label={t("projects.details.uploadDrawingModal.modalButton")}
							variant={"secondary"}
							withPlusIcon
						/>
					</div>
					<div className={"plan-templates_content"}>
						{planTemplates.length > 0
							? <PlanUploadPanel
								planTemplates={planTemplates}
								onTemplateClick={onTemplateClick}
								onPageClick={onPageClick}
								renderTemplateOptionsDropdown={renderTemplateOptionsDropdown}
								renderPageOptionsDropdown={renderPageOptionsDropdown}
							/>
							: <div className={"plan-templates_content_item no-records"}>
								{t("projects.details.planTemplates.noPlansMessage")}
							</div>}
					</div>
				</>
			) : null}
		</div>
	);
};

export {PlanTemplates}
