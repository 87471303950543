import {useMaterialChangeOneOffData} from "../../hooks/useMaterialChangeOneOffData";
import {MeasurementStyleSelector} from "../../../../components/MeasurementStyleSelector";
import React from "react";

export function OneOffMaterialChangeStyles(stylesContainer: ReturnType<typeof useMaterialChangeOneOffData>["stylesContainer"]) {
	return (
		<div className="one-off-modal_styles">
			<div className="one-off-modal_styles_container">
				<MeasurementStyleSelector {...stylesContainer.measurementStyleSelector} />
			</div>
		</div>
	)
}