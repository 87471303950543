import React from "react";
import "./dropdownItem.scss";
import {useDropdownContext} from "./CommonDropdown";

interface IDropdownItemProps {
	label: string | React.ReactNode,
	onClick: () => void,
	disabled?: boolean
}

const DropdownItem: React.FC<IDropdownItemProps> = ({label, onClick, disabled}) => {

	const handleClick = (evt: React.MouseEvent<HTMLLIElement>) => {
		evt.stopPropagation();
		if (disabled !== true) {
			onClose()
			onClick()
		}
	}

	const {onClose} = useDropdownContext()

	const cls = [
		"dropdown-item",
		disabled === true && "is-disabled"
	].filter(Boolean).join(" ")

	return (
		<li className={cls} onClick={handleClick}>{label}</li>
	);
};

export default DropdownItem
