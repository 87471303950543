import React from 'react';
import {AppSettingsProvider} from "./providers/AppSettingsProvider";
import {MeProvider} from "./providers/MeProvider";
import {Route, Switch} from "react-router-dom"
import {UserSettingsProvider} from "./providers/UserSettingsProvider";
import IframeChecker from "./components/IframeChecker";
import {DirectProjectAccessDataProvider} from "./providers/DirectProjectAccessDataProvider";
import {UserConfirmationDataProvider, useUserConfirmationDataContext} from "./providers/UserConfirmationDataProvider";
import {SymbolCacheDataProvider} from "./providers/SymbolCacheDataProvider";
import {IsAnyModalOpenProvider} from "./providers/IsAnyModalOpenProvider";
import {BreadcrumbsProvider} from "./features/breadcrumbs";
import {ReactQueryProvider} from "./providers/ReactQueryProvider";
import {ErrorPage, MainRouter} from "./routes";
import {FullscreenProvider} from "./providers/FullscreenProvider";
import {paths, RouterWithNavigatorProvider} from "./features/navigator";

function AppWrapper() {
	return (
		<UserConfirmationDataProvider>
			<IsAnyModalOpenProvider>
				<App/>
			</IsAnyModalOpenProvider>
		</UserConfirmationDataProvider>
	)
}

function App() {
	const {setData} = useUserConfirmationDataContext()
	return (
		<RouterWithNavigatorProvider
			getUserConfirmation={(message, callback) => {
				setData({message, callback})
			}}
			getNavigateTo={history => (path, options) => {
				if (options?.replace) {
					history.replace(path)
				}
				else {
					history.push(path)
				}
			}}
		>
			<Switch>
				<Route exact path={paths.error} component={ErrorPage}/>
				<Route path={paths.root}>
					<Providers>
						<div className="app">
							<MainRouter/>
						</div>
					</Providers>
				</Route>
			</Switch>
		</RouterWithNavigatorProvider>
	);
}

const Providers: React.FC = function({children}) {
	/* When adding new Provider, bridge it also in Editor.tsx when needed */
	return (
		<ReactQueryProvider>
			<SymbolCacheDataProvider>
				<AppSettingsProvider>
					<IframeChecker>
						<MeProvider>
							<DirectProjectAccessDataProvider>
								<UserSettingsProvider>
									<BreadcrumbsProvider>
										<FullscreenProvider>
											{children}
										</FullscreenProvider>
									</BreadcrumbsProvider>
								</UserSettingsProvider>
							</DirectProjectAccessDataProvider>
						</MeProvider>
					</IframeChecker>
				</AppSettingsProvider>
			</SymbolCacheDataProvider>
		</ReactQueryProvider>
	)
}

export default AppWrapper;
