import React from "react";
import "./modalTitle.scss";

const ModalTitle: React.FC = ({children}) => {
	return (
		<div className="modal-title">
			{children}
		</div>
	);
};

export default ModalTitle;
