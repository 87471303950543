import React, {useState} from "react";
import "./treeCostCenter.scss";
import {useTranslation} from "react-i18next";
import IconFolder from "../../../../components/icons/IconFolder";
import {DeletedTreeElementModals} from "../../../project-drawings/features/editor/models/enums";
import OptionsDropdown from "../../../../components/ui/OptionsDropdown";
import DropdownItem from "../../../../components/ui/DropdownItem";
import AssignToExistingCostCenterModal from "./AssignToExistingCostCenterModal";
import AssignToNewCostCenterModal from "./AssignToNewCostCenterModal";
import {CostCenter} from "../../../../models/CostCenter";
import DeleteCostCenterModal from "./DeleteCostCenterModal";
import {Colors} from "../../../../styles";


interface ITreeCostCenterHeaderProps {
	costCenter: CostCenter,
	isChecked: boolean,
	onChange: () => void
	isDeleted: boolean
}

const TreeCostCenterHeader: React.FC<ITreeCostCenterHeaderProps> = ({costCenter, isChecked, onChange, isDeleted}) => {
	const {t} = useTranslation();
	const [modal, setModal] = useState<DeletedTreeElementModals>(DeletedTreeElementModals.NONE)

	const openAssignToExistingCostCenterModal = () => {
		setModal(DeletedTreeElementModals.EXISTING)
	}

	const openAssignToNewCostCenterModal = () => {
		setModal(DeletedTreeElementModals.NEW)
	}

	const openDeleteCostCenterModal = () => {
		setModal(DeletedTreeElementModals.DELETE)
	}

	const onCloseModal = () => {
		setModal(DeletedTreeElementModals.NONE)
	}

	return (
		<>
			{modal === DeletedTreeElementModals.EXISTING &&
				<AssignToExistingCostCenterModal deletedCostCenter={costCenter} onClose={onCloseModal}/>}
			{modal === DeletedTreeElementModals.NEW &&
				<AssignToNewCostCenterModal deletedCostCenter={costCenter} onClose={onCloseModal}/>}
			{modal === DeletedTreeElementModals.DELETE &&
				<DeleteCostCenterModal deletedCostCenterId={costCenter.id} onClose={onCloseModal}/>
			}
			<input
				type={"checkbox"}
				checked={isChecked}
				onChange={onChange}
			/>
			<IconFolder fill={Colors.B4B4B4}/>
			<span className={"tree-cost-center_header_label-container"}>
				<span className={"tree-cost-center_header_label-container_label"}>
					{isDeleted && <span className={"tree-cost-center_header_label-container_label_deleted"}>{`${t(
						"projects.details.tree.costCenter.labelDeleted")}: `}</span>}
					{costCenter.name}
				</span>
				{!isDeleted && ` (${t("projects.details.tree.costCenter.label")})`}
			</span>
			{isDeleted && <OptionsDropdown>
				<DropdownItem label={t("projects.details.tree.costCenter.deletedOptions.assignToNew")}
							  onClick={openAssignToExistingCostCenterModal}/>
				<DropdownItem label={t("projects.details.tree.costCenter.deletedOptions.assignToExisting")}
							  onClick={openAssignToNewCostCenterModal}/>
				<DropdownItem label={t("projects.details.tree.costCenter.deletedOptions.deleteCostCenter")}
							  onClick={openDeleteCostCenterModal}/>
			</OptionsDropdown>}
		</>
	);
};

export default TreeCostCenterHeader
