import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectActiveTool} from "../config/configSlice";
import {EditorTool} from "../../models/enums";
import ConfigHelper from "../config/ConfigHelper";
import ScaleModal from "../scale/ScaleModal";
import IconRuler from "../../icons/selectableEditorHeaderIcons/IconRuler";
import IconWithTooltip from "../../../../../../components/ui/IconWithTooltip";
import {useTranslation} from "react-i18next";
import {useScaleSetDataContext} from "../scale/ScaleSetProvider";
import {selectScaleConfig} from "../scale/scaleSlice";

type RulerButtonProps = {
	disabled?: boolean
}
export const RulerButton: React.FC<RulerButtonProps> = ({disabled}) => {

	const [isScaleOpen, setIsScaleOpen] = useState(false);
	const activeTool = useSelector(selectActiveTool);
	const scaleConfig = useSelector(selectScaleConfig);
	const dispatch = useDispatch();
	const {t} = useTranslation();
	const {openScaleSetModal} = useScaleSetDataContext()

	function onScaleModalConfirm() {
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.RULER}))
		openScaleSetModal()
		setIsScaleOpen(false)
	}

	function handleClick() {
		if (activeTool === EditorTool.RULER) {
			dispatch(configActions.switchEditorToolBack({}))
		}
		else {
			ConfigHelper.isScaleSet(scaleConfig)
				? dispatch(configActions.switchEditorTool({editorTool: EditorTool.RULER}))
				: setIsScaleOpen(true)
		}
	}

	return (
		<>
			{isScaleOpen && <ScaleModal isOpen={true}
										onClose={() => setIsScaleOpen(false)}
										onSave={onScaleModalConfirm}
										disableSave={false}/>}
			<IconWithTooltip icon={<IconRuler onClick={handleClick}
											  active={activeTool === EditorTool.RULER}
											  disabled={disabled}/>}
							 tooltipText={t("editor.tooltip.ruler")}/>
		</>
	)
};