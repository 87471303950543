import React from "react";

const IconFolderOutline: React.FC = () => {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path
				d="M20,17.99998 L4,17.99998 L4,8 L20,8 L20,17.99998 Z M20,6 L12,6 L10,4 L4,4 C2.89,4 2,4.89 2,6 L2,17.99998 C2,18.53048 2.21071,19.03918 2.58579,19.41418 C2.96086,19.78928 3.46957,19.99998 4,19.99998 L20,19.99998 C20.53041,19.99998 21.03911,19.78928 21.41421,19.41418 C21.78931,19.03918 22,18.53048 22,17.99998 L22,8 C22,6.89 21.10001,6 20,6 Z"
				fill="#1976D2"/>
		</svg>
	);
};

export default IconFolderOutline
