import React, {createContext, useState} from "react";
import {AppSettingsApiDto} from "../models/restModels";
import AppSettings from "../models/AppSettings";

const AppSettingsDataContext = createContext<AppSettingsApiDto | undefined>(undefined)

const AppSettingsProvider: React.FC = ({children}) => {

	const [settings] = useState<AppSettings>(AppSettings.fromJson(window.__APPLICATION_SETTINGS__ || {}));

	return (
		<AppSettingsDataContext.Provider value={settings}>
			{children}
		</AppSettingsDataContext.Provider>
	);
};

function useAppSettingsDataContext() {
	const context = React.useContext(AppSettingsDataContext)
	if (!context) {
		throw new Error("useAppSettingsDataContext must be used within a AppSettingsProvider")
	}
	return context
}

export {AppSettingsProvider, useAppSettingsDataContext}
