import {useTranslation} from "react-i18next";
import React from "react";
import {apiInstance} from "../../../api/api";
import {Status} from "../../../models/enums";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {SymbolPack} from "../../../models/SymbolPack";
import {getDefaultGroupPacks, isSystemSymbolGroup} from "../../project-drawings/features/editor/icons/symbol_pack/defaultPacks";
import {useQuery} from "react-query";
import {toStatus} from "../../../utils/ReactQueryUtils";
import {TIME_5_MIN_IN_MILLISECONDS} from "../../project-drawings/features/editor/constants";
import {SYMBOL_QUERY_KEY} from "../../../api/SymbolsApi";

function useSymbolPacks(groupId?: string, enabledSystemPacksIds?: string[]) {
	const {t} = useTranslation();

	const queryKey: ([string, { url: string, groupId: string }]) | undefined = React.useMemo(() => {
		if (groupId && !isSystemSymbolGroup(groupId)) {
			return [SYMBOL_QUERY_KEY, {url: apiInstance.symbolsApi.fetchSymbolPacks(groupId).url, groupId}]
		}
		return undefined
	}, [groupId])

	const {data, status} = useQuery({
		queryKey,
		enabled: queryKey !== undefined,
		queryFn: ({queryKey}) => {
			const [, {groupId}] = queryKey
			return apiInstance.symbolsApi.fetchSymbolPacks(groupId).promise()
		},
		onError: (err: any) => {
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), t("settings.tabs.symbols.fetchPacksError"));
		},
		staleTime: TIME_5_MIN_IN_MILLISECONDS
	});

	const loadStatus: Status = React.useMemo(() => {
		if (groupId) {
			if (queryKey) {
				return toStatus(status)
			}
			else {
				return Status.SUCCESS
			}
		}
		return Status.IDLE
	}, [status, groupId, queryKey])

	const symbolPacks: SymbolPack[] = React.useMemo(() => {
		if (groupId) {
			if (isSystemSymbolGroup(groupId)) {
				const defaultSymbolPacks = getDefaultGroupPacks(groupId);
				return enabledSystemPacksIds
					? defaultSymbolPacks.filter(pack => enabledSystemPacksIds.some(id => id === pack.id))
					: defaultSymbolPacks
			}
			else {
				return data ? data : [];
			}
		}
		return []
	}, [groupId, enabledSystemPacksIds, data])

	return {symbolPacks, loadStatus}
}

export default useSymbolPacks;
