import React from "react";

interface IconFillProps {
	fill: string,
}

const IconPlusFilled: React.FC<IconFillProps> = ({fill}) => {

	return (
		<svg width={12} height={12} viewBox="0 0 32 32">
			<path fill={fill}
				  d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-6v6h-4v-6h-6v-4h6v-6h4v6h6v4z"/>
		</svg>
	);
};

export default IconPlusFilled

