import React from "react";
import "./editorPanelTabContent.scss"

const EditorPanelTabContent: React.FC = ({children}) => {
	return (
		<div className={"editor-panel-tab-content"}>
			{children}
		</div>
	);
};

export default EditorPanelTabContent
