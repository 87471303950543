import React from "react";
import {SelectRectangleLayer} from "../../../../../base-konva/components/SelectRectangleLayer";
import {useSelector} from "react-redux";
import {selectStageConfig} from "../config/configSlice";
import {selectPanInProgress} from "../view/viewSlice";
import {useSymbolRecognition} from "./useSymbolRecognition";
import {SymbolRecognitionPanelStage} from "./types";
import {selectPanelStage} from "./symbolRecognitionSlice";

const SymbolRecognitionLayer: React.FC = () => {
	const stageConfig = useSelector(selectStageConfig);
	const panInProgress = useSelector(selectPanInProgress);
	const panelStage = useSelector(selectPanelStage)

	return (
		panelStage !== SymbolRecognitionPanelStage.INITIAL ?
			<SelectRectangleLayer correspondingHook={useSymbolRecognition} stageConfig={stageConfig}
								  panInProgress={panInProgress}/> : null
	)
}

export {SymbolRecognitionLayer}