import {AxiosInstance} from "axios";
import {CostCenterDrawing} from "../models/CostCenterDrawing";
import DrawingDetails from "../models/DrawingDetails";
import {PageScaleRatioApiDto, UpdateDrawingApiDto} from "../models/restModels";
import DrawingData from "../models/DrawingData";
import {DrawingVersion} from "../models/DrawingVersion";
import {DrawingType} from "../models/enums";
import DrawingCopyResult from "../models/DrawingCopyResult";

export default class DrawingsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	putDrawingData(drawingId: string, drawingData: UpdateDrawingApiDto): Promise<DrawingVersion> {
		const url = `/api/drawings/${drawingId}/data`;
		return this.axiosInstance.put(url, drawingData).then(resp => DrawingVersion.fromJson(resp.data))
	}

	fetchDrawingDetails(drawingId: string): Promise<DrawingDetails> {
		const url = `/api/drawings/${drawingId}`;
		return this.axiosInstance.get(url).then(resp => DrawingDetails.fromJson(resp.data))
	}

	deleteDrawing(drawingId: string): Promise<void> {
		const url = `/api/drawings/${drawingId}`;
		return this.axiosInstance.delete(url);
	}

	fetchDrawingData(versionId: string): Promise<DrawingData> {
		const url = `/api/drawing_versions/${versionId}`;
		return this.axiosInstance.get(url).then(resp => DrawingData.fromJson(resp.data))
	}

	fetchCostCenterDrawings(costCenterId: string): Promise<CostCenterDrawing[]> {
		const url = `/api/cost_center/${costCenterId}/drawings`;
		return this.axiosInstance.get(url).then(resp => resp.data.map((CostCenterDrawing.fromJson)));
	}

	completeDrawingVersion(drawingVersionId: string): Promise<DrawingData> {
		const url = `/api/drawing_versions/${drawingVersionId}/complete`;
		return this.axiosInstance.post(url).then(resp => DrawingData.fromJson(resp.data))
	}

	revertDrawingVersion(drawingVersionId: string): Promise<DrawingData> {
		const url = `/api/drawing_versions/${drawingVersionId}/revert`;
		return this.axiosInstance.post(url).then(resp => DrawingData.fromJson(resp.data))
	}

	copyDrawingVersion(drawingVersionId: string, drawingName: string, targetCostCenterId: string): Promise<DrawingCopyResult> {
		const url = `/api/drawing_versions/${drawingVersionId}/copy`;
		const data = {
			drawingName,
			targetCostCenterId
		}
		return this.axiosInstance.post(url, data).then(resp => DrawingCopyResult.fromJson(resp.data))
	}

	moveDrawingVersion(drawingId: string, costCenterId: string): Promise<void> {
		const url = `/api/drawings/${drawingId}/move`;
		const data = {costCenterId};
		return this.axiosInstance.put(url, data)
	}

	renameDrawing(drawingId: string, newName: string): Promise<void> {
		const url = `/api/drawings/${drawingId}/name`;
		const data = {newName};
		return this.axiosInstance.put(url, data);
	}

	lockDrawing(drawingId: string): Promise<void> {
		const url = `/api/drawings/${drawingId}/lock`;
		return this.axiosInstance.put(url)
	}

	unlockDrawing(drawingId: string): Promise<void> {
		const url = `/api/drawings/${drawingId}/unlock`;
		return this.axiosInstance.put(url)
	}

	lockDrawingVersion(drawingVersionId: string): Promise<void> {
		const url = `/api/drawing_versions/${drawingVersionId}/lock`;
		return this.axiosInstance.put(url)
	}

	unlockDrawingVersion(drawingVersionId: string): Promise<void> {
		const url = `/api/drawing_versions/${drawingVersionId}/unlock`;
		return this.axiosInstance.put(url)
	}

	postDrawingFromSource(projectId: string,
		sectionId: string,
		costCenterId: string,
		name: string,
		sourceDrawingId: string,
		drawingType: DrawingType): Promise<DrawingDetails> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/cost_centers/${costCenterId}/drawing_from_source`
		const data = {name, sourceDrawingId, drawingType}
		return this.axiosInstance.post(url, data).then(resp => DrawingDetails.fromJson(resp.data))
	}

	putDrawingScale(drawingId: string,
		planScale: number,
		pageScaleRatio?: PageScaleRatioApiDto): Promise<void> {
		const url = `/api/drawings/${drawingId}/plan_scale`;
		const data = {planScale, pageScaleRatio};
		return this.axiosInstance.put(url, data)
	}

	connectImageWithDrawing(drawingId: string, imageId: string): Promise<void> {
		const url = `/api/drawings/${drawingId}/images/${imageId}`
		const data = {drawingId, imageId}
		return this.axiosInstance.post(url, data)
	}

	removeImage(drawingId: string, imageId: string): Promise<void> {
		const url = `/api/drawings/${drawingId}/images/${imageId}`
		return this.axiosInstance.delete(url)
	}

	putDrawingsToNewCostCenter(drawingIds: string[], newCostCenterId: string): Promise<void> {
		const url = `/api/drawings/move`;
		const data = {drawingIds, costCenterId: newCostCenterId};
		return this.axiosInstance.put(url, data);
	}
}