import React, {useState} from "react";
import {configActions, selectActiveTool, selectEditorLockState, selectStageConfig} from "../config/configSlice";
import {useDispatch, useSelector} from "react-redux";
import EditorHeaderScalePanel from "./EditorHeaderScalePanel";
import SaveDrawing from "./SaveDrawing";
import "./editorHeader.scss";
import IconPenTool from "../../icons/selectableEditorHeaderIcons/IconPenTool";
import IconSnapAngle from "../../icons/selectableEditorHeaderIcons/IconSnapAngle";
import {EditorHeaderFinishShapePanel} from "./EditorHeaderFinishShapePanel";
import {EditorTool} from "../../models/enums";
import IconTextTool from "../../icons/selectableEditorHeaderIcons/IconTextTool";
import {selectEditorGuidelines, selectSnappingState, selectZoomState, viewActions} from "../view/viewSlice";
import EditorHeaderUndoPanel from "./EditorHeaderUndoPanel";
import {RulerButton} from "./RulerButton";
import {PenToolOptions} from "./options/PenToolOptions";
import {TextToolOptions} from "./options/TextToolOptions";
import {RulerOptions} from "./options/RulerOptions";
import {EditorHeaderExportPanel} from "./EditorHeaderExportPanel";
import IconRotateCw from "../../icons/unselectableEditorHeaderIcons/IconRotateCw";
import {ImageOptions} from "./options/ImageOptions";
import IconSnapPoint from "../../icons/selectableEditorHeaderIcons/IconSnapPoint";
import {ResizeButton} from "../stage-resize/ResizeButton";
import IconAreaSelection from "../../icons/selectableEditorHeaderIcons/IconAreaSelection";
import IconRotateCcw from "../../icons/unselectableEditorHeaderIcons/IconRotateCcw";
import IconFullScreen from "../../icons/unselectableEditorHeaderIcons/IconFullScreen";
import IconWithTooltip from "../../../../../../components/ui/IconWithTooltip";
import {useTranslation} from "react-i18next";
import {useZoomActions} from "../view/useZoomActions";
import ImageIcon from "../../icons/selectableEditorHeaderIcons/ImageIcon";
import IconSelect from "../../icons/selectableEditorHeaderIcons/IconSelect";
import IconBin from "../../icons/unselectableEditorHeaderIcons/IconBin";
import {useDeleteActiveItem} from "../../hooks/useDeleteActiveItem";
import IconGuidelines from "../../icons/IconGuidelines";
import CreateAsBuilt from "./CreateAsBuilt";
import {EditorHeaderVisibilityDropdown} from "./EditorHeaderVisibilityDropdown";
import IconArcTool from "../../icons/selectableEditorHeaderIcons/IconArcTool";
import SymbolRecognitionIcon from "../../icons/selectableEditorHeaderIcons/SymbolRecognitionIcon";
import SymbolRecognitionCancelModal from "../symbol-recognition/SymbolRecognitionCancelModal";
import ScaleModal from "../scale/ScaleModal";
import {useScaleSetDataContext} from "../scale/ScaleSetProvider";
import ConfigHelper from "../config/ConfigHelper";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {DrawingType} from "../../../../../../models/enums";
import {ButtonsGroup, ButtonsGroupDivider, ButtonsGroupSpacer} from "../../../../../../components/layout";
import {ZoomControls} from "../../../../../base-konva/features/view";
import {imageActions} from "../image/imageSlice";
import {getId} from "../../../../../../utils";
import {useFullscreenContext} from "../../../../../../providers/FullscreenProvider";
import {selectScaleConfig} from "../scale/scaleSlice";
import {rotateClockwise, rotateCounterClockwise} from "../../../../../base-konva/utils";

const EditorHeader = () => {
	const zoomState = useSelector(selectZoomState);
	const stageConfig = useSelector(selectStageConfig);
	const snapping = useSelector(selectSnappingState);
	const activeTool = useSelector(selectActiveTool);
	const editorLocked = useSelector(selectEditorLockState);
	const guidelines = useSelector(selectEditorGuidelines);
	const scaleConfig = useSelector(selectScaleConfig);
	const {openScaleSetModal} = useScaleSetDataContext()
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext()
	const {fullscreenHandle} = useFullscreenContext()
	const dispatch = useDispatch();
	const {t} = useTranslation();

	const {
		minZoom, maxZoom,
		handleZoomOutByPercent,
		handleZoomInByPercent,
		handleZoomReset,
	} = useZoomActions();

	const {canDeleteActiveItem, deleteActiveItem} = useDeleteActiveItem();
	const [isSymbolRecognitionCancelModal, setIsSymbolRecognitionCancelModal] = useState<boolean>(false)
	const [isScaleOpen, setIsScaleOpen] = useState(false);

	function handleAngleSnappingToggle() {
		if (snapping.point)
			dispatch(viewActions.togglePointSnapping());
		dispatch(viewActions.toggleAngleSnapping());
	}

	function handlePointSnappingToggle() {
		if (snapping.angle)
			dispatch(viewActions.toggleAngleSnapping());
		dispatch(viewActions.togglePointSnapping());
	}

	function handleButtonToggle(buttonTool: EditorTool) {
		return () => {
			activeTool === buttonTool ?
				dispatch(configActions.switchEditorToolBack({})) :
				dispatch(configActions.switchEditorTool({editorTool: buttonTool}));
		}
	}

	function handleSymbolRecognitionToggle() {
		if (!ConfigHelper.isScaleSet(scaleConfig)) {
			setIsScaleOpen(true);
			return;
		}
		if (activeTool === EditorTool.SYMBOL_RECOGNITION) {
			setIsSymbolRecognitionCancelModal(true);
		}
		else {
			if (guidelines) {
				handleGuidelinesToggle()
			}
			dispatch(configActions.switchEditorTool({editorTool: EditorTool.SYMBOL_RECOGNITION}))
		}
	}

	function handleZoomOut() {
		handleZoomOutByPercent(10)
	}

	function handleZoomIn() {
		handleZoomInByPercent(10)
	}

	function handleStageRotateCw() {
		dispatch(viewActions.setZoomState({
			...zoomState,
			rotation: rotateClockwise(zoomState.rotation)
		}))
	}

	function handleStageRotateCcw() {
		dispatch(viewActions.setZoomState({
			...zoomState,
			rotation: rotateCounterClockwise(zoomState.rotation)
		}))
	}

	function handleGuidelinesToggle() {
		dispatch(viewActions.toggleGuidelines());
	}

	const shouldDisplayExtraToolbar = !editorLocked &&
		[EditorTool.PEN, EditorTool.TEXT, EditorTool.RULER, EditorTool.IMAGE].includes(activeTool);

	const symbolRecognitionToolActive = activeTool === EditorTool.SYMBOL_RECOGNITION

	/**
	 * Symbol recognition tool is disabled for AS_BUILT and blank drawings
	 */
	const symbolRecognitionAvailable = drawingDetails?.drawingType === DrawingType.TAKE_OFF && !!drawingDetails.planId

	function onScaleModalConfirm() {
		setIsScaleOpen(false);
		openScaleSetModal()
	}

	const handleUploadedImageSave = (selectedItem: { value: string, label: string }) => {
		dispatch(imageActions.addImage({
			id: getId(),
			attachmentId: selectedItem.value,
			visible: true,
			name: selectedItem.label,
			position: {x: stageConfig.width / 2, y: stageConfig.height / 2},
			rotation: -zoomState.rotation,
			scale: {x: 1, y: 1},
		}))
	}

	return (
		<>
			{isScaleOpen && <ScaleModal
				isOpen={true}
				onClose={() => setIsScaleOpen(false)}
				onSave={onScaleModalConfirm}
				disableSave={false}
			/>}
			{isSymbolRecognitionCancelModal &&
				<SymbolRecognitionCancelModal onClose={() => {setIsSymbolRecognitionCancelModal(false)}}/>}
			<div className={"editor-header"}>
				<div className={"editor-header_main"}>
					<ButtonsGroup>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.selectMeasurement")}
							icon={(
								<IconSelect
									active={activeTool === EditorTool.MEASUREMENT_SELECT}
									disabled={editorLocked || symbolRecognitionToolActive}
									onClick={handleButtonToggle(EditorTool.MEASUREMENT_SELECT)}
								/>
							)}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.arcMode")}
							icon={(
								<IconArcTool
									active={activeTool === EditorTool.ARC}
									disabled={editorLocked || symbolRecognitionToolActive}
									onClick={handleButtonToggle(EditorTool.ARC)}
								/>
							)}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.selection")}
							icon={(
								<IconAreaSelection
									active={activeTool === EditorTool.GROUP_MEASUREMENT_SELECT}
									disabled={editorLocked || symbolRecognitionToolActive}
									onClick={handleButtonToggle(EditorTool.GROUP_MEASUREMENT_SELECT)}
								/>
							)}
						/>
						<ResizeButton disabled={editorLocked || symbolRecognitionToolActive}/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.fullScreen")}
							icon={(
								<IconFullScreen
									active={fullscreenHandle.active}
									onClick={fullscreenHandle.active ? fullscreenHandle.exit : fullscreenHandle.enter}
									disabled={symbolRecognitionToolActive}
								/>
							)}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.guidelines")}
							icon={(
								<IconGuidelines
									active={guidelines}
									onClick={handleGuidelinesToggle}
									disabled={symbolRecognitionToolActive}
								/>
							)}
						/>
						{symbolRecognitionAvailable ? (
							<IconWithTooltip
								tooltipText={t("editor.tooltip.symbolRecognition")}
								icon={(
									<SymbolRecognitionIcon
										active={activeTool === EditorTool.SYMBOL_RECOGNITION}
										onClick={handleSymbolRecognitionToggle}
										disabled={editorLocked}
									/>
								)}
							/>
						) : null}
						<IconWithTooltip
							tooltipText={t("editor.tooltip.rotateLeft")}
							icon={<IconRotateCcw onClick={handleStageRotateCcw} disabled={symbolRecognitionToolActive}/>}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.rotateRight")}
							icon={<IconRotateCw onClick={handleStageRotateCw} disabled={symbolRecognitionToolActive}/>}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.penTool")}
							icon={(
								<IconPenTool
									active={activeTool === EditorTool.PEN}
									onClick={handleButtonToggle(EditorTool.PEN)}
									disabled={editorLocked || symbolRecognitionToolActive}
								/>
							)}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.textTool")}
							icon={(
								<IconTextTool
									active={activeTool === EditorTool.TEXT}
									onClick={handleButtonToggle(EditorTool.TEXT)}
									disabled={editorLocked || symbolRecognitionToolActive}
								/>
							)}
						/>
						<RulerButton disabled={editorLocked || symbolRecognitionToolActive}/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.imageTool")}
							icon={(
								<ImageIcon
									active={activeTool === EditorTool.IMAGE}
									onClick={handleButtonToggle(EditorTool.IMAGE)}
									disabled={editorLocked || symbolRecognitionToolActive}
								/>
							)}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<ZoomControls
							currentZoom={Math.round(zoomState.scale * 100)}
							minZoom={minZoom}
							maxZoom={maxZoom}
							handleZoomOut={handleZoomOut}
							handleZoomReset={handleZoomReset}
							handleZoomIn={handleZoomIn}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.snapAngle")}
							icon={(
								<IconSnapAngle
									onClick={handleAngleSnappingToggle}
									active={snapping.angle}
									disabled={editorLocked || activeTool === EditorTool.COUNT || symbolRecognitionToolActive}
								/>
							)}
						/>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.snapPoint")}
							icon={(
								<IconSnapPoint
									onClick={handlePointSnappingToggle} active={snapping.point}
									disabled={editorLocked || symbolRecognitionToolActive}
								/>
							)}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<EditorHeaderUndoPanel disabled={editorLocked || symbolRecognitionToolActive}/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<IconWithTooltip
							tooltipText={t("editor.tooltip.delete")}
							icon={(
								<IconBin
									onClick={deleteActiveItem}
									disabled={editorLocked || !canDeleteActiveItem || symbolRecognitionToolActive}
								/>
							)}
						/>
					</ButtonsGroup>
					<ButtonsGroupDivider/>
					<ButtonsGroup>
						<EditorHeaderVisibilityDropdown disabled={symbolRecognitionToolActive}/>
					</ButtonsGroup>
					<ButtonsGroupSpacer/>
					<ButtonsGroup customClass="scale-buttons">
						<CreateAsBuilt disabled={editorLocked || symbolRecognitionToolActive}/>
						<EditorHeaderScalePanel disabled={editorLocked || symbolRecognitionToolActive}/>
					</ButtonsGroup>
					<ButtonsGroup customClass="save-finish-buttons">
						<EditorHeaderFinishShapePanel/>
						<SaveDrawing disabled={symbolRecognitionToolActive}/>
						<EditorHeaderExportPanel disabled={symbolRecognitionToolActive}/>
					</ButtonsGroup>
				</div>
				{shouldDisplayExtraToolbar &&
					<div className={"editor-header_options"}>
						{activeTool === EditorTool.PEN ? <PenToolOptions/> : null}
						{activeTool === EditorTool.TEXT ? <TextToolOptions/> : null}
						{activeTool === EditorTool.RULER ? <RulerOptions/> : null}
						{activeTool === EditorTool.IMAGE ? <ImageOptions handleImageSave={handleUploadedImageSave}/> : null}
					</div>}
			</div>
		</>
	);
};

export default EditorHeader
