type Argument = string | Record<string, boolean>;

/**
 * Returns formatted className string based on provided string boolean record or className
 * @param args
 */
export function get(...args: Argument[]) {
	const classes: (string | null)[] = []

	for (let arg of args) {
		if (typeof arg === "string") {
			classes.push(arg)
		}
		else {
			const classRecord = arg;
			const classNames = Object.keys(classRecord).map(className => {
				return classRecord[className] ? className : null
			})
			classes.push(...classNames)
		}
	}

	return classes.filter(Boolean).join(" ")
}