import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {selectStageConfig} from "../config/configSlice";
import {selectPanInProgress, selectSnappingState} from "../view/viewSlice";
import {BaseScaleLayer} from "../../../../../base-konva/features/scale";
import {scaleActions, selectScalePoints} from "./scaleSlice";

type ScaleLayerProps = {
	listening?: boolean
}
export const ScaleLayer: React.FC<ScaleLayerProps> = ({listening = false}) => {
	const dispatch = useDispatch();
	const scalePoints = useSelector(selectScalePoints);
	const snapping = useSelector(selectSnappingState);
	const stageConfig = useSelector(selectStageConfig);
	const panInProgress = useSelector(selectPanInProgress);

	return (
		<BaseScaleLayer
			listening={listening}
			addScalePoint={position => {dispatch(scaleActions.addScalePoint(position))}}
			scalePoints={scalePoints}
			snapping={snapping}
			stageConfig={stageConfig}
			panInProgress={panInProgress}
		/>
	)
};
