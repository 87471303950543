import React from "react";
import "./tableOverlaySpinner.scss";
import InlineSpinner from "../InlineSpinner";

interface TableOverlaySpinnerProps {
	loading: boolean,
}

const TableOverlaySpinner: React.FC<TableOverlaySpinnerProps> = ({loading, children}) => {
	return (
		<div className={"table-overlay"}>
			{loading ? <div className={"table-overlay_spinner"}><InlineSpinner width={40} height={40}/></div> : null}
			{children}
		</div>
	);
};

export default TableOverlaySpinner
