import {PlanUploadData, PlanUploadPageData} from "../types";
import {useTranslation} from "react-i18next";
import {usePlanUploadExporter} from "./usePlanUploadExporter";
import {useState} from "react";
import {Status} from "../../../../../models/enums";
import {apiInstance} from "../../../../../api/api";
import {clearStoresDirtyFlag, resetPlanUploadStoreInstance} from "../planUploadStore";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import {OnSuccess} from "../../../../../utils/CallbackUtils";


function useSavePlanTemplatePage() {
	const {generateFileFromPlanPage} = usePlanUploadExporter()

	async function savePlanTemplatePage(
		projectId: string,
		templateId: string,
		planPage: PlanUploadPageData,
		planPageScale: number
	): Promise<PlanUploadPageData> {
		const file = await generateFileFromPlanPage(planPage)
		const {id: planPageAttachmentId} = await apiInstance.attachmentApi.uploadFile(file)

		const newPageData = {
			pageNumber: planPage.pageNumber,
			pageScaleRatio: planPage.pageScaleRatio,
			attachmentId: planPageAttachmentId,
			planScale: planPageScale,
			title: planPage.title,
		}

		await apiInstance.projectsApi.createPlanTemplatePage(
			projectId,
			templateId,
			newPageData
		)

		return {...newPageData, existsExternally: true}
	}

	return {savePlanTemplatePage}
}


export function useSaveAllPlanPages({
	triggerEditorIsDirtyCheck
}: {
	triggerEditorIsDirtyCheck: () => void
}) {

	const {t} = useTranslation()
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {savePlanTemplatePage} = useSavePlanTemplatePage()

	async function saveAllPlanPages(
		projectId: string,
		editorPlanTemplateData: PlanUploadData,
		onSuccess: OnSuccess
	) {
		setSaveStatus(Status.LOADING)
		try {
			for (let page of editorPlanTemplateData.pages) {
				if (!page.planScale) {
					throw new Error("Cannot save page without a scale")
				}
				await savePlanTemplatePage(projectId, editorPlanTemplateData.id, page, page.planScale);
			}

			clearStoresDirtyFlag();
			triggerEditorIsDirtyCheck();

			setSaveStatus(Status.SUCCESS)
			NotificationService.successNotification(
				t("common.success"),
				t("planUploadEditor.saveAllPlanPages.saveAllPlanPagesSuccessDesc")
			)
			onSuccess()
		}
		catch (e) {
			setSaveStatus(Status.ERROR)
			LoggerService.logError(e)
			NotificationService.errorNotification(
				t("common.error"),
				t("planUploadEditor.saveAllPlanPages.saveAllPlanPagesErrorDesc")
			)
		}
	}

	return {
		saveStatus,
		saveAllPlanPages
	}
}


export function useSavePlanPageData({
	editorPlanTemplateData,
	updateEditorPlanPage,
}: {
	editorPlanTemplateData: PlanUploadData | undefined
	updateEditorPlanPage: (newPage: PlanUploadPageData) => void
}) {

	const {t} = useTranslation()
	const {savePlanTemplatePage} = useSavePlanTemplatePage()
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);

	const canSavePlanPage = (currentEditorPlanPage: PlanUploadPageData | undefined) =>
		saveStatus !== Status.LOADING &&
		!!currentEditorPlanPage &&
		!!editorPlanTemplateData &&
		currentEditorPlanPage.planScale !== undefined

	async function savePlanPage(
		projectId: string,
		currentEditorPlanPage: PlanUploadPageData | undefined,
		onSuccess: OnSuccess
	) {
		if (!canSavePlanPage(currentEditorPlanPage) || !currentEditorPlanPage || !editorPlanTemplateData) {
			return;
		}
		try {
			setSaveStatus(Status.LOADING)
			const newPageData = await savePlanTemplatePage(
				projectId,
				editorPlanTemplateData.id,
				currentEditorPlanPage,
				currentEditorPlanPage.planScale!
			)
			updateEditorPlanPage(newPageData)
			resetPlanUploadStoreInstance(newPageData.pageNumber, newPageData)

			NotificationService.successNotification(
				t("common.success"),
				t("planUploadEditor.savePlanPage.savePlanPageSuccessDesc")
			)
			setSaveStatus(Status.SUCCESS)
			onSuccess()
		}
		catch (e) {
			setSaveStatus(Status.ERROR)
			LoggerService.logError(e)
			NotificationService.errorNotification(
				t("common.error"),
				t("planUploadEditor.savePlanPage.savePlanPageErrorDesc")
			)
		}
	}

	return {
		canSavePlanPage,
		saveStatus,
		savePlanPage
	}
}