import {SymbolRecognitionResultDataApiDto} from "./restModels";
import {SymbolRecognitionSummaryStatus} from "./enums";
import LoggerService from "../services/LoggerService";
import {
	SymbolRecognitionResultsReferentialData
} from "../features/project-drawings/features/editor/features/symbol-recognition/types";

export class SymbolRecognitionResultData {
	constructor(
		public readonly status: SymbolRecognitionSummaryStatus,
		public readonly error?: string,
		public readonly result?: { results: SymbolRecognitionResultsReferentialData[] }
	) {}

	public static fromJson(json: SymbolRecognitionResultDataApiDto): SymbolRecognitionResultData {

		let status = SymbolRecognitionSummaryStatus[json.data.status];
		if (status === undefined) {
			const error = new Error(`Unknown symbol recognition status: ${json.data.status}`);
			LoggerService.logError(error);
			throw error;
		}

		return new SymbolRecognitionResultData(
			status,
			json.data.error,
			{
				results: json.data.result?.results ?? []
			}
		);
	}
}
