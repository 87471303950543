import {useInternalDataContext, useMaterialModalContext} from "../../../MaterialModalProvider";
import {Search} from "../../../components/Search";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import Pagination from "../../../../../../../../../../components/ui/Pagination";
import React from "react";
import TableOverlaySpinner from "../../../../../../../../../../components/ui/TableOverlaySpinner";
import GroupsTable from "../../../components/GroupsTable";
import {ContentFooter} from "./ContentFooter";
import {ItemsTable} from "../../../components/ItemsTable";
import {FavouriteSwitch} from "../../../components/FavouriteSwitch";

export function PrebuildTabContent() {
	const {closeModal} = useMaterialModalContext()
	const {materialChangeData} = useInternalDataContext();
	const {
		search, overlay, breadcrumbs, groupsTable, favouriteSwitch, itemsTable, pagination, contentFooter
	} = materialChangeData.preBuilds

	return (
		<>
			<div className="material-modal_container_content">
				<Search {...search}/>
				<TableOverlaySpinner {...overlay}>
					<Breadcrumbs {...breadcrumbs}/>
					<GroupsTable {...groupsTable}/>
					<FavouriteSwitch {...favouriteSwitch}/>
					<ItemsTable {...itemsTable} />
					<Pagination {...pagination}/>
				</TableOverlaySpinner>
			</div>
			<ContentFooter {...contentFooter} closeModal={closeModal}/>
		</>
	)
}