import {useTranslation} from "react-i18next";
import React, {useCallback, useState} from "react";
import JSZip from "jszip";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import TextInput from "../../project-drawings/features/editor/components/TextInput";
import Button from "../../../components/ui/Button";
import {formatBytes} from "../../../utils/ConversionUtils";
import IconClose from "../../../components/icons/IconClose";
import {LoadedZipInfo} from "../../../models/interfaces";
import {SymbolPackCreateStep} from "../../../models/enums";
import {removeNotAllowedCharsFromFilename} from "../../../utils/TextUtils";
import SingleFileDropzone from "../../../components/ui/SingleFileDropzone";
import {Colors} from "../../../styles";

export function useSymbolPackFormControl(skipFirstStep: boolean = false) {

	const {t} = useTranslation();
	const [symbolPackName, setSymbolPackName] = useState("");
	const [step, setStep] = useState<SymbolPackCreateStep>(skipFirstStep ? SymbolPackCreateStep.DRAG_AND_DROP : SymbolPackCreateStep.NO_SYMBOL_PACK);
	const [loadedZip, setLoadedZip] = useState<LoadedZipInfo | null>(null);

	function reset() {
		setSymbolPackName("")
		setStep(skipFirstStep ? SymbolPackCreateStep.DRAG_AND_DROP : SymbolPackCreateStep.NO_SYMBOL_PACK)
		setLoadedZip(null)
	}

	const onDrop = useCallback(async (acceptedFiles: File[]) => {
		const zipFile = acceptedFiles[0]
		if (!zipFile) return;

		const symbolFiles: File[] = []
		try {
			const zip = await JSZip.loadAsync(zipFile)
			const filenames = Object.keys(zip.files);
			for (let filename of filenames) {
				if (!zip.files[filename].dir && filename.toLowerCase().endsWith(".svg") && !filename.startsWith("__MACOSX")) {
					const fileBlob = await zip.files[filename].async("blob")
					const saveFileName = removeNotAllowedCharsFromFilename(filename);
					symbolFiles.push(new File([fileBlob], saveFileName))
				}
			}
		}
		catch (e) {
			LoggerService.logError(e);
			NotificationService.errorNotification(
				t("common.error"),
				t("settings.symbolSettings.createSymbolGroupModal.zipLoadErrorDesc")
			)
			return
		}

		setStep(SymbolPackCreateStep.ZIP_FILES_LOADED)
		setLoadedZip({
			name: zipFile.name,
			SVGs: symbolFiles
		})
	}, [t])

	function renderSymbolPackInput() {
		return (
			<div className={"create-symbol-group-modal_field"}>
				<div className="create-symbol-group-modal_field_label">
					{t("settings.symbolSettings.createSymbolGroupModal.symbolPackName")}
				</div>
				<TextInput
					value={symbolPackName}
					onChange={evt => setSymbolPackName(evt.target.value)}
				/>
			</div>
		)
	}

	function render() {
		switch (step) {
			case SymbolPackCreateStep.NO_SYMBOL_PACK:
				return (
					<>
						<div className={"create-symbol-group-modal_field"}>
							<div className={"create-symbol-group-modal_field_label secondary"}>
								{t("settings.symbolSettings.createSymbolGroupModal.noSymbolPacksAdded")}
							</div>
						</div>
						<div className={"create-symbol-group-modal_field center"}>
							<Button
								label={`+ ${t("settings.symbolSettings.createSymbolGroupModal.symbolPack")}`}
								variant={"secondary"}
								onClick={() => {
									setStep(SymbolPackCreateStep.DRAG_AND_DROP)
								}}
							/>
						</div>
					</>
				)
			case SymbolPackCreateStep.DRAG_AND_DROP:
				return (
					<>
						{renderSymbolPackInput()}
						<div className={"create-symbol-group-modal_field"}>
							<SingleFileDropzone onDrop={onDrop}
												accept={".zip, application/zip"}
												text={t("settings.symbolSettings.createSymbolGroupModal.dropzoneInfo")}/>
						</div>
					</>
				)
			case SymbolPackCreateStep.ZIP_FILES_LOADED:
				return loadedZip ? (
					<>
						{renderSymbolPackInput()}
						<div className={"create-symbol-group-modal_row"}>
							<div className={"create-symbol-group-modal_row_field"}>{loadedZip.name}</div>
							<div className={"create-symbol-group-modal_row_field"}>
								({loadedZip.SVGs.length} SVGs, {
								formatBytes(loadedZip.SVGs.reduce(
									(previous, current) => previous + current.size, 0)
								)})
							</div>
							<div className={"create-symbol-group-modal_row_divider"}/>
							<div
								className={"create-symbol-group-modal_row_remove-btn"}
								onClick={() => {
									setStep(SymbolPackCreateStep.DRAG_AND_DROP)
									setLoadedZip(null)
								}}>
								<IconClose color={Colors.WHITE}/>
							</div>
						</div>
					</>
				) : null
			default:
				return null;
		}
	}

	return {
		loadedZip, symbolPackName,
		renderSymbolPackFormControl: render,
		resetSymbolPackFormControl: reset
	}
}