import React from "react";

interface InlineSpinnerProps {
	width?: number,
	height?: number,
}

const InlineSpinner: React.FC<InlineSpinnerProps> = ({width, height}) => {


	return (
		<svg viewBox="0 0 100 100" width={`${width || 18}px`} height={`${height || 18}px`}>
			<circle cx="50" cy="50" fill="none" stroke="#1976D2" strokeWidth="10" r="35"
					strokeDasharray="164.93361431346415 56.97787143782138">
				<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s"
								  values="0 50 50;360 50 50" keyTimes="0;1"/>
			</circle>
		</svg>
	);
};

export default InlineSpinner


