import React from "react";
import {matchPath, Redirect, Route, Switch, useLocation} from "react-router-dom";
import {ProjectDetailProvider} from "../providers/ProjectDetailProvider";
import {ProjectDetailsByUuid} from "./ProjectDetailsByUuid";
import {ProjectDetailsSimproIdAccessLoader, ProjectDetailsSimproIdLoader} from "./ProjectDetailsBySimproId";
import {paths} from "../../navigator";

export const projectPaths = [
	paths.takeoffs_quotes_details_projectId,
	paths.takeoffs_jobs_details_projectId
]

export const ProjectDetailsRoutes = () => {
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const redirectUrl = type === "quotes"
		? paths.takeoffs_quotes_details_projectId
		: paths.takeoffs_jobs_details_projectId

	const projectLoaderPaths = [paths.takeoffs_quotes_loader_simproId, paths.takeoffs_jobs_loader_simproId]
	const projectAccessLoaderPaths = [paths.takeoffs_quote_simproId, paths.takeoffs_job_simproId]
	const projectIdMatch = matchPath<{ projectId: string }>(location.pathname, {path: projectPaths})
	const projectId = projectIdMatch?.params?.projectId;

	return (
		<ProjectDetailProvider projectId={projectId}>
			<Switch>
				<Route component={ProjectDetailsByUuid} path={projectPaths}/>
				<Route component={ProjectDetailsSimproIdAccessLoader} path={projectAccessLoaderPaths}/>
				<Route exact component={ProjectDetailsSimproIdLoader} path={projectLoaderPaths}/>
				<Redirect to={redirectUrl}/>
			</Switch>
		</ProjectDetailProvider>
	);
}
