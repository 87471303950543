import React from "react";
import {useTranslation} from "react-i18next";
import "./dataFetchError.scss";

interface DataFetchErrorProps {
	message?: string,
}

const DataFetchError: React.FC<DataFetchErrorProps> = ({message}) => {

	const {t} = useTranslation();

	return (
		<div className={"data-fetch-error"}>
			{message?.length ? message : t("common.fetchErrorDesc")}
		</div>
	);
};

export default DataFetchError
