import React from "react";
import "./page.scss";

const Page: React.FC = ({children}) => {
	return (
		<div className={"page"}>
			{children}
		</div>
	);
};

export default Page
