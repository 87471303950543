import React, {useEffect, useRef, useState} from "react";
import {useUserSettingsDataContext} from "../../../../providers/UserSettingsProvider";
import Konva from "konva";
import {ShapeDragState} from "../../../project-drawings/features/editor/models/editor";
import {useCursorStyle} from "../../../project-drawings/features/editor/hooks/useCursorStyle";
import {useTranslation} from "react-i18next";
import {
	calculateSizes,
	containerHeight,
	getDefaultPosition,
	MIN_WIDTH,
	rectLeftPadding,
	textHorizontalPadding
} from "../../../project-drawings/features/editor/features/scaleInfo/scaleInfoUtils";
import {KonvaEventObject} from "konva/types/Node";
import {CursorStyle, TextStyle} from "../../enums";
import {getPointerPosition} from "../../utils";
import {Vector2d} from "konva/types/types";
import {roundNumber} from "../../../../utils/NumberUtils";
import {Colors} from "../../../../styles";
import {UnitsType} from "../../../../models/enums";
import {Group, Rect, Text, Transformer} from "react-konva";
import {BaseStageConfig, ScaleInfoState} from "../../types";

export type BaseScaleInfoLayerActions = {
	switchEditorTool: () => void,
	switchEditorToolBack: (evt: KonvaEventObject<MouseEvent | TouchEvent>) => void,
}

interface BaseScaleInfoLayerProps {
	showTransformer?: boolean,
	scale: number | null,
	stageConfig: BaseStageConfig,
	editorLocked: boolean,
	actionsRef: React.MutableRefObject<BaseScaleInfoLayerActions>,
	isEditorToolScaleInfo: boolean
	scaleInfoState: ScaleInfoState,
	setScaleInfoState: (state: ScaleInfoState) => void
}

export const BACKGROUND_RECT_SCALE_INFO_LAYER = "background_rect_scale_info_layer"

export const BaseScaleInfoLayer: React.FC<BaseScaleInfoLayerProps> = ({
	showTransformer,
	scale,
	stageConfig,
	editorLocked,
	actionsRef,
	scaleInfoState,
	setScaleInfoState,
	isEditorToolScaleInfo
}) => {
	const {settings} = useUserSettingsDataContext();
	const [shapeDragState, setShapeDragState] = useState<ShapeDragState>({isDragging: false});
	const transformerRef = useRef<Konva.Transformer>(null);
	const groupRef = useRef<Konva.Group>(null);
	const {changeCursor} = useCursorStyle();
	const {t} = useTranslation();

	const [transformScaleInfoState, setTransformScaleInfoState] = useState<ScaleInfoState | null>(null);
	const renderScaleInfoState = transformScaleInfoState ?? scaleInfoState

	useEffect(() => {
		if (transformerRef.current && groupRef.current) {
			transformerRef.current.nodes([groupRef.current]);
			transformerRef.current.getLayer()?.batchDraw();
		}
	}, [scale, showTransformer, transformerRef, groupRef]);

	const rectWidth = renderScaleInfoState.unitWidth / 2;

	const onDragStart = (evt: KonvaEventObject<DragEvent>) => {
		actionsRef.current.switchEditorTool();
		changeCursor({event: evt, cursor: CursorStyle.MOVE});
		const startPointerPosition = getPointerPosition(evt);
		if (startPointerPosition) {
			setShapeDragState({isDragging: true, startPointerPosition});
		}
	};

	const onDragMove = (evt: KonvaEventObject<DragEvent>) => {
		const mousePos = getPointerPosition(evt);
		if (mousePos) {
			setShapeDragState(prevState => {
				let positionDelta: Vector2d | undefined;
				if (prevState.startPointerPosition) {
					positionDelta = {
						x: mousePos.x - prevState.startPointerPosition.x,
						y: mousePos.y - prevState.startPointerPosition.y
					};
				}
				return {...prevState, positionDelta};
			})
		}
	};

	const onDragEnd = (evt: KonvaEventObject<DragEvent>) => {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT});
		if (shapeDragState.positionDelta) {
			const x = (renderScaleInfoState.position?.x || 0) + shapeDragState.positionDelta.x;
			const y = (renderScaleInfoState.position?.y || 0) + shapeDragState.positionDelta.y;
			setScaleInfoState({...renderScaleInfoState, position: {x, y}});
		}
		setShapeDragState({isDragging: false});
	};

	function handleTransform(evt: KonvaEventObject<DragEvent>) {
		// unallowed rotation fix - if left anchor is dragged beyond right anchor position (and vice versa)
		if (evt.target.rotation() !== 0) {
			evt.target.rotation(0);
			evt.target.scaleY(1);
		}
		const newScale = evt.target.scaleX();
		let newUnitFactor = renderScaleInfoState.unitFactor * newScale;
		const newSizes = calculateSizes((scale || 0), newUnitFactor, settings, renderScaleInfoState);
		const newX = evt.target.x();
		// if deltaX is not 0, it means group was transformed by dragging left anchor, if it equals 0 - by right one.
		// when group is transformed by dragging left anchor, we have to calculate new x position of element.
		// when group is transformed by dragging right anchor, x is not changing, but konva returns numbers with small differences
		// on far decimal places and deltaX value is not real - rounding fixes that problem
		const deltaX = roundNumber(newX, 4)! - roundNumber((renderScaleInfoState.position?.x || 0), 4)!;
		if (newSizes.containerWidth > MIN_WIDTH) {
			const position = deltaX === 0 ? renderScaleInfoState.position : calculatePosition(newX, newSizes);
			setTransformScaleInfoState({...newSizes, position});
		}
		else {
			evt.target.x(renderScaleInfoState.position!.x);
			evt.target.y(renderScaleInfoState.position!.y);
		}
		groupRef.current?.scaleX(1);
	}

	function onTransformEnd() {
		if (transformScaleInfoState && scale) {
			const {position, unitWidth, unitFactor} = transformScaleInfoState
			const sizesToSet = calculateSizes(scale, unitFactor, settings, scaleInfoState, unitWidth)
			setScaleInfoState({
				...sizesToSet,
				position: position || getDefaultPosition(stageConfig, sizesToSet.containerWidth),
				visible: true
			});
		}
		setTransformScaleInfoState(null)
	}

	/**
	 * Calculates new x position (left anchor transformation only)
	 * Moves x by value of container widths difference - this way end of element is in same place like before transform.
	 * @param newX
	 * @param newSizes
	 */
	const calculatePosition = (newX: number, newSizes: ScaleInfoState): Vector2d => {
		const deltaContainer = newSizes.containerWidth - renderScaleInfoState.containerWidth;
		return {...renderScaleInfoState.position!, x: (renderScaleInfoState.position?.x || 0) - deltaContainer};
	};

	const rectStyle = {
		height: 9,
		stroke: Colors.MIDGREY,
		strokeWidth: 1,
		y: 34
	};

	const textStyle = {
		fill: Colors.MIDGREY,
		fontSize: 14,
		fontStyle: TextStyle.BOLD,
		fontFamily: "Roboto",
		y: 13
	};

	const unitKeyTranslations = {
		[UnitsType.METRIC]: t("common.units.meterSymbol"),
		[UnitsType.IMPERIAL]: t("common.units.footSymbol")
	}

	return renderScaleInfoState.position && scale && renderScaleInfoState.visible ? (
		<Group
			onMouseDown={evt => {actionsRef.current.switchEditorToolBack(evt)}}
			onTouchStart={evt => {actionsRef.current.switchEditorToolBack(evt)}}
		>
			{isEditorToolScaleInfo ? (
				<Rect
					name={BACKGROUND_RECT_SCALE_INFO_LAYER}
					width={stageConfig.width}
					height={stageConfig.height}
				/>
			) : null}
			<Group ref={groupRef}
				   x={renderScaleInfoState.position.x}
				   y={renderScaleInfoState.position.y}
				   draggable={true}
				   listening={!editorLocked}
				   onDragStart={onDragStart}
				   onTransform={handleTransform}
				   onTransformEnd={onTransformEnd}
				   onDragMove={onDragMove}
				   onDragEnd={onDragEnd}
				   onClick={() => {actionsRef.current.switchEditorTool()}}
				   onTap={() => {actionsRef.current.switchEditorTool()}}>
				<Rect width={renderScaleInfoState.containerWidth} height={containerHeight} cornerRadius={35}
					  fill={Colors.LIGHTGREY}/>
				<Rect width={rectWidth} {...rectStyle} x={rectLeftPadding} fill={Colors.MIDGREY}/>
				<Rect width={rectWidth} {...rectStyle} x={rectWidth + rectLeftPadding}/>
				<Text text={"0"} {...textStyle} x={textHorizontalPadding}/>
				<Text text={`${roundNumber(renderScaleInfoState.unitFactor, 2)}${
					unitKeyTranslations[settings?.measurementSystem || UnitsType.METRIC]}`}
					  {...textStyle}
					  x={textHorizontalPadding + renderScaleInfoState.unitWidth}/>
			</Group>
			{showTransformer && (
				<Transformer
					ref={transformerRef}
					rotateEnabled={false}
					enabledAnchors={["middle-right", "middle-left"]}
				/>
			)}
		</Group>
	) : null;
};
