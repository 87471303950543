import React from "react";
import {Status} from "../../../models/enums";

const defaultState: UseImageState = {image: undefined, status: Status.LOADING};

type UseImageState = {
	image: HTMLImageElement | undefined
	status: Status
}

export async function loadImage(url: string, crossOrigin?: string): Promise<HTMLImageElement> {
	return new Promise((resolve, reject) => {
		if (!url) {
			return reject(new Error('No url provided'));
		}

		const img = document.createElement('img');

		function onload() {
			resolve(img)
			cleanup();
		}

		function onerror(e: ErrorEvent) {
			reject(e)
			cleanup()
		}

		function cleanup() {
			img.removeEventListener('load', onload);
			img.removeEventListener('error', onerror);
		}

		img.addEventListener('load', onload);
		img.addEventListener('error', onerror);
		crossOrigin && (img.crossOrigin = crossOrigin);
		img.src = url;
	})
}

export function useImage(url: string, crossOrigin?: string, loadData: boolean = true): [HTMLImageElement | undefined, Status] {
	const res = React.useState<UseImageState>(defaultState);
	const image = res[0].image;
	const status = res[0].status;

	const setState = res[1];

	React.useEffect(
		function() {
			if (!(url && loadData)) return;

			loadImage(url, crossOrigin).then(image => {
				setState({image, status: Status.SUCCESS});
			}).catch(() => {
				setState({image: undefined, status: Status.ERROR});
			})

			return function cleanup() {
				setState(defaultState);
			};
		},
		[url, crossOrigin, loadData, setState]
	);

	return [image, status];
}