import React from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import {DrawingType, Status} from "../../../../../models/enums";
import "./modalContent.scss";
import useVersionComplete from "../../../hooks/useVersionComplete";
import {SelectedDrawingVersion} from "../../../features/drawing-exporter";

interface CompleteVersionModalProps {
	onSuccess: () => void,
	onClose: () => void,
	drawingType: DrawingType
	selectedDrawingVersion: SelectedDrawingVersion
}

const CompleteVersionModal: React.FC<CompleteVersionModalProps> = ({
	onSuccess,
	onClose,
	drawingType,
	selectedDrawingVersion
}) => {

	const {t} = useTranslation()
	const {saveStatus, completeDrawingVersion} = useVersionComplete(drawingType);

	async function handleSubmit() {
		await completeDrawingVersion(selectedDrawingVersion, onSuccess);
	}

	const translations = {
		"title": t("projects.details.tree.completeRevertModal.completeTitle"),
		"content": t("projects.details.tree.completeRevertModal.completeContent")
	}

	const isLoading = saveStatus === Status.LOADING;

	return (
		<EditValueModal
			isOpen={true}
			onClose={onClose}
			onSave={handleSubmit}
			title={translations["title"]}
			disableSave={isLoading}
			isLoading={isLoading}
			saveLabel={t("projects.details.tree.completeRevertModal.completeButton")}
		>
			<div className={"modal-content_message"}>
				{translations["content"]}
			</div>
		</EditValueModal>
	);
};

export default CompleteVersionModal;
