import {AxiosInstance} from "axios";
import Company from "../models/Company";
import {JobSummaryApiDto} from "../models/restModels";
import {getPaginationFromHeaders} from "../utils/PaginationUtils";
import {PaginatedResponse} from "../models/interfaces";
import QuoteSummary from "../models/QuoteSummary";
import JobSummary from "../models/JobSummary";
import {JobStage, QuoteStage} from "../models/enums";
import {paramsSerializer} from "../utils/ApiUtils";

export const COMPANY_QUOTES_WITH_STAGE_QUERY_KEY = "COMPANY_QUOTES_WITH_STAGE_QUERY_KEY"
export const COMPANY_JOBS_WITH_STAGE_QUERY_KEY = "COMPANY_JOBS_WITH_STAGE_QUERY_KEY"

export default class CompaniesApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchCompany(companyId: number): Promise<Company> {
		const url = `/api/companies/${companyId}`;
		return this.axiosInstance.get(url).then(resp => Company.fromJson(resp.data));
	}

	fetchCompanyQuotesWithStage(stage: QuoteStage) {
		return (page: number, size: number, orderBy: string, query?: string): Promise<PaginatedResponse<QuoteSummary>> => {
			const params = {
				page,
				size,
				order_by: orderBy,
				query,
				// Backend default stage is "open"
				stage: stage.toLowerCase()
			};
			const url = "/api/quotes";
			return this.axiosInstance.get(url, {params, paramsSerializer}).then(resp => {
				const paginationConfig = getPaginationFromHeaders(resp.headers);
				const records = resp.data.map(QuoteSummary.fromJson);
				return ({records, ...paginationConfig})
			});
		}
	}

	fetchCompanyJobsWithStage(stage: JobStage) {
		return (page: number, size: number, orderBy: string, query?: string): Promise<PaginatedResponse<JobSummary>> => {
			const params = {
				page,
				size,
				order_by: orderBy,
				query,
				// Backend default stage is "pending"
				stage: stage.toLowerCase()
			};
			const url = `/api/jobs`;
			return this.axiosInstance.get(url, {params, paramsSerializer}).then(resp => {
				const paginationConfig = getPaginationFromHeaders(resp.headers);
				const records = resp.data.map((j: JobSummaryApiDto) => JobSummary.fromJson(j));
				return ({records, ...paginationConfig})
			});
		}
	}
}