import React from "react";
import CommonDropdown from "./CommonDropdown";
import "./commonDropdown.scss";
import IconMore from "../icons/IconMore";

type DropdownProps = {
	disabled?: boolean;
	isActive?: boolean;
}
const Dropdown: React.FC<DropdownProps> = ({children, disabled, isActive}) => {
	const button =
		<div className="dropdown_button_icon-more">
			<IconMore active={isActive}/>
		</div>;
	return (
		<CommonDropdown button={button} disabled={disabled}>
			{children}
		</CommonDropdown>
	);
};

export default Dropdown;
