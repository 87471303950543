import {CompanyCostCenterApiDto} from "./restModels";

export default class CompanyCostCenter {
	constructor(
		public readonly id: number,
		public readonly name: string,
	) {}

	public static fromJson(json: CompanyCostCenterApiDto): CompanyCostCenter {
		return new CompanyCostCenter(json.id, json.name);
	}
}