import React from "react";

const IconBlueArrow: React.FC = () => {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<polygon fill="#1976D2" points="7.41 8.59 12 13.17 16.59 8.59 18 10 12 16 6 10"/>
		</svg>
	);
};

export default IconBlueArrow
