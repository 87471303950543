import React from "react";
import {ActiveIconProps} from "../../../../project-drawings/features/editor/models/interfaces";
import IconSelectable from "../../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconSelectable";


const IconClipTool: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	const transform = `translate(4,4)`;
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			customTransform={transform}
			disabled={disabled}
			paths={["M19 7C19 5.897 18.103 5 17 5H7V2H5V5H2V7H17V22H19V19H22V17H19V7Z",
				"M5 9V17C5 18.103 5.897 19 7 19H15V17H7V9H5Z"]}/>
	);
};

export default IconClipTool
