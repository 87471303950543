import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconBin: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width="22" height="22" viewBox="0 0 25 25" style={{cursor}}>
				<path fill={fill}
					  d="M20.42,2.45H15.66V.78A.66.66,0,0,0,15,.11H9.93a.66.66,0,0,0-.65.67V2.45H4.52a1.32,1.32,0,0,0-1.3,1.33V6h18.5V3.78A1.32,1.32,0,0,0,20.42,2.45Z"/>
				<path fill={fill}
					  d="M4.4,7.45V23.09a2,2,0,0,0,2,2H18.59a2,2,0,0,0,2-2V7.45ZM8.89,21.93a.74.74,0,1,1-1.47,0V11.33a.74.74,0,1,1,1.47,0Zm4.32,0a.74.74,0,1,1-1.47,0V11.33a.74.74,0,1,1,1.47,0Zm4.32,0a.74.74,0,1,1-1.48,0V11.33a.74.74,0,1,1,1.48,0Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconBin;
