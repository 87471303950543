import {UseCatalogGroupsHook} from "./useCatalogGroups";
import {useCatalogMaterialItems, usePrebuildMaterialItems} from "./useMaterialItems";
import {useRef, useState} from "react";
import {CatalogItemGroup} from "../../../../../../../../models/CatalogItemGroup";
import {Status} from "../../../../../../../../models/enums";

export type Props = {
	loadGroupsHook: (parentGroupId?: number | null) => UseCatalogGroupsHook
	useMaterialItemsHook: typeof useCatalogMaterialItems | typeof usePrebuildMaterialItems
	rootGroupLabel: string
}

export function useMaterialData({useMaterialItemsHook, loadGroupsHook, rootGroupLabel}: Props) {
	const [group, setGroup] = useState<CatalogItemGroup | null>(null);
	const [groupsBreadcrumbs, setGroupsBreadcrumbs] = useState<CatalogItemGroup[]>([
		new CatalogItemGroup(undefined, rootGroupLabel, undefined)
	]);
	const [showFavorite, setShowFavorite] = useState(false);
	const [query, setQuery] = useState<string | undefined>(undefined);
	const {groups, loadStatus: groupsLoadStatus} = loadGroupsHook(group?.id);
	const {
		items, pageNumber, totalPages, onPageChange, loadStatus, saveStatus, saveAttributes
	} = useMaterialItemsHook(query, showFavorite, group?.id);
	const tableRef = useRef<HTMLTableElement | null>(null);

	const onSearch = (query: string) => {
		pageNumber !== 0 && onPageChange(0);
		setQuery(query.length > 0 ? query : undefined);
	};

	const onGroupClick = (group: CatalogItemGroup) => {
		setGroup(group);
		setGroupsBreadcrumbs(groupsBreadcrumbs.concat(group));
	};

	function onShowFavouritesChange(value: boolean) {
		setShowFavorite(value);
		onPageChange(0);
	}

	const onEnteredGroupClick = (enteredGroup: CatalogItemGroup) => {
		setGroup(enteredGroup);
		setGroupsBreadcrumbs(groupsBreadcrumbs.slice(0, groupsBreadcrumbs.indexOf(enteredGroup) + 1));
	};

	return {
		search: {
			isSearching: loadStatus === Status.LOADING,
			onSearch
		},
		overlay: {
			loading: loadStatus === Status.LOADING
				|| groupsLoadStatus === Status.LOADING
				|| saveStatus === Status.LOADING
		},
		breadcrumbs: {
			groupsBreadcrumbs,
			onEnteredGroupClick
		},
		groupsTable: {
			items: groups,
			checkedItemId: group?.id || undefined,
			onItemClick: onGroupClick
		},
		itemsTable: {
			tableRef,
			items,
			saveAttributes
		},
		pagination: {
			pageNumber,
			totalPages,
			onPageChange: (page: number) => {
				tableRef.current?.scrollIntoView()
				onPageChange(page)
			},
			disabled: loadStatus === Status.LOADING
		},
		favouriteSwitch: {
			showFavorite,
			onShowFavouritesChange
		}
	}
}