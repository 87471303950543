import {useEffect, useState} from "react";
import {useTakeoffTemplateItems} from "../api/useTakeoffTemplateItems";
import {Status} from "../../../../../../../../models/enums";

export function useItemPresenterData(selectedTemplateId: number | undefined) {
	const [query, setQuery] = useState<string | undefined>(undefined);
	const {
		loadStatus,
		items,
		loadTakeoffTemplateItemsRef,
		updateTakeoffTemplateItems
	} = useTakeoffTemplateItems()

	const onSearch = (query: string) => {
		setQuery(query.length > 0 ? query : undefined);
	};

	useEffect(() => {
		const loadTakeoffTemplateItems = loadTakeoffTemplateItemsRef.current
		if (selectedTemplateId) {
			setQuery(undefined)
			loadTakeoffTemplateItems(selectedTemplateId)
		}
	}, [selectedTemplateId, loadTakeoffTemplateItemsRef])

	return {
		loadStatus,
		search: {
			isSearching: loadStatus === Status.LOADING,
			onSearch: onSearch,
			query
		},
		overlay: {
			loading: loadStatus === Status.LOADING
		},
		itemsTable: {
			items,
			updateTakeoffTemplateItems
		}
	}
}