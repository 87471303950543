import React, {KeyboardEventHandler} from "react";
import "./numberInput.scss";
import {UnitsType} from "../../models/enums";
import NumberInput from "./NumberInput";
import ImperialLengthInput from "./ImperialLengthInput";

interface LengthInputProps {
	label: string,
	value: number | undefined,
	unitsType?: UnitsType,
	placeholder?: string,
	onFocus?: () => void,
	onChange: (value: number | undefined) => void,
	onKeyDown?: KeyboardEventHandler<HTMLInputElement>,
	isError?: boolean,
}

const LengthInput: React.FC<LengthInputProps> = (props) => {
	if (props.unitsType === UnitsType.METRIC) {
		const {unitsType, ...numberInputProps} = props;
		return <NumberInput {...numberInputProps} min={0}/>
	}
	else {
		const {unitsType, placeholder, onKeyDown, ...imperialInputProps} = props;
		return <ImperialLengthInput  {...imperialInputProps}/>;
	}
};

export default LengthInput;
