import React, {useState} from "react";
import "./treeSectionSummary.scss";
import AccordionBar from "../../../../../components/ui/AccordionBar";
import TreeCostCenterSummary from "./TreeCostCenterSummary";
import IconFolder from "../../../../../components/icons/IconFolder";
import Section from "../../../../../models/Section";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";

interface ITreeSummarySectionProps {
	section: Section,
}

const TreeSectionSummary: React.FC<ITreeSummarySectionProps> = ({section}) => {

	const {selectionState: {isSectionChecked, onSectionToggle}} = useSyncMaterialsContext()
	const [open, setOpen] = useState(true);
	const {isServiceProject} = useProjectDetailContext()

	const toggleOpen = () => setOpen(!open);

	const cls = ["tree-section-summary", open && "is-open"].filter(Boolean).join(" ");

	return (
		<>
			{!isServiceProject ? (
				<div className={cls}>
					<div className="tree-section-summary_header">
						<input
							type={"checkbox"}
							checked={isSectionChecked(section)}
							onChange={() => onSectionToggle(section)}
						/>
						<AccordionBar label={section.getLabel()} onClick={toggleOpen} isOpen={open} icon={<IconFolder/>}/>
					</div>
					<div className="tree-section-summary_content">
						{section.costCenters.map(cc =>
							<TreeCostCenterSummary
								key={cc.id}
								costCenter={cc}
								section={section}
							/>
						)}
					</div>
				</div>
			) : (
				section.costCenters.map(cc => (
					<TreeCostCenterSummary
						key={cc.id}
						costCenter={cc}
						section={section}
					/>
				))
			)}
		</>
	);
};

export default TreeSectionSummary;
