import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import SubTabs from "../../../components/ui/SubTabs";
import SubTab from "../../../components/ui/SubTab";
import PageTabsBottomSpacer from "../../../components/layout/page/PageTabsBottomSpacer";
import UserSymbolsRouter from "./UserSymbolsRouter";
import SystemSymbolsRouter from "./SystemSymbolsRouter";
import {useTranslation} from "react-i18next";
import {paths, useNavigator} from "../../navigator";

const SymbolsSubTabs: React.FC = () => {
	const location = useLocation();
	const {navigateTo} = useNavigator()
	const {t} = useTranslation();

	return (
		<>
			<SubTabs>
				<SubTab
					label={t("settings.tabs.symbols.packs.userTabName")}
					active={/user/.test(location.pathname)}
					onClick={() => { navigateTo(paths.settings_symbols_user) }}
				/>
				<SubTab
					label={t("settings.tabs.symbols.packs.systemTabName")}
					active={/system/.test(location.pathname)}
					onClick={() => { navigateTo(paths.settings_symbols_system) }}
				/>
			</SubTabs>
			<PageTabsBottomSpacer/>
			<Switch>
				<Route path={paths.settings_symbols_user} component={() => <UserSymbolsRouter/>}/>
				<Route path={paths.settings_symbols_system} component={() => <SystemSymbolsRouter/>}/>
				<Redirect to={paths.settings_symbols_system}/>
			</Switch>
		</>
	);
};

export {SymbolsSubTabs};
