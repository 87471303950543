import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {ButtonsGroup} from "../../../../../../components/layout";
import TextStyleSelector from "../../../../../base-konva/features/text/TextStyleSelector";
import {planUploadTextActions, selectPlanUploadTextSelectorStyle} from "./planUploadTextSlice";
import {TextToolStyle} from "../../../../../base-konva/types";

export const PlanUploadTextToolOptions: React.FC = () => {
	const dispatch = useDispatch();
	const textSelectorStyle = useSelector(selectPlanUploadTextSelectorStyle);

	function handleStyleSave(style: TextToolStyle) {
		dispatch(planUploadTextActions.changeTextStyle(style));
	}

	return (
		<>
			<ButtonsGroup>
				<TextStyleSelector style={textSelectorStyle} onStyleChange={handleStyleSave}/>
			</ButtonsGroup>
		</>
	)
}