import React from "react";
import {Group, Rect, Text} from "react-konva";
import {LINE_POINT_RECT_SIZE} from "../constants";
import {useUserSettingsDataContext} from "../../../../../providers/UserSettingsProvider";
import {formatLength} from "../../../../../utils/TextUtils";
import {Colors} from "../../../../../styles";

interface DropMarkProps {
	value: number,
}

const DropMark: React.FC<DropMarkProps> = ({value}) => {
	const {settings} = useUserSettingsDataContext();
	const lengthLabel = formatLength(value, settings?.measurementSystem);

	const elementSize = {
		width: lengthLabel.length >= 6 ? 60 : 45,
		height: 20,
	}
	const rectangleStyle = {
		...elementSize,
		cornerRadius: 10,
		fill: Colors.SIMPROBLUE,
	}
	const textStyle = {
		...elementSize,
		offsetY: -1,
		align: "center",
		verticalAlign: "middle",
		ellipsis: true,
		fill: Colors.WHITE,
	}

	return (
		<Group offsetX={elementSize.width / 2} offsetY={elementSize.height + (LINE_POINT_RECT_SIZE / 2) + 2}
			   {...elementSize}>
			<Rect {...rectangleStyle}/>
			<Text listening={false} {...textStyle} text={lengthLabel}/>
		</Group>
	)
}

export default DropMark;