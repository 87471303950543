export enum Colors {
	SIMPROBLUE = "#1976D2",
	GREY = "#979797",
	LIGHTERTEXT = "#82888c",
	WHITE = "#FFFFFF",
	BLACK = "#000000",
	GREEN = "#41B555",
	GREEN2 = "#28D13C",
	LIME = "#18FF1F",
	TRANSPARENT = "#00000000",
	YELLOW = "#FEED78",
	LIGHTGREY = "#F0F1F2",
	RED = "#FF0000",
	RED2 = "#DE104F",
	MIDGREY = "#4C4C4C",
	LIGHTGREY2 = "#DCDEDF",
	B4B4B4 = "#B4B4B4"
}