import React from "react";

const IconVisible: React.FC<{ fill: string }> = ({fill}) => {
	return (
		<svg width={16} height={16} viewBox="0 0 16 16">
			<path
				fill={fill}
				d="M8,4.5c2.211,0 4.182,1.242 5.145,3.208c-0.963,1.966 -2.934,3.209 -5.145,3.209c-2.211,-0 -4.182,-1.243 -5.145,-3.209c0.963,-1.966 2.934,-3.208 5.145,-3.208m0,-1.167c-2.917,0 -5.407,1.814 -6.417,4.375c1.01,2.561 3.5,4.375 6.417,4.375c2.917,0 5.408,-1.814 6.417,-4.375c-1.009,-2.561 -3.5,-4.375 -6.417,-4.375Zm0,2.917c0.805,0 1.458,0.653 1.458,1.458c0,0.805 -0.653,1.459 -1.458,1.459c-0.805,-0 -1.458,-0.654 -1.458,-1.459c-0,-0.805 0.653,-1.458 1.458,-1.458m0,-1.167c-1.447,0 -2.625,1.179 -2.625,2.625c0,1.447 1.178,2.625 2.625,2.625c1.447,0 2.625,-1.178 2.625,-2.625c0,-1.446 -1.178,-2.625 -2.625,-2.625Z"/>
		</svg>
	);
};

export default IconVisible