import {useTranslation} from "react-i18next";
import {useInternalDataContext, useMaterialModalContext} from "../../../../MaterialModalProvider";
import ModalWithHeader from "../../../../../../../../../../../components/modal/ModalWithHeader";
import React from "react";
import {ContentFooter} from "../../../../components/one-off/ContentFooter";
import {AddItemsStyles} from "./AddItemsStyles";
import {NameInput} from "../../../../components/one-off/NameInput";

export function AddItemsModal() {
	const {t} = useTranslation()
	const {closeModal} = useMaterialModalContext()
	const {addItemsData} = useInternalDataContext()
	const {nameInput, stylesContainer, contentFooter} = addItemsData.oneOffs

	return (
		<ModalWithHeader isOpen={true} title={t("projects.oneOffModal.title.addItem")} onClose={closeModal}>
			<div className="one-off-modal">
				<NameInput {...nameInput} />
				<AddItemsStyles {...stylesContainer}/>
				<ContentFooter {...{...contentFooter, closeModal}}/>
			</div>
		</ModalWithHeader>
	)
}