import {CatalogItemGroupApiDto} from "./restModels";

export class CatalogItemGroup {
	constructor(public readonly id: number | null | undefined,
		public readonly name: string,
		public readonly parentGroupId: number | undefined) {}

	public static fromJson(json: CatalogItemGroupApiDto) {
		return new CatalogItemGroup(json.id, json.name, json.parentGroupId);
	}
}
