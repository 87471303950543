import React, {useEffect} from "react";
import Section from "../../../models/Section";
import {useStateWithCallback} from "../../../hooks/useStateWithCallback";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {SelectedDrawingVersions} from "../features/drawing-exporter";
import {getLatestDrawingVersion} from "../../../utils/DrawingVersionUtils";
import {INITIAL_VERSION_ID} from "../../project-drawings/features/editor/constants";
import {CostCenterDrawing} from "../../../models/CostCenterDrawing";
import {CostCenter} from "../../../models/CostCenter";

type SelectedMapItem = {
	selected: boolean,
	drawing: CostCenterDrawing,
	costCenter: CostCenter,
	section: Section
}
type SelectedDrawingsMapType = Record<string, SelectedMapItem>

function useTreeSelectionData(sections: Section[]) {

	const [selectedDrawingsMap, setSelectedDrawingsMap] = useStateWithCallback<SelectedDrawingsMapType>({})
	const dataRef = useUpdatedRef({selectedDrawingsMap, setSelectedDrawingsMap})

	useEffect(() => {
		const {selectedDrawingsMap, setSelectedDrawingsMap} = dataRef.current
		const newSelectedDrawingsMap: SelectedDrawingsMapType = {}
		for (let section of sections) {
			for (let costCenter of section.costCenters) {
				for (let drawing of costCenter.drawings) {
					newSelectedDrawingsMap[drawing.id] = {
						selected: selectedDrawingsMap[drawing.id]?.selected ?? false,
						drawing, costCenter, section
					}
				}
			}
		}
		setSelectedDrawingsMap(newSelectedDrawingsMap)
	}, [sections, dataRef]);

	function reset() {
		const getNewSelectedDrawingsMap = () => {
			return transform(selectedDrawingsMap, ([, value]) => ({...value, selected: false}))
		}
		setSelectedDrawingsMap(getNewSelectedDrawingsMap())
	}

	function transform(
		selectedDrawingsMap: SelectedDrawingsMapType,
		predicate: (entry: [string, SelectedMapItem]) => SelectedMapItem
	): SelectedDrawingsMapType {
		const newSelectedDrawingsMap = {...selectedDrawingsMap}
		for (let [key, value] of Object.entries(selectedDrawingsMap)) {
			newSelectedDrawingsMap[key] = predicate([key, value])
		}
		return newSelectedDrawingsMap
	}

	function selectAllTakeoffs() {
		const getNewSelectedDrawingsMap = (selected: boolean) => {
			return transform(selectedDrawingsMap, ([, value]) => ({...value, selected}))
		}
		setSelectedDrawingsMap(getNewSelectedDrawingsMap(!isAllTakeoffsSelected()))
	}

	function selectSection(selectedSectionId: string) {
		const getNewSelectedDrawingsMap = (selected: boolean) => {
			return transform(selectedDrawingsMap, ([, value]) => {
				if (value.section.id === selectedSectionId) {
					return ({...value, selected})
				}
				return ({...value});
			})
		}
		setSelectedDrawingsMap(getNewSelectedDrawingsMap(!isSectionSelected(selectedSectionId)))
	}

	function selectCostCenter(selectedSectionId: string, selectedCostCenterId: string) {
		const getNewSelectedDrawingsMap = (selected: boolean) => {
			return transform(selectedDrawingsMap, ([, value]) => {
				if (value.section.id === selectedSectionId && value.costCenter.id === selectedCostCenterId) {
					return ({...value, selected})
				}
				return ({...value});
			})
		}
		setSelectedDrawingsMap(getNewSelectedDrawingsMap(!isCostCenterSelected(selectedSectionId, selectedCostCenterId)))
	}

	function selectDrawing(selectedSectionId: string, selectedCostCenterId: string, selectedDrawingId: string) {
		const getNewSelectedDrawingsMap = (selected: boolean) => {
			return transform(selectedDrawingsMap, ([, value]) => {
				if (value.section.id === selectedSectionId &&
					value.costCenter.id === selectedCostCenterId &&
					value.drawing.id === selectedDrawingId
				) {
					return ({...value, selected})
				}
				return ({...value});
			})
		}
		const selected = isDrawingSelected(selectedSectionId, selectedCostCenterId, selectedDrawingId)
		setSelectedDrawingsMap(getNewSelectedDrawingsMap(!selected))
	}

	function isAllTakeoffsSelected(): boolean {
		return Object.values(selectedDrawingsMap).every(item => item.selected)
	}

	function isSectionSelected(sectionId: string): boolean {
		const sectionDrawings = Object.values(selectedDrawingsMap)
			.filter(item => item.section.id === sectionId)

		return sectionDrawings.length === 0 ? false : sectionDrawings.every(item => item.selected);
	}

	function isCostCenterSelected(sectionId: string, ccId: string): boolean {
		const costCenterDrawings = Object.values(selectedDrawingsMap)
			.filter(item => item.section.id === sectionId && item.costCenter.id === ccId)

		return costCenterDrawings.length === 0 ? false : costCenterDrawings.every(item => item.selected)
	}

	function isDrawingSelected(sectionId: string, ccId: string, drawingId: string): boolean {
		return Object.values(selectedDrawingsMap)
			.filter(item => item.section.id === sectionId && item.costCenter.id === ccId && item.drawing.id === drawingId)
			.every(item => item.selected)
	}

	const selection: SelectedDrawingVersions = React.useMemo(() => {
		return Object.values(selectedDrawingsMap)
			.filter(item => item.selected)
			.map(item => {
				const latestDrawingVersion = getLatestDrawingVersion(item.drawing.versions)
				return ({
					selectedSectionId: item.section.id,
					selectedCostCenterId: item.costCenter.id,
					selectedDrawingId: item.drawing.id,
					selectedDrawingName: item.drawing.name,
					selectedVersionId: latestDrawingVersion ? latestDrawingVersion.id : INITIAL_VERSION_ID,
					selectedVersionLabel: latestDrawingVersion
						? latestDrawingVersion.version.getVersionLabel()
						: "Initial version",
					drawingType: item.drawing.drawingType
				});
			})
	}, [selectedDrawingsMap])

	return {
		selection,
		canDownload: selection.length !== 0,
		reset,
		selectAllTakeoffs,
		selectSection,
		selectCostCenter,
		selectDrawing,
		isAllTakeoffsSelected,
		isSectionSelected,
		isCostCenterSelected,
		isDrawingSelected
	}
}

export {useTreeSelectionData}