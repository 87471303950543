import {Colors} from "../../../../../../styles";
import React from "react";

export const IconLength = function() {
	const fill = Colors.SIMPROBLUE;
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path fill={fill} d="M22.53,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,22.53,2Z"/>
			<path fill={fill} d="M1.47,2a1,1,0,0,0-1,1V21a1,1,0,0,0,2,0V3A1,1,0,0,0,1.47,2Z"/>
			<path fill={fill}
				  d="M20.15,11.61,16.82,8.27a1,1,0,0,0-1.41,0,1,1,0,0,0,0,1.42L17,11.32H7L8.6,9.69a1,1,0,0,0,0-1.42,1,1,0,0,0-1.41,0L3.85,11.62a.87.87,0,0,0-.22.32,1,1,0,0,0-.08.38,1,1,0,0,0,.08.39.87.87,0,0,0,.22.32l3.33,3.33a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41L7,13.32H17L15.4,15a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l3.31-3.3a1,1,0,0,0,.25-.37.11.11,0,0,0,0,0,.83.83,0,0,0,.07-.32h0A1,1,0,0,0,20.15,11.61Z"/>
		</svg>
	)
}