import React from "react";
import "./modalButtons.scss";

const ModalButtons: React.FC = ({children}) => {
	return (
		<div className="modal-buttons">
			{children}
		</div>
	);
};

export default ModalButtons;
