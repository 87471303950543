import React, {createContext, useState} from "react";
import {ProjectSource} from "../models/enums";
import {pages, paths, useNavigator} from "../features/navigator";

type ProjectAccessData = {
	projectId: string,
	simproId: number,
	projectSource: ProjectSource
}

interface DirectProjectAccessContextData {
	isProjectDirectAccess: boolean,
	projectSource: ProjectSource | undefined,
	setProjectDirectAccess: (isProjectDirectAccess: boolean) => void,
	setProjectAccessData: (data: ProjectAccessData) => void,
	redirectToProjectOrProjectsList: () => void;
	redirectToTheSimpro: () => void;
}

const DirectProjectAccessDataContext = createContext<DirectProjectAccessContextData | undefined>(undefined);

const DirectProjectAccessDataProvider: React.FC = ({children}) => {
	const [projectAccessData, setProjectAccessData] = useState<ProjectAccessData | undefined>();
	const [isProjectDirectAccess, setProjectDirectAccess] = useState(false);
	const {navigateTo} = useNavigator()

	const redirectToProjectOrProjectsList = () => {
		if (projectAccessData) {
			const type = projectAccessData.projectSource === ProjectSource.JOB ? "jobs" : "quotes"
			navigateTo(pages.projectDetailsPage(type, projectAccessData.projectId))
		}
		else {
			navigateTo(paths.root)
		}
	};

	const redirectToTheSimpro = () => {
		if (projectAccessData) {
			window.parent.postMessage({
				action: "redirect",
				target: {
					type: projectAccessData.projectSource === ProjectSource.QUOTE
						? "quote"
						: "job",
					id: projectAccessData.simproId
				}
			}, "*")
		}
	}

	return (
		<DirectProjectAccessDataContext.Provider value={{
			isProjectDirectAccess,
			projectSource: projectAccessData?.projectSource,
			setProjectDirectAccess,
			setProjectAccessData,
			redirectToProjectOrProjectsList,
			redirectToTheSimpro
		}}>
			{children}
		</DirectProjectAccessDataContext.Provider>
	);
};

function useDirectProjectAccessDataContext() {
	const context = React.useContext(DirectProjectAccessDataContext)
	if (!context) {
		throw new Error("useDirectProjectAccessDataContext must be used within a DirectProjectAccessDataProvider")
	}
	return context
}

export {DirectProjectAccessDataProvider, useDirectProjectAccessDataContext}
