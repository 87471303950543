import React from "react";
import Konva from "konva";
import {Document, Image, Page} from "@react-pdf/renderer";

interface StageAsPDFProps {
	stage: Konva.Stage;
}

export const StageAsPDF: React.FC<StageAsPDFProps> = ({stage}) => {
	const width = stage.width();
	const height = stage.height();
	const url = stage.toDataURL();

	return (
		<Document>
			<Page size={[width, height]}>
				<Image src={url} fixed={true}/>
			</Page>
		</Document>
	);
};
