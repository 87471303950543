import React from "react";
import "./fullPageMessage.scss";

interface FullPageMessageProps {
	message: string,
	height?: number,
}

const FullPageMessage: React.FC<FullPageMessageProps> = ({message, height}) => {

	return (
		<div className={"full-page-message"} style={height ? {height} : {}}>{message}</div>
	);
};

export default FullPageMessage
