import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {initialState, reducers} from "../../../../../base-konva/features/scale/baseScaleSlice";

const scaleSlice = createSlice({
	name: 'scale',
	initialState,
	reducers: {
		...reducers,
		reset: (_,
			{payload}: PayloadAction<{ planScale: number | null, unitFactor: number | null }>) => ({
			scaleConfig: {
				...initialState.scaleConfig,
				scale: payload.planScale,
				scaleInfoState: {
					...initialState.scaleConfig.scaleInfoState,
					unitFactor: payload.unitFactor ?? initialState.scaleConfig.scaleInfoState.unitFactor
				}
			}
		}),
		resetScale: state => {
			state.scaleConfig = initialState.scaleConfig
		},
		setInputLength: (state, action: PayloadAction<number | null>) => {
			state.scaleConfig.inputValue = action.payload
		},
	}
})
export const scaleReducer = scaleSlice.reducer;
export const scaleActions = scaleSlice.actions;

export const selectScaleConfig = (state: RootState) => state.scale.scaleConfig;
export const selectScalePoints = (state: RootState) => state.scale.scaleConfig.scalePoints;
export const selectScale = (state: RootState) => state.scale.scaleConfig.scale;
export const selectInputLength = (state: RootState) => state.scale.scaleConfig.inputValue;
