import {capitalizeString, uuidRegexp} from "../../../utils/TextUtils";
import React from "react";
import {SectionDetailsBreadcrumb} from "./SectionDetailsBreadcrumb";

export function SectionBreadcrumb({match}: any) {
	const {projectId, sectionId} = match.params

	return (
		<>
			{uuidRegexp.test(projectId) && uuidRegexp.test(sectionId)
				? <SectionDetailsBreadcrumb sectionId={sectionId}/>
				: <span>{capitalizeString(sectionId)}</span>
			}
		</>
	)
}