import {ImageData} from "../../../../../base-konva/types";
import {Vector2d} from "konva/types/types";
import {add} from "../../utils";

export class ImageHelper {
	static getMovedImages(images: ImageData[], delta: Vector2d): ImageData[] {
		return images.map(image => ({
			...image,
			position: add(image.position, delta),
		}))
	}
}