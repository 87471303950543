import {useEffect, useState} from "react";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {loadImage} from "../../../../hooks/useImage";
import {SymbolMap, SymbolType} from "../../icons/symbol_pack/default";
import LoggerService from "../../../../../../services/LoggerService";
import NotificationService from "../../../../../../services/NotificationService";
import {useSymbolCacheDataContext} from "../../../../../../providers/SymbolCacheDataProvider";
import {useTranslation} from "react-i18next";
import {CountMeasurement, RemoteSymbol} from "../../models/editor";

type RemoteSymbolId = string;
type StaticSymbolValue = {
	staticSymbol: SymbolType,
	image: HTMLImageElement | undefined,
}
type RemoteSymbolValue = {
	remoteSymbol: RemoteSymbol,
	image: HTMLImageElement | undefined,
}
type ImageElementsData = {
	staticSymbols: Map<SymbolType, StaticSymbolValue>
	remoteSymbols: Map<RemoteSymbolId, RemoteSymbolValue>
}

function useImageElementsData(countGroups: CountMeasurement[]) {
	const {t} = useTranslation();
	const {getSymbolUrl} = useSymbolCacheDataContext()

	const [imageElementsData, setImageElements] = useState<ImageElementsData | null>(null);

	const dataRef = useUpdatedRef({imageElementsData})
	useEffect(function() {
		let active = true;

		async function getImageElements() {
			const symbols = countGroups.reduce<ImageElementsData>(
				(previousValue, currentValue) => {
					const {symbol, remoteSymbol} = currentValue.style
					if (symbol) {
						previousValue.staticSymbols.set(symbol, {
							staticSymbol: symbol,
							image: undefined,
						})
					}
					if (remoteSymbol) {
						previousValue.remoteSymbols.set(remoteSymbol.id, {
							remoteSymbol: remoteSymbol,
							image: undefined,
						})
					}
					return previousValue
				}, {staticSymbols: new Map(), remoteSymbols: new Map()}
			)

			const {imageElementsData} = dataRef.current

			for (const staticSymbolEntry of Array.from(symbols.staticSymbols)) {
				if (!active) {
					throw new Error("effect no longer active")
				}

				const [symbolType] = staticSymbolEntry
				const existingStaticSymbolValue = imageElementsData?.staticSymbols.get(symbolType)

				const getStaticSymbolValue = async function(): Promise<StaticSymbolValue> {
					const imageElement = await loadImage(SymbolMap[symbolType])
					return {
						staticSymbol: symbolType,
						image: imageElement
					}
				}

				symbols.staticSymbols.set(
					symbolType,
					existingStaticSymbolValue ?? await getStaticSymbolValue()
				)
			}

			for (const remoteSymbolEntry of Array.from(symbols.remoteSymbols)) {
				if (!active) {
					throw new Error("effect no longer active")
				}

				const [remoteSymbolId, remoteSymbolValue] = remoteSymbolEntry
				const existingRemoteSymbolValue = imageElementsData?.remoteSymbols.get(remoteSymbolId)

				const getRemoteSymbolValue = async function(): Promise<RemoteSymbolValue> {
					const symbolUrl = await getSymbolUrl(remoteSymbolValue.remoteSymbol)
					const imageElement = await loadImage(symbolUrl)
					return {
						remoteSymbol: remoteSymbolValue.remoteSymbol,
						image: imageElement
					}
				}

				symbols.remoteSymbols.set(
					remoteSymbolId,
					existingRemoteSymbolValue ?? await getRemoteSymbolValue()
				)
			}

			return symbols
		}

		getImageElements().then(imageElementsData => {
			if (active) {
				setImageElements(imageElementsData)
			}
		}).catch(err => {
			if (active) {
				LoggerService.logError(err);
				setImageElements(null)
				NotificationService.errorNotification(
					t("common.error"),
					t("editor.count.fetchRemoteSymbolErrorDesc")
				);
			}
		})

		return () => { active = false;}
	}, [countGroups, getSymbolUrl, t, dataRef])

	return {imageElementsData}
}

export {useImageElementsData}