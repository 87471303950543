import {Group, Image, Transformer} from "react-konva";
import React from "react";
import Konva from "konva";
import isEqual from "lodash/isEqual";
import Vector2d = Konva.Vector2d;
import ShapeConfig = Konva.ShapeConfig;
import KonvaEventObject = Konva.KonvaEventObject;

export type ImageEventHandlers = {
	onClick: (evt: KonvaEventObject<Event>) => void,
	onTap: (evt: KonvaEventObject<Event>) => void,
	onMouseEnter: (evt: KonvaEventObject<MouseEvent>) => void,
	onMouseLeave: (evt: KonvaEventObject<MouseEvent>) => void,
	onDragEnd?: (evt: KonvaEventObject<DragEvent>) => void,
	onTransformEnd?: (evt: KonvaEventObject<DragEvent>) => void
}

type ImageElementKonvaProps = {
	draggable: boolean,
	isVisible: boolean,
	imageRef: React.RefObject<Konva.Image>,
	position: Vector2d,
	rotation: number,
	scale: Vector2d,
	image?: HTMLImageElement,
	style?: ShapeConfig,
	eventsRef: React.MutableRefObject<ImageEventHandlers>,
	showTransformer: boolean,
	transformerRef: React.RefObject<Konva.Transformer>
}

const _ImageElementKonva = function({
	draggable,
	isVisible,
	imageRef,
	position,
	rotation,
	scale,
	image,
	style,
	eventsRef,
	showTransformer,
	transformerRef

}: ImageElementKonvaProps) {

	function onClick(evt: KonvaEventObject<Event>) {
		eventsRef.current.onClick(evt)
	}

	function onTap(evt: KonvaEventObject<Event>) {
		eventsRef.current.onTap(evt)
	}

	function onMouseEnter(evt: KonvaEventObject<MouseEvent>) {
		eventsRef.current.onMouseEnter(evt)
	}

	function onMouseLeave(evt: KonvaEventObject<MouseEvent>) {
		eventsRef.current.onMouseLeave(evt)
	}

	function onDragEnd(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragEnd?.(evt)
	}

	function onTransformEnd(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onTransformEnd?.(evt)
	}

	return (
		<Group visible={isVisible}>
			<Image
				ref={imageRef}
				{...position}
				rotation={rotation}
				scale={scale}
				image={image}
				{...style}
				draggable={draggable}
				onClick={onClick}
				onTap={onTap}
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
				onDragEnd={onDragEnd}
				onTransformEnd={onTransformEnd}
			/>
			{showTransformer ? (
				<Transformer
					ref={transformerRef}
					rotationSnaps={[0, 90, 180, 270]}
					boundBoxFunc={(oldBox, newBox) => {
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}/>
			) : null}
		</Group>
	)
}

export const ImageElementKonva = React.memo(_ImageElementKonva, isEqual)