import React from "react";
import {capitalizeString, uuidRegexp} from "../../../utils/TextUtils";
import CostCenterDetailsBreadcrumb from "./CostCenterDetailsBreadcrumb";

const CostCenterBreadcrumb: React.FC<any> = ({match}) => {
	const {projectId, costCenterId, sectionId} = match.params;
	return (
		<>
			{uuidRegexp.test(costCenterId) && uuidRegexp.test(projectId) && uuidRegexp.test(sectionId) ?
				<CostCenterDetailsBreadcrumb costCenterId={costCenterId} sectionId={sectionId}/> :
				<span>{capitalizeString(costCenterId)}</span>
			}
		</>
	);
};

export default CostCenterBreadcrumb
