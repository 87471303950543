import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RulerStyle} from "../../../models/editor";
import RulerStyleSelector from "../../../components/RulerStyleSelector";
import {selectRulerStyle, viewActions} from "../../view/viewSlice";
import {ButtonsGroup} from "../../../../../../../components/layout";

export const RulerOptions: React.FC = () => {
	const dispatch = useDispatch();

	const rulerStyle = useSelector(selectRulerStyle);

	function handleStyleSave(style: RulerStyle) {
		dispatch(viewActions.changeRulerStyle(style));
	}

	return (
		<ButtonsGroup>
			<RulerStyleSelector style={rulerStyle} onStyleChange={handleStyleSave} horizontal={true}/>
		</ButtonsGroup>
	)
}