import React from "react";
import {RulerStyle} from "../models/editor";
import {useTranslation} from "react-i18next";
import ColorPicker from "./ColorPicker";
import "../../../../base-konva/features/text/styleSelector.scss";

interface LineStyleSelectorProps {
	style: RulerStyle,
	onStyleChange: (style: RulerStyle) => void,
	horizontal?: boolean,
}

const RulerStyleSelector: React.FC<LineStyleSelectorProps> = ({style, onStyleChange, horizontal = false}) => {

	const {t} = useTranslation();

	const cls = [
		"style-selector",
		horizontal && "is-horizontal",
	].filter(Boolean).join(" ")

	return (
		<div className={cls}>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.rulerStyleEditor.color")}</div>
				<ColorPicker color={style.color} onChange={color => {onStyleChange({...style, color})}}/>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.rulerStyleEditor.backgroundColor")}</div>
				<ColorPicker color={style.backgroundColor}
							 onChange={color => {onStyleChange({...style, backgroundColor: color})}}/>
			</div>
		</div>
	);
};

export default RulerStyleSelector
