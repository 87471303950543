import {UserApiDto} from "./restModels";

export default class User {
	constructor(
		public readonly companyId: number,
		public readonly name: string,
		public readonly simproUserId: number,
		public readonly userId: string
	) {}

	public static fromJson(json: UserApiDto): User {
		return new User(json.companyId, json.name, json.simproUserId, json.userId);
	}
}