import React from "react";
import {Colors} from "../../../../../styles";
import "./iconVisibleButton.scss"
import IconVisible from "../icons/IconVisible";

interface IconVisibleButtonProps {
	onClick: () => void,
	visible: boolean,
	isActive?: boolean,
}

const IconVisibleButton: React.FC<IconVisibleButtonProps> = ({onClick, visible, isActive}) => {
	const inactiveColor = visible ? Colors.SIMPROBLUE : Colors.LIGHTERTEXT;
	const activeColor = visible ? "white" : Colors.GREY;
	const fill = isActive ? activeColor : inactiveColor;
	const handleClick = () => onClick();

	return (
		<div onClick={handleClick} className={"icon-visible-button"}>
			<IconVisible fill={fill}/>
		</div>
	);
};

export default IconVisibleButton