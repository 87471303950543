import {Vector2d} from "konva/types/types";
import {add} from "../../utils";
import {ScaleConfig} from "../../../../../base-konva/types";

export class ScaleHelper {
	static getMovedScale(scaleConfig: ScaleConfig, delta: Vector2d): ScaleConfig {
		return {
			...scaleConfig,
			scalePoints: scaleConfig.scalePoints.map(point => add(point, delta))
		}
	}
}