import {DashPatternType, LineStyle, ScaleInfoState} from "./types";
import {Colors} from "../../styles";
import {LineConfig} from "konva/types/shapes/Line";
import {CircleConfig} from "konva/types/shapes/Circle";
import {ShapeConfig} from "konva/types/Shape";

export const defaultPenStyle: LineStyle = {
	strokeWidth: 2,
	stroke: Colors.BLACK,
	dashType: DashPatternType.LINE,
}

export const scaleLineDefaultStyle: Partial<LineConfig> = {
	strokeWidth: 3,
	lineCap: "round",
	lineJoin: "round",
	stroke: Colors.GREY,
}

export const scalePointLinesDefaultStyle: Partial<CircleConfig> = {
	radius: 5,
	strokeWidth: 0,
	stroke: Colors.SIMPROBLUE,
	fill: Colors.SIMPROBLUE,
}

export const selectionStyle: ShapeConfig = {
	shadowBlur: 5,
	shadowColor: Colors.LIME,
}

export const highlightStyle: ShapeConfig = {
	shadowBlur: 5,
	shadowColor: Colors.RED,
}

export const initialScaleInfoState: ScaleInfoState = {
	unitWidth: 0,
	containerWidth: 0,
	unitFactor: 1,
	position: null,
	visible: false
}