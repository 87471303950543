import React from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import {Status} from "../../../../../models/enums";
import {CostCenterDrawing} from "../../../../../models/CostCenterDrawing";
import useDrawingVersionCopy from "../../../hooks/useDrawingVersionCopy";
import DrawingDetails from "../../../../../models/DrawingDetails";
import {BaseCopyVersionModal} from "./BaseCopyVersionModal";
import {pages, useNavigator} from "../../../../navigator";

interface CopyCostCenterDrawingVersionModalProps {
	drawing: CostCenterDrawing,
	drawingVersionId: string,
	onSuccess: () => void,
	onClose: () => void,
}

const CopyCostCenterDrawingVersionModal: React.FC<CopyCostCenterDrawingVersionModalProps> = ({
	drawing,
	drawingVersionId,
	onSuccess,
	onClose
}) => {
	const {t} = useTranslation();
	const {projectId} = useParams<{ projectId: string }>();
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {navigateTo} = useNavigator()

	const handleCopySuccess = () => {
		onSuccess();
		handleClose();
	};

	const openDrawingDetails = (drawingDetails: DrawingDetails, version: string, sectionId: string, ccId: string) => {
		handleCopySuccess();
		if (drawingDetails) {
			navigateTo(pages.projectDrawingPage(
				type, projectId, sectionId, ccId, drawingDetails.id, version
			))
		}
	};
	const {copyDrawingVersion, saveStatus} = useDrawingVersionCopy(projectId);

	const handleClose = () => {
		onClose();
	};

	const handleCopy = (open: boolean = false) => (name: string, sectionId?: string, ccId?: string) => {
		if (name && sectionId && ccId) {
			copyDrawingVersion(
				drawing.id,
				drawingVersionId,
				name, sectionId, ccId,
				(drawingDetails, version) => {
					if (open) {
						openDrawingDetails(drawingDetails, version, sectionId, ccId)
					}
					else {
						handleCopySuccess()
					}
				}
			)
		}
	};

	return (
		<BaseCopyVersionModal
			initialName={drawing.name}
			title={t("projects.details.copyDrawingModal.title")}
			isLoading={saveStatus === Status.LOADING}
			onClose={handleClose}
			onSave={handleCopy()}
			onSaveAndOpen={handleCopy(true)}
		/>
	)
};

export default CopyCostCenterDrawingVersionModal;
