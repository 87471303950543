import {AxiosInstance} from "axios";
import FitTime from "../models/FitTime";
import SummaryMeasurement from "../models/SummaryMeasurement";

export default class MeasurementsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchFitTimes(): Promise<FitTime[]> {
		const url = `/api/fit_times`;
		return this.axiosInstance.get(url).then(resp => resp.data.map(FitTime.fromJson));
	}

	putMeasurementData(measurementId: string, fitTimeId: number | undefined, laborTime: number | undefined): Promise<void> {
		const data = {fitTimeId, laborTime};
		const url = `/api/measurements/${measurementId}`;
		return this.axiosInstance.put(url, data);
	}

	fetchMeasurements(costCenterId: string) {
		const url = `/api/cost_centers/${costCenterId}/summary`;
		return this.axiosInstance.get(url).then(resp => resp.data.map(SummaryMeasurement.fromJson));
	}
}
