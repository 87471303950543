import {useSelector} from "react-redux";
import {selectPlanUploadActiveTool} from "../features/config/planUploadConfigSlice";
import {Group} from "react-konva";
import {PlanUploadEditorTool} from "../types";
import React, {useState} from "react";
import {getId} from "../../../../../utils";
import {PlanUploadMeasurementsLayer} from "./PlanUploadMeasurementsLayer";
import {PlanUploadScaleLayer} from "../features/scale/PlanUploadScaleLayer";
import {PlanUploadClipLayer} from "../features/clip/PlanUploadClipLayer";
import {PlanUploadEraseAreaLayer} from "../features/erase-area/PlanUploadEraseAreaLayer";
import {PlanUploadScaleInfoLayer} from "../features/scaleInfo/PlanUploadScaleInfoLayer";

function PlanUploadStageLayers() {
	const activeTool = useSelector(selectPlanUploadActiveTool)
	const [measurementsLayerId] = useState(getId())
	const [scaleInfoLayerId] = useState(getId())

	switch (activeTool) {
		case PlanUploadEditorTool.PEN:
		case PlanUploadEditorTool.TEXT:
		case PlanUploadEditorTool.IMAGE:
		case PlanUploadEditorTool.MEASUREMENT_SELECT:
			return (
				<Group>
					<PlanUploadMeasurementsLayer key={measurementsLayerId} listening={true}/>
					<PlanUploadScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case PlanUploadEditorTool.SCALE:
			return (
				<Group>
					<PlanUploadMeasurementsLayer key={measurementsLayerId}/>
					<PlanUploadScaleLayer listening={true}/>
					<PlanUploadScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case PlanUploadEditorTool.ERASE_AREA:
			return (
				<Group>
					<PlanUploadMeasurementsLayer key={measurementsLayerId}/>
					<PlanUploadEraseAreaLayer/>
					<PlanUploadScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case PlanUploadEditorTool.CLIP:
			return (
				<Group>
					<PlanUploadMeasurementsLayer key={measurementsLayerId}/>
					<PlanUploadClipLayer/>
					<PlanUploadScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case PlanUploadEditorTool.SCALE_INFO:
			return (
				<Group>
					<PlanUploadMeasurementsLayer key={measurementsLayerId}/>
					<PlanUploadScaleInfoLayer key={scaleInfoLayerId} showTransformer={true}/>
				</Group>
			)
		default:
			return (
				<Group>
					<PlanUploadMeasurementsLayer key={measurementsLayerId}/>
					<PlanUploadScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
	}
}

export {PlanUploadStageLayers}