import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {
	SymbolRecognitionPanelStage,
	SymbolRecognitionResults,
	SymbolRecognitionResultsReferentialData,
	SymbolRecognitionState
} from "./types";
import {simpleCompare} from "../../../../../../utils/SortUtils";
import {SelectionState} from "../../../../../base-konva/types";

const initialState: SymbolRecognitionState = {
	panelStage: SymbolRecognitionPanelStage.INITIAL,
	symbolRecognitionResults: {
		referentialData: [],
		rejectedIndexes: [],
		approvedIndexes: [],
		remainingIndexes: [],
		displayableIndexes: [],
		activeIndex: 0
	}
}


export const symbolRecognitionSlice = createSlice({
	name: 'symbol-recognition',
	initialState,
	reducers: {
		reset: () => ({...initialState}),
		setSelection: (state, {payload}: PayloadAction<SelectionState | undefined>) => {
			state.selection = payload
		},
		setPanelStage: (state, {payload}: PayloadAction<SymbolRecognitionPanelStage>) => {
			state.panelStage = payload
		},
		setSymbolRecognitionResults: (state, {payload}: PayloadAction<SymbolRecognitionResultsReferentialData[]>) => {
			state.symbolRecognitionResults = {
				referentialData: payload,
				rejectedIndexes: [],
				approvedIndexes: [],
				remainingIndexes: [],
				displayableIndexes: [],
				activeIndex: 0
			}
		},
		setActiveIndex: (state, {payload}: PayloadAction<number>) => {
			state.symbolRecognitionResults.activeIndex = payload;
		},
		setRejectedIndexes: (state, {payload}: PayloadAction<number[]>) => {
			state.symbolRecognitionResults.rejectedIndexes = payload;
			setDisplayableIndexes(state.symbolRecognitionResults);
			removeVerifiedMatchFromRemaining(state.symbolRecognitionResults.remainingIndexes, payload)
		},
		setApprovedIndexes: (state, {payload}: PayloadAction<number[]>) => {
			state.symbolRecognitionResults.approvedIndexes = payload;
			setDisplayableIndexes(state.symbolRecognitionResults);
			removeVerifiedMatchFromRemaining(state.symbolRecognitionResults.remainingIndexes, payload)

		},
		setRemainingIndexes: (state, {payload}: PayloadAction<number[]>) => {
			state.symbolRecognitionResults.remainingIndexes = payload;
			setDisplayableIndexes(state.symbolRecognitionResults);
		}
	}
})

function removeVerifiedMatchFromRemaining(remainingIndexes: number[], referentialList: number[]) {
	remainingIndexes.filter(index => !referentialList.includes(index));
}

function setDisplayableIndexes(symbolRecognitionResults: SymbolRecognitionResults) {
	symbolRecognitionResults.displayableIndexes =
		[...symbolRecognitionResults.rejectedIndexes, ...symbolRecognitionResults.approvedIndexes,
			...symbolRecognitionResults.remainingIndexes].sort((a, b) => simpleCompare(a, b));
}

export const symbolRecognitionReducer = symbolRecognitionSlice.reducer;
export const symbolRecognitionActions = symbolRecognitionSlice.actions;

/**
 * Selection is always relative to plan image not to stage dimensions,
 * if stage is resized add vector from stageConfig.backgroundImagePosition
 */
export const selectSymbolRecognitionSelection = (state: RootState) => state.symbolRecognition.selection;
export const selectPanelStage = (state: RootState) => state.symbolRecognition.panelStage;
export const selectSymbolRecognitionResults = (state: RootState) => state.symbolRecognition.symbolRecognitionResults;