import React, {useState} from "react";
import "./settingsSymbols.scss";
import PageHeader from "../../../components/layout/page/PageHeader";
import {useTranslation} from "react-i18next";
import PageHeaderButtons from "../../../components/layout/page/PageHeaderButtons";
import Button from "../../../components/ui/Button";
import PageContent from "../../../components/layout/page/PageContent";
import useSymbolGroups from "../hooks/useSymbolGroups";
import {Status, SymbolGroupsModal} from "../../../models/enums";
import SymbolGroupsTable from "./SymbolGroupsTable";
import Spinner from "../../../components/Spinner";
import DataFetchError from "../../../components/ui/DataFetchError";
import {CreateSymbolGroupModal} from "./CreateSymbolGroupModal";
import {useInvalidateSymbolQueries} from "../hooks/useInvalidateSymbolQueries";
import {paths, useNavigator} from "../../navigator";

const SymbolGroups: React.FC = () => {

	const {navigateTo} = useNavigator()
	const {t} = useTranslation();

	const {invalidateSymbolQueries} = useInvalidateSymbolQueries()
	const {symbolGroups, loadStatus} = useSymbolGroups();
	const [modal, setModal] = useState<SymbolGroupsModal>(SymbolGroupsModal.NONE);

	function closeModal() {
		setModal(SymbolGroupsModal.NONE)
	}

	return (
		<>
			{modal === SymbolGroupsModal.CREATE ? (
				<CreateSymbolGroupModal
					isOpen={true}
					onClose={closeModal}
					onSave={invalidateSymbolQueries}
				/>
			) : null}
			<PageHeader>
				<div className={"settings-symbols_title"}>{t("settings.tabs.symbols.groups.title")}</div>
				<PageHeaderButtons>
					<Button
						variant={"primary"} onClick={() => setModal(SymbolGroupsModal.CREATE)}
						label={t("settings.tabs.symbols.groups.createGroup")}
					/>
					<Button
						label={t("common.buttons.cancel")}
						onClick={() => { navigateTo(paths.root) }}
						variant={"optional"}
					/>
				</PageHeaderButtons>
			</PageHeader>
			<PageContent>
				{loadStatus === Status.LOADING && <Spinner/>}
				{loadStatus === Status.SUCCESS &&
					<SymbolGroupsTable
						symbolGroups={symbolGroups}
						reloadSymbolGroups={invalidateSymbolQueries}
					/>
				}
				{loadStatus === Status.ERROR && <DataFetchError/>}
			</PageContent>
		</>
	);
};

export default SymbolGroups;
