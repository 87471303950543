import {
	EditorDrawingData,
	EditorDrawingData_v1,
	EditorDrawingData_v2,
	EditorDrawingData_v3,
	EditorDrawingData_v4
} from "../../models/editor";
import {DRAWING_SCHEMA_VERSION} from "../../constants";
import {from_v1_to_v2} from "./scripts/from_v1_to_v2";
import {from_v2_to_v3} from "./scripts/from_v2_to_v3";
import {from_v3_to_v4} from "./scripts/from_v3_to_v4";

type EditorDrawingDatas = EditorDrawingData_v1 | EditorDrawingData_v2 | EditorDrawingData_v3 | EditorDrawingData_v4;

export class SchemaMigrator {
	static migrate(schema: EditorDrawingDatas): EditorDrawingData {
		if (DRAWING_SCHEMA_VERSION === schema.schemaVersion) {
			return schema as EditorDrawingData;
		}
		else {
			return SchemaMigrator.migrate(migrationMap[schema.schemaVersion](schema))
		}
	}
}

const migrationMap: Record<number, (schema: any) => EditorDrawingDatas> = {
	1: from_v1_to_v2,
	2: from_v2_to_v3,
	3: from_v3_to_v4
}