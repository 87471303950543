import React from "react";
import {IconTakeoff} from "./IconTakeoff";
import "./iconAsBuilt.scss"
import {Colors} from "../../styles";

type Props = {
	color?: string
}
export const IconAsBuilt: React.FC<Props> = function({color = Colors.SIMPROBLUE}) {

	return (
		<div className={"icon-as-built"}>
			<IconTakeoff color={color}/>
			<div className={"icon-as-built_signature"}>AS BUILT</div>
		</div>
	)
}