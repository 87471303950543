import {PlanUploadPageData} from "../types";
import {Provider, ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {planUploadConfigActions, selectPlanUploadStageConfig} from "../features/config/planUploadConfigSlice";
import React, {useEffect, useMemo, useRef} from "react";
import Konva from "konva";
import {useImage} from "../../../../project-drawings/hooks/useImage";
import {Group, Image, Layer, Rect, Stage} from "react-konva";
import {PlanUploadStageLayers} from "./PlanUploadStageLayers";
import {Status} from "../../../../../models/enums";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import LoggerService from "../../../../../services/LoggerService";
import AttachmentApi from "../../../../../api/AttachmentApi";
import {designateClipFunction, stageToPngBlob} from "../utils";
import {selectClipArea} from "../features/clip/planUploadClipSlice";
import {selectPlanUploadErasedAreas} from "../features/erase-area/planUploadEraseAreaSlice";
import {selectPlanUploadRemoteContentLoadedInfo, selectPlanUploadZoomState} from "../features/view/planUploadViewSlice";
import {EditorDrawnItemsDataProvider} from "../../../../project-drawings/features/editor/EditorDrawnItemsDataProvider";
import {IsAnyModalOpenContextBridge} from "../../../../../providers/IsAnyModalOpenProvider";
import {EraseAreaRectangles} from "../features/erase-area/EraseAreaRectangles";
import {UserSettingsDataContextBridge} from "../../../../../providers/UserSettingsProvider";
import {getRotationAwareSizeProps} from "../../../../base-konva/utils";


const PlanUploadHiddenEditor: React.FC<{
	planPage: PlanUploadPageData,
	onSuccess: (blob: Blob) => void,
	onFailure: () => void,
}> = ({
	planPage,
	onSuccess,
	onFailure
}) => {
	const dispatch = useDispatch()
	const stageConfig = useSelector(selectPlanUploadStageConfig)
	const stageRef = useRef<Konva.Stage>(null)
	const dataRef = useUpdatedRef({onSuccess, onFailure})
	const clipArea = useSelector(selectClipArea)
	const erasedAreas = useSelector(selectPlanUploadErasedAreas)
	const zoomState = useSelector(selectPlanUploadZoomState)
	const {allItemsLoaded} = useSelector(selectPlanUploadRemoteContentLoadedInfo)

	function getImageUrl(planPage: PlanUploadPageData) {
		return AttachmentApi.getAttachmentDownloadUrl(planPage.attachmentId, planPage.title)
	}

	const [image, status] = useImage(getImageUrl(planPage), "anonymous");

	useEffect(function initStageSize() {
		if (stageConfig.width === 0 && image && status === Status.SUCCESS) {
			dispatch(planUploadConfigActions.setInitialStageConfig({
				height: image.height,
				width: image.width
			}))
		}
	}, [dispatch, stageConfig, image, status])

	const canGenerate = Boolean(
		allItemsLoaded &&
		stageConfig.width !== 0 &&
		status === Status.SUCCESS
	)

	useEffect(function generateStagePngBlob() {
		if (
			canGenerate &&
			stageRef.current
		) {
			const {onSuccess, onFailure} = dataRef.current;
			stageRef.current.batchDraw()
			stageToPngBlob(stageRef.current).then(onSuccess).catch(e => {
				LoggerService.logError(e)
				onFailure()
			})
		}
	}, [canGenerate, stageRef, dataRef])

	const sizeProps = useMemo(() => {
		return getRotationAwareSizeProps(stageConfig, zoomState.rotation)
	}, [stageConfig, zoomState.rotation])

	return (
		<UserSettingsDataContextBridge.Consumer>{(settings) => (
			<ReactReduxContext.Consumer>{({store}) => (
				<IsAnyModalOpenContextBridge.Consumer>{modalData => (
					<Stage
						ref={stageRef}
						listening={false}
						rotation={zoomState.rotation}
						offsetX={stageConfig.width / 2}
						offsetY={stageConfig.height / 2}
						{...sizeProps}
					>
						<UserSettingsDataContextBridge.Provider value={settings}>
							<Provider store={store}>
								<EditorDrawnItemsDataProvider>
									<IsAnyModalOpenContextBridge.Provider value={modalData}>
										<Layer>
											<Rect
												fill={"white"}
												x={0} y={0}
												width={stageConfig.width}
												height={stageConfig.height}
											/>
											<Group clipFunc={designateClipFunction(clipArea)}>
												<Image image={image}/>
											</Group>
											<Rect
												name={"background"}
												width={stageConfig.width}
												height={stageConfig.height}
											/>
											<EraseAreaRectangles erasedAreas={erasedAreas}/>
											<PlanUploadStageLayers/>
										</Layer>
									</IsAnyModalOpenContextBridge.Provider>
								</EditorDrawnItemsDataProvider>
							</Provider>
						</UserSettingsDataContextBridge.Provider>
					</Stage>
				)}</IsAnyModalOpenContextBridge.Consumer>
			)}</ReactReduxContext.Consumer>
		)}</UserSettingsDataContextBridge.Consumer>
	)
}

export {PlanUploadHiddenEditor}