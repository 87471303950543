import {useProjectDetailContext} from "../providers/ProjectDetailProvider";
import React from "react";
import "./projectDetailsHeader.scss";
import {useTranslation} from "react-i18next";
import {Status} from "../../../models/enums";
import Spinner from "../../../components/Spinner";
import DataFetchError from "../../../components/ui/DataFetchError";

type ProjectDetailsHeaderProps = ReturnType<typeof useProjectDetailContext>["header"]
export const ProjectDetailsHeader: React.FC<ProjectDetailsHeaderProps> = function(props) {

	const {t} = useTranslation();
	const {loadStatus, customer, site, salesperson} = props

	return (
		<div className={"project-details-header"}>
			{loadStatus === Status.LOADING ? <Spinner/> : null}
			{loadStatus === Status.SUCCESS ? (
				<div className={"project-details-header_container"}>
				<span>{t("projects.details.customerName")}: <span
					className={"project-details-header_container_blue-text"}>{customer}</span></span>
					<span>{t("projects.details.site")}: <span
						className={"project-details-header_container_blue-text"}>{site}</span></span>
					<span>{t("projects.details.salesperson")}: <span
						className={"project-details-header_container_blue-text"}>{salesperson}</span></span>
				</div>
			) : null}
			{loadStatus === Status.ERROR ? <DataFetchError/> : null}
		</div>
	)
}