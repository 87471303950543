import React from "react";

const IconDeletionMinus: React.FC = () => {
	const fill = "#DE104F";

	return (
		<svg width={16} height={16} viewBox="0 0 32 32">
			<path fill={fill}
				  d="M16 0c-8.836 0-16 7.164-16 16s7.164 16 16 16 16-7.164 16-16-7.164-16-16-16zM24 18h-16v-4h16v4z"/>
		</svg>
	);
};

export default IconDeletionMinus