import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../../../../../components/modal/Modal";
import Button from "../../../../../../components/ui/Button";
import "./scaleModal.scss";
import ModalTitle from "../../../../../../components/modal/ModalTitle";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import ScaleSetInstructionModal from "./ScaleSetInstructionModal";
import {DrawingType} from "../../../../../../models/enums";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";

interface ScaleModalProps {
	isOpen: boolean,
	onClose: () => void,
	onSave: () => void,
	disableSave: boolean
}

const ScaleModal: React.FC<ScaleModalProps> = ({isOpen, onClose, onSave, disableSave}) => {
	const {t} = useTranslation();
	const [tutorialOpen, setTutorialOpen] = useState(false);
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF

	const noScaleModalTitleTranslations = {
		[DrawingType.TAKE_OFF]: t(`editor.scale.noScaleModalTitle.take_off`),
		[DrawingType.AS_BUILT]: t(`editor.scale.noScaleModalTitle.as_built`)
	}

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose}>
				<ModalTitle>{noScaleModalTitleTranslations[drawingType]}</ModalTitle>
				<div className="scale-modal_content">{t("editor.scale.noScaleModalContent")}</div>
				<ModalButtons>
					<Button label={t("editor.scale.showScaleSetInstruction")} variant={"optional"}
							onClick={() => setTutorialOpen(true)}/>
					<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
					<Button label={t("editor.scale.setScaleButton")} variant={"primary"} onClick={onSave} disabled={disableSave}/>
				</ModalButtons>
			</Modal>
			<ScaleSetInstructionModal
				open={tutorialOpen}
				onClose={() => setTutorialOpen(false)}
				drawingType={drawingType}
			/>
		</>
	);
};

export default ScaleModal;
