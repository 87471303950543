import React from "react";
import "./symbolRecognitionInitialPanel.scss"
import Button from "../../../../../../components/ui/Button"
import {useTranslation} from "react-i18next";
import {symbolRecognitionActions} from "./symbolRecognitionSlice";
import {SymbolRecognitionPanelStage} from "./types";
import {useDispatch} from "react-redux";

type SymbolRecognitionInitialPanelProps = {
	showSymbolRecognitionCancelModal: () => void
}

const SymbolRecognitionInitialPanel: React.FC<SymbolRecognitionInitialPanelProps> = ({showSymbolRecognitionCancelModal}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch()

	const onStartSelection = () => {
		dispatch(symbolRecognitionActions.setPanelStage(SymbolRecognitionPanelStage.SYMBOL_SELECTION))
	}

	return (
		<div className={"symbol-recognition-initial-panel"}>
			<div className={"symbol-recognition-initial-panel_header"}>
				<div className={"symbol-recognition-initial-panel_header_title"}>
					{t("editor.symbolRecognitionPanel.entryScreen.title")}
				</div>
			</div>
			<div className={"symbol-recognition-initial-panel_body"}>
				{t("editor.symbolRecognitionPanel.entryScreen.description")}
				<br/> <br/>
				{t("editor.symbolRecognitionPanel.entryScreen.instruction")}
			</div>
			<div className={"symbol-recognition-initial-panel_buttons"}>
				<Button onClick={showSymbolRecognitionCancelModal} variant={"optional"} label={t("common.buttons.cancel")}/>
				<Button onClick={onStartSelection} variant={"secondary"}
						label={t("editor.symbolRecognitionPanel.entryScreen.startSelectionButtonLabel")}/>
			</div>
		</div>
	)
}

export default SymbolRecognitionInitialPanel;
