import React from "react";

interface IconPenToolSmallProps {
	isActive?: boolean,
}

const IconPenToolSmall: React.FC<IconPenToolSmallProps> = (props) => {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16">
			<path
				d="M7.80107713,2.56230436 L8.28449219,3.43769564 L7.70140928,3.7663875 C7.04412745,4.14493066 6.46708259,4.50613935 5.96818987,4.84920383 L5.55965822,5.13879673 C3.80695504,6.42179916 3.20810259,7.4314183 3.64346489,8.12118804 C3.88006305,8.49604422 4.13391886,8.61248577 4.80755908,8.39412978 L5.04820556,8.30883976 L5.32326015,8.19812126 L5.63603585,8.06134561 L6.38800243,7.70710819 L7.07563552,7.36835441 C8.75755571,6.53098807 9.87180701,6.14732049 11.087423,6.14732049 C12.9485021,6.14732049 13.7279123,7.31949668 13.441685,9.09128289 C13.2460057,10.3025645 12.5490546,11.8030025 11.6883333,13.0240624 L11.4869517,13.3006273 L10.6878943,12.6993727 C11.5496083,11.5541691 12.2726689,10.0572625 12.4544838,8.93180321 C12.6503392,7.71943143 12.2699279,7.14732049 11.087423,7.14732049 C10.1391174,7.14732049 9.20579415,7.44692102 7.82436607,8.11477278 L6.33440891,8.85533953 L6.22184548,8.90881948 C5.73963222,9.13586124 5.3548375,9.28799086 5.00021603,9.38145493 C4.04411172,9.63344588 3.28838386,9.43216275 2.79782008,8.65493409 C2.08080224,7.51892114 2.60913106,6.2681866 4.28731668,4.86564264 L4.65090317,4.57328284 C5.15747763,4.18054916 5.75105489,3.77606815 6.42963033,3.35906123 L6.95446775,3.04394527 C7.04458641,2.99103045 7.13602533,2.93791687 7.22878061,2.88460302 L7.80107713,2.56230436 Z"
				fill={props.isActive ? "white" : "#262626"}/>
		</svg>
	);
};

export default IconPenToolSmall
