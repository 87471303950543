import React from "react";
import {ProjectSummary} from "./ProjectsTable";
import DatePresenter from "../../../components/ui/DatePresenter";
import ProjectStatusPresenter from "../../../components/ui/ProjectStatusPresenter";
import "./projectsTableRow.scss";
import IconDone from "../../../components/icons/IconDone";

interface ProjectImportDataTableRowProps {
	item: ProjectSummary,
	onRowClick: (simproId: number) => void,
}

const ProjectsTableRow: React.FC<ProjectImportDataTableRowProps> = ({item, onRowClick}) => (
	<tr onClick={() => onRowClick(item.id)} className="projects-table-row">
		<td>
			<div className={"projects-table-row_takeoffs"}>
				<IconDone active={Boolean(item.hasDrawings)}/>
			</div>
		</td>
		<td>{item.id}</td>
		<td><ProjectStatusPresenter status={item.status}/></td>
		<td>{item.customer}</td>
		<td>{item.site}</td>
		<td>{item.name}</td>
		<td>{item.salesperson || "-"}</td>
		<td><DatePresenter date={item.dueDate}/></td>
	</tr>
);

export default ProjectsTableRow
