import React, {useState} from "react";
import Section from "../../../../../models/Section";
import {CostCenter} from "../../../../../models/CostCenter";

export function useSyncMaterialsTreeSelectionData(takeoffSections: Section[]) {

	const [selectedCostCenters, setSelectedCostCenters] = useState<CostCenter[]>([]);

	React.useEffect(() => {
		setSelectedCostCenters([])
	}, [takeoffSections])

	function reset() {
		setSelectedCostCenters([])
	}

	function isSectionChecked(section: Section): boolean {
		return section.costCenters.every(cc => isCostCenterChecked(cc))
	}

	function onSectionToggle(section: Section) {
		if (isSectionChecked(section)) {
			setSelectedCostCenters(selectedCostCenters.filter(cc => (
				section.getAllCostCenterWithCompleteDrawingsIds().every(id => cc.id !== id)
			)));
		}
		else {
			const ccsToConcat = section.costCenters.filter(cc => (
				cc.hasCompletedDrawings() && !selectedCostCenters.some(scc => scc.id === cc.id)
			));
			setSelectedCostCenters(selectedCostCenters.concat(ccsToConcat));
		}
	}

	function isCostCenterChecked(costCenter: CostCenter): boolean {
		return selectedCostCenters.some(cc => cc.id === costCenter.id)
	}

	function onCostCenterToggle(section: Section, costCenter: CostCenter) {
		if (isCostCenterChecked(costCenter)) {
			setSelectedCostCenters(selectedCostCenters.filter(cc => cc.id !== costCenter.id));
		}
		else {
			setSelectedCostCenters(selectedCostCenters.concat(costCenter));
		}
	}

	return {
		isSectionChecked,
		onSectionToggle,
		isCostCenterChecked,
		onCostCenterToggle,
		selectedCostCenters,
		reset,
	}
}