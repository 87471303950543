import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Konva from "konva";
import {SelectionToolBox} from "./SelectionToolBox";
import {
	planUploadEraseAreaActions,
	selectPlanUploadEraseAreaSelectionState
} from "../features/erase-area/planUploadEraseAreaSlice";

type EraseAreaToolBoxProps = {
	whiteCanvasRef: React.RefObject<Konva.Rect>
}
export const EraseAreaToolBox: React.FC<EraseAreaToolBoxProps> = ({whiteCanvasRef}) => {
	const eraseAreaSelection = useSelector(selectPlanUploadEraseAreaSelectionState)
	const dispatch = useDispatch();

	function onCancel() {
		dispatch(planUploadEraseAreaActions.setSelection(undefined))
	}

	function onConfirmSelection() {
		dispatch(planUploadEraseAreaActions.confirmEraseArea())
	}


	return (
		<SelectionToolBox whiteCanvasRef={whiteCanvasRef} selection={eraseAreaSelection} onCancel={onCancel}
						  onConfirmSelection={onConfirmSelection}/>
	)
};
