import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import NumberInput from "../../../../../components/ui/NumberInput";
import {apiInstance} from "../../../../../api/api";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import {PlanSummaryItem} from "../../../../../models/PlanSummaryItem";
import SummaryMeasurement from "../../../../../models/SummaryMeasurement";

interface EditLabourModalProps {
	itemToEdit: PlanSummaryItem | SummaryMeasurement | undefined,
	onSuccess: () => void
	onClose: () => void
}

const EditLabourModal: React.FC<EditLabourModalProps> = ({itemToEdit, onSuccess, onClose}) => {
	const [labour, setLabour] = useState<number | undefined>(itemToEdit?.laborTime);
	const [isLoading, setLoading] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		setLabour(itemToEdit?.laborTime);
	}, [itemToEdit]);

	const onSave = () => {
		setLoading(true);
		apiInstance.measurementsApi.putMeasurementData(itemToEdit!.id, itemToEdit!.fitTimeId, labour)
			.then(() => {
				NotificationService.successNotification(t("common.success"),
					t("projects.summary.itemsTable.editLabourModal.saveSuccessDesc"));
				onSuccess();
			})
			.catch(err => {
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("projects.summary.itemsTable.editLabourModal.saveErrorDesc"))
			})
			.finally(() => setLoading(false))
	};

	return (
		<EditValueModal isOpen={!!itemToEdit}
						onClose={onClose}
						onSave={onSave}
						isLoading={isLoading}
						title={t("projects.summary.itemsTable.editLabourModal.title")}
						disableSave={labour === undefined || isLoading}>
			<NumberInput label={t("projects.summary.itemsTable.editLabourModal.inputLabel")}
						 value={labour}
						 min={0}
						 onChange={setLabour}/>
		</EditValueModal>
	);
};

export default EditLabourModal;
