import {useDispatch} from "react-redux";
import {useTemplatesData} from "../../../hooks/useTemplatesData";
import {useItemPresenterData} from "../../../hooks/useItemPresenterData";
import React, {useState} from "react";
import {ChangeState} from "./useMaterialChangeData";
import TakeoffTemplateItem from "../../../../../../../../../../models/TakeoffTemplateItem";
import TableHeaderCell from "../../../../../../../../../../components/layout/table/TableHeaderCell";
import {get} from "../../../../../../../../../../utils/ClassNameUtils";
import {MeasurementStyle} from "../../../../../../../../../../models/interfaces";
import {ActionCreatorWithPayload} from "@reduxjs/toolkit";
import {MaterialChangeActionPayload} from "../../../../../../models/editor";
import {EditorTool} from "../../../../../../models/enums";
import {countActions} from "../../../../../count/countSlice";
import {lengthActions} from "../../../../../length/lengthSlice";
import {areaActions} from "../../../../../area/areaSlice";
import {ItemsTableProps} from "../../../components/ItemsTable";
import {useTranslation} from "react-i18next";

export function useMaterialChangeTemplatesData() {
	const {t} = useTranslation()
	const dispatch = useDispatch();
	const {selectedTemplateId, ...templatesData} = useTemplatesData()
	const itemPresenterData = useItemPresenterData(selectedTemplateId)

	const [itemToAddId, setItemToAddId] = useState<string | undefined>(undefined);
	const [changeState, setChangeState] = useState<ChangeState | undefined>();

	const query = itemPresenterData.search.query
	const items = itemPresenterData.itemsTable.items.filter(item => {
		if (query === undefined) {
			return true
		}
		return item.name.toLowerCase().includes(query.toLowerCase())
	})

	const itemsTable: ItemsTableProps<TakeoffTemplateItem> = {
		tableRef: {current: null},
		items: items,
		columns: [{
			id: "name",
			label: t("projects.addItemModal.table.columns.name"),
			sortable: false
		}],
		headerTemplate: col => {
			return (
				<TableHeaderCell id={col.id} label={col.label} width={col.width}/>
			)
		},
		rowTemplate: item => {
			return (
				<tr
					className={get({"is-checked": item.id === itemToAddId})}
					onClick={() => setItemToAddId(item.id)}
				>
					<td>{item.name}</td>
				</tr>
			)
		}
	}

	let measurementStyleSelector = undefined;
	if (changeState) {
		measurementStyleSelector = {
			measurementType: changeState.measurementType,
			style: changeState.measurementStyle,
			onChange: (newStyle: MeasurementStyle) => {
				setChangeState(prevState => {
					if (prevState) {
						return {...prevState, measurementStyle: newStyle}
					}
					return undefined
				})
			}
		}
	}

	const contentFooter = {
		canChangeMaterial: itemToAddId !== undefined && changeState !== undefined,
		changeMaterial: () => {
			if (contentFooter.canChangeMaterial) {
				const [item] = itemsTable.items.filter(item => item.id === itemToAddId)
				let measurementMaterialChangeAction: ActionCreatorWithPayload<MaterialChangeActionPayload>
				switch (changeState!.measurementType) {
					case EditorTool.COUNT:
						measurementMaterialChangeAction = countActions.changeMaterial;
						break;
					case EditorTool.LENGTH:
						measurementMaterialChangeAction = lengthActions.changeMaterial;
						break;
					default:
						measurementMaterialChangeAction = areaActions.changeMaterial;
						break;
				}
				dispatch(measurementMaterialChangeAction({
					id: changeState!.measurementId,
					material: {
						id: item.materialId,
						name: item.name,
						type: item.materialType,
						partNumber: item.partNumber,
						laborTime: undefined,
						fitTimeId: undefined,
					},
					style: changeState!.measurementStyle
				}))
				setItemToAddId(undefined)
			}
		},
		measurementStyleSelector
	}

	return {
		...templatesData,
		itemPresenterData: {
			...itemPresenterData,
			itemsTable,
			contentFooter
		},
		setChangeState
	}
}