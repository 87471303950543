import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {ConfigState, StageConfig, SwitchEditorToolActionPayload, TabsVisibilityState} from "../../models/editor";
import {EditorTool} from "../../models/enums";
import {ActionIdValuePayload, UndoRedoExclusionPayload} from "../../../../../base-konva/types";

const initialState: ConfigState = {
	activeTool: EditorTool.COUNT,
	lastActiveTool: EditorTool.COUNT,
	locked: false,
	stageConfig: {
		height: 0,
		width: 0,
		backgroundImagePosition: {x: 0, y: 0}
	},
	tabsVisibility: {
		COUNT: true,
		LENGTH: true,
		AREA: true,
		VOLUME: true,
		LAYERS: true,
		VALUE_MARKS: true,
	}
}

const configSlice = createSlice({
	name: 'config',
	initialState,
	reducers: {
		reset: (_, {payload}: PayloadAction<{ stageConfig: StageConfig | null }>) => ({
			...initialState,
			stageConfig: payload.stageConfig ?? initialState.stageConfig,
		}),
		switchEditorTool: (state, action: PayloadAction<SwitchEditorToolActionPayload>) => {
			state.lastActiveTool = state.activeTool;
			state.activeTool = action.payload.editorTool;
		},
		switchEditorToolBack: (state, _: PayloadAction<UndoRedoExclusionPayload>) => {
			state.activeTool = state.lastActiveTool;
		},
		setTabsVisibilityState: (state, {payload}: PayloadAction<TabsVisibilityState>) => {
			state.tabsVisibility = payload;
		},
		setStageConfig: (state, {payload}: PayloadAction<ActionIdValuePayload<StageConfig>>) => {
			state.stageConfig = payload.value
		},
		// Same as setStageConfig but undo/redo excluded
		setInitialStageConfig: (state, {payload}: PayloadAction<ActionIdValuePayload<StageConfig>>) => {
			state.stageConfig = payload.value
		},
		setEditorLockState: (state, {payload}: PayloadAction<boolean>) => {
			state.locked = payload;
		},
	}
})
export const configReducer = configSlice.reducer;
export const configActions = configSlice.actions;

export const selectActiveTool = (state: RootState) => state.undoGroup.present.config.activeTool;
export const selectTabsVisibilityState = (state: RootState) => state.undoGroup.present.config.tabsVisibility;
export const selectStageConfig = (state: RootState) => state.undoGroup.present.config.stageConfig;
export const selectEditorLockState = (state: RootState) => state.undoGroup.present.config.locked;


