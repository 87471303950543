import Konva from "konva";
import {mimeTypeMap} from "../../../../project-drawings/features/editor/constants";
import {FileType} from "../../../../../models/enums";
import {SelectionState, Vertices} from "../../../../base-konva/types";

export async function stageToPngBlob(stage: Konva.Stage) {
	return new Promise<Blob>((resolve, reject) => {
		try {
			stage.toCanvas({}).toBlob(blob => {
				if (blob) {
					return resolve(blob)
				}
				else {
					return reject(new Error('Blob object is undefined'))
				}
			}, mimeTypeMap[FileType.PNG], 1)
		}
		catch (e) {
			return reject(e)
		}
	})
}

export function designateClipFunction(clipArea: SelectionState | undefined) {
	if (clipArea) {
		return (ctx: CanvasRenderingContext2D) => {ctx.rect(clipArea.x, clipArea.y, clipArea.width, clipArea.height)}
	}
	else {
		return undefined
	}
}

export function convertSelectionToPoints(selection: SelectionState): number[] {
	return [
		selection.x, selection.y,
		selection.x + selection.width, selection.y,
		selection.x + selection.width,
		selection.y + selection.height,
		selection.x, selection.y + selection.height
	]
}

export function convertSelectionToVertices(selection: SelectionState): Vertices {
	const selectionPoints = convertSelectionToPoints(selection)
	return {
		topLeft: {x: selectionPoints[0], y: selectionPoints[1]},
		topRight: {x: selectionPoints[2], y: selectionPoints[3]},
		bottomRight: {x: selectionPoints[4], y: selectionPoints[5]},
		bottomLeft: {x: selectionPoints[6], y: selectionPoints[7]}
	}
}

export function convertVerticesToPoints(vertices: Vertices): number[] {
	return [vertices.topLeft.x, vertices.topLeft.y,
		vertices.topRight.x, vertices.topRight.y,
		vertices.bottomRight.x, vertices.bottomRight.y,
		vertices.bottomLeft.x, vertices.bottomLeft.y]
}