import {useKeyboard} from "./useKeyboard";
import {useIsAnyModalOpenContext} from "../../../../../providers/IsAnyModalOpenProvider";

interface UseKeyboardOnModalHook {
	isShiftDown: boolean,
	isAltDown: boolean
}

interface UseKeyboardOnModalProps {
	keyDownMap?: { [code: string]: () => void }
	keyUpMap?: { [code: string]: () => void }
	when: boolean
}

export function useKeyboardOnModal({
	keyDownMap = {},
	keyUpMap = {},
	when
}: UseKeyboardOnModalProps): UseKeyboardOnModalHook {
	const {isAnyModalOpen} = useIsAnyModalOpenContext()
	const {isShiftDown, isAltDown} = useKeyboard({keyDownMap, keyUpMap, when: when && isAnyModalOpen});
	return {isShiftDown, isAltDown}
}
