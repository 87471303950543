import React from "react";
import {IconDownloadSmall} from "../../../../components/icons/IconDownload";
import CommonDropdown from "../../../../components/ui/CommonDropdown";
import {Status} from "../../../../models/enums";
import Button from "../../../../components/ui/Button";
import {useTranslation} from "react-i18next";

interface DownloadDrawingsDropdownProps {
	downloadStatus: Status,
	disabled: boolean
}

export const DownloadDrawingsDropdown: React.FC<DownloadDrawingsDropdownProps> = ({downloadStatus, disabled, children}) => {
	const {t} = useTranslation();

	const button =
		<Button
			label={t(downloadStatus === Status.LOADING ? "common.buttons.downloading" : "common.buttons.download")}
			variant={"secondary"}
			icon={<IconDownloadSmall/>}
			disabled={disabled || downloadStatus === Status.LOADING}/>;

	return (
		<CommonDropdown button={button}>
			{children}
		</CommonDropdown>
	);
};
