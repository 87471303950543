import {useTranslation} from "react-i18next";
import {useRef, useState} from "react";
import {useBrowserData} from "./useBrowserData";
import {Status} from "../../../../../../../../models/enums";
import {TakeoffTemplate} from "../../../../../../../../models/TakeoffTemplate";
import {Breadcrumb, Level, TemplateView} from "../types";

export function useTemplatesData() {
	const {t} = useTranslation()
	const tableRef = useRef<HTMLTableElement | null>(null);
	const [selectedTemplateId, setSelectedTemplateId] = useState<number | undefined>(undefined);
	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([{
		id: undefined,
		name: t("projects.addItemModal.takeoffTemplates.rootBreadcrumbName"),
		level: Level.ROOT
	}]);
	const browserData = useBrowserData(breadcrumbs, setBreadcrumbs)

	const view = selectedTemplateId === undefined ? TemplateView.BROWSER : TemplateView.ITEM_PRESENTER;

	function handleBreadcrumbClick(breadcrumb: Breadcrumb) {
		if (view === TemplateView.ITEM_PRESENTER && breadcrumb.level !== Level.TEMPLATE) {
			browserData.onSearch("")
		}
		if (breadcrumb.level !== Level.TEMPLATE) {
			setSelectedTemplateId(undefined)
		}
		setBreadcrumbs(breadcrumbs.slice(0, breadcrumbs.indexOf(breadcrumb) + 1))
	}

	return {
		selectedTemplateId,
		view,
		breadcrumbs: {
			breadcrumbs,
			handleBreadcrumbClick,
		},
		browserData: {
			loadStatus: browserData.loadStatus,
			search: {
				isSearching: browserData.loadStatus === Status.LOADING,
				onSearch: browserData.onSearch
			},
			overlay: {
				loading: browserData.loadStatus === Status.LOADING || browserData.groupsLoadStatus === Status.LOADING
			},
			groupsTable: {
				items: browserData.groups,
				onItemClick: browserData.onGroupClick
			},
			templatesTable: {
				tableRef,
				items: browserData.items,
				onItemClick: (checkedItem: TakeoffTemplate) => {
					setBreadcrumbs([...breadcrumbs, {
						id: checkedItem.id,
						name: checkedItem.name,
						level: Level.TEMPLATE
					}])
					setSelectedTemplateId(checkedItem.id)
				}
			},
			pagination: {
				pageNumber: browserData.pageNumber,
				totalPages: browserData.totalPages,
				onPageChange: (page: number) => {
					tableRef.current?.scrollIntoView()
					browserData.onPageChange(page)
				},
				disabled: browserData.loadStatus === Status.LOADING
			}
		}
	}
}