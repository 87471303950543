import React, {useEffect, useState} from "react";
import Button from "../../../../../../components/ui/Button";
import {selectActiveTool} from "../config/configSlice";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import "./editorHeaderScalePanel.scss";
import {EditorTool} from "../../models/enums";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";
import {convertToMeters, convertToUnit} from "../../../../../../utils/ConversionUtils";
import {Status, UnitsType} from "../../../../../../models/enums";
import {useScaleSetDataContext} from "../scale/ScaleSetProvider";
import {viewActions} from "../view/viewSlice";
import LengthInput from "../../../../../../components/ui/LengthInput";
import {maxFourDecimalsNumberRegexp} from "../../../../../../utils/TextUtils";
import {scaleActions, selectInputLength, selectScalePoints} from "../scale/scaleSlice";

type EditorHeaderScalePanelProps = {
	disabled?: boolean,
}
const EditorHeaderScalePanel: React.FC<EditorHeaderScalePanelProps> = ({disabled}) => {

	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const inputLength = useSelector(selectInputLength);
	const scalePoints = useSelector(selectScalePoints);
	const {settings} = useUserSettingsDataContext();
	const {t} = useTranslation();
	const {openScaleSetModal, save, saveStatus} = useScaleSetDataContext()

	const [length, setLength] = useState<number | undefined>(
		convertToUnit(inputLength || undefined, settings?.measurementSystem) || undefined
	);

	useEffect(() => {
		setLength(convertToUnit(inputLength || undefined, settings?.measurementSystem) || undefined)
	}, [inputLength, settings?.measurementSystem])

	function handleResetScale() {
		dispatch(scaleActions.resetScale());
	}

	function handleLengthChange(value: number | undefined) {
		if (value === undefined) {
			setLength(undefined);
		}
		else {
			if (settings?.measurementSystem === UnitsType.IMPERIAL || maxFourDecimalsNumberRegexp.test(value.toString())) {
				setLength(Number(value));
			}
		}
	}

	function handleSaveLength() {
		if (scalePoints.length === 2 && length !== null) {
			let inputLength = convertToMeters(length || undefined, settings?.measurementSystem) || null;
			if (inputLength) {
				const [p1, p2] = scalePoints;
				const distance = Math.hypot(p1.x - p2.x, p1.y - p2.y);
				let scale = 1 / (distance / inputLength);
				save(scale);
				dispatch(scaleActions.setInputLength(inputLength));
				dispatch(viewActions.resetScaleOptions());
			}
		}
	}

	function handleKeySave(evt: React.KeyboardEvent) {
		(evt.key === 'Enter' && length) && handleSaveLength();
	}

	const cls = [
		"editor-header-scale-panel",
		settings?.measurementSystem === UnitsType.IMPERIAL && "imperial"
	].filter(Boolean).join(" ");

	return (
		<div className={cls}>
			{activeTool === EditorTool.SCALE ?
				<>
					<span className={"editor-header-scale-panel_input-label"}>{t("editor.scale.inputLabel")}</span>
					<LengthInput
						unitsType={settings?.measurementSystem}
						label={""} value={length}
						onKeyDown={handleKeySave} onChange={handleLengthChange}
						placeholder={t("editor.scale.inputPlaceholder")}
					/>
					<Button label={t("editor.scale.doneButton")} variant={"primary"} onClick={handleSaveLength}
							disabled={length === null || scalePoints.length !== 2 || saveStatus === Status.LOADING}/>
					<Button label={t("editor.scale.resetButton")} variant={"optional"} onClick={handleResetScale}
							disabled={saveStatus === Status.LOADING}/>
				</> :
				<Button variant={"optional"} label={t("editor.scale.setScaleButton")} onClick={openScaleSetModal}
						disabled={disabled}/>
			}
		</div>
	);
};

export default EditorHeaderScalePanel
