import React from "react";
import {capitalizeString, uuidRegexp} from "../../../utils/TextUtils";
import ProjectDetailsBreadcrumb from "./ProjectDetailsBreadcrumb";

const ProjectBreadcrumb: React.FC<any> = ({match}) => {
	return (
		<>
			{uuidRegexp.test(match.params.projectId) ?
				<ProjectDetailsBreadcrumb projectId={match.params.projectId}/> :
				<span>{capitalizeString(match.params.projectId)}</span>
			}
		</>
	);
};

export default ProjectBreadcrumb
