import React from "react";

interface IIconDoneProps {
	active?: boolean,
}

const IconDone: React.FC<IIconDoneProps> = ({active}) => {

	const color = active === false ? "#CCCCCC" : "#41B555";

	return (
		<svg width="20px" height="20px" viewBox="0 0 24 24">
			<polygon fill={color} points="19.2 5 8.6 15.6 4.4 11.4 3 12.8 8.6 18.4 20.6 6.4"/>
		</svg>
	);
};

export default IconDone
