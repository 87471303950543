import React from "react";
import {SelectableIconProps} from "../../models/interfaces";
import {getDefaultSelectableIconClasses} from "../iconUtils";
import "./iconSelectable.scss";

const IconSelectable: React.FC<SelectableIconProps> = ({
	paths,
	onClick,
	active = false,
	disabled = false,
	size = 32,
	customTransform
}) => {

	const handleClick = () => !disabled && onClick();

	const cls = getDefaultSelectableIconClasses(active, disabled);
	let transform = `translate(${(32 - size) / 2}, ${(32 - size) / 2})`;
	return (
		<div onClick={handleClick} className={cls}>
			<svg width={size} height={size} viewBox="0 0 32 32">
				{paths.map((path, index) =>
					<path
						key={index}
						transform={customTransform ?? transform}
						d={path}/>
				)}
			</svg>
		</div>
	);
};

export default IconSelectable

