import React, {useState} from "react";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import {useTranslation} from "react-i18next";
import TextInput from "../../../project-drawings/features/editor/components/TextInput";
import "./createSectionModal.scss"
import Button from "../../../../components/ui/Button";
import ModalButtons from "../../../../components/modal/ModalButtons";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import {ProjectSource} from "../../../../models/enums";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import InlineSpinner from "../../../../components/InlineSpinner";
import LoggerService from "../../../../services/LoggerService";

type CreateSectionModalProps = {
	onClose: () => void
}

export const CreateSectionModal: React.FC<CreateSectionModalProps> = ({onClose}) => {
	const {t} = useTranslation()
	const [sectionName, setSectionName] = useState<string>("")
	const [isUpdating, setIsUpdating] = useState<boolean>(false)

	const {forceTreeUpdate} = useProjectDetailContext()
	const {projectDetails: {project}} = useProjectDetailContext()

	const canSave = project && !isUpdating && sectionName.length !== 0
	const onSave = () => {
		if (canSave) {
			setIsUpdating(true);
			(project!.source === ProjectSource.JOB ?
					apiInstance.projectsApi.postNewJobSection(project!.id, sectionName) :
					apiInstance.projectsApi.postNewQuoteSection(project!.id, sectionName)
			).then(
				(response) => {
					if (response.errors.length > 0) {
						response.errors.forEach(error => {
							NotificationService.errorNotification(
								t("common.error"),
								error.message
							)
						})
					}
					else {
						NotificationService.successNotification(
							t("common.success"),
							t("projects.details.createNewSectionModal.creationSuccessDesc")
						)
						forceTreeUpdate();
						onClose();
					}
				})
				.catch(
					(error) => {
						LoggerService.logError(error)
						NotificationService.errorNotification(
							t("common.error"),
							t("projects.details.createNewSectionModal.creationErrorDesc")
						)
					}
				)
				.finally(() => {
					setIsUpdating(false)
				})
		}
	}

	const onSectionNameChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
		setSectionName(evt.target.value)
	}

	return (
		<ModalWithHeader customClass={"create-section-modal"} isOpen={true}
						 title={t("projects.details.createNewSectionModal.title")} onClose={onClose}>
			<div className={"create-section-modal_container"}>
				<span className={"create-section-modal_container_label"}>
					{t("projects.details.createNewSectionModal.header")}</span>
				<TextInput value={sectionName} onChange={onSectionNameChange}
						   placeholder={t("projects.details.createNewSectionModal.inputPlaceholder")}
						   className={"create-section-modal_container_input"}/>
				<div className={"create-section-modal_container_footer"}>
					<ModalButtons>
						{isUpdating && <InlineSpinner/>}
						<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
						<Button label={t("common.buttons.save")} disabled={!canSave}
								variant={"primary"}
								onClick={onSave}/>
					</ModalButtons>
				</div>
			</div>
		</ModalWithHeader>
	)
}