import React from "react";
import {Document, Image, Page, Text} from "@react-pdf/renderer";
import {PlanSummaryItem} from "../../../../../../models/PlanSummaryItem";
import {DataTableCell, Table, TableBody, TableCell, TableHeader} from "@david.kucsai/react-pdf-table";
import {useTranslation} from "react-i18next";
import {formatLabor} from "../../../../../../utils/TextUtils";
import FitTime from "../../../../../../models/FitTime";
import {DrawingFileInfo} from "./useDrawingSummaryPDF";
import {DrawingType, KeySettingsType, UnitsType} from "../../../../../../models/enums";
import {SymbolPreview} from "./symbol-preview/SymbolPreview";
import {formatDeduction, formatMarkupQty, formatValue} from "../../../../../../utils/SummaryUtils";
import UserSettings from "../../../../../../models/UserSettings";
import {useBreakAll} from "./useBreakAll";

interface DrawingSummaryPDFProps {
	items: PlanSummaryItem[];
	fitTimes: FitTime[];
	drawingFileInfo: DrawingFileInfo
	drawingType: DrawingType
	settings: UserSettings | undefined
}

export const DrawingSummaryPDF: React.FC<DrawingSummaryPDFProps> = ({
	items, fitTimes, drawingFileInfo, drawingType, settings
}) => {
	const {t} = useTranslation();
	const breakAll = useBreakAll()
	const unitType = settings?.measurementSystem ?? UnitsType.METRIC
	const isKeySettingsDetailed = (settings?.keySettings ?? KeySettingsType.DETAILED) === KeySettingsType.DETAILED
	const isDrawingTakeoffType = drawingType === DrawingType.TAKE_OFF;

	const imagePadding = 10;
	const pageWidth = drawingFileInfo.width + imagePadding + imagePadding
	const pageHeight = drawingFileInfo.height + imagePadding // + 90

	// const drawingTypeLabel = drawingType === DrawingType.TAKE_OFF
	// 	? t("projects.summary.summaryPdfTableColumns.takeoff")
	// 	: t("projects.summary.summaryPdfTableColumns.asBuilt")

	return (
		<Document>
			<Page size={[pageWidth, pageHeight]}>
				<Image
					style={{
						height: drawingFileInfo.height,
						paddingTop: imagePadding,
						paddingLeft: imagePadding,
						paddingRight: imagePadding
					}}
					src={drawingFileInfo.url}
				/>
				{/*<View style={{width: 500, position: "absolute", right: 30, bottom: 30}}>*/}
				{/*	<Table data={[*/}
				{/*		{*/}
				{/*			first: `${t("projects.summary.summaryPdfTableColumns.date")}: ${moment().format("DD/MM/YYYY")}`,*/}
				{/*			second: `${t(*/}
				{/*				"projects.summary.summaryPdfTableColumns.costCenter"*/}
				{/*			)}: ${drawingFileInfo.costCenter.name}`*/}
				{/*		}*/}
				{/*	]}>*/}
				{/*		<TableHeader fontSize={11}>*/}
				{/*			<TableCell weighting={0.6} style={{padding: 5}}>*/}
				{/*				{`${t(*/}
				{/*					"projects.summary.summaryPdfTableColumns.name"*/}
				{/*				)}: ${drawingFileInfo.drawing.name} (${drawingTypeLabel})`}*/}
				{/*			</TableCell>*/}
				{/*			<TableCell weighting={0.4} style={{padding: 5}}>*/}
				{/*				{`${t("projects.summary.summaryPdfTableColumns.section")}: ${drawingFileInfo.section.name}`}*/}
				{/*			</TableCell>*/}
				{/*		</TableHeader>*/}
				{/*		<TableBody fontSize={11}>*/}
				{/*			<DataTableCell weighting={0.6} style={{padding: 5}} getContent={(item) => item.first}/>*/}
				{/*			<DataTableCell weighting={0.4} style={{padding: 5}} getContent={(item) => item.second}/>*/}
				{/*		</TableBody>*/}
				{/*	</Table>*/}
				{/*</View>*/}
			</Page>
			<Page break={false} style={{padding: "1cm"}}>
				<Table data={items}>
					<TableHeader fontSize={11} textAlign={"center"}>
						{isKeySettingsDetailed ?
							<TableCell weighting={0.2}>
							{t("projects.summary.summaryPdfTableColumns.partNumber")}
						</TableCell> : null}

						<TableCell weighting={!isKeySettingsDetailed ? 0.6 : isDrawingTakeoffType ? 0.3 : 0.5}>
							{t("projects.summary.summaryPdfTableColumns.name")}
						</TableCell>
						{isDrawingTakeoffType && isKeySettingsDetailed ?
							<TableCell weighting={0.1}>
								{t("projects.summary.summaryPdfTableColumns.labour")}
							</TableCell> : null}
						{isDrawingTakeoffType && isKeySettingsDetailed?
							<TableCell weighting={0.1}>
								{t("projects.summary.summaryPdfTableColumns.fitTimeMultiplier")}
							</TableCell> : null}
						{isKeySettingsDetailed ?
							<TableCell weighting={0.1}>
							{t("projects.summary.summaryPdfTableColumns.markUpQty")}
							</TableCell> : null }
						{isKeySettingsDetailed ?
							<TableCell weighting={0.1}>
								{t("projects.summary.summaryPdfTableColumns.deduction")}
							</TableCell> : null }
						<TableCell weighting={isKeySettingsDetailed ? 0.1 : 0.2}>
							{t("projects.summary.summaryPdfTableColumns.quantity")}
						</TableCell>
						{null}
						<TableCell weighting={isKeySettingsDetailed ? 0.1 : 0.2}>
							{t("projects.summary.summaryPdfTableColumns.symbol")}
						</TableCell>
					</TableHeader>
					<TableBody fontSize={10} textAlign={"center"}>
						{isKeySettingsDetailed ?
							<DataTableCell weighting={0.2} getContent={(item) =>
								<Text {...breakAll}>{item.partNumber}</Text>}
							/> : null }
						<DataTableCell weighting={!isKeySettingsDetailed ? 0.6 : isDrawingTakeoffType ? 0.3 : 0.5} getContent={(item) =>
							<Text {...breakAll}>{item.name}</Text>}
						/>
						{isDrawingTakeoffType && isKeySettingsDetailed ?
							<DataTableCell weighting={0.1} getContent={(item) => formatLabor(item.laborTime)}/>
							: null}
						{isDrawingTakeoffType && isKeySettingsDetailed ?
							<DataTableCell weighting={0.1}
										   getContent={(item) => {
											   const fitTime = fitTimes.find(ft => ft.id === item.fitTimeId);
											   return fitTime?.getFormattedMultiplier() ?? "-";
										   }}/>
							: null}
						{isKeySettingsDetailed ?
							<DataTableCell weighting={0.1} getContent={(item) => formatMarkupQty(item, unitType)}/>
							: null }
						{isKeySettingsDetailed ?
							<DataTableCell weighting={0.1} getContent={(item) => formatDeduction(item, unitType)}/>
							: null }
						<DataTableCell weighting={isKeySettingsDetailed ? 0.1 : 0.2} getContent={(item) => formatValue(item, unitType)}/>
						<DataTableCell weighting={isKeySettingsDetailed ? 0.1 : 0.2} getContent={(item) => <SymbolPreview item={item}/>}/>
					</TableBody>
				</Table>
				<Text style={{position: "absolute", right: 30, bottom: 30, fontSize: 12}}
					  render={({pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)} fixed/>
			</Page>
		</Document>
	);
};



