import React from "react";
import "./planTreeTemplate.scss";
import PlanTreePage from "./PlanTreePage";
import IconPDF from "../../../project-drawings/features/editor/icons/IconPDF";
import ImageFilledIcon from "../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/ImageFilledIcon";
import {PlanUploadData, PlanUploadPageData} from "../../features/plan-upload/types";
import {Colors} from "../../../../styles";

interface PlanTreeTemplateProps {
	planTemplate: PlanUploadData
	renderPageOptionsDropdown: (template: PlanUploadData,
		page: PlanUploadPageData) => (selectedPageNumber: number | undefined) => JSX.Element
	renderTemplateOptionsDropdown: (template: PlanUploadData) => JSX.Element,
	selectedPageNumber?: number,
	onPageClick: (page: PlanUploadPageData) => void,
	onTemplateClick?: (template: PlanUploadData) => void
}

const PlanTreeTemplate: React.FC<PlanTreeTemplateProps> = ({
	planTemplate,
	renderPageOptionsDropdown,
	renderTemplateOptionsDropdown,
	onPageClick,
	selectedPageNumber,
	onTemplateClick
}) => {
	const renderFileIcon = () => {
		if (planTemplate.isPdf) {
			return <span><IconPDF/></span>
		}
		else {
			return <span><ImageFilledIcon fill={Colors.SIMPROBLUE}/></span>
		}
	}

	const headerContainerCls = [
		"plan-tree-template_header_container",
		selectedPageNumber === undefined && "previewable"
	].filter(Boolean).join(" ");

	const onHeaderClick = () => {
		if (onTemplateClick) {
			onTemplateClick(planTemplate)
		}
	}

	return (
		<div className={"plan-tree-template"}>
			<div className={"plan-tree-template_header"}>
				<div className={headerContainerCls} onClick={onHeaderClick}>
					<div className={"plan-tree-template_header_container_title"}>
						{renderFileIcon()}
						<span className={"plan-tree-template_header_container_title_text"}>
                            {planTemplate.name}
                        </span>
					</div>
				</div>
				{renderTemplateOptionsDropdown(planTemplate)}
			</div>
			<div className={"plan-tree-template_tree"}>
				<div className={"plan-tree-template_tree_pages-container"}>
					{planTemplate.pages.map((page, index) =>
						<PlanTreePage
							isFirstItem={index === 0}
							isLastItem={index === planTemplate.pages.length - 1}
							key={page.pageNumber}
							page={page}
							onPageClick={onPageClick}
							selectedPageNumber={selectedPageNumber}
							renderPageOptionsDropdown={renderPageOptionsDropdown(planTemplate, page)}/>
					)}
				</div>
			</div>
		</div>
	);
};

export default PlanTreeTemplate;
