import {useState} from "react";
import {DrawingVersion} from "../../../models/DrawingVersion";

export function useVersionsModalData() {
	type UnsavedChangesPromptModalData = {
		onCancel: () => void,
		onConfirm: () => void,
		onSave: () => void,
		middleButtonLabel?: string
		contentLine1?: string,
		contentLine2?: string,
	}
	type Modal =
		| { type: 'versions' }
		| { type: 'copy-drawing-version', data: { drawingVersion: DrawingVersion } }
		| { type: 'revert-drawing-version', data: { drawingVersion: DrawingVersion } }
		| { type: 'unsaved-changes-prompt', data: UnsavedChangesPromptModalData }

	const [modal, setModal] = useState<Modal>()

	function closeModal() {
		setModal(undefined);
	}

	function openVersionListModal() {
		setModal({type: 'versions'})
	}

	function openCopyDrawingVersionModal(drawingVersion: DrawingVersion) {
		setModal({type: 'copy-drawing-version', data: {drawingVersion}})
	}

	function openRevertDrawingVersionModal(drawingVersion: DrawingVersion) {
		setModal({type: 'revert-drawing-version', data: {drawingVersion}})
	}

	function openUnsavedChangesPromptModal({onCancel, onConfirm, onSave, middleButtonLabel, contentLine1, contentLine2}: {
		onCancel: () => void,
		onConfirm: () => void,
		onSave: () => void,
		middleButtonLabel?: string,
		contentLine1?: string,
		contentLine2?: string,
	}) {
		setModal({
			type: 'unsaved-changes-prompt',
			data: {onCancel, onConfirm, onSave, middleButtonLabel, contentLine1, contentLine2}
		})
	}

	return {
		modal,
		closeModal,
		openVersionListModal,
		openCopyDrawingVersionModal,
		openRevertDrawingVersionModal,
		openUnsavedChangesPromptModal,
	}
}