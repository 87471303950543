import {useLocation, useParams} from "react-router-dom";
import React, {useState} from "react";
import {capitalizeString, uuidRegexp} from "../../../utils/TextUtils";
import {DrawingType, Status} from "../../../models/enums";
import "./projectDrawingTitle.scss";
import {useTranslation} from "react-i18next";
import {useDrawingDetailsDataContext} from "../providers/DrawingDetailsProvider";
import {INITIAL_VERSION_ID} from "../features/editor/constants";
import Button from "../../../components/ui/Button";
import {useProjectDetailContext} from "../../project-details/providers/ProjectDetailProvider";
import {VersionsModal} from "./VersionsModal";
import {DrawingVersion} from "../../../models/DrawingVersion";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {useSelector} from "react-redux";
import {selectEditorIsDirty} from "../features/editor/features/view/viewSlice";
import {apiInstance} from "../../../api/api";
import {useSaveDrawingValidator} from "../../../hooks/useSaveDrawingValidator";
import {CopyDrawingVersionModal} from "./CopyDrawingVersionModal";
import {UnsavedChangesPromptModal} from "./UnsavedChangesPromptModal";
import {RevertDrawingVersionModal} from "./RevertDrawingVersionModal";
import {pages, useNavigator} from "../../navigator";

export function ProjectDrawingTitle() {
	const params = useParams<{ projectId: string, sectionId: string, costCenterId: string, drawingId: string, versionId: string }>();
	const {projectId} = params;
	const location = useLocation();
	const {navigateTo} = useNavigator()
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {
		forceDrawingUpdate,
		drawingData: {loadStatus, drawingDetails},
		drawingSaveData,
		versionsModalsData: {
			modal,
			closeModal,
			openVersionListModal,
			openCopyDrawingVersionModal,
			openRevertDrawingVersionModal,
			openUnsavedChangesPromptModal,
		}
	} = useDrawingDetailsDataContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {userData: {users, usersLoadStatus}, forceTreeUpdate} = useProjectDetailContext()
	const isDirty = useSelector(selectEditorIsDirty);
	const {t} = useTranslation();
	const [copyStatus, setCopyStatus] = useState(Status.IDLE);
	const {validateSaveDrawing} = useSaveDrawingValidator()

	function getDrawingName() {
		if (uuidRegexp.test(params.drawingId)) {
			return drawingDetails?.name
		}
		else {
			return capitalizeString(params.drawingId)
		}
	}

	function getDrawingVersion() {
		if (params.versionId === INITIAL_VERSION_ID) {
			return t("common.initialVersion");
		}
		else if (uuidRegexp.test(params.versionId)) {
			const version = drawingDetails?.versions.find(v => v.id === params.versionId)
			return `${t("common.version")} ${version?.version.getVersionLabel()} (${version?.formattedLabels})`
		}
		else {
			return capitalizeString(params.versionId)
		}
	}

	function openDrawingVersion(
		sectionId: string,
		ccId: string,
		drawingId: string,
		versionId: string,
	) {
		navigateTo(pages.projectDrawingPage(
			type, projectId, sectionId, ccId, drawingId, versionId
		))
	}

	function onCopy(mode: 'save' | 'save-and-open', drawingVersion: DrawingVersion) {
		return function(name: string, sectionId?: string, ccId?: string) {

			const handleError = function(err?: any) {
				setCopyStatus(Status.ERROR)
				LoggerService.logError(err);
				NotificationService.errorNotification(
					t("common.error"),
					t("editor.versionsModal.copyDrawingVersionErrDesc")
				)
			};

			(async function() {
				setCopyStatus(Status.LOADING)
				if (!ccId) {
					throw new Error("Target cost center id is undefined")
				}
				if (!sectionId) {
					throw new Error("Target section id is undefined")
				}

				const copyDrawingVersion = async function() {
					const result = await apiInstance.drawingsApi.copyDrawingVersion(drawingVersion.id, name, ccId)
					forceTreeUpdate();
					return result;
				}

				const copyAndOpen = async function() {
					const {drawingId, drawingVersionsIds} = await copyDrawingVersion();
					const [versionId] = drawingVersionsIds

					if (!versionId) {
						throw new Error("Version id is undefined")
					}

					openDrawingVersion(sectionId, ccId, drawingId, versionId)
					setCopyStatus(Status.SUCCESS)
					closeModal()
				}

				const handleSave = async function() {
					await copyDrawingVersion();
					setCopyStatus(Status.SUCCESS)
					closeModal()
				}

				const handleSaveAndOpen = async function() {
					if (isDirty) {
						setCopyStatus(Status.IDLE)
						openUnsavedChangesPromptModal({
							middleButtonLabel: t("editor.versionsModal.unsavedChangesPrompt.copy.buttonLabel"),
							contentLine1: t("editor.versionsModal.unsavedChangesPrompt.copy.contentLine1"),
							contentLine2: t("editor.versionsModal.unsavedChangesPrompt.copy.contentLine2"),
							onCancel: openVersionListModal,
							onConfirm: async () => {
								try {
									setCopyStatus(Status.LOADING)
									await copyAndOpen();
								}
								catch (e) {
									handleError(e)
								}
							},
							onSave: () => {
								validateSaveDrawing(async () => {
									try {
										setCopyStatus(Status.LOADING)
										const data = await drawingSaveData.saveDrawing(
											params.drawingId, params.versionId, drawingType
										)
										if (data) {
											await copyAndOpen();
										}
										else {
											setCopyStatus(Status.ERROR)
										}
									}
									catch (e) {
										handleError(e)
									}
								})
							},
						})
					}
					else {
						await copyAndOpen();
					}
				}

				switch (mode) {
					case "save":
						return handleSave();
					case "save-and-open": {
						return handleSaveAndOpen();
					}
				}
			})().catch(handleError)
		}
	}

	function handleLoadSelectedVersion(versionId: string) {
		const openSelectedVersion = function() {
			openDrawingVersion(params.sectionId, params.costCenterId, params.drawingId, versionId)
			closeModal();
		}

		if (isDirty) {
			openUnsavedChangesPromptModal({
				middleButtonLabel: t("editor.versionsModal.unsavedChangesPrompt.load.buttonLabel"),
				contentLine1: t("editor.versionsModal.unsavedChangesPrompt.load.contentLine1"),
				contentLine2: t("editor.versionsModal.unsavedChangesPrompt.load.contentLine2"),
				onCancel: closeModal,
				onConfirm: () => {
					openSelectedVersion();
					closeModal();
				},
				onSave: () => {
					validateSaveDrawing(async () => {
						setCopyStatus(Status.LOADING)
						const data = await drawingSaveData.saveDrawing(
							params.drawingId, params.versionId, drawingType
						)
						if (data) {
							setCopyStatus(Status.SUCCESS)
							openSelectedVersion();
							closeModal()
						}
						else {
							setCopyStatus(Status.ERROR)
						}
					})
				},
			})
		}
		else {
			openSelectedVersion()
		}
	}

	return (
		<div className="project-drawing-title">
			{loadStatus === Status.LOADING || usersLoadStatus === Status.LOADING ? "..." : null}
			{loadStatus === Status.SUCCESS && usersLoadStatus === Status.SUCCESS ? (<>
				{modal?.type === "versions" ? (
					<VersionsModal
						onClose={closeModal}
						currentVersionId={params.versionId}
						versions={drawingDetails!.versions}
						users={users}
						onLoad={handleLoadSelectedVersion}
						openCopyDrawingVersionModal={openCopyDrawingVersionModal}
						openRevertDrawingVersionModal={openRevertDrawingVersionModal}
					/>
				) : null}
				{modal?.type === "copy-drawing-version" ? (
					<CopyDrawingVersionModal
						copyStatus={copyStatus}
						drawingName={drawingDetails!.name}
						drawingVersion={modal.data.drawingVersion}
						onClose={closeModal}
						onCopy={onCopy}
					/>
				) : null}
				{modal?.type === "revert-drawing-version" ? (
					<RevertDrawingVersionModal
						drawingType={drawingType}
						drawingVersion={modal.data.drawingVersion}
						onClose={closeModal}
						onSuccess={() => {
							forceTreeUpdate();
							forceDrawingUpdate();
							closeModal();
						}}
					/>
				) : null}
				{modal?.type === "unsaved-changes-prompt" ? (
					<UnsavedChangesPromptModal
						disableButtons={copyStatus === Status.LOADING}
						onClose={modal.data.onCancel}
						onConfirm={modal.data.onConfirm}
						onSave={modal.data.onSave}
						middleButtonLabel={modal.data.middleButtonLabel}
						contentLine1={modal.data.contentLine1}
						contentLine2={modal.data.contentLine2}
					/>
				) : null}
				{}
				<span className="project-drawing-title_name">{getDrawingName()}</span>
				{" - "}
				<span className="project-drawing-title_version">{getDrawingVersion()}</span>
				{(drawingDetails?.versions && drawingDetails.versions.length > 0) ? (
					<Button
						variant={"secondary"}
						onClick={openVersionListModal}
						label={t("common.versions")}
					/>
				) : null}
			</>) : null}
		</div>
	)
}
