import React from "react";
import "./planUploadConfirmModals.scss";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";

const PlanUploadBaseConfirmModal = ({
	title,
	line1,
	line2,
	onClose,
	buttons,
}: {
	title: string,
	line1: string,
	line2: string,
	onClose: () => void,
	buttons: React.ReactNode
}) => {
	return (
		<ModalWithHeader
			isOpen={true}
			customClass={"plan-upload-confirm-modal"}
			title={title}
			onClose={onClose}
		>
			<div className={"plan-upload-confirm-modal_content"}>
				<div className={"plan-upload-confirm-modal_content_line1"}>{line1}</div>
				<div className={"plan-upload-confirm-modal_content_line2"}>{line2}</div>
				<ModalButtons>{buttons}</ModalButtons>
			</div>
		</ModalWithHeader>
	)
}

const PlanUploadCancelModal = ({onClose, onLeave}: {
	onClose: () => void,
	onLeave: () => void,
}) => {
	const {t} = useTranslation();

	return (
		<PlanUploadBaseConfirmModal
			title={t("planUploadEditor.planUploadConfirmModals.cancel.title")}
			line1={t("planUploadEditor.planUploadConfirmModals.cancel.contentLine1")}
			line2={t("planUploadEditor.planUploadConfirmModals.cancel.contentLine2")}
			onClose={onClose}
			buttons={(<>
				<Button
					label={t("planUploadEditor.planUploadConfirmModals.cancel.leaveButtonLabel")}
					variant={"optional"}
					onClick={onLeave}
				/>
				<Button
					label={t("planUploadEditor.planUploadConfirmModals.cancel.continueButtonLabel")}
					variant={"secondary"}
					onClick={onClose}
				/>
			</>)}
		/>
	)
};

const ConfirmReselectModal = ({onClose, onRestartWizard}: {
	onClose: () => void,
	onRestartWizard: () => void,
}) => {
	const {t} = useTranslation();

	return (
		<PlanUploadBaseConfirmModal
			title={t("planUploadEditor.planUploadConfirmModals.reselect.title")}
			line1={t("planUploadEditor.planUploadConfirmModals.reselect.contentLine1")}
			line2={t("planUploadEditor.planUploadConfirmModals.reselect.contentLine2")}
			onClose={onClose}
			buttons={(<>
				<Button
					label={t("planUploadEditor.planUploadConfirmModals.reselect.restartWizardLabel")}
					variant={"optional"}
					onClick={onRestartWizard}
				/>
				<Button
					label={t("planUploadEditor.planUploadConfirmModals.reselect.continueButtonLabel")}
					variant={"secondary"}
					onClick={onClose}
				/>
			</>)}
		/>
	)
}

export {PlanUploadCancelModal, ConfirmReselectModal}
