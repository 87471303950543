import React from "react";

const IconGear: React.FC = () => {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path
				d="M12.249,15.156c-0.576,-0 -1.103,-0.144 -1.583,-0.432c-0.479,-0.287 -0.863,-0.671 -1.151,-1.151c-0.287,-0.479 -0.431,-1.004 -0.431,-1.573c-0,-0.569 0.144,-1.094 0.431,-1.573c0.288,-0.48 0.672,-0.864 1.151,-1.151c0.48,-0.288 1.004,-0.432 1.574,-0.432c0.569,0 1.094,0.144 1.573,0.432c0.48,0.287 0.863,0.671 1.151,1.151c0.288,0.479 0.432,1.004 0.432,1.573c-0,0.569 -0.144,1.094 -0.432,1.573c-0.288,0.48 -0.671,0.864 -1.151,1.151c-0.479,0.288 -1.007,0.432 -1.582,0.432l0.018,-0Zm8.901,-1.996c0.06,-0.432 0.09,-0.818 0.09,-1.16c-0,-0.342 -0.03,-0.728 -0.09,-1.16l-2.374,-0.593c-0.156,-0.576 -0.377,-1.121 -0.665,-1.637l1.259,-2.086c-0.48,-0.623 -1.031,-1.174 -1.655,-1.654l-2.086,1.259c-0.515,-0.288 -1.061,-0.51 -1.636,-0.665l-0.593,-2.374c-0.42,-0.06 -0.807,-0.09 -1.16,-0.09c-0.354,0 -0.74,0.03 -1.16,0.09l-0.593,2.374c-0.576,0.155 -1.121,0.377 -1.637,0.665l-2.086,-1.259c-0.623,0.48 -1.175,1.025 -1.654,1.636l1.259,2.104c-0.288,0.516 -0.51,1.061 -0.666,1.637l-2.373,0.593c-0.06,0.42 -0.09,0.806 -0.09,1.16c-0,0.354 0.03,0.74 0.09,1.16l2.373,0.593c0.156,0.576 0.378,1.121 0.666,1.637l-1.259,2.086c0.479,0.623 1.031,1.174 1.654,1.654l2.086,-1.259c0.516,0.288 1.061,0.51 1.637,0.665l0.593,2.374c0.42,0.06 0.803,0.09 1.151,0.09c0.347,0 0.737,-0.03 1.169,-0.09l0.593,-2.374c0.587,-0.155 1.133,-0.377 1.636,-0.665l2.086,1.259c0.624,-0.48 1.175,-1.031 1.655,-1.654l-1.259,-2.086c0.288,-0.516 0.509,-1.061 0.665,-1.637l2.374,-0.593Z"
				fill="#1976D2"/>
		</svg>
	);
};

const IconSettings = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="17.001" height="17" viewBox="0 0 17.001 17">
			<g transform="translate(-0.734 -0.736)">
				<g transform="translate(0.962 0.766)">
					<path
						d="M2.782,1.587A9.136,9.136,0,0,0,5.171,2.974c.4.172.461.125.278.477a2.989,2.989,0,0,1-.521.541c-.416.434-.82.874-1.2,1.342h.535A12.732,12.732,0,0,1,2.581,3.675c-.5-.6-.4-.906.074-1.449a7.03,7.03,0,0,1,.777-.77C3.8,1.141,3.267.608,2.9.92c-.618.523-1.689,1.423-1.452,2.341C1.707,4.278,2.966,5.232,3.73,5.869a.383.383,0,0,0,.535,0c.5-.624,1.061-1.2,1.616-1.775.264-.276.732-.619.621-1.065-.092-.371-.546-.531-.858-.671A9.221,9.221,0,0,1,3.317,1.052c-.37-.312-.909.221-.535.535Z"
						transform="translate(-1.411 -0.825)" fill="#1976d2"/>
				</g>
				<g transform="translate(0.734 0.736)">
					<path
						d="M14.012,1.379c-1.19,1.164-3.8,3.257-1.7,4.955C14.25,7.906,16.125,5.2,17.284,4l-.633-.167c.565,2.061.562,4.548-2.266,4.684-.93.045-2.154-.32-3.044-.017-.691.235-1.141,1-1.61,1.532L7.1,13.017a30.322,30.322,0,0,1-2.462,2.66,6.61,6.61,0,0,1-1.247.931q-2.108-.855-1.07-1.636A9,9,0,0,1,3.99,13.345l6.2-6.043a.379.379,0,0,0,.1-.368A4.394,4.394,0,0,1,10.805,2.8c1.177-1.308,2.537-1.413,4.159-1.254L14.7.9l-.8.552a.379.379,0,0,0,.382.654,3.981,3.981,0,0,0,.958-.67c.277-.233.025-.617-.268-.646C12.857.585,11.2.894,9.9,2.7a3.568,3.568,0,0,0-.619,2.366c0,.365.065.741.057,1.1A1.861,1.861,0,0,1,8.7,7.7C6.724,10.037,4.2,12.047,2.025,14.2c-.87.863-2.09,2.206-.568,3.169,1.479.936,3.156-.6,4.149-1.576,1.934-1.9,3.427-4.373,5.469-6.147,1.415-1.228,3.915.3,5.52-1.036,1.5-1.245,1.24-3.318.785-4.981a.382.382,0,0,0-.633-.167c-.443.458-1.451,1.966-2.084,2.143l-1.87-1.971.314-.317a9.044,9.044,0,0,1,1.439-1.407c.349-.341-.186-.876-.535-.535Z"
						transform="translate(-0.734 -0.736)" fill="#1976d2"/>
				</g>
				<g transform="translate(4.366 4.258)">
					<path
						d="M11.707,11.917a6.67,6.67,0,0,1,1.563,1.708,13.523,13.523,0,0,0,1.75,1.813c.367.318.9-.216.535-.535a13.685,13.685,0,0,1-1.976-2.111,5.671,5.671,0,0,0-1.489-1.529c-.415-.258-.8.4-.382.654Z"
						transform="translate(-11.528 -11.205)" fill="#1976d2"/>
				</g>
				<g transform="translate(9.547 9.576)">
					<path
						d="M29.593,27.7a22.1,22.1,0,0,1,3.055,3.026c.309.413.644.747.982,1.132.655.746.862,1.066.24,1.868q-.275.924-1.083.577a1.238,1.238,0,0,1-1.125-.462,21.9,21.9,0,0,1-2.836-2.814,6.3,6.3,0,0,1-.639-.768c-.423-.694-.2-.693.253-1.13.527-.514,1.074-1.006,1.632-1.487.37-.319-.168-.852-.535-.535-.87.75-1.7,1.533-2.495,2.359a.383.383,0,0,0-.059.459,20.975,20.975,0,0,0,4.442,4.694,2.386,2.386,0,0,0,2.977.05,2.081,2.081,0,0,0,.211-2.91,39.736,39.736,0,0,0-4.483-4.593c-.362-.324-.9.21-.535.535Z"
						transform="translate(-26.929 -27.011)" fill="#1976d2"/>
				</g>
				<g transform="translate(9.979 9.874)">
					<path d="M28.33,28.536l.64.594c.357.331.893-.2.535-.535L28.866,28c-.357-.331-.893.2-.535.535Z"
						  transform="translate(-28.212 -27.897)" fill="#1976d2"/>
				</g>
				<g transform="translate(12.939 13.107)">
					<path
						d="M39.356,37.671c-.353.476-.743.894-1.114,1.345s-.7.934-1.122,1.359c-.342.347.192.883.535.535.442-.448.776-.963,1.187-1.431s.794-.924,1.167-1.427c.29-.392-.367-.77-.654-.382Z"
						transform="translate(-37.01 -37.508)" fill="#1976d2"/>
				</g>
			</g>
		</svg>
	)
}

export {IconSettings, IconGear}
