import React, {FC, useState} from "react";
import {useTranslation} from "react-i18next";
import {FileType, Status} from "../../../../models/enums";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import LoggerService from "../../../../services/LoggerService";
import Modal from "../../../../components/modal/Modal";
import ModalTitle from "../../../../components/modal/ModalTitle";
import FileUploader from "../../../../components/ui/FileUploader";
import {getAllowedFormats} from "../../../project-drawings/features/editor/utils";
import ModalButtons from "../../../../components/modal/ModalButtons";
import Button from "../../../../components/ui/Button";
import PreviewImageModal from "../../../project-drawings/features/editor/features/image/PreviewImageModal";
import "./baseImageUploadModal.scss"

type BaseImageUploadModalProps = {
	isOpen: boolean
	onCloseModal: () => void
	onHandleSave: (selectedItem: { value: string, label: string }) => void,
	onImageUpload: (attachmentId: string, filename: string) => Promise<void>,
	connectedImageSelector?: JSX.Element,
	selectedItem: { value: string, label: string } | undefined
}

export const BaseImageUploadModal: FC<BaseImageUploadModalProps> = function({
	isOpen,
	onCloseModal,
	onHandleSave,
	onImageUpload,
	connectedImageSelector,
	selectedItem
}) {
	const {t} = useTranslation();
	const [uploadStatus, setUploadStatus] = useState(Status.IDLE)
	const [file, setFile] = useState<File | null>(null);
	const [isImagePreview, setIsImagePreview] = useState(false);

	function closeModal() {
		setFile(null)
		onCloseModal();
	}

	async function handleFileUpload(file: File) {
		setFile(file)
		setUploadStatus(Status.LOADING)
		try {
			const attachment = await apiInstance.attachmentApi.uploadFile(file)
			await onImageUpload(attachment.id, file.name);
			setUploadStatus(Status.SUCCESS)
			NotificationService.successNotification(
				t("common.success"),
				t("editor.uploadImageModal.uploadSuccessDesc")
			)
		}
		catch (err) {
			setUploadStatus(Status.ERROR)
			NotificationService.errorNotification(
				t("common.error"),
				t("editor.uploadImageModal.uploadErrorDesc")
			)
			LoggerService.logError(err)
		}
	}

	function handleSave() {
		if (selectedItem) {
			onHandleSave(selectedItem)
			closeModal()
		}
	}

	const supportedTypes = [
		FileType.JPG,
		FileType.JPEG,
		FileType.PNG,
		FileType.BMP,
		FileType.GIF
	]

	return (
		<>
			<Modal isOpen={isOpen} onClose={closeModal}>
				<ModalTitle>{t("editor.uploadImageModal.title")}</ModalTitle>
				{file ? (
					<div className={"base-image-upload-modal_files"}>{file.name}</div>
				) : null}
				<div className={"base-image-upload-modal_add-button"}>
					<FileUploader onFileUpload={handleFileUpload}
								  disabled={uploadStatus === Status.LOADING}
								  accept={supportedTypes}/>
				</div>
				<div className={"base-image-upload-modal_formats"}>
					{t("projects.details.uploadDrawingModal.allowedFormats")} {getAllowedFormats(supportedTypes)}.
				</div>
				{connectedImageSelector}
				<ModalButtons>
					{selectedItem ? (
						<Button variant={"secondary"} onClick={() => setIsImagePreview(true)}
								label={t("editor.uploadImageModal.previewButton")}/>
					) : null}
					<div style={{marginRight: "auto"}}/>
					<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={closeModal}/>
					<Button label={t("editor.uploadImageModal.addToEditor")}
							variant={"primary"} onClick={handleSave} disabled={!selectedItem}/>
				</ModalButtons>
			</Modal>
			{isImagePreview ? (
				<PreviewImageModal
					previewImageName={selectedItem!.label}
					previewImageId={selectedItem!.value}
					onClose={() => setIsImagePreview(false)}
				/>
			) : null}
		</>

	)
}