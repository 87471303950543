import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import FullPageMessage from "../../../../components/ui/FullPageMessage";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import "./projectPlanSummaryModal.scss";
import ModalButtons from "../../../../components/modal/ModalButtons";
import Button from "../../../../components/ui/Button";
import {Status} from "../../../../models/enums";
import Spinner from "../../../../components/Spinner";
import PlanSummaryItemsTable from "./PlanSummaryItemsTable";
import DataFetchError from "../../../../components/ui/DataFetchError";
import useDrawingSummary from "../../hooks/useDrawingSummary";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {INITIAL_VERSION_ID} from "../../features/editor/constants";
import {useProjectDetailContext} from "../../../project-details/providers/ProjectDetailProvider";

interface ProjectPlanSummaryModalProps {
	onClose: () => void;
}

const ProjectPlanSummaryModal: React.FC<ProjectPlanSummaryModalProps> = ({onClose}) => {
	const {versionId} = useParams<{ versionId: string }>();
	const {drawingSummaryItems, loadData, loadStatus: drawingSummaryLoadStatus} = useDrawingSummary(versionId);
	const {fitTimesData: {fitTimes, loadStatus: fitTimesLoadStatus}} = useProjectDetailContext()
	const {t} = useTranslation();

	const loadDataRef = useUpdatedRef(loadData);

	useEffect(() => {
		if (versionId !== INITIAL_VERSION_ID) {
			const loadData = loadDataRef.current;
			loadData();
		}
	}, [versionId, loadDataRef]);

	const forceUpdate = () => loadData();

	return (
		<ModalWithHeader isOpen={true} title={t("projects.summary.modalTitle")} onClose={onClose}
						 customClass="project-plan-summary-modal">
			<div className="project-plan-summary-modal_content">
				{versionId === INITIAL_VERSION_ID ?
					<FullPageMessage message={t("projects.summary.savedVersionMessage")}/> :
					<>
						{drawingSummaryLoadStatus === Status.LOADING || fitTimesLoadStatus === Status.LOADING ?
							<Spinner/> : null}
						{drawingSummaryLoadStatus === Status.SUCCESS && fitTimesLoadStatus === Status.SUCCESS ?
							<>
								<PlanSummaryItemsTable items={drawingSummaryItems} fitTimes={fitTimes} onUpdate={forceUpdate}/>
								<ModalButtons>
									<Button variant={"secondary"} label={t("common.buttons.ok")} onClick={onClose}/>
								</ModalButtons>
							</> : null}
						{drawingSummaryLoadStatus === Status.ERROR || fitTimesLoadStatus === Status.ERROR ?
							<DataFetchError/> : null}
					</>
				}
			</div>
		</ModalWithHeader>
	);
};

export default ProjectPlanSummaryModal;
