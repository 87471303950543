import React from "react";
import CommonDropdown from "./CommonDropdown";
import "./commonDropdown.scss";
import {useTranslation} from "react-i18next";

type DropdownProps = {
	disabled?: boolean;
}
const OptionsDropdown: React.FC<DropdownProps> = ({children, disabled}) => {
	const {t} = useTranslation();
	const button =
		<button className="dropdown_button_options">{t("common.buttons.options")}
			<span className="dropdown_button_options_arrow-container">
					<span className="dropdown_button_options_arrow-container_icon"/>
				</span>
		</button>

	return (
		<CommonDropdown button={button} disabled={disabled}>
			{children}
		</CommonDropdown>
	);
};

export default OptionsDropdown;
