import {SymbolType} from "../features/project-drawings/features/editor/icons/symbol_pack/default";

export class SymbolSystemPack {
	constructor(public readonly id: string,
		public readonly name: string,
		public readonly industry: string,
		public readonly group: string,
		public readonly symbols: SymbolType[]) {}

	public static sortByName(a: SymbolSystemPack, b: SymbolSystemPack) {
		return a.name.localeCompare(b.name);
	}

	public static sortByIndustry(a: SymbolSystemPack, b: SymbolSystemPack) {
		return a.industry.localeCompare(b.industry);
	}

	public static sortByGroup(a: SymbolSystemPack, b: SymbolSystemPack) {
		return a.group.localeCompare(b.group);
	}
}
