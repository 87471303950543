import React, {useEffect, useMemo, useState} from "react";
import {Vector2d} from "konva/types/types";
import {
	BOTTOM_POINT,
	KonvaEventType,
	LEFT_POINT,
	PointDragState,
	RIGHT_POINT,
	SelectionState,
	TOP_POINT,
	Vertices
} from "../../../../base-konva/types";
import {convertSelectionToPoints, convertSelectionToVertices, convertVerticesToPoints} from "../utils";
import {createTopLeftOrientedVertices, getDraggingVertices} from "../../../../base-konva/utils/frameUtils";
import {LinePoint, LinePointProps} from "../../../../project-drawings/features/editor/components/LinePoint";
import {getPointerPosition} from "../../../../base-konva/utils";
import {noop} from "../../../../../utils/CallbackUtils";
import {midpoint} from "../../../../project-drawings/features/editor/utils";
import {KonvaEventObject} from "konva/types/Node";
import {Line} from "react-konva";
import {Colors} from "../../../../../styles";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";

function useBaseSelection(
	selectionState: SelectionState | undefined,
	setSelection: (vertices: Vertices) => void,
	stroke: Colors,
) {
	const [firstPoint, setFirstPoint] = useState<Vector2d | null>(null);
	const [secondPoint, setSecondPoint] = useState<Vector2d | null>(null);
	const [mousePosition, setMousePosition] = useState<Vector2d | null>(null);
	const [pointDragState, setPointDragState] = useState<PointDragState>({isDragging: false});

	const setSelectionRef = useUpdatedRef(setSelection)

	useEffect(function resetPointsWhenNoSelection() {
		if (!selectionState) {
			setFirstPoint(null)
			setSecondPoint(null)
			setMousePosition(null)
		}
	}, [selectionState])

	const points = useMemo(() => {
		if (selectionState) {
			if (!pointDragState.isDragging) {
				return convertSelectionToPoints(selectionState)
			}
			else {
				const selectionVertices = convertSelectionToVertices(selectionState);
				return convertVerticesToPoints(getDraggingVertices(selectionVertices, pointDragState))
			}
		}

		if (!firstPoint) return []

		if (!secondPoint) {
			if (!mousePosition) return []
			return [
				firstPoint.x, firstPoint.y,
				firstPoint.x, mousePosition.y,
				mousePosition.x, mousePosition.y,
				mousePosition.x, firstPoint.y,
			]
		}
		return []
	}, [selectionState, firstPoint, secondPoint, pointDragState, mousePosition])

	const linePoints = useMemo(() => {
		if (!selectionState) return []

		const result: JSX.Element[] = []
		const selectionVertices = convertSelectionToVertices(selectionState);
		const {
			topLeft, topRight, bottomRight, bottomLeft
		} = pointDragState.isDragging ? getDraggingVertices(selectionVertices, pointDragState) : selectionVertices

		const getProps = function(pointId: string, position: Vector2d): LinePointProps & { key: string } {
			return {
				key: pointId,
				point: {id: pointId, position},
				onDragStart: (evt) => {
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = mousePosition;
						setPointDragState({
							isDragging: true,
							dragPointId: pointId,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						})
					}
				},
				onDragMove: (evt) => {
					if (pointId === TOP_POINT || pointId === BOTTOM_POINT) {
						evt.target.x(position.x)
					}
					else {
						evt.target.y(position.y)
					}
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						const pointer = {
							x: mousePosition.x,
							y: mousePosition.y
						}
						setPointDragState(prevState => ({
							...prevState,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						}))
					}
				},
				onDragEnd: (evt) => {
					if (pointId === TOP_POINT || pointId === BOTTOM_POINT) {
						evt.target.x(position.x)
					}
					else {
						evt.target.y(position.y)
					}

					setPointDragState({isDragging: false});
					const {topLeft, bottomRight} = getDraggingVertices(selectionVertices, pointDragState)
					setSelectionRef.current(createTopLeftOrientedVertices(topLeft, bottomRight))
				},
				onMouseEnter: noop,
				onMouseLeave: noop,
			}
		}

		const getPosition = function(pointA: Vector2d, pointB: Vector2d) {
			return midpoint(pointA, pointB)
		}

		result.push(<LinePoint {...getProps(LEFT_POINT, getPosition(topLeft, bottomLeft))} />)
		result.push(<LinePoint {...getProps(TOP_POINT, getPosition(topLeft, topRight))} />)
		result.push(<LinePoint {...getProps(RIGHT_POINT, getPosition(topRight, bottomRight))} />)
		result.push(<LinePoint {...getProps(BOTTOM_POINT, getPosition(bottomLeft, bottomRight))} />)

		return result
	}, [pointDragState, selectionState, setSelectionRef])

	function onMouseUp(event: KonvaEventObject<KonvaEventType>) {
		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			const relativePosition = {
				x: mousePosition.x,
				y: mousePosition.y
			}

			if (!firstPoint) {
				setFirstPoint(relativePosition)
			}
			else if (!secondPoint) {
				setSecondPoint(relativePosition)
				setSelection(createTopLeftOrientedVertices(firstPoint, relativePosition))
			}
		}
	}

	function onMouseMove(event: KonvaEventObject<KonvaEventType>) {
		if (secondPoint) return;

		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			setMousePosition(mousePosition)
		}
	}

	function render() {
		return (<>
				<Line
					listening={false}
					closed={true}
					dash={[4, 7]}
					points={points}
					stroke={stroke}
					strokeWidth={2}
					lineCap={"round"}
					lineJoin={"round"}
				/>
				{linePoints}
			</>
		)
	}

	return {
		render,
		onMouseUp,
		onMouseMove
	}
}

export {useBaseSelection}