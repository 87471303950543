import {combineReducers, configureStore, Store} from '@reduxjs/toolkit';
import logger from 'redux-logger';
import {configActions, configReducer} from "./features/config/configSlice";
import {lengthActions, lengthReducer} from "./features/length/lengthSlice";
import {countActions, countReducer} from "./features/count/countSlice";
import {penActions, penReducer} from "./features/pen/penSlice";
import {areaActions, areaReducer} from "./features/area/areaSlice";
import {textActions, textReducer} from "./features/text/textSlice";
import undoable, {ActionCreators, excludeAction} from 'redux-undo';
import {viewActions, viewReducer} from "./features/view/viewSlice";
import {imageActions, imageReducer} from "./features/image/imageSlice";
import {autoFinishToolWhenSwitchEditorTool} from "./middlewares/autoFinishToolWhenSwitchEditorTool";
import {editorIsDirtyMarker} from "./middlewares/editorIsDirtyMarker";
import {activeItemsCleaner} from "./middlewares/activeItemsCleaner";
import {PageScaleRatio} from "../../../../models/DrawingDetails";
import {StageConfig} from "./models/editor";
import {Dispatch} from "redux";
import {symbolRecognitionActions, symbolRecognitionReducer} from "./features/symbol-recognition/symbolRecognitionSlice";
import {scaleActions, scaleReducer} from "./features/scale/scaleSlice";

const undoReducer = undoable(combineReducers({
	config: configReducer,
	length: lengthReducer,
	count: countReducer,
	pen: penReducer,
	area: areaReducer,
	text: textReducer,
	image: imageReducer,
}), {
	groupBy: (action: any) => action.payload?.actionId || null,
	filter: (action, currentState, previousHistory) => {
		if (action.payload?.isUndoRedoExcluded) {
			return false;
		}
		if (typeof action.type === "string" &&
			(action.type.startsWith("view") || action.type.startsWith("scale"))) {
			return false;
		}
		else {
			return excludeAction([
				textActions.addTextElement.type,
				configActions.setInitialStageConfig.type,
			])(action, currentState, previousHistory)
		}
	},

})

export const editorStore = getStoreInstance();

export function getStoreInstance() {
	return configureStore({
		reducer: {
			view: viewReducer,
			scale: scaleReducer,
			symbolRecognition: symbolRecognitionReducer,
			undoGroup: undoReducer,
		},
		middleware: (getDefaultMiddleware => {
			const custom = [
				autoFinishToolWhenSwitchEditorTool,
				editorIsDirtyMarker,
				activeItemsCleaner
			]

			if (process.env.NODE_ENV === "production") {
				return getDefaultMiddleware().concat(...custom)
			}
			return getDefaultMiddleware().concat(...custom
				, logger
			)
		})
	})
}

type ResetStoreParams = {
	store: Store<RootState>,
	stageConfig?: StageConfig,
	planScale?: number,
	pageScaleRatio?: PageScaleRatio,
	unitFactor?: number,
	postResetInit?: (initDispatch: Dispatch) => void
}

export function resetStore(params: ResetStoreParams) {
	const {store, stageConfig, planScale, pageScaleRatio, unitFactor, postResetInit} = params
	const dispatch = store.dispatch;
	dispatch(configActions.reset({
		stageConfig: stageConfig ?? null
	}))
	dispatch(scaleActions.reset({
		planScale: planScale ?? null,
		unitFactor: unitFactor ?? null
	}))
	dispatch(lengthActions.reset())
	dispatch(countActions.reset())
	dispatch(penActions.reset())
	dispatch(areaActions.reset())
	dispatch(textActions.reset())
	dispatch(imageActions.reset())
	dispatch(viewActions.reset(pageScaleRatio ? {...pageScaleRatio} : null))
	dispatch(symbolRecognitionActions.reset())
	postResetInit?.(dispatch);
	dispatch(ActionCreators.clearHistory())
}

export const selectHasUndo = (state: RootState) => state.undoGroup.past.length !== 0;
export const selectHasRedo = (state: RootState) => state.undoGroup.future.length !== 0;

export type RootState = ReturnType<typeof editorStore.getState>;
//export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
