import "./symbolRecognitionPreview.scss"
import React, {useEffect, useRef, useState} from "react";
import {Image, Layer, Stage} from "react-konva";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";

type SymbolRecognitionPreviewProps = {
	templateData: { x: number, y: number, width: number, height: number }
}

const SymbolRecognitionPreview: React.FC<SymbolRecognitionPreviewProps> = ({
	templateData
}) => {
	const {drawingData: {image}} = useDrawingDetailsDataContext()
	const previewContainerRef = useRef<HTMLDivElement>(null)
	const [scale, setScale] = useState<number>(1)

	useEffect(() => {
		if (previewContainerRef.current) {
			const scaleY = previewContainerRef.current.clientHeight / templateData.height
			const scaleX = previewContainerRef.current.clientWidth / templateData.width
			setScale(Math.min(scaleX, scaleY))
		}
	}, [templateData.height, templateData.width,
		previewContainerRef?.current?.clientHeight,
		previewContainerRef?.current?.clientWidth])

	return (
		<div ref={previewContainerRef} className={"symbol-recognition-preview"}>
			<Stage style={{border: "2px solid black"}} listening={false}
				   offsetX={templateData.x}
				   offsetY={templateData.y}
				   height={templateData.height * scale}
				   width={templateData.width * scale}
				   scale={{x: scale, y: scale}}>
				<Layer listening={false}>
					<Image image={image}/>
				</Layer>
			</Stage>
		</div>
	)
}
export default SymbolRecognitionPreview;
