import React, {useRef, useState} from "react";
import Konva from "konva";
import {Vector2d} from "konva/types/types";
import {distance, getStageBorderPosition} from "../../../project-drawings/features/editor/utils";
import {KonvaEventType} from "../../types";

type UseBasePanProps = {
	stageRef: React.RefObject<Konva.Stage>;
	canScroll: () => boolean;
	viewportSize: { width: number, height: number }
}

export function useBasePan({stageRef, canScroll, viewportSize}: UseBasePanProps) {
	const [panInProgress, setPanInProgress] = useState(false);
	const panStartVector = useRef<Vector2d>({x: 0, y: 0});

	const shouldScroll = (event: Konva.KonvaEventObject<MouseEvent | TouchEvent>) => {
		const stage = stageRef.current;
		return canScroll() && (event.target === stage || event.target.name().startsWith("background"));
	};

	const handleMove = (
		clientX: number,
		clientY: number,
		previousClientX: number,
		previousClientY: number,
		event: KonvaEventType,
	) => {
		const movementDistance = distance({x: clientX, y: clientY}, panStartVector.current)
		if (movementDistance > 1) {
			setPanInProgress(true)
		}
		event.preventDefault();

		const deltaX = previousClientX - clientX;
		const deltaY = previousClientY - clientY;

		const stage = stageRef.current;
		if (stage) {
			let newX = stage.x() - deltaX
			let newY = stage.y() - deltaY

			stage.position(getStageBorderPosition(
				{x: newX, y: newY},
				stage.getClientRect(),
				viewportSize
			))
			stage.batchDraw()
		}
	};

	const onMouseDown = (event: Konva.KonvaEventObject<MouseEvent>) => {
		const mouseDownEvent = event.evt;

		if (shouldScroll(event)) {
			mouseDownEvent.preventDefault();

			let previousClientX = mouseDownEvent.clientX
			let previousClientY = mouseDownEvent.clientY
			panStartVector.current = {
				x: previousClientX,
				y: previousClientY
			}

			const onMouseMove = (mouseMoveEvent: MouseEvent) => {
				handleMove(
					mouseMoveEvent.clientX,
					mouseMoveEvent.clientY,
					previousClientX,
					previousClientY,
					mouseMoveEvent
				);
				previousClientX = mouseMoveEvent.clientX
				previousClientY = mouseMoveEvent.clientY
			}

			const onMouseUp = () => {
				setPanInProgress(false);
				window.removeEventListener("mouseup", onMouseUp)
				window.removeEventListener("mousemove", onMouseMove)
			}

			window.addEventListener("mouseup", onMouseUp)
			window.addEventListener("mousemove", onMouseMove)
		}
	}

	const onTouchStart = (event: Konva.KonvaEventObject<TouchEvent>) => {
		const touchStartEvent = event.evt;

		if (shouldScroll(event)) {
			touchStartEvent.preventDefault();

			const startTouch = touchStartEvent.touches.item(0);
			if (startTouch) {
				let previousClientX = startTouch.clientX;
				let previousClientY = startTouch.clientY;
				panStartVector.current = {
					x: previousClientX,
					y: previousClientY
				}

				const onTouchMove = (touchMoveEvent: TouchEvent) => {
					const moveTouch = touchMoveEvent.touches[0];
					if (moveTouch) {
						handleMove(
							moveTouch.clientX,
							moveTouch.clientY,
							previousClientX,
							previousClientY,
							touchMoveEvent
						);
						previousClientX = moveTouch.clientX;
						previousClientY = moveTouch.clientY;
					}
				};
				const onTouchEnd = () => {
					setPanInProgress(false);
					window.removeEventListener("touchend", onTouchEnd);
					window.removeEventListener("touchmove", onTouchMove);
				};

				window.addEventListener("touchend", onTouchEnd);
				window.addEventListener("touchmove", onTouchMove);
			}
		}
	};

	return {
		onMouseDown,
		onTouchStart,
		panInProgress,
	}
}