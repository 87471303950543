import DropdownItem from "../../../../../../components/ui/DropdownItem";
import React from "react";
import {useTranslation} from "react-i18next";
import {ExportDropdown} from "../../components/ExportDropdown";
import {ExportMode} from "../../models/enums";
import {useStore} from "react-redux";
import {useParams} from "react-router-dom";
import {useDrawingExporter} from "../../../../../project-details/features/drawing-exporter";
import {useProjectDetailContext} from "../../../../../project-details/providers/ProjectDetailProvider";
import {DrawingType} from "../../../../../../models/enums";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {INITIAL_VERSION_ID} from "../../constants";
import {RootState} from "../../editorStore";
import {saveAs} from "file-saver";
import LoggerService from "../../../../../../services/LoggerService";
import NotificationService from "../../../../../../services/NotificationService";
import {useDrawingSummaryPDF} from "../../../../../project-details/features/sync-materials/components/pdf/useDrawingSummaryPDF";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";

export const EditorHeaderExportPanel = ({disabled}: { disabled?: boolean }) => {

	const {t} = useTranslation();
	const {treeData: {sections}, fitTimesData} = useProjectDetailContext()
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext()
	const {settings} = useUserSettingsDataContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const store = useStore<RootState>()
	const {fitTimes} = fitTimesData

	const {
		sectionId,
		costCenterId,
		drawingId,
		versionId
	} = useParams<{ sectionId: string, costCenterId: string, drawingId: string, versionId: string }>();
	const {generateFilesFromSelection} = useDrawingExporter()
	const {createSelectedSummaryAttachment} = useDrawingSummaryPDF()

	function handleExport(exportMode: ExportMode) {
		return async () => {

			const activeSection = sections.find(section => section.id === sectionId)
			const activeCostCenter = activeSection?.costCenters.find(cc => cc.id === costCenterId)
			const activeDrawing = activeCostCenter?.drawings.find(drawing => drawing.id === drawingId)
			const activeVersion = activeDrawing?.versions.find(v => v.id === versionId)
			const selectedVersionId = activeVersion ? activeVersion.id : INITIAL_VERSION_ID
			const selectedVersionLabel = activeVersion ? activeVersion.version.getVersionLabel() : "Initial version"
			const downloadKeyTable = settings ? settings.downloadKeyTable && exportMode === ExportMode.PDF : false
			const filenameTranslations = {
				[DrawingType.TAKE_OFF]: t(`editor.defaultFileNameTemple.take_off`),
				[DrawingType.AS_BUILT]: t(`editor.defaultFileNameTemple.as_built`)
			}

			try {
				if (!activeDrawing) {
					const detailData = JSON.stringify({sectionId, costCenterId, drawingId, versionId})
					return handleError(new Error(`Cannot find drawing, ${detailData}`))
				}
				// To add a summary table, the export type will need to be changed to PNG
				// in order for the getDrawingFileData function to get the width and height.
				const exportModeType = downloadKeyTable ? ExportMode.PNG : exportMode
				const files = await generateFilesFromSelection(
					{fileType: exportModeType},
					[{
						selectedSectionId: sectionId,
						selectedCostCenterId: costCenterId,
						selectedDrawingId: drawingId,
						selectedDrawingName: activeDrawing.name,
						selectedVersionId,
						selectedVersionLabel,
						drawingType
					}],
					store.getState()
				)
				let [selectedVersionFile] = files
				if (!selectedVersionFile) {
					return handleError(new Error("File not generated"))
				} else if (downloadKeyTable && activeSection && activeCostCenter && activeDrawing) {
					selectedVersionFile = await createSelectedSummaryAttachment(
						selectedVersionFile,
						activeSection,
						activeCostCenter,
						activeDrawing,
						selectedVersionId,
						drawingType,
						fitTimes
					)
				}
				if (selectedVersionFile) {
					const filename = `${filenameTranslations[drawingType]}.${exportMode.toLocaleLowerCase()}`;
					saveAs(selectedVersionFile, filename)
				}
			}
			catch (e) {
				handleError(e)
			}
		}
	}

	function handleError(error: unknown) {
		LoggerService.logError(error);
		NotificationService.errorNotification(t("common.error"), t("common.buttons.export.exportErrorDesc"));
	}

	return (
		<ExportDropdown disabled={disabled}>
			<DropdownItem label={t("common.buttons.export.png")} onClick={handleExport(ExportMode.PNG)}/>
			<DropdownItem label={t("common.buttons.export.jpg")} onClick={handleExport(ExportMode.JPG)}/>
			<DropdownItem label={t("common.buttons.export.pdf")} onClick={handleExport(ExportMode.PDF)}/>
		</ExportDropdown>
	)
};