import React, {useState} from "react";
import {PaginatedResponse, SortConfig} from "../../../models/interfaces";
import {ProjectSource, SortDirection, Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {ProjectSummary} from "../components/ProjectsTable";
import {useQuery} from "react-query";
import {toStatus} from "../../../utils/ReactQueryUtils";
import {COMPANY_JOBS_WITH_STAGE_QUERY_KEY, COMPANY_QUOTES_WITH_STAGE_QUERY_KEY} from "../../../api/CompaniesApi";
import {pages, useNavigator} from "../../navigator";

interface UseProjectsListHook {
	items: ProjectSummary[],
	onPageChange: (page: number) => void,
	loadStatus: Status,
	pageNumber: number,
	totalPages: number,
	totalElements: number,
	redirectToProject: (simproId: number, projectSource: ProjectSource) => void,
}

type QueryKey = [string, QueryKeyParams]
type QueryKeyParams = {
	pageNumber: number,
	pageSize: number,
	orderBy: string,
	query?: string
}

type UseProjectListQueryKeyId = typeof COMPANY_QUOTES_WITH_STAGE_QUERY_KEY | typeof COMPANY_JOBS_WITH_STAGE_QUERY_KEY
export type UseProjectListQueryKeyArgs = { id: UseProjectListQueryKeyId, stage: string }

function useProjectsList(
	queryKeyArgs: UseProjectListQueryKeyArgs,
	asyncDataLoad: (page: number, size: number, orderBy: string, query?: string) => Promise<PaginatedResponse<ProjectSummary>>,
	sortConfig: SortConfig<ProjectSummary>,
	query: string | undefined,
	projectSource: ProjectSource
): UseProjectsListHook {
	const pageSize = 10;
	const {navigateTo} = useNavigator()
	const {t} = useTranslation();
	const [pageNumber, setPageNumber] = useState(0);

	const queryKey: QueryKey = React.useMemo(() => {
		const orderBy = `${sortConfig.direction === SortDirection.DESC ? "-" : ""}${sortConfig.key}`;
		return [queryKeyArgs.id, {pageNumber, pageSize, orderBy, query, stage: queryKeyArgs.stage}]
	}, [queryKeyArgs, pageNumber, pageSize, query, sortConfig])

	const {data, status, isPreviousData} = useQuery({
		queryKey,
		queryFn: ({queryKey}) => {
			const [, {pageNumber, pageSize, orderBy, query}] = queryKey
			/* Direct method call makes "this" reference inside api method undefined, so axios instance call causes crash.
	  		Passing - in this case - "apiInstance.companiesApi" as "this" using .call fixes this problem. */
			return asyncDataLoad.call(apiInstance.companiesApi, pageNumber, pageSize, orderBy, query)
		},
		onError: (err) => {
			const errorMessageTranslations = {
				[ProjectSource.JOB]: t(`projects.job.fetchErrorDesc`),
				[ProjectSource.QUOTE]: t(`projects.quote.fetchErrorDesc`)
			}
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"), errorMessageTranslations[projectSource]);
		},
		keepPreviousData: true
	})

	const onPageChange = React.useCallback((page: number) => {
		setPageNumber(page)
	}, [])

	const items = React.useMemo(() => data?.records ?? [], [data])
	const totalPages = React.useMemo(() => data?.totalPages || 0, [data])
	const totalElements = React.useMemo(() => data?.totalElements || 0, [data])
	const loadStatus = React.useMemo(() => {
		if (isPreviousData) {
			return Status.LOADING
		}
		return toStatus(status)
	}, [isPreviousData, status])

	const redirectToProject = (simproId: number, projectSource: ProjectSource) => {
		navigateTo(pages.projectDetailsSimproIdLoader(
			projectSource === ProjectSource.JOB ? "jobs" : "quotes",
			simproId
		))
	}

	return {
		items,
		loadStatus,
		onPageChange,
		pageNumber,
		totalPages,
		totalElements,
		redirectToProject
	};
}

export default useProjectsList;
