import {JobsOrQuotes} from "./types";

const paths = {
	root: "/",
	error: "/error",
	any: "*",

	settings: "/settings",
	settings_main: "/settings/main",
	settings_symbols: "/settings/symbols",
	settings_symbols_user: "/settings/symbols/user",
	settings_symbols_user_groups: "/settings/symbols/user/groups",
	settings_symbols_user_groups_default: "/settings/symbols/user/groups/default",
	settings_symbols_user_groups_groupId: "/settings/symbols/user/groups/:groupId",
	settings_symbols_user_groups_groupId_packs_packId: "/settings/symbols/user/groups/:groupId/packs/:packId",
	settings_symbols_system: "/settings/symbols/system",
	settings_symbols_system_packId: "/settings/symbols/system/:packId",

	takeoffs: "/takeoffs",
	takeoffs_jobs_loader_simproId: "/takeoffs/jobs/loader/:simproId",
	takeoffs_quotes_loader_simproId: "/takeoffs/quotes/loader/:simproId",
	takeoffs_jobs_details_projectId: "/takeoffs/jobs/details/:projectId",
	takeoffs_quotes_details_projectId: "/takeoffs/quotes/details/:projectId",
	takeoffs_job_simproId: "/takeoffs/job/:simproId",
	takeoffs_quote_simproId: "/takeoffs/quote/:simproId",

	takeoffs_jobs: "/takeoffs/jobs",
	takeoffs_jobs_all: "/takeoffs/jobs/all",
	takeoffs_jobs_pending: "/takeoffs/jobs/pending",
	takeoffs_jobs_progress: "/takeoffs/jobs/progress",
	takeoffs_jobs_complete: "/takeoffs/jobs/complete",
	takeoffs_jobs_invoiced: "/takeoffs/jobs/invoiced",
	takeoffs_jobs_archived: "/takeoffs/jobs/archived",

	takeoffs_quotes: "/takeoffs/quotes",
	takeoffs_quotes_all: "/takeoffs/quotes/all",
	takeoffs_quotes_open: "/takeoffs/quotes/open",
	takeoffs_quotes_progress: "/takeoffs/quotes/progress",
	takeoffs_quotes_complete: "/takeoffs/quotes/complete",
	takeoffs_quotes_approved: "/takeoffs/quotes/approved",
	takeoffs_quotes_closed: "/takeoffs/quotes/closed",

	takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version_versionId: "/takeoffs/quotes/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId/version/:versionId",
	takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version_versionId: "/takeoffs/jobs/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId/version/:versionId",

	takeoffs_quotes_details_projectId_planUpload: "/takeoffs/quotes/details/:projectId/plan-upload",
	takeoffs_jobs_details_projectId_planUpload: "/takeoffs/jobs/details/:projectId/plan-upload",


	takeoffs_jobs_details: "/takeoffs/jobs/details",
	takeoffs_quotes_details: "/takeoffs/quotes/details",
	takeoffs_jobs_details_projectId_section: "/takeoffs/jobs/details/:projectId/section",
	takeoffs_quotes_details_projectId_section: "/takeoffs/quotes/details/:projectId/section",
	takeoffs_jobs_details_projectId_section_sectionId: "/takeoffs/jobs/details/:projectId/section/:sectionId",
	takeoffs_quotes_details_projectId_section_sectionId: "/takeoffs/quotes/details/:projectId/section/:sectionId",
	takeoffs_jobs_details_projectId_section_sectionId_costCenter: "/takeoffs/jobs/details/:projectId/section/:sectionId/costCenter",
	takeoffs_quotes_details_projectId_section_sectionId_costCenter: "/takeoffs/quotes/details/:projectId/section/:sectionId/costCenter",
	takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId: "/takeoffs/jobs/details/:projectId/section/:sectionId/costCenter/:costCenterId",
	takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId: "/takeoffs/quotes/details/:projectId/section/:sectionId/costCenter/:costCenterId",
	takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs: "/takeoffs/jobs/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs",
	takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs: "/takeoffs/quotes/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs",
	takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId: "/takeoffs/jobs/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId",
	takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId: "/takeoffs/quotes/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId",
	takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version: "/takeoffs/jobs/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId/version",
	takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version: "/takeoffs/quotes/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId/version",
	settings_symbols_user_groups_groupId_packs: "/settings/symbols/user/groups/:groupId/packs",


} as const

const pages = {
	simproIdJobPage: (simproId: string) => `/takeoffs/job/${simproId}`,
	simproIdQuotePage: (simproId: string) => `/takeoffs/quote/${simproId}`,
	symbolsPreviewPage: (groupId: string, packId: string) => {
		return `/settings/symbols/user/groups/${groupId ?? "default"}/packs/${packId}`
	},
	symbolPacksPage: (groupId: string) => `/settings/symbols/user/groups/${groupId}`,
	defaultSymbolsPreviewPage: (packId: string) => `/settings/symbols/system/${packId}`,
	projectDetailsSimproIdLoader: (type: JobsOrQuotes, simproId: number) => `/takeoffs/${type}/loader/${simproId}`,
	projectDetailsPage: (type: JobsOrQuotes, projectId: string) => `/takeoffs/${type}/details/${projectId}`,
	projectDrawingPage: (
		type: JobsOrQuotes,
		projectId: string,
		sectionId: string,
		costCenterId: string,
		drawingId: string,
		versionId: string
	) => {
		return `/takeoffs/${type}/details/${projectId}/section/${sectionId}/costCenter/${costCenterId}/takeoffs/${drawingId}/version/${versionId}`
	},
	planUploadPage: (type: JobsOrQuotes, projectId: string) => `/takeoffs/${type}/details/${projectId}/plan-upload`,
} as const

export {paths, pages}