import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const ImageIcon: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M24.3150384,6 C25.1947174,6 25.9190053,6.67798897 25.9928093,7.53887421 L25.9990021,7.68396368 L26,24.3160363 C26,25.195479 25.322011,25.9199783 24.4611258,25.9938053 L24.3160363,26 L7.68396368,26 C6.80428469,26 6.07999683,25.3217871 6.00619278,24.4610904 L6,24.3160363 L6,7.68396368 C6,6.80428469 6.67798897,6.07999683 7.53887421,6.00619278 L7.68396368,6 L24.3150384,6 Z M21.1968366,14.830456 L16.1339687,19.9025546 L18.5359246,22.3042611 C18.7863986,22.5549845 18.7863986,22.962878 18.5359246,23.2136014 C18.3048847,23.4338639 17.9501746,23.4532631 17.700948,23.2763459 L17.6220936,23.2101088 L12.3616406,17.9494062 L7.28929249,23.0220038 L7.28929249,24.3160363 C7.28929249,24.5066049 7.42471513,24.6658486 7.60447484,24.7026819 L7.68396368,24.7107075 L24.3349965,24.7107075 C24.5179249,24.7017576 24.6685733,24.5676992 24.7033772,24.3927658 L24.710957,24.3155374 L24.7099591,18.343329 L21.1968366,14.830456 Z M24.3160363,7.28829458 L7.68396368,7.28829458 L7.60634542,7.29589581 C7.53066061,7.31098131 7.46086967,7.34816885 7.40455044,7.40430097 C7.34841832,7.4606202 7.31123079,7.53055147 7.29614528,7.60641169 L7.28854406,7.68396368 L7.28854406,21.2028241 L11.9090909,16.5867678 C12.1349666,16.3611167 12.4879478,16.3387536 12.7388809,16.5191331 L12.8184313,16.5867678 L15.2201377,18.9884742 L20.7450354,13.4675681 C20.9709111,13.241917 21.3238923,13.2195539 21.5748254,13.3999334 L21.6543758,13.4675681 L24.7107075,16.5238998 L24.7107075,7.68296577 C24.7107075,7.49261551 24.5752849,7.33320808 24.3955252,7.29633044 L24.3160363,7.28829458 Z M10.5,8 C11.8784576,8 13,9.12154241 13,10.5 C13,11.8784576 11.8784576,13 10.5,13 C9.12154241,13 8,11.8784576 8,10.5 C8,9.12154241 9.12154241,8 10.5,8 Z M10.5,9.18428284 C9.77413446,9.18428284 9.18336692,9.77505038 9.18336692,10.5009159 C9.18336692,11.2267815 9.77413446,11.817549 10.5,11.817549 C11.2258655,11.817549 11.8166331,11.2267815 11.8166331,10.5009159 C11.8166331,9.77505038 11.2258655,9.18428284 10.5,9.18428284 Z"]}/>
	);
};

export default ImageIcon

