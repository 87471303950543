import React from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import "./settingsPage.scss";
import Tabs from "../../../components/ui/Tabs";
import Tab from "../../../components/ui/Tab";
import SettingsMain from "../components/SettingsMain";
import {useTranslation} from "react-i18next";
import {SymbolsSubTabs} from "../../symbols";
import Page from "../../../components/layout/page/Page";
import {NavigationHeader} from "../../../routes";
import {paths, useNavigator} from "../../navigator";

const SettingsPage: React.FC = () => {
	const location = useLocation();
	const {navigateTo} = useNavigator()
	const {t} = useTranslation();

	return (
		<Page>
			<NavigationHeader/>
			<div className={"settings-page"}>
				<Tabs>
					<Tab label={t("settings.tabs.main")} active={/main/.test(location.pathname)}
						 onClick={() => { navigateTo(paths.settings_main) }}/>
					<Tab label={t("settings.tabs.symbols.tabName")} active={/symbols/.test(location.pathname)}
						 onClick={() => { navigateTo(paths.settings_symbols) }}/>
				</Tabs>
				<Switch>
					<Route path={paths.settings_main} component={SettingsMain}/>
					<Route path={paths.settings_symbols} component={SymbolsSubTabs}/>
					<Redirect to={paths.settings_main}/>
				</Switch>
			</div>
		</Page>
	);
};

export {SettingsPage};
