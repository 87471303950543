import ProjectDetails from "../../../../../models/ProjectDetails";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Button from "../../../../../components/ui/Button";
import {useAttachments} from "./useAttachments";
import {useAttachmentFolders} from "./useAttachmentFolders";
import {AttachmentTable} from "./AttachmentTable";
import {Breadcrumbs} from "./Breadcrumbs";
import {AttachmentDetailsFolder} from "../../../../../models/AttachmentDetailsFolder";
import {AttachmentDetails} from "../../../../../models/AttachmentDetails";
import {apiInstance} from "../../../../../api/api";
import {useParams} from "react-router-dom";
import InlineSpinner from "../../../../../components/InlineSpinner";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import {PlanUploadAttachment} from "../../../features/plan-upload/types";
import {wait} from "../../../../../utils/ApiUtils";
import {ExternalAttachmentImportState} from "../../../../../models/ExternalAttachmentImportState";
import {AsyncRequestStatus} from "../../../../../models/enums";
import AttachmentApi from "../../../../../api/AttachmentApi";

export type Breadcrumb = {
	folderId?: string,
	name: string,
}

const initialBreadcrumb: Breadcrumb = {folderId: undefined, name: "Home"}

type AttachmentSelectModalProps = {
	project: ProjectDetails | undefined
	onClose: () => void
	onNext: (fileDownloadUrl: string, filename: string, attachmentData: PlanUploadAttachment) => Promise<void>,
	isLoading: boolean,
	setIsLoading: (isLoading: boolean) => void
}

export function useAttachmentSelectModal({project, onClose, onNext, isLoading, setIsLoading}: AttachmentSelectModalProps) {

	const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([{...initialBreadcrumb}]);
	const {attachments, loadAttachmentDetailsRef, loadStatus: filesLoadStatus} = useAttachments()
	const {attachmentFolders, loadAttachmentDetailFoldersRef, loadStatus: folderLoadStatus} = useAttachmentFolders()
	const [selectedFile, setSelectedFile] = useState<AttachmentDetails | undefined>(undefined);
	const {projectId} = useParams<{ projectId: string }>();
	const {t} = useTranslation();

	useEffect(() => {
		if (breadcrumbs.length && project) {
			const active = breadcrumbs[breadcrumbs.length - 1]
			loadAttachmentDetailsRef.current(project.source, project.number, active.folderId)
			loadAttachmentDetailFoldersRef.current(project.source, project.number, active.folderId)
		}
	}, [breadcrumbs, project, loadAttachmentDetailsRef, loadAttachmentDetailFoldersRef]);

	useEffect(() => {
		setSelectedFile(undefined)
	}, [attachments, attachmentFolders])

	function handleClose() {
		setBreadcrumbs([{...initialBreadcrumb}])
		onClose()
	}

	function handleBreadcrumbClick(breadcrumb: Breadcrumb) {
		setBreadcrumbs(breadcrumbs.slice(0, breadcrumbs.indexOf(breadcrumb) + 1))
	}

	function handleFolderClick(folder: AttachmentDetailsFolder) {
		setBreadcrumbs([...breadcrumbs, {folderId: folder.id, name: folder.name}])
	}

	function handleFileSelect(file: AttachmentDetails) {
		setSelectedFile(file)
	}

	async function onNextClick() {
		setIsLoading(true)
		try {
			const importInitializationData = await apiInstance.projectsApi.initializeExternalFileImport(projectId,
				{externalAttachmentId: selectedFile!.id})
			const attachmentId = await repetitivelyAskForImportState(importInitializationData.id);
			const fileDownloadUrl = AttachmentApi.getAttachmentDownloadUrl(attachmentId,
				importInitializationData.filename);
			await onNext(fileDownloadUrl, importInitializationData.filename, {
				id: attachmentId,
				contentType: importInitializationData.contentType,
				filename: importInitializationData.filename
			});
		}
		catch (e) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.uploadPlanTemplateErrorDesc"));
			LoggerService.logError(e);
		}
		finally {
			setIsLoading(false)
		}
	}

	const repetitivelyAskForImportState = async (externalAttachmentImportId: string): Promise<string> => {
		let timeElapsed = 0;
		let importState: ExternalAttachmentImportState | undefined
		const timeoutInSeconds = 300;
		const timeBetweenChecksInSeconds = 2;
		let isFinished = false;
		while (timeElapsed < timeoutInSeconds && !isFinished) {
			await wait(timeBetweenChecksInSeconds * 1000)
			importState = await apiInstance.projectsApi.fetchExternalAttachmentImportState(externalAttachmentImportId);
			isFinished = importState.status !== AsyncRequestStatus.IN_PROGRESS
			timeElapsed = timeElapsed + timeBetweenChecksInSeconds
		}
		if (!importState || importState.status === AsyncRequestStatus.FINISHED_WITH_ERRORS || !importState.attachmentId) {
			throw new Error("File import error.")
		}
		return importState.attachmentId;
	}
	return {
		children: (
			<>
				<Breadcrumbs
					breadcrumbs={breadcrumbs}
					onBreadcrumbClick={handleBreadcrumbClick}
				/>
				<AttachmentTable
					onFolderClick={handleFolderClick}
					onFileSelect={handleFileSelect}
					filesLoadStatus={filesLoadStatus}
					folderLoadStatus={folderLoadStatus}
					attachments={attachments}
					attachmentFolders={attachmentFolders}
					selectedFileId={selectedFile?.id ?? null}
				/>
			</>
		),
		buttons: (
			<>
				{isLoading && <InlineSpinner/>}
				<Button
					label={t("common.buttons.cancel")}
					variant={"optional"}
					onClick={handleClose}
					disabled={isLoading}
				/>
				<Button
					label={t("common.buttons.next")}
					variant={"primary"}
					disabled={!selectedFile?.id || isLoading}
					onClick={onNextClick}
				/>
			</>
		)
	}
}
