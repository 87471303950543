import React, {ReactNode} from "react";
import {usePopper} from "./usePopper";

const Popper: React.FC<{
	target: ReactNode,
	children: ReactNode,
	disabled?: boolean,
}> = ({target, disabled, children}) => {
	const {renderPopper} = usePopper({disabled})
	return renderPopper({target, content: children})
}

export {Popper}

