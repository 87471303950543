import {SystemPackVisibilityStateApiDto} from "./restModels";

export default class SystemPackVisibilityState {
	constructor(
		public readonly id: string,
		public readonly enabled: boolean
	) {}

	public static fromJson(json: SystemPackVisibilityStateApiDto): SystemPackVisibilityState {
		return new SystemPackVisibilityState(json.name, json.enabled);
	}
}