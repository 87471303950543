import React, {useEffect, useRef} from "react";
import {noop} from "../../utils/CallbackUtils";
import "./customSelect.scss";
import IconOrder from "../icons/IconOrder";
import {CustomSelectItem} from "../../models/interfaces";
import {useUpdatedRef} from "../../hooks/useUpdatedRef";
import {usePrevious} from "../../hooks/usePrevious";
import {usePopper} from "../popper";

export interface SelectorContextData {
	onClose: () => void,
}

const initialContextData: SelectorContextData = {
	onClose: noop
};

interface CustomSelectProps<T> {
	placeholder: string,
	items: CustomSelectItem<T>[],
	onChange: (value: T) => void,
	value?: T,
	customClass?: string;
	popperDisablePortal?: boolean
}

export const SelectContext = React.createContext(initialContextData);

function CustomSelect<T>(props: CustomSelectProps<T>): React.ReactElement<CustomSelectProps<T>> {
	const {placeholder, items, onChange, value, customClass, popperDisablePortal} = props;
	const updatedRefs = useUpdatedRef({items, onChange})
	const prevItems = usePrevious(items);
	const ref = useRef<HTMLDivElement | null>(null);
	const {renderPopper, isPopperOpen, closePopper} = usePopper({disablePortal: popperDisablePortal})

	useEffect(() => {
		const {items, onChange} = updatedRefs.current
		const haveItemsChanged = items.length !== prevItems?.length
			|| !items.every(item => prevItems?.find(prevItem => prevItem.value === item.value));
		if (items.length === 1 && haveItemsChanged) {
			onChange(items[0].value)
		}
	}, [updatedRefs, items, prevItems]);

	const handleClick = (item: CustomSelectItem<T>) => (evt: React.MouseEvent<HTMLDivElement>) => {
		onChange(item.value);
		evt.stopPropagation();
		if (!item.dontCloseOnClick) {
			closePopper();
		}
	};

	const getSelectedItemLabel = () => {
		const [item] = items.filter(i => i.value === value);
		return item ? item.label : placeholder;
	};

	const inputCls = [
		"custom-select",
		isPopperOpen ? "active" : "inactive",
		!value && "no-value",
		customClass && customClass
	].filter(Boolean).join(" ");

	return renderPopper({
		target: (
			<div className={inputCls} ref={ref}>
				<div className={"custom-select_label"}>
					<span>{getSelectedItemLabel()}</span>
					<IconOrder customRotate={0} active={false}/>
				</div>
			</div>
		),
		content: (
			<div className="custom-select_items" style={{width: ref.current?.offsetWidth}}>
				<SelectContext.Provider value={{onClose: closePopper}}>
					{items.length ? items.map((item, index) => {
						const selected = value === item.value;
						const itemCls = [
							"custom-select_items_item",
							selected && "selected"
						].filter(Boolean).join(" ");
						return (
							<div key={index} onClick={handleClick(item)}>
								<div className={itemCls}>
									{item.optionLabel || item.label}
									{item.dontCloseOnClick &&
										<IconOrder customRotate={selected ? 0 : -90} active={false}/>}
								</div>
								<div className="custom-select_items_item_additional">
									{item.renderExtraComponent && item.renderExtraComponent(closePopper)}
								</div>
							</div>
						)
					}) : <div className={"custom-select_items_item"}>No items to display</div>}
				</SelectContext.Provider>
			</div>
		)
	})
}

export default CustomSelect;
