import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconUndo: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width={32} height={32} viewBox="0 0 32 32" style={{cursor: cursor}}>
				<path fill={fill}
					  d="M13.8140729,15.8828828 C14.0344309,16.1100437 14.0589152,16.4626621 13.8875256,16.7177025 L13.8140729,16.808333 C13.5937149,17.0354939 13.2516566,17.060734 13.0042541,16.8840533 L12.9163377,16.808333 L9.18592706,12.962757 C8.96557036,12.7355975 8.94108496,12.3829816 9.11247177,12.1279413 L9.18592333,12.0373107 L12.916334,8.19167085 C13.1642346,7.93611272 13.5661644,7.93610928 13.8140692,8.19166316 C14.034429,8.41882216 14.0589161,8.77144034 13.8875286,9.02648215 L13.8140767,9.11711331 L11.016,12 L18.4348808,12 C21.3823389,12 24,14.7715713 24,17.8471551 C24,21.1198096 21.8365599,23.8722604 18.6582552,23.9956814 L18.4348808,24 L8.61184826,24 C8.2739338,24 8,23.7274944 8,23.3913416 C8,23.0857483 8.22639157,22.8327558 8.52143381,22.7892827 L8.61184826,22.7826833 L18.4348808,22.7826833 C20.9928439,22.7826833 22.7763035,20.5749801 22.7763035,17.8471551 C22.7763035,15.4947056 20.8032206,13.3402659 18.622286,13.22239 L18.4348808,13.2173167 L11.229,13.217 L13.8140729,15.8828828 Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconUndo