import React, {useState} from "react";
import "./assignToExistingSectionModal.scss";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import CustomSelect from "../../../../components/ui/CustomSelect";
import ModalButtons from "../../../../components/modal/ModalButtons";
import Button from "../../../../components/ui/Button";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import InlineSpinner from "../../../../components/InlineSpinner";
import LoggerService from "../../../../services/LoggerService";
import {useLocation, useParams} from "react-router-dom";
import {useCreateCostCenter, useMoveDrawingsToExistingCostCenter} from "../../hooks";

interface AssignToExistingSectionModalProps {
	onClose: () => void
	deletedSectionId: string
}

const AssignToExistingSectionModal: React.FC<AssignToExistingSectionModalProps> = ({
	onClose,
	deletedSectionId
}) => {
	const {t} = useTranslation();
	const {treeData: {sections, companyCostCenters}, refreshProjectTreeAfterSimproUpdate} = useProjectDetailContext()
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {projectId} = useParams<{ projectId: string }>();
	const {createCostCenter} = useCreateCostCenter();
	const {moveDrawingsToExistingCostCenter} = useMoveDrawingsToExistingCostCenter();

	const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const handleSectionSelect = (sectionId: string) => {
		setSelectedSectionId(sectionId)
	}

	const sectionsToSelect = sections.filter(s => !s.deletedExternal);

	const onSave = async () => {
		const selectedSectionCostCenters = sections.find(s => s.id === selectedSectionId)?.costCenters;
		const deletedSectionCostCenters = sections.find(s => s.id === deletedSectionId)?.costCenters;
		const costCentersThatRemainDeletedIds: string[] = [];
		setIsLoading(true)
		try {
			if (deletedSectionCostCenters) {
				for (const deletedCostCenter of deletedSectionCostCenters) {
					const costCenterWithSameName = selectedSectionCostCenters?.find(cc => cc.name === deletedCostCenter.name);
					const companyCostCenterWithSameName = companyCostCenters.find(ccc => ccc.name === deletedCostCenter.name);

					if (costCenterWithSameName !== undefined) {
						await moveDrawingsToExistingCostCenter(deletedCostCenter, costCenterWithSameName.id)
					}
					else if (companyCostCenterWithSameName !== undefined) {
						const createdCostCentreId = await createCostCenter(
							type === "jobs", projectId, selectedSectionId!,
							companyCostCenterWithSameName.name,
							companyCostCenterWithSameName.id
						)
						if (createdCostCentreId) {
							await moveDrawingsToExistingCostCenter(deletedCostCenter, createdCostCentreId)
						}
					}
					else {
						costCentersThatRemainDeletedIds.push(deletedCostCenter.id)
					}
				}
				if (costCentersThatRemainDeletedIds.length > 0) {
					await apiInstance.projectsApi.moveDeletedCostCenterWithoutCreatingExternally(
						costCentersThatRemainDeletedIds,
						selectedSectionId!
					);
				}
				refreshProjectTreeAfterSimproUpdate();
				onClose();
			}
		}
		catch (error) {
			LoggerService.logError(error)
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.assignToExistingSectionModal.onAssignmentErrDesc")
			)
		}
		finally {
			setIsLoading(false)
		}
	}

	return (
		<ModalWithHeader
			customClass={"assign-to-existing-section-modal"}
			isOpen={true} title={t("projects.details.assignToExistingSectionModal.title")}
			onClose={onClose}>
			<div className={"assign-to-existing-section-modal_container"}>
				<div className={"assign-to-existing-section-modal_container_field"}>
					<span className={"assign-to-existing-section-modal_container_field_label"}>
						{t("projects.details.assignToExistingSectionModal.sectionSelectLabel")}
					</span>
					<CustomSelect
						placeholder={t("projects.details.assignToExistingSectionModal.sectionSelectPlaceholder")}
						customClass="assign-to-existing-section-modal_container_field_select"
						items={sectionsToSelect.map(s => ({label: s.getLabel(), value: s.id}))}
						onChange={handleSectionSelect}
						value={selectedSectionId}
					/>
				</div>
				<ModalButtons>
					{isLoading && <InlineSpinner/>}
					<Button label={t("common.buttons.cancel")}
							variant={"optional"}
							disabled={isLoading}
							onClick={onClose}/>
					<Button label={t("common.buttons.save")}
							variant={"primary"}
							disabled={!selectedSectionId || isLoading}
							onClick={onSave}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	);
};

export default AssignToExistingSectionModal
