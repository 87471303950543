import React from "react";
import {useTranslation} from "react-i18next";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import SubTabs from "../../../components/ui/SubTabs";
import SubTab from "../../../components/ui/SubTab";
import PageTabsBottomSpacer from "../../../components/layout/page/PageTabsBottomSpacer";
import QuotesLoad from "../components/QuotesLoad";
import {QuoteStage} from "../../../models/enums";
import Tab from "../../../components/ui/Tab";
import Tabs from "../../../components/ui/Tabs";
import {paths, useNavigator} from "../../navigator";

const QuotesSubTabs: React.FC = () => {
	const {t} = useTranslation();
	const location = useLocation();
	const {navigateTo} = useNavigator()
	let isQuote = /quotes/.test(location.pathname);
	let isJob = /jobs/.test(location.pathname);

	const values: { stage: QuoteStage, path: string, label: string }[] = [{
		stage: QuoteStage.ALL,
		path: paths.takeoffs_quotes_all,
		label: t(`common.stages.quote.all`)
	}, {
		stage: QuoteStage.OPEN,
		path: paths.takeoffs_quotes_open,
		label: t(`common.stages.quote.open`)
	}, {
		stage: QuoteStage.PROGRESS,
		path: paths.takeoffs_quotes_progress,
		label: t(`common.stages.quote.progress`)
	}, {
		stage: QuoteStage.COMPLETE,
		path: paths.takeoffs_quotes_complete,
		label: t(`common.stages.quote.complete`)
	}, {
		stage: QuoteStage.APPROVED,
		path: paths.takeoffs_quotes_approved,
		label: t(`common.stages.quote.approved`)
	}, {
		stage: QuoteStage.CLOSED,
		path: paths.takeoffs_quotes_closed,
		label: t(`common.stages.quote.closed`)
	}]

	return (
		<>
			<Tabs className={"has-sub-tabs"}>
				<Tab label={t("projects.quote.tabName")} active={isQuote}
					 onClick={() => { navigateTo(paths.takeoffs_quotes) }}/>
				<Tab label={t("projects.job.tabName")} active={isJob}
					 onClick={() => { navigateTo(paths.takeoffs_jobs) }}/>
			</Tabs>
			<SubTabs>
				{values.map(v => (
					<SubTab key={v.stage} label={v.label}
							active={new RegExp(v.stage, "i").test(location.pathname)}
							onClick={() => { navigateTo(v.path) }}/>
				))}
			</SubTabs>
			<PageTabsBottomSpacer/>
			<Switch>
				{values.map(v => (
					<Route
						key={v.stage}
						path={v.path}
						component={() => <QuotesLoad sourceStage={v.stage}/>}
					/>
				))}
				<Redirect to={paths.takeoffs_quotes_open}/>
			</Switch>
		</>
	);
};

export default QuotesSubTabs
