import React from "react";
import "./dropdownArrow.scss"

interface IProps {
	variant?: "primary" | "secondary" | "tertiary" | "optional";
}

const DropdownArrow: React.FC<IProps> = (props) => {

	const {variant} = props;

	const variantMap = {
		"primary": "arrow-white",
		"secondary": "arrow-white",
		"tertiary": "arrow-white",
		"optional": "arrow-blue",
	}

	const cls = variant ? variantMap[variant] : "arrow-white";

	return (
		<span className={`${cls}-container`}>
			<span className={`${cls}-container_icon`}/>
		</span>
	);
};

export default DropdownArrow
