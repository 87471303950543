import React, {MutableRefObject} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {textActions} from "./textSlice";
import {EditorPanelTabKeys, EditorTool} from "../../models/enums";
import Konva from "konva";
import {configActions, selectActiveTool} from "../config/configSlice";
import {selectExportInProgress} from "../view/viewSlice";
import {Vector2d} from "konva/types/types";
import {TextGroup, TextItem} from "../../../../../base-konva/types";
import {BaseTextElement} from "../../../../../base-konva/features/text/BaseTextElement";
import {useGetHighlightShapeStyle, useHighlightSetter} from "../../hooks/highlight";
import {useGetSelectionShapeStyle, useSelectSetter} from "../../hooks/select";
import {useActivate} from "../../hooks/activate";
import {useEditorPanelDataContext} from "../panel/EditorPanelProvider";
import {DrawingItemType} from "../../../../../base-konva/enums";

type TextElementProps = {
	textGroup: TextGroup,
	textItem: TextItem,
	isTransformMode: MutableRefObject<boolean>,
}
export const TextElement: React.FC<TextElementProps> = ({textItem, textGroup, isTransformMode}) => {
	const {style} = textGroup;
	const {id} = textItem;
	const activeTool = useSelector(selectActiveTool);
	const exportInProgress = useSelector(selectExportInProgress);
	const dispatch = useDispatch();
	const {getHighlightStyle} = useGetHighlightShapeStyle();
	const highlightActions = useHighlightSetter(id);
	const textHighlightStyle = getHighlightStyle(highlightedPanelItemId =>
		highlightedPanelItemId === id || highlightedPanelItemId === textGroup.id)
	const activateActions = useActivate();
	const selectActions = useSelectSetter();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext();


	const {getSelectionStyle} = useGetSelectionShapeStyle();
	const selectionStyle = getSelectionStyle(highlighted => (
		highlighted.some(item => (
			item.type === DrawingItemType.TEXT &&
			item.itemId === textGroup.id &&
			(item.subItemIds && item.subItemIds.some(id => id === textItem.id))
		))
	))

	function selectElement() {
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.TEXT}))
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.MEASUREMENT_SELECT}))
		activateActions.activateText(textItem.id, textGroup.id)
		selectActions.clearSelection()
		selectActions.setSelection(DrawingItemType.TEXT, textGroup.id, undefined, [textItem.id])
		getExpandState(EditorPanelTabKeys.LAYERS).expandTab();
	}

	function handleDoubleClick() {
		dispatch(textActions.unselectAllTextItems())
		dispatch(textActions.editTextItem({id}))
	}

	function handleClick() {
		dispatch(textActions.selectTextItem({id}))
	}

	function handleDragEnd(evt: Konva.KonvaEventObject<Event>) {
		const position = evt.target.position();
		dispatch(textActions.updateTextElementPosition({itemId: textItem.id, newPosition: position}));
	}

	const onTransformEnd = (newPosition: Vector2d, scale: number, rotation: number) => {
		dispatch(textActions.transformTextElement({
			itemId: textItem.id, newPosition, fontSize: style.fontSize * scale, rotation
		}))
	}

	const isToolSelect = activeTool === EditorTool.MEASUREMENT_SELECT

	return (
		<BaseTextElement
			textGroup={textGroup}
			textItem={textItem}
			isTransformMode={isTransformMode}
			exportInProgress={exportInProgress}
			isTextToolActive={activeTool === EditorTool.TEXT}
			onTransformEnd={onTransformEnd}
			handleDoubleClick={isToolSelect ? undefined : handleDoubleClick}
			handleClick={isToolSelect ? selectElement : handleClick}
			handleDragEnd={handleDragEnd}
			onEnableHighlight={highlightActions.setHighlight}
			onClearHighlight={highlightActions.clearHighlight}
			selectionStyle={selectionStyle}
			textHighlightStyle={textHighlightStyle}/>
	);
};

