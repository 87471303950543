import {Status} from "../models/enums";
import {useEffect, useState} from "react";
import {apiInstance} from "../api/api";
import UserSummary from "../models/UserSummary";
import LoggerService from "../services/LoggerService";
import NotificationService from "../services/NotificationService";
import {useTranslation} from "react-i18next";

interface UseUsersHook {
	usersLoadStatus: Status,
	users: UserSummary[],
}

function useUsers(): UseUsersHook {

	const [usersLoadStatus, setUsersLoadStatus] = useState(Status.IDLE);
	const [users, setUsers] = useState<UserSummary[]>([]);
	const {t} = useTranslation();

	useEffect(() => {
		setUsersLoadStatus(Status.LOADING)
		apiInstance.usersApi.fetchUsers()
			.then((users) => {
				setUsers(users);
				setUsersLoadStatus(Status.SUCCESS);
			})
			.catch(err => {
				NotificationService.errorNotification(t("common.error"), t("projects.details.fetchDetailsUsersErrorDesc"));
				LoggerService.logError(err);
				setUsersLoadStatus(Status.ERROR);
			})
	}, [t]);

	return {usersLoadStatus, users};
}

export default useUsers;
