import {CostCenterExportStatusApiDto, ProjectExportStatusResultApiDto} from "./restModels";
import {ExportStatus} from "./SummariesExportStatusResult";
import LoggerService from "../services/LoggerService";
import SimproApiError from "./SimproApiError";

export default class ProjectExportStatusResult {
	constructor(
		public readonly id: string,
		public readonly costCenterStatuses: CostCenterExportStatusResult[]
	) {}

	public static fromJson(json: ProjectExportStatusResultApiDto): ProjectExportStatusResult {
		const {id, costCenterStatuses} = json

		return new ProjectExportStatusResult(
			id,
			costCenterStatuses.map(status => CostCenterExportStatusResult.fromJson(status))
		);
	}
}

export class CostCenterExportStatusResult {
	constructor(
		public readonly costCenterId: string,
		public readonly drawingsCount: number,
		public readonly errors: SimproApiError[],
		public readonly exportedDrawingsCount: number,
		public readonly status: ExportStatus
	) {}

	public static fromJson(json: CostCenterExportStatusApiDto): CostCenterExportStatusResult {
		const {
			costCenterId,
			drawingsCount,
			exportedDrawingsCount,
			errors
		} = json

		const status = ExportStatus[json.status];
		if (status === undefined) {
			const error = new Error(`Unknown status type: ${json.status}`);
			LoggerService.logError(error);
			throw error;
		}

		return new CostCenterExportStatusResult(
			costCenterId,
			drawingsCount,
			errors.map(error => SimproApiError.fromJson(error)),
			exportedDrawingsCount,
			status,
		);
	}
}