import React, {FC, useState} from "react";
import {BaseImageUploadModal} from "../../../../../base-konva/features/image/BaseImageUploadModal";

type PlanUploadImageUploadModalProps = {
	isOpen: boolean
	onCloseModal: () => void
	onHandleSave: (selectedItem: { value: string, label: string }) => void
}

export const PlanUploadImageUploadModal: FC<PlanUploadImageUploadModalProps> = function({isOpen, onCloseModal, onHandleSave}) {
	const [selectedItem, setSelectedItem] = useState<{ value: string, label: string } | undefined>(undefined)

	const onImageUpload = async (attachmentId: string, filename: string) => {
		setSelectedItem({value: attachmentId, label: filename})
	}

	const clearSelectedItemAndClose = () => {
		onCloseModal()
		setSelectedItem(undefined)
	}

	return (
		<BaseImageUploadModal
			isOpen={isOpen}
			onCloseModal={clearSelectedItemAndClose}
			onHandleSave={onHandleSave}
			selectedItem={selectedItem}
			onImageUpload={onImageUpload}/>
	)
}