import React from "react";
import {ActiveIconProps} from "../../../../project-drawings/features/editor/models/interfaces";
import IconSelectable from "../../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconSelectable";


const IconEraseAreaTool: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	const transform = `translate(3.5,3.5)`;
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			customTransform={transform}
			disabled={disabled}
			paths={["M8 3.5H5V0.5H3.5V3.5H0.5V5H3.5V8H5V5H8V3.5ZM11 3.5H14V5H11V3.5ZM17 3.5V5H20V8H21.5V5C21.5 4.60218 21.342 4.22064 21.0607 3.93934C20.7794 3.65804 20.3978 3.5 20 3.5H17Z",
				"M3.5 11H5V14H3.5V11ZM5 20V17H3.5V20C3.5 20.3978 3.65804 20.7794 3.93934 21.0607C4.22064 21.342 4.60218 21.5 5 21.5H8V20H5ZM20 11H21.5V14H20V11Z",
				"M11 20H14V21.5H11V20ZM20 17V20H17V21.5H20C20.3978 21.5 20.7794 21.342 21.0607 21.0607C21.342 20.7794 21.5 20.3978 21.5 20V17H20Z",
				"" +
				"M14.9188 6.66823L18.0245 9.77323C18.155 9.90382 18.2586 10.0588 18.3293 10.2295C18.4 10.4001 18.4364 10.5829 18.4364 10.7676C18.4364 10.9523 18.4 11.1351 18.3293 11.3058C18.2586 11.4764 18.155 11.6314 18.0245 11.762L12.5982 17.187L16.4095 17.1876C16.5227 17.1876 16.6322 17.2286 16.7175 17.3031C16.8029 17.3775 16.8584 17.4804 16.8738 17.5926L16.8782 17.6564C16.8782 17.7697 16.8371 17.8792 16.7625 17.9646C16.6879 18.05 16.5849 18.1055 16.4726 18.1207L16.4095 18.1251L11.1532 18.1257C10.9558 18.1369 10.7582 18.1062 10.5735 18.0357C10.3888 17.9651 10.221 17.8563 10.0813 17.7164L6.97634 14.6107C6.84576 14.4802 6.74217 14.3251 6.6715 14.1545C6.60083 13.9839 6.56445 13.801 6.56445 13.6164C6.56445 13.4317 6.60083 13.2488 6.6715 13.0782C6.74217 12.9076 6.84576 12.7526 6.97634 12.622L12.9301 6.66823C13.0607 6.53765 13.2157 6.43407 13.3863 6.36339C13.5569 6.29272 13.7398 6.25635 13.9245 6.25635C14.1091 6.25635 14.292 6.29272 14.4626 6.36339C14.6332 6.43407 14.7883 6.53765 14.9188 6.66823ZM8.56822 12.3551L7.63884 13.2851C7.55106 13.373 7.50175 13.4921 7.50175 13.6164C7.50175 13.7406 7.55106 13.8597 7.63884 13.9476L10.7445 17.0532C10.8357 17.1445 10.9557 17.1907 11.0757 17.1907L11.0945 17.1876L11.1182 17.1889C11.2276 17.1791 11.3301 17.1311 11.4076 17.0532L12.3363 16.1239L8.56759 12.3551H8.56822ZM13.5932 7.33073L9.23072 11.692L12.9995 15.4607L17.3613 11.0995C17.4049 11.056 17.4395 11.0043 17.463 10.9474C17.4866 10.8905 17.4987 10.8295 17.4987 10.7679C17.4987 10.7063 17.4866 10.6454 17.463 10.5885C17.4395 10.5316 17.4049 10.4799 17.3613 10.4364L14.2557 7.33073C14.1678 7.24295 14.0487 7.19365 13.9245 7.19365C13.8002 7.19365 13.6811 7.24295 13.5932 7.33073Z"]}/>
	);
};

export default IconEraseAreaTool
