import {KonvaEventObject} from "konva/types/Node";
import React, {useState} from "react";
import {distance, setActiveOnTopLayer} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {penActions, selectActivePenGroupId, selectPenType} from "./penSlice";
import {ToolHookResult} from "../../models/editor";
import {EditorTool} from "../../models/enums";
import {useSnapToPoint} from "../length/snapUtils";
import {Vector2d} from "konva/types/types";
import {selectSnappingState} from "../view/viewSlice";
import {PEN_TOOL_THRESHOLD} from "../../constants";
import {PenElement} from "./PenElement";
import {useSelectionCleaner} from "../../hooks/select";
import {KonvaEventType, PenGroup, PenLine, PenToolType} from "../../../../../base-konva/types";
import {getId} from "../../../../../../utils";
import {getPointerPosition, getSnappedPosition} from "../../../../../base-konva/utils";

function usePen(penDrawGroups: PenGroup[]): ToolHookResult {
	const {snapToPoint} = useSnapToPoint()
	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const penType = useSelector(selectPenType);
	const snapping = useSelector(selectSnappingState);
	const activePenGroupId = useSelector(selectActivePenGroupId);
	const [isDrawing, setIsDrawing] = useState(false)
	const [currentLine, setCurrentLine] = useState<PenLine>({id: getId(), name: "-", visible: true, points: []})

	useSelectionCleaner(EditorTool.PEN)

	function onMouseDown(evt: KonvaEventObject<KonvaEventType>) {

		if (penDrawGroups.length === 0 || penDrawGroups.every(group => group.id !== activePenGroupId)) {
			dispatch(penActions.addPenGroup())
		}

		setIsDrawing(true);
		const position = getPointerPosition(evt);
		if (position) {
			setCurrentLine(line => ({
				...line,
				points: line.points.concat([position.x, position.y])
			}))
		}
	}

	function onMouseUp() {
		// Do not update redux state until finished drawing lines,
		// Update on mouse move will cause problem with future undo/redo functionality
		// State should be update after finished whole action
		dispatch(penActions.addPenLine(currentLine.points))
		setIsDrawing(false)
		setCurrentLine(line => ({...line, points: []}))
	}

	function onMouseMove(evt: KonvaEventObject<KonvaEventType>) {
		const position = getPointerPosition(evt);
		if (position && isDrawing) {
			if (penType === PenToolType.FREE) {
				if (currentLine.points.length >= 2) {
					const lastPosition = currentLine.points.slice(-2)
					if (distance({x: lastPosition[0], y: lastPosition[1]}, position) >= PEN_TOOL_THRESHOLD) {
						setCurrentLine(line => ({
							...line,
							points: line.points.concat([position.x, position.y])
						}))
					}
				}
			}
			else {
				const setPoints = (points: number[]) => {
					if (points.length <= 1) return [position.x, position.y]
					const firstPoint: Vector2d = {x: points[0], y: points[1]}
					const newPosition = snapping.point ? snapToPoint(position).snapTo : snapping.angle
						? getSnappedPosition(firstPoint, position)
						: position
					return [points[0], points[1], newPosition.x, newPosition.y]
				}
				setCurrentLine(line => ({
					...line,
					points: setPoints(line.points)
				}))
			}
		}
	}

	function render() {
		// Current line visualization, without concat only stored lines will be displayed.
		return setActiveOnTopLayer(penDrawGroups, activePenGroupId).map(group => (
			group.penLines
				.concat(group.id === activePenGroupId ? currentLine : [])
				.map(line => (
					<PenElement
						key={line.id}
						line={line}
						group={group}
						isDrawing={isDrawing}/>
				))
		))
	}

	return {
		id: hookRenderId,
		render,
		tool: EditorTool.PEN,
		callbacks: {
			onMouseUp,
			onMouseDown,
			onMouseMove,
		}
	}
}

export default usePen;
