import React, {useEffect, useState} from "react";
import {ResizeAnchor, ResizeStageDimensions} from "../../models/editor";
import {useTranslation} from "react-i18next";
import {anchors} from "./anchors";
import {useSelector} from "react-redux";
import Modal from "../../../../../../components/modal/Modal";
import ModalTitle from "../../../../../../components/modal/ModalTitle";
import ModalButtons from "../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../components/ui/Button";
import {selectStageConfig} from "../config/configSlice";
import AnchorGrid from "./AnchorGrid";
import "./resizeStageModal.scss"
import {MinMaxValidatedNumberInput} from "../../components/MinMaxValidatedNumberInput";

interface ResizeStageModalProps {
	isOpen: boolean,
	onClose: () => void,
	onResize: (anchor: ResizeAnchor, resizeStageDimensions: ResizeStageDimensions) => void,
}

export const ResizeStageModal: React.FC<ResizeStageModalProps> = ({isOpen, onClose, onResize}) => {
	const {t} = useTranslation();
	const [activeAnchor, setActiveAnchor] = useState<ResizeAnchor>(anchors[0][0]);
	const stageConfig = useSelector(selectStageConfig)

	const [newStageWidth, setNewStageWidth] = useState(0);
	const [newStageHeight, setNewStageHeight] = useState(0);
	const oldStageWidth = stageConfig.width;
	const oldStageHeight = stageConfig.height;

	useEffect(() => {
		if (isOpen) {
			setNewStageHeight(oldStageHeight)
			setNewStageWidth(oldStageWidth)
		}
	}, [isOpen, oldStageHeight, oldStageWidth])

	function handleResize() {
		onResize(activeAnchor, {
			oldHeight: oldStageHeight,
			oldWidth: oldStageWidth,
			newHeight: newStageHeight,
			newWidth: newStageWidth,
		});
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<ModalTitle>{t("editor.resizeStageModal.title")}</ModalTitle>
			<div className="resize-stage-modal_content">
				<div className={"resize-stage-modal_content_title"}>
					{t("editor.resizeStageModal.currentSize")}
				</div>
				<div style={{marginBottom: 20}}>
					<div style={{marginBottom: 5}}>
						{t("common.width")}: <span
						className={"resize-stage-modal_content_title"}>{stageConfig.width}px</span><br/>
					</div>
					<div style={{marginBottom: 5}}>
						{t("common.height")}: <span className={"resize-stage-modal_content_title"}>{stageConfig.height}px</span>
					</div>
				</div>
				<div className={"resize-stage-modal_content_resize-form"}>
					<div className={"resize-stage-modal_content_resize-form_title"}>
						{t("editor.resizeStageModal.newSize")}
					</div>
					<div className={"resize-stage-modal_content_resize-form_field"}>
						<MinMaxValidatedNumberInput
							label={`${t("common.width")} `}
							min={100} max={100000} value={newStageWidth}
							onChange={value => setNewStageWidth(value)}
						/>
						<span>{t("common.px")}</span>
					</div>
					<div className={"resize-stage-modal_content_resize-form_field"}>
						<MinMaxValidatedNumberInput
							label={t("common.height")}
							min={100} max={100000} value={newStageHeight}
							onChange={value => setNewStageHeight(value)}
						/>
						<span>{t("common.px")}</span>
					</div>
				</div>
				<AnchorGrid activeAnchor={activeAnchor} setActiveAnchor={setActiveAnchor}/>
			</div>
			<ModalButtons>
				<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
				<Button label={t("editor.resizeStageModal.resize")} variant={"primary"} onClick={handleResize}/>
			</ModalButtons>
		</Modal>
	);
};