import React from "react";
import "./settingsSymbols.scss";
import PageHeader from "../../../components/layout/page/PageHeader";
import {useTranslation} from "react-i18next";
import PageHeaderButtons from "../../../components/layout/page/PageHeaderButtons";
import Button from "../../../components/ui/Button";
import {useParams} from "react-router-dom";
import useSymbols from "../hooks/useSymbols";
import {Status} from "../../../models/enums";
import Spinner from "../../../components/Spinner";
import DataFetchError from "../../../components/ui/DataFetchError";
import useSymbolPacks from "../hooks/useSymbolPacks";
import useSymbolGroups from "../hooks/useSymbolGroups";
import SymbolPreviewElement from "./SymbolPreviewElement";
import {pages, paths, useNavigator} from "../../navigator";

const SymbolsPreview: React.FC = () => {
	const {groupId, packId} = useParams<{ groupId?: string, packId?: string }>();
	const {symbols, loadStatus} = useSymbols(packId);

	const {symbolGroups} = useSymbolGroups();
	const symbolGroup = symbolGroups.find(group => group.id === groupId);

	const {symbolPacks} = useSymbolPacks(groupId);
	const symbolPack = symbolPacks.find(pack => pack.id === packId);

	const {t} = useTranslation();
	const {navigateTo} = useNavigator()

	const redirectToGroups = () => {
		navigateTo(paths.settings_symbols_user_groups)
	};

	const redirectToPacks = () => {
		if (!groupId) return;
		navigateTo(pages.symbolPacksPage(groupId))
	};

	return (
		<>
			{loadStatus === Status.LOADING && <Spinner/>}
			{loadStatus === Status.SUCCESS &&
				<div className={"settings-symbols"}>
					<PageHeader>
						<div className="settings-symbols_breadcrumbs">
							{(symbolGroup && symbolPack) &&
								<>
							<span className="settings-symbols_breadcrumbs_item" onClick={redirectToGroups}>
								{t("settings.tabs.symbols.groups.title")}
							</span>
									<span>{" / "}</span>
									<span className="settings-symbols_breadcrumbs_item" onClick={redirectToPacks}>
								{symbolGroup.name}
							</span>
									<span>{" / "}</span>
									<span className="settings-symbols_breadcrumbs_current-item">{symbolPack.name}</span>
								</>}
						</div>
						<PageHeaderButtons>
							<Button
								label={t("common.buttons.cancel")}
								onClick={() => {navigateTo(paths.root)}}
								variant={"optional"}
							/>
						</PageHeaderButtons>
					</PageHeader>
					<div className={"settings-symbols_preview"}>
						{symbols.map((symbol) => (
							<div className={"settings-symbols_preview_item"} key={symbol.id}>
								<SymbolPreviewElement remoteSymbol={{
									id: symbol.id,
									filename: symbol.filename
								}}/>
							</div>
						))}
					</div>
				</div>
			}
			{loadStatus === Status.ERROR && <DataFetchError/>}
		</>
	);
};

export default SymbolsPreview
