import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorL: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M20,11.104l-12.604,0l3.844,-3.85l-1.252,-1.254l-5.988,6l5.988,6l1.252,-1.254l-3.844,-3.85l12.604,-0l0,-1.792Z"]}/>
	);
};

export default AnchorL