import {useState} from "react";
import {useTranslation} from "react-i18next";
import {PlanUploadAttachment, PlanUploadData, PlanUploadPageData} from "../features/plan-upload/types";
import AttachmentApi from "../../../api/AttachmentApi";
import {convertPdfToImages} from "../../../utils/PdfUtils";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {DrawingType} from "../../../models/enums";
import {FileData} from "../components/plan-templates/modals/FilePreviewModal";

type Modal = {
	type: 'upload-template',
	data: {
		onNext: (previewSources: string[], filename: string, attachmentData: PlanUploadAttachment) => void,
	}
} | {
	type: 'create-template',
	data: {
		previewSources: string[],
		filename: string,
		attachmentData: PlanUploadAttachment,
		refreshPlanTemplates: () => void,
		onPrevious: () => void,
	}
} | {
	type: 'edit-template',
	data: {
		template: PlanUploadData,
		previewSources: string[],
		displayedFilename: string,
		refreshPlanTemplates: () => void
	}
} | {
	type: 'rename-template',
	data: {
		template: PlanUploadData,
	}
} | {
	type: 'delete-template',
	data: {
		template: PlanUploadData,
		refreshPlanTemplates: () => void
	}
} | {
	type: 'rename-page',
	data: {
		template: PlanUploadData,
		page: PlanUploadPageData,
		onPageRename: (newPlanPage: PlanUploadPageData, planTemplateId: string) => Promise<void>,
	}
} | {
	type: 'delete-page',
	data: {
		template: PlanUploadData,
		page: PlanUploadPageData,
	}
} | {
	type: 'create-drawing',
	data: {
		template: PlanUploadData,
		page: PlanUploadPageData,
		drawingType: DrawingType,
		onSuccess: () => void,
	}
} | {
	type: 'preview-file',
	data: {
		fileData: FileData
	}
}

function usePlanTemplateModalsData() {

	const {t} = useTranslation();
	const [modal, setModal] = useState<Modal | null>(null);

	function closeModal() {
		setModal(null)
	}

	function openUploadTemplateModal(
		onNext: (previewSources: string[], filename: string, attachmentData: PlanUploadAttachment) => void
	) {
		setModal({
			type: 'upload-template',
			data: {onNext}
		})
	}

	function openCreateTemplateModal({data, refreshPlanTemplates, onPrevious}: {
		data: { previewSources: string[], filename: string, attachmentData: PlanUploadAttachment },
		refreshPlanTemplates: () => void,
		onPrevious: () => void,
	}) {
		setModal({
			type: 'create-template',
			data: {...data, refreshPlanTemplates, onPrevious}
		})
	}

	function openEditTemplateModal(template: PlanUploadData, refreshPlanTemplates: () => void) {
		setModal({
			type: 'edit-template',
			data: {
				template,
				previewSources: [],
				displayedFilename: template.name,
				refreshPlanTemplates,
			}
		});
		(async function() {
			const fileDownloadUrl = AttachmentApi.getAttachmentDownloadUrl(template.attachment.id, template.name);
			try {
				let previewSources: string[];
				if (template.isPdf) {
					previewSources = await convertPdfToImages(fileDownloadUrl);
				}
				else {
					previewSources = [AttachmentApi.getAttachmentDownloadUrl(template.attachment.id, template.name)]
				}
				setModal({
					type: 'edit-template',
					data: {
						template,
						previewSources,
						displayedFilename: template.name,
						refreshPlanTemplates,
					}
				})
			}
			catch (e) {
				NotificationService.errorNotification(
					t("common.error"),
					t("common.utils.pdfUtils.convertPdfToImagesErrorDesc")
				);
				LoggerService.logError(e);
				closeModal();
			}
		})();
	}

	function openRenameTemplateModal(template: PlanUploadData) {
		setModal({
			type: "rename-template",
			data: {template}
		})
	}

	function openDeleteTemplateModal(template: PlanUploadData, refreshPlanTemplates: () => void) {
		setModal({
			type: "delete-template",
			data: {template, refreshPlanTemplates}
		})
	}

	function openRenamePageModal(
		template: PlanUploadData,
		page: PlanUploadPageData,
		onPageRename: (newPlanPage: PlanUploadPageData, planTemplateId: string) => Promise<void>,
	) {
		setModal({
			type: 'rename-page',
			data: {template, page, onPageRename}
		})
	}

	function openDeletePageModal(template: PlanUploadData, page: PlanUploadPageData) {
		setModal({
			type: 'delete-page',
			data: {template, page}
		})
	}

	function openCreateTakeoffDrawingModal(
		template: PlanUploadData,
		page: PlanUploadPageData,
		onSuccess: () => void,
	) {
		setModal({
			type: 'create-drawing',
			data: {template, page, drawingType: DrawingType.TAKE_OFF, onSuccess}
		})
	}

	function openCreateAsBuiltDrawingModal(
		template: PlanUploadData,
		page: PlanUploadPageData,
		onSuccess: () => void,
	) {
		setModal({
			type: 'create-drawing',
			data: {template, page, drawingType: DrawingType.AS_BUILT, onSuccess}
		})
	}

	function openFilePreviewModal(fileData: FileData) {
		setModal({
			type: 'preview-file',
			data: {fileData}
		})
	}

	return {
		modal,
		closeModal,
		openUploadTemplateModal,
		openCreateTemplateModal,
		openEditTemplateModal,
		openRenameTemplateModal,
		openDeleteTemplateModal,
		openRenamePageModal,
		openDeletePageModal,
		openCreateTakeoffDrawingModal,
		openCreateAsBuiltDrawingModal,
		openFilePreviewModal,
	}
}

export {usePlanTemplateModalsData};