import {SelectedDrawingVersions} from "../../features/drawing-exporter";
import {useTranslation} from "react-i18next";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import {useDrawingVersionsSummaryExporter} from "../../features/sync-materials/hooks/useDrawingVersionsSummaryExporter";
import React, {useState} from "react";
import {INITIAL_VERSION_ID} from "../../../project-drawings/features/editor/constants";
import {ConversionSourceType, Status} from "../../../../models/enums";
import NotificationService from "../../../../services/NotificationService";
import LoggerService from "../../../../services/LoggerService";
import ConfirmationModal from "../../../../components/ui/ConfirmationModal";
import Button from "../../../../components/ui/Button";

export function SendSelectedAttachmentsButton({selection}: { selection: SelectedDrawingVersions }) {

	const {t} = useTranslation();
	const {projectDetails, treeData: {sections, selectionData}, fitTimesData} = useProjectDetailContext()
	const drawingVersionsSummaryExporter = useDrawingVersionsSummaryExporter()
	const {fitTimes, loadStatus: fitTimesLoadStatus} = fitTimesData
	const [confirmModalOpen, setConfirmModalOpen] = useState(false);

	const selectedDrawingDataList = React.useMemo(() => {
		return selection.filter(item => (
			item.selectedVersionId !== INITIAL_VERSION_ID
		)).map(item => ({
			versionId: item.selectedVersionId,
			drawingType: item.drawingType
		}))
	}, [selection])

	const canSendSelectedToSimpro = Boolean(
		drawingVersionsSummaryExporter.status !== Status.LOADING &&
		fitTimesLoadStatus === Status.SUCCESS &&
		!!projectDetails.project &&
		projectDetails.project.id &&
		projectDetails.project.conversionSourceType === ConversionSourceType.Quote &&
		selectedDrawingDataList.length > 0
	);

	function handleSendSelectedToSimpro() {
		if (!canSendSelectedToSimpro) {
			return;
		}

		drawingVersionsSummaryExporter.exportDrawingVersionsSummary(
			projectDetails.project!.id,
			selectedDrawingDataList,
			fitTimes,
			sections,
			() => {
				setConfirmModalOpen(false);
				selectionData.reset();
				NotificationService.successNotification(
					t("common.success"),
					t("projects.details.sendAttachments.sendSuccessDesc")
				);
			},
			error => {
				LoggerService.logError(error);
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.sendAttachments.sendErrorDesc")
				);
			}
		)
	}

	const confirmButtonLabel = React.useMemo(() => {
		if (drawingVersionsSummaryExporter.status === Status.LOADING) {
			return t("projects.details.sendAttachments.confirmationModal.processingButton")
		}
		return t("projects.details.sendAttachments.confirmationModal.confirmButton")
	}, [drawingVersionsSummaryExporter.status, t])

	return (
		<>
			{confirmModalOpen ? (
				<ConfirmationModal
					title={t("projects.details.sendAttachments.confirmationModal.title")}
					text={t("projects.details.sendAttachments.confirmationModal.message")}
					confirmationButtonLabel={confirmButtonLabel}
					onConfirm={handleSendSelectedToSimpro}
					onClose={() => {
						if (drawingVersionsSummaryExporter.status !== Status.LOADING) {
							setConfirmModalOpen(false)
						}
					}}
					isLoading={drawingVersionsSummaryExporter.status === Status.LOADING}
					isOpen={true}
				/>
			) : null}
			<Button
				label={t("projects.details.sendAttachments.button")}
				variant={"secondary"}
				disabled={!canSendSelectedToSimpro}
				onClick={() => { setConfirmModalOpen(true); }}
			/>
		</>
	)
}