import {useDispatch, useSelector} from "react-redux";
import {selectZoomState, viewActions} from "./viewSlice";
import {useBaseZoomActions} from "../../../../../base-konva/features/view";

export function useZoomActions() {
	const zoomState = useSelector(selectZoomState);
	const dispatch = useDispatch();

	return useBaseZoomActions({
		zoomState,
		setZoomState: value => {
			dispatch(viewActions.setZoomState(value))
		}
	})
}