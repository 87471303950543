import React, {useCallback} from "react";
import Konva from "konva";
import {useDispatch, useSelector} from "react-redux";
import {BaseEditTextContainer} from "../../../../../base-konva/features/text/BaseEditTextContainer";
import {
	planUploadTextActions,
	selectPlanUploadActiveTextGroupId,
	selectPlanUploadTextGroups,
	selectPlanUploadTextSelectorStyle
} from "./planUploadTextSlice";
import {selectPlanUploadActiveTool} from "../config/planUploadConfigSlice";
import {PlanUploadEditorTool} from "../../types";
import {selectPlanUploadZoomState} from "../view/planUploadViewSlice";
import {SaveTextItemActionPayload} from "../../../../../project-drawings/features/editor/models/editor";

type PlanUploadEditTextContainerProps = {
	whiteCanvasRef: React.RefObject<Konva.Rect>
}
export const PlanUploadEditTextContainer: React.FC<PlanUploadEditTextContainerProps> = ({whiteCanvasRef}) => {
	const textGroups = useSelector(selectPlanUploadTextGroups);
	const activeTextGroupId = useSelector(selectPlanUploadActiveTextGroupId);
	const group = textGroups.find(group => group.id === activeTextGroupId)
	const dispatch = useDispatch();
	const activeTool = useSelector(selectPlanUploadActiveTool);
	const isTextTool = activeTool === PlanUploadEditorTool.TEXT;
	const zoomState = useSelector(selectPlanUploadZoomState)
	const style = useSelector(selectPlanUploadTextSelectorStyle);

	const deleteTextItem = useCallback((id: string) => {
		dispatch(planUploadTextActions.deleteTextItem({id}))
	}, [dispatch])

	function saveTextItem(payload: SaveTextItemActionPayload) {
		dispatch(planUploadTextActions.saveTextItem(payload))
	}

	return (
		<BaseEditTextContainer
			whiteCanvasRef={whiteCanvasRef}
			group={group}
			isTextTool={isTextTool}
			zoomState={zoomState}
			style={style}
			deleteTextItem={deleteTextItem}
			saveTextItem={saveTextItem}
		/>
	)
};
