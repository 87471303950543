import React from "react";

const IconXSmall: React.FC = () => {
	return (
		<svg width="15px" height="15px" viewBox="0 0 15 15">
			<polygon fill="#FF0000"
					 points="12.6666667 3.07428571 11.592381 2 7.33333333 6.25904762 3.07428571 2 2 3.07428571 6.25904762 7.33333333 2 11.592381 3.07428571 12.6666667 7.33333333 8.40761905 11.592381 12.6666667 12.6666667 11.592381 8.40761905 7.33333333"/>
		</svg>
	);
};

export default IconXSmall
