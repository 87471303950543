import React from "react";
import {useBreadcrumbsContext} from "../providers/BreadcrumbsProvider";

type Props = {
	sectionId: string
}

export function SectionDetailsBreadcrumb({sectionId}: Props) {
	const {projectData} = useBreadcrumbsContext()
	if (projectData === undefined) {
		return <span>...</span>
	}
	const {sections, treeExpandDataRef} = projectData
	return (
		<span onClick={() => {
			treeExpandDataRef.current.handleSectionBreadcrumbClick(sectionId)
		}}>
			{sections.find(section => section.id === sectionId)?.name}
		</span>
	)
}