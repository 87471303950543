import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../models/interfaces";
import SymbolStyleSelector from "../../../../components/SymbolStyleSelector";
import {AreaStyle, CountMeasurementStyle} from "../../../../models/editor";
import LineStyleSelector from "../../../../components/LineStyleSelector";
import AreaStyleSelector from "../../../../components/AreaStyleSelector";
import {EditorTool} from "../../../../models/enums";
import React from "react";
import {LineStyle} from "../../../../../../../base-konva/types";

type MeasurementStyleSelectorProps = {
	measurementType: MaterialAddableTool
	style: MeasurementStyle
	onChange: (newStyle: MeasurementStyle) => void
}

export function MeasurementStyleSelector({measurementType, style, onChange}: MeasurementStyleSelectorProps) {
	let renderStyleSelector: () => JSX.Element

	const renderCountStyleSelector = () => (
		<SymbolStyleSelector style={style as CountMeasurementStyle} onStyleChange={onChange}/>
	);
	const renderLengthStyleSelector = () => (
		<LineStyleSelector style={style as LineStyle} onStyleChange={onChange}/>
	);
	const renderAreaStyleSelector = () => (
		<AreaStyleSelector style={style as AreaStyle} onStyleChange={onChange}/>
	);

	switch (measurementType) {
		case EditorTool.COUNT:
			renderStyleSelector = renderCountStyleSelector;
			break;
		case EditorTool.LENGTH:
			renderStyleSelector = renderLengthStyleSelector;
			break;
		case EditorTool.AREA:
		case EditorTool.VOLUME:
			renderStyleSelector = renderAreaStyleSelector;
			break
	}
	return renderStyleSelector()
}