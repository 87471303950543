import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectActiveTool, selectEditorLockState} from "../../config/configSlice";
import {useTranslation} from "react-i18next";
import IconArrowSmall from "../../../icons/IconArrowSmall";
import Dropdown from "../../../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../../../components/ui/DropdownItem";
import {AreaHelper} from "../../area/AreaHelper";
//Reused length panel item styles
import "../length/lengthPanelItem.scss"
import {AreaMeasurement, AreaStyle, Deduction} from "../../../models/editor";
import {areaActions, selectActiveAreaId} from "../../area/areaSlice";
import MeasurementStyleSelectorModal from "../../../components/MeasurementStyleSelectorModal";
import ConfirmationModal from "../../../../../../../components/ui/ConfirmationModal";
import HeightItem from "./HeightItem";
import {useHighlightItem, useHighlightSetter} from "../../../hooks/highlight";
import {useUserSettingsDataContext} from "../../../../../../../providers/UserSettingsProvider";
import {AreaPanelItemModal} from "../../../../../../../models/enums";
import {EditorTool, MaterialType, MeasurementType} from "../../../models/enums";
import SetNumberValueModal from "../length/SetNumberValueModal";
import {useEditorPanelDataContext} from "../EditorPanelProvider";
import {IconPin} from "../../../icons/side_panel/IconPin";
import {useMaterialModalContext} from "../material/MaterialModalProvider";
import {DeductionItem} from "./DeductionItem";
import IconVisibleButton from "../../../components/IconVisibleButton";
import {getId} from "../../../../../../../utils";
import {selectScale} from "../../scale/scaleSlice";

interface AreaBasedPanelItemProps {
	area: AreaMeasurement,
	type: EditorTool.AREA | EditorTool.VOLUME
}

const AreaBasedPanelItem: React.FC<AreaBasedPanelItemProps> = ({area, type}) => {
	const dispatch = useDispatch();
	const scale = useSelector(selectScale);
	const editorLocked = useSelector(selectEditorLockState);
	const activeTool = useSelector(selectActiveTool);
	const areaActive = useSelector(selectActiveAreaId) === area.id && activeTool === type;
	const {settings} = useUserSettingsDataContext();
	const {pinnedItems, findPinnedMeasurement} = useEditorPanelDataContext()
	const {openMaterialChangeModal, openOneOffChangeModal} = useMaterialModalContext()

	const {t} = useTranslation();

	const [isExpanded, setIsExpanded] = useState(false);
	const [modal, setModal] = useState<AreaPanelItemModal>(AreaPanelItemModal.NONE);
	const highlightPanelActions = useHighlightSetter(area.id);
	const {isItemHover} = useHighlightItem(area.id);
	const isOneOff = area.material.type === MaterialType.ONE_OFF;
	const everyFragmentClosed = area.areaFragments.length > 0 && area.areaFragments.every(fragment => fragment.closed)

	function createDeduction() {
		if (everyFragmentClosed) {
			setIsExpanded(true)
			dispatch(configActions.switchEditorTool({editorTool: type}));
			handleActivate();
			dispatch(areaActions.addAreaDeduction({areaId: area.id}))
		}
	}

	function handleDeductionClick(deduction: Deduction) {
		return function() {
			dispatch(configActions.switchEditorTool({editorTool: type}));
			dispatch(areaActions.activateDeduction({
				areaId: area.id,
				deductionId: deduction.id
			}))
		}
	}

	function handleDeleteDeduction(deduction: Deduction) {
		return function() {
			dispatch(areaActions.deleteDeduction({
				areaId: area.id,
				deductionId: deduction.id
			}))
		}
	}

	const openHeightModal = () => setModal(AreaPanelItemModal.HEIGHT);
	const openDeleteModal = () => setModal(AreaPanelItemModal.DELETE);
	const openStyleModal = () => setModal(AreaPanelItemModal.STYLE);
	const openChangeMaterialModal = () => openMaterialChangeModal({
		measurementId: area.id,
		measurementStyle: area.style,
		measurementType: type
	});
	const openChangeOneOffModal = (modalTitle: string) => () => openOneOffChangeModal({
		measurementId: area.id,
		measurementStyle: area.style,
		measurementType: type,
		materialName: area.material.name,
		modalTitle: modalTitle
	})
	const closeModal = () => setModal(AreaPanelItemModal.NONE);

	function toggleExpand() {
		setIsExpanded(!isExpanded);
	}

	const cls = [
		"panel-item",
		areaActive && "is-active",
		isExpanded && "is-expanded",
	].filter(Boolean).join(" ");

	const panelItemBarCls = [
		"panel-item_bar",
		isItemHover && "is-hover",
	].filter(Boolean).join(" ");

	function handleActivate() {
		const activationId = getId()
		dispatch(configActions.switchEditorTool({editorTool: type, actionId: activationId}));
		dispatch(areaActions.activateArea({id: area.id, actionId: activationId}))
		dispatch(areaActions.setVisibility({id: area.id, visible: true, type, actionId: activationId}))
	}

	function handleDeactivate() {
		dispatch(areaActions.clearActiveArea({}))
	}

	function toggleActivate() {
		areaActive ? handleDeactivate() : handleActivate()
	}

	function handleStyleSave(style: AreaStyle) {
		closeModal();
		dispatch(areaActions.changeStyle({id: area.id, style}));
	}

	function handleDelete() {
		dispatch(areaActions.deleteArea({id: area.id}));
	}

	function handleSetHeight(height: number) {
		dispatch(areaActions.setAreaHeight({id: area.id, height}))
		closeModal();
		setIsExpanded(true)
	}

	function handleDeleteHeight() {
		dispatch(areaActions.setAreaHeight({id: area.id, height: undefined}))
	}

	function toggleVisibility() {
		dispatch(areaActions.setVisibility({id: area.id, visible: !area.visible, type}))
	}

	const pinnedMeasurement = findPinnedMeasurement(area);

	function addToPinnedItems() {
		const measurementType = type === EditorTool.VOLUME ? MeasurementType.VOLUME : MeasurementType.AREA;
		pinnedItems.createPinnedMeasurement(
			area.material.id,
			area.material.type,
			measurementType,
			area.material.name,
			area.height,
			undefined,
			area.style
		);
	}

	function removeFromPinnedItems() {
		pinnedItems.deletePinnedMeasurement(pinnedMeasurement!.id)
	}

	return (
		<>
			<SetNumberValueModal isOpen={modal === AreaPanelItemModal.HEIGHT}
								 initialValue={area.height}
								 onSave={handleSetHeight}
								 onClose={closeModal}
								 title={t("editor.area.setHeightModal.title")}
								 inputLabel={t("editor.area.setHeightModal.inputLabel")}/>
			<MeasurementStyleSelectorModal isOpen={modal === AreaPanelItemModal.STYLE}
										   initialStyle={area.style}
										   measurementType={type}
										   onSave={(style) => handleStyleSave(style as AreaStyle)}
										   onClose={closeModal}/>
			<ConfirmationModal isOpen={modal === AreaPanelItemModal.DELETE}
							   text={t("editor.deleteItemConfirmationModalQuestion")}
							   onConfirm={handleDelete}
							   onClose={closeModal}/>
			<div className={cls}>
				<div className={panelItemBarCls}
					 onMouseEnter={highlightPanelActions.setHighlight}
					 onMouseLeave={highlightPanelActions.clearHighlight}>
					<div className={"panel-item_bar_icon"} onClick={toggleExpand}>
						<IconArrowSmall isActive={areaActive}/>
					</div>
					<div className={"panel-item_bar_content"} onClick={toggleActivate}>
						<div className="panel-item_bar_content_label">
							{area.material.name}
						</div>
						{!!pinnedMeasurement && (
							<div className="panel-item_bar_content_pin-icon"><IconPin/></div>
						)}
						<div className="panel-item_bar_content_spacer"/>
					</div>
					<div className={"panel-item_bar_props"}>({AreaHelper.getAreaValueFormat(area, scale,
						settings?.measurementSystem)})
					</div>
					<div className={"panel-item_bar_actions"}>
						<IconVisibleButton onClick={toggleVisibility} isActive={areaActive} visible={area.visible}/>
						<Dropdown disabled={editorLocked} isActive={areaActive}>
							{isOneOff &&
								<>
									<DropdownItem label={t("common.buttons.edit")}
												  onClick={openChangeOneOffModal(t("projects.oneOffModal.title.editItem"))}/>
									<DropdownItem label={t("editor.convertToCatalogue")} onClick={openChangeMaterialModal}/>
								</>}
							{!isOneOff &&
								<>
									<DropdownItem label={t("common.buttons.edit")} onClick={openStyleModal}/>
									<DropdownItem label={t("editor.changeMaterial")} onClick={openChangeMaterialModal}/>
									<DropdownItem label={t("editor.convertToOneOff")}
												  onClick={openChangeOneOffModal(t("editor.convertToOneOff"))}/>
								</>}
							<DropdownItem
								label={t("editor.area.createDeduction")}
								onClick={createDeduction}
								disabled={!(everyFragmentClosed)}
							/>
							<DropdownItem label={t("common.buttons.delete")} onClick={openDeleteModal}/>
							<DropdownItem label={t("editor.area.setHeight")} onClick={openHeightModal}/>
							{!!pinnedMeasurement ? (
								<DropdownItem label={t("editor.panel.pinnedItems.removeFromPinnedItems")}
											  onClick={removeFromPinnedItems}/>
							) : (
								<DropdownItem label={t("editor.panel.pinnedItems.addToPinnedItems")}
											  onClick={addToPinnedItems}/>
							)}
						</Dropdown>
					</div>
				</div>
				<div className={"panel-item_content"}>
					{area.height ? <HeightItem value={area.height}
											   disabled={editorLocked}
											   onDelete={handleDeleteHeight}
											   onPropsClick={openHeightModal}/> : null}
					{area.deductions.map(deduction => {
						const formattedValue = AreaHelper.getDeductionValueFormat(
							deduction, area.height, scale, settings?.measurementSystem
						)
						return (
							<DeductionItem
								key={deduction.id}
								onDeductionClick={handleDeductionClick(deduction)}
								active={area.activeDeductionId === deduction.id}
								value={formattedValue}
								onDelete={handleDeleteDeduction(deduction)}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default AreaBasedPanelItem
