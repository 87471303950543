import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const IconSnapPoint: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M23.0954041,24.3646383 C22.7216551,24.3646383 22.38555,24.4794489 22.1247324,24.6328358 L10.547478,16.9402985 C10.6971568,16.6723307 10.8091919,16.3662457 10.8091919,16.0603904 C10.8091919,15.7921929 10.7348006,15.5249139 10.6227655,15.2952928 L21.340487,8.17634902 C21.6765921,8.48220436 22.1247324,8.673938 22.6472639,8.673938 C23.6932231,8.673938 24.5518598,7.83191734 24.5518598,6.836969 C24.514216,5.84225029 23.6932231,5 22.6472639,5 C21.6013046,5 20.7426679,5.84225029 20.7426679,6.836969 C20.7426679,7.0282434 20.7803117,7.21997704 20.854703,7.41102181 L10.0249465,14.567853 C9.72648509,14.3382319 9.31509237,14.184845 8.90459593,14.184845 C7.85863666,14.184845 7,15.0270953 7,16.021814 C7,17.0551091 7.85863666,17.858783 8.90459593,17.858783 C9.27834487,17.858783 9.61445003,17.7439724 9.87526764,17.5908152 L21.452522,25.2831228 C21.3028432,25.5513203 21.1908081,25.8571757 21.1908081,26.163031 C21.1908081,27.1963261 22.0494448,28 23.0954041,28 C24.1413633,28 25,27.1577497 25,26.163031 C25,25.1683123 24.1413633,24.3646383 23.0954041,24.3646383"]}/>
	);
};

export default IconSnapPoint
