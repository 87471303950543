import User from "../models/User";

type CustomData = Record<string, any>

export default class LoggerService {
	/**
	 * Additional field used to log info about user
	 * @private
	 */
	private static loggerUser: User | undefined;

	public static setLoggerUser(user: User | undefined) {
		LoggerService.loggerUser = user
	}

	public static logError(err: any, customData?: CustomData) {
		const getCustomData = function(): CustomData {
			const defaultData: CustomData = {
				userId: LoggerService.loggerUser?.userId,
				companyId: LoggerService.loggerUser?.companyId
			}
			return customData ? {...customData, ...defaultData} : defaultData;
		}

		if (window.rg4js) {
			window.rg4js("send", {
				error: err,
				customData: getCustomData()
			})
		}
		console.error(err)
	}
}