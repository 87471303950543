import React from "react";
import ProjectDrawingExporter from "../../../project-drawings/components/ProjectDrawingExporter";
import {CountMeasurementItem, ProjectPlanDownloadOptions} from "../../../project-drawings/features/editor/models/editor";
import {viewActions} from "../../../project-drawings/features/editor/features/view/viewSlice";
import {getStoreInstance, RootState} from "../../../project-drawings/features/editor/editorStore";
import {Provider} from "react-redux";
import {ProjectDetailContextBridge, useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import ReactDOM from "react-dom";
import {useParams} from "react-router-dom";
import {UserSettingsDataContextBridge, useUserSettingsDataContext} from "../../../../providers/UserSettingsProvider";
import {IsAnyModalOpenProvider} from "../../../../providers/IsAnyModalOpenProvider";
import {BreadcrumbsProvider} from "../../../breadcrumbs";
import {DirectProjectAccessDataProvider} from "../../../../providers/DirectProjectAccessDataProvider";
import {SymbolCacheDataContextBridge, useSymbolCacheDataContext} from "../../../../providers/SymbolCacheDataProvider";
import {countActions} from "../../../project-drawings/features/editor/features/count/countSlice";
import {lengthActions} from "../../../project-drawings/features/editor/features/length/lengthSlice";
import {areaActions} from "../../../project-drawings/features/editor/features/area/areaSlice";
import {penActions} from "../../../project-drawings/features/editor/features/pen/penSlice";
import {textActions} from "../../../project-drawings/features/editor/features/text/textSlice";
import {imageActions} from "../../../project-drawings/features/editor/features/image/imageSlice";
import {SelectedDrawingVersions} from "./types";
import {RouterWithNavigatorProvider} from "../../../navigator";

function useDrawingExporter() {

	const {projectId} = useParams<{ projectId: string }>();
	const userSettingsContext = useUserSettingsDataContext();
	const projectDetails = useProjectDetailContext()
	const symbolCache = useSymbolCacheDataContext()

	/**
	 * Generate files from given drawing versions selection
	 *
	 * When list contains only one element initState can be used to override redux state
	 * @param options
	 * @param selectedDrawingVersions
	 * @param initState
	 */
	async function generateFilesFromSelection(
		options: ProjectPlanDownloadOptions,
		selectedDrawingVersions: SelectedDrawingVersions,
		initState?: RootState
	): Promise<File[]> {
		return new Promise<File[]>((resolve, reject) => {
			const store = getStoreInstance()
			store.dispatch(viewActions.setExportInProgress(true));

			const initData = () => {
				const {dispatch} = store

				if (initState && selectedDrawingVersions.length === 1) {
					const {count, length, area, pen, text, image} = initState.undoGroup.present
					const {zoom} = initState.view
					dispatch(countActions.setCounts({value: count.countGroups}))
					dispatch(lengthActions.setLengths({value: length.lengths}))
					dispatch(areaActions.setAreas({value: area.areas}))
					dispatch(penActions.setPenGroups({value: pen.penGroups}))
					dispatch(textActions.setTextGroups({value: text.textGroups}))
					dispatch(imageActions.setImages({value: image.images}))
					dispatch(viewActions.setZoomState(zoom))

					const loadedInfos = count.countGroups
						.reduce<CountMeasurementItem[]>((prev, current) => [...prev, ...current.countItems], [])
						.map(item => ({id: item.id, loaded: false}))
						.concat((image.images).map(image => ({id: image.id, loaded: false})))

					dispatch(viewActions.setRemoteContentLoadedInfo({
						allItemsLoaded: loadedInfos.length === 0,
						loadedInfos: loadedInfos
					}))
				}
			}

			try {
				const container = document.createElement("div")
				ReactDOM.render((
					<RouterWithNavigatorProvider getNavigateTo={_history => (_path, _options) => {
						/** noop - exporter should not navigate to any page */
					}}>
						<ProjectDetailContextBridge.Provider value={projectDetails}>
							<SymbolCacheDataContextBridge.Provider value={symbolCache}>
								<UserSettingsDataContextBridge.Provider value={userSettingsContext}>
									<DirectProjectAccessDataProvider>
										<BreadcrumbsProvider>
											<IsAnyModalOpenProvider>
												<Provider store={store}>
													<ProjectDrawingExporter
														drawings={selectedDrawingVersions}
														initData={initData}
														options={options}
														onSuccess={resolve}
														projectId={projectId}
													/>
												</Provider>
											</IsAnyModalOpenProvider>
										</BreadcrumbsProvider>
									</DirectProjectAccessDataProvider>
								</UserSettingsDataContextBridge.Provider>
							</SymbolCacheDataContextBridge.Provider>
						</ProjectDetailContextBridge.Provider>
					</RouterWithNavigatorProvider>
				), container)
			}
			catch (e) {
				reject(e)
			}
		})
	}

	return {
		generateFilesFromSelection,
	}
}

export {useDrawingExporter}
