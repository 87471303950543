import React, {useReducer} from "react";
import {Status} from "../../../../../models/enums";
import Spinner from "../../../../../components/Spinner";
import DataFetchError from "../../../../../components/ui/DataFetchError";
import useCostCenterSummary from "../hooks/useCostCenterSummary";
import ProjectSummaryItemsTable from "./ProjectSummaryItemsTable";
import {useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";

interface ProjectCCsSummaryDetailsProps {
	costCenterId: string,
	loadData: boolean
}

const ProjectCCsSummaryDetails: React.FC<ProjectCCsSummaryDetailsProps> = ({costCenterId, loadData}) => {
	const [reloadCounter, forceUpdate] = useReducer(x => x + 1, 0);
	const {measurements, loadStatus: drawingSummaryLoadStatus} = useCostCenterSummary(costCenterId, loadData, reloadCounter);
	const {fitTimes} = useSyncMaterialsContext()

	return (
		<>
			{drawingSummaryLoadStatus === Status.LOADING ? <Spinner/> : null}
			{drawingSummaryLoadStatus === Status.ERROR ? <DataFetchError/> : null}
			{drawingSummaryLoadStatus === Status.SUCCESS ? (
				<ProjectSummaryItemsTable
					items={measurements}
					fitTimes={fitTimes}
					onUpdate={forceUpdate}
				/>
			) : null}
		</>
	);
};

export default ProjectCCsSummaryDetails;
