import React from "react";
import "./iconUnselectable.scss";

const IconUnselectable: React.FC<{ handleClick?: () => void }> = ({handleClick, children}) => {
	return (
		<div onClick={handleClick} className={"icon-unselectable"}>
			{children}
		</div>
	);
};

export default IconUnselectable

