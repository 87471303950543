import React, {createContext, FC, useState} from "react";

type DataType = {
	isOpen: boolean,
	setIsOpen: (value: boolean) => void
}
const IsAnyModalOpenContext = createContext<DataType | undefined>(undefined)

const IsAnyModalOpenProvider: FC = function({children}) {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<IsAnyModalOpenContext.Provider value={{isOpen, setIsOpen}}>
			{children}
		</IsAnyModalOpenContext.Provider>
	)
}

function useIsAnyModalOpenContext() {
	const context = React.useContext(IsAnyModalOpenContext)
	if (!context) {
		throw new Error("useIsAnyModalOpenContext must be used within a IsAnyModalOpenProvider")
	}
	return {isAnyModalOpen: context.isOpen}
}

/**
 * Use only to set if modal is open/closed in Modal effect hook
 */
function useIsAnyModalOpenSetter() {
	const context = React.useContext(IsAnyModalOpenContext)
	if (!context) {
		throw new Error("useIsAnyModalOpenContext must be used within a IsAnyModalOpenProvider")
	}
	return {
		setIsOpen: context.setIsOpen
	}
}

const IsAnyModalOpenContextBridge = {
	Consumer: IsAnyModalOpenContext.Consumer,
	Provider: IsAnyModalOpenContext.Provider
}

export {
	IsAnyModalOpenProvider,
	useIsAnyModalOpenContext,
	useIsAnyModalOpenSetter,
	IsAnyModalOpenContextBridge
}