import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorR: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M4,12.896l12.604,-0l-3.844,3.85l1.252,1.254l5.988,-6l-5.988,-6l-1.252,1.254l3.844,3.85l-12.604,0l0,1.792Z"]}/>
	);
};

export default AnchorR