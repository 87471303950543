import UserSettings from "../../../../../../models/UserSettings";
import {UnitsType} from "../../../../../../models/enums";
import {feetToMeters, metersToFeet} from "../../../../../../utils/ConversionUtils";
import {Vector2d} from "konva/types/types";
import {roundNumber} from "../../../../../../utils/NumberUtils";
import {BaseStageConfig, ScaleInfoState} from "../../../../../base-konva/types";

export const MAX_WIDTH = 300;
export const MIN_WIDTH = 100;
export const textHorizontalPadding = 30;
export const rectLeftPadding = 34;
export const rectRightPadding = 47;
export const containerHeight = 56;
export const defaultRightAndBottomPadding = 20;

export function calculateSizes(
	scale: number,
	unitFactor: number,
	settings: UserSettings | undefined,
	scaleInfoState: ScaleInfoState,
	cachedUnitWidth?: number
): ScaleInfoState {
	const unitFactorInMeters = settings?.measurementSystem === UnitsType.METRIC
		? unitFactor
		: feetToMeters(unitFactor)!;

	const newUnitWidth = cachedUnitWidth ?? unitFactorInMeters / scale;

	const newUnitFactor = cachedUnitWidth ?
		(settings?.measurementSystem === UnitsType.METRIC ?
			cachedUnitWidth : metersToFeet(cachedUnitWidth)) * scale :
		unitFactor

	return {
		unitWidth: newUnitWidth,
		containerWidth: newUnitWidth + rectLeftPadding + rectRightPadding,
		unitFactor: newUnitFactor,
		position: scaleInfoState.position,
		visible: scaleInfoState.visible
	};
}

export function getUnitFactorFromContainerSize(containerSize: number, settings: UserSettings | undefined, scale: number) {
	const unitWidth = containerSize - rectLeftPadding - rectRightPadding;
	const unitFactorInMeters = unitWidth * (scale || 0);
	return settings?.measurementSystem === UnitsType.METRIC ? unitFactorInMeters : metersToFeet(unitFactorInMeters)!;
}

export function getDefaultPosition(stageConfig: BaseStageConfig, containerWidth: number): Vector2d {
	const x = stageConfig.width - (containerWidth + defaultRightAndBottomPadding);
	const y = stageConfig.height - (containerHeight + defaultRightAndBottomPadding);
	return {x, y};
}

export function getScaleInfoState(
	scale: number,
	scaleInfoState: ScaleInfoState,
	settings: UserSettings | undefined,
	stageConfig: BaseStageConfig
) {
	let sizesToSet = calculateSizes(scale, scaleInfoState.unitFactor, settings, scaleInfoState);
	if (sizesToSet.containerWidth > MAX_WIDTH || sizesToSet.containerWidth < MIN_WIDTH) {
		const factorForMaxSize = getUnitFactorFromContainerSize(Math.min(MAX_WIDTH, stageConfig.width * 0.7), settings, scale);
		sizesToSet = calculateSizes(scale, roundNumber(factorForMaxSize, 0)!, settings, scaleInfoState);
		if (sizesToSet.containerWidth > MAX_WIDTH || sizesToSet.containerWidth < MIN_WIDTH) {
			sizesToSet = calculateSizes(scale, factorForMaxSize, settings, scaleInfoState);
		}
	}
	return {
		...sizesToSet,
		position: getDefaultPosition(stageConfig, sizesToSet.containerWidth),
		visible: true
	}
}