import {AttachmentDetailFolderApiDto} from "./restModels";

export class AttachmentDetailsFolder {
	constructor(
		public readonly id: string,
		public readonly name: string
	) {}

	static fromJson(json: AttachmentDetailFolderApiDto) {
		const {id, name} = json
		return new AttachmentDetailsFolder(id, name)
	}
}