import isEqual from "lodash/isEqual";
import React from "react";
import {Line, Rect} from "react-konva";
import {LineDistanceMark} from "../../components/LineDistanceMark";
import {EditorLine} from "../../models/editor";
import {ShapeConfig} from "konva/types/Shape";
import {KonvaEventObject} from "konva/types/Node";
import {ARC_LINE_TENSION, defaultMarkStyle} from "../../constants";
import {EventHandlers} from "./LengthLineKonva";
import {PointDragState} from "../../../../../base-konva/types";

export type ArcEventHandlers = {
	onMouseEnter: (evt: KonvaEventObject<Event>) => void,
	onMouseLeave: (evt: KonvaEventObject<Event>) => void,
	onDragStart: (evt: KonvaEventObject<DragEvent>) => void,
	onDragMove: (evt: KonvaEventObject<DragEvent>) => void,
	onDragEnd: (evt: KonvaEventObject<DragEvent>, lineId: string) => void,
}

type LengthLineKonvaProps = {
	line: EditorLine
	arcPointDragState: PointDragState
	style: ShapeConfig
	listening: boolean
	draggable: boolean
	eventsRef: React.MutableRefObject<EventHandlers>
	arcEventsRef: React.MutableRefObject<ArcEventHandlers>
	isLineInArcMode: boolean
}

function _LengthArcKonva({
	line,
	arcPointDragState,
	style,
	listening,
	draggable,
	eventsRef,
	arcEventsRef,
	isLineInArcMode,
}: LengthLineKonvaProps) {

	const arcPointRadius = 6.5;
	const elementSize = {
		width: arcPointRadius * 2,
		height: arcPointRadius * 2,
	}

	const rectangleStyle = {
		...elementSize,
		...defaultMarkStyle
	}
	let inheritedArcPointCoordinates = line.arcPointPosition ?? line.center

	function onMouseEnter(evt: KonvaEventObject<Event>) {
		eventsRef.current.onMouseEnter(evt)
	}

	function onMouseLeave(evt: KonvaEventObject<Event>) {
		eventsRef.current.onMouseLeave(evt)
	}

	function onDragStart(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragStart(evt)
	}

	function onDragMove(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragMove(evt)
	}

	function onDragEnd(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragEnd(evt)
	}

	function onArcPointMouseEnter(evt: KonvaEventObject<Event>) {
		arcEventsRef.current.onMouseEnter(evt)
	}

	function onArcPointMouseLeave(evt: KonvaEventObject<Event>) {
		arcEventsRef.current.onMouseLeave(evt)
	}

	function onArcPointDragStart(evt: KonvaEventObject<DragEvent>) {
		arcEventsRef.current.onDragStart(evt)
	}

	function onArcPointDragMove(evt: KonvaEventObject<DragEvent>) {
		arcEventsRef.current.onDragMove(evt)
	}

	function onArcPointDragEnd(evt: KonvaEventObject<DragEvent>) {
		arcEventsRef.current.onDragEnd(evt, line.id)
	}


	function getPoints() {
		return [
			line.from.position.x,
			line.from.position.y,
			(isLineInArcMode ? arcPointDragState.dragPointCurrentLocation?.x : null) ?? inheritedArcPointCoordinates.x,
			(isLineInArcMode ? arcPointDragState.dragPointCurrentLocation?.y : null) ?? inheritedArcPointCoordinates.y,
			line.to.position.x,
			line.to.position.y
		]
	}

	const points = getPoints();

	function onClick() {
		eventsRef.current.onClick?.(line.id)
	}

	function onTap() {
		eventsRef.current.onTap?.(line.id)
	}

	const callbacks = {
		onMouseEnter,
		onMouseLeave,
		onDragStart,
		onDragMove,
		onDragEnd
	}

	return (
		<>
			<Line
				points={points}
				{...style}
				listening={listening}
				{...callbacks}
				draggable={draggable}
				onClick={onClick}
				onTap={onTap}
				tension={ARC_LINE_TENSION}
			/>
			{isLineInArcMode ?
				<Rect {...rectangleStyle}
					  listening={listening}
					  draggable={true}
					  onMouseEnter={onArcPointMouseEnter}
					  onMouseLeave={onArcPointMouseLeave}
					  onDragStart={onArcPointDragStart}
					  onDragMove={onArcPointDragMove}
					  onDragEnd={onArcPointDragEnd}
					  x={points[2] - arcPointRadius}
					  y={points[3] - arcPointRadius}
				/> :
				<LineDistanceMark
					line={line}
					draggable={draggable}
					listening={listening}
					position={{x: points[2], y: points[3]}}
					callbacks={listening ? callbacks : {}}
					onClick={onClick}
					onTap={onTap}
				/>}
		</>
	)
}

export const LengthArcKonva = React.memo(_LengthArcKonva, isEqual)
