import React, {useState} from "react";
import {ModalTab, ModalWithTabs} from "../../../../../components/modal";
import {useTranslation} from "react-i18next";
import FileUploader from "../../../../../components/ui/FileUploader";
import {getAllowedFormats} from "../../../../project-drawings/features/editor/utils";
import {FileType, ProjectSource, Status} from "../../../../../models/enums";
import Button from "../../../../../components/ui/Button";
import "./uploadPlanTemplateModal.scss"
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {useAttachmentSelectModal} from "../import-attachment-modal/AttachmentSelectModal";
import UploadStatusPresenter from "../../../../../components/ui/UploadStatusPresenter";
import IconClose from "../../../../../components/icons/IconClose";
import InlineSpinner from "../../../../../components/InlineSpinner";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {convertPdfToImages, pdfGetDocumentError} from "../../../../../utils/PdfUtils";
import {apiInstance} from "../../../../../api/api";
import {Colors} from "../../../../../styles";
import {PlanUploadAttachment} from "../../../features/plan-upload/types";
import {checkIfAttachmentIsPdf} from "../../../utils/planTemplateUtils";

const UploadPlanTemplateModal: React.FC<{
	onClose: () => void,
	onNext: (previewSources: string[], filename: string, attachmentData: PlanUploadAttachment) => void,
}> = ({
	onClose,
	onNext,
}) => {
	const {t} = useTranslation();
	const onNextClick = async (fileDownloadUrl: string, filename: string, attachmentData: PlanUploadAttachment) => {
		try {
			const previewSources = await getPreviewSources(fileDownloadUrl, filename, attachmentData.contentType);
			onNext(previewSources, filename, attachmentData)
		}
		catch (e: any) {
			if (e.message === pdfGetDocumentError) {
				NotificationService.errorNotification(t("common.error"),
					t("common.utils.pdfUtils.convertPdfToImagesErrorDesc"));
			}
			else {
				LoggerService.logError(e);
				NotificationService.errorNotification(t("common.error"),
					t("projects.details.planTemplates.uploadPlanTemplateErrorDesc"));
			}
		}
	}

	const uploadFromDeviceTab = useUploadFromDevice({onClose, onNextClick})
	const uploadFromProjectTab = useUploadFromProject({onClose, onNextClick})

	const tabs = [uploadFromDeviceTab, uploadFromProjectTab]


	const getPreviewSources = async (fileDownloadUrl: string, filename: string, contentType?: string): Promise<string[]> => {
		const isPdf = checkIfAttachmentIsPdf(filename, contentType);
		if (isPdf) {
			return await convertPdfToImages(fileDownloadUrl);
		}
		else {
			return [fileDownloadUrl]
		}
	}

	return (
		<ModalWithTabs
			tabs={tabs}
		/>
	)
}

const supportedTypes = [
	FileType.JPG,
	FileType.JPEG,
	FileType.PNG,
	FileType.GIF,
	FileType.BMP,
	FileType.PDF,
]

function useUploadFromDevice(props: {
	onClose: () => void,
	onNextClick: (fileDownloadUrl: string, filename: string, attachmentData: PlanUploadAttachment) => Promise<void>,
}): ModalTab {
	const {onClose, onNextClick} = props
	const {t} = useTranslation();
	const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const handleClose = () => {
		onClose();
	}

	const handleUploadedFileDelete = () => {
		if (!isLoading)
			setUploadedFile(undefined)
	}

	const onNext = async () => {
		if (uploadedFile) {
			setIsLoading(true)
			try {
				const attachmentData = {
					id: (await apiInstance.attachmentApi.uploadFile(uploadedFile!)).id,
					filename: uploadedFile.name,
					contentType: uploadedFile.type
				}
				const fileURL = URL.createObjectURL(uploadedFile!);
				await onNextClick(fileURL, uploadedFile!.name, attachmentData)
			}
			catch (e) {
				NotificationService.errorNotification(t("common.error"),
					t("projects.details.planTemplates.uploadPlanTemplateErrorDesc"));
				LoggerService.logError(e);
			}
			finally {
				setIsLoading(false)
			}
		}
	}

	return {
		key: "UPLOAD_FROM_DEVICE_TAB_KEY",
		onClose: () => {},
		tabSwitchDisabled: isLoading,
		title: t("projects.details.uploadDrawingModal.title"),
		children: (
			<>
				{uploadedFile && <div className={"drawing-upload-modal_status"}>
					<UploadStatusPresenter uploadStatus={Status.SUCCESS}/>
					<span className="drawing-upload-modal_status_filename">{uploadedFile.name}</span>
					<span className={"drawing-upload-modal_status_icon"} onClick={handleUploadedFileDelete}>
						<IconClose size={22} color={Colors.SIMPROBLUE}/>
					</span>
				</div>}
				<div className={"drawing-upload-modal_subtitle"}>{t("projects.details.uploadDrawingModal.subTitle")}</div>
				<div className={"drawing-upload-modal_dropzone"}>
					<FileUploader onFileUpload={(file: File) => {setUploadedFile(file)}}
								  disabled={uploadedFile != null} accept={supportedTypes}/>
				</div>
				<div className={"drawing-upload-modal_formats"}>
					{t("projects.details.uploadDrawingModal.allowedFormats")} {getAllowedFormats(supportedTypes)}.
				</div>
			</>
		),
		buttons: (
			<>
				{isLoading && <InlineSpinner/>}
				<Button label={t("common.buttons.cancel")} variant={"optional"} disabled={isLoading} onClick={handleClose}/>
				<Button label={t("common.buttons.next")} variant={"primary"}
						onClick={onNext}
						disabled={!uploadedFile || isLoading}/>
			</>
		)
	}
}

function useUploadFromProject(props: {
	onClose: () => void,
	onNextClick: (fileDownloadUrl: string, filename: string, attachmentData: PlanUploadAttachment) => Promise<void>,
}): ModalTab {
	const {onClose, onNextClick} = props
	const {t} = useTranslation();
	const {projectDetails: {project}} = useProjectDetailContext()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const openImportAttachmentOptionLabelTranslations = {
		[ProjectSource.JOB]: t("projects.details.uploadDrawingModal.dropdownLabel.job"),
		[ProjectSource.QUOTE]: t("projects.details.uploadDrawingModal.dropdownLabel.quote")
	}

	const attachmentSelectModalData = useAttachmentSelectModal({
		project,
		onClose: onClose,
		onNext: onNextClick,
		isLoading,
		setIsLoading
	})

	return {
		tabSwitchDisabled: isLoading,
		key: "UPLOAD_FROM_PROJECT_TAB_KEY",
		onClose: () => {},
		title: project ? openImportAttachmentOptionLabelTranslations[project.source] : "",
		children: attachmentSelectModalData.children,
		buttons: attachmentSelectModalData.buttons
	}
}

export {UploadPlanTemplateModal}