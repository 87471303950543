import {useDispatch, useSelector} from "react-redux";
import {planUploadViewActions, selectPlanUploadZoomState} from "./planUploadViewSlice";
import {useBaseZoomActions} from "../../../../../base-konva/features/view";

export function usePlanUploadZoomActions() {
	const zoomState = useSelector(selectPlanUploadZoomState)
	const dispatch = useDispatch()

	return useBaseZoomActions({
		zoomState,
		setZoomState: value => {
			dispatch(planUploadViewActions.setZoomState(value))
		}
	})
}