import {createBrowserHistory} from 'history';
import {SESSION_COOKIE_NAME} from "../../features/project-drawings/features/editor/constants";

export const authorizationResponseInterceptor = (error: any) => {
	const {response} = error;
	if (response?.status === 401) {
		let url = "/error?login";
		if (!document.cookie.includes(SESSION_COOKIE_NAME)) {
			url = "/error?cookies";
		}
		createBrowserHistory().push(url);
		window.location.reload();
	}
	return Promise.reject(error)
}