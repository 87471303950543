import {AreaMeasurement_v4, DrawingItem_v4, EditorDrawingData_v3, EditorDrawingData_v4} from "../../../models/editor";
import {DrawingItemType} from "../../../../../../base-konva/enums";

export function from_v3_to_v4(schema: EditorDrawingData_v3): EditorDrawingData_v4 {
	return {
		...schema,
		schemaVersion: 4,
		items: schema.items.map(item => {
			if (item.type === DrawingItemType.AREA) {
				const areaMeasurement = item.data as AreaMeasurement_v4;
				const areaMeasurement_v4: AreaMeasurement_v4 = {...areaMeasurement, deductions: []};
				return {
					type: DrawingItemType.AREA,
					data: areaMeasurement_v4
				}
			}
			return item as DrawingItem_v4
		}),
		rotation: undefined,
	}
}
