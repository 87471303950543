import {PlanTemplatePageDataApiDto} from "./restModels";
import {PageScaleRatio} from "./DrawingDetails";

export class PlanTemplatePageData {
	constructor(
		public readonly pageNumber: number,
		public readonly pageScaleRatio: PageScaleRatio | undefined,
		public readonly attachmentId: string,
		public readonly planScale: number,
		public readonly title: string,
	) {
	}

	public static fromJson(json: PlanTemplatePageDataApiDto): PlanTemplatePageData {
		return new PlanTemplatePageData(
			json.pageNumber,
			json.pageScaleRatio ? PageScaleRatio.fromJson(json.pageScaleRatio) : undefined,
			json.attachmentId,
			json.planScale,
			json.title
		);
	}
}