import {AreaFragment, AreaMeasurement, Deduction, EditorLine, LengthFragment, Line} from "../../models/editor";
import {formatArea, formatVolume} from "../../../../../../utils/TextUtils";
import {UnitsType} from "../../../../../../models/enums";
import {Vector2d} from "konva/types/types";
import {add, getEditorLine, getMovedEditorLine} from "../../utils";
import polylabel from "polylabel";
import {KonvaAreaFill} from "./KonvaArea";
import {FillType} from "../../models/enums";
import {getId} from "../../../../../../utils";

const ClipperLib = require("js-clipper"); // add typings
const multiplier = 1000;

export class AreaHelper {

	static getArea(area: AreaMeasurement, scale: number) {
		const reducer = (prev: number, current: AreaFragment) => {
			return prev + AreaHelper.getFragmentArea(current, scale);
		}
		return area.areaFragments.reduce<number>(reducer, 0)
	};

	static getDeductionsArea(area: AreaMeasurement, scale: number) {
		const reducer = (prev: number, deduction: Deduction) => {
			return prev + AreaHelper.getFragmentArea(deduction, scale)
		}
		return area.deductions.reduce(reducer, 0)
	}

	static getFragmentArea(fragment: AreaFragment, scale: number) {
		if (fragment.closed) {
			let points = fragment.lines.map(
				line => {return {X: line.from.position.x * multiplier, Y: line.from.position.y * multiplier}});
			const simplePolygon = ClipperLib.Clipper.SimplifyPolygon(points, ClipperLib.PolyFillType.pftNonZero);
			const simplePolygonScaleData = simplePolygon.map(
				(polygons: any) => polygons.map(
					(point: any) => {return {X: point.X / multiplier * scale, Y: point.Y / multiplier * scale}}));
			return ClipperLib.JS.AreaOfPolygons(simplePolygonScaleData);
		}
		return 0;
	};

	static getUnscaledAreaValue(area: AreaMeasurement, scale: number | null): number {
		const areaValue = scale ? AreaHelper.getArea(area, 1) : 0;
		const height = (area.height && scale) ? (area.height / scale) : 1;
		return areaValue * height;
	}

	static getUnscaledDeductionValue(area: AreaMeasurement, scale: number | null): number {
		const deductionAreaValue = scale ? AreaHelper.getDeductionsArea(area, 1) : 0;
		const height = (area.height && scale) ? (area.height / scale) : 1;
		return deductionAreaValue * height
	}

	static getAreaValue(area: AreaMeasurement, scale: number | null): number {
		const areaValue = scale ? AreaHelper.getArea(area, scale) : 0;
		return areaValue * (area.height || 1);
	}

	static getFragmentAreaValue(fragment: AreaFragment, areaHeight: number | undefined, scale: number | null): number {
		const areaValue = scale ? AreaHelper.getFragmentArea(fragment, scale) : 0;
		return areaValue * (areaHeight || 1);
	}

	static getAreaValueFormat(area: AreaMeasurement, scale: number | null, unitsType: UnitsType | undefined) {
		const areaValue = AreaHelper.getAreaValue(area, scale);
		return area.height
			? formatVolume(areaValue, unitsType)
			: formatArea(areaValue, unitsType);
	}

	static getDeductionValueFormat(
		deduction: Deduction,
		areaHeight: number | undefined,
		scale: number | null,
		unitsType: UnitsType | undefined
	) {
		const deductionValue = AreaHelper.getFragmentAreaValue(deduction, areaHeight, scale)
		return areaHeight
			? formatVolume(deductionValue, unitsType)
			: formatArea(deductionValue, unitsType)
	}

	static getFragmentAreaValueFormat(
		fragment: AreaFragment,
		areaHeight: number | undefined,
		scale: number | null,
		unitsType: UnitsType | undefined
	) {
		const areaValue = AreaHelper.getFragmentAreaValue(fragment, areaHeight, scale);
		return areaHeight
			? formatVolume(areaValue, unitsType)
			: formatArea(areaValue, unitsType);
	}

	static getMovedAreas(areas: AreaMeasurement[], delta: Vector2d): AreaMeasurement[] {
		return areas.map(area => ({
			...area,
			areaFragments: area.areaFragments.map(fragment => ({
				...fragment,
				lines: fragment.lines.map(line => getMovedEditorLine(line, delta)),
				lastMouseUpPosition: fragment.lastMouseUpPosition
					? add(fragment.lastMouseUpPosition, delta)
					: fragment.lastMouseUpPosition
			})),
			deductions: area.deductions.map(deduction => ({
				...deduction,
				lines: deduction.lines.map(line => getMovedEditorLine(line, delta)),
				lastMouseUpPosition: deduction.lastMouseUpPosition
					? add(deduction.lastMouseUpPosition, delta)
					: deduction.lastMouseUpPosition
			}))
		}))
	}

	static getPoleOfInaccessibility(lines: EditorLine[]): Vector2d | null {
		if (lines.length >= 3) {
			const coordinates = [lines.map(line => ([line.from.position.x, line.from.position.y]))
				.concat(
					[[lines[lines.length - 1].to.position.x, lines[lines.length - 1].to.position.y]])]
			const poleOfInaccessibilityCoordinates = polylabel(coordinates, 1.0)
			return {x: poleOfInaccessibilityCoordinates[0], y: poleOfInaccessibilityCoordinates[1]}
		}
		return null
	}

	static isAreaLinesIntersecting<T extends Line>(lines: T[], areaToIntersect: Vector2d[]) {
		let points = lines.map(line => ({
			X: line.from.position.x * multiplier,
			Y: line.from.position.y * multiplier
		}));
		const simplePolygon = ClipperLib.Clipper.SimplifyPolygon(points, ClipperLib.PolyFillType.pftNonZero);
		let solution = new ClipperLib.Paths();
		let c = new ClipperLib.Clipper();
		c.AddPath(_prepareSelectorPath(areaToIntersect, multiplier), ClipperLib.PolyType.ptSubject, true);
		c.AddPaths(simplePolygon, ClipperLib.PolyType.ptClip, true);
		c.Execute(ClipperLib.ClipType.ctIntersection, solution);
		return solution.length !== 0;
	}

	static isLengthFragmentIntersecting(lengthFragment: LengthFragment, areaToIntersect: Vector2d[]) {
		let solution = new ClipperLib.PolyTree();
		let c = new ClipperLib.Clipper();
		let points = lengthFragment.lines.map(
			line => {return {X: line.from.position.x * multiplier, Y: line.from.position.y * multiplier}});
		points.push({
			X: lengthFragment.lines[lengthFragment.lines.length - 1].to.position.x * multiplier,
			Y: lengthFragment.lines[lengthFragment.lines.length - 1].to.position.y * multiplier
		});
		c.AddPath(points, ClipperLib.PolyType.ptSubject, false); // a line is open
		c.AddPath(_prepareSelectorPath(areaToIntersect, multiplier), ClipperLib.PolyType.ptClip, true);
		c.Execute(ClipperLib.ClipType.ctIntersection, solution);
		return solution.m_Childs.length !== 0;
	}

	static isLineIntersecting<T extends Line>(line: T, areaToIntersect: Vector2d[]) {
		let solution = new ClipperLib.PolyTree();
		let c = new ClipperLib.Clipper();
		let points = [
			{X: line.from.position.x * multiplier, Y: line.from.position.y * multiplier},
			{X: line.to.position.x * multiplier, Y: line.to.position.y * multiplier}
		];
		c.AddPath(points, ClipperLib.PolyType.ptSubject, false); // a line is open
		c.AddPath(_prepareSelectorPath(areaToIntersect, multiplier), ClipperLib.PolyType.ptClip, true);
		c.Execute(ClipperLib.ClipType.ctIntersection, solution);
		return solution.m_Childs.length !== 0;
	}

	static simplifyPolygonLines(lines: EditorLine[]): EditorLine[][] {
		const getPoints = function() {
			const fragmentLines = lines
			const positions = [fragmentLines[0].from.position]
			for (let fragmentLine of fragmentLines) {
				positions.push(fragmentLine.to.position)
			}
			return positions.map(p => {return {x: p.x, y: p.y}})
		}
		let points = getPoints().map(
			line => {return {X: line.x * multiplier, Y: line.y * multiplier}});
		const simplePolygons = ClipperLib.Clipper.SimplifyPolygon(points, ClipperLib.PolyFillType.pftNonZero);
		const simplifiedPolygons = simplePolygons.map(
			(polygons: any) => polygons.map(
				(point: any) => {return {x: point.X / multiplier, y: point.Y / multiplier}}));
		return simplifiedPolygons.map(
			(polygon: Vector2d[]) => {
				let newPolygon: EditorLine[] = [];
				for (let i = 1; i < polygon.length; i++) {
					newPolygon.push(
						getEditorLine(getId(), {id: getId(), position: polygon[i - 1]}, {id: getId(), position: polygon[i]}));
				}
				newPolygon.push(
					getEditorLine(getId(), {id: getId(), position: polygon[polygon.length - 1]},
						{id: getId(), position: polygon[0]}));

				newPolygon.forEach((value, index) => {
					const isFirst = index === 0
					const isLast = index === newPolygon.length - 1
					if (!isFirst) {
						const prev = newPolygon[index - 1]
						value.from.id = prev.to.id
					}
					if (isLast) {
						const first = newPolygon[0]
						value.to.id = first.from.id
					}
				})
				return newPolygon;
			});
	};
}

function _prepareSelectorPath(areaToIntersect: Vector2d[], multiplier: number) {
	return [
		{X: areaToIntersect[0].x * multiplier, Y: areaToIntersect[0].y * multiplier},
		{X: areaToIntersect[1].x * multiplier, Y: areaToIntersect[0].y * multiplier},
		{X: areaToIntersect[1].x * multiplier, Y: areaToIntersect[1].y * multiplier},
		{X: areaToIntersect[0].x * multiplier, Y: areaToIntersect[1].y * multiplier}
	]
}

function getDiagonalGradientCanvas(color_1: string, color_2: string) {
	const tile = document.createElement('canvas')
	const size = 20
	tile.width = tile.height = size
	const tileCtx = tile.getContext('2d');
	const gradient = tileCtx!.createLinearGradient(0, 0, tile.width, tile.height);
	gradient.addColorStop(0, color_1)
	gradient.addColorStop(0.25, color_1)
	gradient.addColorStop(0.25, color_2)
	gradient.addColorStop(0.5, color_2)
	gradient.addColorStop(0.5, color_1)
	gradient.addColorStop(0.75, color_1)
	gradient.addColorStop(0.75, color_2)
	gradient.addColorStop(1, color_2)
	tileCtx!.fillStyle = gradient
	tileCtx!.fillRect(0, 0, size, size)

	return tile
}

export function generateFillStyle(fill: KonvaAreaFill): string | CanvasPattern | null {
	if (fill.type === FillType.SOLID) {
		return fill.color
	}
	else {
		const canvas = document.createElement("canvas")
		const context = canvas.getContext("2d")
		const patternSource = getDiagonalGradientCanvas(fill.color_1, fill.color_2)
		return context?.createPattern(patternSource, "repeat") ?? null
	}
}
