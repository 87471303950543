import ProjectDetails from "../../../models/ProjectDetails";
import {ConversionSourceType} from "../../../models/enums";

export function canSyncMaterials(project: ProjectDetails | undefined): boolean {
	if (!project) return false

	switch (project.conversionSourceType) {
		case ConversionSourceType.Quote:
			return false
		default:
			return true;
	}
}