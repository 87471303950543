import React, {useState} from "react";
import "./assignToExistingCostCenterModal.scss";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../components/modal/ModalWithHeader";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import CustomSelect from "../../../../components/ui/CustomSelect";
import ModalButtons from "../../../../components/modal/ModalButtons";
import Button from "../../../../components/ui/Button";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import InlineSpinner from "../../../../components/InlineSpinner";
import LoggerService from "../../../../services/LoggerService";
import {CostCenter} from "../../../../models/CostCenter";

interface AssignToExistingCostCenterModalProps {
	onClose: () => void
	deletedCostCenter: CostCenter
}

const AssignToExistingCostCenterModal: React.FC<AssignToExistingCostCenterModalProps> = ({
		onClose,
		deletedCostCenter
	}) => {
		const {t} = useTranslation();
		const {treeData: {sections}, refreshProjectTreeAfterSimproUpdate, isServiceProject} = useProjectDetailContext()

		const [selectedSectionId, setSelectedSectionId] = useState<string | undefined>(isServiceProject ? sections[0].id : undefined)
		const [selectedCostCenterId, setSelectedCostCenterId] = useState<string | undefined>(undefined)
		const [isLoading, setIsLoading] = useState<boolean>(false)

		const handleSectionSelect = (sectionId: string) => {
			setSelectedSectionId(sectionId)
		}

		const handleCostCenterSelect = (costCenterId: string) => {
			setSelectedCostCenterId(costCenterId)
		}

		const sectionsToSelect = sections.filter(s => !s.deletedExternal);

		const costCentersToSelect = sectionsToSelect.find(s => s.id === selectedSectionId)?.costCenters.filter(
			cc => !cc.deletedExternal).map(cc => {
			return {label: cc.name, value: cc.id};
		}) || [];

		const onSave = () => {
			const movedDrawingsIds = deletedCostCenter.drawings.map(drawing => drawing.id)
			if (movedDrawingsIds && movedDrawingsIds.length > 0 && selectedCostCenterId) {
				setIsLoading(true)
				apiInstance.drawingsApi.putDrawingsToNewCostCenter(movedDrawingsIds, selectedCostCenterId)
					.then(() => {
						NotificationService.successNotification(t("common.success"),
							t(`projects.details.assignToExistingCostCenterModal.onAssignmentSuccess`));
						refreshProjectTreeAfterSimproUpdate();
						onClose();
					})
					.catch((error) => {
						LoggerService.logError(error)
						NotificationService.errorNotification(
							t("common.error"),
							t("projects.details.assignToExistingCostCenterModal.onAssignmentErrDesc")
						)
					})
					.finally(() => {
						setIsLoading(false)
					})
			}
		}

		return (
			<ModalWithHeader
				customClass={"assign-to-existing-cost-center-modal"}
				isOpen={true} title={t("projects.details.assignToExistingCostCenterModal.title")}
				onClose={onClose}>
				<div className={"assign-to-existing-cost-center-modal_container"}>
					{!isServiceProject && <div className={"assign-to-existing-cost-center-modal_container_field"}>
						<span className={"assign-to-existing-cost-center-modal_container_field_label"}>
							{t("projects.details.assignToExistingCostCenterModal.sectionSelectLabel")}
						</span>
						<CustomSelect placeholder={t("projects.details.assignToExistingCostCenterModal.sectionSelectPlaceholder")}
									  customClass="assign-to-existing-cost-center-modal_container_field_select"
									  items={sectionsToSelect.map(s => ({label: s.getLabel(), value: s.id}))}
									  onChange={handleSectionSelect}
									  value={selectedSectionId}/>
					</div>}
					<div className={"assign-to-existing-cost-center-modal_container_field"}>
						<span className={"assign-to-existing-cost-center-modal_container_field_label"}>
							{t("projects.details.assignToExistingCostCenterModal.costCenterSelectLabel")}
						</span>
						<CustomSelect placeholder={t("projects.details.assignToExistingCostCenterModal.costCenterSelectPlaceholder")}
									  customClass="assign-to-existing-cost-center-modal_container_field_select"
									  items={costCentersToSelect}
									  onChange={handleCostCenterSelect}
									  value={selectedCostCenterId}/>
					</div>
					<ModalButtons>
						{isLoading && <InlineSpinner/>}
						<Button label={t("common.buttons.cancel")}
								variant={"optional"}
								disabled={isLoading}
								onClick={onClose}/>
						<Button label={t("common.buttons.save")}
								variant={"primary"}
								disabled={!selectedSectionId || !selectedCostCenterId || isLoading}
								onClick={onSave}/>
					</ModalButtons>
				</div>
			</ModalWithHeader>
		);
	}
;

export default AssignToExistingCostCenterModal
