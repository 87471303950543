import React from "react";
import FitTime from "../../../../../../models/FitTime";
import {pdf} from "@react-pdf/renderer";
import {DrawingSummaryPDF} from "./DrawingSummaryPDF";
import {apiInstance} from "../../../../../../api/api";
import {
	deserializePlanToSummaryItems
} from "../../../../../project-drawings/features/editor/features/serialization/SerializationHelper";
import DrawingData from "../../../../../../models/DrawingData";
import {ExportMode} from "../../../../../project-drawings/features/editor/models/enums";
import Section from "../../../../../../models/Section";
import {CostCenterDrawing} from "../../../../../../models/CostCenterDrawing";
import {CostCenter} from "../../../../../../models/CostCenter";
import {DrawingType} from "../../../../../../models/enums";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";
import {SymbolCacheDataContextBridge, useSymbolCacheDataContext} from "../../../../../../providers/SymbolCacheDataProvider";
import {useDrawingExporter} from "../../../drawing-exporter";
import {AttachmentInfo} from "../../../../../../models/AttachmentInfo";

type ImgData = {
	height: number,
	width: number
}

export type DrawingFileInfo = {
	file: File
	url: string
	height: number
	width: number
	section: Section
	costCenter: CostCenter
	drawing: CostCenterDrawing
}

export function useDrawingSummaryPDF() {
	const {generateFilesFromSelection} = useDrawingExporter()
	const {settings} = useUserSettingsDataContext()
	const symbolCache = useSymbolCacheDataContext()

	async function createSummaryAttachment(
		drawingType: DrawingType,
		drawingVersionId: string,
		fitTimes: FitTime[],
		sections: Section[]
	): Promise<AttachmentInfo> {
		const drawingData = await apiInstance.drawingsApi.fetchDrawingData(drawingVersionId);
		if (drawingData.data) {
			const drawingFileInfo = await getDrawingFileInfo(sections, drawingVersionId)
			const summaryFile = await createSummaryPDF(drawingData, drawingFileInfo, fitTimes, drawingType)
			return await apiInstance.attachmentApi.uploadFile(summaryFile)
		}
		else {
			throw new Error("Missing drawing data")
		}
	}

	async function createSummaryPDF(
		drawingData: DrawingData, drawingFileInfo: DrawingFileInfo, fitTimes: FitTime[], drawingType: DrawingType
	) {
		const drawingSummaryItems = deserializePlanToSummaryItems(
			drawingData.data!, drawingData.measurements, drawingData.planScale
		);
		const summary = await pdf(
			<SymbolCacheDataContextBridge.Provider value={symbolCache}>
				<DrawingSummaryPDF
					items={drawingSummaryItems}
					fitTimes={fitTimes}
					drawingFileInfo={drawingFileInfo}
					drawingType={drawingType}
					settings={settings}
				/>
			</SymbolCacheDataContextBridge.Provider>
		).toBlob();
		return new File([summary], "summary.pdf"); // file is renamed by backend before sending to simpro
	}

	async function getDrawingFileInfo(sections: Section[], drawingVersionId: string) {
		for (let section of sections) {
			for (let costCenter of section.costCenters) {
				for (let drawing of costCenter.drawings) {
					for (let version of drawing.versions) {
						if (drawingVersionId === version.id) {
							return await new Promise<DrawingFileInfo>(async (resolve, reject) => {
								try {
									const files = await generateFilesFromSelection(
										{fileType: ExportMode.PNG},
										[{
											selectedSectionId: section.id,
											selectedCostCenterId: costCenter.id,
											selectedDrawingId: drawing.id,
											selectedDrawingName: drawing.name,
											selectedVersionId: version.id,
											selectedVersionLabel: version.version.getVersionLabel(),
											drawingType: drawing.drawingType
										}]
									)
									const [selectedVersionFile] = files
									if (selectedVersionFile) {
										const {url, height, width} = await getDrawingFileData(selectedVersionFile)
										return resolve({
											file: selectedVersionFile,
											url,
											height,
											width,
											section,
											costCenter,
											drawing
										})
									}
									else {
										return reject()
									}
								}
								catch (e) {
									return reject(e)
								}
							})
						}
					}
				}
			}
		}
		throw new Error("Drawing version id not found")
	}

	async function getSelectedDrawingFileInfo(
		file: File,
		section: Section,
		costCenter: CostCenter,
		drawing: CostCenterDrawing
	): Promise<DrawingFileInfo> {
		return new Promise<DrawingFileInfo>(async (resolve) => {
			const {url, height, width} = await getDrawingFileData(file)
			return resolve({
				file: file,
				url,
				height,
				width,
				section,
				costCenter,
				drawing
			})
		})
	}

	async function getDrawingFileData(drawingFile: File) {
		const imgBlobUrl = URL.createObjectURL(drawingFile)
		const imgData = await new Promise<ImgData>((resolve, reject) => {
			const img = document.createElement('img')
			img.src = imgBlobUrl
			img.onload = function() {
				resolve({
					height: img.height,
					width: img.width
				})
			}
			img.onerror = function() {
				reject()
			}
		})
		return {
			url: imgBlobUrl,
			...imgData
		}
	}

	async function createSelectedSummaryAttachment(
		file: File,
		section: Section,
		costCenter: CostCenter,
		drawing: CostCenterDrawing,
		selectedVersionId: string,
		drawingType: DrawingType,
		fitTimes: FitTime[]
	): Promise<File> {
		const drawingFileInfo = await getSelectedDrawingFileInfo(file, section, costCenter, drawing)
		const drawingData = await apiInstance.drawingsApi.fetchDrawingData(selectedVersionId);
		return await createSummaryPDF(drawingData, drawingFileInfo, fitTimes, drawingType)
	}

	return {
		createSummaryAttachment,
		createSelectedSummaryAttachment
	};
}
