import React, {useEffect, useState} from 'react'
import {ChromePicker, ColorResult} from 'react-color'
import "./colorPicker.scss";
import {colorHexToRGB, colorRgbToHex} from "../../../../../utils/TextUtils";
import {usePopper} from "../../../../../components/popper";
import {useInView} from "react-intersection-observer";

interface ColorPickerProps {
	color: string;
	onChange: (color: string) => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({color, onChange}) => {
	const [pickerChangeResult, setPickerChangeResult] = useState<ColorResult | null>(null);

	const handleChange = (newColor: ColorResult) => {
		setPickerChangeResult(newColor);
	};

	const rgbColor = colorHexToRGB(color);
	const {r, g, b, a} = rgbColor;

	const {inView, ref} = useInView({threshold: 0.9})
	const {renderPopper, isPopperOpen, closePopper} = usePopper({
		onClose: () => {
			if (pickerChangeResult) {
				onChange(colorRgbToHex(pickerChangeResult.rgb));
			}
		}
	})

	useEffect(() => {
		if (!inView && isPopperOpen) {
			closePopper()
		}
	}, [inView, isPopperOpen, closePopper])

	return (
		<div className="color-picker">
			{renderPopper({
				target: (
					<div className="color-picker_swatch" ref={ref}>
						{a === 0 ?
							<div className="color-picker_swatch_transparent">
								<svg viewBox={"0 0  36 16"}>
									<path fill={"black"} stroke={"#000"} strokeWidth={1} d="M0.007,-0.001l35.944,15.971"/>
									<path fill={"black"} stroke={"#000"} strokeWidth={1} d="M-0.014,15.963l36.048,-15.961"/>
								</svg>
							</div> :
							<div className="color-picker_swatch_color" style={{background: `rgba(${r}, ${g}, ${b}, ${a})`}}/>
						}
					</div>
				), content: (
					<ChromePicker
						onChange={handleChange}
						color={pickerChangeResult?.rgb ?? rgbColor}
					/>
				)
			})}
		</div>
	);
};

export default ColorPicker;
