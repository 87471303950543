import React, {useState} from "react";
import "./baseImageOptions.scss";
import {useTranslation} from "react-i18next";
import {ButtonsGroup} from "../../../../components/layout";
import IconAdd from "../../../project-drawings/features/editor/icons/IconAdd";
import {noop} from "lodash";

interface BaseImageOptionsProps {
	renderImageUploadModal: (isOpen: boolean, onCloseModal: () => void) => JSX.Element
}

export const BaseImageOptions: React.FC<BaseImageOptionsProps> = ({renderImageUploadModal}) => {

	const [isOpen, setIsOpen] = useState(false);
	const {t} = useTranslation();

	function openModal() {
		setIsOpen(true)
	}

	function closeModal() {
		setIsOpen(false)
	}

	return (
		<ButtonsGroup>
			{renderImageUploadModal(isOpen, closeModal)}
			<div className={"base-image-options"}>
				<button onClick={openModal} className={"base-image-options_add-button"}>
					<IconAdd onClick={noop}/>
					{t("editor.image.addNewImage")}
				</button>
			</div>
		</ButtonsGroup>
	)
}