export enum DrawingItemType {
	COUNT = "COUNT",
	LENGTH = "LENGTH",
	AREA = "AREA",
	PEN = "PEN",
	TEXT = "TEXT",
	IMAGE = "IMAGE",
}

export enum PageSizeFormat {
	A0 = "A0",
	A1 = "A1",
	A2 = "A2",
	A3 = "A3",
	A4 = "A4",
	A5 = "A5",
}

export enum CursorStyle {
	DEFAULT = "default",
	CROSSHAIR = "crosshair",
	MOVE = "move",
	POINTER = "pointer",
	SRESIZE = "s-resize",
	NOTALLOWED = "not-allowed",
}

export enum TextStyle {
	NORMAL = "normal",
	BOLD = "bold",
	ITALIC = "italic",
}

export enum TextAlign {
	CENTER = "center",
	LEFT = "left",
	RIGHT = "right",
}
