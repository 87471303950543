import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import TableHeaderCell from "../../../../../components/layout/table/TableHeaderCell";
import {TableColumnConfig} from "../../../../../models/interfaces";
import TableNoRecords from "../../../../../components/layout/table/TableNoRecords";
import "../../../../project-drawings/components/summary/planSummaryItemsTable.scss";
import EditableContentLabel from "../../../../../components/layout/table/EditableContentLabel";
import EditLabourModal from "./EditLabourModal";
import FitTime from "../../../../../models/FitTime";
import EditFtmModal from "./EditFtmModal";
import SummaryMeasurement from "../../../../../models/SummaryMeasurement";
import {formatLabor} from "../../../../../utils/TextUtils";
import {MaterialType} from "../../../../project-drawings/features/editor/models/enums";
import {useUserSettingsDataContext} from "../../../../../providers/UserSettingsProvider";
import {UnitsType} from "../../../../../models/enums";
import {formatDeduction, formatMarkupQty, formatValue} from "../../../../../utils/SummaryUtils";

export interface IProjectSummaryItemsTableProps {
	items: SummaryMeasurement[],
	fitTimes: FitTime[],
	onUpdate: () => void
}

const ProjectSummaryItemsTable: React.FC<IProjectSummaryItemsTableProps> = ({items, fitTimes, onUpdate}) => {
	const [itemToEditLabour, setItemToEditLabour] = useState<SummaryMeasurement | undefined>(undefined);
	const [itemToChangeFitTime, setItemToChangeFitTime] = useState<SummaryMeasurement | undefined>(undefined);
	const [sortedItems, setSortedItems] = useState<SummaryMeasurement[]>([])
	const {t} = useTranslation();
	const {settings} = useUserSettingsDataContext()
	const unitType = settings?.measurementSystem ?? UnitsType.METRIC

	const sortItems = () => {
		const sortedItems = [...items].sort((a, b) => a.materialName.localeCompare(b.materialName));
		setSortedItems([...sortedItems])
	}

	useEffect(sortItems, [items])

	const columns: TableColumnConfig<SummaryMeasurement>[] = [
		{
			id: "materialPartNumber",
			label: t("projects.summary.itemsTable.ccsTableColumns.partNumber"),
			sortable: false,
			width: "15%"
		},
		{
			id: "materialName",
			label: t("projects.summary.itemsTable.ccsTableColumns.name"),
			sortable: false,
			width: "25%"
		},
		{
			id: "laborTime",
			label: t("projects.summary.itemsTable.ccsTableColumns.labour"),
			sortable: false,
			width: "10%"
		},
		{
			id: "fitTimeId",
			label: t("projects.summary.itemsTable.ccsTableColumns.fitTimeMultiplier"),
			sortable: false,
			width: "10%"
		},
		{
			id: "markupQty",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.markupQty"),
			sortable: false,
			width: 120
		},
		{
			id: "deduction",
			label: t("projects.summary.itemsTable.drawingItemsTableColumns.deductions"),
			sortable: false,
			width: 120
		},
		{
			id: "measurementValue",
			label: t("projects.summary.itemsTable.ccsTableColumns.quantity"),
			sortable: false,
			width: "8%"
		},
		{
			id: "drawingName",
			label: t("projects.summary.itemsTable.ccsTableColumns.takeoff"),
			sortable: false,
			width: "25%"
		},
	];

	const onLabourEditSuccess = () => {
		setItemToEditLabour(undefined);
		onUpdate();
	};

	const onFitTimeChangeSuccess = () => {
		setItemToChangeFitTime(undefined);
		onUpdate();
	};

	return (
		<>
			<table className="drawing-summary-items-table">
				<thead>
				<tr>
					{columns.map(col =>
						<TableHeaderCell<SummaryMeasurement> key={col.id} id={col.id} label={col.label} width={col.width}/>
					)}
				</tr>
				</thead>
				<tbody>
				{sortedItems.length ?
					sortedItems.map(item => {
						const fitTime = fitTimes.find(ft => ft.id === item.fitTimeId);
						return (
							<tr key={item.id}>
								<td className={"break-word"}>{item.materialPartNumber}</td>
								<td className={"break-word"}>{item.materialName}</td>
								<td>
									<EditableContentLabel label={formatLabor(item.laborTime)}
														  onClick={() => setItemToEditLabour(item)}/>
								</td>
								<td>
									<EditableContentLabel label={fitTime?.getFormattedMultiplier() || "-"}
														  onClick={() => setItemToChangeFitTime(item)}
														  isNotEditable={item.materialType === MaterialType.ONE_OFF}/>
								</td>
								<td>{formatMarkupQty(item, unitType)}</td>
								<td>{formatDeduction(item, unitType)}</td>
								<td>
									{item.measurementValue
										? formatValue({...item, value: item.measurementValue}, unitType)
										: ""}
								</td>
								<td className={"break-word"}>{item.getFormattedDrawingLabel()}</td>
							</tr>
						)
					}) :
					<TableNoRecords cols={columns.length}/>
				}
				</tbody>
			</table>
			<EditLabourModal itemToEdit={itemToEditLabour}
							 onSuccess={onLabourEditSuccess}
							 onClose={() => setItemToEditLabour(undefined)}/>
			<EditFtmModal itemToEdit={itemToChangeFitTime}
						  fitTimes={fitTimes}
						  onSuccess={onFitTimeChangeSuccess}
						  onClose={() => setItemToChangeFitTime(undefined)}/>
		</>
	);
};

export default ProjectSummaryItemsTable;
