import {Store} from 'react-notifications-component';

export default class NotificationService {
	public static successNotification(title: string, message: string) {
		Store.addNotification({
			title, message,
			type: "success",
			insert: "bottom",
			container: "bottom-right",
			dismiss: {
				duration: 5000,
				showIcon: true,
			}
		})
	}

	public static errorNotification(title: string, message: string) {
		Store.addNotification({
			title, message,
			type: "danger",
			insert: "bottom",
			container: "bottom-right",
			dismiss: {
				duration: 0,
				showIcon: true,
			}
		})
	}
}