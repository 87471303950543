import React from "react";

interface IconArrowSmallProps {
	isActive?: boolean
}

const IconArrowSmall: React.FC<IconArrowSmallProps> = (props) => {
	return (
		<svg width={9} height={9} viewBox="0 0 12 12">
			<path
				fill={props.isActive ? "white" : "black"}
				d="M5.125,6l4.58,4.59l-1.41,1.41l-6,-6l6,-6l1.41,1.41l-4.58,4.59Z"/>
		</svg>
	);
};

export default IconArrowSmall
