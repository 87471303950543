import Section from "../../../../models/Section";
import FitTime from "../../../../models/FitTime";
import {CostCenter} from "../../../../models/CostCenter";
import {ExportStatus} from "../../../../models/SummariesExportStatusResult";

export type SyncMaterialsModalData = {
	takeoffSections: Section[], fitTimes: FitTime[]
}
export enum StatusType {
	SUMMARY = "SUMMARY",
	MEASUREMENT = "MEASUREMENT",
	RESULT = "RESULT"
}

export type CostCenterExportStatusItem = {
	costCenter: CostCenter,
	exportSummaryStatus: ExportStatus,
	exportMeasurementStatus: ExportStatus,
	progress: number,
	errors: string[],
	statusType: StatusType
}

export type CostCenterExportStatuses = CostCenterExportStatusItem[]