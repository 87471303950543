import React from "react";
import {get} from "../../utils/ClassNameUtils";
import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import "./singleFileDropzone.scss";
import {useTranslation} from "react-i18next";

interface SingleFileDropzoneProps {
	onDrop: <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void,
	accept: string,
	text?: string,
	disabled?: boolean
}

const SingleFileDropzone: React.FC<SingleFileDropzoneProps> = ({onDrop, accept, text, disabled}) => {
	const {t} = useTranslation();
	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, accept, multiple: false, disabled});

	return (
		<div {...getRootProps()} className={get({
			"dropzone": true,
			"active-drag": isDragActive,
			"disabled": !!disabled
		})}>
			<input {...getInputProps()}/>
			<p>
				{text ?? t("common.dropzoneInfo")}
			</p>
		</div>
	);
};

export default SingleFileDropzone;
