import React from "react";
import "./tabs.scss";

interface TabsProps {
	className?: string;
}

const Tabs: React.FC<TabsProps> = ({className, children}) => {
	const cls = [
		"tabs",
		className && className
	].filter(Boolean).join(" ");

	return (
		<div className={cls}>{children}</div>
	);
};

export default Tabs
