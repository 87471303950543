import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Status} from "../../../models/enums";
import {useInvalidateSymbolQueries} from "./useInvalidateSymbolQueries";
import {OnFailure} from "../../../utils/CallbackUtils";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";

export function useChangeSystemPackVisibility() {
	const {t} = useTranslation();
	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {invalidateSymbolQueries} = useInvalidateSymbolQueries()

	const changeSystemPackVisibility = (systemPackId: string, visible: boolean, onFailure: OnFailure) => {
		setSaveStatus(Status.LOADING);
		apiInstance.symbolsApi.putSystemPackVisibility(systemPackId, visible)
			.then(() => {
				invalidateSymbolQueries()
				setSaveStatus(Status.SUCCESS);
			})
			.catch(err => {
				setSaveStatus(Status.ERROR);
				LoggerService.logError(err);
				onFailure(err);
				NotificationService.errorNotification(t("common.error"),
					t("settings.tabs.symbols.packs.systemPackToggleVisibilityErrorDesc"));
			});
	};

	return {changeSystemPackVisibility, saveStatus}
}