import {ToolHookResult} from "../../models/editor";
import React, {useState} from "react";
import {setActiveOnTopLayer} from "../../utils";
import {EditorTool} from "../../models/enums";
import {useSelector} from "react-redux";
import {selectActiveImageId} from "./imageSlice";
import {ImageElement} from "./ImageElement";
import {useSelectionCleaner} from "../../hooks/select";
import {getId} from "../../../../../../utils";
import {ImageData} from "../../../../../base-konva/types";


export function useImage(images: ImageData[]): ToolHookResult {

	const [hookRenderId] = useState(getId());
	const activeImageId = useSelector(selectActiveImageId);

	useSelectionCleaner(EditorTool.IMAGE)

	function render() {
		return setActiveOnTopLayer(images, activeImageId).map(image => (
			<ImageElement key={image.id} imageData={image}/>
		))
	}

	return {
		id: hookRenderId,
		render,
		tool: EditorTool.IMAGE,
		callbacks: {}
	}
}