import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Konva from "konva";
import {planUploadClipActions, selectClipSelectionState} from "../features/clip/planUploadClipSlice";
import {SelectionToolBox} from "./SelectionToolBox";

type ClipToolBoxProps = {
	whiteCanvasRef: React.RefObject<Konva.Rect>
}
export const ClipToolBox: React.FC<ClipToolBoxProps> = ({whiteCanvasRef}) => {
	const clipSelection = useSelector(selectClipSelectionState)
	const dispatch = useDispatch();

	function onCancel() {
		dispatch(planUploadClipActions.setSelection(undefined))
	}

	function onConfirmSelection() {
		dispatch((planUploadClipActions.confirmClipArea()))
	}


	return (
		<SelectionToolBox whiteCanvasRef={whiteCanvasRef} selection={clipSelection} onCancel={onCancel}
						  onConfirmSelection={onConfirmSelection}/>
	)
};
