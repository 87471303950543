import React, {useState} from "react";
import Modal from "./Modal";
import ModalTitle from "./ModalTitle";
import ModalButtons from "./ModalButtons";
import "./modalWithTabs.scss"
import {get} from "../../utils/ClassNameUtils";

export type ModalTab = {
	key: string
	title: string
	children: React.ReactNode
	buttons: React.ReactNode
	onClose: () => void
	tabSwitchDisabled: boolean
}

const ModalWithTabs: React.FC<{
	tabs: ModalTab[]
}> = ({tabs}) => {
	const [activeIndex, setActiveIndex] = useState(0);
	const activeTab = tabs[activeIndex] ? tabs[activeIndex] : null

	function handleTabChange(index: number) {
		return function() {
			if (!activeTab?.tabSwitchDisabled)
				setActiveIndex(index)
		}
	}

	return activeTab ? (
		<Modal
			isOpen={true}
			onClose={activeTab.onClose}
			customClass={"modal-with-tabs"}
		>
			<div className="modal-with-tabs_tabs">
				{tabs.map((tab, index) => (
					<button
						key={tab.key}
						className={get({
							"active": index === activeIndex,
							"disabled": index !== activeIndex && tabs.some(tab => tab.tabSwitchDisabled)
						})}
						onClick={handleTabChange(index)}
					>
						{tab.title}
					</button>
				))}
			</div>
			<div className="modal-with-tabs_content">
				<ModalTitle>{activeTab.title}</ModalTitle>
				<div className="modal-with-tabs_content_children">
					{activeTab.children}
				</div>
				<ModalButtons>{activeTab.buttons}</ModalButtons>
			</div>
		</Modal>
	) : null
}

export {ModalWithTabs}