import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {KonvaEventObject} from "konva/types/Node";
import {Vector2d} from "konva/types/types";
import {areaActions, selectActiveAreaId} from "./areaSlice";
import {canCloseArea, canCloseDeduction, setActiveOnTopLayer} from "../../utils";
import {AreaMeasurement, ToolHookResult} from "../../models/editor";
import {useSnapToPoint} from "../length/snapUtils";
import {selectActiveTool} from "../config/configSlice";
import {EditorTool} from "../../models/enums";
import {useKeyboardOnEditor} from "../../hooks/useKeyboardOnEditor";
import {selectSnappingState} from "../view/viewSlice";
import {AreaElement} from "./AreaElement";
import {useSelectionCleaner} from "../../hooks/select";
import {useDoubleClick} from "../../hooks/useDoubleClick";
import {getId} from "../../../../../../utils";
import {KonvaEventType} from "../../../../../base-konva/types";
import {getPointerPosition, getSnappedPosition} from "../../../../../base-konva/utils";

type UseAreaProps = {
	isPointerOverLayer: boolean,
	areas: AreaMeasurement[],
	hookTool: EditorTool,
}

export function useArea({isPointerOverLayer, areas, hookTool}: UseAreaProps): ToolHookResult {
	const {snapToPoint} = useSnapToPoint()
	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const activeTool = useSelector(selectActiveTool);
	const snapping = useSelector(selectSnappingState);
	const activeAreaId = useSelector(selectActiveAreaId);
	const activeArea = areas.find(area => area.id === activeAreaId);
	const [pointerLocation, setPointerLocation] = useState<Vector2d>({x: -50, y: -50});

	function handleCloseArea() {
		if (activeArea && canCloseArea(activeArea)) {
			dispatch(areaActions.closeArea({area: activeArea}));
		}
	}

	function handleCloseActiveDeduction() {
		if (activeArea && activeArea.activeDeductionId !== null) {
			const deduction = activeArea.deductions.find(d => d.id === activeArea.activeDeductionId)
			if (deduction && canCloseDeduction(deduction)) {
				dispatch(areaActions.closeDeduction({
					areaId: activeArea.id,
					deductionId: deduction.id
				}));
			}
		}
	}

	useSelectionCleaner(hookTool)

	useKeyboardOnEditor({
		when: activeTool === hookTool,
		keyUpMap: {
			"Enter": closeShape,
			"Escape": cancelDeduction
		}
	})

	function cancelDeduction() {
		if (activeArea && activeArea.activeDeductionId !== null) {
			const deduction = activeArea.deductions.find(
				deduction => deduction.id === activeArea.activeDeductionId
			)
			if (deduction && !deduction.closed) {
				//Cancel deduction
				dispatch(areaActions.deleteDeduction({
					areaId: activeArea.id,
					deductionId: deduction.id
				}))
			}
		}
	}

	function onSingleClick(event: KonvaEventObject<KonvaEventType>) {
		if (activeArea && activeArea.activeDeductionId !== null) {
			handleDeductionSingleClick(event)
		}
		else {
			handleHookToolSingleClick(event)
		}
	}

	function handleHookToolSingleClick(event: KonvaEventObject<KonvaEventType>) {
		if (areas.length === 0) {
			return;
		}

		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			const active = areas.find(area => area.id === activeAreaId);
			if (active) {
				const fragment = active.areaFragments.find(f => f.id === active.activeAreaFragmentId)
				if (fragment) {
					const useSnapPosition = snapping.angle && fragment.lastMouseUpPosition != null;
					let snapToPointData = null;
					if (snapping.point)
						snapToPointData = snapToPoint(mousePosition);
					if (snapToPointData && snapToPointData.isClosing)
						dispatch(areaActions.closeArea({area: active}));
					else
						dispatch(areaActions.addPointToActiveFragment
						(snapToPointData
							? snapToPointData.snapTo
							: useSnapPosition
								? getSnappedPosition(fragment.lastMouseUpPosition!, mousePosition)
								: mousePosition
						))
				}
				else {
					dispatch(areaActions.startNewAreaFragment(mousePosition))
				}
			}
		}
	}

	function handleDeductionSingleClick(event: KonvaEventObject<KonvaEventType>) {
		if (!activeArea || activeArea.deductions.length === 0) {
			return;
		}

		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			const deduction = activeArea.deductions.find(d => d.id === activeArea.activeDeductionId)
			if (deduction) {
				const useSnapPosition = snapping.angle && deduction.lastMouseUpPosition != null;
				let snapToPointData = null;
				if (snapping.point) {
					snapToPointData = snapToPoint(mousePosition);
				}
				if (snapToPointData && snapToPointData.isClosing) {
					dispatch(areaActions.closeDeduction({areaId: activeArea.id, deductionId: deduction.id}));
				}
				else {
					const pointPosition = snapToPointData
						? snapToPointData.snapTo
						: useSnapPosition
							? getSnappedPosition(deduction.lastMouseUpPosition!, mousePosition)
							: mousePosition

					dispatch(areaActions.addPointToDeduction({
						areaId: activeArea.id,
						deductionId: deduction.id,
						pointPosition
					}))
				}
			}
		}
	}


	function closeShape() {
		if (activeArea && activeArea.activeDeductionId !== null) {
			handleCloseActiveDeduction()
		}
		else {
			handleCloseArea();
		}
	}

	const {onMouseUp} = useDoubleClick({onSingleClick, onDoubleClick: closeShape});

	function onMouseMove(event: KonvaEventObject<KonvaEventType>) {
		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			setPointerLocation(mousePosition);
		}
	}

	function render() {
		return setActiveOnTopLayer(areas, activeAreaId).map(area => (
			<AreaElement
				key={area.id}
				area={area}
				hookTool={hookTool}
				isPointerOverLayer={isPointerOverLayer}
				pointerLocation={area.id === activeAreaId ? pointerLocation : undefined}/>
		))
	}

	return {
		id: hookRenderId,
		render,
		tool: hookTool,
		callbacks: {
			onMouseUp,
			onMouseMove
		}
	}
}
