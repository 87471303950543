import {useTranslation} from "react-i18next";
import TextInput from "../../../../../components/TextInput";
import React from "react";
import {useMaterialChangeOneOffData} from "../../features/material-change/hooks/useMaterialChangeOneOffData";

export function NameInput({name, setName}: ReturnType<typeof useMaterialChangeOneOffData>["nameInput"]) {
	const {t} = useTranslation();
	return (
		<TextInput value={name || ""}
				   onChange={(evt) => setName(evt.target.value)}
				   className="one-off-modal_name-input"
				   placeholder={t("projects.oneOffModal.inputPlaceholder")}/>
	)
}