import React from "react";
import "./settingsSymbols.scss";
import PageHeader from "../../../components/layout/page/PageHeader";
import {useTranslation} from "react-i18next";
import PageHeaderButtons from "../../../components/layout/page/PageHeaderButtons";
import Button from "../../../components/ui/Button";
import PageContent from "../../../components/layout/page/PageContent";
import SymbolSystemPacksTable from "./SymbolSystemPacksTable";
import {paths, useNavigator} from "../../navigator";

const DefaultSymbolPacks: React.FC = () => {

	const {t} = useTranslation();
	const {navigateTo} = useNavigator()

	return (
		<>
			<PageHeader>
				<div className="settings-symbols_breadcrumbs">
					<span className="settings-symbols_breadcrumbs_current-item">{t("settings.symbolSettings.systemPacks")}</span>
				</div>
				<PageHeaderButtons>
					<Button
						label={t("common.buttons.cancel")}
						onClick={() => { navigateTo(paths.root) }}
						variant={"optional"}
					/>
				</PageHeaderButtons>
			</PageHeader>
			<PageContent>
				<SymbolSystemPacksTable/>
			</PageContent>
		</>
	);
};

export default DefaultSymbolPacks;
