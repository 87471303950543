import {KonvaEventObject} from "konva/types/Node";
import {Vector2d} from "konva/types/types";
import {useRef} from "react";
import {distance} from "../utils";
import {LONG_TOUCH_DISTANCE_THRESHOLD, LONG_TOUCH_DURATION} from "../constants";

export function useLongTouch(onLongTouch?: (evt: KonvaEventObject<TouchEvent>, touchPosition: Vector2d) => void) {
	const timer = useRef<ReturnType<typeof setTimeout>>();
	const touchStart = useRef<Vector2d>({x: 0, y: 0})

	function onTouchStart(evt: KonvaEventObject<TouchEvent>) {
		const touch = evt.evt.touches.item(0)
		if (touch) {
			const {clientX, clientY} = touch
			touchStart.current = {x: clientX, y: clientY}
			timer.current = setTimeout(() => {
				onLongTouch?.(evt, touchStart.current)
			}, LONG_TOUCH_DURATION)
		}
	}

	function onTouchMove(evt: KonvaEventObject<TouchEvent>) {
		const touch = evt.evt.touches.item(0)
		if (touch) {
			const {clientX, clientY} = touch
			if (distance(touchStart.current, {x: clientX, y: clientY}) > LONG_TOUCH_DISTANCE_THRESHOLD) {
				clearTimer()
			}
		}
	}

	function onTouchEnd(_evt: KonvaEventObject<TouchEvent>) {
		clearTimer()
	}

	function clearTimer() {
		if (timer.current) {
			clearTimeout(timer.current)
		}
	}

	return {
		events: {
			onTouchStart,
			onTouchMove,
			onTouchEnd
		},
		cancel: clearTimer
	}
}