import React from "react";
import "./filePreviewModal.scss";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import "./planTemplateDeleteModal.scss"
import {apiInstance} from "../../../../../api/api";
import {PlanUploadData} from "../../../features/plan-upload/types";
import {useParams} from "react-router-dom";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";

type PlanTemplateDeleteModalProps = {
	onClose: () => void,
	planTemplate: PlanUploadData,
	refreshPlanTemplates: () => void
}

const PlanTemplateDeleteModal: React.FC<PlanTemplateDeleteModalProps> = ({onClose, planTemplate, refreshPlanTemplates}) => {
	const {t} = useTranslation();
	const {projectId} = useParams<{ projectId: string }>();

	const onConfirm = async () => {
		try {
			await apiInstance.projectsApi.deletePlanTemplate(projectId, planTemplate.id)
			refreshPlanTemplates()
			onClose();
			NotificationService.successNotification(
				t("common.success"),
				t("projects.details.planTemplates.deletePlanTemplateModal.deleteSuccess")
			)
		}
		catch (e) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.deletePlanTemplateModal.deleteErrorDesc"));
			LoggerService.logError(e);
		}

	}

	return (
		<ModalWithHeader customClass={"plan-template-delete-modal"} isOpen={true}
						 title={t("projects.details.planTemplates.deletePlanTemplateModal.title")}
						 onClose={onClose}>
			<div className={"plan-template-delete-modal_content"}>
				<div className={"plan-template-delete-modal_content_message"}>
					{t("projects.details.planTemplates.deletePlanTemplateModal.message")}
				</div>
				<ModalButtons>
					<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
					<Button label={t("common.buttons.ok")}
							variant={"primary"}
							onClick={onConfirm}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	);
};

export default PlanTemplateDeleteModal;
