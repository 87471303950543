import {Status} from "../../../models/enums";
import {useState} from "react";
import {apiInstance} from "../../../api/api";
import NotificationService from "../../../services/NotificationService";
import LoggerService from "../../../services/LoggerService";
import {useTranslation} from "react-i18next";
import {OnSuccess} from "../../../utils/CallbackUtils";
import {useInvalidateSymbolQueries} from "./useInvalidateSymbolQueries";

interface UseRenameSymbolPackHook {
	saveStatus: Status,
	renameSymbolPack: (newName: string) => void,
}

function useRenameSymbolPack(symboPackId: string, onSuccess: OnSuccess): UseRenameSymbolPackHook {

	const {t} = useTranslation();

	const [saveStatus, setSaveStatus] = useState(Status.IDLE);
	const {invalidateSymbolQueries}= useInvalidateSymbolQueries();

	function renameSymbolPack(newName: string) {
		setSaveStatus(Status.LOADING);
		apiInstance.symbolsApi.putSymbolPack(newName, symboPackId)
			.then(() => {
				invalidateSymbolQueries();
				setSaveStatus(Status.SUCCESS);
				NotificationService.successNotification(t("common.success"),
					t("settings.tabs.symbols.packs.dropdown.rename.renameSuccessDesc"));
				onSuccess();
			})
			.catch(err => {
				setSaveStatus(Status.ERROR);
				NotificationService.errorNotification(t("common.error"),
					t("settings.tabs.symbols.packs.dropdown.rename.renameErrorDesc"));
				LoggerService.logError(err);
			});
	}

	return {saveStatus, renameSymbolPack}
}

export default useRenameSymbolPack;
