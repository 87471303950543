import {useDispatch, useSelector} from "react-redux";
import {DrawingItemType} from "../../../../base-konva/enums";
import {useEffect, useRef, useState} from "react";
import {planUploadViewActions, selectPlanUploadSelectionState} from "../features/view/planUploadViewSlice";
import {PlanUploadEditorTool} from "../types";
import {selectPlanUploadActiveTool} from "../features/config/planUploadConfigSlice";
import {selectionStyle} from "../../../../base-konva/constants";
import {SelectedItem} from "../../../../base-konva/types";

export function usePlanUploadSelectSetter() {
	const dispatch = useDispatch();

	function setSelection(type: DrawingItemType, id: string, fragmentId?: string, subItemsIds?: string[], actionId?: string) {
		dispatch(planUploadViewActions.selectItem({id: id, subIds: subItemsIds, type: type, fragmentId, actionId}));
	}

	function clearSelection() {
		dispatch(planUploadViewActions.clearSelection({}));
	}

	return {
		setSelection,
		clearSelection,
	}
}

export function usePlanUploadSelectionCleaner(tool: PlanUploadEditorTool) {
	const activeTool = useSelector(selectPlanUploadActiveTool);
	const selectActions = usePlanUploadSelectSetter();
	const selectActionsRef = useRef(selectActions)

	useEffect(() => {
		const selectActions = selectActionsRef.current
		if (activeTool !== tool) {
			selectActions.clearSelection()
		}
	}, [activeTool, tool, selectActionsRef]);
}

export function usePlanUploadSelectedItem(itemId: string) {
	const [isHover, setIsHover] = useState(false);
	const selection = useSelector(selectPlanUploadSelectionState);

	useEffect(() => {
		setIsHover(selection.some(item => item.itemId === itemId));
	}, [selection, itemId]);

	return {
		isItemHover: isHover
	}
}

export function usePlanUploadGetSelectionShapeStyle() {
	const selection = useSelector(selectPlanUploadSelectionState);

	function getSelectionStyle(isSelected: (selected: SelectedItem[]) => boolean) {
		return isSelected(selection) ? selectionStyle : {}
	}

	return {
		getSelectionStyle
	}
}
