import React from "react";
import {useTranslation} from "react-i18next";
import {TextButton} from "../../../../components/ui/TextButton";
import "./emptyTreeRowLabel.scss"

type EmptyTreeRowLabelProps = {
	textBeforeButton: string;
	textAfterButton: string;
	onClick: () => void;
}

export const EmptyTreeRowLabel: React.FC<EmptyTreeRowLabelProps> = ({textBeforeButton, textAfterButton, onClick}) => {
	const {t} = useTranslation()

	return (
		<div className="empty-tree-row-label">
			{textBeforeButton}
			<TextButton onClick={onClick}>
				{t("common.buttons.create")}
			</TextButton>
			{textAfterButton}
		</div>
	)
}