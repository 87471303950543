export function initWalkMe() {
	if (!window._walkmeConfig) {
		const walkme = document.createElement('script');
		walkme.type = 'text/javascript';
		walkme.async = true;
		walkme.src =
			'https://cdn.walkme.com/users/085d27a9135e4a1997f381187ccb511a/walkme_085d27a9135e4a1997f381187ccb511a_https.js';
		const s = document.getElementsByTagName('script')[0];
		if (s.parentNode) {
			s.parentNode.insertBefore(walkme, s);
			window._walkmeConfig = {smartLoad: true};
			removeWalkMePlayer();
		}
	}
}

export function removeWalkMe() {
	if (window._walkMe) {
		window._walkMe.removeWalkMe();
	}
	window._walkmeConfig = undefined;
}

export function removeWalkMePlayer() {
	if (window.WalkMePlayerAPI) {
		window.WalkMePlayerAPI.removePlayer();
	}
	else {
		setTimeout(removeWalkMePlayer, 50);
	}
}
