import {BOTTOM_POINT, LEFT_POINT, PointDragState, TOP_POINT, Vertices} from "../types";
import {Vector2d} from "konva/types/types";

export function getDraggingVertices(vertices: Vertices, pointDragState: PointDragState): Vertices {
	if (pointDragState.dragPointCurrentLocation) {
		if (pointDragState.dragPointId === TOP_POINT) {
			return {
				...vertices,
				topLeft: {x: vertices.topLeft.x, y: pointDragState.dragPointCurrentLocation.y},
				topRight: {x: vertices.topRight.x, y: pointDragState.dragPointCurrentLocation.y}
			}
		}
		else if (pointDragState.dragPointId === BOTTOM_POINT) {
			return {
				...vertices,
				bottomLeft: {x: vertices.bottomLeft.x, y: pointDragState.dragPointCurrentLocation.y},
				bottomRight: {x: vertices.bottomRight.x, y: pointDragState.dragPointCurrentLocation.y}
			}
		}
		else if (pointDragState.dragPointId === LEFT_POINT) {
			return {
				...vertices,
				topLeft: {x: pointDragState.dragPointCurrentLocation.x, y: vertices.topLeft.y},
				bottomLeft: {x: pointDragState.dragPointCurrentLocation.x, y: vertices.bottomLeft.y}
			}
		}
		else {
			return {
				...vertices,
				topRight: {x: pointDragState.dragPointCurrentLocation.x, y: vertices.topRight.y},
				bottomRight: {x: pointDragState.dragPointCurrentLocation.x, y: vertices.bottomRight.y}
			}
		}
	}
	else {
		return vertices
	}
}

export function createTopLeftOrientedVertices(start: Vector2d, end: Vector2d): Vertices {
	if (start.x < end.x && start.y < end.y) {
		return {
			topLeft: start,
			topRight: {x: end.x, y: start.y},
			bottomLeft: {x: start.x, y: end.y},
			bottomRight: end
		}
	}
	else if (start.x < end.x && start.y > end.y) {
		return {
			topLeft: {x: start.x, y: end.y},
			topRight: end,
			bottomLeft: start,
			bottomRight: {x: end.x, y: start.y}
		}
	}
	else if (start.x > end.x && start.y > end.y) {
		return {
			topLeft: end,
			topRight: {x: start.x, y: end.y},
			bottomLeft: {x: end.x, y: start.y},
			bottomRight: start,
		}
	}
	else {
		return {
			topLeft: {x: end.x, y: start.y},
			topRight: start,
			bottomLeft: end,
			bottomRight: {x: start.x, y: end.y},
		}
	}
}