import {Group, Label, Tag, Text, Transformer} from "react-konva";
import {colorHexToRGB} from "../../../../utils/TextUtils";
import {Colors} from "../../../../styles";
import {TEXT_TOOL_DEFAULT_PADDING} from "../../../project-drawings/features/editor/constants";
import React from "react";
import Konva from "konva";
import isEqual from "lodash/isEqual";
import {ShapeConfig} from "konva/types/Shape";
import Vector2d = Konva.Vector2d;
import {TextToolStyle} from "../../types";

export type TextEventHandlers = {
	onDragEnd: (evt: Konva.KonvaEventObject<Event>) => void,
	onTransformEnd: (evt: Konva.KonvaEventObject<Event>) => void,
	onTransformStart: () => void,
	onMouseEnter: () => void,
	onMouseLeave: () => void,
	onClick: () => void,
	onTap: () => void,
	onDblClick?: () => void,
	onDBLTap?: () => void,
}

type BaseTextElementKonvaProps = {
	visible: boolean,
	textRef: React.RefObject<Konva.Label>,
	position: Vector2d,
	rotation: number,
	isEditing: boolean,
	isSelected: boolean,
	eventsRef: React.MutableRefObject<TextEventHandlers>,
	text: string,
	styles: TextToolStyle & ShapeConfig,
	id: string,
	showTransformer: boolean,
	transformerRef: React.RefObject<Konva.Transformer>
}

const _BaseTextElementKonva = function({
	visible,
	textRef,
	position,
	rotation,
	isEditing,
	isSelected,
	eventsRef,
	text,
	styles,
	id,
	showTransformer,
	transformerRef,
}: BaseTextElementKonvaProps) {

	function onMouseEnter() {
		eventsRef.current.onMouseEnter()
	}

	function onMouseLeave() {
		eventsRef.current.onMouseLeave()
	}

	function onClick() {
		eventsRef.current.onClick()
	}

	function onTap() {
		eventsRef.current.onTap()
	}

	function onDblClick() {
		eventsRef.current.onDblClick?.()
	}

	function onDblTap() {
		eventsRef.current.onDBLTap?.()
	}

	function onDragEnd(evt: Konva.KonvaEventObject<Event>) {
		eventsRef.current.onDragEnd(evt)
	}

	function onTransformEnd(evt: Konva.KonvaEventObject<Event>) {
		eventsRef.current.onTransformEnd(evt)
	}

	function onTransformStart() {
		eventsRef.current.onTransformStart()
	}

	return (
		<Group visible={visible}>
			<Label
				ref={textRef}
				x={position.x} y={position.y}
				rotation={rotation}
				opacity={isEditing ? 0 : 1}
				draggable={isSelected}
				onDragEnd={onDragEnd}
				onTransformEnd={onTransformEnd}
				onTransformStart={onTransformStart}
			>
				{colorHexToRGB(styles.backgroundFill ? styles.backgroundFill : Colors.TRANSPARENT).a !== 0 &&
					<Tag fill={styles.backgroundFill}/>}
				<Text
					id={`text_element_${id}`}
					text={text}
					{...styles}
					onMouseEnter={onMouseEnter}
					onMouseLeave={onMouseLeave}
					onClick={onClick}
					onTap={onTap}
					onDblClick={onDblClick}
					onDBLTap={onDblTap}
					padding={TEXT_TOOL_DEFAULT_PADDING}
					align={styles.align}
				/>
			</Label>
			{showTransformer ? (
				<Transformer
					name={"transformer"}
					ref={transformerRef}
					rotateEnabled={true}
					resizeEnabled={true}
					enabledAnchors={[
						"top-left",
						"top-right",
						"bottom-left",
						"bottom-right"
					]}
					rotationSnaps={[0, 90, 180, 270]}
					boundBoxFunc={(oldBox, newBox) => {
						// limit resize
						if (newBox.width < 5 || newBox.height < 5) {
							return oldBox;
						}
						return newBox;
					}}
				/>
			) : null}
		</Group>
	);
}

export const BaseTextElementKonva = React.memo(_BaseTextElementKonva, isEqual)