import React, {useRef, useState} from "react";
import {KonvaEventObject} from "konva/types/Node";
import {useDispatch, useSelector} from "react-redux";
import {getId} from "../../../../../../utils";
import {KonvaEventType, TextGroup} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {PlanUploadEditorTool, PlanUploadToolHookResult} from "../../types";
import {selectPlanUploadPanInProgress} from "../view/planUploadViewSlice";
import {planUploadTextActions, selectPlanUploadActiveTextGroupId} from "./planUploadTextSlice";
import {PlanUploadTextElement} from "./PlanUploadTextElement";
import {usePlanUploadSelectionCleaner} from "../../hooks/planUploadSelect";

export function usePlanUploadText(textGroups: TextGroup[]): PlanUploadToolHookResult {

	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const isTransformMode = useRef(false);
	const panInProgress = useSelector(selectPlanUploadPanInProgress);
	const activeTextGroupId = useSelector(selectPlanUploadActiveTextGroupId)
	const isEditing = Boolean(
		textGroups.find(group => group.id === activeTextGroupId)
			?.textItems.find(item => item.isEditing)
	);

	usePlanUploadSelectionCleaner(PlanUploadEditorTool.TEXT)

	function onMouseUp(evt: KonvaEventObject<KonvaEventType>) {
		if (isTransformMode.current) {
			isTransformMode.current = false
		}
		else {
			// TextElement inner Text element has set id contain "text_element_" string,
			// We are checking if click was on the one of the previous element and prevent from
			// adding overlapping elements.
			if (!(/text_element_/.test(evt.target.attrs.id) || isEditing || panInProgress)) {
				if (textGroups.length === 0 || textGroups.every(group => group.id !== activeTextGroupId)) {
					dispatch(planUploadTextActions.unselectAllTextItems())
					dispatch(planUploadTextActions.addTextGroup())
				}

				const pointerPosition = getPointerPosition(evt);
				if (pointerPosition) {
					dispatch(planUploadTextActions.unselectAllTextItems())
					dispatch(planUploadTextActions.addTextElement(pointerPosition))
				}
			}
		}
	}

	function render() {
		return textGroups.map(group => (
			group.textItems.map((item) => (
				<PlanUploadTextElement key={item.id}
									   textItem={item} textGroup={group}
									   isTransformMode={isTransformMode}/>
			))
		));
	}

	return {
		id: hookRenderId,
		render,
		tool: PlanUploadEditorTool.TEXT,
		callbacks: {
			onMouseUp
		}
	}
}
