import React, {useEffect, useState} from "react";
import {useUserSettingsDataContext} from "../../../providers/UserSettingsProvider";
import {Status} from "../../../models/enums";
import {HiddenEditor} from "../features/editor/HiddenEditor";
import {SelectedDrawingVersion, SelectedDrawingVersions} from "../../project-details/features/drawing-exporter";
import {ProjectPlanDownloadOptions} from "../features/editor/models/editor";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {INITIAL_VERSION_ID} from "../features/editor/constants";
import {ScaleSetProvider} from "../features/editor/features/scale/ScaleSetProvider";
import {DrawingDetailsProvider, useDrawingDetailsDataContext} from "../providers/DrawingDetailsProvider";
import {useDispatch} from "react-redux";
import {viewActions} from "../features/editor/features/view/viewSlice";

export type ProjectDrawingExporterProps = {
	drawings: SelectedDrawingVersions,
	options: ProjectPlanDownloadOptions,
	onSuccess: (files: File[]) => void
	initData: () => void
	projectId: string
}

const ProjectDrawingExporter: React.FC<ProjectDrawingExporterProps> = ({
	drawings,
	options,
	onSuccess,
	initData,
	projectId,
}) => {
	const [files, setFiles] = useState<File[]>([])
	const [currentDrawing, setCurrentDrawing] = useState<SelectedDrawingVersion | null>(drawings[0]);

	const storeImage = (data: Blob) => {
		if (currentDrawing) {
			const initialVersionName = `${currentDrawing.selectedDrawingName}_${currentDrawing.selectedVersionId}_${currentDrawing.drawingType.toLowerCase()}.${options.fileType.toLowerCase()}`
			const versionedName = `${currentDrawing.selectedDrawingName}_${currentDrawing.selectedVersionId}_v${currentDrawing.selectedVersionLabel}_${currentDrawing.drawingType.toLowerCase()}.${options.fileType.toLowerCase()}`
			const name = currentDrawing.selectedVersionId === INITIAL_VERSION_ID ? initialVersionName : versionedName;
			const nextIndex = drawings.indexOf(currentDrawing) + 1;

			const imageFiles = [...files, new File([data], name)]
			setFiles(imageFiles)

			if (drawings.length === nextIndex) {
				onSuccess(imageFiles)
			}
			else {
				setCurrentDrawing(drawings[nextIndex])
			}
		}
	};

	if (!currentDrawing)
		return null;

	return (
		<DrawingDetailsProvider
			data={{
				projectId,
				drawingId: currentDrawing.selectedDrawingId,
				sectionId: currentDrawing.selectedSectionId,
				costCenterId: currentDrawing.selectedCostCenterId,
				versionId: currentDrawing.selectedVersionId
			}}
			silent={true}
		>
			<ScaleSetProvider drawingId={currentDrawing.selectedDrawingId}>
				<ProjectDrawingWrapperHidden
					currentDrawing={currentDrawing}
					onSave={storeImage}
					options={options}
					initData={initData}
				/>
			</ScaleSetProvider>
		</DrawingDetailsProvider>
	)
};

const ProjectDrawingWrapperHidden: React.FC<{
	currentDrawing: SelectedDrawingVersion,
	options: ProjectPlanDownloadOptions,
	onSave: (data: Blob) => void,
	initData: () => void
}> = ({
	currentDrawing,
	options,
	onSave,
	initData,
}) => {
	const {drawingData: {image, loadStatus, loadDrawing}} = useDrawingDetailsDataContext()
	const {loadStatus: settingsLoadStatus, settings} = useUserSettingsDataContext();
	const dataRef = useUpdatedRef({loadDrawing, initData})
	const dispatch = useDispatch()

	useEffect(() => {
		const {loadDrawing, initData} = dataRef.current
		if (settings) {
			loadDrawing(settings, () => {
				initData()
				dispatch(viewActions.setDrawingLoaded(true))
			})
		}
	}, [currentDrawing, dataRef, settings, dispatch]);

	return (
		<div className={"project-plan-drawing"}
			 style={{
				 width: 10,
				 height: 10,
				 visibility: "hidden",
				 position: "fixed",
				 opacity: 0,
				 top: 0,
				 left: 0,
				 pointerEvents: "none"
			 }}>
			{settingsLoadStatus === Status.SUCCESS && loadStatus === Status.SUCCESS
				? <HiddenEditor image={image} options={options} onSave={onSave}/>
				: null}
		</div>
	)
};

export default ProjectDrawingExporter;
