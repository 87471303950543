import React from "react";

const IconSearch: React.FC = () => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 18 18">
			<path
				d="M11.625,10.5 L11.0325,10.5 L10.8225,10.2975 C11.5575,9.4425 12,8.3325 12,7.125 C12,4.4325 9.8175,2.25 7.125,2.25 C4.4325,2.25 2.25,4.4325 2.25,7.125 C2.25,9.8175 4.4325,12 7.125,12 C8.3325,12 9.4425,11.5575 10.2975,10.8225 L10.5,11.0325 L10.5,11.625 L14.25,15.3675 L15.3675,14.25 L11.625,10.5 Z M7.125,10.5 C5.2575,10.5 3.75,8.9925 3.75,7.125 C3.75,5.2575 5.2575,3.75 7.125,3.75 C8.9925,3.75 10.5,5.2575 10.5,7.125 C10.5,8.9925 8.9925,10.5 7.125,10.5 Z"
				id="Shape" fill="#CCCCCC"/>
		</svg>
	);
};

export default IconSearch
