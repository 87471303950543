import React, {useState} from "react";
import {penActions, selectActivePenGroupId} from "../../pen/penSlice";
import {useDispatch, useSelector} from "react-redux";
import IconArrowSmall from "../../../icons/IconArrowSmall";
import Dropdown from "../../../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../../../components/ui/DropdownItem";
import {useTranslation} from "react-i18next";
//Reused length panel item styles
import "../length/lengthPanelItem.scss"
import GroupItemPresenter from "../../../components/GroupItemPresenter";
import ConfirmationModal from "../../../../../../../components/ui/ConfirmationModal";
import IconPenToolSmall from "../../../icons/side_panel/IconPenToolSmall";
import {configActions, selectActiveTool, selectEditorLockState} from "../../config/configSlice";
import {EditorTool} from "../../../models/enums";
import {useHighlightSetter} from "../../../hooks/highlight";
import {selectHighlightState} from "../../view/viewSlice";
import {get} from "../../../../../../../utils/ClassNameUtils";
import IconVisibleButton from "../../../components/IconVisibleButton";
import {getId} from "../../../../../../../utils";
import {PenGroup} from "../../../../../../base-konva/types";

interface PenTabItemProps {
	penGroup: PenGroup,
}

const PenPanelItem: React.FC<PenTabItemProps> = ({penGroup}) => {
	const dispatch = useDispatch();
	const activeTool = useSelector(selectActiveTool);
	const editorLocked = useSelector(selectEditorLockState);
	const penGroupActive = useSelector(selectActivePenGroupId) === penGroup.id && activeTool === EditorTool.PEN;
	const {t} = useTranslation();

	const [isExpanded, setIsExpanded] = useState(false);
	const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
	const highlightActions = useHighlightSetter(penGroup.id);

	const {itemId: highlightedItemId} = useSelector(selectHighlightState);
	const isItemBarHover =
		penGroup.id === highlightedItemId ||
		penGroup.penLines.some(penLine => penLine.id === highlightedItemId)

	function toggleExpand() {
		setIsExpanded(!isExpanded);
	}

	const cls = [
		"panel-item",
		(activeTool === EditorTool.PEN && penGroupActive) && "is-active",
		isExpanded && "is-expanded",
	].filter(Boolean).join(" ");

	function handleActivate() {
		const activationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.PEN, actionId: activationId}))
		dispatch(penActions.activatePenGroup({id: penGroup.id, actionId: activationId}))
		dispatch(penActions.setVisibility({id: penGroup.id, visible: true, actionId: activationId}))
	}

	function handleDeactivate() {
		const deactivationId = getId();
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.COUNT, actionId: deactivationId}))
		dispatch(penActions.deactivatePenGroup({actionId: deactivationId}))
	}

	function handleDelete() {
		dispatch(penActions.deletePenGroup({id: penGroup.id}));
	}

	function toggleActivate() {
		penGroupActive ? handleDeactivate() : handleActivate()
	}

	function toggleVisibility() {
		dispatch(penActions.setVisibility({id: penGroup.id, visible: !penGroup.visible}))
	}

	return (
		<>
			<ConfirmationModal isOpen={isConfirmationModalOpen}
							   text={t("editor.deleteItemConfirmationModalQuestion")}
							   onConfirm={handleDelete}
							   onClose={() => setConfirmationModalOpen(false)}/>
			<div className={cls}>
				<div className={get("panel-item_bar", {"is-hover": isItemBarHover})}
					 onMouseEnter={highlightActions.setHighlight}
					 onMouseLeave={highlightActions.clearHighlight}>
					<div className={"panel-item_bar_icon"} onClick={toggleExpand}>
						<IconArrowSmall isActive={penGroupActive}/>
					</div>
					<div className="panel-item_bar_content" onClick={toggleActivate}>
						<div className="panel-item_bar_content_start-icon">
							<IconPenToolSmall isActive={penGroupActive}/>
						</div>
						<div className="panel-item_bar_content_label">
							{penGroup.name}
						</div>
						<div className="panel-item_bar_content_spacer"/>
					</div>
					<div className={"panel-item_bar_props"}>&times;{penGroup.penLines.length}</div>
					<div className={"panel-item_bar_actions"}>
						<IconVisibleButton onClick={toggleVisibility} visible={penGroup.visible} isActive={penGroupActive}/>
						<Dropdown disabled={editorLocked} isActive={penGroupActive}>
							<DropdownItem label={t("common.buttons.delete")} onClick={() => setConfirmationModalOpen(true)}/>
						</Dropdown>
					</div>
				</div>
				<div className={"panel-item_content"}>
					{
						penGroup.penLines.map(item => (
							<GroupItemPresenter key={item.id} parentId={penGroup.id}
												item={item} disabled={editorLocked}
												setItemVisibility={penActions.setItemVisibility}
												onDelete={penActions.deletePenLine}/>
						))
					}
				</div>
			</div>
		</>
	);
};

export default PenPanelItem
