import {CompanyApiDto} from "./restModels";
import {phpDateFormatToMomentJsFormat} from "../utils/TextUtils";

export default class Company {
	constructor(public readonly id: number,
		public readonly name: string,
		public readonly timezone: string,
		public readonly uiDateFormat: string,
		public readonly uiTimeFormat: string,) {}

	public static fromJson(json: CompanyApiDto): Company {
		const momentDateFormat = phpDateFormatToMomentJsFormat(json.uiDateFormat);
		const momentTimeFormat = phpDateFormatToMomentJsFormat(json.uiTimeFormat);
		return new Company(json.id, json.name, json.timezone, momentDateFormat, momentTimeFormat)
	}
}