import {useState} from "react";
import {EditorPanelTabKeys} from "../../models/enums";

export function useEditorPanelExpandData() {
	const [expandedTabs, setExpandedTabs] = useState<EditorPanelTabKeys[]>([]);

	function getExpandState(tab: EditorPanelTabKeys) {
		const isExpanded = expandedTabs.includes(tab)
		const toggle = () => {
			if (isExpanded) {
				setExpandedTabs(expandedTabs.filter(expandedTab => expandedTab !== tab))
			}
			else {
				setExpandedTabs([...expandedTabs, tab])
			}
		}
		const expandTab = () => {
			if (!isExpanded) {
				setExpandedTabs([...expandedTabs, tab]);
			}
		}
		return {isExpanded, toggle, expandTab}
	}

	return {getExpandState}
}