import React from "react";
import "./breadcrumbs.scss";
import useBreadcrumbs, {BreadcrumbData, BreadcrumbsRoute} from 'use-react-router-breadcrumbs';
import {NavLink} from "react-router-dom";
import ProjectBreadcrumb from "./ProjectBreadcrumb";
import CostCenterBreadcrumb from "./CostCenterBreadcrumb";
import {SectionBreadcrumb} from "./SectionBreadcrumb";
import DrawingBreadcrumb from "./DrawingBreadcrumb";
import {useBreadcrumbsContext} from "../providers/BreadcrumbsProvider";
import {paths} from "../../navigator";

export const Breadcrumbs: React.FC = () => {
	const {isProjectDirectAccess, isServiceProject} = useBreadcrumbsContext()

	const routes: BreadcrumbsRoute[] = [
		{path: paths.takeoffs_jobs_details, breadcrumb: null},
		{path: paths.takeoffs_quotes_details, breadcrumb: null},
		{path: paths.takeoffs_jobs_details_projectId, breadcrumb: ProjectBreadcrumb},
		{path: paths.takeoffs_quotes_details_projectId, breadcrumb: ProjectBreadcrumb},
		{path: paths.takeoffs_jobs_details_projectId_section, breadcrumb: null},
		{path: paths.takeoffs_quotes_details_projectId_section, breadcrumb: null},
		{path: paths.takeoffs_jobs_details_projectId_section_sectionId, breadcrumb: SectionBreadcrumb},
		{path: paths.takeoffs_quotes_details_projectId_section_sectionId, breadcrumb: SectionBreadcrumb},
		{path: paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter, breadcrumb: null},
		{path: paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter, breadcrumb: null},
		{
			path: paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId,
			breadcrumb: CostCenterBreadcrumb
		},
		{
			path: paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId,
			breadcrumb: CostCenterBreadcrumb
		},
		{path: paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs, breadcrumb: null},
		{path: paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs, breadcrumb: null},
		{
			path: paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId,
			breadcrumb: DrawingBreadcrumb
		},
		{
			path: paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId,
			breadcrumb: DrawingBreadcrumb
		},
		{
			path: paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version,
			breadcrumb: null
		},
		{
			path: paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version,
			breadcrumb: null
		},
		{
			path: paths.takeoffs_jobs_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version_versionId,
			breadcrumb: null
		},
		{
			path: paths.takeoffs_quotes_details_projectId_section_sectionId_costCenter_costCenterId_takeoffs_drawingId_version_versionId,
			breadcrumb: null
		},
		{path: paths.settings_symbols_user_groups, breadcrumb: null},
		{path: paths.settings_symbols_user_groups_groupId, breadcrumb: null},
		{path: paths.settings_symbols_user_groups_groupId_packs, breadcrumb: null},
		{path: paths.settings_symbols_user_groups_groupId_packs_packId, breadcrumb: null},
		{path: paths.settings_symbols_system_packId, breadcrumb: null},
		{path: paths.takeoffs_quotes_details_projectId_planUpload, breadcrumb: null},
		{path: paths.takeoffs_jobs_details_projectId_planUpload, breadcrumb: null},
	]
	// matches all paths starting with /takeoffs/:type/details/:projectId/(...) (and itself) - e.g. /takeoffs/:type/details/:projectId/section/:sectionId/costCenter/:costCenterId/takeoffs/:drawingId/version/:versionId
	const drawingsAndFurtherPathRegex = /^\/takeoffs\/([^/]+?)\/details(?:\/(?=$))?(?=\/|$)/i;

	const alwaysEnabledPathsRegexes = [drawingsAndFurtherPathRegex];

	const renderBreadcrumb = (value: BreadcrumbData, index: number, array: BreadcrumbData[]) => {
		const {match, breadcrumb} = value;
		const path = (match as any).path as string
		const last = index === (array.length - 1)
		const alwaysEnabled = alwaysEnabledPathsRegexes.some(regex => regex.test(value.key))

		const renderBreadcrumbItem = function() {
			const isSectionPath =
				path === paths.takeoffs_jobs_details_projectId_section_sectionId ||
				path === paths.takeoffs_quotes_details_projectId_section_sectionId

			if (isSectionPath && isServiceProject) {
				return null;
			}
			return (
				<span className="breadcrumbs_item">
					<NavLink to={match.url}>{breadcrumb}</NavLink>
				</span>
			)
		}

		return (
			<React.Fragment key={match.url}>
				{/* Display last part of breadcrumbs as normal text instead link */}
				{last || (isProjectDirectAccess && !alwaysEnabled)
					? <span className="breadcrumbs_item">{breadcrumb}</span>
					: (
						<>
							{renderBreadcrumbItem()}
						</>
					)
				}
			</React.Fragment>
		);
	};

	const cls = ["breadcrumbs", isProjectDirectAccess && "are-disabled"].filter(Boolean).join(" ");
	const breadcrumbs = useBreadcrumbs(routes);
	return (
		<div className={cls}>
			{breadcrumbs.filter(br => br.key !== "/").map(renderBreadcrumb)}
		</div>
	);
};
