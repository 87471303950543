import React from "react";
import {selectCountGroups} from "../count/countSlice";
import {useSelector} from "react-redux";
import EditorPanelTabContent from "./EditorPanelTabContent";
import CountPanelItem from "./count/CountPanelItem";

const CountPanelTab: React.FC = () => {
	const countGroups = useSelector(selectCountGroups);

	return (
		<EditorPanelTabContent>
			{countGroups.map(group => <CountPanelItem key={group.id} countGroup={group}/>)}
		</EditorPanelTabContent>
	);
};

export default CountPanelTab
