import {EditorLine, EditorPoint, LengthFragment, LengthMeasurementDrop, ShapeDragState} from "../../models/editor";
import {Vector2d} from "konva/types/types";
import React, {FC, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {configActions, selectActiveTool} from "../config/configSlice";
import {selectExportInProgress, selectSelectionState, selectSnappingState, viewActions} from "../view/viewSlice";
import {useGetHighlightShapeStyle, useHighlightSetter} from "../../hooks/highlight";
import {useGetSelectionShapeStyle, useSelectSetter} from "../../hooks/select";
import {getDashTypePointArray, getPointDraggingLines, getShapeDraggingLines} from "../../utils";
import {useSnapToPoint} from "./snapUtils";
import {EditorPanelTabKeys, EditorTool} from "../../models/enums";
import {lengthActions} from "./lengthSlice";
import {LinePoint, LinePointProps} from "../../components/LinePoint";
import Konva from "konva";
import {LINE_POINT_RECT_NAME} from "../../constants";
import {KonvaEventObject} from "konva/types/Node";
import {useActivate} from "../../hooks/activate";
import {useEditorPanelDataContext} from "../panel/EditorPanelProvider";
import {EventHandlers, LengthLineKonva} from "./LengthLineKonva";
import {ShapeConfig} from "konva/types/Shape";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {ArcEventHandlers, LengthArcKonva} from "./LengthArcKonva";
import {LineStyle, PointDragState} from "../../../../../base-konva/types";
import {getId} from "../../../../../../utils";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {CursorStyle, DrawingItemType} from "../../../../../base-konva/enums";

type LengthFragmentElementProps = {
	lengthFragment: LengthFragment
	lengthFragmentActive: boolean
	isPointerOverLayer: boolean
	lengthId: string
	lengthStyle: LineStyle
	lengthActive: boolean
	lengthDrops: LengthMeasurementDrop[]
	lengthHasPreviewLine: boolean
	previewLine: EditorLine | null
}
const _LengthFragmentElement: FC<LengthFragmentElementProps> = function({
	lengthFragment,
	lengthFragmentActive,
	isPointerOverLayer,
	lengthId,
	lengthStyle,
	lengthActive,
	lengthDrops,
	lengthHasPreviewLine,
	previewLine
}) {
	const {snapToPoint} = useSnapToPoint()
	const {activePointId} = lengthFragment;
	const dispatch = useDispatch();
	const {changeCursor} = useCursorStyle()
	const activeTool = useSelector(selectActiveTool);
	const snapping = useSelector(selectSnappingState);
	const highlightActions = useHighlightSetter(lengthId);
	const {getHighlightStyle} = useGetHighlightShapeStyle();
	const selectActions = useSelectSetter();
	const {getSelectionStyle} = useGetSelectionShapeStyle();
	const isLinePointHover = useRef(false);
	const [pointDragState, setPointDragState] = useState<PointDragState>({isDragging: false});
	const [shapeDragState, setShapeDragState] = useState<ShapeDragState>({isDragging: false});
	const [arcPointDragState, setArcPointDragState] = useState<PointDragState>({isDragging: false});
	const activateActions = useActivate();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext();
	const exportInProgress = useSelector(selectExportInProgress);
	const showPointElements = lengthActive && !exportInProgress &&
		(activeTool === EditorTool.LENGTH || activeTool === EditorTool.MEASUREMENT_SELECT || activeTool === EditorTool.ARC)
	const selectedLinesSubItemsIds: string[] = [];
	useSelector(selectSelectionState).forEach(item => {
		if (item.type === DrawingItemType.LENGTH) {
			selectedLinesSubItemsIds.push(...item.subItemIds)
		}
	})


	function handleElementClick(lineId?: string) {
		const actionId = getId();
		if (activeTool === EditorTool.MEASUREMENT_SELECT) {
			dispatch(configActions.switchEditorTool({editorTool: EditorTool.LENGTH}));
			dispatch(configActions.switchEditorTool({editorTool: EditorTool.MEASUREMENT_SELECT}));
		}
		activateActions.activateLength(lengthId, actionId);
		if (lineId) {
			selectActions.clearSelection();
			selectActions.setSelection(DrawingItemType.LENGTH, lengthId, lengthFragment.id, [lineId], actionId);
			getExpandState(EditorPanelTabKeys.LENGTH).expandTab();
		}
	}

	function getPointElements(lines: EditorLine[],
		activePointId: string | null,
		drops: LengthMeasurementDrop[]): JSX.Element[] {
		const lineMap: Map<string, JSX.Element> = new Map<string, JSX.Element>()

		const getProps = (point: EditorPoint): LinePointProps & { key: string, drop: LengthMeasurementDrop | undefined } => {
			let xPosition = point.position.x, yPosition = point.position.y
			if (shapeDragState.isDragging && shapeDragState.positionDelta) {
				xPosition += shapeDragState.positionDelta.x
				yPosition += shapeDragState.positionDelta.y
			}

			const drop = drops.find(dr => dr.pointId === point.id);

			return {
				key: point.id,
				drop,
				point: {
					id: point.id,
					position: {
						x: xPosition,
						y: yPosition,
					}
				},
				isActive: lengthFragmentActive && activePointId === point.id,
				onMouseEnter: (evt) => {
					isLinePointHover.current = true
					changeCursor({
						event: evt,
						cursor: CursorStyle.MOVE,
						shiftKeyCursor: CursorStyle.POINTER,
						altKeyCursor: drop ? CursorStyle.NOTALLOWED : CursorStyle.SRESIZE,
					})
				},
				onMouseLeave: (evt) => {
					isLinePointHover.current = false
					changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
				},
				onDragStart: (evt) => {
					changeCursor({event: evt, cursor: CursorStyle.MOVE});
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
						setPointDragState({
							isDragging: true,
							dragPointId: point.id,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						})
					}
				},
				onDragMove: (evt) => {
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
						setPointDragState(prevState => ({
							...prevState,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						}))
					}
				},
				onDragEnd: (evt) => {
					changeCursor({event: evt, cursor: CursorStyle.DEFAULT});
					setPointDragState({isDragging: false});
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
						dispatch(lengthActions.updateLengthPointPosition({
							itemId: point.id,
							newPosition: {
								x: pointer.x,
								y: pointer.y,
							},
							fragmentId: lengthFragment.id
						}))
					}
				},
				onLongTouch: (event, touchPosition) => {
					event.evt.preventDefault();
					if (event.target instanceof Konva.Rect && event.target.name() === LINE_POINT_RECT_NAME) {
						const pointId = event.target.id()
						dispatch(viewActions.setLengthPointContextMenuState({
							visible: true,
							x: touchPosition.x,
							y: touchPosition.y,
							pointId
						}))
					}
				}
			}
		};

		for (let line of lines) {
			if (!lineMap.has(line.from.id)) {
				lineMap.set(line.from.id, <LinePoint {...getProps(line.from)} />)
			}
			if (!lineMap.has(line.to.id)) {
				lineMap.set(line.to.id, <LinePoint {...getProps(line.to)} />)
			}
		}

		return Array.from(lineMap.values())
	}

	const lines: EditorLine[] = [];

	if (lengthActive && pointDragState.isDragging) {
		lines.push(...getPointDraggingLines(lengthFragment.lines, pointDragState))
	}
	else if (lengthActive && shapeDragState.isDragging) {
		lines.push(...getShapeDraggingLines(lengthFragment.lines, shapeDragState))
	}
	else {
		lines.push(...lengthFragment.lines)
	}

	if (lengthActive &&
		lengthFragmentActive &&
		isPointerOverLayer &&
		previewLine?.distance && previewLine.distance > 20 &&
		!shapeDragState.isDragging &&
		!pointDragState.isDragging &&
		!isLinePointHover.current) {
		lines.push(previewLine)
	}

	const handleMouseEnter = (evt: KonvaEventObject<Event>) => {
		changeCursor({
			event: evt,
			cursor: activeTool === EditorTool.MEASUREMENT_SELECT || activeTool === EditorTool.ARC
				? CursorStyle.POINTER
				: CursorStyle.MOVE
		})
		highlightActions.setHighlight()
	};

	const handleMouseLeave = (evt: KonvaEventObject<Event>) => {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
		highlightActions.clearHighlight()
	};

	const handleDragStart = (evt: KonvaEventObject<DragEvent>) => {
		changeCursor({event: evt, cursor: CursorStyle.MOVE})
		const mousePos = getPointerPosition(evt);
		if (mousePos) {
			setShapeDragState({
				isDragging: true,
				startPointerPosition: mousePos
			})
		}
	};

	const handleDragMove = (evt: KonvaEventObject<DragEvent>) => {
		evt.target.x(0)
		evt.target.y(0)
		const mousePos = getPointerPosition(evt);
		if (mousePos) {
			setShapeDragState(prevState => {
				let positionDelta: Vector2d | undefined
				if (prevState.startPointerPosition) {
					positionDelta = {
						x: mousePos.x - prevState.startPointerPosition.x,
						y: mousePos.y - prevState.startPointerPosition.y
					}
				}
				return {...prevState, positionDelta}
			})
		}
	};

	const handleDragEnd = (evt: KonvaEventObject<DragEvent>) => {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
		if (shapeDragState.positionDelta) {
			dispatch(lengthActions.disableLinePreview())
			dispatch(lengthActions.updateLengthFragmentPosition({
				positionDelta: shapeDragState.positionDelta,
				fragmentId: lengthFragment.id
			}))
		}
		setShapeDragState({isDragging: false})
	};

	const lineEventsRef = useUpdatedRef<EventHandlers>({
		onMouseEnter: handleMouseEnter,
		onMouseLeave: handleMouseLeave,
		onDragStart: handleDragStart,
		onDragMove: handleDragMove,
		onDragEnd: handleDragEnd,
		onClick: activeTool === EditorTool.MEASUREMENT_SELECT || activeTool === EditorTool.ARC
			? (id: string) => { handleElementClick(id); }
			: undefined,
		onTap: activeTool === EditorTool.MEASUREMENT_SELECT || activeTool === EditorTool.ARC
			? (id: string) => { handleElementClick(id); }
			: undefined,
	})

	function onArcPointMouseEnter(evt: KonvaEventObject<Event>) {
		changeCursor({
			event: evt,
			cursor: CursorStyle.MOVE,
		})
	}

	function onArcPointMouseLeave(evt: KonvaEventObject<Event>) {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
	}

	function onArcPointDragStart(evt: KonvaEventObject<DragEvent>) {
		changeCursor({event: evt, cursor: CursorStyle.MOVE});
		const mousePosition = getPointerPosition(evt);
		if (mousePosition) {
			let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
			setArcPointDragState({
				isDragging: true,
				dragPointCurrentLocation: {
					x: pointer.x,
					y: pointer.y,
				}
			})
		}
	}

	function onArcPointDragMove(evt: KonvaEventObject<DragEvent>) {
		const mousePosition = getPointerPosition(evt);
		if (mousePosition) {
			let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
			setArcPointDragState(prevState => ({
				...prevState,
				dragPointCurrentLocation: {
					x: pointer.x,
					y: pointer.y,
				}
			}))
		}
	}

	function onArcPointDragEnd(evt: KonvaEventObject<DragEvent>, lineId: string) {
		changeCursor({event: evt, cursor: CursorStyle.DEFAULT});
		setArcPointDragState({isDragging: false})
		const mousePosition = getPointerPosition(evt);
		if (mousePosition) {
			let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
			dispatch(lengthActions.updateLengthArcPointPosition({
				itemId: lineId,
				newPosition: {
					x: pointer.x,
					y: pointer.y,
				},
				fragmentId: lengthFragment.id,
			}))
		}
	}

	const arcEventsRef = useUpdatedRef<ArcEventHandlers>({
		onMouseEnter: onArcPointMouseEnter,
		onMouseLeave: onArcPointMouseLeave,
		onDragStart: onArcPointDragStart,
		onDragMove: onArcPointDragMove,
		onDragEnd: onArcPointDragEnd,
	})

	return (
		<>
			{lines.map(line => {
				const isPreviewLine = Boolean(previewLine?.id === line.id);
				const style: ShapeConfig = {
					...lengthStyle,
					lineCap: "round",
					lineJoin: "round",
					...getHighlightStyle(id => id === lengthId),
					...getSelectionStyle((selected) => {
						return selected.some(item =>
							item.type === DrawingItemType.LENGTH && item.itemId === lengthId &&
							(item.subItemIds && item.subItemIds.some((id: string) => id === line.id)));
					}),
					dash: getDashTypePointArray(lengthStyle.dashType, lengthStyle.strokeWidth)
				}
				const isLineInArcMode = activeTool === EditorTool.ARC && selectedLinesSubItemsIds.includes(line.id);
				return (
					line.arcPointPosition || isLineInArcMode ?
						<LengthArcKonva
							key={line.id}
							arcPointDragState={arcPointDragState}
							line={line}
							isLineInArcMode={isLineInArcMode}
							style={style}
							listening={lengthHasPreviewLine ? false : !isPreviewLine}
							draggable={lengthActive && activeTool !== EditorTool.ARC}
							eventsRef={lineEventsRef}
							arcEventsRef={arcEventsRef}/> :
						<LengthLineKonva
							key={line.id}
							line={line}
							style={style}
							listening={lengthHasPreviewLine ? false : !isPreviewLine}
							draggable={lengthActive}
							eventsRef={lineEventsRef}
						/>

				)
			})}
			{showPointElements && getPointElements(lengthFragment.lines, activePointId, lengthDrops)}
		</>
	)
}

export const LengthFragmentElement = React.memo(_LengthFragmentElement);
