import React, {useState} from 'react';
import "./sidePanel.scss";
import IconArrowLeft from "../../icons/IconArrowLeft";
import {get} from "../../../../../../utils/ClassNameUtils";
import {EditorTool} from "../../models/enums";
import SymbolRecognitionPanel from "../symbol-recognition/SymbolRecognitionPanel";
import {useSelector} from "react-redux";
import {selectActiveTool} from "../config/configSlice";
import {EditorPanel} from "./EditorPanel";

export const SidePanel = () => {
	const [isExpanded, setIsExpanded] = useState(false);
	const activeTool = useSelector(selectActiveTool);

	function toggleExpand() {
		setIsExpanded(!isExpanded);
	}

	return (
		<div className={get("side-panel", {"is-expanded": isExpanded})}>
			<div className="side-panel_wrapper">
				{activeTool === EditorTool.SYMBOL_RECOGNITION ? <SymbolRecognitionPanel/> : <EditorPanel/>}
			</div>
			<div className={"side-panel_toggle"} onClick={toggleExpand}>
				<IconArrowLeft/>
			</div>
		</div>
	);
};
