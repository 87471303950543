import React, {useEffect, useRef, useState} from "react";
import "./searchBar.scss";
import IconClose from "../icons/IconClose";
import IconSearch from "../icons/IconSearch";
import {useMeDataContext} from "../../providers/MeProvider";
import {DEFAULT_DATE_FORMAT, replaceDatesWithIsoDates} from "../../utils/TextUtils";
import {useUpdatedRef} from "../../hooks/useUpdatedRef";

export interface ISearchBarProps {
	onSearch: (search: string) => void,
	isSearching: boolean,
	minQueryLength?: number,
	placeholder?: string
}

const SearchBar: React.FC<ISearchBarProps> = ({onSearch, isSearching, minQueryLength, placeholder}) => {

	const [searchString, setSearchString] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);
	const {company} = useMeDataContext();
	const dateFormat = company ? company.uiDateFormat : DEFAULT_DATE_FORMAT;

	const clearSearch = () => {
		setSearchString("");
		onSearch("");
	}
	const clearSearchRef = useUpdatedRef(clearSearch)

	useEffect(() => {
		const clearSearch = clearSearchRef.current
		return () => {
			clearSearch()
		}
	}, [clearSearchRef])

	const handleSearch = () => {
		onSearch(replaceDatesWithIsoDates(searchString, dateFormat));
	}

	const handleKeySearch = (evt: React.KeyboardEvent<HTMLInputElement>) => {
		evt.key === 'Enter' && onSearch(searchString);
	}

	const handlePlaceholderClick = () => {
		inputRef.current && inputRef.current.focus();
	};

	const buttonDisabled = isSearching || searchString.length < (minQueryLength || 0);

	const cls = [
		"search-bar_submit",
		buttonDisabled && "is-disabled"
	].filter(Boolean).join(" ");

	return (
		<div className="search-bar">
			<span className="search-bar_clear" onClick={clearSearch}>
				<IconClose/>
			</span>
			<input type={"text"} className={"search-bar_input"}
				   ref={inputRef}
				   disabled={isSearching}
				   value={searchString}
				   onKeyDown={handleKeySearch}
				   onChange={(evt) => setSearchString(evt.target.value)}/>
			{searchString.length ? null :
				<span className="search-bar_placeholder" onClick={handlePlaceholderClick}>
					<span className="search-bar_search-icon">
						<IconSearch/>
					</span>
					{placeholder &&
						<span className="search-bar_placeholder-text">
						{placeholder}
					</span>}
				</span>
			}
			<button type="button" className={cls} onClick={handleSearch} disabled={buttonDisabled}>
				Search
			</button>
		</div>
	);
};

export default SearchBar
