import React, {useEffect, useRef} from "react";
import Konva from "konva";
import {colorHexToRGB} from "../../../../../../../utils/TextUtils";
import {Image, Layer, Stage} from "react-konva";
import {useUpdatedRef} from "../../../../../../../hooks/useUpdatedRef";

type CountSymbolPreviewGeneratorProps = {
	image: HTMLImageElement
	color: string
	onSuccess(stageDataUrl: string): void
	onFailure(): void
}

export function CountSymbolPreviewGenerator({image, color, onSuccess, onFailure}: CountSymbolPreviewGeneratorProps) {
	const imageRef = useRef<Konva.Image>(null);
	const dataRef = useUpdatedRef({onSuccess, onFailure})

	useEffect(() => {
		const {onSuccess, onFailure} = dataRef.current

		if (imageRef.current) {
			const rgbColor = colorHexToRGB(color);
			imageRef.current.cache()
			imageRef.current.red(rgbColor.r)
			imageRef.current.green(rgbColor.g)
			imageRef.current.blue(rgbColor.b)
			if (rgbColor.a) {
				imageRef.current.opacity(rgbColor.a);
			}
			imageRef.current.getLayer()?.batchDraw()

			try {
				const dataUrl = imageRef.current.getStage()?.toDataURL()
				if (dataUrl) {
					onSuccess(dataUrl)
				}
				else {
					onFailure()
				}
			}
			catch (e) {
				onFailure()
			}
		}
	}, [image, imageRef, color, dataRef]);

	return (
		<Stage height={40} width={40}>
			<Layer>
				<Image
					ref={imageRef} height={40} width={40}
					filters={[Konva.Filters.RGB]} image={image}
				/>
			</Layer>
		</Stage>
	)
}