import React from "react";
import {dashPatterConfig} from "../constants";
import {useTranslation} from "react-i18next";
import "../../../../base-konva/features/text/styleSelector.scss";
import ColorPicker from "./ColorPicker";
import {MinMaxValidatedNumberInput} from "./MinMaxValidatedNumberInput";
import {DashPatternType, LineStyle} from "../../../../base-konva/types";

interface LineStyleSelectorProps {
	style: LineStyle,
	onStyleChange: (style: LineStyle) => void,
	horizontal?: boolean
}

const LineStyleSelector: React.FC<LineStyleSelectorProps> = ({style, onStyleChange, horizontal}) => {

	const {t} = useTranslation();

	const cls = [
		"style-selector",
		horizontal && "is-horizontal",
	].filter(Boolean).join(" ")

	return (
		<div className={cls}>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.lineStyleEditor.stroke")}</div>
				<div className={"style-selector_field_input"}>
					<MinMaxValidatedNumberInput
						min={1} max={10} value={style.strokeWidth}
						onChange={value => { onStyleChange({...style, strokeWidth: value}) }}
					/>
				</div>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.lineStyleEditor.color")}</div>
				<ColorPicker color={style.stroke} onChange={color => {onStyleChange({...style, stroke: color})}}/>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.lineStyleEditor.pattern")}</div>
				<select value={style.dashType} onChange={event => {
					onStyleChange({
							...style,
							dashType: event.target.value as DashPatternType
						}
					)
				}}>
					{Object.keys(dashPatterConfig).map(key =>
						<option key={key} value={key}>
							{dashPatterConfig[key].optionDescription}
						</option>)}
				</select>
			</div>
		</div>
	);
};

export default LineStyleSelector
