import React from "react";
import {useBreadcrumbsContext} from "../providers/BreadcrumbsProvider";

interface CostCenterDetailsBreadcrumbProps {
	costCenterId: string,
	sectionId: string
}

const CostCenterDetailsBreadcrumb: React.FC<CostCenterDetailsBreadcrumbProps> = ({costCenterId, sectionId}) => {
	const {projectData} = useBreadcrumbsContext()
	if (projectData === undefined) {
		return <span>...</span>
	}
	const {sections, treeExpandDataRef} = projectData

	return (
		<span onClick={() => {
			treeExpandDataRef.current.handleCostCenterBreadcrumbClick(costCenterId)
		}}>
			{sections
				.find(section => section.id === sectionId)?.costCenters
				.find(s => s.id === costCenterId)?.name
			}
		</span>
	);
};

export default CostCenterDetailsBreadcrumb
