import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../models/interfaces";
import {MeasurementStyleSelector} from "../features/panel/material/components/MeasurementStyleSelector";

interface MeasurementStyleSelectorModalProps {
	isOpen: boolean,
	initialStyle: MeasurementStyle,
	measurementType: MaterialAddableTool,
	onSave: (style: MeasurementStyle) => void,
	onClose: () => void,
}

const MeasurementStyleSelectorModal: React.FC<MeasurementStyleSelectorModalProps> = ({
	isOpen, initialStyle, measurementType, onSave, onClose
}) => {
	const {t} = useTranslation();
	const [style, setStyle] = useState<MeasurementStyle>(initialStyle);

	useEffect(() => setStyle(initialStyle), [isOpen, initialStyle]);

	return isOpen ? (
		<EditValueModal isOpen={true}
						onClose={onClose}
						onSave={() => onSave(style)}
						title={t("editor.styleSelectorModalTitle")}
						saveLabel={t("common.buttons.done")}>
			<MeasurementStyleSelector measurementType={measurementType} style={style} onChange={setStyle}/>
		</EditValueModal>
	) : null
};

export default MeasurementStyleSelectorModal;
