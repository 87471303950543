import {useAddItemsOneOffData} from "../../hooks/useAddItemsOneOffData";
import {useTranslation} from "react-i18next";
import {MaterialAddableTool} from "../../../../../../../../../../../models/interfaces";
import {EditorTool} from "../../../../../../../models/enums";
import {capitalizeString} from "../../../../../../../../../../../utils/TextUtils";
import {MeasurementStyleSelector} from "../../../../components/MeasurementStyleSelector";
import React from "react";
import {HeightInput} from "../../../../components/one-off/HeightInput";
import {DefaultDropInput} from "../../../../components/one-off/DefaultDropInput";

export function AddItemsStyles(stylesContainer: ReturnType<typeof useAddItemsOneOffData>["stylesContainer"]) {
	const {t} = useTranslation();
	return (
		<div className="one-off-modal_styles">
			<div className="one-off-modal_styles_container">
				<div className="style-selector_field item-type">
					<div className={"style-selector_field_label"}>{t("projects.addItemModal.itemTypeSelectorLabel")}</div>
					<select
						onChange={(event) => stylesContainer.changeItemType(event.target.value as MaterialAddableTool)}
						value={stylesContainer.type}>
						{[EditorTool.COUNT, EditorTool.LENGTH, EditorTool.AREA, EditorTool.VOLUME].map((type) =>
							<option key={type} value={type}>{capitalizeString(type)}</option>)}
					</select>
				</div>
				<MeasurementStyleSelector {...stylesContainer.measurementStyleSelector} />
				{stylesContainer.heightInput ? <HeightInput {...stylesContainer.heightInput}/> : null}
				{stylesContainer.defaultDropInput ? <DefaultDropInput {...stylesContainer.defaultDropInput}/> : null}
			</div>
		</div>
	)
}