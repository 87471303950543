import React from "react";
import "./pagination.scss";
import {useTranslation} from "react-i18next";

interface PaginationProps {
	pageNumber: number,
	totalPages: number,
	onPageChange: (pageNumber: number) => void,
	siblings?: number,
	disabled?: boolean,
}

const Pagination: React.FC<PaginationProps> = ({pageNumber, totalPages, onPageChange, siblings, disabled}) => {

	const {t} = useTranslation();

	// Default number of visible siblings
	const noOfSiblings = siblings || 2;

	let range: Array<number> = [];

	for (let i = 0; i <= totalPages - 1; i++) {
		range.push(i);
	}

	const handlePageChange = (newPage: number) => {
		!(disabled || newPage === pageNumber) && onPageChange(newPage);
	}

	const handleRangeChange = (newPage: number) => {
		handlePageChange(newPage);
	}

	const onPrevious = () => {
		if (pageNumber >= 1) {
			handlePageChange(pageNumber - 1)
		}
	};

	const onNext = () => {
		if (pageNumber < totalPages - 1) {
			handlePageChange(pageNumber + 1)
		}
	}

	const onFirst = () => {
		handlePageChange(0);
	}

	const onLast = () => {
		handlePageChange(totalPages - 1);
	}

	const renderPaginationElement = (rangeNumber: number) => {

		const itemCls = [
			"pagination_link",
			pageNumber === rangeNumber && "active",
		].filter(Boolean).join(" ");

		return (
			((rangeNumber === pageNumber - noOfSiblings) || (rangeNumber === pageNumber + noOfSiblings)) ?
				"..." :
				((rangeNumber < pageNumber - noOfSiblings) || (rangeNumber > pageNumber + noOfSiblings)) ?
					null :
					<span key={rangeNumber} className={itemCls}
						  onClick={() => handleRangeChange(rangeNumber)}>{rangeNumber + 1}</span>
		);
	}

	const cls = [
		"pagination",
		disabled && "disabled"
	].filter(Boolean).join(" ");

	return (
		totalPages < 1 ? <div className="pagination_placeholder"/> :
			<div className={cls}>
				<span className={"pagination_link"} onClick={onFirst}>&laquo;</span>
				<span className={"pagination_link"} onClick={onPrevious}>{t("common.pagination.previous")}</span>
				{
					range.map(renderPaginationElement)
				}
				<span className={"pagination_link"} onClick={onNext}>{t("common.pagination.next")}</span>
				<span className={"pagination_link"} onClick={onLast}>&raquo;</span>
			</div>
	);
};

export default Pagination
