import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import NotificationService from "../../../../services/NotificationService";
import {apiInstance} from "../../../../api/api";
import LoggerService from "../../../../services/LoggerService";
import ConfirmationModal from "../../../../components/ui/ConfirmationModal";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";

interface DeleteCostCenterModalProps {
	onClose: () => void
	deletedCostCenterId: string
}

const DeleteCostCenterModal: React.FC<DeleteCostCenterModalProps> = ({
	onClose, deletedCostCenterId
}) => {
	const {t} = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const {refreshProjectTreeAfterSimproUpdate} = useProjectDetailContext()

	const onDelete = () => {
		setIsLoading(true)
		apiInstance.projectsApi.deleteCostCenter(deletedCostCenterId)
			.then(() => {
				NotificationService.successNotification(t("common.success"),
					t(`projects.details.deleteCostCenterModal.deleteCostCenterSuccessDesc`))
				refreshProjectTreeAfterSimproUpdate();
				onClose()
			})
			.catch((error) => {
				LoggerService.logError(error)
				if (error?.response?.data?.exception?.includes("CostCenterNotDeletedInExternalSystemException")) {
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.deleteCostCenterModal.onNotDeletedInExternalSystemError"))
				}
				else {
					NotificationService.errorNotification(
						t("common.error"),
						t("projects.details.deleteCostCenterModal.onDeleteErrorDesc"))
				}
			})
			.finally(() => {
				setIsLoading(false)
			})
	}

	return (
		<ConfirmationModal isLoading={isLoading} title={t("projects.details.deleteCostCenterModal.title")} isOpen={true}
						   text={t("projects.details.deleteCostCenterModal.message")} onConfirm={onDelete}
						   onClose={onClose}/>
	);
};

export default DeleteCostCenterModal
