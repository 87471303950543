import {DrawingType, Status} from "../../../../../../models/enums";
import React, {createContext, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useDrawingScale from "../../../../hooks/useDrawingScale";
import {configActions, selectActiveTool, selectStageConfig} from "../config/configSlice";
import {SetScaleModal} from "./SetScaleModal";
import {EditorTool} from "../../models/enums";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {PageScaleRatio} from "../../../../../../models/DrawingDetails";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {getId} from "../../../../../../utils";
import {getScaleInfoState} from "../scaleInfo/scaleInfoUtils";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";
import {scaleActions, selectScaleConfig} from "./scaleSlice";

type ScaleSetDataContextType = {
	openScaleSetModal: () => void
	save: (scale: number, pageScaleRatio?: PageScaleRatio) => void
	saveStatus: Status
}

const ScaleSetDataContext = createContext<ScaleSetDataContextType | undefined>(undefined)

export const ScaleSetDataContextBridge = {
	Consumer: ScaleSetDataContext.Consumer,
	Provider: ScaleSetDataContext.Provider
}

export function useScaleSetDataContext() {
	const context = useContext(ScaleSetDataContext);
	if (context === undefined) {
		throw new Error("useScaleSetDataContext must be used within a ScaleSetProvider")
	}
	return context
}

type Props = {
	drawingId: string | undefined
}
export const ScaleSetProvider: React.FC<Props> = ({drawingId, children}) => {

	const dispatch = useDispatch();
	const {settings} = useUserSettingsDataContext();
	const stageConfig = useSelector(selectStageConfig);
	const [scaleModalOpen, setScaleModalOpen] = useState(false)
	const {drawingData: {drawingDetails}} = useDrawingDetailsDataContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {saveScale, saveStatus} = useDrawingScale(drawingType)
	const activeTool = useSelector(selectActiveTool);
	const activeToolRef = useUpdatedRef(activeTool)
	const scaleInfoState = useSelector(selectScaleConfig).scaleInfoState;

	useEffect(() => {
		if (saveStatus === Status.SUCCESS && activeToolRef.current === EditorTool.SCALE)
			dispatch(configActions.switchEditorToolBack({isUndoRedoExcluded: true}));
	}, [saveStatus, dispatch, activeToolRef])

	function openScaleSetModal() {
		setScaleModalOpen(true)
	}

	function closeScaleSetModal() {
		setScaleModalOpen(false)
	}

	function save(scale: number, pageScaleRatio?: PageScaleRatio) {
		saveScale(drawingId, scale, pageScaleRatio)

		const actionId = getId()
		const infoState = getScaleInfoState(scale, scaleInfoState, settings, stageConfig)
		dispatch(scaleActions.setScale({value: scale, actionId}));
		dispatch(scaleActions.setScaleInfoState({value: infoState, actionId}))
	}

	return (
		<ScaleSetDataContext.Provider
			value={{
				openScaleSetModal,
				save,
				saveStatus,
			}}
		>
			{scaleModalOpen ? (
				<SetScaleModal
					isOpen={scaleModalOpen}
					onClose={closeScaleSetModal}
				/>
			) : null}
			{children}
		</ScaleSetDataContext.Provider>
	)
}
