import {KeySettingsType, UnitsType} from "./enums";
import {UserSettingsApiDto} from "./restModels";
import LoggerService from "../services/LoggerService";

export default class UserSettings {
	constructor(public readonly measurementSystem: UnitsType,
				public readonly keySettings: KeySettingsType,
				public readonly downloadKeyTable: boolean) {}

	public static fromJson(json: UserSettingsApiDto): UserSettings | never {
		const measurementSystem = UnitsType[json.measurementSystem];
		const keySettings = KeySettingsType[json.keySettings];
		const downloadKeyTable = json.downloadKeyTable;
		if (measurementSystem === undefined) {
			const error = new Error(`Unknown units type: ${json.measurementSystem}`);
			LoggerService.logError(error);
			throw error;
		}
		if (keySettings === undefined) {
			const error = new Error(`Unknown key settings: ${json.keySettings}`);
			LoggerService.logError(error);
			throw error;
		}
		return new UserSettings(measurementSystem, keySettings, downloadKeyTable)
	}

	// getLanguageOptions(): CustomSelectItem<string>[] {
	// 	return this.availableLanguages.map(l => ({label: l, value: l}))
	// }
}
