import React, {InputHTMLAttributes, PropsWithChildren} from "react";
import "./textInput.scss";
import {getId} from "../../../../../utils";

interface TextInputProps {
	label?: string
}

const TextInput: React.FC<PropsWithChildren<InputHTMLAttributes<HTMLInputElement> & TextInputProps>> = (props) => {

	const id = getId();

	const label = props.label?.length !== 0 ? props.label : null;

	const cls = [
		"text-input",
		label && "is-labeled",
	].filter(Boolean).join(" ")

	return (
		<label htmlFor={id} className={cls}>
			{label}
			<input {...props} id={id}/>
		</label>
	);
};

export default TextInput
