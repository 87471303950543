import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const IconSelect: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M13.0262632,6 C12.6628183,6.00382265 12.3546047,6.26575023 12.2974132,6.61945574 L10.0100522,20.9054581 C9.96450005,21.1769594 10.0759577,21.4513241 10.2998554,21.6157324 C10.5237433,21.7801603 10.8222703,21.8069262 11.0723248,21.6855191 L13.902372,20.3471501 L15.0189082,24.4539322 C15.0702763,24.6432147 15.1953061,24.8047744 15.3668457,24.902274 C15.5383978,24.9997835 15.7428951,25.025595 15.9338476,24.973972 L19.7643113,23.9568331 C19.9562166,23.9071215 20.1200149,23.783803 20.2188656,23.6136509 C20.3177262,23.4444435 20.3438953,23.2436955 20.291557,23.0543983 L19.1750208,18.9476162 L22.3075252,18.7105359 C22.584724,18.6914153 22.8270371,18.5222103 22.9375246,18.2717384 C23.0489847,18.0212837 23.0092459,17.7306691 22.8347884,17.5174864 L13.6156728,6.27614628 C13.4731978,6.09929108 13.2551308,5.99796386 13.0263898,6.00082718 L13.0262632,6 Z M13.5069941,8.48545849 L20.779863,17.3568518 L18.1669201,17.5480456 C17.9459402,17.5652525 17.7443484,17.6790143 17.6173858,17.8577735 C17.4894506,18.0374969 17.4497118,18.2630922 17.5078635,18.4734115 L18.6631559,22.7331486 L16.2672677,23.3602644 L15.1119752,19.1081138 C15.0557614,18.895888 14.9055327,18.7190451 14.7029756,18.627272 C14.499441,18.5345421 14.2658641,18.5374103 14.0652349,18.6339629 L11.7081029,19.7505348 L13.5069941,8.48545849 Z"]}/>
	);
};

export default IconSelect
