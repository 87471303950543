import React, {useEffect} from "react";
import {createBrowserHistory} from "history";
import {useAppSettingsDataContext} from "../providers/AppSettingsProvider";

const IframeChecker: React.FC = ({children}) => {

	const isInIframe = () => {
		try {
			return window.self !== window.top;
		}
		catch (e) {
			return true;
		}
	}

	const {allowWithoutIframe} = useAppSettingsDataContext()

	useEffect(() => {
		if (!allowWithoutIframe && !isInIframe()) {
			createBrowserHistory().push('/error?direct');
			window.location.reload();
		}
	}, [allowWithoutIframe])

	return (
		<>
			{children}
		</>
	);
};

export default IframeChecker
