import {TakeoffTemplateApiDto} from "./restModels";

export class TakeoffTemplate {
	constructor(
		public readonly id: number,
		public readonly name: string
	) {}

	static fromJson(json: TakeoffTemplateApiDto) {
		return new TakeoffTemplate(
			json.id,
			json.name
		)
	}
}