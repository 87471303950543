import {useState} from "react";
import {ProjectSource, Status} from "../../../../../models/enums";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../../../api/api";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {AttachmentDetails} from "../../../../../models/AttachmentDetails";

export function useAttachments() {
	const [attachments, setAttachments] = useState<AttachmentDetails[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const loadAttachmentDetailsRef = useUpdatedRef(loadAttachmentDetails)
	const {t} = useTranslation();

	function loadAttachmentDetails(source: ProjectSource, simproId: number, folderId?: string) {
		const apiMethod = source === ProjectSource.JOB
			? apiInstance.projectsApi.fetchJobAttachments
			: apiInstance.projectsApi.fetchQuoteAttachments
		setLoadStatus(Status.LOADING);
		apiMethod.bind(apiInstance.projectsApi)(simproId, folderId).then(data => {
			setAttachments(data)
			setLoadStatus(Status.SUCCESS)
		}).catch(err => {
			setAttachments([])
			setLoadStatus(Status.ERROR)
			LoggerService.logError(err)
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.attachmentSelectModal.loadAttachmentFileErrorDesc")
			)
		})
	}

	return {loadAttachmentDetailsRef, loadStatus, attachments}
}
