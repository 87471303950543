import React from "react";
import "./settingsSymbols.scss";
import PageHeader from "../../../components/layout/page/PageHeader";
import {useTranslation} from "react-i18next";
import PageHeaderButtons from "../../../components/layout/page/PageHeaderButtons";
import Button from "../../../components/ui/Button";
import {useParams} from "react-router-dom";
import {getDefaultSymbolPack} from "../../project-drawings/features/editor/icons/symbol_pack/defaultPacks";
import SymbolPreviewElement from "./SymbolPreviewElement";
import {paths, useNavigator} from "../../navigator";

const DefaultSymbolsPreview: React.FC = () => {
	const {packId} = useParams<{ packId: string }>();

	const symbolSystemPack = getDefaultSymbolPack(packId);

	const {t} = useTranslation();
	const {navigateTo} = useNavigator()

	const redirectToPacks = () => {
		navigateTo(paths.settings_symbols_system)
	};

	return (
		<div className={"settings-symbols"}>
			<PageHeader>
				<div className="settings-symbols_breadcrumbs">
					<span className="settings-symbols_breadcrumbs_item" onClick={redirectToPacks}>
						{t("settings.symbolSettings.systemPacks")}
					</span>
					<span>{" / "}</span>
					<span className="settings-symbols_breadcrumbs_current-item">{symbolSystemPack?.name}</span>
				</div>
				<PageHeaderButtons>
					<Button
						label={t("common.buttons.cancel")}
						onClick={() => { navigateTo(paths.root) }}
						variant={"optional"}/>
				</PageHeaderButtons>
			</PageHeader>
			<div className={"settings-symbols_preview"}>
				{symbolSystemPack?.symbols.map((symbol) => (
					<div className={"settings-symbols_preview_item"} key={symbol}>
						<SymbolPreviewElement symbol={symbol}/>
					</div>
				))}
			</div>
		</div>
	);
};

export default DefaultSymbolsPreview
