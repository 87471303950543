import {useEffect} from "react";
import {calculateSizes, getScaleInfoState} from "../../../project-drawings/features/editor/features/scaleInfo/scaleInfoUtils";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {BaseStageConfig, ScaleConfig, ScaleInfoState} from "../../types";
import UserSettings from "../../../../models/UserSettings";

type Props = {
	scaleConfig: ScaleConfig,
	settings: UserSettings | undefined
	setScaleInfoState: (state: ScaleInfoState) => void,
	stageConfig: BaseStageConfig,
	isLoading: boolean,
}

function useInitScaleInfoState(props: Props) {
	const {scaleConfig, settings, setScaleInfoState, stageConfig, isLoading} = props
	const initDataRef = useUpdatedRef({scaleConfig, settings, setScaleInfoState})

	useEffect(function initScaleInfoState() {
		const {scaleConfig, settings, setScaleInfoState} = initDataRef.current
		const scale = scaleConfig.scale
		const scaleInfoState = scaleConfig.scaleInfoState
		const savedUnitFactor = scaleInfoState.unitFactor
		const saveUnitWidth = scaleInfoState.unitWidth
		const savedPosition = scaleInfoState.position

		if (!isLoading && scale && stageConfig.width) {
			if (savedPosition) {
				const sizesToSet = calculateSizes(scale, savedUnitFactor, settings, scaleInfoState, saveUnitWidth)
				setScaleInfoState({
					...sizesToSet,
					position: savedPosition,
					visible: true
				});
			}
			else {
				const newScaleInfoState = getScaleInfoState(scale, scaleInfoState, settings, stageConfig)
				setScaleInfoState(newScaleInfoState)
			}
		}
	}, [isLoading, stageConfig, initDataRef]);
}

export {useInitScaleInfoState}