import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../components/modal/EditValueModal";
import {Status} from "../../../models/enums";
import TextInput from "../../project-drawings/features/editor/components/TextInput";
import useRenameSymbolGroup from "../hooks/useRenameSymbolGroup";
import {SymbolGroup} from "../../../models/SymbolGroup";

interface RenameSymbolGroupModalProps {
	isOpen: boolean,
	symbolGroup: SymbolGroup,
	onSuccess: () => void,
	onClose: () => void,
}

const RenameSymbolGroupModal: React.FC<RenameSymbolGroupModalProps> = ({isOpen, symbolGroup, onSuccess, onClose}) => {

	const [name, setName] = useState<string | undefined>(symbolGroup.name);
	const {t} = useTranslation();

	const {saveStatus, renameSymbolGroup} = useRenameSymbolGroup(symbolGroup.id, onSuccess);

	const handleSubmit = () => {
		name && renameSymbolGroup(name);
	};

	const clearData = () => {
		setName(undefined);
	};

	const handleClose = () => {
		clearData();
		onClose();
	};

	return (
		<EditValueModal isOpen={isOpen}
						onClose={handleClose}
						onSave={handleSubmit}
						title={t("settings.tabs.symbols.groups.dropdown.rename.modalTitle")}
						disableSave={!name || saveStatus === Status.LOADING}
						isLoading={saveStatus === Status.LOADING}
						saveLabel={t("common.buttons.done")}>
			<div className="modal-content">
				<div className="modal-content_field">
					<div className="modal-content_field_label">
						{t("settings.tabs.symbols.groups.dropdown.rename.inputLabel")}
					</div>
					<TextInput value={name}
							   onChange={(evt) => setName(evt.target.value)}
							   placeholder={t("settings.tabs.symbols.groups.dropdown.rename.inputPlaceholder")}/>
				</div>
			</div>
		</EditValueModal>
	);
};

export default RenameSymbolGroupModal;
