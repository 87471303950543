import React from "react";

const IconEditGrey: React.FC = () => {
	return (
		<svg width="18px" height="18px" viewBox="0 0 18 18">
			<path
				d="M3,12.5003472 L3,15 L5.49965283,15 L12.8719622,7.6276906 L10.3723094,5.12803777 L3,12.5003472 Z M14.8050271,5.69462575 C15.064991,5.43466185 15.064991,5.01472018 14.8050271,4.75475628 L13.2452437,3.19497292 C12.9852798,2.93500903 12.5653381,2.93500903 12.3053743,3.19497292 L11.0855437,4.4148035 L13.5851965,6.91445633 L14.8050271,5.69462575 Z"
				fill="#CCCCCC"/>
		</svg>
	);
};

export default IconEditGrey;
