import {useTranslation} from "react-i18next";
import React from "react";
import {useUserSettingsDataContext} from "../../../../../../../../../providers/UserSettingsProvider";
import {UnitsType} from "../../../../../../../../../models/enums";
import LengthInput from "../../../../../../../../../components/ui/LengthInput";

export type OneOffInputProps = {
	value: number | undefined
	onChange: (value: (number | undefined)) => void
}

export function HeightInput({value, onChange}: OneOffInputProps) {
	const {t} = useTranslation();
	const {settings} = useUserSettingsDataContext();
	const inputLabel = `${t("editor.area.setHeightModal.inputLabel")}  ${settings?.measurementSystem === UnitsType.METRIC ?
		`[${t("common.units.meterSymbol")}]` : ""}`;

	return (
		<LengthInput label={inputLabel}
					 value={value}
					 unitsType={settings?.measurementSystem}
					 onChange={onChange}/>
	)
}