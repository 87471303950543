import React from "react";
import {useProjectDetailContext} from "../../../../providers/ProjectDetailProvider";
import {ConfirmReselectModal} from "./PlanUploadConfirmModals";
import {PlanUploadNoScaleInfoModal} from "./PlanUploadNoScaleInfoModal";
import EditorPlanPageResetModal from "./EditorPlanPageResetModal";
import PlanTemplateRenameModal from "../../../../components/plan-templates/modals/PlanTemplateRenameModal";
import PlanPageRenameModal from "../../../../components/plan-templates/modals/PlanPageRenameModal";
import PlanPageDeleteModal from "../../../../components/plan-templates/modals/PlanPageDeleteModal";

export const PlanUploadPageModals = () => {

	const {planUploadData: {modals: {modal, closeModal}}} = useProjectDetailContext()

	switch (modal?.type) {
		case 'reselect-pages': {
			const {template, onConfirmReselect} = modal.data
			const onRestartWizard = function() {
				onConfirmReselect(template);
				closeModal();
			}
			return (
				<ConfirmReselectModal onRestartWizard={onRestartWizard} onClose={closeModal}/>
			)
		}
		case 'rename-template': {
			const {template, onSuccess} = modal.data
			return (
				<PlanTemplateRenameModal
					onSuccess={onSuccess}
					planTemplate={template}
					onClose={closeModal}
				/>
			)
		}
		case 'rename-page': {
			const {template, page, onDone} = modal.data
			return (
				<PlanPageRenameModal
					onClose={closeModal}
					planPage={page}
					onDone={onDone}
					planTemplate={template}
				/>
			)
		}
		case 'delete-page': {
			const {template, page, onSuccess} = modal.data
			return (
				<PlanPageDeleteModal
					planTemplate={template}
					onSuccess={onSuccess}
					planPage={page}
					onClose={closeModal}
				/>
			)
		}
		case 'reset-page': {
			const {template, page} = modal.data
			return (
				<EditorPlanPageResetModal
					planTemplate={template}
					planPage={page}
					onClose={closeModal}
				/>
			)
		}
		case 'no-scale-info': {
			const {onClose, pageTitles} = modal.data
			return (
				<PlanUploadNoScaleInfoModal
					onClose={() => {
						closeModal();
						onClose();
					}}
					pageTitles={pageTitles}
				/>
			)
		}
		default:
			return null;
	}
}