import React, {useEffect, useState} from "react";
import {AddItemsModal} from "./features/add-items/AddItemsModal";
import {MaterialType} from "../../../models/enums";
import useCatalogGroups from "./hooks/useCatalogGroups";
import usePreBuildGroups from "./hooks/usePreBuildGroups";
import {useCatalogMaterialItems, usePrebuildMaterialItems} from "./hooks/useMaterialItems";
import {MaterialChangeModal} from "./features/material-change/MaterialChangeModal";
import {useAddItemsData} from "./features/add-items/hooks/useAddItemsData";
import {ChangeState, useMaterialChangeData} from "./features/material-change/hooks/useMaterialChangeData";
import {useTranslation} from "react-i18next";
import {useAddItemsOneOffData} from "./features/add-items/hooks/useAddItemsOneOffData";
import {OneOffChangeState, useMaterialChangeOneOffData} from "./features/material-change/hooks/useMaterialChangeOneOffData";
import {MaterialModal} from "../../../../../../../models/enums";
import {useAddItemsTemplatesData} from "./features/add-items/hooks/useAddItemsTemplatesData";
import {useMaterialChangeTemplatesData} from "./features/material-change/hooks/useMaterialChangeTemplatesData";
import "./materialModal.scss"
import "./oneOffModal.scss"
import {useUpdatedRef} from "../../../../../../../hooks/useUpdatedRef";

const useInternalState = function() {
	const [modal, setModal] = useState<MaterialModal | null>(null);

	const {t} = useTranslation()
	const preBuildRootGroupLabel = t("projects.addItemModal.preBuilds.rootCategoryName")
	const catalogRootGroupLabel = t("projects.addItemModal.catalog.rootCategoryName")

	const [activeTab, setActiveTab] = useState<MaterialType>(MaterialType.PREBUILD);

	const catalogHooks = {useMaterialItemsHook: useCatalogMaterialItems, loadGroupsHook: useCatalogGroups}
	const preBuildHooks = {useMaterialItemsHook: usePrebuildMaterialItems, loadGroupsHook: usePreBuildGroups}

	const addItemsCatalogsData = useAddItemsData({
		...catalogHooks, rootGroupLabel: catalogRootGroupLabel, type: MaterialType.CATALOG
	})
	const addItemsPreBuildsData = useAddItemsData({
		...preBuildHooks, rootGroupLabel: preBuildRootGroupLabel, type: MaterialType.PREBUILD
	})
	const addItemsTemplatesData = useAddItemsTemplatesData()
	const materialChangeCatalogsData = useMaterialChangeData({
		...catalogHooks, rootGroupLabel: catalogRootGroupLabel, type: MaterialType.CATALOG
	})
	const materialChangePreBuildsData = useMaterialChangeData({
		...preBuildHooks, rootGroupLabel: preBuildRootGroupLabel, type: MaterialType.PREBUILD
	})
	const materialChangeTemplatesData = useMaterialChangeTemplatesData()

	const addItemsOneOffData = useAddItemsOneOffData()
	const materialChangeOneOffData = useMaterialChangeOneOffData()

	function restoreInitialState() {
		addItemsOneOffData.restoreInitialState()
	}

	const dataRef = useUpdatedRef({restoreInitialState})
	useEffect(() => {
		if (modal === null) {
			const {restoreInitialState} = dataRef.current
			restoreInitialState()
		}
	}, [modal, dataRef])

	return {
		modal, setModal,
		modalTab: {
			active: activeTab,
			activate: setActiveTab
		},
		addItemsData: {
			catalogs: addItemsCatalogsData,
			preBuilds: addItemsPreBuildsData,
			oneOffs: addItemsOneOffData,
			templates: addItemsTemplatesData
		},
		materialChangeData: {
			setChangeState: (state: ChangeState) => {
				materialChangeCatalogsData.setChangeState(state)
				materialChangePreBuildsData.setChangeState(state)
				materialChangeTemplatesData.setChangeState(state)
			},
			catalogs: materialChangeCatalogsData,
			preBuilds: materialChangePreBuildsData,
			oneOffs: materialChangeOneOffData,
			templates: materialChangeTemplatesData
		}
	}
}

const InternalDataContext = React.createContext<ReturnType<typeof useInternalState> | undefined>(undefined)
const InternalDataProvider: React.FC = function({children}) {
	const internalState = useInternalState()
	return (
		<InternalDataContext.Provider value={internalState}>
			{children}
		</InternalDataContext.Provider>
	)
}
const useInternalDataContext = function() {
	const context = React.useContext(InternalDataContext)
	if (context === undefined) {
		throw new Error("useInternalDataProvider must be used within a InternalDataProvider")
	}
	return context;
}


type MaterialModalContextType = {
	openAddItemsModal: (activeTab: MaterialType) => void
	openMaterialChangeModal: (state: ChangeState) => void
	openOneOffChangeModal: (state: OneOffChangeState) => void
	closeModal: () => void
}
const MaterialModalContext = React.createContext<MaterialModalContextType | undefined>(undefined)
const MaterialModalProvider: React.FC = function({children}) {
	return (
		<InternalDataProvider>
			<InternalDataContext.Consumer>
				{internalData => {
					function openAddItemsModal(activeTab: MaterialType) {
						internalData?.setModal(MaterialModal.ADD_ITEMS)
						internalData?.modalTab.activate(activeTab)
					}

					function openMaterialChangeModal(state: ChangeState) {
						internalData?.setModal(MaterialModal.MATERIAL_CHANGE)
						internalData?.modalTab.activate(MaterialType.TEMPLATE)
						internalData?.materialChangeData.setChangeState(state)
					}

					function openOneOffChangeModal(state: OneOffChangeState) {
						internalData?.setModal(MaterialModal.MATERIAL_CHANGE)
						internalData?.modalTab.activate(MaterialType.ONE_OFF)
						internalData?.materialChangeData.oneOffs.setChangeState(state)
					}

					function closeModal() {
						internalData?.setModal(null)
					}

					return (
						<MaterialModalContext.Provider value={{
							openAddItemsModal,
							openMaterialChangeModal,
							openOneOffChangeModal,
							closeModal
						}}>
							<>
								{internalData?.modal === MaterialModal.ADD_ITEMS ? <AddItemsModal/> : null}
								{internalData?.modal === MaterialModal.MATERIAL_CHANGE ? <MaterialChangeModal/> : null}
								{children}
							</>
						</MaterialModalContext.Provider>
					);
				}}
			</InternalDataContext.Consumer>
		</InternalDataProvider>
	)
}
const useMaterialModalContext = function() {
	const context = React.useContext(MaterialModalContext)
	if (context === undefined) {
		throw new Error("useMaterialModalProvider must be used within a MaterialModalProvider")
	}
	return context;
}

export {MaterialModalProvider, useMaterialModalContext, useInternalDataContext}