import {UnitsType} from "../models/enums";
import {roundNumber} from "./NumberUtils";
import i18n from "i18next";
import {ImperialLength} from "../models/interfaces";
import {MeasurementType} from "../features/project-drawings/features/editor/models/enums";

const FOOT_TO_METER_RATIO = 3.280839895;
export const INCH_TO_FOOT_RATIO = 12;

export function metersToFeet(value: number) {
	return value * FOOT_TO_METER_RATIO;
}

export function squareMetersToSquareFeet(value: number) {
	return value * Math.pow(FOOT_TO_METER_RATIO, 2);
}

export function cubicMetersToCubicFeet(value: number) {
	return value * Math.pow(FOOT_TO_METER_RATIO, 3);
}

export function feetToMeters(value: number | undefined) {
	if (value === undefined) {
		return undefined;
	}
	return value / FOOT_TO_METER_RATIO;
}

function feetToInches(value: number): number {
	return value * INCH_TO_FOOT_RATIO;
}

function inchesToFeet(value: number): number {
	return value / INCH_TO_FOOT_RATIO;
}

export function feetToImperialLength(value: number | undefined): ImperialLength | undefined {
	if (value === undefined) {
		return {feet: undefined, inches: undefined};
	}
	const feet = Math.floor(value);
	const inches = roundNumber(feetToInches(value - feet), 2);
	return {feet: feet === 0 ? undefined : feet, inches: inches === 0 ? undefined : inches};
}

export function imperialLengthToFeet(value: ImperialLength): number | undefined {
	if (value.feet === undefined && value.inches === undefined) {
		return undefined;
	}
	return (value.feet || 0) + inchesToFeet(value.inches || 0);
}

/**
 * Returns value converted to given unit.
 * @param value - meters value
 * @param outputUnit - unit to convert to
 */
export function convertToUnit(value: number | undefined, outputUnit: UnitsType | undefined) {
	if (value) {
		if (outputUnit === UnitsType.METRIC) {
			return value;
		}
		else if (outputUnit === UnitsType.IMPERIAL) {
			return roundNumber(metersToFeet(value), 4);
		}
	}
	return undefined;
}

/**
 * Returns value converted to meters.
 * @param value - meters or feet value
 * @param inputUnit - unit to convert from
 */
export function convertToMeters(value: number | undefined, inputUnit: UnitsType | undefined) {
	if (value) {
		if (inputUnit === UnitsType.METRIC) {
			return value;
		}
		else if (inputUnit === UnitsType.IMPERIAL) {
			return roundNumber(feetToMeters(value), 4);
		}
	}
	return undefined;
}

export function formatBytes(bytes: number): string {
	const divider = 1000
	const magnitude = Math.log(bytes) / Math.log(divider) | 0
	const result = (bytes / Math.pow(divider, magnitude))
	const fixed = result.toFixed(2)

	const suffix = magnitude
		? ('kMGT'[magnitude - 1]) + 'B' : 'B'
	return `${fixed} ${i18n.t(`common.units.data.${suffix}`)}`
}

export function convertMeasurementValueToImperial(metricValue: number, measurementType: MeasurementType) {
	switch (measurementType) {
		case MeasurementType.LENGTH:
			return metersToFeet(metricValue)
		case MeasurementType.AREA:
			return squareMetersToSquareFeet(metricValue)
		case MeasurementType.VOLUME:
			return cubicMetersToCubicFeet(metricValue)
	}
	return metricValue;
}
