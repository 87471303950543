import React from "react";
import {ActiveIconProps} from "../models/interfaces";
import IconSelectable from "./selectableEditorHeaderIcons/IconSelectable";

const IconGuidelines: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			size={27}
			paths={["M 3.9375 3.371094 L 9.96875 3.371094 L 9.96875 4.476562 L 4.476562 4.476562 L 4.476562 9.96875 L 3.371094 9.96875 L 3.371094 3.371094 Z M 14.898438 14.503906 L 16.050781 15.65625 L 17.195312 14.503906 L 17.253906 14.445312 L 17.316406 14.503906 L 17.59375 14.785156 L 17.648438 14.84375 L 16.441406 16.050781 L 17.59375 17.195312 L 17.648438 17.253906 L 17.59375 17.316406 L 17.316406 17.59375 L 17.253906 17.648438 L 17.195312 17.59375 L 16.050781 16.441406 L 14.84375 17.648438 L 14.769531 17.59375 L 14.488281 17.316406 L 14.429688 17.253906 L 14.488281 17.195312 L 15.636719 16.050781 L 14.488281 14.898438 L 14.429688 14.84375 L 14.828125 14.445312 Z M 16.417969 0 L 16.417969 10.386719 L 15.679688 10.386719 L 15.679688 0 Z M 16.417969 21.707031 L 16.417969 32.097656 L 15.679688 32.097656 L 15.679688 21.707031 Z M 0 15.679688 L 10.386719 15.679688 L 10.386719 16.417969 L 0 16.417969 Z M 21.710938 15.679688 L 32.097656 15.679688 L 32.097656 16.417969 L 21.710938 16.417969 Z M 22.246094 3.371094 L 28.726562 3.371094 L 28.726562 9.96875 L 27.625 9.96875 L 27.625 4.476562 L 22.125 4.476562 L 22.125 3.371094 Z M 28.726562 22.246094 L 28.726562 28.726562 L 22.125 28.726562 L 22.125 27.625 L 27.625 27.625 L 27.625 22.125 L 28.726562 22.125 Z M 9.847656 28.726562 L 3.371094 28.726562 L 3.371094 22.125 L 4.476562 22.125 L 4.476562 27.625 L 9.96875 27.625 L 9.96875 28.726562 Z M 9.847656 28.726562 "]}/>
	);
};

export default IconGuidelines
