import React, {ReactNode, useState} from "react";
import {useUpdatedRef} from "../../hooks/useUpdatedRef";
import MuiPopper, {PopperPlacementType} from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";

type UsePopperProps = {
	onClose?: () => void
	placement?: PopperPlacementType,
	disabled?: boolean
	disablePortal?: boolean
}

type RenderPopperProps = {
	target: ReactNode
	content: ReactNode
}

function usePopper(props?: UsePopperProps) {
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [open, setOpen] = useState(false);

	const dataRef = useUpdatedRef({onClose: props?.onClose})
	React.useEffect(() => {
		const {onClose} = dataRef.current
		if (!open && onClose) {
			onClose()
		}
	}, [open, dataRef])

	function toggle() {
		if (!props?.disabled) {
			setOpen(!open)
		}
	}

	const close = React.useCallback(() => {
		setOpen(false)
	}, [])

	function renderPopper({target, content}: RenderPopperProps) {
		return (
			<>
				<div ref={setAnchorEl} onClick={toggle}>
					{target}
				</div>
				<MuiPopper
					open={open}
					disablePortal={props?.disablePortal}
					anchorEl={anchorEl}
					placement={props?.placement}
				>
					<ClickAwayListener onClickAway={close}>
						<div>
							{content}
						</div>
					</ClickAwayListener>
				</MuiPopper>
			</>
		)
	}

	return {
		renderPopper,
		isPopperOpen: open,
		closePopper: close
	}
}

export {usePopper}