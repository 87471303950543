import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TextStyleSelector from "../../../../../../base-konva/features/text/TextStyleSelector";
import {selectTextSelectorStyle, textActions} from "../../text/textSlice";
import {ButtonsGroup} from "../../../../../../../components/layout";
import {TextToolStyle} from "../../../../../../base-konva/types";

export const TextToolOptions: React.FC = () => {
	const dispatch = useDispatch();
	const textSelectorStyle = useSelector(selectTextSelectorStyle);

	function handleStyleSave(style: TextToolStyle) {
		dispatch(textActions.changeTextStyle(style));
	}

	return (
		<>
			<ButtonsGroup>
				<TextStyleSelector style={textSelectorStyle} onStyleChange={handleStyleSave}/>
			</ButtonsGroup>
		</>
	)
}