import React, {createContext, useEffect} from "react";
import {DrawingType} from "../../../models/enums";
import {useBreadcrumbsContext} from "../../breadcrumbs";
import useDrawingData, {useSaveDrawing} from "../hooks/useDrawingData";
import useVersionComplete from "../../project-details/hooks/useVersionComplete";
import {useVersionsModalData} from "../hooks/useVersionsModalData";

export interface DrawingDetailsContextData {
	forceDrawingUpdate: () => void,
	drawingData: ReturnType<typeof useDrawingData>
	drawingSaveData: ReturnType<typeof useSaveDrawing>
	versionCompleteData: ReturnType<typeof useVersionComplete>
	versionsModalsData: ReturnType<typeof useVersionsModalData>
}

const DrawingDetailsDataContext = createContext<DrawingDetailsContextData | undefined>(undefined);

type Props = {
	data: {
		projectId: string
		drawingId: string
		versionId: string
		costCenterId: string
		sectionId: string
	}
	silent?: boolean,
	lockDrawingOnLoad?: boolean
}
const DrawingDetailsProvider: React.FC<Props> = ({
	data,
	children,
	silent = false,
	lockDrawingOnLoad = false,
}) => {
	const drawingData = useDrawingData(
		data.projectId,
		data.drawingId,
		data.versionId,
		data.costCenterId,
		data.sectionId,
		silent,
		lockDrawingOnLoad
	)
	const drawingSaveData = useSaveDrawing()
	const versionCompleteData = useVersionComplete(drawingData.drawingDetails?.drawingType ?? DrawingType.TAKE_OFF);
	const versionsModalsData = useVersionsModalData()

	const {setDrawingData} = useBreadcrumbsContext()
	useEffect(() => {
		setDrawingData({drawingDetails: drawingData.drawingDetails})
		return () => {
			setDrawingData(undefined)
		};
	}, [setDrawingData, drawingData.drawingDetails]);

	const forceDrawingUpdate = () => {
		drawingData.forceDrawingUpdate();
	};

	return (
		<DrawingDetailsDataContext.Provider
			value={{
				forceDrawingUpdate,
				drawingSaveData,
				versionCompleteData,
				drawingData,
				versionsModalsData,
			}}>
			{children}
		</DrawingDetailsDataContext.Provider>
	);
};

function useDrawingDetailsDataContext() {
	const context = React.useContext(DrawingDetailsDataContext)
	if (context === undefined) {
		throw new Error("useDrawingDetailsDataContext must be used within a DrawingDetailsProvider")
	}
	return context;
}

export {DrawingDetailsProvider, useDrawingDetailsDataContext}
