import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {KonvaEventObject} from "konva/types/Node";
import {
	BACKGROUND_RECT_SCALE_INFO_LAYER,
	BaseScaleInfoLayer,
	BaseScaleInfoLayerActions
} from "../../../../../base-konva/features/scaleInfo/BaseScaleInfoLayer";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {
	planUploadConfigActions,
	selectPlanUploadActiveTool,
	selectPlanUploadStageConfig
} from "../config/planUploadConfigSlice";
import {PlanUploadEditorTool} from "../../types";
import {ScaleInfoState} from "../../../../../base-konva/types";
import {planUploadScaleActions, selectPlanUploadScaleConfig} from "../scale/planUploadScaleSlice";

interface PlanUploadScaleInfoLayerProps {
	showTransformer?: boolean;
}


export const PlanUploadScaleInfoLayer: React.FC<PlanUploadScaleInfoLayerProps> = ({showTransformer}) => {
	const activeTool = useSelector(selectPlanUploadActiveTool)
	const stageConfig = useSelector(selectPlanUploadStageConfig);
	const {scale, scaleInfoState} = useSelector(selectPlanUploadScaleConfig)
	const dispatch = useDispatch();
	const setScaleInfoState = function(state: ScaleInfoState) {
		dispatch(planUploadScaleActions.setScaleInfoState({value: state}))
	}

	const switchEditorTool = () => dispatch(
		planUploadConfigActions.switchEditorTool(
			{planUploadEditorTool: PlanUploadEditorTool.SCALE_INFO, isUndoRedoExcluded: true}));
	const switchEditorToolBack = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
		if (evt.target.name() === BACKGROUND_RECT_SCALE_INFO_LAYER && activeTool === PlanUploadEditorTool.SCALE_INFO) {
			dispatch(planUploadConfigActions.switchEditorToolBack({isUndoRedoExcluded: true}));
		}
	};

	const actionsRef = useUpdatedRef<BaseScaleInfoLayerActions>({
		switchEditorTool: switchEditorTool,
		switchEditorToolBack: switchEditorToolBack
	})

	return (
		<BaseScaleInfoLayer
			showTransformer={showTransformer}
			scale={scale}
			stageConfig={stageConfig}
			isEditorToolScaleInfo={activeTool === PlanUploadEditorTool.SCALE_INFO}
			editorLocked={false}
			actionsRef={actionsRef}
			scaleInfoState={scaleInfoState}
			setScaleInfoState={setScaleInfoState}
		/>
	)
};
