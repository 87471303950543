import {PlanTemplateAttachmentApiDto} from "./restModels";

export class PlanTemplateAttachment {
	constructor(
		public readonly id: string,
		public readonly filename: string,
		public readonly contentType?: string,
	) {
	}

	public static fromJson(json: PlanTemplateAttachmentApiDto): PlanTemplateAttachment {
		return new PlanTemplateAttachment(
			json.id,
			json.filename,
			json.contentType,
		);
	}
}