import React from "react";
import "./editableContentLabel.scss";
import IconEditGrey from "../../icons/IconEditGrey";

export interface IEditableContentLabelProps {
	label: string;
	onClick: () => void;
	isNotEditable?: boolean
}

const EditableContentLabel: React.FC<IEditableContentLabelProps> = ({label, onClick, isNotEditable}) => {

	return (
		<span className="editable-content-label">
				{label}{!isNotEditable && <span onClick={onClick}><IconEditGrey/></span>}
			</span>
	);
};

export default EditableContentLabel;
