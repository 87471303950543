import React, {useRef, useState} from "react";
import {KonvaEventObject} from "konva/types/Node";
import {setActiveOnTopLayer} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {selectActiveTextGroupId, textActions} from "./textSlice";
import {TextElement} from "./TextElement";
import {selectPanInProgress} from "../view/viewSlice";
import {EditorTool} from "../../models/enums";
import {useSelectionCleaner} from "../../hooks/select";
import {getId} from "../../../../../../utils";
import {KonvaEventType, TextGroup} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {ToolHookResult} from "../../models/editor";

export function useText(textGroups: TextGroup[]): ToolHookResult {

	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const isTransformMode = useRef(false);
	const panInProgress = useSelector(selectPanInProgress);
	const activeTextGroupId = useSelector(selectActiveTextGroupId)
	const isEditing = Boolean(
		textGroups.find(group => group.id === activeTextGroupId)
			?.textItems.find(item => item.isEditing)
	);

	useSelectionCleaner(EditorTool.TEXT)

	function onMouseUp(evt: KonvaEventObject<KonvaEventType>) {
		if (isTransformMode.current) {
			isTransformMode.current = false
		}
		else {
			// TextElement inner Text element has set id contain "text_element_" string,
			// We are checking if click was on the one of the previous element and prevent from
			// adding overlapping elements.
			if (!(/text_element_/.test(evt.target.attrs.id) || isEditing || panInProgress)) {
				if (textGroups.length === 0 || textGroups.every(group => group.id !== activeTextGroupId)) {
					dispatch(textActions.unselectAllTextItems())
					dispatch(textActions.addTextGroup())
				}

				const pointerPosition = getPointerPosition(evt);
				if (pointerPosition) {
					dispatch(textActions.unselectAllTextItems())
					dispatch(textActions.addTextElement(pointerPosition))
				}
			}
		}
	}

	function render() {
		return setActiveOnTopLayer(textGroups, activeTextGroupId).map(group => (
			group.textItems.map((item) => (
				<TextElement
					key={item.id}
					textItem={item} textGroup={group}
					isTransformMode={isTransformMode}
				/>
			))
		)).flat();
	}

	return {
		id: hookRenderId,
		render,
		tool: EditorTool.TEXT,
		callbacks: {
			onMouseUp
		}
	}
}
