import {KonvaEventObject} from "konva/types/Node";
import {
	BaseStageConfig,
	KonvaEventType,
	Rotation,
	ROTATION_NINETY,
	ROTATION_NONE,
	ROTATION_ONE_EIGHTY,
	ROTATION_TWO_SEVENTY
} from "../types";
import {IRect, Vector2d} from "konva/types/types";
import {CursorStyle} from "../enums";
import {lineAngle} from "../../project-drawings/features/editor/utils";
import Konva from "konva";

export function setCurrentToolOnTop<S, T extends { tool: S }>(renderResults: T[], activeTool: S): T[] {
	const activeRenderResult = renderResults.filter(result => result.tool === activeTool)
	return renderResults.filter(result => result.tool !== activeTool).concat(activeRenderResult)
}

export function getPointerPosition(event: KonvaEventObject<KonvaEventType>) {
	let position: Vector2d | null = null;
	const stage = event.target.getStage();
	const mousePosition = stage?.getPointerPosition();

	if (stage && mousePosition) {
		const transform = stage.getAbsoluteTransform().copy().invert();
		position = transform.point(mousePosition);
	}

	return position;
}

export function setStageCursor(event: KonvaEventObject<Event>, cursor: CursorStyle) {
	const container = event.target.getStage()?.container();
	if (container) container.style.cursor = cursor;
}

const DEFAULT_SNAP_DEGREE_THRESHOLD = 15;

export function getSnappedPosition(
	startPosition: Vector2d,
	pointerPosition: Vector2d,
	snapDegreeThreshold = DEFAULT_SNAP_DEGREE_THRESHOLD
): Vector2d {

	const snapDegree = getSnapDegree(lineAngle(startPosition, pointerPosition), snapDegreeThreshold)

	const
		x1 = startPosition.x,
		y1 = startPosition.y,
		b = 10,
		a = b * Math.tan(Konva.Util._degToRad(snapDegree)),
		x2 = x1 + b,
		y2 = y1 + a,
		x3 = pointerPosition.x,
		y3 = pointerPosition.y

	const k = ((y2 - y1) * (x3 - x1) - (x2 - x1) * (y3 - y1)) / ((y2 - y1) * (y2 - y1) + (x2 - x1) * (x2 - x1))
	const
		x4 = x3 - k * (y2 - y1),
		y4 = y3 + k * (x2 - x1)

	return {x: x4, y: y4}
}

function getSnapDegree(currentDegree: number, snapDegreeThreshold = DEFAULT_SNAP_DEGREE_THRESHOLD) {
	const halfThreshold = snapDegreeThreshold / 2
	let currentAngle = -180 - halfThreshold

	while (currentAngle <= 180 + halfThreshold) {
		const nextAngle = currentAngle + snapDegreeThreshold

		if (currentDegree >= currentAngle && currentDegree < nextAngle) {
			return (currentAngle + nextAngle) / 2
		}

		currentAngle = nextAngle
	}

	return 0
}

/**
 * Stage client rect is rotation independent.
 *
 * If rotation is applied, top left point and dimensions must be updated.
 * @param rotation
 * @param clientRect
 */
export function transformKonvaRect(rotation: Rotation, clientRect: IRect): IRect {
	// noinspection JSSuspiciousNameCombination
	const rotationMap: Record<Rotation, IRect> = {
		[ROTATION_NONE]: {
			...clientRect
		},
		[ROTATION_NINETY]: {
			x: clientRect.x + clientRect.width,
			y: clientRect.y,
			height: clientRect.width,
			width: clientRect.height
		},
		[ROTATION_ONE_EIGHTY]: {
			x: clientRect.x + clientRect.width,
			y: clientRect.y + clientRect.height,
			height: clientRect.height,
			width: clientRect.width
		},
		[ROTATION_TWO_SEVENTY]: {
			x: clientRect.x,
			y: clientRect.y + clientRect.height,
			height: clientRect.width,
			width: clientRect.height
		}
	}
	return rotationMap[rotation]
}

export function rotateClockwise(rotation: Rotation): Rotation {
	const clockwiseRotations: Record<Rotation, Rotation> = {
		[ROTATION_NONE]: ROTATION_NINETY,
		[ROTATION_NINETY]: ROTATION_ONE_EIGHTY,
		[ROTATION_ONE_EIGHTY]: ROTATION_TWO_SEVENTY,
		[ROTATION_TWO_SEVENTY]: ROTATION_NONE,
	}
	return clockwiseRotations[rotation]
}

export function rotateCounterClockwise(rotation: Rotation): Rotation {
	const counterClockwiseRotations: Record<Rotation, Rotation> = {
		[ROTATION_NONE]: ROTATION_TWO_SEVENTY,
		[ROTATION_NINETY]: ROTATION_NONE,
		[ROTATION_ONE_EIGHTY]: ROTATION_NINETY,
		[ROTATION_TWO_SEVENTY]: ROTATION_ONE_EIGHTY,
	}
	return counterClockwiseRotations[rotation]
}

/**
 * When exporting rotated stage, rotation prop rotates only its content,
 * to provide proper exported image size we have to adjust props (x, y, height, width)
 * @param stageConfig
 * @param rotation
 */
export function getRotationAwareSizeProps(stageConfig: BaseStageConfig, rotation: Rotation): IRect {

	function normalSize(): IRect {
		return {
			x: stageConfig.width / 2,
			y: stageConfig.height / 2,
			width: stageConfig.width,
			height: stageConfig.height,
		}
	}

	function swapHeightAndWidth(): IRect {
		// noinspection JSSuspiciousNameCombination
		return {
			x: stageConfig.height / 2,
			y: stageConfig.width / 2,
			width: stageConfig.height,
			height: stageConfig.width,
		}
	}

	const sizes: Record<Rotation, () => IRect> = {
		[ROTATION_NONE]: normalSize,
		[ROTATION_ONE_EIGHTY]: normalSize,

		[ROTATION_NINETY]: swapHeightAndWidth,
		[ROTATION_TWO_SEVENTY]: swapHeightAndWidth,
	}

	return sizes[rotation]()
}