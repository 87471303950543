import {useTranslation} from "react-i18next";
import {TableColumnConfig} from "../../../../../../../../../models/interfaces";
import {TakeoffTemplate} from "../../../../../../../../../models/TakeoffTemplate";
import TableHeaderCell from "../../../../../../../../../components/layout/table/TableHeaderCell";
import TableNoRecords from "../../../../../../../../../components/layout/table/TableNoRecords";
import React from "react";
import {useTemplatesData} from "../../hooks/useTemplatesData";

export function TemplatesTable({
	items,
	onItemClick,
	tableRef
}: ReturnType<typeof useTemplatesData>["browserData"]["templatesTable"]) {
	const {t} = useTranslation();

	const columns: TableColumnConfig<TakeoffTemplate>[] = [{
		id: "name",
		label: t(`projects.addItemModal.table.columns.templateName`),
		sortable: false
	}];

	return (
		<div className="material-modal_container_content_table-container items">
			<table className={"table"} ref={tableRef}>
				<thead>
				<tr>
					{columns.map(col =>
						<TableHeaderCell<TakeoffTemplate>
							key={col.id}
							id={col.id}
							label={col.label}
							width={col.width}
						/>
					)}
				</tr>
				</thead>
				<tbody>
				{items.length ?
					items.map(item =>
						<tr key={item.id} className={"link"} onClick={() => onItemClick(item)}>
							<td>{item.name}</td>
						</tr>)
					: <TableNoRecords cols={columns.length}/>}
				</tbody>
			</table>
		</div>
	);
}