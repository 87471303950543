import {useKeyboard} from "./useKeyboard";
import {useEditorDrawnItemsDataContext} from "../EditorDrawnItemsDataProvider";
import {useIsAnyModalOpenContext} from "../../../../../providers/IsAnyModalOpenProvider";

interface UseKeyboardOnEditorHook {
	isShiftDown: boolean,
	isAltDown: boolean
}

interface UseKeyboardOnEditorProps {
	keyDownMap?: { [code: string]: () => void }
	keyUpMap?: { [code: string]: () => void }
	when: boolean
}

export function useKeyboardOnEditor({
	keyDownMap = {},
	keyUpMap = {},
	when
}: UseKeyboardOnEditorProps): UseKeyboardOnEditorHook {
	const {isAnyModalOpen} = useIsAnyModalOpenContext()
	const {isEditTextVisible} = useEditorDrawnItemsDataContext();
	const {isShiftDown, isAltDown} = useKeyboard({
		keyDownMap,
		keyUpMap,
		when: when && !(isAnyModalOpen || isEditTextVisible)
	});
	return {isShiftDown, isAltDown}
}
