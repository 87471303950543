import {useDispatch, useSelector} from "react-redux";
import {selectActiveTool, selectEditorLockState} from "../features/config/configSlice";
import {countActions, selectActiveCountGroupId, selectGroupActiveItemId} from "../features/count/countSlice";
import {lengthActions, selectActiveLengthId} from "../features/length/lengthSlice";
import {areaActions, selectActiveAreaId, selectAreaActiveDeductionId} from "../features/area/areaSlice";
import {EditorTool} from "../models/enums";
import {useKeyboardOnEditor} from "./useKeyboardOnEditor";
import {selectSelectionState} from "../features/view/viewSlice";
import {useSelectSetter} from "./select";
import {penActions, selectActivePenGroupId} from "../features/pen/penSlice";
import {selectActiveTextGroupId, textActions} from "../features/text/textSlice";
import {imageActions, selectActiveImageId} from "../features/image/imageSlice";
import {getId} from "../../../../../utils";
import {DrawingItemType} from "../../../../base-konva/enums";

export function useDeleteActiveItem() {
	const dispatch = useDispatch();
	const editorLocked = useSelector(selectEditorLockState);
	const activeTool = useSelector(selectActiveTool);

	const activeCountGroupId = useSelector(selectActiveCountGroupId);
	const activeCountItemId = useSelector(selectGroupActiveItemId(activeCountGroupId!));
	const activeLengthItemId = useSelector(selectActiveLengthId);
	const activeAreaItemId = useSelector(selectActiveAreaId);
	const activeAreaDeductionId = useSelector(selectAreaActiveDeductionId(activeAreaItemId))
	const activePenGroupId = useSelector(selectActivePenGroupId)
	const activeTextGroupId = useSelector(selectActiveTextGroupId)
	const activeImageId = useSelector(selectActiveImageId)

	const selection = useSelector(selectSelectionState);
	const selectActions = useSelectSetter();

	const canDeleteSelectedItem = selection.length > 0;
	const canDeleteActiveItem = !editorLocked &&
		!!(
			(activeTool === EditorTool.COUNT && activeCountGroupId)
			|| (activeTool === EditorTool.LENGTH && activeLengthItemId)
			|| (activeTool === EditorTool.AREA && activeAreaItemId)
			|| (activeTool === EditorTool.VOLUME && activeAreaItemId)
			|| (activeTool === EditorTool.PEN && activePenGroupId)
			|| (activeTool === EditorTool.TEXT && activeTextGroupId)
			|| (activeTool === EditorTool.IMAGE && activeImageId)
		)

	function deleteActiveArcItem(removalId: string) {
		selection.forEach(singleSelection => {
			dispatch(lengthActions.removeArcPoint({
				actionId: removalId,
				fragmentId: singleSelection.fragmentId,
				subIds: singleSelection.subItemIds
			}));
		})
	}

	function deleteActiveItem() {
		if (canDeleteSelectedItem) {
			const removalId = getId();
			if (activeTool === EditorTool.ARC) {
				deleteActiveArcItem(removalId)
			}
			else {
				selection.forEach(singleSelection => {
					switch (singleSelection.type) {
						case DrawingItemType.COUNT:
							dispatch(countActions.removeSelectionItems({
								id: singleSelection.itemId,
								subIds: singleSelection.subItemIds,
								actionId: removalId
							}));
							break;
						case DrawingItemType.LENGTH:
							dispatch(lengthActions.removeSelectionItems({
								actionId: removalId,
								id: singleSelection.itemId,
								fragmentId: singleSelection.fragmentId,
								subIds: singleSelection.subItemIds
							}));
							break;
						case DrawingItemType.AREA:
							dispatch(areaActions.removeSelectionItems({
								actionId: removalId,
								id: singleSelection.itemId,
								fragmentId: singleSelection.fragmentId
							}));
							break;
						case DrawingItemType.PEN:
							dispatch(penActions.removeSelectionItems({
								id: singleSelection.itemId,
								subIds: singleSelection.subItemIds,
								actionId: removalId
							}))
							break;
						case DrawingItemType.TEXT:
							dispatch(textActions.removeSelectionItems({
								id: singleSelection.itemId,
								subIds: singleSelection.subItemIds,
								actionId: removalId
							}))
							break;
						case DrawingItemType.IMAGE:
							dispatch(imageActions.removeSelectionItems({
								id: singleSelection.itemId,
								actionId: removalId
							}))
							break;
					}
				});
			}
			selectActions.clearSelection();
		}
		else if (canDeleteActiveItem) {
			const removalId = getId();
			switch (activeTool) {
				case EditorTool.COUNT:
					if (activeCountItemId) {
						dispatch(countActions.deleteCountItem({
							parentId: activeCountGroupId!,
							id: activeCountItemId!,
							actionId: removalId
						}));
						dispatch(countActions.clearActiveCountItem({actionId: removalId}));
					}
					else {
						dispatch(countActions.deleteCountGroup({
							id: activeCountGroupId!,
							actionId: removalId
						}));
						dispatch(countActions.clearActiveCountGroup({actionId: removalId}))
					}
					break;
				case EditorTool.LENGTH:
					dispatch(lengthActions.deleteLength({id: activeLengthItemId!, actionId: removalId}));
					dispatch(lengthActions.clearActiveLength({actionId: removalId}));
					break;
				case EditorTool.AREA:
				case EditorTool.VOLUME:
					if (activeAreaItemId && activeAreaDeductionId) {
						dispatch(areaActions.deleteDeduction({
							areaId: activeAreaItemId,
							deductionId: activeAreaDeductionId,
							actionId: removalId
						}))
					}
					else {
						dispatch(areaActions.deleteArea({
							id: activeAreaItemId!,
							actionId: removalId
						}));
						dispatch(areaActions.clearActiveArea({actionId: removalId}));
					}
					break;
				case EditorTool.PEN:
					dispatch(penActions.deletePenGroup({id: activePenGroupId!, actionId: removalId}))
					dispatch(penActions.deactivatePenGroup({actionId: removalId}))
					break;
				case EditorTool.TEXT:
					dispatch(textActions.deleteTextGroup({id: activeTextGroupId!, actionId: removalId}))
					dispatch(textActions.deactivateTextGroup({actionId: removalId}))
					break;
				case EditorTool.IMAGE:
					dispatch(imageActions.deleteImage({id: activeImageId!, actionId: removalId}))
					dispatch(imageActions.deactivateImage({actionId: removalId}))
					break;
			}
		}
	}

	useKeyboardOnEditor({
		when: canDeleteActiveItem || canDeleteSelectedItem,
		keyUpMap: {
			"Delete": deleteActiveItem,
			"Backspace": deleteActiveItem
		}
	});

	return {
		canDeleteActiveItem: canDeleteActiveItem || canDeleteSelectedItem, deleteActiveItem
	}
}
