import React, {useRef, useState} from "react";
import {KonvaEventObject} from "konva/types/Node";
import {useDispatch, useSelector} from "react-redux";
import {countActions, selectActiveCountGroupId} from "./countSlice";
import {SymbolElement} from "./SymbolElement";
import {setActiveOnTopLayer} from "../../utils";
import {CountMeasurement, ToolHookResult} from "../../models/editor";
import {EditorTool} from "../../models/enums";
import {useSelectionCleaner} from "../../hooks/select";
import {viewActions} from "../view/viewSlice";
import {getId} from "../../../../../../utils";
import {KonvaEventType} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {useImageElementsData} from "./useImageElementsData";

export function useCount(countGroups: CountMeasurement[]): ToolHookResult {
	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const isTransformMode = useRef(false);
	const activeCountGroupId = useSelector(selectActiveCountGroupId);
	const {imageElementsData} = useImageElementsData(countGroups)

	useSelectionCleaner(EditorTool.COUNT)

	function onMouseUp(event: KonvaEventObject<KonvaEventType>) {
		if (isTransformMode.current) {
			isTransformMode.current = false;
		}
		else {
			// SymbolElement outer Line element has set id contain "symbol_element" string,
			// We are checking if click was on the one of the previous element and prevent from
			// adding overlapping elements.
			if (!/symbol_element/.test(event.target.attrs.id) && activeCountGroupId) {
				const mousePosition = getPointerPosition(event);
				if (mousePosition) {
					dispatch(countActions.addCountItem({
						position: mousePosition
					}))
					dispatch(viewActions.clearHighlight({}))
				}
			}
		}
	}

	function render() {
		return setActiveOnTopLayer(countGroups, activeCountGroupId).map(group => {
			const {symbol, remoteSymbol} = group.style
			let symbolImage: HTMLImageElement | undefined;

			if (symbol) {
				symbolImage = imageElementsData?.staticSymbols.get(symbol)?.image
			}
			if (remoteSymbol) {
				symbolImage = imageElementsData?.remoteSymbols.get(remoteSymbol.id)?.image
			}

			const renderSymbolElements = (symbolImage: HTMLImageElement) => {
				return (
					group.countItems.map(item => (
						<SymbolElement
							key={item.id}
							countGroup={group}
							countItem={item}
							isTransformMode={isTransformMode}
							symbolImage={symbolImage}
						/>
					))
				)
			}
			return symbolImage ? renderSymbolElements(symbolImage) : null;
		}).flat()
	}

	return {
		id: hookRenderId,
		render,
		tool: EditorTool.COUNT,
		callbacks: {
			onMouseUp,
		}
	}
}
