import {useTranslation} from "react-i18next";
import ModalButtons from "../../../../../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../../../../../components/ui/Button";
import React from "react";

type ContentFooterProps = {
	closeModal: () => void
	canSaveItems: boolean
	saveItems: () => void
	canAddItems: boolean
	addItems: () => boolean
	clearErrors: () => void
}

export function ContentFooter({closeModal, canSaveItems, saveItems, canAddItems, addItems, clearErrors}: ContentFooterProps) {
	const {t} = useTranslation();
	return (
		<ModalButtons>
			<Button label={t("projects.addItemModal.saveItems")}
					variant={"primary"}
					disabled={!canSaveItems}
					onClick={saveItems}/>
			<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={() => {
				closeModal()
				clearErrors()
			}
			}/>
			<Button label={t("projects.addItemModal.addItems")}
					variant={"primary"}
					disabled={!canAddItems}
					onClick={() => {
						const succeed = addItems()
						if (succeed)
							closeModal()
					}}/>
		</ModalButtons>
	)
}