import React, {FC} from "react";
import {useSelector} from "react-redux";
import {EditorLine, EditorPoint, LengthFragment, LengthMeasurement, PreviewLineProps} from "../../models/editor";
import {selectActiveLengthId} from "./lengthSlice";
import {Vector2d} from "konva/types/types";
import {Group} from "react-konva";
import {LengthFragmentElement} from "./LengthFragmentElement";
import {selectExportInProgress, selectSnappingState} from "../view/viewSlice";
import {getEditorLine} from "../../utils";
import {useSnapToPoint} from "./snapUtils";
import {getId} from "../../../../../../utils";
import {getSnappedPosition} from "../../../../../base-konva/utils";

type LengthElementProps = {
	length: LengthMeasurement
	isPointerOverLayer: boolean,
	pointerLocation?: Vector2d
}

const _LengthElement: FC<LengthElementProps> = function({
	length,
	isPointerOverLayer,
	pointerLocation,
}) {
	const {snapToPoint} = useSnapToPoint()
	const snapping = useSelector(selectSnappingState);
	const lengthActive = useSelector(selectActiveLengthId) === length.id;
	const exportInProgress = useSelector(selectExportInProgress);
	const visible = length.visible || exportInProgress;
	const previewLineProps = getPreviewLineProps()

	function getPreviewLineProps(): PreviewLineProps {
		if (lengthActive) {
			const activeFragment = length.lengthFragments.find(fragment => fragment.id === length.activeLengthFragmentId)
			if (activeFragment) {
				return {
					previewLine: getPreviewLine(activeFragment),
					previewLineFragmentId: activeFragment.id
				}
			}
		}
		return {
			previewLine: null,
			previewLineFragmentId: null
		}
	}

	function getPreviewLine(lengthFragment: LengthFragment): EditorLine | null {
		const {activePointId, lastMouseUpPosition} = lengthFragment;
		let previewLine: EditorLine | null = null;
		const pointerPosition = pointerLocation ?? {x: 0, y: 0}

		if (activePointId) {
			let activePoint: EditorPoint | null = null;
			for (let line of lengthFragment.lines) {
				if (line.from.id === activePointId) {
					activePoint = line.from;
					break;
				}
				if (line.to.id === activePointId) {
					activePoint = line.to;
					break;
				}
			}
			if (activePoint) {
				const pointer: EditorPoint = {
					id: getId(),
					position: snapping.point ? snapToPoint(pointerPosition).snapTo : snapping.angle
						? getSnappedPosition(activePoint.position, pointerPosition)
						: pointerPosition
				}
				previewLine = getEditorLine(getId(), activePoint, pointer)
			}
		}
		else if (lastMouseUpPosition) {
			const lastPointer: EditorPoint = {
				id: getId(),
				position: lastMouseUpPosition
			}
			const pointer: EditorPoint = {
				id: getId(),
				position: snapping.point ? snapToPoint(pointerPosition).snapTo : snapping.angle
					? getSnappedPosition(lastPointer.position, pointerPosition)
					: pointerPosition
			}
			previewLine = getEditorLine(getId(), lastPointer, pointer)
		}

		return previewLine;
	}

	return <Group visible={visible}>
		{length.lengthFragments.map(fragment => {
			const previewLine = previewLineProps.previewLineFragmentId === fragment.id
				? previewLineProps.previewLine
				: null

			return (
				<LengthFragmentElement
					key={fragment.id}
					lengthFragment={fragment}
					lengthFragmentActive={length.activeLengthFragmentId === fragment.id}
					isPointerOverLayer={isPointerOverLayer}
					lengthId={length.id}
					lengthStyle={length.style}
					lengthActive={lengthActive}
					lengthDrops={length.drops}
					lengthHasPreviewLine={previewLineProps.previewLineFragmentId !== null}
					previewLine={previewLine}
				/>
			);
		})}
	</Group>
}
export const LengthElement = React.memo(_LengthElement)