import {ImageData} from "../../../../../base-konva/types";
import React, {useState} from "react";
import {getId} from "../../../../../../utils";
import {PlanUploadEditorTool, PlanUploadToolHookResult} from "../../types";
import {PlanUploadImageElement} from "./PlanUploadImageElement";
import {usePlanUploadSelectionCleaner} from "../../hooks/planUploadSelect";

export function usePlanUploadImage(images: ImageData[]): PlanUploadToolHookResult {

	const [hookRenderId] = useState(getId());

	usePlanUploadSelectionCleaner(PlanUploadEditorTool.IMAGE)

	function render() {
		return images.map(image => <PlanUploadImageElement key={image.id} imageData={image}/>)
	}

	return {
		id: hookRenderId,
		render,
		tool: PlanUploadEditorTool.IMAGE,
		callbacks: {}
	}
}