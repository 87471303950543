import {PlanTemplateDataApiDto} from "./restModels";
import {PlanTemplatePageData} from "./PlanTemplatePageData";
import {PlanTemplateAttachment} from "./PlanTemplateAttachment";
import {checkIfAttachmentIsPdf} from "../features/project-details/utils/planTemplateUtils";

export class PlanTemplateData {
	constructor(
		public readonly id: string,
		public readonly attachment: PlanTemplateAttachment,
		public readonly name: string,
		public readonly pages: PlanTemplatePageData[],
		public readonly isPdf: boolean,
		public readonly fullPageCount?: number,
		public readonly dpi?: number
	) {
	}

	public static fromJson(json: PlanTemplateDataApiDto): PlanTemplateData {
		return new PlanTemplateData(
			json.id,
			PlanTemplateAttachment.fromJson(json.attachment),
			json.name,
			json.pages.map(PlanTemplatePageData.fromJson),
			checkIfAttachmentIsPdf(json.attachment.filename, json.attachment.contentType),
			json.fullPageCount,
			json.dpi
		);
	}
}