import React from "react";
import IconBlueArrow from "../../../../components/icons/IconBlueArrow";
import "./treeRow.scss"
import {get} from "../../../../utils/ClassNameUtils";

type Props = {
	header: React.ReactNode
	toggleExpand: () => void
	isOpen: boolean
}
export const TreeRow: React.FC<Props> = ({header, toggleExpand, isOpen, children}) => {
	return (
		<div className="tree-row">
			<div className="tree-row_header">
				<div
					className={get("tree-row_header_toggle-arrow", {"is-open": isOpen})}
					onClick={toggleExpand}>
					<IconBlueArrow/>
				</div>
				{header}
			</div>
			{isOpen ? (
				<div className="tree-row_content">{children}</div>
			) : null}
		</div>
	)
}