import React, {useEffect, useState} from "react";

const AnimatedEllipsis: React.FC = () => {
	const [displayedDots, setDisplayedDots] = useState<string>("")


	useEffect(() => {
		const animateDots = () => {
			setDisplayedDots((prevState => prevState.length === 3 ? "" : prevState + "."))
		}

		const dotsAnimation = setInterval(animateDots, 500)
		return () => {
			clearInterval(dotsAnimation);
		}
	}, [])


	return (
		<span>{displayedDots}</span>
	);
};

export default AnimatedEllipsis
