import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectTabsVisibilityState} from "../config/configSlice";
import IconWithTooltip from "../../../../../../components/ui/IconWithTooltip";
import IconVisibility from "../../icons/unselectableEditorHeaderIcons/IconVisibility";
import {EditorTool, GlobalVisibilityOptions} from "../../models/enums";
import {TabsVisibilityState} from "../../models/editor";
import {lengthActions} from "../length/lengthSlice";
import {countActions} from "../count/countSlice";
import {areaActions} from "../area/areaSlice";
import {penActions} from "../pen/penSlice";
import {textActions} from "../text/textSlice";
import {imageActions} from "../image/imageSlice";
import "./editorHeaderVisibilityDropdown.scss";
import {Popper} from "../../../../../../components/popper";
import {SetVisibilityActionPayload} from "../../../../../base-konva/types";

export function EditorHeaderVisibilityDropdown({disabled}: { disabled?: boolean }) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const tabsVisibilityState = useSelector(selectTabsVisibilityState);

	const button = (
		<IconWithTooltip
			icon={<IconVisibility disabled={disabled} onClick={() => {}}/>}
			tooltipText={t("editor.tooltip.visibility")}
		/>
	)

	function toggleTabVisibility(key: GlobalVisibilityOptions) {
		return () => {
			const visible = !tabsVisibilityState[key];

			dispatch(configActions.setTabsVisibilityState({
				...tabsVisibilityState,
				[key]: visible
			}))

			const payload: SetVisibilityActionPayload = {id: "", visible, setAll: true};
			switch (key) {
				case GlobalVisibilityOptions.LENGTH:
					dispatch(lengthActions.setVisibility(payload));
					break;
				case GlobalVisibilityOptions.COUNT:
					dispatch(countActions.setVisibility(payload));
					break;
				case GlobalVisibilityOptions.AREA:
					dispatch(areaActions.setVisibility({...payload, type: EditorTool.AREA}));
					break;
				case GlobalVisibilityOptions.VOLUME:
					dispatch(areaActions.setVisibility({...payload, type: EditorTool.VOLUME}));
					break;
				case GlobalVisibilityOptions.LAYERS:
					dispatch(penActions.setVisibility(payload));
					dispatch(textActions.setVisibility(payload));
					dispatch(imageActions.setVisibility(payload));
					break;
			}
		}
	}


	const globalVisibilityOptionsLabelsTranslations = {
		[GlobalVisibilityOptions.AREA]: t(`editor.globalVisibilityOptionsLabels.area`),
		[GlobalVisibilityOptions.COUNT]: t(`editor.globalVisibilityOptionsLabels.count`),
		[GlobalVisibilityOptions.LENGTH]: t(`editor.globalVisibilityOptionsLabels.length`),
		[GlobalVisibilityOptions.VOLUME]: t(`editor.globalVisibilityOptionsLabels.volume`),
		[GlobalVisibilityOptions.VALUE_MARKS]: t(`editor.globalVisibilityOptionsLabels.value_marks`),
		[GlobalVisibilityOptions.LAYERS]: t(`editor.globalVisibilityOptionsLabels.layers`)

	}

	return (
		<Popper target={button} disabled={disabled}>
			<div className="editor-header-visibility-dropdown">
				{Object.keys(tabsVisibilityState).map((t) => {
					const tab = t as keyof TabsVisibilityState
					return (
						<div className="editor-header-visibility-dropdown_item"
							 key={tab} onClick={toggleTabVisibility(tab)}>
							{tabsVisibilityState[tab]
								? <EyeIcon/>
								: <EyeOffIcon/>}
							<span>{globalVisibilityOptionsLabelsTranslations[tab]}</span>
						</div>
					)
				})}
			</div>
		</Popper>
	)
}

function EyeIcon() {
	const fill = "#707070"

	return (
		<svg width={17} height={24} viewBox="0 0 24 24">
			<path fill={fill}
				  d="M12,4.33C5.65,4.33.5,12,.5,12S5.65,19.67,12,19.67,23.5,12,23.5,12,18.35,4.33,12,4.33Zm0,12.6A4.93,4.93,0,1,1,16.93,12,4.93,4.93,0,0,1,12,16.93Z"/>
			<path fill={fill} d="M12,8.38A3.62,3.62,0,1,0,15.62,12,3.62,3.62,0,0,0,12,8.38Z"/>
		</svg>
	)
}

function EyeOffIcon() {
	const fill = "#c5c5c5"

	return (
		<svg width={17} height={24} viewBox="0 0 24 24">
			<path fill={fill}
				  d="M12.27,8.14A3.8,3.8,0,0,0,11,8.37L15.65,13a3.57,3.57,0,0,0,.24-1.26A3.62,3.62,0,0,0,12.27,8.14Z"/>
			<path fill={fill}
				  d="M12.27,6.83a4.92,4.92,0,0,1,4.93,4.93A4.86,4.86,0,0,1,16.64,14l2.59,2.59a24.33,24.33,0,0,0,4.54-4.83s-5.15-7.67-11.5-7.67A9.54,9.54,0,0,0,7.89,5.25L10,7.39A4.86,4.86,0,0,1,12.27,6.83Z"/>
			<path fill={fill}
				  d="M15.76,15.24l-.93-.92L9.71,9.2l-.92-.93L6.55,6,3.43,2.92,2.37,4l3,2.94A24.46,24.46,0,0,0,.77,11.76s5.15,7.66,11.5,7.66a9.55,9.55,0,0,0,4.39-1.16l3.39,3.39,1.06-1.06L18,17.48Zm-3.49,1.44a4.92,4.92,0,0,1-4.92-4.92,4.87,4.87,0,0,1,.56-2.25l1,1a3.71,3.71,0,0,0-.24,1.26,3.63,3.63,0,0,0,3.62,3.62,3.71,3.71,0,0,0,1.26-.24l1,1A4.87,4.87,0,0,1,12.27,16.68Z"/>
		</svg>
	)
}