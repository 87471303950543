import React from "react";
import {useSelector} from "react-redux";
import {selectPlanUploadActiveTool, selectPlanUploadStageConfig} from "../features/config/planUploadConfigSlice";
import {selectPlanUploadPanInProgress} from "../features/view/planUploadViewSlice";
import {selectPlanUploadPenGroups, usePlanUploadPen} from "../features/pen";
import {KonvaEventObject} from "konva/types/Node";
import {Group, Rect} from "react-konva";
import {setCurrentToolOnTop} from "../../../../base-konva/utils";
import {KonvaEventType} from "../../../../base-konva/types";
import {selectPlanUploadTextGroups} from "../features/text/planUploadTextSlice";
import {usePlanUploadText} from "../features/text/usePlanUploadText";
import {selectPlanUploadImages} from "../features/image/planUploadImageSlice";
import {usePlanUploadImage} from "../features/image/usePlanUploadImage";

export const PlanUploadMeasurementsLayer: React.FC<{
	listening?: boolean
}> = function({
	listening = false
}) {
	const stageConfig = useSelector(selectPlanUploadStageConfig)
	const activeTool = useSelector(selectPlanUploadActiveTool)
	const panInProgress = useSelector(selectPlanUploadPanInProgress);

	const penGroups = useSelector(selectPlanUploadPenGroups)
	const textGroups = useSelector(selectPlanUploadTextGroups)
	const images = useSelector(selectPlanUploadImages)

	const penResult = usePlanUploadPen(penGroups)
	const textResult = usePlanUploadText(textGroups)
	const imageResult = usePlanUploadImage(images)


	const resultArray = [penResult, textResult, imageResult]

	function getCurrent() {
		return resultArray.find(result => result.tool === activeTool)
	}

	function handleMouseDown(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		getCurrent()?.callbacks.onMouseDown?.(e)
	}

	function handleMouseUp(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		else getCurrent()?.callbacks.onMouseUp?.(e)
	}

	function handleMouseMove(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		getCurrent()?.callbacks.onMouseMove?.(e)
	}

	return (
		<Group
			listening={listening}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			onTouchStart={handleMouseDown}
			onTouchEnd={handleMouseUp}
			onTouchMove={handleMouseMove}
		>
			<Rect
				name={"background_rect_plan_upload_drawn_layer"}
				width={stageConfig.width}
				height={stageConfig.height}
			/>
			{setCurrentToolOnTop(resultArray, activeTool).map(result => (
				<Group key={result.id}>
					{result.render()}
				</Group>
			))}
		</Group>
	)
}