import React, {useState} from "react";
import Button from "../../../../../components/ui/Button";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import ConfigHelper from "../features/config/ConfigHelper";
import {selectEditorLockState} from "../features/config/configSlice";
import ScaleModal from "../features/scale/ScaleModal";
import {useScaleSetDataContext} from "../features/scale/ScaleSetProvider";
import CommonDropdown from "../../../../../components/ui/CommonDropdown";
import DropdownItem from "../../../../../components/ui/DropdownItem";
import {EditorPanelMode, MaterialType} from "../models/enums";
import {IconPin} from "../icons/side_panel/IconPin";
import "./measurementMaterialAddButton.scss";
import {useEditorPanelDataContext} from "../features/panel/EditorPanelProvider";
import {useMaterialModalContext} from "../features/panel/material/MaterialModalProvider";
import {selectScaleConfig} from "../features/scale/scaleSlice";

const MeasurementMaterialAddButton = () => {
	const {openAddItemsModal} = useMaterialModalContext()
	const [isScaleOpen, setIsScaleOpen] = useState(false);
	const scaleConfig = useSelector(selectScaleConfig);
	const editorLocked = useSelector(selectEditorLockState);
	const {t} = useTranslation();
	const {openScaleSetModal} = useScaleSetDataContext()
	const {setEditorPanelMode} = useEditorPanelDataContext()

	function handleModalTabOpen(initialMaterialType: MaterialType) {
		return function() {
			if (editorLocked) return;
			ConfigHelper.isScaleSet(scaleConfig)
				? openAddItemsModal(initialMaterialType)
				: setIsScaleOpen(true);
		}
	}

	function handlePinnedItemsOpen() {
		ConfigHelper.isScaleSet(scaleConfig) ? setEditorPanelMode(EditorPanelMode.PINNED_ITEMS) : setIsScaleOpen(true);
	}

	function onScaleModalConfirm() {
		setIsScaleOpen(false);
		openScaleSetModal()
	}

	const dropdownButton = (
		<Button
			dropdownArrow={true}
			variant={"secondary"}
			label={t("editor.addNewButton")}
		/>
	)

	const pinnedItemsLabel = (
		<div className="measurement-material-add-button_pinned-items-label">
			<IconPin/>{t("editor.panel.pinnedItems.addItemButton")}
		</div>
	)

	return (
		<>
			{isScaleOpen && <ScaleModal
				isOpen={true}
				onClose={() => setIsScaleOpen(false)}
				onSave={onScaleModalConfirm}
				disableSave={false}
			/>}
			<CommonDropdown variant={"invertedBlue"} disabled={editorLocked} button={dropdownButton}>
				<DropdownItem label={t("projects.addItemModal.preBuilds.tabName")}
							  onClick={handleModalTabOpen(MaterialType.PREBUILD)}/>
				<DropdownItem label={t("projects.addItemModal.catalog.tabName")}
							  onClick={handleModalTabOpen(MaterialType.CATALOG)}/>
				<DropdownItem label={t("projects.oneOffModal.addButtonLabel")}
							  onClick={handleModalTabOpen(MaterialType.ONE_OFF)}/>
				<DropdownItem label={t("projects.addItemModal.takeoffTemplates.tabName")}
							  onClick={handleModalTabOpen(MaterialType.TEMPLATE)}/>
				<DropdownItem label={pinnedItemsLabel}
							  onClick={handlePinnedItemsOpen}/>
			</CommonDropdown>
		</>
	);
};

export default MeasurementMaterialAddButton
