export function getDefaultSelectableIconClasses(active: boolean, disabled: boolean): string {
	return [
		"icon-selectable",
		active && "is-active",
		disabled && "is-disabled",
	].filter(Boolean).join(" ")
}

export function getDefaultAnchorIconClasses(active: boolean, disabled: boolean): string {
	return [
		"icon-anchor",
		active && "is-active",
		disabled && "is-disabled",
	].filter(Boolean).join(" ")
}

export function getArcToolIconClasses(active: boolean, disabled: boolean): string {
	return [
		"icon-arc-tool",
		active && "is-active",
		disabled && "is-disabled",
	].filter(Boolean).join(" ")
}
