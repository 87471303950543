import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {EditorTool} from "../../models/enums";
import {configActions, selectActiveTool, selectEditorLockState, selectStageConfig} from "../config/configSlice";
import {KonvaEventObject} from "konva/types/Node";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {
	BACKGROUND_RECT_SCALE_INFO_LAYER,
	BaseScaleInfoLayer,
	BaseScaleInfoLayerActions
} from "../../../../../base-konva/features/scaleInfo/BaseScaleInfoLayer";
import {ScaleInfoState} from "../../../../../base-konva/types";
import {scaleActions, selectScale, selectScaleConfig} from "../scale/scaleSlice";

interface ScaleInfoLayerProps {
	showTransformer?: boolean;
}

export const ScaleInfoLayer: React.FC<ScaleInfoLayerProps> = ({showTransformer}) => {
	const activeTool = useSelector(selectActiveTool)
	const stageConfig = useSelector(selectStageConfig);
	const editorLocked = useSelector(selectEditorLockState);
	const scale = useSelector(selectScale);
	const scaleConfig = useSelector(selectScaleConfig)
	const dispatch = useDispatch();

	const scaleInfoState = scaleConfig.scaleInfoState
	const setScaleInfoState = function(state: ScaleInfoState) {
		dispatch(scaleActions.setScaleInfoState({value: state}))
	}

	const switchEditorTool = () => dispatch(configActions.switchEditorTool({
		editorTool: EditorTool.SCALE_INFO,
		isUndoRedoExcluded: true
	}));
	const switchEditorToolBack = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
		if (evt.target.name() === BACKGROUND_RECT_SCALE_INFO_LAYER && activeTool === EditorTool.SCALE_INFO) {
			dispatch(configActions.switchEditorToolBack({isUndoRedoExcluded: true}));
		}
	};

	const actionsRef = useUpdatedRef<BaseScaleInfoLayerActions>({
		switchEditorTool: switchEditorTool,
		switchEditorToolBack: switchEditorToolBack
	})

	return (
		<BaseScaleInfoLayer
			showTransformer={showTransformer}
			scale={scale}
			stageConfig={stageConfig}
			isEditorToolScaleInfo={activeTool === EditorTool.SCALE_INFO}
			editorLocked={editorLocked}
			actionsRef={actionsRef}
			scaleInfoState={scaleInfoState}
			setScaleInfoState={setScaleInfoState}
		/>
	)
};
