import {PlanSummaryItem} from "../models/PlanSummaryItem";
import {UnitsType} from "../models/enums";
import {convertMeasurementValueToImperial} from "./ConversionUtils";

type MarkupQtyLike = Pick<PlanSummaryItem, "markupQty" | "measurementType">
type ValueLike = Pick<PlanSummaryItem, "value" | "measurementType">
type DeductionLike = Pick<PlanSummaryItem, "deduction" | "measurementType">

export function formatMarkupQty<T extends MarkupQtyLike>(item: T, unitType: UnitsType) {
	const result = unitType === UnitsType.IMPERIAL
		? convertMeasurementValueToImperial(item.markupQty, item.measurementType)
		: item.markupQty
	return result.toFixed(2)
}

export function formatValue<T extends ValueLike>(item: T, unitType: UnitsType) {
	const result = unitType === UnitsType.IMPERIAL
		? convertMeasurementValueToImperial(item.value, item.measurementType)
		: item.value
	return result.toFixed(2)
}

export function formatDeduction<T extends DeductionLike>(item: T, unitType: UnitsType) {
	if (item.deduction === undefined || item.deduction === null) {
		return "-"
	}

	const result = unitType === UnitsType.IMPERIAL
		? convertMeasurementValueToImperial(item.deduction, item.measurementType)
		: item.deduction
	return result?.toFixed(2)
}