import {AxiosInstance} from "axios";
import {PaginatedResponse} from "../models/interfaces";
import {getPaginationFromHeaders} from "../utils/PaginationUtils";
import {CatalogItem} from "../models/CatalogItem";
import {CatalogItemGroup} from "../models/CatalogItemGroup";
import {paramsSerializer} from "../utils/ApiUtils";
import {CatalogItemUpdateApiDto} from "../models/restModels";

export const CATALOG_QUERY_KEY = "CATALOG_QUERY_KEY"

export default class CatalogsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchCatalogs(
		page: number,
		size: number,
		onlyFavorites: boolean,
		query?: string,
		groupId?: number | null
	): Promise<PaginatedResponse<CatalogItem>> {
		const params = {page, size, query, is_favorite: onlyFavorites ? true : undefined};
		const url = `/api/catalogs${(groupId || groupId === null) ? "?group_id=" + groupId : ""}`;
		return this.axiosInstance.get(url, {params, paramsSerializer}).then(resp => {
			const paginationConfig = getPaginationFromHeaders(resp.headers);
			const records = resp.data.map(CatalogItem.fromJson);
			return ({records, ...paginationConfig})
		})
	}

	putCatalogsAttributes(items: CatalogItemUpdateApiDto[]) {
		const url = `/api/catalogs_attributes`
		const data = {
			catalogsAttributes: items
		}
		return this.axiosInstance.put(url, data)
	}

	fetchCatalogGroups(parentGroupId?: number | null): Promise<CatalogItemGroup[]> {
		const params = {parent_group_id: parentGroupId};
		const url = `/api/catalog_groups`;
		return this.axiosInstance.get(url, {params}).then(resp => resp.data.map(CatalogItemGroup.fromJson));
	}
}
