import React from "react";
import {capitalizeString, uuidRegexp} from "../../../utils/TextUtils";
import DrawingDetailsBreadcrumb from "./DrawingDetailsBreadcrumb";

const DrawingBreadcrumb: React.FC<any> = ({match}) => {

	return (
		<>
			{uuidRegexp.test(match.params.drawingId) ?
				<DrawingDetailsBreadcrumb drawingId={match.params.drawingId}/> :
				<span>{capitalizeString(match.params.drawingId)}</span>
			}
		</>
	);
};

export default DrawingBreadcrumb
