import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconRedo: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width={32} height={32} viewBox="0 0 32 32" style={{cursor: cursor}}>
				<path fill={fill}
					  d="M18.1859308,8.19166316 C18.4090451,7.96166467 18.7569197,7.93866761 19.004983,8.12267005 L19.083666,8.19167085 L22.8140767,12.0373107 L22.8875282,12.1279413 C23.0398721,12.3546438 23.0374527,12.658443 22.8802707,12.8826966 L22.8140729,12.962757 L19.0836623,16.808333 L18.9957459,16.8840533 C18.7758326,17.0411028 18.4811294,17.03861 18.2635904,16.8765747 L18.1859271,16.808333 L18.1124744,16.7177025 C17.9601281,16.4909999 17.9625463,16.1871987 18.119729,15.9629438 L18.1859271,15.8828828 L20.77,13.217 L13.5651192,13.2173167 L13.377714,13.22239 C11.1967794,13.3402659 9.22369652,15.4947056 9.22369652,17.8471551 C9.22369652,20.4992072 10.9094511,22.6596318 13.35377,22.777609 L13.5651192,22.7826833 L23.3881517,22.7826833 L23.4785662,22.7892827 C23.7736084,22.8327558 24,23.0857483 24,23.3913416 C24,23.6938791 23.7781136,23.9448624 23.4873967,23.9920337 L23.3881517,24 L13.5651192,24 L13.3417448,23.9956814 C10.1634401,23.8722604 8,21.1198096 8,17.8471551 C8,14.8430965 10.4973251,12.1290747 13.3600464,12.0044658 L13.5651192,12 L20.983,12 L18.1859233,9.11711331 L18.1124714,9.02648215 C17.9410839,8.77144034 17.965571,8.41882216 18.1859308,8.19166316 Z"/>
			</svg>
		</IconUnselectable>
	);
};

export default IconRedo