import {SummaryMeasurementApiDto} from "./restModels";
import i18n from "../i18n";
import {MaterialType, MeasurementType} from "../features/project-drawings/features/editor/models/enums";
import LoggerService from "../services/LoggerService";

export default class SummaryMeasurement {
	constructor(public readonly id: string,
		public readonly materialId: number,
		public readonly materialName: string,
		public readonly materialPartNumber: string,
		public readonly materialType: MaterialType,
		public readonly fitTimeId: number | undefined,
		public readonly laborTime: number | undefined,
		public readonly measurementValue: number,
		public readonly measurementType: MeasurementType,
		public readonly drawingName: string,
		public readonly drawingVersion: string,
		public readonly planScale: number | undefined,
		public readonly markupQty: number,
		public readonly deduction: number | undefined,
	) {}

	public static fromJson(json: SummaryMeasurementApiDto): SummaryMeasurement {
		const measurementType = MeasurementType[json.measurementType];
		if (measurementType === undefined) {
			const error = new Error(`Unknown Measurement type: ${json.measurementType}`);
			LoggerService.logError(error);
			throw error;
		}
		const materialType = MaterialType[json.materialType];
		if (materialType === undefined) {
			const error = new Error(`Unknown Material type: ${json.materialType}`);
			LoggerService.logError(error);
			throw error;
		}
		const calculatedMeasurementValue = SummaryMeasurement.GetValue(json.planScale, measurementType, json.measurementValue);
		const calculatedDeductionValue = json.deduction
			? SummaryMeasurement.GetValue(json.planScale, measurementType, json.deduction)
			: undefined

		return new SummaryMeasurement(
			json.id,
			json.materialId,
			json.materialName,
			json.materialPartNumber,
			materialType,
			json.fitTimeId,
			json.laborTime,
			calculatedMeasurementValue - (calculatedDeductionValue ?? 0), measurementType,
			json.drawingName,
			json.drawingVersion,
			json.planScale,
			calculatedMeasurementValue,
			calculatedDeductionValue
		);
	}

	getFormattedDrawingLabel() {
		return `${this.drawingName} - ${i18n.t("common.version")} ${this.drawingVersion} 
		(${i18n.t("projects.summary.latestCompletedVersion")})`
	}

	static GetValue(planScale: number | undefined, measurementType: MeasurementType, measurementValue: number): number {
		if (!planScale) return 0;
		switch (measurementType) {
			case MeasurementType.AREA:
				return (measurementValue * Math.pow(planScale, 2));
			case MeasurementType.COUNT:
				return measurementValue;
			case MeasurementType.LENGTH:
				return (measurementValue * planScale);
			case MeasurementType.VOLUME:
				return (measurementValue * Math.pow(planScale, 3));
			default:
				return 0;
		}
	}
}
