import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {initialState, reducers} from "../../../../../base-konva/features/text/baseTextSlice";


export const textSlice = createSlice({
	name: "text",
	initialState,
	reducers

})

export const textActions = textSlice.actions;
export const selectTextGroups = (state: RootState) => state.undoGroup.present.text.textGroups;
export const selectActiveTextGroupId = (state: RootState) => state.undoGroup.present.text.activeTextGroupId;
export const selectTextSelectorStyle = (state: RootState) => state.undoGroup.present.text.textSelectorStyle;

export const textReducer = textSlice.reducer;