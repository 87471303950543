import React, {createContext, useReducer, useState} from "react";
import UserSettings from "../models/UserSettings";
import {KeySettingsType, Status, UnitsType} from "../models/enums";
import useUserSettings from "../features/settings/components/useUserSettings";
import {apiInstance} from "../api/api";
import NotificationService from "../services/NotificationService";
import {useTranslation} from "react-i18next";
import LoggerService from "../services/LoggerService";
import {useDirectProjectAccessDataContext} from "./DirectProjectAccessDataProvider";

interface UserSettingsContextData {
	settings: UserSettings | undefined,
	saveSettings: (unitsType: UnitsType, keyType: KeySettingsType, downloadKeyTable: boolean) => void,
	loadStatus: Status,
	saveStatus: Status;
}

const UserSettingsDataContext = createContext<UserSettingsContextData | undefined>(undefined);

const UserSettingsProvider: React.FC = ({children}) => {
	const [reloadCounter, forceUpdate] = useReducer(x => x + 1, 0);
	const {settings, loadStatus} = useUserSettings(reloadCounter);
	const [saveStatus, setSaveStatus] = useState<Status>(Status.IDLE);
	const {redirectToProjectOrProjectsList} = useDirectProjectAccessDataContext();
	const {t} = useTranslation();

	const saveSettings = (unitsType: UnitsType, keyType: KeySettingsType, downloadKeyTable: boolean) => {
		setSaveStatus(Status.LOADING);
		apiInstance.settingsApi.putSettings(unitsType, keyType, downloadKeyTable)
			.then(() => {
				forceUpdate();
				redirectToProjectOrProjectsList();
				setSaveStatus(Status.SUCCESS);
				NotificationService.successNotification(t("common.success"), t("settings.mainSettings.saveSuccess"));
			})
			.catch(err => {
				LoggerService.logError(err);
				setSaveStatus(Status.ERROR);
				NotificationService.errorNotification(t("common.error"), t("settings.mainSettings.saveErrorDesc"))
			});
	};

	return (
		<UserSettingsDataContext.Provider value={{settings, saveSettings, loadStatus, saveStatus}}>
			{children}
		</UserSettingsDataContext.Provider>
	);
};

function useUserSettingsDataContext() {
	const context = React.useContext(UserSettingsDataContext)
	if (!context) {
		throw new Error("useUserSettingsDataContext must be used within a UserSettingsProvider")
	}
	return context
}

const UserSettingsDataContextBridge = {
	Consumer: UserSettingsDataContext.Consumer,
	Provider: UserSettingsDataContext.Provider
}

export {UserSettingsProvider, useUserSettingsDataContext, UserSettingsDataContextBridge}
