import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {highlightStyle} from "../../../../base-konva/constants";
import {planUploadViewActions, selectPlanUploadHighlightState} from "../features/view/planUploadViewSlice";

export function usePlanUploadHighlightSetter(itemId: string) {
	const dispatch = useDispatch();

	function setHighlight() {
		dispatch(planUploadViewActions.highlightItem({id: itemId}));
	}

	function clearHighlight() {
		dispatch(planUploadViewActions.highlightItem({}));
	}

	return {
		setHighlight,
		clearHighlight,
	}
}

export function usePlanUploadHighlightItem(itemId: string) {
	const [isHover, setIsHover] = useState(false);
	const {itemId: highlightedItemId} = useSelector(selectPlanUploadHighlightState);

	useEffect(() => {
		setIsHover(highlightedItemId === itemId);
	}, [highlightedItemId, itemId]);

	return {
		isItemHover: isHover
	}
}

export function usePlanUploadGetHighlightShapeStyle() {
	const {itemId: highlightedPanelItemId} = useSelector(selectPlanUploadHighlightState);

	function getHighlightStyle(isHighlighted: (highlightedPanelItemId?: string) => boolean) {
		return isHighlighted(highlightedPanelItemId) ? highlightStyle : {}
	}

	function isItemHighlighted(isHighlighted: (highlightedPanelItemId?: string) => boolean) {
		return isHighlighted(highlightedPanelItemId)
	}

	return {
		getHighlightStyle,
		isItemHighlighted,
	}
}
