import React from "react";
import {Vector2d} from "konva/types/types";
import {scalePointLinesDefaultStyle} from "../../constants";
import {Circle} from "react-konva";

interface ScalePointProps {
	center: Vector2d,
}

const ScalePoint: React.FC<ScalePointProps> = ({center}) => {
	return <Circle {...center!} {...scalePointLinesDefaultStyle} radius={scalePointLinesDefaultStyle.radius!}/>
}
export default ScalePoint
