import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import {paths} from "../../navigator";
import DefaultSymbolPacks from "../components/DefaultSymbolPacks";
import DefaultSymbolsPreview from "../components/DefaultSymbolsPreview";

const SystemSymbolsRouter: React.FC = () => {
	return (
		<Switch>
			<Route path={paths.settings_symbols_system_packId} component={DefaultSymbolsPreview}/>
			<Route path={paths.settings_symbols_system} component={DefaultSymbolPacks}/>
			<Redirect to={paths.settings_symbols_system}/>
		</Switch>
	);
};

export default SystemSymbolsRouter;
