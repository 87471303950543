import React from "react";
import {Colors} from "../../styles";

interface IIconCloseProps {
	color?: Colors;
	size?: number;
}

const IconClose: React.FC<IIconCloseProps> = ({color, size}) => {
	const iconSize = size ?? 18;
	return (
		<svg width={`${iconSize}px`} height={`${iconSize}px`} viewBox="0 0 18 18">
			<polygon id="Path" fill={color ? color : "#CCCCCC"}
					 points="13 5.80571429 12.1942857 5 9 8.19428571 5.80571429 5 5 5.80571429 8.19428571 9 5 12.1942857 5.80571429 13 9 9.80571429 12.1942857 13 13 12.1942857 9.80571429 9"/>
		</svg>
	);
};

export default IconClose
