import React from "react";
import "./manualApprovalButton.scss"

interface IProps {
	label: string;
	onClick?: () => void;
	variant: "red" | "green";
	isSelected: boolean;
}

const ManualApprovalButton: React.FC<IProps> = (props) => {

	const {label, onClick, variant, isSelected} = props;

	const variantMap = {
		"green": "green",
		"red": "red",
	}

	const cls = [
		variantMap[variant],
		isSelected && "_selected",
	].filter(Boolean).join("")


	return (
		<button className={cls} onClick={onClick}>
			{label}
		</button>
	);
};

export default ManualApprovalButton
