import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import {UnsavedChangesNavigationPrompt} from "../../components/UnsavedChangesNavigationPrompt";
import ButtonWithDropdown from "../../../../../../components/ui/ButtonWithDropdown";
import DropdownItem from "../../../../../../components/ui/DropdownItem";
import {DrawingVersion} from "../../../../../../models/DrawingVersion";
import {SelectedDrawingVersion} from "../../../../../project-details/features/drawing-exporter";
import {DrawingType} from "../../../../../../models/enums";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {OnSuccessData} from "../../../../../../utils/CallbackUtils";
import {useSaveDrawingValidator} from "../../../../../../hooks/useSaveDrawingValidator";
import {useProjectDetailContext} from "../../../../../project-details/providers/ProjectDetailProvider";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {getLatestDrawingVersion} from "../../../../../../utils/DrawingVersionUtils";
import ConfirmationModal from "../../../../../../components/ui/ConfirmationModal";
import {pages, useNavigator} from "../../../../../navigator";

type Modal = {
	type: 'older-version-save-confirmation',
	data: { onConfirm: () => void, message: string }
}

const SaveDrawing = ({disabled}: { disabled?: boolean }) => {
	const {navigateTo} = useNavigator()
	const location = useLocation();
	const {t} = useTranslation();
	const {
		projectId, drawingId, versionId, costCenterId, sectionId
	} = useParams<{ projectId: string, drawingId: string, versionId: string, costCenterId: string, sectionId: string }>();

	const {
		forceDrawingUpdate,
		drawingData: {drawingDetails},
		drawingSaveData,
		versionCompleteData
	} = useDrawingDetailsDataContext()
	const {forceTreeUpdateAsync} = useProjectDetailContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {saveDrawing} = drawingSaveData
	const {completeDrawingVersion} = versionCompleteData
	const {validateSaveDrawing} = useSaveDrawingValidator()
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";

	const [modal, setModal] = useState<Modal | null>(null);

	function closeModal() { setModal(null); }

	const onSaveAndCompleteSuccess = () => {
		onSaveSuccess();
		navigateTo(pages.projectDetailsPage(type, projectId))
	}

	const completeDrawing = async (drawingVersion: DrawingVersion) => {
		const selectedDrawing: SelectedDrawingVersion = {
			selectedSectionId: sectionId,
			selectedCostCenterId: costCenterId,
			selectedDrawingId: drawingId,
			selectedDrawingName: drawingDetails?.name ?? "",
			selectedVersionId: drawingVersion.id,
			selectedVersionLabel: drawingVersion.version.getVersionLabel(),
			drawingType: drawingType
		};

		await forceTreeUpdateAsync()
		await completeDrawingVersionRef.current(selectedDrawing)
	};

	// Using ref to avoid stale closure after tree update, complete drawing needs to have updated sections
	const completeDrawingVersionRef = useUpdatedRef(
		async function(selectedDrawing: SelectedDrawingVersion) {
			await completeDrawingVersion(selectedDrawing, onSaveAndCompleteSuccess)
		}
	)

	function onSaveSuccess() {
		forceTreeUpdateAsync().then()
		forceDrawingUpdate();
	}

	function handleSaveDrawing(onSuccess: OnSuccessData<DrawingVersion>, navigateToSaved: boolean) {

		const handleSaveDrawing = async function() {
			const data = await saveDrawing(drawingId, versionId, drawingType)
			if (data) {
				onSuccess(data)
				if (navigateToSaved) {
					navigateTo(pages.projectDrawingPage(
						type, projectId, sectionId, costCenterId, drawingId, data.id
					))
				}
			}
		}

		validateSaveDrawing(async () => {
			if (!drawingDetails) {
				return;
			}

			const isOlderVersionLoaded = function() {
				const drawingLatestVersion = getLatestDrawingVersion(drawingDetails.versions)
				return !!(drawingLatestVersion && drawingLatestVersion.id !== versionId);
			}

			if (isOlderVersionLoaded()) {
				const part1 = t("editor.olderVersionSaveConfirmationModal.messagePart1")
				const version = drawingDetails?.versions.find(v => v.id === versionId)
				const versionNumber = `(${drawingDetails.name} - ${t("common.version")} ${version?.version.getVersionLabel()}),`
				const part2 = t("editor.olderVersionSaveConfirmationModal.messagePart2")
				setModal({
					type: 'older-version-save-confirmation',
					data: {
						message: `${part1} ${versionNumber} ${part2}`,
						onConfirm: () => { handleSaveDrawing(); }
					}
				})
			}
			else {
				await handleSaveDrawing();
			}
		})
	}

	return (
		<>
			{modal?.type === "older-version-save-confirmation" ? (
				<ConfirmationModal
					isOpen={true}
					title={t("editor.olderVersionSaveConfirmationModal.title")}
					text={modal.data.message}
					confirmationButtonLabel={t("common.buttons.confirm")}
					onConfirm={() => {
						modal.data.onConfirm();
						closeModal();
					}}
					onClose={closeModal}
				/>
			) : null}
			<UnsavedChangesNavigationPrompt/>
			<ButtonWithDropdown
				disabled={disabled}
				label={t("common.buttons.save")}
				onClick={() => handleSaveDrawing(onSaveSuccess, true)}
			>
				<DropdownItem
					label={t("common.buttons.saveAndComplete")}
					onClick={() => handleSaveDrawing(completeDrawing, false)}
				/>
			</ButtonWithDropdown>
		</>
	);
};

export default SaveDrawing
