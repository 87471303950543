import React, {useState} from "react";
import {RectPoints} from "./features/group-measurement-select/GroupMeasurementSelectionHelper";

type ContextData = {
	textToolRectPointsMap: Map<string, RectPoints>,
	setTextItemRectPoints: (textItemId: string, rectPoints: RectPoints) => void
	imageToolRectPointsMap: Map<string, RectPoints>
	setImageRectPoints: (imageId: string, rectPoints: RectPoints) => void,
	isEditTextVisible: boolean,
	setEditTextVisible: (value: boolean) => void;
}

const EditorDrawnItemsDataContext = React.createContext<ContextData | undefined>(undefined)

const EditorDrawnItemsDataProvider: React.FC = ({children}) => {
	const [textToolRectPointsMap, setTextToolRectPointsMap] = useState<Map<string, RectPoints>>(new Map());
	const [imageToolRectPointsMap, setImageToolRectPointsMap] = useState<Map<string, RectPoints>>(new Map());
	const [isEditTextVisible, setEditTextVisible] = useState(false);

	function setTextItemRectPoints(textItemId: string, rectPoints: RectPoints) {
		setTextToolRectPointsMap(prev => (
			new Map([...Array.from(prev.entries()), [textItemId, rectPoints]])
		))
	}

	function setImageRectPoints(imageId: string, rectPoints: RectPoints) {
		setImageToolRectPointsMap(prev => (
			new Map([...Array.from(prev.entries()), [imageId, rectPoints]])
		))
	}

	return (
		<EditorDrawnItemsDataContext.Provider value={{
			textToolRectPointsMap,
			setTextItemRectPoints,
			imageToolRectPointsMap,
			setImageRectPoints,
			isEditTextVisible,
			setEditTextVisible
		}}>
			{children}
		</EditorDrawnItemsDataContext.Provider>
	)
}

function useEditorDrawnItemsDataContext() {
	const context = React.useContext(EditorDrawnItemsDataContext)
	if (!context) {
		throw new Error("useEditorDrawnItemsDataContext must be used within a EditorDrawnItemsDataProvider")
	}
	return context
}

const EditorDrawnItemsDataContextBridge = {
	Consumer: EditorDrawnItemsDataContext.Consumer,
	Provider: EditorDrawnItemsDataContext.Provider
}

export {EditorDrawnItemsDataProvider, useEditorDrawnItemsDataContext, EditorDrawnItemsDataContextBridge}