import {Status} from "../models/enums";
import DrawingDetails from "../models/DrawingDetails";
import {useState} from "react";
import {apiInstance} from "../api/api";
import LoggerService from "../services/LoggerService";
import NotificationService from "../services/NotificationService";
import {useTranslation} from "react-i18next";

function useDrawingDetails() {

	const [drawingDetails, setDrawingDetails] = useState<DrawingDetails | undefined>();
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	const loadData = (drawingId: string) => {
		setLoadStatus(Status.LOADING);
		apiInstance.drawingsApi.fetchDrawingDetails(drawingId).then(details => {
			setDrawingDetails(details);
			setLoadStatus(Status.SUCCESS)
		}).catch((err) => {
			setDrawingDetails(undefined)
			setLoadStatus(Status.ERROR);
			LoggerService.logError(err);
			if (!err?.response?.data?.exception?.includes("DrawingNotFoundException")) {
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.fetchDrawingDetailsErrorDesc")
				)
			}
		})
	};

	return {loadStatus, drawingDetails, loadData}
}

export default useDrawingDetails;
