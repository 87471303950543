import React from "react";
import "./planTreePage.scss";
import IconWarning from "../../../project-drawings/features/editor/icons/IconWarning";
import {PlanUploadPageData} from "../../features/plan-upload/types";

interface PlanTreePageProps {
	page: PlanUploadPageData
	onPageClick: (page: PlanUploadPageData) => void,
	selectedPageNumber: number | undefined,
	renderPageOptionsDropdown: (selectedPageNumber: number | undefined, pageNumber: number) => JSX.Element,
	isFirstItem: boolean
	isLastItem: boolean
}

const PlanTreePage: React.FC<PlanTreePageProps> = ({
	page,
	onPageClick,
	selectedPageNumber,
	renderPageOptionsDropdown,
	isFirstItem,
	isLastItem,
}) => {

	const pageItemCls = [
		"plan-tree-page_container_content",
		selectedPageNumber === page.pageNumber && "selected"
	].filter(Boolean).join(" ");

	const verticalLineCls = [
		"plan-tree-page_line-vertical",
		(isFirstItem && !isLastItem) && "first-item",
		isLastItem && "last-item"
	].filter(Boolean).join(" ");

	return (
		<div className={"plan-tree-page"}>
			<div className={verticalLineCls}/>
			<div className={"plan-tree-page_container"}>
				<div className={"plan-tree-page_container_line-horizontal"}/>
				<div className={pageItemCls}>
					<div onClick={() => {onPageClick(page)}} className={"plan-tree-page_container_content_title"}>
						<span className={"plan-tree-page_container_content_title_text"}>{page.title}</span>
						{!page.planScale && <div className={"plan-tree-page_container_content_title_icon"}>
							<IconWarning/>
						</div>}
					</div>
					{renderPageOptionsDropdown(selectedPageNumber, page.pageNumber)}
				</div>
			</div>
		</div>
	);
};

export default PlanTreePage;
