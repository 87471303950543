import React from "react";
import "./tab.scss";

export interface ITab {
	label: string,
	active?: boolean,
	onClick?: () => void,
}

const Tab: React.FC<ITab> = ({label, active, onClick}) => {

	const cls = [
		"tabs-tab",
		active && "is-active",
		onClick && "is-clickable",
	].filter(Boolean).join(" ")

	const handleClick = () => {
		onClick && onClick()
	}

	return (
		<div className={cls} onClick={handleClick}>
			{label}
		</div>
	);
};

export default Tab
