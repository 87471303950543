import {useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {Status} from "../../../models/enums";
import {useTranslation} from "react-i18next";
import Section from "../../../models/Section";

function useProjectTreeRepository() {

	const [sections, setSections] = useState<Section[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();

	const loadProjectTree = async (projectId: string) => {
		setLoadStatus(Status.LOADING);
		try {
			const data = await apiInstance.projectsApi.fetchTree(projectId)
			setSections(data)
			setLoadStatus(Status.SUCCESS)
		}
		catch (err: any) {
			try {
				const detailError = new Error(`Could not fetch project tree (projectId: ${projectId})`);
				detailError.stack += `\n\nCaused by: ${err?.stack}`;
				LoggerService.logError(detailError)
			}
			finally {
				setLoadStatus(Status.ERROR);
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"), t("projects.details.tree.fetchTreeErrorDesc"));
			}
		}
	};

	const clearProjectTree = () => {
		setSections([])
		setLoadStatus(Status.IDLE);
	};

	return {sections, loadProjectTree, clearProjectTree, projectTreeRepositoryLoadStatus: loadStatus}
}

export {useProjectTreeRepository}
