import {AreaFragment, AreaStyle, EditorLine} from "../../models/editor";
import {EditorPanelTabKeys, EditorTool, FillType} from "../../models/enums";
import {Vector2d} from "konva/types/types";
import React, {FC} from "react";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectActiveTool} from "../config/configSlice";
import {selectExportInProgress, selectSnappingState} from "../view/viewSlice";
import {useGetHighlightShapeStyle, useHighlightSetter} from "../../hooks/highlight";
import {useGetSelectionShapeStyle, useSelectSetter} from "../../hooks/select";
import {getDashTypePointArray} from "../../utils";
import {areaActions} from "./areaSlice";
import {useActivate} from "../../hooks/activate";
import {useEditorPanelDataContext} from "../panel/EditorPanelProvider";
import {EventHandlers, KonvaArea} from "./KonvaArea";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {ShapeConfig} from "konva/types/Shape";
import {AreaHelper} from "./AreaHelper";
import {useUserSettingsDataContext} from "../../../../../../providers/UserSettingsProvider";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {useSnapToPoint} from "../length/snapUtils";
import {CursorStyle, DrawingItemType} from "../../../../../base-konva/enums";
import {selectScale} from "../scale/scaleSlice";

type AreaFragmentElementProps = {
	areaFragment: AreaFragment
	areaFragmentActive: boolean
	activeDeductionId: string | null
	isPointerOverLayer: boolean
	areaId: string
	areaStyle: AreaStyle
	areaActive: boolean
	areaHeight: number | undefined
	hookTool: EditorTool,
	pointerLocation?: Vector2d
	areaHasPreviewLine: boolean
	previewLine: EditorLine | null
}
const _AreaFragmentElement: FC<AreaFragmentElementProps> = function({
	areaFragment,
	areaFragmentActive,
	activeDeductionId,
	isPointerOverLayer,
	areaId,
	areaStyle,
	areaActive,
	areaHeight,
	hookTool,
	pointerLocation,
	areaHasPreviewLine,
	previewLine
}) {
	const {snapToPoint} = useSnapToPoint()
	const {closed} = areaFragment;
	const dispatch = useDispatch();
	const {changeCursor} = useCursorStyle();
	const activeTool = useSelector(selectActiveTool);
	const snapping = useSelector(selectSnappingState);
	const {getHighlightStyle} = useGetHighlightShapeStyle();
	const {getSelectionStyle} = useGetSelectionShapeStyle();
	const highlightActions = useHighlightSetter(areaId);
	const selectActions = useSelectSetter();
	const activateActions = useActivate();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext();
	const exportInProgress = useSelector(selectExportInProgress);
	const showPointElements = areaActive && !exportInProgress &&
		(activeTool === hookTool || activeTool === EditorTool.MEASUREMENT_SELECT)
	const scale = useSelector(selectScale);
	const {settings} = useUserSettingsDataContext();
	const centerOfMassLabel = AreaHelper.getFragmentAreaValueFormat(areaFragment, areaHeight, scale, settings?.measurementSystem)

	function handleElementClick() {
		dispatch(configActions.switchEditorTool({editorTool: hookTool}));
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.MEASUREMENT_SELECT}));
		activateActions.activateAreaOrVolume(areaId);
		selectActions.clearSelection();
		selectActions.setSelection(DrawingItemType.AREA, areaId, areaFragment.id);
		getExpandState(areaHeight ? EditorPanelTabKeys.VOLUME : EditorPanelTabKeys.AREA).expandTab();
	}

	const style: ShapeConfig = {
		...areaStyle,
		...getHighlightStyle((id) => id === areaId),
		...getSelectionStyle((highlighted) => {
			return highlighted.some(
				item => item.type === DrawingItemType.AREA && item.itemId === areaId && areaFragment.id ===
					item.fragmentId);
		}),
		dash: getDashTypePointArray(areaStyle.dashType, areaStyle.strokeWidth),
		globalCompositeOperation: "source-over"
	}

	const showPreviewLines = areaActive && areaFragmentActive && isPointerOverLayer && previewLine !== null

	const getCloseLinePoints = (): number[] => {
		if (areaFragment.lines[0]) {
			const pointerPosition = pointerLocation ?? {x: 0, y: 0}
			const closePoint = areaFragment.lines[0].from;
			return [pointerPosition.x, pointerPosition.y, closePoint.position.x, closePoint.position.y];
		}
		return [];
	}

	const eventsRef = useUpdatedRef<EventHandlers>({
		onPointDragEnd: ({pointId, newPosition}) => {
			dispatch(areaActions.updateAreaPointPosition({
				itemId: pointId,
				newPosition,
				fragmentId: areaFragment.id
			}))
		},
		onShapeDragEnd: ({positionDelta}) => {
			dispatch(areaActions.updateAreaFragmentPosition({
				positionDelta,
				fragmentId: areaFragment.id
			}))
		},
		onClick: () => {
			if (activeTool === EditorTool.MEASUREMENT_SELECT) {
				handleElementClick()
			}
		},
		onMouseEnter: evt => {
			changeCursor({
				event: evt,
				cursor: activeTool === EditorTool.MEASUREMENT_SELECT
					? CursorStyle.POINTER
					: areaActive
						? CursorStyle.MOVE
						: CursorStyle.DEFAULT
			})
			if (!activeDeductionId) {
				highlightActions.setHighlight()
			}
		},
		onMouseLeave: evt => {
			changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
			highlightActions.clearHighlight()
		},
		snapToPoint
	})

	return (
		<KonvaArea
			areaActive={areaActive}
			style={{...style, fill: undefined}}
			fill={{
				type: FillType.SOLID,
				color: areaStyle.fill
			}}
			listening={areaHasPreviewLine ? false : closed}
			draggable={areaActive}
			lines={areaFragment.lines}
			showPreviewLines={showPreviewLines}
			previewLine={showPreviewLines ? previewLine ?? undefined : undefined}
			closeLinePoints={showPreviewLines ? getCloseLinePoints() : undefined}
			closed={closed}
			showPointElements={showPointElements}
			snapping={snapping}
			centerOfMassLabel={centerOfMassLabel}
			eventsRef={eventsRef}
		/>
	)
}
export const AreaFragmentElement = React.memo(_AreaFragmentElement)
