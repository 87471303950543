import React from "react";
import {SortDirection} from "../../models/enums";

interface IIconOrderProps {
	direction?: SortDirection,
	active: boolean,
	customRotate?: number;
}

const IconOrder: React.FC<IIconOrderProps> = ({direction, active, customRotate}) => {
	const iconSize = 9;
	const fillColor = active ? "#262626" : "#82888c";
	const transform = customRotate !== undefined ? `rotate(${customRotate}, ${iconSize / 2}, ${iconSize / 2})`
		: direction === SortDirection.ASC ? "rotate(0)" : `rotate(180, ${iconSize / 2}, ${iconSize / 2})`;
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 9" width={iconSize} height={iconSize}>
			<polygon transform={transform} fill={fillColor}
					 points="4.5 7.24 8.29 2.01 0.71 2.01 4.5 7.24"/>
		</svg>
	);
};

export default IconOrder
