import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";
import {lengthActions, selectActiveLengthId} from "./lengthSlice";
import {KonvaEventObject} from "konva/types/Node";
import {Vector2d} from "konva/types/types";
import Konva from "konva";
import {setActiveOnTopLayer} from "../../utils";
import {useSnapToPoint} from "./snapUtils";
import {LengthMeasurement, ToolHookResult} from "../../models/editor";
import {EditorTool} from "../../models/enums";
import {LINE_POINT_RECT_NAME} from "../../constants";
import {selectSnappingState} from "../view/viewSlice";
import {LengthElement} from "./LengthElement";
import {useKeyboardOnEditor} from "../../hooks/useKeyboardOnEditor";
import {selectActiveTool} from "../config/configSlice";
import {useLengthPointContextMenuActions} from "./useLengthPointContextMenuActions";
import {useSelectionCleaner} from "../../hooks/select";
import {useDoubleClick} from "../../hooks/useDoubleClick";
import {getId} from "../../../../../../utils";
import {KonvaEventType} from "../../../../../base-konva/types";
import {getPointerPosition, getSnappedPosition} from "../../../../../base-konva/utils";

type UseLengthProps = {
	isPointerOverLayer: boolean,
	lengths: LengthMeasurement[],
}

export function useLength({isPointerOverLayer, lengths}: UseLengthProps): ToolHookResult {
	const {snapToPoint} = useSnapToPoint()
	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const activeTool = useSelector(selectActiveTool);
	const snapping = useSelector(selectSnappingState);
	const activeLengthId = useSelector(selectActiveLengthId);
	const [pointerLocation, setPointerLocation] = useState<Vector2d>({x: -50, y: -50});
	const {activateLengthPoint, addDropToActive} = useLengthPointContextMenuActions()

	function handleFinishLine() {
		dispatch(lengthActions.disableLinePreview());
	}

	useSelectionCleaner(EditorTool.LENGTH)

	useKeyboardOnEditor({
		when: activeTool === EditorTool.LENGTH,
		keyUpMap: {
			"Enter": handleFinishLine
		}
	})

	function onSingleClick(event: KonvaEventObject<KonvaEventType>) {
		if (lengths.length === 0) {
			return;
		}
		if (event.target instanceof Konva.Rect && event.target.name() === LINE_POINT_RECT_NAME) {
			const pointId = event.target.id()
			if (event.evt.shiftKey) {
				activateLengthPoint(pointId)
			}
			if (event.evt.altKey) {
				addDropToActive(pointId)
			}
			return;
		}

		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			const active = lengths.find(length => length.id === activeLengthId);
			if (active) {
				const fragment = active.lengthFragments.find(f => f.id === active.activeLengthFragmentId)
				if (fragment) {
					const useSnapPosition = snapping.angle && fragment.lastMouseUpPosition != null
					dispatch(lengthActions.addPointToActiveFragment
					(snapping.point
						? snapToPoint(mousePosition).snapTo
						: useSnapPosition
							? getSnappedPosition(fragment.lastMouseUpPosition!, mousePosition)
							: mousePosition
					))
				}
				else {
					dispatch(lengthActions.startNewLengthFragment(mousePosition))
				}
			}
		}
	}

	function onDoubleClick() {
		handleFinishLine();
	}

	const {onMouseUp} = useDoubleClick({onSingleClick, onDoubleClick});

	function onMouseMove(event: KonvaEventObject<KonvaEventType>) {
		const mousePosition = getPointerPosition(event);
		if (mousePosition) {
			setPointerLocation(mousePosition);
		}
	}

	function render() {
		return setActiveOnTopLayer(lengths, activeLengthId).map(length => (
			<LengthElement
				key={length.id}
				length={length}
				isPointerOverLayer={isPointerOverLayer}
				pointerLocation={length.id === activeLengthId ? pointerLocation : undefined}/>
		))
	}

	return {
		id: hookRenderId,
		render,
		tool: EditorTool.LENGTH,
		callbacks: {
			onMouseUp,
			onMouseMove
		}
	}
}
