import React from "react";

interface IIconOrderProps {
	active?: boolean,
}

const IconMore: React.FC<IIconOrderProps> = ({active}) => {

	const fillColor = active ? "white" : "#83898d";
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={18} height={18}>
			<path
				d="M4,12 C4,13.1 4.89999998,14 6,14 C7.10000002,14 8,13.1 8,12 C8,10.9 7.10000002,10 6,10 C4.89999998,10 4,10.9 4,12 Z M16,12 C16,13.1 16.9,14 18,14 C19.1,14 20,13.1 20,12 C20,10.9 19.1,10 18,10 C16.9,10 16,10.9 16,12 Z M10,12 C10,13.1 10.9,14 12,14 C13.1,14 14,13.1 14,12 C14,10.9 13.1,10 12,10 C10.9,10 10,10.9 10,12 Z"
				fill={fillColor}/>
		</svg>
	);
};

export default IconMore
