import React from "react";
import {useSelector} from "react-redux";
import {selectTabsVisibilityState} from "../features/config/configSlice";
import {Group, KonvaNodeEvents, Rect, Text} from "react-konva";
import {EditorLine} from "../models/editor";
import {formatLength} from "../../../../../utils/TextUtils";
import {useUserSettingsDataContext} from "../../../../../providers/UserSettingsProvider";
import {defaultMarkStyle, defaultMarkTextStyle} from "../constants";
import {selectExportInProgress, selectZoomState} from "../features/view/viewSlice";
import {Vector2d} from "konva/types/types";
import isEqual from "lodash/isEqual";
import UserSettings from "../../../../../models/UserSettings";
import {selectScale} from "../features/scale/scaleSlice";

type LineDistanceMarkProps = {
	line: EditorLine,
	onClick?: () => void,
	onTap?: () => void,
	callbacks?: KonvaNodeEvents,
	draggable?: boolean,
	listening?: boolean,
	position: Vector2d,
}

export function LineDistanceMark({
	line,
	onClick,
	onTap,
	callbacks = {},
	draggable = false,
	listening = true,
	position
}: LineDistanceMarkProps) {
	const scale = useSelector(selectScale);
	const zoomState = useSelector(selectZoomState);
	const {settings} = useUserSettingsDataContext();
	const tabsVisibilityState = useSelector(selectTabsVisibilityState);
	const exportInProgress = useSelector(selectExportInProgress);
	const isVisible = tabsVisibilityState.VALUE_MARKS || exportInProgress;

	return <LineDistanceMarkKonva
		scale={scale}
		settings={settings}
		rotation={zoomState.rotation}
		isVisible={isVisible}
		line={line}
		onClick={onClick}
		onTap={onTap}
		callbacks={callbacks}
		draggable={draggable}
		listening={listening}
		position={position}/>
}

type LineDistanceMarkKonvaProps = LineDistanceMarkProps & {
	scale: number | null
	settings?: UserSettings
	rotation: number
	isVisible: boolean
}

export function _LineDistanceMarkKonva({
	scale,
	settings,
	rotation,
	isVisible,
	line,
	onClick,
	onTap,
	callbacks = {},
	draggable = false,
	listening = true,
	position
}: LineDistanceMarkKonvaProps) {
	const lengthLabel = scale ? formatLength(line.distance * scale, settings?.measurementSystem) : '';

	const elementSize = {
		width: lengthLabel.length >= 6 ? 60 : 45,
		height: 20,
	}

	const rectangleStyle = {
		...elementSize,
		...defaultMarkStyle
	}
	const textStyle = {
		...elementSize,
		...defaultMarkTextStyle
	}

	function getRotation() {
		let angle = (line.angle + rotation) % 360
		if (angle < 1) angle += 360;
		angle = Math.trunc(angle);
		const quarter = angle === 0 ? 4 : Math.floor((angle + 89) / 90)
		if (quarter === 1 || quarter === 4) {
			return line.angle
		}
		return line.angle + 180
	}

	function handleOnClick() {
		onClick?.();
	}

	function handleOnTap() {
		onTap?.()
	}

	return (
		<Group
			listening={listening}
			visible={isVisible}
			offsetX={elementSize.width / 2}
			offsetY={elementSize.height / 2}
			x={position.x}
			y={position.y}
			rotation={getRotation()}
			{...elementSize}
		>
			<Rect {...rectangleStyle}
				  {...callbacks} draggable={draggable}
				  onClick={handleOnClick} onTap={handleOnTap}/>
			<Text listening={false} {...textStyle} text={lengthLabel} offsetY={-1}/>
		</Group>
	)
}

const LineDistanceMarkKonva = React.memo(_LineDistanceMarkKonva, isEqual)