import React from "react";
import {CatalogItemGroup} from "../../../../../../../../models/CatalogItemGroup";

interface GroupsTableRowProps {
	item: CatalogItemGroup,
	checked: boolean,
	onClick: (item: CatalogItemGroup) => void,
}

const GroupsTableRow: React.FC<GroupsTableRowProps> = ({item, checked, onClick}) => {

	const cls = checked ? "is-checked" : "";

	return (
		<tr className={cls} onClick={() => onClick(item)}>
			<td className="link">{item.name}</td>
		</tr>
	);
};

export default GroupsTableRow;
