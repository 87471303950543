import React from "react";
import Button from "../../../../../../components/ui/Button";
import {DrawingType, Status} from "../../../../../../models/enums";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import ConfigHelper from "../config/ConfigHelper";
import useCreateAsBuiltFromTakeoff from "../../../../../project-details/hooks/useCreateAsBuiltFromTakeoff";
import {useDrawingDetailsDataContext} from "../../../../providers/DrawingDetailsProvider";
import {useProjectDetailContext} from "../../../../../project-details/providers/ProjectDetailProvider";
import {INITIAL_VERSION_ID} from "../../constants";
import {selectScaleConfig} from "../scale/scaleSlice";

interface CreateAsBuiltProps {
	disabled: boolean;
}

const CreateAsBuilt: React.FC<CreateAsBuiltProps> = ({disabled}) => {
	const {t} = useTranslation();
	const {
		projectId,
		drawingId,
		versionId,
		costCenterId,
		sectionId
	} = useParams<{ projectId: string, sectionId: string, costCenterId: string, drawingId: string, versionId: string }>();
	const scaleConfig = useSelector(selectScaleConfig);
	const {isJobProject, forceTreeUpdate} = useProjectDetailContext()
	const {drawingData: {loadStatus: drawingDetailsLoadStatus, drawingDetails}} = useDrawingDetailsDataContext();
	const {createAsBuilt, saveStatus} = useCreateAsBuiltFromTakeoff(
		projectId, sectionId, costCenterId, drawingId, versionId, drawingDetails?.name ?? "As Built"
	);

	function handleClick() {
		createAsBuilt(forceTreeUpdate)
	}

	return isJobProject && drawingDetails?.drawingType === DrawingType.TAKE_OFF ? (
		<Button label={t("projects.details.planTemplates.createAsBuiltButton")}
				onClick={handleClick}
				variant={"optional"}
				disabled={!ConfigHelper.isScaleSet(scaleConfig)
					|| saveStatus === Status.LOADING
					|| disabled
					|| versionId === INITIAL_VERSION_ID
					|| drawingDetailsLoadStatus !== Status.SUCCESS}/>
	) : null;
};

export default CreateAsBuilt;
