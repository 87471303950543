import {Status} from "../../../models/enums";
import {useEffect, useState} from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";

interface UseApplicationVersionHook {
	applicationVersion: string,
	loadStatus: Status
}

function useApplicationVersion(): UseApplicationVersionHook {
	const [applicationVersion, setApplicationVersion] = useState<string>("")
	const [loadStatus, setLoadStatus] = useState<Status>(Status.IDLE)

	useEffect(() => {
		setLoadStatus(Status.LOADING)
		Promise.all([
			apiInstance.settingsApi.fetchWebVersion(),
			apiInstance.settingsApi.fetchBackendVersion()
		])
			.then(([web, backend]) => {
				setApplicationVersion(`UI:${web} / B:${backend}`);
				setLoadStatus(Status.SUCCESS);
			})
			.catch(err => {
				LoggerService.logError(err);
				setLoadStatus(Status.ERROR);
			})
	}, [])

	return {applicationVersion, loadStatus}
}

export default useApplicationVersion;