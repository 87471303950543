import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../components/modal/EditValueModal";
import {Status} from "../../../models/enums";
//import "./modalContent.scss";
import TextInput from "../../project-drawings/features/editor/components/TextInput";
import {SymbolPack} from "../../../models/SymbolPack";
import useRenameSymbolPack from "../hooks/useRenameSymbolPack";

interface RenameSymbolPackModalProps {
	isOpen: boolean,
	symbolPack: SymbolPack,
	onSuccess: () => void,
	onClose: () => void,
}

const RenameSymbolPackModal: React.FC<RenameSymbolPackModalProps> = ({isOpen, symbolPack, onSuccess, onClose}) => {

	const [name, setName] = useState<string | undefined>(symbolPack.name);
	const {t} = useTranslation();

	const {saveStatus, renameSymbolPack} = useRenameSymbolPack(symbolPack.id, onSuccess);

	const handleSubmit = () => {
		name && renameSymbolPack(name);
	};

	const clearData = () => {
		setName(undefined);
	};

	const handleClose = () => {
		clearData();
		onClose();
	};

	return (
		<EditValueModal isOpen={isOpen}
						onClose={handleClose}
						onSave={handleSubmit}
						title={t("settings.tabs.symbols.packs.dropdown.rename.modalTitle")}
						disableSave={!name || saveStatus === Status.LOADING}
						isLoading={saveStatus === Status.LOADING}
						saveLabel={t("common.buttons.done")}>
			<div className="modal-content">
				<div className="modal-content_field">
					<div className="modal-content_field_label">
						{t("settings.tabs.symbols.packs.dropdown.rename.inputLabel")}
					</div>
					<TextInput value={name}
							   onChange={(evt) => setName(evt.target.value)}
							   placeholder={t("settings.tabs.symbols.packs.dropdown.rename.inputPlaceholder")}/>
				</div>
			</div>
		</EditValueModal>
	);
};

export default RenameSymbolPackModal;
