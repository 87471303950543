import React, {ChangeEvent, useState} from "react";
import {useTranslation} from "react-i18next";
import "./planPageRenameModal.scss"
import ModalWithHeader from "../../../../../components/modal/ModalWithHeader";
import TextInput from "../../../../project-drawings/features/editor/components/TextInput";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import {PlanUploadData, PlanUploadPageData} from "../../../features/plan-upload/types";
import NotificationService from "../../../../../services/NotificationService";

type PlanPageRenameModalProps = {
	onClose: () => void,
	planTemplate: PlanUploadData,
	planPage: PlanUploadPageData,
	onDone: (newPlanPage: PlanUploadPageData, templateId: string) => void
}

const PlanPageRenameModal: React.FC<PlanPageRenameModalProps> = ({
	onClose,
	planPage,
	planTemplate,
	onDone,
}) => {
	const [nameInput, setNameInput] = useState<string>(planPage.title)
	const {t} = useTranslation();

	const onNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setNameInput(e.target.value)
	}

	const externalFileNameLengthLimit = 900

	const onDoneClick = () => {
		if (nameInput.length > externalFileNameLengthLimit) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.modals.renamePlanPageModal.pageNameTooLongErrorDesc"));
			return;
		}
		onDone({...planPage, title: nameInput}, planTemplate.id)
		onClose()
	}

	return (
		<ModalWithHeader customClass={"plan-page-rename-modal"} isOpen={true}
						 title={t("projects.details.planTemplates.modals.renamePlanPageModal.title")}
						 onClose={onClose}>
			<div className={"plan-page-rename-modal_content"}>
				<div className={"plan-page-rename-modal_content_input-container"}>
					<span className={"plan-page-rename-modal_content_input-container_input-label"}
					>{t("projects.details.planTemplates.modals.renamePlanPageModal.inputLabel")}</span>
					<TextInput value={nameInput} className={"plan-page-rename-modal_content_input-container_text-input"}
							   onChange={onNameChange}/>
				</div>
				<ModalButtons>
					<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
					<Button label={t("common.buttons.done")}
							variant={"primary"}
							onClick={onDoneClick}
							disabled={nameInput.trim().length === 0 || nameInput === planPage.title}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	);
};

export default PlanPageRenameModal;
