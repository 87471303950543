import React from "react";
import {useZoomActions} from "./useZoomActions";
import {useSelector} from "react-redux";
import {selectZoomState} from "./viewSlice";
import Konva from "konva";
import {useBaseZoomOnWheel} from "../../../../../base-konva/features/view";


type UseZoomOnWheelProps = {
	scrollContainer: React.RefObject<HTMLElement>
	stageRef: React.RefObject<Konva.Stage>;
}

export function useZoomOnWheel({scrollContainer, stageRef}: UseZoomOnWheelProps) {

	const zoomState = useSelector(selectZoomState)
	const {handleZoomInByPercent, handleZoomOutByPercent} = useZoomActions();

	useBaseZoomOnWheel({
		scrollContainer,
		stageRef,
		zoomState,
		handleZoomOutByPercent,
		handleZoomInByPercent
	})
}