import {UserConfirmationData} from "../../../models/interfaces";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectEditorIsDirty} from "../features/editor/features/view/viewSlice";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import React, {useEffect, useState} from "react";
import {useProjectDetailContext} from "../../project-details/providers/ProjectDetailProvider";
import {useDrawingDetailsDataContext} from "../providers/DrawingDetailsProvider";
import {DrawingType, PromptModalType, Status} from "../../../models/enums";
import {useSaveDrawingValidator} from "../../../hooks/useSaveDrawingValidator";
import {UnsavedChangesPromptModal} from "../components/UnsavedChangesPromptModal";

export function usePromptCustomConfirmationModal(userConfirmationData: UserConfirmationData) {
	const isDirty = useSelector(selectEditorIsDirty)
	const isDirtyRef = useUpdatedRef(isDirty)
	const [open, setOpen] = useState(false);
	const {drawingId, versionId} = useParams<{ drawingId: string, versionId: string }>();
	const {forceTreeUpdate} = useProjectDetailContext()
	const {forceDrawingUpdate, drawingData: {drawingDetails}, drawingSaveData} = useDrawingDetailsDataContext();
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF
	const {saveStatus, saveDrawing} = drawingSaveData
	const {validateSaveDrawing} = useSaveDrawingValidator()

	useEffect(() => {
		const {current: isDirty} = isDirtyRef
		if (isDirty) {
			setOpen(true)
		}
	}, [userConfirmationData, isDirtyRef]);

	function handleClose() {
		userConfirmationData.callback(false)
		setOpen(false)
	}

	function handleConfirm() {
		userConfirmationData.callback(true);
		setOpen(false);
	}

	function handleSave() {
		validateSaveDrawing(async () => {
			const result = await saveDrawing(drawingId, versionId, drawingType);
			if (result) {
				forceTreeUpdate();
				forceDrawingUpdate();
			}
			userConfirmationData.callback(true);
			setOpen(false);
		}, () => {
			userConfirmationData.callback(false);
			setOpen(false);
		})
	}

	function renderPromptCustomConfirmationModal() {
		if (userConfirmationData.message === PromptModalType.UNSAVED_CHANGES && open) {
			return (
				<UnsavedChangesPromptModal
					disableButtons={saveStatus === Status.LOADING}
					onClose={handleClose}
					onConfirm={handleConfirm}
					onSave={handleSave}
				/>
			)
		}
		return null;
	}

	return {renderPromptCustomConfirmationModal}
}