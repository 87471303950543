import React, {useState} from "react";
import Button from "../../../components/ui/Button";
import Modal from "../../../components/modal/Modal";
import ModalTitle from "../../../components/modal/ModalTitle";
import ModalButtons from "../../../components/modal/ModalButtons";
import "./createSymbolGroupModal.scss"
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import InlineSpinner from "../../../components/InlineSpinner";
import {useSymbolPackFormControl} from "../hooks/useSymbolPackFormControl";
import {useParams} from "react-router-dom";
import {useInvalidateSymbolQueries} from "../hooks/useInvalidateSymbolQueries";

type CreateSymbolPackModalProps = {
	isOpen: boolean
	onClose(): void
	onSave(): void;
}

export function CreateSymbolPackModal({isOpen, onClose, onSave}: CreateSymbolPackModalProps) {

	const {groupId} = useParams<{ groupId: string }>();
	const [saving, setSaving] = useState(false);
	const {t} = useTranslation();

	const {
		loadedZip, symbolPackName,
		resetSymbolPackFormControl,
		renderSymbolPackFormControl
	} = useSymbolPackFormControl(true);
	const {invalidateSymbolQueries} = useInvalidateSymbolQueries();

	const canSave = Boolean(loadedZip && loadedZip.SVGs.length >= 1 && symbolPackName && !saving);

	function handleClose() {
		resetSymbolPackFormControl();
		onClose();
	}

	async function handleSave() {
		if (!canSave) return;

		setSaving(true);

		try {
			const symbolIds: string[] = [];
			const attachments = await Promise.all(
				loadedZip!.SVGs.map(file => apiInstance.attachmentApi.uploadFile(file))
			);
			symbolIds.push(...attachments.map(attachment => attachment.id));
			await apiInstance.symbolsApi.postSymbolPack(groupId, symbolPackName, symbolIds);
			invalidateSymbolQueries();
			setSaving(false);
			NotificationService.successNotification(
				t("common.success"),
				t("settings.symbolSettings.createSymbolPackModal.success")
			);
			handleClose();
			onSave();
		}
		catch (e) {
			setSaving(false);
			LoggerService.logError(e);
			NotificationService.errorNotification(t("common.error"),
				t("settings.symbolSettings.createSymbolPackModal.errorDesc"));
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<ModalTitle>{t("settings.symbolSettings.createSymbolPackModal.title")}</ModalTitle>
			<div className={"create-symbol-group-modal"}>
				{renderSymbolPackFormControl()}
			</div>
			<ModalButtons>
				{saving && <InlineSpinner/>}
				<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={handleClose} disabled={saving}/>
				<Button label={t("common.buttons.save")} variant={"primary"} onClick={handleSave} disabled={!canSave}/>
			</ModalButtons>
		</Modal>
	)
}
