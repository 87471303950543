import {PlanSummaryItem} from "../../../../../../../models/PlanSummaryItem";
import {MeasurementType} from "../../../../../../project-drawings/features/editor/models/enums";
import {AreaStyle, CountMeasurementStyle} from "../../../../../../project-drawings/features/editor/models/editor";
import {View} from "@react-pdf/renderer";
import React from "react";
import {CountSymbolPreview} from "./CountSymbolPreview";
import {LengthSymbolPreview} from "./LengthSymbolPreview";
import {LineStyle} from "../../../../../../base-konva/types";

type SymbolPreviewProps = {
	item: PlanSummaryItem
}

export function SymbolPreview({item}: SymbolPreviewProps) {
	const {measurementType, style} = item

	function render() {
		switch (measurementType) {
			case MeasurementType.LENGTH:
				const lengthStyle = style as LineStyle;
				return <LengthSymbolPreview style={lengthStyle}/>
			case MeasurementType.AREA:
			case MeasurementType.VOLUME:
				const areaStyle = style as AreaStyle
				return (
					<View style={{
						height: 20,
						width: 20,
						backgroundColor: areaStyle.fill,
						border: `1px solid ${areaStyle.stroke.substring(0, 7)}`
					}}/>
				)
			case MeasurementType.COUNT:
				const countStyle = style as CountMeasurementStyle
				return <CountSymbolPreview style={countStyle}/>
			default:
				return null
		}
	}

	return (
		<View style={{padding: 2, alignItems: "center", display: "flex"}}>
			{render()}
		</View>
	)
}