import * as React from "react";
import Switch from "react-switch";
import "./switchComponent.scss";
import {Colors} from "../../../styles";
import {useTranslation} from "react-i18next";

interface IProps {
	checked: boolean;
	onSwitch: (checked: boolean) => void;
	disabled?: boolean;
}

const SwitchComponent: React.FC<IProps> = ({checked, onSwitch, disabled}) => {
	const {t} = useTranslation();

	const onChange = (checked: boolean, event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent) => {
		event.stopPropagation();
		onSwitch(checked);
	};

	const checkedIcon = <div className="switch-component-icon">{t("common.buttons.switch.onLabel")}</div>;
	const unckeckedIcon = <div className="switch-component-icon off">{t("common.buttons.switch.offLabel")}</div>;

	return (
		<Switch checked={checked}
				onChange={onChange}
				handleDiameter={19}
				onClick={() => onSwitch(!checked)}
				disabled={disabled}
				checkedIcon={checkedIcon}
				uncheckedIcon={unckeckedIcon}
				onColor={Colors.SIMPROBLUE}
				offColor={Colors.LIGHTGREY2}
				activeBoxShadow="0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
				height={24}
				width={65}/>
	);
};

export default SwitchComponent;
