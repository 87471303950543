import {PayloadAction} from "@reduxjs/toolkit";
import i18n from "../../../../i18n";
import {
	ActionIdValuePayload,
	ActivateActionPayload,
	ActivatePenLineActionPayload,
	DeleteActionPayload,
	DeleteChildItemActionPayload,
	DeleteSelectedItemActionPayload,
	GroupedActionPayload,
	LineStyle,
	PenGroup,
	PenToolState,
	PenToolType,
	SetItemVisibilityActionPayload,
	SetVisibilityActionPayload
} from "../../types";
import {defaultPenStyle} from "../../constants";
import {getId} from "../../../../utils";

const initialState: PenToolState = {
	penGroups: [],
	type: PenToolType.FREE,
	penSelectorStyle: defaultPenStyle,
}

const reducers = {
	reset: () => ({...initialState}),
	setPenGroups: (state: PenToolState, {payload}: PayloadAction<ActionIdValuePayload<PenGroup[]>>) => {
		state.penGroups = payload.value ?? initialState.penGroups
	},
	addPenGroup: (state: PenToolState) => {
		const newId = getId();
		state.activePenGroupId = newId;
		state.penGroups.push({
			id: newId,
			visible: true,
			name: `${i18n.t("editor.pen.penGroup")} ${state.penGroups.length + 1}`,
			penLines: [],
			style: state.penSelectorStyle
		})
	},
	activatePenLine: (state: PenToolState, {payload}: PayloadAction<ActivatePenLineActionPayload>) => {
		const {id, parentId} = payload
		for (let penGroup of state.penGroups) {
			if (penGroup.id === parentId) {
				penGroup.activePenLineId = penGroup.activePenLineId === id ? undefined : id;
				break;
			}
		}
	},
	activatePenGroup: (state: PenToolState, {payload}: PayloadAction<ActivateActionPayload>) => {
		state.activePenGroupId = payload.id;
		const penGroup = state.penGroups.find(group => group.id === payload.id)
		if (penGroup) {
			state.penSelectorStyle = penGroup.style
		}
	},
	deactivatePenGroup: (state: PenToolState, _action: PayloadAction<GroupedActionPayload>) => {
		state.activePenGroupId = undefined;
	},
	deletePenGroup: (state: PenToolState, {payload}: PayloadAction<DeleteActionPayload>) => {
		state.penGroups = state.penGroups
			.filter(pg => pg.id !== payload.id)
			.map((pg, idx) => ({...pg, name: `${i18n.t("editor.pen.penGroup")} ${idx + 1}`}))
	},
	addPenLine: (state: PenToolState, {payload}: PayloadAction<number[]>) => {
		for (let penGroup of state.penGroups) {
			// line must consists of minimum two points [x1,y1,x2,y2]
			if (penGroup.id === state.activePenGroupId && payload.length >= 4) {
				penGroup.penLines.push({
					id: getId(),
					name: `${i18n.t("editor.pen.draw")} ${penGroup.penLines.length + 1}`,
					points: payload,
					visible: true,
				})
			}
		}
	},
	deletePenLine: (state: PenToolState, {payload}: PayloadAction<DeleteChildItemActionPayload>) => {
		for (let penGroup of state.penGroups) {
			if (penGroup.id === payload.parentId) {
				penGroup.penLines = penGroup.penLines
					.filter(item => item.id !== payload.id)
					.map((item, idx) => ({...item, name: `${i18n.t("editor.pen.draw")} ${idx + 1}`}))
				break;
			}
		}
	},
	changePenType: (state: PenToolState, {payload}: PayloadAction<PenToolType>) => {
		state.type = payload;
	},
	changePenStyle: (state: PenToolState, {payload}: PayloadAction<LineStyle>) => {
		state.penSelectorStyle = payload;
		for (let penGroup of state.penGroups) {
			if (penGroup.id === state.activePenGroupId) {
				penGroup.style = payload
				break;
			}
		}
	},
	setVisibility: (state: PenToolState, {payload}: PayloadAction<SetVisibilityActionPayload>) => {
		const {id, visible, setAll} = payload;
		for (let measurement of state.penGroups) {
			if (measurement.id === id || setAll) {
				measurement.visible = visible;
				for (let penLine of measurement.penLines) {
					penLine.visible = visible;
				}
				if (!setAll) break;
			}
		}
	},
	setItemVisibility: (state: PenToolState, {payload}: PayloadAction<SetItemVisibilityActionPayload>) => {
		const {id, visible, parentId} = payload;
		for (let group of state.penGroups) {
			if (group.id === parentId) {
				for (let item of group.penLines) {
					if (item.id === id) {
						item.visible = visible;
						break;
					}
				}
				break;
			}
		}
	},
	removeSelectionItems: (state: PenToolState, {payload}: PayloadAction<DeleteSelectedItemActionPayload>) => {
		for (let penGroup of state.penGroups) {
			if (penGroup.id === payload.id) {
				penGroup.penLines = penGroup.penLines
					.filter(item => (!payload.subIds!.some(id => item.id === id)))
					.map((item, idx) => ({...item, name: `${i18n.t("editor.pen.draw")} ${idx + 1}`}))
				break;
			}
		}
	},
}

export {initialState, reducers}