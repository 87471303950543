import {PayloadAction} from "@reduxjs/toolkit";
import {
	ActionIdValuePayload,
	ActivateActionPayload,
	DeleteActionPayload,
	DeleteSelectedItemActionPayload,
	GroupedActionPayload,
	ImageData,
	ImageElementTransformationPayload,
	ImageState,
	SetVisibilityActionPayload
} from "../../types";

const initialState: ImageState = {
	images: [],
	activeImageId: undefined,
}

const reducers = {
	reset: () => ({...initialState}),
	setImages: (state: ImageState, {payload}: PayloadAction<ActionIdValuePayload<ImageData[]>>) => {
		state.images = payload.value ?? initialState.images
	},
	addImage: (state: ImageState, {payload}: PayloadAction<ImageData>) => {
		state.activeImageId = payload.id;
		state.images.push(payload)
	},
	transformImage: (state: ImageState, {payload}: PayloadAction<ImageElementTransformationPayload>) => {
		const {itemId, newPosition, rotation, scale} = payload;
		for (let image of state.images) {
			if (image.id === itemId) {
				image.position = newPosition;
				image.rotation = rotation;
				image.scale = scale;
				break;
			}
		}
	},
	activateImage: (state: ImageState, {payload}: PayloadAction<Partial<ActivateActionPayload>>) => {
		const {id} = payload;
		state.activeImageId = id;
	},
	deactivateImage: (state: ImageState, _action: PayloadAction<GroupedActionPayload>) => {
		state.activeImageId = undefined;
	},
	deleteImage: (state: ImageState, {payload}: PayloadAction<DeleteActionPayload>) => {
		const {id} = payload;
		state.images = state.images.filter(image => image.id !== id)
	},
	setVisibility: (state: ImageState, {payload}: PayloadAction<SetVisibilityActionPayload>) => {
		const {id, visible, setAll} = payload;
		for (let image of state.images) {
			if (image.id === id || setAll) {
				image.visible = visible;
				if (!setAll) break;
			}
		}
	},
	removeSelectionItems: (state: ImageState, {payload}: PayloadAction<DeleteSelectedItemActionPayload>) => {
		const {id} = payload;
		state.images = state.images.filter(image => image.id !== id)
	},
}

export {initialState, reducers}