import {useMaterialModalContext} from "../../../../MaterialModalProvider";
import {Status} from "../../../../../../../../../../../models/enums";
import Spinner from "../../../../../../../../../../../components/Spinner";
import {Search} from "../../../../components/Search";
import TableOverlaySpinner from "../../../../../../../../../../../components/ui/TableOverlaySpinner";
import {TemplateBreadcrumbs} from "../../../../components/template/TemplateBreadcrumbs";
import {ContentFooter} from "../ContentFooter";
import React from "react";
import {useMaterialChangeTemplatesData} from "../../hooks/useMaterialChangeTemplatesData";
import {useTemplatesData} from "../../../../hooks/useTemplatesData";
import {ItemsTable} from "../../../../components/ItemsTable";

type TemplateItemPresenterProps = {
	itemPresenterData: ReturnType<typeof useMaterialChangeTemplatesData>["itemPresenterData"],
	breadcrumbs: ReturnType<typeof useTemplatesData>["breadcrumbs"]
}

export function TemplateItemPresenter(props: TemplateItemPresenterProps) {
	const {closeModal} = useMaterialModalContext()
	const {itemPresenterData, breadcrumbs} = props

	return (
		<>
			{itemPresenterData.loadStatus === Status.LOADING ? <Spinner/> : null}
			{itemPresenterData.loadStatus === Status.SUCCESS ?
				<>
					<div className="material-modal_container_content">
						<Search {...itemPresenterData.search} />
						<TableOverlaySpinner {...itemPresenterData.overlay}>
							<TemplateBreadcrumbs {...breadcrumbs}/>
							<ItemsTable {...itemPresenterData.itemsTable}/>
						</TableOverlaySpinner>
					</div>
					<ContentFooter {...itemPresenterData.contentFooter} closeModal={closeModal}/>
				</> : null
			}
		</>
	)
}