import {BasePenToolOptions} from "../../../../../base-konva/features/pen";
import {LineStyle, PenToolType} from "../../../../../base-konva/types";
import {useDispatch, useSelector} from "react-redux";
import {planUploadPenActions, selectPlanUploadPenSelectorStyle, selectPlanUploadPenType} from "./planUploadPenSlice";

export const PlanUploadPenToolOptions = () => {
	const dispatch = useDispatch()
	const penType = useSelector(selectPlanUploadPenType)
	const penSelectorStyle = useSelector(selectPlanUploadPenSelectorStyle)

	function handleStyleSave(style: LineStyle) {
		dispatch(planUploadPenActions.changePenStyle(style));
	}

	function handlePenTypeChange(type: PenToolType) {
		dispatch(planUploadPenActions.changePenType(type))
	}

	return (
		<BasePenToolOptions
			penType={penType}
			penSelectorStyle={penSelectorStyle}
			onStyleSave={handleStyleSave}
			onPenTypeChange={handlePenTypeChange}
		/>
	)
}