import React from "react";
import {CheckListItem, SortConfig, TableColumnConfig} from "../../../models/interfaces";
import TableHeaderCell from "../../../components/layout/table/TableHeaderCell";
import ProjectsTableRow from "./ProjectsTableRow";
import {useTranslation} from "react-i18next";
import {ProjectSource, SortDirection} from "../../../models/enums";
import TableNoRecords from "../../../components/layout/table/TableNoRecords";
import QuoteSummary from "../../../models/QuoteSummary";
import JobSummary from "../../../models/JobSummary";

export type ProjectSummary = QuoteSummary | JobSummary;

interface ProjectImportDataTableProps {
	items: ProjectSummary[],
	projectSource: ProjectSource,
	sortConfig: SortConfig<ProjectSummary>,
	onSortConfigChange: (sortConfig: SortConfig<ProjectSummary>) => void;
	redirectToProject: (simproId: number, projectSource: ProjectSource) => void,
}

const ProjectsTable: React.FC<ProjectImportDataTableProps> = ({
	items,
	projectSource,
	sortConfig,
	redirectToProject,
	onSortConfigChange
}) => {

	const {t} = useTranslation();

	const translations = {
		"takeoffs": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.takeoffs`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.takeoffs`)
		},
		"id": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.id`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.id`)
		},
		"status": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.status`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.status`)
		},
		"customer": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.customer`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.customer`)
		},
		"site": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.site`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.site`)
		},
		"name": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.name`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.name`)
		},
		"salesperson": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.salesperson`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.salesperson`)
		},
		"dueDate": {
			[ProjectSource.JOB]: t(`projects.job.table.columns.dueDate`),
			[ProjectSource.QUOTE]: t(`projects.quote.table.columns.dueDate`)
		}
	}

	const columns: TableColumnConfig<CheckListItem<ProjectSummary>>[] = [
		{
			id: "hasDrawings",
			label: translations["takeoffs"][projectSource],
			sortable: false,
			width: 65
		},
		{
			id: "id",
			label: translations["id"][projectSource],
			sortable: true,
			width: 85,
		},
		{
			id: "status",
			label: translations["status"][projectSource],
			sortable: false,
			width: 80,
		},
		{
			id: "customer",
			label: translations["customer"][projectSource],
			sortable: false
		},
		{
			id: "site",
			label: translations["site"][projectSource],
			sortable: false
		},
		{
			id: "name",
			label: translations["name"][projectSource],
			sortable: true
		},
		{
			id: "salesperson",
			label: translations["salesperson"][projectSource],
			sortable: false
		},
		{
			id: "dueDate",
			label: translations["dueDate"][projectSource],
			sortable: true,
			width: 120,
		}
	]

	const onHeaderCellClick = (id: string) => {
		if (id === sortConfig.key) {
			onSortConfigChange(
				{
					...sortConfig,
					direction: sortConfig.direction === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC
				}
			);
		}
		else {
			onSortConfigChange(
				{
					key: id as keyof ProjectSummary,
					direction: SortDirection.DESC
				}
			)
		}
	};

	return (
		<table className={"table"}>
			<thead>
			<tr>
				{columns.map(
					col => <TableHeaderCell<CheckListItem<ProjectSummary>>
						key={col.id}
						id={col.id}
						sortable={col.sortable}
						sortConfig={sortConfig}
						onClick={onHeaderCellClick}
						label={col.label}
						width={col.width}/>)}
			</tr>
			</thead>
			<tbody>
			{items.length ?
				items.map(item => <ProjectsTableRow
					key={item.id} item={item}
					onRowClick={(simproNumber) => redirectToProject(simproNumber,
						projectSource)}/>)
				: <TableNoRecords cols={9}/>}
			</tbody>
		</table>
	);
};

export default ProjectsTable
