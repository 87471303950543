import Circle from "./Circle.svg";
import Square from "./Square.svg";
import Triangle from "./Triangle.svg";
import Star from "./Star.svg";

import Annunciator from "./Annunciator-01.svg";
import AutomaticFireAlarmDevice from "./AutomaticFireAlarmDevice-01.svg";
import Battery from "./Battery-01.svg";
import Buzzer from "./Buzzer-01.svg";
import CableTelevisionOutlet from "./CableTelevisionOutlet.svg";
import CardReaderAccessSystem from "./CardReaderAccessSystem-01.svg";
import CeilingFan from "./CeilingFan.svg";
import DigitalSatelliteSystem from "./DigitalSatelliteSystem-01.svg";
import DimmerSwitches from "./DimmerSwitches.svg";
import DimmerSwitches_1 from "./DimmerSwitches-01.svg";
import Doorbell from "./Doorbell-01.svg";
import DoorbellChime from "./DoorbellChime-01.svg";
import DoorbellTransformer from "./DoorbellTransformer-01.svg";
import DoubleSocket from "./DoubleSocket.svg";
import Downlighter from "./Downlighter.svg";
import ElectricDoorOpener from "./ElectricDoorOpener-01.svg";
import ElectricMotors from "./ElectricMotors-01.svg";
import ElectromagneticDoor from "./ElectromagneticDoor-01.svg";
import EmergencyLight from "./EmergencyLight.svg";
import EmergencyReleaseButton from "./EmergencyReleaseButton-01.svg";
import FireAlarmBell from "./FireAlarmBell-01.svg";
import FireAlarmCentralStation from "./FireAlarmCentralStation-01.svg";
import FireAlarmPanel from "./FireAlarmPanel.svg";
import FireAlarmSounder from "./FireAlarmSounder-01.svg";
import FireAlarmStation from "./FireAlarmStation-01.svg";
import FlourescentFixture from "./FlourescentFixture.svg";
import GroundConnection from "./GroundConnection-01.svg";
import Horn from "./Horn-01.svg";
import IlluminatedEmergencySign from "./IlluminatedEmergencySign.svg";
import InsideAntenna from "./InsideAntenna-01.svg";
import Intercom from "./Intercom-01.svg";
import MagneticDooHold from "./MagneticDooHold-01.svg";
import MaidsSignalPlug from "./MaidsSignalPlug-01.svg";
import MainControl from "./MainControl-01.svg";
import ModularFlourescentFitting from "./ModularFlourescentFitting.svg";
import MotionSensor from "./MotionSensor-01.svg";
import MultiLightBar from "./Multi-LightBar.svg";
import MultiSwitch from "./Multi-Switch.svg";
import OutdoorLighting from "./OutdoorLighting.svg";
import OutsideAntenna from "./OutsideAntenna-01.svg";
import PullCordSwitch from "./PullCordSwitch.svg";
import PushButton from "./PushButton-01.svg";
import SignalCentralStation from "./SignalCentralStation-01.svg";
import SinglePhase from "./SinglePhase-01.svg";
import SocketOutlet from "./SocketOutlet.svg";
import StereoSpeakerOutlet from "./StereoSpeakerOutlet.svg";
import SurfaceFlourescentLight from "./SurfaceFlourescentLight.svg";
import Switch from "./Switch.svg";
import Switch2Way from "./Switch2Way.svg";
import Switch12Pole from "./Switch12Pole.svg";
import SwitchboardsFlush from "./SwitchboardsFlush-01.svg";
import SwitchboardsSurface from "./SwitchboardsSurface-01.svg";
import SwitchedSocket from "./SwitchedSocket.svg";
import SwitchIntermediate from "./SwitchIntermediate.svg";
import TelephoneKeySystem from "./TelephoneKeySystem-01.svg";
import TelephoneOutlet from "./TelephoneOutlet.svg";
import Thermostat from "./Thermostat.svg";
import ThreeOfPolyphase from "./ThreeOfPolyphase-01.svg";
import WallLight from "./WallLight.svg";
import WallMountedElectricalJunctionBox from "./WallMountedElectricalJunctionBox-01.svg";
import WallMountedTelephoneDataBox from "./WallMountedTelephone-DataBox-01.svg";
import WatchmansCentralStation from "./WatchmansCentralStation-01.svg";
import WatchmansStation from "./WatchmansStation-01.svg";
import TwoGangSSO from "./2GangSSO-01.svg";
import TwoFourTubeFlourescentLuminaire from "./24TubeFlourescentLuminaire-01.svg";
import ConsumerUnit from "./ConsumerUnit-01.svg";
import ConsumerUnitDistributionBoardFuseBoard from "./ConsumerUnitDistributionBoardFuseBoard-01.svg";
import EmergencyLight01 from "./EmergencyLight-01.svg";
import EnergyMeter from "./EnergyMeter-01.svg";
import FlourescentLuminaire from "./FlourescentLuminaire-01.svg";
import Fuse from "./Fuse-01.svg";
import Inverterter from "./Inverterter-01.svg";
import MotorStarter from "./MotorStarter-01.svg";
import Rectifier from "./Rectifier-01.svg";
import SelfContainedEmergencyLight from "./SelfContainedEmergencyLight-01.svg";
import SocketOutlet01 from "./SocketOutlet-01.svg";
import StarDeltaStarter from "./StarDeltaStarter-01.svg";
import Switch01 from "./Switch-01.svg";
import SwitchedSocketOutlet from "./SwitchedSocketOutlet-01.svg";
import ThreePhaseWindingDelta from "./ThreePhaseWindingDelta-01.svg";
import ThreePhaseWindingStar from "./ThreePhaseWindingStar-01.svg";

import CeilingRose from "./CeilingRose-01.svg";
import EmergencyLamp_1 from "./EmergencyLamp1-01.svg";
import EmergencyLamp_2 from "./EmergencyLamp2-01.svg";
import EmergencyLamp_3 from "./EmergencyLamp3-01.svg";
import EmergencyLamp_4 from "./EmergencyLamp4-01.svg";
import FlashingLamp from "./FlashingLamp-01.svg";
import Floodlight from "./Floodlight-01.svg";
import Flourescent_1 from "./Flourescent1-01.svg";
import Flourescent_2 from "./Flourescent2-01.svg";
import Flourescent_3 from "./Flourescent3-01.svg";
import Flourescent_4 from "./Flourescent4-01.svg";
import Lamp_1 from "./Lamp1-01.svg";
import Lamp_2 from "./Lamp2-01.svg";
import Lamp_3 from "./Lamp3-01.svg";
import Lamp_4 from "./Lamp4-01.svg";
import LampWallMounted from "./LampWallMounted-01.svg";
import LightFitting from "./LightFitting-01.svg";
import LightingPosition from "./LightingPosition-01.svg";
import ProtectedLightFitting from "./ProtectedLightFitting-01.svg";
import PullCord from "./PullCord-01.svg";
import Sconce from "./Sconce-01.svg";
import Spotlight_1 from "./Spotlight1-01.svg";
import Spotlight_2 from "./Spotlight2-01.svg";

import OneGangTwoWayLightSwitch from "./1Gang2WayLightSwitch-01.svg";
import OneGangThreeWayLightSwitch from "./1Gang3WayLightSwitch-01.svg";
import OneGangLightSwitch from "./1GangLightSwitch-01.svg";
import OneGangPullCord from "./1GangPullCord-01.svg";
import TwoCoreSpeakerCable from "./2CoreSpeakerCable-01.svg";
import TwoGangTwoWayLightSwitch from "./2Gang2WayLightSwitch-01.svg";
import TwoGangThreeWayLightSwitch from "./2Gang3WayLightSwitch-01.svg";
import TwoGangLightSwitch from "./2GangLightSwitch-01-01.svg";
import AudioSpeaker from "./AudioSpeaker-01.svg";
import Cat5E from "./CAT5E-01.svg";
import Cat5Or6DataSocket from "./CAT5or6DataSocket-01.svg";
import ConsumerUnit_2 from "./ConsumerUnit-02.svg";
import CookerSwitch from "./CookerSwitch-01.svg";
import DoorbellChime_2 from "./DoorbellChime-02.svg";
import DoorbellPush from "./DoorbellPush-01.svg";
import DoubleSwitchedFloorSockets from "./DoubleSwitchedFloorSockets-01.svg";
import DoubleSwitchedWallSocket from "./DoubleSwitchedWallSocket-01.svg";
import DoubleSwitchedWallSocket1200mm from "./DoubleSwitchedWallSocket-1200mmFL-01.svg";
import ExternalLight from "./ExternalLight-01.svg";
import ExternalLightBulkheadType from "./ExternalLight-BulkheadType-01.svg";
import FlourescentStripLight from "./FlourescentStripLight-01.svg";
import FuseSpurForTowelRail from "./FuseSpurForTowelRail-01.svg";
import HeatDetectorPlusFireAlarmSounder from "./HeatDetectorFireAlarmSounder-01.svg";
import ImmersionHeaterAndThermostat from "./ImmersionHeaterAndThermostat-01.svg";
import LightPointBattenHolder from "./LightPoint-BattenHolder-01.svg";
import LightPointPendantHolder4Inch from "./LightPoint-PendantHolder4Inch-01.svg";
import LightPointPendantHolder6Inch from "./LightPoint-PendantHolder6Inch-01.svg";
import MechanicalExtractFan from "./MechanicalExtractFan-01.svg";
import MechanicalExtractFanCookerHobHood from "./MechanicalExtractFanCookerHobHood-01.svg";
import Radiator from "./Radiator-01.svg";
import RecessedDownlight from "./RecessedDownlight-01.svg";
import SecurityAlarm from "./SecurityAlarm-01.svg";
import SingleSwitchedFuseSpurAtHighLevelForExtractFan from "./SingleSwitchedFuseSpurAtHighLevelForExtractFan-01.svg";
import SingleSwitchedFuseSpurBelowFL from "./SingleSwitchedFuseSpurBelowFL-01.svg";
import SingleSwitchedWallSocket from "./SingleSwitchedWallSocket-01.svg";
import SingleSwitchedWallSocket1200mm from "./SingleSwitchedWallSocket-1200mmFL-01.svg";
import SmokeHeatDetectorFireAlarmSounder from "./SmokeHeatDetectorFireAlarmSounder-01.svg";
import TelephonePoint from "./TelephonePoint-01.svg";
import Thermostat_2 from "./Thermostat-01.svg";
import TVAerialPoint from "./TVAerialPoint-01.svg";
import UnderfloorHeatingManifold from "./UnderfloorHeatingManifold-01.svg";
import WallLight_2 from "./WallLight-01.svg";

import ActuatorbyMechanicalEnergyAccumulation from "./electrical_actuators/ActuatorbyMechanicalEnergyAccumulation.svg";
import ActuatorLevelPoweredbyLiquidLevel from "./electrical_actuators/ActuatorLevelPoweredbyLiquidLevel.svg";
import ActuatorManualControl from "./electrical_actuators/ActuatorManualControl.svg";
import ActuatorManualProtectagainstRoughHandling from "./electrical_actuators/ActuatorManualProtectagainstRoughHandling.svg";
import ContactControl from "./electrical_actuators/ContactControl.svg";
import CounterPoweredActuator from "./electrical_actuators/CounterPoweredActuator.svg";
import DeviceActivatedPneumaticHydraulicDoubleEffect
	from "./electrical_actuators/DeviceActivatedPneumaticHydraulicDoubleEffect.svg";
import DeviceActivatedPneumaticHydraulicSA from "./electrical_actuators/DeviceActivatedPneumaticHydraulicSA.svg";
import ElectricalClockControl from "./electrical_actuators/ElectricalClockControl.svg";
import ElectricMotorControl from "./electrical_actuators/ElectricMotorControl.svg";
import ElectromagneticActuator from "./electrical_actuators/ElectromagneticActuator.svg";
import EmergencyActuator from "./electrical_actuators/EmergencyActuator.svg";
import FluidActuator from "./electrical_actuators/FluidActuator.svg";
import FrequencyActuator from "./electrical_actuators/FrequencyActuator.svg";
import GasActuatorPoweredbyFluidGas from "./electrical_actuators/GasActuatorPoweredbyFluidGas.svg";
import MechanicalControlByCrank from "./electrical_actuators/MechanicalControlByCrank.svg";
import MechanicalControlbyPedal from "./electrical_actuators/MechanicalControlbyPedal.svg";
import MechanicalControlbyRollerorSlide from "./electrical_actuators/MechanicalControlbyRollerorSlide.svg";
import MechanicalControlByShooterAutoReturn from "./electrical_actuators/MechanicalControlByShooterAutoReturn.svg";
import MechanicalControlCam from "./electrical_actuators/MechanicalControlCam.svg";
import MechanicalControlHandWheel from "./electrical_actuators/MechanicalControlHandWheel.svg";
import MechanicalControlKey from "./electrical_actuators/MechanicalControlKey.svg";
import MechanicalControlPushButtonAutoReturn from "./electrical_actuators/MechanicalControlPushButtonAutoReturn.svg";
import MechanicalManualControlWithLever from "./electrical_actuators/MechanicalManualControlWithLever.svg";
import MechanicalManualRotaryControl from "./electrical_actuators/MechanicalManualRotaryControl.svg";
import PressureActuator from "./electrical_actuators/PressureActuator.svg";
import ProxyControlGeneric from "./electrical_actuators/ProxyControlGeneric.svg";
import RelativeHumidityActuator from "./electrical_actuators/RelativeHumidityActuator.svg";
import RelayElectromagneticactuator from "./electrical_actuators/RelayElectromagneticactuator.svg";
import RemovableManualControl from "./electrical_actuators/RemovableManualControl.svg";
import ThermalActuatorByTempEffect from "./electrical_actuators/ThermalActuatorByTempEffect.svg";

import AlarmButtonPanicButton from "./fire_alarm/AlarmButtonPanicButton.svg";
import FireSensorAutomaticFireSensor from "./fire_alarm/FireSensorAutomaticFireSensor.svg";
import FireWarningDevice from "./fire_alarm/FireWarningDevice.svg";
import FlameDetector from "./fire_alarm/FlameDetector.svg";
import HeatDetector from "./fire_alarm/HeatDetector.svg";
import IonizingSmokeDetector from "./fire_alarm/IonizingSmokeDetector.svg";
import SirenAlarm from "./fire_alarm/SirenAlarm.svg";
import SmokeAlarm from "./fire_alarm/SmokeAlarm.svg";
import SmokeDetector from "./fire_alarm/SmokeDetector.svg";
import SmokeDetectorwithAlarm from "./fire_alarm/SmokeDetectorwithAlarm.svg";

import BrokenGlassSensor from "./security_system/BrokenGlassSensor.svg";
import BuglarSecurityAlarm from "./security_system/BuglarSecurityAlarm.svg";
import GlassBreakDetector from "./security_system/GlassBreakDetector.svg";
import InfraredMotionSensor from "./security_system/InfraredMotionSensor.svg";
import KeyboardAlarmKeypad from "./security_system/KeyboardAlarmKeypad.svg";
import MagneticSensorMagnetofAlarm from "./security_system/MagneticSensorMagnetofAlarm.svg";
import MotionDetectorSensor from "./security_system/MotionDetectorSensor.svg";
import PanicButton from "./security_system/PanicButton.svg";
import PIRAntimasking from "./security_system/PIRAntimasking.svg";
import PIREmitter from "./security_system/PIREmitter.svg";
import PIRReceptor from "./security_system/PIRReceptor.svg";
import PIRSensorPassiveInfraredDetectorMotionDetector from "./security_system/PIRSensorPassiveInfraredDetectorMotionDetector.svg";
import PIRWeatheringPIROutdoor from "./security_system/PIRWeatheringPIROutdoor.svg";
import SpeakerSirenAlarm from "./security_system/SpeakerSirenAlarm.svg";
import UltrasonicMotionSensor from "./security_system/UltrasonicMotionSensor.svg";

import AutonomousLightPoint from "./lighting_symbols/AutonomousLightPoint.svg";
import AuxiliaryDischargeLampDevice from "./lighting_symbols/AuxiliaryDischargeLampDevice.svg";
import BlockAutonomousEmergencyLight from "./lighting_symbols/BlockAutonomousEmergencyLight.svg";
import DischargeLamp2 from "./lighting_symbols/DischargeLamp2.svg";
import EmbeddedFluorescenLineOutput from "./lighting_symbols/EmbeddedFluorescenLineOutput.svg";
import EmbeddedFluorescentOutput from "./lighting_symbols/EmbeddedFluorescentOutput.svg";
import EmbeddedMercuryLampOutput from "./lighting_symbols/EmbeddedMercuryLampOutput.svg";
import EmergencyLighting from "./lighting_symbols/EmergencyLighting.svg";
import Flashbulb from "./lighting_symbols/Flashbulb.svg";
import FluorescentLamp from "./lighting_symbols/FluorescentLamp.svg";
import FluorescentOutput from "./lighting_symbols/FluorescentOutput.svg";
import FluorescentOutputLine from "./lighting_symbols/FluorescentOutputLine.svg";
import IncandescentLampLightbulb from "./lighting_symbols/IncandescentLampLightbulb.svg";
import IncandescentLampLightbulb2 from "./lighting_symbols/IncandescentLampLightbulb2.svg";
import IncandescentLampLightbulb3 from "./lighting_symbols/IncandescentLampLightbulb3.svg";
import Lamp from "./lighting_symbols/Lamp.svg";
import LampLoad from "./lighting_symbols/LampLoad.svg";
import LampwithTransformer from "./lighting_symbols/LampwithTransformer.svg";
import LightbulbGeneric from "./lighting_symbols/LightbulbGeneric.svg";
import LightingOutput from "./lighting_symbols/LightingOutput.svg";
import LightningLightLamp from "./lighting_symbols/LightningLightLamp.svg";
import LowIntensityLamps from "./lighting_symbols/LowIntensityLamps.svg";
import LuminaireLightFixture from "./lighting_symbols/LuminaireLightFixture.svg";
import LuminairePoint from "./lighting_symbols/LuminairePoint.svg";
import Luminairewiththreeelement from "./lighting_symbols/Luminairewiththreeelement.svg";
import LuminousIndicator from "./lighting_symbols/LuminousIndicator.svg";
import MercuryLampOutput from "./lighting_symbols/MercuryLampOutput.svg";
import NeonLamp from "./lighting_symbols/NeonLamp.svg";
import OscillatoryLamp from "./lighting_symbols/OscillatoryLamp.svg";
import ProjectedLight from "./lighting_symbols/ProjectedLight.svg";
import SeperateAuxiliaryLights from "./lighting_symbols/SeperateAuxiliaryLights.svg";
import WallLampOutput from "./lighting_symbols/WallLampOutput.svg";
import WallLighting from "./lighting_symbols/WallLighting.svg";
import XenonFlashLamp from "./lighting_symbols/XenonFlashLamp.svg";

import Bathtub from "./plumbing_symbols/Bathtub.svg";
import Cap from "./plumbing_symbols/Cap.svg";
import CleanOut from "./plumbing_symbols/CleanOut.svg";
import ClothesWasher from "./plumbing_symbols/ClothesWasher.svg";
import Dishwasher from "./plumbing_symbols/Dishwasher.svg";
import DoubleSinkWithCabinet from "./plumbing_symbols/DoubleSinkWithCabinet.svg";
import FloorDrain from "./plumbing_symbols/FloorDrain.svg";
import FreestandingSink from "./plumbing_symbols/FreestandingSink.svg";
import GateValve from "./plumbing_symbols/GateValve.svg";
import Lavatory from "./plumbing_symbols/Lavatory.svg";
import PipeTurnsDown from "./plumbing_symbols/PipeTurnsDown.svg";
import PipeTurnsUp from "./plumbing_symbols/PipeTurnsUp.svg";
import Shower from "./plumbing_symbols/Shower.svg";
import SinkWithCabinet from "./plumbing_symbols/SinkWithCabinet.svg";
import Toilet from "./plumbing_symbols/Toilet.svg";
import Urinal from "./plumbing_symbols/Urinal.svg";
import VentThruRoof from "./plumbing_symbols/VentThruRoof.svg";
import WaterCloset from "./plumbing_symbols/WaterCloset.svg";
import WaterHeater from "./plumbing_symbols/WaterHeater.svg";
import WaterHeaterShutOff from "./plumbing_symbols/WaterHeaterShutOff.svg";
import WaterMeter from "./plumbing_symbols/WaterMeter.svg";

import AirConditioner from "./hvac_symbols/AirConditioner.svg";
import AirFilter from "./hvac_symbols/AirFilter.svg";
import Condenser from "./hvac_symbols/Condenser.svg";
import Fan from "./hvac_symbols/Fan.svg";
import Heater from "./hvac_symbols/Heater.svg";
import ReturnAirVent from "./hvac_symbols/ReturnAirVent.svg";
import StraightDuct from "./hvac_symbols/StraightDuct.svg";
import YJunctionDuct from "./hvac_symbols/YJunctionDuct.svg";
import Symbolpack_2_11 from "./general_letters/Symbolpack_2_11.svg";
import Symbolpack_2_12 from "./general_letters/Symbolpack_2_12.svg";
import Symbolpack_2_13 from "./general_letters/Symbolpack_2_13.svg";
import Symbolpack_2_14 from "./general_letters/Symbolpack_2_14.svg";
import Symbolpack_2_15 from "./general_letters/Symbolpack_2_15.svg";
import Symbolpack_2_16 from "./general_letters/Symbolpack_2_16.svg";
import Symbolpack_2_17 from "./general_letters/Symbolpack_2_17.svg";
import Symbolpack_2_18 from "./general_letters/Symbolpack_2_18.svg";
import Symbolpack_2_19 from "./general_letters/Symbolpack_2_19.svg";
import Symbolpack_2_20 from "./general_letters/Symbolpack_2_20.svg";
import Symbolpack_2_21 from "./general_letters/Symbolpack_2_21.svg";
import Symbolpack_2_22 from "./general_letters/Symbolpack_2_22.svg";
import Symbolpack_2_23 from "./general_letters/Symbolpack_2_23.svg";
import Symbolpack_2_24 from "./general_letters/Symbolpack_2_24.svg";
import Symbolpack_2_25 from "./general_letters/Symbolpack_2_25.svg";
import Symbolpack_2_26 from "./general_letters/Symbolpack_2_26.svg";
import Symbolpack_2_27 from "./general_letters/Symbolpack_2_27.svg";
import Symbolpack_2_28 from "./general_letters/Symbolpack_2_28.svg";
import Symbolpack_2_29 from "./general_letters/Symbolpack_2_29.svg";
import Symbolpack_2_30 from "./general_letters/Symbolpack_2_30.svg";
import Symbolpack_2_31 from "./general_letters/Symbolpack_2_31.svg";
import Symbolpack_2_32 from "./general_letters/Symbolpack_2_32.svg";
import Symbolpack_2_33 from "./general_letters/Symbolpack_2_33.svg";
import Symbolpack_2_34 from "./general_letters/Symbolpack_2_34.svg";
import Symbolpack_2_35 from "./general_letters/Symbolpack_2_35.svg";
import Symbolpack_2_36 from "./general_letters/Symbolpack_2_36.svg";
import Symbolpack_3_11 from "./general_letters/Symbolpack_3_11.svg";
import Symbolpack_3_12 from "./general_letters/Symbolpack_3_12.svg";
import Symbolpack_3_13 from "./general_letters/Symbolpack_3_13.svg";
import Symbolpack_3_14 from "./general_letters/Symbolpack_3_14.svg";
import Symbolpack_3_15 from "./general_letters/Symbolpack_3_15.svg";
import Symbolpack_3_16 from "./general_letters/Symbolpack_3_16.svg";
import Symbolpack_3_17 from "./general_letters/Symbolpack_3_17.svg";
import Symbolpack_3_18 from "./general_letters/Symbolpack_3_18.svg";
import Symbolpack_3_19 from "./general_letters/Symbolpack_3_19.svg";
import Symbolpack_3_20 from "./general_letters/Symbolpack_3_20.svg";
import Symbolpack_3_21 from "./general_letters/Symbolpack_3_21.svg";
import Symbolpack_3_22 from "./general_letters/Symbolpack_3_22.svg";
import Symbolpack_3_23 from "./general_letters/Symbolpack_3_23.svg";
import Symbolpack_3_24 from "./general_letters/Symbolpack_3_24.svg";
import Symbolpack_3_25 from "./general_letters/Symbolpack_3_25.svg";
import Symbolpack_3_26 from "./general_letters/Symbolpack_3_26.svg";
import Symbolpack_3_27 from "./general_letters/Symbolpack_3_27.svg";
import Symbolpack_3_28 from "./general_letters/Symbolpack_3_28.svg";
import Symbolpack_3_29 from "./general_letters/Symbolpack_3_29.svg";
import Symbolpack_3_30 from "./general_letters/Symbolpack_3_30.svg";
import Symbolpack_3_31 from "./general_letters/Symbolpack_3_31.svg";
import Symbolpack_3_32 from "./general_letters/Symbolpack_3_32.svg";
import Symbolpack_3_33 from "./general_letters/Symbolpack_3_33.svg";
import Symbolpack_3_34 from "./general_letters/Symbolpack_3_34.svg";
import Symbolpack_3_35 from "./general_letters/Symbolpack_3_35.svg";
import Symbolpack_3_36 from "./general_letters/Symbolpack_3_36.svg";
import Symbolpack_4_11 from "./general_letters/Symbolpack_4_11.svg";
import Symbolpack_4_12 from "./general_letters/Symbolpack_4_12.svg";
import Symbolpack_4_13 from "./general_letters/Symbolpack_4_13.svg";
import Symbolpack_4_14 from "./general_letters/Symbolpack_4_14.svg";
import Symbolpack_4_15 from "./general_letters/Symbolpack_4_15.svg";
import Symbolpack_4_16 from "./general_letters/Symbolpack_4_16.svg";
import Symbolpack_4_17 from "./general_letters/Symbolpack_4_17.svg";
import Symbolpack_4_18 from "./general_letters/Symbolpack_4_18.svg";
import Symbolpack_4_19 from "./general_letters/Symbolpack_4_19.svg";
import Symbolpack_4_20 from "./general_letters/Symbolpack_4_20.svg";
import Symbolpack_4_21 from "./general_letters/Symbolpack_4_21.svg";
import Symbolpack_4_22 from "./general_letters/Symbolpack_4_22.svg";
import Symbolpack_4_23 from "./general_letters/Symbolpack_4_23.svg";
import Symbolpack_4_24 from "./general_letters/Symbolpack_4_24.svg";
import Symbolpack_4_25 from "./general_letters/Symbolpack_4_25.svg";
import Symbolpack_4_26 from "./general_letters/Symbolpack_4_26.svg";
import Symbolpack_4_27 from "./general_letters/Symbolpack_4_27.svg";
import Symbolpack_4_28 from "./general_letters/Symbolpack_4_28.svg";
import Symbolpack_4_29 from "./general_letters/Symbolpack_4_29.svg";
import Symbolpack_4_30 from "./general_letters/Symbolpack_4_30.svg";
import Symbolpack_4_31 from "./general_letters/Symbolpack_4_31.svg";
import Symbolpack_4_32 from "./general_letters/Symbolpack_4_32.svg";
import Symbolpack_4_33 from "./general_letters/Symbolpack_4_33.svg";
import Symbolpack_4_34 from "./general_letters/Symbolpack_4_34.svg";
import Symbolpack_4_35 from "./general_letters/Symbolpack_4_35.svg";
import Symbolpack_4_36 from "./general_letters/Symbolpack_4_36.svg";
import Symbolpack_5_11 from "./general_letters/Symbolpack_5_11.svg";
import Symbolpack_5_12 from "./general_letters/Symbolpack_5_12.svg";
import Symbolpack_5_13 from "./general_letters/Symbolpack_5_13.svg";
import Symbolpack_5_14 from "./general_letters/Symbolpack_5_14.svg";
import Symbolpack_5_15 from "./general_letters/Symbolpack_5_15.svg";
import Symbolpack_5_16 from "./general_letters/Symbolpack_5_16.svg";
import Symbolpack_5_17 from "./general_letters/Symbolpack_5_17.svg";
import Symbolpack_5_18 from "./general_letters/Symbolpack_5_18.svg";
import Symbolpack_5_19 from "./general_letters/Symbolpack_5_19.svg";
import Symbolpack_5_20 from "./general_letters/Symbolpack_5_20.svg";
import Symbolpack_5_21 from "./general_letters/Symbolpack_5_21.svg";
import Symbolpack_5_22 from "./general_letters/Symbolpack_5_22.svg";
import Symbolpack_5_23 from "./general_letters/Symbolpack_5_23.svg";
import Symbolpack_5_24 from "./general_letters/Symbolpack_5_24.svg";
import Symbolpack_5_25 from "./general_letters/Symbolpack_5_25.svg";
import Symbolpack_5_26 from "./general_letters/Symbolpack_5_26.svg";
import Symbolpack_5_27 from "./general_letters/Symbolpack_5_27.svg";
import Symbolpack_5_28 from "./general_letters/Symbolpack_5_28.svg";
import Symbolpack_5_29 from "./general_letters/Symbolpack_5_29.svg";
import Symbolpack_5_30 from "./general_letters/Symbolpack_5_30.svg";
import Symbolpack_5_31 from "./general_letters/Symbolpack_5_31.svg";
import Symbolpack_5_32 from "./general_letters/Symbolpack_5_32.svg";
import Symbolpack_5_33 from "./general_letters/Symbolpack_5_33.svg";
import Symbolpack_5_34 from "./general_letters/Symbolpack_5_34.svg";
import Symbolpack_5_35 from "./general_letters/Symbolpack_5_35.svg";
import Symbolpack_5_36 from "./general_letters/Symbolpack_5_36.svg";
import Symbolpack_6_11 from "./general_letters/Symbolpack_6_11.svg";
import Symbolpack_6_12 from "./general_letters/Symbolpack_6_12.svg";
import Symbolpack_6_13 from "./general_letters/Symbolpack_6_13.svg";
import Symbolpack_6_14 from "./general_letters/Symbolpack_6_14.svg";
import Symbolpack_6_15 from "./general_letters/Symbolpack_6_15.svg";
import Symbolpack_6_16 from "./general_letters/Symbolpack_6_16.svg";
import Symbolpack_6_17 from "./general_letters/Symbolpack_6_17.svg";
import Symbolpack_6_18 from "./general_letters/Symbolpack_6_18.svg";
import Symbolpack_6_19 from "./general_letters/Symbolpack_6_19.svg";
import Symbolpack_6_20 from "./general_letters/Symbolpack_6_20.svg";
import Symbolpack_6_21 from "./general_letters/Symbolpack_6_21.svg";
import Symbolpack_6_22 from "./general_letters/Symbolpack_6_22.svg";
import Symbolpack_6_23 from "./general_letters/Symbolpack_6_23.svg";
import Symbolpack_6_24 from "./general_letters/Symbolpack_6_24.svg";
import Symbolpack_6_25 from "./general_letters/Symbolpack_6_25.svg";
import Symbolpack_6_26 from "./general_letters/Symbolpack_6_26.svg";
import Symbolpack_6_27 from "./general_letters/Symbolpack_6_27.svg";
import Symbolpack_6_28 from "./general_letters/Symbolpack_6_28.svg";
import Symbolpack_6_29 from "./general_letters/Symbolpack_6_29.svg";
import Symbolpack_6_30 from "./general_letters/Symbolpack_6_30.svg";
import Symbolpack_6_31 from "./general_letters/Symbolpack_6_31.svg";
import Symbolpack_6_32 from "./general_letters/Symbolpack_6_32.svg";
import Symbolpack_6_33 from "./general_letters/Symbolpack_6_33.svg";
import Symbolpack_6_34 from "./general_letters/Symbolpack_6_34.svg";
import Symbolpack_6_35 from "./general_letters/Symbolpack_6_35.svg";
import Symbolpack_6_36 from "./general_letters/Symbolpack_6_36.svg";
import Symbolpack_6_37 from "./general_letters/Symbolpack_6_37.svg";
import Symbolpack_6_38 from "./general_letters/Symbolpack_6_38.svg";
import Symbolpack_6_39 from "./general_letters/Symbolpack_6_39.svg";
import Symbolpack_6_40 from "./general_letters/Symbolpack_6_40.svg";
import Symbolpack_6_41 from "./general_letters/Symbolpack_6_41.svg";
import Symbolpack_6_42 from "./general_letters/Symbolpack_6_42.svg";
import Symbolpack_6_43 from "./general_letters/Symbolpack_6_43.svg";
import Symbolpack_6_44 from "./general_letters/Symbolpack_6_44.svg";
import Symbolpack_6_45 from "./general_letters/Symbolpack_6_45.svg";
import Symbolpack_6_46 from "./general_letters/Symbolpack_6_46.svg";
import Symbolpack_6_47 from "./general_letters/Symbolpack_6_47.svg";
import Symbolpack_6_48 from "./general_letters/Symbolpack_6_48.svg";
import Symbolpack_3_solid_11 from "./general_letters/Symbolpack_3_solid_11.svg";
import Symbolpack_3_solid_12 from "./general_letters/Symbolpack_3_solid_12.svg";
import Symbolpack_3_solid_13 from "./general_letters/Symbolpack_3_solid_13.svg";
import Symbolpack_3_solid_14 from "./general_letters/Symbolpack_3_solid_14.svg";
import Symbolpack_3_solid_15 from "./general_letters/Symbolpack_3_solid_15.svg";
import Symbolpack_3_solid_16 from "./general_letters/Symbolpack_3_solid_16.svg";
import Symbolpack_3_solid_17 from "./general_letters/Symbolpack_3_solid_17.svg";
import Symbolpack_3_solid_18 from "./general_letters/Symbolpack_3_solid_18.svg";
import Symbolpack_3_solid_19 from "./general_letters/Symbolpack_3_solid_19.svg";
import Symbolpack_3_solid_20 from "./general_letters/Symbolpack_3_solid_20.svg";
import Symbolpack_3_solid_21 from "./general_letters/Symbolpack_3_solid_21.svg";
import Symbolpack_3_solid_22 from "./general_letters/Symbolpack_3_solid_22.svg";
import Symbolpack_3_solid_23 from "./general_letters/Symbolpack_3_solid_23.svg";
import Symbolpack_3_solid_24 from "./general_letters/Symbolpack_3_solid_24.svg";
import Symbolpack_3_solid_25 from "./general_letters/Symbolpack_3_solid_25.svg";
import Symbolpack_3_solid_26 from "./general_letters/Symbolpack_3_solid_26.svg";
import Symbolpack_3_solid_27 from "./general_letters/Symbolpack_3_solid_27.svg";
import Symbolpack_3_solid_28 from "./general_letters/Symbolpack_3_solid_28.svg";
import Symbolpack_3_solid_29 from "./general_letters/Symbolpack_3_solid_29.svg";
import Symbolpack_3_solid_30 from "./general_letters/Symbolpack_3_solid_30.svg";
import Symbolpack_3_solid_31 from "./general_letters/Symbolpack_3_solid_31.svg";
import Symbolpack_3_solid_32 from "./general_letters/Symbolpack_3_solid_32.svg";
import Symbolpack_3_solid_33 from "./general_letters/Symbolpack_3_solid_33.svg";
import Symbolpack_3_solid_34 from "./general_letters/Symbolpack_3_solid_34.svg";
import Symbolpack_3_solid_35 from "./general_letters/Symbolpack_3_solid_35.svg";
import Symbolpack_3_solid_36 from "./general_letters/Symbolpack_3_solid_36.svg";
import Symbolpack_3_solid_37 from "./general_letters/Symbolpack_3_solid_37.svg";
import Symbolpack_3_solid_38 from "./general_letters/Symbolpack_3_solid_38.svg";
import Symbolpack_3_solid_39 from "./general_letters/Symbolpack_3_solid_39.svg";
import Symbolpack_3_solid_40 from "./general_letters/Symbolpack_3_solid_40.svg";
import Symbolpack_3_solid_41 from "./general_letters/Symbolpack_3_solid_41.svg";
import Symbolpack_3_solid_42 from "./general_letters/Symbolpack_3_solid_42.svg";
import Symbolpack_3_solid_43 from "./general_letters/Symbolpack_3_solid_43.svg";
import Symbolpack_3_solid_44 from "./general_letters/Symbolpack_3_solid_44.svg";
import Symbolpack_3_solid_45 from "./general_letters/Symbolpack_3_solid_45.svg";
import Symbolpack_3_solid_46 from "./general_letters/Symbolpack_3_solid_46.svg";
import Symbolpack_3_solid_47 from "./general_letters/Symbolpack_3_solid_47.svg";
import Symbolpack_3_solid_48 from "./general_letters/Symbolpack_3_solid_48.svg";
import Symbolpack_4_solid_11 from "./general_letters/Symbolpack_4_solid_11.svg";
import Symbolpack_4_solid_12 from "./general_letters/Symbolpack_4_solid_12.svg";
import Symbolpack_4_solid_13 from "./general_letters/Symbolpack_4_solid_13.svg";
import Symbolpack_4_solid_14 from "./general_letters/Symbolpack_4_solid_14.svg";
import Symbolpack_4_solid_15 from "./general_letters/Symbolpack_4_solid_15.svg";
import Symbolpack_4_solid_16 from "./general_letters/Symbolpack_4_solid_16.svg";
import Symbolpack_4_solid_17 from "./general_letters/Symbolpack_4_solid_17.svg";
import Symbolpack_4_solid_18 from "./general_letters/Symbolpack_4_solid_18.svg";
import Symbolpack_4_solid_19 from "./general_letters/Symbolpack_4_solid_19.svg";
import Symbolpack_4_solid_20 from "./general_letters/Symbolpack_4_solid_20.svg";
import Symbolpack_4_solid_21 from "./general_letters/Symbolpack_4_solid_21.svg";
import Symbolpack_4_solid_22 from "./general_letters/Symbolpack_4_solid_22.svg";
import Symbolpack_4_solid_23 from "./general_letters/Symbolpack_4_solid_23.svg";
import Symbolpack_4_solid_24 from "./general_letters/Symbolpack_4_solid_24.svg";
import Symbolpack_4_solid_25 from "./general_letters/Symbolpack_4_solid_25.svg";
import Symbolpack_4_solid_26 from "./general_letters/Symbolpack_4_solid_26.svg";
import Symbolpack_4_solid_27 from "./general_letters/Symbolpack_4_solid_27.svg";
import Symbolpack_4_solid_28 from "./general_letters/Symbolpack_4_solid_28.svg";
import Symbolpack_4_solid_29 from "./general_letters/Symbolpack_4_solid_29.svg";
import Symbolpack_4_solid_30 from "./general_letters/Symbolpack_4_solid_30.svg";
import Symbolpack_4_solid_31 from "./general_letters/Symbolpack_4_solid_31.svg";
import Symbolpack_4_solid_32 from "./general_letters/Symbolpack_4_solid_32.svg";
import Symbolpack_4_solid_33 from "./general_letters/Symbolpack_4_solid_33.svg";
import Symbolpack_4_solid_34 from "./general_letters/Symbolpack_4_solid_34.svg";
import Symbolpack_4_solid_35 from "./general_letters/Symbolpack_4_solid_35.svg";
import Symbolpack_4_solid_36 from "./general_letters/Symbolpack_4_solid_36.svg";
import Symbolpack_4_solid_37 from "./general_letters/Symbolpack_4_solid_37.svg";
import Symbolpack_4_solid_38 from "./general_letters/Symbolpack_4_solid_38.svg";
import Symbolpack_4_solid_39 from "./general_letters/Symbolpack_4_solid_39.svg";
import Symbolpack_4_solid_40 from "./general_letters/Symbolpack_4_solid_40.svg";
import Symbolpack_4_solid_41 from "./general_letters/Symbolpack_4_solid_41.svg";
import Symbolpack_4_solid_42 from "./general_letters/Symbolpack_4_solid_42.svg";
import Symbolpack_4_solid_43 from "./general_letters/Symbolpack_4_solid_43.svg";
import Symbolpack_4_solid_44 from "./general_letters/Symbolpack_4_solid_44.svg";
import Symbolpack_4_solid_45 from "./general_letters/Symbolpack_4_solid_45.svg";
import Symbolpack_4_solid_46 from "./general_letters/Symbolpack_4_solid_46.svg";
import Symbolpack_4_solid_47 from "./general_letters/Symbolpack_4_solid_47.svg";
import Symbolpack_4_solid_48 from "./general_letters/Symbolpack_4_solid_48.svg";
import Symbolpack_5_solid_11 from "./general_letters/Symbolpack_5_solid_11.svg";
import Symbolpack_5_solid_12 from "./general_letters/Symbolpack_5_solid_12.svg";
import Symbolpack_5_solid_13 from "./general_letters/Symbolpack_5_solid_13.svg";
import Symbolpack_5_solid_14 from "./general_letters/Symbolpack_5_solid_14.svg";
import Symbolpack_5_solid_15 from "./general_letters/Symbolpack_5_solid_15.svg";
import Symbolpack_5_solid_16 from "./general_letters/Symbolpack_5_solid_16.svg";
import Symbolpack_5_solid_17 from "./general_letters/Symbolpack_5_solid_17.svg";
import Symbolpack_5_solid_18 from "./general_letters/Symbolpack_5_solid_18.svg";
import Symbolpack_5_solid_19 from "./general_letters/Symbolpack_5_solid_19.svg";
import Symbolpack_5_solid_20 from "./general_letters/Symbolpack_5_solid_20.svg";
import Symbolpack_5_solid_21 from "./general_letters/Symbolpack_5_solid_21.svg";
import Symbolpack_5_solid_22 from "./general_letters/Symbolpack_5_solid_22.svg";
import Symbolpack_5_solid_23 from "./general_letters/Symbolpack_5_solid_23.svg";
import Symbolpack_5_solid_24 from "./general_letters/Symbolpack_5_solid_24.svg";
import Symbolpack_5_solid_25 from "./general_letters/Symbolpack_5_solid_25.svg";
import Symbolpack_5_solid_26 from "./general_letters/Symbolpack_5_solid_26.svg";
import Symbolpack_5_solid_27 from "./general_letters/Symbolpack_5_solid_27.svg";
import Symbolpack_5_solid_28 from "./general_letters/Symbolpack_5_solid_28.svg";
import Symbolpack_5_solid_29 from "./general_letters/Symbolpack_5_solid_29.svg";
import Symbolpack_5_solid_30 from "./general_letters/Symbolpack_5_solid_30.svg";
import Symbolpack_5_solid_31 from "./general_letters/Symbolpack_5_solid_31.svg";
import Symbolpack_5_solid_32 from "./general_letters/Symbolpack_5_solid_32.svg";
import Symbolpack_5_solid_33 from "./general_letters/Symbolpack_5_solid_33.svg";
import Symbolpack_5_solid_34 from "./general_letters/Symbolpack_5_solid_34.svg";
import Symbolpack_5_solid_35 from "./general_letters/Symbolpack_5_solid_35.svg";
import Symbolpack_5_solid_36 from "./general_letters/Symbolpack_5_solid_36.svg";
import Symbolpack_5_solid_37 from "./general_letters/Symbolpack_5_solid_37.svg";
import Symbolpack_5_solid_38 from "./general_letters/Symbolpack_5_solid_38.svg";
import Symbolpack_5_solid_39 from "./general_letters/Symbolpack_5_solid_39.svg";
import Symbolpack_5_solid_40 from "./general_letters/Symbolpack_5_solid_40.svg";
import Symbolpack_5_solid_41 from "./general_letters/Symbolpack_5_solid_41.svg";
import Symbolpack_5_solid_42 from "./general_letters/Symbolpack_5_solid_42.svg";
import Symbolpack_5_solid_43 from "./general_letters/Symbolpack_5_solid_43.svg";
import Symbolpack_5_solid_44 from "./general_letters/Symbolpack_5_solid_44.svg";
import Symbolpack_5_solid_45 from "./general_letters/Symbolpack_5_solid_45.svg";
import Symbolpack_5_solid_46 from "./general_letters/Symbolpack_5_solid_46.svg";
import Symbolpack_5_solid_47 from "./general_letters/Symbolpack_5_solid_47.svg";
import Symbolpack_5_solid_48 from "./general_letters/Symbolpack_5_solid_48.svg";
import Symbolpack_6_solid_11 from "./general_letters/Symbolpack_6_solid_11.svg";
import Symbolpack_6_solid_12 from "./general_letters/Symbolpack_6_solid_12.svg";
import Symbolpack_6_solid_13 from "./general_letters/Symbolpack_6_solid_13.svg";
import Symbolpack_6_solid_14 from "./general_letters/Symbolpack_6_solid_14.svg";
import Symbolpack_6_solid_15 from "./general_letters/Symbolpack_6_solid_15.svg";
import Symbolpack_6_solid_16 from "./general_letters/Symbolpack_6_solid_16.svg";
import Symbolpack_6_solid_17 from "./general_letters/Symbolpack_6_solid_17.svg";
import Symbolpack_6_solid_18 from "./general_letters/Symbolpack_6_solid_18.svg";
import Symbolpack_6_solid_19 from "./general_letters/Symbolpack_6_solid_19.svg";
import Symbolpack_6_solid_20 from "./general_letters/Symbolpack_6_solid_20.svg";
import Symbolpack_6_solid_21 from "./general_letters/Symbolpack_6_solid_21.svg";
import Symbolpack_6_solid_22 from "./general_letters/Symbolpack_6_solid_22.svg";
import Symbolpack_6_solid_23 from "./general_letters/Symbolpack_6_solid_23.svg";
import Symbolpack_6_solid_24 from "./general_letters/Symbolpack_6_solid_24.svg";
import Symbolpack_6_solid_25 from "./general_letters/Symbolpack_6_solid_25.svg";
import Symbolpack_6_solid_26 from "./general_letters/Symbolpack_6_solid_26.svg";
import Symbolpack_6_solid_27 from "./general_letters/Symbolpack_6_solid_27.svg";
import Symbolpack_6_solid_28 from "./general_letters/Symbolpack_6_solid_28.svg";
import Symbolpack_6_solid_29 from "./general_letters/Symbolpack_6_solid_29.svg";
import Symbolpack_6_solid_30 from "./general_letters/Symbolpack_6_solid_30.svg";
import Symbolpack_6_solid_31 from "./general_letters/Symbolpack_6_solid_31.svg";
import Symbolpack_6_solid_32 from "./general_letters/Symbolpack_6_solid_32.svg";
import Symbolpack_6_solid_33 from "./general_letters/Symbolpack_6_solid_33.svg";
import Symbolpack_6_solid_34 from "./general_letters/Symbolpack_6_solid_34.svg";
import Symbolpack_6_solid_35 from "./general_letters/Symbolpack_6_solid_35.svg";
import Symbolpack_6_solid_36 from "./general_letters/Symbolpack_6_solid_36.svg";
import Symbolpack_6_solid_37 from "./general_letters/Symbolpack_6_solid_37.svg";
import Symbolpack_6_solid_38 from "./general_letters/Symbolpack_6_solid_38.svg";
import Symbolpack_6_solid_39 from "./general_letters/Symbolpack_6_solid_39.svg";
import Symbolpack_6_solid_40 from "./general_letters/Symbolpack_6_solid_40.svg";
import Symbolpack_6_solid_41 from "./general_letters/Symbolpack_6_solid_41.svg";
import Symbolpack_6_solid_42 from "./general_letters/Symbolpack_6_solid_42.svg";
import Symbolpack_6_solid_43 from "./general_letters/Symbolpack_6_solid_43.svg";
import Symbolpack_6_solid_44 from "./general_letters/Symbolpack_6_solid_44.svg";
import Symbolpack_6_solid_45 from "./general_letters/Symbolpack_6_solid_45.svg";
import Symbolpack_6_solid_46 from "./general_letters/Symbolpack_6_solid_46.svg";
import Symbolpack_6_solid_47 from "./general_letters/Symbolpack_6_solid_47.svg";
import Symbolpack_6_solid_48 from "./general_letters/Symbolpack_6_solid_48.svg";
import Symbolpack_2_37 from "./general_characters/Symbolpack_2_37.svg";
import Symbolpack_2_38 from "./general_characters/Symbolpack_2_38.svg";
import Symbolpack_2_39 from "./general_characters/Symbolpack_2_39.svg";
import Symbolpack_2_40 from "./general_characters/Symbolpack_2_40.svg";
import Symbolpack_2_41 from "./general_characters/Symbolpack_2_41.svg";
import Symbolpack_2_42 from "./general_characters/Symbolpack_2_42.svg";
import Symbolpack_2_43 from "./general_characters/Symbolpack_2_43.svg";
import Symbolpack_2_44 from "./general_characters/Symbolpack_2_44.svg";
import Symbolpack_2_45 from "./general_characters/Symbolpack_2_45.svg";
import Symbolpack_2_46 from "./general_characters/Symbolpack_2_46.svg";
import Symbolpack_2_47 from "./general_characters/Symbolpack_2_47.svg";
import Symbolpack_2_48 from "./general_characters/Symbolpack_2_48.svg";
import Symbolpack_3_37 from "./general_characters/Symbolpack_3_37.svg";
import Symbolpack_3_38 from "./general_characters/Symbolpack_3_38.svg";
import Symbolpack_3_39 from "./general_characters/Symbolpack_3_39.svg";
import Symbolpack_3_40 from "./general_characters/Symbolpack_3_40.svg";
import Symbolpack_3_41 from "./general_characters/Symbolpack_3_41.svg";
import Symbolpack_3_42 from "./general_characters/Symbolpack_3_42.svg";
import Symbolpack_3_43 from "./general_characters/Symbolpack_3_43.svg";
import Symbolpack_3_44 from "./general_characters/Symbolpack_3_44.svg";
import Symbolpack_3_45 from "./general_characters/Symbolpack_3_45.svg";
import Symbolpack_3_46 from "./general_characters/Symbolpack_3_46.svg";
import Symbolpack_3_47 from "./general_characters/Symbolpack_3_47.svg";
import Symbolpack_3_48 from "./general_characters/Symbolpack_3_48.svg";
import Symbolpack_4_37 from "./general_characters/Symbolpack_4_37.svg";
import Symbolpack_4_38 from "./general_characters/Symbolpack_4_38.svg";
import Symbolpack_4_39 from "./general_characters/Symbolpack_4_39.svg";
import Symbolpack_4_40 from "./general_characters/Symbolpack_4_40.svg";
import Symbolpack_4_41 from "./general_characters/Symbolpack_4_41.svg";
import Symbolpack_4_42 from "./general_characters/Symbolpack_4_42.svg";
import Symbolpack_4_43 from "./general_characters/Symbolpack_4_43.svg";
import Symbolpack_4_44 from "./general_characters/Symbolpack_4_44.svg";
import Symbolpack_4_45 from "./general_characters/Symbolpack_4_45.svg";
import Symbolpack_4_46 from "./general_characters/Symbolpack_4_46.svg";
import Symbolpack_4_47 from "./general_characters/Symbolpack_4_47.svg";
import Symbolpack_4_48 from "./general_characters/Symbolpack_4_48.svg";
import Symbolpack_5_37 from "./general_characters/Symbolpack_5_37.svg";
import Symbolpack_5_38 from "./general_characters/Symbolpack_5_38.svg";
import Symbolpack_5_39 from "./general_characters/Symbolpack_5_39.svg";
import Symbolpack_5_40 from "./general_characters/Symbolpack_5_40.svg";
import Symbolpack_5_41 from "./general_characters/Symbolpack_5_41.svg";
import Symbolpack_5_42 from "./general_characters/Symbolpack_5_42.svg";
import Symbolpack_5_43 from "./general_characters/Symbolpack_5_43.svg";
import Symbolpack_5_44 from "./general_characters/Symbolpack_5_44.svg";
import Symbolpack_5_45 from "./general_characters/Symbolpack_5_45.svg";
import Symbolpack_5_46 from "./general_characters/Symbolpack_5_46.svg";
import Symbolpack_5_47 from "./general_characters/Symbolpack_5_47.svg";
import Symbolpack_5_48 from "./general_characters/Symbolpack_5_48.svg";
import Symbolpack_1_01 from "./general_basic/Symbolpack_1_01.svg";
import Symbolpack_1_02 from "./general_basic/Symbolpack_1_02.svg";
import Symbolpack_1_02_Copy from "./general_basic/Symbolpack_1_02_Copy.svg";
import Symbolpack_1_06 from "./general_basic/Symbolpack_1_06.svg";
import Symbolpack_1_08 from "./general_basic/Symbolpack_1_08.svg";
import Symbolpack_1_09 from "./general_basic/Symbolpack_1_09.svg";
import Symbolpack_1_10 from "./general_basic/Symbolpack_1_10.svg";
import Symbolpack_1_12 from "./general_basic/Symbolpack_1_12.svg";
import Symbolpack_1_13 from "./general_basic/Symbolpack_1_13.svg";
import Symbolpack_1_14 from "./general_basic/Symbolpack_1_14.svg";
import Symbolpack_1_15 from "./general_basic/Symbolpack_1_15.svg";
import Symbolpack_1_16 from "./general_basic/Symbolpack_1_16.svg";
import Symbolpack_1_17 from "./general_basic/Symbolpack_1_17.svg";
import Symbolpack_1_18 from "./general_basic/Symbolpack_1_18.svg";
import Symbolpack_1_19 from "./general_basic/Symbolpack_1_19.svg";
import Symbolpack_1_20 from "./general_basic/Symbolpack_1_20.svg";
import Symbolpack_1_21 from "./general_basic/Symbolpack_1_21.svg";
import Symbolpack_1_22 from "./general_basic/Symbolpack_1_22.svg";
import Symbolpack_1_23 from "./general_basic/Symbolpack_1_23.svg";
import Symbolpack_1_24 from "./general_basic/Symbolpack_1_24.svg";
import Symbolpack_1_25 from "./general_basic/Symbolpack_1_25.svg";
import Symbolpack_1_26 from "./general_basic/Symbolpack_1_26.svg";
import Symbolpack_1_27 from "./general_basic/Symbolpack_1_27.svg";
import Symbolpack_1_28 from "./general_basic/Symbolpack_1_28.svg";
import Symbolpack_1_29 from "./general_basic/Symbolpack_1_29.svg";
import Symbolpack_1_30 from "./general_basic/Symbolpack_1_30.svg";
import Symbolpack_1_32 from "./general_basic/Symbolpack_1_32.svg";
import Symbolpack_1_32_Copy from "./general_basic/Symbolpack_1_32_Copy.svg";
import Symbolpack_1_33 from "./general_basic/Symbolpack_1_33.svg";
import Symbolpack_1_34 from "./general_basic/Symbolpack_1_34.svg";
import Symbolpack_1_35 from "./general_basic/Symbolpack_1_35.svg";
import Symbolpack_1_36 from "./general_basic/Symbolpack_1_36.svg";
import Symbolpack_1_37 from "./general_basic/Symbolpack_1_37.svg";
import Symbolpack_1_38 from "./general_basic/Symbolpack_1_38.svg";
import Symbolpack_1_39 from "./general_basic/Symbolpack_1_39.svg";
import Symbolpack_1_40 from "./general_basic/Symbolpack_1_40.svg";
import Symbolpack_1_41 from "./general_basic/Symbolpack_1_41.svg";
import Symbolpack_1_42 from "./general_basic/Symbolpack_1_42.svg";
import Symbolpack_1_43 from "./general_basic/Symbolpack_1_43.svg";
import Symbolpack_1_44 from "./general_basic/Symbolpack_1_44.svg";
import Symbolpack_1_45 from "./general_basic/Symbolpack_1_45.svg";
import Symbolpack_1_46 from "./general_basic/Symbolpack_1_46.svg";
import Symbolpack_1_47 from "./general_basic/Symbolpack_1_47.svg";
import Symbolpack_1_48 from "./general_basic/Symbolpack_1_48.svg";
import Symbolpack_1_49 from "./general_basic/Symbolpack_1_49.svg";
import Symbolpack_1_50 from "./general_basic/Symbolpack_1_50.svg";
import Symbolpack_1_51 from "./general_basic/Symbolpack_1_51.svg";
import Symbolpack_1_52 from "./general_basic/Symbolpack_1_52.svg";
import Symbolpack_1_53 from "./general_basic/Symbolpack_1_53.svg";
import Symbolpack_1_54 from "./general_basic/Symbolpack_1_54.svg";
import Symbolpack_1_55 from "./general_basic/Symbolpack_1_55.svg";
import Symbolpack_1_57 from "./general_basic/Symbolpack_1_57.svg";
import Symbolpack_1_58 from "./general_basic/Symbolpack_1_58.svg";
import Symbolpack_1_59 from "./general_basic/Symbolpack_1_59.svg";
import Symbolpack_1_65 from "./general_basic/Symbolpack_1_65.svg";
import Symbolpack_1_88 from "./general_basic/Symbolpack_1_88.svg";
import Symbolpack_1_89 from "./general_basic/Symbolpack_1_89.svg";
import Symbolpack_1_109 from "./general_basic/Symbolpack_1_109.svg";
import Symbolpack_1_110 from "./general_basic/Symbolpack_1_110.svg";
import Symbolpack_1_112 from "./general_basic/Symbolpack_1_112.svg";
import Symbolpack_1_113 from "./general_basic/Symbolpack_1_113.svg";
import Symbolpack_1_114 from "./general_basic/Symbolpack_1_114.svg";
import Symbolpack_1_115 from "./general_basic/Symbolpack_1_115.svg";
import Symbolpack_1_116 from "./general_basic/Symbolpack_1_116.svg";
import Symbolpack_1_117 from "./general_basic/Symbolpack_1_117.svg";
import Symbolpack_1_118 from "./general_basic/Symbolpack_1_118.svg";
import Symbolpack_1_119 from "./general_basic/Symbolpack_1_119.svg";
import Symbolpack_1_120 from "./general_basic/Symbolpack_1_120.svg";
import Symbolpack_1_121 from "./general_basic/Symbolpack_1_121.svg";
import Symbolpack_1_122 from "./general_basic/Symbolpack_1_122.svg";
import Symbolpack_1_123 from "./general_basic/Symbolpack_1_123.svg";
import Symbolpack_1_124 from "./general_basic/Symbolpack_1_124.svg";
import Symbolpack_1_125 from "./general_basic/Symbolpack_1_125.svg";
import Symbolpack_1_127 from "./general_basic/Symbolpack_1_127.svg";
import Symbolpack_1_128 from "./general_basic/Symbolpack_1_128.svg";
import Symbolpack_1_129 from "./general_basic/Symbolpack_1_129.svg";
import Symbolpack_1_130 from "./general_basic/Symbolpack_1_130.svg";
import Symbolpack_1_132 from "./general_basic/Symbolpack_1_132.svg";
import Symbolpack_1_133 from "./general_basic/Symbolpack_1_133.svg";
import Symbolpack_1_134 from "./general_basic/Symbolpack_1_134.svg";
import Symbolpack_1_135 from "./general_basic/Symbolpack_1_135.svg";
import Symbolpack_1_136 from "./general_basic/Symbolpack_1_136.svg";
import Symbolpack_1_137 from "./general_basic/Symbolpack_1_137.svg";
import Symbolpack_1_138 from "./general_basic/Symbolpack_1_138.svg";
import Symbolpack_1_139 from "./general_basic/Symbolpack_1_139.svg";
import Symbolpack_1_140 from "./general_basic/Symbolpack_1_140.svg";
import Symbolpack_1_141 from "./general_basic/Symbolpack_1_141.svg";
import Symbolpack_1_146 from "./general_basic/Symbolpack_1_146.svg";
import Symbolpack_1_147 from "./general_basic/Symbolpack_1_147.svg";
import Symbolpack_1_148 from "./general_basic/Symbolpack_1_148.svg";
import Symbolpack_1_149 from "./general_basic/Symbolpack_1_149.svg";
import Symbolpack_1_150 from "./general_basic/Symbolpack_1_150.svg";
import Symbolpack_1_151 from "./general_basic/Symbolpack_1_151.svg";
import Symbolpack_1_152 from "./general_basic/Symbolpack_1_152.svg";
import Symbolpack_1_153 from "./general_basic/Symbolpack_1_153.svg";
import Symbolpack_1_154 from "./general_basic/Symbolpack_1_154.svg";
import Symbolpack_1_155 from "./general_basic/Symbolpack_1_155.svg";
import Symbolpack_1_156 from "./general_basic/Symbolpack_1_156.svg";
import Symbolpack_1_157 from "./general_basic/Symbolpack_1_157.svg";
import Symbolpack_1_158 from "./general_basic/Symbolpack_1_158.svg";
import Symbolpack_1_159 from "./general_basic/Symbolpack_1_159.svg";
import Symbolpack_1_160 from "./general_basic/Symbolpack_1_160.svg";
import Symbolpack_1_161 from "./general_basic/Symbolpack_1_161.svg";
import Symbolpack_1_162 from "./general_basic/Symbolpack_1_162.svg";
import Symbolpack_1_163 from "./general_basic/Symbolpack_1_163.svg";
import Symbolpack_1_164 from "./general_basic/Symbolpack_1_164.svg";
import Symbolpack_1_165 from "./general_basic/Symbolpack_1_165.svg";
import Symbolpack_1_166 from "./general_basic/Symbolpack_1_166.svg";
import Symbolpack_1_167 from "./general_basic/Symbolpack_1_167.svg";
import Symbolpack_1_168 from "./general_basic/Symbolpack_1_168.svg";
import Symbolpack_1_169 from "./general_basic/Symbolpack_1_169.svg";
import Symbolpack_1_170 from "./general_basic/Symbolpack_1_170.svg";
import Symbolpack_1_171 from "./general_basic/Symbolpack_1_171.svg";
import Symbolpack_1_172 from "./general_basic/Symbolpack_1_172.svg";
import Symbolpack_1_173 from "./general_basic/Symbolpack_1_173.svg";
import Symbolpack_1_174 from "./general_basic/Symbolpack_1_174.svg";
import Symbolpack_1_175 from "./general_basic/Symbolpack_1_175.svg";
import Symbolpack_1_176 from "./general_basic/Symbolpack_1_176.svg";
import Symbolpack_1_177 from "./general_basic/Symbolpack_1_177.svg";
import Symbolpack_1_178 from "./general_basic/Symbolpack_1_178.svg";
import Symbolpack_1_179 from "./general_basic/Symbolpack_1_179.svg";
import Symbolpack_1_180 from "./general_basic/Symbolpack_1_180.svg";
import Symbolpack_1_90 from "./plumbing_symbols2/Symbolpack_1_90.svg";
import Symbolpack_1_91 from "./plumbing_symbols2/Symbolpack_1_91.svg";
import Symbolpack_1_92 from "./plumbing_symbols2/Symbolpack_1_92.svg";
import Symbolpack_1_93 from "./plumbing_symbols2/Symbolpack_1_93.svg";
import Symbolpack_1_94 from "./plumbing_symbols2/Symbolpack_1_94.svg";
import Symbolpack_1_95 from "./plumbing_symbols2/Symbolpack_1_95.svg";
import Symbolpack_1_96 from "./plumbing_symbols2/Symbolpack_1_96.svg";
import Symbolpack_1_142 from "./plumbing_symbols2/Symbolpack_1_142.svg";
import Symbolpack_1_143 from "./plumbing_symbols2/Symbolpack_1_143.svg";
import Symbolpack_1_144 from "./plumbing_symbols2/Symbolpack_1_144.svg";
import Symbolpack_1_145 from "./plumbing_symbols2/Symbolpack_1_145.svg";
import Symbolpack_1_106 from "./fire_general/Symbolpack_1_106.svg";
import Symbolpack_1_107 from "./fire_general/Symbolpack_1_107.svg";
import Symbolpack_1_108 from "./fire_general/Symbolpack_1_108.svg";
import Symbolpack_1_60 from "./electric_general/Symbolpack_1_60.svg";
import Symbolpack_1_61 from "./electric_general/Symbolpack_1_61.svg";
import Symbolpack_1_62 from "./electric_general/Symbolpack_1_62.svg";
import Symbolpack_1_63 from "./electric_general/Symbolpack_1_63.svg";
import Symbolpack_1_64 from "./electric_general/Symbolpack_1_64.svg";
import Symbolpack_1_66 from "./electric_general/Symbolpack_1_66.svg";
import Symbolpack_1_67 from "./electric_general/Symbolpack_1_67.svg";
import Symbolpack_1_68 from "./electric_general/Symbolpack_1_68.svg";
import Symbolpack_1_69 from "./electric_general/Symbolpack_1_69.svg";
import Symbolpack_1_70 from "./electric_general/Symbolpack_1_70.svg";
import Symbolpack_1_71 from "./electric_general/Symbolpack_1_71.svg";
import Symbolpack_1_72 from "./electric_general/Symbolpack_1_72.svg";
import Symbolpack_1_73 from "./electric_general/Symbolpack_1_73.svg";
import Symbolpack_1_74 from "./electric_general/Symbolpack_1_74.svg";
import Symbolpack_1_75 from "./electric_general/Symbolpack_1_74.svg";
import Symbolpack_1_76 from "./electric_general/Symbolpack_1_76.svg";
import Symbolpack_1_77 from "./electric_general/Symbolpack_1_77.svg";
import Symbolpack_1_78 from "./electric_general/Symbolpack_1_78.svg";
import Symbolpack_1_79 from "./electric_general/Symbolpack_1_79.svg";
import Symbolpack_1_80 from "./electric_general/Symbolpack_1_80.svg";
import Symbolpack_1_81 from "./electric_general/Symbolpack_1_81.svg";
import Symbolpack_1_82 from "./electric_general/Symbolpack_1_82.svg";
import Symbolpack_1_83 from "./electric_general/Symbolpack_1_83.svg";
import Symbolpack_1_84 from "./electric_general/Symbolpack_1_84.svg";
import Symbolpack_1_86 from "./electric_general/Symbolpack_1_86.svg";
import Symbolpack_1_87 from "./electric_general/Symbolpack_1_87.svg";
import Symbolpack_1_97 from "./electric_general/Symbolpack_1_97.svg";
import Symbolpack_1_98 from "./electric_general/Symbolpack_1_98.svg";
import Symbolpack_1_99 from "./electric_general/Symbolpack_1_99.svg";
import Symbolpack_1_100 from "./electric_general/Symbolpack_1_100.svg";
import Symbolpack_1_101 from "./electric_general/Symbolpack_1_101.svg";
import Symbolpack_1_102 from "./electric_general/Symbolpack_1_102.svg";
import Symbolpack_1_104 from "./electric_general/Symbolpack_1_104.svg";
import Symbolpack_1_105 from "./electric_general/Symbolpack_1_105.svg";
import Symbolpack_2_01 from "./general_numbers/Symbolpack_2_01.svg";
import Symbolpack_2_02 from "./general_numbers/Symbolpack_2_02.svg";
import Symbolpack_2_03 from "./general_numbers/Symbolpack_2_03.svg";
import Symbolpack_2_04 from "./general_numbers/Symbolpack_2_04.svg";
import Symbolpack_2_05 from "./general_numbers/Symbolpack_2_05.svg";
import Symbolpack_2_06 from "./general_numbers/Symbolpack_2_06.svg";
import Symbolpack_2_07 from "./general_numbers/Symbolpack_2_07.svg";
import Symbolpack_2_08 from "./general_numbers/Symbolpack_2_08.svg";
import Symbolpack_2_09 from "./general_numbers/Symbolpack_2_09.svg";
import Symbolpack_2_10 from "./general_numbers/Symbolpack_2_10.svg";
import Symbolpack_3_01 from "./general_numbers/Symbolpack_3_01.svg";
import Symbolpack_3_02 from "./general_numbers/Symbolpack_3_02.svg";
import Symbolpack_3_03 from "./general_numbers/Symbolpack_3_03.svg";
import Symbolpack_3_04 from "./general_numbers/Symbolpack_3_04.svg";
import Symbolpack_3_05 from "./general_numbers/Symbolpack_3_05.svg";
import Symbolpack_3_06 from "./general_numbers/Symbolpack_3_06.svg";
import Symbolpack_3_07 from "./general_numbers/Symbolpack_3_07.svg";
import Symbolpack_3_08 from "./general_numbers/Symbolpack_3_08.svg";
import Symbolpack_3_09 from "./general_numbers/Symbolpack_3_09.svg";
import Symbolpack_3_10 from "./general_numbers/Symbolpack_3_10.svg";
import Symbolpack_4_01 from "./general_numbers/Symbolpack_4_01.svg";
import Symbolpack_4_02 from "./general_numbers/Symbolpack_4_02.svg";
import Symbolpack_4_03 from "./general_numbers/Symbolpack_4_03.svg";
import Symbolpack_4_04 from "./general_numbers/Symbolpack_4_04.svg";
import Symbolpack_4_05 from "./general_numbers/Symbolpack_4_05.svg";
import Symbolpack_4_06 from "./general_numbers/Symbolpack_4_06.svg";
import Symbolpack_4_07 from "./general_numbers/Symbolpack_4_07.svg";
import Symbolpack_4_08 from "./general_numbers/Symbolpack_4_08.svg";
import Symbolpack_4_09 from "./general_numbers/Symbolpack_4_09.svg";
import Symbolpack_4_10 from "./general_numbers/Symbolpack_4_10.svg";
import Symbolpack_5_01 from "./general_numbers/Symbolpack_5_01.svg";
import Symbolpack_5_02 from "./general_numbers/Symbolpack_5_02.svg";
import Symbolpack_5_03 from "./general_numbers/Symbolpack_5_03.svg";
import Symbolpack_5_04 from "./general_numbers/Symbolpack_5_04.svg";
import Symbolpack_5_05 from "./general_numbers/Symbolpack_5_05.svg";
import Symbolpack_5_06 from "./general_numbers/Symbolpack_5_06.svg";
import Symbolpack_5_07 from "./general_numbers/Symbolpack_5_07.svg";
import Symbolpack_5_08 from "./general_numbers/Symbolpack_5_08.svg";
import Symbolpack_5_09 from "./general_numbers/Symbolpack_5_09.svg";
import Symbolpack_5_10 from "./general_numbers/Symbolpack_5_10.svg";
import Symbolpack_6_01 from "./general_numbers/Symbolpack_6_01.svg";
import Symbolpack_6_02 from "./general_numbers/Symbolpack_6_02.svg";
import Symbolpack_6_03 from "./general_numbers/Symbolpack_6_03.svg";
import Symbolpack_6_04 from "./general_numbers/Symbolpack_6_04.svg";
import Symbolpack_6_05 from "./general_numbers/Symbolpack_6_05.svg";
import Symbolpack_6_06 from "./general_numbers/Symbolpack_6_06.svg";
import Symbolpack_6_07 from "./general_numbers/Symbolpack_6_07.svg";
import Symbolpack_6_08 from "./general_numbers/Symbolpack_6_08.svg";
import Symbolpack_6_09 from "./general_numbers/Symbolpack_6_09.svg";
import Symbolpack_6_10 from "./general_numbers/Symbolpack_6_10.svg";

import Symbolpack_3_solid_01 from "./general_numbers/Symbolpack_3_solid_01.svg";
import Symbolpack_3_solid_02 from "./general_numbers/Symbolpack_3_solid_02.svg";
import Symbolpack_3_solid_03 from "./general_numbers/Symbolpack_3_solid_03.svg";
import Symbolpack_3_solid_04 from "./general_numbers/Symbolpack_3_solid_04.svg";
import Symbolpack_3_solid_05 from "./general_numbers/Symbolpack_3_solid_05.svg";
import Symbolpack_3_solid_06 from "./general_numbers/Symbolpack_3_solid_06.svg";
import Symbolpack_3_solid_07 from "./general_numbers/Symbolpack_3_solid_07.svg";
import Symbolpack_3_solid_08 from "./general_numbers/Symbolpack_3_solid_08.svg";
import Symbolpack_3_solid_09 from "./general_numbers/Symbolpack_3_solid_09.svg";
import Symbolpack_3_solid_10 from "./general_numbers/Symbolpack_3_solid_10.svg";
import Symbolpack_4_solid_01 from "./general_numbers/Symbolpack_4_solid_01.svg";
import Symbolpack_4_solid_02 from "./general_numbers/Symbolpack_4_solid_02.svg";
import Symbolpack_4_solid_03 from "./general_numbers/Symbolpack_4_solid_03.svg";
import Symbolpack_4_solid_04 from "./general_numbers/Symbolpack_4_solid_04.svg";
import Symbolpack_4_solid_05 from "./general_numbers/Symbolpack_4_solid_05.svg";
import Symbolpack_4_solid_06 from "./general_numbers/Symbolpack_4_solid_06.svg";
import Symbolpack_4_solid_07 from "./general_numbers/Symbolpack_4_solid_07.svg";
import Symbolpack_4_solid_08 from "./general_numbers/Symbolpack_4_solid_08.svg";
import Symbolpack_4_solid_09 from "./general_numbers/Symbolpack_4_solid_09.svg";
import Symbolpack_4_solid_10 from "./general_numbers/Symbolpack_4_solid_10.svg";
import Symbolpack_5_solid_01 from "./general_numbers/Symbolpack_5_solid_01.svg";
import Symbolpack_5_solid_02 from "./general_numbers/Symbolpack_5_solid_02.svg";
import Symbolpack_5_solid_03 from "./general_numbers/Symbolpack_5_solid_03.svg";
import Symbolpack_5_solid_04 from "./general_numbers/Symbolpack_5_solid_04.svg";
import Symbolpack_5_solid_05 from "./general_numbers/Symbolpack_5_solid_05.svg";
import Symbolpack_5_solid_06 from "./general_numbers/Symbolpack_5_solid_06.svg";
import Symbolpack_5_solid_07 from "./general_numbers/Symbolpack_5_solid_07.svg";
import Symbolpack_5_solid_08 from "./general_numbers/Symbolpack_5_solid_08.svg";
import Symbolpack_5_solid_09 from "./general_numbers/Symbolpack_5_solid_09.svg";
import Symbolpack_5_solid_10 from "./general_numbers/Symbolpack_5_solid_10.svg";
import Symbolpack_6_solid_01 from "./general_numbers/Symbolpack_6_solid_01.svg";
import Symbolpack_6_solid_02 from "./general_numbers/Symbolpack_6_solid_02.svg";
import Symbolpack_6_solid_03 from "./general_numbers/Symbolpack_6_solid_03.svg";
import Symbolpack_6_solid_04 from "./general_numbers/Symbolpack_6_solid_04.svg";
import Symbolpack_6_solid_05 from "./general_numbers/Symbolpack_6_solid_05.svg";
import Symbolpack_6_solid_06 from "./general_numbers/Symbolpack_6_solid_06.svg";
import Symbolpack_6_solid_07 from "./general_numbers/Symbolpack_6_solid_07.svg";
import Symbolpack_6_solid_08 from "./general_numbers/Symbolpack_6_solid_08.svg";
import Symbolpack_6_solid_09 from "./general_numbers/Symbolpack_6_solid_09.svg";
import Symbolpack_6_solid_10 from "./general_numbers/Symbolpack_6_solid_10.svg";

import Symbolpack_New_255 from "./security_system/Symbolpack_New_255.svg";
import Symbolpack_New_256 from "./security_system/Symbolpack_New_256.svg";
import Symbolpack_New_257 from "./security_system/Symbolpack_New_257.svg";
import Symbolpack_New_258 from "./security_system/Symbolpack_New_258.svg";
import Symbolpack_New_259 from "./security_system/Symbolpack_New_259.svg";

import Symbolpack_New_232 from "./communications/Symbolpack_New_232.svg";
import Symbolpack_New_233 from "./communications/Symbolpack_New_233.svg";
import Symbolpack_New_234 from "./communications/Symbolpack_New_234.svg";
import Symbolpack_New_235 from "./communications/Symbolpack_New_235.svg";
import Symbolpack_New_236 from "./communications/Symbolpack_New_236.svg";
import Symbolpack_New_237 from "./communications/Symbolpack_New_237.svg";

import Symbolpack_New_201 from "./communications/Symbolpack_New_201.svg";
import Symbolpack_New_202 from "./communications/Symbolpack_New_202.svg";
import Symbolpack_New_203 from "./communications/Symbolpack_New_203.svg";
import Symbolpack_New_204 from "./communications/Symbolpack_New_204.svg";
import Symbolpack_New_205 from "./communications/Symbolpack_New_205.svg";
import Symbolpack_New_206 from "./communications/Symbolpack_New_206.svg";
import Symbolpack_New_207 from "./communications/Symbolpack_New_207.svg";

import Symbolpack_New_196 from "./emergency_lighting/Symbolpack_New_196.svg";
import Symbolpack_New_197 from "./emergency_lighting/Symbolpack_New_197.svg";
import Symbolpack_New_198 from "./emergency_lighting/Symbolpack_New_198.svg";
import Symbolpack_New_199 from "./emergency_lighting/Symbolpack_New_199.svg";
import Symbolpack_New_200 from "./emergency_lighting/Symbolpack_New_200.svg";

import Symbolpack_New_191 from "./fire/Symbolpack_New_191.svg";
import Symbolpack_New_192 from "./fire/Symbolpack_New_192.svg";
import Symbolpack_New_193 from "./fire/Symbolpack_New_193.svg";
import Symbolpack_New_194 from "./fire/Symbolpack_New_194.svg";
import Symbolpack_New_195 from "./fire/Symbolpack_New_195.svg";
import Symbolpack_New_227 from "./fire/Symbolpack_New_227.svg";
import Symbolpack_New_275 from "./fire/Symbolpack_New_275.svg";
import Symbolpack_New_282 from "./fire/Symbolpack_New_282.svg";
import Symbolpack_New_283 from "./fire/Symbolpack_New_283.svg";
import Symbolpack_New_333 from "./fire/Symbolpack_New_333.svg";
import Symbolpack_New_334 from "./fire/Symbolpack_New_334.svg";
import Symbolpack_New_335 from "./fire/Symbolpack_New_335.svg";
import Symbolpack_New_336 from "./fire/Symbolpack_New_336.svg";
import Symbolpack_New_337 from "./fire/Symbolpack_New_337.svg";
import Symbolpack_New_338 from "./fire/Symbolpack_New_338.svg";
import Symbolpack_New_339 from "./fire/Symbolpack_New_339.svg";
import Symbolpack_New_340 from "./fire/Symbolpack_New_340.svg";
import Symbolpack_New_341 from "./fire/Symbolpack_New_341.svg";
import Symbolpack_New_342 from "./fire/Symbolpack_New_342.svg";
import Symbolpack_New_343 from "./fire/Symbolpack_New_343.svg";
import Symbolpack_New_344 from "./fire/Symbolpack_New_344.svg";
import Symbolpack_New_345 from "./fire/Symbolpack_New_345.svg";

import Symbolpack_New_260 from "./lighting/Symbolpack_New_260.svg";
import Symbolpack_New_261 from "./lighting/Symbolpack_New_261.svg";
import Symbolpack_New_262 from "./lighting/Symbolpack_New_262.svg";
import Symbolpack_New_263 from "./lighting/Symbolpack_New_263.svg";
import Symbolpack_New_264 from "./lighting/Symbolpack_New_264.svg";
import Symbolpack_New_265 from "./lighting/Symbolpack_New_265.svg";
import Symbolpack_New_266 from "./lighting/Symbolpack_New_266.svg";

import Symbolpack_New_267 from "./plumbing/Symbolpack_New_267.svg";
import Symbolpack_New_268 from "./plumbing/Symbolpack_New_268.svg";
import Symbolpack_New_269 from "./plumbing/Symbolpack_New_269.svg";
import Symbolpack_New_270 from "./plumbing/Symbolpack_New_270.svg";
import Symbolpack_New_271 from "./plumbing/Symbolpack_New_271.svg";
import Symbolpack_New_272 from "./plumbing/Symbolpack_New_272.svg";
import Symbolpack_New_273 from "./plumbing/Symbolpack_New_273.svg";
import Symbolpack_New_274 from "./plumbing/Symbolpack_New_274.svg";
import Symbolpack_New_276 from "./plumbing/Symbolpack_New_276.svg";
import Symbolpack_New_277 from "./plumbing/Symbolpack_New_277.svg";
import Symbolpack_New_278 from "./plumbing/Symbolpack_New_278.svg";
import Symbolpack_New_279 from "./plumbing/Symbolpack_New_279.svg";
import Symbolpack_New_280 from "./plumbing/Symbolpack_New_280.svg";
import Symbolpack_New_281 from "./plumbing/Symbolpack_New_281.svg";
import Symbolpack_New_284 from "./plumbing/Symbolpack_New_284.svg";
import Symbolpack_New_285 from "./plumbing/Symbolpack_New_285.svg";
import Symbolpack_New_286 from "./plumbing/Symbolpack_New_286.svg";
import Symbolpack_New_287 from "./plumbing/Symbolpack_New_287.svg";
import Symbolpack_New_288 from "./plumbing/Symbolpack_New_288.svg";
import Symbolpack_New_289 from "./plumbing/Symbolpack_New_289.svg";
import Symbolpack_New_290 from "./plumbing/Symbolpack_New_290.svg";
import Symbolpack_New_291 from "./plumbing/Symbolpack_New_291.svg";
import Symbolpack_New_292 from "./plumbing/Symbolpack_New_292.svg";
import Symbolpack_New_293 from "./plumbing/Symbolpack_New_293.svg";
import Symbolpack_New_294 from "./plumbing/Symbolpack_New_294.svg";
import Symbolpack_New_295 from "./plumbing/Symbolpack_New_295.svg";
import Symbolpack_New_296 from "./plumbing/Symbolpack_New_296.svg";
import Symbolpack_New_297 from "./plumbing/Symbolpack_New_297.svg";
import Symbolpack_New_298 from "./plumbing/Symbolpack_New_298.svg";
import Symbolpack_New_299 from "./plumbing/Symbolpack_New_299.svg";
import Symbolpack_New_300 from "./plumbing/Symbolpack_New_300.svg";
import Symbolpack_New_301 from "./plumbing/Symbolpack_New_301.svg";
import Symbolpack_New_302 from "./plumbing/Symbolpack_New_302.svg";
import Symbolpack_New_303 from "./plumbing/Symbolpack_New_303.svg";
import Symbolpack_New_304 from "./plumbing/Symbolpack_New_304.svg";
import Symbolpack_New_305 from "./plumbing/Symbolpack_New_305.svg";
import Symbolpack_New_306 from "./plumbing/Symbolpack_New_306.svg";
import Symbolpack_New_307 from "./plumbing/Symbolpack_New_307.svg";
import Symbolpack_New_308 from "./plumbing/Symbolpack_New_308.svg";
import Symbolpack_New_309 from "./plumbing/Symbolpack_New_309.svg";
import Symbolpack_New_310 from "./plumbing/Symbolpack_New_310.svg";
import Symbolpack_New_311 from "./plumbing/Symbolpack_New_311.svg";
import Symbolpack_New_312 from "./plumbing/Symbolpack_New_312.svg";
import Symbolpack_New_313 from "./plumbing/Symbolpack_New_313.svg";
import Symbolpack_New_314 from "./plumbing/Symbolpack_New_314.svg";
import Symbolpack_New_315 from "./plumbing/Symbolpack_New_315.svg";
import Symbolpack_New_316 from "./plumbing/Symbolpack_New_316.svg";
import Symbolpack_New_317 from "./plumbing/Symbolpack_New_317.svg";
import Symbolpack_New_318 from "./plumbing/Symbolpack_New_318.svg";
import Symbolpack_New_319 from "./plumbing/Symbolpack_New_319.svg";
import Symbolpack_New_320 from "./plumbing/Symbolpack_New_320.svg";
import Symbolpack_New_321 from "./plumbing/Symbolpack_New_321.svg";
import Symbolpack_New_322 from "./plumbing/Symbolpack_New_322.svg";
import Symbolpack_New_324 from "./plumbing/Symbolpack_New_324.svg";
import Symbolpack_New_325 from "./plumbing/Symbolpack_New_325.svg";
import Symbolpack_New_326 from "./plumbing/Symbolpack_New_326.svg";
import Symbolpack_New_327 from "./plumbing/Symbolpack_New_327.svg";
import Symbolpack_New_328 from "./plumbing/Symbolpack_New_328.svg";
import Symbolpack_New_329 from "./plumbing/Symbolpack_New_329.svg";
import Symbolpack_New_330 from "./plumbing/Symbolpack_New_330.svg";
import Symbolpack_New_331 from "./plumbing/Symbolpack_New_331.svg";
import Symbolpack_New_332 from "./plumbing/Symbolpack_New_332.svg";

import Symbolpack_New_208 from "./power/Symbolpack_New_208.svg";
import Symbolpack_New_209 from "./power/Symbolpack_New_209.svg";
import Symbolpack_New_210 from "./power/Symbolpack_New_210.svg";
import Symbolpack_New_211 from "./power/Symbolpack_New_211.svg";
import Symbolpack_New_212 from "./power/Symbolpack_New_212.svg";
import Symbolpack_New_213 from "./power/Symbolpack_New_213.svg";
import Symbolpack_New_214 from "./power/Symbolpack_New_214.svg";
import Symbolpack_New_215 from "./power/Symbolpack_New_215.svg";
import Symbolpack_New_216 from "./power/Symbolpack_New_216.svg";

import Symbolpack_New_217 from "./security_system/Symbolpack_New_217.svg";
import Symbolpack_New_218 from "./security_system/Symbolpack_New_218.svg";
import Symbolpack_New_219 from "./security_system/Symbolpack_New_219.svg";
import Symbolpack_New_220 from "./security_system/Symbolpack_New_220.svg";
import Symbolpack_New_221 from "./security_system/Symbolpack_New_221.svg";
import Symbolpack_New_222 from "./security_system/Symbolpack_New_222.svg";
import Symbolpack_New_223 from "./security_system/Symbolpack_New_223.svg";
import Symbolpack_New_224 from "./security_system/Symbolpack_New_224.svg";
import Symbolpack_New_225 from "./security_system/Symbolpack_New_225.svg";
import Symbolpack_New_226 from "./security_system/Symbolpack_New_226.svg";
import Symbolpack_New_228 from "./security_system/Symbolpack_New_228.svg";
import Symbolpack_New_229 from "./security_system/Symbolpack_New_229.svg";
import Symbolpack_New_230 from "./security_system/Symbolpack_New_230.svg";
import Symbolpack_New_231 from "./security_system/Symbolpack_New_231.svg";

import Symbolpack_New_238 from "./switch_board_schematics/Symbolpack_New_238.svg";
import Symbolpack_New_239 from "./switch_board_schematics/Symbolpack_New_239.svg";
import Symbolpack_New_240 from "./switch_board_schematics/Symbolpack_New_240.svg";
import Symbolpack_New_241 from "./switch_board_schematics/Symbolpack_New_241.svg";
import Symbolpack_New_242 from "./switch_board_schematics/Symbolpack_New_242.svg";
import Symbolpack_New_243 from "./switch_board_schematics/Symbolpack_New_243.svg";
import Symbolpack_New_244 from "./switch_board_schematics/Symbolpack_New_244.svg";
import Symbolpack_New_245 from "./switch_board_schematics/Symbolpack_New_245.svg";
import Symbolpack_New_246 from "./switch_board_schematics/Symbolpack_New_246.svg";
import Symbolpack_New_247 from "./switch_board_schematics/Symbolpack_New_247.svg";
import Symbolpack_New_248 from "./switch_board_schematics/Symbolpack_New_248.svg";
import Symbolpack_New_249 from "./switch_board_schematics/Symbolpack_New_249.svg";
import Symbolpack_New_250 from "./switch_board_schematics/Symbolpack_New_250.svg";
import Symbolpack_New_251 from "./switch_board_schematics/Symbolpack_New_251.svg";
import Symbolpack_New_252 from "./switch_board_schematics/Symbolpack_New_252.svg";
import Symbolpack_New_253 from "./switch_board_schematics/Symbolpack_New_253.svg";
import Symbolpack_New_254 from "./switch_board_schematics/Symbolpack_New_254.svg";
import Symbolpack_New_323 from "./switch_board_schematics/Symbolpack_New_323.svg";

import Symbol_pack_346 from "./security_system/Symbol_pack_346.svg";
import Symbol_pack_347 from "./security_system/Symbol_pack_347.svg";
import Symbol_pack_348 from "./security_system/Symbol_pack_348.svg";
import Symbol_pack_349 from "./security_system/Symbol_pack_349.svg";
import Symbol_pack_350 from "./security_system/Symbol_pack_350.svg";
import Symbol_pack_351 from "./security_system/Symbol_pack_351.svg";
import Symbol_pack_352 from "./security_system/Symbol_pack_352.svg";
import Symbol_pack_353 from "./security_system/Symbol_pack_353.svg";
import Symbol_pack_354 from "./security_system/Symbol_pack_354.svg";
import Symbol_pack_355 from "./security_system/Symbol_pack_355.svg";
import Symbol_pack_356 from "./security_system/Symbol_pack_356.svg";
import Symbol_pack_357 from "./security_system/Symbol_pack_357.svg";
import Symbol_pack_358 from "./security_system/Symbol_pack_358.svg";
import Symbol_pack_359 from "./security_system/Symbol_pack_359.svg";
import Symbol_pack_360 from "./security_system/Symbol_pack_360.svg";
import Symbol_pack_361 from "./security_system/Symbol_pack_361.svg";
import Symbol_pack_362 from "./security_system/Symbol_pack_362.svg";
import Symbol_pack_363 from "./security_system/Symbol_pack_363.svg";
import Symbol_pack_364 from "./security_system/Symbol_pack_364.svg";
import Symbol_pack_365 from "./security_system/Symbol_pack_365.svg";
import Symbol_pack_366 from "./security_system/Symbol_pack_366.svg";
import Symbol_pack_367 from "./security_system/Symbol_pack_367.svg";
import Symbol_pack_368 from "./security_system/Symbol_pack_368.svg";
import Symbol_pack_369 from "./security_system/Symbol_pack_369.svg";
import Symbol_pack_370 from "./security_system/Symbol_pack_370.svg";
import Symbol_pack_371 from "./security_system/Symbol_pack_371.svg";
import Symbol_pack_372 from "./security_system/Symbol_pack_372.svg";
import Symbol_pack_373 from "./security_system/Symbol_pack_373.svg";
import Symbol_pack_374 from "./security_system/Symbol_pack_374.svg";
import Symbol_pack_375 from "./security_system/Symbol_pack_375.svg";
import Symbol_pack_376 from "./security_system/Symbol_pack_376.svg";
import Symbol_pack_377 from "./security_system/Symbol_pack_377.svg";
import Symbol_pack_378 from "./security_system/Symbol_pack_378.svg";
import Symbol_pack_379 from "./security_system/Symbol_pack_379.svg";
import Symbol_pack_380 from "./security_system/Symbol_pack_380.svg";
import Symbol_pack_381 from "./security_system/Symbol_pack_381.svg";
import Symbol_pack_382 from "./security_system/Symbol_pack_382.svg";
import Symbol_pack_383 from "./security_system/Symbol_pack_383.svg";
import Symbol_pack_384 from "./security_system/Symbol_pack_384.svg";
import Symbol_pack_385 from "./security_system/Symbol_pack_385.svg";
import Symbol_pack_386 from "./security_system/Symbol_pack_386.svg";
import Symbol_pack_387 from "./security_system/Symbol_pack_387.svg";
import Symbol_pack_388 from "./security_system/Symbol_pack_388.svg";
import Symbol_pack_389 from "./security_system/Symbol_pack_389.svg";
import Symbol_pack_390 from "./security_system/Symbol_pack_390.svg";
import Symbol_pack_391 from "./security_system/Symbol_pack_391.svg";
import Symbol_pack_392 from "./security_system/Symbol_pack_392.svg";
import Symbol_pack_393 from "./security_system/Symbol_pack_393.svg";
import Symbol_pack_394 from "./security_system/Symbol_pack_394.svg";
import Symbol_pack_395 from "./security_system/Symbol_pack_395.svg";
import Symbol_pack_396 from "./security_system/Symbol_pack_396.svg";
import Symbol_pack_397 from "./security_system/Symbol_pack_397.svg";
import Symbol_pack_398 from "./security_system/Symbol_pack_398.svg";
import Symbol_pack_399 from "./security_system/Symbol_pack_399.svg";
import Symbol_pack_400 from "./security_system/Symbol_pack_400.svg";
import Symbol_pack_401 from "./security_system/Symbol_pack_401.svg";
import Symbol_pack_402 from "./security_system/Symbol_pack_402.svg";
import Symbol_pack_403 from "./security_system/Symbol_pack_403.svg";


export enum SymbolType {
	CIRCLE = "CIRCLE",
	SQUARE = "SQUARE",
	TRIANGLE = "TRIANGLE",
	STAR = "STAR",
	ANNUNCIATOR_01 = "ANNUNCIATOR_01",
	AUTOMATIC_FIRE_ALARM_DEVICE_01 = "AUTOMATIC_FIRE_ALARM_DEVICE_01",
	BATTERY_01 = "BATTERY_01",
	BUZZER_01 = "BUZZER_01",
	CABLE_TV_OUTLET = "CABLE_TV_OUTLET",
	CARD_READER_ACCESS_SYSTEM_01 = "CARD_READER_ACCESS_SYSTEM_01",
	CEILING_FAN = "CEILING_FAN",
	DIGITAL_SATELLITE_SYSTEM_01 = "DIGITAL_SATELLITE_SYSTEM_01",
	DIMMER_SWITCHES = "DIMMER_SWITCHES",
	DIMMER_SWITCHES_01 = "DIMMER_SWITCHES_01",
	DOORBELL_TRANSFORMER_01 = "DOORBELL_TRANSFORMER_01",
	DOORBELL_01 = "DOORBELL_01",
	DOORBELL_CHIME_01 = "DOORBELL_CHIME_01",
	DOUBLE_SOCKET = "DOUBLE_SOCKET",
	DOWNLIGHTER = "DOWNLIGHTER",
	ELECTRIC_DOOR_OPENER_01 = "ELECTRIC_DOOR_OPENER_01",
	ELECTRIC_MOTORS_01 = "ELECTRIC_MOTORS_01",
	ELECTROMAGNETIC_DOOR_01 = "ELECTROMAGNETIC_DOOR_01",
	EMERGENCY_LIGHT = "EMERGENCY_LIGHT",
	EMERGENCY_RELEASE_BUTTON_01 = "EMERGENCY_RELEASE_BUTTON_01",
	FIRE_ALARM_BELL_01 = "FIRE_ALARM_BELL_01",
	FIRE_ALARM_CENTRAL_STATION_01 = "FIRE_ALARM_CENTRAL_STATION_01",
	FIRE_ALARM_PANEL = "FIRE_ALARM_PANEL",
	FIRE_ALARM_SOUNDER_01 = "FIRE_ALARM_SOUNDER_01",
	FIRE_ALARM_STATION_01 = "FIRE_ALARM_STATION_01",
	FLOURESCENT_FIXTURE = "FLOURESCENT_FIXTURE",
	GROUND_CONNECTION_01 = "GROUND_CONNECTION_01",
	HORN_01 = "HORN_01",
	ILLUMINATED_EMERGENCY_SIGN = "ILLUMINATED_EMERGENCY_SIGN",
	INSIDE_ANTENNA_01 = "INSIDE_ANTENNA_01",
	INTERCOM_01 = "INTERCOM_01",
	MAGNETIC_DOO_HOLD_01 = "MAGNETIC_DOO_HOLD_01",
	MAIDS_SIGNAL_PLUG_01 = "MAIDS_SIGNAL_PLUG_01",
	MAIN_CONTROL_01 = "MAIN_CONTROL_01",
	MODULAR_FLOURESCENT_FITTING = "MODULAR_FLOURESCENT_FITTING",
	MOTION_SENSOR_01 = "MOTION_SENSOR_01",
	MULTI_LIGHTBAR = "MULTI_LIGHTBAR",
	MULTI_SWITCH = "MULTI_SWITCH",
	OUTDOOR_LIGHTING = "OUTDOOR_LIGHTING",
	OUTSIDE_ANTENNA_01 = "OUTSIDE_ANTENNA_01",
	PULL_CORD_SWITCH = "PULL_CORD_SWITCH",
	PUSH_BUTTON_01 = "PUSH_BUTTON_01",
	SIGNAL_CENTRAL_STATION_01 = "SIGNAL_CENTRAL_STATION_01",
	SINGLE_PHASE_01 = "SINGLE_PHASE_01",
	SOCKET_OUTLET = "SOCKET_OUTLET",
	STEREO_SPEAKER_OUTLET = "STEREO_SPEAKER_OUTLET",
	SURFACE_FLOURESCENT_LIGHT = "SURFACE_FLOURESCENT_LIGHT",
	SWITCH = "SWITCH",
	SWITCH_2WAY = "SWITCH_2WAY",
	SWITCH_12POLE = "SWITCH_12POLE",
	SWITCHBOARDS_FLUSH_01 = "SWITCHBOARDS_FLUSH_01",
	SWITCHBOARDS_SURFACE_01 = "SWITCHBOARDS_SURFACE_01",
	SWITCHED_SOCKET = "SWITCHED_SOCKET",
	SWITCH_INTERMEDIATE = "SWITCH_INTERMEDIATE",
	TELEPHONE_KEY_SYSTEM_01 = "TELEPHONE_KEY_SYSTEM_01",
	TELEPHONE_OUTLET = "TELEPHONE_OUTLET",
	THERMOSTAT = "THERMOSTAT",
	THREE_OF_POLYPHASE_01 = "THREE_OF_POLYPHASE_01",
	WALL_LIGHT = "WALL_LIGHT",
	WALL_MOUNTED_ELECTRICAL_JUNCTION_BOX_01 = "WALL_MOUNTED_ELECTRICAL_JUNCTION_BOX_01",
	WALL_MOUNTED_TELEPHONE_DATABOX_01 = "WALL_MOUNTED_TELEPHONE_DATABOX_01",
	WATCHMANS_CENTRAL_STATION_01 = "WATCHMANS_CENTRAL_STATION_01",
	WATCHMANS_STATION_01 = "WATCHMANS_STATION_01",
	TWO_GANG_SSO_01 = "TWO_GANG_SSO_01",
	TWO_FOUR_TUBE_FLOURESCENT_LUMINAIRE_01 = "TWO_FOUR_TUBE_FLOURESCENT_LUMINAIRE_01",
	CONSUMER_UNIT_01 = "CONSUMER_UNIT_01",
	CONSUMER_UNIT_DISTRIBUTION_BOARD_FUSE_BOARD_01 = "CONSUMER_UNIT_DISTRIBUTION_BOARD_FUSE_BOARD_01",
	EMERGENCY_LIGHT_01 = "EMERGENCY_LIGHT_01",
	ENERGY_METER_01 = "ENERGY_METER_01",
	FLOURESCENT_LUMINAIRE_01 = "FLOURESCENT_LUMINAIRE_01",
	FUSE_01 = "FUSE_01",
	INVERTERTER_01 = "INVERTERTER_01",
	MOTOR_STARTER_01 = "MOTOR_STARTER_01",
	RECTIFIER_01 = "RECTIFIER_01",
	SELF_CONTAINED_EMERGENCY_LIGHT_01 = "SELF_CONTAINED_EMERGENCY_LIGHT_01",
	SOCKET_OUTLET_01 = "SOCKET_OUTLET_01",
	STAR_DELTA_STARTER_01 = "STAR_DELTA_STARTER_01",
	SWITCH_01 = "SWITCH_01",
	SWITCHED_SOCKET_OUTLET_01 = "SWITCHED_SOCKET_OUTLET_01",
	THREE_PHASE_WINDING_DELTA_01 = "THREE_PHASE_WINDING_DELTA_01",
	THREE_PHASE_WINDING_STAR_01 = "THREE_PHASE_WINDING_STAR_01",
	CEILING_ROSE = "CEILING_ROSE",
	EMERGENCY_LAMP_1 = "EMERGENCY_LAMP_1",
	EMERGENCY_LAMP_2 = "EMERGENCY_LAMP_2",
	EMERGENCY_LAMP_3 = "EMERGENCY_LAMP_3",
	EMERGENCY_LAMP_4 = "EMERGENCY_LAMP_4",
	FLASHING_LAMP = "FLASHING_LAMP",
	FLOODLIGHT = "FLOODLIGHT",
	FLOURESCENT_1 = "FLOURESCENT_1",
	FLOURESCENT_2 = "FLOURESCENT_2",
	FLOURESCENT_3 = "FLOURESCENT_3",
	FLOURESCENT_4 = "FLOURESCENT_4",
	LAMP_1 = "LAMP_1",
	LAMP_2 = "LAMP_2",
	LAMP_3 = "LAMP_3",
	LAMP_4 = "LAMP_4",
	LAMP_WALL_MOUNTED = "LAMP_WALL_MOUNTED",
	LIGHT_FITTING = "LIGHT_FITTING",
	LIGHTING_POSITION = "LIGHTING_POSITION",
	PROTECTED_LIGHT_FITTING = "PROTECTED_LIGHT_FITTING",
	PULL_CORD = "PULL_CORD",
	SCONCE = "SCONCE",
	SPOTLIGHT_1 = "SPOTLIGHT_1",
	SPOTLIGHT_2 = "SPOTLIGHT_2",
	ONE_GANG_TWO_WAY_LIGHT_SWITCHING = "ONE_GANG_TWO_WAY_LIGHT_SWITCHING",
	ONE_GANG_THREE_WAY_LIGHT_SWITCHING = "ONE_GANG_THREE_WAY_LIGHT_SWITCHING",
	ONE_GANG_LIGHT_SWITCH = "ONE_GANG_LIGHT_SWITCH",
	ONE_GANG_PULL_CORD = "ONE_GANG_PULL_CORD",
	TWO_CORE_SPEAKER_CABLE = "TWO_CORE_SPEAKER_CABLE",
	TWO_GANG_TWO_WAY_LIGHT_SWITCH = "TWO_GANG_TWO_WAY_LIGHT_SWITCH",
	TWO_GANG_THREE_WAY_LIGHT_SWITCH = "TWO_GANG_THREE_WAY_LIGHT_SWITCH",
	TWO_GANG_LIGHT_SWITCH = "TWO_GANG_LIGHT_SWITCH",
	AUDIO_SPEAKER = "AUDIO_SPEAKER",
	CAT_5E = "CAT_5E",
	CAT_5_OR_6_DATA_SOCKET = "CAT_5_OR_6_DATA_SOCKET",
	CONSUMER_UNIT_2 = "CONSUMER_UNIT_2",
	COOKER_SWITCH = "COOKER_SWITCH",
	DOORBELL_CHIME_2 = "DOORBELL_CHIME_2",
	DOORBELL_PUSH = "DOORBELL_PUSH",
	DOUBLE_SWITCHED_FLOOR_SOCKETS = "DOUBLE_SWITCHED_FLOOR_SOCKETS",
	DOUBLE_SWITCHED_WALL_SOCKET = "DOUBLE_SWITCHED_WALL_SOCKET",
	DOUBLE_SWITCHED_WALL_SOCKET_1200MM = "DOUBLE_SWITCHED_WALL_SOCKET_1200MM",
	EXTERNAL_LIGHT = "EXTERNAL_LIGHT",
	EXTERNAL_LIGHT_BULKHEAD_TYPE = "EXTERNAL_LIGHT_BULKHEAD_TYPE",
	FLOURESCENT_STRIP_LIGHT = "FLOURESCENT_STRIP_LIGHT",
	FUSE_SPUR_FOR_TOWEL_RAIL = "FUSE_SPUR_FOR_TOWEL_RAIL",
	HEAT_DETECTOR_AND_FIRE_ALARM_SOUNDER = "HEAT_DETECTOR_AND_FIRE_ALARM_SOUNDER",
	IMMERSION_HEATER_AND_THERMOSTAT = "IMMERSION_HEATER_AND_THERMOSTAT",
	LIGHT_POINT_BATTEN_HOLDER = "LIGHT_POINT_BATTEN_HOLDER",
	LIGHT_POINT_PENDANT_HOLDER_4INCH = "LIGHT_POINT_PENDANT_HOLDER_4INCH",
	LIGHT_POINT_PENDANT_HOLDER_6INCH = "LIGHT_POINT_PENDANT_HOLDER_6INCH",
	MECHANICAL_EXTRACT_FAN = "MECHANICAL_EXTRACT_FAN",
	MECHANICAL_EXTRACT_FAN_COOKER_HOB_HOOD = "MECHANICAL_EXTRACT_FAN_COOKER_HOB_HOOD",
	RADIATOR = "RADIATOR",
	RECESSED_DOWNLIGHT = "RECESSED_DOWNLIGHT",
	SECURITY_ALARM = "SECURITY_ALARM",
	SINGLE_SWITCHED_FUSE_SPUR_AT_HIGH_LEVEL_FOR_EXTRACT_FAN = "SINGLE_SWITCHED_FUSE_SPUR_AT_HIGH_LEVEL_FOR_EXTRACT_FAN",
	SINGLE_SWITCHED_FUSE_SPUR_BELOW_FL = "SINGLE_SWITCHED_FUSE_SPUR_BELOW_FL",
	SINGLE_SWITCHED_WALL_SOCKET = "SINGLE_SWITCHED_WALL_SOCKET",
	SINGLE_SWITCHED_WALL_SOCKET_1200MM = "SINGLE_SWITCHED_WALL_SOCKET_1200MM",
	SMOKE_HEAT_DETECTOR_FIRE_ALARM_SOUNDER = "SMOKE_HEAT_DETECTOR_FIRE_ALARM_SOUNDER",
	TELEPHONE_POINT = "TELEPHONE_POINT",
	THERMOSTAT_2 = "THERMOSTAT_2",
	TV_AERIAL_POINT = "TV_AERIAL_POINT",
	UNDERFLOOR_HEATING_MANIFOLD = "UNDERFLOOR_HEATING_MANIFOLD",
	WALL_LIGHT_2 = "WALL_LIGHT_2",
	// electrical_actuators
	ACTUATORBY_MECHANICAL_ENERGY_ACCUMULATION = "ACTUATORBY_MECHANICAL_ENERGY_ACCUMULATION",
	ACTUATOR_LEVEL_POWEREDBY_LIQUID_LEVEL = "ACTUATOR_LEVEL_POWEREDBY_LIQUID_LEVEL",
	ACTUATOR_MANUAL_CONTROL = "ACTUATOR_MANUAL_CONTROL",
	ACTUATOR_MANUAL_PROTECTAGAINST_ROUGH_HANDLING = "ACTUATOR_MANUAL_PROTECTAGAINST_ROUGH_HANDLING",
	CONTACT_CONTROL = "CONTACT_CONTROL",
	COUNTER_POWERED_ACTUATOR = "COUNTER_POWERED_ACTUATOR",
	DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_DOUBLE_EFFECT = "DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_DOUBLE_EFFECT",
	DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_SA = "DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_SA",
	ELECTRICAL_CLOCK_CONTROL = "ELECTRICAL_CLOCK_CONTROL",
	ELECTRIC_MOTOR_CONTROL = "ELECTRIC_MOTOR_CONTROL",
	ELECTROMAGNETIC_ACTUATOR = "ELECTROMAGNETIC_ACTUATOR",
	EMERGENCY_ACTUATOR = "EMERGENCY_ACTUATOR",
	FLUID_ACTUATOR = "FLUID_ACTUATOR",
	FREQUENCY_ACTUATOR = "FREQUENCY_ACTUATOR",
	GAS_ACTUATOR_POWEREDBY_FLUID_GAS = "GAS_ACTUATOR_POWEREDBY_FLUID_GAS",
	MECHANICAL_CONTROL_BY_CRANK = "MECHANICAL_CONTROL_BY_CRANK",
	MECHANICAL_CONTROLBY_PEDAL = "MECHANICAL_CONTROLBY_PEDAL",
	MECHANICAL_CONTROLBY_ROLLEROR_SLIDE = "MECHANICAL_CONTROLBY_ROLLEROR_SLIDE",
	MECHANICAL_CONTROL_BY_SHOOTER_AUTO_RETURN = "MECHANICAL_CONTROL_BY_SHOOTER_AUTO_RETURN",
	MECHANICAL_CONTROL_CAM = "MECHANICAL_CONTROL_CAM",
	MECHANICAL_CONTROL_HAND_WHEEL = "MECHANICAL_CONTROL_HAND_WHEEL",
	MECHANICAL_CONTROL_KEY = "MECHANICAL_CONTROL_KEY",
	MECHANICAL_CONTROL_PUSH_BUTTON_AUTO_RETURN = "MECHANICAL_CONTROL_PUSH_BUTTON_AUTO_RETURN",
	MECHANICAL_MANUAL_CONTROL_WITH_LEVER = "MECHANICAL_MANUAL_CONTROL_WITH_LEVER",
	MECHANICAL_MANUAL_ROTARY_CONTROL = "MECHANICAL_MANUAL_ROTARY_CONTROL",
	PRESSURE_ACTUATOR = "PRESSURE_ACTUATOR",
	PROXY_CONTROL_GENERIC = "PROXY_CONTROL_GENERIC",
	RELATIVE_HUMIDITY_ACTUATOR = "RELATIVE_HUMIDITY_ACTUATOR",
	RELAY_ELECTROMAGNETICACTUATOR = "RELAY_ELECTROMAGNETICACTUATOR",
	REMOVABLE_MANUAL_CONTROL = "REMOVABLE_MANUAL_CONTROL",
	THERMAL_ACTUATOR_BY_TEMP_EFFECT = "THERMAL_ACTUATOR_BY_TEMP_EFFECT",
	// fire_alarm
	ALARM_BUTTON_PANIC_BUTTON = "ALARM_BUTTON_PANIC_BUTTON",
	FIRE_SENSOR_AUTOMATIC_FIRE_SENSOR = "FIRE_SENSOR_AUTOMATIC_FIRE_SENSOR",
	FIRE_WARNING_DEVICE = "FIRE_WARNING_DEVICE",
	FLAME_DETECTOR = "FLAME_DETECTOR",
	HEAT_DETECTOR = "HEAT_DETECTOR",
	IONIZING_SMOKE_DETECTOR = "IONIZING_SMOKE_DETECTOR",
	SIREN_ALARM = "SIREN_ALARM",
	SMOKE_ALARM = "SMOKE_ALARM",
	SMOKE_DETECTOR = "SMOKE_DETECTOR",
	SMOKE_DETECTORWITH_ALARM = "SMOKE_DETECTORWITH_ALARM",
	// security_system
	BROKEN_GLASS_SENSOR = "BROKEN_GLASS_SENSOR",
	BUGLAR_SECURITY_ALARM = "BUGLAR_SECURITY_ALARM",
	GLASS_BREAK_DETECTOR = "GLASS_BREAK_DETECTOR",
	INFRARED_MOTION_SENSOR = "INFRARED_MOTION_SENSOR",
	KEYBOARD_ALARM_KEYPAD = "KEYBOARD_ALARM_KEYPAD",
	MAGNETIC_SENSOR_MAGNETOF_ALARM = "MAGNETIC_SENSOR_MAGNETOF_ALARM",
	MOTION_DETECTOR_SENSOR = "MOTION_DETECTOR_SENSOR",
	PANIC_BUTTON = "PANIC_BUTTON",
	P_I_R_ANTIMASKING = "P_I_R_ANTIMASKING",
	P_I_R_EMITTER = "P_I_R_EMITTER",
	P_I_R_RECEPTOR = "P_I_R_RECEPTOR",
	P_I_R_SENSOR_PASSIVE_INFRARED_DETECTOR_MOTION_DETECTOR = "P_I_R_SENSOR_PASSIVE_INFRARED_DETECTOR_MOTION_DETECTOR",
	P_I_R_WEATHERING_P_I_R_OUTDOOR = "P_I_R_WEATHERING_P_I_R_OUTDOOR",
	SPEAKER_SIREN_ALARM = "SPEAKER_SIREN_ALARM",
	ULTRASONIC_MOTION_SENSOR = "ULTRASONIC_MOTION_SENSOR",
	// lighting_symbols
	AUTONOMOUS_LIGHT_POINT = "AUTONOMOUS_LIGHT_POINT",
	AUXILIARY_DISCHARGE_LAMP_DEVICE = "AUXILIARY_DISCHARGE_LAMP_DEVICE",
	BLOCK_AUTONOMOUS_EMERGENCY_LIGHT = "BLOCK_AUTONOMOUS_EMERGENCY_LIGHT",
	DISCHARGE_LAMP2 = "DISCHARGE_LAMP2",
	EMBEDDED_FLUORESCEN_LINE_OUTPUT = "EMBEDDED_FLUORESCEN_LINE_OUTPUT",
	EMBEDDED_FLUORESCENT_OUTPUT = "EMBEDDED_FLUORESCENT_OUTPUT",
	EMBEDDED_MERCURY_LAMP_OUTPUT = "EMBEDDED_MERCURY_LAMP_OUTPUT",
	EMERGENCY_LIGHTING = "EMERGENCY_LIGHTING",
	FLASHBULB = "FLASHBULB",
	FLUORESCENT_LAMP = "FLUORESCENT_LAMP",
	FLUORESCENT_OUTPUT = "FLUORESCENT_OUTPUT",
	FLUORESCENT_OUTPUT_LINE = "FLUORESCENT_OUTPUT_LINE",
	INCANDESCENT_LAMP_LIGHTBULB = "INCANDESCENT_LAMP_LIGHTBULB",
	INCANDESCENT_LAMP_LIGHTBULB2 = "INCANDESCENT_LAMP_LIGHTBULB2",
	INCANDESCENT_LAMP_LIGHTBULB3 = "INCANDESCENT_LAMP_LIGHTBULB3",
	LAMP = "LAMP",
	LAMP_LOAD = "LAMP_LOAD",
	LAMPWITH_TRANSFORMER = "LAMPWITH_TRANSFORMER",
	LIGHTBULB_GENERIC = "LIGHTBULB_GENERIC",
	LIGHTING_OUTPUT = "LIGHTING_OUTPUT",
	LIGHTNING_LIGHT_LAMP = "LIGHTNING_LIGHT_LAMP",
	LOW_INTENSITY_LAMPS = "LOW_INTENSITY_LAMPS",
	LUMINAIRE_LIGHT_FIXTURE = "LUMINAIRE_LIGHT_FIXTURE",
	LUMINAIRE_POINT = "LUMINAIRE_POINT",
	LUMINAIRE_WITH_THREE_ELEMENT = "LUMINAIRE_WITH_THREE_ELEMENT",
	LUMINOUS_INDICATOR = "LUMINOUS_INDICATOR",
	MERCURY_LAMP_OUTPUT = "MERCURY_LAMP_OUTPUT",
	NEON_LAMP = "NEON_LAMP",
	OSCILLATORY_LAMP = "OSCILLATORY_LAMP",
	PROJECTED_LIGHT = "PROJECTED_LIGHT",
	SEPERATE_AUXILIARY_LIGHTS = "SEPERATE_AUXILIARY_LIGHTS",
	WALL_LAMP_OUTPUT = "WALL_LAMP_OUTPUT",
	WALL_LIGHTING = "WALL_LIGHTING",
	XENON_FLASH_LAMP = "XENON_FLASH_LAMP",
	// plumbing_symbols
	BATHTUB = "BATHTUB",
	CAP = "CAP",
	CLEAN_OUT = "CLEAN_OUT",
	CLOTHES_WASHER = "CLOTHES_WASHER",
	DISHWASHER = "DISHWASHER",
	DOUBLE_SINK_WITH_CABINET = "DOUBLE_SINK_WITH_CABINET",
	FLOOR_DRAIN = "FLOOR_DRAIN",
	FREESTANDING_SINK = "FREESTANDING_SINK",
	GATE_VALVE = "GATE_VALVE",
	LAVATORY = "LAVATORY",
	PIPE_TURNS_DOWN = "PIPE_TURNS_DOWN",
	PIPE_TURNS_UP = "PIPE_TURNS_UP",
	SHOWER = "SHOWER",
	SINK_WITH_CABINET = "SINK_WITH_CABINET",
	TOILET = "TOILET",
	URINAL = "URINAL",
	VENT_THRU_ROOF = "VENT_THRU_ROOF",
	WATER_CLOSET = "WATER_CLOSET",
	WATER_HEATER = "WATER_HEATER",
	WATER_HEATER_SHUT_OFF = "WATER_HEATER_SHUT_OFF",
	WATER_METER = "WATER_METER",
	// hvac_symbols
	AIR_CONDITIONER = "AIR_CONDITIONER",
	AIR_FILTER = "AIR_FILTER",
	CONDENSER = "CONDENSER",
	FAN = "FAN",
	HEATER = "HEATER",
	RETURN_AIR_VENT = "RETURN_AIR_VENT",
	STRAIGHT_DUCT = "STRAIGHT_DUCT",
	Y_JUNCTION_DUCT = "Y_JUNCTION_DUCT",
	// general_letters
	SYMBOLPACK_2_11 = "SYMBOLPACK_2_11",
	SYMBOLPACK_2_12 = "SYMBOLPACK_2_12",
	SYMBOLPACK_2_13 = "SYMBOLPACK_2_13",
	SYMBOLPACK_2_14 = "SYMBOLPACK_2_14",
	SYMBOLPACK_2_15 = "SYMBOLPACK_2_15",
	SYMBOLPACK_2_16 = "SYMBOLPACK_2_16",
	SYMBOLPACK_2_17 = "SYMBOLPACK_2_17",
	SYMBOLPACK_2_18 = "SYMBOLPACK_2_18",
	SYMBOLPACK_2_19 = "SYMBOLPACK_2_19",
	SYMBOLPACK_2_20 = "SYMBOLPACK_2_20",
	SYMBOLPACK_2_21 = "SYMBOLPACK_2_21",
	SYMBOLPACK_2_22 = "SYMBOLPACK_2_22",
	SYMBOLPACK_2_23 = "SYMBOLPACK_2_23",
	SYMBOLPACK_2_24 = "SYMBOLPACK_2_24",
	SYMBOLPACK_2_25 = "SYMBOLPACK_2_25",
	SYMBOLPACK_2_26 = "SYMBOLPACK_2_26",
	SYMBOLPACK_2_27 = "SYMBOLPACK_2_27",
	SYMBOLPACK_2_28 = "SYMBOLPACK_2_28",
	SYMBOLPACK_2_29 = "SYMBOLPACK_2_29",
	SYMBOLPACK_2_30 = "SYMBOLPACK_2_30",
	SYMBOLPACK_2_31 = "SYMBOLPACK_2_31",
	SYMBOLPACK_2_32 = "SYMBOLPACK_2_32",
	SYMBOLPACK_2_33 = "SYMBOLPACK_2_33",
	SYMBOLPACK_2_34 = "SYMBOLPACK_2_34",
	SYMBOLPACK_2_35 = "SYMBOLPACK_2_35",
	SYMBOLPACK_2_36 = "SYMBOLPACK_2_36",
	SYMBOLPACK_3_11 = "SYMBOLPACK_3_11",
	SYMBOLPACK_3_12 = "SYMBOLPACK_3_12",
	SYMBOLPACK_3_13 = "SYMBOLPACK_3_13",
	SYMBOLPACK_3_14 = "SYMBOLPACK_3_14",
	SYMBOLPACK_3_15 = "SYMBOLPACK_3_15",
	SYMBOLPACK_3_16 = "SYMBOLPACK_3_16",
	SYMBOLPACK_3_17 = "SYMBOLPACK_3_17",
	SYMBOLPACK_3_18 = "SYMBOLPACK_3_18",
	SYMBOLPACK_3_19 = "SYMBOLPACK_3_19",
	SYMBOLPACK_3_20 = "SYMBOLPACK_3_20",
	SYMBOLPACK_3_21 = "SYMBOLPACK_3_21",
	SYMBOLPACK_3_22 = "SYMBOLPACK_3_22",
	SYMBOLPACK_3_23 = "SYMBOLPACK_3_23",
	SYMBOLPACK_3_24 = "SYMBOLPACK_3_24",
	SYMBOLPACK_3_25 = "SYMBOLPACK_3_25",
	SYMBOLPACK_3_26 = "SYMBOLPACK_3_26",
	SYMBOLPACK_3_27 = "SYMBOLPACK_3_27",
	SYMBOLPACK_3_28 = "SYMBOLPACK_3_28",
	SYMBOLPACK_3_29 = "SYMBOLPACK_3_29",
	SYMBOLPACK_3_30 = "SYMBOLPACK_3_30",
	SYMBOLPACK_3_31 = "SYMBOLPACK_3_31",
	SYMBOLPACK_3_32 = "SYMBOLPACK_3_32",
	SYMBOLPACK_3_33 = "SYMBOLPACK_3_33",
	SYMBOLPACK_3_34 = "SYMBOLPACK_3_34",
	SYMBOLPACK_3_35 = "SYMBOLPACK_3_35",
	SYMBOLPACK_3_36 = "SYMBOLPACK_3_36",
	SYMBOLPACK_4_11 = "SYMBOLPACK_4_11",
	SYMBOLPACK_4_12 = "SYMBOLPACK_4_12",
	SYMBOLPACK_4_13 = "SYMBOLPACK_4_13",
	SYMBOLPACK_4_14 = "SYMBOLPACK_4_14",
	SYMBOLPACK_4_15 = "SYMBOLPACK_4_15",
	SYMBOLPACK_4_16 = "SYMBOLPACK_4_16",
	SYMBOLPACK_4_17 = "SYMBOLPACK_4_17",
	SYMBOLPACK_4_18 = "SYMBOLPACK_4_18",
	SYMBOLPACK_4_19 = "SYMBOLPACK_4_19",
	SYMBOLPACK_4_20 = "SYMBOLPACK_4_20",
	SYMBOLPACK_4_21 = "SYMBOLPACK_4_21",
	SYMBOLPACK_4_22 = "SYMBOLPACK_4_22",
	SYMBOLPACK_4_23 = "SYMBOLPACK_4_23",
	SYMBOLPACK_4_24 = "SYMBOLPACK_4_24",
	SYMBOLPACK_4_25 = "SYMBOLPACK_4_25",
	SYMBOLPACK_4_26 = "SYMBOLPACK_4_26",
	SYMBOLPACK_4_27 = "SYMBOLPACK_4_27",
	SYMBOLPACK_4_28 = "SYMBOLPACK_4_28",
	SYMBOLPACK_4_29 = "SYMBOLPACK_4_29",
	SYMBOLPACK_4_30 = "SYMBOLPACK_4_30",
	SYMBOLPACK_4_31 = "SYMBOLPACK_4_31",
	SYMBOLPACK_4_32 = "SYMBOLPACK_4_32",
	SYMBOLPACK_4_33 = "SYMBOLPACK_4_33",
	SYMBOLPACK_4_34 = "SYMBOLPACK_4_34",
	SYMBOLPACK_4_35 = "SYMBOLPACK_4_35",
	SYMBOLPACK_4_36 = "SYMBOLPACK_4_36",
	SYMBOLPACK_5_11 = "SYMBOLPACK_5_11",
	SYMBOLPACK_5_12 = "SYMBOLPACK_5_12",
	SYMBOLPACK_5_13 = "SYMBOLPACK_5_13",
	SYMBOLPACK_5_14 = "SYMBOLPACK_5_14",
	SYMBOLPACK_5_15 = "SYMBOLPACK_5_15",
	SYMBOLPACK_5_16 = "SYMBOLPACK_5_16",
	SYMBOLPACK_5_17 = "SYMBOLPACK_5_17",
	SYMBOLPACK_5_18 = "SYMBOLPACK_5_18",
	SYMBOLPACK_5_19 = "SYMBOLPACK_5_19",
	SYMBOLPACK_5_20 = "SYMBOLPACK_5_20",
	SYMBOLPACK_5_21 = "SYMBOLPACK_5_21",
	SYMBOLPACK_5_22 = "SYMBOLPACK_5_22",
	SYMBOLPACK_5_23 = "SYMBOLPACK_5_23",
	SYMBOLPACK_5_24 = "SYMBOLPACK_5_24",
	SYMBOLPACK_5_25 = "SYMBOLPACK_5_25",
	SYMBOLPACK_5_26 = "SYMBOLPACK_5_26",
	SYMBOLPACK_5_27 = "SYMBOLPACK_5_27",
	SYMBOLPACK_5_28 = "SYMBOLPACK_5_28",
	SYMBOLPACK_5_29 = "SYMBOLPACK_5_29",
	SYMBOLPACK_5_30 = "SYMBOLPACK_5_30",
	SYMBOLPACK_5_31 = "SYMBOLPACK_5_31",
	SYMBOLPACK_5_32 = "SYMBOLPACK_5_32",
	SYMBOLPACK_5_33 = "SYMBOLPACK_5_33",
	SYMBOLPACK_5_34 = "SYMBOLPACK_5_34",
	SYMBOLPACK_5_35 = "SYMBOLPACK_5_35",
	SYMBOLPACK_5_36 = "SYMBOLPACK_5_36",
	SYMBOLPACK_6_11 = "SYMBOLPACK_6_11",
	SYMBOLPACK_6_12 = "SYMBOLPACK_6_12",
	SYMBOLPACK_6_13 = "SYMBOLPACK_6_13",
	SYMBOLPACK_6_14 = "SYMBOLPACK_6_14",
	SYMBOLPACK_6_15 = "SYMBOLPACK_6_15",
	SYMBOLPACK_6_16 = "SYMBOLPACK_6_16",
	SYMBOLPACK_6_17 = "SYMBOLPACK_6_17",
	SYMBOLPACK_6_18 = "SYMBOLPACK_6_18",
	SYMBOLPACK_6_19 = "SYMBOLPACK_6_19",
	SYMBOLPACK_6_20 = "SYMBOLPACK_6_20",
	SYMBOLPACK_6_21 = "SYMBOLPACK_6_21",
	SYMBOLPACK_6_22 = "SYMBOLPACK_6_22",
	SYMBOLPACK_6_23 = "SYMBOLPACK_6_23",
	SYMBOLPACK_6_24 = "SYMBOLPACK_6_24",
	SYMBOLPACK_6_25 = "SYMBOLPACK_6_25",
	SYMBOLPACK_6_26 = "SYMBOLPACK_6_26",
	SYMBOLPACK_6_27 = "SYMBOLPACK_6_27",
	SYMBOLPACK_6_28 = "SYMBOLPACK_6_28",
	SYMBOLPACK_6_29 = "SYMBOLPACK_6_29",
	SYMBOLPACK_6_30 = "SYMBOLPACK_6_30",
	SYMBOLPACK_6_31 = "SYMBOLPACK_6_31",
	SYMBOLPACK_6_32 = "SYMBOLPACK_6_32",
	SYMBOLPACK_6_33 = "SYMBOLPACK_6_33",
	SYMBOLPACK_6_34 = "SYMBOLPACK_6_34",
	SYMBOLPACK_6_35 = "SYMBOLPACK_6_35",
	SYMBOLPACK_6_36 = "SYMBOLPACK_6_36",
	SYMBOLPACK_6_37 = "SYMBOLPACK_6_37",
	SYMBOLPACK_6_38 = "SYMBOLPACK_6_38",
	SYMBOLPACK_6_39 = "SYMBOLPACK_6_39",
	SYMBOLPACK_6_40 = "SYMBOLPACK_6_40",
	SYMBOLPACK_6_41 = "SYMBOLPACK_6_41",
	SYMBOLPACK_6_42 = "SYMBOLPACK_6_42",
	SYMBOLPACK_6_43 = "SYMBOLPACK_6_43",
	SYMBOLPACK_6_44 = "SYMBOLPACK_6_44",
	SYMBOLPACK_6_45 = "SYMBOLPACK_6_45",
	SYMBOLPACK_6_46 = "SYMBOLPACK_6_46",
	SYMBOLPACK_6_47 = "SYMBOLPACK_6_47",
	SYMBOLPACK_6_48 = "SYMBOLPACK_6_48",
	SYMBOLPACK_3_SOLID_11 = "SYMBOLPACK_3_SOLID_11",
	SYMBOLPACK_3_SOLID_12 = "SYMBOLPACK_3_SOLID_12",
	SYMBOLPACK_3_SOLID_13 = "SYMBOLPACK_3_SOLID_13",
	SYMBOLPACK_3_SOLID_14 = "SYMBOLPACK_3_SOLID_14",
	SYMBOLPACK_3_SOLID_15 = "SYMBOLPACK_3_SOLID_15",
	SYMBOLPACK_3_SOLID_16 = "SYMBOLPACK_3_SOLID_16",
	SYMBOLPACK_3_SOLID_17 = "SYMBOLPACK_3_SOLID_17",
	SYMBOLPACK_3_SOLID_18 = "SYMBOLPACK_3_SOLID_18",
	SYMBOLPACK_3_SOLID_19 = "SYMBOLPACK_3_SOLID_19",
	SYMBOLPACK_3_SOLID_20 = "SYMBOLPACK_3_SOLID_20",
	SYMBOLPACK_3_SOLID_21 = "SYMBOLPACK_3_SOLID_21",
	SYMBOLPACK_3_SOLID_22 = "SYMBOLPACK_3_SOLID_22",
	SYMBOLPACK_3_SOLID_23 = "SYMBOLPACK_3_SOLID_23",
	SYMBOLPACK_3_SOLID_24 = "SYMBOLPACK_3_SOLID_24",
	SYMBOLPACK_3_SOLID_25 = "SYMBOLPACK_3_SOLID_25",
	SYMBOLPACK_3_SOLID_26 = "SYMBOLPACK_3_SOLID_26",
	SYMBOLPACK_3_SOLID_27 = "SYMBOLPACK_3_SOLID_27",
	SYMBOLPACK_3_SOLID_28 = "SYMBOLPACK_3_SOLID_28",
	SYMBOLPACK_3_SOLID_29 = "SYMBOLPACK_3_SOLID_29",
	SYMBOLPACK_3_SOLID_30 = "SYMBOLPACK_3_SOLID_30",
	SYMBOLPACK_3_SOLID_31 = "SYMBOLPACK_3_SOLID_31",
	SYMBOLPACK_3_SOLID_32 = "SYMBOLPACK_3_SOLID_32",
	SYMBOLPACK_3_SOLID_33 = "SYMBOLPACK_3_SOLID_33",
	SYMBOLPACK_3_SOLID_34 = "SYMBOLPACK_3_SOLID_34",
	SYMBOLPACK_3_SOLID_35 = "SYMBOLPACK_3_SOLID_35",
	SYMBOLPACK_3_SOLID_36 = "SYMBOLPACK_3_SOLID_36",
	SYMBOLPACK_3_SOLID_37 = "SYMBOLPACK_3_SOLID_37",
	SYMBOLPACK_3_SOLID_38 = "SYMBOLPACK_3_SOLID_38",
	SYMBOLPACK_3_SOLID_39 = "SYMBOLPACK_3_SOLID_39",
	SYMBOLPACK_3_SOLID_40 = "SYMBOLPACK_3_SOLID_40",
	SYMBOLPACK_3_SOLID_41 = "SYMBOLPACK_3_SOLID_41",
	SYMBOLPACK_3_SOLID_42 = "SYMBOLPACK_3_SOLID_42",
	SYMBOLPACK_3_SOLID_43 = "SYMBOLPACK_3_SOLID_43",
	SYMBOLPACK_3_SOLID_44 = "SYMBOLPACK_3_SOLID_44",
	SYMBOLPACK_3_SOLID_45 = "SYMBOLPACK_3_SOLID_45",
	SYMBOLPACK_3_SOLID_46 = "SYMBOLPACK_3_SOLID_46",
	SYMBOLPACK_3_SOLID_47 = "SYMBOLPACK_3_SOLID_47",
	SYMBOLPACK_3_SOLID_48 = "SYMBOLPACK_3_SOLID_48",
	SYMBOLPACK_4_SOLID_11 = "SYMBOLPACK_4_SOLID_11",
	SYMBOLPACK_4_SOLID_12 = "SYMBOLPACK_4_SOLID_12",
	SYMBOLPACK_4_SOLID_13 = "SYMBOLPACK_4_SOLID_13",
	SYMBOLPACK_4_SOLID_14 = "SYMBOLPACK_4_SOLID_14",
	SYMBOLPACK_4_SOLID_15 = "SYMBOLPACK_4_SOLID_15",
	SYMBOLPACK_4_SOLID_16 = "SYMBOLPACK_4_SOLID_16",
	SYMBOLPACK_4_SOLID_17 = "SYMBOLPACK_4_SOLID_17",
	SYMBOLPACK_4_SOLID_18 = "SYMBOLPACK_4_SOLID_18",
	SYMBOLPACK_4_SOLID_19 = "SYMBOLPACK_4_SOLID_19",
	SYMBOLPACK_4_SOLID_20 = "SYMBOLPACK_4_SOLID_20",
	SYMBOLPACK_4_SOLID_21 = "SYMBOLPACK_4_SOLID_21",
	SYMBOLPACK_4_SOLID_22 = "SYMBOLPACK_4_SOLID_22",
	SYMBOLPACK_4_SOLID_23 = "SYMBOLPACK_4_SOLID_23",
	SYMBOLPACK_4_SOLID_24 = "SYMBOLPACK_4_SOLID_24",
	SYMBOLPACK_4_SOLID_25 = "SYMBOLPACK_4_SOLID_25",
	SYMBOLPACK_4_SOLID_26 = "SYMBOLPACK_4_SOLID_26",
	SYMBOLPACK_4_SOLID_27 = "SYMBOLPACK_4_SOLID_27",
	SYMBOLPACK_4_SOLID_28 = "SYMBOLPACK_4_SOLID_28",
	SYMBOLPACK_4_SOLID_29 = "SYMBOLPACK_4_SOLID_29",
	SYMBOLPACK_4_SOLID_30 = "SYMBOLPACK_4_SOLID_30",
	SYMBOLPACK_4_SOLID_31 = "SYMBOLPACK_4_SOLID_31",
	SYMBOLPACK_4_SOLID_32 = "SYMBOLPACK_4_SOLID_32",
	SYMBOLPACK_4_SOLID_33 = "SYMBOLPACK_4_SOLID_33",
	SYMBOLPACK_4_SOLID_34 = "SYMBOLPACK_4_SOLID_34",
	SYMBOLPACK_4_SOLID_35 = "SYMBOLPACK_4_SOLID_35",
	SYMBOLPACK_4_SOLID_36 = "SYMBOLPACK_4_SOLID_36",
	SYMBOLPACK_4_SOLID_37 = "SYMBOLPACK_4_SOLID_37",
	SYMBOLPACK_4_SOLID_38 = "SYMBOLPACK_4_SOLID_38",
	SYMBOLPACK_4_SOLID_39 = "SYMBOLPACK_4_SOLID_39",
	SYMBOLPACK_4_SOLID_40 = "SYMBOLPACK_4_SOLID_40",
	SYMBOLPACK_4_SOLID_41 = "SYMBOLPACK_4_SOLID_41",
	SYMBOLPACK_4_SOLID_42 = "SYMBOLPACK_4_SOLID_42",
	SYMBOLPACK_4_SOLID_43 = "SYMBOLPACK_4_SOLID_43",
	SYMBOLPACK_4_SOLID_44 = "SYMBOLPACK_4_SOLID_44",
	SYMBOLPACK_4_SOLID_45 = "SYMBOLPACK_4_SOLID_45",
	SYMBOLPACK_4_SOLID_46 = "SYMBOLPACK_4_SOLID_46",
	SYMBOLPACK_4_SOLID_47 = "SYMBOLPACK_4_SOLID_47",
	SYMBOLPACK_4_SOLID_48 = "SYMBOLPACK_4_SOLID_48",
	SYMBOLPACK_5_SOLID_11 = "SYMBOLPACK_5_SOLID_11",
	SYMBOLPACK_5_SOLID_12 = "SYMBOLPACK_5_SOLID_12",
	SYMBOLPACK_5_SOLID_13 = "SYMBOLPACK_5_SOLID_13",
	SYMBOLPACK_5_SOLID_14 = "SYMBOLPACK_5_SOLID_14",
	SYMBOLPACK_5_SOLID_15 = "SYMBOLPACK_5_SOLID_15",
	SYMBOLPACK_5_SOLID_16 = "SYMBOLPACK_5_SOLID_16",
	SYMBOLPACK_5_SOLID_17 = "SYMBOLPACK_5_SOLID_17",
	SYMBOLPACK_5_SOLID_18 = "SYMBOLPACK_5_SOLID_18",
	SYMBOLPACK_5_SOLID_19 = "SYMBOLPACK_5_SOLID_19",
	SYMBOLPACK_5_SOLID_20 = "SYMBOLPACK_5_SOLID_20",
	SYMBOLPACK_5_SOLID_21 = "SYMBOLPACK_5_SOLID_21",
	SYMBOLPACK_5_SOLID_22 = "SYMBOLPACK_5_SOLID_22",
	SYMBOLPACK_5_SOLID_23 = "SYMBOLPACK_5_SOLID_23",
	SYMBOLPACK_5_SOLID_24 = "SYMBOLPACK_5_SOLID_24",
	SYMBOLPACK_5_SOLID_25 = "SYMBOLPACK_5_SOLID_25",
	SYMBOLPACK_5_SOLID_26 = "SYMBOLPACK_5_SOLID_26",
	SYMBOLPACK_5_SOLID_27 = "SYMBOLPACK_5_SOLID_27",
	SYMBOLPACK_5_SOLID_28 = "SYMBOLPACK_5_SOLID_28",
	SYMBOLPACK_5_SOLID_29 = "SYMBOLPACK_5_SOLID_29",
	SYMBOLPACK_5_SOLID_30 = "SYMBOLPACK_5_SOLID_30",
	SYMBOLPACK_5_SOLID_31 = "SYMBOLPACK_5_SOLID_31",
	SYMBOLPACK_5_SOLID_32 = "SYMBOLPACK_5_SOLID_32",
	SYMBOLPACK_5_SOLID_33 = "SYMBOLPACK_5_SOLID_33",
	SYMBOLPACK_5_SOLID_34 = "SYMBOLPACK_5_SOLID_34",
	SYMBOLPACK_5_SOLID_35 = "SYMBOLPACK_5_SOLID_35",
	SYMBOLPACK_5_SOLID_36 = "SYMBOLPACK_5_SOLID_36",
	SYMBOLPACK_5_SOLID_37 = "SYMBOLPACK_5_SOLID_37",
	SYMBOLPACK_5_SOLID_38 = "SYMBOLPACK_5_SOLID_38",
	SYMBOLPACK_5_SOLID_39 = "SYMBOLPACK_5_SOLID_39",
	SYMBOLPACK_5_SOLID_40 = "SYMBOLPACK_5_SOLID_40",
	SYMBOLPACK_5_SOLID_41 = "SYMBOLPACK_5_SOLID_41",
	SYMBOLPACK_5_SOLID_42 = "SYMBOLPACK_5_SOLID_42",
	SYMBOLPACK_5_SOLID_43 = "SYMBOLPACK_5_SOLID_43",
	SYMBOLPACK_5_SOLID_44 = "SYMBOLPACK_5_SOLID_44",
	SYMBOLPACK_5_SOLID_45 = "SYMBOLPACK_5_SOLID_45",
	SYMBOLPACK_5_SOLID_46 = "SYMBOLPACK_5_SOLID_46",
	SYMBOLPACK_5_SOLID_47 = "SYMBOLPACK_5_SOLID_47",
	SYMBOLPACK_5_SOLID_48 = "SYMBOLPACK_5_SOLID_48",
	SYMBOLPACK_6_SOLID_11 = "SYMBOLPACK_6_SOLID_11",
	SYMBOLPACK_6_SOLID_12 = "SYMBOLPACK_6_SOLID_12",
	SYMBOLPACK_6_SOLID_13 = "SYMBOLPACK_6_SOLID_13",
	SYMBOLPACK_6_SOLID_14 = "SYMBOLPACK_6_SOLID_14",
	SYMBOLPACK_6_SOLID_15 = "SYMBOLPACK_6_SOLID_15",
	SYMBOLPACK_6_SOLID_16 = "SYMBOLPACK_6_SOLID_16",
	SYMBOLPACK_6_SOLID_17 = "SYMBOLPACK_6_SOLID_17",
	SYMBOLPACK_6_SOLID_18 = "SYMBOLPACK_6_SOLID_18",
	SYMBOLPACK_6_SOLID_19 = "SYMBOLPACK_6_SOLID_19",
	SYMBOLPACK_6_SOLID_20 = "SYMBOLPACK_6_SOLID_20",
	SYMBOLPACK_6_SOLID_21 = "SYMBOLPACK_6_SOLID_21",
	SYMBOLPACK_6_SOLID_22 = "SYMBOLPACK_6_SOLID_22",
	SYMBOLPACK_6_SOLID_23 = "SYMBOLPACK_6_SOLID_23",
	SYMBOLPACK_6_SOLID_24 = "SYMBOLPACK_6_SOLID_24",
	SYMBOLPACK_6_SOLID_25 = "SYMBOLPACK_6_SOLID_25",
	SYMBOLPACK_6_SOLID_26 = "SYMBOLPACK_6_SOLID_26",
	SYMBOLPACK_6_SOLID_27 = "SYMBOLPACK_6_SOLID_27",
	SYMBOLPACK_6_SOLID_28 = "SYMBOLPACK_6_SOLID_28",
	SYMBOLPACK_6_SOLID_29 = "SYMBOLPACK_6_SOLID_29",
	SYMBOLPACK_6_SOLID_30 = "SYMBOLPACK_6_SOLID_30",
	SYMBOLPACK_6_SOLID_31 = "SYMBOLPACK_6_SOLID_31",
	SYMBOLPACK_6_SOLID_32 = "SYMBOLPACK_6_SOLID_32",
	SYMBOLPACK_6_SOLID_33 = "SYMBOLPACK_6_SOLID_33",
	SYMBOLPACK_6_SOLID_34 = "SYMBOLPACK_6_SOLID_34",
	SYMBOLPACK_6_SOLID_35 = "SYMBOLPACK_6_SOLID_35",
	SYMBOLPACK_6_SOLID_36 = "SYMBOLPACK_6_SOLID_36",
	SYMBOLPACK_6_SOLID_37 = "SYMBOLPACK_6_SOLID_37",
	SYMBOLPACK_6_SOLID_38 = "SYMBOLPACK_6_SOLID_38",
	SYMBOLPACK_6_SOLID_39 = "SYMBOLPACK_6_SOLID_39",
	SYMBOLPACK_6_SOLID_40 = "SYMBOLPACK_6_SOLID_40",
	SYMBOLPACK_6_SOLID_41 = "SYMBOLPACK_6_SOLID_41",
	SYMBOLPACK_6_SOLID_42 = "SYMBOLPACK_6_SOLID_42",
	SYMBOLPACK_6_SOLID_43 = "SYMBOLPACK_6_SOLID_43",
	SYMBOLPACK_6_SOLID_44 = "SYMBOLPACK_6_SOLID_44",
	SYMBOLPACK_6_SOLID_45 = "SYMBOLPACK_6_SOLID_45",
	SYMBOLPACK_6_SOLID_46 = "SYMBOLPACK_6_SOLID_46",
	SYMBOLPACK_6_SOLID_47 = "SYMBOLPACK_6_SOLID_47",
	SYMBOLPACK_6_SOLID_48 = "SYMBOLPACK_6_SOLID_48",
	// general_characters
	SYMBOLPACK_2_37 = "SYMBOLPACK_2_37",
	SYMBOLPACK_2_38 = "SYMBOLPACK_2_38",
	SYMBOLPACK_2_39 = "SYMBOLPACK_2_39",
	SYMBOLPACK_2_40 = "SYMBOLPACK_2_40",
	SYMBOLPACK_2_41 = "SYMBOLPACK_2_41",
	SYMBOLPACK_2_42 = "SYMBOLPACK_2_42",
	SYMBOLPACK_2_43 = "SYMBOLPACK_2_43",
	SYMBOLPACK_2_44 = "SYMBOLPACK_2_44",
	SYMBOLPACK_2_45 = "SYMBOLPACK_2_45",
	SYMBOLPACK_2_46 = "SYMBOLPACK_2_46",
	SYMBOLPACK_2_47 = "SYMBOLPACK_2_47",
	SYMBOLPACK_2_48 = "SYMBOLPACK_2_48",
	SYMBOLPACK_3_37 = "SYMBOLPACK_3_37",
	SYMBOLPACK_3_38 = "SYMBOLPACK_3_38",
	SYMBOLPACK_3_39 = "SYMBOLPACK_3_39",
	SYMBOLPACK_3_40 = "SYMBOLPACK_3_40",
	SYMBOLPACK_3_41 = "SYMBOLPACK_3_41",
	SYMBOLPACK_3_42 = "SYMBOLPACK_3_42",
	SYMBOLPACK_3_43 = "SYMBOLPACK_3_43",
	SYMBOLPACK_3_44 = "SYMBOLPACK_3_44",
	SYMBOLPACK_3_45 = "SYMBOLPACK_3_45",
	SYMBOLPACK_3_46 = "SYMBOLPACK_3_46",
	SYMBOLPACK_3_47 = "SYMBOLPACK_3_47",
	SYMBOLPACK_3_48 = "SYMBOLPACK_3_48",
	SYMBOLPACK_4_37 = "SYMBOLPACK_4_37",
	SYMBOLPACK_4_38 = "SYMBOLPACK_4_38",
	SYMBOLPACK_4_39 = "SYMBOLPACK_4_39",
	SYMBOLPACK_4_40 = "SYMBOLPACK_4_40",
	SYMBOLPACK_4_41 = "SYMBOLPACK_4_41",
	SYMBOLPACK_4_42 = "SYMBOLPACK_4_42",
	SYMBOLPACK_4_43 = "SYMBOLPACK_4_43",
	SYMBOLPACK_4_44 = "SYMBOLPACK_4_44",
	SYMBOLPACK_4_45 = "SYMBOLPACK_4_45",
	SYMBOLPACK_4_46 = "SYMBOLPACK_4_46",
	SYMBOLPACK_4_47 = "SYMBOLPACK_4_47",
	SYMBOLPACK_4_48 = "SYMBOLPACK_4_48",
	SYMBOLPACK_5_37 = "SYMBOLPACK_5_37",
	SYMBOLPACK_5_38 = "SYMBOLPACK_5_38",
	SYMBOLPACK_5_39 = "SYMBOLPACK_5_39",
	SYMBOLPACK_5_40 = "SYMBOLPACK_5_40",
	SYMBOLPACK_5_41 = "SYMBOLPACK_5_41",
	SYMBOLPACK_5_42 = "SYMBOLPACK_5_42",
	SYMBOLPACK_5_43 = "SYMBOLPACK_5_43",
	SYMBOLPACK_5_44 = "SYMBOLPACK_5_44",
	SYMBOLPACK_5_45 = "SYMBOLPACK_5_45",
	SYMBOLPACK_5_46 = "SYMBOLPACK_5_46",
	SYMBOLPACK_5_47 = "SYMBOLPACK_5_47",
	SYMBOLPACK_5_48 = "SYMBOLPACK_5_48",
	// general_basic
	SYMBOLPACK_1_01 = "SYMBOLPACK_1_01",
	SYMBOLPACK_1_02 = "SYMBOLPACK_1_02",
	SYMBOLPACK_1_02_COPY = "SYMBOLPACK_1_02_COPY",
	SYMBOLPACK_1_06 = "SYMBOLPACK_1_06",
	SYMBOLPACK_1_08 = "SYMBOLPACK_1_08",
	SYMBOLPACK_1_09 = "SYMBOLPACK_1_09",
	SYMBOLPACK_1_10 = "SYMBOLPACK_1_10",
	SYMBOLPACK_1_12 = "SYMBOLPACK_1_12",
	SYMBOLPACK_1_13 = "SYMBOLPACK_1_13",
	SYMBOLPACK_1_14 = "SYMBOLPACK_1_14",
	SYMBOLPACK_1_15 = "SYMBOLPACK_1_15",
	SYMBOLPACK_1_16 = "SYMBOLPACK_1_16",
	SYMBOLPACK_1_17 = "SYMBOLPACK_1_17",
	SYMBOLPACK_1_18 = "SYMBOLPACK_1_18",
	SYMBOLPACK_1_19 = "SYMBOLPACK_1_19",
	SYMBOLPACK_1_20 = "SYMBOLPACK_1_20",
	SYMBOLPACK_1_21 = "SYMBOLPACK_1_21",
	SYMBOLPACK_1_22 = "SYMBOLPACK_1_22",
	SYMBOLPACK_1_23 = "SYMBOLPACK_1_23",
	SYMBOLPACK_1_24 = "SYMBOLPACK_1_24",
	SYMBOLPACK_1_25 = "SYMBOLPACK_1_25",
	SYMBOLPACK_1_26 = "SYMBOLPACK_1_26",
	SYMBOLPACK_1_27 = "SYMBOLPACK_1_27",
	SYMBOLPACK_1_28 = "SYMBOLPACK_1_28",
	SYMBOLPACK_1_29 = "SYMBOLPACK_1_29",
	SYMBOLPACK_1_30 = "SYMBOLPACK_1_30",
	SYMBOLPACK_1_32 = "SYMBOLPACK_1_32",
	SYMBOLPACK_1_32_COPY = "SYMBOLPACK_1_32_COPY",
	SYMBOLPACK_1_33 = "SYMBOLPACK_1_33",
	SYMBOLPACK_1_34 = "SYMBOLPACK_1_34",
	SYMBOLPACK_1_35 = "SYMBOLPACK_1_35",
	SYMBOLPACK_1_36 = "SYMBOLPACK_1_36",
	SYMBOLPACK_1_37 = "SYMBOLPACK_1_37",
	SYMBOLPACK_1_38 = "SYMBOLPACK_1_38",
	SYMBOLPACK_1_39 = "SYMBOLPACK_1_39",
	SYMBOLPACK_1_40 = "SYMBOLPACK_1_40",
	SYMBOLPACK_1_41 = "SYMBOLPACK_1_41",
	SYMBOLPACK_1_42 = "SYMBOLPACK_1_42",
	SYMBOLPACK_1_43 = "SYMBOLPACK_1_43",
	SYMBOLPACK_1_44 = "SYMBOLPACK_1_44",
	SYMBOLPACK_1_45 = "SYMBOLPACK_1_45",
	SYMBOLPACK_1_46 = "SYMBOLPACK_1_46",
	SYMBOLPACK_1_47 = "SYMBOLPACK_1_47",
	SYMBOLPACK_1_48 = "SYMBOLPACK_1_48",
	SYMBOLPACK_1_49 = "SYMBOLPACK_1_49",
	SYMBOLPACK_1_50 = "SYMBOLPACK_1_50",
	SYMBOLPACK_1_51 = "SYMBOLPACK_1_51",
	SYMBOLPACK_1_52 = "SYMBOLPACK_1_52",
	SYMBOLPACK_1_53 = "SYMBOLPACK_1_53",
	SYMBOLPACK_1_54 = "SYMBOLPACK_1_54",
	SYMBOLPACK_1_55 = "SYMBOLPACK_1_55",
	SYMBOLPACK_1_57 = "SYMBOLPACK_1_57",
	SYMBOLPACK_1_58 = "SYMBOLPACK_1_58",
	SYMBOLPACK_1_59 = "SYMBOLPACK_1_59",
	SYMBOLPACK_1_65 = "SYMBOLPACK_1_65",
	SYMBOLPACK_1_88 = "SYMBOLPACK_1_88",
	SYMBOLPACK_1_89 = "SYMBOLPACK_1_89",
	SYMBOLPACK_1_109 = "SYMBOLPACK_1_109",
	SYMBOLPACK_1_110 = "SYMBOLPACK_1_110",
	SYMBOLPACK_1_112 = "SYMBOLPACK_1_112",
	SYMBOLPACK_1_113 = "SYMBOLPACK_1_113",
	SYMBOLPACK_1_114 = "SYMBOLPACK_1_114",
	SYMBOLPACK_1_115 = "SYMBOLPACK_1_115",
	SYMBOLPACK_1_116 = "SYMBOLPACK_1_116",
	SYMBOLPACK_1_117 = "SYMBOLPACK_1_117",
	SYMBOLPACK_1_118 = "SYMBOLPACK_1_118",
	SYMBOLPACK_1_119 = "SYMBOLPACK_1_119",
	SYMBOLPACK_1_120 = "SYMBOLPACK_1_120",
	SYMBOLPACK_1_121 = "SYMBOLPACK_1_121",
	SYMBOLPACK_1_122 = "SYMBOLPACK_1_122",
	SYMBOLPACK_1_123 = "SYMBOLPACK_1_123",
	SYMBOLPACK_1_124 = "SYMBOLPACK_1_124",
	SYMBOLPACK_1_125 = "SYMBOLPACK_1_125",
	SYMBOLPACK_1_127 = "SYMBOLPACK_1_127",
	SYMBOLPACK_1_128 = "SYMBOLPACK_1_128",
	SYMBOLPACK_1_129 = "SYMBOLPACK_1_129",
	SYMBOLPACK_1_130 = "SYMBOLPACK_1_130",
	SYMBOLPACK_1_132 = "SYMBOLPACK_1_132",
	SYMBOLPACK_1_133 = "SYMBOLPACK_1_133",
	SYMBOLPACK_1_134 = "SYMBOLPACK_1_134",
	SYMBOLPACK_1_135 = "SYMBOLPACK_1_135",
	SYMBOLPACK_1_136 = "SYMBOLPACK_1_136",
	SYMBOLPACK_1_137 = "SYMBOLPACK_1_137",
	SYMBOLPACK_1_138 = "SYMBOLPACK_1_138",
	SYMBOLPACK_1_139 = "SYMBOLPACK_1_139",
	SYMBOLPACK_1_140 = "SYMBOLPACK_1_140",
	SYMBOLPACK_1_141 = "SYMBOLPACK_1_141",
	SYMBOLPACK_1_146 = "SYMBOLPACK_1_146",
	SYMBOLPACK_1_147 = "SYMBOLPACK_1_147",
	SYMBOLPACK_1_148 = "SYMBOLPACK_1_148",
	SYMBOLPACK_1_149 = "SYMBOLPACK_1_149",
	SYMBOLPACK_1_150 = "SYMBOLPACK_1_150",
	SYMBOLPACK_1_151 = "SYMBOLPACK_1_151",
	SYMBOLPACK_1_152 = "SYMBOLPACK_1_152",
	SYMBOLPACK_1_153 = "SYMBOLPACK_1_153",
	SYMBOLPACK_1_154 = "SYMBOLPACK_1_154",
	SYMBOLPACK_1_155 = "SYMBOLPACK_1_155",
	SYMBOLPACK_1_156 = "SYMBOLPACK_1_156",
	SYMBOLPACK_1_157 = "SYMBOLPACK_1_157",
	SYMBOLPACK_1_158 = "SYMBOLPACK_1_158",
	SYMBOLPACK_1_159 = "SYMBOLPACK_1_159",
	SYMBOLPACK_1_160 = "SYMBOLPACK_1_160",
	SYMBOLPACK_1_161 = "SYMBOLPACK_1_161",
	SYMBOLPACK_1_162 = "SYMBOLPACK_1_162",
	SYMBOLPACK_1_163 = "SYMBOLPACK_1_163",
	SYMBOLPACK_1_164 = "SYMBOLPACK_1_164",
	SYMBOLPACK_1_165 = "SYMBOLPACK_1_165",
	SYMBOLPACK_1_166 = "SYMBOLPACK_1_166",
	SYMBOLPACK_1_167 = "SYMBOLPACK_1_167",
	SYMBOLPACK_1_168 = "SYMBOLPACK_1_168",
	SYMBOLPACK_1_169 = "SYMBOLPACK_1_169",
	SYMBOLPACK_1_170 = "SYMBOLPACK_1_170",
	SYMBOLPACK_1_171 = "SYMBOLPACK_1_171",
	SYMBOLPACK_1_172 = "SYMBOLPACK_1_172",
	SYMBOLPACK_1_173 = "SYMBOLPACK_1_173",
	SYMBOLPACK_1_174 = "SYMBOLPACK_1_174",
	SYMBOLPACK_1_175 = "SYMBOLPACK_1_175",
	SYMBOLPACK_1_176 = "SYMBOLPACK_1_176",
	SYMBOLPACK_1_177 = "SYMBOLPACK_1_177",
	SYMBOLPACK_1_178 = "SYMBOLPACK_1_178",
	SYMBOLPACK_1_179 = "SYMBOLPACK_1_179",
	SYMBOLPACK_1_180 = "SYMBOLPACK_1_180",
	// plumbing_symbols2
	SYMBOLPACK_1_90 = "SYMBOLPACK_1_90",
	SYMBOLPACK_1_91 = "SYMBOLPACK_1_91",
	SYMBOLPACK_1_92 = "SYMBOLPACK_1_92",
	SYMBOLPACK_1_93 = "SYMBOLPACK_1_93",
	SYMBOLPACK_1_94 = "SYMBOLPACK_1_94",
	SYMBOLPACK_1_95 = "SYMBOLPACK_1_95",
	SYMBOLPACK_1_96 = "SYMBOLPACK_1_96",
	SYMBOLPACK_1_142 = "SYMBOLPACK_1_142",
	SYMBOLPACK_1_143 = "SYMBOLPACK_1_143",
	SYMBOLPACK_1_144 = "SYMBOLPACK_1_144",
	SYMBOLPACK_1_145 = "SYMBOLPACK_1_145",
	// fire_general
	SYMBOLPACK_1_106 = "SYMBOLPACK_1_106",
	SYMBOLPACK_1_107 = "SYMBOLPACK_1_107",
	SYMBOLPACK_1_108 = "SYMBOLPACK_1_108",
	// electric_general
	SYMBOLPACK_1_60 = "SYMBOLPACK_1_60",
	SYMBOLPACK_1_61 = "SYMBOLPACK_1_61",
	SYMBOLPACK_1_62 = "SYMBOLPACK_1_62",
	SYMBOLPACK_1_63 = "SYMBOLPACK_1_63",
	SYMBOLPACK_1_64 = "SYMBOLPACK_1_64",
	SYMBOLPACK_1_66 = "SYMBOLPACK_1_66",
	SYMBOLPACK_1_67 = "SYMBOLPACK_1_67",
	SYMBOLPACK_1_68 = "SYMBOLPACK_1_68",
	SYMBOLPACK_1_69 = "SYMBOLPACK_1_69",
	SYMBOLPACK_1_70 = "SYMBOLPACK_1_70",
	SYMBOLPACK_1_71 = "SYMBOLPACK_1_71",
	SYMBOLPACK_1_72 = "SYMBOLPACK_1_72",
	SYMBOLPACK_1_73 = "SYMBOLPACK_1_73",
	SYMBOLPACK_1_74 = "SYMBOLPACK_1_74",
	SYMBOLPACK_1_75 = "SYMBOLPACK_1_75",
	SYMBOLPACK_1_76 = "SYMBOLPACK_1_76",
	SYMBOLPACK_1_77 = "SYMBOLPACK_1_77",
	SYMBOLPACK_1_78 = "SYMBOLPACK_1_78",
	SYMBOLPACK_1_79 = "SYMBOLPACK_1_79",
	SYMBOLPACK_1_80 = "SYMBOLPACK_1_80",
	SYMBOLPACK_1_81 = "SYMBOLPACK_1_81",
	SYMBOLPACK_1_82 = "SYMBOLPACK_1_82",
	SYMBOLPACK_1_83 = "SYMBOLPACK_1_83",
	SYMBOLPACK_1_84 = "SYMBOLPACK_1_84",
	SYMBOLPACK_1_86 = "SYMBOLPACK_1_86",
	SYMBOLPACK_1_87 = "SYMBOLPACK_1_87",
	SYMBOLPACK_1_97 = "SYMBOLPACK_1_97",
	SYMBOLPACK_1_98 = "SYMBOLPACK_1_98",
	SYMBOLPACK_1_99 = "SYMBOLPACK_1_99",
	SYMBOLPACK_1_100 = "SYMBOLPACK_1_100",
	SYMBOLPACK_1_101 = "SYMBOLPACK_1_101",
	SYMBOLPACK_1_102 = "SYMBOLPACK_1_102",
	SYMBOLPACK_1_104 = "SYMBOLPACK_1_104",
	SYMBOLPACK_1_105 = "SYMBOLPACK_1_105",
	// general_numbers
	SYMBOLPACK_2_01 = "SYMBOLPACK_2_01",
	SYMBOLPACK_2_02 = "SYMBOLPACK_2_02",
	SYMBOLPACK_2_03 = "SYMBOLPACK_2_03",
	SYMBOLPACK_2_04 = "SYMBOLPACK_2_04",
	SYMBOLPACK_2_05 = "SYMBOLPACK_2_05",
	SYMBOLPACK_2_06 = "SYMBOLPACK_2_06",
	SYMBOLPACK_2_07 = "SYMBOLPACK_2_07",
	SYMBOLPACK_2_08 = "SYMBOLPACK_2_08",
	SYMBOLPACK_2_09 = "SYMBOLPACK_2_09",
	SYMBOLPACK_2_10 = "SYMBOLPACK_2_10",
	SYMBOLPACK_3_01 = "SYMBOLPACK_3_01",
	SYMBOLPACK_3_02 = "SYMBOLPACK_3_02",
	SYMBOLPACK_3_03 = "SYMBOLPACK_3_03",
	SYMBOLPACK_3_04 = "SYMBOLPACK_3_04",
	SYMBOLPACK_3_05 = "SYMBOLPACK_3_05",
	SYMBOLPACK_3_06 = "SYMBOLPACK_3_06",
	SYMBOLPACK_3_07 = "SYMBOLPACK_3_07",
	SYMBOLPACK_3_08 = "SYMBOLPACK_3_08",
	SYMBOLPACK_3_09 = "SYMBOLPACK_3_09",
	SYMBOLPACK_3_10 = "SYMBOLPACK_3_10",
	SYMBOLPACK_4_01 = "SYMBOLPACK_4_01",
	SYMBOLPACK_4_02 = "SYMBOLPACK_4_02",
	SYMBOLPACK_4_03 = "SYMBOLPACK_4_03",
	SYMBOLPACK_4_04 = "SYMBOLPACK_4_04",
	SYMBOLPACK_4_05 = "SYMBOLPACK_4_05",
	SYMBOLPACK_4_06 = "SYMBOLPACK_4_06",
	SYMBOLPACK_4_07 = "SYMBOLPACK_4_07",
	SYMBOLPACK_4_08 = "SYMBOLPACK_4_08",
	SYMBOLPACK_4_09 = "SYMBOLPACK_4_09",
	SYMBOLPACK_4_10 = "SYMBOLPACK_4_10",
	SYMBOLPACK_5_01 = "SYMBOLPACK_5_01",
	SYMBOLPACK_5_02 = "SYMBOLPACK_5_02",
	SYMBOLPACK_5_03 = "SYMBOLPACK_5_03",
	SYMBOLPACK_5_04 = "SYMBOLPACK_5_04",
	SYMBOLPACK_5_05 = "SYMBOLPACK_5_05",
	SYMBOLPACK_5_06 = "SYMBOLPACK_5_06",
	SYMBOLPACK_5_07 = "SYMBOLPACK_5_07",
	SYMBOLPACK_5_08 = "SYMBOLPACK_5_08",
	SYMBOLPACK_5_09 = "SYMBOLPACK_5_09",
	SYMBOLPACK_5_10 = "SYMBOLPACK_5_10",
	SYMBOLPACK_6_01 = "SYMBOLPACK_6_01",
	SYMBOLPACK_6_02 = "SYMBOLPACK_6_02",
	SYMBOLPACK_6_03 = "SYMBOLPACK_6_03",
	SYMBOLPACK_6_04 = "SYMBOLPACK_6_04",
	SYMBOLPACK_6_05 = "SYMBOLPACK_6_05",
	SYMBOLPACK_6_06 = "SYMBOLPACK_6_06",
	SYMBOLPACK_6_07 = "SYMBOLPACK_6_07",
	SYMBOLPACK_6_08 = "SYMBOLPACK_6_08",
	SYMBOLPACK_6_09 = "SYMBOLPACK_6_09",
	SYMBOLPACK_6_10 = "SYMBOLPACK_6_10",
	SYMBOLPACK_3_SOLID_01 = "SYMBOLPACK_3_SOLID_01",
	SYMBOLPACK_3_SOLID_02 = "SYMBOLPACK_3_SOLID_02",
	SYMBOLPACK_3_SOLID_03 = "SYMBOLPACK_3_SOLID_03",
	SYMBOLPACK_3_SOLID_04 = "SYMBOLPACK_3_SOLID_04",
	SYMBOLPACK_3_SOLID_05 = "SYMBOLPACK_3_SOLID_05",
	SYMBOLPACK_3_SOLID_06 = "SYMBOLPACK_3_SOLID_06",
	SYMBOLPACK_3_SOLID_07 = "SYMBOLPACK_3_SOLID_07",
	SYMBOLPACK_3_SOLID_08 = "SYMBOLPACK_3_SOLID_08",
	SYMBOLPACK_3_SOLID_09 = "SYMBOLPACK_3_SOLID_09",
	SYMBOLPACK_3_SOLID_10 = "SYMBOLPACK_3_SOLID_10",
	SYMBOLPACK_4_SOLID_01 = "SYMBOLPACK_4_SOLID_01",
	SYMBOLPACK_4_SOLID_02 = "SYMBOLPACK_4_SOLID_02",
	SYMBOLPACK_4_SOLID_03 = "SYMBOLPACK_4_SOLID_03",
	SYMBOLPACK_4_SOLID_04 = "SYMBOLPACK_4_SOLID_04",
	SYMBOLPACK_4_SOLID_05 = "SYMBOLPACK_4_SOLID_05",
	SYMBOLPACK_4_SOLID_06 = "SYMBOLPACK_4_SOLID_06",
	SYMBOLPACK_4_SOLID_07 = "SYMBOLPACK_4_SOLID_07",
	SYMBOLPACK_4_SOLID_08 = "SYMBOLPACK_4_SOLID_08",
	SYMBOLPACK_4_SOLID_09 = "SYMBOLPACK_4_SOLID_09",
	SYMBOLPACK_4_SOLID_10 = "SYMBOLPACK_4_SOLID_10",
	SYMBOLPACK_5_SOLID_01 = "SYMBOLPACK_5_SOLID_01",
	SYMBOLPACK_5_SOLID_02 = "SYMBOLPACK_5_SOLID_02",
	SYMBOLPACK_5_SOLID_03 = "SYMBOLPACK_5_SOLID_03",
	SYMBOLPACK_5_SOLID_04 = "SYMBOLPACK_5_SOLID_04",
	SYMBOLPACK_5_SOLID_05 = "SYMBOLPACK_5_SOLID_05",
	SYMBOLPACK_5_SOLID_06 = "SYMBOLPACK_5_SOLID_06",
	SYMBOLPACK_5_SOLID_07 = "SYMBOLPACK_5_SOLID_07",
	SYMBOLPACK_5_SOLID_08 = "SYMBOLPACK_5_SOLID_08",
	SYMBOLPACK_5_SOLID_09 = "SYMBOLPACK_5_SOLID_09",
	SYMBOLPACK_5_SOLID_10 = "SYMBOLPACK_5_SOLID_10",
	SYMBOLPACK_6_SOLID_01 = "SYMBOLPACK_6_SOLID_01",
	SYMBOLPACK_6_SOLID_02 = "SYMBOLPACK_6_SOLID_02",
	SYMBOLPACK_6_SOLID_03 = "SYMBOLPACK_6_SOLID_03",
	SYMBOLPACK_6_SOLID_04 = "SYMBOLPACK_6_SOLID_04",
	SYMBOLPACK_6_SOLID_05 = "SYMBOLPACK_6_SOLID_05",
	SYMBOLPACK_6_SOLID_06 = "SYMBOLPACK_6_SOLID_06",
	SYMBOLPACK_6_SOLID_07 = "SYMBOLPACK_6_SOLID_07",
	SYMBOLPACK_6_SOLID_08 = "SYMBOLPACK_6_SOLID_08",
	SYMBOLPACK_6_SOLID_09 = "SYMBOLPACK_6_SOLID_09",
	SYMBOLPACK_6_SOLID_10 = "SYMBOLPACK_6_SOLID_10",
	//security_system (new)
	SYMBOLPACK_NEW_255 = "SYMBOLPACK_NEW_255",
	SYMBOLPACK_NEW_256 = "SYMBOLPACK_NEW_256",
	SYMBOLPACK_NEW_257 = "SYMBOLPACK_NEW_257",
	SYMBOLPACK_NEW_258 = "SYMBOLPACK_NEW_258",
	SYMBOLPACK_NEW_259 = "SYMBOLPACK_NEW_259",
	SYMBOLPACK_NEW_217 = "SYMBOLPACK_NEW_217",
	SYMBOLPACK_NEW_218 = "SYMBOLPACK_NEW_218",
	SYMBOLPACK_NEW_219 = "SYMBOLPACK_NEW_219",
	SYMBOLPACK_NEW_220 = "SYMBOLPACK_NEW_220",
	SYMBOLPACK_NEW_221 = "SYMBOLPACK_NEW_221",
	SYMBOLPACK_NEW_222 = "SYMBOLPACK_NEW_222",
	SYMBOLPACK_NEW_223 = "SYMBOLPACK_NEW_223",
	SYMBOLPACK_NEW_224 = "SYMBOLPACK_NEW_224",
	SYMBOLPACK_NEW_225 = "SYMBOLPACK_NEW_225",
	SYMBOLPACK_NEW_226 = "SYMBOLPACK_NEW_226",
	SYMBOLPACK_NEW_228 = "SYMBOLPACK_NEW_228",
	SYMBOLPACK_NEW_229 = "SYMBOLPACK_NEW_229",
	SYMBOLPACK_NEW_230 = "SYMBOLPACK_NEW_230",
	SYMBOLPACK_NEW_231 = "SYMBOLPACK_NEW_231",
	//security_system (2021)
	SYMBOLPACK_346 = "SYMBOLPACK_346",
	SYMBOLPACK_347 = "SYMBOLPACK_347",
	SYMBOLPACK_348 = "SYMBOLPACK_348",
	SYMBOLPACK_349 = "SYMBOLPACK_349",
	SYMBOLPACK_350 = "SYMBOLPACK_350",
	SYMBOLPACK_351 = "SYMBOLPACK_351",
	SYMBOLPACK_352 = "SYMBOLPACK_352",
	SYMBOLPACK_353 = "SYMBOLPACK_353",
	SYMBOLPACK_354 = "SYMBOLPACK_354",
	SYMBOLPACK_355 = "SYMBOLPACK_355",
	SYMBOLPACK_356 = "SYMBOLPACK_356",
	SYMBOLPACK_357 = "SYMBOLPACK_357",
	SYMBOLPACK_358 = "SYMBOLPACK_358",
	SYMBOLPACK_359 = "SYMBOLPACK_359",
	SYMBOLPACK_360 = "SYMBOLPACK_360",
	SYMBOLPACK_361 = "SYMBOLPACK_361",
	SYMBOLPACK_362 = "SYMBOLPACK_362",
	SYMBOLPACK_363 = "SYMBOLPACK_363",
	SYMBOLPACK_364 = "SYMBOLPACK_364",
	SYMBOLPACK_365 = "SYMBOLPACK_365",
	SYMBOLPACK_366 = "SYMBOLPACK_366",
	SYMBOLPACK_367 = "SYMBOLPACK_367",
	SYMBOLPACK_368 = "SYMBOLPACK_368",
	SYMBOLPACK_369 = "SYMBOLPACK_369",
	SYMBOLPACK_370 = "SYMBOLPACK_370",
	SYMBOLPACK_371 = "SYMBOLPACK_371",
	SYMBOLPACK_372 = "SYMBOLPACK_372",
	SYMBOLPACK_373 = "SYMBOLPACK_373",
	SYMBOLPACK_374 = "SYMBOLPACK_374",
	SYMBOLPACK_375 = "SYMBOLPACK_375",
	SYMBOLPACK_376 = "SYMBOLPACK_376",
	SYMBOLPACK_377 = "SYMBOLPACK_377",
	SYMBOLPACK_378 = "SYMBOLPACK_378",
	SYMBOLPACK_379 = "SYMBOLPACK_379",
	SYMBOLPACK_380 = "SYMBOLPACK_380",
	SYMBOLPACK_381 = "SYMBOLPACK_381",
	SYMBOLPACK_382 = "SYMBOLPACK_382",
	SYMBOLPACK_383 = "SYMBOLPACK_383",
	SYMBOLPACK_384 = "SYMBOLPACK_384",
	SYMBOLPACK_385 = "SYMBOLPACK_385",
	SYMBOLPACK_386 = "SYMBOLPACK_386",
	SYMBOLPACK_387 = "SYMBOLPACK_387",
	SYMBOLPACK_388 = "SYMBOLPACK_388",
	SYMBOLPACK_389 = "SYMBOLPACK_389",
	SYMBOLPACK_390 = "SYMBOLPACK_390",
	SYMBOLPACK_391 = "SYMBOLPACK_391",
	SYMBOLPACK_392 = "SYMBOLPACK_392",
	SYMBOLPACK_393 = "SYMBOLPACK_393",
	SYMBOLPACK_394 = "SYMBOLPACK_394",
	SYMBOLPACK_395 = "SYMBOLPACK_395",
	SYMBOLPACK_396 = "SYMBOLPACK_396",
	SYMBOLPACK_397 = "SYMBOLPACK_397",
	SYMBOLPACK_398 = "SYMBOLPACK_398",
	SYMBOLPACK_399 = "SYMBOLPACK_399",
	SYMBOLPACK_400 = "SYMBOLPACK_400",
	SYMBOLPACK_401 = "SYMBOLPACK_401",
	SYMBOLPACK_402 = "SYMBOLPACK_402",
	SYMBOLPACK_403 = "SYMBOLPACK_403",
	// communications
	SYMBOLPACK_NEW_232 = "SYMBOLPACK_NEW_232",
	SYMBOLPACK_NEW_233 = "SYMBOLPACK_NEW_233",
	SYMBOLPACK_NEW_234 = "SYMBOLPACK_NEW_234",
	SYMBOLPACK_NEW_235 = "SYMBOLPACK_NEW_235",
	SYMBOLPACK_NEW_236 = "SYMBOLPACK_NEW_236",
	SYMBOLPACK_NEW_237 = "SYMBOLPACK_NEW_237",
	SYMBOLPACK_NEW_201 = "SYMBOLPACK_NEW_201",
	SYMBOLPACK_NEW_202 = "SYMBOLPACK_NEW_202",
	SYMBOLPACK_NEW_203 = "SYMBOLPACK_NEW_203",
	SYMBOLPACK_NEW_204 = "SYMBOLPACK_NEW_204",
	SYMBOLPACK_NEW_205 = "SYMBOLPACK_NEW_205",
	SYMBOLPACK_NEW_206 = "SYMBOLPACK_NEW_206",
	SYMBOLPACK_NEW_207 = "SYMBOLPACK_NEW_207",
	// emergency_lighting
	SYMBOLPACK_NEW_196 = "SYMBOLPACK_NEW_196",
	SYMBOLPACK_NEW_197 = "SYMBOLPACK_NEW_197",
	SYMBOLPACK_NEW_198 = "SYMBOLPACK_NEW_198",
	SYMBOLPACK_NEW_199 = "SYMBOLPACK_NEW_199",
	SYMBOLPACK_NEW_200 = "SYMBOLPACK_NEW_200",
	// fire
	SYMBOLPACK_NEW_191 = "SYMBOLPACK_NEW_191",
	SYMBOLPACK_NEW_192 = "SYMBOLPACK_NEW_192",
	SYMBOLPACK_NEW_193 = "SYMBOLPACK_NEW_193",
	SYMBOLPACK_NEW_194 = "SYMBOLPACK_NEW_194",
	SYMBOLPACK_NEW_195 = "SYMBOLPACK_NEW_195",
	SYMBOLPACK_NEW_227 = "SYMBOLPACK_NEW_227",
	SYMBOLPACK_NEW_275 = "SYMBOLPACK_NEW_275",
	SYMBOLPACK_NEW_282 = "SYMBOLPACK_NEW_282",
	SYMBOLPACK_NEW_283 = "SYMBOLPACK_NEW_283",
	SYMBOLPACK_NEW_333 = "SYMBOLPACK_NEW_333",
	SYMBOLPACK_NEW_334 = "SYMBOLPACK_NEW_334",
	SYMBOLPACK_NEW_335 = "SYMBOLPACK_NEW_335",
	SYMBOLPACK_NEW_336 = "SYMBOLPACK_NEW_336",
	SYMBOLPACK_NEW_337 = "SYMBOLPACK_NEW_337",
	SYMBOLPACK_NEW_338 = "SYMBOLPACK_NEW_338",
	SYMBOLPACK_NEW_339 = "SYMBOLPACK_NEW_339",
	SYMBOLPACK_NEW_340 = "SYMBOLPACK_NEW_340",
	SYMBOLPACK_NEW_341 = "SYMBOLPACK_NEW_341",
	SYMBOLPACK_NEW_342 = "SYMBOLPACK_NEW_342",
	SYMBOLPACK_NEW_343 = "SYMBOLPACK_NEW_343",
	SYMBOLPACK_NEW_344 = "SYMBOLPACK_NEW_344",
	SYMBOLPACK_NEW_345 = "SYMBOLPACK_NEW_345",
	// lighting
	SYMBOLPACK_NEW_260 = "SYMBOLPACK_NEW_260",
	SYMBOLPACK_NEW_261 = "SYMBOLPACK_NEW_261",
	SYMBOLPACK_NEW_262 = "SYMBOLPACK_NEW_262",
	SYMBOLPACK_NEW_263 = "SYMBOLPACK_NEW_263",
	SYMBOLPACK_NEW_264 = "SYMBOLPACK_NEW_264",
	SYMBOLPACK_NEW_265 = "SYMBOLPACK_NEW_265",
	SYMBOLPACK_NEW_266 = "SYMBOLPACK_NEW_266",
	//plumbing
	SYMBOLPACK_NEW_267 = "SYMBOLPACK_NEW_267",
	SYMBOLPACK_NEW_268 = "SYMBOLPACK_NEW_268",
	SYMBOLPACK_NEW_269 = "SYMBOLPACK_NEW_269",
	SYMBOLPACK_NEW_270 = "SYMBOLPACK_NEW_270",
	SYMBOLPACK_NEW_271 = "SYMBOLPACK_NEW_271",
	SYMBOLPACK_NEW_272 = "SYMBOLPACK_NEW_272",
	SYMBOLPACK_NEW_273 = "SYMBOLPACK_NEW_273",
	SYMBOLPACK_NEW_274 = "SYMBOLPACK_NEW_274",
	SYMBOLPACK_NEW_276 = "SYMBOLPACK_NEW_276",
	SYMBOLPACK_NEW_277 = "SYMBOLPACK_NEW_277",
	SYMBOLPACK_NEW_278 = "SYMBOLPACK_NEW_278",
	SYMBOLPACK_NEW_279 = "SYMBOLPACK_NEW_279",
	SYMBOLPACK_NEW_280 = "SYMBOLPACK_NEW_280",
	SYMBOLPACK_NEW_281 = "SYMBOLPACK_NEW_281",
	SYMBOLPACK_NEW_284 = "SYMBOLPACK_NEW_284",
	SYMBOLPACK_NEW_285 = "SYMBOLPACK_NEW_285",
	SYMBOLPACK_NEW_286 = "SYMBOLPACK_NEW_286",
	SYMBOLPACK_NEW_287 = "SYMBOLPACK_NEW_287",
	SYMBOLPACK_NEW_288 = "SYMBOLPACK_NEW_288",
	SYMBOLPACK_NEW_289 = "SYMBOLPACK_NEW_289",
	SYMBOLPACK_NEW_290 = "SYMBOLPACK_NEW_290",
	SYMBOLPACK_NEW_291 = "SYMBOLPACK_NEW_291",
	SYMBOLPACK_NEW_292 = "SYMBOLPACK_NEW_292",
	SYMBOLPACK_NEW_293 = "SYMBOLPACK_NEW_293",
	SYMBOLPACK_NEW_294 = "SYMBOLPACK_NEW_294",
	SYMBOLPACK_NEW_295 = "SYMBOLPACK_NEW_295",
	SYMBOLPACK_NEW_296 = "SYMBOLPACK_NEW_296",
	SYMBOLPACK_NEW_297 = "SYMBOLPACK_NEW_297",
	SYMBOLPACK_NEW_298 = "SYMBOLPACK_NEW_298",
	SYMBOLPACK_NEW_299 = "SYMBOLPACK_NEW_299",
	SYMBOLPACK_NEW_300 = "SYMBOLPACK_NEW_300",
	SYMBOLPACK_NEW_301 = "SYMBOLPACK_NEW_301",
	SYMBOLPACK_NEW_302 = "SYMBOLPACK_NEW_302",
	SYMBOLPACK_NEW_303 = "SYMBOLPACK_NEW_303",
	SYMBOLPACK_NEW_304 = "SYMBOLPACK_NEW_304",
	SYMBOLPACK_NEW_305 = "SYMBOLPACK_NEW_305",
	SYMBOLPACK_NEW_306 = "SYMBOLPACK_NEW_306",
	SYMBOLPACK_NEW_307 = "SYMBOLPACK_NEW_307",
	SYMBOLPACK_NEW_308 = "SYMBOLPACK_NEW_308",
	SYMBOLPACK_NEW_309 = "SYMBOLPACK_NEW_309",
	SYMBOLPACK_NEW_310 = "SYMBOLPACK_NEW_310",
	SYMBOLPACK_NEW_311 = "SYMBOLPACK_NEW_311",
	SYMBOLPACK_NEW_312 = "SYMBOLPACK_NEW_312",
	SYMBOLPACK_NEW_313 = "SYMBOLPACK_NEW_313",
	SYMBOLPACK_NEW_314 = "SYMBOLPACK_NEW_314",
	SYMBOLPACK_NEW_315 = "SYMBOLPACK_NEW_315",
	SYMBOLPACK_NEW_316 = "SYMBOLPACK_NEW_316",
	SYMBOLPACK_NEW_317 = "SYMBOLPACK_NEW_317",
	SYMBOLPACK_NEW_318 = "SYMBOLPACK_NEW_318",
	SYMBOLPACK_NEW_319 = "SYMBOLPACK_NEW_319",
	SYMBOLPACK_NEW_320 = "SYMBOLPACK_NEW_320",
	SYMBOLPACK_NEW_321 = "SYMBOLPACK_NEW_321",
	SYMBOLPACK_NEW_322 = "SYMBOLPACK_NEW_322",
	SYMBOLPACK_NEW_324 = "SYMBOLPACK_NEW_324",
	SYMBOLPACK_NEW_325 = "SYMBOLPACK_NEW_325",
	SYMBOLPACK_NEW_326 = "SYMBOLPACK_NEW_326",
	SYMBOLPACK_NEW_327 = "SYMBOLPACK_NEW_327",
	SYMBOLPACK_NEW_328 = "SYMBOLPACK_NEW_328",
	SYMBOLPACK_NEW_329 = "SYMBOLPACK_NEW_329",
	SYMBOLPACK_NEW_330 = "SYMBOLPACK_NEW_330",
	SYMBOLPACK_NEW_331 = "SYMBOLPACK_NEW_331",
	SYMBOLPACK_NEW_332 = "SYMBOLPACK_NEW_332",
	// power
	SYMBOLPACK_NEW_208 = "SYMBOLPACK_NEW_208",
	SYMBOLPACK_NEW_209 = "SYMBOLPACK_NEW_209",
	SYMBOLPACK_NEW_210 = "SYMBOLPACK_NEW_210",
	SYMBOLPACK_NEW_211 = "SYMBOLPACK_NEW_211",
	SYMBOLPACK_NEW_212 = "SYMBOLPACK_NEW_212",
	SYMBOLPACK_NEW_213 = "SYMBOLPACK_NEW_213",
	SYMBOLPACK_NEW_214 = "SYMBOLPACK_NEW_214",
	SYMBOLPACK_NEW_215 = "SYMBOLPACK_NEW_215",
	SYMBOLPACK_NEW_216 = "SYMBOLPACK_NEW_216",
	// switch board schematics
	SYMBOLPACK_NEW_238 = "SYMBOLPACK_NEW_238",
	SYMBOLPACK_NEW_239 = "SYMBOLPACK_NEW_239",
	SYMBOLPACK_NEW_240 = "SYMBOLPACK_NEW_240",
	SYMBOLPACK_NEW_241 = "SYMBOLPACK_NEW_241",
	SYMBOLPACK_NEW_242 = "SYMBOLPACK_NEW_242",
	SYMBOLPACK_NEW_243 = "SYMBOLPACK_NEW_243",
	SYMBOLPACK_NEW_244 = "SYMBOLPACK_NEW_244",
	SYMBOLPACK_NEW_245 = "SYMBOLPACK_NEW_245",
	SYMBOLPACK_NEW_246 = "SYMBOLPACK_NEW_246",
	SYMBOLPACK_NEW_247 = "SYMBOLPACK_NEW_247",
	SYMBOLPACK_NEW_248 = "SYMBOLPACK_NEW_248",
	SYMBOLPACK_NEW_249 = "SYMBOLPACK_NEW_249",
	SYMBOLPACK_NEW_250 = "SYMBOLPACK_NEW_250",
	SYMBOLPACK_NEW_251 = "SYMBOLPACK_NEW_251",
	SYMBOLPACK_NEW_252 = "SYMBOLPACK_NEW_252",
	SYMBOLPACK_NEW_253 = "SYMBOLPACK_NEW_253",
	SYMBOLPACK_NEW_254 = "SYMBOLPACK_NEW_254",
	SYMBOLPACK_NEW_323 = "SYMBOLPACK_NEW_323"
}

export const SymbolMap = {
	[SymbolType.CIRCLE]: Circle,
	[SymbolType.SQUARE]: Square,
	[SymbolType.TRIANGLE]: Triangle,
	[SymbolType.STAR]: Star,
	[SymbolType.ANNUNCIATOR_01]: Annunciator,
	[SymbolType.AUTOMATIC_FIRE_ALARM_DEVICE_01]: AutomaticFireAlarmDevice,
	[SymbolType.BATTERY_01]: Battery,
	[SymbolType.BUZZER_01]: Buzzer,
	[SymbolType.CABLE_TV_OUTLET]: CableTelevisionOutlet,
	[SymbolType.CARD_READER_ACCESS_SYSTEM_01]: CardReaderAccessSystem,
	[SymbolType.CEILING_FAN]: CeilingFan,
	[SymbolType.DIGITAL_SATELLITE_SYSTEM_01]: DigitalSatelliteSystem,
	[SymbolType.DIMMER_SWITCHES]: DimmerSwitches,
	[SymbolType.DIMMER_SWITCHES_01]: DimmerSwitches_1,
	[SymbolType.DOORBELL_01]: Doorbell,
	[SymbolType.DOORBELL_CHIME_01]: DoorbellChime,
	[SymbolType.DOORBELL_TRANSFORMER_01]: DoorbellTransformer,
	[SymbolType.DOUBLE_SOCKET]: DoubleSocket,
	[SymbolType.DOWNLIGHTER]: Downlighter,
	[SymbolType.ELECTRIC_DOOR_OPENER_01]: ElectricDoorOpener,
	[SymbolType.ELECTRIC_MOTORS_01]: ElectricMotors,
	[SymbolType.ELECTROMAGNETIC_DOOR_01]: ElectromagneticDoor,
	[SymbolType.EMERGENCY_LIGHT]: EmergencyLight,
	[SymbolType.EMERGENCY_RELEASE_BUTTON_01]: EmergencyReleaseButton,
	[SymbolType.FIRE_ALARM_BELL_01]: FireAlarmBell,
	[SymbolType.FIRE_ALARM_CENTRAL_STATION_01]: FireAlarmCentralStation,
	[SymbolType.FIRE_ALARM_PANEL]: FireAlarmPanel,
	[SymbolType.FIRE_ALARM_SOUNDER_01]: FireAlarmSounder,
	[SymbolType.FIRE_ALARM_STATION_01]: FireAlarmStation,
	[SymbolType.FLOURESCENT_FIXTURE]: FlourescentFixture,
	[SymbolType.GROUND_CONNECTION_01]: GroundConnection,
	[SymbolType.HORN_01]: Horn,
	[SymbolType.ILLUMINATED_EMERGENCY_SIGN]: IlluminatedEmergencySign,
	[SymbolType.INSIDE_ANTENNA_01]: InsideAntenna,
	[SymbolType.INTERCOM_01]: Intercom,
	[SymbolType.MAGNETIC_DOO_HOLD_01]: MagneticDooHold,
	[SymbolType.MAIDS_SIGNAL_PLUG_01]: MaidsSignalPlug,
	[SymbolType.MAIN_CONTROL_01]: MainControl,
	[SymbolType.MODULAR_FLOURESCENT_FITTING]: ModularFlourescentFitting,
	[SymbolType.MOTION_SENSOR_01]: MotionSensor,
	[SymbolType.MULTI_LIGHTBAR]: MultiLightBar,
	[SymbolType.MULTI_SWITCH]: MultiSwitch,
	[SymbolType.OUTDOOR_LIGHTING]: OutdoorLighting,
	[SymbolType.OUTSIDE_ANTENNA_01]: OutsideAntenna,
	[SymbolType.PULL_CORD_SWITCH]: PullCordSwitch,
	[SymbolType.PUSH_BUTTON_01]: PushButton,
	[SymbolType.SIGNAL_CENTRAL_STATION_01]: SignalCentralStation,
	[SymbolType.SINGLE_PHASE_01]: SinglePhase,
	[SymbolType.SOCKET_OUTLET]: SocketOutlet,
	[SymbolType.STEREO_SPEAKER_OUTLET]: StereoSpeakerOutlet,
	[SymbolType.SURFACE_FLOURESCENT_LIGHT]: SurfaceFlourescentLight,
	[SymbolType.SWITCH]: Switch,
	[SymbolType.SWITCH_2WAY]: Switch2Way,
	[SymbolType.SWITCH_12POLE]: Switch12Pole,
	[SymbolType.SWITCHBOARDS_FLUSH_01]: SwitchboardsFlush,
	[SymbolType.SWITCHBOARDS_SURFACE_01]: SwitchboardsSurface,
	[SymbolType.SWITCHED_SOCKET]: SwitchedSocket,
	[SymbolType.SWITCH_INTERMEDIATE]: SwitchIntermediate,
	[SymbolType.TELEPHONE_KEY_SYSTEM_01]: TelephoneKeySystem,
	[SymbolType.TELEPHONE_OUTLET]: TelephoneOutlet,
	[SymbolType.THERMOSTAT]: Thermostat,
	[SymbolType.THREE_OF_POLYPHASE_01]: ThreeOfPolyphase,
	[SymbolType.WALL_LIGHT]: WallLight,
	[SymbolType.WALL_MOUNTED_ELECTRICAL_JUNCTION_BOX_01]: WallMountedElectricalJunctionBox,
	[SymbolType.WALL_MOUNTED_TELEPHONE_DATABOX_01]: WallMountedTelephoneDataBox,
	[SymbolType.WATCHMANS_CENTRAL_STATION_01]: WatchmansCentralStation,
	[SymbolType.WATCHMANS_STATION_01]: WatchmansStation,
	[SymbolType.TWO_GANG_SSO_01]: TwoGangSSO,
	[SymbolType.TWO_FOUR_TUBE_FLOURESCENT_LUMINAIRE_01]: TwoFourTubeFlourescentLuminaire,
	[SymbolType.CONSUMER_UNIT_01]: ConsumerUnit,
	[SymbolType.CONSUMER_UNIT_DISTRIBUTION_BOARD_FUSE_BOARD_01]: ConsumerUnitDistributionBoardFuseBoard,
	[SymbolType.EMERGENCY_LIGHT_01]: EmergencyLight01,
	[SymbolType.ENERGY_METER_01]: EnergyMeter,
	[SymbolType.FLOURESCENT_LUMINAIRE_01]: FlourescentLuminaire,
	[SymbolType.FUSE_01]: Fuse,
	[SymbolType.INVERTERTER_01]: Inverterter,
	[SymbolType.MOTOR_STARTER_01]: MotorStarter,
	[SymbolType.RECTIFIER_01]: Rectifier,
	[SymbolType.SELF_CONTAINED_EMERGENCY_LIGHT_01]: SelfContainedEmergencyLight,
	[SymbolType.SOCKET_OUTLET_01]: SocketOutlet01,
	[SymbolType.STAR_DELTA_STARTER_01]: StarDeltaStarter,
	[SymbolType.SWITCH_01]: Switch01,
	[SymbolType.SWITCHED_SOCKET_OUTLET_01]: SwitchedSocketOutlet,
	[SymbolType.THREE_PHASE_WINDING_DELTA_01]: ThreePhaseWindingDelta,
	[SymbolType.THREE_PHASE_WINDING_STAR_01]: ThreePhaseWindingStar,
	[SymbolType.CEILING_ROSE]: CeilingRose,
	[SymbolType.EMERGENCY_LAMP_1]: EmergencyLamp_1,
	[SymbolType.EMERGENCY_LAMP_2]: EmergencyLamp_2,
	[SymbolType.EMERGENCY_LAMP_3]: EmergencyLamp_3,
	[SymbolType.EMERGENCY_LAMP_4]: EmergencyLamp_4,
	[SymbolType.FLASHING_LAMP]: FlashingLamp,
	[SymbolType.FLOODLIGHT]: Floodlight,
	[SymbolType.FLOURESCENT_1]: Flourescent_1,
	[SymbolType.FLOURESCENT_2]: Flourescent_2,
	[SymbolType.FLOURESCENT_3]: Flourescent_3,
	[SymbolType.FLOURESCENT_4]: Flourescent_4,
	[SymbolType.LAMP_1]: Lamp_1,
	[SymbolType.LAMP_2]: Lamp_2,
	[SymbolType.LAMP_3]: Lamp_3,
	[SymbolType.LAMP_4]: Lamp_4,
	[SymbolType.LAMP_WALL_MOUNTED]: LampWallMounted,
	[SymbolType.LIGHT_FITTING]: LightFitting,
	[SymbolType.LIGHTING_POSITION]: LightingPosition,
	[SymbolType.PROTECTED_LIGHT_FITTING]: ProtectedLightFitting,
	[SymbolType.PULL_CORD]: PullCord,
	[SymbolType.SCONCE]: Sconce,
	[SymbolType.SPOTLIGHT_1]: Spotlight_1,
	[SymbolType.SPOTLIGHT_2]: Spotlight_2,
	[SymbolType.ONE_GANG_TWO_WAY_LIGHT_SWITCHING]: OneGangTwoWayLightSwitch,
	[SymbolType.ONE_GANG_THREE_WAY_LIGHT_SWITCHING]: OneGangThreeWayLightSwitch,
	[SymbolType.ONE_GANG_LIGHT_SWITCH]: OneGangLightSwitch,
	[SymbolType.ONE_GANG_PULL_CORD]: OneGangPullCord,
	[SymbolType.TWO_CORE_SPEAKER_CABLE]: TwoCoreSpeakerCable,
	[SymbolType.TWO_GANG_TWO_WAY_LIGHT_SWITCH]: TwoGangTwoWayLightSwitch,
	[SymbolType.TWO_GANG_THREE_WAY_LIGHT_SWITCH]: TwoGangThreeWayLightSwitch,
	[SymbolType.TWO_GANG_LIGHT_SWITCH]: TwoGangLightSwitch,
	[SymbolType.AUDIO_SPEAKER]: AudioSpeaker,
	[SymbolType.CAT_5E]: Cat5E,
	[SymbolType.CAT_5_OR_6_DATA_SOCKET]: Cat5Or6DataSocket,
	[SymbolType.CONSUMER_UNIT_2]: ConsumerUnit_2,
	[SymbolType.COOKER_SWITCH]: CookerSwitch,
	[SymbolType.DOORBELL_CHIME_2]: DoorbellChime_2,
	[SymbolType.DOORBELL_PUSH]: DoorbellPush,
	[SymbolType.DOUBLE_SWITCHED_FLOOR_SOCKETS]: DoubleSwitchedFloorSockets,
	[SymbolType.DOUBLE_SWITCHED_WALL_SOCKET]: DoubleSwitchedWallSocket,
	[SymbolType.DOUBLE_SWITCHED_WALL_SOCKET_1200MM]: DoubleSwitchedWallSocket1200mm,
	[SymbolType.EXTERNAL_LIGHT]: ExternalLight,
	[SymbolType.EXTERNAL_LIGHT_BULKHEAD_TYPE]: ExternalLightBulkheadType,
	[SymbolType.FLOURESCENT_STRIP_LIGHT]: FlourescentStripLight,
	[SymbolType.FUSE_SPUR_FOR_TOWEL_RAIL]: FuseSpurForTowelRail,
	[SymbolType.HEAT_DETECTOR_AND_FIRE_ALARM_SOUNDER]: HeatDetectorPlusFireAlarmSounder,
	[SymbolType.IMMERSION_HEATER_AND_THERMOSTAT]: ImmersionHeaterAndThermostat,
	[SymbolType.LIGHT_POINT_BATTEN_HOLDER]: LightPointBattenHolder,
	[SymbolType.LIGHT_POINT_PENDANT_HOLDER_4INCH]: LightPointPendantHolder4Inch,
	[SymbolType.LIGHT_POINT_PENDANT_HOLDER_6INCH]: LightPointPendantHolder6Inch,
	[SymbolType.MECHANICAL_EXTRACT_FAN]: MechanicalExtractFan,
	[SymbolType.MECHANICAL_EXTRACT_FAN_COOKER_HOB_HOOD]: MechanicalExtractFanCookerHobHood,
	[SymbolType.RADIATOR]: Radiator,
	[SymbolType.RECESSED_DOWNLIGHT]: RecessedDownlight,
	[SymbolType.SECURITY_ALARM]: SecurityAlarm,
	[SymbolType.SINGLE_SWITCHED_FUSE_SPUR_AT_HIGH_LEVEL_FOR_EXTRACT_FAN]: SingleSwitchedFuseSpurAtHighLevelForExtractFan,
	[SymbolType.SINGLE_SWITCHED_FUSE_SPUR_BELOW_FL]: SingleSwitchedFuseSpurBelowFL,
	[SymbolType.SINGLE_SWITCHED_WALL_SOCKET]: SingleSwitchedWallSocket,
	[SymbolType.SINGLE_SWITCHED_WALL_SOCKET_1200MM]: SingleSwitchedWallSocket1200mm,
	[SymbolType.SMOKE_HEAT_DETECTOR_FIRE_ALARM_SOUNDER]: SmokeHeatDetectorFireAlarmSounder,
	[SymbolType.TELEPHONE_POINT]: TelephonePoint,
	[SymbolType.THERMOSTAT_2]: Thermostat_2,
	[SymbolType.TV_AERIAL_POINT]: TVAerialPoint,
	[SymbolType.UNDERFLOOR_HEATING_MANIFOLD]: UnderfloorHeatingManifold,
	[SymbolType.WALL_LIGHT_2]: WallLight_2,
	// electrical_actuators
	[SymbolType.ACTUATORBY_MECHANICAL_ENERGY_ACCUMULATION]: ActuatorbyMechanicalEnergyAccumulation,
	[SymbolType.ACTUATOR_LEVEL_POWEREDBY_LIQUID_LEVEL]: ActuatorLevelPoweredbyLiquidLevel,
	[SymbolType.ACTUATOR_MANUAL_CONTROL]: ActuatorManualControl,
	[SymbolType.ACTUATOR_MANUAL_PROTECTAGAINST_ROUGH_HANDLING]: ActuatorManualProtectagainstRoughHandling,
	[SymbolType.CONTACT_CONTROL]: ContactControl,
	[SymbolType.COUNTER_POWERED_ACTUATOR]: CounterPoweredActuator,
	[SymbolType.DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_DOUBLE_EFFECT]: DeviceActivatedPneumaticHydraulicDoubleEffect,
	[SymbolType.DEVICE_ACTIVATED_PNEUMATIC_HYDRAULIC_SA]: DeviceActivatedPneumaticHydraulicSA,
	[SymbolType.ELECTRICAL_CLOCK_CONTROL]: ElectricalClockControl,
	[SymbolType.ELECTRIC_MOTOR_CONTROL]: ElectricMotorControl,
	[SymbolType.ELECTROMAGNETIC_ACTUATOR]: ElectromagneticActuator,
	[SymbolType.EMERGENCY_ACTUATOR]: EmergencyActuator,
	[SymbolType.FLUID_ACTUATOR]: FluidActuator,
	[SymbolType.FREQUENCY_ACTUATOR]: FrequencyActuator,
	[SymbolType.GAS_ACTUATOR_POWEREDBY_FLUID_GAS]: GasActuatorPoweredbyFluidGas,
	[SymbolType.MECHANICAL_CONTROL_BY_CRANK]: MechanicalControlByCrank,
	[SymbolType.MECHANICAL_CONTROLBY_PEDAL]: MechanicalControlbyPedal,
	[SymbolType.MECHANICAL_CONTROLBY_ROLLEROR_SLIDE]: MechanicalControlbyRollerorSlide,
	[SymbolType.MECHANICAL_CONTROL_BY_SHOOTER_AUTO_RETURN]: MechanicalControlByShooterAutoReturn,
	[SymbolType.MECHANICAL_CONTROL_CAM]: MechanicalControlCam,
	[SymbolType.MECHANICAL_CONTROL_HAND_WHEEL]: MechanicalControlHandWheel,
	[SymbolType.MECHANICAL_CONTROL_KEY]: MechanicalControlKey,
	[SymbolType.MECHANICAL_CONTROL_PUSH_BUTTON_AUTO_RETURN]: MechanicalControlPushButtonAutoReturn,
	[SymbolType.MECHANICAL_MANUAL_CONTROL_WITH_LEVER]: MechanicalManualControlWithLever,
	[SymbolType.MECHANICAL_MANUAL_ROTARY_CONTROL]: MechanicalManualRotaryControl,
	[SymbolType.PRESSURE_ACTUATOR]: PressureActuator,
	[SymbolType.PROXY_CONTROL_GENERIC]: ProxyControlGeneric,
	[SymbolType.RELATIVE_HUMIDITY_ACTUATOR]: RelativeHumidityActuator,
	[SymbolType.RELAY_ELECTROMAGNETICACTUATOR]: RelayElectromagneticactuator,
	[SymbolType.REMOVABLE_MANUAL_CONTROL]: RemovableManualControl,
	[SymbolType.THERMAL_ACTUATOR_BY_TEMP_EFFECT]: ThermalActuatorByTempEffect,
	// fire_alarm
	[SymbolType.ALARM_BUTTON_PANIC_BUTTON]: AlarmButtonPanicButton,
	[SymbolType.FIRE_SENSOR_AUTOMATIC_FIRE_SENSOR]: FireSensorAutomaticFireSensor,
	[SymbolType.FIRE_WARNING_DEVICE]: FireWarningDevice,
	[SymbolType.FLAME_DETECTOR]: FlameDetector,
	[SymbolType.HEAT_DETECTOR]: HeatDetector,
	[SymbolType.IONIZING_SMOKE_DETECTOR]: IonizingSmokeDetector,
	[SymbolType.SIREN_ALARM]: SirenAlarm,
	[SymbolType.SMOKE_ALARM]: SmokeAlarm,
	[SymbolType.SMOKE_DETECTOR]: SmokeDetector,
	[SymbolType.SMOKE_DETECTORWITH_ALARM]: SmokeDetectorwithAlarm,
	// security_system
	[SymbolType.BROKEN_GLASS_SENSOR]: BrokenGlassSensor,
	[SymbolType.BUGLAR_SECURITY_ALARM]: BuglarSecurityAlarm,
	[SymbolType.GLASS_BREAK_DETECTOR]: GlassBreakDetector,
	[SymbolType.INFRARED_MOTION_SENSOR]: InfraredMotionSensor,
	[SymbolType.KEYBOARD_ALARM_KEYPAD]: KeyboardAlarmKeypad,
	[SymbolType.MAGNETIC_SENSOR_MAGNETOF_ALARM]: MagneticSensorMagnetofAlarm,
	[SymbolType.MOTION_DETECTOR_SENSOR]: MotionDetectorSensor,
	[SymbolType.PANIC_BUTTON]: PanicButton,
	[SymbolType.P_I_R_ANTIMASKING]: PIRAntimasking,
	[SymbolType.P_I_R_EMITTER]: PIREmitter,
	[SymbolType.P_I_R_RECEPTOR]: PIRReceptor,
	[SymbolType.P_I_R_SENSOR_PASSIVE_INFRARED_DETECTOR_MOTION_DETECTOR]: PIRSensorPassiveInfraredDetectorMotionDetector,
	[SymbolType.P_I_R_WEATHERING_P_I_R_OUTDOOR]: PIRWeatheringPIROutdoor,
	[SymbolType.SPEAKER_SIREN_ALARM]: SpeakerSirenAlarm,
	[SymbolType.ULTRASONIC_MOTION_SENSOR]: UltrasonicMotionSensor,
	[SymbolType.SYMBOLPACK_NEW_255]: Symbolpack_New_255,
	[SymbolType.SYMBOLPACK_NEW_256]: Symbolpack_New_256,
	[SymbolType.SYMBOLPACK_NEW_257]: Symbolpack_New_257,
	[SymbolType.SYMBOLPACK_NEW_258]: Symbolpack_New_258,
	[SymbolType.SYMBOLPACK_NEW_259]: Symbolpack_New_259,
	[SymbolType.SYMBOLPACK_NEW_217]: Symbolpack_New_217,
	[SymbolType.SYMBOLPACK_NEW_218]: Symbolpack_New_218,
	[SymbolType.SYMBOLPACK_NEW_219]: Symbolpack_New_219,
	[SymbolType.SYMBOLPACK_NEW_220]: Symbolpack_New_220,
	[SymbolType.SYMBOLPACK_NEW_221]: Symbolpack_New_221,
	[SymbolType.SYMBOLPACK_NEW_222]: Symbolpack_New_222,
	[SymbolType.SYMBOLPACK_NEW_223]: Symbolpack_New_223,
	[SymbolType.SYMBOLPACK_NEW_224]: Symbolpack_New_224,
	[SymbolType.SYMBOLPACK_NEW_225]: Symbolpack_New_225,
	[SymbolType.SYMBOLPACK_NEW_226]: Symbolpack_New_226,
	[SymbolType.SYMBOLPACK_NEW_228]: Symbolpack_New_228,
	[SymbolType.SYMBOLPACK_NEW_229]: Symbolpack_New_229,
	[SymbolType.SYMBOLPACK_NEW_230]: Symbolpack_New_230,
	[SymbolType.SYMBOLPACK_NEW_231]: Symbolpack_New_231,

	[SymbolType.SYMBOLPACK_346]: Symbol_pack_346,
	[SymbolType.SYMBOLPACK_347]: Symbol_pack_347,
	[SymbolType.SYMBOLPACK_348]: Symbol_pack_348,
	[SymbolType.SYMBOLPACK_349]: Symbol_pack_349,
	[SymbolType.SYMBOLPACK_350]: Symbol_pack_350,
	[SymbolType.SYMBOLPACK_351]: Symbol_pack_351,
	[SymbolType.SYMBOLPACK_352]: Symbol_pack_352,
	[SymbolType.SYMBOLPACK_353]: Symbol_pack_353,
	[SymbolType.SYMBOLPACK_354]: Symbol_pack_354,
	[SymbolType.SYMBOLPACK_355]: Symbol_pack_355,
	[SymbolType.SYMBOLPACK_356]: Symbol_pack_356,
	[SymbolType.SYMBOLPACK_357]: Symbol_pack_357,
	[SymbolType.SYMBOLPACK_358]: Symbol_pack_358,
	[SymbolType.SYMBOLPACK_359]: Symbol_pack_359,
	[SymbolType.SYMBOLPACK_360]: Symbol_pack_360,
	[SymbolType.SYMBOLPACK_361]: Symbol_pack_361,
	[SymbolType.SYMBOLPACK_362]: Symbol_pack_362,
	[SymbolType.SYMBOLPACK_363]: Symbol_pack_363,
	[SymbolType.SYMBOLPACK_364]: Symbol_pack_364,
	[SymbolType.SYMBOLPACK_365]: Symbol_pack_365,
	[SymbolType.SYMBOLPACK_366]: Symbol_pack_366,
	[SymbolType.SYMBOLPACK_367]: Symbol_pack_367,
	[SymbolType.SYMBOLPACK_368]: Symbol_pack_368,
	[SymbolType.SYMBOLPACK_369]: Symbol_pack_369,
	[SymbolType.SYMBOLPACK_370]: Symbol_pack_370,
	[SymbolType.SYMBOLPACK_371]: Symbol_pack_371,
	[SymbolType.SYMBOLPACK_372]: Symbol_pack_372,
	[SymbolType.SYMBOLPACK_373]: Symbol_pack_373,
	[SymbolType.SYMBOLPACK_374]: Symbol_pack_374,
	[SymbolType.SYMBOLPACK_375]: Symbol_pack_375,
	[SymbolType.SYMBOLPACK_376]: Symbol_pack_376,
	[SymbolType.SYMBOLPACK_377]: Symbol_pack_377,
	[SymbolType.SYMBOLPACK_378]: Symbol_pack_378,
	[SymbolType.SYMBOLPACK_379]: Symbol_pack_379,
	[SymbolType.SYMBOLPACK_380]: Symbol_pack_380,
	[SymbolType.SYMBOLPACK_381]: Symbol_pack_381,
	[SymbolType.SYMBOLPACK_382]: Symbol_pack_382,
	[SymbolType.SYMBOLPACK_383]: Symbol_pack_383,
	[SymbolType.SYMBOLPACK_384]: Symbol_pack_384,
	[SymbolType.SYMBOLPACK_385]: Symbol_pack_385,
	[SymbolType.SYMBOLPACK_386]: Symbol_pack_386,
	[SymbolType.SYMBOLPACK_387]: Symbol_pack_387,
	[SymbolType.SYMBOLPACK_388]: Symbol_pack_388,
	[SymbolType.SYMBOLPACK_389]: Symbol_pack_389,
	[SymbolType.SYMBOLPACK_390]: Symbol_pack_390,
	[SymbolType.SYMBOLPACK_391]: Symbol_pack_391,
	[SymbolType.SYMBOLPACK_392]: Symbol_pack_392,
	[SymbolType.SYMBOLPACK_393]: Symbol_pack_393,
	[SymbolType.SYMBOLPACK_394]: Symbol_pack_394,
	[SymbolType.SYMBOLPACK_395]: Symbol_pack_395,
	[SymbolType.SYMBOLPACK_396]: Symbol_pack_396,
	[SymbolType.SYMBOLPACK_397]: Symbol_pack_397,
	[SymbolType.SYMBOLPACK_398]: Symbol_pack_398,
	[SymbolType.SYMBOLPACK_399]: Symbol_pack_399,
	[SymbolType.SYMBOLPACK_400]: Symbol_pack_400,
	[SymbolType.SYMBOLPACK_401]: Symbol_pack_401,
	[SymbolType.SYMBOLPACK_402]: Symbol_pack_402,
	[SymbolType.SYMBOLPACK_403]: Symbol_pack_403,
	// lighting_symbols
	[SymbolType.AUTONOMOUS_LIGHT_POINT]: AutonomousLightPoint,
	[SymbolType.AUXILIARY_DISCHARGE_LAMP_DEVICE]: AuxiliaryDischargeLampDevice,
	[SymbolType.BLOCK_AUTONOMOUS_EMERGENCY_LIGHT]: BlockAutonomousEmergencyLight,
	[SymbolType.DISCHARGE_LAMP2]: DischargeLamp2,
	[SymbolType.EMBEDDED_FLUORESCEN_LINE_OUTPUT]: EmbeddedFluorescenLineOutput,
	[SymbolType.EMBEDDED_FLUORESCENT_OUTPUT]: EmbeddedFluorescentOutput,
	[SymbolType.EMBEDDED_MERCURY_LAMP_OUTPUT]: EmbeddedMercuryLampOutput,
	[SymbolType.EMERGENCY_LIGHTING]: EmergencyLighting,
	[SymbolType.FLASHBULB]: Flashbulb,
	[SymbolType.FLUORESCENT_LAMP]: FluorescentLamp,
	[SymbolType.FLUORESCENT_OUTPUT]: FluorescentOutput,
	[SymbolType.FLUORESCENT_OUTPUT_LINE]: FluorescentOutputLine,
	[SymbolType.INCANDESCENT_LAMP_LIGHTBULB]: IncandescentLampLightbulb,
	[SymbolType.INCANDESCENT_LAMP_LIGHTBULB2]: IncandescentLampLightbulb2,
	[SymbolType.INCANDESCENT_LAMP_LIGHTBULB3]: IncandescentLampLightbulb3,
	[SymbolType.LAMP]: Lamp,
	[SymbolType.LAMP_LOAD]: LampLoad,
	[SymbolType.LAMPWITH_TRANSFORMER]: LampwithTransformer,
	[SymbolType.LIGHTBULB_GENERIC]: LightbulbGeneric,
	[SymbolType.LIGHTING_OUTPUT]: LightingOutput,
	[SymbolType.LIGHTNING_LIGHT_LAMP]: LightningLightLamp,
	[SymbolType.LOW_INTENSITY_LAMPS]: LowIntensityLamps,
	[SymbolType.LUMINAIRE_LIGHT_FIXTURE]: LuminaireLightFixture,
	[SymbolType.LUMINAIRE_POINT]: LuminairePoint,
	[SymbolType.LUMINAIRE_WITH_THREE_ELEMENT]: Luminairewiththreeelement,
	[SymbolType.LUMINOUS_INDICATOR]: LuminousIndicator,
	[SymbolType.MERCURY_LAMP_OUTPUT]: MercuryLampOutput,
	[SymbolType.NEON_LAMP]: NeonLamp,
	[SymbolType.OSCILLATORY_LAMP]: OscillatoryLamp,
	[SymbolType.PROJECTED_LIGHT]: ProjectedLight,
	[SymbolType.SEPERATE_AUXILIARY_LIGHTS]: SeperateAuxiliaryLights,
	[SymbolType.WALL_LAMP_OUTPUT]: WallLampOutput,
	[SymbolType.WALL_LIGHTING]: WallLighting,
	[SymbolType.XENON_FLASH_LAMP]: XenonFlashLamp,
	// plumbing_symbols
	[SymbolType.BATHTUB]: Bathtub,
	[SymbolType.CAP]: Cap,
	[SymbolType.CLEAN_OUT]: CleanOut,
	[SymbolType.CLOTHES_WASHER]: ClothesWasher,
	[SymbolType.DISHWASHER]: Dishwasher,
	[SymbolType.DOUBLE_SINK_WITH_CABINET]: DoubleSinkWithCabinet,
	[SymbolType.FLOOR_DRAIN]: FloorDrain,
	[SymbolType.FREESTANDING_SINK]: FreestandingSink,
	[SymbolType.GATE_VALVE]: GateValve,
	[SymbolType.LAVATORY]: Lavatory,
	[SymbolType.PIPE_TURNS_DOWN]: PipeTurnsDown,
	[SymbolType.PIPE_TURNS_UP]: PipeTurnsUp,
	[SymbolType.SHOWER]: Shower,
	[SymbolType.SINK_WITH_CABINET]: SinkWithCabinet,
	[SymbolType.TOILET]: Toilet,
	[SymbolType.URINAL]: Urinal,
	[SymbolType.VENT_THRU_ROOF]: VentThruRoof,
	[SymbolType.WATER_CLOSET]: WaterCloset,
	[SymbolType.WATER_HEATER]: WaterHeater,
	[SymbolType.WATER_HEATER_SHUT_OFF]: WaterHeaterShutOff,
	[SymbolType.WATER_METER]: WaterMeter,
	// hvac_symbols
	[SymbolType.AIR_CONDITIONER]: AirConditioner,
	[SymbolType.AIR_FILTER]: AirFilter,
	[SymbolType.CONDENSER]: Condenser,
	[SymbolType.FAN]: Fan,
	[SymbolType.HEATER]: Heater,
	[SymbolType.RETURN_AIR_VENT]: ReturnAirVent,
	[SymbolType.STRAIGHT_DUCT]: StraightDuct,
	[SymbolType.Y_JUNCTION_DUCT]: YJunctionDuct,
	[SymbolType.SYMBOLPACK_2_11]: Symbolpack_2_11,
	[SymbolType.SYMBOLPACK_2_12]: Symbolpack_2_12,
	[SymbolType.SYMBOLPACK_2_13]: Symbolpack_2_13,
	[SymbolType.SYMBOLPACK_2_14]: Symbolpack_2_14,
	[SymbolType.SYMBOLPACK_2_15]: Symbolpack_2_15,
	[SymbolType.SYMBOLPACK_2_16]: Symbolpack_2_16,
	[SymbolType.SYMBOLPACK_2_17]: Symbolpack_2_17,
	[SymbolType.SYMBOLPACK_2_18]: Symbolpack_2_18,
	[SymbolType.SYMBOLPACK_2_19]: Symbolpack_2_19,
	[SymbolType.SYMBOLPACK_2_20]: Symbolpack_2_20,
	[SymbolType.SYMBOLPACK_2_21]: Symbolpack_2_21,
	[SymbolType.SYMBOLPACK_2_22]: Symbolpack_2_22,
	[SymbolType.SYMBOLPACK_2_23]: Symbolpack_2_23,
	[SymbolType.SYMBOLPACK_2_24]: Symbolpack_2_24,
	[SymbolType.SYMBOLPACK_2_25]: Symbolpack_2_25,
	[SymbolType.SYMBOLPACK_2_26]: Symbolpack_2_26,
	[SymbolType.SYMBOLPACK_2_27]: Symbolpack_2_27,
	[SymbolType.SYMBOLPACK_2_28]: Symbolpack_2_28,
	[SymbolType.SYMBOLPACK_2_29]: Symbolpack_2_29,
	[SymbolType.SYMBOLPACK_2_30]: Symbolpack_2_30,
	[SymbolType.SYMBOLPACK_2_31]: Symbolpack_2_31,
	[SymbolType.SYMBOLPACK_2_32]: Symbolpack_2_32,
	[SymbolType.SYMBOLPACK_2_33]: Symbolpack_2_33,
	[SymbolType.SYMBOLPACK_2_34]: Symbolpack_2_34,
	[SymbolType.SYMBOLPACK_2_35]: Symbolpack_2_35,
	[SymbolType.SYMBOLPACK_2_36]: Symbolpack_2_36,
	[SymbolType.SYMBOLPACK_3_11]: Symbolpack_3_11,
	[SymbolType.SYMBOLPACK_3_12]: Symbolpack_3_12,
	[SymbolType.SYMBOLPACK_3_13]: Symbolpack_3_13,
	[SymbolType.SYMBOLPACK_3_14]: Symbolpack_3_14,
	[SymbolType.SYMBOLPACK_3_15]: Symbolpack_3_15,
	[SymbolType.SYMBOLPACK_3_16]: Symbolpack_3_16,
	[SymbolType.SYMBOLPACK_3_17]: Symbolpack_3_17,
	[SymbolType.SYMBOLPACK_3_18]: Symbolpack_3_18,
	[SymbolType.SYMBOLPACK_3_19]: Symbolpack_3_19,
	[SymbolType.SYMBOLPACK_3_20]: Symbolpack_3_20,
	[SymbolType.SYMBOLPACK_3_21]: Symbolpack_3_21,
	[SymbolType.SYMBOLPACK_3_22]: Symbolpack_3_22,
	[SymbolType.SYMBOLPACK_3_23]: Symbolpack_3_23,
	[SymbolType.SYMBOLPACK_3_24]: Symbolpack_3_24,
	[SymbolType.SYMBOLPACK_3_25]: Symbolpack_3_25,
	[SymbolType.SYMBOLPACK_3_26]: Symbolpack_3_26,
	[SymbolType.SYMBOLPACK_3_27]: Symbolpack_3_27,
	[SymbolType.SYMBOLPACK_3_28]: Symbolpack_3_28,
	[SymbolType.SYMBOLPACK_3_29]: Symbolpack_3_29,
	[SymbolType.SYMBOLPACK_3_30]: Symbolpack_3_30,
	[SymbolType.SYMBOLPACK_3_31]: Symbolpack_3_31,
	[SymbolType.SYMBOLPACK_3_32]: Symbolpack_3_32,
	[SymbolType.SYMBOLPACK_3_33]: Symbolpack_3_33,
	[SymbolType.SYMBOLPACK_3_34]: Symbolpack_3_34,
	[SymbolType.SYMBOLPACK_3_35]: Symbolpack_3_35,
	[SymbolType.SYMBOLPACK_3_36]: Symbolpack_3_36,
	[SymbolType.SYMBOLPACK_4_11]: Symbolpack_4_11,
	[SymbolType.SYMBOLPACK_4_12]: Symbolpack_4_12,
	[SymbolType.SYMBOLPACK_4_13]: Symbolpack_4_13,
	[SymbolType.SYMBOLPACK_4_14]: Symbolpack_4_14,
	[SymbolType.SYMBOLPACK_4_15]: Symbolpack_4_15,
	[SymbolType.SYMBOLPACK_4_16]: Symbolpack_4_16,
	[SymbolType.SYMBOLPACK_4_17]: Symbolpack_4_17,
	[SymbolType.SYMBOLPACK_4_18]: Symbolpack_4_18,
	[SymbolType.SYMBOLPACK_4_19]: Symbolpack_4_19,
	[SymbolType.SYMBOLPACK_4_20]: Symbolpack_4_20,
	[SymbolType.SYMBOLPACK_4_21]: Symbolpack_4_21,
	[SymbolType.SYMBOLPACK_4_22]: Symbolpack_4_22,
	[SymbolType.SYMBOLPACK_4_23]: Symbolpack_4_23,
	[SymbolType.SYMBOLPACK_4_24]: Symbolpack_4_24,
	[SymbolType.SYMBOLPACK_4_25]: Symbolpack_4_25,
	[SymbolType.SYMBOLPACK_4_26]: Symbolpack_4_26,
	[SymbolType.SYMBOLPACK_4_27]: Symbolpack_4_27,
	[SymbolType.SYMBOLPACK_4_28]: Symbolpack_4_28,
	[SymbolType.SYMBOLPACK_4_29]: Symbolpack_4_29,
	[SymbolType.SYMBOLPACK_4_30]: Symbolpack_4_30,
	[SymbolType.SYMBOLPACK_4_31]: Symbolpack_4_31,
	[SymbolType.SYMBOLPACK_4_32]: Symbolpack_4_32,
	[SymbolType.SYMBOLPACK_4_33]: Symbolpack_4_33,
	[SymbolType.SYMBOLPACK_4_34]: Symbolpack_4_34,
	[SymbolType.SYMBOLPACK_4_35]: Symbolpack_4_35,
	[SymbolType.SYMBOLPACK_4_36]: Symbolpack_4_36,
	[SymbolType.SYMBOLPACK_5_11]: Symbolpack_5_11,
	[SymbolType.SYMBOLPACK_5_12]: Symbolpack_5_12,
	[SymbolType.SYMBOLPACK_5_13]: Symbolpack_5_13,
	[SymbolType.SYMBOLPACK_5_14]: Symbolpack_5_14,
	[SymbolType.SYMBOLPACK_5_15]: Symbolpack_5_15,
	[SymbolType.SYMBOLPACK_5_16]: Symbolpack_5_16,
	[SymbolType.SYMBOLPACK_5_17]: Symbolpack_5_17,
	[SymbolType.SYMBOLPACK_5_18]: Symbolpack_5_18,
	[SymbolType.SYMBOLPACK_5_19]: Symbolpack_5_19,
	[SymbolType.SYMBOLPACK_5_20]: Symbolpack_5_20,
	[SymbolType.SYMBOLPACK_5_21]: Symbolpack_5_21,
	[SymbolType.SYMBOLPACK_5_22]: Symbolpack_5_22,
	[SymbolType.SYMBOLPACK_5_23]: Symbolpack_5_23,
	[SymbolType.SYMBOLPACK_5_24]: Symbolpack_5_24,
	[SymbolType.SYMBOLPACK_5_25]: Symbolpack_5_25,
	[SymbolType.SYMBOLPACK_5_26]: Symbolpack_5_26,
	[SymbolType.SYMBOLPACK_5_27]: Symbolpack_5_27,
	[SymbolType.SYMBOLPACK_5_28]: Symbolpack_5_28,
	[SymbolType.SYMBOLPACK_5_29]: Symbolpack_5_29,
	[SymbolType.SYMBOLPACK_5_30]: Symbolpack_5_30,
	[SymbolType.SYMBOLPACK_5_31]: Symbolpack_5_31,
	[SymbolType.SYMBOLPACK_5_32]: Symbolpack_5_32,
	[SymbolType.SYMBOLPACK_5_33]: Symbolpack_5_33,
	[SymbolType.SYMBOLPACK_5_34]: Symbolpack_5_34,
	[SymbolType.SYMBOLPACK_5_35]: Symbolpack_5_35,
	[SymbolType.SYMBOLPACK_5_36]: Symbolpack_5_36,
	[SymbolType.SYMBOLPACK_6_11]: Symbolpack_6_11,
	[SymbolType.SYMBOLPACK_6_12]: Symbolpack_6_12,
	[SymbolType.SYMBOLPACK_6_13]: Symbolpack_6_13,
	[SymbolType.SYMBOLPACK_6_14]: Symbolpack_6_14,
	[SymbolType.SYMBOLPACK_6_15]: Symbolpack_6_15,
	[SymbolType.SYMBOLPACK_6_16]: Symbolpack_6_16,
	[SymbolType.SYMBOLPACK_6_17]: Symbolpack_6_17,
	[SymbolType.SYMBOLPACK_6_18]: Symbolpack_6_18,
	[SymbolType.SYMBOLPACK_6_19]: Symbolpack_6_19,
	[SymbolType.SYMBOLPACK_6_20]: Symbolpack_6_20,
	[SymbolType.SYMBOLPACK_6_21]: Symbolpack_6_21,
	[SymbolType.SYMBOLPACK_6_22]: Symbolpack_6_22,
	[SymbolType.SYMBOLPACK_6_23]: Symbolpack_6_23,
	[SymbolType.SYMBOLPACK_6_24]: Symbolpack_6_24,
	[SymbolType.SYMBOLPACK_6_25]: Symbolpack_6_25,
	[SymbolType.SYMBOLPACK_6_26]: Symbolpack_6_26,
	[SymbolType.SYMBOLPACK_6_27]: Symbolpack_6_27,
	[SymbolType.SYMBOLPACK_6_28]: Symbolpack_6_28,
	[SymbolType.SYMBOLPACK_6_29]: Symbolpack_6_29,
	[SymbolType.SYMBOLPACK_6_30]: Symbolpack_6_30,
	[SymbolType.SYMBOLPACK_6_31]: Symbolpack_6_31,
	[SymbolType.SYMBOLPACK_6_32]: Symbolpack_6_32,
	[SymbolType.SYMBOLPACK_6_33]: Symbolpack_6_33,
	[SymbolType.SYMBOLPACK_6_34]: Symbolpack_6_34,
	[SymbolType.SYMBOLPACK_6_35]: Symbolpack_6_35,
	[SymbolType.SYMBOLPACK_6_36]: Symbolpack_6_36,
	[SymbolType.SYMBOLPACK_6_37]: Symbolpack_6_37,
	[SymbolType.SYMBOLPACK_6_38]: Symbolpack_6_38,
	[SymbolType.SYMBOLPACK_6_39]: Symbolpack_6_39,
	[SymbolType.SYMBOLPACK_6_40]: Symbolpack_6_40,
	[SymbolType.SYMBOLPACK_6_41]: Symbolpack_6_41,
	[SymbolType.SYMBOLPACK_6_42]: Symbolpack_6_42,
	[SymbolType.SYMBOLPACK_6_43]: Symbolpack_6_43,
	[SymbolType.SYMBOLPACK_6_44]: Symbolpack_6_44,
	[SymbolType.SYMBOLPACK_6_45]: Symbolpack_6_45,
	[SymbolType.SYMBOLPACK_6_46]: Symbolpack_6_46,
	[SymbolType.SYMBOLPACK_6_47]: Symbolpack_6_47,
	[SymbolType.SYMBOLPACK_6_48]: Symbolpack_6_48,
	[SymbolType.SYMBOLPACK_3_SOLID_11]: Symbolpack_3_solid_11,
	[SymbolType.SYMBOLPACK_3_SOLID_12]: Symbolpack_3_solid_12,
	[SymbolType.SYMBOLPACK_3_SOLID_13]: Symbolpack_3_solid_13,
	[SymbolType.SYMBOLPACK_3_SOLID_14]: Symbolpack_3_solid_14,
	[SymbolType.SYMBOLPACK_3_SOLID_15]: Symbolpack_3_solid_15,
	[SymbolType.SYMBOLPACK_3_SOLID_16]: Symbolpack_3_solid_16,
	[SymbolType.SYMBOLPACK_3_SOLID_17]: Symbolpack_3_solid_17,
	[SymbolType.SYMBOLPACK_3_SOLID_18]: Symbolpack_3_solid_18,
	[SymbolType.SYMBOLPACK_3_SOLID_19]: Symbolpack_3_solid_19,
	[SymbolType.SYMBOLPACK_3_SOLID_20]: Symbolpack_3_solid_20,
	[SymbolType.SYMBOLPACK_3_SOLID_21]: Symbolpack_3_solid_21,
	[SymbolType.SYMBOLPACK_3_SOLID_22]: Symbolpack_3_solid_22,
	[SymbolType.SYMBOLPACK_3_SOLID_23]: Symbolpack_3_solid_23,
	[SymbolType.SYMBOLPACK_3_SOLID_24]: Symbolpack_3_solid_24,
	[SymbolType.SYMBOLPACK_3_SOLID_25]: Symbolpack_3_solid_25,
	[SymbolType.SYMBOLPACK_3_SOLID_26]: Symbolpack_3_solid_26,
	[SymbolType.SYMBOLPACK_3_SOLID_27]: Symbolpack_3_solid_27,
	[SymbolType.SYMBOLPACK_3_SOLID_28]: Symbolpack_3_solid_28,
	[SymbolType.SYMBOLPACK_3_SOLID_29]: Symbolpack_3_solid_29,
	[SymbolType.SYMBOLPACK_3_SOLID_30]: Symbolpack_3_solid_30,
	[SymbolType.SYMBOLPACK_3_SOLID_31]: Symbolpack_3_solid_31,
	[SymbolType.SYMBOLPACK_3_SOLID_32]: Symbolpack_3_solid_32,
	[SymbolType.SYMBOLPACK_3_SOLID_33]: Symbolpack_3_solid_33,
	[SymbolType.SYMBOLPACK_3_SOLID_34]: Symbolpack_3_solid_34,
	[SymbolType.SYMBOLPACK_3_SOLID_35]: Symbolpack_3_solid_35,
	[SymbolType.SYMBOLPACK_3_SOLID_36]: Symbolpack_3_solid_36,
	[SymbolType.SYMBOLPACK_3_SOLID_37]: Symbolpack_3_solid_37,
	[SymbolType.SYMBOLPACK_3_SOLID_38]: Symbolpack_3_solid_38,
	[SymbolType.SYMBOLPACK_3_SOLID_39]: Symbolpack_3_solid_39,
	[SymbolType.SYMBOLPACK_3_SOLID_40]: Symbolpack_3_solid_40,
	[SymbolType.SYMBOLPACK_3_SOLID_41]: Symbolpack_3_solid_41,
	[SymbolType.SYMBOLPACK_3_SOLID_42]: Symbolpack_3_solid_42,
	[SymbolType.SYMBOLPACK_3_SOLID_43]: Symbolpack_3_solid_43,
	[SymbolType.SYMBOLPACK_3_SOLID_44]: Symbolpack_3_solid_44,
	[SymbolType.SYMBOLPACK_3_SOLID_45]: Symbolpack_3_solid_45,
	[SymbolType.SYMBOLPACK_3_SOLID_46]: Symbolpack_3_solid_46,
	[SymbolType.SYMBOLPACK_3_SOLID_47]: Symbolpack_3_solid_47,
	[SymbolType.SYMBOLPACK_3_SOLID_48]: Symbolpack_3_solid_48,
	[SymbolType.SYMBOLPACK_4_SOLID_11]: Symbolpack_4_solid_11,
	[SymbolType.SYMBOLPACK_4_SOLID_12]: Symbolpack_4_solid_12,
	[SymbolType.SYMBOLPACK_4_SOLID_13]: Symbolpack_4_solid_13,
	[SymbolType.SYMBOLPACK_4_SOLID_14]: Symbolpack_4_solid_14,
	[SymbolType.SYMBOLPACK_4_SOLID_15]: Symbolpack_4_solid_15,
	[SymbolType.SYMBOLPACK_4_SOLID_16]: Symbolpack_4_solid_16,
	[SymbolType.SYMBOLPACK_4_SOLID_17]: Symbolpack_4_solid_17,
	[SymbolType.SYMBOLPACK_4_SOLID_18]: Symbolpack_4_solid_18,
	[SymbolType.SYMBOLPACK_4_SOLID_19]: Symbolpack_4_solid_19,
	[SymbolType.SYMBOLPACK_4_SOLID_20]: Symbolpack_4_solid_20,
	[SymbolType.SYMBOLPACK_4_SOLID_21]: Symbolpack_4_solid_21,
	[SymbolType.SYMBOLPACK_4_SOLID_22]: Symbolpack_4_solid_22,
	[SymbolType.SYMBOLPACK_4_SOLID_23]: Symbolpack_4_solid_23,
	[SymbolType.SYMBOLPACK_4_SOLID_24]: Symbolpack_4_solid_24,
	[SymbolType.SYMBOLPACK_4_SOLID_25]: Symbolpack_4_solid_25,
	[SymbolType.SYMBOLPACK_4_SOLID_26]: Symbolpack_4_solid_26,
	[SymbolType.SYMBOLPACK_4_SOLID_27]: Symbolpack_4_solid_27,
	[SymbolType.SYMBOLPACK_4_SOLID_28]: Symbolpack_4_solid_28,
	[SymbolType.SYMBOLPACK_4_SOLID_29]: Symbolpack_4_solid_29,
	[SymbolType.SYMBOLPACK_4_SOLID_30]: Symbolpack_4_solid_30,
	[SymbolType.SYMBOLPACK_4_SOLID_31]: Symbolpack_4_solid_31,
	[SymbolType.SYMBOLPACK_4_SOLID_32]: Symbolpack_4_solid_32,
	[SymbolType.SYMBOLPACK_4_SOLID_33]: Symbolpack_4_solid_33,
	[SymbolType.SYMBOLPACK_4_SOLID_34]: Symbolpack_4_solid_34,
	[SymbolType.SYMBOLPACK_4_SOLID_35]: Symbolpack_4_solid_35,
	[SymbolType.SYMBOLPACK_4_SOLID_36]: Symbolpack_4_solid_36,
	[SymbolType.SYMBOLPACK_4_SOLID_37]: Symbolpack_4_solid_37,
	[SymbolType.SYMBOLPACK_4_SOLID_38]: Symbolpack_4_solid_38,
	[SymbolType.SYMBOLPACK_4_SOLID_39]: Symbolpack_4_solid_39,
	[SymbolType.SYMBOLPACK_4_SOLID_40]: Symbolpack_4_solid_40,
	[SymbolType.SYMBOLPACK_4_SOLID_41]: Symbolpack_4_solid_41,
	[SymbolType.SYMBOLPACK_4_SOLID_42]: Symbolpack_4_solid_42,
	[SymbolType.SYMBOLPACK_4_SOLID_43]: Symbolpack_4_solid_43,
	[SymbolType.SYMBOLPACK_4_SOLID_44]: Symbolpack_4_solid_44,
	[SymbolType.SYMBOLPACK_4_SOLID_45]: Symbolpack_4_solid_45,
	[SymbolType.SYMBOLPACK_4_SOLID_46]: Symbolpack_4_solid_46,
	[SymbolType.SYMBOLPACK_4_SOLID_47]: Symbolpack_4_solid_47,
	[SymbolType.SYMBOLPACK_4_SOLID_48]: Symbolpack_4_solid_48,
	[SymbolType.SYMBOLPACK_5_SOLID_11]: Symbolpack_5_solid_11,
	[SymbolType.SYMBOLPACK_5_SOLID_12]: Symbolpack_5_solid_12,
	[SymbolType.SYMBOLPACK_5_SOLID_13]: Symbolpack_5_solid_13,
	[SymbolType.SYMBOLPACK_5_SOLID_14]: Symbolpack_5_solid_14,
	[SymbolType.SYMBOLPACK_5_SOLID_15]: Symbolpack_5_solid_15,
	[SymbolType.SYMBOLPACK_5_SOLID_16]: Symbolpack_5_solid_16,
	[SymbolType.SYMBOLPACK_5_SOLID_17]: Symbolpack_5_solid_17,
	[SymbolType.SYMBOLPACK_5_SOLID_18]: Symbolpack_5_solid_18,
	[SymbolType.SYMBOLPACK_5_SOLID_19]: Symbolpack_5_solid_19,
	[SymbolType.SYMBOLPACK_5_SOLID_20]: Symbolpack_5_solid_20,
	[SymbolType.SYMBOLPACK_5_SOLID_21]: Symbolpack_5_solid_21,
	[SymbolType.SYMBOLPACK_5_SOLID_22]: Symbolpack_5_solid_22,
	[SymbolType.SYMBOLPACK_5_SOLID_23]: Symbolpack_5_solid_23,
	[SymbolType.SYMBOLPACK_5_SOLID_24]: Symbolpack_5_solid_24,
	[SymbolType.SYMBOLPACK_5_SOLID_25]: Symbolpack_5_solid_25,
	[SymbolType.SYMBOLPACK_5_SOLID_26]: Symbolpack_5_solid_26,
	[SymbolType.SYMBOLPACK_5_SOLID_27]: Symbolpack_5_solid_27,
	[SymbolType.SYMBOLPACK_5_SOLID_28]: Symbolpack_5_solid_28,
	[SymbolType.SYMBOLPACK_5_SOLID_29]: Symbolpack_5_solid_29,
	[SymbolType.SYMBOLPACK_5_SOLID_30]: Symbolpack_5_solid_30,
	[SymbolType.SYMBOLPACK_5_SOLID_31]: Symbolpack_5_solid_31,
	[SymbolType.SYMBOLPACK_5_SOLID_32]: Symbolpack_5_solid_32,
	[SymbolType.SYMBOLPACK_5_SOLID_33]: Symbolpack_5_solid_33,
	[SymbolType.SYMBOLPACK_5_SOLID_34]: Symbolpack_5_solid_34,
	[SymbolType.SYMBOLPACK_5_SOLID_35]: Symbolpack_5_solid_35,
	[SymbolType.SYMBOLPACK_5_SOLID_36]: Symbolpack_5_solid_36,
	[SymbolType.SYMBOLPACK_5_SOLID_37]: Symbolpack_5_solid_37,
	[SymbolType.SYMBOLPACK_5_SOLID_38]: Symbolpack_5_solid_38,
	[SymbolType.SYMBOLPACK_5_SOLID_39]: Symbolpack_5_solid_39,
	[SymbolType.SYMBOLPACK_5_SOLID_40]: Symbolpack_5_solid_40,
	[SymbolType.SYMBOLPACK_5_SOLID_41]: Symbolpack_5_solid_41,
	[SymbolType.SYMBOLPACK_5_SOLID_42]: Symbolpack_5_solid_42,
	[SymbolType.SYMBOLPACK_5_SOLID_43]: Symbolpack_5_solid_43,
	[SymbolType.SYMBOLPACK_5_SOLID_44]: Symbolpack_5_solid_44,
	[SymbolType.SYMBOLPACK_5_SOLID_45]: Symbolpack_5_solid_45,
	[SymbolType.SYMBOLPACK_5_SOLID_46]: Symbolpack_5_solid_46,
	[SymbolType.SYMBOLPACK_5_SOLID_47]: Symbolpack_5_solid_47,
	[SymbolType.SYMBOLPACK_5_SOLID_48]: Symbolpack_5_solid_48,
	[SymbolType.SYMBOLPACK_6_SOLID_11]: Symbolpack_6_solid_11,
	[SymbolType.SYMBOLPACK_6_SOLID_12]: Symbolpack_6_solid_12,
	[SymbolType.SYMBOLPACK_6_SOLID_13]: Symbolpack_6_solid_13,
	[SymbolType.SYMBOLPACK_6_SOLID_14]: Symbolpack_6_solid_14,
	[SymbolType.SYMBOLPACK_6_SOLID_15]: Symbolpack_6_solid_15,
	[SymbolType.SYMBOLPACK_6_SOLID_16]: Symbolpack_6_solid_16,
	[SymbolType.SYMBOLPACK_6_SOLID_17]: Symbolpack_6_solid_17,
	[SymbolType.SYMBOLPACK_6_SOLID_18]: Symbolpack_6_solid_18,
	[SymbolType.SYMBOLPACK_6_SOLID_19]: Symbolpack_6_solid_19,
	[SymbolType.SYMBOLPACK_6_SOLID_20]: Symbolpack_6_solid_20,
	[SymbolType.SYMBOLPACK_6_SOLID_21]: Symbolpack_6_solid_21,
	[SymbolType.SYMBOLPACK_6_SOLID_22]: Symbolpack_6_solid_22,
	[SymbolType.SYMBOLPACK_6_SOLID_23]: Symbolpack_6_solid_23,
	[SymbolType.SYMBOLPACK_6_SOLID_24]: Symbolpack_6_solid_24,
	[SymbolType.SYMBOLPACK_6_SOLID_25]: Symbolpack_6_solid_25,
	[SymbolType.SYMBOLPACK_6_SOLID_26]: Symbolpack_6_solid_26,
	[SymbolType.SYMBOLPACK_6_SOLID_27]: Symbolpack_6_solid_27,
	[SymbolType.SYMBOLPACK_6_SOLID_28]: Symbolpack_6_solid_28,
	[SymbolType.SYMBOLPACK_6_SOLID_29]: Symbolpack_6_solid_29,
	[SymbolType.SYMBOLPACK_6_SOLID_30]: Symbolpack_6_solid_30,
	[SymbolType.SYMBOLPACK_6_SOLID_31]: Symbolpack_6_solid_31,
	[SymbolType.SYMBOLPACK_6_SOLID_32]: Symbolpack_6_solid_32,
	[SymbolType.SYMBOLPACK_6_SOLID_33]: Symbolpack_6_solid_33,
	[SymbolType.SYMBOLPACK_6_SOLID_34]: Symbolpack_6_solid_34,
	[SymbolType.SYMBOLPACK_6_SOLID_35]: Symbolpack_6_solid_35,
	[SymbolType.SYMBOLPACK_6_SOLID_36]: Symbolpack_6_solid_36,
	[SymbolType.SYMBOLPACK_6_SOLID_37]: Symbolpack_6_solid_37,
	[SymbolType.SYMBOLPACK_6_SOLID_38]: Symbolpack_6_solid_38,
	[SymbolType.SYMBOLPACK_6_SOLID_39]: Symbolpack_6_solid_39,
	[SymbolType.SYMBOLPACK_6_SOLID_40]: Symbolpack_6_solid_40,
	[SymbolType.SYMBOLPACK_6_SOLID_41]: Symbolpack_6_solid_41,
	[SymbolType.SYMBOLPACK_6_SOLID_42]: Symbolpack_6_solid_42,
	[SymbolType.SYMBOLPACK_6_SOLID_43]: Symbolpack_6_solid_43,
	[SymbolType.SYMBOLPACK_6_SOLID_44]: Symbolpack_6_solid_44,
	[SymbolType.SYMBOLPACK_6_SOLID_45]: Symbolpack_6_solid_45,
	[SymbolType.SYMBOLPACK_6_SOLID_46]: Symbolpack_6_solid_46,
	[SymbolType.SYMBOLPACK_6_SOLID_47]: Symbolpack_6_solid_47,
	[SymbolType.SYMBOLPACK_6_SOLID_48]: Symbolpack_6_solid_48,
	// general_characters
	[SymbolType.SYMBOLPACK_2_37]: Symbolpack_2_37,
	[SymbolType.SYMBOLPACK_2_38]: Symbolpack_2_38,
	[SymbolType.SYMBOLPACK_2_39]: Symbolpack_2_39,
	[SymbolType.SYMBOLPACK_2_40]: Symbolpack_2_40,
	[SymbolType.SYMBOLPACK_2_41]: Symbolpack_2_41,
	[SymbolType.SYMBOLPACK_2_42]: Symbolpack_2_42,
	[SymbolType.SYMBOLPACK_2_43]: Symbolpack_2_43,
	[SymbolType.SYMBOLPACK_2_44]: Symbolpack_2_44,
	[SymbolType.SYMBOLPACK_2_45]: Symbolpack_2_45,
	[SymbolType.SYMBOLPACK_2_46]: Symbolpack_2_46,
	[SymbolType.SYMBOLPACK_2_47]: Symbolpack_2_47,
	[SymbolType.SYMBOLPACK_2_48]: Symbolpack_2_48,
	[SymbolType.SYMBOLPACK_3_37]: Symbolpack_3_37,
	[SymbolType.SYMBOLPACK_3_38]: Symbolpack_3_38,
	[SymbolType.SYMBOLPACK_3_39]: Symbolpack_3_39,
	[SymbolType.SYMBOLPACK_3_40]: Symbolpack_3_40,
	[SymbolType.SYMBOLPACK_3_41]: Symbolpack_3_41,
	[SymbolType.SYMBOLPACK_3_42]: Symbolpack_3_42,
	[SymbolType.SYMBOLPACK_3_43]: Symbolpack_3_43,
	[SymbolType.SYMBOLPACK_3_44]: Symbolpack_3_44,
	[SymbolType.SYMBOLPACK_3_45]: Symbolpack_3_45,
	[SymbolType.SYMBOLPACK_3_46]: Symbolpack_3_46,
	[SymbolType.SYMBOLPACK_3_47]: Symbolpack_3_47,
	[SymbolType.SYMBOLPACK_3_48]: Symbolpack_3_48,
	[SymbolType.SYMBOLPACK_4_37]: Symbolpack_4_37,
	[SymbolType.SYMBOLPACK_4_38]: Symbolpack_4_38,
	[SymbolType.SYMBOLPACK_4_39]: Symbolpack_4_39,
	[SymbolType.SYMBOLPACK_4_40]: Symbolpack_4_40,
	[SymbolType.SYMBOLPACK_4_41]: Symbolpack_4_41,
	[SymbolType.SYMBOLPACK_4_42]: Symbolpack_4_42,
	[SymbolType.SYMBOLPACK_4_43]: Symbolpack_4_43,
	[SymbolType.SYMBOLPACK_4_44]: Symbolpack_4_44,
	[SymbolType.SYMBOLPACK_4_45]: Symbolpack_4_45,
	[SymbolType.SYMBOLPACK_4_46]: Symbolpack_4_46,
	[SymbolType.SYMBOLPACK_4_47]: Symbolpack_4_47,
	[SymbolType.SYMBOLPACK_4_48]: Symbolpack_4_48,
	[SymbolType.SYMBOLPACK_5_37]: Symbolpack_5_37,
	[SymbolType.SYMBOLPACK_5_38]: Symbolpack_5_38,
	[SymbolType.SYMBOLPACK_5_39]: Symbolpack_5_39,
	[SymbolType.SYMBOLPACK_5_40]: Symbolpack_5_40,
	[SymbolType.SYMBOLPACK_5_41]: Symbolpack_5_41,
	[SymbolType.SYMBOLPACK_5_42]: Symbolpack_5_42,
	[SymbolType.SYMBOLPACK_5_43]: Symbolpack_5_43,
	[SymbolType.SYMBOLPACK_5_44]: Symbolpack_5_44,
	[SymbolType.SYMBOLPACK_5_45]: Symbolpack_5_45,
	[SymbolType.SYMBOLPACK_5_46]: Symbolpack_5_46,
	[SymbolType.SYMBOLPACK_5_47]: Symbolpack_5_47,
	[SymbolType.SYMBOLPACK_5_48]: Symbolpack_5_48,
	// general_basic
	[SymbolType.SYMBOLPACK_1_01]: Symbolpack_1_01,
	[SymbolType.SYMBOLPACK_1_02]: Symbolpack_1_02,
	[SymbolType.SYMBOLPACK_1_02_COPY]: Symbolpack_1_02_Copy,
	[SymbolType.SYMBOLPACK_1_06]: Symbolpack_1_06,
	[SymbolType.SYMBOLPACK_1_08]: Symbolpack_1_08,
	[SymbolType.SYMBOLPACK_1_09]: Symbolpack_1_09,
	[SymbolType.SYMBOLPACK_1_10]: Symbolpack_1_10,
	[SymbolType.SYMBOLPACK_1_12]: Symbolpack_1_12,
	[SymbolType.SYMBOLPACK_1_13]: Symbolpack_1_13,
	[SymbolType.SYMBOLPACK_1_14]: Symbolpack_1_14,
	[SymbolType.SYMBOLPACK_1_15]: Symbolpack_1_15,
	[SymbolType.SYMBOLPACK_1_16]: Symbolpack_1_16,
	[SymbolType.SYMBOLPACK_1_17]: Symbolpack_1_17,
	[SymbolType.SYMBOLPACK_1_18]: Symbolpack_1_18,
	[SymbolType.SYMBOLPACK_1_19]: Symbolpack_1_19,
	[SymbolType.SYMBOLPACK_1_20]: Symbolpack_1_20,
	[SymbolType.SYMBOLPACK_1_21]: Symbolpack_1_21,
	[SymbolType.SYMBOLPACK_1_22]: Symbolpack_1_22,
	[SymbolType.SYMBOLPACK_1_23]: Symbolpack_1_23,
	[SymbolType.SYMBOLPACK_1_24]: Symbolpack_1_24,
	[SymbolType.SYMBOLPACK_1_25]: Symbolpack_1_25,
	[SymbolType.SYMBOLPACK_1_26]: Symbolpack_1_26,
	[SymbolType.SYMBOLPACK_1_27]: Symbolpack_1_27,
	[SymbolType.SYMBOLPACK_1_28]: Symbolpack_1_28,
	[SymbolType.SYMBOLPACK_1_29]: Symbolpack_1_29,
	[SymbolType.SYMBOLPACK_1_30]: Symbolpack_1_30,
	[SymbolType.SYMBOLPACK_1_32]: Symbolpack_1_32,
	[SymbolType.SYMBOLPACK_1_32_COPY]: Symbolpack_1_32_Copy,
	[SymbolType.SYMBOLPACK_1_33]: Symbolpack_1_33,
	[SymbolType.SYMBOLPACK_1_34]: Symbolpack_1_34,
	[SymbolType.SYMBOLPACK_1_35]: Symbolpack_1_35,
	[SymbolType.SYMBOLPACK_1_36]: Symbolpack_1_36,
	[SymbolType.SYMBOLPACK_1_37]: Symbolpack_1_37,
	[SymbolType.SYMBOLPACK_1_38]: Symbolpack_1_38,
	[SymbolType.SYMBOLPACK_1_39]: Symbolpack_1_39,
	[SymbolType.SYMBOLPACK_1_40]: Symbolpack_1_40,
	[SymbolType.SYMBOLPACK_1_41]: Symbolpack_1_41,
	[SymbolType.SYMBOLPACK_1_42]: Symbolpack_1_42,
	[SymbolType.SYMBOLPACK_1_43]: Symbolpack_1_43,
	[SymbolType.SYMBOLPACK_1_44]: Symbolpack_1_44,
	[SymbolType.SYMBOLPACK_1_45]: Symbolpack_1_45,
	[SymbolType.SYMBOLPACK_1_46]: Symbolpack_1_46,
	[SymbolType.SYMBOLPACK_1_47]: Symbolpack_1_47,
	[SymbolType.SYMBOLPACK_1_48]: Symbolpack_1_48,
	[SymbolType.SYMBOLPACK_1_49]: Symbolpack_1_49,
	[SymbolType.SYMBOLPACK_1_50]: Symbolpack_1_50,
	[SymbolType.SYMBOLPACK_1_51]: Symbolpack_1_51,
	[SymbolType.SYMBOLPACK_1_52]: Symbolpack_1_52,
	[SymbolType.SYMBOLPACK_1_53]: Symbolpack_1_53,
	[SymbolType.SYMBOLPACK_1_54]: Symbolpack_1_54,
	[SymbolType.SYMBOLPACK_1_55]: Symbolpack_1_55,
	[SymbolType.SYMBOLPACK_1_57]: Symbolpack_1_57,
	[SymbolType.SYMBOLPACK_1_58]: Symbolpack_1_58,
	[SymbolType.SYMBOLPACK_1_59]: Symbolpack_1_59,
	[SymbolType.SYMBOLPACK_1_65]: Symbolpack_1_65,
	[SymbolType.SYMBOLPACK_1_88]: Symbolpack_1_88,
	[SymbolType.SYMBOLPACK_1_89]: Symbolpack_1_89,
	[SymbolType.SYMBOLPACK_1_109]: Symbolpack_1_109,
	[SymbolType.SYMBOLPACK_1_110]: Symbolpack_1_110,
	[SymbolType.SYMBOLPACK_1_112]: Symbolpack_1_112,
	[SymbolType.SYMBOLPACK_1_113]: Symbolpack_1_113,
	[SymbolType.SYMBOLPACK_1_114]: Symbolpack_1_114,
	[SymbolType.SYMBOLPACK_1_115]: Symbolpack_1_115,
	[SymbolType.SYMBOLPACK_1_116]: Symbolpack_1_116,
	[SymbolType.SYMBOLPACK_1_117]: Symbolpack_1_117,
	[SymbolType.SYMBOLPACK_1_118]: Symbolpack_1_118,
	[SymbolType.SYMBOLPACK_1_119]: Symbolpack_1_119,
	[SymbolType.SYMBOLPACK_1_120]: Symbolpack_1_120,
	[SymbolType.SYMBOLPACK_1_121]: Symbolpack_1_121,
	[SymbolType.SYMBOLPACK_1_122]: Symbolpack_1_122,
	[SymbolType.SYMBOLPACK_1_123]: Symbolpack_1_123,
	[SymbolType.SYMBOLPACK_1_124]: Symbolpack_1_124,
	[SymbolType.SYMBOLPACK_1_125]: Symbolpack_1_125,
	[SymbolType.SYMBOLPACK_1_127]: Symbolpack_1_127,
	[SymbolType.SYMBOLPACK_1_128]: Symbolpack_1_128,
	[SymbolType.SYMBOLPACK_1_129]: Symbolpack_1_129,
	[SymbolType.SYMBOLPACK_1_130]: Symbolpack_1_130,
	[SymbolType.SYMBOLPACK_1_132]: Symbolpack_1_132,
	[SymbolType.SYMBOLPACK_1_133]: Symbolpack_1_133,
	[SymbolType.SYMBOLPACK_1_134]: Symbolpack_1_134,
	[SymbolType.SYMBOLPACK_1_135]: Symbolpack_1_135,
	[SymbolType.SYMBOLPACK_1_136]: Symbolpack_1_136,
	[SymbolType.SYMBOLPACK_1_137]: Symbolpack_1_137,
	[SymbolType.SYMBOLPACK_1_138]: Symbolpack_1_138,
	[SymbolType.SYMBOLPACK_1_139]: Symbolpack_1_139,
	[SymbolType.SYMBOLPACK_1_140]: Symbolpack_1_140,
	[SymbolType.SYMBOLPACK_1_141]: Symbolpack_1_141,
	[SymbolType.SYMBOLPACK_1_146]: Symbolpack_1_146,
	[SymbolType.SYMBOLPACK_1_147]: Symbolpack_1_147,
	[SymbolType.SYMBOLPACK_1_148]: Symbolpack_1_148,
	[SymbolType.SYMBOLPACK_1_149]: Symbolpack_1_149,
	[SymbolType.SYMBOLPACK_1_150]: Symbolpack_1_150,
	[SymbolType.SYMBOLPACK_1_151]: Symbolpack_1_151,
	[SymbolType.SYMBOLPACK_1_152]: Symbolpack_1_152,
	[SymbolType.SYMBOLPACK_1_153]: Symbolpack_1_153,
	[SymbolType.SYMBOLPACK_1_154]: Symbolpack_1_154,
	[SymbolType.SYMBOLPACK_1_155]: Symbolpack_1_155,
	[SymbolType.SYMBOLPACK_1_156]: Symbolpack_1_156,
	[SymbolType.SYMBOLPACK_1_157]: Symbolpack_1_157,
	[SymbolType.SYMBOLPACK_1_158]: Symbolpack_1_158,
	[SymbolType.SYMBOLPACK_1_159]: Symbolpack_1_159,
	[SymbolType.SYMBOLPACK_1_160]: Symbolpack_1_160,
	[SymbolType.SYMBOLPACK_1_161]: Symbolpack_1_161,
	[SymbolType.SYMBOLPACK_1_162]: Symbolpack_1_162,
	[SymbolType.SYMBOLPACK_1_163]: Symbolpack_1_163,
	[SymbolType.SYMBOLPACK_1_164]: Symbolpack_1_164,
	[SymbolType.SYMBOLPACK_1_165]: Symbolpack_1_165,
	[SymbolType.SYMBOLPACK_1_166]: Symbolpack_1_166,
	[SymbolType.SYMBOLPACK_1_167]: Symbolpack_1_167,
	[SymbolType.SYMBOLPACK_1_168]: Symbolpack_1_168,
	[SymbolType.SYMBOLPACK_1_169]: Symbolpack_1_169,
	[SymbolType.SYMBOLPACK_1_170]: Symbolpack_1_170,
	[SymbolType.SYMBOLPACK_1_171]: Symbolpack_1_171,
	[SymbolType.SYMBOLPACK_1_172]: Symbolpack_1_172,
	[SymbolType.SYMBOLPACK_1_173]: Symbolpack_1_173,
	[SymbolType.SYMBOLPACK_1_174]: Symbolpack_1_174,
	[SymbolType.SYMBOLPACK_1_175]: Symbolpack_1_175,
	[SymbolType.SYMBOLPACK_1_176]: Symbolpack_1_176,
	[SymbolType.SYMBOLPACK_1_177]: Symbolpack_1_177,
	[SymbolType.SYMBOLPACK_1_178]: Symbolpack_1_178,
	[SymbolType.SYMBOLPACK_1_179]: Symbolpack_1_179,
	[SymbolType.SYMBOLPACK_1_180]: Symbolpack_1_180,
	// plumbing_symbols2
	[SymbolType.SYMBOLPACK_1_90]: Symbolpack_1_90,
	[SymbolType.SYMBOLPACK_1_91]: Symbolpack_1_91,
	[SymbolType.SYMBOLPACK_1_92]: Symbolpack_1_92,
	[SymbolType.SYMBOLPACK_1_93]: Symbolpack_1_93,
	[SymbolType.SYMBOLPACK_1_94]: Symbolpack_1_94,
	[SymbolType.SYMBOLPACK_1_95]: Symbolpack_1_95,
	[SymbolType.SYMBOLPACK_1_96]: Symbolpack_1_96,
	[SymbolType.SYMBOLPACK_1_142]: Symbolpack_1_142,
	[SymbolType.SYMBOLPACK_1_143]: Symbolpack_1_143,
	[SymbolType.SYMBOLPACK_1_144]: Symbolpack_1_144,
	[SymbolType.SYMBOLPACK_1_145]: Symbolpack_1_145,
	// fire_general
	[SymbolType.SYMBOLPACK_1_106]: Symbolpack_1_106,
	[SymbolType.SYMBOLPACK_1_107]: Symbolpack_1_107,
	[SymbolType.SYMBOLPACK_1_108]: Symbolpack_1_108,
	// electric_general
	[SymbolType.SYMBOLPACK_1_60]: Symbolpack_1_60,
	[SymbolType.SYMBOLPACK_1_61]: Symbolpack_1_61,
	[SymbolType.SYMBOLPACK_1_62]: Symbolpack_1_62,
	[SymbolType.SYMBOLPACK_1_63]: Symbolpack_1_63,
	[SymbolType.SYMBOLPACK_1_64]: Symbolpack_1_64,
	[SymbolType.SYMBOLPACK_1_66]: Symbolpack_1_66,
	[SymbolType.SYMBOLPACK_1_67]: Symbolpack_1_67,
	[SymbolType.SYMBOLPACK_1_68]: Symbolpack_1_68,
	[SymbolType.SYMBOLPACK_1_69]: Symbolpack_1_69,
	[SymbolType.SYMBOLPACK_1_70]: Symbolpack_1_70,
	[SymbolType.SYMBOLPACK_1_71]: Symbolpack_1_71,
	[SymbolType.SYMBOLPACK_1_72]: Symbolpack_1_72,
	[SymbolType.SYMBOLPACK_1_73]: Symbolpack_1_73,
	[SymbolType.SYMBOLPACK_1_74]: Symbolpack_1_74,
	[SymbolType.SYMBOLPACK_1_75]: Symbolpack_1_75,
	[SymbolType.SYMBOLPACK_1_76]: Symbolpack_1_76,
	[SymbolType.SYMBOLPACK_1_77]: Symbolpack_1_77,
	[SymbolType.SYMBOLPACK_1_78]: Symbolpack_1_78,
	[SymbolType.SYMBOLPACK_1_79]: Symbolpack_1_79,
	[SymbolType.SYMBOLPACK_1_80]: Symbolpack_1_80,
	[SymbolType.SYMBOLPACK_1_81]: Symbolpack_1_81,
	[SymbolType.SYMBOLPACK_1_82]: Symbolpack_1_82,
	[SymbolType.SYMBOLPACK_1_83]: Symbolpack_1_83,
	[SymbolType.SYMBOLPACK_1_84]: Symbolpack_1_84,
	[SymbolType.SYMBOLPACK_1_86]: Symbolpack_1_86,
	[SymbolType.SYMBOLPACK_1_87]: Symbolpack_1_87,
	[SymbolType.SYMBOLPACK_1_97]: Symbolpack_1_97,
	[SymbolType.SYMBOLPACK_1_98]: Symbolpack_1_98,
	[SymbolType.SYMBOLPACK_1_99]: Symbolpack_1_99,
	[SymbolType.SYMBOLPACK_1_100]: Symbolpack_1_100,
	[SymbolType.SYMBOLPACK_1_101]: Symbolpack_1_101,
	[SymbolType.SYMBOLPACK_1_102]: Symbolpack_1_102,
	[SymbolType.SYMBOLPACK_1_104]: Symbolpack_1_104,
	[SymbolType.SYMBOLPACK_1_105]: Symbolpack_1_105,
	// general_numbers
	[SymbolType.SYMBOLPACK_2_01]: Symbolpack_2_01,
	[SymbolType.SYMBOLPACK_2_02]: Symbolpack_2_02,
	[SymbolType.SYMBOLPACK_2_03]: Symbolpack_2_03,
	[SymbolType.SYMBOLPACK_2_04]: Symbolpack_2_04,
	[SymbolType.SYMBOLPACK_2_05]: Symbolpack_2_05,
	[SymbolType.SYMBOLPACK_2_06]: Symbolpack_2_06,
	[SymbolType.SYMBOLPACK_2_07]: Symbolpack_2_07,
	[SymbolType.SYMBOLPACK_2_08]: Symbolpack_2_08,
	[SymbolType.SYMBOLPACK_2_09]: Symbolpack_2_09,
	[SymbolType.SYMBOLPACK_2_10]: Symbolpack_2_10,
	[SymbolType.SYMBOLPACK_3_01]: Symbolpack_3_01,
	[SymbolType.SYMBOLPACK_3_02]: Symbolpack_3_02,
	[SymbolType.SYMBOLPACK_3_03]: Symbolpack_3_03,
	[SymbolType.SYMBOLPACK_3_04]: Symbolpack_3_04,
	[SymbolType.SYMBOLPACK_3_05]: Symbolpack_3_05,
	[SymbolType.SYMBOLPACK_3_06]: Symbolpack_3_06,
	[SymbolType.SYMBOLPACK_3_07]: Symbolpack_3_07,
	[SymbolType.SYMBOLPACK_3_08]: Symbolpack_3_08,
	[SymbolType.SYMBOLPACK_3_09]: Symbolpack_3_09,
	[SymbolType.SYMBOLPACK_3_10]: Symbolpack_3_10,
	[SymbolType.SYMBOLPACK_4_01]: Symbolpack_4_01,
	[SymbolType.SYMBOLPACK_4_02]: Symbolpack_4_02,
	[SymbolType.SYMBOLPACK_4_03]: Symbolpack_4_03,
	[SymbolType.SYMBOLPACK_4_04]: Symbolpack_4_04,
	[SymbolType.SYMBOLPACK_4_05]: Symbolpack_4_05,
	[SymbolType.SYMBOLPACK_4_06]: Symbolpack_4_06,
	[SymbolType.SYMBOLPACK_4_07]: Symbolpack_4_07,
	[SymbolType.SYMBOLPACK_4_08]: Symbolpack_4_08,
	[SymbolType.SYMBOLPACK_4_09]: Symbolpack_4_09,
	[SymbolType.SYMBOLPACK_4_10]: Symbolpack_4_10,
	[SymbolType.SYMBOLPACK_5_01]: Symbolpack_5_01,
	[SymbolType.SYMBOLPACK_5_02]: Symbolpack_5_02,
	[SymbolType.SYMBOLPACK_5_03]: Symbolpack_5_03,
	[SymbolType.SYMBOLPACK_5_04]: Symbolpack_5_04,
	[SymbolType.SYMBOLPACK_5_05]: Symbolpack_5_05,
	[SymbolType.SYMBOLPACK_5_06]: Symbolpack_5_06,
	[SymbolType.SYMBOLPACK_5_07]: Symbolpack_5_07,
	[SymbolType.SYMBOLPACK_5_08]: Symbolpack_5_08,
	[SymbolType.SYMBOLPACK_5_09]: Symbolpack_5_09,
	[SymbolType.SYMBOLPACK_5_10]: Symbolpack_5_10,
	[SymbolType.SYMBOLPACK_6_01]: Symbolpack_6_01,
	[SymbolType.SYMBOLPACK_6_02]: Symbolpack_6_02,
	[SymbolType.SYMBOLPACK_6_03]: Symbolpack_6_03,
	[SymbolType.SYMBOLPACK_6_04]: Symbolpack_6_04,
	[SymbolType.SYMBOLPACK_6_05]: Symbolpack_6_05,
	[SymbolType.SYMBOLPACK_6_06]: Symbolpack_6_06,
	[SymbolType.SYMBOLPACK_6_07]: Symbolpack_6_07,
	[SymbolType.SYMBOLPACK_6_08]: Symbolpack_6_08,
	[SymbolType.SYMBOLPACK_6_09]: Symbolpack_6_09,
	[SymbolType.SYMBOLPACK_6_10]: Symbolpack_6_10,

	[SymbolType.SYMBOLPACK_3_SOLID_01]: Symbolpack_3_solid_01,
	[SymbolType.SYMBOLPACK_3_SOLID_02]: Symbolpack_3_solid_02,
	[SymbolType.SYMBOLPACK_3_SOLID_03]: Symbolpack_3_solid_03,
	[SymbolType.SYMBOLPACK_3_SOLID_04]: Symbolpack_3_solid_04,
	[SymbolType.SYMBOLPACK_3_SOLID_05]: Symbolpack_3_solid_05,
	[SymbolType.SYMBOLPACK_3_SOLID_06]: Symbolpack_3_solid_06,
	[SymbolType.SYMBOLPACK_3_SOLID_07]: Symbolpack_3_solid_07,
	[SymbolType.SYMBOLPACK_3_SOLID_08]: Symbolpack_3_solid_08,
	[SymbolType.SYMBOLPACK_3_SOLID_09]: Symbolpack_3_solid_09,
	[SymbolType.SYMBOLPACK_3_SOLID_10]: Symbolpack_3_solid_10,
	[SymbolType.SYMBOLPACK_4_SOLID_01]: Symbolpack_4_solid_01,
	[SymbolType.SYMBOLPACK_4_SOLID_02]: Symbolpack_4_solid_02,
	[SymbolType.SYMBOLPACK_4_SOLID_03]: Symbolpack_4_solid_03,
	[SymbolType.SYMBOLPACK_4_SOLID_04]: Symbolpack_4_solid_04,
	[SymbolType.SYMBOLPACK_4_SOLID_05]: Symbolpack_4_solid_05,
	[SymbolType.SYMBOLPACK_4_SOLID_06]: Symbolpack_4_solid_06,
	[SymbolType.SYMBOLPACK_4_SOLID_07]: Symbolpack_4_solid_07,
	[SymbolType.SYMBOLPACK_4_SOLID_08]: Symbolpack_4_solid_08,
	[SymbolType.SYMBOLPACK_4_SOLID_09]: Symbolpack_4_solid_09,
	[SymbolType.SYMBOLPACK_4_SOLID_10]: Symbolpack_4_solid_10,
	[SymbolType.SYMBOLPACK_5_SOLID_01]: Symbolpack_5_solid_01,
	[SymbolType.SYMBOLPACK_5_SOLID_02]: Symbolpack_5_solid_02,
	[SymbolType.SYMBOLPACK_5_SOLID_03]: Symbolpack_5_solid_03,
	[SymbolType.SYMBOLPACK_5_SOLID_04]: Symbolpack_5_solid_04,
	[SymbolType.SYMBOLPACK_5_SOLID_05]: Symbolpack_5_solid_05,
	[SymbolType.SYMBOLPACK_5_SOLID_06]: Symbolpack_5_solid_06,
	[SymbolType.SYMBOLPACK_5_SOLID_07]: Symbolpack_5_solid_07,
	[SymbolType.SYMBOLPACK_5_SOLID_08]: Symbolpack_5_solid_08,
	[SymbolType.SYMBOLPACK_5_SOLID_09]: Symbolpack_5_solid_09,
	[SymbolType.SYMBOLPACK_5_SOLID_10]: Symbolpack_5_solid_10,
	[SymbolType.SYMBOLPACK_6_SOLID_01]: Symbolpack_6_solid_01,
	[SymbolType.SYMBOLPACK_6_SOLID_02]: Symbolpack_6_solid_02,
	[SymbolType.SYMBOLPACK_6_SOLID_03]: Symbolpack_6_solid_03,
	[SymbolType.SYMBOLPACK_6_SOLID_04]: Symbolpack_6_solid_04,
	[SymbolType.SYMBOLPACK_6_SOLID_05]: Symbolpack_6_solid_05,
	[SymbolType.SYMBOLPACK_6_SOLID_06]: Symbolpack_6_solid_06,
	[SymbolType.SYMBOLPACK_6_SOLID_07]: Symbolpack_6_solid_07,
	[SymbolType.SYMBOLPACK_6_SOLID_08]: Symbolpack_6_solid_08,
	[SymbolType.SYMBOLPACK_6_SOLID_09]: Symbolpack_6_solid_09,
	[SymbolType.SYMBOLPACK_6_SOLID_10]: Symbolpack_6_solid_10,

	[SymbolType.SYMBOLPACK_NEW_232]: Symbolpack_New_232,
	[SymbolType.SYMBOLPACK_NEW_233]: Symbolpack_New_233,
	[SymbolType.SYMBOLPACK_NEW_234]: Symbolpack_New_234,
	[SymbolType.SYMBOLPACK_NEW_235]: Symbolpack_New_235,
	[SymbolType.SYMBOLPACK_NEW_236]: Symbolpack_New_236,
	[SymbolType.SYMBOLPACK_NEW_237]: Symbolpack_New_237,
	[SymbolType.SYMBOLPACK_NEW_201]: Symbolpack_New_201,
	[SymbolType.SYMBOLPACK_NEW_202]: Symbolpack_New_202,
	[SymbolType.SYMBOLPACK_NEW_203]: Symbolpack_New_203,
	[SymbolType.SYMBOLPACK_NEW_204]: Symbolpack_New_204,
	[SymbolType.SYMBOLPACK_NEW_205]: Symbolpack_New_205,
	[SymbolType.SYMBOLPACK_NEW_206]: Symbolpack_New_206,
	[SymbolType.SYMBOLPACK_NEW_207]: Symbolpack_New_207,
	// emergency_lighting
	[SymbolType.SYMBOLPACK_NEW_196]: Symbolpack_New_196,
	[SymbolType.SYMBOLPACK_NEW_197]: Symbolpack_New_197,
	[SymbolType.SYMBOLPACK_NEW_198]: Symbolpack_New_198,
	[SymbolType.SYMBOLPACK_NEW_199]: Symbolpack_New_199,
	[SymbolType.SYMBOLPACK_NEW_200]: Symbolpack_New_200,
	[SymbolType.SYMBOLPACK_NEW_191]: Symbolpack_New_191,
	[SymbolType.SYMBOLPACK_NEW_192]: Symbolpack_New_192,
	[SymbolType.SYMBOLPACK_NEW_193]: Symbolpack_New_193,
	[SymbolType.SYMBOLPACK_NEW_194]: Symbolpack_New_194,
	[SymbolType.SYMBOLPACK_NEW_195]: Symbolpack_New_195,
	[SymbolType.SYMBOLPACK_NEW_227]: Symbolpack_New_227,
	[SymbolType.SYMBOLPACK_NEW_275]: Symbolpack_New_275,
	[SymbolType.SYMBOLPACK_NEW_282]: Symbolpack_New_282,
	[SymbolType.SYMBOLPACK_NEW_283]: Symbolpack_New_283,
	[SymbolType.SYMBOLPACK_NEW_333]: Symbolpack_New_333,
	[SymbolType.SYMBOLPACK_NEW_334]: Symbolpack_New_334,
	[SymbolType.SYMBOLPACK_NEW_335]: Symbolpack_New_335,
	[SymbolType.SYMBOLPACK_NEW_336]: Symbolpack_New_336,
	[SymbolType.SYMBOLPACK_NEW_337]: Symbolpack_New_337,
	[SymbolType.SYMBOLPACK_NEW_338]: Symbolpack_New_338,
	[SymbolType.SYMBOLPACK_NEW_339]: Symbolpack_New_339,
	[SymbolType.SYMBOLPACK_NEW_340]: Symbolpack_New_340,
	[SymbolType.SYMBOLPACK_NEW_341]: Symbolpack_New_341,
	[SymbolType.SYMBOLPACK_NEW_342]: Symbolpack_New_342,
	[SymbolType.SYMBOLPACK_NEW_343]: Symbolpack_New_343,
	[SymbolType.SYMBOLPACK_NEW_344]: Symbolpack_New_344,
	[SymbolType.SYMBOLPACK_NEW_345]: Symbolpack_New_345,
	// lighting
	[SymbolType.SYMBOLPACK_NEW_260]: Symbolpack_New_260,
	[SymbolType.SYMBOLPACK_NEW_261]: Symbolpack_New_261,
	[SymbolType.SYMBOLPACK_NEW_262]: Symbolpack_New_262,
	[SymbolType.SYMBOLPACK_NEW_263]: Symbolpack_New_263,
	[SymbolType.SYMBOLPACK_NEW_264]: Symbolpack_New_264,
	[SymbolType.SYMBOLPACK_NEW_265]: Symbolpack_New_265,
	[SymbolType.SYMBOLPACK_NEW_266]: Symbolpack_New_266,
	// plumbing
	[SymbolType.SYMBOLPACK_NEW_267]: Symbolpack_New_267,
	[SymbolType.SYMBOLPACK_NEW_268]: Symbolpack_New_268,
	[SymbolType.SYMBOLPACK_NEW_269]: Symbolpack_New_269,
	[SymbolType.SYMBOLPACK_NEW_270]: Symbolpack_New_270,
	[SymbolType.SYMBOLPACK_NEW_271]: Symbolpack_New_271,
	[SymbolType.SYMBOLPACK_NEW_272]: Symbolpack_New_272,
	[SymbolType.SYMBOLPACK_NEW_273]: Symbolpack_New_273,
	[SymbolType.SYMBOLPACK_NEW_274]: Symbolpack_New_274,
	[SymbolType.SYMBOLPACK_NEW_276]: Symbolpack_New_276,
	[SymbolType.SYMBOLPACK_NEW_277]: Symbolpack_New_277,
	[SymbolType.SYMBOLPACK_NEW_278]: Symbolpack_New_278,
	[SymbolType.SYMBOLPACK_NEW_279]: Symbolpack_New_279,
	[SymbolType.SYMBOLPACK_NEW_280]: Symbolpack_New_280,
	[SymbolType.SYMBOLPACK_NEW_281]: Symbolpack_New_281,
	[SymbolType.SYMBOLPACK_NEW_284]: Symbolpack_New_284,
	[SymbolType.SYMBOLPACK_NEW_285]: Symbolpack_New_285,
	[SymbolType.SYMBOLPACK_NEW_286]: Symbolpack_New_286,
	[SymbolType.SYMBOLPACK_NEW_287]: Symbolpack_New_287,
	[SymbolType.SYMBOLPACK_NEW_288]: Symbolpack_New_288,
	[SymbolType.SYMBOLPACK_NEW_289]: Symbolpack_New_289,
	[SymbolType.SYMBOLPACK_NEW_290]: Symbolpack_New_290,
	[SymbolType.SYMBOLPACK_NEW_291]: Symbolpack_New_291,
	[SymbolType.SYMBOLPACK_NEW_292]: Symbolpack_New_292,
	[SymbolType.SYMBOLPACK_NEW_293]: Symbolpack_New_293,
	[SymbolType.SYMBOLPACK_NEW_294]: Symbolpack_New_294,
	[SymbolType.SYMBOLPACK_NEW_295]: Symbolpack_New_295,
	[SymbolType.SYMBOLPACK_NEW_296]: Symbolpack_New_296,
	[SymbolType.SYMBOLPACK_NEW_297]: Symbolpack_New_297,
	[SymbolType.SYMBOLPACK_NEW_298]: Symbolpack_New_298,
	[SymbolType.SYMBOLPACK_NEW_299]: Symbolpack_New_299,
	[SymbolType.SYMBOLPACK_NEW_300]: Symbolpack_New_300,
	[SymbolType.SYMBOLPACK_NEW_301]: Symbolpack_New_301,
	[SymbolType.SYMBOLPACK_NEW_302]: Symbolpack_New_302,
	[SymbolType.SYMBOLPACK_NEW_303]: Symbolpack_New_303,
	[SymbolType.SYMBOLPACK_NEW_304]: Symbolpack_New_304,
	[SymbolType.SYMBOLPACK_NEW_305]: Symbolpack_New_305,
	[SymbolType.SYMBOLPACK_NEW_306]: Symbolpack_New_306,
	[SymbolType.SYMBOLPACK_NEW_307]: Symbolpack_New_307,
	[SymbolType.SYMBOLPACK_NEW_308]: Symbolpack_New_308,
	[SymbolType.SYMBOLPACK_NEW_309]: Symbolpack_New_309,
	[SymbolType.SYMBOLPACK_NEW_310]: Symbolpack_New_310,
	[SymbolType.SYMBOLPACK_NEW_311]: Symbolpack_New_311,
	[SymbolType.SYMBOLPACK_NEW_312]: Symbolpack_New_312,
	[SymbolType.SYMBOLPACK_NEW_313]: Symbolpack_New_313,
	[SymbolType.SYMBOLPACK_NEW_314]: Symbolpack_New_314,
	[SymbolType.SYMBOLPACK_NEW_315]: Symbolpack_New_315,
	[SymbolType.SYMBOLPACK_NEW_316]: Symbolpack_New_316,
	[SymbolType.SYMBOLPACK_NEW_317]: Symbolpack_New_317,
	[SymbolType.SYMBOLPACK_NEW_318]: Symbolpack_New_318,
	[SymbolType.SYMBOLPACK_NEW_319]: Symbolpack_New_319,
	[SymbolType.SYMBOLPACK_NEW_320]: Symbolpack_New_320,
	[SymbolType.SYMBOLPACK_NEW_321]: Symbolpack_New_321,
	[SymbolType.SYMBOLPACK_NEW_322]: Symbolpack_New_322,
	[SymbolType.SYMBOLPACK_NEW_324]: Symbolpack_New_324,
	[SymbolType.SYMBOLPACK_NEW_325]: Symbolpack_New_325,
	[SymbolType.SYMBOLPACK_NEW_326]: Symbolpack_New_326,
	[SymbolType.SYMBOLPACK_NEW_327]: Symbolpack_New_327,
	[SymbolType.SYMBOLPACK_NEW_328]: Symbolpack_New_328,
	[SymbolType.SYMBOLPACK_NEW_329]: Symbolpack_New_329,
	[SymbolType.SYMBOLPACK_NEW_330]: Symbolpack_New_330,
	[SymbolType.SYMBOLPACK_NEW_331]: Symbolpack_New_331,
	[SymbolType.SYMBOLPACK_NEW_332]: Symbolpack_New_332,
	// power
	[SymbolType.SYMBOLPACK_NEW_208]: Symbolpack_New_208,
	[SymbolType.SYMBOLPACK_NEW_209]: Symbolpack_New_209,
	[SymbolType.SYMBOLPACK_NEW_210]: Symbolpack_New_210,
	[SymbolType.SYMBOLPACK_NEW_211]: Symbolpack_New_211,
	[SymbolType.SYMBOLPACK_NEW_212]: Symbolpack_New_212,
	[SymbolType.SYMBOLPACK_NEW_213]: Symbolpack_New_213,
	[SymbolType.SYMBOLPACK_NEW_214]: Symbolpack_New_214,
	[SymbolType.SYMBOLPACK_NEW_215]: Symbolpack_New_215,
	[SymbolType.SYMBOLPACK_NEW_216]: Symbolpack_New_216,
	// switch board schematics
	[SymbolType.SYMBOLPACK_NEW_238]: Symbolpack_New_238,
	[SymbolType.SYMBOLPACK_NEW_239]: Symbolpack_New_239,
	[SymbolType.SYMBOLPACK_NEW_240]: Symbolpack_New_240,
	[SymbolType.SYMBOLPACK_NEW_241]: Symbolpack_New_241,
	[SymbolType.SYMBOLPACK_NEW_242]: Symbolpack_New_242,
	[SymbolType.SYMBOLPACK_NEW_243]: Symbolpack_New_243,
	[SymbolType.SYMBOLPACK_NEW_244]: Symbolpack_New_244,
	[SymbolType.SYMBOLPACK_NEW_245]: Symbolpack_New_245,
	[SymbolType.SYMBOLPACK_NEW_246]: Symbolpack_New_246,
	[SymbolType.SYMBOLPACK_NEW_247]: Symbolpack_New_247,
	[SymbolType.SYMBOLPACK_NEW_248]: Symbolpack_New_248,
	[SymbolType.SYMBOLPACK_NEW_249]: Symbolpack_New_249,
	[SymbolType.SYMBOLPACK_NEW_250]: Symbolpack_New_250,
	[SymbolType.SYMBOLPACK_NEW_251]: Symbolpack_New_251,
	[SymbolType.SYMBOLPACK_NEW_252]: Symbolpack_New_252,
	[SymbolType.SYMBOLPACK_NEW_253]: Symbolpack_New_253,
	[SymbolType.SYMBOLPACK_NEW_254]: Symbolpack_New_254,
	[SymbolType.SYMBOLPACK_NEW_323]: Symbolpack_New_323,
};
