export function paramsSerializer(params: any): string {
	let result = "";
	Object.keys(params).forEach(key => {
		if (params[key] !== undefined) {
			result += `${key}=${encodeURIComponent(params[key])}&`;
		}
	});
	return result.substr(0, result.length - 1);
}

export async function wait(milliseconds: number) {
	return new Promise<void>(resolve => {
		setTimeout(() => {
			resolve();
		}, milliseconds)
	})
}
