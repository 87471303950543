import {createSlice} from "@reduxjs/toolkit";
import {initialState, reducers} from "../../../../../base-konva/features/image/baseImageSlice";
import {PlanUploadRootState} from "../../planUploadStore";

export const imageSlice = createSlice({
	name: "plan-upload-image",
	initialState,
	reducers
})

export const planUploadImageReducer = imageSlice.reducer;
export const planUploadImageActions = imageSlice.actions;

export const selectPlanUploadImages = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.image.images;
export const selectPlanUploadActiveImageId = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.image.activeImageId;