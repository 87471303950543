import {useState} from "react";
import {EditorTool} from "../../../../models/enums";
import {getDefaultStyle} from "../../../../utils";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../models/interfaces";

type ModalState = {
	type: MaterialAddableTool
	measurementStyle: MeasurementStyle
	height: number | undefined
	defaultDrop: number | undefined
	modalTitle: string | undefined
}

export function useOneOffData() {
	const [name, setName] = useState<string | undefined>();
	const [state, setState] = useState<ModalState>({
		type: EditorTool.COUNT,
		measurementStyle: getDefaultStyle(EditorTool.COUNT),
		height: undefined,
		defaultDrop: undefined,
		modalTitle: undefined
	});

	const changeItemType = (type: MaterialAddableTool) => {
		setState(prevState => ({...prevState, type, measurementStyle: getDefaultStyle(type)}))
	};

	const setStyleValue = (newStyle: MeasurementStyle) => {
		setState(prevState => ({...prevState, measurementStyle: newStyle}))
	}

	const setHeightValue = (newHeight: number | undefined) => {
		setState(prevState => ({...prevState, height: newHeight}))
	}

	const setDefaultDropValue = (newDefaultDrop: number | undefined) => {
		setState(prevState => ({...prevState, defaultDrop: newDefaultDrop}))
	}

	const nameInput = {
		name, setName
	}

	const stylesContainer = {
		type: state.type,
		changeItemType,
		measurementStyleSelector: {
			measurementType: state.type,
			style: state.measurementStyle,
			onChange: setStyleValue
		},
		heightInput: state.type === EditorTool.VOLUME ? {
			value: state.height,
			onChange: setHeightValue
		} : undefined,
		defaultDropInput: state.type === EditorTool.LENGTH ? {
			value: state.defaultDrop,
			onChange: setDefaultDropValue
		} : undefined
	}

	return {
		state,
		setState,
		nameInput,
		stylesContainer
	}
}