import React from "react";
import {IconProps} from "../../models/interfaces";
import IconUnselectable from "./IconUnselectable";

const IconRotateCw: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : "#000000";
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<IconUnselectable handleClick={handleClick}>
			<svg width={32} height={32} viewBox="0 0 32 32" fill={"none"} style={{cursor: cursor}}>
				<path
					d="M21.6569 10.3431C19.7356 8.42192 17.0006 7.63704 14.4076 8.16383L16 6.57143L15.0574 5.62885L11.7574 8.92892L15.0574 12.229L16 11.2864L14.2732 9.55966C16.5508 8.94801 19.0116 9.58299 20.7143 11.2857C23.3235 13.8949 23.3235 18.105 20.7143 20.7143C18.1051 23.3235 13.8949 23.3235 11.2857 20.7143L10.3431 21.6568C13.4615 24.7752 18.5385 24.7752 21.6569 21.6568C24.7752 18.5385 24.7752 13.4615 21.6569 10.3431Z"
					fill={fill}
				/>
				<path
					d="M16 23.3245C20.0507 23.3245 23.3345 20.0423 23.3345 15.9935C23.3345 11.9448 20.0507 8.6626 16 8.6626"
					stroke={fill}
					strokeWidth={1.3}
				/>
				<path
					d="M8.66553 16.0001C8.66553 20.0489 11.9493 23.3311 16 23.3311"
					stroke={fill}
					strokeWidth={1.3}
				/>
			</svg>
		</IconUnselectable>
	);
};

export default IconRotateCw