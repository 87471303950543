import {ProjectExportResultApiDto} from "./restModels";
import SimproApiError from "./SimproApiError";

export default class ProjectExportResult {
	constructor(
		public readonly errors: SimproApiError[],
	) {}

	public static fromJson(json: ProjectExportResultApiDto): ProjectExportResult {
		const errors = json.unhandleableErrors.map(error => SimproApiError.fromJson(error));
		return new ProjectExportResult(errors);
	}
}
