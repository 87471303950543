import React from "react";
import SearchBarWrapper from "../../../../../../../../components/ui/SearchBarWrapper";
import SearchBar from "../../../../../../../../components/ui/SearchBar";

type SearchProps = {
	isSearching: boolean,
	onSearch: (search: string) => void
}
export const Search: React.FC<SearchProps> = function(props) {
	const {isSearching, onSearch} = props
	return (
		<SearchBarWrapper>
			<SearchBar isSearching={isSearching}
					   onSearch={onSearch}
					   minQueryLength={1}/>
		</SearchBarWrapper>
	)
}