import React from "react";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./errorPage.scss";
import Modal from "../components/modal/Modal";
import ModalTitle from "../components/modal/ModalTitle";
import {noop} from "../utils/CallbackUtils";

const ErrorPage: React.FC = () => {

	const location = useLocation();
	const {t} = useTranslation();

	const getErrorMessage = (locationSearch: string) => {
		switch (locationSearch) {
			case "?login":
				return "login.generalLoginError"
			case "?direct":
				return "login.directAccessError"
			default:
				return "login.errorMessage"
		}
	}

	return (
		<div className={"error-page"}>
			{location.search === "?cookies" ?
				<Modal isOpen={true} onClose={noop} customClass={"error-page_cookie-modal"}>
					<ModalTitle>{t("login.cookiesEnableErrorTitle")}</ModalTitle>
					{t("login.cookiesEnableError")}
				</Modal> :
				<div className={"error-page_heading"}>
					{t(getErrorMessage(location.search))}
				</div>
			}
		</div>
	);
}

export {ErrorPage}
