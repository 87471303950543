import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlanUploadRootState} from "../../planUploadStore";
import {initialState, reducers} from "../../../../../base-konva/features/scale/baseScaleSlice";
import {ResetPlanUploadScaleActionPayload} from "../../types";


const planUploadScaleSlice = createSlice({
	name: 'plan-upload-scale',
	initialState: initialState,
	reducers: {
		...reducers,
		reset: (_, {payload}: PayloadAction<ResetPlanUploadScaleActionPayload>) => ({
			scaleConfig: {
				...initialState.scaleConfig,
				scale: payload.planScale,
				infoUnitFactor: payload.unitFactor
			}
		}),
		resetScalePoints: (state) => {
			state.scaleConfig.scalePoints = [];
		}
	}
})

export const planUploadScaleReducer = planUploadScaleSlice.reducer;
export const planUploadScaleActions = planUploadScaleSlice.actions;

export const selectPlanUploadScaleConfig = (state: PlanUploadRootState) => state.planUploadScale.scaleConfig;
export const selectPlanUploadScalePoints = (state: PlanUploadRootState) => state.planUploadScale.scaleConfig.scalePoints;
export const selectPlanUploadInputLength = (state: PlanUploadRootState) => state.planUploadScale.scaleConfig.inputValue;
