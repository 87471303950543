import React from "react";
import {Line} from "react-konva";
import {scaleLineDefaultStyle} from "../../constants";

interface ScaleLineProps {
	points: number[],
}

const ScaleLine: React.FC<ScaleLineProps> = ({points}) => <Line points={points} {...scaleLineDefaultStyle}/>;

export default ScaleLine
