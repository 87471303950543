import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {PlanUploadData} from "../../../features/plan-upload/types";
import TemplatePagesPreviewModal from "./TemplatePagesPreviewModal";
import {DEFAULT_PDF_RASTERIZATION_DPI, getPlanPagesToUpload} from "../../../utils/planTemplateUtils";
import {pages, useNavigator} from "../../../../navigator";

interface EditPlanTemplateModalProps {
	refreshPlanTemplates: () => void,
	onClose: () => void,
	planTemplate: PlanUploadData,
	previewFileData: { previewSources: string[], displayedFilename: string },
}

const EditPlanTemplateModal: React.FC<EditPlanTemplateModalProps> = ({
	onClose,
	previewFileData,
	refreshPlanTemplates,
	planTemplate,
}) => {
	const {t} = useTranslation();
	const {previewSources, displayedFilename} = previewFileData;
	const {projectId} = useParams<{ projectId: string }>();
	const [dpiValue, setDpiValue] = useState<number>(planTemplate.dpi ?? DEFAULT_PDF_RASTERIZATION_DPI)
	const {planUploadData: {initEditorPlanUploadData}} = useProjectDetailContext()
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {navigateTo} = useNavigator();
	const alreadyExistingPages = planTemplate?.pages.map(p => p.pageNumber);


	const onNext = async (selectedPagesNumbers: number[]) => {
		if (displayedFilename !== "") {
			try {
				const planPages = await getPlanPagesToUpload(projectId, planTemplate.isPdf, planTemplate.id,
					planTemplate.attachment.id, selectedPagesNumbers, dpiValue)

				const existingTemplatePages = planTemplate.pages.map(page => {
					return ({
						attachmentId: page.attachmentId,
						pageNumber: page.pageNumber,
						pageScaleRatio: page.pageScaleRatio,
						planScale: page.planScale,
						title: page.title,
						existsExternally: true
					})
				})

				initEditorPlanUploadData({
					attachment: planTemplate.attachment,
					name: displayedFilename,
					pages: [...existingTemplatePages, ...planPages],
					id: planTemplate.id,
					isPdf: planTemplate.isPdf,
					fullPageCount: previewSources.length,
					dpi: dpiValue
				})

				navigateTo(pages.planUploadPage(type, projectId))
				onClose();
			}
			catch (err) {
				LoggerService.logError(err);
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.planTemplates.planTemplateEditionErrorDesc")
				)
			}
			finally {
				refreshPlanTemplates()
			}
		}
	}

	const onDpiChange = (value: number) => {
		setDpiValue(value)
	}

	return (
		<TemplatePagesPreviewModal
			onClose={onClose}
			dpiValue={dpiValue}
			isTemplatePdf={planTemplate.isPdf}
			onDpiInputChange={onDpiChange}
			unselectablePagesNumbers={alreadyExistingPages}
			onBackButtonClick={onClose}
			backButtonLabel={t("common.buttons.cancel")}
			onNext={onNext} previewFileData={previewFileData}/>
	);
};

export default EditPlanTemplateModal;