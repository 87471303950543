import {useDispatch, useSelector} from "react-redux";
import {getId} from "../../../../../../utils";
import {DrawingItemType} from "../../../../../base-konva/enums";
import {useKeyboardOnEditor} from "../../../../../project-drawings/features/editor/hooks/useKeyboardOnEditor";
import {planUploadPenActions} from "../pen";
import {planUploadTextActions} from "../text/planUploadTextSlice";
import {planUploadImageActions} from "../image/planUploadImageSlice";
import {selectPlanUploadSelectionState} from "../view/planUploadViewSlice";
import {usePlanUploadSelectSetter} from "../../hooks/planUploadSelect";

export function usePlanUploadDeleteActiveItem() {
	const dispatch = useDispatch();

	const selection = useSelector(selectPlanUploadSelectionState);
	const selectActions = usePlanUploadSelectSetter();
	const canDeleteSelectedItem = selection.length > 0;

	function deleteActiveItem() {
		if (canDeleteSelectedItem) {
			const removalId = getId();
			selection.forEach(singleSelection => {
				switch (singleSelection.type) {
					case DrawingItemType.PEN:
						dispatch(planUploadPenActions.removeSelectionItems({
							id: singleSelection.itemId,
							subIds: singleSelection.subItemIds,
							actionId: removalId
						}))
						break;
					case DrawingItemType.TEXT:
						dispatch(planUploadTextActions.removeSelectionItems({
							id: singleSelection.itemId,
							subIds: singleSelection.subItemIds,
							actionId: removalId
						}))
						break;
					case DrawingItemType.IMAGE:
						dispatch(planUploadImageActions.removeSelectionItems({
							id: singleSelection.itemId,
							actionId: removalId
						}))
						break;
				}
			});
			selectActions.clearSelection();
		}
	}

	useKeyboardOnEditor({
		when: canDeleteSelectedItem,
		keyUpMap: {
			"Delete": deleteActiveItem,
			"Backspace": deleteActiveItem
		}
	});
	return {
		canDeleteActiveItem: canDeleteSelectedItem, deleteActiveItem
	}
}
