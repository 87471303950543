import {SectionCreationResultApiDto} from "./restModels";
import SimproApiError from "./SimproApiError";

export default class SectionCreationResult {
	constructor(
		public readonly errors: SimproApiError[],
		public readonly id: string
	) {}

	public static fromJson(json: SectionCreationResultApiDto): SectionCreationResult {
		const errors = json.unhandleableErrors.map(error => SimproApiError.fromJson(error));
		return new SectionCreationResult(errors, json.id);
	}
}
