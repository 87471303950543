import {CostCenterApiDto} from "./restModels";
import {CostCenterDrawing} from "./CostCenterDrawing";

export class CostCenter {
	constructor(
		public readonly id: string,
		public readonly name: string,
		public readonly deletedExternal: boolean,
		public readonly drawings: CostCenterDrawing[]) {}

	public static fromJson(json: CostCenterApiDto): CostCenter {
		return new CostCenter(json.id, json.name, json.deletedExternal, json.drawings.map(CostCenterDrawing.fromJson));
	}

	hasCompletedDrawings() {
		return this.drawings.some(drawing => drawing.versions.some(version => version.isCompleted()));
	}

	getAllLatestCompletedDrawingVersions() {
		return this.drawings.flatMap(drawing => {
			const latestCompletedVersion = drawing.getLatestCompletedVersion()
			if (!latestCompletedVersion) {
				return []
			}
			return [{
				versionId: latestCompletedVersion.id,
				drawingType: drawing.drawingType
			}]
		});
	}

	containsDrawingVersion(drawingVersionId: string): boolean {
		return this.drawings.some(drawing => drawing.versions.some(version => version.id === drawingVersionId))
	}
}
