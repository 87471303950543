import {useTemplatesData} from "../../hooks/useTemplatesData";
import {useTranslation} from "react-i18next";
import {useMaterialModalContext} from "../../MaterialModalProvider";
import {Status} from "../../../../../../../../../models/enums";
import Spinner from "../../../../../../../../../components/Spinner";
import {Search} from "../Search";
import TableOverlaySpinner from "../../../../../../../../../components/ui/TableOverlaySpinner";
import {TemplateBreadcrumbs} from "./TemplateBreadcrumbs";
import {GroupsTable} from "./GroupsTable";
import {TemplatesTable} from "./TemplatesTable";
import Pagination from "../../../../../../../../../components/ui/Pagination";
import ModalButtons from "../../../../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../../../../components/ui/Button";
import React from "react";

type TemplateBrowserProps = {
	browserData: ReturnType<typeof useTemplatesData>["browserData"],
	breadcrumbs: ReturnType<typeof useTemplatesData>["breadcrumbs"]
}

export function TemplateBrowser(props: TemplateBrowserProps) {
	const {t} = useTranslation()
	const {closeModal} = useMaterialModalContext()
	const {browserData, breadcrumbs} = props

	return (
		<>
			{browserData.loadStatus === Status.LOADING ? <Spinner/> : null}
			{browserData.loadStatus === Status.SUCCESS ?
				<>
					<div className="material-modal_container_content">
						<Search {...browserData.search} />
						<TableOverlaySpinner {...browserData.overlay} >
							<TemplateBreadcrumbs {...breadcrumbs}/>
							<GroupsTable {...browserData.groupsTable} />
							<TemplatesTable {...browserData.templatesTable}/>
							<Pagination {...browserData.pagination} />
						</TableOverlaySpinner>
					</div>
					<div className="material-modal_container_footer">
						<div/>
						<ModalButtons>
							<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={closeModal}/>
						</ModalButtons>
					</div>
				</> : null
			}
		</>
	)
}