import {IRect, Vector2d} from "konva/types/types";
import {EditorTool, XAxisAnchor, YAxisAnchor} from "./models/enums";
import {DashPatternType, ImageData, PenGroup, PointDragState, TextGroup} from "../../../base-konva/types";
import {
	AreaFragment,
	AreaMeasurement,
	CountMeasurement,
	Deduction,
	DrawingItem,
	EditorLine,
	EditorPoint,
	LengthMeasurement,
	ResizeAnchor,
	ResizeStageDimensions,
	ShapeDragState,
	Size
} from "./models/editor";
import {
	ARC_LINE_TENSION,
	dashPatterConfig,
	defaultAreaStyle,
	defaultCountStyle,
	defaultLineStyle,
	mimeTypeMap
} from "./constants";
import {FileType} from "../../../../models/enums";
import {MaterialAddableTool, MeasurementStyle} from "../../../../models/interfaces";
import Flatten from "@flatten-js/core";
import {Bezier} from "bezier-js";
import {getId} from "../../../../utils";
import {DrawingItemType} from "../../../base-konva/enums";

function getScrollbarWidth() {
	const scrollDiv = document.createElement("div")
	scrollDiv.style.width = "100px"
	scrollDiv.style.height = "100px"
	scrollDiv.style.overflow = "scroll"
	scrollDiv.style.position = "absolute"
	scrollDiv.style.top = "-9999px"
	document.body.appendChild(scrollDiv)
	const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
	document.body.removeChild(scrollDiv)
	return scrollbarWidth + 2
}

export const scrollBarWidth = getScrollbarWidth()

export function midpoint(pointA: Vector2d, pointB: Vector2d): Vector2d {
	return {
		x: (pointA.x + pointB.x) / 2,
		y: (pointA.y + pointB.y) / 2,
	}
}

export function distance(from: Vector2d, to: Vector2d, arcPointPosition?: Vector2d) {
	if (arcPointPosition) {
		const bezier = Bezier.quadraticFromPoints(
			{x: from.x, y: from.y},
			{x: arcPointPosition?.x, y: arcPointPosition?.y},
			{x: to.x, y: to.y},
			ARC_LINE_TENSION
		)
		return bezier.length()
	}
	else {
		return Math.sqrt(distanceSquared(from, to))
	}
}

function distanceSquared(from: Vector2d, to: Vector2d) {
	const dx = from.x - to.x, dy = from.y - to.y;
	return dx * dx + dy * dy;
}

export function add(v1: Vector2d, v2: Vector2d): Vector2d {
	return {x: v1.x + v2.x, y: v1.y + v2.y}
}

export function lineAngle(pointA: Vector2d, pointB: Vector2d) {
	const rad = Math.atan2(pointB.y - pointA.y, pointB.x - pointA.x)
	return 180 * rad / Math.PI;
}

export function getEditorLine(id: string, from: EditorPoint, to: EditorPoint, arcPointPosition?: Vector2d): EditorLine {
	return {
		id, from, to, arcPointPosition,
		distance: distance(from.position, to.position, arcPointPosition),
		angle: lineAngle(from.position, to.position),
		center: midpoint(from.position, to.position)
	}
}

export function getPointDraggingLines(currentLines: EditorLine[], pointDragState: PointDragState): EditorLine[] {
	const lines: EditorLine[] = []

	for (let line of currentLines) {
		if (pointDragState.dragPointId !== line.from.id &&
			pointDragState.dragPointId !== line.to.id) {
			lines.push(line)
		}
		else {
			if (pointDragState.dragPointCurrentLocation && pointDragState.dragPointId) {
				const from: EditorPoint = {id: line.from.id, position: {x: 0, y: 0}}
				const to: EditorPoint = {id: line.to.id, position: {x: 0, y: 0}}

				if (pointDragState.dragPointId === line.to.id) {
					from.position = line.from.position
					to.position = pointDragState.dragPointCurrentLocation
				}
				else {
					from.position = pointDragState.dragPointCurrentLocation
					to.position = line.to.position
				}
				lines.push(getEditorLine(line.id, from, to, line.arcPointPosition))
			}
		}
	}

	return lines
}

export function getShapeDraggingLines(currentLines: EditorLine[], shapeDragState: ShapeDragState): EditorLine[] {
	const lines: EditorLine[] = []

	if (shapeDragState.isDragging && shapeDragState.positionDelta) {
		const {x: xDelta, y: yDelta} = shapeDragState.positionDelta

		for (let line of currentLines) {
			const from: EditorPoint = {
				id: line.from.id,
				position: {
					x: line.from.position.x + xDelta,
					y: line.from.position.y + yDelta,
				}
			}
			const to: EditorPoint = {
				id: line.to.id,
				position: {
					x: line.to.position.x + xDelta,
					y: line.to.position.y + yDelta
				}
			}

			let arcPointPosition: Vector2d | undefined;
			if (line.arcPointPosition) {
				arcPointPosition = {
					x: line.arcPointPosition.x + xDelta,
					y: line.arcPointPosition.y + yDelta
				}
			}
			lines.push(getEditorLine(line.id, from, to, arcPointPosition))
		}
	}
	else {
		lines.push(...currentLines)
	}

	return lines
}

export function getDashTypePointArray(dashType: DashPatternType, strokeWidth: number): number[] {
	return dashPatterConfig[dashType].konvaDashArray.map(
		(value, index) => {
			if (index % 2 === 1) {
				return value + strokeWidth;
			}
			return value;
		})
}

export function setActiveOnTopLayer<T extends { id: string }>(items: T[], activeId: string | undefined): T[] {
	const activeItem = items.filter(a => a.id === activeId);
	return items.filter(a => a.id !== activeId).concat(activeItem);
}

export function canCloseArea(area: AreaMeasurement): boolean {
	const fragment = area.areaFragments.find(f => f.id === area.activeAreaFragmentId)
	if (fragment) {
		return !(fragment.closed || fragment.lines.length <= 1)
	}
	return false;
}

export function canCloseDeduction(deduction: Deduction): boolean {
	return !(deduction.closed || deduction.lines.length <= 1)
}

export function canFinishLength(length: LengthMeasurement): boolean {
	const fragment = length.lengthFragments.find(f => f.id === length.activeLengthFragmentId)
	if (fragment) {
		return (fragment.activePointId !== null || fragment.lastMouseUpPosition !== null) && fragment.lines.length >= 1;
	}
	return false;
}

export function isLengthFinished(length: LengthMeasurement): boolean {
	return length.lengthFragments.every(fragment =>
		fragment.activePointId === null &&
		fragment.lastMouseUpPosition === null &&
		fragment.lines.length >= 1
	)
}

export function getDelta(anchor: ResizeAnchor, resizeStageDimensions: ResizeStageDimensions): Vector2d {
	const {oldWidth, oldHeight, newWidth, newHeight} = resizeStageDimensions;
	const xDifference = Math.abs(oldWidth - newWidth);
	const yDifference = Math.abs(oldHeight - newHeight);
	const deltaVector: Vector2d = {x: 0, y: 0}

	switch (anchor.xAxisAnchor) {
		case XAxisAnchor.LEFT:
			deltaVector.x = 0;
			break;
		case XAxisAnchor.CENTER:
			if (newWidth > oldWidth) {
				deltaVector.x = (xDifference / 2);
			}
			else {
				deltaVector.x = -(xDifference / 2);
			}
			break;
		case XAxisAnchor.RIGHT:
			if (newWidth > oldWidth) {
				deltaVector.x = xDifference;
			}
			else {
				deltaVector.x = -xDifference;
			}
			break;
	}
	switch (anchor.yAxisAnchor) {
		case YAxisAnchor.TOP:
			deltaVector.y = 0;
			break;
		case YAxisAnchor.CENTER:
			if (newHeight > oldHeight) {
				deltaVector.y = (yDifference / 2);
			}
			else {
				deltaVector.y = -(yDifference / 2);
			}
			break;
		case YAxisAnchor.BOTTOM:
			if (newHeight > oldHeight) {
				deltaVector.y = yDifference;
			}
			else {
				deltaVector.y = -yDifference;
			}
			break;
	}

	return deltaVector;
}

export function getMovedEditorLine(line: EditorLine, delta: Vector2d): EditorLine {
	return {
		...line,
		from: {
			...line.from,
			position: add(line.from.position, delta)
		},
		to: {
			...line.to,
			position: add(line.to.position, delta)
		},
		center: add(line.center, delta),
		arcPointPosition: line.arcPointPosition ? add(line.arcPointPosition, delta) : undefined
	}
}

/**
 * Moves Konva specific point vector array in format [x1,y1,x2,y2...]
 */
export function getMovedPoints(points: number[], delta: Vector2d): number[] {
	const result: number[] = [];

	if (points.length >= 2) {
		for (let i = 1; i < points.length; i += 2) {
			const vector = {x: points[i - 1], y: points[i]}
			const movedPoint = add(vector, delta)
			result.push(movedPoint.x, movedPoint.y)
		}
	}

	return result;
}

export function getAcceptString(types: FileType[]) {
	const mimeTypes = types.map(type => mimeTypeMap[type])
	return [...Array.from(new Set(mimeTypes))].join(", ")
}

export function getAllowedFormats(types: FileType[]) {
	return types
		.map(type => `.${type}`.toLowerCase())
		.join(", ")
}

export function transformDrawingItems(drawingItems: DrawingItem[]) {
	const countMeasurements: CountMeasurement[] = []
	const lengthMeasurements: LengthMeasurement[] = []
	const areaMeasurements: AreaMeasurement[] = []
	const penGroups: PenGroup[] = []
	const textGroups: TextGroup[] = []
	const images: ImageData[] = []

	drawingItems.forEach(drawingItem => {
		if (drawingItem.type === DrawingItemType.COUNT) {
			countMeasurements.push(drawingItem.data as CountMeasurement)
		}
		if (drawingItem.type === DrawingItemType.LENGTH) {
			lengthMeasurements.push(drawingItem.data as LengthMeasurement)
		}
		if (drawingItem.type === DrawingItemType.AREA) {
			areaMeasurements.push(drawingItem.data as AreaMeasurement)
		}
		if (drawingItem.type === DrawingItemType.PEN) {
			penGroups.push(drawingItem.data as PenGroup)
		}
		if (drawingItem.type === DrawingItemType.TEXT) {
			textGroups.push(drawingItem.data as TextGroup)
		}
		if (drawingItem.type === DrawingItemType.IMAGE) {
			images.push(drawingItem.data as ImageData)
		}
	})

	return {
		countMeasurements,
		lengthMeasurements,
		areaMeasurements,
		penGroups,
		textGroups,
		images,
	}
}

/**
 * Validate stage position after <x,y> change, keeps stage always visible in viewport
 * @param newPosition
 * @param stageClientRect
 * @param viewportSize
 */
export function getStageBorderPosition(
	newPosition: Vector2d,
	stageClientRect: IRect,
	viewportSize: Size
): Vector2d {
	let newX = newPosition.x
	let newY = newPosition.y

	if (newX + stageClientRect.width / 2 < viewportSize.width * .5) {
		newX = viewportSize.width * .5 - stageClientRect.width / 2
	}
	if (newY + stageClientRect.height / 2 < viewportSize.height * .5) {
		newY = viewportSize.height * .5 - stageClientRect.height / 2
	}
	if (newX - stageClientRect.width / 2 > viewportSize.width * .5) {
		newX = viewportSize.width * .5 + stageClientRect.width / 2
	}
	if (newY - stageClientRect.height / 2 > viewportSize.height * .5) {
		newY = viewportSize.height * .5 + stageClientRect.height / 2
	}

	return {x: newX, y: newY}
}

export const getDefaultStyle = (type: MaterialAddableTool): MeasurementStyle => {
	switch (type) {
		case EditorTool.COUNT:
			return defaultCountStyle;
		case EditorTool.LENGTH:
			return defaultLineStyle;
		case EditorTool.AREA:
		case EditorTool.VOLUME:
			return defaultAreaStyle;
	}
};

function getPolygon(lines: EditorLine[]): Flatten.Polygon {
	const getPoints = function(): [number, number][] {
		const fragmentLines = lines
		const positions = [fragmentLines[0].from.position]
		for (let fragmentLine of fragmentLines) {
			positions.push(fragmentLine.to.position)
		}
		return positions.map(p => ([p.x, p.y]))
	}
	return new Flatten.Polygon([getPoints()])
}

function getAreaFragmentClippedPoint(
	firstPoint: Flatten.Point,
	pointPosition: Flatten.Point,
	areaFragment: AreaFragment
): Flatten.Point | null {
	const polygon = getPolygon(areaFragment.lines)
	if (!polygon.contains(firstPoint)) {
		return null
	}
	if (firstPoint.equalTo(pointPosition)) {
		return firstPoint
	}

	const segment = new Flatten.Segment(firstPoint, pointPosition)

	if (polygon.contains(segment)) {
		return pointPosition
	}
	else {
		const lineIntersection = polygon.intersect(segment)
		if (lineIntersection.length <= 0) {
			throw new Error("Point not inside polygon, and cannot determine its intersections")
		}

		const minDistanceInfo = {
			point: lineIntersection[0],
			minDistance: lineIntersection[0].distanceTo(firstPoint)[0]
		}
		lineIntersection.forEach(intersectionPoint => {
			const distanceToFirstPoint = firstPoint.distanceTo(intersectionPoint)
			if (distanceToFirstPoint[0] < minDistanceInfo.minDistance) {
				minDistanceInfo.point = intersectionPoint
				minDistanceInfo.minDistance = distanceToFirstPoint[0]
			}
		})
		return minDistanceInfo.point
	}

}

function polygonToLines(polygon: Flatten.Polygon): EditorLine[][] {
	const lines: EditorLine[][] = []
	const faces = Array.from(polygon.faces.values())
	for (let face of faces) {
		if (face instanceof Flatten.Face) {
			const faceLines: EditorLine[] = []
			face.edges.forEach(edge => {
				faceLines.push(getEditorLine(
					getId(),
					{id: getId(), position: {x: edge.start.x, y: edge.start.y}},
					{id: getId(), position: {x: edge.end.x, y: edge.end.y}}
				))
			})

			//connect
			faceLines.forEach((value, index) => {
				const isFirst = index === 0
				const isLast = index === faceLines.length - 1
				if (!isFirst) {
					const prev = faceLines[index - 1]
					value.from.id = prev.to.id
				}
				if (isLast) {
					const first = faceLines[0]
					value.to.position = first.from.position;
					value.to.id = first.from.id
				}
			})

			lines.push(faceLines)
		}
	}
	return lines
}

function intersectPolygons(polygon1: Flatten.Polygon, polygon2: Flatten.Polygon): Flatten.Polygon {
	const [polygon1Face] = Array.from(polygon1.faces.values())
	const [polygon2Face] = Array.from(polygon2.faces.values())
	if (polygon1Face instanceof Flatten.Face &&
		polygon2Face instanceof Flatten.Face
	) {
		if (polygon1Face.orientation() !== polygon2Face.orientation()) {
			polygon1 = polygon1.reverse()
		}
	}

	return Flatten.BooleanOperations.intersect(polygon1, polygon2)
}

function unifyPolygons(polygon1: Flatten.Polygon, polygon2: Flatten.Polygon): Flatten.Polygon {

	const [polygon1Face] = Array.from(polygon1.faces.values())
	const [polygon2Face] = Array.from(polygon2.faces.values())
	if (polygon1Face instanceof Flatten.Face &&
		polygon2Face instanceof Flatten.Face
	) {
		if (polygon1Face.orientation() !== polygon2Face.orientation()) {
			polygon1 = polygon1.reverse()
		}
	}

	return Flatten.BooleanOperations.unify(polygon1, polygon2)
}

export const FlattenUtils = {
	getPolygon,
	getAreaFragmentClippedPoint,
	unifyPolygons,
	subtract: Flatten.BooleanOperations.subtract,
	intersectPolygons,
	polygonToLines
}
