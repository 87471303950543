import React from "react";
import {ActiveIconProps} from "../../models/interfaces";
import IconSelectable from "./IconSelectable";

const IconSnapAngle: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {
	return (
		<IconSelectable
			onClick={onClick}
			active={active}
			disabled={disabled}
			paths={["M7.5475075,21.6185075 C7.5126325,21.3424325 7.4154325,21.0861575 7.2694075,20.8645325 L11.2582075,16.7492825 C12.8622325,17.8742825 13.8817075,19.6628075 14.0270575,21.6185075 L7.5475075,21.6185075 Z M25.3076575,20.0619575 C24.3997825,20.0619575 23.6525575,20.7410075 23.5416325,21.6185075 L14.9056825,21.6185075 C14.7562825,19.4256575 13.6488325,17.4085325 11.8778575,16.1078075 L16.5038575,11.3353325 C16.7612575,11.4797825 17.0578075,11.5632575 17.3739325,11.5632575 C18.3578575,11.5632575 19.1554825,10.7656325 19.1554825,9.7817075 C19.1554825,8.7975575 18.3578575,7.9999325 17.3739325,7.9999325 C16.3900075,7.9999325 15.5923825,8.7975575 15.5923825,9.7817075 C15.5923825,10.1291075 15.6934075,10.4522075 15.8653075,10.7262575 L10.8802075,15.8706575 L6.6137575,20.2685075 C6.3651325,20.1371075 6.0823075,20.0619575 5.7814825,20.0619575 C4.7975575,20.0619575 3.9999325,20.8595825 3.9999325,21.8435075 C3.9999325,22.8274325 4.7975575,23.6250575 5.7814825,23.6250575 C6.5343325,23.6250575 7.1769325,23.1575075 7.4377075,22.4975825 L23.6514325,22.4975825 C23.9122075,23.1575075 24.5548075,23.6250575 25.3076575,23.6250575 C26.2915825,23.6250575 27.0892075,22.8274325 27.0892075,21.8435075 C27.0892075,20.8595825 26.2915825,20.0619575 25.3076575,20.0619575 L25.3076575,20.0619575 Z"]}/>
	);
};

export default IconSnapAngle
