import {AxiosInstance} from "axios";
import User from "../models/User";
import UserSummary from "../models/UserSummary";
import CompanyCostCenter from "../models/CompanyCostCenter";

export default class UserApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchMe(): Promise<User> {
		const url = `/api/me`;
		return this.axiosInstance.get(url).then(resp => User.fromJson(resp.data));
	}

	fetchUsers(): Promise<UserSummary[]> {
		const url = "/api/users";
		return this.axiosInstance.get(url).then(resp => resp.data.map(UserSummary.fromJson))
	}

	fetchCompanyCostCenters(): Promise<CompanyCostCenter[]> {
		const url = "/api/users/me/company/cost_centers"
		return this.axiosInstance.get(url).then(resp => resp.data.map(CompanyCostCenter.fromJson))
	}
}
