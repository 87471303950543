import {PlanTemplatePreviewDataApiDto} from "./restModels";
import {AsyncRequestStatus} from "./enums";
import LoggerService from "../services/LoggerService";

export class PlanTemplatePreviewData {
	constructor(
		public readonly pageNumber: number,
		public readonly status: keyof typeof AsyncRequestStatus,
		public readonly attachmentId?: string,
		public readonly error?: string,
	) {
	}

	public static fromJson(json: PlanTemplatePreviewDataApiDto): PlanTemplatePreviewData {
		const status = AsyncRequestStatus[json.status]
		if (status === undefined) {
			const error = new Error(`Unknown creation status: ${json.status}`);
			LoggerService.logError(error);
			throw error;
		}
		return new PlanTemplatePreviewData(
			json.pageNumber,
			status,
			json.attachmentId,
			json.error
		);
	}
}