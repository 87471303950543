import React from "react";

const IconAccept: React.FC = () => {
	return (
		<svg width="16px" height="16px" viewBox="0 0 16 16">
			<path d="M 12.3090909 3.5 5.56363636 10.461194 2.89090909 7.70298507 2 8.62238806 5.56363636 12.3 13.2 4.41940299z"
				  fill="#ffffff"/>
		</svg>
	);
};

export default IconAccept
