import React, {useState} from "react";
import "./treeDrawing.scss"
import {CostCenterDrawing} from "../../../../models/CostCenterDrawing";
import {DrawingType, Status, TreeDrawingModal, TreeVersionModal} from "../../../../models/enums";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import ConfirmationModal from "../../../../components/ui/ConfirmationModal";
import useDeleteDrawing from "../../../../hooks/useDeleteDrawing";
import Section from "../../../../models/Section";
import MoveDrawingModal from "./modals/MoveDrawingModal";
import ChangeDrawingNameModal from "./modals/ChangeDrawingNameModal";
import DropdownItem from "../../../../components/ui/DropdownItem";
import {CostCenter} from "../../../../models/CostCenter";
import {IconTakeoff} from "../../../../components/icons/IconTakeoff";
import {getLatestDrawingVersion} from "../../../../utils/DrawingVersionUtils";
import DrawingInfoModal from "./modals/DrawingInfoModal";
import {useProjectDetailContext} from "../../providers/ProjectDetailProvider";
import {IconAsBuilt} from "../../../../components/icons/IconAsBuilt";
import OptionsDropdown from "../../../../components/ui/OptionsDropdown";
import CompleteVersionModal from "./modals/CompleteVersionModal";
import TimePresenter from "../../../../components/ui/TimePresenter";
import DatePresenter from "../../../../components/ui/DatePresenter";
import CopyCostCenterDrawingVersionModal from "./modals/CopyCostCenterDrawingVersionModal";
import {INITIAL_VERSION_ID} from "../../../project-drawings/features/editor/constants";
import {pages, useNavigator} from "../../../navigator";

interface ITreeDrawingProps {
	drawing: CostCenterDrawing,
	section: Section,
	costCenter: CostCenter,
}

const TreeDrawing: React.FC<ITreeDrawingProps> = ({drawing, section, costCenter}) => {

	const costCenterId = costCenter.id
	const {navigateTo} = useNavigator()
	const location = useLocation();
	const [modal, setModal] = useState<TreeDrawingModal | TreeVersionModal>(TreeDrawingModal.NONE);
	const {
		refreshProjectTreeAfterSimproUpdate,
		onlyOneCostCenterInProject,
		userData: {users},
		planTemplates: {loadPlanTemplates},
		treeData
	} = useProjectDetailContext()
	const {isDrawingSelected, selectDrawing} = treeData.selectionData
	const {projectId} = useParams<{ projectId: string }>();
	const {t} = useTranslation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const existingDrawingNames: string[] = costCenter.drawings.map(drawing => drawing.name);
	const latestVersion = getLatestDrawingVersion(drawing.versions)
	const drawingType = drawing.drawingType
	const _changeSelection = () => {
		selectDrawing(section.id, costCenterId, drawing.id);
	};

	const deleteDrawingSuccess = () => {
		loadPlanTemplates()
		refreshProjectTreeAfterSimproUpdate();
	};

	const openMoveModal = () => setModal(TreeDrawingModal.MOVE);
	const openCopyModal = () => setModal(TreeDrawingModal.COPY);
	const openDeleteModal = () => setModal(TreeDrawingModal.DELETE);
	const openRenameModal = () => setModal(TreeDrawingModal.RENAME);
	const openInfoModal = () => setModal(TreeDrawingModal.INFO);
	const handleCompleteVersion = () => setModal(TreeVersionModal.COMPLETE);
	const handleClose = () => setModal(TreeDrawingModal.NONE);

	const {deleteStatus, deleteDrawing} = useDeleteDrawing(drawing.id, drawingType, deleteDrawingSuccess)

	const lastEditingUser = users.find(user => user.id === drawing.modifiedBy)?.name ?? "Unknown"

	const onVersionEdit = () => {
		let versionId;

		if (drawing.versions.length === 0) {
			versionId = INITIAL_VERSION_ID
		}
		else if (latestVersion) {
			versionId = latestVersion.id
		}

		if (versionId) {
			navigateTo(pages.projectDrawingPage(
				type,
				projectId,
				section.id,
				costCenterId,
				drawing.id,
				versionId,
			))
		}
	};

	const renderVersionLabel = () => {
		let versionName = t("projects.details.tree.initialVersion")
		const isCompleted = latestVersion?.isCompleted();
		if (drawing.versions.length !== 0) {
			versionName = isCompleted ? "COMPLETED" : `V${latestVersion?.version.getVersionLabel()}`
		}

		const clsVersion = [
			"tree-drawing_clickable-container_version-container_version",
			isCompleted && "completed",
		].filter(Boolean).join(" ");

		return (
			<div className={"tree-drawing_clickable-container_version-container"}>
				<span className={clsVersion}>{versionName}</span>
				<span className={"tree-drawing_clickable-container_version-container_item"}>
					{`${"- " + t("projects.details.tree.drawing.lastEditedBy")} ${lastEditingUser} -`}
				</span>
				<span className={"tree-drawing_clickable-container_version-container_item"}>
					<TimePresenter date={drawing.modifiedAt}/>
				</span>
				<span className={"tree-drawing_clickable-container_version-container_item"}>
					<DatePresenter date={drawing.modifiedAt}/>
				</span>
			</div>
		)
	}

	return (
		<div className={"tree-drawing"}>
			<input
				type={"checkbox"}
				className={"tree-drawing_checkbox"}
				checked={isDrawingSelected(section.id, costCenterId, drawing.id)}
				onChange={_changeSelection}
			/>
			<div className="tree-drawing_clickable-container" onClick={onVersionEdit}>
				<div className={"tree-drawing_clickable-container_takeoff-icon"}>
					{drawingType === DrawingType.AS_BUILT ? <IconAsBuilt/> : <IconTakeoff/>}
				</div>
				<div className="tree-drawing_clickable-container_name" title={drawing.name}>
					{drawing.name}
				</div>
				{renderVersionLabel()}
			</div>
			<OptionsDropdown>
				{(drawingType === DrawingType.TAKE_OFF && latestVersion) &&
					<DropdownItem label={t("common.menus.copy")} onClick={openCopyModal}/>}
				<DropdownItem label={t("common.menus.changeName")} onClick={openRenameModal}
							  disabled={drawing.locked}/>
				<DropdownItem label={t("common.menus.delete")} onClick={openDeleteModal}
							  disabled={drawing.locked}/>
				{!onlyOneCostCenterInProject ? (
					<DropdownItem label={t("common.menus.move")} onClick={openMoveModal}
								  disabled={drawing.locked}/>
				) : null}
				<DropdownItem label={t("common.menus.details")} onClick={openInfoModal}/>
				{(latestVersion && !latestVersion.isCompleted()) ? (
					<DropdownItem label={t("common.menus.complete")} onClick={handleCompleteVersion}/>
				) : null}
			</OptionsDropdown>
			{modal === TreeDrawingModal.DELETE ? (
				<ConfirmationModal
					isOpen={true}
					isLoading={deleteStatus === Status.LOADING}
					text={t(drawingType === DrawingType.TAKE_OFF ?
						`projects.details.tree.deleteDrawingConfirmationModalQuestion.take_off` :
						`projects.details.tree.deleteDrawingConfirmationModalQuestion.as_built`)}
					onConfirm={deleteDrawing}
					onClose={handleClose}
				/>
			) : null}
			{(modal === TreeDrawingModal.COPY && latestVersion) ? (
				<CopyCostCenterDrawingVersionModal
					drawing={drawing}
					drawingVersionId={latestVersion.id}
					onSuccess={refreshProjectTreeAfterSimproUpdate}
					onClose={handleClose}
				/>
			) : null}
			{modal === TreeDrawingModal.MOVE ? (
				<MoveDrawingModal
					isOpen={true}
					costCenterId={costCenterId}
					drawingId={drawing.id}
					drawingType={drawingType}
					onClose={handleClose}
					onSuccess={refreshProjectTreeAfterSimproUpdate}
				/>
			) : null}
			{modal === TreeDrawingModal.RENAME ? (
				<ChangeDrawingNameModal
					isOpen={true}
					existingDrawingNames={existingDrawingNames}
					drawingId={drawing.id}
					drawingName={drawing.name}
					drawingType={drawingType}
					onSuccess={refreshProjectTreeAfterSimproUpdate}
					onClose={handleClose}
				/>
			) : null}
			{modal === TreeDrawingModal.INFO ? (
				<DrawingInfoModal
					isOpen={true}
					onClose={handleClose}
					drawingId={drawing.id}
				/>
			) : null}
			{(modal === TreeVersionModal.COMPLETE && latestVersion) ? (
				<CompleteVersionModal
					onClose={handleClose}
					onSuccess={() => {
						refreshProjectTreeAfterSimproUpdate();
						handleClose();
					}}
					drawingType={drawingType}
					selectedDrawingVersion={{
						selectedSectionId: section.id,
						selectedCostCenterId: costCenterId,
						selectedDrawingId: drawing.id,
						selectedDrawingName: drawing.name,
						selectedVersionId: latestVersion.id,
						selectedVersionLabel: latestVersion.version.getVersionLabel(),
						drawingType: drawing.drawingType
					}}
				/>
			) : null}
		</div>
	);
};

export default TreeDrawing
