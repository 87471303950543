import React, {useEffect, useRef, useState} from "react";
import {EditorLine, EditorPoint, ShapeDragState} from "../../models/editor";
import {getDashTypePointArray, getPointDraggingLines, getShapeDraggingLines} from "../../utils";
import {AreaHelper, generateFillStyle} from "./AreaHelper";
import {Context} from "konva/types/Context";
import {Shape as ShapeType, ShapeConfig} from "konva/types/Shape";
import {LinePoint, LinePointProps} from "../../components/LinePoint";
import {FillType} from "../../models/enums";
import {KonvaEventObject} from "konva/types/Node";
import {Vector2d} from "konva/types/types";
import {Line, Shape} from "react-konva";
import {closePreviewLineStyle} from "../../constants";
import {LineDistanceMark} from "../../components/LineDistanceMark";
import isEqual from "lodash/isEqual";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {AreaMeasurementValueMark} from "../../components/AreaMeasurementValueMark";
import {DashPatternType, PointDragState, SnappingState} from "../../../../../base-konva/types";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {CursorStyle} from "../../../../../base-konva/enums";

export type PointDragEndHandler = (args: { pointId: string, newPosition: Vector2d }) => void
export type ShapeDragEndHandler = (args: { positionDelta: Vector2d }) => void
export type ClickHandler = () => void
export type MouseEnterHandler = (evt: KonvaEventObject<MouseEvent>) => void
export type MouseLeaveHandler = (evt: KonvaEventObject<MouseEvent>) => void

export type EventHandlers = {
	onPointDragEnd: PointDragEndHandler,
	onShapeDragEnd: ShapeDragEndHandler,
	onClick: ClickHandler,
	onMouseEnter: MouseEnterHandler,
	onMouseLeave: MouseLeaveHandler,
	snapToPoint: (pointerPosition: Vector2d) => { snapTo: Vector2d, isClosing: boolean }
}

export type KonvaAreaFill =
	| { type: FillType.SOLID, color: string }
	| { type: FillType.DIAGONAL_PATTERN, color_1: string, color_2: string }

type KonvaAreaProps = {
	areaActive: boolean
	style: ShapeConfig
	fill: KonvaAreaFill
	listening: boolean
	draggable: boolean
	lines: EditorLine[]
	showPreviewLines: boolean
	previewLine?: EditorLine
	closeLinePoints?: number[]
	closed: boolean
	showPointElements: boolean
	snapping: SnappingState
	centerOfMassLabel?: string
	eventsRef: React.MutableRefObject<EventHandlers>
}

const _KonvaArea = function({
	areaActive,
	style,
	fill,
	listening,
	draggable,
	lines: baseLines,
	showPreviewLines,
	previewLine,
	closeLinePoints,
	closed,
	showPointElements,
	snapping,
	centerOfMassLabel,
	eventsRef
}: KonvaAreaProps) {

	function snapToPoint(mousePosition: Vector2d) {
		return eventsRef.current.snapToPoint(mousePosition)
	}

	function onPointDragEnd(args: { pointId: string, newPosition: Vector2d }) {
		eventsRef.current.onPointDragEnd(args)
	}

	function onMouseEnter(evt: KonvaEventObject<MouseEvent>) {
		eventsRef.current.onMouseEnter(evt)
	}

	function onMouseLeave(evt: KonvaEventObject<MouseEvent>) {
		eventsRef.current.onMouseLeave(evt)
	}

	function onShapeDragEnd(args: { positionDelta: Vector2d }) {
		eventsRef.current.onShapeDragEnd(args)
	}

	function onClick() {
		eventsRef.current.onClick()
	}

	const {changeCursor} = useCursorStyle();
	const [pointDragState, setPointDragState] = useState<PointDragState>({isDragging: false});
	const [shapeDragState, setShapeDragState] = useState<ShapeDragState>({isDragging: false});
	const isLinePointHover = useRef(false);
	const [fillStyle, setFillStyle] = useState<string | CanvasPattern | null>(() => generateFillStyle(fill));

	useEffect(() => {
		setFillStyle(generateFillStyle(fill))
	}, [fill]);

	const showPreviewLinesInternal =
		showPreviewLines &&
		!closed &&
		!shapeDragState.isDragging &&
		!pointDragState.isDragging &&
		!isLinePointHover.current;

	const lines: EditorLine[] = [];
	let centerOfMass = null;
	if (areaActive && pointDragState.isDragging) {
		lines.push(...getPointDraggingLines(baseLines, pointDragState))
	}
	else if (areaActive && shapeDragState.isDragging) {
		lines.push(...getShapeDraggingLines(baseLines, shapeDragState))
	}
	else {
		lines.push(...baseLines)
	}
	if (closed) {
		centerOfMass = AreaHelper.getPoleOfInaccessibility(lines);
	}

	function sceneFunc(context: Context, shapeConfig: ShapeType<ShapeConfig>) {
		context.beginPath();

		if (showPreviewLinesInternal && previewLine) {
			lines.push(previewLine)
		}

		lines.forEach((areaLine: EditorLine, index: number) => {
			if (index === 0) {
				context.moveTo(areaLine.from.position.x, areaLine.from.position.y);
			}
			context.lineTo(areaLine.to.position.x, areaLine.to.position.y);
		});

		if (closed && fillStyle !== null) {
			context.setAttr("fillStyle", fillStyle)
			context.fill()
		}
		context.strokeShape(shapeConfig)
	}

	function getPointElements(): JSX.Element[] {
		const lineMap: Map<string, JSX.Element> = new Map<string, JSX.Element>()

		const getProps = (point: EditorPoint): LinePointProps & { key: string } => {
			return {
				key: point.id,
				point: {
					id: point.id,
					position: {
						x: point.position.x,
						y: point.position.y,
					}
				},
				isActive: false,
				onMouseEnter: (evt) => {
					isLinePointHover.current = true
					changeCursor({event: evt, cursor: CursorStyle.MOVE})
				},
				onMouseLeave: (evt) => {
					isLinePointHover.current = false
					changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
				},
				onDragStart: (evt) => {
					changeCursor({event: evt, cursor: CursorStyle.MOVE});
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
						setPointDragState({
							isDragging: true,
							dragPointId: point.id,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						})
					}
				},
				onDragMove: (evt) => {
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
						setPointDragState(prevState => ({
							...prevState,
							dragPointCurrentLocation: {
								x: pointer.x,
								y: pointer.y,
							}
						}))
					}
				},
				onDragEnd: (evt) => {
					changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
					const mousePosition = getPointerPosition(evt);
					if (mousePosition) {
						let pointer = snapping.point ? snapToPoint(mousePosition).snapTo : mousePosition;
						onPointDragEnd({
							pointId: point.id,
							newPosition: {
								x: pointer.x,
								y: pointer.y,
							}
						})
					}
					setPointDragState({isDragging: false})
				},
			}
		}

		for (let line of lines) {
			if (!lineMap.has(line.from.id)) {
				lineMap.set(line.from.id, <LinePoint {...getProps(line.from)} />)
			}
			if (!lineMap.has(line.to.id)) {
				lineMap.set(line.to.id, <LinePoint {...getProps(line.to)} />)
			}
		}

		return Array.from(lineMap.values())
	}

	const callbacks = {
		onMouseEnter: (evt: KonvaEventObject<MouseEvent>) => {
			onMouseEnter(evt)
		},
		onMouseLeave: (evt: KonvaEventObject<MouseEvent>) => {
			onMouseLeave(evt)
		},
		onDragStart: (evt: KonvaEventObject<DragEvent>) => {
			changeCursor({event: evt, cursor: CursorStyle.MOVE})
			const mousePos = getPointerPosition(evt);
			if (mousePos) {
				setShapeDragState({
					isDragging: true,
					startPointerPosition: mousePos
				})
			}
		},
		onDragMove: (evt: KonvaEventObject<DragEvent>) => {
			evt.target.x(0);
			evt.target.y(0);
			const mousePos = getPointerPosition(evt);
			if (mousePos) {
				setShapeDragState(prevState => {
					let positionDelta: Vector2d | undefined
					if (prevState.startPointerPosition) {
						positionDelta = {
							x: mousePos.x - prevState.startPointerPosition.x,
							y: mousePos.y - prevState.startPointerPosition.y
						}
					}
					return {...prevState, positionDelta}
				})
			}
		},
		onDragEnd: (evt: KonvaEventObject<DragEvent>) => {
			changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
			if (shapeDragState.positionDelta) {
				onShapeDragEnd({positionDelta: shapeDragState.positionDelta})
			}
			setShapeDragState({isDragging: false})
		}
	};

	function getHitBoxPoints(): number[] {
		if (!closed || lines.length < 3) return []

		return lines.reduce<number[]>((previousValue, currentValue, index) => {
			const result: number[] = []
			if (index === 0) {
				result.push(currentValue.from.position.x, currentValue.from.position.y)
			}
			result.push(...previousValue, currentValue.to.position.x, currentValue.to.position.y)
			return result
		}, [])
	}

	return (
		<>
			<Shape
				{...style}
				sceneFunc={sceneFunc}
				lineCap={"round"}
				lineJoin={"round"}
			/>
			{closed ? (
				<Line
					name={"hitBoxPolygon"}
					points={getHitBoxPoints()}
					closed={closed}
					listening={listening}
					draggable={draggable}
					{...callbacks}
					onClick={onClick}
					onTap={onClick}
					lineCap={"round"}
					lineJoin={"round"}
				/>
			) : null}
			{closeLinePoints ? (
				<Line
					points={closeLinePoints}
					lineCap={"round"}
					lineJoin={"round"}
					{...closePreviewLineStyle}
					dash={getDashTypePointArray(DashPatternType.DOT, 2)}
				/>
			) : null}
			{lines.map((line) => (
				<LineDistanceMark
					callbacks={callbacks}
					listening={listening}
					draggable={areaActive}
					position={line.center}
					key={line.id}
					line={line}
					onClick={onClick}
					onTap={onClick}
				/>
			))}
			{centerOfMassLabel && centerOfMass ? (
				<AreaMeasurementValueMark
					label={centerOfMassLabel}
					callbacks={{
						...callbacks,
						onClick: onClick,
						onTap: onClick
					}}
					listening={listening}
					draggable={areaActive}
					centerOfMass={centerOfMass}
				/>
			) : null}
			{showPointElements && getPointElements()}
		</>
	)
}

export const KonvaArea = React.memo(_KonvaArea, isEqual)
