import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import QuotesSubTabs from "./QuotesSubTabs";
import JobsSubTabs from "./JobsSubTabs";
import {ProjectDetailsRoutes} from "../../project-details/routes";
import Page from "../../../components/layout/page/Page";
import {NavigationHeader} from "../../../routes";
import {paths} from "../../navigator";

const TakeoffsRouter: React.FC = () => {
	return (
		<Switch>
			<Route
				component={ProjectDetailsRoutes}
				path={[
					paths.takeoffs_jobs_loader_simproId,
					paths.takeoffs_quotes_loader_simproId,
					paths.takeoffs_jobs_details_projectId,
					paths.takeoffs_quotes_details_projectId,
					paths.takeoffs_job_simproId,
					paths.takeoffs_quote_simproId,
				]}
			/>
			<Route path={paths.any}>
				<Page>
					<NavigationHeader/>
					<Switch>
						<Route path={paths.takeoffs_quotes} component={QuotesSubTabs}/>
						<Route path={paths.takeoffs_jobs} component={JobsSubTabs}/>
						<Redirect to={paths.takeoffs_quotes}/>
					</Switch>
				</Page>
			</Route>
		</Switch>
	);
};

export {TakeoffsRouter}
