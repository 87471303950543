import {AxiosInstance, AxiosResponse} from "axios";
import AttachmentUploadUri from "../models/AttachmentUploadUri";
import {AttachmentInfo} from "../models/AttachmentInfo";
import {removeNotAllowedCharsFromFilename} from "../utils/TextUtils";

export default class AttachmentApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	static getAttachmentDownloadUrl(id: string, filename: string) {
		return encodeURI(`/api/attachments/${id}/external/${filename}`);
	}

	private createUploadUri(filename: string): Promise<AttachmentUploadUri> {
		const url = `/api/attachments`;
		const params = {action: "create"};
		return this.axiosInstance.post(url, {filename}, {params}).then(resp => AttachmentUploadUri.fromJson(resp.data))
	}

	private markAttachmentUploaded(attachmentId: string): Promise<void> {
		const url = `/api/attachments/${attachmentId}/uploaded`;
		return this.axiosInstance.put(url);
	}

	uploadFile(file: File): Promise<AttachmentInfo> {
		const renamedFile = new File([file], removeNotAllowedCharsFromFilename(file.name), {type: file.type});
		return this.createUploadUri(renamedFile.name).then(attUploadUri => {
			const config = {
				headers: {
					'Content-Type': renamedFile.type
				}
			};
			return this.axiosInstance.put(attUploadUri.uploadUri, renamedFile, config)
				.then(() => this.markAttachmentUploaded(attUploadUri.id)
					.then(() => new AttachmentInfo(attUploadUri.id)));
		});
	}

	downloadExternal(id: string, filename: string): Promise<AxiosResponse<any>> {
		return this.axiosInstance.get(AttachmentApi.getAttachmentDownloadUrl(id, filename), {responseType: "arraybuffer"})
	}
}
