import React from "react";
import {selectAreas} from "../area/areaSlice";
import {useSelector} from "react-redux";
import AreaBasedPanelItem from "./area/AreaBasedPanelItem";
import EditorPanelTabContent from "./EditorPanelTabContent";
import {AreaMeasurement} from "../../models/editor";
import {EditorTool} from "../../models/enums";

type AreaBasedPanelTabProps = {
	type: EditorTool.AREA | EditorTool.VOLUME
}

const AreaBasedPanelTab: React.FC<AreaBasedPanelTabProps> = ({type}) => {
	const areas = useSelector(selectAreas);

	function areaByView(area: AreaMeasurement): boolean {
		return (type === EditorTool.AREA && area.height === undefined) ||
			(type === EditorTool.VOLUME && area.height !== undefined)
	}

	return (
		<EditorPanelTabContent>
			{areas.filter(areaByView).map(area => (<AreaBasedPanelItem key={area.id} area={area} type={type}/>))}
		</EditorPanelTabContent>
	);
};

export default AreaBasedPanelTab
