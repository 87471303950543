import React from "react";
import {Vector2d} from "konva/types/types";
import {KonvaEventObject} from "konva/types/Node";
import {useCursorStyle} from "../../hooks/useCursorStyle";
import {Group, Line, Rect} from "react-konva";
import {rulerPointLinesDefaultStyle} from "../../constants";
import {LineConfig} from "konva/types/shapes/Line";
import {Colors} from "../../../../../../styles";
import {CursorStyle} from "../../../../../base-konva/enums";


type RulerPointProps = {
	center: Vector2d,
	angle: number,
	onDragMove: (evt: KonvaEventObject<DragEvent>) => Vector2d,
}

export const RulerPoint: React.FC<RulerPointProps> = ({center, onDragMove, angle}) => {

	const {changeCursor} = useCursorStyle();
	const radius = rulerPointLinesDefaultStyle.radius!;
	const lineStyle: Partial<LineConfig> = {
		stroke: Colors.SIMPROBLUE,
		strokeWidth: 1
	}

	return <Group
		x={center.x}
		y={center.y}
		rotation={angle}
		draggable={true}
		onMouseEnter={evt => {
			changeCursor({event: evt, cursor: CursorStyle.MOVE})
		}}
		onMouseLeave={evt => {
			changeCursor({event: evt, cursor: CursorStyle.DEFAULT})
		}}
		onDragStart={evt => {
			changeCursor({event: evt, cursor: CursorStyle.MOVE});
		}}
		onDragMove={evt => {
			const currentPosition = onDragMove(evt);
			evt.target.position(currentPosition)
		}}
		onDragEnd={evt => {
			changeCursor({event: evt, cursor: CursorStyle.DEFAULT});
		}}
	>
		<Rect offset={{x: radius * 2, y: radius * 2}}
			  width={radius * 4} height={radius * 4}
		/>
		<Line points={[0, -radius * 2, 0, +radius * 2]} {...lineStyle} />
		<Line points={[-radius * 2, 0, +radius * 2, 0,]} {...lineStyle} />
	</Group>
}