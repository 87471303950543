import React from "react";
import {StageConfig} from "../../models/editor";
import {SymbolRecognitionResultsReferentialData,} from "./types";
import {Colors} from "../../../../../../styles";
import {ResultFrameKonva} from "./ResultFrames";
import {SelectionState} from "../../../../../base-konva/types";

export function getFrameMap(
	indexes: number[],
	stroke: Colors,
	selection: SelectionState,
	symbolRecognitionResultList: SymbolRecognitionResultsReferentialData[],
	stageConfig: StageConfig,
): Record<number, JSX.Element> {
	const frameMap: Record<number, JSX.Element> = {}

	for (let index of indexes) {
		const item = symbolRecognitionResultList[index]
		if (!item) {
			continue
		}

		frameMap[index] = (
			<ResultFrameKonva
				key={index}
				stroke={stroke}
				x={item.x + stageConfig.backgroundImagePosition.x}
				y={item.y + stageConfig.backgroundImagePosition.y}
				height={selection.height}
				width={selection.width}
				rotation={item.rotationAngleInDegrees}
			/>
		)
	}

	return frameMap
}
