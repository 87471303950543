import React, {useEffect} from "react";
import {useLocation, useParams} from "react-router-dom";
import {ProjectSource, Status} from "../../../models/enums";
import {useDirectProjectAccessDataContext} from "../../../providers/DirectProjectAccessDataProvider";
import {useProjectDetailContext} from "../providers/ProjectDetailProvider";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import Spinner from "../../../components/Spinner";
import NotificationService from "../../../services/NotificationService";
import {useTranslation} from "react-i18next";
import {takeoffsTabsPaths} from "../hooks";
import {pages, useNavigator} from "../../navigator";


const ProjectDetailsSimproIdAccessLoader: React.FC = () => {
	const location = useLocation();
	const {simproId} = useParams<{ simproId: string }>();
	const projectSource = /quote/.test(location.pathname) ? ProjectSource.QUOTE : ProjectSource.JOB;
	const {setProjectDirectAccess, setProjectAccessData} = useDirectProjectAccessDataContext();
	const {navigateTo} = useNavigator()
	const {projectDetails: {project, loadProjectDetailsBySimproId, loadStatus}} = useProjectDetailContext()
	const dataRef = useUpdatedRef({loadProjectDetailsBySimproId, navigateTo})
	const {t} = useTranslation();


	useEffect(() => {
		const {loadProjectDetailsBySimproId, navigateTo} = dataRef.current
		const simproIdAsNumber = Number(simproId);
		if (isNaN(simproIdAsNumber)) {
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.wrongSimproIdFormatErrorDesc")
			)
			navigateTo(takeoffsTabsPaths[projectSource]);
		}
		else {
			loadProjectDetailsBySimproId(simproIdAsNumber, projectSource)
		}
	}, [dataRef, simproId, projectSource, t])

	useEffect(() => {
		const {navigateTo} = dataRef.current
		setProjectDirectAccess(true);
		if (loadStatus === Status.SUCCESS && project) {
			setProjectAccessData({
				projectId: project.id,
				simproId: project.number,
				projectSource
			});

			navigateTo(pages.projectDetailsPage(
				projectSource === ProjectSource.QUOTE ? "quotes" : "jobs",
				project.id
			))
		}
	}, [dataRef, simproId, project, loadStatus, setProjectAccessData, setProjectDirectAccess, projectSource]);

	return (
		<Spinner/>
	);
};

const ProjectDetailsSimproIdLoader: React.FC = () => {
	const location = useLocation();
	const {simproId} = useParams<{ simproId: string }>();
	const projectSource = /quote/.test(location.pathname) ? ProjectSource.QUOTE : ProjectSource.JOB;
	const {projectDetails: {project, loadProjectDetailsBySimproId}} = useProjectDetailContext()
	const {navigateTo} = useNavigator()
	const dataRef = useUpdatedRef({loadProjectDetailsBySimproId, navigateTo})
	const {t} = useTranslation();

	useEffect(() => {
		const {loadProjectDetailsBySimproId, navigateTo} = dataRef.current
		const simproIdAsNumber = Number(simproId);
		if (isNaN(simproIdAsNumber)) {
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.details.wrongSimproIdFormatErrorDesc")
			)
			navigateTo(takeoffsTabsPaths[projectSource])
		}
		else {
			loadProjectDetailsBySimproId(simproIdAsNumber, projectSource)
		}
	}, [dataRef, simproId, projectSource, t])

	useEffect(() => {
		const {navigateTo} = dataRef.current
		if (project) {
			navigateTo(pages.projectDetailsPage(
				projectSource === ProjectSource.QUOTE ? "quotes" : "jobs",
				project.id
			), {replace: true})
		}
	}, [dataRef, project, projectSource])

	return (
		<Spinner/>
	);
};

export {ProjectDetailsSimproIdAccessLoader, ProjectDetailsSimproIdLoader}