import React, {useEffect, useRef} from "react";
import Konva from "konva";
import {useDebouncedResizeObserver} from "../../../../project-drawings/features/editor/hooks/useDebouncedResizeObserver";
import {Provider, ReactReduxContext, useDispatch, useSelector} from "react-redux";
import {
	planUploadViewActions,
	selectPlanUploadEditorIsDirty,
	selectPlanUploadZoomState
} from "../features/view/planUploadViewSlice";
import {planUploadConfigActions, selectPlanUploadStageConfig} from "../features/config/planUploadConfigSlice";
import {DEFAULT_BLANK_STAGE_HEIGHT, DEFAULT_BLANK_STAGE_WIDTH} from "../../../../project-drawings/features/editor/constants";
import {get} from "../../../../../utils/ClassNameUtils";
import {Group, Image, Layer, Rect, Stage} from "react-konva";
import "./planUploadEditor.scss"
import {PlanUploadEditorHeader} from "./PlanUploadEditorHeader";
import {usePlanUploadZoomOnWheel} from "../features/view/usePlanUploadZoomOnWheel";
import {PlanUploadStageLayers} from "./PlanUploadStageLayers";
import {usePlanUploadPan} from "../features/pan/usePlanUploadPan";
import {ClipToolBox} from "./ClipToolBox";
import {selectClipArea} from "../features/clip/planUploadClipSlice";
import {designateClipFunction} from "../utils";
import {EraseAreaToolBox} from "./EraseAreaToolBox";
import {selectPlanUploadErasedAreas} from "../features/erase-area/planUploadEraseAreaSlice";
import {EditorDrawnItemsDataContextBridge} from "../../../../project-drawings/features/editor/EditorDrawnItemsDataProvider";
import {PlanUploadEditTextContainer} from "../features/text/PlanUploadEditTextContainer";
import {IsAnyModalOpenContextBridge} from "../../../../../providers/IsAnyModalOpenProvider";
import {EraseAreaRectangles} from "../features/erase-area/EraseAreaRectangles";
import {UserSettingsDataContextBridge, useUserSettingsDataContext} from "../../../../../providers/UserSettingsProvider";
import {ScaleInfoState} from "../../../../base-konva/types";
import {useInitScaleInfoState} from "../../../../base-konva/features/config";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {planUploadScaleActions, selectPlanUploadScaleConfig} from "../features/scale/planUploadScaleSlice";
import {blurDocumentActiveElement} from "../../../../../utils/DOMUtils";

export const PlanUploadEditor: React.FC<{
	image: HTMLImageElement | undefined;
	isLoading: boolean;
}> = function({
	image, isLoading
}) {

	const stageRef = useRef<Konva.Stage>(null)
	const whiteCanvasRef = useRef<Konva.Rect>(null)
	const scrollContainer = useRef<HTMLDivElement>(null)
	const shouldCenterStagePosition = useRef(true)
	const editorHostObserver = useDebouncedResizeObserver()
	const {settings} = useUserSettingsDataContext();
	const {planUploadData: {isAnyEditorDirty: {triggerCheck}}} = useProjectDetailContext()

	const dispatch = useDispatch()
	const zoomState = useSelector(selectPlanUploadZoomState)
	const stageConfig = useSelector(selectPlanUploadStageConfig)
	const scaleConfig = useSelector(selectPlanUploadScaleConfig)
	const clipArea = useSelector(selectClipArea)
	const erasedAreas = useSelector(selectPlanUploadErasedAreas)
	const isPlanUploadEditorDirty = useSelector(selectPlanUploadEditorIsDirty)
	const {onMouseDown, onTouchStart} = usePlanUploadPan({stageRef})
	usePlanUploadZoomOnWheel({scrollContainer, stageRef})

	useEffect(function triggerEditorIsDirtyCheck() {
		triggerCheck()
	}, [triggerCheck, isPlanUploadEditorDirty])

	const setScaleInfoState = function(state: ScaleInfoState) {
		dispatch(planUploadScaleActions.setScaleInfoState({value: state, isUndoRedoExcluded: true}))
	}
	useInitScaleInfoState({scaleConfig, settings, setScaleInfoState, stageConfig, isLoading})

	useEffect(() => {
		if (!isLoading && editorHostObserver.height && editorHostObserver.width) {
			const stageHeight = stageConfig.height !== 0 ? stageConfig.height : image?.height ?? DEFAULT_BLANK_STAGE_HEIGHT;
			const stageWidth = stageConfig.width !== 0 ? stageConfig.width : image?.width ?? DEFAULT_BLANK_STAGE_WIDTH;

			if (stageConfig.width === 0) {
				dispatch(planUploadConfigActions.setInitialStageConfig({
					height: stageHeight,
					width: stageWidth
				}))
			}
		}
	}, [dispatch, editorHostObserver.height, editorHostObserver.width, image, isLoading, stageConfig])

	useEffect(() => {
		if (!isLoading && editorHostObserver.height && editorHostObserver.width) {
			const viewportHeight = editorHostObserver.height;
			const viewportWidth = editorHostObserver.width;

			dispatch(planUploadViewActions.setDimensions({
				height: viewportHeight,
				width: viewportWidth
			}))
		}
	}, [dispatch, editorHostObserver.height, editorHostObserver.width, isLoading])

	/**
	 * Center stage on init
	 */
	useEffect(() => {
		const viewportWidth = editorHostObserver.width;
		const stageHeight = stageConfig.height !== 0 ? stageConfig.height : image?.height ?? DEFAULT_BLANK_STAGE_HEIGHT;
		const stage = stageRef.current

		if (shouldCenterStagePosition.current && viewportWidth && stage) {
			shouldCenterStagePosition.current = false;
			stage.position({
				x: viewportWidth / 2,
				y: stageHeight / 2
			})
			stage.batchDraw()
		}
	}, [stageConfig, editorHostObserver.width, image])

	return (
		<div className="plan-upload-editor">
			<div ref={editorHostObserver.ref} className="plan-upload-editor_host">
				<div
					ref={scrollContainer}
					className={get("plan-upload-editor_host_scroll-container")}
					style={{
						height: zoomState.viewportSize.height,
						width: zoomState.viewportSize.width,
					}}
				>
					<EditorDrawnItemsDataContextBridge.Consumer>{editorDrawnItemsData => (
						<IsAnyModalOpenContextBridge.Consumer>{modalData => (
							<UserSettingsDataContextBridge.Consumer>{settings => (
								<ReactReduxContext.Consumer>{({store}) => (
									<Stage
										ref={stageRef}
										listening={true}
										rotation={zoomState.rotation}
										offsetX={stageConfig.width / 2}
										offsetY={stageConfig.height / 2}
										height={zoomState.viewportSize.height}
										width={zoomState.viewportSize.width}
										scale={{x: zoomState.scale, y: zoomState.scale}}
										onMouseDown={onMouseDown}
										onTouchStart={evt => {
											onTouchStart(evt)
											blurDocumentActiveElement()
										}}
									>
										<UserSettingsDataContextBridge.Provider value={settings}>
											<Provider store={store}>
												<IsAnyModalOpenContextBridge.Provider value={modalData}>
													<EditorDrawnItemsDataContextBridge.Provider
														value={editorDrawnItemsData}>
														<Layer>
															<Rect
																ref={whiteCanvasRef}
																fill={"white"}
																x={0} y={0}
																width={stageConfig.width}
																height={stageConfig.height}
															/>
															<Group clipFunc={designateClipFunction(clipArea)}>
																<Image image={image}/>
															</Group>
															<Rect
																name={"background"}
																width={stageConfig.width}
																height={stageConfig.height}
															/>
															<EraseAreaRectangles erasedAreas={erasedAreas}/>
															<PlanUploadStageLayers/>
														</Layer>
													</EditorDrawnItemsDataContextBridge.Provider>
												</IsAnyModalOpenContextBridge.Provider>
											</Provider>
										</UserSettingsDataContextBridge.Provider>
									</Stage>
								)}
								</ReactReduxContext.Consumer>
							)}
							</UserSettingsDataContextBridge.Consumer>
						)}
						</IsAnyModalOpenContextBridge.Consumer>
					)}
					</EditorDrawnItemsDataContextBridge.Consumer>
					<ClipToolBox whiteCanvasRef={whiteCanvasRef}/>
					<EraseAreaToolBox whiteCanvasRef={whiteCanvasRef}/>
					<PlanUploadEditTextContainer whiteCanvasRef={whiteCanvasRef}/>
				</div>
			</div>
			<PlanUploadEditorHeader/>
		</div>
	)
}
