import {Vector2d} from "konva/types/types";
import {distance} from "../../utils";
import {RootState} from "../../editorStore";
import {POINT_SNAPPING_DISTANCE} from "../../constants";
import {useStore} from "react-redux";

export function useSnapToPoint() {
	const store = useStore<RootState>()

	function snapToPoint(pointerPosition: Vector2d): { snapTo: Vector2d, isClosing: boolean } {
		let snapToDistance: number = POINT_SNAPPING_DISTANCE;
		let snapTo: Vector2d | null = null;
		let isClosing = false;
		const storeData = store.getState().undoGroup.present;
		const activeAreaId = storeData.area.activeAreaId;
		const areas = storeData.area.areas.filter(item => item.visible);
		const countGroups = storeData.count.countGroups.filter(item => item.visible);
		const lengths = storeData.length.lengths.filter(item => item.visible);
		areas.forEach(area => {
			area.areaFragments.forEach(fragment => {
				fragment.lines.forEach((line, index) => {
					if (index === 0 && fragment.lines.length > 1) { //The length check is only for active items because they can be in this weird state.
						let dist = distance(pointerPosition, line.from.position);
						if (snapToDistance > dist) {
							snapTo = line.from.position;
							snapToDistance = dist;
							if (area.id === activeAreaId)
								isClosing = true;
						}
					}
					let dist = distance(pointerPosition, line.to.position);
					if (snapToDistance > dist) {
						snapTo = line.to.position;
						snapToDistance = dist;
						isClosing = false;
					}
				})
			})
		})
		countGroups.forEach(count => count.countItems.forEach(item => {
			if (item.visible) {
				let dist = distance(pointerPosition, item.position);
				if (snapToDistance > dist) {
					snapTo = item.position;
					snapToDistance = dist;
				}
			}
		}));
		lengths.forEach(length => {
			length.lengthFragments.forEach(fragment => {
				fragment.lines.forEach((line, index) => {
					if (fragment.lines.length - 1 === index) {
						let dist = distance(pointerPosition, line.to.position);
						if (snapToDistance > dist) {
							snapTo = line.to.position;
							snapToDistance = dist;
						}
					}
					let dist = distance(pointerPosition, line.from.position);
					if (snapToDistance > dist) {
						snapTo = line.from.position;
						snapToDistance = dist;
					}
				})
			})
		})
		return {snapTo: snapTo ?? pointerPosition, isClosing};
	}

	return {snapToPoint}
}
