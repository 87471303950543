import React, {useEffect} from "react";
import ReactModal from 'react-modal';
import "./modal.scss";
import {useIsAnyModalOpenSetter} from "../../providers/IsAnyModalOpenProvider";
import ModalTitle from "./ModalTitle";
import ModalButtons from "./ModalButtons";

interface ModalProps {
	isOpen: boolean,
	onClose: () => void,
	customClass?: string;
}

const Modal: React.FC<ModalProps> = ({isOpen, onClose, customClass, children}) => {
	const cls = ["react-modal", customClass && customClass].filter(Boolean).join(" ");
	const {setIsOpen} = useIsAnyModalOpenSetter()

	useEffect(() => {
		setIsOpen(isOpen)
		return () => {
			setIsOpen(false)
		}
	}, [isOpen, setIsOpen])

	return (
		<ReactModal
			isOpen={isOpen}
			ariaHideApp={false}
			onRequestClose={onClose}
			overlayClassName={"react-modal-overlay"}
			className={cls}
		>
			{children}
		</ReactModal>
	);
};

export function ModalWithTitleAndButtons({isOpen, onClose, title, children, modalButtons}: {
	isOpen: boolean,
	onClose: () => void,
	title: string,
	children: React.ReactNode,
	modalButtons: React.ReactNode,
}) {
	return isOpen ? (
		<Modal isOpen={true} onClose={onClose}>
			<ModalTitle>{title}</ModalTitle>
			{children}
			<ModalButtons>{modalButtons}</ModalButtons>
		</Modal>
	) : null
}

export default Modal
