import {createSlice} from "@reduxjs/toolkit";
import {initialState as baseInitialState, reducers} from "../../../../../base-konva/features/pen/basePenSlice";
import {PlanUploadRootState} from "../../planUploadStore";
import {PenToolState} from "../../../../../base-konva/types";
import {getId} from "../../../../../../utils";
import i18n from "../../../../../../i18n";

const id = getId()

const initialState: PenToolState = {
	...baseInitialState,
	activePenGroupId: id,
	penGroups: [{
		id,
		visible: true,
		name: `${i18n.t("editor.pen.penGroup")} ${baseInitialState.penGroups.length + 1}`,
		penLines: [],
		style: baseInitialState.penSelectorStyle
	}]
}

const planUploadPenSlice = createSlice({
	name: 'plan-upload-pen',
	initialState,
	reducers: {
		...reducers,
		reset: () => ({...initialState})
	}
})

export const planUploadPenReducer = planUploadPenSlice.reducer;
export const planUploadPenActions = planUploadPenSlice.actions;

export const selectPlanUploadPenGroups = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.pen.penGroups;
export const selectPlanUploadPenType = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.pen.type;
export const selectPlanUploadActivePenGroupId = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.pen.activePenGroupId;
export const selectPlanUploadPenSelectorStyle = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.pen.penSelectorStyle;

