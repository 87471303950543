import {PlanUploadEditorTool, PlanUploadToolHookResult} from "../../types";
import {useState} from "react";
import {getId} from "../../../../../../utils";
import {KonvaEventType, PenGroup, PenLine, PenToolType} from "../../../../../base-konva/types";
import {KonvaEventObject} from "konva/types/Node";
import {distance} from "../../../../../project-drawings/features/editor/utils";
import {PEN_TOOL_THRESHOLD} from "../../../../../project-drawings/features/editor/constants";
import {useDispatch, useSelector} from "react-redux";
import {planUploadPenActions, selectPlanUploadActivePenGroupId, selectPlanUploadPenType} from "./planUploadPenSlice";
import {PlanUploadPenElement} from "./PlanUploadPenElement";
import {getPointerPosition} from "../../../../../base-konva/utils";
import {usePlanUploadSelectionCleaner} from "../../hooks/planUploadSelect";


function usePlanUploadPen(penDrawGroups: PenGroup[]): PlanUploadToolHookResult {
	const dispatch = useDispatch();
	const [hookRenderId] = useState(getId());
	const [isDrawing, setIsDrawing] = useState(false)
	const [currentLine, setCurrentLine] = useState<PenLine>({id: getId(), name: "-", visible: true, points: []})

	usePlanUploadSelectionCleaner(PlanUploadEditorTool.PEN)
	const penType = useSelector(selectPlanUploadPenType);
	const activePenGroupId = useSelector(selectPlanUploadActivePenGroupId);

	function onMouseDown(evt: KonvaEventObject<KonvaEventType>) {
		setIsDrawing(true);
		const position = getPointerPosition(evt);
		if (position) {
			setCurrentLine(line => ({
				...line,
				points: line.points.concat([position.x, position.y])
			}))
		}
	}

	function onMouseUp() {
		// Do not update redux state until finished drawing lines,
		// Update on mouse move will cause problem with future undo/redo functionality
		// State should be update after finished whole action
		dispatch(planUploadPenActions.addPenLine(currentLine.points))
		setIsDrawing(false)
		setCurrentLine(line => ({...line, points: []}))
	}

	function onMouseMove(evt: KonvaEventObject<KonvaEventType>) {
		const position = getPointerPosition(evt);
		if (position && isDrawing) {
			if (penType === PenToolType.FREE) {
				if (currentLine.points.length >= 2) {
					const lastPosition = currentLine.points.slice(-2)
					if (distance({x: lastPosition[0], y: lastPosition[1]}, position) >= PEN_TOOL_THRESHOLD) {
						setCurrentLine(line => ({
							...line,
							points: line.points.concat([position.x, position.y])
						}))
					}
				}
			}
			else {
				const setPoints = (points: number[]) => {
					if (points.length <= 1) {
						return [position.x, position.y]
					}
					return [points[0], points[1], position.x, position.y]
				}
				setCurrentLine(line => ({
					...line,
					points: setPoints(line.points)
				}))
			}
		}
	}

	function render() {
		return penDrawGroups.map(group => (
			group.penLines
				.concat(group.id === activePenGroupId ? currentLine : [])
				.map(line => (
					<PlanUploadPenElement
						key={line.id}
						line={line}
						group={group}
						isDrawing={isDrawing}
					/>
				))
		))
	}

	return {
		id: hookRenderId,
		render,
		tool: PlanUploadEditorTool.PEN,
		callbacks: {
			onMouseUp,
			onMouseDown,
			onMouseMove,
		}
	}
}

export {usePlanUploadPen}