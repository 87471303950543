import {useInternalDataContext} from "../../../../MaterialModalProvider";
import React from "react";
import {TemplateView} from "../../../../types";
import {TemplateBrowser} from "../../../../components/template/TemplateBrowser";
import {TemplateItemPresenter} from "./TemplateItemPresenter";

export function TemplateTabContent() {
	const {addItemsData} = useInternalDataContext()
	const {
		view,
		breadcrumbs,
		browserData,
		itemPresenterData
	} = addItemsData.templates

	return (
		<>
			{view === TemplateView.BROWSER ? <TemplateBrowser {...{browserData, breadcrumbs}}/> : null}
			{view === TemplateView.ITEM_PRESENTER ? <TemplateItemPresenter {...{itemPresenterData, breadcrumbs}}/> : null}
		</>
	)
}