import React from "react";

interface ITableNoRecordsProps {
	cols: number,
	label?: string,
}


const TableNoRecords: React.FC<ITableNoRecordsProps> = ({cols, label}) => {
	return (
		<tr>
			<td colSpan={cols} style={{textAlign: "center"}}>
				{label ? label : <span className={"heading2"}>No records to display</span>}
			</td>
		</tr>
	);
};

export default TableNoRecords
