import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import CustomSelect from "../../../../components/ui/CustomSelect";
import {CustomSelectItem} from "../../../../models/interfaces";
import FitTime from "../../../../models/FitTime";
import {PlanSummaryItem} from "../../../../models/PlanSummaryItem";
import {apiInstance} from "../../../../api/api";
import NotificationService from "../../../../services/NotificationService";
import LoggerService from "../../../../services/LoggerService";
import EditableCell from "./EditableCell";

interface EditFtmCellProps {
	item: PlanSummaryItem,
	fitTimes: FitTime[],
	onSuccess: () => void;
}

const EditFtmCell: React.FC<EditFtmCellProps> = ({item, fitTimes, onSuccess}) => {
	const [fitTimeId, setFitTimeId] = useState<number | undefined>(item.fitTimeId);
	const [isSaving, setIsSaving] = useState(false);
	const {t} = useTranslation();

	const toSelectItem = (fitTime: FitTime): CustomSelectItem<number> => {
		return {
			label: `${fitTime.multiplier}${t("projects.summary.itemsTable.suffixes.fitTimeMultiplier")}`,
			value: fitTime.id
		};
	};

	const onFtmChange = (value: number) => setFitTimeId(value);

	const onSave = () => {
		setIsSaving(true);
		apiInstance.measurementsApi.putMeasurementData(item.id, fitTimeId, item.laborTime)
			.then(() => {
				NotificationService.successNotification(t("common.success"),
					t("projects.summary.itemsTable.editFtmModal.saveSuccessDesc"));
				onSuccess();
			})
			.catch(err => {
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("projects.summary.itemsTable.editFtmModal.saveErrorDesc"))
			})
			.finally(() => setIsSaving(false))
	};

	const fitTime = fitTimes.find(ft => ft.id === item.fitTimeId);

	return (
		<EditableCell label={fitTime?.getFormattedMultiplier() ?? "-"} onSave={onSave} isSaving={isSaving}>
			<CustomSelect placeholder={"Select..."}
						  customClass="editable-cell_select"
						  items={fitTimes.map(toSelectItem)}
						  onChange={onFtmChange}
						  value={fitTimeId}/>
		</EditableCell>
	);
};

export default EditFtmCell;
