import {ProjectStatusApiDto} from "./restModels";

export default class ProjectStatus {
	constructor(
		public readonly id: number,
		public readonly name: string,
		public readonly color: string | undefined
	) {}

	public static fromJson(json: ProjectStatusApiDto): ProjectStatus {
		return new ProjectStatus(json.id, json.name, json.color);
	}
}
