import React, {useEffect, useState} from "react";
import {ButtonsGroup, ButtonsGroupDivider, ButtonsGroupSpacer} from "../../../../../components/layout";
import {ZoomControls} from "../../../../base-konva/features/view";
import "./planUploadEditorHeader.scss"
import {useDispatch, useSelector} from "react-redux";
import {planUploadViewActions, selectPlanUploadZoomState} from "../features/view/planUploadViewSlice";
import {usePlanUploadZoomActions} from "../features/view/usePlanUploadZoomActions";
import IconWithTooltip from "../../../../../components/ui/IconWithTooltip";
import IconPenTool from "../../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconPenTool";
import {useTranslation} from "react-i18next";
import {planUploadConfigActions, selectPlanUploadActiveTool} from "../features/config/planUploadConfigSlice";
import {PlanUploadEditorTool} from "../types";
import {PlanUploadPenToolOptions} from "../features/pen";
import {PlanUploadScaleDialog} from "./PlanUploadScaleDialog";
import ConfigHelper from "../../../../project-drawings/features/editor/features/config/ConfigHelper";
import {PlanUploadScaleInfo} from "./PlanUploadScaleInfo";
import {get} from "../../../../../utils/ClassNameUtils";
import {SavePlanPageButton} from "./SavePlanPageButton";
import IconClipTool from "../Icons/IconClipTool";
import {planUploadClipActions, selectClipSelectionState} from "../features/clip/planUploadClipSlice";
import IconEraseAreaTool from "../Icons/IconEraseAreaTool";
import {
	planUploadEraseAreaActions,
	selectPlanUploadEraseAreaSelectionState
} from "../features/erase-area/planUploadEraseAreaSlice";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import IconTextTool from "../../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconTextTool";
import {PlanUploadTextToolOptions} from "../features/text/PlanUploadTextToolOptions";
import ImageIcon from "../../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/ImageIcon";
import {PlanUploadImageOptions} from "../features/image/PlanUploadImageOptions";
import IconRotateCcw from "../../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconRotateCcw";
import IconRotateCw from "../../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconRotateCw";
import IconSelect from "../../../../project-drawings/features/editor/icons/selectableEditorHeaderIcons/IconSelect";
import IconBin from "../../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconBin";
import {usePlanUploadDeleteActiveItem} from "../features/delete/usePlanUploadDeleteActiveItem";
import PlanUploadEditorHeaderUndoPanel from "./PlanUploadEditorHeaderUndoPanel";
import {selectPlanUploadScaleConfig} from "../features/scale/planUploadScaleSlice";
import {rotateClockwise, rotateCounterClockwise} from "../../../../base-konva/utils";


export const PlanUploadEditorHeader: React.FC = () => {

	const zoomState = useSelector(selectPlanUploadZoomState)
	const activeTool = useSelector(selectPlanUploadActiveTool);
	const scaleConfig = useSelector(selectPlanUploadScaleConfig)
	const eraseAreaSelection = useSelector(selectPlanUploadEraseAreaSelectionState)
	const clipSelection = useSelector(selectClipSelectionState)
	const dataRef = useUpdatedRef({eraseAreaSelection, clipSelection})
	const hasScale = ConfigHelper.isScaleSet(scaleConfig)
	const [isScaleDialogVisible, setIsScaleDialogVisible] = useState<boolean>(!hasScale)
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const shouldDisplayExtraToolbar = activeTool &&
		[PlanUploadEditorTool.PEN, PlanUploadEditorTool.TEXT, PlanUploadEditorTool.IMAGE].includes(activeTool);

	useEffect(function clearProperToolSelectionOnToolChange() {
		const {eraseAreaSelection, clipSelection} = dataRef.current

		if (eraseAreaSelection) {
			dispatch(planUploadEraseAreaActions.setSelection(undefined))
		}
		else if (clipSelection) {
			dispatch(planUploadClipActions.setSelection(undefined))
		}
	}, [activeTool, dispatch, dataRef])

	const {
		minZoom, maxZoom,
		handleZoomOutByPercent,
		handleZoomInByPercent,
		handleZoomReset,
	} = usePlanUploadZoomActions()

	const {canDeleteActiveItem, deleteActiveItem} = usePlanUploadDeleteActiveItem();

	function handleZoomOut() {
		handleZoomOutByPercent(10)
	}

	function handleZoomIn() {
		handleZoomInByPercent(10)
	}

	function handleButtonToggle(buttonTool: PlanUploadEditorTool) {
		return () => {
			activeTool === buttonTool ?
				dispatch(planUploadConfigActions.switchEditorToolBack({})) :
				dispatch(planUploadConfigActions.switchEditorTool({planUploadEditorTool: buttonTool}));
		}
	}

	function handleStageRotateCw() {
		dispatch(planUploadViewActions.setZoomState({
			...zoomState,
			rotation: rotateClockwise(zoomState.rotation)
		}))
	}

	function handleStageRotateCcw() {
		dispatch(planUploadViewActions.setZoomState({
			...zoomState,
			rotation: rotateCounterClockwise(zoomState.rotation)
		}))
	}

	return (
		<div className="plan-upload-editor-header">
			<div className={get("plan-upload-editor-header_main", {"has-scale": hasScale})}>
				{(isScaleDialogVisible || !hasScale) ?
					<PlanUploadScaleDialog onClose={() => {setIsScaleDialogVisible(false)}}/>
					: <PlanUploadScaleInfo onClick={() => {setIsScaleDialogVisible(true)}}/>}
				<ButtonsGroup>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.selection")}
						icon={(
							<IconSelect
								active={activeTool === PlanUploadEditorTool.MEASUREMENT_SELECT}
								onClick={handleButtonToggle(PlanUploadEditorTool.MEASUREMENT_SELECT)}
							/>
						)}
					/>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.rotateLeft")}
						icon={<IconRotateCcw onClick={handleStageRotateCcw}/>}
					/>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.rotateRight")}
						icon={<IconRotateCw onClick={handleStageRotateCw}/>}
					/>
					<ZoomControls
						currentZoom={Math.round(zoomState.scale * 100)}
						minZoom={minZoom}
						maxZoom={maxZoom}
						handleZoomOut={handleZoomOut}
						handleZoomReset={handleZoomReset}
						handleZoomIn={handleZoomIn}
					/>
				</ButtonsGroup>
				<ButtonsGroupDivider/>
				<ButtonsGroup>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.penTool")}
						icon={(
							<IconPenTool
								active={activeTool === PlanUploadEditorTool.PEN}
								onClick={handleButtonToggle(PlanUploadEditorTool.PEN)}
							/>
						)}
					/>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.textTool")}
						icon={(
							<IconTextTool
								active={activeTool === PlanUploadEditorTool.TEXT}
								onClick={handleButtonToggle(PlanUploadEditorTool.TEXT)}
							/>
						)}
					/>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.imageTool")}
						icon={(
							<ImageIcon
								active={activeTool === PlanUploadEditorTool.IMAGE}
								onClick={handleButtonToggle(PlanUploadEditorTool.IMAGE)}
							/>
						)}
					/>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.eraseArea")}
						icon={(
							<IconEraseAreaTool
								active={activeTool === PlanUploadEditorTool.ERASE_AREA}
								onClick={handleButtonToggle(PlanUploadEditorTool.ERASE_AREA)}
							/>
						)}
					/>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.clip")}
						icon={(
							<IconClipTool
								active={activeTool === PlanUploadEditorTool.CLIP}
								onClick={handleButtonToggle(PlanUploadEditorTool.CLIP)}
							/>
						)}
					/>
				</ButtonsGroup>
				<ButtonsGroupDivider/>
				<ButtonsGroup>
					<IconWithTooltip
						tooltipText={t("editor.tooltip.delete")}
						icon={(
							<IconBin
								onClick={deleteActiveItem}
								disabled={!canDeleteActiveItem}
							/>
						)}
					/>
				</ButtonsGroup>
				<ButtonsGroupDivider/>
				<ButtonsGroup>
					<PlanUploadEditorHeaderUndoPanel/>
				</ButtonsGroup>
				<ButtonsGroupDivider/>
				<ButtonsGroupSpacer/>
				<ButtonsGroup>
					<SavePlanPageButton/>
				</ButtonsGroup>
			</div>
			{shouldDisplayExtraToolbar && (
				<div className="plan-upload-editor-header_options">
					{activeTool === PlanUploadEditorTool.PEN ? <PlanUploadPenToolOptions/> : null}
					{activeTool === PlanUploadEditorTool.TEXT ? <PlanUploadTextToolOptions/> : null}
					{activeTool === PlanUploadEditorTool.IMAGE ? <PlanUploadImageOptions/> : null}
				</div>
			)}
		</div>
	)
}