import TakeoffTemplateItem from "../../../../../../../../../../../models/TakeoffTemplateItem";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {useUserSettingsDataContext} from "../../../../../../../../../../../providers/UserSettingsProvider";
import {EditorTool} from "../../../../../../../models/enums";
import {getDefaultStyle} from "../../../../../../../utils";
import {MaterialAddableTool, MeasurementStyle} from "../../../../../../../../../../../models/interfaces";
import {AreaStyle, CountMeasurementStyle} from "../../../../../../../models/editor";
import SymbolStyleSelector from "../../../../../../../components/SymbolStyleSelector";
import LineStyleSelector from "../../../../../../../components/LineStyleSelector";
import AreaStyleSelector from "../../../../../../../components/AreaStyleSelector";
import {UnitsType} from "../../../../../../../../../../../models/enums";
import LengthInput from "../../../../../../../../../../../components/ui/LengthInput";
import {MeasurementTypeSelect} from "../../../../components/MeasurementTypeSelect";
import {RenderStyleSelectorHandler} from "../../../../types";
import {LineStyle} from "../../../../../../../../../../base-konva/types";

type Props = {
	item: TakeoffTemplateItem,
	row: {
		isSelected: (id: string) => boolean,
		toggle: (id: string) => void
	},
	updateItem: (id: string, source: TakeoffTemplateItem) => void
}

export function TemplateItemsTableRow({item, row, updateItem}: Props) {
	const {t} = useTranslation();
	const {settings} = useUserSettingsDataContext();
	const [localStyleMap, setLocalStyleMap] = useState({
		[EditorTool.COUNT]: getStyleFromItem(EditorTool.COUNT) ?? getDefaultStyle(EditorTool.COUNT),
		[EditorTool.LENGTH]: getStyleFromItem(EditorTool.LENGTH) ?? getDefaultStyle(EditorTool.LENGTH),
		[EditorTool.AREA]: getStyleFromItem(EditorTool.AREA) ?? getDefaultStyle(EditorTool.AREA),
		[EditorTool.VOLUME]: getStyleFromItem(EditorTool.VOLUME) ?? getDefaultStyle(EditorTool.VOLUME)
	});

	function getStyleFromItem(tool: MaterialAddableTool) {
		if (item.measurementType === tool && item.style) {
			return item.style
		}
		return undefined
	}

	const renderCountStyleSelector = (
		style: MeasurementStyle,
		onChange: (style: CountMeasurementStyle) => void
	) => {
		return (
			<SymbolStyleSelector style={style as CountMeasurementStyle}
								 onStyleChange={onChange}/>
		);
	};

	const renderLengthStyleSelector = (style: MeasurementStyle, onChange: (style: LineStyle) => void) => {
		return (
			<LineStyleSelector style={style as LineStyle} onStyleChange={onChange}/>
		);
	};

	const renderAreaStyleSelector = (style: MeasurementStyle, onChange: (style: AreaStyle) => void) => {
		return (
			<AreaStyleSelector style={style as AreaStyle} onStyleChange={onChange}/>
		);
	};

	let renderStyleSelector: RenderStyleSelectorHandler

	switch (item.measurementType) {
		case EditorTool.COUNT:
			renderStyleSelector = renderCountStyleSelector;
			break;
		case EditorTool.LENGTH:
			renderStyleSelector = renderLengthStyleSelector;
			break;
		default:
			renderStyleSelector = renderAreaStyleSelector;
			break;
	}

	function renderDefaultDropInput() {
		const inputLabel = `${t("editor.length.setDefaultDropValueModal.inputLabel")}  ${settings?.measurementSystem ===
		UnitsType.METRIC ?
			`[${t("common.units.meterSymbol")}]` : ""}`;

		return (
			<LengthInput
				label={inputLabel}
				value={item.defaultDrop}
				unitsType={settings?.measurementSystem}
				onChange={value => {
					updateItem(item.id, item.withNewDefaultDrop(value))
				}}
			/>
		)
	}

	const onHeightValueChange = (value?: number) => {
		updateItem(item.id, item.withNewHeight(value, value ? false : item.isVolumeHeightError))
	}


	function renderHeightInput() {
		const inputLabel = `${t("editor.area.setHeightModal.inputLabel")}  ${settings?.measurementSystem === UnitsType.METRIC ?
			`[${t("common.units.meterSymbol")}]` : ""}`;

		return (
			<LengthInput
				label={inputLabel}
				isError={item.isVolumeHeightError}
				value={item.height}
				unitsType={settings?.measurementSystem}
				onChange={onHeightValueChange}
			/>
		)
	}

	const onMeasurementTypeChange = (value: MaterialAddableTool) => {
		updateItem(item.id, item.withNewTypeAndStyle(value, localStyleMap[value], false))
	}

	return (
		<tr>
			<td><input type={"checkbox"} checked={row.isSelected(item.id)} onChange={() => row.toggle(item.id)}/></td>
			<td className={"break-word"}>{item.name}</td>
			<td>
				<MeasurementTypeSelect
					value={item.measurementType}
					onChange={onMeasurementTypeChange}
				/>
			</td>
			<td>
				<div style={{display: "flex"}}>
					{renderStyleSelector(item.style!, newStyle => {
						if (item.measurementType) {
							const newLocalStyleMap = {...localStyleMap}
							newLocalStyleMap[item.measurementType] = newStyle
							setLocalStyleMap(newLocalStyleMap)
							updateItem(item.id, item.withNewTypeAndStyle(item.measurementType, newStyle))
						}
					})}
					{(item.measurementType === EditorTool.VOLUME) && renderHeightInput()}
					{(item.measurementType === EditorTool.LENGTH) && renderDefaultDropInput()}
				</div>
			</td>
		</tr>
	)
}