import React from "react";
import {NavigateToOptions, NavigatorDataContextType} from "./types";

const NavigatorDataContext = React.createContext<NavigatorDataContextType | undefined>(undefined)

function NavigatorProvider({children, navigateTo}: {
	children: React.ReactNode
	navigateTo: (path: string, options: NavigateToOptions | undefined) => void
}) {
	return (
		<NavigatorDataContext.Provider value={{navigateTo}}>
			{children}
		</NavigatorDataContext.Provider>
	)
}

function useNavigator() {
	const context = React.useContext(NavigatorDataContext)
	if (!context) {
		throw new Error("useNavigator must be used within a NavigatorProvider")
	}
	return context;
}

export {NavigatorProvider, useNavigator}