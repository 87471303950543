import {useTranslation} from "react-i18next";
import {useInternalDataContext} from "../MaterialModalProvider";
import Tabs from "../../../../../../../../components/ui/Tabs";
import Tab from "../../../../../../../../components/ui/Tab";
import {MaterialType} from "../../../../models/enums";
import React from "react";

export function MaterialModalTabs() {
	const {t} = useTranslation();
	const {modalTab} = useInternalDataContext()
	return (
		<div className="material-modal_container_tabs">
			<Tabs>
				<Tab label={t("projects.addItemModal.takeoffTemplates.tabName")}
					 active={modalTab.active === MaterialType.TEMPLATE}
					 onClick={() => modalTab.activate(MaterialType.TEMPLATE)}/>
				<Tab label={t("projects.addItemModal.preBuilds.tabName")}
					 active={modalTab.active === MaterialType.PREBUILD}
					 onClick={() => modalTab.activate(MaterialType.PREBUILD)}/>
				<Tab label={t("projects.addItemModal.catalog.tabName")}
					 active={modalTab.active === MaterialType.CATALOG}
					 onClick={() => modalTab.activate(MaterialType.CATALOG)}/>
			</Tabs>
		</div>
	)
}
