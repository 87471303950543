import React from "react";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {useUserConfirmationDataContext} from "../../../../../providers/UserConfirmationDataProvider";
import {usePlanUploadPromptCustomConfirmationModal} from "../hooks/usePlanUploadPromptCustomConfirmationModal";
import {checkIfAnyEditorIsDirty} from "../planUploadStore";
import {Beforeunload} from "react-beforeunload";
import {Prompt} from "react-router-dom";
import {PromptModalType} from "../../../../../models/enums";

export const PlanUploadUnsavedChangesPrompt: React.FC = ({children}) => {

	const {
		planUploadData: {
			isAnyEditorDirty, modals: {modal: planUploadPageModal}
		}
	} = useProjectDetailContext()
	const {data: userConfirmationData} = useUserConfirmationDataContext()
	const {renderPlanUploadPromptCustomConfirmationModal} = usePlanUploadPromptCustomConfirmationModal(userConfirmationData)

	function handleBeforeUnload(e: Event) {
		if (checkIfAnyEditorIsDirty()) {
			//Displays browser modal
			e.preventDefault();
		}
	}

	return (
		<Beforeunload onBeforeunload={handleBeforeUnload}>
			<Prompt
				when={isAnyEditorDirty.value && planUploadPageModal?.type !== "reselect-pages"}
				message={PromptModalType.PLAN_UPLOAD_UNSAVED_CHANGES}
			/>
			{renderPlanUploadPromptCustomConfirmationModal()}
			{children}
		</Beforeunload>
	)
}