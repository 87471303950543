import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Spinner from "./components/Spinner";
import './i18n';
import "react-datepicker/dist/react-datepicker.css";
import {ReactNotifications} from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

ReactDOM.render(
	<React.StrictMode>
		<ReactNotifications/>
		<Suspense fallback={<Spinner/>}>
			<App/>
		</Suspense>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
