import {TextGroup, TextItem, TextToolStyle} from "../../types";
import React, {MutableRefObject, useEffect, useRef} from "react";
import Konva from "konva";
import {useEditorDrawnItemsDataContext} from "../../../project-drawings/features/editor/EditorDrawnItemsDataProvider";
import {useUpdatedRef} from "../../../../hooks/useUpdatedRef";
import {
	RectPoints
} from "../../../project-drawings/features/editor/features/group-measurement-select/GroupMeasurementSelectionHelper";
import {Vector2d} from "konva/types/types";
import {BaseTextElementKonva, TextEventHandlers} from "./BaseTextElementKonva";
import {ShapeConfig} from "konva/types/Shape";
import {noop} from "lodash";

type BaseTextElementProps = {
	textGroup: TextGroup,
	textItem: TextItem,
	isTransformMode: MutableRefObject<boolean>,
	exportInProgress: boolean,
	isTextToolActive: boolean,
	onTransformEnd: (newPosition: Vector2d, scale: number, rotation: number) => void,
	handleDoubleClick?: () => void,
	handleClick: () => void,
	handleDragEnd: (evt: Konva.KonvaEventObject<Event>) => void,
	selectionStyle?: ShapeConfig,
	textHighlightStyle?: ShapeConfig,
	onEnableHighlight?: () => void,
	onClearHighlight?: () => void,
}
export const BaseTextElement: React.FC<BaseTextElementProps> = ({
	textItem,
	exportInProgress,
	isTextToolActive,
	textGroup,
	isTransformMode,
	onTransformEnd,
	handleClick,
	handleDoubleClick,
	handleDragEnd,
	selectionStyle,
	textHighlightStyle,
	onEnableHighlight,
	onClearHighlight,
}) => {
	const {style} = textGroup;
	const {id, position, rotation, text, isEditing, isSelected} = textItem;
	const textRef = useRef<Konva.Label>(null);
	const transformerRef = useRef<Konva.Transformer>(null);
	const showTransformer = !exportInProgress && isSelected && !isEditing && isTextToolActive
	const visible = (textGroup.visible && textItem.visible && textItem.text.length !== 0) || exportInProgress;
	const {setTextItemRectPoints} = useEditorDrawnItemsDataContext()
	const setTextItemRectPointsRef = useUpdatedRef(setTextItemRectPoints)

	useEffect(() => {
		if (transformerRef && textRef?.current) {
			transformerRef!.current?.nodes([textRef.current!])
			transformerRef!.current?.getLayer()?.batchDraw();
		}
	}, [showTransformer, style]);

	useEffect(() => {
		const text = textRef.current;
		const setTextItemRectPoints = setTextItemRectPointsRef.current;

		if (text) {
			const textSize = text.size()
			const rectPoints: RectPoints = {
				topLeft: {x: 0, y: 0},
				topRight: {x: textSize.width, y: 0},
				bottomRight: {x: textSize.width, y: textSize.height},
				bottomLeft: {x: 0, y: textSize.height}
			}
			const transform = text.getTransform();
			const transformPoint = (point: Vector2d): Vector2d => {
				return transform.point(point)
			};
			setTextItemRectPoints(textItem.id, {
				topLeft: transformPoint(rectPoints.topLeft),
				topRight: transformPoint(rectPoints.topRight),
				bottomRight: transformPoint(rectPoints.bottomRight),
				bottomLeft: transformPoint(rectPoints.bottomLeft)
			})
		}
	}, [textGroup.style, textItem, textRef, setTextItemRectPointsRef])

	function handleTransformEnd(evt: Konva.KonvaEventObject<Event>) {
		const newPosition = evt.target.position()
		const rotation = evt.target.attrs.rotation;
		const node = textRef.current;
		// We get only scaleX because only proportional scaling is enabled, so scaleX = scaleY
		const scaleX = node?.scaleX();
		// When rotating, scale floats around 1 - should be reasonably rounded.
		const scale = scaleX ? Math.round(scaleX * 1000) / 1000 : 1;
		onTransformEnd(newPosition, scale, rotation)
		// We have to reset node scale after applied transformation.
		node?.scaleX(1)
		node?.scaleY(1)
		// We set transform end in the parent component, check useText.tsx
	}

	function handleTransformStart() {
		isTransformMode.current = true
	}

	const eventsRef: React.MutableRefObject<TextEventHandlers> = useUpdatedRef(
		{
			onMouseEnter: onEnableHighlight ?? noop,
			onMouseLeave: onClearHighlight ?? noop,
			onClick: handleClick,
			onDblClick: handleDoubleClick,
			onTap: handleClick,
			onDblTap: handleDoubleClick,
			onDragEnd: handleDragEnd,
			onTransformEnd: handleTransformEnd,
			onTransformStart: handleTransformStart
		}
	)

	const styles: TextToolStyle & ShapeConfig = {
		...style,
		...textHighlightStyle,
		...selectionStyle
	}

	return (
		<BaseTextElementKonva
			visible={visible}
			textRef={textRef}
			position={position}
			rotation={rotation}
			isEditing={isEditing}
			isSelected={isSelected}
			eventsRef={eventsRef}
			text={text}
			styles={styles}
			id={id}
			showTransformer={showTransformer}
			transformerRef={transformerRef}
		/>
	);
};

