import {createSlice} from "@reduxjs/toolkit";
import {initialState as baseInitialState, reducers} from "../../../../../base-konva/features/text/baseTextSlice";
import {PlanUploadRootState} from "../../planUploadStore";
import {getId} from "../../../../../../utils";
import i18n from "../../../../../../i18n";
import {TextToolState} from "../../../../../base-konva/types";

const id = getId()

const initialState: TextToolState = {
	...baseInitialState,
	activeTextGroupId: id,
	textGroups: [{
		id,
		visible: true,
		name: `${i18n.t("editor.text.textGroup")} ${baseInitialState.textGroups.length + 1}`,
		textItems: [],
		style: baseInitialState.textSelectorStyle
	}]
}

const planUploadTextSlice = createSlice({
	name: 'plan-upload-text',
	initialState,
	reducers: {
		...reducers,
		reset: () => ({...initialState})
	}
})

export const planUploadTextReducer = planUploadTextSlice.reducer;
export const planUploadTextActions = planUploadTextSlice.actions;

export const selectPlanUploadTextGroups = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.text.textGroups;
export const selectPlanUploadActiveTextGroupId = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.text.activeTextGroupId;
export const selectPlanUploadTextSelectorStyle = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.text.textSelectorStyle;

