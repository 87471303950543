import {SimproApiErrorApiDto} from "./restModels";

export default class SimproApiError {
	constructor(
		public readonly message: string,
		// public readonly path: string,
		// public readonly value: string,
	) {}

	public static fromJson(json: SimproApiErrorApiDto): SimproApiError {
		return new SimproApiError(json.message);
	}
}