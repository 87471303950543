import React from "react";
import "./pageHeaderButtons.scss";

const PageHeaderButtons: React.FC = ({children}) => {
	return (
		<div className={"page-header-buttons"}>
			{children}
		</div>
	);
};

export default PageHeaderButtons
