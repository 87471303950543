import {DrawingVersion} from "../models/DrawingVersion";
import Version from "../models/Version";
import {VersionLabel} from "../models/enums";

export function getLatestDrawingVersion(versions: DrawingVersion[]) {
	return versions.length ? versions.sort((a, b) => compareVersions(a.version, b.version))[0] : undefined;
}

export function isTheSameVersion(first: Version, second: Version): boolean {
	return compareVersions(first, second) === 0;
}

export function compareVersions(first: Version, second: Version): -1 | 0 | 1 {
	if (first.major === second.major && first.minor === second.minor) {
		return 0;
	}
	return ((first.major > second.major) || (first.major === second.major && first.minor > second.minor)) ? -1 : 1
}

export function getLabeledDrawingVersions(versions: DrawingVersion[]): DrawingVersion[] {
	const latestVersion = getLatestDrawingVersion(versions);
	const latestCompletedVersion = getLatestDrawingVersion(versions.filter(v => v.isCompleted()))
	return versions.length ?
		[...versions].map(version => {
			let labels: VersionLabel[] = []
			if (version.isCompleted()) {
				labels.push(VersionLabel.COMPLETED);
				if (latestCompletedVersion && isTheSameVersion(version.version, latestCompletedVersion.version)) {
					labels.push(VersionLabel.LATEST)
				}
				else {
					labels.push(VersionLabel.SUPERSEDED);
				}
				if (latestVersion && isTheSameVersion(version.version, latestVersion.version)) {
					labels.push(VersionLabel.REVERTABLE)
				}
			}
			else {
				labels.push(VersionLabel.DRAFT)
				if (latestVersion && isTheSameVersion(version.version, latestVersion.version)) {
					labels.push(VersionLabel.LATEST)
				}
			}
			version.versionLabels = labels
			return version
		}).sort((dv1, dv2) => compareVersions(dv1.version, dv2.version)) : []
}