import React, {useCallback, useEffect, useState} from "react";
import {NavigationHeader} from "../../../../../routes";
import Page from "../../../../../components/layout/page/Page";
import {ProjectDetailsHeader} from "../../../components/ProjectDetailsHeader";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import "./projectPlanUploadPage.scss"
import {Provider} from "react-redux";
import {PlanUploadEditor} from "../components/PlanUploadEditor";
import {
	checkPagesScalePresence,
	deleteAllPlanUploadStoreInstances,
	deletePlanUploadStoreInstance,
	getPlanUploadStore
} from "../planUploadStore";
import Dropdown from "../../../../../components/ui/Dropdown";
import DropdownItem from "../../../../../components/ui/DropdownItem";
import OptionsDropdown from "../../../../../components/ui/OptionsDropdown";
import PlanUploadPanel from "../../../components/plan-templates/PlanUploadPanel";
import {useTranslation} from "react-i18next";
import {PlanUploadData, PlanUploadPageData} from "../types";
import {Status} from "../../../../../models/enums";
import Button from "../../../../../components/ui/Button";
import {useLocation, useParams} from "react-router-dom";
import InlineSpinner from "../../../../../components/InlineSpinner";
import {useImage} from "../../../../project-drawings/hooks/useImage";
import AttachmentApi from "../../../../../api/AttachmentApi";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import {EditorDrawnItemsDataProvider} from "../../../../project-drawings/features/editor/EditorDrawnItemsDataProvider";
import {PlanUploadUnsavedChangesPrompt} from "../components/PlanUploadUnsavedChangesPrompt";
import {PlanUploadPageModals} from "../components/modals/PlanUploadPageModals";
import {pages, useNavigator} from "../../../../navigator";

function useForceRender() {
	const [, setCount] = useState(0);
	const forceRender = useCallback(() => {
		setCount(prevState => prevState + 1)
	}, []);
	return {forceRender}
}

const ProjectPlanUploadPage = () => {

	const {forceRender} = useForceRender()
	const {
		header,
		forcePlanTemplatesUpdate,
		planTemplateModalsData: {openEditTemplateModal},
		planUploadData: {
			activePageNumber,
			editorPlanTemplateData,
			switchActivePageNumber,
			updateEditorPlanTemplateName,
			updateEditorPlanPage,
			deleteEditorPlanPage,
			clearEditorPlanTemplateData,
			currentEditorPlanPage,
			modals,
			savePlanPageData,
			saveAllPlanPagesData,
		}
	} = useProjectDetailContext()
	const {projectId} = useParams<{ projectId: string }>();
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {navigateTo} = useNavigator()
	const currentStore = getPlanUploadStore(activePageNumber)
	const {t} = useTranslation();

	useEffect(() => {
		forceRender()
	}, [activePageNumber, forceRender])

	const unmountDataRef = useUpdatedRef({
		clearEditorPlanTemplateData,
		deleteAllPlanUploadStoreInstances
	})

	useEffect(() => {
		const {
			clearEditorPlanTemplateData,
			deleteAllPlanUploadStoreInstances
		} = unmountDataRef.current
		return () => {
			clearEditorPlanTemplateData();
			deleteAllPlanUploadStoreInstances()
		}
	}, [unmountDataRef])

	const onPageClick = (page: PlanUploadPageData) => {
		switchActivePageNumber(page.pageNumber)
	}

	const onEditorPlanPageDeleteSuccess = (pageNumber: number, planTemplate: PlanUploadData) => {
		deletePlanUploadStoreInstance(pageNumber)
		deleteEditorPlanPage(pageNumber)
		if (pageNumber === activePageNumber) {
			const currentPageIndex = planTemplate.pages.findIndex(page => page.pageNumber === pageNumber)
			const nextPage = planTemplate.pages[currentPageIndex + 1] ?? planTemplate.pages[0]
			switchActivePageNumber(nextPage.pageNumber)
		}
	}

	const navigateToProjectDetails = () => {
		navigateTo(pages.projectDetailsPage(type, projectId), {replace: true})
	}

	const dataRef = useUpdatedRef({navigateToProjectDetails})
	useEffect(function navigateToProjectDetailsWhenNoPages() {
		const {navigateToProjectDetails} = dataRef.current
		if (!currentEditorPlanPage) {
			navigateToProjectDetails()
		}
	}, [currentEditorPlanPage, dataRef])

	const onCancelClick = () => {
		navigateToProjectDetails()
	}

	function handleConfirmReselectPages(template: PlanUploadData) {
		navigateToProjectDetails();
		openEditTemplateModal(template, forcePlanTemplatesUpdate);
	}

	async function handleSaveAllAndFinish() {
		if (!editorPlanTemplateData) {
			return;
		}

		const checkResult = checkPagesScalePresence()

		if (checkResult.allPagesHaveScale) {
			await saveAllPlanPagesData.saveAllPlanPages(
				projectId,
				editorPlanTemplateData,
				() => {
					forcePlanTemplatesUpdate();
					navigateToProjectDetails()
				}
			)
		}
		else {
			const noScalePages = editorPlanTemplateData.pages.filter(
				page => checkResult.noScalePageNumbers.includes(page.pageNumber)
			)
			const pageTitles = noScalePages.map(page => page.title)
			modals.openNoScaleInfoModal(pageTitles, onNoScaleInfoModalClose)
		}
	}

	function getImageUrl(planPage: PlanUploadPageData) {
		return AttachmentApi.getAttachmentDownloadUrl(planPage.attachmentId, planPage.title)
	}

	const [image, status] = useImage(
		currentEditorPlanPage ? getImageUrl(currentEditorPlanPage) : "",
		"anonymous"
	);

	const isLoading =
		status === Status.LOADING ||
		savePlanPageData.saveStatus === Status.LOADING ||
		saveAllPlanPagesData.saveStatus === Status.LOADING

	const onNoScaleInfoModalClose = () => {
		const closestPageWithNoScale = editorPlanTemplateData?.pages.find(
			page => !page.planScale
		)
		if (closestPageWithNoScale) {
			switchActivePageNumber(closestPageWithNoScale.pageNumber)
		}
	}

	const renderTemplateOptionsDropdown = (template: PlanUploadData) => {
		return (
			<OptionsDropdown>
				<DropdownItem
					label={t("planUploadEditor.planTemplateOptionsDropdown.rename")}
					onClick={() => { modals.openRenameTemplateModal(template, updateEditorPlanTemplateName); }}
				/>
				{template.isPdf ? (
					<DropdownItem
						label={t("planUploadEditor.planTemplateOptionsDropdown.reselectPages")}
						onClick={() => { modals.openReselectPagesModal(template, handleConfirmReselectPages); }}
					/>
				) : null}
			</OptionsDropdown>
		)
	}

	const renderPageOptionsDropdown = (
		template: PlanUploadData,
		page: PlanUploadPageData
	) => (selectedPageNumber: number | undefined) => {
		return (
			<Dropdown isActive={selectedPageNumber === page.pageNumber}>
				<DropdownItem
					label={t("planUploadEditor.planTemplatePageOptionsDropdown.rename")}
					onClick={() => { modals.openRenamePageModal(template, page, updateEditorPlanPage); }}
				/>
				{template.pages.length > 1 ? (
					<DropdownItem
						label={t("planUploadEditor.planTemplatePageOptionsDropdown.delete")}
						onClick={() => { modals.openDeletePageModal(template, page, onEditorPlanPageDeleteSuccess); }}
					/>
				) : null}
				<DropdownItem
					label={t("planUploadEditor.planTemplatePageOptionsDropdown.reset")}
					onClick={() => { modals.openResetPageModal(template, page); }}
				/>
			</Dropdown>
		)
	}

	return (
		<PlanUploadUnsavedChangesPrompt>
			<PlanUploadPageModals/>
			<div className="project-plan-upload-page">
				<Page>
					<div className="project-plan-upload-page_header">
						<NavigationHeader config={{saveAndFinishButtonVisibility: "hidden"}}>
							<Button
								variant={"tertiary"}
								label={t("planUploadEditor.header.cancel")}
								onClick={onCancelClick}
							/>
							<Button
								variant={"primary"}
								label={t("planUploadEditor.saveAllPlanPages.label")}
								onClick={handleSaveAllAndFinish}
								disabled={!editorPlanTemplateData}
							/>
						</NavigationHeader>
						<div className="project-plan-upload-page_header_title">
							<span className="project-plan-upload-page_header_title_editor-label">
								{t("planUploadEditor.header.title")}
							</span>
							{editorPlanTemplateData ? (
								<span className="project-plan-upload-page_header_title_filename-label">
									{`${editorPlanTemplateData.name} - ${
										editorPlanTemplateData.pages.find(p => p.pageNumber === activePageNumber)?.title
									}`}
								</span>
							) : null}
						</div>
						<ProjectDetailsHeader {...header}/>
					</div>
					<div className="project-plan-upload-page_body">
						{isLoading ? (
							<div className="project-plan-upload-page_body_overlay">
								<InlineSpinner height={40} width={40}/>
							</div>
						) : null}
						<div className="project-plan-upload-page_body_panel">
							{editorPlanTemplateData ? (
								<PlanUploadPanel
									selectedPageNumber={activePageNumber}
									onPageClick={onPageClick}
									planTemplates={[editorPlanTemplateData]}
									renderTemplateOptionsDropdown={renderTemplateOptionsDropdown}
									renderPageOptionsDropdown={renderPageOptionsDropdown}
								/>
							) : null}
						</div>
						{currentStore ? (
							<div className="project-plan-upload-page_body_editor">
								<Provider store={currentStore}>
									<EditorDrawnItemsDataProvider>
										<PlanUploadEditor image={image} isLoading={isLoading}/>
									</EditorDrawnItemsDataProvider>
								</Provider>
							</div>
						) : null}
					</div>
				</Page>
			</div>
		</PlanUploadUnsavedChangesPrompt>
	)
}

export {ProjectPlanUploadPage}