import {useState} from "react";
import TakeoffTemplateItem from "../../../../../../../../models/TakeoffTemplateItem";
import {Status} from "../../../../../../../../models/enums";
import {useTranslation} from "react-i18next";
import {useUpdatedRef} from "../../../../../../../../hooks/useUpdatedRef";
import {apiInstance} from "../../../../../../../../api/api";
import LoggerService from "../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../services/NotificationService";
import {TakeoffTemplateItemUpdateApiDto} from "../../../../../../../../models/restModels";

export function useTakeoffTemplateItems() {
	const [items, setItems] = useState<TakeoffTemplateItem[]>([]);
	const [loadStatus, setLoadStatus] = useState(Status.IDLE);
	const {t} = useTranslation();
	const loadTakeoffTemplateItemsRef = useUpdatedRef(loadTakeoffTemplateItems)

	function loadTakeoffTemplateItems(templateId: number) {
		setLoadStatus(Status.LOADING)
		apiInstance.takeoffTemplatesApi.fetchTakeoffTemplateItems(
			templateId
		).then(items => {
			setItems(items)
			setLoadStatus(Status.SUCCESS)
		}).catch(err => {
			setLoadStatus(Status.ERROR);
			LoggerService.logError(err);
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.addItemModal.takeoffTemplates.loadTemplateItemsErrorDesc")
			);
		})
	}

	function updateTakeoffTemplateItems(templateId: number, items: TakeoffTemplateItemUpdateApiDto[]) {
		setLoadStatus(Status.LOADING)
		apiInstance.takeoffTemplatesApi.putTakeoffTemplateItems(
			templateId, items
		).then(() => {
			setLoadStatus(Status.SUCCESS)
		}).catch(err => {
			setLoadStatus(Status.ERROR);
			LoggerService.logError(err);
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.addItemModal.takeoffTemplates.saveTakeoffTemplateItemsErrorDesc")
			)
		})
	}

	return {loadStatus, items, loadTakeoffTemplateItemsRef, updateTakeoffTemplateItems}
}