import {PenLine} from "../../types";
import {ShapeConfig} from "konva/types/Shape";
import React from "react";
import isEqual from "lodash/isEqual";
import {Line} from "react-konva";

type BasePenElementKonvaProps = {
	visible: boolean,
	line: PenLine,
	style: ShapeConfig,
	eventsRef: React.MutableRefObject<BasePenEventHandlers>
}

export type BasePenEventHandlers = {
	onMouseEnter?: () => void,
	onMouseLeave?: () => void,
	onClick?: () => void,
	onTap?: () => void
}

const _BasePenElementKonva = function({
	visible,
	line,
	style,
	eventsRef
}: BasePenElementKonvaProps) {

	function onMouseEnter() {
		eventsRef.current.onMouseEnter?.()
	}

	function onMouseLeave() {
		eventsRef.current.onMouseLeave?.()
	}

	function onClick() {
		eventsRef.current.onClick?.()
	}

	function onTap() {
		eventsRef.current.onTap?.()
	}

	return (
		<Line
			id={line.id}
			points={line.points}
			visible={visible}
			{...style}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			onClick={onClick}
			onTap={onTap}
		/>
	)
}

export const BasePenElementKonva = React.memo(_BasePenElementKonva, isEqual)