import {UserSummaryApiDto} from "./restModels";

export default class UserSummary {
	constructor(
		public readonly id: string,
		public readonly name: string | undefined,
	) {}

	public static fromJson(json: UserSummaryApiDto): UserSummary {
		return new UserSummary(json.id, json.name);
	}
}