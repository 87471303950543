import React from "react";
import {Group, Rect} from "react-konva";
import {EditorPoint, LengthMeasurementDrop} from "../models/editor";
import {KonvaEventObject} from "konva/types/Node";
import {Colors} from "../../../../../styles";
import DropMark from "./DropMark";
import {LINE_POINT_RECT_NAME, LINE_POINT_RECT_SIZE} from "../constants";
import {Vector2d} from "konva/types/types";
import {useLongTouch} from "../hooks/useLongTouch";
import isEqual from "lodash/isEqual";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";

export type LinePointProps = {
	point: EditorPoint,
	isActive?: boolean,
	drop?: LengthMeasurementDrop,
	onMouseEnter: (evt: KonvaEventObject<MouseEvent>) => void
	onMouseLeave: (evt: KonvaEventObject<MouseEvent>) => void
	onDragStart: (evt: KonvaEventObject<DragEvent>) => void
	onDragMove: (evt: KonvaEventObject<DragEvent>) => void
	onDragEnd: (evt: KonvaEventObject<DragEvent>) => void
	onLongTouch?: (evt: KonvaEventObject<TouchEvent>, touchPosition: Vector2d) => void
}
export const LinePoint: React.FC<LinePointProps> = ({
	point,
	isActive = false,
	drop,
	onMouseEnter, onMouseLeave, onDragStart, onDragMove, onDragEnd, onLongTouch
}) => {
	const eventsRef = useUpdatedRef({
		onMouseEnter,
		onMouseLeave,
		onDragStart,
		onDragMove,
		onDragEnd,
		onLongTouch
	})

	return (
		<LinePointKonva
			point={point}
			isActive={isActive}
			drop={drop}
			eventsRef={eventsRef}
		/>
	)
}

type LinePointKonvaProps = {
	point: EditorPoint,
	isActive?: boolean,
	drop?: LengthMeasurementDrop,
	eventsRef: React.MutableRefObject<{
		onMouseEnter: (evt: KonvaEventObject<MouseEvent>) => void
		onMouseLeave: (evt: KonvaEventObject<MouseEvent>) => void
		onDragStart: (evt: KonvaEventObject<DragEvent>) => void
		onDragMove: (evt: KonvaEventObject<DragEvent>) => void
		onDragEnd: (evt: KonvaEventObject<DragEvent>) => void
		onLongTouch?: (evt: KonvaEventObject<TouchEvent>, touchPosition: Vector2d) => void
	}>
}

function _LinePointKonva({
	point, isActive, drop, eventsRef
}: LinePointKonvaProps) {

	function onLongTouch(evt: KonvaEventObject<TouchEvent>, touchPosition: Vector2d) {
		eventsRef.current.onLongTouch?.(evt, touchPosition)
	}

	function onDragStart(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragStart(evt)
	}

	function onDragMove(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragMove(evt)
	}

	function onDragEnd(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragEnd(evt)
	}

	function onMouseEnter(evt: KonvaEventObject<MouseEvent>) {
		eventsRef.current.onMouseEnter(evt)
	}

	function onMouseLeave(evt: KonvaEventObject<MouseEvent>) {
		eventsRef.current.onMouseLeave(evt)
	}

	// Rect size is increased to compensate stroke width.
	// Stroke type is center, so to maintain inner size for stroke 2px we should increase rect size.
	const rectSize = drop ? LINE_POINT_RECT_SIZE + 2 : LINE_POINT_RECT_SIZE;
	const {events, cancel: cancelLongTouch} = useLongTouch(onLongTouch)

	return (
		<Group x={point.position.x}
			   y={point.position.y}
			   draggable={true}
			   onTouchStart={events.onTouchStart}
			   onTouchMove={events.onTouchMove}
			   onTouchEnd={events.onTouchEnd}
			   onDragStart={evt => {
				   cancelLongTouch()
				   onDragStart(evt)
			   }}
			   {...{
				   onMouseEnter,
				   onMouseLeave,
				   onDragMove,
				   onDragEnd,
			   }}
		>
			<Rect key={point.id} id={point.id}
				  name={LINE_POINT_RECT_NAME}
				  offsetX={rectSize / 2}
				  offsetY={rectSize / 2}
				  width={rectSize} height={rectSize}
				  fill={isActive ? Colors.GREEN : drop ? Colors.WHITE : Colors.GREY}
				  stroke={Colors.SIMPROBLUE}
				  strokeWidth={drop ? 2 : 0}
				  cornerRadius={drop ? 5 : 0}
			/>
			{drop?.value ? <DropMark value={drop.value}/> : null}
		</Group>
	)
}

const LinePointKonva = React.memo(_LinePointKonva, isEqual)