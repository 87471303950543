import React from "react";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import "./createSectionInputField.scss";
import {useTranslation} from "react-i18next";
import TextInput from "../../../../project-drawings/features/editor/components/TextInput";
import Section from "../../../../../models/Section";
import {TextButton} from "../../../../../components/ui/TextButton";

interface CreateSectionInputFieldProps {
	isSectionFieldInCreateMode: boolean
	sectionName: string
	onSectionNameChange: (evt: React.ChangeEvent<HTMLInputElement>) => void
	handleSectionChange: (sectionId: string) => void
	sections: Section[]
	sectionId: string | undefined
	toggleCreateSection: () => void
}


const CreateSectionInputField: React.FC<CreateSectionInputFieldProps> = ({
		isSectionFieldInCreateMode,
		sectionName,
		onSectionNameChange,
		handleSectionChange,
		sections,
		sectionId,
		toggleCreateSection
	}) => {
		const {t} = useTranslation();

		return (
			<div className="create-section-input">
				<div className="create-section-input_label">
					{t("projects.details.createSectionInputField.label")}
				</div>
				{isSectionFieldInCreateMode ?
					<TextInput value={sectionName} onChange={onSectionNameChange}
							   placeholder={t("projects.details.createSectionInputField.textInputPlaceholder")}
							   className={"create-section-input_input"}/> :
					<CustomSelect placeholder={t("projects.details.createSectionInputField.selectPlaceholder")}
								  customClass="create-section-input_select"
								  items={sections.map(s => ({label: s.getLabel(), value: s.id}))}
								  onChange={handleSectionChange}
								  value={sectionId}/>
				}
				<div className="create-section-input_create-new-section-label-container">
					<TextButton onClick={toggleCreateSection} fontSize={10}>
						{isSectionFieldInCreateMode ?
							t("projects.details.createSectionInputField.chooseExistingSectionLabel") :
							t("projects.details.createSectionInputField.createNewSectionLabel")
						}
					</TextButton>
				</div>
			</div>
		);
	}
;

export default CreateSectionInputField;
