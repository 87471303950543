import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorTR: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M7.602,18.29l8.912,-8.912l0.004,5.441l1.772,0.001l-0.008,-8.477l-8.477,-0.008l0.002,1.772l5.44,0.004l-8.912,8.912l1.267,1.267Z"]}/>
	);
};

export default AnchorTR