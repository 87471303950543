import {useCallback, useEffect, useMemo, useState} from "react";
import {PlanUploadData, PlanUploadPageData} from "../types";
import {checkIfAnyEditorIsDirty, initPlanUploadStoreMap, markPlanUploadStoreAsDirty} from "../planUploadStore";
import {useSaveAllPlanPages, useSavePlanPageData} from "./savePlanHooks";
import {useModalsData} from "./useModalsData";

export function usePlanUploadData() {

	const [activePageNumber, setActivePageNumber] = useState<number | undefined>();
	const [editorPlanTemplateData, setEditorPlanTemplateData] = useState<PlanUploadData | undefined>();

	const [isAnyEditorDirty, setIsAnyEditorDirty] = useState(false);
	const triggerEditorIsDirtyCheck = useCallback(() => {
		setIsAnyEditorDirty(checkIfAnyEditorIsDirty())
	}, [])
	useEffect(function synchronizeIsDirtyState() {
		triggerEditorIsDirtyCheck()
	}, [activePageNumber, editorPlanTemplateData, triggerEditorIsDirtyCheck]);

	const currentEditorPlanPage = useMemo(() => {
		return editorPlanTemplateData?.pages.find(page => page.pageNumber === activePageNumber)
	}, [activePageNumber, editorPlanTemplateData]);

	const modals = useModalsData()

	const savePlanPageData = useSavePlanPageData({
		editorPlanTemplateData,
		updateEditorPlanPage
	})
	const saveAllPlanPagesData = useSaveAllPlanPages({
		triggerEditorIsDirtyCheck
	})

	function initEditorPlanUploadData(data: PlanUploadData) {
		const [firstPage] = data.pages
		if (!firstPage) {
			throw new Error("Cannot init plan upload data: template has no pages")
		}
		initPlanUploadStoreMap(data.pages)
		setActivePageNumber(firstPage.pageNumber)
		setEditorPlanTemplateData(data)
	}

	function clearEditorPlanTemplateData() {
		setEditorPlanTemplateData(undefined)
	}

	function updateEditorPlanTemplateName(newName: string) {
		if (editorPlanTemplateData !== undefined)
			setEditorPlanTemplateData({...editorPlanTemplateData, name: newName})
	}

	const switchActivePageNumber = (pageNumber: number) => {
		setActivePageNumber(pageNumber)
	}

	const deleteEditorPlanPage = (pageNumber: number) => {
		if (editorPlanTemplateData !== undefined) {
			const deletedPageIndex = editorPlanTemplateData.pages.findIndex(page => page.pageNumber === pageNumber)
			if (deletedPageIndex > -1) {
				const editedPlanPages = [...editorPlanTemplateData.pages]
				editedPlanPages.splice(deletedPageIndex, 1)
				setEditorPlanTemplateData({...editorPlanTemplateData, pages: editedPlanPages})
			}
		}
	}

	function updateEditorPlanPage(newPage: PlanUploadPageData) {
		if (editorPlanTemplateData !== undefined) {
			const editedPageIndex = editorPlanTemplateData.pages.findIndex(page => page.pageNumber === newPage.pageNumber)
			if (editedPageIndex > -1) {
				const editedPlanPages = [...editorPlanTemplateData.pages]
				editedPlanPages[editedPageIndex] = newPage;
				markPlanUploadStoreAsDirty(newPage.pageNumber)
				setEditorPlanTemplateData({...editorPlanTemplateData, pages: editedPlanPages})
			}
		}
	}

	return {
		activePageNumber,
		currentEditorPlanPage,
		switchActivePageNumber,
		editorPlanTemplateData,
		initEditorPlanUploadData,
		updateEditorPlanTemplateName,
		deleteEditorPlanPage,
		updateEditorPlanPage,
		clearEditorPlanTemplateData,
		modals,
		savePlanPageData,
		saveAllPlanPagesData,
		isAnyEditorDirty: {
			value: isAnyEditorDirty,
			triggerCheck: triggerEditorIsDirtyCheck
		}
	}
}