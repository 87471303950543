import {SelectionState} from "../../../../../base-konva/types";

export const STROKE_FRAME_WIDTH = 3

export type SymbolRecognitionResultsReferentialData = {
	x: number,
	y: number,
	matchQuality: number,
	rotationAngleInDegrees: number
}

export type SymbolRecognitionState = {
	panelStage: SymbolRecognitionPanelStage
	selection?: SelectionState
	symbolRecognitionResults: SymbolRecognitionResults
}

export type SymbolRecognitionResults = {
	referentialData: SymbolRecognitionResultsReferentialData[],
	rejectedIndexes: number[],
	approvedIndexes: number[],
	remainingIndexes: number[],
	displayableIndexes: number[],
	activeIndex: number
}

export enum SymbolRecognitionPanelStage {
	INITIAL = "INITIAL",
	SYMBOL_SELECTION = "SYMBOL_SELECTION",
	PROCESSING = "PROCESSING",
	MATCHES_DISPLAY = "MATCHES_DISPLAY",
}