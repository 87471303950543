import React from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import IconWithTooltip from "../../../../components/ui/IconWithTooltip";
import IconUndo from "../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconUndo";
import {ActionCreators} from "redux-undo";
import IconRedo from "../../../project-drawings/features/editor/icons/unselectableEditorHeaderIcons/IconRedo";

type BaseEditorHeaderUndoPanelProps = {
	hasUndo:boolean,
	hasRedo:boolean,
	disabled?: boolean,
}
const BaseEditorHeaderUndoPanel: React.FC<BaseEditorHeaderUndoPanelProps> = ({disabled, hasRedo, hasUndo}) => {
	const {t} = useTranslation();

	const dispatch = useDispatch()

	return (
		<>
			<IconWithTooltip icon={<IconUndo disabled={!hasUndo || disabled}
											 onClick={() => dispatch(ActionCreators.undo())}/>}
							 tooltipText={t("editor.tooltip.undo")}/>
			<IconWithTooltip icon={<IconRedo disabled={!hasRedo || disabled}
											 onClick={() => dispatch(ActionCreators.redo())}/>}
							 tooltipText={t("editor.tooltip.redo")}/>
		</>
	);
};

export default BaseEditorHeaderUndoPanel
