import React, {useEffect} from "react";
import usePlanTemplates from "../hooks/usePlanTemplates";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {usePlanTemplateModalsData, useProjectDetails, useProjectDetailsHeaderData, useProjectTreeData} from "../hooks";
import {ProjectSource, ProjectType} from "../../../models/enums";
import {useBreadcrumbsContext} from "../../breadcrumbs";
import useUsers from "../../../hooks/useUsers";
import {usePlanUploadData} from "../features/plan-upload";
import {PlanTemplateModals} from "../components/plan-templates/modals/PlanTemplateModals";
import useFitTimes from "../../../hooks/useFitTimes";

type ProjectDetailContextType = {
	projectDetails: ReturnType<typeof useProjectDetails>
	isServiceProject: boolean
	isJobProject: boolean
	onlyOneCostCenterInProject: boolean
	planTemplates: ReturnType<typeof usePlanTemplates>
	planTemplateModalsData: ReturnType<typeof usePlanTemplateModalsData>
	header: ReturnType<typeof useProjectDetailsHeaderData>
	treeData: ReturnType<typeof useProjectTreeData>
	planUploadData: ReturnType<typeof usePlanUploadData>
	forceProjectUpdate: () => Promise<void>
	forceTreeUpdate: () => void
	refreshProjectTreeAfterSimproUpdate: () => void
	forceTreeUpdateAsync: () => Promise<void>
	forcePlanTemplatesUpdate: () => void
	userData: ReturnType<typeof useUsers>
	fitTimesData: ReturnType<typeof useFitTimes>
}
const ProjectDetailContext = React.createContext<ProjectDetailContextType | undefined>(undefined)
type Props = {
	projectId: string | undefined
}
const ProjectDetailProvider: React.FC<Props> = function({projectId, children}) {
	const projectDetails = useProjectDetails()

	const header = useProjectDetailsHeaderData(projectDetails)
	const planTemplates = usePlanTemplates(projectId);
	const planTemplateModalsData = usePlanTemplateModalsData()
	const treeData = useProjectTreeData()
	const planUploadData = usePlanUploadData()
	const userData = useUsers()
	const fitTimesData = useFitTimes()

	const {setProjectData} = useBreadcrumbsContext()
	const treeExpandDataRef = useUpdatedRef(treeData.treeExpandData)
	useEffect(() => {
		setProjectData({
			project: projectDetails.project,
			treeExpandDataRef: treeExpandDataRef,
			sections: treeData.sections
		})
		return function() {
			setProjectData(undefined)
		}
	}, [setProjectData, projectDetails.project, treeData.sections, treeExpandDataRef])

	const isServiceProject = projectDetails.project?.type === ProjectType.SERVICE
	const isJobProject = projectDetails.project?.source === ProjectSource.JOB
	const onlyOneCostCenterInProject = treeData.sections.flatMap(section => section.costCenters).length <= 1

	const updatedDataRef = useUpdatedRef({
		loadPlanTemplates: planTemplates.loadPlanTemplates,
		loadProjectDetails: projectDetails.loadProjectDetails,
		loadTree: treeData.loadProjectTree,
		loadCompanyCostCenters: treeData.loadCompanyCostCenters
	})
	useEffect(() => {
		const {
			loadPlanTemplates,
			loadTree,
			loadCompanyCostCenters,
		} = updatedDataRef.current

		if (projectId) {
			loadPlanTemplates()
			loadTree(projectId).then()
			loadCompanyCostCenters().then()
		}
	}, [projectId, updatedDataRef]);

	async function forceProjectUpdate() {
		if (projectId) {
			await projectDetails.loadProjectDetails(projectId)
		}
	}

	function forceTreeUpdate() {
		if (projectId) {
			treeData.loadProjectTree(projectId).then()
		}
	}

	/**
	 * Syncing Sections and Cost Centers information with simPRO before tree load.
	 * Sections and Cost Centers deleted at simPRO are automatically deleted by our backend side if empty
	 */
	async function refreshProjectTreeAfterSimproUpdate() {
		forceProjectUpdate().then(() => {forceTreeUpdate()})
	}

	async function forceTreeUpdateAsync() {
		if (projectId) {
			await treeData.loadProjectTree(projectId)
		}
	}

	function forcePlanTemplatesUpdate() {
		planTemplates.loadPlanTemplates()
	}

	const providerValue: ProjectDetailContextType = {
		projectDetails,
		isServiceProject,
		isJobProject,
		onlyOneCostCenterInProject,
		planTemplates,
		planTemplateModalsData,
		header,
		treeData,
		planUploadData,
		forceProjectUpdate,
		forceTreeUpdate,
		refreshProjectTreeAfterSimproUpdate,
		forceTreeUpdateAsync,
		forcePlanTemplatesUpdate,
		userData,
		fitTimesData,
	}

	return (
		<ProjectDetailContext.Provider value={providerValue}>
			<PlanTemplateModals/>
			{children}
		</ProjectDetailContext.Provider>
	)
}

function useProjectDetailContext() {
	const context = React.useContext(ProjectDetailContext)
	if (context === undefined) {
		throw new Error("useProjectDetailContext must be used within a ProjectDetailProvider")
	}
	return context;
}

const ProjectDetailContextBridge = {
	Provider: ProjectDetailContext.Provider
}

export {ProjectDetailProvider, useProjectDetailContext, ProjectDetailContextBridge}