import React from "react";
import {ImageData} from "../../../../../base-konva/types";
import {BaseImageElement, BaseImageElementActions} from "../../../../../base-konva/features/image/BaseImageElement";
import {useDispatch, useSelector} from "react-redux";
import {selectExportInProgress, viewActions} from "../view/viewSlice";
import {imageActions, selectActiveImageId} from "./imageSlice";
import {configActions, selectActiveTool} from "../config/configSlice";
import {EditorPanelTabKeys, EditorTool} from "../../models/enums";
import {useGetSelectionShapeStyle, useSelectSetter} from "../../hooks/select";
import {useGetHighlightShapeStyle, useHighlightSetter} from "../../hooks/highlight";
import {useActivate} from "../../hooks/activate";
import {useEditorPanelDataContext} from "../panel/EditorPanelProvider";
import {DrawingItemType} from "../../../../../base-konva/enums";

type ImageElementProps = {
	imageData: ImageData
}
export const ImageElement: React.FC<ImageElementProps> = ({imageData}) => {
	const dispatch = useDispatch();
	const activeImageId = useSelector(selectActiveImageId);
	const activeTool = useSelector(selectActiveTool);
	const exportInProgress = useSelector(selectExportInProgress);
	const selectActions = useSelectSetter();
	const highlightActions = useHighlightSetter(imageData.id);
	const activateActions = useActivate();
	const {getHighlightStyle} = useGetHighlightShapeStyle();
	const {getSelectionStyle} = useGetSelectionShapeStyle();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext();
	const isElementActive = activeTool === EditorTool.IMAGE && activeImageId === imageData.id;

	const actions: BaseImageElementActions = {
		setRemoteItemLoaded: (id) => {dispatch(viewActions.setRemoteItemLoaded(id))},
		activateImage: (id) => {dispatch(imageActions.activateImage({id}))},
		transformImage: (id, newPosition, rotation, scale) => {
			dispatch(imageActions.transformImage({itemId: id, newPosition, rotation, scale}))
		},
	}

	const getStyle = (id: string) => {
		const highlightStyle = getHighlightStyle(
			(id) => id === imageData.id
		);
		const selectionStyle = getSelectionStyle(highlighted => (
			highlighted.some(item => (
				item.type === DrawingItemType.IMAGE &&
				item.itemId === id
			))
		))

		return {
			...highlightStyle,
			...selectionStyle
		}
	}

	function selectElement(id: string) {
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.IMAGE}))
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.MEASUREMENT_SELECT}))
		activateActions.activateImage(id)
		selectActions.clearSelection()
		selectActions.setSelection(DrawingItemType.IMAGE, id)
		getExpandState(EditorPanelTabKeys.LAYERS).expandTab();
	}

	return (
		<BaseImageElement
			isEditorToolSelect={activeTool === EditorTool.MEASUREMENT_SELECT}
			isElementActive={isElementActive}
			getStyle={getStyle}
			selectElement={selectElement}
			onEnableHighlight={highlightActions.setHighlight}
			onClearHighlight={highlightActions.clearHighlight}
			exportInProgress={exportInProgress}
			actions={actions}
			imageData={imageData}/>
	)
};
