import React, {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import {DrawingType, Status} from "../../../../../models/enums";
import "./modalContent.scss";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import useDrawingVersionMove from "../../../hooks/useDrawingVersionMove";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";

interface MoveDrawingModalProps {
	isOpen: boolean,
	costCenterId: string,
	drawingId: string,
	drawingType: DrawingType
	onSuccess: () => void,
	onClose: () => void,
}

const MoveDrawingModal: React.FC<MoveDrawingModalProps> = ({
	isOpen,
	costCenterId,
	drawingId,
	drawingType,
	onSuccess,
	onClose
}) => {
	const [sectionId, setSectionId] = useState<string | undefined>(undefined);
	const [ccId, setCcId] = useState<string | undefined>(undefined);
	const {isServiceProject, treeData: {sections}} = useProjectDetailContext()
	const {t} = useTranslation();

	const {saveStatus, moveDrawingVersion} = useDrawingVersionMove(drawingId, drawingType, onSuccess)

	useEffect(() => {
		setSectionId(isServiceProject ? sections[0]?.id : undefined)
	}, [sections, isServiceProject]);

	const handleSubmit = () => {
		ccId && moveDrawingVersion(ccId)
	}

	const handleSectionChange = (sectionId: string) => {
		setSectionId(sectionId);
		setCcId(undefined);
	};

	const sectionsToSelect = useMemo(() => {
		return sections
			.filter(section => !section.deletedExternal)
			.map(s => ({label: s.getLabel(), value: s.id}))
	}, [sections])

	const costCentersToSelect = useMemo(() => {
		return sections
			.filter(section => !section.deletedExternal)
			.find(s => s.id === sectionId)?.costCenters
			.filter(cc => cc.id !== costCenterId && !cc.deletedExternal)
			.map(cc => ({label: cc.name, value: cc.id})) || [];
	}, [costCenterId, sectionId, sections])

	const clearData = () => {
		setCcId(undefined);
	};

	const handleClose = () => {
		clearData();
		onClose();
	};

	const translations = {
		"modalTitle": {
			[DrawingType.TAKE_OFF]: t(`projects.details.moveDrawingModal.title.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.moveDrawingModal.title.as_built`)
		},
		"saveLabel": {
			[DrawingType.TAKE_OFF]: t(`projects.details.moveDrawingModal.button.take_off`),
			[DrawingType.AS_BUILT]: t(`projects.details.moveDrawingModal.button.as_built`)
		}
	}

	return (
		<EditValueModal isOpen={isOpen}
						onClose={handleClose}
						onSave={handleSubmit}
						title={translations["modalTitle"][drawingType]}
						disableSave={!ccId || saveStatus === Status.LOADING}
						isLoading={saveStatus === Status.LOADING}
						saveLabel={translations["saveLabel"][drawingType]}>
			{!isServiceProject ?
				<div className="modal-content_field">
					<div className="modal-content_field_label">
						{t("projects.details.createNewDrawingModal.sectionLabel")}
					</div>
					<CustomSelect placeholder={t("projects.details.copyDrawingModal.sectionPlaceholder")}
								  customClass="modal-content_field_select"
								  items={sectionsToSelect}
								  onChange={handleSectionChange}
								  value={sectionId}/>
				</div> : null
			}
			<div className="modal-content_field">
				<div className="modal-content_field_label">
					{t("projects.details.moveDrawingModal.ccLabel")}
				</div>
				<CustomSelect placeholder={t("projects.details.moveDrawingModal.ccPlaceholder")}
							  customClass="modal-content_field_select"
							  items={costCentersToSelect}
							  onChange={setCcId}
							  value={ccId}/>
			</div>
		</EditValueModal>
	);
};

export default MoveDrawingModal;
