import {useSelector} from "react-redux";
import {selectActiveTool, selectStageConfig} from "./features/config/configSlice";
import React, {useState} from "react";
import {selectLengths} from "./features/length/lengthSlice";
import {selectCountGroups} from "./features/count/countSlice";
import {useArea} from "./features/area/useArea";
import {Group, Rect} from "react-konva";
import {selectAreas} from "./features/area/areaSlice";
import {useLength} from "./features/length/useLength";
import {useCount} from "./features/count/useCount";
import {KonvaEventObject} from "konva/types/Node";
import {EditorTool} from "./models/enums";
import {AreaMeasurement, ToolHookResult} from "./models/editor";
import {selectPanInProgress} from "./features/view/viewSlice";
import usePen from "./features/pen/usePen";
import {selectPenGroups} from "./features/pen/penSlice";
import {selectTextGroups} from "./features/text/textSlice";
import {useText} from "./features/text/useText";
import {selectImages} from "./features/image/imageSlice";
import {useImage} from "./features/image/useImage";
import {setCurrentToolOnTop} from "../../../base-konva/utils";
import {KonvaEventType} from "../../../base-konva/types";


type MeasurementsLayerProps = {
	listening?: boolean
	visible?: boolean
}
export const MeasurementsLayer: React.FC<MeasurementsLayerProps> = function({
	listening = false,
	visible = true,
}) {

	const stageConfig = useSelector(selectStageConfig)
	const activeTool = useSelector(selectActiveTool)
	const panInProgress = useSelector(selectPanInProgress);
	const [isPointerOverLayer, setIsPointerOverLayer] = useState(false)

	const lengths = useSelector(selectLengths)
	const areaBasedMeasurements = useSelector(selectAreas)
	const countGroups = useSelector(selectCountGroups)
	const penDrawGroups = useSelector(selectPenGroups)
	const textGroups = useSelector(selectTextGroups)
	const images = useSelector(selectImages)

	let areas: AreaMeasurement[] = [];
	let volumes: AreaMeasurement[] = [];
	for (let areaElement of areaBasedMeasurements) {
		if (areaElement.height === undefined) {
			areas.push(areaElement)
		}
		else {
			volumes.push(areaElement)
		}
	}

	const lengthResult = useLength({isPointerOverLayer, lengths})
	const areaResult = useArea({isPointerOverLayer, hookTool: EditorTool.AREA, areas});
	const volumeResult = useArea({isPointerOverLayer, hookTool: EditorTool.VOLUME, areas: volumes})
	const countResult = useCount(countGroups)
	const penResult = usePen(penDrawGroups)
	const textResult = useText(textGroups)
	const imageResult = useImage(images)

	const resultArray = [lengthResult, areaResult, volumeResult, countResult, penResult, textResult, imageResult]

	function getCurrent() {
		return resultArray.find(result => result.tool === activeTool)
	}

	function handleMouseDown(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		getCurrent()?.callbacks.onMouseDown?.(e)
	}

	function handleMouseUp(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		else getCurrent()?.callbacks.onMouseUp?.(e)
	}

	function handleMouseMove(e: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		getCurrent()?.callbacks.onMouseMove?.(e)
	}

	function handleMouseEnter() {
		setIsPointerOverLayer(true)
	}

	function handleMouseLeave() {
		setIsPointerOverLayer(false)
	}

	function getGroupListening(result: ToolHookResult) {
		if (activeTool === EditorTool.MEASUREMENT_SELECT || activeTool === EditorTool.ARC) {
			return true
		}
		return activeTool === result.tool
	}

	function getGroupVisibility(result: ToolHookResult) {
		if (activeTool === EditorTool.SCALE) {
			if (result.tool === EditorTool.LENGTH ||
				result.tool === EditorTool.AREA ||
				result.tool === EditorTool.VOLUME ||
				result.tool === EditorTool.COUNT
			) {
				return false
			}
		}
		return true
	}

	return (
		<Group
			listening={listening}
			visible={visible}
			onMouseDown={handleMouseDown}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onTouchStart={handleMouseDown}
			onTouchEnd={handleMouseUp}
			onTouchMove={handleMouseMove}>
			<Rect
				name={"background_rect_measurements_layer"}
				width={stageConfig.width}
				height={stageConfig.height}/>
			{setCurrentToolOnTop(resultArray, activeTool).map(result => (
				<Group key={result.id} listening={getGroupListening(result)} visible={getGroupVisibility(result)}>
					{result.render()}
				</Group>
			))}
		</Group>
	)
}
