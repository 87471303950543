import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Colors} from "../../../../../../styles";
import {getId} from "../../../../../../utils";
import {PlanUploadEditorTool, PlanUploadToolHookResult} from "../../types";
import {distance} from "../../../../../project-drawings/features/editor/utils";
import {planUploadEraseAreaActions, selectPlanUploadEraseAreaSelectionState} from "./planUploadEraseAreaSlice";
import {useBaseSelection} from "../../hooks/useBaseSelection";

function usePlanUploadEraseArea(): PlanUploadToolHookResult {
	const [renderId] = useState(() => getId())

	const eraseAreaSelectionState = useSelector(selectPlanUploadEraseAreaSelectionState)
	const dispatch = useDispatch()

	const {render, onMouseUp, onMouseMove} = useBaseSelection(
		eraseAreaSelectionState,
		vertices => {
			dispatch(planUploadEraseAreaActions.setSelection({
				x: vertices.topLeft.x,
				y: vertices.topLeft.y,
				height: distance(vertices.topLeft, vertices.bottomLeft),
				width: distance(vertices.topLeft, vertices.topRight)
			}))
		},
		Colors.RED
	)

	return {
		id: renderId,
		render,
		tool: PlanUploadEditorTool.ERASE_AREA,
		callbacks: {
			onMouseUp,
			onMouseMove,
		}
	}
}

export {usePlanUploadEraseArea}