import React, {FC} from "react";
import {useGetHighlightShapeStyle, useHighlightSetter} from "../../hooks/highlight";
import {useDispatch, useSelector} from "react-redux";
import {configActions, selectActiveTool} from "../config/configSlice";
import {EditorPanelTabKeys, EditorTool} from "../../models/enums";
import {useActivate} from "../../hooks/activate";
import {useGetSelectionShapeStyle, useSelectSetter} from "../../hooks/select";
import {useEditorPanelDataContext} from "../panel/EditorPanelProvider";
import {selectExportInProgress} from "../view/viewSlice";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {getDashTypePointArray} from "../../utils";
import {ShapeConfig} from "konva/types/Shape";
import {PenGroup, PenLine} from "../../../../../base-konva/types";
import {BasePenElementKonva, BasePenEventHandlers} from "../../../../../base-konva/features/pen";
import {DrawingItemType} from "../../../../../base-konva/enums";

type PenElementProps = {
	line: PenLine
	group: PenGroup
	isDrawing: boolean
}
const _PenElement: FC<PenElementProps> = function({
	line,
	group,
	isDrawing,
}) {

	const activeTool = useSelector(selectActiveTool);
	const exportInProgress = useSelector(selectExportInProgress);
	const {getHighlightStyle} = useGetHighlightShapeStyle();
	const highlightActions = useHighlightSetter(line.id);
	const highlightStyle = getHighlightStyle(
		(id) => id === line.id || id === group.id
	);
	const {getSelectionStyle} = useGetSelectionShapeStyle();
	const selectionStyle = getSelectionStyle(highlighted => (
		highlighted.some(item => (
			item.type === DrawingItemType.PEN &&
			item.itemId === group.id &&
			(item.subItemIds && item.subItemIds.some(id => id === line.id))
		))
	))
	const dispatch = useDispatch();
	const activateActions = useActivate();
	const selectActions = useSelectSetter();
	const {takeoffs: {getExpandState}} = useEditorPanelDataContext();

	function selectElement() {
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.PEN}))
		dispatch(configActions.switchEditorTool({editorTool: EditorTool.MEASUREMENT_SELECT}))
		activateActions.activatePen(line.id, group.id)
		selectActions.clearSelection();
		selectActions.setSelection(DrawingItemType.PEN, group.id, undefined, [line.id])
		getExpandState(EditorPanelTabKeys.LAYERS).expandTab();
	}

	const events: BasePenEventHandlers = {
		onMouseEnter: highlightActions.setHighlight,
		onMouseLeave: highlightActions.clearHighlight
	}
	if (activeTool === EditorTool.MEASUREMENT_SELECT) {
		events.onClick = selectElement
		events.onTap = selectElement
	}

	const eventsRef: React.MutableRefObject<BasePenEventHandlers> = useUpdatedRef(
		isDrawing ? {} : events
	)

	const style: ShapeConfig = {
		...group.style,
		...highlightStyle,
		...selectionStyle,
		lineCap: "round",
		lineJoin: "round",
		dash: getDashTypePointArray(group.style.dashType, group.style.strokeWidth)
	}

	const visible = (group.visible && line.visible) || exportInProgress;

	return (
		<BasePenElementKonva
			visible={visible}
			line={line}
			style={style}
			eventsRef={eventsRef}
		/>
	)
}

export const PenElement = React.memo(_PenElement)
