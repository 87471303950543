import React, {useState} from "react";
import {Vector2d} from "konva/types/types";
import {KonvaEventObject} from "konva/types/Node";
import {BaseStageConfig, KonvaEventType, SnappingState} from "../../types";
import {getPointerPosition, getSnappedPosition, setStageCursor} from "../../utils";
import {CursorStyle} from "../../enums";
import ScaleLine from "./ScaleLine";
import ScalePoint from "./ScalePoint";
import {Group, Rect} from "react-konva";

const BaseScaleLayer: React.FC<{
	listening?: boolean
	addScalePoint: (position: Vector2d) => void
	scalePoints: Vector2d[]
	snapping?: SnappingState,
	stageConfig: BaseStageConfig,
	panInProgress: boolean
}> = ({
	listening = false,
	addScalePoint,
	scalePoints,
	snapping,
	stageConfig,
	panInProgress
}) => {
	const [pointerLocation, setPointerLocation] = useState<Vector2d | null>(null);

	function handleMouseUp(event: KonvaEventObject<KonvaEventType>) {
		if (panInProgress) return;
		const position = getPointerPosition(event);
		if (position) {
			if (scalePoints.length === 0) {
				addScalePoint(position)
			}
			if (scalePoints.length === 1) {
				addScalePoint(snapping?.angle
					? getSnappedPosition(scalePoints[0], position)
					: position);
				setStageCursor(event, CursorStyle.DEFAULT)
			}
		}
	}

	function handleMouseMove(event: KonvaEventObject<MouseEvent>) {
		if (panInProgress) return;
		if (scalePoints.length < 2) {
			setStageCursor(event, CursorStyle.CROSSHAIR)
		}
		const position = getPointerPosition(event);
		if (position) {
			if (scalePoints.length === 0) {
				setPointerLocation(position);
			}
			if (scalePoints.length === 1) {
				setPointerLocation(snapping?.angle
					? getSnappedPosition(scalePoints[0], position)
					: position)
			}
		}
	}

	function renderScalePoints() {
		return scalePoints.map(
			(point, i) => <ScalePoint key={i} center={point}/>
		)
	}

	function renderScaleLine() {
		if (scalePoints.length) {
			let points: number[] | null = null;
			if (scalePoints.length === 1 && pointerLocation) {
				points = [scalePoints[0].x, scalePoints[0].y, pointerLocation.x, pointerLocation.y];
			}
			if (scalePoints.length >= 2) {
				points = [scalePoints[0].x, scalePoints[0].y, scalePoints[1].x, scalePoints[1].y]
			}
			return points ? <ScaleLine points={points}/> : null;
		}
		return null;
	}

	return (
		<Group listening={listening} onMouseUp={handleMouseUp} onTouchEnd={handleMouseUp} onMouseMove={handleMouseMove}>
			<Rect name={"background_rect_scale"} width={stageConfig.width} height={stageConfig.height}/>
			{renderScaleLine()}
			{renderScalePoints()}
		</Group>
	);
};

export {BaseScaleLayer}