import {selectHighlightState, viewActions} from "../features/view/viewSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {highlightStyle} from "../../../../base-konva/constants";

export function useHighlightSetter(itemId: string) {
	const dispatch = useDispatch();

	function setHighlight() {
		dispatch(viewActions.highlightItem({id: itemId}));
	}

	function clearHighlight() {
		dispatch(viewActions.highlightItem({}));
	}

	return {
		setHighlight,
		clearHighlight,
	}
}

export function useHighlightItem(itemId: string) {
	const [isHover, setIsHover] = useState(false);
	const {itemId: highlightedItemId} = useSelector(selectHighlightState);

	useEffect(() => {
		setIsHover(highlightedItemId === itemId);
	}, [highlightedItemId, itemId]);

	return {
		isItemHover: isHover
	}
}

export function useGetHighlightShapeStyle() {
	const {itemId: highlightedPanelItemId} = useSelector(selectHighlightState);

	function getHighlightStyle(isHighlighted: (highlightedPanelItemId?: string) => boolean) {
		return isHighlighted(highlightedPanelItemId) ? highlightStyle : {}
	}

	function isItemHighlighted(isHighlighted: (highlightedPanelItemId?: string) => boolean) {
		return isHighlighted(highlightedPanelItemId)
	}

	return {
		getHighlightStyle,
		isItemHighlighted,
	}
}
