import React from "react";
import IconAnchor from "./IconAnchor";
import {ActiveIconProps} from "../../../models/interfaces";

const AnchorTL: React.FC<ActiveIconProps> = (props) => {

	return (
		<IconAnchor {...props}
					paths={["M18.29,17.023l-8.912,-8.912l5.441,-0.004l0.001,-1.772l-8.477,0.008l-0.008,8.477l1.772,-0.001l0.004,-5.441l8.912,8.912l1.267,-1.267Z"]}/>
	);
};

export default AnchorTL