import React from "react";
import {getArcToolIconClasses} from "../iconUtils";
import "./iconArcTool.scss";
import {ActiveIconProps} from "../../models/interfaces";

const IconArcTool: React.FC<ActiveIconProps> = ({onClick, active = false, disabled = false}) => {

	const handleClick = () => !disabled && onClick();

	const cls = getArcToolIconClasses(active, disabled);
	return (
		<div onClick={handleClick} className={cls}>
			<svg width="32" height="32" viewBox="0 0 32 32">
				<path d="M26,0,4.5,5.833,0,25" transform="translate(0.487 0.483)" fill="none"
					  stroke={"#707070"} strokeWidth={1} strokeDasharray={4}/>
			</svg>
		</div>
	);
};

export default IconArcTool

