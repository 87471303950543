import React from "react";
import {DrawingType, Status} from "../../../models/enums";
import {DrawingVersion} from "../../../models/DrawingVersion";
import {OnSuccess} from "../../../utils/CallbackUtils";
import {useTranslation} from "react-i18next";
import useVersionComplete from "../../project-details/hooks/useVersionComplete";
import EditValueModal from "../../../components/modal/EditValueModal";

export function RevertDrawingVersionModal({drawingType, drawingVersion, onClose, onSuccess}: {
	drawingType: DrawingType,
	drawingVersion: DrawingVersion,
	onClose: () => void,
	onSuccess: OnSuccess,
}) {

	const {t} = useTranslation()
	const {saveStatus, revertDrawingVersionCompletion} = useVersionComplete(drawingType);
	const isLoading = saveStatus === Status.LOADING;

	const translations = {
		"title": t("projects.details.tree.completeRevertModal.revertTitle"),
		"content": t("projects.details.tree.completeRevertModal.revertContent")
	}

	function handleSubmit() {
		revertDrawingVersionCompletion(drawingVersion.id, onSuccess)
	}

	return (
		<EditValueModal
			isOpen={true}
			onClose={onClose}
			onSave={handleSubmit}
			title={translations["title"]}
			disableSave={isLoading}
			isLoading={isLoading}
			saveLabel={t("projects.details.tree.completeRevertModal.revertButton")}
		>
			<div className={"modal-content_message"}>
				{translations["content"]}
			</div>
		</EditValueModal>
	);
}