import React, {useEffect, useState} from "react";
import {SymbolType} from "../icons/symbol_pack/default";
import "./symbolIconSelector.scss";
import useSymbols from "../../../../symbols/hooks/useSymbols";
import useSymbolGroups from "../../../../symbols/hooks/useSymbolGroups";
import useSymbolPacks from "../../../../symbols/hooks/useSymbolPacks";
import {SymbolGroup} from "../../../../../models/SymbolGroup";
import {SymbolPack} from "../../../../../models/SymbolPack";
import SymbolPreviewElement from "../../../../symbols/components/SymbolPreviewElement";
import Spinner from "../../../../../components/Spinner";
import {Status} from "../../../../../models/enums";
import {useUpdatedRef} from "../../../../../hooks/useUpdatedRef";
import {RemoteSymbol} from "../models/editor";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import {getDefaultSymbolPack, isSystemSymbolGroup} from "../icons/symbol_pack/defaultPacks";
import useSymbolSystemPacks from "../../../../symbols/hooks/useSymbolSystemPacks";
import {get} from "../../../../../utils/ClassNameUtils";
import {usePopper} from "../../../../../components/popper";
import {useInView} from "react-intersection-observer";

type SymbolChangeArgs = {
	symbol?: SymbolType
	remoteSymbol?: RemoteSymbol
}

interface SymbolIconSelectorProps {
	onSymbolChange: (changeArgs: SymbolChangeArgs) => void,
	symbol?: SymbolType
	remoteSymbol?: RemoteSymbol
	color: string
}

const SymbolIconSelector: React.FC<SymbolIconSelectorProps> = ({symbol, remoteSymbol, onSymbolChange, color}) => {

	const {renderPopper, isPopperOpen, closePopper} = usePopper()
	const {inView, ref} = useInView({threshold: 0.9})

	useEffect(() => {
		if (!inView && isPopperOpen) {
			closePopper()
		}
	}, [inView, isPopperOpen, closePopper])

	function handleSymbolChange(changeArgs: SymbolChangeArgs) {
		return function(e: React.MouseEvent) {
			e.stopPropagation()
			onSymbolChange(changeArgs)
			closePopper()
		}
	}

	const [selectedGroup, setSelectedGroup] = useState<SymbolGroup | null>(null);
	const [selectedPack, setSelectedPack] = useState<SymbolPack | null>(null);
	const showDefaultSymbols = React.useMemo(() => {
		return selectedGroup ? isSystemSymbolGroup(selectedGroup.id) : false;
	}, [selectedGroup])

	const {enabledSystemPackIds, loadStatus: systemPackLoadStatus} = useSymbolSystemPacks();
	const {symbolGroups} = useSymbolGroups(enabledSystemPackIds, true);
	const {symbolPacks, loadStatus: symbolPackLoadStatus} = useSymbolPacks(
		selectedGroup?.id,
		selectedGroup && isSystemSymbolGroup(selectedGroup.id) ? enabledSystemPackIds : undefined
	);
	const {symbols, loadStatus: symbolsLoadStatus} = useSymbols(
		!isSystemSymbolGroup(selectedGroup?.id ?? "") && selectedPack ? selectedPack.id : undefined
	);

	const selectedGroupRef = useUpdatedRef(selectedGroup)

	useEffect(() => {
		if (symbolGroups.length >= 1 && !selectedGroupRef.current) {
			setSelectedGroup(symbolGroups[0])
		}
	}, [symbolGroups, selectedGroupRef])

	const selectedPackRef = useUpdatedRef(selectedPack)

	useEffect(() => {
		if (symbolPacks.length >= 1 && !selectedPackRef.current) {
			setSelectedPack(symbolPacks[0])
		}
	}, [symbolPacks, selectedPackRef]);

	const groupToSelect = symbolGroups.map(group => ({label: group.name, value: group.id}))

	function onGroupChange(value: string) {
		if (value !== selectedGroup?.id) {
			setSelectedPack(null)
			setSelectedGroup(symbolGroups.find(group => group.id === value) ?? selectedGroup)
		}
	}

	function renderGroupSelect() {
		if (!selectedGroup) return null

		return (
			<CustomSelect
				placeholder={""}
				items={groupToSelect}
				onChange={onGroupChange}
				value={selectedGroup.id}
			/>
		)
	}

	const symbolPackToSelect = symbolPacks.map(pack => ({label: pack.name, value: pack.id}))

	function onSymbolPackChange(value: string) {
		setSelectedPack(symbolPacks.find(pack => pack.id === value) ?? selectedPack)
	}

	function renderSymbolPackSelect() {
		const items = selectedPack ? symbolPackToSelect : []

		return (
			<CustomSelect
				placeholder={""}
				items={items}
				onChange={onSymbolPackChange}
				value={selectedPack?.id}
			/>
		)
	}

	function renderDefaultSymbols() {
		if (
			symbolPackLoadStatus === Status.LOADING ||
			symbolsLoadStatus === Status.LOADING ||
			systemPackLoadStatus === Status.LOADING
		) {
			return <Spinner/>
		}

		const symbolSystemPack = getDefaultSymbolPack(selectedPack?.id ?? "");

		return (
			<div className="symbol-icon-selector_content_grid-container">
				<div className={"symbol-icon-selector_content_grid"}>
					{symbolSystemPack?.symbols.map(k => (
						<div key={k}
							 className={"symbol-icon-selector_content_grid_item"}
							 onClick={handleSymbolChange({symbol: k as SymbolType})}>
							<SymbolPreviewElement color={color} symbol={k as SymbolType}/>
						</div>
					))}
				</div>
			</div>
		)
	}

	function renderRemoteSymbols() {
		if (
			symbolPackLoadStatus === Status.LOADING ||
			symbolsLoadStatus === Status.LOADING
		) {
			return <Spinner/>
		}

		return (
			<div className="symbol-icon-selector_content_grid-container">
				<div className={"symbol-icon-selector_content_grid"}>
					{symbols.map(symbol => (
						<div key={symbol.id}
							 className={"symbol-icon-selector_content_grid_item"}
							 onClick={handleSymbolChange({remoteSymbol: {...symbol}})}>
							<SymbolPreviewElement
								color={color}
								remoteSymbol={{
									id: symbol.id,
									filename: symbol.filename
								}}/>
						</div>
					))}
				</div>
			</div>
		)
	}

	return (
		<div className={get({"symbol-icon-selector": true, "is-expanded": isPopperOpen})}>
			{renderPopper({
				target: (
					<div className="symbol-icon-selector_icon" ref={ref}>
						<SymbolPreviewElement symbol={symbol} color={color} remoteSymbol={remoteSymbol}/>
					</div>
				),
				content: (
					<div className="symbol-icon-selector_content">
						<div className={"symbol-icon-selector_content_header-grid"}>
							{renderGroupSelect()}
							{renderSymbolPackSelect()}
						</div>
						{showDefaultSymbols
							? renderDefaultSymbols()
							: renderRemoteSymbols()}
					</div>
				)
			})}
		</div>
	);
};

export default SymbolIconSelector
