import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import EditValueModal from "../../../../../components/modal/EditValueModal";
import "../../../components/tree/modals/editFtmModal.scss";
import CustomSelect from "../../../../../components/ui/CustomSelect";
import {CustomSelectItem} from "../../../../../models/interfaces";
import FitTime from "../../../../../models/FitTime";
import {PlanSummaryItem} from "../../../../../models/PlanSummaryItem";
import {apiInstance} from "../../../../../api/api";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import SummaryMeasurement from "../../../../../models/SummaryMeasurement";

interface EditFtmModalProps {
	itemToEdit: PlanSummaryItem | SummaryMeasurement | undefined,
	fitTimes: FitTime[],
	onSuccess: () => void
	onClose: () => void
}

const EditFtmModal: React.FC<EditFtmModalProps> = ({itemToEdit, fitTimes, onSuccess, onClose}) => {
	const [fitTimeId, setFitTimeId] = useState<number | undefined>(itemToEdit?.fitTimeId);
	const [isLoading, setLoading] = useState(false);
	const {t} = useTranslation();

	useEffect(() => {
		setFitTimeId(itemToEdit?.fitTimeId);
	}, [itemToEdit]);

	const toSelectItem = (fitTime: FitTime): CustomSelectItem<number> => {
		return {
			label: `${fitTime.multiplier}${t("projects.summary.itemsTable.suffixes.fitTimeMultiplier")}`,
			value: fitTime.id
		};
	};

	const onFtmChange = (value: number) => setFitTimeId(value);

	const onSave = () => {
		setLoading(true);
		apiInstance.measurementsApi.putMeasurementData(itemToEdit!.id, fitTimeId, itemToEdit!.laborTime)
			.then(() => {
				NotificationService.successNotification(t("common.success"),
					t("projects.summary.itemsTable.editFtmModal.saveSuccessDesc"));
				onSuccess();
			})
			.catch(err => {
				LoggerService.logError(err);
				NotificationService.errorNotification(t("common.error"),
					t("projects.summary.itemsTable.editFtmModal.saveErrorDesc"))
			})
			.finally(() => setLoading(false))
	};

	return (
		<EditValueModal isOpen={!!itemToEdit}
						onClose={onClose}
						onSave={onSave}
						title={t("projects.summary.itemsTable.editFtmModal.title")}
						isLoading={isLoading}
						disableSave={fitTimeId === undefined || isLoading}>
			<div className="edit-ftm-input_label">
				{t("projects.summary.itemsTable.editFtmModal.inputLabel")}
			</div>
			<div className="edit-ftm-input">
				<CustomSelect placeholder={"Select..."}
							  customClass="edit-ftm-input_select"
							  items={fitTimes.map(toSelectItem)}
							  onChange={onFtmChange}
							  value={fitTimeId}/>
			</div>
		</EditValueModal>
	);
};

export default EditFtmModal;
