import React from "react";
import {useMeDataContext} from "../../providers/MeProvider";
import {formatDate} from "../../utils/TextUtils";
import moment from "moment";

interface DatePresenterProps {
	date: moment.Moment | undefined,
}

const DatePresenter: React.FC<DatePresenterProps> = ({date}) => {
	const {company} = useMeDataContext();
	const outDate = formatDate(date, company);
	return (<>{outDate}</>);
};

export default DatePresenter
