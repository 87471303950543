import React from "react";
import {useTranslation} from "react-i18next";
import "./uploadStatusPresenter.scss";
import InlineSpinner from "../InlineSpinner";
import IconDoneSmall from "../icons/IconDoneSmall";
import IconXSmall from "../icons/IxonXSmall";
import {Status} from "../../models/enums";


interface UploadStatusPresenterProps {
	uploadStatus: Status;
}

const UploadStatusPresenter: React.FC<UploadStatusPresenterProps> = ({uploadStatus}) => {

	const {t} = useTranslation();

	const uploadStatusTranslations = {
		[Status.SUCCESS]: t(`common.uploadStatus.success`),
		[Status.ERROR]: t(`common.uploadStatus.error`)
	}

	const renderUploadStatus = () => {
		switch (uploadStatus) {
			case Status.SUCCESS:
				return (
					<>
						<IconDoneSmall/> {uploadStatusTranslations[uploadStatus]}
					</>
				);
			case Status.ERROR:
				return (
					<>
						<IconXSmall/> {uploadStatusTranslations[uploadStatus]}
					</>
				);
			case Status.IDLE:
			default:
				return null;
		}
	}

	return (
		<div className={`upload-status ${uploadStatus.toLowerCase()}`}>
			{uploadStatus === Status.LOADING ?
				<InlineSpinner width={15} height={15}/> :
				renderUploadStatus()
			}
		</div>
	);
};

export default UploadStatusPresenter
