import React from 'react';
import "./editorPanel.scss";
import {PinnedItemsEditorPanel} from "./PinnedItemsEditorPanel";
import {TakeoffsEditorPanel} from "./TakeoffsEditorPanel";
import {EditorPanelDataContextBridge} from './EditorPanelProvider';
import {EditorPanelMode} from "../../models/enums";
import {MaterialModalProvider} from "./material/MaterialModalProvider";

export const EditorPanel = () => {
	return (
		<MaterialModalProvider>
			<div className="editor-panel_container">
				<EditorPanelDataContextBridge.Consumer>
					{data => data
						? (data.editorPanelMode === EditorPanelMode.TAKEOFF_ITEMS
							? <TakeoffsEditorPanel/>
							: <PinnedItemsEditorPanel/>)
						: null}
				</EditorPanelDataContextBridge.Consumer>
			</div>
		</MaterialModalProvider>
	);
};
