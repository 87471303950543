import {useMemo} from "react";
import {useProjectTreeRepository} from "../api";
import {useTreeSelectionData} from "./useTreeSelectionData";
import {useTreeExpandData} from "./useTreeExpandData";
import useCompanyCostCenters from "./useCompanyCostCenters";
import {Status} from "../../../models/enums";

export function useProjectTreeData() {
	const {sections, loadProjectTree, projectTreeRepositoryLoadStatus} = useProjectTreeRepository();
	const {companyCostCenters, loadCompanyCostCenters, companyCostCentersLoadStatus} = useCompanyCostCenters();
	const treeExpandData = useTreeExpandData(sections)
	const selectionData = useTreeSelectionData(sections)

	const loadStatus = useMemo(() => {
		if (projectTreeRepositoryLoadStatus === Status.ERROR || companyCostCentersLoadStatus === Status.ERROR) {
			return Status.ERROR
		}
		else if (projectTreeRepositoryLoadStatus === Status.LOADING || companyCostCentersLoadStatus === Status.LOADING) {
			return Status.LOADING
		}
		else if (projectTreeRepositoryLoadStatus === Status.SUCCESS && companyCostCentersLoadStatus === Status.SUCCESS) {
			return Status.SUCCESS
		}
		else {
			return Status.IDLE
		}
	}, [projectTreeRepositoryLoadStatus, companyCostCentersLoadStatus])

	return {
		loadProjectTree,
		loadStatus,
		sections,
		treeExpandData,
		selectionData,
		companyCostCenters,
		loadCompanyCostCenters
	}
}