import React, {useCallback, useEffect, useRef, useState} from "react"
import {useDispatch, useSelector} from "react-redux";
import {selectLengthPointContextMenuState, viewActions} from "../view/viewSlice";
import {useLengthPointContextMenuActions} from "./useLengthPointContextMenuActions";
import "./lengthPointContextMenuContainer.scss";
import {useTranslation} from "react-i18next";
import {CONTEXT_MENU_ITEM_HEIGHT, CONTEXT_MENU_ITEM_WIDTH} from "../../constants";
import {ElementSize} from "../../../../../../models/interfaces";

const LengthPointContextMenuContainer: React.FC = () => {

	const contextMenuState = useSelector(selectLengthPointContextMenuState)
	const contextMenuContainerRef = useRef<HTMLDivElement>(null);
	const contextMenuWrapperRef = useRef<HTMLDivElement>(null);
	const [containerSize, setContainerSize] = useState<ElementSize>({height: 0, width: 0});
	const dispatch = useDispatch();
	const {activateLengthPoint, addDropToActive} = useLengthPointContextMenuActions()
	const closeContextMenu = useCallback(function() {
		dispatch(viewActions.setLengthPointContextMenuState({
			visible: false,
			x: 0, y: 0,
			pointId: ""
		}))
	}, [dispatch])

	const {t} = useTranslation();

	useEffect(() => {
		const height = contextMenuWrapperRef.current?.offsetHeight || 0;
		const width = contextMenuWrapperRef.current?.offsetWidth || 0;
		if (height !== 0 && width !== 0) {
			setContainerSize({height, width});
		}
	}, [contextMenuWrapperRef.current?.offsetWidth, contextMenuWrapperRef.current?.offsetHeight]);

	useEffect(() => {
		const pageClickEvent = (evt: any) => {
			if (contextMenuContainerRef.current !== null && !contextMenuContainerRef.current.contains(evt.target)) {
				closeContextMenu();
			}
		};

		if (contextMenuState.visible) {
			window.addEventListener('click', pageClickEvent);
		}

		return () => {
			window.removeEventListener('click', pageClickEvent);
		}

	}, [closeContextMenu, contextMenuContainerRef, contextMenuWrapperRef, contextMenuState.visible]);

	const getMenuStyles = () => {
		const width = containerSize.width;
		const height = containerSize.height;
		let styles = {};
		const {x, y} = contextMenuState;
		styles = x + CONTEXT_MENU_ITEM_WIDTH >= width ?
			{...styles, left: x - CONTEXT_MENU_ITEM_WIDTH - 10} :
			{...styles, left: x + 10}
		styles = y + CONTEXT_MENU_ITEM_HEIGHT * 2 >= height ?
			{...styles, top: y - CONTEXT_MENU_ITEM_HEIGHT * 2 - 10} :
			{...styles, top: y + 10}
		return styles
	}

	function handleActivatePoint() {
		activateLengthPoint(contextMenuState.pointId)
		closeContextMenu()
	}

	function handleAddDrop() {
		addDropToActive(contextMenuState.pointId)
		closeContextMenu()
	}

	const cls = [
		"len-ctx",
		!contextMenuState.visible && "hidden",
	].filter(Boolean).join(" ");

	return (
		<div className={cls}
			 ref={contextMenuWrapperRef}>
			<div className={"len-ctx_menu"} ref={contextMenuContainerRef}
				 style={getMenuStyles()}>
				<ul className={"len-ctx_menu_list"}>
					<li className={"len-ctx_menu_list_item"} onClick={handleActivatePoint}>{t(
						"editor.contextMenu.activatePoint")}</li>
					<li className={"len-ctx_menu_list_item"} onClick={handleAddDrop}>{t("editor.contextMenu.addDrop")}</li>
				</ul>
			</div>
		</div>
	);
}

export default LengthPointContextMenuContainer;