import isEqual from "lodash/isEqual";
import React from "react";
import {Line} from "react-konva";
import {LineDistanceMark} from "../../components/LineDistanceMark";
import {EditorLine} from "../../models/editor";
import {ShapeConfig} from "konva/types/Shape";
import {KonvaEventObject} from "konva/types/Node";

export type EventHandlers = {
	onMouseEnter: (evt: KonvaEventObject<Event>) => void,
	onMouseLeave: (evt: KonvaEventObject<Event>) => void,
	onDragStart: (evt: KonvaEventObject<DragEvent>) => void,
	onDragMove: (evt: KonvaEventObject<DragEvent>) => void,
	onDragEnd: (evt: KonvaEventObject<DragEvent>) => void,
	onClick?: (id: string) => void,
	onTap?: (id: string) => void,
}

type LengthLineKonvaProps = {
	line: EditorLine
	style: ShapeConfig
	listening: boolean
	draggable: boolean
	eventsRef: React.MutableRefObject<EventHandlers>
}

function _LengthLineKonva({
	line, style, listening, draggable, eventsRef
}: LengthLineKonvaProps) {

	function onMouseEnter(evt: KonvaEventObject<Event>) {
		eventsRef.current.onMouseEnter(evt)
	}

	function onMouseLeave(evt: KonvaEventObject<Event>) {
		eventsRef.current.onMouseLeave(evt)
	}

	function onDragStart(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragStart(evt)
	}

	function onDragMove(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragMove(evt)
	}

	function onDragEnd(evt: KonvaEventObject<DragEvent>) {
		eventsRef.current.onDragEnd(evt)
	}

	function onClick() {
		eventsRef.current.onClick?.(line.id)
	}

	function onTap() {
		eventsRef.current.onTap?.(line.id)
	}

	const callbacks = {
		onMouseEnter,
		onMouseLeave,
		onDragStart,
		onDragMove,
		onDragEnd
	}

	return (
		<>
			<Line
				points={[
					line.from.position.x,
					line.from.position.y,
					line.to.position.x,
					line.to.position.y
				]}
				{...style}
				listening={listening}
				draggable={draggable}
				{...callbacks}
				onClick={onClick}
				onTap={onTap}
			/>
			<LineDistanceMark
				line={line}
				position={line.center}
				draggable={draggable}
				listening={listening}
				callbacks={listening ? callbacks : {}}
				onClick={onClick}
				onTap={onTap}
			/>
		</>
	)
}

export const LengthLineKonva = React.memo(_LengthLineKonva, isEqual)
