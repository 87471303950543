import React, {useState} from "react";
import {selectActiveTool, selectEditorLockState} from "./features/config/configSlice";
import {useSelector} from "react-redux";
import {ScaleLayer} from "./features/scale/ScaleLayer";
import {EditorTool} from "./models/enums";
import {RulerLayer} from "./features/ruler/RulerLayer";
import {Group} from "react-konva";
import {MeasurementsLayer} from "./MeasurementsLayer";
import {ScaleInfoLayer} from "./features/scaleInfo/ScaleInfoLayer";
import {getId} from "../../../../utils";
import {SymbolRecognitionLayer} from "./features/symbol-recognition/SymbolRecognitionLayer";

const EditorStageLayers: React.FC = () => {
	const activeTool = useSelector(selectActiveTool);
	const editorLocked = useSelector(selectEditorLockState);
	const listening = !editorLocked;

	const isListening = activeTool !== EditorTool.GROUP_MEASUREMENT_SELECT;

	const [measurementsLayerId] = useState(getId())
	const [scaleInfoLayerId] = useState(getId())

	switch (activeTool) {
		case EditorTool.LENGTH:
		case EditorTool.ARC:
		case EditorTool.COUNT:
		case EditorTool.AREA:
		case EditorTool.VOLUME:
		case EditorTool.PEN:
		case EditorTool.TEXT:
		case EditorTool.IMAGE:
		case EditorTool.MEASUREMENT_SELECT:
			return (
				<Group listening={isListening}>
					<MeasurementsLayer key={measurementsLayerId} {...{listening}}/>
					<ScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case EditorTool.SYMBOL_RECOGNITION:
			return (
				<Group listening={isListening}>
					<MeasurementsLayer key={measurementsLayerId} visible={false}/>
					<SymbolRecognitionLayer/>
				</Group>
			)
		case EditorTool.GROUP_MEASUREMENT_SELECT:
			return (
				<Group listening={isListening}>
					<MeasurementsLayer key={measurementsLayerId}/>
					<ScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case EditorTool.RULER:
			return (
				<Group listening={isListening}>
					<MeasurementsLayer key={measurementsLayerId}/>
					<RulerLayer {...{listening}}/>
					<ScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>
			)
		case EditorTool.SCALE:
			return (
				<Group>
					<MeasurementsLayer key={measurementsLayerId}/>
					<ScaleLayer {...{listening}}/>
					<ScaleInfoLayer key={scaleInfoLayerId}/>
				</Group>)
		case EditorTool.SCALE_INFO:
			return (
				<Group>
					<MeasurementsLayer key={measurementsLayerId}/>
					<ScaleInfoLayer key={scaleInfoLayerId} showTransformer={true}/>
				</Group>)
		default:
			return null;
	}
};

export default EditorStageLayers
