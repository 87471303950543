import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {PlanUploadRootState} from "../../planUploadStore";
import {
	PlanUploadConfigState,
	PlanUploadEditorTool,
	ResetPlanUploadConfigActionPayload,
	SwitchPlanUploadEditorToolActionPayload
} from "../../types";
import {UndoRedoExclusionPayload} from "../../../../../base-konva/types";


const initialState: PlanUploadConfigState = {
	activeTool: PlanUploadEditorTool.MEASUREMENT_SELECT,
	lastActiveTool: PlanUploadEditorTool.MEASUREMENT_SELECT,
	stageConfig: {
		height: 0,
		width: 0
	}
}

const planUploadConfigSlice = createSlice({
	name: 'plan-upload-config',
	initialState: initialState,
	reducers: {
		reset: (_,
			{payload}: PayloadAction<ResetPlanUploadConfigActionPayload>) => ({
			...initialState,
			stageConfig: payload.stageConfig ?? initialState.stageConfig,
		}),
		setInitialStageConfig: (state, {payload}: PayloadAction<typeof initialState.stageConfig>) => {
			state.stageConfig = payload
		},
		switchEditorTool: (state, action: PayloadAction<SwitchPlanUploadEditorToolActionPayload>) => {
			state.lastActiveTool = state.activeTool;
			state.activeTool = action.payload.planUploadEditorTool;
		},
		switchEditorToolBack: (state, _: PayloadAction<UndoRedoExclusionPayload>) => {
			state.activeTool = state.lastActiveTool;
		}
	}
})

export const planUploadConfigReducer = planUploadConfigSlice.reducer;
export const planUploadConfigActions = planUploadConfigSlice.actions;
export const selectPlanUploadActiveTool = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.config.activeTool;
export const selectPlanUploadStageConfig = (state: PlanUploadRootState) => state.planUploadUndoGroup.present.config.stageConfig;
