import React, {useEffect} from "react";
import {Redirect, Route, Switch, useLocation,} from "react-router-dom";
import {SettingsPage} from "../features/settings";
import {TakeoffsRouter} from "../features/project-list";
import {initWalkMe, removeWalkMe} from "../utils/WalkMeUtils";
import {useUpdatedRef} from "../hooks/useUpdatedRef";
import {pages, paths, useNavigator} from "../features/navigator";

const MainRouter: React.FC = () => {

	const {navigateTo} = useNavigator()
	const locationSearch = useLocation().search
	const dataRef = useUpdatedRef({locationSearch, navigateTo})

	useEffect(() => {
		initWalkMe();
		return removeWalkMe;
	}, [])

	useEffect(function urlParamRedirect() {
		const {locationSearch, navigateTo} = dataRef.current
		const query = new URLSearchParams(locationSearch)

		if (query.has("encodedUri") && query.has("e") && window.pendo) {
			const encodedUri = query.get("encodedUri")
			const e = query.get("e")

			const uri = decodeURIComponent(encodedUri!)
			const visitorId = `${uri}-${e}`
			const accountId = uri

			window.pendo.initialize({
				visitor: {
					id: visitorId,
				},
				account: {
					id: accountId,
				}
			});
		}

		if (query.has("q")) {
			navigateTo(pages.simproIdQuotePage(query.get("q")!))
		}
		if (query.has("j")) {
			navigateTo(pages.simproIdJobPage(query.get("j")!))
		}
	}, [dataRef]);

	return (
		<Switch>
			<Route path={paths.settings} component={SettingsPage}/>
			<Route path={paths.takeoffs} component={TakeoffsRouter}/>
			<Redirect to={paths.takeoffs}/>
		</Switch>
	);
};

export {MainRouter}
