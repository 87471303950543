import {ExternalAttachmentImportStateApiDto} from "./restModels";
import {AsyncRequestStatus} from "./enums";
import LoggerService from "../services/LoggerService";

export class ExternalAttachmentImportState {
	constructor(
		public readonly status: AsyncRequestStatus,
		public readonly attachmentId?: string,
		public readonly error?: string
	) {
	}

	public static fromJson(json: ExternalAttachmentImportStateApiDto): ExternalAttachmentImportState {
		const requestStatus = AsyncRequestStatus[json.status]
		if (requestStatus === undefined) {
			const error = new Error(`Unknown request status: ${json.status}`);
			LoggerService.logError(error);
			throw error;
		}
		return new ExternalAttachmentImportState(requestStatus, json.attachmentId, json.error);
	}
}