import {CostCenterDrawingApiDto} from "./restModels";
import {DrawingVersion} from "./DrawingVersion";
import {getLatestDrawingVersion} from "../utils/DrawingVersionUtils";
import {DrawingType} from "./enums";
import LoggerService from "../services/LoggerService";
import moment from "moment";

export class CostCenterDrawing {
	constructor(
		public readonly id: string,
		public readonly name: string,
		public readonly drawingType: DrawingType,
		public readonly locked: boolean,
		public readonly versions: DrawingVersion[],
		public readonly modifiedAt: moment.Moment,
		public readonly modifiedBy?: string
	) {}

	public static fromJson(json: CostCenterDrawingApiDto): CostCenterDrawing {
		const drawingType = DrawingType[json.drawingType];
		if (drawingType === undefined) {
			const error = new Error(`Unknown Drawing type: ${json.drawingType}`);
			LoggerService.logError(error);
			throw error;
		}
		return new CostCenterDrawing(
			json.id,
			json.name,
			drawingType,
			json.locked,
			json.versions.map(DrawingVersion.fromJson),
			moment(json.modifiedAt),
			json.modifiedBy
		);
	}

	getLabel() {
		return `${this.name}`
	}

	getLatestCompletedVersion(): DrawingVersion | undefined {
		return getLatestDrawingVersion(this.versions.filter(v => v.isCompleted()))
	}
}
