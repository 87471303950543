import {Vector2d} from "konva/types/types";
import {add} from "../../utils";
import {TextGroup} from "../../../../../base-konva/types";

export class TextHelper {
	static getMovedTextGroups(textGroups: TextGroup[], delta: Vector2d): TextGroup[] {
		return textGroups.map(textGroup => ({
			...textGroup,
			textItems: textGroup.textItems.map(textItem => ({
				...textItem,
				position: add(textItem.position, delta)
			}))
		}))
	}
}