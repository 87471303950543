import React from "react";
import IconDownload from "../../../../../components/icons/IconDownload";
import CommonDropdown from "../../../../../components/ui/CommonDropdown";
import {get} from "../../../../../utils/ClassNameUtils";

export const ExportDropdown: React.FC<{ disabled?: boolean }> = ({children, disabled}) => {

	const button = (
		<div className={get("btnGreen", {"disabled": !!disabled})} style={{padding: "0"}}>
			<IconDownload/>
		</div>
	);

	return (
		<CommonDropdown button={button} disabled={disabled}>
			{children}
		</CommonDropdown>
	);
};
