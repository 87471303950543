import React, {useEffect, useMemo} from "react";
import Konva from "konva";
import {useSelector} from "react-redux";
import {selectActiveTool, selectStageConfig} from "../config/configSlice";
import {selectPanelStage, selectSymbolRecognitionResults} from "./symbolRecognitionSlice";
import {selectZoomState} from "../view/viewSlice";
import {EditorTool} from "../../models/enums";
import {SymbolRecognitionPanelStage} from "./types";
import {useUpdatedRef} from "../../../../../../hooks/useUpdatedRef";
import {getStageBorderPosition} from "../../utils";

function useCenterStageOnActiveRecognitionSymbol({stageRef}: { stageRef: React.RefObject<Konva.Stage>; }) {
	const activeTool = useSelector(selectActiveTool);
	const panelStage = useSelector(selectPanelStage)
	const zoomState = useSelector(selectZoomState);
	const stageConfig = useSelector(selectStageConfig);
	const {
		referentialData,
		activeIndex,
	} = useSelector(selectSymbolRecognitionResults)

	const currentItem = useMemo(() => {
		const item = referentialData[activeIndex]
		return item ?? null
	}, [referentialData, activeIndex])

	const canCenter = useMemo(() => {
		return Boolean(
			activeTool === EditorTool.SYMBOL_RECOGNITION &&
			panelStage === SymbolRecognitionPanelStage.MATCHES_DISPLAY &&
			stageRef.current && currentItem
		)
	}, [activeTool, currentItem, panelStage, stageRef]);

	const dataRef = useUpdatedRef({
		zoomState, stageConfig, stageRef
	})

	useEffect(() => {
		if (!canCenter) {
			return;
		}

		const {zoomState, stageRef, stageConfig} = dataRef.current
		const stage = stageRef.current!

		// measuring from center because stage has offset pointing to the center of the plan
		const centerDiffX = (stageConfig.width * zoomState.scale) / 2 - currentItem!.x * zoomState.scale
		const centerDiffY = (stageConfig.height * zoomState.scale) / 2 - currentItem!.y * zoomState.scale
		const newX = zoomState.viewportSize.width / 2 + centerDiffX - stageConfig.backgroundImagePosition.x * zoomState.scale
		const newY = zoomState.viewportSize.height / 2 + centerDiffY - stageConfig.backgroundImagePosition.y * zoomState.scale

		stage.position(getStageBorderPosition(
			{x: newX, y: newY},
			stage.getClientRect(),
			zoomState.viewportSize
		))

	}, [canCenter, currentItem, dataRef])
}

export {useCenterStageOnActiveRecognitionSymbol}