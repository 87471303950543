import {DrawingDetailsApiDto, DrawingImageApiDto, PageScaleRatioApiDto} from "./restModels";
import {DrawingVersion} from "./DrawingVersion";
import {getLabeledDrawingVersions} from "../utils/DrawingVersionUtils";
import moment from "moment";
import {formatBytes} from "../utils/ConversionUtils";
import {DrawingType} from "./enums";
import LoggerService from "../services/LoggerService";
import {PageSizeFormat} from "../features/base-konva/enums";

export default class DrawingDetails {
	constructor(public readonly id: string,
		public readonly drawingType: DrawingType,
		public readonly images: DrawingImage[],
		public readonly locked: boolean,
		public readonly lockedBy: string | undefined,
		public readonly modifiedAt: moment.Moment,
		public readonly modifiedBy: string,
		public readonly name: string,
		public readonly planContentLength: number | undefined,
		public readonly planFilename: string | undefined,
		public readonly planId: string | undefined,
		public readonly planScale: number | undefined,
		public readonly pageScaleRatio: PageScaleRatio | undefined,
		public readonly versions: DrawingVersion[],
	) {}

	public static fromJson(json: DrawingDetailsApiDto): DrawingDetails {
		const drawingType = DrawingType[json.drawingType];
		if (drawingType === undefined) {
			const error = new Error(`Unknown Drawing type: ${json.drawingType}`);
			LoggerService.logError(error);
			throw error;
		}
		const versions = json.versions.map(DrawingVersion.fromJson);
		const modifiedAt = moment(json.modifiedAt);
		const images = json.images.map(DrawingImage.fromJson);
		const pageScaleRatio = json.pageScaleRatio ? PageScaleRatio.fromJson(json.pageScaleRatio) : undefined;
		return new DrawingDetails(json.id, drawingType, images, json.locked, json.lockedBy,
			modifiedAt, json.modifiedBy, json.name, json.planContentLength, json.planFilename, json.planId, json.planScale,
			pageScaleRatio, getLabeledDrawingVersions(versions)
		);
	}

	getContentInMegabytes() {
		return formatBytes(this.planContentLength ?? 0);
	}
}

class DrawingImage {
	constructor(
		public readonly id: string,
		public readonly filename: string) {}

	public static fromJson(json: DrawingImageApiDto): DrawingImage {
		return new DrawingImage(json.id, json.filename)
	}
}

export class PageScaleRatio {
	constructor(public readonly scaleRatio: number, public readonly pageFormat: PageSizeFormat) {}

	public static fromJson(json: PageScaleRatioApiDto) {
		const pageFormat = PageSizeFormat[json.pageFormat];
		if (pageFormat === undefined) {
			const error = new Error(`Unknown page format: ${json.pageFormat}`);
			LoggerService.logError(error);
			throw error;
		}
		return new PageScaleRatio(json.scaleRatio, pageFormat);
	}

	toJson(): PageScaleRatioApiDto {
		return {
			scaleRatio: this.scaleRatio,
			pageFormat: this.pageFormat
		}
	}
}
