import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../editorStore";
import {
	ActivateCountItemActionPayload,
	AddCountItemActionPayload,
	ChangeStyleActionPayload,
	CountMeasurement,
	CountMeasurementState,
	CountMeasurementStyle,
	DeleteManyCountActionPayload,
	MaterialChangeActionPayload,
	MaterialMeasurementCreateActionPayload,
	TransformCountItemActionPayload,
	UpdateChildItemPositionActionPayload
} from "../../models/editor";
import {MeasurementType} from "../../models/enums";
import {
	ActionIdValuePayload,
	ActivateActionPayload,
	DeleteActionPayload,
	DeleteChildItemActionPayload,
	DeleteSelectedItemActionPayload,
	GroupedActionPayload,
	SetItemVisibilityActionPayload,
	SetVisibilityActionPayload
} from "../../../../../base-konva/types";
import {getId} from "../../../../../../utils";

const initialState: CountMeasurementState = {
	countGroups: []
}

export const countSlice = createSlice({
	name: 'count',
	initialState: initialState,
	reducers: {
		reset: () => ({...initialState}),
		addCount: (state, {payload}: PayloadAction<MaterialMeasurementCreateActionPayload>) => {
			const newId = getId();
			state.activeCountGroupId = newId;
			state.countGroups.push({
				id: newId,
				material: payload.material,
				visible: true,
				countItems: [],
				style: payload.style as CountMeasurementStyle,
				type: MeasurementType.COUNT
			})
		},
		addCountItem: (state, {payload}: PayloadAction<AddCountItemActionPayload>) => {
			for (let countGroup of state.countGroups) {
				if (countGroup.id === state.activeCountGroupId) {
					countGroup.activeItemId = undefined;
					countGroup.countItems.push({
						id: getId(),
						name: `${countGroup.material.name} ${countGroup.countItems.length + 1}`,
						position: payload.position,
						visible: true,
						rotation: 0,
					})
					break;
				}
			}
		},
		updateCountItemPosition: (state, {payload}: PayloadAction<UpdateChildItemPositionActionPayload>) => {
			for (let countGroup of state.countGroups) {
				if (countGroup.id === payload.parentId) {
					for (let countItem of countGroup.countItems) {
						if (countItem.id === payload.id) {
							countItem.position = payload.position
							break;
						}
					}
					break;
				}
			}
		},
		activateCountGroup: (state, {payload}: PayloadAction<ActivateActionPayload>) => {
			state.activeCountGroupId = payload.id
		},
		clearActiveCountGroup: (state, _action: PayloadAction<GroupedActionPayload>) => {
			state.activeCountGroupId = undefined;
			for (let countGroup of state.countGroups) {
				countGroup.activeItemId = undefined;
			}
		},
		deleteCountGroup: (state, {payload}: PayloadAction<DeleteActionPayload>) => {
			state.countGroups = state.countGroups.filter(cg => cg.id !== payload.id)
			state.activeCountGroupId = state.countGroups.find(countGroup => countGroup.id === state.activeCountGroupId)?.id
		},
		changeStyle: (state, {payload}: PayloadAction<ChangeStyleActionPayload<CountMeasurementStyle>>) => {
			for (let countGroup of state.countGroups) {
				if (countGroup.id === payload.id) {
					countGroup.style = payload.style
					break;
				}
			}
		},
		changeMaterial: (state, {payload}: PayloadAction<MaterialChangeActionPayload>) => {
			const {id, material, style} = payload;
			for (let countGroup of state.countGroups) {
				if (countGroup.id === id) {
					countGroup.material = material;
					countGroup.style = style as CountMeasurementStyle;
					countGroup.countItems = countGroup.countItems.map((item, idx) => (
						{...item, name: `${countGroup.material.name} ${idx + 1}`}
					))
					break;
				}
			}
		},
		deleteCountItem: (state, {payload}: PayloadAction<DeleteChildItemActionPayload>) => {
			for (let countGroup of state.countGroups) {
				if (countGroup.id === payload.parentId) {
					countGroup.countItems = countGroup.countItems
						.filter(item => item.id !== payload.id)
						.map((item, idx) => ({...item, name: `${countGroup.material.name} ${idx + 1}`}))
					countGroup.activeItemId = countGroup.countItems.find(item => item.id === countGroup.activeItemId)?.id
					break;
				}
			}
		},
		setCounts: (state, {payload}: PayloadAction<ActionIdValuePayload<CountMeasurement[]>>) => {
			state.countGroups = payload.value
		},
		setVisibility: (state, {payload}: PayloadAction<SetVisibilityActionPayload>) => {
			const {id, visible, setAll} = payload;
			for (let measurement of state.countGroups) {
				if (measurement.id === id || setAll) {
					measurement.visible = visible;
					for (let countItem of measurement.countItems) {
						countItem.visible = visible;
					}
					if (!setAll) break;
				}
			}
		},
		setItemVisibility: (state, {payload}: PayloadAction<SetItemVisibilityActionPayload>) => {
			const {id, visible, parentId} = payload;
			for (let group of state.countGroups) {
				if (group.id === parentId) {
					for (let item of group.countItems) {
						if (item.id === id) {
							item.visible = visible;
							break;
						}
					}
					break;
				}
			}
		},
		removeItems: (state, {payload}: PayloadAction<DeleteManyCountActionPayload>) => {
			for (let parent in payload.items) {
				for (let countGroup of state.countGroups) {
					if (countGroup.id === parent) {
						countGroup.countItems = countGroup.countItems
							.filter(item => !payload.items[parent].some(id => item.id === id))
							.map((item, idx) => ({...item, name: `${countGroup.material.name} ${idx + 1}`}))
						countGroup.activeItemId = countGroup.countItems.find(item => item.id === countGroup.activeItemId)?.id
						break;
					}
				}
			}
		},
		removeSelectionItems: (state, {payload}: PayloadAction<DeleteSelectedItemActionPayload>) => {
			for (let countGroup of state.countGroups) {
				if (countGroup.id === payload.id) {
					countGroup.countItems = countGroup.countItems
						.filter(item => !payload.subIds!.some(id => item.id === id))
						.map((item, idx) => ({...item, name: `${countGroup.material.name} ${idx + 1}`}))
					countGroup.activeItemId = countGroup.countItems.find(item => item.id === countGroup.activeItemId)?.id
					break;
				}
			}
		},
		transformCountItem: (state, {payload}: PayloadAction<TransformCountItemActionPayload>) => {
			const {id, rotation, parentId} = payload
			for (let countGroup of state.countGroups) {
				if (countGroup.id === parentId) {
					for (let countItem of countGroup.countItems) {
						if (countItem.id === id) {
							countItem.rotation = rotation;
							break;
						}
					}
					break;
				}
			}
		},
		activateCountItem: (state, {payload}: PayloadAction<ActivateCountItemActionPayload>) => {
			const {id, parentId} = payload
			for (let countGroup of state.countGroups) {
				if (countGroup.id === parentId) {
					countGroup.activeItemId = countGroup.activeItemId === id ? undefined : id;
					break;
				}
			}
		},
		clearActiveCountItem: (state, _action: PayloadAction<GroupedActionPayload>) => {
			for (let countGroup of state.countGroups) {
				if (countGroup.id === state.activeCountGroupId) {
					countGroup.activeItemId = undefined;
					break;
				}
			}
		}
	}
})

export const countActions = countSlice.actions;
export const selectCountGroups = (state: RootState) => state.undoGroup.present.count.countGroups;
export const selectActiveCountGroupId = (state: RootState) => state.undoGroup.present.count.activeCountGroupId;
export const selectGroupActiveItemId = (groupId: string) => {
	return (state: RootState) => {
		for (let countGroup of state.undoGroup.present.count.countGroups) {
			if (countGroup.id === groupId) {
				return countGroup.activeItemId;
			}
		}
		return undefined;
	}
}

export const countReducer = countSlice.reducer;
