import React, {useEffect, useState} from "react";
import {Status, UnitsType, KeySettingsType} from "../../../models/enums";
import Spinner from "../../../components/Spinner";
import CustomSelect from "../../../components/ui/CustomSelect";
import PageHeader from "../../../components/layout/page/PageHeader";
import {useTranslation} from "react-i18next";
import "./settingsMain.scss";
import {CustomSelectItem} from "../../../models/interfaces";
import {useUserSettingsDataContext} from "../../../providers/UserSettingsProvider";
import Button from "../../../components/ui/Button";
import PageHeaderButtons from "../../../components/layout/page/PageHeaderButtons";
import useApplicationVersion from "./useApplicationVersion";
import {useDirectProjectAccessDataContext} from "../../../providers/DirectProjectAccessDataProvider";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import {Checkbox, Typography} from "@mui/material";

const SettingsMain: React.FC = () => {

	const {settings, saveSettings, loadStatus, saveStatus} = useUserSettingsDataContext();
	const [unitsType, setUnitsType] = useState<UnitsType | undefined>(undefined);
	const [keyType, setKeyType] = useState<KeySettingsType | undefined>(undefined);
	const [downloadKeyTable, setDownloadKeyTable] = useState<boolean>(false);
	const {redirectToProjectOrProjectsList} = useDirectProjectAccessDataContext();
	const {t} = useTranslation();

	const {applicationVersion, loadStatus: versionLoad} = useApplicationVersion()

	useEffect(() => {
		setUnitsType(settings?.measurementSystem);
		setKeyType(settings?.keySettings)
		setDownloadKeyTable(settings ? settings.downloadKeyTable : false)
	}, [settings]);

	const unitsTranslations = {
		[UnitsType.METRIC]: t(`common.units.metric`),
		[UnitsType.IMPERIAL]: t(`common.units.imperial`)
	}

	const units: CustomSelectItem<UnitsType>[] = Object.values(UnitsType).map(u => ({
		label: unitsTranslations[u], value: u
	}))

	const handleKeySettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.value === KeySettingsType.DETAILED) {
			setKeyType(KeySettingsType.DETAILED);
		} else {
			setKeyType(KeySettingsType.SUMMARY);
		}
	};

	const handleDownloadKeySettingsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setDownloadKeyTable(e.target.checked)
	};

	return (
		<div className={"settings-main"}>
			<PageHeader>
				<div className={"settings-main_title"}>
					{t("settings.mainSettings.title")}
				</div>
				<PageHeaderButtons>
					<Button label={t("common.buttons.cancel")} onClick={redirectToProjectOrProjectsList} variant={"optional"}/>
					<Button label={t(saveStatus === Status.LOADING ? "common.buttons.saving" : "common.buttons.save")}
							disabled={!unitsType || !keyType || saveStatus === Status.LOADING}
							variant={"primary"}
							onClick={() => saveSettings(unitsType!, keyType!, downloadKeyTable!)}/>
				</PageHeaderButtons>
			</PageHeader>
			{loadStatus === Status.LOADING ? <Spinner/> : null}
			{loadStatus === Status.SUCCESS ?
				<>
					{/*<div className={"settings-main_select"}>*/}
					{/*	<div className={"settings-main_select_label"}>*/}
					{/*		{t("settings.mainSettings.language")}*/}
					{/*	</div>*/}
					{/*	<div className={"settings-main_select_selector"}>*/}
					{/*		<CustomSelect placeholder={"settings.mainSettings.languageSelectorLabel"}*/}
					{/*					  items={settings ? settings.getLanguageOptions() : []}*/}
					{/*					  value={settings?.language}*/}
					{/*					  onChange={noop}/>*/}
					{/*	</div>*/}
					{/*</div>*/}
					<div className={"settings-main_select"}>
						<div className={"settings-main_select_label"}>
							{t("settings.mainSettings.units")}
						</div>
						<div className={"settings-main_select_selector"}>
							<CustomSelect placeholder={t("settings.mainSettings.unitsSelectorLabel")}
										  items={units}
										  value={unitsType}
										  onChange={setUnitsType}/>
						</div>
					</div>
					<PageHeader>
						<div className={"settings-main_title"}>
							{t("settings.keySettings.title")}
						</div>
					</PageHeader>
					<FormControl>
						<RadioGroup
							row
							value={keyType}
							onChange={value => handleKeySettingsChange(value)}
							className={"settings-main_select_label"}
						>
							<FormControlLabel value={KeySettingsType.DETAILED}
											  control={<Radio checked={keyType === KeySettingsType.DETAILED}
															  sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
											  label={<Typography sx={{fontSize: 12}}>
												  {t("settings.keySettings.detailLabel")}
											  </Typography>}/>
							<FormControlLabel value={KeySettingsType.SUMMARY}
											  control={<Radio checked={keyType === KeySettingsType.SUMMARY}
															  sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
											  label={<Typography sx={{fontSize: 12}}>
												  {t("settings.keySettings.summaryLabel")}
											  </Typography>}/>

						</RadioGroup>
						<FormControlLabel value={downloadKeyTable}
										  control={<Checkbox checked={downloadKeyTable}
															 onChange={value => handleDownloadKeySettingsChange(value)}
															 sx={{'& .MuiSvgIcon-root': {fontSize: 14}}}/>}
										  label={<Typography sx={{fontSize: 12}}>
											  {t("settings.keySettings.downloadKeyTableLabel")}
										  </Typography>}/>
					</FormControl>

					{versionLoad === Status.SUCCESS ?
						<div className={"settings-main_version"}>{applicationVersion}</div> :
						null}
				</>
				: null}
		</div>
	);
};

export default SettingsMain
