import {AxiosInstance} from "axios";
import ProjectDetails from "../models/ProjectDetails";
import ProjectLogItem from "../models/ProjectLogItem";
import {DrawingType} from "../models/enums";
import DrawingDetails from "../models/DrawingDetails";
import Section from "../models/Section";
import ProjectExportResult from "../models/ProjectExportResult";
import ProjectExportStatusResult from "../models/ProjectExportStatusResult";
import {AttachmentDetailsFolder} from "../models/AttachmentDetailsFolder";
import {AttachmentDetails} from "../models/AttachmentDetails";
import SectionCreationResult from "../models/SectionCreationResult";
import CostCenterCreationResult from "../models/CostCenterCreationResult";
import {
	ExportSummaryApiDto,
	ExternalAttachmentApiDto,
	PdfConversionParametersApiDto,
	PlanTemplatePageDataApiDto,
	PlanTemplatePreviewDataApiDto
} from "../models/restModels";
import {PlanTemplateData} from "../models/PlanTemplateData";
import {PlanTemplatePreviewData} from "../models/PlanTemplatePreviewData";
import {PdfPreviewsCreationData} from "../models/PdfPreviewsCreationData";
import {PdfPreviewsCreationStatus} from "../models/PdfPreviewsCreationStatus";
import {ProjectAttachmentImportInitializationData} from "../models/ProjectAttachmentImportInitializationData";
import {ExternalAttachmentImportState} from "../models/ExternalAttachmentImportState";
import {StartExportResult, SummariesExportStatusResult} from "../models/SummariesExportStatusResult";

export default class ProjectsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchProject(projectId: string): Promise<ProjectDetails> {
		const url = `/api/projects/${projectId}`;
		return this.axiosInstance.get(url).then(resp => ProjectDetails.fromJson(resp.data))
	}

	fetchJobProject(simproId: number): Promise<ProjectDetails> {
		const url = `/api/jobs/${simproId}/project`;
		return this.axiosInstance.get(url).then(resp => ProjectDetails.fromJson(resp.data))
	}

	fetchJobAttachments(simproId: number, folderId?: string): Promise<AttachmentDetails[]> {
		const url = `/api/jobs/${simproId}/attachments`
		const params = {folder_id: folderId}
		return this.axiosInstance.get(url, {params}).then(resp =>
			resp.data.map(AttachmentDetails.fromJson)
		)
	}

	fetchJobAttachmentsFolders(simproId: number, parentFolderId?: string): Promise<AttachmentDetailsFolder[]> {
		const url = `/api/jobs/${simproId}/attachments_folders`
		const params = {parent_folder_id: parentFolderId}
		return this.axiosInstance.get(url, {params}).then(resp =>
			resp.data.map(AttachmentDetailsFolder.fromJson)
		)
	}

	fetchQuoteProject(simproId: number): Promise<ProjectDetails> {
		const url = `/api/quotes/${simproId}/project`;
		return this.axiosInstance.get(url).then(resp => ProjectDetails.fromJson(resp.data))
	}

	fetchQuoteAttachments(simproId: number, folderId?: string): Promise<AttachmentDetails[]> {
		const url = `/api/quotes/${simproId}/attachments`
		const params = {folder_id: folderId}
		return this.axiosInstance.get(url, {params}).then(resp =>
			resp.data.map(AttachmentDetails.fromJson)
		)
	}

	fetchQuoteAttachmentsFolders(simproId: number, parentFolderId?: string): Promise<AttachmentDetailsFolder[]> {
		const url = `/api/quotes/${simproId}/attachments_folders`
		const params = {parent_folder_id: parentFolderId}
		return this.axiosInstance.get(url, {params}).then(resp =>
			resp.data.map(AttachmentDetailsFolder.fromJson)
		)
	}

	/**
	 * Fetches project tree
	 * @param projectId project id
	 * @param drawingType when omitted returns tree with takeoffs and asbuilts, otherwise filters based on type
	 */
	fetchTree(projectId: string, drawingType?: DrawingType): Promise<Section[]> {
		const url = `/api/projects/${projectId}/tree`;
		const params = {drawing_type: drawingType};
		return this.axiosInstance.get(url, {params}).then(resp => resp.data.map(Section.fromJson));
	}

	fetchPlanTemplates(projectId: string): Promise<PlanTemplateData[]> {
		const url = `/api/projects/${projectId}/templates`;
		return this.axiosInstance.get(url).then(resp => resp.data.templates.map(PlanTemplateData.fromJson));
	}

	postNewDrawing(projectId: string,
		sectionId: string,
		costCenterId: string,
		templateId: string,
		name: string,
		drawingType: DrawingType,
		pageNumber: number): Promise<DrawingDetails> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/cost_centers/${costCenterId}/drawings`
		const data = {templateId, name, drawingType, pageNumber};
		return this.axiosInstance.post(url, data).then(resp => DrawingDetails.fromJson(resp.data));
	}

	postNewBlankDrawing(
		projectId: string,
		sectionId: string,
		costCenterId: string,
		name: string,
		drawingType: DrawingType
	): Promise<DrawingDetails> {
		const url = `/api/projects/${projectId}/sections/${sectionId}/cost_centers/${costCenterId}/blank_drawing`;
		return this.axiosInstance.post(url, {name, drawingType}).then(resp => DrawingDetails.fromJson(resp.data));
	}

	fetchProjectLogs(projectId: string): Promise<ProjectLogItem[]> {
		const url = `/api/projects/${projectId}/logs`;
		return this.axiosInstance.get(url).then(resp => resp.data.map(ProjectLogItem.fromJson));
	}

	exportCostCenter(costCenterId: string): Promise<ProjectExportResult> {
		const url = `/api/cost_centers/${costCenterId}/export`;
		return this.axiosInstance.post(url).then(resp => ProjectExportResult.fromJson(resp.data));
	}

	startSummaryExport(projectId: string, summaries: ExportSummaryApiDto[]): Promise<StartExportResult> {
		const url = `/api/projects/${projectId}/summaries/export`
		const data = {summaries}
		return this.axiosInstance.post(url, data).then(resp => resp.data)
	}

	askForSummaryExportResults(taskId: string): Promise<SummariesExportStatusResult> {
		const url = `/api/summaries/export_status/${taskId}`
		return this.axiosInstance.get(url).then(resp => SummariesExportStatusResult.fromJson(resp.data))
	}

	startCostCentersExport(projectId: string, ids: string[]): Promise<ProjectExportStatusResult> {
		const url = `/api/projects/${projectId}/cost_centers/export`;
		const data = {costCentersIds: ids}
		return this.axiosInstance.post(url, data).then(resp => ProjectExportStatusResult.fromJson(resp.data))
	}

	fetchCostCentersExportStatus(projectExportStatusId: string): Promise<ProjectExportStatusResult> {
		const url = `/api/cost_centers/export_status/${projectExportStatusId}`
		return this.axiosInstance.get(url).then(resp => ProjectExportStatusResult.fromJson(resp.data))
	}

	postNewJobSection(id: string, name: string): Promise<SectionCreationResult> {
		const url = `/api/jobs/${id}/sections`
		return this.axiosInstance.post(url, {name}).then(resp => SectionCreationResult.fromJson(resp.data))
	}

	postNewQuoteSection(id: string, name: string): Promise<SectionCreationResult> {
		const url = `/api/quotes/${id}/sections`
		return this.axiosInstance.post(url, {name}).then(resp => SectionCreationResult.fromJson(resp.data))
	}

	postNewJobCostCenter(id: string,
		sectionId: string,
		name: string,
		companyCostCenter: number): Promise<CostCenterCreationResult> {
		const url = `/api/jobs/${id}/sections/${sectionId}/cost_centers/`
		return this.axiosInstance.post(url, {name, companyCostCenter}).then(resp => CostCenterCreationResult.fromJson(resp.data))
	}

	postNewQuoteCostCenter(id: string,
		sectionId: string,
		name: string,
		companyCostCenter: number): Promise<CostCenterCreationResult> {
		const url = `/api/quotes/${id}/sections/${sectionId}/cost_centers/`
		return this.axiosInstance.post(url, {name, companyCostCenter}).then(resp => CostCenterCreationResult.fromJson(resp.data))
	}

	deleteSection(sectionId: string): Promise<void> {
		const url = `/api/sections/${sectionId}`;
		return this.axiosInstance.delete(url);
	}

	deleteCostCenter(costCenterId: string): Promise<void> {
		const url = `/api/cost_centers/${costCenterId}`;
		return this.axiosInstance.delete(url);
	}

	moveDeletedCostCenterWithoutCreatingExternally(costCentersIds: string[], sectionId: string) {
		const url = "/api/cost_centers/move"
		return this.axiosInstance.put(url, {costCentersIds, sectionId})
	}

	initializeExternalFileImport(
		projectId: string,
		externalAttachmentData: ExternalAttachmentApiDto
	): Promise<ProjectAttachmentImportInitializationData> {
		const url = `/api/projects/${projectId}/external_attachment_import`
		return this.axiosInstance.post(url, externalAttachmentData).then(
			resp => ProjectAttachmentImportInitializationData.fromJson(resp.data))
	}

	fetchExternalAttachmentImportState(
		externalAttachmentImportId: string
	): Promise<ExternalAttachmentImportState> {
		const url = `/api/external_attachment_import/${externalAttachmentImportId}`
		return this.axiosInstance.get(url).then(
			resp => ExternalAttachmentImportState.fromJson(resp.data))
	}

	createPlanTemplate(
		projectId: string,
		attachmentId: string,
		attachmentName: string,
		fullPageCount: number
	): Promise<PlanTemplateData> {
		const url = `/api/projects/${projectId}/templates`;
		const data = {attachmentId, name: attachmentName, fullPageCount}
		return this.axiosInstance.post(url, data).then(resp => PlanTemplateData.fromJson(resp.data))
	}

	editPlanTemplateName(
		projectId: string,
		templateId: string,
		newName: string
	): Promise<void> {
		const url = `/api/projects/${projectId}/templates/${templateId}`;
		return this.axiosInstance.put(url, {name: newName})
	}

	deletePlanTemplate(
		projectId: string,
		templateId: string,
	): Promise<void> {
		const url = `/api/projects/${projectId}/templates/${templateId}`;
		return this.axiosInstance.delete(url)
	}

	deletePlanPage(
		projectId: string,
		templateId: string,
		pageNumber: number
	): Promise<void> {
		const url = `/api/projects/${projectId}/templates/${templateId}/pages/${pageNumber}`;
		return this.axiosInstance.delete(url)
	}

	createPlanTemplatePage(projectId: string, templateId: string, pageData: PlanTemplatePageDataApiDto): Promise<void> {
		const url = `/api/projects/${projectId}/templates/${templateId}/pages`;
		return this.axiosInstance.post(url, pageData);
	}

	editPlanPageName(
		projectId: string,
		templateId: string,
		pageNumber: number,
		newName: string
	): Promise<void> {
		const url = `/api/projects/${projectId}/templates/${templateId}/pages/${pageNumber}`;
		return this.axiosInstance.put(url, {title: newName})
	}

	startPdfPreviewsCreation(projectId: string, templateId: string,
		pageData: PdfConversionParametersApiDto): Promise<PdfPreviewsCreationData> {
		const url = `/api/projects/${projectId}/templates/${templateId}/pdf_previews`;
		return this.axiosInstance.post(url, pageData).then(
			resp => PdfPreviewsCreationData.fromJson(resp.data))
	}

	fetchPdfPreviewsCreationStatus(pdfPreviewId: string): Promise<PdfPreviewsCreationStatus> {
		const url = `/api/queue/pdf_previews/${pdfPreviewId}`;
		return this.axiosInstance.get(url).then(
			resp => PdfPreviewsCreationStatus.fromJson(resp.data))
	}

	getPdfPreviews(pdfPreviewId: string): Promise<PlanTemplatePreviewData[]> {
		const url = `/api/pdf_previews/${pdfPreviewId}`;
		return this.axiosInstance.get(url).then(
			resp => resp.data.previews.map((preview: PlanTemplatePreviewDataApiDto) => PlanTemplatePreviewData.fromJson(preview)))
	}
}