import "./symbolRecognitionSensitivitySlider.tsx.scss"
import {useTranslation} from "react-i18next";
import {Slider} from "@mui/material";
import React from "react";

type SymbolRecognitionSensitivitySliderProps = {
	sliderValue: number,
	sliderMaxValue: number,
	onSliderChange: (value: number) => void
}


const SymbolRecognitionSensitivitySlider: React.FC<SymbolRecognitionSensitivitySliderProps> = ({
	sliderValue,
	sliderMaxValue,
	onSliderChange
}) => {
	const {t} = useTranslation();
	const sliderMinValue = 0;

	return (
		<div className={"sensitivity-slider-container"}>
			<span className={"sensitivity-slider-container_label"}>
				{t("editor.symbolRecognitionPanel.matchDisplayScreen.sensitivitySection.sliderLabel1")}</span>
			<Slider
				min={sliderMinValue}
				value={sliderValue}
				onChange={(_, value) => {onSliderChange(value as number)}}
				max={sliderMaxValue}
				sx={{
					height: "9px",
					marginLeft: "13px",
					marginRight: "13px",
					'& .MuiSlider-thumb': {
						height: "14px",
						width: "15px",
						color: "#1976D2",
						'&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
							boxShadow: 'inherit',
						},
					},
					'& .MuiSlider-rail': {
						opacity: Math.max(sliderValue / sliderMaxValue, 0.3),
						color: "#A9DAFF",
						border: "0.5px solid rgba(34, 155, 255, 0.3)"
					},
					'& .MuiSlider-track': {
						opacity: 1,
						color: "#A9DAFF",
					}
				}}/>
			<span className={"sensitivity-slider-container_label"}>
			{t("editor.symbolRecognitionPanel.matchDisplayScreen.sensitivitySection.sliderLabel2")}
			</span>
		</div>
	)
}

export default SymbolRecognitionSensitivitySlider;
