import {AreaFragment, AreaMeasurement, EditorLine, EditorPoint, PreviewLineProps} from "../../models/editor";
import {Vector2d} from "konva/types/types";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {EditorTool} from "../../models/enums";
import {selectActiveAreaId} from "./areaSlice";
import {Group} from "react-konva";
import {AreaFragmentElement} from "./AreaFragmentElement";
import {selectExportInProgress, selectSnappingState} from "../view/viewSlice";
import {AreaDeductionElement} from "./AreaDeductionElement";
import {distance, lineAngle, midpoint} from "../../utils";
import {useSnapToPoint} from "../length/snapUtils";
import {getId} from "../../../../../../utils";
import {getSnappedPosition} from "../../../../../base-konva/utils";

type AreaElementProps = {
	area: AreaMeasurement
	isPointerOverLayer: boolean,
	hookTool: EditorTool,
	pointerLocation?: Vector2d
}

const _AreaElement: FC<AreaElementProps> = function({
	area,
	isPointerOverLayer,
	hookTool,
	pointerLocation,
}) {
	const {snapToPoint} = useSnapToPoint()
	const snapping = useSelector(selectSnappingState);
	const areaActive = useSelector(selectActiveAreaId) === area.id && area.activeDeductionId === null
	const exportInProgress = useSelector(selectExportInProgress);
	const visible = area.visible || exportInProgress;
	const previewLineProps = getPreviewLineProps()

	function getPreviewLineProps(): PreviewLineProps {
		if (areaActive) {
			const activeFragment = area.areaFragments.find(fragment => fragment.id === area.activeAreaFragmentId)
			if (activeFragment) {
				return {
					previewLine: getPreviewLine(activeFragment),
					previewLineFragmentId: activeFragment.id
				}
			}
		}
		return {
			previewLine: null,
			previewLineFragmentId: null
		}
	}

	function getPreviewLine(areaFragment: AreaFragment): EditorLine | null {
		const {lastMouseUpPosition} = areaFragment;
		let previewLine: EditorLine | null = null;
		const pointerPosition = pointerLocation ?? {x: 0, y: 0}

		if (lastMouseUpPosition) {
			const lastPointer: EditorPoint = {
				id: getId(),
				position: lastMouseUpPosition
			};
			const pointer: EditorPoint = {
				id: getId(),
				position:
					snapping.point ? snapToPoint(pointerPosition).snapTo :
						snapping.angle
							? getSnappedPosition(lastMouseUpPosition, pointerPosition)
							: pointerPosition
			};
			previewLine = {
				id: getId(),
				from: lastPointer,
				to: pointer,
				angle: lineAngle(lastPointer.position, pointer.position),
				center: midpoint(lastPointer.position, pointer.position),
				distance: distance(lastPointer.position, pointer.position)
			}
		}

		return previewLine;
	}

	return <Group visible={visible}>
		{area.areaFragments.map(fragment => {
			const previewLine = previewLineProps.previewLineFragmentId === fragment.id
				? previewLineProps.previewLine
				: null

			return (
				<AreaFragmentElement
					key={fragment.id}
					areaFragment={fragment}
					areaFragmentActive={area.activeAreaFragmentId === fragment.id}
					isPointerOverLayer={isPointerOverLayer}
					areaId={area.id}
					areaStyle={area.style}
					activeDeductionId={area.activeDeductionId}
					areaActive={areaActive}
					areaHeight={area.height}
					hookTool={hookTool}
					pointerLocation={pointerLocation}
					areaHasPreviewLine={previewLineProps.previewLineFragmentId !== null}
					previewLine={previewLine}
				/>
			);
		})}
		{area.deductions.map(deduction => (
			<AreaDeductionElement
				key={deduction.id}
				area={area}
				deduction={deduction}
				hookTool={hookTool}
				isPointerOverLayer={isPointerOverLayer}
				pointerLocation={pointerLocation}
			/>
		))}
	</Group>
}

export const AreaElement = React.memo(_AreaElement)