import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import {Status} from "../../../../../../../../models/enums";
import {apiInstance} from "../../../../../../../../api/api";
import LoggerService from "../../../../../../../../services/LoggerService";
import NotificationService from "../../../../../../../../services/NotificationService";
import {FETCH_TAKEOFF_TEMPLATES_QUERY_KEY} from "../../../../../../../../api/TakeoffTemplatesApi";
import {useQuery} from "react-query";
import {toStatus} from "../../../../../../../../utils/ReactQueryUtils";

type QueryKey = [string, QueryKeyParams]
type QueryKeyParams = {
	page: number,
	size: number,
	query?: string,
	groupId?: number | null,
	subGroupId?: number | null
}

export function useTakeoffTemplates(enabled: boolean, query?: string, groupId?: number | null, subGroupId?: number | null) {
	const pageSize = 50;
	const {t} = useTranslation();
	const [pageNumber, setPageNumber] = useState(0);

	const queryKey: QueryKey = React.useMemo(() => {
		return [FETCH_TAKEOFF_TEMPLATES_QUERY_KEY, {page: pageNumber, query, groupId, subGroupId, size: pageSize}]
	}, [groupId, pageNumber, query, subGroupId, pageSize])

	const {data, status, isPreviousData} = useQuery({
		queryKey,
		queryFn: ({queryKey}) => {
			const [, {page, size, query, groupId, subGroupId}] = queryKey
			return apiInstance.takeoffTemplatesApi.fetchTakeoffTemplates(
				page, size, query, groupId, subGroupId
			)
		},
		onError: err => {
			LoggerService.logError(err);
			NotificationService.errorNotification(
				t("common.error"),
				t("projects.addItemModal.takeoffTemplates.loadErrorDesc")
			);
		},
		enabled,
		keepPreviousData: true
	})

	const items = React.useMemo(() => data?.records ?? [], [data])
	const totalPages = React.useMemo(() => data?.totalPages || 0, [data])
	const loadStatus = React.useMemo(() => {
		if (isPreviousData) {
			return Status.LOADING
		}
		return toStatus(status)
	}, [isPreviousData, status])

	const onPageChange = React.useCallback((page: number) => {
		setPageNumber(page)
	}, [])

	return {items, loadStatus, onPageChange, pageNumber, totalPages};
}