import React, {useEffect, useState} from "react";
import TextInput from "./TextInput";

type MinMaxValidatedNumberInputProps = {
	label?: string
	min: number,
	max: number,
	value: number,
	onChange(val: number): void
}
export const MinMaxValidatedNumberInput: React.FC<MinMaxValidatedNumberInputProps> = ({
	label, min, max, value, onChange,
}) => {
	const [displayValue, setDisplayValue] = useState<string>(value.toString());

	useEffect(() => {
		setDisplayValue(value.toString())
	}, [value])

	return (
		<TextInput
			type={"number"}
			onKeyUp={(e) => {
				e.stopPropagation()
			}}
			{...{label, min, max, value: displayValue}}
			onChange={event => {
				setDisplayValue(event.target.value)
			}}
			onBlur={() => {
				const currentNumber = Number(displayValue)
				let validValue = currentNumber;

				if (currentNumber < min) {
					validValue = min
				}
				else if (currentNumber > max) {
					validValue = max
				}

				onChange(validValue)
				setDisplayValue(validValue.toString())
			}}
		/>
	)
}