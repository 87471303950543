import React from "react";
import {IconProps} from "../models/interfaces";
import {Colors} from "../../../../../styles";

const IconAdd: React.FC<IconProps> = ({disabled = false, onClick}) => {

	const fill = disabled ? "#82888c" : Colors.SIMPROBLUE;
	const cursor = disabled ? "not-allowed" : "pointer";
	const handleClick = () => !disabled && onClick();

	return (
		<svg width={32} height={32} viewBox="0 1 16 16" onClick={handleClick} style={{cursor: cursor}}>
			<polygon fill={fill} points={"10 4 8 4 8 8 4 8 4 10 8 10 8 14 10 14 10 10 14 10 14 8 10 8"}/>
		</svg>
	);
};

export default IconAdd