import React from "react";
import {ProjectDrawingTitle} from "../components/ProjectDrawingTitle";
import Page from "../../../components/layout/page/Page";
import {NavigationHeader} from "../../../routes";
import ProjectDrawingWrapper from "../components/ProjectDrawingWrapper";
import {DrawingDetailsProvider} from "../providers/DrawingDetailsProvider";
import {ScaleSetProvider} from "../features/editor/features/scale/ScaleSetProvider";
import {useParams} from "react-router-dom";
import {editorStore} from "../features/editor/editorStore";
import {Provider} from "react-redux";

const ProjectDrawingPage: React.FC = () => {
	const {
		projectId,
		drawingId,
		versionId,
		costCenterId,
		sectionId
	} = useParams<{ projectId: string, drawingId: string, versionId: string, costCenterId: string, sectionId: string }>();

	return (
		<Provider store={editorStore}>
			<DrawingDetailsProvider
				data={{
					projectId,
					drawingId,
					sectionId,
					costCenterId,
					versionId
				}}
				silent={false}
				lockDrawingOnLoad={true}
			>
				<ScaleSetProvider drawingId={drawingId}>
					<Page>
						<NavigationHeader/>
						<ProjectDrawingTitle/>
						<ProjectDrawingWrapper/>
					</Page>
				</ScaleSetProvider>
			</DrawingDetailsProvider>
		</Provider>
	)
};

export {ProjectDrawingPage}
