import {FitTimeApiDto} from "./restModels";
import i18n from "../i18n";

export default class FitTime {
	constructor(public readonly id: number,
		public readonly name: string,
		public readonly multiplier: number,
		public readonly archived: boolean) {}

	public static fromJson(json: FitTimeApiDto): FitTime {
		return new FitTime(json.id, json.name, json.multiplier, json.archived);
	}

	getFormattedMultiplier(): string {
		return `${this.multiplier}${i18n.t("projects.summary.itemsTable.suffixes.fitTimeMultiplier")}`;
	}
}
