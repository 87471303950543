import {DrawingItemType, PageSizeFormat, TextAlign, TextStyle} from "./enums";
import {Vector2d} from "konva/types/types";
import {ReactNode} from "react";
import {KonvaEventObject} from "konva/types/Node";

export const LEFT_POINT = "LEFT_POINT"
export const TOP_POINT = "TOP_POINT"
export const RIGHT_POINT = "RIGHT_POINT"
export const BOTTOM_POINT = "BOTTOM_POINT"

export type Vertices = {
	topLeft: Vector2d,
	topRight: Vector2d,
	bottomLeft: Vector2d,
	bottomRight: Vector2d
}

export type Rotation =
	| typeof ROTATION_NONE
	| typeof ROTATION_NINETY
	| typeof ROTATION_ONE_EIGHTY
	| typeof ROTATION_TWO_SEVENTY

export const ROTATION_NONE = 0;
export const ROTATION_NINETY = 90;
export const ROTATION_ONE_EIGHTY = 180;
export const ROTATION_TWO_SEVENTY = 270;

export type ZoomState = {
	scale: number
	savedScale: number | undefined
	minScale: number
	viewportSize: { height: number, width: number }
	rotation: Rotation
}

export type BaseStageConfig = {
	height: number,
	width: number,
}

export type BaseViewState = {
	zoom: ZoomState
	panInProgress: boolean,
	editorIsDirty: boolean
	scaleOptions: ScaleOptionsState,
	exportInProgress: boolean,
	selection: SelectedItem[],
	highlight: HighlightState,
	remoteContentLoadedInfo: RemoteContentLoadedInfo,
}

export enum PenToolType {
	FREE = "FREE",
	POLYLINE = "POLYLINE"
}

export enum DashPatternType {
	LINE = "LINE",
	DOT = "DOT",
	DASH = "DASH",
	DASHDOT = "DASHDOT"
}

export type LineStyle = {
	stroke: string,
	strokeWidth: number,
	dashType: DashPatternType
}

export type GroupedActionPayload = {
	actionId?: string
}

export type UndoRedoExclusionPayload = {
	isUndoRedoExcluded?: boolean
}

export type PenToolState = {
	penGroups: PenGroup[],
	activePenGroupId?: string
	type: PenToolType,
	penSelectorStyle: LineStyle,
}

export type ScaleState = {
	scaleConfig: ScaleConfig
}

export type PenLine = {
	id: string,
	name: string,
	points: number[],
	visible: boolean,
}

export type PenGroup = {
	id: string,
	name: string,
	visible: boolean,
	penLines: PenLine[]
	activePenLineId?: string
	style: LineStyle
}

export type TextToolState = {
	textGroups: TextGroup[],
	activeTextGroupId?: string
	textSelectorStyle: TextToolStyle,
}

export type TextItem = {
	id: string,
	isEditing: boolean,
	isSelected: boolean,
	text: string,
	position: Vector2d,
	rotation: number,
	visible: boolean,
}

export type TextGroup = {
	id: string,
	name: string,
	visible: boolean,
	textItems: TextItem[],
	activeTextItemId?: string,
	style: TextToolStyle,
}

export type ActionIdValuePayload<T> = {
	value: T
} & GroupedActionPayload & UndoRedoExclusionPayload

export type ActivateActionPayload = {
	id: string
} & GroupedActionPayload

export type DeleteActionPayload = ActivateActionPayload & GroupedActionPayload

export type ParentChildIdPayload = {
	parentId: string,
	id: string,
}

export type DeleteChildItemActionPayload = ParentChildIdPayload & GroupedActionPayload

export type ActivatePenLineActionPayload = ParentChildIdPayload

export type FragmentActionPayload = {
	fragmentId?: string,
}

export type SubItemsActionPayload = {
	subIds?: string[],
}

export type DeleteSelectedItemActionPayload =
	Partial<ActivateActionPayload>
	& SubItemsActionPayload
	& FragmentActionPayload
	& GroupedActionPayload

export type SetVisibilityActionPayload = {
	id: string,
	visible: boolean,
	setAll?: boolean,
} & GroupedActionPayload

export type SetItemVisibilityActionPayload = SetVisibilityActionPayload & {
	parentId: string;
}

export type ScaleOptionsState = {
	ratioInput: number | undefined,
	pageSize: PageSizeFormat
}

export type KonvaEventType = MouseEvent | TouchEvent;

export type SnappingState = {
	angle: boolean,
	point: boolean,
}

export type ScaleConfig = {
	scalePoints: Vector2d[],
	scale: number | null,
	inputValue: number | null
	scaleInfoState: ScaleInfoState
}

export interface ScaleInfoState {
	containerWidth: number,
	unitWidth: number,
	unitFactor: number,
	position: Vector2d | null,
	visible: boolean
}

export type PageScaleRatioPayload = {
	scaleRatio: number,
	pageFormat: PageSizeFormat
}

export type PointDragState = {
	isDragging: boolean
	dragPointId?: string
	dragPointCurrentLocation?: Vector2d
}

export type BaseToolHookResult = {
	id: string,
	render: () => ReactNode,
	callbacks: {
		onMouseUp?: (evt: KonvaEventObject<KonvaEventType>) => void
		onMouseDown?: (evt: KonvaEventObject<KonvaEventType>) => void
		onMouseMove?: (evt: KonvaEventObject<KonvaEventType>) => void
		onDblClick?: (evt: KonvaEventObject<KonvaEventType>) => void
	}
}
export type ImageData = {
	id: string,
	attachmentId: string,
	name: string,
	visible: boolean,
	position: Vector2d,
	rotation: number,
	scale: Vector2d,
}

export type ImageElementTransformationPayload = UpdateItemPositionActionPayload & {
	rotation: number
	scale: Vector2d
}

export type ImageState = {
	images: ImageData[];
	activeImageId?: string;
}

export type SelectedItem = {
	type: DrawingItemType,
	itemId: string,
	fragmentId?: string,
	subItemIds: string[]
}

export type ClipState = {
	selection?: SelectionState,
	clipArea?: SelectionState
}

export type EraseAreaState = {
	selection?: SelectionState,
	erasedAreas: SelectionState[]
}

export type SelectionState = {
	x: number,
	y: number,
	height: number,
	width: number
}

export type TextToolStyle = {
	fontFamily: string,
	fontSize: number,
	fontStyle: TextStyle,
	align: TextAlign,
	fill: string,
	backgroundFill?: string,
}

export type UpdateItemPositionActionPayload = {
	itemId: string, newPosition: Vector2d
}

export type HighlightItemActionPayload = Partial<ActivateActionPayload>

export type RemoveHighlightItemActionPayload = {}

export type SelectItemActionPayload =
	Partial<ActivateActionPayload>
	& SubItemsActionPayload
	& DrawingItemTypePayload
	& FragmentActionPayload
	& GroupedActionPayload

export type DrawingItemTypePayload = {
	type: DrawingItemType
}

export type HighlightState = {
	itemId?: string,
}

export type RemoteContentLoadedInfo = {
	allItemsLoaded: boolean,
	loadedInfos: { id: string, loaded: boolean }[]
}