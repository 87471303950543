import React, {useState} from "react";
import {Tree} from "../components/tree/Tree";
import "./projectDetails.scss";
import {PlanTemplates} from "../components/plan-templates/PlanTemplates";
import {useProjectDetailContext} from "../providers/ProjectDetailProvider";
import {ProjectDetailsHeader} from "../components/ProjectDetailsHeader";
import Page from "../../../components/layout/page/Page";
import {NavigationHeader} from "../../../routes";
import Button from "../../../components/ui/Button";
import {useTranslation} from "react-i18next";
import {ProjectDetailsModal} from "../../../models/enums";
import {useRefetchProjectTreeOnMount} from "../hooks";
import {SyncMaterialsModal} from "../features/sync-materials";
import {canSyncMaterials} from "../utils";

const ProjectDetails: React.FC = () => {

	useRefetchProjectTreeOnMount()

	const {t} = useTranslation()
	const [modal, setModal] = useState<ProjectDetailsModal>(ProjectDetailsModal.NONE);
	const {planTemplates, header, treeData, projectDetails} = useProjectDetailContext()
	const canSyncMaterialsValue = canSyncMaterials(projectDetails.project)

	const closeModal = () => { setModal(ProjectDetailsModal.NONE) }
	const openSyncMaterialsModal = () => {
		if (canSyncMaterialsValue) {
			setModal(ProjectDetailsModal.SYNC_MATERIALS)
		}
	}

	return (
		<Page>
			{modal === ProjectDetailsModal.SYNC_MATERIALS ? (
				<SyncMaterialsModal onClose={closeModal}/>
			) : null}
			<NavigationHeader>
				<Button
					variant={"secondary"}
					disabled={!canSyncMaterialsValue}
					label={t("projects.details.syncMaterials")}
					onClick={openSyncMaterialsModal}
				/>
			</NavigationHeader>
			<div className="project-details-page">
				<ProjectDetailsHeader {...header}/>
				<div className="project-details-page_content">
					<PlanTemplates {...planTemplates}/>
					<Tree {...treeData}/>
				</div>
			</div>
		</Page>
	);
};

export default ProjectDetails

