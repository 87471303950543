import {selectSelectionState, viewActions} from "../features/view/viewSlice";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useRef, useState} from "react";
import {EditorTool} from "../models/enums";
import {selectActiveTool} from "../features/config/configSlice";
import {selectionStyle} from "../../../../base-konva/constants";
import {DrawingItemType} from "../../../../base-konva/enums";
import {SelectedItem} from "../../../../base-konva/types";

export function useSelectSetter() {
	const dispatch = useDispatch();

	function setSelection(type: DrawingItemType, id: string, fragmentId?: string, subItemsIds?: string[], actionId?: string) {
		dispatch(viewActions.selectItem({id: id, subIds: subItemsIds, type: type, fragmentId, actionId}));
	}

	function clearSelection() {
		dispatch(viewActions.clearSelection({}));
	}

	return {
		setSelection,
		clearSelection,
	}
}

export function useSelectionCleaner(tool: EditorTool) {
	const activeTool = useSelector(selectActiveTool);
	const selectActions = useSelectSetter();
	const selectActionsRef = useRef(selectActions)

	useEffect(() => {
		const selectActions = selectActionsRef.current
		if (activeTool !== tool) {
			selectActions.clearSelection()
		}
	}, [activeTool, tool, selectActionsRef]);
}

export function useSelectedItem(itemId: string) {
	const [isHover, setIsHover] = useState(false);
	const selection = useSelector(selectSelectionState);

	useEffect(() => {
		setIsHover(selection.some(item => item.itemId === itemId));
	}, [selection, itemId]);

	return {
		isItemHover: isHover
	}
}

export function useGetSelectionShapeStyle() {
	const selection = useSelector(selectSelectionState);

	function getSelectionStyle(isSelected: (selected: SelectedItem[]) => boolean) {
		return isSelected(selection) ? selectionStyle : {}
	}

	return {
		getSelectionStyle
	}
}
