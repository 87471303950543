import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "../../../../../components/modal/Modal";
import ModalTitle from "../../../../../components/modal/ModalTitle";
import "./projectExportErrorsModal.scss";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import {Status} from "../../../../../models/enums";
import IconDoneSmall from "../../../../../components/icons/IconDoneSmall";
import IconXSmall from "../../../../../components/icons/IxonXSmall";
import InlineSpinner from "../../../../../components/InlineSpinner";
import "./ccExportStatusModal.scss";
import {useSyncMaterialsContext} from "../providers/SyncMaterialsDataProvider";
import {ExportStatus} from "../../../../../models/SummariesExportStatusResult";

interface CcExportStatusModalProps {
	onTryAgain: () => void,
	onShowError: () => void,
	onClose: () => void,
}

const CcExportStatusModal: React.FC<CcExportStatusModalProps> = ({
	onTryAgain,
	onShowError,
	onClose,
}) => {

	const {result: {status, costCenterExportStatuses}} = useSyncMaterialsContext()
	const {t} = useTranslation();

	const renderIcon = (status: ExportStatus) => {
		return (
			<>
				{status === ExportStatus.PROGRESS && <InlineSpinner/>}
				{status === ExportStatus.PENDING && t("projects.summary.exportStatusModal.pending")}
				{status === ExportStatus.COMPLETE && <IconDoneSmall/>}
				{status === ExportStatus.FAIL && <IconXSmall/>}
			</>
		)
	};

	function hasErrors(): boolean {
		return costCenterExportStatuses.some(ccStatus =>
			ccStatus.exportMeasurementStatus === ExportStatus.FAIL
		)
	}

	return (
		<Modal isOpen={true} onClose={onClose} customClass="export-status-modal">
			{/* Title: Job update, Quote update */}
			<ModalTitle>{t("projects.summary.exportStatusModal.title")}</ModalTitle>
			<table>
				<thead>
				<tr>
					<th>{t("projects.summary.exportStatusModal.columns.costCenter")}</th>
					<th>{t("projects.summary.exportStatusModal.columns.measurementStatus")}</th>
					<th>{t("projects.summary.exportStatusModal.columns.summaryStatus")}</th>
				</tr>
				</thead>
				<tbody>
				{costCenterExportStatuses.map(result => (
					<tr key={result.costCenter.id}>
						<td>{result.costCenter.name}</td>
						<td>{renderIcon(result.exportMeasurementStatus)}</td>
						<td>{renderIcon(result.exportSummaryStatus)}</td>
					</tr>
				))}
				</tbody>
			</table>
			<ModalButtons>
				{status === Status.ERROR &&
					<>
						{hasErrors() &&
							<Button
								label={t("projects.summary.exportStatusModal.buttons.showErrorDetails")}
								variant={"optional"}
								onClick={onShowError}
							/>
						}
						<Button
							label={t("projects.summary.exportStatusModal.buttons.tryAgain")}
							variant={"primary"}
							onClick={onTryAgain}
						/>
						<Button
							label={t("projects.summary.exportStatusModal.buttons.goBack")}
							variant={"optional"}
							onClick={onClose}
						/>
					</>
				}
				{status === Status.SUCCESS ? (
					<Button
						label={t("projects.summary.exportStatusModal.buttons.close")}
						variant={"optional"}
						onClick={onClose}
					/>
				) : null}
			</ModalButtons>
		</Modal>
	);
};

export default CcExportStatusModal;
