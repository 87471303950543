import React from "react";
import "./iconAnchor.scss";
import {SelectableIconProps} from "../../../models/interfaces";
import {getDefaultAnchorIconClasses} from "../../iconUtils";

const IconAnchor: React.FC<SelectableIconProps> = ({paths, onClick, active = false, disabled = false}) => {

	const handleClick = () => !disabled && onClick();

	const cls = getDefaultAnchorIconClasses(active, disabled)

	return (
		<div onClick={handleClick} className={cls}>
			<svg width={24} height={24} viewBox="0 0 24 24">
				{paths.map((path, index) =>
					<path
						key={index}
						d={path}/>)}
			</svg>
		</div>
	);
};

export default IconAnchor

