import * as Redux from "redux";
import {areaActions, selectActiveAreaId} from "../features/area/areaSlice";
import {countActions, selectActiveCountGroupId} from "../features/count/countSlice";
import {imageActions, selectActiveImageId} from "../features/image/imageSlice";
import {lengthActions, selectActiveLengthId} from "../features/length/lengthSlice";
import {penActions, selectActivePenGroupId} from "../features/pen/penSlice";
import {selectActiveTextGroupId, textActions} from "../features/text/textSlice";
import {configActions} from "../features/config/configSlice";
import {EditorTool} from "../models/enums";

const shouldCleanSet = new Set([
	areaActions.addArea.type,
	lengthActions.addLength.type,
	countActions.addCount.type,
	countActions.activateCountGroup.type,
	lengthActions.activateLength.type,
	areaActions.activateArea.type,
	penActions.activatePenLine.type,
	textActions.activateTextItem.type,
	imageActions.activateImage.type,
])

export const activeItemsCleaner: Redux.Middleware = store => next => action => {
	const {dispatch} = store;

	function clearActiveItems() {
		const activeCountGroupId = selectActiveCountGroupId(store.getState());
		const activeLengthId = selectActiveLengthId(store.getState());
		const activeAreaId = selectActiveAreaId(store.getState());
		const activeTextGroupId = selectActiveTextGroupId(store.getState());
		const activePenGroupId = selectActivePenGroupId(store.getState());
		const activeImageId = selectActiveImageId(store.getState());
		if (activeCountGroupId) {
			dispatch(countActions.clearActiveCountGroup({}));
		}
		if (activeLengthId) {
			dispatch(lengthActions.clearActiveLength({}));
		}
		if (activeAreaId) {
			dispatch(areaActions.clearActiveArea({}));
		}
		if (activeTextGroupId) {
			dispatch(textActions.deactivateTextGroup({}));
		}
		if (activePenGroupId) {
			dispatch(penActions.deactivatePenGroup({}));
		}
		if (activeImageId) {
			dispatch(imageActions.deactivateImage({}));
		}
	}

	const isMeasurementSelectSwitch =
		action.type === configActions.switchEditorTool.type &&
		action.payload === EditorTool.MEASUREMENT_SELECT;

	if (shouldCleanSet.has(action.type) || isMeasurementSelectSwitch) {
		clearActiveItems()
	}

	next(action)
}