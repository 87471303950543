import React, {PropsWithChildren} from "react";
import NumberInput from "./NumberInput";
import {feetToImperialLength, imperialLengthToFeet, INCH_TO_FOOT_RATIO} from "../../utils/ConversionUtils";
import {useTranslation} from "react-i18next";
import "./imperialLengthInput.scss";

interface ImperialLengthInputProps {
	label: string,
	value: number | undefined,
	onFocus?: () => void,
	onChange: (value: number | undefined) => void;
	isError?: boolean
}

const ImperialLengthInput: React.FC<PropsWithChildren<ImperialLengthInputProps>> = (props) => {
	const {t} = useTranslation();
	const imperialLength = feetToImperialLength(props.value);

	const onFeetChange = (feet: number | undefined) => {
		props.onChange(imperialLengthToFeet({...imperialLength, feet}));
	};

	const onInchesChange = (inches: number | undefined) => {
		if ((inches || 0) < INCH_TO_FOOT_RATIO) {
			props.onChange(imperialLengthToFeet({...imperialLength, inches}));
		}
	};

	return (
		<div>
			<span className="imperial-length-input_label">{props.label}</span>
			<div className="imperial-length-input_container">
				<NumberInput
					label={`[${t("common.units.footSymbol")}]`}
					value={imperialLength?.feet}
					isError={props.isError}
					onChange={onFeetChange}
					min={0}
					placeholder={t("editor.imperialLengthInput.feetInputPlaceholder")}
					onFocus={props.onFocus}
				/>
				<div className="imperial-length-input_spacer"/>
				<NumberInput
					label={`[${t("common.units.inchSymbol")}]`}
					value={imperialLength?.inches}
					isError={props.isError}
					onChange={onInchesChange}
					min={0}
					placeholder={t("editor.imperialLengthInput.inchesInputPlaceholder")}
					onFocus={props.onFocus}
				/>
			</div>
		</div>
	);
};

export default ImperialLengthInput;
