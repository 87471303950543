import React from "react";
import "./commonDropdown.scss";
import {usePopper} from "../popper";

export interface IDropdownContextData {
	onClose: () => void,
}

const DropdownContext = React.createContext<IDropdownContextData | undefined>(undefined);

type CommonDropdownProps = {
	button: JSX.Element,
	disabled?: boolean
	variant?: "invertedBlue"
}

const CommonDropdown: React.FC<CommonDropdownProps> = ({
	children, button, disabled, variant
}) => {
	const variantMap = {
		"invertedBlue": "inverted-blue",
	}

	const cls = [
		"dropdown",
		disabled && "disabled",
	].filter(Boolean).join(" ");

	const {renderPopper, isPopperOpen, closePopper} = usePopper({placement: "bottom-end", disabled})

	return (
		<div className={cls}>
			{renderPopper({
				target: button,
				content: (
					<nav className={
						`dropdown_menu 
						${isPopperOpen ? 'active' : 'inactive'} 
						${variant ? variantMap[variant] : ""}`
					}>
						<DropdownContext.Provider value={{onClose: closePopper}}>
							<ul>
								{children}
							</ul>
						</DropdownContext.Provider>
					</nav>
				)
			})}
		</div>
	)
};

function useDropdownContext() {
	const context = React.useContext(DropdownContext)
	if (!context) {
		throw new Error("useDropdownContext must be used within a CommonDropdown")
	}
	return context
}

const DropdownContextBridge = {
	Consumer: DropdownContext.Consumer,
	Provider: DropdownContext.Provider
}

export default CommonDropdown;
export {useDropdownContext, DropdownContextBridge}
