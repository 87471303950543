import React from "react";
import {useTranslation} from "react-i18next";
import "../../../../base-konva/features/text/styleSelector.scss";
import {CountMeasurementStyle} from "../models/editor";
import SymbolIconSelector from "./SymbolIconSelector";
import ColorPicker from "./ColorPicker";
import {MinMaxValidatedNumberInput} from "./MinMaxValidatedNumberInput";

interface SymbolStyleSelectorProps {
	style: CountMeasurementStyle,
	onStyleChange: (style: CountMeasurementStyle) => void,
}

const SymbolStyleSelector: React.FC<SymbolStyleSelectorProps> = ({style, onStyleChange}) => {

	const {t} = useTranslation();

	return (
		<div className={"style-selector"}>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.countStyleEditor.size")}</div>
				<div className={"style-selector_field_input"}>
					<MinMaxValidatedNumberInput
						min={5} max={120} value={style.size}
						onChange={value => {onStyleChange({...style, size: value})}}
					/>
				</div>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.countStyleEditor.color")}</div>
				<ColorPicker color={style.color} onChange={color => {onStyleChange({...style, color})}}/>
			</div>
			<div className={"style-selector_field"}>
				<div className={"style-selector_field_label"}>{t("editor.countStyleEditor.symbol")}</div>
				<SymbolIconSelector
					symbol={style.symbol}
					color={style.color}
					remoteSymbol={style.remoteSymbol}
					onSymbolChange={(changeArgs => {
						const newStyle = {...style};
						if (changeArgs.symbol) {
							delete newStyle.remoteSymbol
							newStyle.symbol = changeArgs.symbol
						}
						else if (changeArgs.remoteSymbol) {
							delete newStyle.symbol
							newStyle.remoteSymbol = changeArgs.remoteSymbol
						}
						onStyleChange(newStyle)
					})}
				/>
			</div>
		</div>
	);
};

export default SymbolStyleSelector
