import {useTranslation} from "react-i18next";
import React from "react";
import {apiInstance} from "../../../api/api";
import LoggerService from "../../../services/LoggerService";
import NotificationService from "../../../services/NotificationService";
import {useQuery} from "react-query";
import {toStatus} from "../../../utils/ReactQueryUtils";
import {TIME_5_MIN_IN_MILLISECONDS} from "../../project-drawings/features/editor/constants";
import {SYMBOL_QUERY_KEY} from "../../../api/SymbolsApi";

function useSymbolSystemPacks() {
	const {t} = useTranslation();

	const {data: systemPackVisibilityStates, status} = useQuery({
		queryKey: [SYMBOL_QUERY_KEY, {url: apiInstance.symbolsApi.fetchSystemPackVisibilityStates().url}],
		queryFn: () => apiInstance.symbolsApi.fetchSystemPackVisibilityStates().promise(),
		onError: (err: any) => {
			LoggerService.logError(err);
			NotificationService.errorNotification(t("common.error"),
				t("settings.tabs.symbols.fetchEnabledSystemPackIdsError"));
		},
		staleTime: TIME_5_MIN_IN_MILLISECONDS
	})

	const loadStatus = toStatus(status)
	const enabledSystemPackIds: string[] = React.useMemo(() => {
		if (systemPackVisibilityStates) {
			const generalSystemPackVisibilityState = systemPackVisibilityStates.find(state => state.id === "default");
			const basicSystemPackVisibilityState = systemPackVisibilityStates.find(state => state.id === "basic");
			const generalLettersSystemPackVisibilityState = systemPackVisibilityStates.find(
				state => state.id === "general_letters");
			const generalCharactersSystemPackVisibilityState = systemPackVisibilityStates.find(
				state => state.id === "general_characters");
			const generalNumbersSystemPackVisibilityState = systemPackVisibilityStates.find(
				state => state.id === "general_numbers");
			const enabledIds = systemPackVisibilityStates.filter(state => state.enabled).map(state => state.id);
			if (!generalSystemPackVisibilityState) {
				enabledIds.push("default");
			}
			if (!basicSystemPackVisibilityState) {
				enabledIds.push("basic");
			}
			if (!generalLettersSystemPackVisibilityState) {
				enabledIds.push("general_letters");
			}
			if (!generalCharactersSystemPackVisibilityState) {
				enabledIds.push("general_characters");
			}
			if (!generalNumbersSystemPackVisibilityState) {
				enabledIds.push("general_numbers");
			}
			return enabledIds
		}
		return []
	}, [systemPackVisibilityStates])

	return {
		enabledSystemPackIds,
		loadStatus,
	};
}

export default useSymbolSystemPacks;
