import {useMemo, useState} from "react";
import debounce from "lodash/debounce";
import useResizeObserver from "use-resize-observer";

export const useDebouncedResizeObserver = (wait: number = 100) => {
	const [size, setSize] = useState<{ width?: number, height?: number }>({});

	const onResize = useMemo(() => debounce(setSize, wait), [wait]);
	const {ref} = useResizeObserver({onResize});

	return {ref, ...size};
};