import React, {useMemo} from "react";
import {ProjectSource, QuoteStage} from "../../../models/enums";
import {apiInstance} from "../../../api/api";
import ProjectsWrapper from "./ProjectsWrapper";
import {useTranslation} from "react-i18next";
import {COMPANY_QUOTES_WITH_STAGE_QUERY_KEY} from "../../../api/CompaniesApi";
import {UseProjectListQueryKeyArgs} from "../hooks/useProjectsList";

interface QuotesLoadProps {
	sourceStage: QuoteStage,
}

const QuotesLoad: React.FC<QuotesLoadProps> = ({sourceStage}) => {

	const {t} = useTranslation();

	const stageLabelTranslations = {
		[QuoteStage.COMPLETE]: t(`common.stages.quote.complete`),
		[QuoteStage.PROGRESS]: t(`common.stages.quote.progress`),
		[QuoteStage.OPEN]: t(`common.stages.quote.open`),
		[QuoteStage.ALL]: t(`common.stages.quote.all`),
		[QuoteStage.APPROVED]: t(`common.stages.quote.approved`),
		[QuoteStage.CLOSED]: t(`common.stages.quote.closed`),
	}

	const queryKeyArgs: UseProjectListQueryKeyArgs = useMemo(() => {
		return {
			id: COMPANY_QUOTES_WITH_STAGE_QUERY_KEY,
			stage: sourceStage
		}
	}, [sourceStage])

	return (
		<ProjectsWrapper
			projectSource={ProjectSource.QUOTE}
			stageLabel={stageLabelTranslations[sourceStage]}
			queryKeyArgs={queryKeyArgs}
			asyncDataLoad={apiInstance.companiesApi.fetchCompanyQuotesWithStage(sourceStage)}
		/>
	);
};

export default QuotesLoad
