import React from "react";
import "./textButton.scss"

type TextButtonProps = {
	onClick: () => void
	fontSize?: number
}

export const TextButton: React.FC<TextButtonProps> = ({
	onClick,
	children,
	fontSize
}) => {
	return (
		<button
			style={{fontSize: fontSize ?? 16}}
			onClick={onClick}
			className={"text-button"}>
			{children}
		</button>
	)
}
