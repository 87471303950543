import React from "react";

const IconArrowLeft: React.FC = () => {
	return (
		<svg width="24px" height="24px" viewBox="0 0 24 24">
			<path d="M12,12.5799999 L16.5900002,8 L18,9.40999985 L12,15.4099998 L6,9.40999985 L7.40999985,8 L12,12.5799999 Z"
				  fill="#E2E2E2"
				  transform="translate(12.000000, 11.705000) scale(-1, -1) rotate(-90.000000) translate(-12.000000, -11.705000) "/>
		</svg>
	);
};

export default IconArrowLeft
