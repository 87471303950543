import {SectionApiDto} from "./restModels";
import i18n from "i18next";
import {CostCenter} from "./CostCenter";

export default class Section {
	constructor(
		public readonly id: string,
		public readonly name: string,
		public readonly deletedExternal: boolean,
		public readonly costCenters: CostCenter[],) {}

	public static fromJson(json: SectionApiDto): Section {
		const costCenters = json.costCenters.map(CostCenter.fromJson);
		return new Section(json.id, json.name, json.deletedExternal, costCenters);
	}

	public getAllCostCenterWithCompleteDrawingsIds(): string[] {
		return this.costCenters.filter(cc => cc.hasCompletedDrawings()).map(cc => cc.id);
	}

	getLabel(): string {
		return this.name.length !== 0 ? this.name : i18n.t("projects.details.sectionDefaultName")
	}
}

