import * as Redux from "redux";
import {lengthActions} from "../features/length/lengthSlice";
import {viewActions} from "../features/view/viewSlice";
import {countActions} from "../features/count/countSlice";
import {penActions} from "../features/pen/penSlice";
import {areaActions} from "../features/area/areaSlice";
import {textActions} from "../features/text/textSlice";
import {imageActions} from "../features/image/imageSlice";
import {configActions} from "../features/config/configSlice";
import {scaleActions} from "../features/scale/scaleSlice";

const dirtableActionTypeSet = new Set([
	scaleActions.addScalePoint.type,
	scaleActions.setScale.type,
	scaleActions.setInputLength.type,
	scaleActions.setScaleConfig.type,

	configActions.setStageConfig.type,

	lengthActions.addLength.type,
	lengthActions.addPointToActiveFragment.type,
	lengthActions.startNewLengthFragment.type,
	lengthActions.updateLengthPointPosition.type,
	lengthActions.setWastage.type,
	lengthActions.deleteLength.type,
	lengthActions.changeLengthStyle.type,
	lengthActions.changeMaterial.type,
	lengthActions.disableLinePreview.type,
	lengthActions.finishLines.type,
	lengthActions.updateLengthFragmentPosition.type,
	lengthActions.addDropToActive.type,
	lengthActions.setDropValue.type,
	lengthActions.setDefaultDropValue.type,
	lengthActions.setAllDropsValue.type,
	lengthActions.deleteDrop.type,
	lengthActions.removeItems.type,

	countActions.addCount.type,
	countActions.addCountItem.type,
	countActions.updateCountItemPosition.type,
	countActions.deleteCountGroup.type,
	countActions.changeStyle.type,
	countActions.changeMaterial.type,
	countActions.deleteCountItem.type,
	countActions.removeItems.type,
	countActions.transformCountItem.type,

	penActions.addPenGroup.type,
	penActions.deletePenGroup.type,
	penActions.addPenLine.type,
	penActions.deletePenLine.type,
	penActions.changePenStyle.type,

	areaActions.addArea.type,
	areaActions.addPointToActiveFragment.type,
	areaActions.startNewAreaFragment.type,
	areaActions.closeArea.type,
	areaActions.closeAreas.type,
	areaActions.updateAreaPointPosition.type,
	areaActions.updateAreaFragmentPosition.type,
	areaActions.changeStyle.type,
	areaActions.changeMaterial.type,
	areaActions.deleteArea.type,
	areaActions.setAreaHeight.type,
	areaActions.removeItems.type,
	areaActions.addAreaDeduction.type,
	areaActions.addPointToDeduction.type,
	areaActions.closeDeduction.type,
	areaActions.updateDeductionPointPosition.type,
	areaActions.updateDeductionPosition.type,
	areaActions.deleteDeduction.type,

	textActions.addTextGroup.type,
	textActions.addTextElement.type,
	textActions.updateTextElementPosition.type,
	textActions.transformTextElement.type,
	textActions.changeTextStyle.type,
	textActions.saveTextItem.type,
	textActions.deleteTextItem.type,
	textActions.editTextItem.type,
	textActions.deleteTextGroup.type,

	imageActions.addImage.type,
	imageActions.transformImage.type,
	imageActions.deleteImage.type
])

export const editorIsDirtyMarker: Redux.Middleware = store => next => action => {
	const {dispatch} = store;

	if (dirtableActionTypeSet.has(action.type)) {
		dispatch(viewActions.setEditorIsDirty(true))
	}

	next(action)
}
