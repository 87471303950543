import React from "react";
import {ImageUploadModal} from "../../image/ImageUploadModal";
import {ButtonsGroup} from "../../../../../../../components/layout";
import {BaseImageOptions} from "../../../../../../base-konva/features/image/BaseImageOptions";

interface ImageOptionsProps {
	handleImageSave: (selectedItem: { value: string, label: string }) => void
}

export const ImageOptions: React.FC<ImageOptionsProps> = ({handleImageSave}) => {


	const renderImageUploadModal = (isOpen: boolean, onCloseModal: () => void) => {
		return (
			<ImageUploadModal
				onHandleSave={handleImageSave}
				isOpen={isOpen}
				onCloseModal={onCloseModal}
			/>
		)
	}

	return (
		<ButtonsGroup>
			<BaseImageOptions renderImageUploadModal={renderImageUploadModal}/>
		</ButtonsGroup>
	)
}