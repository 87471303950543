import {useEffect, useState} from "react"

interface UseKeyboardHook {
	isShiftDown: boolean,
	isAltDown: boolean
}

interface UseKeyboardProps {
	keyDownMap?: { [code: string]: () => void }
	keyUpMap?: { [code: string]: () => void }
	when: boolean
}

export function useKeyboard({
	keyDownMap = {},
	keyUpMap = {},
	when: attachListener
}: UseKeyboardProps): UseKeyboardHook {
	const [isShiftDown, setIsShiftDown] = useState(false)
	const [isAltDown, setIsAltDown] = useState(false)

	useEffect(() => {
		const keyDown = (event: KeyboardEvent) => {
			if (keyDownMap[event.code]) {
				keyDownMap[event.code]()
			}
			/** added to handle android "go" button (undefined "code" on mobile devices) **/
			else if (keyDownMap[event.keyCode.toString()]) {
				keyDownMap[event.keyCode.toString()]();
			}
			setIsShiftDown(event.shiftKey)
			setIsAltDown(event.altKey)
		}
		const keyUp = (event: KeyboardEvent) => {
			if (keyUpMap[event.code]) {
				keyUpMap[event.code]()
			}
			/** added to handle android "go" button (undefined "code" on mobile devices) **/
			else if (keyUpMap[event.keyCode.toString()]) {
				keyUpMap[event.keyCode.toString()]();
			}
			setIsShiftDown(event.shiftKey)
			setIsAltDown(event.altKey)
		}

		if (attachListener) {
			document.addEventListener("keydown", keyDown)
			document.addEventListener("keyup", keyUp)
			return () => {
				document.removeEventListener("keydown", keyDown)
				document.removeEventListener("keyup", keyUp)
			}
		}
	}, [keyDownMap, keyUpMap, attachListener])


	return {isShiftDown, isAltDown}
}
