import React from "react";
import "./filePreviewModal.scss";
import {useTranslation} from "react-i18next";
import ModalWithHeader from "../../../../../components/modal/ModalWithHeader";
import ModalButtons from "../../../../../components/modal/ModalButtons";
import Button from "../../../../../components/ui/Button";
import "./planPageDeleteModal.scss"
import {apiInstance} from "../../../../../api/api";
import {PlanUploadData, PlanUploadPageData} from "../../../features/plan-upload/types";
import {useParams} from "react-router-dom";
import NotificationService from "../../../../../services/NotificationService";
import LoggerService from "../../../../../services/LoggerService";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";

type PlanPageDeleteModalProps = {
	onClose: () => void,
	planTemplate: PlanUploadData,
	planPage: PlanUploadPageData,
	onSuccess?: (pageNumber: number, planTemplate: PlanUploadData) => void
}

const PlanPageDeleteModal: React.FC<PlanPageDeleteModalProps> = ({onClose, planTemplate, planPage, onSuccess}) => {
	const {t} = useTranslation();
	const {projectId} = useParams<{ projectId: string }>();
	const {planTemplates: {loadPlanTemplates}} = useProjectDetailContext()

	const onConfirm = async () => {
		try {
			if (planPage.existsExternally) {
				await apiInstance.projectsApi.deletePlanPage(projectId, planTemplate.id, planPage.pageNumber)
				loadPlanTemplates()
			}
			if (onSuccess) {
				onSuccess(planPage.pageNumber, planTemplate)
			}
			onClose();
			NotificationService.successNotification(
				t("common.success"),
				t("projects.details.planTemplates.modals.deletePlanPageModal.deleteSuccess")
			)
		}
		catch (e) {
			NotificationService.errorNotification(t("common.error"),
				t("projects.details.planTemplates.modals.deletePlanPageModal.deleteErrorDesc"));
			LoggerService.logError(e);
		}

	}

	return (
		<ModalWithHeader customClass={"plan-page-delete-modal"} isOpen={true}
						 title={t("projects.details.planTemplates.modals.deletePlanPageModal.title")}
						 onClose={onClose}>
			<div className={"plan-page-delete-modal_content"}>
				<div className={"plan-page-delete-modal_content_message"}>
					{t("projects.details.planTemplates.modals.deletePlanPageModal.message")}
				</div>
				<ModalButtons>
					<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={onClose}/>
					<Button label={t("common.buttons.ok")}
							variant={"primary"}
							onClick={onConfirm}/>
				</ModalButtons>
			</div>
		</ModalWithHeader>
	);
};

export default PlanPageDeleteModal;
