import {useTranslation} from "react-i18next";
import ModalButtons from "../../../../../../../../../components/modal/ModalButtons";
import Button from "../../../../../../../../../components/ui/Button";
import React from "react";

type ContentFooterProps = {
	canSubmit: boolean,
	submit: () => void,
	closeModal: () => void
}

export function ContentFooter(contentFooter: ContentFooterProps) {
	const {t} = useTranslation();
	return (
		<ModalButtons>
			<Button label={t("common.buttons.cancel")} variant={"optional"} onClick={contentFooter.closeModal}/>
			<Button
				label={t("projects.oneOffModal.addItem")}
				variant={"primary"}
				disabled={!contentFooter.canSubmit}
				onClick={() => {
					contentFooter.submit()
					contentFooter.closeModal()
				}}
			/>
		</ModalButtons>
	)
}