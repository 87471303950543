import React, {useEffect} from "react";
import {DrawingType, Status} from "../../../models/enums";
import InlineSpinner from "../../../components/InlineSpinner";
import "./projectDrawingWrapper.scss";
import {useParams} from "react-router-dom";
import {SidePanel} from "../features/editor/features/panel/SidePanel";
import {Editor} from "../features/editor/Editor";
import {useUserSettingsDataContext} from "../../../providers/UserSettingsProvider";
import {Beforeunload} from "react-beforeunload";
import useDrawingLockUnlock from "../../project-details/hooks/useDrawingLockUnlock";
import {useDispatch, useSelector, useStore} from "react-redux";
import {
	selectDrawingLoaded,
	selectEditorIsDirty,
	selectExportInProgress,
	selectRemoteContentLoadedInfo,
	viewActions
} from "../features/editor/features/view/viewSlice";
import {useUserConfirmationDataContext} from "../../../providers/UserConfirmationDataProvider";
import {usePromptCustomConfirmationModal} from "../hooks/usePromptCustomConfirmationModal";
import {useUpdatedRef} from "../../../hooks/useUpdatedRef";
import {useDrawingDetailsDataContext} from "../providers/DrawingDetailsProvider";
import {useProjectDetailContext} from "../../project-details/providers/ProjectDetailProvider";
import {EditorPanelProvider} from "../features/editor/features/panel/EditorPanelProvider";
import {EditorDrawnItemsDataProvider} from "../features/editor/EditorDrawnItemsDataProvider";
import {get} from "../../../utils/ClassNameUtils";
import {useFullscreenContext} from "../../../providers/FullscreenProvider";
import {resetStore, RootState} from "../features/editor/editorStore";

const ProjectDrawingWrapper: React.FC = () => {
	const {fullscreenHandle} = useFullscreenContext()
	const {drawingId, versionId} = useParams<{ drawingId: string, versionId: string }>();
	const {
		drawingData: {
			image, loadStatus, loadDrawing, drawingDetails
		},
		drawingSaveData,
		versionCompleteData,
	} = useDrawingDetailsDataContext()
	const drawingType = drawingDetails?.drawingType ?? DrawingType.TAKE_OFF

	const loadDrawingRef = useUpdatedRef(loadDrawing);
	const {unlockDrawing} = useDrawingLockUnlock(drawingId, drawingType);
	const unlockDrawingRef = useUpdatedRef(unlockDrawing)

	const {loadStatus: settingsLoadStatus, settings} = useUserSettingsDataContext();
	const {projectDetails} = useProjectDetailContext()
	const projectDetailsLoadStatus = projectDetails.loadStatus

	const isDirty = useSelector(selectEditorIsDirty)
	const {allItemsLoaded} = useSelector(selectRemoteContentLoadedInfo)
	const drawingLoaded = useSelector(selectDrawingLoaded)
	const exportInProgress = useSelector(selectExportInProgress);

	const {data: userConfirmationData} = useUserConfirmationDataContext()
	const loadStatusRef = useUpdatedRef(loadStatus)
	const {renderPromptCustomConfirmationModal} = usePromptCustomConfirmationModal(userConfirmationData)
	const dispatch = useDispatch()
	const store = useStore<RootState>()

	// Reset store on editor unmount, and version change
	useEffect(() => {
		return () => {
			resetStore({store})
		}
	}, [store, versionId])

	useEffect(() => {
		const {current: loadDrawing} = loadDrawingRef
		if (settings) {
			loadDrawing(settings, () => {
				dispatch(viewActions.setDrawingLoaded(true))
			})
		}
	}, [drawingId, versionId, loadDrawingRef, settings, dispatch])

	useEffect(() => {
		return () => {
			const {current: unlockDrawing} = unlockDrawingRef
			const {current: loadStatus} = loadStatusRef
			if (loadStatus === Status.SUCCESS) {
				unlockDrawing()
			}
		}
	}, [unlockDrawingRef, loadStatusRef]);

	const exitFullscreenOnUnmountDataRef = useUpdatedRef({fullscreenHandle})
	useEffect(function() {
		return function exitFullscreenOnUnmount() {
			const {current: {fullscreenHandle}} = exitFullscreenOnUnmountDataRef
			if (fullscreenHandle.active) {
				fullscreenHandle.exit().then()
			}
		}
	}, [exitFullscreenOnUnmountDataRef])

	const isLoading = !allItemsLoaded ||
		!drawingLoaded ||
		drawingSaveData.saveStatus === Status.LOADING ||
		versionCompleteData.saveStatus === Status.LOADING ||
		loadStatus === Status.LOADING ||
		settingsLoadStatus !== Status.SUCCESS ||
		projectDetailsLoadStatus !== Status.SUCCESS ||
		exportInProgress;

	function handleBeforeUnload(e: Event) {
		if (isDirty) {
			//Displays browser modal
			e.preventDefault();
		}
	}

	return (
		<EditorPanelProvider>
			<EditorDrawnItemsDataProvider>
				<Beforeunload onBeforeunload={handleBeforeUnload}>
					{renderPromptCustomConfirmationModal()}
					<div className={get(
						"project-plan-drawing",
						{"has-overlay": isLoading},
						{"fullscreen": fullscreenHandle.active}
					)}>
						{isLoading ? (
							<div className={"project-plan-drawing_overlay"}>
								<InlineSpinner height={40} width={40}/>
							</div>
						) : null}
						<SidePanel/>
						<Editor image={image} isLoading={isLoading}/>
					</div>
				</Beforeunload>
			</EditorDrawnItemsDataProvider>
		</EditorPanelProvider>
	);
};

export default ProjectDrawingWrapper
