import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {apiInstance} from "../../../../../api/api";
import {useLocation, useParams} from "react-router-dom";
import LoggerService from "../../../../../services/LoggerService";
import NotificationService from "../../../../../services/NotificationService";
import {useProjectDetailContext} from "../../../providers/ProjectDetailProvider";
import {PlanUploadAttachment} from "../../../features/plan-upload/types";
import TemplatePagesPreviewModal from "./TemplatePagesPreviewModal";
import {checkIfAttachmentIsPdf, DEFAULT_PDF_RASTERIZATION_DPI, getPlanPagesToUpload} from "../../../utils/planTemplateUtils";
import {pages, useNavigator} from "../../../../navigator";

interface CreatePlanTemplateModalProps {
	refreshPlanTemplates: () => void,
	onClose: () => void,
	onPrevious: () => void,
	previewFileData: { previewSources: string[], displayedFilename: string, attachmentData?: PlanUploadAttachment },
}

const CreatePlanTemplateModal: React.FC<CreatePlanTemplateModalProps> = ({
	onClose,
	onPrevious,
	previewFileData,
	refreshPlanTemplates,
}) => {
	const {t} = useTranslation();
	const {previewSources, attachmentData, displayedFilename} = previewFileData;
	const {projectId} = useParams<{ projectId: string }>();
	const [dpiValue, setDpiValue] = useState<number>(DEFAULT_PDF_RASTERIZATION_DPI)
	const {planUploadData: {initEditorPlanUploadData}} = useProjectDetailContext()
	const location = useLocation();
	const type = /quotes/.test(location.pathname) ? "quotes" : "jobs";
	const {navigateTo} = useNavigator()

	const onNext = async (selectedPageNumbers: number[]) => {
		if (attachmentData && displayedFilename !== "") {
			try {
				const template = await apiInstance.projectsApi.createPlanTemplate(
					projectId, attachmentData.id, displayedFilename, previewSources.length
				)
				const planPages = await getPlanPagesToUpload(projectId, template.isPdf, template.id,
					template.attachment.id, selectedPageNumbers, dpiValue)

				initEditorPlanUploadData({
					attachment: attachmentData,
					name: displayedFilename,
					pages: planPages,
					id: template.id,
					isPdf: template.isPdf,
					fullPageCount: previewSources.length,
					dpi: dpiValue
				})
				navigateTo(pages.planUploadPage(type, projectId));
				onClose();
			}
			catch (err) {
				LoggerService.logError(err);
				NotificationService.errorNotification(
					t("common.error"),
					t("projects.details.planTemplates.planTemplateCreationErrorDesc")
				)
			}
			finally {
				refreshPlanTemplates()
			}
		}
	}

	const onDpiChange = (value: number) => {
		setDpiValue(value)
	}

	const isTemplatePdf = (attachmentData && checkIfAttachmentIsPdf(attachmentData.filename, attachmentData.contentType)) ?? false

	return (
		<TemplatePagesPreviewModal
			onClose={onClose}
			isTemplatePdf={isTemplatePdf}
			dpiValue={dpiValue}
			onDpiInputChange={onDpiChange}
			onBackButtonClick={onPrevious}
			backButtonLabel={t("common.buttons.previous")}
			onNext={onNext} previewFileData={previewFileData}/>
	);
};

export default CreatePlanTemplateModal;