import {AxiosInstance} from "axios";
import {getPaginationFromHeaders} from "../utils/PaginationUtils";
import {paramsSerializer} from "../utils/ApiUtils";
import {TakeoffTemplate} from "../models/TakeoffTemplate";
import {TakeoffTemplateGroup} from "../models/TakeoffTemplateGroup";
import {PaginatedResponse} from "../models/interfaces";
import TakeoffTemplateItem from "../models/TakeoffTemplateItem";
import {TakeoffTemplateItemUpdateApiDto} from "../models/restModels";

export const FETCH_TAKEOFF_TEMPLATES_QUERY_KEY = 'FETCH_TAKEOFF_TEMPLATES_QUERY_KEY'

export default class TakeoffTemplatesApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchTakeoffTemplates(
		page: number,
		size: number,
		query?: string,
		groupId?: number | null,
		subGroupId?: number | null
	): Promise<PaginatedResponse<TakeoffTemplate>> {
		const params = {page, size, query, group_id: groupId, sub_group_id: subGroupId};
		const url = `/api/take_off_templates`
		return this.axiosInstance.get(url, {params, paramsSerializer}).then(resp => {
			const paginationConfig = getPaginationFromHeaders(resp.headers)
			const records = resp.data.map(TakeoffTemplate.fromJson)
			return ({records, ...paginationConfig})
		})
	}

	fetchTakeoffTemplateGroups(parentGroupId?: number | null): Promise<TakeoffTemplateGroup[]> {
		const params = {parent_group_id: parentGroupId}
		const url = `/api/take_off_template_groups`
		return this.axiosInstance.get(url, {params}).then(resp => {
			return resp.data.map(TakeoffTemplateGroup.fromJson)
		})
	}

	fetchTakeoffTemplateItems(templateId: number): Promise<TakeoffTemplateItem[]> {
		const url = `/api/take_off_templates/${templateId}/items`
		return this.axiosInstance.get(url).then(resp => {
			return resp.data.map(TakeoffTemplateItem.fromJson)
		})
	}

	putTakeoffTemplateItems(templateId: number, items: TakeoffTemplateItemUpdateApiDto[]): Promise<void> {
		const url = `/api/take_off_templates/${templateId}/items`
		const data = {
			takeOffTemplateItems: items
		}
		return this.axiosInstance.put(url, data);
	}
}