import {AxiosInstance} from "axios";
import {SymbolGroup} from "../models/SymbolGroup";
import {SymbolPack} from "../models/SymbolPack";
import {Symbol} from "../models/Symbol";
import SystemPackVisibilityState from "../models/SystemPackVisibilityState";
import {WithUrl} from "./api";

export const SYMBOL_QUERY_KEY = "SYMBOL_QUERY_KEY"

export default class SymbolsApi {
	constructor(public readonly axiosInstance: AxiosInstance) {}

	fetchSymbolGroups(): WithUrl<SymbolGroup[]> {
		const url = `/api/symbol_groups`
		return {
			url,
			promise: () => this.axiosInstance.get(url).then(resp => resp.data.map(SymbolGroup.fromJson))
		}
	}

	postSymbolGroup(name: string): Promise<SymbolGroup> {
		const url = `/api/symbol_groups`;
		const data = {name};
		return this.axiosInstance.post(url, data).then(resp => SymbolGroup.fromJson(resp.data));
	}

	putSymbolGroup(name: string, symbolGroupId: string): Promise<void> {
		const url = `/api/symbol_groups/${symbolGroupId}/edit`;
		const data = {name};
		return this.axiosInstance.put(url, data);
	}

	deleteSymbolGroup(symbolGroupId: string): Promise<void> {
		const url = `/api/symbol_groups/${symbolGroupId}`;
		return this.axiosInstance.delete(url);
	}

	fetchSymbolPacks(symbolGroupId: string): WithUrl<SymbolPack[]> {
		const url = `/api/symbol_groups/${symbolGroupId}/symbol_packs`;
		return {
			url,
			promise: () => this.axiosInstance.get(url).then(resp => resp.data.map(SymbolPack.fromJson))
		}
	}

	postSymbolPack(symbolGroupId: string, name: string, symbols: string[]): Promise<SymbolPack> {
		const url = `/api/symbol_groups/${symbolGroupId}/symbol_packs`;
		const data = {name, symbols};
		return this.axiosInstance.post(url, data).then(resp => SymbolPack.fromJson(resp.data));
	}

	putSymbolPack(name: string, symbolPackId: string): Promise<void> {
		const url = `/api/symbol_packs/${symbolPackId}/edit`;
		const data = {name};
		return this.axiosInstance.put(url, data);
	}

	deleteSymbolPack(symbolPackId: string): Promise<void> {
		const url = `/api/symbol_packs/${symbolPackId}`;
		return this.axiosInstance.delete(url);
	}

	fetchSymbols(symbolPackId: string): WithUrl<Symbol[]> {
		const url = `/api/symbol_packs/${symbolPackId}/symbols`;
		return {
			url,
			promise: () => this.axiosInstance.get(url).then(resp => resp.data.map(Symbol.fromJson))
		}
	}

	putSystemPackVisibility(systemPackId: string, enabled: boolean): Promise<void> {
		const url = "/api/system_packs";
		return this.axiosInstance.put(url, {name: systemPackId, enabled});
	}

	fetchSystemPackVisibilityStates(): WithUrl<SystemPackVisibilityState[]> {
		const url = "/api/system_packs";
		return {
			url,
			promise: () => this.axiosInstance.get(url).then(
				resp => resp.data.map(SystemPackVisibilityState.fromJson)
			)
		}
	}
}
